import React from 'react';
import './ClickableText.scss';
import { ReactComponent as IconMemory } from '../../../assets/icons/u_ayudamemoria.svg';
type Props = {
  text: any;
  onClick: any;
  fontSize?: any;
  textTransform?: any;
  iconStyle?: any;
  icon?: any;
};

const ClickableText = ({ text, onClick,  icon }: Props) => {
  return (
    <>
      <div
        className="project-detail-clickable-text btn btn-primary-text"
        onClick={onClick}
      >
        {icon}
        {text}
      </div>
    </>
  );
};

export default ClickableText;
