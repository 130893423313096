import React from 'react';
import './ProjectCounter.scss';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../../../services/booleanUtil';
import { IndicatorKeyEnum } from '../../../models/IndicatorKeyEnum';
import { AverageLagCounter } from '../AverageLagCounter/AverageLagCounter';
import { DynamicProjectProductTypeCounter } from './DynamicProjectProductTypeCounter/DynamicProjectProductTypeCounter';
import { ProjectAndProductTypeCounter } from './ProjectAndProductTypeCounter/ProjectAndProductTypeCounter';
import { JustProjectCounter } from './JustProjectCounter/JustProjectCounter';

interface ReceivedProps {
  arrayToCount: any;
  countProjects: boolean; //If false Count Accordance
  widthStyle?: string;
  indicatorKey?: any;
  footerColumnsInformation?: any;
  nestedProject?: any;
  countOnlyProduct?: boolean;
  showOnlyProjects?: boolean;
}

type Props = ReceivedProps;

export const setPlurals = (projectsLength, countProjects) => {
  if (countProjects) {
    return projectsLength > 1 ? 'projects' : 'operation';
  } else {
    return projectsLength > 1 ? 'accordances' : 'accordance';
  }
};

export const setProductPlurals = products => {
  const lengthValue = products.length;
  return lengthValue > 1 ? 'knowledge_products' : 'knowledge_product';
};

export const ProjectCounter = (props: Props) => {
  const { t } = useTranslation();
  const {
    arrayToCount,
    countProjects,
    widthStyle,
    indicatorKey,
    footerColumnsInformation,
    nestedProject,
    countOnlyProduct,
    showOnlyProjects = false,
  } = props;

  const totalProjects = countTotalProjects(arrayToCount, haveNestedProperty(nestedProject), indicatorKey);
  const textValue = countOnlyProduct ? setProductPlurals(arrayToCount) : setPlurals(totalProjects, countProjects);

  const printTotalLoan = () => {
    switch (indicatorKey) {
      case IndicatorKeyEnum.PROJECTS_OVERALL_STAGE_CODE_DISBURSING_NO_DISBURSEMENTS_OVER_180_DAYS:
      case IndicatorKeyEnum.PROJECTS_EXTENDED_NOT_FULLY_DISBURSED:
      case IndicatorKeyEnum.PROJECTS_PENDING_DISBURSEMENT_ELIGIBILITY:
      case IndicatorKeyEnum.PROJECTS_PENDING_SIGNATURE_OR_LEGISLATIVE_APPROVAL:
      case IndicatorKeyEnum.DISBURSEMENT_PROJECTION_CURRENT_YEAR_INCLUDING_REGIONALS:
      case IndicatorKeyEnum.PMR_STATE_DISTRIBUTION:
      case IndicatorKeyEnum.PROJECTS_LAST_DISBURSEMENT_DATE_CLOSE_12MONTHS_OR_EXPIRED:
      case IndicatorKeyEnum.USER_PENDING_TASKS:
      case IndicatorKeyEnum.DISTRIBUTION_ACCORDING_TO_LATEST_EFAS:
      case IndicatorKeyEnum.ESG_DISTRIBUTION_ACCORDING_TO_ENVIRONMENTAL_AND_SOCIAL_CATEGORY:
      case IndicatorKeyEnum.ESG_SAFEGUARD_PERFORMANCE_RATING:
      case IndicatorKeyEnum.ESG_ENVIRONMENTAL_AND_SOCIAL_RISK_RATING:
      case IndicatorKeyEnum.PROJECTS_SUMMARY_PORTFOLIO:
        return showOnlyProjects ? (
          <JustProjectCounter
            arrayToCount={arrayToCount}
            countProjects={countProjects}
            totalProjects={totalProjects}
            widthStyle={widthStyle}
            textValue={textValue}
          />
        ) : (
          <ProjectAndProductTypeCounter
            arrayToCount={arrayToCount}
            countProjects={countProjects}
            totalProjects={totalProjects}
            widthStyle={widthStyle}
            textValue={textValue}
          />
        );

      case IndicatorKeyEnum.AVERAGE_LAG:
        return <AverageLagCounter footerColumnsInformation={footerColumnsInformation} />;

      case IndicatorKeyEnum.BID_CONSULTANCY:
      case IndicatorKeyEnum.GCM_ALERTS:
        return (
          <div
            className="project-counter-container"
            style={isMobile() ? { width: '100%' } : { width: '100%', maxWidth: widthStyle }}
          >
            <div className="values">
              <div className="project-value" style={{ lineHeight: '40px' }}>
                <span className="capitlaize-text"> {t('total')}</span>: {arrayToCount.length}{' '}
                <span className="lowercase-text">
                  {' '}
                  {indicatorKey === IndicatorKeyEnum.GCM_ALERTS
                    ? t('technical_cooperation')
                    : t('active_contracts')}{' '}
                </span>
              </div>
            </div>
          </div>
        );
      case IndicatorKeyEnum.PROJECTS_IN_PREPARATION:
        return (
          <DynamicProjectProductTypeCounter
            arrayToCount={arrayToCount}
            countProjects={countProjects}
            totalProjects={totalProjects}
            widthStyle={widthStyle}
          />
        );
      case IndicatorKeyEnum.TCP_PERFORMANCE:
        return (
          <ProjectAndProductTypeCounter
            arrayToCount={arrayToCount}
            countProjects={countProjects}
            totalProjects={totalProjects}
            widthStyle={widthStyle}
            textValue={textValue}
          />
        );

      default:
        return (
          <JustProjectCounter
            arrayToCount={arrayToCount}
            countProjects={countProjects}
            totalProjects={totalProjects}
            widthStyle={widthStyle}
            textValue={textValue}
          />
        );
    }
  };

  return printTotalLoan();
};
export const countTotalProjects = (arrayToCount, nestedProperty = '', indicatorKey = '') => {
  if (
    indicatorKey === IndicatorKeyEnum.ACCORDANCES_EXPIRING ||
    indicatorKey === IndicatorKeyEnum.ACCORDANCES_WAITING_APPROVAL
  ) {
    return arrayToCount.length;
  }

  return arrayToCount.filter((actualValue, actualIndex, arrayToFind) => {
    return nestedProperty
      ? arrayToFind.findIndex(arrayValue => arrayValue[nestedProperty].code === actualValue[nestedProperty].code) ===
          actualIndex
      : arrayToFind.findIndex(arrayValue => arrayValue.code === actualValue.code) === actualIndex;
  }).length;
};

const haveNestedProperty = nestedProject => {
  return nestedProject !== '' ? nestedProject : '';
};
