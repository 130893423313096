import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Project from '../../../models/Project';
import ConsultanciesFinancesAndExecutedByTheBankTable from '../ConsultanciesFinancesAndExecutedByTheBankTable/ConsultanciesFinancesAndExecutedByTheBankTable';
import { bidConsultancyIndicatorTableColumns } from '../../../services/util';
import { ProjectCounter } from '../ProjectCounter/ProjectCounter';
import { getFilterSessionValue } from '../../../services/sessionFiltersIndicatorDetailHelper';
import { IDetailFilters } from '../../../models/FilterIndicators';
import { setFilterIndicators } from '../../../redux/actions/filterIndicators';

type Props = {
  indicatorKey: string;
};

const PortfolioConsFinaAndExecutedBank = (props: Props) => {
  const { indicatorKey } = props;
  const dispatch = useDispatch();
  const { filterIndicators } = useSelector<any, any>(state => state);
  const indicatorProjects = useSelector<any, Project[]>(state => state.indicatorProjects);
  const [filteredProjects, setFilteredProjects] = useState(indicatorProjects);
  const { detailFilters } = useSelector<any, any>(state => state.filterIndicators);
  const { searchTerm, stateFilter, sourceFilter } = detailFilters as IDetailFilters;

  useEffect(() => {
    dispatch(
      setFilterIndicators({
        ...filterIndicators,
        detailFilters: {
          stateFilter: 'all',
          sourceFilter: 'all',
          searchTerm: '',
        },
      }),
    );
    setFilteredProjects(indicatorProjects);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indicatorProjects]);

  useEffect(() => {
    setFilteredProjects(filterAndSearchProjects(indicatorProjects, searchTerm, stateFilter, sourceFilter));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, stateFilter, sourceFilter]);

  return (
    <>
      <ConsultanciesFinancesAndExecutedByTheBankTable
        projects={filteredProjects}
        indicatorKey={indicatorKey}
        tableInfo={bidConsultancyIndicatorTableColumns(getFilterSessionValue('consultanciesTabs'))}
      />
      <ProjectCounter arrayToCount={filteredProjects} countProjects={true} indicatorKey={indicatorKey} />
    </>
  );
};

export default PortfolioConsFinaAndExecutedBank;

const filterAndSearchProjects = (projects, searchTerm, stateFilter, sourceFilter) => {
  return projects.filter(p => {
    return (
      (p.field2.toLowerCase().search(searchTerm) !== -1 ||
        p.code.toLowerCase().search(searchTerm) !== -1 ||
        p.field1.toLowerCase().search(searchTerm) !== -1) &&
      handleStateFilter(p, stateFilter) &&
      handleSourceFilter(p, sourceFilter)
    );
  });
};

const handleStateFilter = (project, stateFilter) => {
  if (stateFilter === 'all') {
    return project;
  }
  return project.field8 === stateFilter;
};

const handleSourceFilter = (project, sourceFilter) => {
  if (sourceFilter === 'all') {
    return project;
  }
  return project.field9.includes(sourceFilter);
};
