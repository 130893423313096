import { Quadrants } from '../../../models/QuadrantsEnum';
import { groupBy } from 'lodash';
import { getRealativeRadio } from '../../Portfolio/IndicatorsHelpers/util';
import 'chartjs-plugin-zoom';
import { ProjectProductTypes } from '../../../models/ProjectProductTypes';
export const handleModalPositionLimits = elementSelected => {
  const cardHeigth = 336;
  const cardWidth = 324;
  const { bottom, right } = elementSelected[0]._chart.chartArea;
  const { x, y } = elementSelected[0]._model;

  const radius = elementSelected[0]._model.radius;

  const adjustYByRadius = radius <= 25 ? 30 : 0;
  const xModalAdjustValue = 50;
  const yModalAdjustValue = cardHeigth / 2 + adjustYByRadius;
  const scrolled = window.pageYOffset;

  let modalX = x + xModalAdjustValue;

  let modalY = y + yModalAdjustValue;

  const brakeRightLimitX = modalX + cardWidth >= right;

  if (brakeRightLimitX) {
    const diff = right - x;
    modalX = x + xModalAdjustValue - diff + (radius <= 25 ? 30 : 15);
  }

  const brakeBottomLimitY = modalY + cardHeigth >= bottom;
  if (brakeBottomLimitY) {
    if (cardHeigth - y > 0) {
      modalY = modalY - cardHeigth;
    } else {
      modalY = modalY - cardHeigth - scrolled;
    }
  } else {
    if (scrolled > 0) {
      modalY = modalY - scrolled;
    }
  }

  return { x: modalX, y: modalY };
};

export const setAxisYPosition = (filterApplied = ProjectProductTypes.Loan) => {
  if (filterApplied === ProjectProductTypes.Loan) {
    return 2.5;
  } else if (filterApplied === ProjectProductTypes.CT) {
    return 1.5;
  } else {
    return 2;
  }
};

export const setAxis = chart => {
  const upperY = chart.scales['y-axis-0'].end;
  const bottomY = chart.scales['y-axis-0'].start;
  const leftX = chart.scales['x-axis-0'].end;
  const rightX = chart.scales['x-axis-0'].start;

  localStorage.setItem('bubblesAxis', JSON.stringify({ upperY, bottomY, leftX, rightX }));
};

export const setOptions = (filterApplied, axis, forReport = false) => {
  const { upperY, bottomY, leftX, rightX } = axis;
  const verticalLinePostion = setAxisYPosition(filterApplied);
  return {
    annotation: {
      annotations: [
        {
          drawTime: 'beforeDatasetsDraw',
          borderColor: '#979797',
          borderDash: [4, 4],
          borderWidth: 2,
          mode: 'vertical',
          type: 'line',
          value: verticalLinePostion,
          scaleID: 'x-axis-0',
        },
        {
          drawTime: 'beforeDatasetsDraw',
          borderColor: '#979797',
          borderDash: [4, 4],
          borderWidth: 2,
          mode: 'horizontal',
          type: 'line',
          value: 50,
          scaleID: 'y-axis-0',
        },
        {
          drawTime: 'beforeDatasetsDraw',
          borderColor: '#979797',
          borderWidth: 2,
          mode: 'horizontal',
          type: 'line',
          value: 0,
          scaleID: 'y-axis-0',
        },
      ],
    },
    animation: {
      duration: forReport ? 0 : 1000,
    },
    plugins: {
      datalabels: {
        display: false,
      },

      zoom: forReport
        ? {}
        : {
            pan: {
              enabled: true,
              mode: 'xy',
              onPanComplete({ chart }) {
                setAxis(chart);
                chart.update();
              },
            },

            zoom: {
              enabled: true,
              mode: 'xy',
              onZoomComplete({ chart }) {
                setAxis(chart);
                chart.update();
              },
            },
          },
    },

    scales: {
      xAxes: [
        {
          display: true,
          stacked: false,
          ticks: {
            beginAtZero: true,
            min: leftX,
            max: rightX,
            stepSize: 0.5,
            fontSize: 12,
            fontFamily: 'Rubik',
            fontColor: '#727e8c',
          },
          gridLines: {
            display: true,
          },
        },
      ],

      yAxes: [
        {
          display: true,
          stacked: true,
          ticks: {
            min: bottomY,
            max: upperY,
            stepSize: 10,
            beginAtZero: false,
            fontSize: 12,
            fontFamily: 'Rubik',
            fontColor: '#727e8c',
          },
          gridLines: {
            display: true,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    tooltips: {
      enabled: true,
      backgroundColor: '#101319',
    titleFontSize: 14, 
    bodyFontSize: 14,
      callbacks: {
        title: () => {},
        label(tooltipItem, data) {
          return data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].modalData.code;
        },
      },
    },
    hover: {
      onHover: e => (e.target.style.cursor = 'pointer'),
      mode: 'point',
      intersect: true,
    },
  };
};

export const getQuadrant = (bubble, filterApplied = ProjectProductTypes.Loan) => {
  const axisYByFilterApplied = setAxisYPosition(filterApplied);
  if (parseFloat(bubble.field2) <= 50) {
    if (parseFloat(bubble.field1) <= axisYByFilterApplied) {
      return Quadrants.BOTTOM_LEFT;
    } else {
      return Quadrants.BOTTOM_RIGHT;
    }
  } else {
    if (parseFloat(bubble.field1) <= axisYByFilterApplied) {
      return Quadrants.TOP_LEFT;
    } else {
      return Quadrants.TOP_RIGHT;
    }
  }
};

const QUADRANTS_COLORS = ['orange', 'red', 'green', 'grey'];

//Esta función revisa las burbujas de un cuadrante dado y si alguna de sus burbujas coincide
//en todos los valores :: EJE X, Y, RADIO realiza un movimiento en el eje X dado por la
//constante xMovmentIndex
const checkIfHasDuplicatedAndMoveIt = (arr: any = []) => {
  const xMovmentIndex = 1.003;
  const seen = new Set();
  const duplicated: any = [];
  const filtered = arr.filter(bubble => {
    const bubbleSeen = `${bubble.x}-${bubble.y}-${bubble.r}`;
    const isDuplicate: boolean = seen.has(bubbleSeen);
    isDuplicate && duplicated.push(bubble);
    seen.add(bubbleSeen);
    return !isDuplicate;
  });
  const rearangeDuplicatedObjects = duplicated.map(d => ({ ...d, x: d.x * xMovmentIndex }));
  return [...filtered, ...rearangeDuplicatedObjects];
};

export const parseToChartBubbles = (data, filterApplied = ProjectProductTypes.Loan, maxRadioInPixels = 100) => {
  const maxRadioData = Math.max(...data.map(i => parseFloat(i.field5)));
  const minRadioData = Math.min(...data.map(o => parseFloat(o.field5)).filter(i => parseInt(i) !== 0));

  const createBubbleObject = data.map(bubble => {
    const {
      code,
      title,
      loanNumber,
      id: projectId,
      field1: yearsOfLegalValidity,
      field2: currentDisbursement,
      field3: pendingDisbursement,
      field5: pendingDisbursementVolume,
      field6: extension,
    } = bubble;

    return {
      color: QUADRANTS_COLORS[getQuadrant(bubble, filterApplied)],
      x: parseFloat(yearsOfLegalValidity),
      y: parseFloat(currentDisbursement),
      r: getRealativeRadio(
        maxRadioInPixels,
        maxRadioData,
        parseInt(pendingDisbursementVolume > 0 ? pendingDisbursementVolume : minRadioData),
      ),
      projectId,
      modalData: {
        code,
        title,
        loanNumber,
        yearsOfLegalValidity,
        currentDisbursement,
        pendingDisbursement,
        extension: extension && extension === '1' ? true : false,
        projectId,
      },
    };
  });
  const grupedByColor = groupBy(createBubbleObject, 'color');
  return {
    datasets: [
      bubbleDataObject('green', checkIfHasDuplicatedAndMoveIt(grupedByColor['green']), '#4E9C6E'),
      bubbleDataObject('grey', checkIfHasDuplicatedAndMoveIt(grupedByColor['grey']), '#727E8C'),
      bubbleDataObject('orange', checkIfHasDuplicatedAndMoveIt(grupedByColor['orange']), '#FDAC41'),
      bubbleDataObject('red', checkIfHasDuplicatedAndMoveIt(grupedByColor['red']), '#F06D6D'),
    ],
  };
};

const bubbleDataObject = (label, data, color) => {
  return {
    label,
    data,
    backgroundColor: 'transparent',
    borderColor: color,
    hoverBorderColor: color,
    borderWidth: 2,
    hoverBorderWidth: 4,
  };
};
