import React from 'react';
import { useTranslation } from 'react-i18next';
import CloseCrossButton from '../../Buttons/CloseCrossButton/CloseCrossButton';
import { overflowYModalStyles } from '../Styles';
import analyticStyles from './AnalyticIndicatorModal.module.scss';

import { renderAnalyticCard } from '../../../ContentManager/Analytics/AnalyticCard/AnalyticCard';
import { capitalizeFirstLetter } from '../../../../services/stringUtil';
import { AnalyticsKeyEnum } from '../../../../models/AnalyticsKeyEnum';
import { MultipleStackedBar } from '../../../ContentManager/Analytics/AnalyticCard/Cards/MultipleStackedBar/MultipleStackedBar';
import BaseModal from '../BaseModal';

type Props = {
  isOpen: boolean;
  closeModal: any;
  indicatorData?: any;
  sessionIndFilter?: any;
  updateSessionIndFilter?: any;
};

const AnalyticIndicatorModal = ({
  isOpen,
  closeModal,
  indicatorData,
  sessionIndFilter,
  updateSessionIndFilter,
}: Props) => {
  const { title, indicatorKey } = indicatorData;

  const { t } = useTranslation();
  return (
    <BaseModal
      isOpen={isOpen}
      onRequestClose={() => {
        closeModal();
      }}
      style={overflowYModalStyles}
      className={defineContentClassName(indicatorKey)}
    >
      <div className={analyticStyles.analytcModalContainer}>
        <div className={analyticStyles.closeCrossContainer}>
          <CloseCrossButton handleClick={closeModal} />
        </div>
        {indicatorData.indicatorKey === AnalyticsKeyEnum.AGGREGATE_INDICATORS_LOGINS_COMPLEX ||
        indicatorData.indicatorKey === AnalyticsKeyEnum.AGGREGATE_INDICATORS_USERS_WHO_ENTERED_THE_TOOL_COMPLEX ? (
          <div className={analyticStyles.title}>
            {capitalizeFirstLetter(`${t(title)} - ${sessionIndFilter.id === 0 ? t('all_roles') : t('all_countries')}`)}
          </div>
        ) : (
          <div className={analyticStyles.title}>{capitalizeFirstLetter(t(title))}</div>
        )}

        {indicatorData.indicatorKey === AnalyticsKeyEnum.AGGREGATE_INDICATORS_LOGINS_COMPLEX ||
        indicatorData.indicatorKey === AnalyticsKeyEnum.PROJECT_INDICATORS_USE_OF_SECTIONS_COMPLEX ||
        indicatorData.indicatorKey === AnalyticsKeyEnum.AGGREGATE_INDICATORS_USERS_WHO_ENTERED_THE_TOOL_COMPLEX ? (
          <div className={analyticStyles.multiChartHorizontal}>
            {indicatorData && indicatorData.indicatorKey ? (
              <MultipleStackedBar
                card={indicatorData}
                isOpen={isOpen}
                sessionIndFilter={sessionIndFilter}
                updateSessionIndFilter={item => updateSessionIndFilter(item)}
                simple={indicatorData.indicatorKey === AnalyticsKeyEnum.PROJECT_INDICATORS_USE_OF_SECTIONS_COMPLEX}
              />
            ) : null}
          </div>
        ) : (
          <div className={setHorizontalClass(indicatorKey)}>
            {renderAnalyticCard(defineObjectToRender(indicatorData), true, t, false, updateSessionIndFilter)}
          </div>
        )}
      </div>
    </BaseModal>
  );
};

export default AnalyticIndicatorModal;

const setHorizontalClass = analyticIndicator => {
  if (analyticIndicator === AnalyticsKeyEnum.PORTFOLIO_INDICATORS_MOST_USED_INDICATORS) {
    return analyticStyles.extraHeightHorizontal;
  } else if (analyticIndicator === AnalyticsKeyEnum.PROJECT_INDICATORS_AGREEMENTS_BAR_GRAPH) {
    return analyticStyles.accordanceHeightHorizontal;
  } else {
    return analyticStyles.horizontal;
  }
};

const defineObjectToRender = indicatorData => {
  if (
    AnalyticsKeyEnum.PORTFOLIO_INDICATORS_MOST_USED_INDICATORS === indicatorData.indicatorKey ||
    AnalyticsKeyEnum.PROJECT_INDICATORS_AGREEMENTS_BAR_GRAPH === indicatorData.indicatorKey
  ) {
    return indicatorData.dataChartDetail;
  } else {
    return indicatorData;
  }
};

const defineContentClassName = indicatorKey => {
  if (indicatorKey === AnalyticsKeyEnum.PORTFOLIO_INDICATORS_MOST_USED_INDICATORS) {
    return analyticStyles.analyticMostUsedModalContent;
  } else if (indicatorKey === AnalyticsKeyEnum.PROJECT_INDICATORS_AGREEMENTS_BAR_GRAPH) {
    return analyticStyles.analyticAccordanceBarchartModalContent;
  }

  return analyticStyles.analyticModalContent;
};
