import React, { useEffect, useState } from 'react';
import { TabsFilter } from './TabsFilter/TabsFilter';
import styles from './LastDisbursementDateCloseOrExpired.module.scss';
import { EarlyWarningsFilters } from '../EarlyWarningsFilters/EarlyWarningsFilters';
import { LastDisbursementTable } from './Table/Table';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectsSimpleTableIndicator } from '../../../redux/actions/indicatorProjects';
import { LAST_DISBURSEMENT_DATE_CLOSE_OR_EXPIRED } from '../../../services/sessionFiltersIndicatorDetailHelper';
import { FilterConfigValues, IconCode, TabFilterOption } from '../../../models/EarlyWarnings';
import { useEarlyWarningsFilters } from '../../../hooks/useEarlyWarningsFilters';
import { showEarlyWarningModule } from './Util';
import { createFilterConfigComponent } from '../EarlyWarningsFilters/Util';
import { handleLoading } from '../../utils';

const filterConfigValues: FilterConfigValues[] = [
  { id: 0, filterIcon: IconCode.GREEN, filterLabel: 'within_the_period_for_justification_120_days' },
  { id: 1, filterIcon: IconCode.YELLOW, filterLabel: 'with_date_for_last_disbursement_expired_by_less_than_1_year' },
  { id: 2, filterIcon: IconCode.RED, filterLabel: 'with_date_for_last_disbursement_expired_by_1_year_or_more' },
];
const filterConfigComponent: TabFilterOption[] = createFilterConfigComponent(filterConfigValues);

const defaultSessionFilterObject = {
  selectedTab: 0,
  filterConfigState: filterConfigComponent,
  changeProjectType: true,
};

export const LastDisbursementDateCloseOrExpired = () => {
  const { currentIndicator } = useSelector<any, any>(state => state);
  const { projectTypeFilterOnDetail } = useSelector<any, any>(state => state.indicatorDetailFilter);
  const { filterConfigState, handleEarlyWarningsFilter, handleProjectsQuantities, selectedTab, setSelectedTab } =
    useEarlyWarningsFilters({
      sessionKey: LAST_DISBURSEMENT_DATE_CLOSE_OR_EXPIRED,
      defaultSessionFilterObject,
      projectTypeFilterOnDetail,
    });
  const dispatch = useDispatch();
  const { container, tabsContainer, filtersEarlyWarningsContainer, tableContainer } = styles;
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getFilteredSimpleIndicators(dispatch, currentIndicator.id, currentIndicator.indicatorKey, setLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndicator.id, dispatch, projectTypeFilterOnDetail]);

  useEffect(() => {
    if (!sessionStorage.getItem(LAST_DISBURSEMENT_DATE_CLOSE_OR_EXPIRED)) {
      sessionStorage.setItem(LAST_DISBURSEMENT_DATE_CLOSE_OR_EXPIRED, JSON.stringify(defaultSessionFilterObject));
    }
  }, []);

  const handleFilterApplied = filter => {
    setSelectedTab(filter);
  };

  return (
    <div className={container}>
      <div className={tabsContainer}>
        <TabsFilter filterBy={selectedTab} handleFilterApplied={handleFilterApplied} />
      </div>
      {showEarlyWarningModule(projectTypeFilterOnDetail, selectedTab) && (
        <div className={filtersEarlyWarningsContainer}>
          <EarlyWarningsFilters filterSet={filterConfigState} handleFilter={handleEarlyWarningsFilter} />
        </div>
      )}
      <div className={tableContainer}>
        <LastDisbursementTable
          selectedTab={selectedTab}
          earlyWarningsTab={[
            filterConfigState[0].selected,
            filterConfigState[1].selected,
            filterConfigState[2].selected,
          ]}
          projectsQuantities={handleProjectsQuantities}
          loading={loading}
        />
      </div>
    </div>
  );
};

const getFilteredSimpleIndicators = (d, currentIndicatorId, currentIndicatorKey, setLoading) => {
  return handleLoading(() => d(getProjectsSimpleTableIndicator(currentIndicatorId, currentIndicatorKey)), setLoading);
};
