import React, { useState } from 'react';
import './OrderIndicators.scss';
import { useTranslation } from 'react-i18next';
import SaveButton from '../../../Buttons/SaveButton/SaveButton';
import { useCustomizableReportsContext } from '../CustomizableReportsContext';
import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc';
import CustomCheckbox from '../../../CustomCheckbox/CustomCheckbox';
import CustomSortableItem from './CustomSortableItem';

type Props = {
  generate: Function;
  submitting: boolean;
};

export const OrderIndicators = ({ generate, submitting }: Props) => {
  const { t } = useTranslation();
  const [includeAllDetails, setIncludeAllDetails] = useState<boolean>(false);

  const [context, setContext] = useCustomizableReportsContext();

  React.useEffect(() => {
    setIncludeAllDetails(context.indicators.every(indicator => indicator.includeDetails));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.indicators]);

  const SortableItem = SortableElement(({ indicator }) => (
    <CustomSortableItem indicator={indicator} onIncludeDetailsChange={includeDetailsChange} />
  ));

  const SortableList = SortableContainer(({ items }) => {
    return (
      <div className="cards-container">
        {items.map((value, index) => (
          <SortableItem key={value.id} index={index} indicator={value} />
        ))}
      </div>
    );
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setContext({
      ...context,
      indicators: arrayMove(context.indicators, oldIndex, newIndex),
    });
  };

  const includeDetailsChange = (id: number, includeDetails: boolean) => {
    const indicators = context.indicators.map(indicator => {
      if (indicator.id !== id) {
        return indicator;
      }

      indicator.includeDetails = includeDetails;
      return indicator;
    });

    setContext({
      ...context,
      indicators,
    });
  };

  const includeAllDetailsChange = (isChecked: boolean) => {
    setContext({
      ...context,
      indicators: context.indicators.map(i => ({
        ...i,
        includeDetails: isChecked,
      })),
    });
  };

  return (
    <div className="order-indicators">
      <p className="modal-title">
        {t('customizable_report_title')} / <span>{t('customizable_report_step_4')}</span>
      </p>

      <div className="modal-content">
        <h3>{t('customizable_report_step_4_title')}</h3>

        <SortableList
          items={context.indicators}
          onSortEnd={onSortEnd}
          axis="xy"
          pressDelay={200}
          helperClass="sortableHelper"
        />

        <div className="all-details-checkbox">
          <CustomCheckbox
            handleOnClick={isChecked => includeAllDetailsChange(isChecked)}
            isChecked={includeAllDetails}
            labelText={t('customizable_report_step_4_include_all_details')}
          />
        </div>

        <div className="buttons-section">
          <SaveButton handleClick={generate} customText={t('generate')} disabled={submitting} />
        </div>
      </div>
    </div>
  );
};
