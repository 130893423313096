import React, { useEffect } from 'react';
import CloseCrossButton from '../../Util/Buttons/CloseCrossButton/CloseCrossButton';
import BaseModal from '../../Util/Modals/BaseModal';
import { styles } from '../../Util/Modals/Styles';
import { ReactComponent as IconWarning } from '../../../assets/icons/u_warning.svg';
import { MemoryHelpReturnModalpProps } from '../models';
import { useTranslation } from 'react-i18next';
import '../MemoryHelp.scss';
import { useFormMemoryHelpContext } from '../../../context/MemoryHelpFormContext';

export const MemoryHelpReturnModal = ({ isOpen, closeModal, setVisibleComponent, navigatePath }: MemoryHelpReturnModalpProps) => {
    const { t } = useTranslation();

    const { setTrigger } = useFormMemoryHelpContext();

    const handleOnClick = () => {
        setVisibleComponent(navigatePath);
        closeModal();
    }

    useEffect(() => setTrigger(prev => !prev), [])

    return (
        <BaseModal
            isOpen={isOpen}
            onRequestClose={closeModal}
            style={styles}
            className='modal-bid'
        >
            <div>
                <div className='close'>
                    <CloseCrossButton handleClick={closeModal} />
                </div>
                <div className='container'>
                    <div className='content'>
                        <IconWarning />
                        <h3 className='ci-type-display-3'>{t('memory_help.container_tabs.completed.modals.return_modal_title')}</h3>
                        <p>{t('memory_help.container_tabs.completed.modals.return_modal_content')}</p>
                    </div>
                    <div className='button'>
                        <button className={'btn btn-primary-outlined ci-type-display-5'} onClick={closeModal}>
                            {t('memory_help.container_tabs.completed.modals.button_cancel')}
                        </button>
                        <button className={'btn-primary ci-type-display-5'} onClick={handleOnClick}>{t('memory_help.container_tabs.completed.modals.button_confirm')}</button>
                    </div>
                </div>
            </div>
        </BaseModal>
    );
}
