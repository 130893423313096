import { SET_INTEREST_ISSUES } from './types';
import InterestIssue from '../../models/InterestIssue';
import { getInterestIssues } from '../../api/filterApi';

export const setInterestIssues = (interestIssues: InterestIssue[]) => {
  return {
    type: SET_INTEREST_ISSUES,
    payload: interestIssues,
  };
};

export function getCurrentInterestIssues() {
  return async (dispatch: any) => {
    try {
      const response = await getInterestIssues();
      dispatch(setInterestIssues(response));
    } catch (error) {}
  };
}
