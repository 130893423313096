import { withStyles, createStyles, Theme, TableHead, TableCell, TableRow, TableContainer } from '@material-ui/core';
import { isMobile } from '../../services/booleanUtil';
import { textAlign } from 'html2canvas/dist/types/css/property-descriptors/text-align';

//Body table
export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      color: '#29303f',
      fontFamily: 'Rubik',
      fontSize: '14px',
      fontWeight: 400,
      marginTop: '0px',
      padding: '8px 4px 8px 8px',
      textAlign: 'left',
      borderBottom: 0,
    },
  }),
)(TableCell);

//Header
export const StyledTableHeader = withStyles((theme: Theme) =>
  createStyles({
    root: {
      background: '#F1F3F8',
    },
  }),
)(TableHead);

export const StyledTableCellHeader = withStyles((theme: Theme) =>
  createStyles({
    root: {
      color: '#236BFF',
      background: '#F1F3F8',
      fontFamily: 'rubik',
      fontSize: isMobile() ? '9px' : '14px',
      textTransform: 'capitalize',
      fontWeight: 500,
      cursor: 'default',
      lineHeight: isMobile() ? '0.7rem' : '1.375rem',
      textAlign: 'left',
      padding: '12px 4px 12px 8px'
    },
  }),
)(TableCell);

export const StyledTableRowHeader = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 'fit-content',
    },
  }),
)(TableRow);

export const StyledTableContainer = withStyles((theme: Theme) =>
  createStyles({
    root: {
      maxHeight: '50vh',
      overflowY: 'auto',
    },
  }),
)(TableContainer);
