import {
  addCalendarSynchronizationWithOutlook,
  addDisbursementData,
  addIndicatorsCustomization,
  addMyProjects,
} from './AnalyticsHelpers/analyticsRadialsHelpers';
import {
  addAAccordancesBarGraph,
  addMostUsedIndicators,
  addPortfolioVerticalIndicatorProjectTypeFilter,
  addMostUsedSections,
} from './AnalyticsHelpers/analyticsBarChartVerticalHelpers';
import {
  addPortfolioIndicatorsUseOfFilters,
  addProjectIndicatorsUseOfFilters,
} from './AnalyticsHelpers/analyticsBarChartHorizontalHelpers';
import {
  addProjectIndicatorGeneratedReports,
  addProjectIndicatorAgreementsNumbers,
  addPortfolioIndicatorGeneratedReports,
} from './AnalyticsHelpers/analyticsDoubleNumberHelper';
import {
  addAggregateIndicatorsLogins,
  addAggregateIndicatorsUsersWhoEnteredTheTool,
  addInquiriesToSupportAboutDataErrors,
  addRequestCustomizationByCountry,
  addSatisfactionSurveyWithinTheOnlineHelp,
} from './AnalyticsHelpers/analyticsSimpleNumberHelper';
import {
  addAggregateIndicatorsLoginsComplex,
  addProjectIndicatorsUseOfSectionsComplex,
} from './AnalyticsHelpers/analyticsComplexIndicatorsHelper';
import { AnalyticsKeyEnum } from '../../../models/AnalyticsKeyEnum';

/*//TODO TurnOff desactivar función
De forma provisoria se dejan 3 indicadores.
  1-addRequestCustomizationByCountry
  2-addSatisfactionSurveyWithinTheOnlineHelp
 

  con una flag en true (turnOff) que lo que hace es devolver una propiedad turnOff a la lista de indicadores
  para esconder de la vista los indicadores que tengan dicha flag.

  Esta situación se mantiene así visto que para dichos indicadores aún no se genera la data en CI que los alimentaran.
  Para hacer visible cualquiera de los indicadores basta con borrar la flag de la llamada de la función.


  Obs. En caso que no quede ningún indicador flagueado, puede borrarse también de los helpers. Las llamadas a la función
  setTurnOffIndicator y la función setTurnOffIndicator.
*/

export const setTurnOffIndicator = (analyticIndicators, indicatorKey, turnOff) =>
  analyticIndicators.push({ indicatorKey, turnOff });

export const processAnalyticIndicators = (analyticList, datePeriod, regions?) => {
  const analyticIndicators: any[] = [];
  addPortfolioVerticalIndicatorProjectTypeFilter(analyticList, analyticIndicators);
  addAggregateIndicatorsLogins(analyticList, analyticIndicators);
  addInquiriesToSupportAboutDataErrors(analyticList, analyticIndicators);
  addRequestCustomizationByCountry(analyticList, analyticIndicators, true);
  addSatisfactionSurveyWithinTheOnlineHelp(analyticList, analyticIndicators, true);
  addIndicatorsCustomization(analyticList, analyticIndicators);
  addMyProjects(analyticList, analyticIndicators);
  addCalendarSynchronizationWithOutlook(analyticList, analyticIndicators);
  addDisbursementData(analyticList, analyticIndicators);
  addMostUsedIndicators(analyticList, analyticIndicators);
  addAAccordancesBarGraph(analyticList, analyticIndicators);
  addProjectIndicatorGeneratedReports(analyticList, analyticIndicators);
  addProjectIndicatorAgreementsNumbers(analyticList, analyticIndicators);
  addPortfolioIndicatorGeneratedReports(analyticList, analyticIndicators);
  addPortfolioIndicatorsUseOfFilters(analyticList, analyticIndicators);
  addProjectIndicatorsUseOfFilters(analyticList, analyticIndicators);
  addProjectIndicatorsUseOfSectionsComplex(analyticList, analyticIndicators, datePeriod);
  addAggregateIndicatorsLoginsComplex(
    analyticList,
    analyticIndicators,
    datePeriod,
    AnalyticsKeyEnum.AGGREGATE_INDICATORS_LOGINS_COMPLEX,
    regions || [],
  );
  addAggregateIndicatorsUsersWhoEnteredTheTool(analyticList, analyticIndicators);
  addAggregateIndicatorsLoginsComplex(
    analyticList,
    analyticIndicators,
    datePeriod,
    AnalyticsKeyEnum.AGGREGATE_INDICATORS_USERS_WHO_ENTERED_THE_TOOL_COMPLEX,
    regions || [],
  );
  addMostUsedSections(analyticList, analyticIndicators);

  return analyticIndicators;
};

export default processAnalyticIndicators;
