import { AnalyticsKeyEnum } from '../../../../models/AnalyticsKeyEnum';
import { BarChartColorPalleteEnum } from '../../../../models/BarChartColorPalleteEnum';
import { CardTypesEnum } from '../../../../models/CardTypesEnum';
import { createArrayOpacityBlues, getIndicatorData } from '../../../Portfolio/IndicatorsHelpers/indicatorHelperUtil';

const addPortfolioVerticalIndicatorProjectTypeFilter = (analyticList, analyticIndicators) => {
  const analyticIndicator = getIndicatorData(
    analyticList,
    AnalyticsKeyEnum.PORTFOLIO_INDICATORS_USE_OF_TYPE_PROJECT_FILTER,
  );
  analyticIndicator && barChartLargeSimpleVerticalIndicatorsHelper(analyticIndicator, analyticIndicators);
};
const dictionaryOfProjectTypeFilters = {
  '0': 'LON',
  '1': 'TCP',
  '2': 'IGR<3MM',
  '3': 'IGR>3MM',
  '0|1': 'LON / TCP',
  '0|2': 'LON / IGR<3MM',
  '0|3': 'LON / IGR>3MM',
  '1|2': 'TCP / IGR<3MM',
  '1|3': 'TCP / IGR>3MM',
  '2|3': 'IGR<3MM / IGR>3MM',
  '0|1|2': 'LON / TCP / IGR<3MM',
  '0|1|3': 'LON / TCP / IGR>3MM',
  '0|2|3': 'LON / IGR<3MM / IGR>3MM',
  '1|2|3': 'TCP / IGR<3MM / IGR>3MM',
  '0|1|2|3': 'LON / TCP / IGR<3MM / IGR>3MM',
};

const findNameTypeOfProject = name => {
  const ascendentNameOrder = name
    .split('|')
    .sort((a: number, b: number) => a - b)
    .join('|');
  return dictionaryOfProjectTypeFilters[ascendentNameOrder];
};
const spaceIndentTypeFilter = nameTypeFilter => {
  const indentTypeFilterDictionary = {
    'LON / TCP / IGR<3MM / IGR>3MM': '                                                         ',
    'TCP / IGR<3MM / IGR>3MM': '                                                ',
    'LON / IGR<3MM / IGR>3MM': '                                                ',
    'LON / TCP / IGR>3MM': '                                       ',
    'IGR<3MM / IGR>3MM': '                                     ',
    'LON / TCP / IGR<3MM': '                                       ',
    'TCP / IGR>3MM': '                          ',
    'TCP / IGR<3MM': '                          ',
    'LON / IGR>3MM': '                          ',
    'LON / IGR<3MM': '                          ',
    'LON / TCP': '                 ',
    'IGR>3MM': '               ',
    'IGR<3MM': '               ',
    LON: '       ',
    TCP: '       ',
  };
  return indentTypeFilterDictionary[nameTypeFilter] + nameTypeFilter;
};
const barChartLargeSimpleVerticalIndicatorsHelper = (analyticIndicator, analyticIndicators) => {
  const metrics = analyticIndicator.metrics;

  const filteredNameOfProjectTypeFilters = { ...dictionaryOfProjectTypeFilters };

  const metricsWithTypeName = metrics.map(metric => {
    delete filteredNameOfProjectTypeFilters[metric.metricName];
    return { name: findNameTypeOfProject(metric.metricName), metricValue: metric.metricValue };
  });

  Object.entries(filteredNameOfProjectTypeFilters).forEach(([key, value]) => {
    const emptyProjectType = {
      name: value,
      metricValue: 0,
    };
    metricsWithTypeName.push(emptyProjectType);
  });

  const metricsSortedByValue = [...metricsWithTypeName].sort((a, b) => b.metricValue - a.metricValue);
  const name = metricsSortedByValue.map(item => spaceIndentTypeFilter(item.name));
  const values = metricsSortedByValue.map(item => item.metricValue);

  const dataProjectFilter = {
    indicatorKey: analyticIndicator.key,
    type: CardTypesEnum.VERTICAL_SIMPLE,
    title: 'Preferencia en configuración de filtros por tipo',
    projectTypeMetrics: metricsSortedByValue,
    data: {
      labels: values,
      datasets: [
        {
          label: name,
          data: values,
          backgroundColor: createArrayOpacityBlues(),
          borderWidth: 0,
          barThickness: 8,
        },
      ],
    },
  };
  analyticIndicators.push(dataProjectFilter);
};
const addAAccordancesBarGraph = (analyticList, analyticIndicators) => {
  const analyticIndicator = getIndicatorData(analyticList, AnalyticsKeyEnum.PROJECT_INDICATORS_AGREEMENTS_BAR_GRAPH);

  if (analyticIndicator) {
    barChartVerticalIndicatorsHelper(analyticIndicator, analyticIndicators, 'accordances', '');
  } else {
    const emptyDataObject = {
      indicatorKey: AnalyticsKeyEnum.PROJECT_INDICATORS_AGREEMENTS_BAR_GRAPH,
      type: CardTypesEnum.HALF_SIZE_VERTICAL,
      dataChart: {
        title: 'accordances',
      },
    };
    analyticIndicators.push(emptyDataObject);
  }
};

const addMostUsedIndicators = (analyticList, analyticIndicators) => {
  const analyticIndicator = getIndicatorData(analyticList, AnalyticsKeyEnum.PORTFOLIO_INDICATORS_MOST_USED_INDICATORS);

  if (analyticIndicator) {
    barChartVerticalIndicatorsHelper(
      analyticIndicator,
      analyticIndicators,
      'most_used_indicators',
      'most_used_indicators_subtitle',
    );
  } else {
    const emptyDataObject = {
      indicatorKey: AnalyticsKeyEnum.PORTFOLIO_INDICATORS_MOST_USED_INDICATORS,
      type: CardTypesEnum.HALF_SIZE_VERTICAL,
      dataChart: {
        title: 'most_used_indicators',
        subtitle: 'most_used_indicators_subtitle',
      },
    };
    analyticIndicators.push(emptyDataObject);
  }
};

const addMostUsedSections = (analyticList, analyticIndicators) => {
  const analyticIndicator = getIndicatorData(analyticList, AnalyticsKeyEnum.AGGREGATE_INDICATORS_MOST_USED_SECTION);

  if (analyticIndicator) {
    const defensiveNullAnalyticIndicator = {
      ...analyticIndicator,
      metrics: analyticIndicator.metrics.filter(metric => metric.metricName !== null),
    };
    barChartVerticalSimpleIndicatorsHelper(
      defensiveNullAnalyticIndicator,
      analyticIndicators,
      'most_used_sections',
      'number_of_users',
    );
  } else {
    const emptyDataObject = {
      indicatorKey: AnalyticsKeyEnum.AGGREGATE_INDICATORS_MOST_USED_SECTION,
      type: CardTypesEnum.VERTICAL,
      title: 'most_used_sections',
      subtitle: 'number_of_users',
      data: {
        dataListTranslationLabels: [],
      },
    };
    analyticIndicators.push(emptyDataObject);
  }
};

const orderByMetricValue = analyticIndicator => {
  return analyticIndicator.metrics.sort((a, b) => (a.metricValue > b.metricValue ? -1 : 1));
};
const setArrayFromProperty = (data, propertyToAdd) => {
  return data.map(m => m[propertyToAdd]);
};

const barChartVerticalSimpleIndicatorsHelper = (analyticIndicator, analyticIndicators, title, subtitle) => {
  if (analyticIndicator) {
    orderByMetricValue(analyticIndicator);
    analyticIndicator.metrics.map((m, i) => (m.color = BarChartColorPalleteEnum[i]));
    analyticIndicator.metrics.map(m => (m.colorId = BarChartColorPalleteEnum[m.color]));
    const metrics = analyticIndicator.metrics;
    const backgroundColor = setArrayFromProperty(metrics, 'color');
    const backgroundColorId = setArrayFromProperty(metrics, 'colorId');
    const data = setArrayFromProperty(metrics, 'metricValue');
    const dataListTranslationLabels = metrics.map(m => `menu_${m.metricName.toLowerCase()}`);
    const labels: string[] = data.map(m => '');

    const dataChart: any = {
      indicatorKey: analyticIndicator.key,
      type: CardTypesEnum.VERTICAL,
      title,
      subtitle,
      detailTitle: '',
      detailChartTitle: '',
      detailChartFoot: '',
      data: labels.length
        ? {
            labels,
            datasets: [
              {
                data,
                backgroundColor,
                backgroundColorId,
                borderWidth: 0,
                barThickness: 25,
                label: 'firstStack',
              },
            ],
            subLabels: [],
            cornerRadius: 3,
            gridLines: true,
            dataListTranslationLabels,
            dataQtyProjects: data,
            indicatorKey: analyticIndicator.key,
          }
        : null,
    };

    analyticIndicators.push(dataChart);
  }
};

const barChartVerticalIndicatorsHelper = (analyticIndicator, analyticIndicators, title, subtitle) => {
  if (analyticIndicator) {
    orderByMetricValue(analyticIndicator);
    analyticIndicator.metrics.map((m, i) => (m.color = BarChartColorPalleteEnum[i]));
    analyticIndicator.metrics.map((m, i) => (m.colorId = BarChartColorPalleteEnum[m.color]));

    const fiveBiggest = analyticIndicator.metrics.slice(0, 5);

    const allData = analyticIndicator.metrics.filter(m => m.metricValue);

    const backgroundColor = setArrayFromProperty(fiveBiggest, 'color');
    const backgroundColorId = setArrayFromProperty(fiveBiggest, 'colorId');
    const data = setArrayFromProperty(fiveBiggest, 'metricValue');

    const dataListTranslationLabels = fiveBiggest.map(m => m.metricName.toLowerCase());
    dataListTranslationLabels.pop();

    const backgroundColorDetail = setArrayFromProperty(allData, 'color');
    const backgroundColorIdDetail = setArrayFromProperty(allData, 'colorId');
    const dataDetail = setArrayFromProperty(allData, 'metricValue');

    const dataListTranslationLabelsDetail = allData.map(m => m.metricName.toLowerCase());

    const labels: string[] = data.map(m => '');
    const labelsDetail: string[] = dataDetail.map(m => '');

    const indicatorData = {
      data,
      backgroundColor,
      backgroundColorId,
      borderWidth: 0,
      barThickness: 25,
      label: 'firstStack',
    };
    const datasets = [indicatorData];
    const dataChart: any = {
      indicatorKey: analyticIndicator.key,
      type: CardTypesEnum.HALF_SIZE_VERTICAL,
      title,
      subtitle,
      detailTitle: '',
      detailChartTitle: '',
      detailChartFoot: '',
      data: labels.length
        ? {
            labels,
            datasets,
            subLabels: [],
            cornerRadius: 3,
            gridLines: true,
            dataListTranslationLabels,
            dataQtyProjects: data,
            indicatorKey: analyticIndicator.key,
          }
        : null,
    };
    const indicatorDetailData = {
      data: dataDetail,
      backgroundColor: backgroundColorDetail,
      backgroundColorId: backgroundColorIdDetail,
      borderWidth: 0,
      barThickness: 8,
      label: 'firstStack',
    };
    const datasetsDetail = [indicatorDetailData];
    const dataChartDetail: any = {
      indicatorKey: analyticIndicator.key,
      type: CardTypesEnum.HALF_SIZE_VERTICAL,
      title,
      subtitle: '',
      detailTitle: '',
      detailChartTitle: '',
      detailChartFoot: '',
      data: labelsDetail.length
        ? {
            labels: labelsDetail,
            datasets: datasetsDetail,
            subLabels: [],
            cornerRadius: 3,
            gridLines: true,
            dataListTranslationLabels: dataListTranslationLabelsDetail,
            dataQtyProjects: dataDetail,
            indicatorKey: analyticIndicator.key,
          }
        : null,
    };
    const indicatorAndDetailDatailObjects = { indicatorKey: analyticIndicator.key, title, dataChart, dataChartDetail };
    analyticIndicators.push(indicatorAndDetailDatailObjects);
  }
};

export {
  addAAccordancesBarGraph,
  addMostUsedIndicators,
  addPortfolioVerticalIndicatorProjectTypeFilter,
  addMostUsedSections,
};
