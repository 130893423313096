import React from 'react';
import styles from './SelectAll.module.scss';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  setNoneReviewSelectedProject,
  setListReviewSelectedProject,
} from '../../../redux/actions/reviewProjectsSelectedRows';

type Props = {
  allProjects: any[];
};

const SelectAll = ({ allProjects }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const reviewProjectsSelectedRows = useSelector<any, any>(state => state.reviewProjectsSelectedRows);

  const handleClick = () => {
    if (allProjects.length === reviewProjectsSelectedRows.length) {
      dispatch(setNoneReviewSelectedProject());
    } else {
      const allItemsList = allProjects.map(item => item.id);
      dispatch(setListReviewSelectedProject(allItemsList));
    }
  };
  return (
    <div className={styles.container} onClick={handleClick}>
      <span
        className={allProjects.length === reviewProjectsSelectedRows.length ? styles.iconSelected : styles.icon}
      ></span>
      <span className={styles.text}>{t('select_all')}</span>
    </div>
  );
};

export default SelectAll;
