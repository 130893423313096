import React from 'react';
import { useTranslation } from 'react-i18next';
import useFilters from '../../../hooks/useFilters';
import { CalledFrom } from '../../../models/CalledFromEnum';
import { Bubble } from '../BubbleElement/BubbleElement';

interface IProps {
  indicatorCall?: boolean;
  calledFrom?: CalledFrom;
}
type Props = IProps;

const bubblesFor = (indicatorCall, filterIndicators, filter, calledFrom, filterEarlyWarnings) => {
  if (calledFrom === CalledFrom.EARLY_WARNINGS) {
    return filterEarlyWarnings.common;
  }
  return indicatorCall ? filterIndicators.common : filter.common;
};

export const getSingularOrPluralValue = itsMonths => {
  const singular = itsMonths ? 'month' : 'year';
  const plural = itsMonths ? 'months' : 'years';
  return { singular, plural };
};

const ProjectAgeBubbles = (props: Props) => {
  const { indicatorCall, calledFrom } = props;
  const {
    filter,
    filterIndicators,
    dispatch,
    setFilter,
    setFilterIndicators,
    filterEarlyWarnings,
    setFilterEarlyWarnings,
  } = useFilters();
  const bubbles = bubblesFor(indicatorCall, filterIndicators, filter, calledFrom, filterEarlyWarnings);
  const { t } = useTranslation();
  const label = t('project_age');
  let returnedBubble = <Bubble itsHidden={true} label={label} />;
  const defaultMaxValue = 0;
  const defaultMinValue = 0;
  const closeBubble = label => {
    if (CalledFrom.EARLY_WARNINGS === calledFrom) {
      dispatch(
        setFilterEarlyWarnings({
          ...filterEarlyWarnings,
          common: {
            ...filterEarlyWarnings.common,
            ageProjectFrom: defaultMinValue,
            ageProjectTo: defaultMaxValue,
            ageProjectMonths: true,
          },
        }),
      );
    } else {
      if (indicatorCall) {
        dispatch(
          setFilterIndicators({
            ...filterIndicators,
            common: {
              ...filterIndicators.common,
              ageProjectFrom: defaultMinValue,
              ageProjectTo: defaultMaxValue,
              ageProjectMonths: true,
            },
          }),
        );
      } else {
        dispatch(
          setFilter({
            ...filter,
            common: {
              ...filter.common,
              ageProjectFrom: defaultMinValue,
              ageProjectTo: defaultMaxValue,
              ageProjectMonths: true,
              itSearch: false,
            },
          }),
        );
      }
    }
  };

  const getValidBubble = (from, to, itsMonths) => {
    const singularOrPlural = getSingularOrPluralValue(itsMonths);

    if (from < to && from !== 0 && to !== 0) {
      returnedBubble = (
        <Bubble
          itsHidden={false}
          label={label}
          value={t('between') + from + t('and') + to + (itsMonths ? t('months') : t('years'))}
          closeBubble={closeBubble}
        />
      );
    } else if (from > to && from !== 0 && to !== 0) {
      returnedBubble = <Bubble itsHidden={true} label={label} />;
    } else if (from > to && from !== 0 && to === 0) {
      returnedBubble = (
        <Bubble
          itsHidden={false}
          label={label}
          value={t('greater_than') + from + (from === 1 ? t(singularOrPlural.singular) : t(singularOrPlural.plural))}
          closeBubble={closeBubble}
        />
      );
    } else if (from < to && from === 0 && to !== 0) {
      returnedBubble = (
        <Bubble
          itsHidden={false}
          label={label}
          value={t('less_than') + to + (to === 1 ? t(singularOrPlural.singular) : t(singularOrPlural.plural))}
          closeBubble={closeBubble}
        />
      );
    }

    return returnedBubble;
  };
  returnedBubble = getValidBubble(bubbles.ageProjectFrom, bubbles.ageProjectTo, bubbles.ageProjectMonths);

  return returnedBubble;
};

export default ProjectAgeBubbles;
