import React from 'react';
import { useTranslation } from 'react-i18next';
import { BidTooltip } from '../../Util/Tooltip/Tooltip';
import './EmptyCardsProject.scss';
import { ReactComponent as FollowEye } from '../../../assets/bg-without-projects-follow-icon.svg';
import { tooltipTitle } from '../../../services/stringUtil';

export const EmptyCardsProject = () => {
  const getEmptyProjectLocalStorage = localStorage.getItem('viewEmptyProject') === 'false';
  const [viewEmptyCard, setViewEmptyCard] = React.useState(getEmptyProjectLocalStorage ? false : true);
  const { t } = useTranslation();

  const closeEmptyCard = () => {
    localStorage.setItem('viewEmptyProject', 'false');
    setViewEmptyCard(false);
  };
  return viewEmptyCard ? (
    <div className="empty-card">
      <div className="close-cross">
        <BidTooltip title={tooltipTitle(t, 'close')} enterDelay={500}>
          <span onClick={closeEmptyCard} className="bg-img" id="close-cross"></span>
        </BidTooltip>
      </div>
      <div className="content">
        <span className="title">{t('are_there_projects_that_need_your_attention')}</span>
        <p className="description">
          {t('first_description_projects_without_cards')}
          <FollowEye className="follow-position" />
          {t('second_description_projects_without_cards')}
        </p>
      </div>
    </div>
  ) : (
    <div data-testid="testCard" className="clear"></div>
  );
};
