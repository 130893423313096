import React, { useState } from 'react';
import { isMobile } from '../../../services/booleanUtil';
import styles from './ProjectSwitch.module.scss';
import ExpandedComponent from './ExpandedComponent';
import { EWHistory } from '../../../models/EarlyWariningHistory';
import { DisbursementsProjectionsEvolution } from '../../../models/DisbursementsProjectionsEvolution';

type Props = {
  switchType: string;
  collapsedHeaderComponent: any;
  disbursementsProjectionsEvolution: DisbursementsProjectionsEvolution[];
  hasDataHeader: boolean;
  operativeAlerts?: EWHistory[];
};
const { selectableSection, eventBubblesSection } = styles;
export const ProjectSwitch = ({
  switchType,
  collapsedHeaderComponent,
  disbursementsProjectionsEvolution,
  hasDataHeader,
  operativeAlerts,
}: Props) => {
  const hasDataDesktop =
    ((operativeAlerts && operativeAlerts.length !== 0) || disbursementsProjectionsEvolution.length !== 0) &&
    DataEvolutionEmpty(disbursementsProjectionsEvolution);
  const hasDataMobile = disbursementsProjectionsEvolution?.length !== 0;
  // const hasData = isMobile() ? hasDataMobile : hasDataDesktop;
  const hasData = true;
  const [projectSwitchCollapsed, setProjectSwitchCollapsed] = useState(getSwitchStorageState(switchType, hasData));

  return (
    <div>
      <div className={selectableSection}>
        {hasDataHeader || hasData ? (
          <div className={eventBubblesSection}>{collapsedHeaderComponent}</div>
        ) : (
          showCollapsedData(projectSwitchCollapsed, hasData, collapsedHeaderComponent)
        )}
      </div>
      {hasData ? (
        <div
          className={
            projectSwitchCollapsed ? styles.completeSwitchProjectCollapsed : styles.completeSwitchProjectExpanded
          }
        >
          <ExpandedComponent
            disbursementsProjectionsEvolution={disbursementsProjectionsEvolution}
            dataEvolutionEmpty={DataEvolutionEmpty(disbursementsProjectionsEvolution)}
          />
        </div>
      ) : null}
    </div>
  );
};
const DataEvolutionEmpty = data =>
  Math.max(...data.map(i => i.disbursed)) === 0 && Math.max(...data.map(i => i.projected)) === 0 ? false : true;

const showCollapsedData = (collapsedState, hasData, collapsedHeaderComponent) => {
  return collapsedState && hasData && <div className={styles.eventBubblesSection}>{collapsedHeaderComponent}</div>;
};
const getSwitchStorageState = (switchType, hasData) => {
  localStorage.setItem('evolutionSwitchDetail', 'false');
  if (isMobile()) {
    return false;
  } else {
    return hasData ? localStorage.getItem(switchType) === null || localStorage.getItem(switchType) === 'true' : true;
  }
};
