import React, { useState } from 'react';
import './NewAccordanceForm.scss';
import { useTranslation } from 'react-i18next';
import SaveButton from '../../../../Util/Buttons/SaveButton/SaveButton';
import UndoButton from '../../../../Util/Buttons/UndoButton/UndoButton';
import { AccordanceType } from '../../../../../models/AccordanceType';
import { AccordanceState } from '../../../../../models/AccordanceState';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { ReactComponent as Closebutton } from '../../../../../assets/icons/u_multiply.svg';
import Modal from '@material-ui/core/Modal';

type Props = {
  submit: any;
  submitting: boolean;
  disableDraftSubmit: boolean;
  accordanceType?: AccordanceType;
  saveAsDraft?: any;
  currentUserCanApprove?: boolean;
  cancel: Function;
  editAccordanceData?: any;
  publish: Function;
  closeModalEditReviewer?: any;
  accordanceCreateRevision?: boolean;
};

const NewAccordanceFormButtons = ({
  submit,
  disableDraftSubmit,
  submitting,
  saveAsDraft,
  currentUserCanApprove,
  cancel,
  editAccordanceData,
  publish,
  closeModalEditReviewer,
  accordanceCreateRevision,
}: Props) => {
  const { t } = useTranslation();

  const [openModal, setopenModal] = useState({ modal1: false, modal2: false });

  const openModalForm = modal => {
    setopenModal({ ...openModal, [modal]: true });
  };

  const closeModalForm = modal => {
    setopenModal({ ...openModal, [modal]: false });
  };

  const rootRef = React.useRef<HTMLDivElement>(null);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        height: 300,
        flexGrow: 1,
        minWidth: 300,
        transform: 'translateZ(0)',
        // The position fixed scoping doesn't work in IE 11.
        // Disable this demo to preserve the others.
        '@media all and (-ms-high-contrast: none)': {
          display: 'none',
        },
      },
      modal: {
        display: 'flex',
        padding: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center',
      },
      paper: {
        width: 480,
        display: 'flex',
        justifyContent: 'flexCenter',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: '24px',
        borderRadius: '16px',
        border: 'none',
        height: '358px',
        gap: '48px',
      },
    }),
  );

  const classes = useStyles();
  return (
    <>
      <div className="horizontal-row buttons">
        {editAccordanceData && editAccordanceData.accordanceState === AccordanceState.PENDING ? (
          <>
            <SaveButton
              handleClick={submit}
              customText={t('save')}
              customStyle={{ marginRight: 16 }}
              disabled={submitting}
            />
            <UndoButton
              handleClick={cancel}
              customText={t('cancel')}
              customStyle={{ marginLeft: 16 }}
              disabled={submitting}
            />
          </>
        ) : (
          <>
            <button
              className={`btn btn-primary ${disableDraftSubmit || submitting ? 'disabled' : ''}`}
              disabled={disableDraftSubmit || submitting}
              onClick={() => {
                openModalForm('modal1');
              }}
            >
              {t('new_save_draft')}
            </button>
            {!closeModalEditReviewer && !accordanceCreateRevision ? (
              <button
                className={`btn btn-primary ${disableDraftSubmit || submitting ? 'disabled' : ''}`}
                disabled={submitting}
                onClick={() => {
                  openModalForm('modal2');
                }}
              >
                {t('send_for_approval')}
              </button>
            ) : null}
          </>
        )}
      </div>
      <Modal
        disableBackdropClick
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        onClose={() => setopenModal({ ...openModal, modal1: false })}
        open={openModal.modal1}
        aria-labelledby="server-modal-title"
        aria-describedby="server-modal-description"
        className={classes.modal}
        container={() => rootRef.current}
      >
        <div className={classes.paper}>
          <div className="container-button-toggle">
            <button
              onClick={() => {
                closeModalForm('modal1');
              }}
              className="button-toggle"
            >
              <Closebutton />
            </button>
          </div>
          <div>
            <div className="fullfilled-icon"></div>
            <h3 id="server-modal-title" className="server-modal-title">
              {t('new_save_draft')}
            </h3>
            <p id="server-modal-description" className="modal-description">
              {t('new_all_changes_draft')}
            </p>
          </div>
          <div className="modal-container-buttons">
            <button
              className={`btn btn-primary-outlined`}
              onClick={() => {
                closeModalForm('modal1');
                cancel(false);
                closeModalEditReviewer(false);
              }}
            >
              {t('cancel')}
            </button>

            <SaveButton
              handleClick={saveAsDraft}
              customText={t('save')}
              disabled={disableDraftSubmit || submitting}
              closeModalEditReviewer={closeModalEditReviewer}
            />
          </div>
        </div>
      </Modal>

      <Modal
        disableBackdropClick
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        onClose={() => setopenModal({ ...openModal, modal2: false })}
        open={openModal.modal2}
        aria-labelledby="server-modal-title"
        aria-describedby="server-modal-description"
        className={classes.modal}
        container={() => rootRef.current}
      >
        <div className={classes.paper}>
          <div className="container-button-toggle">
            <button
              onClick={() => {
                closeModalForm('modal2');
              }}
              className="button-toggle"
            >
              <Closebutton />
            </button>
          </div>
          <div>
            <div className="fullfilled-icon"></div>
            <h3 id="server-modal-title" className="server-modal-title">
              {t('new_approve_agreement')}
            </h3>
            <p id="server-modal-description" className="modal-description">
              {t('new_role')}
            </p>
            <p className="modal-question">
              <b>{t('new_question_send')}</b>
            </p>
          </div>

          <div className="modal-container-buttons">
            <button
              className={`btn btn-primary`}
              onClick={() => {
                closeModalForm('modal2');
              }}
            >
              {t('cancel')}
            </button>

            <UndoButton // change button
              handleClick={publish}
              customText={currentUserCanApprove ? t('approve') : t('new_send_accordance')}
              disabled={submitting}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default NewAccordanceFormButtons;
