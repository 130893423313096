import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { updateCategory, updateCriticalIssue, updatePossibleAction } from '../../../../api/contentManager';
import { dataTypes } from '../../ContentManagerKeys';
import styles from './EntitiesModalForm.module.scss';
import { renderTitle } from './util';

interface Iitem {
  id: number;
  text: string;
  type: dataTypes;
  relatedEntityId: number;
  languageId: number;
}

type Props = {
  closeModal: Function;
  item: Iitem;
  updateItem: Function;
};

export const EntitiesModalForm = ({ closeModal, item, updateItem }: Props) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [inputValue, setInputValue] = useState<string>(item.text);
  const [disableChange, setDisableChange] = useState<boolean>(false);

  const handleInputChange = e => {
    e.preventDefault();
    const { value } = e.target;
    setDisableChange(value === '' ? true : false);
    setInputValue(value);
  };

  const handleUpdate = async () => {
    let payload = {};
    let response;

    if (item.type === dataTypes.catergories) {
      payload = {
        id: item.id,
        text: inputValue,
        languageId: item.languageId,
      };
      response = await updateCategory(payload);
    } else if (item.type === dataTypes.criticalIssue) {
      payload = { id: item.id, text: inputValue, categoryId: item.relatedEntityId, languageId: item.languageId };
      response = await updateCriticalIssue(payload);
    } else {
      payload = { id: item.id, text: inputValue, criticalIssueId: item.relatedEntityId, languageId: item.languageId };
      response = await updatePossibleAction(payload);
    }

    response && response.success ? setErrorMessage('') : setErrorMessage(response.errorMessage);
    if (response && response.success) {
      updateItem(response.data);
      closeModal();
    }
  };

  return (
    <div className={styles.layout}>
      <div className={styles.rowHorizontal}>
        <div className={styles.title}>{t(renderTitle(item.type))}</div>
      </div>

      <div className={styles.inputSection}>
        <p className={styles.label}>{t('spanish')}</p>
        <input
          type="text"
          className={errorMessage ? styles.inputError : styles.inputClass}
          onChange={e => {
            handleInputChange(e);
          }}
          value={inputValue}
        ></input>
      </div>
      <span className={styles.errorMessage}>{errorMessage}</span>
      <p className={styles.labelInfo}>{t('in_the_translations_section_you_can_enter_the_name_in_other_languages')}</p>

      <div className={styles.buttonsContainer}>
        <button
          className={disableChange ? styles.buttonDisabled : styles.button}
          onClick={handleUpdate}
          disabled={disableChange}
        >
          {t('save_changes')}
        </button>
        <button style={{ width: '195px' }} className={styles.secondaryButton} onClick={() => closeModal()}>
          {t('close')}
        </button>
      </div>
    </div>
  );
};
