export enum menuTabValuesEnum {
  CID = 'CID',
  CCB = 'CCB',
  CAN = 'CAN',
  CSC = 'CSC',
  REG = 'REG',
  my_portfolio = 'my_portfolio',
  country = 'country',
  global_bid = 'global_bid',
}
