import React from 'react';
import { ReactComponent as Arrow } from '../../../../assets/arrow-accordion-analytics.svg';
import { Accordion, AccordionSummary, AccordionDetails } from './AnalyticsCollapseContainer.styles';
import { AnalyticCard } from '../AnalyticCard/AnalyticCard';

type Props = {
  expanded: any;
  indicatorsList: any[];
  onChange: any;
  title: any;
  getAnalyticIndicatorData: any;
  isLoadingData?: boolean;
};

const CustomAccordion = ({
  expanded,
  indicatorsList,
  onChange,
  title,
  getAnalyticIndicatorData,
  isLoadingData,
}: Props) => {
  return (
    <Accordion elevation={0} expanded={expanded} onChange={onChange}>
      <AccordionSummary children={title} expandIcon={<Arrow />} aria-controls="content" id="header"></AccordionSummary>
      <AccordionDetails>
        {indicatorsList.map(ind => (
          <AnalyticCard data={getAnalyticIndicatorData(ind.key)} {...ind} isLoadingData={isLoadingData} />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomAccordion;
