import React from 'react';
import ContentManagerTable from '../ContentManagerTable/ContentManagerTable';
import ContentManagerModal from '../ContentManagerModal/ContentManagerModal';

type Props = {
  items: any;
  type: any;
  getLanguageTranslationFromKey: any;
  updateFunction: any;
  displayGenericErrorMessage: any;
  buttonText: any;
  isOpen: any;
  openModal: any;
  closeModal: any;
  modalTitle: any;
  children: any;
  languagesData?: any;
  dataInfo?: any;
  dataType?: any;
};

export const ContentsLayout = ({
  items,
  type,
  getLanguageTranslationFromKey,
  updateFunction,
  displayGenericErrorMessage,
  buttonText,
  isOpen,
  openModal,
  closeModal,
  modalTitle,
  children,
  languagesData,
  dataInfo,
  dataType,
}: Props) => {
  return (
    <>
      <ContentManagerTable
        type={type}
        items={items}
        getLanguageTranslationFromKey={getLanguageTranslationFromKey}
        updateFunction={updateFunction}
        displayGenericErrorMessage={displayGenericErrorMessage}
        languagesData={languagesData}
        dataInfo={dataInfo}
        dataType={dataType}
      />
      <ContentManagerModal
        buttonText={buttonText}
        isOpen={isOpen}
        openModal={openModal}
        closeModal={closeModal}
        modalTitle={modalTitle}
      >
        {children}
      </ContentManagerModal>
    </>
  );
};
