import { ConvergenceModalActionTypes, SET_CONVERGENCE_MODAL } from '../actions/types';

const initialState = true;

const convergenceModalReducer = (state = initialState, action: ConvergenceModalActionTypes): boolean => {
  switch (action.type) {
    case SET_CONVERGENCE_MODAL:
      return action.payload;
    default:
      return state;
  }
};

export default convergenceModalReducer;
