import React from 'react';
import styles from '../../SharedStyles/HeaderDrops.module.scss';
import '../../SharedStyles/HeaderDrops.scss';
import SummaryHeaderSingleValue from '../../../SummaryHeaderSingleValue/SummaryHeaderSingleValue';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../../../../../services/booleanUtil';
import { renderValues } from '../Util';
import { SummaryLoanInternalFilter } from '../SummaryLoan';

type Props = {
  currentIndicator: any;
  filterApplied: SummaryLoanInternalFilter;
};

const renderSingularOrPluralText = (problemFiltered, problem, normal) => {
  if (problemFiltered) {
    if (problem.value > 1) {
      return problem.pluralText;
    } else {
      return problem.singularText;
    }
  } else {
    if (normal.value > 1) {
      return normal.pluralText;
    } else {
      return normal.singularText;
    }
  }
};

export const HeaderDrops = ({
  currentIndicator: {
    data: {
      lo: {
        lonAmount,
        lonProjectAmount,
        amountProjectProblem,
        amountLoanProblem,
        amountProblemPercentage,
        lonApprovedAmount,
        amountApprovedProblem,
        lonApprovedInversionAmount,
        lonApprovedPbpAmount,
        lonPendingDisbursementAmount,
        lonDisbursementAmount,
        lonDisbursementPercent,
        lonAgeAverage,
        amountWithoutDisbursementProblem,
        amountDisursementProblem,
        amountDisbursementPercentProblem,
        averageAgeProblem,
        lonPendingSignatureAmount,
        lonPendingRatificationAmount,
        guarantee,
        approvedWarranty,
        guaranteeProblem,
        approvedWarrantyProblem,
      },
    },
  },
  filterApplied,
}: Props) => {
  const {
    dropsConditionalSubLabel,
    dropsLayout,
    firstFilteredDropContainer,
    firstDropContainer,
    dropsTitle,
    dropsValues,
    filteredDropsContainer,
    dropsContainer,
    hideDropsContainer,
    dropsConditionalSubLabelMargin,
    dropConditionalGroupOfLabels,
  } = styles;
  const { t } = useTranslation();
  const { alertProblemSummaryFilterApplied, includeRegionalProjectsApplied } = filterApplied;

  const showConditionalLabel = () => {
    return includeRegionalProjectsApplied ? (
      <div className={dropsConditionalSubLabel}>{t('contemplates_regional_projects')}</div>
    ) : null;
  };

  const showGuaranteesExcludeLabel = () => {
    return renderValues(alertProblemSummaryFilterApplied, guaranteeProblem.value, guarantee.value) >= 1 ? (
      <div className={includeRegionalProjectsApplied ? dropsConditionalSubLabelMargin : dropsConditionalSubLabel}>
        {t('guarantees_are_excluded')}
      </div>
    ) : null;
  };
  return (
    <div className={dropsLayout}>
      <div className={alertProblemSummaryFilterApplied ? firstFilteredDropContainer : firstDropContainer}>
        <div className={dropsTitle}>{t('composition')}</div>
        <div className={`${dropsValues} drops-value`}>
          <SummaryHeaderSingleValue
            value={renderValues(alertProblemSummaryFilterApplied, amountProjectProblem.value, lonProjectAmount.value)}
            title={renderSingularOrPluralText(alertProblemSummaryFilterApplied, amountProjectProblem, lonProjectAmount)}
          />
          <div className={styles.columnDisplay}>
            <SummaryHeaderSingleValue
              value={renderValues(alertProblemSummaryFilterApplied, amountLoanProblem.value, lonAmount.value)}
              title={renderSingularOrPluralText(alertProblemSummaryFilterApplied, amountLoanProblem, lonAmount)}
            />
            <SummaryHeaderSingleValue
              value={renderValues(
                alertProblemSummaryFilterApplied,
                guaranteeProblem.value,
                guarantee?.value !== null ? guarantee.value : 0,
              )}
              title={guarantee.text}
            />
          </div>
          <SummaryHeaderSingleValue
            value={amountProblemPercentage.value}
            title={amountProblemPercentage.text}
            isPercentage={true}
            hideValue={!alertProblemSummaryFilterApplied}
          />
        </div>
        {showConditionalLabel()}
      </div>
      <div className={alertProblemSummaryFilterApplied ? filteredDropsContainer : dropsContainer}>
        <div className={dropsTitle}>{t('current_approved_amount')}</div>
        <div className={`${dropsValues} drops-value`}>
          <SummaryHeaderSingleValue
            value={renderValues(alertProblemSummaryFilterApplied, amountApprovedProblem.value, lonApprovedAmount.value)}
            title={lonApprovedAmount.text}
            millionLogic={true}
          />
          <SummaryHeaderSingleValue
            value={renderValues(
              alertProblemSummaryFilterApplied,
              approvedWarrantyProblem.value,
              lonApprovedInversionAmount.value,
            )}
            title={renderValues(
              alertProblemSummaryFilterApplied,
              approvedWarrantyProblem.text,
              lonApprovedInversionAmount.text,
            )}
            millionLogic={true}
            littleValue={true}
            hideValue={alertProblemSummaryFilterApplied}
          />
          <div className={styles.columnDisplay}>
            <SummaryHeaderSingleValue
              value={lonApprovedPbpAmount.value}
              title={lonApprovedPbpAmount.text}
              millionLogic={true}
              littleValue={true}
              hideValue={alertProblemSummaryFilterApplied}
            />
            <SummaryHeaderSingleValue
              value={approvedWarranty.value}
              title={approvedWarranty.text}
              millionLogic={true}
              littleValue={true}
              hideValue={alertProblemSummaryFilterApplied}
            />
          </div>
        </div>
        {showConditionalLabel()}
      </div>
      <div className={alertProblemSummaryFilterApplied ? filteredDropsContainer : dropsContainer}>
        <div className={dropsTitle}>{t('performance_diff_in_portguese')}</div>
        <div className={`${dropsValues} drops-value`}>
          <SummaryHeaderSingleValue
            value={renderValues(
              alertProblemSummaryFilterApplied,
              amountWithoutDisbursementProblem.value,
              lonPendingDisbursementAmount.value,
            )}
            title={lonPendingDisbursementAmount.text}
            millionLogic={true}
          />
          <SummaryHeaderSingleValue
            value={renderValues(
              alertProblemSummaryFilterApplied,
              amountDisursementProblem.value,
              lonDisbursementAmount.value,
            )}
            title={lonDisbursementAmount.text}
            millionLogic={true}
          />
          <SummaryHeaderSingleValue
            value={renderValues(
              alertProblemSummaryFilterApplied,
              parseInt(amountDisbursementPercentProblem.value),
              parseInt(lonDisbursementPercent.value),
            )}
            title={lonDisbursementPercent.text}
            isPercentage={true}
            customStyle={isMobile() ? { paddingLeft: 0 } : { paddingLeft: 24 }}
          />
          <SummaryHeaderSingleValue
            value={renderValues(alertProblemSummaryFilterApplied, averageAgeProblem.value, lonAgeAverage.value)}
            title={lonAgeAverage.text}
            isYear={true}
          />
        </div>
        <div className={dropConditionalGroupOfLabels}>
          {showConditionalLabel()}
          {showGuaranteesExcludeLabel()}
        </div>
      </div>
      <div className={alertProblemSummaryFilterApplied ? hideDropsContainer : dropsContainer}>
        <div className={dropsTitle}>{t('pendings')}</div>
        <div className={`${dropsValues} drops-value`}>
          <SummaryHeaderSingleValue
            value={lonPendingSignatureAmount.value}
            title={lonPendingSignatureAmount.text}
            millionLogic={true}
          />
          <SummaryHeaderSingleValue
            value={lonPendingRatificationAmount.value}
            title={lonPendingRatificationAmount.text}
            millionLogic={true}
          />
        </div>
        {showConditionalLabel()}
      </div>
    </div>
  );
};
