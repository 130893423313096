import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './Calendar.scss';
import CalendarFilters from './CalendarFilters/CalendarFilters';
import CustomCalendar from './CustomCalendar/CustomCalendar';
import {
  allEventCounterTracker,
  firstMonthDate,
  getCalendarEvents,
  setCalendarEventCounter,
  setDate,
} from '../../../redux/actions/calendar';
import { getIdFromUrl } from '../../../services/numberUtil';
import { getEvents } from '../../../api/calendarApi';
import Project from '../../../models/Project';

const onlyDateString = date => {
  const year = date.getFullYear().toString();
  const monthsList = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  const month = monthsList[date.getMonth()];
  const day = date.getDate().toString();
  return `${year}-${month}-${day}`;
};

export const groupByType = list => {
  return list && list.length
    ? list.reduce((result, event) => {
        if (!result[event.eventType]) {
          result[event.eventType] = [];
        }
        result[event.eventType].push(event);
        return result;
      }, {})
    : [];
};

const groupByDateType = (groupedObj: any) => {
  const result = {};
  for (const [key, value] of Object.entries(groupedObj)) {
    result[key] = groupByType(value);
  }
  return result;
};

export const groupByDate = list => {
  return list.reduce((groups, event) => {
    const parsedDate = onlyDateString(new Date(event.date));
    if (!groups[parsedDate]) {
      groups[parsedDate] = [];
    }
    groups[parsedDate].push(event);
    return groups;
  }, {});
};

export const groupEventsWithMultiple = groups => {
  return Object.keys(groups).map(item => {
    return {
      id: 0,
      title: '',
      start: new Date(`${item}  12:01:00.0000000`),
      end: new Date(`${item}  12:01:00.0000000`),
      multiple: groups[item],
    };
  });
};

export const parseEventsForCalendar = list => {
  if (!list || list.length === 0) {
    return [];
  }
  const groups = groupByDate(list);
  const groupedByType = groupByDateType(groups);
  return groupEventsWithMultiple(groupedByType);
};

type Props = {
  getData?: boolean;
};

const Calendar = ({ getData }: Props) => {
  const calendar = useSelector<any, any>(state => state.calendar);
  const user = useSelector<any, any>(state => state.user);
  const dispatch = useDispatch();
  const { date, filters, events } = calendar;
  const history = useHistory();
  const currentProject = useSelector<any, Project>(state => state.currentProject);
  const [loader, setLoader] = useState<boolean>(false);

  React.useEffect(() => {
    if (getData) {
      dispatch(getCalendarEvents(getIdFromUrl(history.location.search)));
      dispatch(setDate(new Date()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getData, currentProject]);

  const getEventsToEventCounter = async () => {
    const month = firstMonthDate(date);
    const eventTypes = `&eventTypes=0&eventTypes=1&eventTypes=2&eventTypes=3&eventTypes=4&eventTypes=8&eventTypes=9`;
    const response = await getEvents(getIdFromUrl(history.location.search), month, eventTypes);
    dispatch(setCalendarEventCounter(allEventCounterTracker(response)));
  };

  React.useEffect(() => {
    setLoader(true);
    localStorage.setItem('projectDetailCalendarFilters', JSON.stringify(filters));
    setTimeout(() => {
      setLoader(false);
    }, 3000);
  }, [filters]);

  useEffect(() => {
    if (getData) {
      getEventsToEventCounter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events]);

  return (
    <div className="calendar-container">
      <div className="top-container">
        <CustomCalendar eventsList={parseEventsForCalendar(events)} isLoader={loader} />
      </div>

      <div className="bottom-container">
        <CalendarFilters
          disbursements={filters.disbursements}
          acquisitions={filters.acquisitions}
          activities={filters.activities}
          accordancesMonitor={filters.accordancesMonitor}
          accordancesRevision={filters.accordancesRevision}
          interGroup={filters.interGroup}
          supervisoryAction={filters.supervisoryAction}
          linkOutlook={user.syncCalendar}
        />
      </div>
    </div>
  );
};

export default Calendar;
