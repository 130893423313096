export enum CriteriaType {
  string,
  number,
  date,
  nested,
  encoded,
  decimalNumber,
  twoCriteriasSort,
}

export const DEFAULT_PAGINATION_INDICATOR: IPaginationIndicator = {
  sortCriteria: 'field2',
  sortAsc: true,
  currentPage: 1,
  nested: null,
  type: CriteriaType.string,
};
export interface IPaginationIndicator {
  sortCriteria: string;
  sortAsc: boolean;
  currentPage: number;
  nested: string | null;
  type: CriteriaType;
}
