import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectProductTypes } from '../../../../models/ProjectProductTypes';

type Props = {
  projectProductType: any;
  baseInfo: any;
  igrGreater: boolean;
};

const ParticipingCountries = ({ projectProductType, baseInfo, igrGreater }: Props) => {
  const { t } = useTranslation();

  return projectProductType === ProjectProductTypes.CT ||
    (projectProductType === ProjectProductTypes.IG && !igrGreater) ? (
    <div className="objective column">
      <span className="section-title">{t('participing_countries')}</span>
      <div>
        {baseInfo.projectCountries.map((item, ind) => {
          return (
            <span className="text-name">{`${item.code || ''}${
              ind < baseInfo.projectCountries.length - 1 ? ' - ' : ''
            }`}</span>
          );
        })}
      </div>
    </div>
  ) : null;
};

export default ParticipingCountries;
