import { CalledFrom } from '../../../models/CalledFromEnum';

export const buildPmrOptions = (t, PmrClassification) => {
  return [
    {
      id: 1,
      label: t('satisfactory'),
      value: PmrClassification.Green,
    },
    {
      id: 2,
      label: t('alert'),
      value: PmrClassification.Yellow,
    },
    {
      id: 3,
      label: t('problem'),
      value: PmrClassification.Red,
    },
    {
      id: 4,
      label: t('not_assigned'),
      value: PmrClassification.Na,
    },
  ];
};

export const buildPmrIdInitial = ({ calledFrom, indicatorCall, filterIndicators, filter, filterEarlyWarnings }) => {
  if (calledFrom === CalledFrom.EARLY_WARNINGS) {
    return filterEarlyWarnings.common.pmrId;
  }
  return indicatorCall ? filterIndicators.common.pmrId : filter.common.pmrId;
};
