import './LabelData.scss';
import NumberFormat from 'react-number-format';
import { Tooltip, withStyles } from '@material-ui/core';
import React from 'react';

const BlackTooltip = withStyles({
  tooltip: {
    backgroundColor: '#101319',
    fontSize: '16px',
    fontFamily: 'Rubik',
    lineHeight: '20px',
    padding: '8px 12px',
    borderRadius: '8px',
  },
  arrow: {
    fontSize: 20,
    color: '#4A4A4A',
    '&::before': {
      backgroundColor: '#101319',
    },
  },
})(Tooltip);

type Props = {
  label: string;
  data: any;
  reverse?: boolean;
  formatNumber?: boolean;
  forReport?: boolean;
  reverseUsd?: boolean;
  previousToNumber?: string;
};

const LabelData = (props: Props) => {
  const { label, data, reverse, formatNumber, forReport, reverseUsd, previousToNumber } = props;

  return (
    <div
      className={`financial-label-data-container as ${forReport && 'for-report'}`}
      style={reverse ? { flexDirection: 'column-reverse' } : {}}
    >
      {formatNumber ? (
        <>
          <span className="data-text">
            {previousToNumber || ''}
            {!reverseUsd && data && data !== '-' ? 'USD' : ''}{' '}
            {<NumberFormat value={data} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />}{' '}
            {reverseUsd && data && data !== '-' ? 'USD' : ''}
          </span>
        </>
      ) : (
        <span className="data-text">{data}</span>
      )}
      <span className="label-text">{label}</span>
    </div>
  );
};

export default LabelData;
