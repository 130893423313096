import React from 'react';
import './KeyProductAccordeon.scss';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import { Line } from 'rc-progress';
import { KeyProduct } from '../../../../../models/Planning';
import RemoveEdit from '../RemoveEdit/RemoveEdit';
import ConfirmDelete from '../../../../Util/Modals/ConfirmDelete/ConfirmDelete';
import { deleteKeyProduct } from '../../../../../redux/actions/planification';
import { isAuthorizedToEdit } from '../../../../../services/booleanUtil';
import User from '../../../../../models/User';
import Project from '../../../../../models/Project';

type Props = {
  keyProduct: KeyProduct;
};
const KeyProductItem = ({ keyProduct }: Props) => {
  const [deleteModal, setDeleteModal] = React.useState(false);
  const dispatch = useDispatch();
  const user = useSelector<any, User>(state => state.user);
  const currentProject = useSelector<any, Project>(state => state.currentProject);

  return (
    <>
      <div className="heading">
        <span className="chevron-key-product"></span>
        <span className="description">{keyProduct.name}</span>
        <div className="goal">
          <div className="line-container">
            <Line
              className="adjust-project-graphs"
              percent={keyProduct.yearlyPhysicalGoal}
              strokeWidth={10}
              trailWidth={10}
              strokeColor="#A8C7FD"
              trailColor="#E7EDF3"
            />
          </div>
        </div>
        <span className="goal">
          <NumberFormat
            value={keyProduct.yearlyFinancialGoal}
            displayType={'text'}
            thousandSeparator={'.'}
            decimalSeparator={','}
            decimalScale={0}
          />
        </span>

        {isAuthorizedToEdit(user, currentProject) && (
          <div className="remove-edit-container">
            <RemoveEdit
              onDelete={e => {
                setDeleteModal(true);
                e.stopPropagation();
              }}
              onEdit={() => {}}
              hideEdit={true}
            />
          </div>
        )}
      </div>
      <ConfirmDelete
        isOpen={deleteModal}
        closeModal={e => {
          setDeleteModal(false);
          e.stopPropagation();
        }}
        customTitle={'confirm_delete_keyproduct_title'}
        customMessage={'confirm_delete_keyproduct_message'}
        submit={e => {
          dispatch(deleteKeyProduct(keyProduct.id || 0));
          e.stopPropagation();
        }}
      />
    </>
  );
};

export default KeyProductItem;
