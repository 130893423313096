import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import User from '../../../models/User';
import { MenuTabs } from '../../MenuTabs/userTabsInterface';

type Props = {
  userLanguage: string;
  selectedMenuTab: MenuTabs;
};
export const PortfolioTitle = ({ userLanguage, selectedMenuTab }: Props) => {
  const { t } = useTranslation();
  const user = useSelector<any, User>(state => state.user);
  const title =
    selectedMenuTab.internalId === '0_global_bid'
      ? t(selectedMenuTab.title)
      : selectedMenuTab.assignedToMe
      ? user.name
      : selectedMenuTab.title;
  return userLanguage === 'en' ? (
    <>
      <p className="title-bold">
        {title}

        {t('s_apostrophe')}
      </p>{' '}
      {t('portfolio_of')}
    </>
  ) : (
    <>
      {' '}
      {t('portfolio_of')} <p className="title-bold">{title}</p>
    </>
  );
};
