import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setFilterIndicators } from '../../../redux/actions/filterIndicators';
import { setFilter } from '../../../redux/actions/filter';
import { Bubble } from '../BubbleElement/BubbleElement';
import { CalledFrom } from '../../../models/CalledFromEnum';
import useFilters from '../../../hooks/useFilters';
import { IFilterBubble } from '../../../models/FilterBubble';

type Props = IFilterBubble;

const handleActualSectorState = ({
  indicatorCall,
  filterIndicators,
  filterProjects,
  calledFrom,
  filterEarlyWarnings,
}) => {
  if (calledFrom === CalledFrom.EARLY_WARNINGS) {
    return filterEarlyWarnings.common.sectors;
  }
  return indicatorCall ? filterIndicators.common.sectors : filterProjects.common.sectors;
};
const handleActualDivisionState = ({
  indicatorCall,
  filterIndicators,
  filterProjects,
  calledFrom,
  filterEarlyWarnings,
}) => {
  if (calledFrom === CalledFrom.EARLY_WARNINGS) {
    return filterEarlyWarnings.common.divisionId;
  }
  return indicatorCall ? filterIndicators.common.divisionId : filterProjects.common.divisionId;
};

const SectorBubbles = (props: Props) => {
  const { indicatorCall, calledFrom } = props;
  const dispatch = useDispatch();
  const sectorsFromRedux = useSelector<any, any>(state => state.sectors);
  const { filterEarlyWarnings, setFilterEarlyWarnings } = useFilters();

  const filterIndicators = useSelector<any, any>(state => state.filterIndicators);
  const filterProjects = useSelector<any, any>(state => state.filter);

  const bubbles = handleActualSectorState({
    calledFrom,
    filterEarlyWarnings,
    indicatorCall,
    filterIndicators,
    filterProjects,
  });

  const closeBubble = (sectorId: number) => {
    const actualSectorState = handleActualSectorState({
      indicatorCall,
      filterIndicators,
      filterProjects,
      calledFrom,
      filterEarlyWarnings,
    });
    const actualDivisionState = handleActualDivisionState({
      indicatorCall,
      filterIndicators,
      filterProjects,
      calledFrom,
      filterEarlyWarnings,
    });
    const currentSector = sectorsFromRedux.find(sector => sector.id === sectorId);
    const newSectorState = actualSectorState.filter(sector => sector.id !== sectorId);
    const newDivisionState = actualDivisionState.filter(
      item => !currentSector.divisions.map(division => division.id).includes(item.id),
    );

    if (CalledFrom.EARLY_WARNINGS === calledFrom) {
      dispatch(
        setFilterEarlyWarnings({
          ...filterEarlyWarnings,
          common: {
            ...filterEarlyWarnings.common,
            divisionId: newDivisionState,
            sectors: newSectorState,
          },
        }),
      );
    } else {
      if (indicatorCall) {
        dispatch(
          setFilterIndicators({
            ...filterIndicators,
            common: {
              ...filterIndicators.common,
              divisionId: newDivisionState,
              sectors: newSectorState,
            },
          }),
        );
      } else {
        dispatch(
          setFilter({
            ...filterProjects,
            common: {
              ...filterProjects.common,
              divisionId: newDivisionState,
              sectors: newSectorState,
            },
          }),
        );
      }
    }
  };

  return (
    <>
      {bubbles.map(sector => (
        <Bubble key={sector.id} itsHidden={false} label={sector.label} closeBubble={() => closeBubble(sector.id)} />
      ))}
    </>
  );
};

export default SectorBubbles;
