import { AnalyticsKeyEnum } from '../../../../../models/AnalyticsKeyEnum';
import { capitalizeFirstLetter } from '../../../../../services/stringUtil';

const renderAnalyticsTooltips = (indicatorKey, t) => {
  const styleTooltip = {
    backgroundColor: '#101319)',
    bodyFontColor: '#ffffff',
    bodyFontFamily: 'Rubik',
    titleFontSize: 14, 
      bodyFontSize: 14,
  };
  return {
    ...styleTooltip,
    callbacks: {
      title() {
        return;
      },
      label(tooltipItem, data) {
        return analyticsTooltipData(tooltipItem, data, t);
      },
    },
  };
};

const analyticsTooltipData = (tooltipItem, data, t) => {
  if (
    data.indicatorKey === AnalyticsKeyEnum.PORTFOLIO_INDICATORS_MOST_USED_INDICATORS ||
    data.indicatorKey === AnalyticsKeyEnum.PROJECT_INDICATORS_AGREEMENTS_BAR_GRAPH ||
    data.indicatorKey === AnalyticsKeyEnum.AGGREGATE_INDICATORS_MOST_USED_SECTION
  ) {
    return `${capitalizeFirstLetter(t(data.dataListTranslationLabels[tooltipItem.index]))}: ${tooltipItem.value}`;
  } else {
    return `${capitalizeFirstLetter(t(data.subTextTranslatedLabels[tooltipItem.index]))}: ${tooltipItem.value}`;
  }
};

export { renderAnalyticsTooltips, analyticsTooltipData };
