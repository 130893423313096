import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { isMobile } from '../../../../../services/booleanUtil';
import { SupervisionPlanItemState } from '../util';

export const bgColor = '#f1f3f8';
const optionColor = '#E2EDFF';
export const basicBorder = '1px solid #d0d5e0';

export const colourStyles = {
  control: (styles, { isFocused }) => ({
    ...styles,
    borderRadius: 4,
    outline: 'none',
    border: basicBorder,
    boxShadow: isFocused ? 0 : basicBorder,
    '&:hover': {
      border: basicBorder,
    },
    height: '28px',
    minHeight: '28px',
    maxWidth: '128px',
    cursor: 'pointer',
    backgroundColor: bgColor,
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      borderRadius: '5px',
      backgroundColor: isDisabled ? null : isSelected ? optionColor : isFocused ? optionColor : null,
      color: isDisabled ? '#ccc' : data.color,
      cursor: isDisabled ? 'not-allowed' : 'pointer',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && optionColor,
      },
      fontSize: isMobile() ? '11px' : '14px',
      height: '28px',
      display: 'flex',
      alignItems: 'center',
    };
  },
  input: (styles, { data }) => ({ ...styles, color: data?.color, height: '100%', marginTop: '-5px' }),
  placeholder: styles => ({ ...styles }),
  singleValue: (styles, { data }) => ({
    ...styles,
    color: data?.color,
    fontSize: isMobile() ? '10px' : '12px',
    marginTop: '1px',
  }),
  menu: styles => ({
    ...styles,
    backgroundColor: bgColor,
    border: basicBorder,
    borderRadius: '14px',
    boxShadow: 'none',
    padding: '4px 7px',
  }),
  indicatorsContainer: styles => ({ ...styles, height: '100%' }),
  valueContainer: styles => ({ ...styles, height: '100%' }),
};

type Props = {
  selectedValue: SupervisionPlanItemState;
  onChange: Function;
  disabled?: boolean;
};

const StateSelect = ({ selectedValue, onChange, disabled }: Props) => {
  const { t } = useTranslation();
  const stateOptions = [
    { value: SupervisionPlanItemState.Completed, label: t('supervision_plan_activity_state_3'), color: '#5EB890' },
    { value: SupervisionPlanItemState.InProgress, label: t('supervision_plan_activity_state_1'), color: '#264377' },
    { value: SupervisionPlanItemState.Pending, label: t('supervision_plan_activity_state_0'), color: '#424D64' },
    { value: SupervisionPlanItemState.WithDelays, label: t('supervision_plan_activity_state_2'), color: '#E092A3' },
    { value: SupervisionPlanItemState.Cancelled, label: t('supervision_plan_activity_state_4'), color: '#979797' },
  ];
  const [value, setValue] = useState<any>(stateOptions[2]);

  const onChangeState = value => {
    setValue(value);
    onChange(value);
  };

  useEffect(() => {
    setValue(stateOptions.find(o => o.value === selectedValue));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  return (
    <Select
      value={value}
      label="Single select"
      options={stateOptions}
      styles={colourStyles}
      components={{
        IndicatorSeparator: () => null,
      }}
      onChange={value => onChangeState(value)}
      isDisabled={disabled}
    />
  );
};

export default StateSelect;
