import React from 'react';
import './ClearButton.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  onClick: any;
};

const ClearButton = ({ onClick }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="clear-button-container" onClick={onClick}>
      <span className="icon"></span>
      <span className="text">{t('clear')}</span>
    </div>
  );
};

export default ClearButton;
