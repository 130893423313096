import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './ProjectDetailMobile.scss';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { getIdFromUrl } from '../../../services/numberUtil';
import Accordeon from './Accordeon';
import { ProjectProductTypes } from '../../../models/ProjectProductTypes';
import { getProject } from '../../../redux/actions/currentProject';
import { ProjectProductTypeClassification } from '../../../models/ProjectProductTypeClassification';

const initialDivision = { name: '', id: 0 };

export const ProjectDetailMobile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentProject } = useSelector<any, any>(state => state);
  const {
    name = '',
    division = initialDivision,
    code = '',
    loanNumber = 0,
    projectProductTypeClassification,
  } = currentProject;
  const history = useHistory();

  React.useEffect(() => {
    dispatch(getProject(getIdFromUrl(history.location.search)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const MOCK_OPERATION = 'ATN/GN-1111-UR'; //TODO Integrar esta prop cuando venga de BE
  const isLoanOrIGRGreater3 = projectProductType => {
    return (
      projectProductType === ProjectProductTypes.Loan ||
      (projectProductType === ProjectProductTypes.IG &&
        projectProductTypeClassification === ProjectProductTypeClassification.IGR_GREATER_THAN_3MM)
    );
  };
  return (
    <div className="project-detail-mobile-container">
      <div className="general-info">
        <div className="code-division">
          <h3 className="code">{code}</h3>
          <div className="division">{division.name}</div>
        </div>
        <h1>{name}</h1>

        <h3>
          {isLoanOrIGRGreater3(ProjectProductTypes.CT)
            ? `${t('loan')}: ${loanNumber}`
            : `${t('operation')}: ${MOCK_OPERATION}`}
        </h3>
      </div>
      <Accordeon currentProject={currentProject} />
    </div>
  );
};

export default ProjectDetailMobile;
