import {
  ReviewProjectsSelectedRowsActionTypes,
  SET_LIST_REVIEW_SELECTED_PROJECT,
  SET_NONE_REVIEW_SELECTED_PROJECT,
  SET_REVIEW_SELECTED_PROJECT,
} from '../actions/types';

const initialState: number[] = [];

const reviewProjectsSelectedRowsReducer = (
  state = initialState,
  action: ReviewProjectsSelectedRowsActionTypes,
): any => {
  switch (action.type) {
    case SET_REVIEW_SELECTED_PROJECT:
      if (state.includes(action.payload)) {
        return state.filter(i => i !== action.payload);
      } else {
        return [...state, action.payload];
      }
    case SET_LIST_REVIEW_SELECTED_PROJECT:
      return [...action.payload];
    case SET_NONE_REVIEW_SELECTED_PROJECT:
      return [];
    default:
      return state;
  }
};
export default reviewProjectsSelectedRowsReducer;
