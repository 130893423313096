import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEarlyWarningsFilters } from '../../../hooks/useEarlyWarningsFilters';
import { IconCode, TabFilterOption } from '../../../models/EarlyWarnings';
import { getProjectsSimpleTableIndicator } from '../../../redux/actions/indicatorProjects';
import { PMR_ALERT_PROBLEM_LAST_3_YEARS } from '../../../services/sessionFiltersIndicatorDetailHelper';
import { EarlyWarningsFilters } from '../EarlyWarningsFilters/EarlyWarningsFilters';

import styles from './PmrAlertProblemLast3Years.module.scss';
import { Table } from './Table/Table';
import { handleLoading } from '../../utils';

const filterConfigComponent: TabFilterOption[] = [
  {
    id: 0,
    projectQuantity: 0,
    projectLabel: { singular: 'project', plural: 'projects' },
    filterIcon: IconCode.GREEN,
    filterLabel: 'no_alert_or_problem_in_the_last_3_years',
    selected: false,
  },
  {
    id: 1,
    projectQuantity: 0,
    projectLabel: { singular: 'project', plural: 'projects' },
    filterIcon: IconCode.YELLOW,
    filterLabel: 'alert_or_problem_in_one_or_the_last_two_years',
    selected: false,
  },
  {
    id: 2,
    projectQuantity: 0,
    projectLabel: { singular: 'project', plural: 'projects' },
    filterIcon: IconCode.RED,
    filterLabel: 'alert_or_problem_in_the_last_three_years',
    selected: false,
  },
];
const defaultSessionFilterObject = {
  filterConfigState: filterConfigComponent,
  changeProjectType: true,
};
export const PmrAlertProblemLast3Years = () => {
  const { currentIndicator } = useSelector<any, any>(state => state);
  const { filterConfigState, handleEarlyWarningsFilter, handleProjectsQuantities } = useEarlyWarningsFilters({
    sessionKey: PMR_ALERT_PROBLEM_LAST_3_YEARS,
    defaultSessionFilterObject,
  });
  const dispatch = useDispatch();
  const { container, earlyAlertsFilterContainer, bodyContainer } = styles;
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getFilteredSimpleIndicators(dispatch, currentIndicator.id, currentIndicator.indicatorKey, setLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndicator.id, dispatch]);

  useEffect(() => {
    if (!sessionStorage.getItem(PMR_ALERT_PROBLEM_LAST_3_YEARS)) {
      sessionStorage.setItem(PMR_ALERT_PROBLEM_LAST_3_YEARS, JSON.stringify(defaultSessionFilterObject));
    }
  }, []);

  return (
    <div className={container}>
      <div className={earlyAlertsFilterContainer}>
        <EarlyWarningsFilters filterSet={filterConfigState} handleFilter={handleEarlyWarningsFilter} />
      </div>
      <div className={bodyContainer}>
        <Table
          projectsQuantities={handleProjectsQuantities}
          earlyWarningsTab={[
            filterConfigState[0].selected,
            filterConfigState[1].selected,
            filterConfigState[2].selected,
          ]}
          loading={loading}
        />
      </div>
    </div>
  );
};

const getFilteredSimpleIndicators = (d, currentIndicatorId, currentIndicatorKey, setLoading) => {
  return handleLoading(() => d(getProjectsSimpleTableIndicator(currentIndicatorId, currentIndicatorKey)), setLoading);
};
