import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { EarlyWarningsSectionViewsEnum } from '../../models/EarlyWarnings';
import { MenuTabsComponent } from '../MenuTabs/MenuTabsComponent';
import { AppMenuTabs, MenuTabSectionEnum } from '../MenuTabs/userTabsInterface';
import styles from './EarlyWarnings.module.scss';
import EWHeader from './EWHeader/EWHeader';
import { ProjectsSection } from './ProjectsSection/ProjectsSection';
import { TrendsSection } from './TrendsSection/TrendsSection';
import { EARLY_WARNINGS_VIEW, getFilterFromSession } from './Util';

const { earlyWarningsContainer, tabsContainer } = styles;

const EarlyWarnings = () => {
  const [viewState, setViewState] = useState(getFilterFromSession());
  const [waitForResponse, setWaitForResponse] = useState(false);
  const menuTabs = useSelector<any, AppMenuTabs>(state => state.menuTabs);
  const { earlyWarningsSelectedTab } = menuTabs;

  useEffect(() => {
    if (!sessionStorage.getItem(EARLY_WARNINGS_VIEW)) {
      sessionStorage.setItem(EARLY_WARNINGS_VIEW, EarlyWarningsSectionViewsEnum.PROJECTS);
    }
  }, []);

  const updateSession = () => {
    if (getFilterFromSession() === EarlyWarningsSectionViewsEnum.PROJECTS) {
      sessionStorage.setItem(EARLY_WARNINGS_VIEW, EarlyWarningsSectionViewsEnum.TRENDS);
    } else {
      sessionStorage.setItem(EARLY_WARNINGS_VIEW, EarlyWarningsSectionViewsEnum.PROJECTS);
    }
    setViewState(getFilterFromSession());
  };

  const handleWaintingResponse = (value: boolean) => {
    setWaitForResponse(value);
  };

  return (
    <div className={earlyWarningsContainer}>
      <div className={tabsContainer}>
        <MenuTabsComponent
          selectedTab={earlyWarningsSelectedTab}
          menuTabSection={MenuTabSectionEnum.EARLY_WARNINGS_TAB}
        />
      </div>
      <EWHeader updateSession={updateSession} sessionState={viewState} waitForResponse={waitForResponse} />
      {viewState === EarlyWarningsSectionViewsEnum.PROJECTS ? (
        <ProjectsSection waitingResponse={handleWaintingResponse} />
      ) : (
        <TrendsSection waitingResponse={handleWaintingResponse} />
      )}
    </div>
  );
};

export default EarlyWarnings;
