import arrayMove from 'array-move';
import { ADD_INDICATORS, SET_ALL_INDICATORS } from './types';
import Indicator from '../../models/Indicator';
import {
  getAllIndicators,
  updateIndicators,
  removeIndicator,
  sortIndicators,
  getNewFilteredIndicators,
  removeIndicatorFromUserIndicatorCollection,
} from '../../api/indicatorApi';
import { processIndicators } from '../../components/Portfolio/indicatorHelper';
import { setRequestStatus } from './requestStatus';
import { RequestStatus } from '../../models/RequestStatus';
import { setTitleOnUpdateIndicators } from '../../components/Portfolio/PortfolioUtil';
import { setIndicatorLoading } from './dataTable';

export function addIndicators(indicators: Indicator[]) {
  return {
    type: ADD_INDICATORS,
    payload: indicators,
  };
}

export function setAllIndicators(indicators: any[]) {
  return {
    type: SET_ALL_INDICATORS,
    payload: indicators,
  };
}

export function getIndicatorsList() {
  return async (dispatch: any, getState: any) => {
    try {
      const response = await getAllIndicators();

      dispatch(setAllIndicators(response));
    } catch (error) { }
  };
}

export function updateSelectedIndicators(data, t) {
  return async (dispatch: any, getState: any) => {
    const { portfolioSelectedTab } = getState().menuTabs;

    dispatch(setRequestStatus(RequestStatus.Loading));
    try {
      await updateIndicators(data);

      const response = await getNewFilteredIndicators();
      const processedIndicators = processIndicators(response, {
        titleFromTab: setTitleOnUpdateIndicators(portfolioSelectedTab, t),
      });

      dispatch(addIndicators(processedIndicators));
      dispatch(setRequestStatus(RequestStatus.Success));
    } catch (error) {
      dispatch(setRequestStatus(RequestStatus.Default));
    }
  };
}

export function removeIndicatorFromList(id) {
  return async (dispatch: any, getState: any) => {
    try {
      await removeIndicatorFromUserIndicatorCollection(id)
      const indicatorsList = getState().indicators.filter(indicator => indicator.id !== id);
      dispatch(addIndicators(indicatorsList));
    } catch (error) { }
  };
}

export function sortIndicatorsList(oldIndex, newIndex, t) {
  return async (dispatch: any, getState: any) => {
    const sortedIndicators: Indicator[] = arrayMove(getState().indicators, oldIndex, newIndex);
    dispatch(addIndicators(sortedIndicators));
    const indexIndicators = sortedIndicators.map(i => i.id);
    try {
      dispatch(setIndicatorLoading(true));
      await sortIndicators(oldIndex, newIndex);
      await dispatch(updateSelectedIndicators(indexIndicators, t));
    } catch (error) {
      const sortedIndicators: Indicator[] = arrayMove(getState().indicators, newIndex, oldIndex);
      dispatch(addIndicators(sortedIndicators));
    } finally {
      dispatch(setIndicatorLoading(false));
    }
  };
}
