import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import './ProjectsTableRow.scss';
import { Line } from 'rc-progress';
import NumberFormat from 'react-number-format';
import { PmrClassificationComponent } from '../../Util/PmrClassification/PmrClassification';
import Project from '../../../models/Project';
import { Link, useHistory } from 'react-router-dom';
import { setBulletColor } from '../../../services/util';
import { tooltipTitle } from '../../../services/stringUtil';
import { showDisbursement, showIndexTCDI, isMobile } from '../../../services/booleanUtil';
import { PmrPrintValue } from '../../Util/PmrPrintValue/PmrPrintValue';
import AddToPortfolioButton from './AddToPortfolioButton';
import { CodeAndLoan } from '../CodeAndLoan/CodeAndLoan';
import { setReviewSelectedProject } from '../../../redux/actions/reviewProjectsSelectedRows';
import CommentsAlerts from '../../Reviews/CommentsAlerts/CommentsAlerts';
import ReviewAccordance from '../../Reviews/ReviewAccordance/ReviewAccordance';
import { trackActionClicked } from '../../../api/trackActionClickedApi';
import { TrackedActions } from '../../../models/TrackedActionsEnum';
import { TcdiClassificationComponent } from '../../Util/TcdiClassification/TcdiClassification';
import { ProjectProductTypeClassification } from '../../../models/ProjectProductTypeClassification';
import { ReactComponent as IconRowRight } from '../../../assets/icons/u_arrow-right.svg';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';

const BlackTooltip = withStyles({
  tooltip: {
    backgroundColor: '#101319',
    fontSize: '16px',
    fontFamily: 'Rubik',
    lineHeight: '20px',
    padding: '8px 12px',
    borderRadius: '8px',
    transformOrigin: 'center',
  },
  arrow: {
    color: '#101319', 
    '&::before': {
      borderWidth: '0.5em 0.5em 0 0.5em', 
      borderColor: 'transparent transparent transparent #101319', 
    },
    transform: 'translateX(-50%)', 
    top: '100%', 
  },
})(Tooltip);

const renderLastDisbursementInDays = (lastDisbursementInDays, t) => {
  return lastDisbursementInDays ? (lastDisbursementInDays === 1 ? t('day') : t('days')) : '-';
};

const selectableTr = selectable => {
  return selectable ? 'selectable-project-row' : '';
};

const assignedToMeClassName = assignedToMe => {
  return assignedToMe ? 'uppercase-text td-assigned-to-me' : 'uppercase-text project-name';
};

interface ReceivedProps {
  project: Project;
  setInFollow: any;
  selectable?: boolean;
  withComments?: boolean;
  canCreateAccordance?: boolean;
  fromReview?: boolean;
  revision?: any;
}

type Props = ReceivedProps;

const selectedStyle = (selected = false) => {
  return selected ? { backgroundColor: 'rgba(235, 236, 240, 0.5)' } : {};
};

const renderMobileRow = (project, t, handleClick) => {
  return (
    <>
      <tr key={project.id} onClick={handleClick}>
        <td
          rowSpan={3}
          className={
            project.assignedToMe ? 'uppercase-text td-assigned-to-me project-code' : 'uppercase-text project-code'
          }
        >
          <CodeAndLoan project={project} />
        </td>
        <td className="no-border project-name"> {project.name} </td>
        <td rowSpan={3} className="pmr">
          <span className="pmr-value">
            <PmrPrintValue pmrClassi={project.pmrClassification} pmrValue={project.pmrValue} />
          </span>
          <div className="pmr-flag">
            <PmrClassificationComponent classificationColor={project.pmrClassification} />
            <div className="pmr-title">{t('pmr_initials')}</div>
          </div>
        </td>
      </tr>
      <tr>
        <td className="no-border project-temporary">
          {`${t('temporary_advance')}: `}
          <span className="bold-font">
            {project.temporaryAdvance ? project.temporaryAdvance.percentTemporaryAdvanceMade : '-'}{' '}
            {project.temporaryAdvance ? t('percentage_sign') : ''}
          </span>
        </td>
      </tr>
      <tr>
        <td className="project-financial-information">
          {`${t('amount_disbursed')}: `}
          <span className="bold-font">
            {project.financialInformation.percentRealizedDisbursement}
            {t('percentage_sign')}
          </span>
        </td>
      </tr>
    </>
  );
};

const fromReviewObj = (fromReview = false) => (fromReview ? { fromReview: true } : {});

const notSelectableNoAccordanceNoComments = (selectable = false, canCreateAccordance = false, withComments = false) =>
  !selectable && !canCreateAccordance && !withComments;

const notSelectableNoComments = (selectable = false, withComments = false) => !selectable && !withComments;

const fallbackTcdiValue = (project: Project) => project.tcdiValue || null;

const selectSquareClass = (isSelected = false) => (isSelected ? 'selected' : '');

export const ProjectsTableRow = (props: Props) => {
  const { project, selectable, withComments, canCreateAccordance, fromReview, revision } = props;
  const { projectProductType, projectProductTypeClassification } = project;
  const dispatch = useDispatch();
  const reviewProjectsSelectedRows = useSelector<any, any>(state => state.reviewProjectsSelectedRows);
  const { filter } = useSelector<any, any>(state => state);
  const [isSelected, setIsSelected] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const history = useHistory();
  const setInFollow = (id: number) => {
    props.setInFollow(id);
  };
  const handleClick = () => {
    const state = fromReviewObj(fromReview);
    history.push(`/projectdetail?id=${project.id}`, state);
    trackActionClicked(TrackedActions.projectDetailsFromListClicked);
  };

  const handleFollow = (e, projectId) => {
    e.stopPropagation();
    setInFollow(projectId);
    if (!project.inFollow) {
      trackActionClicked(TrackedActions.followProjectFromListClicked);
    }
  };

  const handleSelected = (e, projectId) => {
    e.stopPropagation();
    dispatch(setReviewSelectedProject(projectId));
  };

  React.useEffect(() => {
    setIsSelected(reviewProjectsSelectedRows.includes(project.id));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewProjectsSelectedRows]);

  return isMobile() ? (
    renderMobileRow(project, t, handleClick)
  ) : (
    <tr key={project.id} onClick={handleClick} className={selectableTr(selectable)} style={{ cursor: 'pointer' }}>
      {selectable && (
        <td style={selectedStyle(isSelected)} onClick={e => handleSelected(e, project.id)}>
          <span className={`select-square ${selectSquareClass(isSelected)}`}></span>
        </td>
      )}
      <td className={assignedToMeClassName(project.assignedToMe)} style={selectedStyle(isSelected)}>
        <BlackTooltip  title={t('go_to_project_info')} placement="bottom" enterDelay={500} leaveDelay={200} arrow>
          <div>
           <span className={`project-type-bullet ${setBulletColor(projectProductType)}`}></span>
            {/* <span className='link'>{project.code}</span> */}
            <Link className='link' to={`/projectdetail?id=${project.code}`}>{project.code}</Link>
          </div>
        </BlackTooltip>
      </td>
      <td style={selectedStyle(isSelected)}>{project.loanNumber}</td>
      <td className="adjust-project-name" style={selectedStyle(isSelected)}>
        <BlackTooltip title={project.name} placement="bottom" enterDelay={500} leaveDelay={200} arrow>
          <span>{project.name}</span>
        </BlackTooltip>
      </td>
      <td className="adjust-projec-division" style={selectedStyle(isSelected)}>
        {project.division.code}
      </td>
      <td style={selectedStyle(isSelected)} className="project-aligned">
        {renderTemporaryAdvance(project, t)}
      </td>
      {!canCreateAccordance && (
        <td style={selectedStyle(isSelected)} className="project-aligned">
          {project.financialInformation.lastDisbursementInDays}
          {renderLastDisbursementInDays(project.financialInformation.lastDisbursementInDays, t)}
        </td>
      )}

      <td style={selectedStyle(isSelected)} className="project-aligned">
        {renderDisbursementAmount(project, filter, t)}
      </td>

      {showPendingDisbursementAmount(canCreateAccordance) && (
        <td style={selectedStyle(isSelected)} className="project-aligned">
          <NumberFormat
            value={project.financialInformation.pendingDisbursementAmount}
            displayType={'text'}
            thousandSeparator={'.'}
            decimalSeparator={','}
            suffix={t('dolar_sign')}
          />
        </td>
      )}
      <td style={selectedStyle(isSelected)} className="project-aligned icons">
        {showIndexTCDI(
          projectProductType,
          projectProductTypeClassification === ProjectProductTypeClassification.IGR_GREATER_THAN_3MM,
        ) ? (
          <>
            <TcdiClassificationComponent clasification={fallbackTcdiValue(project)} />
            <span className="pmr-classification-margin-left">{project.tcdiValue}</span>
          </>
        ) : (
          <>
            <PmrClassificationComponent classificationColor={project.pmrClassification} />
            <span className="pmr-classification-margin-left">
              <PmrPrintValue pmrClassi={project.pmrClassification} pmrValue={project.pmrValue} />
            </span>
          </>
        )}
      </td>
      {withComments && (
        <td style={selectedStyle(isSelected)}>
          <CommentsAlerts project={project} revision={revision} />
        </td>
      )}
      {canCreateAccordance && (
        <td style={selectedStyle(isSelected)}>
          <ReviewAccordance project={project} revision={revision} />
        </td>
      )}
      <td style={selectedStyle(isSelected)}>
        {notSelectableNoComments(selectable, withComments) && (
          <>
            {project.inFollow ? (
              <BlackTooltip
                arrow
                title={tooltipTitle(t, 'following')}
                placement="bottom-start"
                enterDelay={500}
                leaveDelay={200}
              >
                <span onClick={e => handleFollow(e, project.id)} className="eye-unfollow" id="unfollowProject" />
              </BlackTooltip>
            ) : (
              <BlackTooltip
                arrow
                title={tooltipTitle(t, 'follow')}
                placement="bottom"
                enterDelay={500}
                leaveDelay={200}
              >
                <span onClick={e => handleFollow(e, project.id)} className="eye-follow" id="followProject" />
              </BlackTooltip>
            )}
          </>
        )}
        {notSelectableNoAccordanceNoComments(selectable, canCreateAccordance, withComments) && (
          <AddToPortfolioButton project={project} />
        )}
        {/* <BidTooltip title={tooltipTitle(t, 'project_info')} placement="bottom-start" enterDelay={500} leaveDelay={200}>
          <span className="fw" />
          
        </BidTooltip> */}
        {/* <IconRowRight className='row-right' /> */}
      </td>
    </tr>
  );
};

const renderTemporaryAdvance = (project, t) => {
  return project.temporaryAdvance ? (
    <BlackTooltip
      title={
        project.temporaryAdvance.temporaryAdvanceMade +
        (project.temporaryAdvance.temporaryAdvanceMade === 1 ? t('month') : t('months'))
      }
       placement="bottom-start"
       arrow
    >
      <div className="line-container">
        <Line
          className="adjust-project-graphs"
          percent={project.temporaryAdvance.percentTemporaryAdvanceMade}
          strokeWidth={12}
          trailWidth={12}
          strokeColor="#538CFF"
          trailColor="#D0D5E0"
        />
      </div>
    </BlackTooltip>
  ) : (
    '-'
  );
};

const renderDisbursementAmount = (project, filter, t) => {
  return showDisbursement(project, filter) ? (
    <BlackTooltip
      title={project.financialInformation.percentRealizedDisbursement + t('percentage_sign')}
      placement="bottom-start"
      enterDelay={500}
      leaveDelay={200}
      arrow
    >
      <div className="line-container">
        <Line
          className="adjust-project-graphs"
          percent={project.financialInformation.percentRealizedDisbursement}
          strokeWidth={12}
          trailWidth={12}
          strokeColor="#538CFF"
          trailColor="#D0D5E0"
        />
      </div>
    </BlackTooltip>
  ) : (
    '-'
  );
};

const showPendingDisbursementAmount = canCreateAccordance => {
  if (!canCreateAccordance) {
    return true;
  }
  return false;
};
