import html2canvas from 'html2canvas';

export const generateBlob = async (id: string, dimensions = { width: 300, height: 300 }) => {
  const element = document.getElementById(id);

  return new Promise(async (resolve, reject) => {
    if (element) {
      try {
        const canvas = await html2canvas(element, {
          backgroundColor: null,
          scale: 2,
          width: dimensions.width,
          height: dimensions.height,
        });
        canvas.toBlob(function (blob) {
          resolve({ name: id, blob });
        });
      } catch (error) {
        reject(error);
      }
    } else {
      reject(new Error(`No element found with id: ${id}`));
    }
  });
};

export const downloadImage = (blob: any) => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.setAttribute('style', 'display: none');
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  const name = new Date().getTime().toString();
  a.download = `img-${name}.png`;
  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
};
