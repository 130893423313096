import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './BidSection.module.scss';
import '../Financial.scss';
import { parseToChartData } from '../util';
import DisbursementsChart from '../DisbursementsChart/DisbursementsChart';
import { labelsTexts } from '../Financial';
import PariPassuSection from '../PariPassuSection/PariPassuSection';
import { DisbursementChartAlertBar } from '../DisbursementChartAlertBar/DisbursementChartAlertBar';
import LabelData from '../../../../Util/LabelData/LabelData';
import ExecutionByComponent from '../ExecutionByComponent/ExecutionByComponent';
import FinancialAdvance from '../FinancialAdvance/FinancialAdvance';
import { checkHundreadPercent } from '../../../GeneralInformationTC/GeneralInformationTC';

type Props = {
  financialInformation: any;
  baseInformation: any;
};

const BidSection = ({ financialInformation, baseInformation }: Props) => {
  const {
    disbursements,
    realizedDisbursementAnnualAmount,
    percentRealizedAnnualDisbursement,
    componentBalances,
    realizedDisbursementAmount,
    commitedAmount,
    availableAmount,
    lastDisbursementDate,
    totalDisbursementAmount,
    percentRealizedDisbursement,
    percentCommitedAmount,
    percentAvailableAmount,
  } = financialInformation;
  const { t } = useTranslation();
  const { chartContainer } = styles;
  return (
    <div className="financial-container">
      <div className="container_boxes">
        <FinancialAdvance
          data={{
            realizedDisbursementAmount,

            commitedAmount,
            availableAmount,
            lastDisbursementDate,
            totalDisbursementAmount,
            percentRealizedDisbursement: Math.round(percentRealizedDisbursement),
            percentAvailableAmount: Math.round(percentAvailableAmount),
            percentCommitedAmount: checkHundreadPercent(
              percentRealizedDisbursement,
              percentCommitedAmount,
              percentAvailableAmount,
            ),
          }}
          baseInformation={baseInformation}
        />

        <hr className="border-advance-container" />
        <PariPassuSection financialInformation={financialInformation} />
      </div>
      <div className="container_boxes-anticipations">
        <ExecutionByComponent data={componentBalances || []} />
      </div>
      <div className='container_boxes-anticipations'>
        <DisbursementsChart data={parseToChartData(new Date().getMonth() + 1, labelsTexts(t), disbursements, true)} />
        <span className="basic-display-column chart-bid">
          <span className="bullet" />
          <LabelData
            label={t('disbursed')}
            data={realizedDisbursementAnnualAmount}
            reverse={true}
            formatNumber={true}
            reverseUsd={true}
            previousToNumber={`${percentRealizedAnnualDisbursement}% = `}
          />
        </span>
        <DisbursementChartAlertBar />
      </div>
    </div>
  );
};

export default BidSection;
