import React from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import Country from '../../../../models/Country';
import styles from './HeaderData.module.scss';
type Props = {
  country: Partial<Country>;
};
export const HeaderData = ({ country }: Props) => {
  const { t } = useTranslation();
  const { headerTableData } = useSelector<any, any>(state => state.currentIndicator);
  const currentHeaderData = headerTableData.find(data => data.country === country.code);
  const {
    headerContainer,
    itemContainer,
    quantityContainer,
    quantity,
    type,
    amount,
    subtitle,
    colorBarLON,
    colorBarTCP,
    colorBarIGR,
  } = styles;

  const colorBar = [colorBarLON, '', colorBarTCP, colorBarIGR];
  const projectTypeAcronym = ['lon', '', 'tcp', 'igr'];
  return (
    <div className={headerContainer}>
      {currentHeaderData &&
        currentHeaderData.data &&
        currentHeaderData.data.map(item => {
          return (
            <div className={itemContainer} key={item.projectType}>
              <div className={quantityContainer}>
                <div className={quantity}>{item.projectsAmount}</div>
                <div className={type}>{projectTypeAcronym[item.projectType]}</div>
              </div>
              <div className={amount}>
                <NumberFormat
                  value={item.approvedAmount}
                  displayType={'text'}
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  decimalScale={1}
                  suffix={' MM'}
                />
              </div>
              <div className={subtitle}>{t('total_approved_amount_usd')}</div>
              <div className={colorBar[item.projectType]}></div>
            </div>
          );
        })}
    </div>
  );
};
