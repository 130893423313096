import React, { useEffect } from 'react';
import { find } from 'lodash';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AccordionDetails, Checkbox, FormControlLabel } from '@material-ui/core';
import { ReactComponent as CheckedIcon } from '../../../../assets/checkbox_checked_icon.svg';
import { ReactComponent as UncheckedIcon } from '../../../../assets/checkbox_unchecked_icon.svg';
import {
  HelpCenterFormAccordion,
  HelpCenterFormAccordionSummary,
} from '../../ContentManagerAccordion/ContentManagerAccordion';
import '../HelpCenterModalForm/HelpCenterModalForm.scss';
import CustomSelect from '../../../Util/CustomSelect/CustomSelect';
import { tutorialsCategory } from '../../ContentManagerKeys';
import { useStyles } from './../HelpCenterModalForm/HelpCenterModalForm';
import { postTutorialListItem, updateTutorialListItem } from '../../../../api/contentManager';

import { findLanguageByValue, findLanguage, getOptionsFromKeys } from '../../../Util/ContentManager/ContentManager';
import {
  getPostTutorialItem,
  mapTranslationsToTutorials,
  getOnChangeUpdatedFormFields,
  defaultFormFields,
  getButtonTitle,
  getDisabledClass,
} from './TutorialsModalFrom.util';

export const defaultOption = { id: 0, value: '0', label: 'Seleccionar' };

type Props = {
  closeModal: Function;
  getLanguageTranslationFromKey: any;
  displayGenericErrorMessage?: any;
  isEdit?: any;
  tutorialItem?: any;
  updateTutorialsList?: any;
  languagesData?: any;
};

const getSpan = (type, t) => {
  return <span className="uncompleted-error">{t(`validation_no_${type}`)}</span>;
};

export const getSelectErrorMessage = (selectErrors, t, type) => {
  let span: any = null;
  if ((selectErrors.categoryError && type === 'category') || type !== 'category') {
    span = getSpan(type, t);
  }
  return span;
};

export default function TutorialsModalForm({
  closeModal,
  getLanguageTranslationFromKey,
  displayGenericErrorMessage,
  isEdit,
  tutorialItem,
  updateTutorialsList,
  languagesData,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [category, setCategory] = React.useState(defaultOption);
  const [formFields, setFormFields] = React.useState(defaultFormFields);
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [selectErrors, setSelectErrors] = React.useState({
    categoryError: false,
  });
  const handleChange = panel => (event, isExpanded) => setExpanded(isExpanded ? panel : false);
  const handleFormChange = e => {
    e.preventDefault();
    const { name, value } = e.target;
    const dataInfo = name.split('-');
    const type = dataInfo[0];
    const language = dataInfo[1];
    const updatedList = getOnChangeUpdatedFormFields(formFields, type, value, language);
    setFormFields(updatedList);
  };
  const handleSubmitTutorialItem = async () => {
    try {
      if (category.id === 0) {
        setSelectErrors({
          categoryError: category.id === 0,
        });
        return;
      }
      const postTutorialItem = getPostTutorialItem(category, formFields, findLanguageByValue, languagesData);

      if (isEdit) {
        await updateTutorialListItem({ ...postTutorialItem, id: tutorialItem.id });
      } else {
        await postTutorialListItem(postTutorialItem);
      }

      updateTutorialsList();
      closeModal();
    } catch {
      displayGenericErrorMessage(true);
    }
  };
  useEffect(() => {
    if (isEdit) {
      setCategory(
        find(
          getOptionsFromKeys(tutorialsCategory, t, null, 'category_'),
          categoryOption => categoryOption.id === tutorialsCategory[tutorialItem.category],
        ),
      );
      setFormFields(mapTranslationsToTutorials(tutorialItem, languagesData, findLanguage));
    }
    // eslint-disable-next-line
  }, []);

  const handleAttachment = tutorialItem => {
    return tutorialItem.attachment && tutorialItem.attachment.url ? tutorialItem.attachment.url : '';
  };
  return (
    <div>
      {formFields.map(tutorialItem => {
        return (
          <HelpCenterFormAccordion
            expanded={tutorialItem.titleError || expanded === tutorialItem.tutorialLanguageId}
            onChange={handleChange(tutorialItem.tutorialLanguageId)}
            key={tutorialItem.tutorialLanguageId}
          >
            <HelpCenterFormAccordionSummary expandIcon={<ExpandMoreIcon />}>
              <FormControlLabel
                classes={classes}
                value="end"
                control={
                  <Checkbox checked={tutorialItem.isChecked} icon={<UncheckedIcon />} checkedIcon={<CheckedIcon />} />
                }
                label={getLanguageTranslationFromKey(tutorialItem.tutorialLanguageId, t)}
                labelPlacement="end"
                disabled
              />
            </HelpCenterFormAccordionSummary>
            <AccordionDetails style={{ display: 'block', padding: '0px' }}>
              <div className="help-center-modal-form-container">
                <div className="horizontal-row">
                  <div className="label-select-container">
                    <p className="label">{t('contents_helpCenter_tutorial_title_label')}</p>
                    <input
                      value={tutorialItem.title}
                      name={`title-${tutorialItem.tutorialLanguageId}`}
                      onChange={e => handleFormChange(e)}
                      style={{ width: 220, marginRight: 20 }}
                      placeholder={t('contents_helpCenter_tutorial_placeholder')}
                    ></input>
                  </div>
                  <div className="label-select-container">
                    <p className="label">{t('contents_helpCenter_tutorial_attach_video_label')}</p>
                    <input
                      value={handleAttachment(tutorialItem)}
                      name={`attachment-${tutorialItem.tutorialLanguageId}`}
                      onChange={e => handleFormChange(e)}
                      style={{ width: 220, marginRight: 20 }}
                      placeholder={t('contents_helpCenter_tutorial_placeholder')}
                    ></input>
                  </div>
                </div>
                <div className="horizontal-row">
                  <div className="label-select-container">
                    <p className="label">{`${t('contents_helpCenter_tutorial_description_label')} (${t(
                      'optional',
                    )})`}</p>
                    <textarea
                      value={tutorialItem.description}
                      name={`description-${tutorialItem.tutorialLanguageId}`}
                      onChange={e => handleFormChange(e)}
                      placeholder={t('contents_helpCenter_tutorial_placeholder')}
                    ></textarea>
                  </div>
                </div>
              </div>
            </AccordionDetails>
          </HelpCenterFormAccordion>
        );
      })}
      <div className="content-manager-modal__options" style={{ marginTop: 0 }}>
        <div className="label-select-container">
          <p className="label">{t('contents_helpCenter_tutorial_category_label')}</p>
          <CustomSelect
            name={'category'}
            options={getOptionsFromKeys(tutorialsCategory, t, null, 'category_')}
            style={{ width: 220, marginRight: 20 }}
            handleSelected={category => {
              setCategory(category);
              setSelectErrors({ ...selectErrors, categoryError: false });
            }}
            selectedOption={category}
            translatedFromBackend
            error={selectErrors.categoryError}
          />
          {getSelectErrorMessage(selectErrors, t, 'category')}
        </div>
      </div>
      <div className="content-manager-modal__button-group">
        <button
          className={`content-manager-modal__button ${getDisabledClass(formFields)}`}
          onClick={() => handleSubmitTutorialItem()}
        >
          {t(getButtonTitle(isEdit))}
        </button>
        <button
          style={{ width: '195px' }}
          className="content-manager-modal__button--secondary"
          onClick={() => closeModal()}
        >
          {t('contents_helpCenter_tutorial_close_button_label')}
        </button>
      </div>
    </div>
  );
}
