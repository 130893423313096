import React, { useEffect, useState } from 'react';
import styles from './LastFiveYearsDisbProjApp.module.scss';
import { animateScroll } from 'react-scroll';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Indicator from '../../../models/Indicator';
import { isMobile } from '../../../services/booleanUtil';
import { BarChart } from '../../Portfolio/CardIndicator/Cards/BarChart/BarChart';
import { getProjectsByDate } from '../../../redux/actions/indicatorProjects';
import Table from './Table';
import { DISBURSEMENT_PROJECTION_LAST_5_YEARS_SELECTED_YEAR } from '../../../services/sessionFiltersIndicatorDetailHelper';
import { getNewFilteredIndicators } from '../../../api/indicatorApi';
import { processIndicators } from '../../Portfolio/indicatorHelper';
import SpinningLoader from '../../ProjectDetail/ProjectTabs/Loader/SpinningLoader';
import { IndicatorKeyEnum } from '../../../models/IndicatorKeyEnum';
import CustomCheckbox from '../../Util/CustomCheckbox/CustomCheckbox';

const baseColor = '#538CFF';
const lastYearColor = '#538CFF';
const fontsColor = '#101319';
const gridLinesColor = '#F2F3F7';
const graphFontFamily = 'Rubik';
const lineChartLabelColor = '#5F6B8A';
const chartStepSize = 500000000;

const updateChartColors = (selectedYear: string, labels: string[]) => {
  const colorsList = new Array(labels.length).fill(baseColor);
  colorsList[colorsList.length - 1] = lastYearColor;
  const newColorIndex = labels.indexOf(selectedYear);
  if (newColorIndex > -1) {
    colorsList[newColorIndex] = colorsList[newColorIndex].replace('1)', '0.4)');
  }
  return colorsList;
};
const ifIntegerFixedValue = value => (value % 1 !== 0 ? value.toFixed(1) : value);
const basicDataLabel = {
  formatter: (value, ctx) => {
    return value === null || value === undefined
      ? ''
      : value < 1000000
      ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      : `${ifIntegerFixedValue(value / 1000000)} MM`;
  },
  anchor: 'start',
  clamp: false,
  align: 'end',
  offset: 310,
  padding: 13,
  backgroundColor: 'transparent',
  color: context => {
    const { dataset, dataIndex } = context;
    return dataIndex < dataset.data.length - 1 ? baseColor : lastYearColor;
  },
  clip: false,
  font: {
    size: isMobile() ? 8 : 16,
    family: graphFontFamily,
    weight: 'bold',
  },
};

export const variableOffset = (dataDatasets, context) => {
  let barchartDataset: any = null;
  if (dataDatasets && dataDatasets.find(i => !i.type)) {
    barchartDataset = dataDatasets.find(i => !i.type);
  }
  if (
    dataDatasets &&
    barchartDataset &&
    barchartDataset.data &&
    context &&
    context.dataIndex !== undefined &&
    barchartDataset.data[context.dataIndex] !== undefined &&
    context.dataset &&
    context.dataset.data &&
    context.dataset.data[context.dataIndex] !== undefined
  ) {
    const barchartValue = barchartDataset.data[context.dataIndex];
    const lineValue = context.dataset.data[context.dataIndex];
    if (barchartValue >= lineValue) {
      return barchartValue / lineValue < 1.1 ? -20 : -10;
    } else {
      return lineValue / barchartValue < 1.1 ? 0 : -10;
    }
  }
  return -10;
};

export const dataForDetail = data => {
  return data
    ? {
        ...data,
        datasets: data.datasets.map(i => {
          if (!i.type) {
            // Bar Chart
            i.barThickness = isMobile() ? 30 : 80;
            i.datalabels = basicDataLabel;
            return i;
          } else {
            // Line Chart
            i.borderWidth = 5;
            i.datalabels = {
              ...basicDataLabel,
              color: lineChartLabelColor,
              anchor: 'end',
              align: 'end',
              offset(context) {
                return variableOffset(data.datasets, context);
              },
            };
            return i;
          }
        }),
      }
    : null;
};
export const optionsforDetail = (t: Function, maxY?: undefined | number) => ({
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        display: true,
        gridLines: {
          display: true,
          borderDash: [2, 2],
          color: gridLinesColor,
          drawBorder: false,
          zeroLineColor: gridLinesColor,
        },
        ticks: {
          max: maxY,
          stepSize: chartStepSize,
          beginAtZero: true,
          fontSize: 12,
          fontFamily: graphFontFamily,
          fontColor: fontsColor,
          callback: (value, ctx) => {
            return `${Math.round(value / 1000000)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;
          },
        },
        scaleLabel: {
          display: true,
          labelString: `${t('millions_word')} ${t('dolar_sign')}`,
          fontSize: 10,
          fontFamily: graphFontFamily,
          fontColor: fontsColor,
        },
      },
    ],
    xAxes: [
      {
        display: true,
        gridLines: {
          display: false,
        },
        ticks: {
          fontSize: 16,
          fontColor: '#101319',
        },
      },
    ],
  },
  cornerRadius: 3,
  tooltips: {
    enabled: false,
    backgroundColor: '#101319',
    bodyFontColor: '#ffffff',
    bodyFontFamily: 'Rubik',
    titleFontColor: '#ffffff',
    titleFontSize: 14,
    bodyFontSize: 14,
    callbacks: {
      label(item) {
        return Math.round(item.value)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      },
      title: item => {
        return item[0] && item[0].datasetIndex === 1 ? t('original_projection') : t('amount_disbursed');
      },
    },
  },
});

const isInPortfolioDetail = (currentIndicator: Indicator, location, projectTypeFilterOnDetail) =>
  currentIndicator &&
  currentIndicator.indicatorKey !== 'default_indicator' &&
  location.pathname === '/portfoliodetail' &&
  projectTypeFilterOnDetail !== null;

const updateMaxIfExceedsGraph = (data, setMaxY: Function) => {
  let barchartData: any = null;
  if (data && data.datasets && data.datasets.find(i => !i.type)) {
    barchartData = data.datasets.find(i => !i.type);
  }
  if (barchartData && barchartData.data && barchartData.data.length) {
    const maxValue = Math.max(...barchartData.data);
    const prevMaxChartHeight = maxValue - (maxValue % chartStepSize) + chartStepSize;
    if (maxValue / prevMaxChartHeight > 0.95) {
      setMaxY(prevMaxChartHeight + chartStepSize);
    } else {
      setMaxY(undefined);
    }
  }
};

const hasSessionStorageVariableAndData = (item: string | null = null, data) => {
  return item && data;
};

const initialYearValue = () => sessionStorage.getItem(DISBURSEMENT_PROJECTION_LAST_5_YEARS_SELECTED_YEAR) || '';

const chartDataColorsUpdated = (chartData: any, newBackgroundColors: string[]) => {
  return {
    ...chartData,
    datasets: chartData.datasets.map(i => {
      if (i.label === 'bar') {
        i.backgroundColor = newBackgroundColors;
        return i;
      } else {
        return i;
      }
    }),
  };
};

const LastFiveYearsDisbProjApp = () => {
  const currentIndicator = useSelector<any, Indicator>(state => state.currentIndicator);
  const { filterIndicators } = useSelector<any, any>(state => state);
  const projectTypeFilterOnDetail = useSelector<any, any>(
    state => state.indicatorDetailFilter.projectTypeFilterOnDetail,
  );

  const [chartData, setChartData] = useState<any>(null);
  const [selectedYear, setSelectedYear] = useState<string>(initialYearValue());
  const { chartContainer, noResultsText } = styles;
  const dispatch = useDispatch();
  const [pblCheked, setPblCheked] = useState<boolean>(false);
  const [pblParam, setPblParam] = useState<any>(null);

  const handleClickBar = (year = '') => {
    setSelectedYear(year);
    setDisplayTable(true);
    sessionStorage.setItem(DISBURSEMENT_PROJECTION_LAST_5_YEARS_SELECTED_YEAR, year);
    setTimeout(() => {
      animateScroll.scrollToBottom({ smooth: true, duration: 800 });
    }, 50);
  };

  const handleChanges = (isChecked: any) => {
    setPblCheked(isChecked.value);
    isChecked.value ? setPblParam('PBL') : setPblParam(null);
  };

  const { t } = useTranslation();
  const location = useLocation();
  const [displayTable, setDisplayTable] = useState<boolean>(false);
  const [maxY, setMaxY] = useState<undefined | number>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  const getChartData = async () => {
    setLoading(true);
    setDisplayTable(false);
    const sessionSelectedYear = sessionStorage.getItem(DISBURSEMENT_PROJECTION_LAST_5_YEARS_SELECTED_YEAR);
    const options = { indicatorId: currentIndicator.id, parameter: pblParam };
    const currentIndicatorOnDetail = await getNewFilteredIndicators(options);
    const justCurrentIndicatorOnDetail = {
      ...currentIndicatorOnDetail,
      indicatorsData: currentIndicatorOnDetail.indicatorsData.filter(i => i.id === currentIndicator.id),
    };
    const indicatorsProcessed = processIndicators(justCurrentIndicatorOnDetail);
    const data = dataForDetail(indicatorsProcessed[0].data);

    if (hasSessionStorageVariableAndData(sessionSelectedYear, data)) {
      const newData = dataForDetail(data);
      updateChartData(newData, sessionSelectedYear, pblParam);
      setDisplayTable(true);
      setLoading(false);
    } else {
      updateMaxIfExceedsGraph(data, setMaxY);
      setChartData(dataForDetail(data));
      setDisplayTable(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (pblCheked || !pblCheked) {
      if (selectedYear && chartData) {
        getChartData();
        updateChartData(chartData, selectedYear, pblParam);
        // getChartData();
      } else if (chartData) {
        getChartData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pblCheked]);

  useEffect(() => {
    if (isInPortfolioDetail(currentIndicator, location, projectTypeFilterOnDetail)) {
      getChartData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectTypeFilterOnDetail]);

  useEffect(() => {
    if (selectedYear && chartData) {
      updateChartData(chartData, selectedYear, pblParam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear, filterIndicators, pblCheked]);

  const updateChartData = (chartData, selectedYear, pblParam) => {
    dispatch(getProjectsByDate(6, parseInt(selectedYear), currentIndicator.id, pblParam));
    const newBackgroundColors = updateChartColors(selectedYear, chartData.labels);
    updateMaxIfExceedsGraph(chartData, setMaxY);
    setChartData(chartDataColorsUpdated(chartData, newBackgroundColors));
  };

  return (
    <>
      {currentIndicator.indicatorKey === IndicatorKeyEnum.DISBURSEMENT_PROJECTION_LAST_5_YEARS ? (
        <>
          <div>
            <CustomCheckbox
              labelText={t('EXCLUDE_PBL')}
              handleOnClick={value => handleChanges({ value })}
              isChecked={pblCheked}
            />
          </div>
        </>
      ) : null}

      <section className={chartContainer}>
        {!loading ? (
          chartData ? (
            <>
              <ChartLabels />
              <BarChart
                type={currentIndicator.type}
                data={chartData}
                showSubLabels={!isMobile()}
                listPage={true}
                datasetClick={e => handleClickBar(e.label)}
                indicatorKey={currentIndicator.indicatorKey}
                chartOptions={optionsforDetail(t, maxY)}
                chartDimensions={{
                  mobile: { width: 300, height: 200 },
                  desktop: { width: 800, height: 400 },
                }}
              />
            </>
          ) : (
            <h2 className={noResultsText}>{t('no_results')}</h2>
          )
        ) : (
          <SpinningLoader customStyle={{ marginBottom: 85 }} />
        )}
      </section>
      {displayTable && <Table />}
    </>
  );
};

export const ChartLabels = () => {
  const { t } = useTranslation();
  const { labelsContainer, labelLine, projIcon, lineText, disbIcon } = styles;
  return (
    <div className={labelsContainer}>
      <div className={labelLine}>
        <div className={projIcon}></div> <p className={lineText}>{t('original_projection')}</p>
      </div>
      <div className={labelLine}>
        <div className={disbIcon}></div> <p className={lineText}>{t('amount_disbursed')}</p>
      </div>
    </div>
  );
};

export default LastFiveYearsDisbProjApp;
