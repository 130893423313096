import React, { useEffect, useState } from 'react';
import { isMobile } from '../../../services/booleanUtil';
import { sortNumbers } from '../../../services/sortsUtil';
import styles from './DisbursementProjectionEvolution.module.scss';
import { DisbursementsProjectionsEvolution } from '../../../models/DisbursementsProjectionsEvolution';
import { CardTypesEnum } from '../../../models/CardTypesEnum';
import { BarChart } from '../../Portfolio/CardIndicator/Cards/BarChart/BarChart';
import { IndicatorKeyEnum } from '../../../models/IndicatorKeyEnum';
import { dataForDetail } from '../../PortfolioDetail/LastFiveYearsDisbProjApp/LastFiveYearsDisbProjApp';
import { lastTenEvents } from '../PerformancePMRCollapsed/PerformancePMRCollapsed';
import { useTranslation } from 'react-i18next';

const fontsColor = '#424D64';
const gridLinesColor = '#A0ABC1';
const graphFontFamily = 'Source Sans Pro';

const {
  disbursementProjectionEvolutionChartContainer,
  disbursementProjectionEvolutionChartContainerHeader,
  disbursementTitle,
  labelsContainer,
  labelLine,
  projIcon,
  lineText,
  disbIcon,
  prevNextContainer,
  prevButton,
  prevButtonDisabled,
  nextButtonDisabled,
  nextButton,
} = styles;

type Props = {
  data: DisbursementsProjectionsEvolution[];
  typeHeader: boolean;
  forReport?: boolean;
};

const chartGraphData = (data, t) => {
  const labels = data.map(item => item.year);
  const dataBars = data.map(item => item.disbursed);
  const dataLines = data.map(item => item.projected);

  const barsBackgroundColors = dataBars.map((bar, index, list) => {
    return '#B1CBFF';
  });

  const bars = {
    data: dataBars,
    backgroundColor: barsBackgroundColors,
    borderWidth: 0,
    barLength: 2,
    order: 2,
    label: `${t('disbursed')}`,
  };

  const line = {
    data: dataLines,
    borderColor: '#A0ABC1',
    backgroundColor: 'transparent',
    borderWidth: 3,
    type: 'line',
    pointRadius: 0,
    lineTension: 0,
    order: 1,
    label: 'Projected',
  };
  return { labels, bars, line };
};
const dataMargin = maxValue => (90 * maxValue) / 100;
const processDisbursementData = (data, typeHeader, setPrevDataChart, t) => {
  const sortData = [...data].sort(sortNumbers('year', 'asc'));
  setPrevDataChart(lastTenEvents(sortData));
  const { labels, bars, line } = chartGraphData(sortData, t);
  const datasets = [bars, line];
  const dataChart: any = {
    type: CardTypesEnum.MIXED_VERTICAL_BAR_LINE,
    title: 'disbursement_projection_evolution',
    subtitle: '',
    detailTitle: 'disbursement_projection_last_5_years_detail_title',
    detailChartTitle: '',
    detailChartFoot: '',
    data: {
      labels,
      datasets,
      subLabels: [],
      cornerRadius: 3,
      gridLines: true,
    },
  };
  const MaxDataBar = Math.max(...sortData.map(i => i.disbursed));
  const maxBarValue = Math.round(dataMargin(MaxDataBar) + MaxDataBar);
  const MaxDataLine = Math.max(...sortData.map(i => i.projected));
  const maxLineValue = Math.round(dataMargin(MaxDataLine) + MaxDataLine);
  const dataChartResult = typeHeader ? dataChart.data : dataForDetail(dataChart.data);
  return { dataChart: dataChartResult, maxBar: maxBarValue, maxLineValue };
};

const maxTicks = (maxLineValue, maxBarValue) => {
  return maxLineValue >= maxBarValue ? maxLineValue : maxBarValue;
};

const DisbursementProjectionEvolution = ({ data, typeHeader, forReport }: Props) => {
  const { t } = useTranslation();
  const [disbursementDataChart, setDisbursementData] = useState<any | null>({});
  const [prevDataChart, setPrevDataChart] = useState<any | null>({});
  const [firstView, setFirstView] = useState<boolean>(true);
  useEffect(() => {
    setDisbursementData(processDisbursementData(data, typeHeader, setPrevDataChart, t));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const renderPrevDataChart = () => {
    if (firstView && prevDataChart) {
      setDisbursementData(processDisbursementData(prevDataChart, typeHeader, setPrevDataChart, t));
      setFirstView(false);
    }
  };
  const renderNextDataChart = () => {
    if (!firstView) {
      setDisbursementData(processDisbursementData(data, typeHeader, setPrevDataChart, t));
      setFirstView(true);
    }
  };
  return (
    <div className={containerStyles(typeHeader)} style={forReport ? { marginLeft: 0, marginTop: 40 } : {}}>
      <div style={{ width: '100%' }}>
        <p className={disbursementTitle}>{t('disbursements_and_projections_title')}</p>
      </div>
      {!typeHeader && (
        <div>
          {forReport ? null : (
            <div className={prevNextContainer}>
              <div className={prevButtonStyle(firstView, prevDataChart)} onClick={() => renderPrevDataChart()}></div>
              <div className={nextButtonStyle(firstView)} onClick={() => renderNextDataChart()}></div>
            </div>
          )}
        </div>
      )}
      <BarChart
        type={CardTypesEnum.MIXED_VERTICAL_BAR_LINE}
        data={disbursementDataChart.dataChart}
        showSubLabels={!isMobile()}
        listPage={true}
        indicatorKey={IndicatorKeyEnum.DISBURSEMENT_PROJECTION_LAST_5_YEARS}
        chartOptions={optionsforDetail(t, disbursementDataChart.maxBar, disbursementDataChart.maxLineValue, typeHeader)}
        chartDimensions={typeHeaderDimensions(typeHeader, forReport)}
      />
      {!typeHeader && (
        <div className={labelsContainer}>
          <div className={labelLine}>
            <div className={projIcon}></div> <p className={lineText}>{t('original_projection')}</p>
          </div>
          <div className={labelLine}>
            <div className={disbIcon}></div> <p className={lineText}>{t('amount_disbursed')}</p>
          </div>
        </div>
      )}
    </div>
  );
};
const prevButtonStyle = (firstView, prevDataChart) => (firstView && prevDataChart ? prevButton : prevButtonDisabled);
const nextButtonStyle = firstView => (!firstView ? nextButton : nextButtonDisabled);

const containerStyles = typeHeader =>
  !typeHeader ? disbursementProjectionEvolutionChartContainer : disbursementProjectionEvolutionChartContainerHeader;
const typeHeaderDimensions = (typeHeader, forReport = false) =>
  typeHeader
    ? {
        mobile: { width: 450, height: 100 },
        desktop: { width: 1200, height: 80 },
      }
    : {
        mobile: { width: 250, height: 200 },
        desktop: forReport ? { width: 600, height: 225 } : { width: 1000, height: 380 },
      };

const optionsforDetail = (t: Function, maxBarValue: number, maxLineValue: number, typeHeader) =>
  typeHeader
    ? {
        plugins: {
          datalabels: {
            display: false,
          },
        },
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              display: false,
              gridLines: {
                display: true,
                borderDash: [2, 2],
                color: gridLinesColor,
                drawBorder: false,
                zeroLineColor: gridLinesColor,
              },
              ticks: {
                max: maxTicks(maxLineValue, maxBarValue),
                beginAtZero: true,
                fontSize: 10,
                fontFamily: graphFontFamily,
                fontColor: fontsColor,
                callback: (value, ctx) => {
                  return `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;
                },
              },
            },
          ],
          xAxes: [
            {
              display: true,
              gridLines: {
                display: false,
              },
              ticks: {
                stepSize: 5,
                fontSize: 12,
              },
            },
          ],
        },
      }
    : {
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              display: true,
              gridLines: {
                display: true,
                borderDash: [10, 5],
                color: gridLinesColor,
                drawBorder: false,
                zeroLineColor: gridLinesColor,
              },
              ticks: {
                max: maxTicks(maxLineValue, maxBarValue),
                beginAtZero: true,
                fontSize: isMobile() ? 8 : 12,
                fontFamily: graphFontFamily,
                fontColor: fontsColor,
                callback: (value, ctx) => {
                  return `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;
                },
              },
              scaleLabel: {
                display: isMobile() ? false : true,
                labelString: `${t('millions_word')} ${t('dolar_sign')}`,
                fontSize: isMobile() ? 5 : 12,
                fontFamily: graphFontFamily,
                fontColor: fontsColor,
              },
            },
          ],
          xAxes: [
            {
              display: true,
              barThickness: isMobile() ? 10 : 60,
              gridLines: {
                display: false,
              },
              ticks: {
                stepSize: 5,
                fontSize: isMobile() ? 8 : 14,
              },
            },
          ],
        },
        cornerRadius: 3,
        tooltips: {
          enabled: true,
          position: 'nearest',
          intersect: false,
          callbacks: {
            label(item) {
              return Math.round(item.value)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            },
            title: item => {
              return item[0] && item[0].datasetIndex === 1 ? t('original_projection') : t('amount_disbursed');
            },
          },
          titleFontSize: 12,
          bodyFontSize: 12,
        },
      };

export default DisbursementProjectionEvolution;
