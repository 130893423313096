import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PlusButtom } from '../../../assets/icons/u_plus.svg';
import styles from './AddMenuTabs.module.scss';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const BlackTooltip = withStyles({
  tooltip: {
    //   color: '$ci-color-greyscale-90',
    backgroundColor: '#101319',
    fontSize: '16px',
    fontFamily: 'Rubik',
    fontWeight: '400',
    lineHeight: '20px',
    padding: '8px 12px',
    borderRadius: '8px',
  },
  arrow: {
    fontSize: 20,
    color: '#4A4A4A',
    '&::before': {
      backgroundColor: '#101319',
    },
  },
})(Tooltip);

type Props = {
  disable: boolean;
  handleButtonClick: () => void;
};

export const AddMenuTab = ({ disable, handleButtonClick }: Props) => {
  const { addMenuTabContainer, enabledButton, disabledButton } = styles;
  const { t } = useTranslation();

  return (
    <div className={addMenuTabContainer}>
      {disable ? (
        <div className={disabledButton}>
          <BlackTooltip
            title={t('max_4_custom_tabs').toString()}
            placement="bottom"
            enterDelay={500}
            leaveDelay={200}
            arrow
          >
            <PlusButtom />
          </BlackTooltip>
        </div>
      ) : (
        <button className={enabledButton} onClick={handleButtonClick} tabIndex={30}>
          <BlackTooltip
            title={t('create_your_custom_tab').toString()}
            placement="bottom"
            enterDelay={500}
            leaveDelay={200}
            arrow
          >
            <PlusButtom />
          </BlackTooltip>
        </button>
      )}
    </div>
  );
};
