import React from 'react';
import { CustomizableReportType } from '../../../../models/CustomizableReportType';
import FilterIndicators from '../../../../models/FilterIndicators';

const initialState = {
  currentStep: 0,
  reportType: CustomizableReportType.Predefined,
  reportFilters: {} as FilterIndicators,
  indicators: [] as any[],
};

type StateT = typeof initialState;
type StateGetSetT = [StateT, React.Dispatch<React.SetStateAction<StateT>>];

const CustomizableReportsContext = React.createContext<StateGetSetT | undefined>(undefined);

type CustomizableReportsContextProviderProps = {
  children: React.ReactNode;
};

const CustomizableReportsContextProvider = ({ children }: CustomizableReportsContextProviderProps) => {
  const contextGetSet = React.useState(initialState);
  return <CustomizableReportsContext.Provider value={contextGetSet}>{children}</CustomizableReportsContext.Provider>;
};

// Custom hook to retrieve and set context state.
type SetPartialStateT = (newVals: Partial<StateT>) => void;
type UseCustomizableReportsContextT = [StateT, SetPartialStateT];

const useCustomizableReportsContext = (): UseCustomizableReportsContextT => {
  const [state, setState] = React.useContext(CustomizableReportsContext) as StateGetSetT;

  const setPartialState = (newVals: Partial<StateT>) => {
    setState({ ...state, ...newVals });
  };
  return [state, setPartialState];
};

export { CustomizableReportsContextProvider, useCustomizableReportsContext };
