import React, { useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { AccordanceState } from '../../../models/AccordanceState';
import './ReviewAccordancesMobile.scss';
import { AccordanceCardMobile } from '../AccordanceCardMobile/AccordanceCardMobile';
import { AccordanceModalsFlow } from '../../Util/Modals/AccordanceModalsFlow/AccordanceModalsFlow';

type Props = {
  accordances: any;
  refreshData: any;
};
export const ReviewAccordancesMobile = ({ accordances, refreshData }: Props) => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedAccordance, setSelectedAccordance] = useState({});

  const { t } = useTranslation();

  const accodanceStateSumary: any[] = [
    { id: AccordanceState.PENDING, title: t('pending_accordance') },
    { id: AccordanceState.VALID, title: t('active_accordance') },
    { id: AccordanceState.FULLFILLED, title: t('accomplished_accordance') },
    { id: AccordanceState.CANCELLED, title: t('cancelled_accordance') },
  ];

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getAccordancesByState = state => {
    const accordancesByState = accordances.filter(accordance => accordance.accordanceState === state);
    return accordancesByState.map(accordance => (
      <AccordanceCardMobile
        key={accordance.id}
        accordance={accordance}
        selectedCard={id => handleSelectedAccordanceClick(id)}
      />
    ));
  };

  const handleDisableAccordion = state => {
    const accordancesByState = accordances.filter(accordance => accordance.accordanceState === state);
    return accordancesByState.length;
  };

  const handleSelectedAccordanceClick = accordanceIdSelected => {
    setSelectedAccordance(accordances.find(accordance => accordance.id === accordanceIdSelected));
    setShowModal(true);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  return (
    <div className={'review-accordances-mobile-container'}>
      {accodanceStateSumary.map(state => (
        <Accordion
          key={state.id}
          expanded={expanded === state.id}
          onChange={handleChange(state.id)}
          elevation={0}
          disabled={handleDisableAccordion(state.id) === 0 ? true : false}
        >
          <AccordionSummary className="summary-root" expandIcon={<ExpandMoreIcon />} aria-controls="content">
            <Typography className="heading">{state.title}</Typography>
          </AccordionSummary>
          <AccordionDetails className="panel-root">{getAccordancesByState(state.id)}</AccordionDetails>
        </Accordion>
      ))}
      <AccordanceModalsFlow
        accordance={selectedAccordance}
        accordanceDetailSetOpen={showModal}
        accordanceDetailIsClosed={hideModal}
        refreshData={refreshData}
      />
    </div>
  );
};
