import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useFormMemoryHelpContext } from '../../../context/MemoryHelpFormContext';

export const AgreementsReached = () => {

  const { t } = useTranslation();
  const history = useHistory();

  const { currentReview } = useFormMemoryHelpContext();

  const handlePath = () => history.push(`/reviewaccordances?id=${currentReview}`)

  return (
    <>
      <h3 className='title'>4. {t('memory_help.tabs.4')}</h3>

      <div className='bg-grey'>
        <span className='title-box'>{t('memory_help.container_tabs.4.info_accordance.title')}</span>
        <p>{t('memory_help.container_tabs.4.info_accordance.description')}</p>
      </div>


      <div className='options'>
        <div className='option-item'>
        </div>
        <div className='option-item'>
        </div>
      </div>
    </>);
};

export default AgreementsReached;
