export enum SortParameterAccordance {
  ProjectCode = 1,
  ProjectName,
  CriticalIssueName,
  AccordanceAbout,
  Description,
  ExpirationDate,
  Responsabile,
  AccordanceState,
}
