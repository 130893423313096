import { FundingSourceEnum } from '../../../../../models/FundingSourceEnum';
import { thousandsSeparator } from '../../../../../services/stringUtil';
import { isMobile } from '../../../../../services/booleanUtil';
import { getFilteredValue } from '../../../../Portfolio/IndicatorsHelpers/util';

export interface IIndicatorObject {
  labels: string[];
  datasets: any[];
  totals: any[];
}

export const emptyIndicatorObject: IIndicatorObject = {
  labels: [],
  datasets: [],
  totals: [],
};

export const customOptions = {
  plugins: {
    datalabels: { display: false },
  },
  legend: {
    display: false,
  },
  tooltips: {
    enabled: true,
    displayColors: false,
    backgroundColor: '#101319',
    titleFontSize: 14, 
    bodyFontSize: 14,
    callbacks: {
      title: (tooltipItem, data) => {
        return tooltipItem[0].xLabel;
      },

      label(tooltipItem, data) {
        return `${thousandsSeparator(tooltipItem.value)} USD`;
      },
    },
  },
  scales: {
    xAxes: [
      {
        stacked: false,
        ticks: {
          beginAtZero: true,
          fontSize: 14,
          fontStyle: 400,
        },
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        stacked: false,
        ticks: {
          beginAtZero: true,
          display: true,
          fontSize: 9,
        },
        gridLines: {
          display: true,
        },
      },
    ],
  },
  cornerRadius: 9,
};

const fundingSourceOrder = [
  FundingSourceEnum[1],
  FundingSourceEnum[2],
  FundingSourceEnum[4],
  FundingSourceEnum[3],
  FundingSourceEnum[5],
];
const setDatasetByState = (fundigSourceOrder, data, state) => {
  return fundigSourceOrder.map(fs => getFilteredValue(data, state, fs));
};
export const getIndicatorDataChart = (data): IIndicatorObject => {
  return {
    labels: fundingSourceOrder,
    datasets: [
      {
        label: 'spent',
        data: setDatasetByState(fundingSourceOrder, data, 'EXPENDITURES_A'),
        backgroundColor: '#FDAC41',
        borderWidth: 2,
        barThickness: isMobile() ? 11 : 13,
        actualMonth: 1,
        borderColor: '#fff',
      },
      {
        label: 'commited',
        data: setDatasetByState(fundingSourceOrder, data, 'COMMITMENT_B'),
        backgroundColor: '#00CFDD',
        borderWidth: 2,
        barThickness: isMobile() ? 11 : 13,
        borderColor: '#fff',
      },
      {
        label: 'available',
        data: setDatasetByState(fundingSourceOrder, data, 'BALANCE_C_MINUS_B_PLUS_A'),
        backgroundColor: '#5A8DEE',
        borderWidth: 2,
        barThickness: isMobile() ? 11 : 13,
        borderColor: '#fff',
      },
    ],
    totals: [
      { value: getFilteredValue(data, 'EXPENDITURES_A', 'NONE'), label: 'total spent' },
      {
        value: getFilteredValue(data, 'COMMITMENT_B', 'NONE'),
        label: 'total committed_supervision',
      },
      { value: getFilteredValue(data, 'BALANCE_C_MINUS_B_PLUS_A', 'NONE'), label: 'total available' },
    ],
  };
};
