import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAccordancesFromProject, getSupervisionPlansFromProject } from '../../../api/earlyWarningApi';
import {
  DEFAULT_AND_EMPTY_ACCORDANCE_TABLE_DATA,
  DEFAULT_AND_EMPTY_SUPERVISION_PLAN_TABLE_DATA,
  TableRowDataAccordances,
  TableRowDataSupervisionPlan,
  TableStateEnum,
  WithColorEnum,
} from '../../../models/EarlyWarningsSection';
import {
  DEFAULT_LINKED_MODAL_INFORMATION,
  LinkedActionsModal,
  OperativeAlertsEnum,
} from '../../../models/LinkedActions';
import { dateIsAfterToday, handleEarlyWarningTimeLapseText } from '../../PortfolioDetail/EarlyWarningsFilters/Util';
import { LinkedActions } from '../../Util/Modals/LinkedActions/LinkedActions';
import {
  handleDisbursementExpired,
  handleElegibleUnisbursementAlert,
  handleEsgSafeguardText,
  handleLast3Pmr,
  handlePartialCancellations,
  handlePendingSignatureAlert,
  handleSafeguardPerformance,
} from '../ProjectsWithEarlyWarningsTable/IconRenderByColumn';
import { AddColumn } from './AddColumn/AddColumn';

type AddColumnsProps = {
  project: any;
};
export const AddColumns = ({ project }: AddColumnsProps) => {
  const { t } = useTranslation();
  const {
    id: projectId,
    code,
    title,
    pendingSignature: pendingSignatureValue,
    pendingSignatureWithColor: pendingSignatureIcon,
    eligibleUnDisbursement: elegibleUndisbursementValue,
    eligibleUnDisbursementWithColor: elegibleUndisbursementIcon,
    pmr: pmrValue,
    pmrWithColor: pmrIcon,
    cancelled: withPartialCancellationsValue,
    cancelledWithColor: withPartialCancellationsIcon,
    expired: disbursementExpiredValue,
    expiredWithColor: disbursementExpiredIcon,
    esgSafeguard: safeguardValue,
    esgSafeguardWithColor: safeguardIcon,
  } = project;

  const pendingSignatureIconValue = handlePendingSignatureAlert(pendingSignatureIcon, pendingSignatureValue);
  const pendingSignatureTextValue = handleGreyLabelText(
    pendingSignatureIcon,
    handleGreenLabelTextOnPendingSignature(
      pendingSignatureValue,
      handleEarlyWarningTimeLapseText(new Date(), new Date(pendingSignatureValue), t),
    ),
  );
  const elegibleUndisbursementIconValue = handleElegibleUnisbursementAlert(
    elegibleUndisbursementIcon,
    elegibleUndisbursementValue,
  );
  const elegibleUndisbursementTextValue = handleGreyLabelText(
    elegibleUndisbursementIcon,
    handleEarlyWarningTimeLapseText(new Date(), new Date(elegibleUndisbursementValue), t),
  );
  const pmrIconValue = handleLast3Pmr(pmrIcon, pmrValue);
  const pmrTextValue = pmrValue;
  const esgSafeguardIconValue = handleSafeguardPerformance(safeguardIcon, safeguardValue);
  const esgSafeguardTextValue = handleEsgSafeguardText(safeguardValue, t);
  const partialCancellationsIconValue = handlePartialCancellations(
    withPartialCancellationsIcon,
    withPartialCancellationsValue,
  );
  const partialCancellationsTextValue = withPartialCancellationsValue;

  const disbursementExpiredIconValue = handleDisbursementExpired(disbursementExpiredIcon, disbursementExpiredValue);
  const disbursementExpiredTextValue = handleGreyLabelText(
    disbursementExpiredIcon,
    handleEarlyWarningTimeLapseText(new Date(), new Date(disbursementExpiredValue), t),
  );

  const [accordances, setAccordances] = useState<TableRowDataAccordances[]>([DEFAULT_AND_EMPTY_ACCORDANCE_TABLE_DATA]);
  const [supervisionPlans, setSupervisionPlan] = useState<TableRowDataSupervisionPlan[]>([
    DEFAULT_AND_EMPTY_SUPERVISION_PLAN_TABLE_DATA,
  ]);
  const [accordanceTableState, setAccordanceTableState] = useState<TableStateEnum>(TableStateEnum.LOADING);
  const [supervisionPlanTableState, setSupervisionPlanTableState] = useState<TableStateEnum>(TableStateEnum.LOADING);
  const [openModal, setOpenModal] = useState(false);
  const [linkedActionInformation, setLinkedActionInformation] = useState<LinkedActionsModal>(
    DEFAULT_LINKED_MODAL_INFORMATION,
  );

  const getAccordances = async (linkedActions: LinkedActionsModal) => {
    const response = await getAccordancesFromProject(linkedActions.projectCode, linkedActions.alertDetail.alertName);
    setAccordanceTableState(response && response.length ? TableStateEnum.READY : TableStateEnum.NO_RESULTS);
    setAccordances(response);
  };

  const getSupervisionPlans = async (linkedActions: LinkedActionsModal) => {
    const response = await getSupervisionPlansFromProject(
      linkedActions.projectCode,
      linkedActions.alertDetail.alertName,
    );

    setSupervisionPlanTableState(response && response.length ? TableStateEnum.READY : TableStateEnum.NO_RESULTS);
    setSupervisionPlan(response);
  };

  const handleModal = (linkedActions: LinkedActionsModal) => {
    getAccordances(linkedActions);
    getSupervisionPlans(linkedActions);
    setLinkedActionInformation(linkedActions);
    setOpenModal(true);
  };

  const linkedActionsBase = getLinkedActionsBase(projectId, code, title);
  const handleCloseModal = () => {
    setOpenModal(false);
    setAccordanceTableState(TableStateEnum.LOADING);
    setSupervisionPlanTableState(TableStateEnum.LOADING);
  };
  return (
    <>
      <AddColumn
        handleModal={handleModal}
        linkedActionsBase={linkedActionsBase}
        text={pendingSignatureTextValue}
        icon={pendingSignatureIconValue}
        alertName={OperativeAlertsEnum.PendingSignature}
      />
      <AddColumn
        handleModal={handleModal}
        linkedActionsBase={linkedActionsBase}
        text={elegibleUndisbursementTextValue}
        icon={elegibleUndisbursementIconValue}
        alertName={OperativeAlertsEnum.EligibleUnDisbursement}
      />
      <AddColumn
        handleModal={handleModal}
        linkedActionsBase={linkedActionsBase}
        text={pmrTextValue}
        icon={pmrIconValue}
        alertName={OperativeAlertsEnum.LastThreePMR}
        pmrFormat={true}
      />
      <AddColumn
        handleModal={handleModal}
        linkedActionsBase={linkedActionsBase}
        text={esgSafeguardTextValue}
        icon={esgSafeguardIconValue}
        alertName={OperativeAlertsEnum.EsgSafeguard}
        safeguardFormat={true}
      />
      <AddColumn
        handleModal={handleModal}
        linkedActionsBase={linkedActionsBase}
        text={partialCancellationsTextValue}
        icon={partialCancellationsIconValue}
        alertName={OperativeAlertsEnum.CancelledAmount}
        percentageFormat={true}
      />
      <AddColumn
        handleModal={handleModal}
        linkedActionsBase={linkedActionsBase}
        text={disbursementExpiredTextValue}
        icon={disbursementExpiredIconValue}
        alertName={OperativeAlertsEnum.ExpiredProject}
      />

      <LinkedActions
        openModal={openModal}
        closeModal={() => handleCloseModal()}
        linkedActionInformation={linkedActionInformation}
        accordances={accordances}
        accordanceTableState={accordanceTableState}
        supervisionPlanTableState={supervisionPlanTableState}
        supervisionPlans={supervisionPlans}
      />
    </>
  );
};

const handleGreenLabelTextOnPendingSignature = (dateValue: string, callback) => {
  return dateIsAfterToday(new Date(dateValue)) ? '' : callback;
};

const handleGreyLabelText = (icon: string, callback) => {
  return icon === WithColorEnum.NOT_APPLICABLE ? 'N/A' : callback;
};

const getLinkedActionsBase = (projectId, code, title) => {
  return { projectId, projectCode: code, projectName: title };
};
