import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getNewFilteredIndicators } from '../../../api/indicatorApi';
import { useFrontEndBasedSelectedsOptions } from '../../../hooks/useFrontEndBasedSelectedsOptions';
import { MultiLine } from '../../Portfolio/CardIndicator/Cards/MultiLine/MultiLine';
import { processIndicators } from '../../Portfolio/indicatorHelper';
import SpinningLoader from '../../ProjectDetail/ProjectTabs/Loader/SpinningLoader';
import CustomSelect from '../../Util/CustomSelect/CustomSelect';
import styles from './PmrEvolutionLast5Years.module.scss';

const fontsColor = '#727e8c';
const graphFontFamily = 'Source Sans Pro';
const lineChartLabelColor = '#416DBC';

const dataLabel = {
  formatter: (value, ctx) => {
    return `${Math.round(value)} %`;
  },
  anchor: 'end',
  clamp: false,
  align: 'start',
  offset: -20,
  padding: 1,
  backgroundColor: 'transparent',
  color: lineChartLabelColor,
  clip: false,
  font: {
    size: 16,
    family: graphFontFamily,
    weight: 'bold',
  },
};

export const dataForDetail = (data, forReport?) => {
  return {
    ...data,
    datasets: data.datasets.map(i => {
      i.borderWidth = forReport ? 4 : 5;
      i.pointRadius = forReport ? 2 : 3;
      i.datalabels = dataLabel;
      return i;
    }),
  };
};

const handleCanvasLimit = (data, forReport?) => {
  const dataConcat = [...data.datasets[0].data, ...data.datasets[1].data, ...data.datasets[2].data];
  const limitValue = dataConcat.find(f => f >= 90);
  return limitValue ? 100 * 1.1 : 100;
};

export const multiLineOptionsDetail = (data, forReport?) => ({
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        display: true,
        gridLines: {
          display: true,
          drawBorder: false,
          borderDash: [3, 3],
          color: '#F2F3F7',
        },
        ticks: {
          stepSize: 20,
          beginAtZero: true,
          fontSize: 10,
          fontFamily: graphFontFamily,
          fontColor: fontsColor,
          max: handleCanvasLimit(data, forReport),
          callback: (value, ctx) => {
            return `${Math.round(value)}%`;
          },
        },
      },
    ],
    xAxes: [
      {
        offset: true,
        gridLines: {
          display: false,
        },
        ticks: {
          fontSize: 14,
          beginAtZero: false,
        },
      },
    ],
  },
  cornerRadius: 3,
  tooltips: {
    enabled: false,
    backgroundColor: '#101319',
    titleFontSize: 14, 
    bodyFontSize: 14,

  },
  plugins: { datalabels: { display: true } },
  animation: {
    duration: forReport ? 0 : 1000,
  },
});

enum FilterStages {
  'STAGE2',
  'STAGE3',
  'null',
}

enum ChartDataState {
  LOADING,
  NO_RESULT,
}

export const PmrEvolutionLast5Years = () => {
  const { t } = useTranslation();
  const { stageOptions, classificationOptions } = useFrontEndBasedSelectedsOptions();
  const { currentIndicator } = useSelector<any, any>(state => state);
  const [stageSelectedOption, setStageSlectedOption] = useState(stageOptions(t)[2]);
  const [classificationSelectedOption, setClassificationSelectedOption] = useState(classificationOptions(t)[1]);
  const [dataChart, setDataChart] = useState<any>(ChartDataState.LOADING);

  const getData = async (indicatorId, filterStage) => {
    const options = {
      indicatorId,
      parameter: filterStage,
    };
    const currentIndicatorOnDetail = await getNewFilteredIndicators(options);
    const justCurrentIndicatorOnDetail = {
      ...currentIndicatorOnDetail,
      indicatorsData: currentIndicatorOnDetail.indicatorsData.filter(i => i.id === indicatorId),
    };
    if (justCurrentIndicatorOnDetail.indicatorsData[0].metrics.length) {
      const indicatorsProcessed = processIndicators(justCurrentIndicatorOnDetail);
      indicatorsProcessed[0].data ? setDataChart(indicatorsProcessed[0].data) : setDataChart(ChartDataState.NO_RESULT);
    } else {
      setDataChart(ChartDataState.NO_RESULT);
    }
  };

  const {
    container,
    filters,
    bulletsContainer,
    chartContainer,
    chartContentContainer,
    customSelectLabel,
    noResultsText,
  } = styles;

  useEffect(() => {
    getData(currentIndicator.id, FilterStages[stageSelectedOption.id]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stageSelectedOption]);

  const handleSelectSatage = optionSelected => {
    setStageSlectedOption(optionSelected);
  };

  const handleSelectClassification = optionSelected => {
    setClassificationSelectedOption(optionSelected);
  };

  return (
    <div className={container}>
      <div className={filters}>
        <span className={customSelectLabel}>{t('stage')}</span>
        <CustomSelect
          options={stageOptions(t)}
          handleSelected={handleSelectSatage}
          selectedOption={stageSelectedOption}
          style={{ width: 180 }}
          type={'small-dark'}
        />
        <span className={customSelectLabel}>{t('classification')}</span>
        <CustomSelect
          options={classificationOptions(t)}
          handleSelected={handleSelectClassification}
          selectedOption={classificationSelectedOption}
          style={{ width: 100 }}
          type={'small-dark'}
        />
      </div>

      {dataChart === ChartDataState.NO_RESULT ? (
        <h2 className={noResultsText}>{t('no_results')}</h2>
      ) : (
        <div className={chartContentContainer}>
          <div className={bulletsContainer}>
            <BulletsList />
          </div>
          <div className={chartContainer}>
            {dataChart ? (
              <MultiLine
                data={dataForDetail(dataChart[classificationSelectedOption.id])}
                customDimensions={{ desktop: { width: 200, height: 50 }, mobile: { width: 300, height: 190 } }}
                options={multiLineOptionsDetail(dataChart[classificationSelectedOption.id])}
              />
            ) : (
              <SpinningLoader />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export const BulletsList = ({ listStyle = {}, itemStyle = {} }) => {
  const { t } = useTranslation();
  const { alert, problem, satisfactory, bullets } = styles;
  return (
    <div className={bullets}>
      <ul style={listStyle}>
        <li style={itemStyle} className={satisfactory}>
          {t('satisfactory')}
        </li>
        <li style={itemStyle} className={alert}>
          {t('alert')}
        </li>
        <li style={itemStyle} className={problem}>
          {t('problem')}
        </li>
      </ul>
    </div>
  );
};
