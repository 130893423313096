import React, { useEffect, useState } from 'react';
import styles from './LastFiveYearsPortfolioOverview.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Indicator from '../../../models/Indicator';
import { capitalizeFirstLetter } from '../../../services/stringUtil';
import { isMobile } from '../../../services/booleanUtil';
import { BarChart } from '../../Portfolio/CardIndicator/Cards/BarChart/BarChart';
import { getProjectsByDate, resetIndicatorProjects } from '../../../redux/actions/indicatorProjects';
import Table from './Table';
import { PORTFOLIO_OVERVIEW_LAST_5_YEARS_SELECTED_YEAR } from '../../../services/sessionFiltersIndicatorDetailHelper';
import IndicatorDetailFilter from '../../../models/IndicatorDetailFilter';
import { getNewFilteredIndicators } from '../../../api/indicatorApi';
import { processIndicators } from '../../Portfolio/indicatorHelper';
import { animateScroll } from 'react-scroll';
import SpinningLoader from '../../ProjectDetail/ProjectTabs/Loader/SpinningLoader';

const baseColor = 'rgba(0, 207, 221, 1)';
const lastYearColor = 'rgba(253, 172, 65, 1)';
const fontsColor = '#727e8c';
const gridLinesColor = '#F2F3F7';
const graphFontFamily = 'Source Sans Pro';
const amountColor = '#416DBC';
const projectColors = '#727E8C';

const updateChartColors = (selectedYear: string, labels: string[]) => {
  const colorsList = new Array(labels.length).fill(baseColor);
  colorsList[colorsList.length - 1] = lastYearColor;
  const newColorIndex = labels.indexOf(selectedYear);
  if (newColorIndex > -1) {
    colorsList[newColorIndex] = colorsList[newColorIndex].replace('1)', '0.4)');
  }
  return colorsList;
};

const basicDataLabel = t => {
  return {
    labels: {
      amount: {
        formatter: (value, ctx) => {
          return `${Math.round(value)} MM`;
        },
        anchor: 'end',
        clamp: true,
        align: 'start',
        offset: -50,
        padding: 1,
        backgroundColor: 'transparent',
        color: amountColor,
        clip: false,
        font: {
          size: 16,
          family: graphFontFamily,
          weight: 'bold',
        },
      },
      projects: {
        formatter: (value, ctx) => {
          const totalProjects = ctx.dataset.projQtyData[ctx.dataIndex];
          const label = totalProjects > 1 ? t('projects') : t('project');
          return `${totalProjects} ${capitalizeFirstLetter(label)}`;
        },
        anchor: 'end',
        clamp: true,
        align: 'start',
        offset: -25,
        padding: 1,
        backgroundColor: 'transparent',
        color: projectColors,
        clip: false,
        font: {
          size: 14,
          family: graphFontFamily,
        },
      },
    },
  };
};

export const dataForDetail = (data, t) => {
  return {
    ...data,
    datasets: data.datasets.map(i => {
      i.barThickness = isMobile() ? 30 : 80;
      i.datalabels = basicDataLabel(t);
      return i;
    }),
  };
};

export const optionsforDetail = (t: Function, suggestedMax: number) => ({
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        display: true,
        gridLines: {
          display: true,
          borderDash: [2, 2],
          color: gridLinesColor,
          drawBorder: false,
          zeroLineColor: gridLinesColor,
        },
        ticks: {
          suggestedMax,
          beginAtZero: true,
          fontSize: 10,
          fontFamily: graphFontFamily,
          fontColor: fontsColor,
          callback: (value, ctx) => {
            return `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;
          },
        },
        scaleLabel: {
          display: true,
          labelString: `${t('millions_word_disbursement_projection_last_5_years')} ${t('dolar_sign')}`,
          fontSize: 10,
          fontFamily: graphFontFamily,
          fontColor: fontsColor,
        },
      },
    ],
    xAxes: [
      {
        display: true,
        gridLines: {
          display: false,
        },
        ticks: {
          fontColor: projectColors,
          fontSize: 14,
        },
      },
    ],
  },
  cornerRadius: 3,
  tooltips: {
    enabled: false,
  },
});

const handleSetChartData = (chartData, newBackgroundColors) => {
  return {
    ...chartData,
    datasets: chartData.datasets.map(i => {
      if (i.label === 'bar') {
        i.backgroundColor = newBackgroundColors;
        return i;
      } else {
        return i;
      }
    }),
  };
};
const date = new Date().getFullYear();

const LastFiveYearsPortfolioOverview = () => {
  const { t } = useTranslation();
  const { projectTypeFilterOnDetail } = useSelector<any, IndicatorDetailFilter>(state => state.indicatorDetailFilter);
  const currentIndicator = useSelector<any, Indicator>(state => state.currentIndicator);

  const { filterIndicators } = useSelector<any, any>(state => state);

  const [chartData, setChartData] = useState<any>(null);

  const [selectedYear, setSelectedYear] = useState<string>(
    sessionStorage.getItem(PORTFOLIO_OVERVIEW_LAST_5_YEARS_SELECTED_YEAR) || '',
  );
  const [yearFilter, setYearFilter] = useState<boolean>(false);
  const [handleYearOnPage, setHandleYearOnPage] = useState(false);
  const { chartContainer } = styles;
  const dispatch = useDispatch();

  const getBarChartData = async () => {
    const options = { indicatorId: currentIndicator.id };
    const currentIndicatorOnDetail = await getNewFilteredIndicators(options);
    const justCurrentIndicatorOnDetail = {
      ...currentIndicatorOnDetail,
      indicatorsData: currentIndicatorOnDetail.indicatorsData.filter(i => i.id === currentIndicator.id),
    };
    const indicatorsProcessed = processIndicators(justCurrentIndicatorOnDetail);
    const data = dataForDetail(indicatorsProcessed[0].data, t);
    if (handleYearOnPage) {
      setChartData(data);
    } else {
      const newBackgroundColors = updateChartColors(selectedYear, data.labels);
      setChartData(handleSetChartData(data, newBackgroundColors));
    }
  };
  const handleClickBar = (year = '') => {
    setYearFilter(prev => !prev);
    setHandleYearOnPage(true);
    year === selectedYear ? setSelectedYear('2020') : setSelectedYear(year);
    sessionStorage.setItem(PORTFOLIO_OVERVIEW_LAST_5_YEARS_SELECTED_YEAR, year);
    sessionStorage.removeItem('PaginationIndicator');
    setTimeout(() => {
      animateScroll.scrollToBottom({ smooth: true, duration: 800 });
    }, 50);
  };

  useEffect(() => {
    selectedYear && !handleYearOnPage && setHandleYearOnPage(true);
    selectedYear && dispatch(resetIndicatorProjects());
    getBarChartData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectTypeFilterOnDetail]);

  useEffect(() => {
    if (selectedYear && chartData) {
      dispatch(getProjectsByDate(6, parseInt(selectedYear), currentIndicator.id));
      const newBackgroundColors = updateChartColors(selectedYear, chartData.labels);
      setChartData(handleSetChartData(chartData, newBackgroundColors));
    } else if (selectedYear && chartData === null) {
      dispatch(getProjectsByDate(6, parseInt(selectedYear), currentIndicator.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear, filterIndicators]);

  return (
    <>
      <section className={chartContainer}>
        {chartData && projectTypeFilterOnDetail !== null ? (
          <BarChart
            type={currentIndicator.type}
            data={chartData}
            showSubLabels={!isMobile()}
            listPage={true}
            datasetClick={e => handleClickBar(e.label)}
            indicatorKey={currentIndicator.indicatorKey}
            chartOptions={optionsforDetail(t, chartData.suggestedMax)}
            chartDimensions={{
              mobile: { width: 300, height: 200 },
              desktop: { width: 800, height: 400 },
            }}
          />
        ) : (
          <SpinningLoader />
        )}
      </section>
      {selectedYear !== '' && <Table yearFilter={yearFilter} />}
    </>
  );
};

export default LastFiveYearsPortfolioOverview;
