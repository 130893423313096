import React from 'react';
import AccordanceTrello from './AccordanceTrello';
import { NewAccordanceContextProvider } from '../../../../Util/Modals/NewAccordance/context/NewAccordanceContext';

const AccordanceTrelloWithContext = () => {
  return (
    <NewAccordanceContextProvider>
      <AccordanceTrello />
    </NewAccordanceContextProvider>
  );
};

export default AccordanceTrelloWithContext;
