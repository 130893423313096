import { Fab } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FilterButtonIcon } from '../../../../assets/icons/u_filters-v.svg';
import { isMobile } from '../../../../services/booleanUtil';
import { useStyles } from '../../../Projects/ProjectsHeader/ProjectsHeader.Styles';

type Props = {
  handleClick: Function;
};

export const FilterButton = ({ handleClick }: Props) => {
  const filterClasses = useStyles();
  const { t } = useTranslation();
  return (
    <button className="btn-primary error" onClick={() => handleClick()}>
      <FilterButtonIcon />
      {isMobile() ? '' : t('filter_button')}
    </button>
  );
};
