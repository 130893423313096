import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Dotdotdot from 'react-dotdotdot';
import styles from './TableRow.module.scss';
import { SupervisionPlanItemType, SupervisionPlanActivity, SupervisionPlanItemState } from '../util';
import StateSelect from '../StateSelect/StateSelect';
import ActivityOptions from './ActivityOptions/ActivityOptions';
import { ReactComponent as Arrow } from '../../../../../assets/expand-icon.svg';
import { Accordion, AccordionSummary, AccordionDetails } from './AccordionStyles';
import { isMobile } from '../../../../../services/booleanUtil';
import { sortDates } from '../../../../../services/sortsUtil';
import { updateActivity } from '../../../../../api/supervisionApi';

type TrInfoProps = {
  activity: SupervisionPlanActivity;
  insideAccordion?: boolean;
  updateData: Function;
  canEdit: boolean;
};

const personsInChargeText = (personsInCharge?: any[] | null) => {
  if (personsInCharge && personsInCharge.length > 0) {
    const sortedList = [...personsInCharge].sort(sortDates('addedDate'));
    return `${sortedList[0].applicationUser.name} ${sortedList[0].applicationUser.lastName}`;
  }
  return '-';
};

const TableRowInfo = ({ activity, insideAccordion, updateData, canEdit }: TrInfoProps) => {
  const { t } = useTranslation();

  const { id, personsInCharge, description, type, plannedDate, state, hasAlert } = activity;

  const handleStateChange = async (newState: any) => {
    const data = {
      id,
      state: newState.value,
    };

    try {
      await updateActivity(data);
      updateData();
    } catch {}
  };

  return (
    <div className={insideAccordion ? styles.containerInsideAccordion : styles.container}>
      <div className={styles.description}>
        <Dotdotdot clamp={3}>{description}</Dotdotdot>
      </div>
      <div className={styles.type}>
        <div className={type === SupervisionPlanItemType.CriticalProduct ? styles.critical : styles.activity}>
          <p className={styles.typeText}>{t(`plan_item_type_${type}`)}</p>
        </div>
      </div>
      <div className={styles.responsible}>
        <Dotdotdot clamp={3}>{personsInChargeText(personsInCharge)}</Dotdotdot>
      </div>
      <div className={styles.plannedDate}>{plannedDate ? moment(plannedDate).format('DD/MM/YYYY') : ''}</div>
      <div className={styles.state} onClick={e => e.stopPropagation()}>
        <StateSelect
          selectedValue={state}
          disabled={!canEdit || activity.state !== SupervisionPlanItemState.InProgress}
          onChange={handleStateChange}
        />
      </div>
      <div className={styles.last} onClick={e => e.stopPropagation()}>
        <ActivityOptions
          activity={activity}
          hasAlert={hasAlert}
          displayAlertOption={
            state !== SupervisionPlanItemState.Completed && state !== SupervisionPlanItemState.Cancelled
          }
          updateData={updateData}
          canEdit={canEdit}
        />
      </div>
    </div>
  );
};

type ListProps = {
  list: any[];
};

const ProductsList = ({ list }: ListProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.listContainer}>
      <p className={styles.listTitle}>{t('linked_critical_products')}</p>
      {list.map(item => (
        <p key={item.description} className={styles.listItem}>
          {item.description}
        </p>
      ))}
    </div>
  );
};

type Props = {
  activity: SupervisionPlanActivity;
  updateData: Function;
  canEdit: boolean;
};

const TableRow = ({ activity, updateData, canEdit }: Props) => {
  return activity.criticalProducts && activity.criticalProducts.length ? (
    <Accordion style={setBackgroundAccordionOnMobile()}>
      <AccordionSummary expandIcon={<Arrow />}>
        <TableRowInfo activity={activity} insideAccordion={true} updateData={updateData} canEdit={canEdit} />
      </AccordionSummary>
      <AccordionDetails>
        <ProductsList list={activity.criticalProducts.filter(i => i.description && i.description.trim())} />
      </AccordionDetails>
    </Accordion>
  ) : (
    <TableRowInfo activity={activity} updateData={updateData} canEdit={canEdit} />
  );
};

const setBackgroundAccordionOnMobile = () => (isMobile() ? { backgroundColor: '#f2f3f7' } : {});

export default TableRow;
