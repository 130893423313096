import React from 'react';
import styles from './AlertsGcm.module.scss';
import { ReactComponent as GcmIssue } from '../../../../assets/gcm-issue.svg';
import { ReactComponent as GcmAlert } from '../../../../assets/gcm-alert.svg';
import { ReactComponent as GcmCancelation } from '../../../../assets/gcm-cancelation.svg';
import useGcmAlerts from '../../../../hooks/useGcmAlerts';

export const AlertsGcm = () => {
  const { showAlertIcons } = useGcmAlerts({});
  const { issue, warning, cancelation } = showAlertIcons;
  const { container, icon, hiddenIcon } = styles;
  return (
    <div className={container}>
      <GcmIssue className={issue.showAlert ? icon : hiddenIcon} />
      <GcmAlert className={warning.showAlert ? icon : hiddenIcon} />
      <GcmCancelation className={cancelation.showAlert ? icon : hiddenIcon} />
    </div>
  );
};
