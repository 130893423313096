import React from 'react';
import styles from './IconsRender.module.scss';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { v4 as uuidv } from 'uuid';

const {
  iconsRenderContainer,
  icon,
  text,
  notApplicable,
  filledGreen,
  hollowYellow,
  filledRed,
  filledYellow,
  hollowRed,
  pmrLabel,
  textValueStyle,
} = styles;

type Props = {
  iconValue: any;
  textValue: string;
  percentageFormat?: boolean;
  pmrFormat?: boolean;
  safeguardFormat?: boolean;
  handleModal: Function;
};
export const IconsRender = ({
  iconValue,
  textValue,
  percentageFormat,
  pmrFormat,
  safeguardFormat,
  handleModal,
}: Props) => {
  const { t } = useTranslation();

  const classNameMap = {
    '2': notApplicable,
    '3': filledGreen,
    '4': hollowYellow,
    '5': filledYellow,
    '6': hollowRed,
    '7': filledRed,
  };
  return (
    <div className={iconsRenderContainer} onClick={handleModal ? () => handleModal() : () => {}}>
      <div className={icon}>
        <span className={classNameMap[iconValue]} />
      </div>
      <div className={text}>{renderText({ t, textValue, percentageFormat, pmrFormat, safeguardFormat })}</div>
    </div>
  );
};

const renderText = options => {
  const { t, textValue, percentageFormat, pmrFormat, safeguardFormat } = options;
  if (percentageFormat) {
    return (
      <NumberFormat
        value={parseFloat(textValue)}
        displayType={'text'}
        className={'value'}
        thousandSeparator={'.'}
        decimalSeparator={','}
        decimalScale={2}
        suffix={'%'}
      />
    );
  } else if (pmrFormat) {
    const textValueArray = textValue && textValue.toLowerCase().split(',');
    const NOT_ASSIGNED_TEXT = 'not_assigned_initials';
    return textValueArray ? (
      <>
        {textValueArray &&
          textValueArray.map(text => {
            const textTransaltedKey = text === 'n/a' ? NOT_ASSIGNED_TEXT : text;
            return (
              <p className={pmrLabel} key={uuidv()}>
                {t(textTransaltedKey)}{' '}
              </p>
            );
          })}
      </>
    ) : (
      <p className={pmrLabel} key={uuidv()}>
        {t(NOT_ASSIGNED_TEXT)}
      </p>
    );
  } else if (safeguardFormat) {
    return textValue && <p className={textValueStyle}>{t(textValue.toLowerCase())}</p>;
  }

  return <p className={textValueStyle}>{t(textValue)}</p>;
};
