import axios from 'axios';
import { loginRequest } from '../authConfig';
import { msalInstance } from '../index';

const axiosDefaultConfig = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_APIM_KEY,
  },
});

axiosDefaultConfig.interceptors.request.use(async config => {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
  }

  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account,
  });

  let conf = { ...config };
  conf.headers.Authorization = `Bearer ${response.accessToken}`;
  if (conf.method === 'get') {
    conf.headers['Pragma'] = 'no-cache';
  }
  return conf;
});

axiosDefaultConfig.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const response = error.response;
    if (response && response.status && response.status === 401) {
      return Promise.reject(error);
    } else if (
      response &&
      response.status &&
      response.status === 400 &&
      response.data &&
      response.data.Code === 'UnderMaintenance'
    ) {
      if (window.location.href.indexOf('maintenance') < 0) {
        window.location.replace(`${process.env.REACT_APP_REDIRECT_URL}maintenance`);
      }
    } else {
      return Promise.reject(error);
    }
  },
);

export { axiosDefaultConfig };
