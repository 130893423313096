import React from 'react';
import './PariPassuSection.scss';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';
import PariPassuAdvance from '../PariPassuAdvance/PariPassuAdvance';

type Props = {
  financialInformation: any;
};

const PariPassuSection = (props: Props) => {
  const {
    pariPassuDeviationPercent,
    pariPassuDeviationAmount,
    contractualBid,
    contractualLocal,
    currentBid,
    currentLocal,
  } = props.financialInformation;
  const { t } = useTranslation();

  return (
    <div className="financial-pari-passu-section-container">
      <div className="title-container">
        <span className="title">{t('pari_passu')}</span>
      </div>
      <div className="container-derivation">
        <span className="deviation">
          <div>
            {`${t('deviation')} `}
            {`${pariPassuDeviationPercent}% = `}
          </div>
          {
            <NumberFormat
              value={pariPassuDeviationAmount}
              displayType={'text'}
              thousandSeparator={'.'}
              decimalSeparator={','}
            />
          }{' '}
          USD
        </span>
      </div>
      {/* <PariPassuAdvance type={'b'} label={t('bid')} completed={contractualBid} notCompleted={contractualLocal} showTitle={true} />
      <PariPassuAdvance type={'l'} label={t('local')} completed={currentBid} notCompleted={currentLocal} /> */}
      <PariPassuAdvance type={'b'} label={t('contractual')} completed={contractualBid} notCompleted={contractualLocal} showTitle={true} />
      <PariPassuAdvance type={'l'} label={t('current_year')} completed={currentBid} notCompleted={currentLocal} />

    </div>
  );
};

export default PariPassuSection;
