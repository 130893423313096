import React from 'react';
import styles from './BubbleCardChart.module.scss';
import { Bubble } from 'react-chartjs-2';
import { translateMultipleText } from '../../../../../services/stringUtil';
import { isMobile } from '../../../../../services/booleanUtil';
import { useTranslation } from 'react-i18next';
import { options } from './Util';
import { ReportImageDisplay } from '../../../../Util/Modals/CustomizableReports/CustomizableReports';

type Props = {
  data: any;
  forReport?: ReportImageDisplay;
};
export const BubbleCardChart = ({ data, forReport }: Props) => {
  const { t } = useTranslation();
  const {
    container,
    topLabels,
    labelQ1,
    labelQ2,
    chartSection,
    leftLabel,
    chart,
    bottomLabels,
    labelQ3,
    labelQ4,
    bottomLabel,
    valueText,
    labelQ1ForReport,
    labelQ2ForReport,
    labelQ3ForReport,
    labelQ4ForReport,
    leftLabelForReport,
    bottomLabelForReport,
  } = styles;
  const dimensions = isMobile() ? { width: 110, height: 110 } : { width: 220, height: 220 };
  const renderLabels = (label, reportLabel) => {
    return forReport === ReportImageDisplay.Detail ? reportLabel : label;
  };
  return (
    <>
      {data ? (
        <div className={container}>
          <div className={topLabels}>
            <div className={renderLabels(labelQ1, labelQ1ForReport)}>
              {translateMultipleText(fallbackLabel(data, 0), t)}
            </div>
            <div className={renderLabels(labelQ2, labelQ2ForReport)}>
              {translateMultipleText(fallbackLabel(data, 1), t)}
            </div>
          </div>
          <div className={chartSection}>
            <div className={renderLabels(leftLabel, leftLabelForReport)}>{t('disbursed')}</div>
            <div className={chart}>
              <Bubble
                data={data}
                options={options(forReport)}
                {...dimensions}
                datasetKeyProvider={datasetKeyProvider}
              />
            </div>
          </div>
          <div className={bottomLabels}>
            <div className={renderLabels(labelQ3, labelQ3ForReport)}>
              {translateMultipleText(fallbackLabel(data, 2), t)}
            </div>
            <div className={renderLabels(labelQ4, labelQ4ForReport)}>
              {translateMultipleText(fallbackLabel(data, 3), t)}
            </div>
            
          </div>
          <div className={renderLabels(bottomLabel, bottomLabelForReport)}> {t('time')}</div>
        </div>
      ) : (
        <div className={valueText}>-</div>
      )}
    </>
  );
};

const fallbackLabel = (data, index) =>
  data && data.datasets[index] && data.datasets[index].label ? data.datasets[index].label : '';

const datasetKeyProvider = () => {
  return btoa(Math.random().toString()).substring(0, 12);
};
