import { IndicatorKeyEnum as keyEnum } from '../../../models/IndicatorKeyEnum';
import { groupBy } from 'lodash';
import {
  findMetricLabel,
  findMetricValue,
  getIndicatorData,
  metricAreZeros,
  metricIsEmpty,
  TaskLabelsEnums,
} from './indicatorHelperUtil';
import { getCtValues, getIgValues, getLoanValues } from './util';

const addPortfolioSummary = (indicators, cardIndicators, indicatorOptions) => {
  const indicator = getIndicatorData(indicators, keyEnum.PROJECTS_SUMMARY_PORTFOLIO);

  if (!indicator) {
    return;
  } else if (metricIsEmpty(indicator) || metricAreZeros(indicator)) {
    cardIndicators.push({
      id: indicator.id,
      indicatorKey: indicator.key,
      type: 'summary',
      title: `title_sum ${indicatorOptions.titleFromTab}`,
      detailTitle: `${indicatorOptions.titleFromTab}`,
      data: null,
      order: indicator.order,
    });
  } else {
    const lo = getLoanValues(indicator.metrics);
    const ct = getCtValues(indicator.metrics);
    const ig = getIgValues(indicator.metrics);
    //DETAIL DATA

    const portfolioSummaryData = {
      id: indicator.id,
      indicatorKey: keyEnum.PROJECTS_SUMMARY_PORTFOLIO,
      type: 'summary',
      title: `title_sum ${indicatorOptions.titleFromTab}`,
      detailTitle: `title_sum ${indicatorOptions.titleFromTab}`,
      data: {
        lo,
        ct,
        ig,
      },

      order: indicator.order,
      tabIndicatorValues: {
        Loan: lo.lonProjectAmount.value ? lo.lonProjectAmount.value : 0,
        CT: ct.nationals.ctsNatProjectAmount.value ? ct.nationals.ctsNatProjectAmount.value : 0,
        IGBoth: ig.igBothProjectAmount.value ? ig.igBothProjectAmount.value : 0,
        IGGreater: ig.igGreaterProjectAmount.value ? ig.igGreaterProjectAmount.value : 0,
        IGLower: ig.igLowerProjectAmount.value ? ig.igLowerProjectAmount.value : 0,
      },
    };

    cardIndicators.push(portfolioSummaryData);
  }
};

const addUserPendingTasks = (indicators, cardIndicators) => {
  const indicator = getIndicatorData(indicators, keyEnum.USER_PENDING_TASKS);

  if (!indicator) {
    return;
  } else if (metricIsEmpty(indicator)) {
    cardIndicators.push({
      id: indicator.id,
      indicatorKey: indicator.key,
      type: 'list',
      title: 'my_pending_tasks',
      detailTitle: 'my_pending_tasks',
      data: null,
      order: indicator.order,
    });
  } else {
    const returnedData: any = [];
    const tasks = groupBy(indicator.metrics, 'groupingValue');

    for (const key in tasks) {
      returnedData.push({
        id: key,
        title: findMetricLabel(tasks, key, TaskLabelsEnums.TASK_NAME),
        code: findMetricLabel(tasks, key, TaskLabelsEnums.PROJECT_CODE),
        expirationDate: findMetricLabel(tasks, key, TaskLabelsEnums.EXPIRATION_DATE),
      });
    }

    const userPendingTasksData = {
      id: indicator.id,
      indicatorKey: keyEnum.USER_PENDING_TASKS,
      type: 'list',
      title: 'my_pending_tasks',
      detailTitle: 'my_pending_tasks',
      data: returnedData,
      order: indicator.order,
    };
    cardIndicators.push(userPendingTasksData);
  }
};

const addConsultanciesFinancedAndExecutedByTheBank = (indicators, cardIndicators) => {
  const indicator = getIndicatorData(indicators, keyEnum.BID_CONSULTANCY);

  if (!indicator) {
    return;
  } else if (metricIsEmpty(indicator) || metricAreZeros(indicator)) {
    cardIndicators.push({
      id: indicator.id,
      indicatorKey: indicator.key,
      type: 'simpleSummary',
      title: 'consultancies_financed_and_executed_by_the_bank',
      detailTitle: 'consultancies_financed_and_executed_by_the_bank',
      data: null,
      order: indicator.order,
    });
  } else {
    const technicalCooperationsValue = findMetricValue(indicator.metrics, 'PROJECT_COUNT');
    const supervisionPlansValue = findMetricValue(indicator.metrics, 'SUPERVISION_PLAN_COUNT');

    const indicatorData = {
      id: indicator.id,
      indicatorKey: indicator.key,
      type: 'simpleSummary',
      title: 'consultancies_financed_and_executed_by_the_bank',
      detailTitle: 'consultancies_financed_and_executed_by_the_bank',
      order: indicator.order,
      data: {
        technicalCooperations: { value: technicalCooperationsValue, text: 'technical_cooperation' },
        supervisionPlans: { value: supervisionPlansValue, text: 'supervision_plans' },
      },
    };

    cardIndicators.push(indicatorData);
  }
};
export { addPortfolioSummary, addUserPendingTasks, addConsultanciesFinancedAndExecutedByTheBank };
