import { SET_INDICATOR_ACCORDANCES, RESET_INDICATOR_ACCORDANCES } from './types';
import Accordance from '../../models/Accordance';
import { getIndicatorAccordance } from '../../api/accordanceApi';

export function setIndicatorAccordances(data: Accordance[]) {
  return {
    type: SET_INDICATOR_ACCORDANCES,
    payload: data,
  };
}

export function resetIndicatorAccordances() {
  return {
    type: RESET_INDICATOR_ACCORDANCES,
  };
}

export function getPendingApprovalAccordances(pending) {
  return async (dispatch: any) => {
    try {
      const response = await getIndicatorAccordance(pending);
      const result: Accordance[] = [];
      for (const acc of response) {
        if (acc.currentUserCanApprove) {
          result.unshift(acc);
        } else {
          result.push(acc);
        }
      }
      await dispatch(setIndicatorAccordances([...result]));
    } catch (error) {}
  };
}
