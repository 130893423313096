import React, { createContext, useState, useContext, FunctionComponent, useCallback } from 'react';
import Indicator from '../models/Indicator';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { setCurrentIndicator } from '../redux/actions/currentIndicator';

type CardType = Indicator | undefined;

interface CardsContextType {
  cards: CardType;
  currentRowClicked: string;
  setCards: React.Dispatch<React.SetStateAction<CardType>>;
  navigateToPortfolioDetail: () => void;
  setCurrentRowClicked: React.Dispatch<React.SetStateAction<string>>;
}

const CardContext = createContext<CardsContextType | undefined>(undefined);

export const useCards = (): CardsContextType => {
  const context = useContext(CardContext);
  if (context === undefined) {
    throw new Error('useCards must be used within a CardsProvider');
  }
  return context;
};

type CardsProviderProps = {
  children: React.ReactNode;
};

export const CardsProvider: FunctionComponent<CardsProviderProps> = ({ children }) => {
  const [cards, setCards] = useState<CardType>(undefined);
  const [currentRowClicked, setCurrentRowClicked] = useState<string>("");

  const history = useHistory();
  const dispatch = useDispatch();

  const navigateToPortfolioDetail = useCallback(() => {
    if (cards) {
      dispatch(setCurrentIndicator(cards));
      history.push('/portfoliodetail');
    }
  }, [cards, dispatch, history]);

  return (
    <CardContext.Provider value={{ cards, setCards, navigateToPortfolioDetail, setCurrentRowClicked, currentRowClicked }}>
      {children}
    </CardContext.Provider>
  );
};
