import { CriteriaType } from './PaginationIndicator';

export interface HeaderTableColumn {
  className: string;
  criteria: string;
  criteriaType: CriteriaType;
  label: string;
  hide?: boolean;
}

export const basicFirstColumns = headerTitleClickable => {
  return [
    {
      className: headerTitleClickable,
      criteria: 'code',
      criteriaType: CriteriaType.string,
      label: 'project',
    },
    {
      className: headerTitleClickable,
      criteria: 'loanNumber',
      criteriaType: CriteriaType.string,
      label: 'operation',
    },
    {
      className: headerTitleClickable,
      criteria: 'title',
      criteriaType: CriteriaType.string,
      label: 'name',
    },
  ];
};
