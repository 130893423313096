import React from 'react';
import './Financial.scss';
import { useTranslation } from 'react-i18next';
import LabelData from '../../../Util/LabelData/LabelData';
import { Efas } from '../../../../models/FinancialInformation';
import { renderDateOrSlash } from '../../../../services/stringUtil';

type Props = {
  efas?: Efas[];
};

const EfasSection = ({ efas }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="basic-display-columns-container efas-section">
      <div className="title">
        <span> {t('efas_finance')}</span>
      </div>

      {efas
        ? efas.map((item, index) => {
          return (
            <>
              <span className="subtitle">{index === 0 ? t('last_presented') : t('next_to_present')}</span>
              <div className="basic-row-display last-present">
                <div className="basic-display-column margin-title">
                  <LabelData label={t('efa_presentation_date')} data={item.fiscalYear || '-'} reverse={true} />
                  <LabelData label={t('expiration')} data={renderDateOrSlash(item.dueDate)} reverse={true} />
                  {index === 0 && (
                    <LabelData
                      label={t('presentation_date')}
                      data={renderDateOrSlash(item.presentationDate)}
                      reverse={true}
                    />
                  )}
                </div>
                <div className="basic-display-column">
                  <LabelData label={t('efa_audit_company')} data={item.efaAuditCompany || '-'} reverse={true} />
                  <LabelData
                    label={t('status')}
                    data={item.fiscalYear && item.state !== null ? t(`efas_pending_states_${item.state}`) : '-'}
                    reverse={true}
                  />
                  {index === 0 && <LabelData label={t('efa_opinion')} data={item.efaComment || '-'} reverse={true} />}
                </div>
              </div>
            </>
          );
        })
        : null}
    </div>
  );
};

export default EfasSection;
