import React, { MouseEventHandler } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { tableRowTypeKeys, dataTypes } from '../ContentManagerKeys';

import { Typography } from '@material-ui/core';
import {
  ContentManagerStyledTableRow,
  ContentManagerTableCell,
  ContentManagerActionsTableCell,
  ContentManagerActionButton,
  ContentManagerTableLabel,
} from './../ContentManagerTable/ContentMangerTable.Styles';

import { ReactComponent as VisibilityIcon } from '../../../assets/contents-visibility-icon.svg';
import { ReactComponent as VisibilityDisabledIcon } from '../../../assets/contents-visibility-disabled-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/contents-delete-icon.svg';
import { ReactComponent as EditIcon } from '../../../assets/contents-edit-icon.svg';
import { ReactComponent as WarningIcon } from '../../../assets/warning-icon.svg';
import { ReactComponent as ArrowIcon } from '../../../assets/big-fw.svg';

import ContentManagerModal from '../ContentManagerModal/ContentManagerModal';
import EmailQuestionModal from '../HelpCenter/EmailQuestionModal/EmailQuestionModal';
import HelpCenterModalForm from '../HelpCenter/HelpCenterModalForm/HelpCenterModalForm';
import TutorialsModalForm from '../HelpCenter/TutorialsModalForm/TutorialsModalForm';
import MessagesFormModal from '../Messages/MessagesFormModal/MessagesFormModal';

import './ContentManagerTableRow.scss';

import {
  deleteEntity,
  deleteFaqListItem,
  deleteSystemMessageListItem,
  deleteTutorialListItem,
  updateMaintenanceService,
} from '../../../api/contentManager';

import { canGetByDataType, getModalTitle } from '../../Util/ContentManager/ContentManager';
import { BidTooltip } from '../../Util/Tooltip/Tooltip';
import { EntitiesModalForm } from '../AddEntities/EntitiesModalForm/EntitiesModalForm';
import { DeleteEntitiesModalConfirmation } from '../AddEntities/DeleteEntitiesModalConfirmation/DeleteEntitiesModalConfirmation';

type Props = {
  item: any;
  type: any;
  updateFunction?: any;
  getLanguageTranslationFromKey?: Function;
  displayGenericErrorMessage?: any;
  languagesData?: any;
  updateItem: Function;
};

const getCommonActions = (
  deleteOnly = false,
  setModal: Function,
  handleDelete: MouseEventHandler<HTMLButtonElement>,
) => {
  return (
    <>
      {!deleteOnly && (
        <ContentManagerActionButton onClick={() => setModal(true)}>
          <EditIcon />
        </ContentManagerActionButton>
      )}
      <ContentManagerActionButton onClick={handleDelete}>
        <DeleteIcon />
      </ContentManagerActionButton>
    </>
  );
};

const getArrowActionInfo = (item: any, t: Function) => (
  <div style={{ display: 'inline-flex', flexDirection: 'column' }}>
    <span>{`${t('contents_helpCenter_emailQuestions_answeredBy')}`}</span>
    <span style={{ textAlign: 'left' }}>{`${item.answeredBy} - ${moment(item.answeredDate).format(
      'DD/MM/YYYY',
    )}`}</span>
  </div>
);

const getModal = (item, canGetByDataType, getModalByDataType) => canGetByDataType(item.type) && getModalByDataType();

const isVisibleOrActive = (visible, active) => visible || active;

const getTextElement = (item, type, t) => {
  if (type === dataTypes.emailQuestions) {
    return (
      <div>
        <span className="email-row__date">{moment.utc(item.received).local().format('DD/MM/YY')}</span>
        <span className="email-row__category">{`${t(item.category)}`}</span>
        <span>{` - ${item.text}`}</span>
      </div>
    );
  }
  return <span>{item.text}</span>;
};

const deleteByActionType = async item => {
  switch (item.type) {
    case dataTypes.faq:
      return deleteFaqListItem(item);
    case dataTypes.tutorials:
      return deleteTutorialListItem(item);
    case dataTypes.messages:
      return deleteSystemMessageListItem(item);
    case dataTypes.maintenance:
      return updateMaintenanceService({ startDate: null, endDate: null });
    case dataTypes.catergories:
    case dataTypes.criticalIssue:
    case dataTypes.possibleAction:
      return deleteEntity(item.type, item);

    default:
      return () => null;
  }
};

export default function ContentManagerTableRow({
  item,
  type,
  updateFunction,
  getLanguageTranslationFromKey,
  displayGenericErrorMessage,
  languagesData,
  updateItem,
}: Props) {
  const { t } = useTranslation();
  const { visible, isNew, active } = item;
  const [modal, setModal] = React.useState(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = React.useState(false);

  const getModalByDataType = () => {
    switch (item.type) {
      case dataTypes.faq:
        return (
          <HelpCenterModalForm
            closeModal={() => setModal(false)}
            updateFaqList={updateFunction}
            getLanguageTranslationFromKey={getLanguageTranslationFromKey}
            isEdit={true}
            faqItem={item}
            languagesData={languagesData}
          />
        );
      case dataTypes.tutorials:
        return (
          <TutorialsModalForm
            closeModal={() => setModal(false)}
            updateTutorialsList={updateFunction}
            getLanguageTranslationFromKey={getLanguageTranslationFromKey}
            isEdit={true}
            tutorialItem={item}
            languagesData={languagesData}
          />
        );
      case dataTypes.emailQuestions:
        return (
          <EmailQuestionModal
            closeModal={() => setModal(false)}
            info={item}
            displayGenericErrorMessage={displayGenericErrorMessage}
            updateFunction={updateFunction}
          />
        );
      case dataTypes.messages:
        return (
          <MessagesFormModal
            closeModal={() => setModal(false)}
            updateFunction={updateFunction}
            getLanguageTranslationFromKey={getLanguageTranslationFromKey}
            displayGenericErrorMessage={displayGenericErrorMessage}
            isEdit={true}
            messageItem={item}
            languagesData={languagesData}
          />
        );
      case dataTypes.catergories:
      case dataTypes.criticalIssue:
      case dataTypes.possibleAction:
        return <EntitiesModalForm closeModal={() => setModal(false)} item={item} updateItem={updateItem} />;
      default:
        return '';
    }
  };

  const handleDelete = async () => {
    try {
      if (
        item.type === dataTypes.catergories ||
        item.type === dataTypes.criticalIssue ||
        item.type === dataTypes.possibleAction
      ) {
        setConfirmDeleteModal(true);
      } else {
        await deleteByActionType(item);
        updateFunction();
      }
    } catch {
      displayGenericErrorMessage();
    }
  };

  const confirmDeleteOnEntities = async () => {
    setConfirmDeleteModal(false);
    const response = await deleteByActionType(item);
    response && !response.success ? displayGenericErrorMessage(true) : updateFunction();
  };

  const getLabelActions = () => {
    return (
      <>
        {areTranslationsIncomplete() && (
          <ContentManagerActionButton>
            <WarningIcon />
          </ContentManagerActionButton>
        )}
        <ContentManagerTableLabel variant="overline">{t(`category_${item.categoryId}`)}</ContentManagerTableLabel>
        {getCommonActions(undefined, setModal, handleDelete)}
      </>
    );
  };

  const areTranslationsIncomplete = () =>
    item &&
    item.translations &&
    item.translations.find(i => (i.title === '' && item.type !== dataTypes.messages) || i.content === '');

  const getDefaultActions = (deleteOnly = false, showVisibleOrActiveIcon = true) => {
    return (
      <>
        {areTranslationsIncomplete() && (
          <ContentManagerActionButton>
            <WarningIcon />
          </ContentManagerActionButton>
        )}
        {!deleteOnly && showVisibleOrActiveIcon && (
          <ContentManagerActionButton>
            {isVisibleOrActive(visible, active) ? <VisibilityIcon /> : <VisibilityDisabledIcon />}
          </ContentManagerActionButton>
        )}
        {getCommonActions(deleteOnly, setModal, handleDelete)}
      </>
    );
  };

  const getArrowActions = () => {
    return (
      <div style={{ display: 'inline-flex', alignItems: 'center' }}>
        {isNew ? (
          <ContentManagerTableLabel variant="overline">
            {t('contents_helpCenter_emailQuestions_new')}
          </ContentManagerTableLabel>
        ) : (
          getArrowActionInfo(item, t)
        )}
        <ContentManagerActionButton onClick={() => setModal(true)}>
          <ArrowIcon />
        </ContentManagerActionButton>
      </div>
    );
  };

  const getActionsByType = () => {
    switch (type) {
      case tableRowTypeKeys.labelActions:
        return getLabelActions();
      case tableRowTypeKeys.arrowActions:
        return getArrowActions();
      case tableRowTypeKeys.deleteOnly:
        return getDefaultActions(true);
      case tableRowTypeKeys.editAndDelete:
        return getDefaultActions(false, false);
      default:
        return getDefaultActions();
    }
  };

  return (
    <>
      <ContentManagerStyledTableRow>
        <ContentManagerTableCell>
          <Typography
            noWrap
            style={{ border: 'none', fontFamily: 'Rubik', fontSize: '14px', color: '#727E8C', fontWeight: 400 }}
          >
            <BidTooltip 
              title={getTextElement(item, type, t)}
              placement="bottom-start"
              enterDelay={500}
              leaveDelay={200}
              arrow
            >
              {getTextElement(item, type, t)}
            </BidTooltip>
          </Typography>
        </ContentManagerTableCell>
        <ContentManagerActionsTableCell>{getActionsByType()}</ContentManagerActionsTableCell>
      </ContentManagerStyledTableRow>
      <ContentManagerModal
        isOpen={modal}
        openModal={() => setModal(true)}
        closeModal={() => setModal(false)}
        modalTitle={t(getModalTitle(item.type))}
        fromButton={false}
      >
        {getModal(item, canGetByDataType, getModalByDataType)}
      </ContentManagerModal>
      <ContentManagerModal
        isOpen={confirmDeleteModal}
        openModal={() => setConfirmDeleteModal(true)}
        closeModal={() => setConfirmDeleteModal(false)}
        modalTitle={''}
        fromButton={false}
      >
        <DeleteEntitiesModalConfirmation
          closeModal={() => setConfirmDeleteModal(false)}
          confirm={confirmDeleteOnEntities}
          itemDataType={item.type}
        />
      </ContentManagerModal>
    </>
  );
}
