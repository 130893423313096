import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import LabelData from '../../../../Util/LabelData/LabelData';
import { emptyValue } from '../constants';
import LinkText from '../../../../Util/Buttons/LinkText/LinkText';
import { acquisitionTypes } from '../../../../ProjectDetail/ProjectTabs/Planning/NewItem/NewItemFormUtil';

type Props = {
  eventData: any;
  createAccordanceReminder: any;
  isCalendarIndicator?: boolean;
};

const AcquisitionDetail = (props: Props) => {
  let { eventData, createAccordanceReminder, isCalendarIndicator } = props;
  if (!eventData) {
    eventData = {
      name: emptyValue,
      acquisitionType: emptyValue,
      startDate: emptyValue,
      amount: emptyValue,
      acquisitionState: { name: emptyValue },
      projectTitle: emptyValue,
      projectCode: emptyValue,
    };
  }
  const { t } = useTranslation();

  const typeLabel = acquisitionType => {
    const type = acquisitionTypes.find(a => a.id === eventData.acquisitionType);
    return type ? t(type.label) : '-';
  };
  return (
    <>
      {isCalendarIndicator && (
        <LabelData label={t('code')} data={`${eventData.projectCode} ${eventData.projectTitle}`} reverse={true} />
      )}
      <LabelData label={t('description')} data={eventData.name} reverse={true} />
      <LabelData label={t('type')} data={typeLabel(eventData.acquisitionType)} reverse={true} />
      <div style={{ display: 'flex' }}>
        <LabelData
          label={t('start_date')}
          data={eventData.startDate ? moment(eventData.startDate).format('DD/MM/YYYY') : emptyValue}
          reverse={true}
        />
        <LinkText
          label={t('create_reminder')}
          onClick={createAccordanceReminder}
          customStyle={{ margin: '0px 0px 3px 10px' }}
        />
      </div>
      <LabelData label={t('amount')} data={eventData.planAmount} reverse={true} />
      <LabelData
        label={t('status')}
        data={
          eventData.acquisitionState && eventData.acquisitionState.name ? eventData.acquisitionState.name : emptyValue
        }
        reverse={true}
      />
    </>
  );
};
export default AcquisitionDetail;
