import { Tab, Tabs } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppMenuTabs, MenuTabs } from '../../../MenuTabs/userTabsInterface';
import { setScrollablOrStandard } from '../../../Portfolio/PortfolioUtil';
import { makeStyles } from '@material-ui/core/styles';
import { tabStyles } from '../../../Projects/ProjectsAll/ProjectsAll.Style';
import { useSelector } from 'react-redux';
type Props = {
  handleChange;
  handleSetMenuValue;
};
export const ManagerialMenuTabs = ({ handleChange, handleSetMenuValue }: Props) => {
  //STYLES
  tabStyles.root.backgroundColor = 'transparent';
  tabStyles.root['&$selected'].backgroundColor = 'transparent';
  const classes = makeStyles(tabStyles)();
  const tabClasses = { root: classes.root, selected: classes.selected };
  const { managerialReviewSelectedTab, userTabs } = useSelector<any, AppMenuTabs>(state => state.menuTabs);
  const { t } = useTranslation();
  const getTabLabelText = (label, t) => {
    return label === 'global_bid' || label === 'my_portfolio' ? t(label) : label;
  };
  const handleAllowedTabs = (userTabs: MenuTabs[]): MenuTabs[] => {
    return userTabs.filter((tab: MenuTabs) => tab.tabId === 0 && !tab.assignedToMe);
  };

  //Managerial Tabs
  const managerialMenuTabs = handleAllowedTabs(userTabs);
  return (
    <Tabs
      value={managerialReviewSelectedTab.internalId}
      onChange={handleChange}
      classes={{
        indicator: classes.indicatorColor,
        root: classes.root,
      }}
      variant={setScrollablOrStandard()}
      aria-label="full width tabs"
    >
      {managerialMenuTabs.map((tab: MenuTabs) => (
        <Tab
          key={tab.internalId}
          value={tab.internalId}
          classes={tabClasses}
          label={getTabLabelText(tab.title, t)}
          onClick={() => handleSetMenuValue(tab)}
        />
      ))}
    </Tabs>
  );
};
