import React, { useEffect, useState } from 'react';
import { Bubble } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import { BubbleDataCard, ModalPosition } from '../../../models/DisbursementTimeIndicatorInterfaces';
import { getProjectsSimpleTableIndicator } from '../../../redux/actions/indicatorProjects';
import { DisbursementTimeCardModal } from '../../Util/Modals/DisbursementTimeCardModal/DisbursementTimeCardModal';
import { handleSelectedDefaultWithProjects, setDefaultValueToCompositionFilter } from '../PortfolioDetailHeader/Util';
import { handleModalPositionLimits, parseToChartBubbles, setOptions } from './Util';
import styles from './DisbursementTime.module.scss';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../../../services/booleanUtil';
import IndicatorDetailFilter from '../../../models/IndicatorDetailFilter';
import { YEARS_OF_TOLERANCE } from '../../Util/Modals/CustomizableReports/LargeImagesComponents/Util';
const EMPTY_MODAL_POSITION: ModalPosition = {
  x: 0,
  y: 0,
};

const EMPTY_BUBBLE_DATA: BubbleDataCard = {
  code: '',
  title: '',
  loanNumber: '',
  yearsOfLegalValidity: '0',
  currentDisbursement: '0',
  pendingDisbursement: '0',
  extension: false,
  projectId: 0,
};

export const DisbursementTime = () => {
  const { t } = useTranslation();
  const { container, bottomLabel, chartContainer, leftLabel } = styles;
  const { currentIndicator } = useSelector<any, any>(state => state);
  const { tabIndicatorValues } = currentIndicator;
  const [openModal, setOpenModal] = useState(false);
  const [bubbleData, setBubbleData] = useState<BubbleDataCard>(EMPTY_BUBBLE_DATA);
  const [modalPosition, setModalPosition] = useState<ModalPosition>(EMPTY_MODAL_POSITION);
  const [bubbles, setBubbles] = useState<any>([]);
  const { projectTypeFilters } = useSelector<any, any>(state => state.filterIndicators.common);
  const { projectTypeFilterOnDetail } = useSelector<any, IndicatorDetailFilter>(state => state.indicatorDetailFilter);
  const { indicatorProjects } = useSelector<any, any>(state => state);
  const dispatch = useDispatch();
  const [filterApplied, setFilterApplied] = useState(
    setDefaultValueToCompositionFilter(handleSelectedDefaultWithProjects(projectTypeFilters, tabIndicatorValues)),
  );
  const bubblesAxis = localStorage.getItem('bubblesAxis');
  const { upperY, bottomY, leftX, rightX } = bubblesAxis
    ? JSON.parse(bubblesAxis)
    : {
        upperY: 100,
        bottomY: -10,
        leftX: -1,
        rightX: Math.round(Math.max(...indicatorProjects.map(i => parseFloat(i.field1)))) + YEARS_OF_TOLERANCE,
      };
  const axis = { upperY, bottomY, leftX, rightX };
  useEffect(() => {
    const parsedData = parseToChartBubbles(indicatorProjects, projectTypeFilterOnDetail, isMobile() ? 10 : 100);
    setBubbles(parsedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indicatorProjects]);

  useEffect(() => {
    if (projectTypeFilterOnDetail !== null) {
      setFilterApplied(projectTypeFilterOnDetail);
      getFilteredSimpleIndicators(dispatch, currentIndicator.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectTypeFilterOnDetail]);

  const getElementAtEvent = element => {
    if (!element.length) {
      return;
    }

    !isMobile() && setModalPosition(handleModalPositionLimits(element));
    const modalData = bubbles.datasets[element[0]._datasetIndex].data[element[0]._index].modalData;

    setBubbleData(modalData);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <div className={container}>
        <div className={leftLabel}>{`${t('percentage_sign')} ${t('disbursed')}`}</div>
        <div className={chartContainer}>
          <Bubble
            data={bubbles}
            options={setOptions(filterApplied, axis)}
            getElementAtEvent={element => getElementAtEvent(element)}
          />
          <DisbursementTimeCardModal
            isOpen={openModal}
            closeModal={handleCloseModal}
            data={bubbleData}
            modalPosition={modalPosition}
          />
        </div>
      </div>
      <div className={bottomLabel}>{t('years_of_legal_validity')}</div>
    </>
  );
};

const getFilteredSimpleIndicators = (d, currentIndicatorId) => {
  return d(getProjectsSimpleTableIndicator(currentIndicatorId));
};
