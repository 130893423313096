import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IDay } from '../CalendarCollapsed/CalendarCollapsed';
import styles from './CalendarBubble.module.scss';

type Props = {
  day: IDay;
};
export const CalendarBubble = ({ day }: Props) => {
  const { t } = useTranslation();
  const { date, eventQty, highLightEvent } = day;
  const { title, eventType } = highLightEvent;

  return (
    <div className={styles.calendarBubbleContainer}>
      <div className={styles.date}>{moment(date).format('DD/MM/YY')}</div>
      {/* <div className={styles.eventCounter}>{renderEventQty(eventQty, t)}</div> */}
      <div className={eventTypeClasses[eventType]}>
        <p className={styles.eventTitle}>{title}</p>
      </div>
    </div>
  );
};

const eventTypeClasses = [
  styles.disbursements,
  styles.acquisitions,
  styles.activities,
  styles.accordancesMonitor,
  styles.accordancesRevision,
  styles.mission,
  styles.externalEvent,
  styles.lifeCycle,
  styles.interGroup,
  styles.supervisoryAction,
];

const renderEventQty = (eventQty, t) => {
  const translateKey = t('there_are_xx_events').replace('xx', eventQty);
  eventQty === 1 && translateKey.slice(0, -1);
  return translateKey;
};
