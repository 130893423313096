import { useState, useEffect, useCallback } from 'react';
import { setMemoryHelpFileAttachment } from '../api/projectApi';

const useSetMemoryHelpFileAttachment = (agreementId: number | null, trigger?: boolean, shouldFetchFileData?: boolean) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<any[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [file, setFile] = useState<any>(null);

    useEffect(() => {
        if (agreementId == null || file == null || !shouldFetchFileData) {
            if (agreementId == null) setError('agreementId is null or undefined');
            if (file == null) setError('file is null or undefined');
            return;
        }

        const fileWithDynamicName = {
            ...file,
            nameFile: `user_fileRevision_${agreementId}`
        };

        const uploadFile = async () => {
            setLoading(true);
            setError(null);
            try {
                const result = await setMemoryHelpFileAttachment(agreementId, fileWithDynamicName);
                setData(result);
            } catch (err) {
                console.error('Error uploading file:', err);
                setError('Error uploading file');
            } finally {
                setLoading(false);
            }
        };

        uploadFile();
    }, [file, trigger, shouldFetchFileData]);

    const handleFileChange = useCallback((newFile: any) => setFile(newFile), []);

    return { loading, data, error, handleFileChange };
};

export default useSetMemoryHelpFileAttachment;
