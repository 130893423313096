import { isMobile } from '../../../services/booleanUtil';
import { ProjectionChartModel } from './interfaces';

const ACTUAL_PROJECTION_AMNT_USEQ = 'BASELINE_PROJECTION_AMNT_USEQ';
const DISBURSED_AMNT_USEQ = 'DISBURSED_AMNT_USEQ';
const actualProjectionColor = '#00CFDD';
const nextYearProjectionColor = 'rgba(0, 207, 221, 0.5)';
const disbursedColor = '#FDAC41';

const setArraysFromMetrics = metrics => {
  const actualProjection: ProjectionChartModel[] = [];
  const nextYearProjection: ProjectionChartModel[] = [];
  const disbursed: ProjectionChartModel[] = [];
  let sumarizeMetricValue = 0;
  metrics.forEach(metric => {
    const currentYear = new Date().getFullYear();
    const splitedGroupingValue = metric.groupingValue.split('_');
    const month = splitedGroupingValue[0];
    const year = splitedGroupingValue[1];
    sumarizeMetricValue += metric.metricValue;
    if (metric.metricName === ACTUAL_PROJECTION_AMNT_USEQ) {
      if (currentYear === parseInt(year)) {
        const projectionChart = {
          month,
          year: parseInt(year),
          value: metric.metricValue,
        };
        actualProjection.push(projectionChart);
      } else {
        const projectionChart = {
          month,
          year: parseInt(year),
          value: metric.metricValue,
        };
        nextYearProjection.push(projectionChart);
      }
    } else if (metric.metricName === DISBURSED_AMNT_USEQ) {
      const disbursedChart = {
        month,
        year: parseInt(year),
        value: metric.metricValue,
      };
      disbursed.push(disbursedChart);
    }
  });

  return { actualProjection, nextYearProjection, disbursed, sumarizeMetricValue };
};

const setupCardData = (
  actualProjection: ProjectionChartModel[],
  nextYearProjection: ProjectionChartModel[],
  disbursed: ProjectionChartModel[],
) => {
  const MAX_QTY_VALUES = 7;
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  actualProjection = actualProjection.slice(0, MAX_QTY_VALUES);
  const actualProjectionValues = actualProjection
    .filter(item => parseInt(item.month) >= currentMonth)
    .map(item => item.value);

  const cardActualProjectionColorsWithNextYear = actualProjection
    .filter(item => parseInt(item.month) >= currentMonth)
    .map(item => (item.year === currentYear ? actualProjectionColor : nextYearProjectionColor))
    .concat(Array(MAX_QTY_VALUES - actualProjectionValues.length).fill(nextYearProjectionColor));

  let cardActualProjectionValuesWithNextYear: number[] = [];
  if (actualProjectionValues.length <= MAX_QTY_VALUES) {
    const nextYearProjectionValues = nextYearProjection.filter(item => item.year > currentYear).map(item => item.value);
    cardActualProjectionValuesWithNextYear = actualProjectionValues.concat(nextYearProjectionValues);
  }

  const cardDisbursedValues = disbursed.filter(item => parseInt(item.month) >= currentMonth).map(item => item.value);
  const cardDisbursedColors = Array(cardDisbursedValues.length).fill(disbursedColor);

  return {
    cardActualProjectionValuesWithNextYear,
    cardActualProjectionColorsWithNextYear,
    cardDisbursedValues,
    cardDisbursedColors,
  };
};

const sortByYearAndMonth = (list: ProjectionChartModel[]) => {
  if (list.length === 0) {
    return [];
  }
  return [...list].sort((a, b) => {
    if (a.year < b.year) {
      return -1;
    } else if (a.year > b.year) {
      return 1;
    } else {
      if (parseInt(a.month) < parseInt(b.month)) {
        return -1;
      } else if (parseInt(a.month) > parseInt(b.month)) {
        return 1;
      } else {
        return 0;
      }
    }
  });
};

const setupDataFullYearData = (dataFullYearAndNextYear: ProjectionChartModel[], disbursed: ProjectionChartModel[]) => {
  const fullYearActualWithNextYear: number[] = [];
  const fullYearDisbursed: number[] = [];
  const fullYearActualWithNextYearColors: string[] = [];
  const fullYearDisbursedColors: string[] = [];
  const currentYear = new Date().getFullYear();
  const fullYearMonthLabels: string[] = [];
  const fullYearMonthNumbers: number[] = [];
  const yearsList: number[] = [];
  const completeGroupingValue: any[] = [];
  const sortedDataFullYearAndNextYear = sortByYearAndMonth(dataFullYearAndNextYear);

  sortedDataFullYearAndNextYear.forEach(item => {
    completeGroupingValue.push(item);
    fullYearMonthLabels.push(completeYearLabels[parseInt(item.month) - 1]);
    fullYearMonthNumbers.push(parseInt(item.month));
    yearsList.push(item.year);
    if (item.year === currentYear) {
      fullYearActualWithNextYear.push(item.value);
      fullYearActualWithNextYearColors.push(actualProjectionColor);
    } else {
      fullYearActualWithNextYear.push(item.value);
      fullYearActualWithNextYearColors.push(nextYearProjectionColor);
    }
  });
  const sortedDisbursed = sortByYearAndMonth(disbursed);
  sortedDisbursed.forEach(item => {
    fullYearDisbursed.push(item.value);
    fullYearDisbursedColors.push(disbursedColor);
  });

  return {
    fullYearActualWithNextYear,
    fullYearDisbursed,
    fullYearActualWithNextYearColors,
    fullYearDisbursedColors,
    fullYearMonthLabels,
    fullYearMonthNumbers,
    yearsList,
    completeGroupingValue,
  };
};

const completeYearLabels: string[] = [
  'january_short',
  'february_short',
  'march_short',
  'april_short',
  'may_short',
  'june_short',
  'july_short',
  'august_short',
  'september_short',
  'october_short',
  'november_short',
  'december_short',
];
const OFFSET = -7;
const getFirstKey = (obj: any) => {
  return Object.keys(obj)[0];
};

const offsetBarPlugin = {
  id: 'offsetBarPlugin',
  afterUpdate: chart => {
    const dataset = chart.config.data.datasets[1];
    const metaObject = dataset._meta[getFirstKey(dataset._meta)];
    for (let model of metaObject.data) {
      model._model.x += OFFSET;
    }
  },
};

const monthlyDisbursementProjectionCurrentYearIncludingRegionalsOptions = (t, valueToVerticalLine) => {
  return {
    annotation: {
      annotations: [
        {
          drawTime: 'beforeDatasetsDraw',
          borderColor: '#DFE1E5',
          borderDash: [4, 4],
          borderWidth: 1,
          mode: 'vertical',
          type: 'line',
          value: valueToVerticalLine - 0.5,
          scaleID: 'x-axis-0',
        },
      ],
    },
    tooltips: {
      enabled: true,
      backgroundColor: '#101319',
      bodyFontColor: '#ffffff',
      bodyFontFamily: 'Rubik',
      titleFontColor: '#ffffff',
      titleFontSize: 14,
      bodyFontSize: 14,

      callbacks: {
        title: (tooltipItem, data) => {
          return tooltipItem[0].xLabel;
        },
        label: (tooltipItem, data) => {
          const currentYear = new Date().getFullYear();
          const value = `${t('dolar_sign')} ${tooltipItem.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;
          if (tooltipItem.datasetIndex === 0) {
            const year = data.yearsList[tooltipItem.index];
            return year === currentYear
              ? `${t(data['datasets'][tooltipItem.datasetIndex].label)} ${value}`
              : `${t('next_year_projection')} ${value}`;
          }
          return `${t(data['datasets'][tooltipItem.datasetIndex].label)} ${value}`;
        },
      },
    },
    plugins: {
      datalabels: { display: false },
    },
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
            fontSize: isMobile() ? 10 : 12,
            fontStyle: 400,
            color: ' #101319',
            fontColor: '#101319',
            fontFamily: 'rubik',
            fontWeight: 400,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          stacked: false,
          ticks: {
            beginAtZero: true,
            display: false,

          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
    cornerRadius: 9,
  };
};

export {
  setArraysFromMetrics,
  setupCardData,
  setupDataFullYearData,
  monthlyDisbursementProjectionCurrentYearIncludingRegionalsOptions,
  ACTUAL_PROJECTION_AMNT_USEQ,
  DISBURSED_AMNT_USEQ,
  completeYearLabels,
  offsetBarPlugin,
};
