import React from 'react';
import styles from '../SupervisionDetailForm.module.scss';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { SupervisionPlanItemState } from '../../../Supervision/util';
import { colourStyles } from '../../StateSelect/StateSelect';
import { replaceMomentFormat } from '../../../../../../services/util';
import moment from 'moment';
import { getEarlyWarningOptions, getResponsibleOptions } from '../util';
import { SupervisionPlanItemType } from '../../../../../ProjectDetail/ProjectTabs/Supervision/util';
import NumberFormat from 'react-number-format';
import { DropdownIndicator, supervisionResponsibleSelectStyle } from '../../../../../Filter/FilterComponent.Styles';
import LinkText from '../../../../../Util/Buttons/LinkText/LinkText';

type Props = {
  isEdit: boolean;
  stateEdit: number;
  setStateEdit: Function;
  handleSelectedUsers: Function;
  locationEdit: string;
  setLocationEdit: Function;
  consultantEdit: string;
  setConsultantEdit: Function;
  activity: any;
  projectResponsibles: any;
  createReminder?: any;
  handleSelectedEarlyWarning: Function;
  earlyWarningList: any;
};

const modalSelectStyle = {
  ...colourStyles,

  control: styles => ({
    ...styles,
    width: '197px',
    borderRadius: '24px',
    borderColor: '#C1C7D0',
    minHeight: '28px',
    height: '28px',

    '&:hover': {
      cursor: 'pointer',
    },
  }),

  menu: styles => ({
    ...styles,
    width: '197px',
    backgroundColor: 'white',
  }),

  indicatorSeparator: () => ({
    display: 'none',
  }),
};

const renderDataByActivityType = (activityType: number, resultA: any, resultB: any, resultC: any) => {
  return activityType === SupervisionPlanItemType.AdministrativeMission ||
    activityType === SupervisionPlanItemType.ProcurementSupervisionVisit ||
    activityType === SupervisionPlanItemType.EnvironmentalSupervisionVisit ||
    activityType === SupervisionPlanItemType.FinancialSupervisionVisit ||
    activityType === SupervisionPlanItemType.TechnicalSupervisionVisit
    ? resultA
    : activityType === SupervisionPlanItemType.AcquisitionTrustConsulting ||
      activityType === SupervisionPlanItemType.FinancialTrustConsulting ||
      activityType === SupervisionPlanItemType.TechnicalConsultancy
    ? resultB
    : resultC;
};

const renderValueOrInput = (isEdit: boolean, value: any, setValue: Function, placeholder: string, t: Function) => {
  return !isEdit ? (
    value ? (
      value
    ) : (
      '-'
    )
  ) : (
    <input
      className={styles.supervisionModalInput}
      value={value}
      onChange={e => setValue(e.target.value)}
      placeholder={t(placeholder)}
    />
  );
};

const SupervisionModalContent = ({
  isEdit,
  stateEdit,
  setStateEdit,
  handleSelectedUsers,
  locationEdit,
  setLocationEdit,
  consultantEdit,
  setConsultantEdit,
  activity,
  projectResponsibles,
  createReminder,
  handleSelectedEarlyWarning,
  earlyWarningList,
}: Props) => {
  const { t } = useTranslation();
  const stateOptionsEdit = [
    { value: SupervisionPlanItemState.Completed, label: t('supervision_plan_activity_state_3'), color: '#5EB890' },
    { value: SupervisionPlanItemState.InProgress, label: t('supervision_plan_activity_state_1'), color: '#264377' },
    { value: SupervisionPlanItemState.Pending, label: t('supervision_plan_activity_state_0'), color: '#666666' },
    { value: SupervisionPlanItemState.WithDelays, label: t('supervision_plan_activity_state_2'), color: '#E092A3' },
  ];

  const {
    state,
    plannedDate,
    personsInCharge,
    complianceDate,
    type,
    description,
    plannedBudget,
    fundingSource,
    operativeAlerts: relatedEarlyWarnings,
  } = activity;

  return (
    <>
      <div className={styles.infoContainer}>
        <div className={styles.infoItem}>
          <span className={styles.infoItemTitle}>{t('state')}</span>
          {handleSupervisionPlanActivityState(isEdit, state, t, stateOptionsEdit, stateEdit, setStateEdit)}
        </div>
        <div className={styles.infoItem}>
          <span className={styles.infoItemTitle}>{t('planned_date')}</span>
          <span className={styles.infoItemContent}>
            {handleDatesEmptyState(plannedDate)}
            {renderCreateReminder(createReminder, t)}
          </span>
        </div>

        <div className={styles.infoItem}>
          <span className={styles.infoItemTitle}>{handleInfoItemTitle(isEdit, t)}</span>
          {handleResponsible(isEdit, personsInCharge, projectResponsibles, handleSelectedUsers, t)}
        </div>

        <div className={styles.infoItem}>
          <span className={styles.infoItemTitle}>{t('supervision_modal_info_compliance_date')}</span>
          <span className={styles.infoItemContent}>{handleDatesEmptyState(complianceDate)}</span>
        </div>

        {type === SupervisionPlanItemType.CriticalProduct && (
          <div className={styles.infoItem}>
            <span className={styles.infoItemTitle}>{t('related_early_warning')}</span>
            {handleRelatedEarlyWarning(isEdit, relatedEarlyWarnings, earlyWarningList, handleSelectedEarlyWarning, t)}
          </div>
        )}
        {type !== SupervisionPlanItemType.CriticalProduct && (
          <>
            <div className={styles.infoItem}>
              <span className={styles.infoItemTitle}>
                {renderDataByActivityType(
                  type,
                  t('supervision_modal_info_location'),
                  t('role_id_16'),
                  t('description'),
                )}
              </span>
              <span className={styles.infoItemContent}>
                {renderDataByActivityType(
                  type,
                  renderValueOrInput(isEdit, locationEdit, setLocationEdit, 'enter_location', t),
                  renderValueOrInput(isEdit, consultantEdit, setConsultantEdit, 'enter_consultant_name', t),
                  description,
                )}
              </span>
            </div>
            <div className={styles.infoItem}>
              <span className={styles.infoItemTitle}>{t('related_early_warning')}</span>
              {handleRelatedEarlyWarning(isEdit, relatedEarlyWarnings, earlyWarningList, handleSelectedEarlyWarning, t)}
            </div>
            <div className={styles.infoItem}>
              <span className={styles.infoItemTitle}>{t('supervision_modal_info_funding_source')}</span>
              <span className={styles.infoItemContent}>
                {fundingSource !== null && fundingSource !== undefined ? t(`funding_source_${fundingSource}`) : '-'}
              </span>
            </div>
            <div className={styles.infoItem}>
              <span className={styles.infoItemTitle}>{t('supervision_modal_info_planned_cost')}</span>
              <span className={styles.infoItemContent}>
                {plannedBudget !== null && plannedBudget !== undefined ? (
                  <NumberFormat
                    value={plannedBudget}
                    displayType={'text'}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    decimalScale={2}
                    prefix={'USD '}
                  />
                ) : (
                  '-'
                )}
              </span>
            </div>
          </>
        )}
      </div>
      <hr className={styles.divider} />
    </>
  );
};

const handleSupervisionPlanActivityState = (isEdit, state, t, stateOptionsEdit, stateEdit, setStateEdit) => {
  if (!isEdit) {
    return <span className={styles[`infoItemContent${state}`]}>{t(`supervision_plan_activity_state_${state}`)}</span>;
  } else {
    return (
      <Select
        value={stateOptionsEdit.find(s => s.value === stateEdit)}
        options={stateOptionsEdit}
        styles={modalSelectStyle}
        onChange={value => setStateEdit(value.value)}
        isDisabled={state !== SupervisionPlanItemState.InProgress}
      />
    );
  }
};

const handleInfoItemTitle = (isEdit, t) => {
  if (isEdit) {
    return `${t('bid_responsible')} (${t('optional')})`;
  } else {
    return `${t('bid_responsible')}`;
  }
};

const handleResponsible = (isEdit, personsInCharge, projectResponsibles, handleSelectedUsers, t) => {
  if (!isEdit) {
    return personsInCharge && personsInCharge.length > 0 ? (
      personsInCharge.map(person => (
        <span
          key={person.applicationUserId}
          className={styles.infoItemContent}
        >{`${person.applicationUser.name} ${person.applicationUser.lastName}`}</span>
      ))
    ) : (
      <span className={styles.infoItemContent}>-</span>
    );
  } else {
    return (
      <Select
        isMulti
        defaultValue={getResponsibleOptions(personsInCharge)}
        options={projectResponsibles}
        components={{ DropdownIndicator }}
        styles={supervisionResponsibleSelectStyle}
        onChange={value => handleSelectedUsers(value)}
        placeholder={t('select_user')}
      />
    );
  }
};

const handleDatesEmptyState = value => (value ? replaceMomentFormat(moment(value).format('DD/MMM/YY')) : '-');

const renderCreateReminder = (createReminder, t) => {
  if (createReminder) {
    return (
      <LinkText label={t('create_reminder')} onClick={createReminder} customStyle={{ margin: '0px 0px 10px 40px' }} />
    );
  }
  return null;
};

const handleRelatedEarlyWarning = (isEdit, relatedEarlyWarning, earlyWarningList, handleSelectedEarlyWarning, t) => {
  const haveRelatedEarlyWarning = relatedEarlyWarning && relatedEarlyWarning.length > 0;

  if (!isEdit) {
    return haveRelatedEarlyWarning ? (
      <span className={styles.infoItemContent}>{relatedEarlyWarning[0].description}</span>
    ) : (
      <span className={styles.infoItemContent}>-</span>
    );
  } else {
    return (
      <Select
        isMulti={false}
        defaultValue={getEarlyWarningOptions(relatedEarlyWarning, t)}
        options={earlyWarningList}
        components={{ DropdownIndicator }}
        styles={supervisionResponsibleSelectStyle}
        onChange={value => handleSelectedEarlyWarning(value)}
      />
    );
  }
};

export default SupervisionModalContent;
