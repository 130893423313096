import React from 'react';
import './CalendarIndicator.scss';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import User from '../../../../../models/User';
import { useTranslation } from 'react-i18next';
import { months } from '../../../../Util/CustomDatePicker/CustomDatePicker';

type Props = {
  data: any;
};
export const CalendarIndicator = (data: Props) => {
  const { t } = useTranslation();
  const user = useSelector<any, User>(state => state.user);

  return (
    <div className="calendar-indicator-container ">
      {data && data.data ? (
        <div className="date-picker-container">
          <DatePicker
            inline={true}
            locale={user.language && user.language.encodedName ? user.language.encodedName : 'en'}
            disabled={true}
            highlightDates={data.data}
            formatWeekDay={nameOfDay => nameOfDay.substr(0, 1)}
            renderCustomHeader={({ date }) =>
              renderCustomHeaderContainer({
                date,
                t,
              })
            }
          />
        </div>
      ) : (
        <div className="no-data-msj-indicator">
          <span className="value-text">-</span>
        </div>
      )}
    </div>
  );
};

const renderCustomHeaderContainer = params => {
  return (
    <div className="custom-header-container">
      <div className="date-container">
        <div className="date-title">{`${params.t(months[params.date.getMonth()])} ${params.date.getFullYear()}`}</div>
      </div>
    </div>
  );
};
