import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Table.module.scss';
import { PmrClassificationComponent } from '../../../Util/PmrClassification/PmrClassification';
import { PmrPrintValue } from '../../../Util/PmrPrintValue/PmrPrintValue';
import { Pagination } from '@material-ui/lab';
import '../../../Util/Pagination/Pagination.scss';
import usePaginationSort from '../../../../hooks/usePaginationSort';
import NumberFormat from 'react-number-format';
import { showIndexTCDI } from '../../../../services/booleanUtil';
import { ProjectProductTypeClassification } from '../../../../models/ProjectProductTypeClassification';
import { TcdiClassificationComponent } from '../../../Util/TcdiClassification/TcdiClassification';
import { ProjectCounter } from '../../ProjectCounter/ProjectCounter';
import { CriteriaType } from '../../../../models/PaginationIndicator';
import useTable from '../../../../hooks/useTable';
import { addSortableColumn, handleTcdiValueOnTcdiComponent } from './Util';
import { ProjectsListSkeleton } from '../../../LoadersSkeletons/ProjectsListSkeleton/ProjectsListSkeleton';
import { ExcelDownloadButton } from '../../../Util/ExcelDownloadButton/ExcelDownloadButton';
import { Tooltip } from '@material-ui/core';
import useDataNormalize from '../../../../hooks/useDataNormalize';
import PaginationTable from '../../PaginationTable/PaginationTable';
import { withStyles } from '@material-ui/core/styles';
import { FilterTables } from '../../../Filter/FilterTables/FilterTables';

const BlackTooltip = withStyles({
  tooltip: {
    //   color: '$ci-color-greyscale-90',
    backgroundColor: '#101319',
    fontSize: '16px',
    fontFamily: 'Rubik',
    fontWeight: '400',
    lineHeight: '20px',
    padding: '8px 12px',
    borderRadius: '8px',
  },
  arrow: {
    fontSize: 20,
    color: '#4A4A4A',
    '&::before': {
      backgroundColor: '#101319',
    },
  },
})(Tooltip);

type Props = {
  hideLoader?: Function;
  isLoading?: boolean;
};

const headers = ['id', 'last_project', 'last_operation', 'name', 'total_approved_amount_usd', 'PMR/tcdi'];

const selectedKeys = ['id', 'code', 'loanNumber', 'name', 'financialInformation_totalDisbursementAmount', 'pmrValue'];

export const Table = ({ hideLoader, isLoading }: Props) => {
  const { t } = useTranslation();
  const [isTableLoading, setTableisLoading] = React.useState(true);

  const {
    container,
    tableHeader,
    tableRow,
    headerTitle,
    rowValue,
    rowList,
    rowValueName,
    fw,
    lastColumn,
    headerTitleClickable,
    borderTable,
    iconTex,
  } = styles;
  const { handleClick, projects } = useTable();
  const [updatedProjects, setUpdatedProjects] = useState(addSortableColumn([...projects]));
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [dataFilter, setDataFilter] = useState<any>(projects);

  useEffect(() => {
    setDataFilter(projects);
  }, [projects]);

  const { handlePaginationChange, handleSort, currentList, pagesCount, currentPage } = usePaginationSort({
    projects: dataFilter,
    defaultSortCriteria: 'name',
    pageCount: rowsPerPage,
  });

  const flattenObject = (obj, parentKey = '', result = {}) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        let propName = parentKey ? parentKey + '_' + key : key;

        if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
          flattenObject(obj[key], propName, result);
        } else {
          result[propName] = obj[key];
        }
      }
    }
    return result;
  };
  const projectsFlatten = projects.map((projects: any) => flattenObject(projects));
  const data = useDataNormalize(headers, selectedKeys, dataFilter);

  useEffect(() => {
    setUpdatedProjects(addSortableColumn([...projects]));
    if (hideLoader && projects.length) {
      hideLoader();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects]);

  // if (isLoading) {
  //   return <ProjectsListSkeleton hideLastColumn={true} />;
  // }

  useEffect(() => {
    setTableisLoading(true);
    if (updatedProjects.length > 0) {
      setTimeout(() => {
        setTableisLoading(false);
      }, 2000);
    }
  }, [updatedProjects]);

  return isTableLoading ? (
    <div className="pmr-alert-problem-last-three-years__loading_container">
      <ProjectsListSkeleton />
    </div>
  ) : updatedProjects.length ? (
    <div className={container}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <ExcelDownloadButton headers={headers} selectedKeys={selectedKeys} dataExcel={data} />
        <FilterTables
          projects={projects}
          keysFilter={selectedKeys}
          fnDataFilter={setDataFilter}
          disabledNumber={currentPage}
          indicatorKey={'PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS_BY_COUNTRY'}
        />
      </div>

      <div className={borderTable}>
        <div className={tableHeader}>
          <h4 className={headerTitleClickable} onClick={() => handleSort('code', CriteriaType.string)}>
            {t('project')}
          </h4>
          <h4 className={headerTitleClickable} onClick={() => handleSort('loanNumber', CriteriaType.string)}>
            {t('operation')}
          </h4>
          <h4 className={headerTitleClickable} onClick={() => handleSort('name', CriteriaType.string)}>
            {t('project_name')}
          </h4>
          <h4
            className={headerTitleClickable}
            onClick={() => handleSort('totalDisbursementAmount', CriteriaType.nested, 'financialInformation')}
          >
            {`${t('total_approved_amount_usd')} ${t('dolar_sign_parentesis')}`}
          </h4>
          <h4 className={headerTitleClickable} onClick={() => handleSort('tcdiPmrValue', CriteriaType.decimalNumber)}>
            {`${t('pmr_initials')}/${t('tcdi_initials')}`}
          </h4>
        </div>
        <ul className={rowList}>
          {dataFilter?.length === 0
            ? dataFilter?.map(project => (
                <li className={tableRow} key={`${project.loanNumber}${project.code}`}>
                  <BlackTooltip title={t('go_to_project_info')} arrow>
                    <p className={`${rowValue} link-project`} onClick={() => handleClick(project)}>
                      {project.code}
                    </p>
                  </BlackTooltip>
                  <p className={rowValue}>{project.loanNumber}</p>
                  <BlackTooltip title={project.name || ''} placement="bottom" enterDelay={500} leaveDelay={100} arrow>
                    <p className={rowValueName}>{project.name}</p>
                  </BlackTooltip>
                  <p className={rowValue}>
                    <NumberFormat
                      value={project.financialInformation.totalDisbursementAmount}
                      displayType={'text'}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                    />
                  </p>
                  <p className={rowValue}>
                    {showIndexTCDI(
                      project.projectProductType,
                      project &&
                        project.projectProductTypeClassification &&
                        project.projectProductTypeClassification.code ===
                          ProjectProductTypeClassification.IGR_GREATER_THAN_3MM,
                    ) ? (
                      <>
                        <div className={iconTex}>
                          <TcdiClassificationComponent
                            clasification={handleTcdiValueOnTcdiComponent(project.tcdiValue)}
                          />
                          <span className="pmr-classification-margin-left">
                            {project.tcdiValue === null ? t('not_assigned_initials') : project.tcdiValue}
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={iconTex}>
                          <PmrClassificationComponent classificationColor={project.pmrClassification} />
                          <span className="pmr-classification-margin-left">
                            <PmrPrintValue pmrClassi={project.pmrClassification} pmrValue={project.pmrValue} />
                          </span>
                        </div>
                      </>
                    )}
                  </p>
                </li>
              ))
            : currentList?.map(project => (
                <li className={tableRow} key={`${project.loanNumber}${project.code}`}>
                  <BlackTooltip title={t('go_to_project_info')} arrow>
                    <p className={`${rowValue} link-project`} onClick={() => handleClick(project)}>
                      {project.code}
                    </p>
                  </BlackTooltip>
                  <p className={rowValue}>{project.loanNumber}</p>
                  <BlackTooltip title={project.name || ''} placement="bottom" enterDelay={500} leaveDelay={100} arrow>
                    <p className={rowValueName}>{project.name}</p>
                  </BlackTooltip>
                  <p className={rowValue}>
                    <NumberFormat
                      value={project.financialInformation.totalDisbursementAmount}
                      displayType={'text'}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                    />
                  </p>
                  <p className={rowValue}>
                    {showIndexTCDI(
                      project.projectProductType,
                      project &&
                        project.projectProductTypeClassification &&
                        project.projectProductTypeClassification.code ===
                          ProjectProductTypeClassification.IGR_GREATER_THAN_3MM,
                    ) ? (
                      <>
                        <div className={iconTex}>
                          <TcdiClassificationComponent
                            clasification={handleTcdiValueOnTcdiComponent(project.tcdiValue)}
                          />
                          <span className="pmr-classification-margin-left">
                            {project.tcdiValue === null ? t('not_assigned_initials') : project.tcdiValue}
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={iconTex}>
                          <PmrClassificationComponent classificationColor={project.pmrClassification} />
                          <span className="pmr-classification-margin-left">
                            <PmrPrintValue pmrClassi={project.pmrClassification} pmrValue={project.pmrValue} />
                          </span>
                        </div>
                      </>
                    )}
                  </p>
                </li>
              ))}
        </ul>
      </div>
      {/* <ProjectCounter arrayToCount={projects} countProjects={true} /> */}
      <div className="pagination-style">
        <PaginationTable fnSetRows={setRowsPerPage} rows={rowsPerPage} numProyects={dataFilter?.length} />
        {dataFilter?.length !== 0 ? (
          <Pagination count={pagesCount} page={currentPage} onChange={handlePaginationChange} />
        ) : null}
      </div>
    </div>
  ) : null;
};
