import React from 'react';
import './../ContentManager.scss';
import { Accordion, AccordionDetails, AccordionSummary, withStyles } from '@material-ui/core';

const borderBottomColor = '1px solid #C1C7D0';
const accordionWidth = '915px';

const ContentsAccordion = withStyles({
  root: {
    backgroundColor: '#F9F9F9',
    borderRadius: '10px',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      marginBottom: '20px',
    },
    marginBottom: '20px',
    width: accordionWidth,
  },
  expanded: {},
})(Accordion);

export const HelpCenterFormAccordion = withStyles({
  root: {
    width: '100%',
    borderBottom: borderBottomColor,
    boxShadow: 'none',
    '&$expanded': {
      marginBottom: '0px',
      marginTop: '0px',
    },
  },
  expanded: {},
})(Accordion);

export const TranslationsIndicatorAccordionSummary = withStyles({
  root: {
    '&$expanded': {
      height: 48,
      margin: '0px',
      borderBottom: borderBottomColor,
      minHeight: '0px',
    },
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    color: '#727E8C',
    fontWeight: 500,
    fontSize: 16,
    backgroundColor: '#F7F7F7',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  },
  content: {
    margin: '0px',
  },
  expanded: {
    minHeight: '0px',
  },
})(AccordionSummary);

export const HelpCenterFormAccordionSummary = withStyles({
  root: {
    '&$expanded': {
      height: 48,
      margin: '0px',
      borderBottom: borderBottomColor,
      minHeight: '0px',
    },
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    color: '#727E8C',
    fontWeight: 500,
  },
  content: {
    margin: '0px',
  },
  expanded: {
    minHeight: '0px',
  },
})(AccordionSummary);

const ContentsAccordionSummary = withStyles({
  root: {
    minHeight: 75,
    '&$expanded': {
      minHeight: 75,
    },
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    color: '#727E8C',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '24px',
    width: accordionWidth,
  },
  content: {},
  expanded: {},
})(AccordionSummary);

const ContentsAccordionDetails = withStyles({
  root: {
    display: 'block',
  },
})(AccordionDetails);

type Props = {
  accordionSummary: any;
  children: any;
  customStyle?: any;
  onChange?: any;
  expanded?: boolean;
  style?: any;
};

const ContentManagerAccordion = ({
  accordionSummary,
  children,
  customStyle = {},
  onChange = (e, expanded) => {},
  expanded = false,
  style = {},
}: Props) => {
  return (
    <>
      <ContentsAccordion onChange={onChange} expanded={expanded} style={style || {}}>
        <ContentsAccordionSummary style={customStyle}>{accordionSummary}</ContentsAccordionSummary>
        <ContentsAccordionDetails>{children}</ContentsAccordionDetails>
      </ContentsAccordion>
    </>
  );
};

export default ContentManagerAccordion;
