
export const mockObjFilter =
{
    "common": {
        "started": false,
        "projectTypeFilters": [
            3,
            1,
            0
        ],
        "executionFilter": null,
        "taxonomyFilter": null,
        "excludeRegionals": false,
        "divisionId": [],
        "coReponsibleDivisions": [],
        "participantDivisions": [],
        "pmrId": [
            {
                "PmrClassification": 0,
                "selected": false
            },
            {
                "PmrClassification": 1,
                "selected": false
            },
            {
                "PmrClassification": 2,
                "selected": false
            },
            {
                "PmrClassification": 3,
                "selected": false
            }
        ],
        "timeWithoutDisbursementFrom": 0,
        "timeWithoutDisbursementTo": 0,
        "ageProjectFrom": 0,
        "ageProjectTo": 0,
        "ageProjectMonths": true,
        "disbursementProjectionFrom": 0,
        "disbursementProjectionTo": 0,
        "disbursementProjectionCompleted": null,
        "regionId": [],
        "countryId": [],
        "isAnnual": "accumulated",
        "itSearch": false,
        "sectors": [],
        "teamLeaders": [],
        "interestIssues": []
    },
    "checkedItems": {
        "LON": true,
        "INV": true,
        "PBL": true,
        "GRF": true,
        "SDL": true,
        "TCP": true,
        "Operation Support": true,
        "Client Support": true,
        "Research and Dissemination": true,
        "GUA": true,
        "IGR": true,
        "Research And Dissemination": true
    },
    "indicatorsFilter": {
        "menu": [
            {
                "id": "global_bid",
                "label": "BID_GLOBAL"
            }
        ],
        "menuValue": "global_bid"
    },
    "optionSelectedApplied": 2,
    "detailFilters": {
        "searchTerm": "",
        "stateFilter": "all",
        "sourceFilter": "all"
    }
}