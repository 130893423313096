import {
  ProjectReportSectionsActionTypes,
  SET_PROJECT_REPORT_SECTIONS,
  RESOLVE_PROJECT_REPORT_SECTIONS,
} from '../actions/types';
import { KeyValueBoolean } from '../../models/KeyValueBoolean';
import { ReportSectionsEnum } from '../../models/ReportEnums';

const initialState: KeyValueBoolean[] = [
  { key: ReportSectionsEnum.baseInfo, value: true, resolved: false },
  { key: ReportSectionsEnum.planning, value: true, resolved: false },
  { key: ReportSectionsEnum.accordances, value: true, resolved: false },
  { key: ReportSectionsEnum.financial, value: true, resolved: false },
  { key: ReportSectionsEnum.risk, value: true, resolved: false },
];

const projectReportSectionsReducer = (
  state = initialState,
  action: ProjectReportSectionsActionTypes,
): KeyValueBoolean[] => {
  switch (action.type) {
    case SET_PROJECT_REPORT_SECTIONS:
      return action.payload;
    case RESOLVE_PROJECT_REPORT_SECTIONS:
      return state.map(item => {
        if (item.key === action.payload) {
          item.resolved = true;
        }
        return item;
      });
    default:
      return state;
  }
};

export default projectReportSectionsReducer;
