import { ProjectDetailsTab } from './ProjectDetailsTabEnum';

export enum EventType {
  Disbursement = 0,
  Acquisition,
  Activity,
  MonitoringAccordance,
  RevisionAccordance,
  Mission,
  ExternalEvent,
  Lifecycle,
  InterGroup,
  SupervisoryAction,
}

// Maps to which tab should be redirected from events details
export const eventTypeProjectTab = {
  [EventType.Disbursement]: ProjectDetailsTab.FINANCIAL,
  [EventType.Acquisition]: ProjectDetailsTab.SUPERVISION,
  [EventType.Activity]: ProjectDetailsTab.SUPERVISION,
  [EventType.MonitoringAccordance]: ProjectDetailsTab.AGREEMENTS,
  [EventType.RevisionAccordance]: ProjectDetailsTab.AGREEMENTS,
  [EventType.Mission]: ProjectDetailsTab.SUPERVISION,
  [EventType.ExternalEvent]: ProjectDetailsTab.SUPERVISION,
  [EventType.Lifecycle]: ProjectDetailsTab.SUPERVISION,
  [EventType.InterGroup]: ProjectDetailsTab.AGREEMENTS,
  [EventType.SupervisoryAction]: ProjectDetailsTab.SUPERVISION,
};
