import React from 'react';
import './SelectReportType.scss';
import { useTranslation } from 'react-i18next';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { CustomizableReportType } from '../../../../../models/CustomizableReportType';
import SaveButton from '../../../Buttons/SaveButton/SaveButton';
import { useCustomizableReportsContext } from '../CustomizableReportsContext';
import { HelpTooltip } from '../../../Tooltip/HelpTooltip/HelpTooltip';

type Props = {
  next: Function;
};

export const SelectReportType = ({ next }: Props) => {
  const { t } = useTranslation();
  const [context, setContext] = useCustomizableReportsContext();

  const handleNext = () => {
    if (!context.reportType) {
      return;
    }

    next();
  };

  const reportTypes = [
    {
      id: 1,
      label: t('predefined_report'),
      value: CustomizableReportType.Predefined,
      tooltipMessage: t('predefined_report_tooltip_message'),
    },
    {
      id: 2,
      label: t('custom_report'),
      value: CustomizableReportType.Custom,
      tooltipMessage: t('custom_report_tooltip_message'),
    },
  ];

  const handleChange = event => {
    setContext({ ...context, reportType: event.target.value });
  };

  return (
    <div className="select-report-type">
      <p className="modal-title">
        {t('customizable_report_title')} / <span>{t('customizable_report_step_1')}</span>
      </p>

      <div className="modal-content">
        <h3>{t('customizable_report_step_1_title')}</h3>

        <FormControl className="radio-buttons-section">
          <RadioGroup aria-label="reportType" name="reportType" value={context.reportType} onChange={handleChange}>
            {reportTypes.map((reportType, index) => (
              <div style={{ display: 'inline-flex' }} key={reportType.id}>
                <FormControlLabel
                  className="radio-label"
                  value={reportType.value}
                  control={<Radio color="primary" />}
                  label={reportType.label}
                />
                <HelpTooltip title={reportType.label} message={reportType.tooltipMessage} />
              </div>
            ))}
          </RadioGroup>
        </FormControl>

        <div className="buttons-section">
          <SaveButton handleClick={handleNext} disabled={!context.reportType} customText={t('next')} />
        </div>
      </div>
    </div>
  );
};
