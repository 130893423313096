import React from 'react';
import { useTranslation } from 'react-i18next';
import './SelectFilter.scss';

type Props = {
  labelValue: any;
  options?: any;
  filterValue?: any;
  filterType?: any;
};
export const SelectFilters = ({ labelValue, options, filterValue, filterType }: Props) => {
  const { t } = useTranslation();
  const [selectValue, setSelectValue] = React.useState('0');

  const selectHandler = (event: any) => {
    const value = event.target.value;
    setSelectValue(value);
    filterValue(value, filterType);
  };

  React.useEffect(() => {
    setSelectValue('0');
  }, [options]);

  return (
    <div className="select-filter-container">
      <span className="select-filter-data-text">{labelValue}</span>
      <select
        data-testid="select"
        value={selectValue}
        className={'select-css select-width-large'}
        onChange={selectHandler}
      >
        <option value="0">{t('all')}</option>
        {options.map(option => (
          <option
            key={filterType === 'Y' ? option.optionLabel : option.value}
            value={filterType === 'Y' ? option.optionLabel : option.value}
            className="capitalize"
          >
            {option.optionLabel}
          </option>
        ))}
      </select>
    </div>
  );
};
