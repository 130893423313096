import React from 'react';
import { useTranslation } from 'react-i18next';
import './LogoBidCartera.scss';
import { ReactComponent as LogoCartera } from '../../../assets/logo-cartera.svg';

type Props = {
  customStyle?: any;
  onClick?: any;
  isContentManager?: any;
};

const LogoBidCartera = ({ customStyle, onClick, isContentManager }: Props) => {
  const { t } = useTranslation(isContentManager && 'contentManager');
  return (
    <div className="logo-bid-cartera-container" style={customStyle || {}}>
      <LogoCartera onClick={onClick || undefined} style={onClick ? { cursor: 'pointer' } : {}} className="logo" />
      <div className="text-container">
        <h3>{t(`app_name${isContentManager ? '_content' : ''}`)}</h3>
        {isContentManager || <p>{t('logo_slogan')}</p>}
      </div>
    </div>
  );
};

export default LogoBidCartera;
