import React, { useEffect, useState } from 'react';
import '../AcquisitionsMilestonesPending.scss';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { ProjectsListSkeleton } from '../../../LoadersSkeletons/ProjectsListSkeleton/ProjectsListSkeleton';
import { ReactComponent as IconSort } from '../../../../assets/icons/u_sort.svg';
import PaginationTable from '../../PaginationTable/PaginationTable';
import { Pagination } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import useTable from '../../../../hooks/useTable';
import { replaceMomentFormat } from '../../../../services/util';
import usePaginationSort from '../../../../hooks/usePaginationSort';
import NumberFormat from 'react-number-format';
import { Tooltip, withStyles } from '@material-ui/core';
import { CriteriaType } from '../../../../models/PaginationIndicator';
import useDataNormalize from '../../../../hooks/useDataNormalize';
import { ExcelDownloadButton } from '../../../Util/ExcelDownloadButton/ExcelDownloadButton';
import { FilterTables } from '../../../Filter/FilterTables/FilterTables';

const BlackTooltip = withStyles({
  tooltip: {
    //   color: '$ci-color-greyscale-90',
    backgroundColor: '#101319',
    fontSize: '16px',
    fontFamily: 'Rubik',
    lineHeight: '20px',
    padding: '8px 12px',
    borderRadius: '8px',
  },
  arrow: {
    fontSize: 20,
    color: '#4A4A4A',
    '&::before': {
      backgroundColor: '#101319',
    },
  },
})(Tooltip);

const headers = [
  'last_project',
  'last_operation',
  'new_id_process',
  'new_description_process',
  'new_estimated_amount',
  'last_type',
  'last_methodo',
  'last_revision',
  'state',
  'new_estimated_publication_date',
  'new_actual_publication_date',
  'new_estimated_hiring_date',
];

const selectedKeys = [
  'code',
  'loanNumber',
  'field1',
  'field2',
  'field3',
  'field4',
  'field5',
  'field6',
  'field7',
  'field8',
  'field9',
  'field10',
];

const headerValue = [
  {
    headerValue: 'project',
    headerCode: 'code',
  },
  {
    headerValue: 'operation',
    headerCode: 'loanNumber',
  },
  {
    headerValue: 'new_id_process',
    headerCode: 'field1',
  },
  {
    headerValue: 'new_description_process',
    headerCode: 'field2',
  },
  {
    headerValue: 'new_estimated_amount',
    headerCode: 'field3',
  },
  {
    headerValue: 'new_type_method_revision',
    headerCode: 'field4',
  },
  {
    headerValue: 'state',
    headerCode: 'field7',
  },
  {
    headerValue: 'new_estimated_publication_date',
    headerCode: 'field8',
  },
  {
    headerValue: 'new_actual_publication_date',
    headerCode: 'field9',
  },
  {
    headerValue: 'new_estimated_hiring_date',
    headerCode: 'field10',
  },
];

type Props = {
  dataTable: any;
};

export default function AcquisitionsMilestonesPendingTable({ dataTable }: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { handleClick } = useTable();

  const [dataFilter, setDataFilter] = useState<any>(dataTable);

  useEffect(() => {
    setDataFilter(dataTable);
  }, [dataTable]);

  const { handlePaginationChange, handleSort, currentList, pagesCount, currentPage } = usePaginationSort({
    projects: dataFilter,
    defaultSortCriteria: 'code',
    pageCount: rowsPerPage,
  });

  const { t } = useTranslation();

  const sortTable = (keyToSort, fieldType) => {
    let criteriaType: CriteriaType;
    switch (fieldType) {
      case 'numeric':
      case 'money':
      case 'percentage':
      case 'no_round_two_decimal':
        criteriaType = CriteriaType.number;
        break;
      case 'date':
        criteriaType = CriteriaType.date;
        break;

      default:
        criteriaType = CriteriaType.string;
        break;
    }
    handleSort(keyToSort, criteriaType);
  };

  const dateColorEstimationPublication = () => {
    dataTable?.map((element, i) => {
      const estimateDate = moment(element.field8).format();
      const actualDate = moment(element.actualPublicationDate).format();
      let currentDate = new Date();
      if (new Date(estimateDate) < new Date(currentDate) && !element.field9) {
        element.colorEstimationPublication = 'alerColorAdquisition';
      }
    });
  };

  const dateColorEstimationHiring = () => {
    dataTable?.map((element, i) => {
      if (element.field10) {
        const estimatedHiringDate = moment(element.field10).format();
        const actualDate = moment(element.field9).format();
        let currentDate = new Date();
        if (new Date(estimatedHiringDate) < new Date(currentDate)) {
          element.colorEstimationHiring = 'alerColorAdquisition';
        }
      }
    });
  };

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    dateColorEstimationPublication();
    dateColorEstimationHiring();
  }, [dataTable]);

  const data = useDataNormalize(headers, selectedKeys, dataFilter);

  return (
    <div className="indicator-detail-table-container">
      <TableContainer>
        {isLoading ? (
          <ProjectsListSkeleton />
        ) : (
          <>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <ExcelDownloadButton headers={headers} selectedKeys={selectedKeys} dataExcel={data} />
              <FilterTables
                projects={dataTable}
                keysFilter={selectedKeys}
                fnDataFilter={setDataFilter}
                disabledNumber={currentPage}
                indicatorKey={'ACQUISITION_WITH_MILESTONES_PENDING_TO_DATE'}
              />
            </div>
            <div className="border-table">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell onClick={() => sortTable(headerValue[0].headerCode, 'string')}>
                      <div className="text-head">
                        <p>{t(headerValue[0].headerValue)}</p> <IconSort className="sortIcon" />
                      </div>
                    </TableCell>
                    <TableCell onClick={() => sortTable(headerValue[1].headerCode, 'string')}>
                      <div className="text-head">
                        <p>{t(headerValue[1].headerValue)}</p> <IconSort className="sortIcon" />
                      </div>
                    </TableCell>
                    <TableCell onClick={() => sortTable(headerValue[2].headerCode, 'string')}>
                      <div className="text-head">
                        <p>{t(headerValue[2].headerValue)}</p> <IconSort className="sortIcon" />
                      </div>
                    </TableCell>
                    <TableCell onClick={() => sortTable(headerValue[3].headerCode, 'string')}>
                      <div className="text-head">
                        <p>{t(headerValue[3].headerValue)}</p> <IconSort className="sortIcon" />
                      </div>
                    </TableCell>
                    <TableCell onClick={() => sortTable(headerValue[4].headerCode, 'numeric')}>
                      <div className="text-head">
                        <p>{t(headerValue[4].headerValue)}</p> <IconSort className="sortIcon" />
                      </div>
                    </TableCell>
                    <TableCell onClick={() => sortTable(headerValue[5].headerCode, 'string')}>
                      <div className="text-head">
                        <p>{t(headerValue[5].headerValue)}</p> <IconSort className="sortIcon" />
                      </div>
                    </TableCell>
                    <TableCell onClick={() => sortTable(headerValue[6].headerCode, 'string')}>
                      <div className="text-head">
                        <p>{t(headerValue[6].headerValue)}</p> <IconSort className="sortIcon" />
                      </div>
                    </TableCell>
                    <TableCell onClick={() => sortTable(headerValue[7].headerCode, 'date')}>
                      <div className="text-head">
                        <p>{t(headerValue[7].headerValue)}</p> <IconSort className="sortIcon" />
                      </div>
                    </TableCell>
                    <TableCell onClick={() => sortTable(headerValue[8].headerCode, 'date')}>
                      <div className="text-head">
                        <p>{t(headerValue[8].headerValue)}</p> <IconSort className="sortIcon" />
                      </div>
                    </TableCell>
                    <TableCell onClick={() => sortTable(headerValue[9].headerCode, 'date')}>
                      <div className="text-head">
                        <p>{t(headerValue[9].headerValue)}</p> <IconSort className="sortIcon" />
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataTable?.length === 0 || dataFilter?.length === 0
                    ? dataFilter?.map((value, i) => (
                        <TableRow key={i}>
                          <TableCell className="link-project" onClick={() => handleClick(value)}>
                            <BlackTooltip
                              title={t('go_to_project_info')}
                              placement="bottom"
                              enterDelay={500}
                              leaveDelay={100}
                              arrow
                            >
                              <p>{value.code}</p>
                            </BlackTooltip>
                          </TableCell>
                          <TableCell>{value.loanNumber}</TableCell>
                          <TableCell style={{ width: '11rem' }}>{value.field1}</TableCell>
                          <TableCell>
                            <BlackTooltip
                              title={value.field2}
                              placement="bottom"
                              enterDelay={500}
                              leaveDelay={100}
                              arrow
                            >
                              <p className=" desc"> {value.field2}</p>
                            </BlackTooltip>
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={value.field3}
                              displayType={'text'}
                              thousandSeparator={','}
                              decimalSeparator={'.'}
                              decimalScale={2}
                            />
                          </TableCell>
                          <TableCell>{`${value.field4 + '/' + value.field5 + '/' + value.field6}`}</TableCell>
                          <TableCell>{t(value.field7)}</TableCell>
                          <TableCell className={value.colorEstimationPublication}>
                            {value.field8 ? replaceMomentFormat(moment(value.field8).format('DD/MMM/YYYY')) : '-'}
                          </TableCell>
                          <TableCell>
                            {value.field9 ? replaceMomentFormat(moment(value.field9).format('DD/MMM/YYYY')) : '-'}
                          </TableCell>
                          <TableCell className={value.colorEstimationHiring}>
                            {value.field10 ? replaceMomentFormat(moment(value.field10).format('DD/MMM/YYYY')) : '-'}
                          </TableCell>
                        </TableRow>
                      ))
                    : currentList?.map((value, i) => (
                        <TableRow key={i}>
                          <TableCell className="link-project" onClick={() => handleClick(value)}>
                            <BlackTooltip
                              title={t('go_to_project_info')}
                              placement="bottom"
                              enterDelay={500}
                              leaveDelay={100}
                              arrow
                            >
                              <p>{value.code}</p>
                            </BlackTooltip>
                          </TableCell>
                          <TableCell>{value.loanNumber}</TableCell>
                          <TableCell style={{ width: '11rem' }}>{value.field1}</TableCell>
                          <TableCell>
                            <BlackTooltip
                              title={value.field2}
                              placement="bottom"
                              enterDelay={500}
                              leaveDelay={100}
                              arrow
                            >
                              <p className=" desc"> {value.field2}</p>
                            </BlackTooltip>
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={value.field3}
                              displayType={'text'}
                              thousandSeparator={','}
                              decimalSeparator={'.'}
                              decimalScale={2}
                            />
                          </TableCell>
                          <TableCell>{`${value.field4 + '/' + value.field5 + '/' + value.field6}`}</TableCell>
                          <TableCell>{t(value.field7)}</TableCell>
                          <TableCell className={value.colorEstimationPublication}>
                            {value.field8 ? replaceMomentFormat(moment(value.field8).format('DD/MMM/YYYY')) : '-'}
                          </TableCell>
                          <TableCell>
                            {value.field9 ? replaceMomentFormat(moment(value.field9).format('DD/MMM/YYYY')) : '-'}
                          </TableCell>
                          <TableCell className={value.colorEstimationHiring}>
                            {value.field10 ? replaceMomentFormat(moment(value.field10).format('DD/MMM/YYYY')) : '-'}
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </div>
          </>
        )}
      </TableContainer>
      <div className="pagination-style">
        <PaginationTable fnSetRows={setRowsPerPage} rows={rowsPerPage} numProyects={dataFilter?.length} />
        {dataFilter?.length !== 0 ? (
          <Pagination
            count={dataTable.length === 0 ? 1 : pagesCount}
            page={currentPage}
            onChange={handlePaginationChange}
          />
        ) : null}
      </div>
    </div>
  );
}
