import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './IndicatorsRecommended.module.scss';
import SaveButton from '../../../Util/Buttons/SaveButton/SaveButton';
import { buttonText } from '../util';
import Select from 'react-select';
import { DropdownIndicator, multiSelectStyle } from '../../../Filter/FilterComponent.Styles';
import Header from '../Header/Header';
import { FormControl, InputLabel, MenuItem } from '@material-ui/core';


type Props = {
  indicator: any;
  handleSelectChange: (selectIndex: number, value: any) => void;
  index: number;
  indicatorOptions: {
    sortOrder: any;
    id: any; value: number, label: string
  }[];
  transformedIndicators: any[];
  onSave: (index: number) => void;
};

const IndicatorsRecommended = ({ indicator, handleSelectChange, indicatorOptions, index, transformedIndicators, onSave }: Props) => {
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [selectedValues, setSelectedValues] = useState<any[]>([]);

  useEffect(() => {
    initializeSelectedValues();
    setEditMode(false);
  }, [indicator]);

  const initializeSelectedValues = () => {
    const initialSelectedValues = indicatorOptions.map((option) => {
      const recommendedIndicator = indicator.listRecommendedIndicators.find(
        (item) => item.sortOrder === option.sortOrder
      );
      if (recommendedIndicator) {
        const selectedIndicator = transformedIndicators.find(
          (transformed) => transformed.id === recommendedIndicator.indicatorId
        );
        return selectedIndicator ? selectedIndicator : null;
      }
      return null;
    });
    setSelectedValues(initialSelectedValues);
  };

  const handleChange = (selectIndex: number, selectedOption: any) => {

    const newSelectedValues = [...selectedValues];
    newSelectedValues[selectIndex] = selectedOption;
    setSelectedValues(newSelectedValues);
    handleSelectChange(selectIndex, selectedOption);
  };

  const buttonPress = () => {
    if (editMode) {
      onSave(index);
      setEditMode(false);
    } else {
      setEditMode(true);
    }
  };

  const getSelectedValue = (selectIndex: number) => {
    const recommendedIndicator = indicator.listRecommendedIndicators.find(
      (item) => item.sortOrder === indicatorOptions[selectIndex].sortOrder
    );
    if (recommendedIndicator) {
      return transformedIndicators.find(
        (option) => option.id === recommendedIndicator.indicatorId
      );
    }
    return null;
  };


  const getFilteredOptionsNew = (selectIndex: number) => {
    const selectedIds = selectedValues.map((value) => value?.id).filter(Boolean);
    return [clearOption, ...transformedIndicators.filter(option =>
      !selectedIds.includes(option.id) &&
      option.id !== indicator.id // Exclude the current row's indicator
    )];
  };

  const clearOption = { value: null, label: `${t('last_clear_selection')}` };

  return (
    <div className='details'>
      <div className='header-indicator'>
        <Header editMode={true} />
      </div>

      <div className="table-row" key={'last_indicator_title'}>
        <div className="title">{t('last_indicator_title')}</div>

        {indicatorOptions.map((option, selectIndex) => (
          <FormControl fullWidth margin="normal" key={selectIndex}>
            <div className="label-select-container">
              <Select
                id={`indicator-select-${selectIndex}`}
                components={{ DropdownIndicator }}
                options={getFilteredOptionsNew(selectIndex)}
                value={getSelectedValue(selectIndex)}
                onChange={(selectedOption) => handleChange(selectIndex, selectedOption)}
                classNamePrefix="All"
                className="basic-multi-select"
                placeholder={t('contents_select_placeholder_search')}
                isDisabled={!editMode}
              />
            </div>
          </FormControl>
        ))}

      </div>

      <div className='footer'>
        <SaveButton
          customText={t(buttonText(editMode))}
          handleClick={buttonPress}
          customStyle={{}}
        />
      </div>
    </div>
  );
};

export default IndicatorsRecommended;
