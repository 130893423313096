import React from 'react';
import { IndicatorKeyEnum } from '../../../../../models/IndicatorKeyEnum';
import { ReportImageDisplay } from '../../../../Util/Modals/CustomizableReports/CustomizableReports';
import GcmAlertProgressChartElement from './GcmAlertProgressChartElement/GcmAlertProgressChartElement';
import PcrNextPresentationChartElement from './PcrNextPresentationChartElement/PcrNextPresentationChartElement';
import PmrValidationStateChartElement from './PmrValidationStateChartElement/PmrValidationStateChartElement';

type Props = {
  data: any[];
  isDetail?: boolean;
  handleClick?: Function;
  indicatorKey?: string;
  forReport?: ReportImageDisplay;
};

const ProgressChart = ({ data, isDetail = false, handleClick, indicatorKey, forReport }: Props) => {
  switch (indicatorKey) {
    case IndicatorKeyEnum.PMR_VALIDATION_STATE:
      return <PmrValidationStateChartElement isDetail={isDetail} handleClick={handleClick} data={data} />;

    case IndicatorKeyEnum.GCM_ALERTS:
      return (
        <GcmAlertProgressChartElement isDetail={isDetail} handleClick={handleClick} data={data} forReport={forReport} />
      );
    case IndicatorKeyEnum.PCR_NEXT_PRESENTATION:
      return <PcrNextPresentationChartElement isDetail={isDetail} data={data} forReport={forReport} />;
    default:
      return <></>;
  }
};

export default ProgressChart;
