import React, { createContext, useEffect, useMemo } from 'react';
import './AccordanceTrello.scss';
import Board from 'react-trello';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AccordanceCard from '../AccordanceCard/AccordanceCard';
import AccordanceCancel from '../../../../Util/Modals/AccordanceCancel/AccordanceCancel';
import AccordanceFulfilled from '../../../../Util/Modals/AccordanceFulfilled/AccordanceFulfilled';
import { getEvents, getValidity, newDataAfterDrag, translateLaneTitle } from './util';
import { isMobile } from '../../../../../services/booleanUtil';
import CollapsedEements from './CollapsedElements/CollapsedElements';
import CustomHeader from './CustomHeader/CustomHeader';
import Project from '../../../../../models/Project';
import { useEventContext } from '../../../../../context/EventContext';

export enum modalType {
  Cancel,
  FulFilled,
  NewAccordance,
}

type Props = {
  data: any;
  updateData: Function;
  boardStyle: any;
};

interface defaultValueProps {
  events: string[];
  validity: string[];
}

const defaultValue: defaultValueProps = {
  events: [],
  validity: [],
};

// export const eventContext = createContext(defaultValue);

const TrelloBoard = (props: Props) => {
  const [showCancelModal, setShowCancelModal] = React.useState(false);
  const [showFulfilledModal, setShowFulfilledModal] = React.useState(false);
  const [dragEndData, setDragEndData] = React.useState<any>(null);
  const [eventBus, setEventBus] = React.useState<any>(undefined);
  // const [eventId, setEventId] = React.useState<any>(undefined);
  const [events, setEvents] = React.useState<any>(undefined);
  const [validity, setValidity] = React.useState<any>(undefined);
  const [eventsCard, setEventsCard] = React.useState<any>(undefined);
  const currentProject = useSelector<any, Project>(state => state.currentProject);

  const { t } = useTranslation();
  const { data, updateData } = props;

  const { getDataEvent, setEventId, eventId, getDataValidity } = useEventContext();

  React.useEffect(() => {
    data.lanes.map(lane => {
      return translateLaneTitle(lane, t);
    });
  }, [data, t]);

  const updateDataCloseModal = (undoChanges: boolean) => {
    if (undoChanges) {
      const newLaneData = [...data.lanes];
      if (eventBus) {
        eventBus.publish({ type: 'UPDATE_LANES', lanes: newLaneData });
      }
    } else {
      updateDataAfterDragEnd();
    }
    setShowCancelModal(false);
    setShowFulfilledModal(false);
  };

  const updateDataAfterDragEnd = () => {
    const { cardId, sourceLaneId, targetLaneId, cardDetails } = dragEndData;
    const updatedCard = {
      ...cardDetails,
      accordance: { ...cardDetails.accordance, accordanceState: parseInt(targetLaneId) },
    };
    const card = { ...updatedCard, draggable: false };
    if (hasDataToEdit(sourceLaneId, targetLaneId, cardDetails)) {
      const newData = newDataAfterDrag(data, { ...dragEndData, cardDetails: updatedCard });
      updateData(newData);
      setTimeout(() => {
        eventBus.publish({ type: 'ADD_CARD', laneId: targetLaneId, card });
        eventBus.publish({ type: 'REMOVE_CARD', laneId: sourceLaneId, cardId });
      }, 0);
      setDragEndData(null);
    }
  };

  const handleDragEnd = (
    cardId: string,
    sourceLaneId: string,
    targetLaneId: string,
    position: number,
    cardDetails: any,
  ) => {
    setDragEndData({ cardId, sourceLaneId, targetLaneId, position, cardDetails });
    displayConfirmModal(targetLaneId);
  };

  const displayConfirmModal = (targetLaneId: string) => {
    if (targetLaneId === '4') {
      setShowCancelModal(true);
    } else if (targetLaneId === '3') {
      setShowFulfilledModal(true);
    }
  };

  const handleCardClick = (cardId, metadata, laneId) => {
    setEventId(cardId);
  };

  // const getDataEvent = async () => {
  //   setEvents(await getEvents(eventId));
  // };

  // const getDataValidity = async () => {
  //   setValidity(await getValidity(eventId, currentProject.id));
  // };

  // const value = useMemo(
  //   () => ({
  //     events,
  //     validity,
  //   }),
  //   [events, validity]
  // );

  // const value = {
  //   events,
  //   validity,
  //   getDataEvent,
  // };

  useEffect(() => {
    if (eventId && currentProject) {
      getDataEvent();
      getDataValidity(currentProject.id);
    }
  }, [eventId]);

  return (
    <>
      <Board
        handleDragEnd={(cardId, sourceLaneId, targetLaneId, position, cardDetails) => {
          handleDragEnd(cardId, sourceLaneId, targetLaneId, position, cardDetails);
        }}
        data={data}
        style={props.boardStyle || {}}
        components={{ Card: AccordanceCard, LaneHeader: CustomHeader }}
        laneDraggable={false}
        eventBusHandle={setEventBus}
        cardDraggable={!isMobile() && currentProject.assignedToMe}
        onCardClick={handleCardClick}
      />
      {!isMobile() && <CollapsedEements data={data} />}
      <AccordanceCancel
        isOpen={showCancelModal}
        closeModal={(undoChanges: boolean) => updateDataCloseModal(undoChanges)}
        accordanceId={getAccordanceId(dragEndData)}
      />
      <AccordanceFulfilled
        isOpen={showFulfilledModal}
        closeModal={(undoChanges: boolean) => updateDataCloseModal(undoChanges)}
        accordanceId={getAccordanceId(dragEndData)}
      />
    </>
  );
};

export default TrelloBoard;

const getAccordanceId = dragEndData => (dragEndData ? dragEndData.cardId : 0);
const hasDataToEdit = (sourceLaneId, targetLaneId, cardDetails) => sourceLaneId && targetLaneId && cardDetails;
