import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Project from '../../../../../models/Project';
import SelectOption from '../../../../../models/SelectOption';
import { setFilterIndicators } from '../../../../../redux/actions/filterIndicators';
import { getFilterSessionValue } from '../../../../../services/sessionFiltersIndicatorDetailHelper';
import CustomSelect from '../../../../Util/CustomSelect/CustomSelect';
import { TabFilter } from '../TabsFilter/TabsFilter';
import styles from './SelectsFilter.module.scss';

enum StateTranslationsKeys {
  'EXEC_PH' = 'supervision_plan_state_1',
  'in_progress' = 'in_progress', //TODO cuando se integre con BE revisar que este estado viene asi
}

const allOptionObject = { id: 0, value: 'all', label: 'all' };

const createStateOptionList = indicatorProjects => {
  const stateOptions: SelectOption[] = [allOptionObject];
  const uniqueStates: string[] = Array.from(new Set(indicatorProjects.map(item => item.field8)));
  uniqueStates.forEach((element: string, index = 1) => {
    stateOptions.push({ id: index, value: element, label: StateTranslationsKeys[element] });
  });
  return stateOptions;
};

const createSourceOptionList = indicatorProjects => {
  const stateOptions: SelectOption[] = [allOptionObject];
  const statesData = indicatorProjects.map(item => item.field9);
  const splitByComma = statesData.map(p => p.split(','));
  const allStatesSplited: string[] = [].concat(...splitByComma.map(a => a));
  const trimedStates = allStatesSplited.map(s => s.trim());
  const uniqueStates: string[] = Array.from(new Set(trimedStates));
  uniqueStates.forEach((element: string, index = 1) => {
    stateOptions.push({ id: index, value: element, label: element });
  });
  return stateOptions;
};
const selectStyle = { width: 130, marginRight: 40 };

type Props = {
  tabFilter: TabFilter;
};
export const SelectsFilter = ({ tabFilter }: Props) => {
  const dispatch = useDispatch();
  const { selectsContainer, label } = styles;
  const indicatorProjects = useSelector<any, Project[]>(state => state.indicatorProjects);
  const { filterIndicators } = useSelector<any, any>(state => state);

  const { t } = useTranslation();
  const [stateOptions, setStateOptions] = useState<SelectOption[]>([allOptionObject]);
  const [sourceOptions, setSourceOptions] = useState<SelectOption[]>([allOptionObject]);
  const [state, setState] = useState<SelectOption>(allOptionObject);
  const [source, setSource] = useState<SelectOption>(allOptionObject);

  useEffect(() => {
    setState(allOptionObject);
    setSource(allOptionObject);
  }, [tabFilter]);

  useEffect(() => {
    setStateOptions(createStateOptionList(indicatorProjects));
    getFilterSessionValue('consultanciesTabs') === 1 && setSourceOptions(createSourceOptionList(indicatorProjects));
  }, [indicatorProjects]);

  useEffect(() => {
    dispatch(
      setFilterIndicators({
        ...filterIndicators,
        detailFilters: {
          ...filterIndicators.detailFilters,
          stateFilter: state.value,
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);
  useEffect(() => {
    dispatch(
      setFilterIndicators({
        ...filterIndicators,
        detailFilters: {
          ...filterIndicators.detailFilters,
          sourceFilter: source.value,
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source]);

  return (
    <div className={selectsContainer}>
      <span className={label}>{t('status')}</span>
      <CustomSelect
        options={stateOptions}
        handleSelected={item => setState(item)}
        selectedOption={state}
        style={selectStyle}
      />

      {tabFilter === TabFilter.SUPERVISION_PLANS && (
        <>
          <span className={label}>{t('source')}</span>
          <CustomSelect
            options={sourceOptions}
            handleSelected={item => setSource(item)}
            selectedOption={source}
            style={selectStyle}
          />
        </>
      )}
    </div>
  );
};
