import React, { createContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloseCrossButton from '../../Buttons/CloseCrossButton/CloseCrossButton';
import BaseModal from '../BaseModal';
import { styles as modalStyles } from '../Styles';
import styles from './ReportAccordance.module.scss';
import Select from 'react-select';
import SaveButton from '../../Buttons/SaveButton/SaveButton';
import { DropdownIndicator, reportAccordanceStyle } from '../../../Filter/FilterComponent.Styles';
import { useFrontEndBasedSelectedsOptions } from '../../../../hooks/useFrontEndBasedSelectedsOptions';
import UndoButton from '../../Buttons/UndoButton/UndoButton';
import { GetSectionReportEnum } from '../../../../models/ReportEnums';
import { IAccordanceReportParams } from '../../../../models/AccordanceReportParams';
import { yearsListOptions } from './Util';
import { UserBasedOptions } from './UserBasedOptions/UserBasedOptions';
import { handleUpdateItemsIds } from './UserBasedOptions/Util';

const {
  modalReportAccordanceContent,
  modalReportAccordanceContainer,
  closeCrossContainer,
  horizontalRow,
  field,
  label,
} = styles;

type Props = {
  isOpen: boolean;
  closeModal: any;
  submit: any;
};

const reportAccordanceParams: IAccordanceReportParams = {
  reportType: GetSectionReportEnum.Accordance_Section,
  accordanceTypes: [],
  accordanceState: [],
  accordanceYears: [],
  country: [],
  region: [],
  sector: [],
  division: [],
};

export const ReportAccordanceContext = createContext([{}, () => {}]);
export const ReportAccordance = ({ isOpen, closeModal, submit }: Props) => {
  const SHOW_YEARS_FROM = 2020;
  const currentYear = new Date().getFullYear();
  const { t } = useTranslation();
  const { acordanceTypeOptions, acordancStateOptions } = useFrontEndBasedSelectedsOptions();
  const yearListOptions = yearsListOptions(SHOW_YEARS_FROM, currentYear);
  const [selectedAccordanceType, setSelectedAccordanceType] = useState<any>([]);
  const [selectedAccordanceState, setSelectedAccordanceState] = useState<any>([]);
  const [selectedAccordanceYears, setSelectedAccordanceYears] = useState<any>([
    yearListOptions.find(option => option.id === currentYear),
  ]);
  const [selectedParamsToEndPoint, setSelectedParamsToEndPoint] = useState(reportAccordanceParams);

  useEffect(() => {
    setSelectedParamsToEndPoint({
      ...selectedParamsToEndPoint,
      accordanceTypes: handleUpdateItemsIds(selectedAccordanceType),
      accordanceState: handleUpdateItemsIds(selectedAccordanceState),
      accordanceYears: handleUpdateItemsIds(selectedAccordanceYears),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccordanceType, selectedAccordanceState, selectedAccordanceYears]);

  const handleSubmit = () => {
    submit(selectedParamsToEndPoint);
  };

  return (
    <BaseModal
      isOpen={isOpen}
      onRequestClose={e => {}}
      className={modalReportAccordanceContent}
      style={{ overlay: { ...modalStyles.overlay, ...{ overflowY: 'auto' } } }}
    >
      <div className={modalReportAccordanceContainer}>
        <ReportAccordanceContext.Provider value={[selectedParamsToEndPoint, setSelectedParamsToEndPoint]}>
          <div className={closeCrossContainer}>
            <CloseCrossButton handleClick={closeModal} />
          </div>
          <h3>{t('report_of_agreements')} </h3>
          <div className={horizontalRow}>
            <div className={field}>
              <p className={label}>{t('type_of_accordance')}</p>
              <Select
                id="accordanceType"
                components={{ DropdownIndicator }}
                value={selectedAccordanceType}
                options={acordanceTypeOptions}
                className="basic-multi-select"
                classNamePrefix="All"
                placeholder={t('all')}
                onChange={value => setSelectedAccordanceType(value)}
                styles={reportAccordanceStyle}
                isMulti
              />
            </div>
          </div>
          <div className={horizontalRow}>
            <div className={field}>
              <p className={label}>{t('accordance_status')}</p>
              <Select
                id="accordanceState"
                components={{ DropdownIndicator }}
                value={selectedAccordanceState}
                options={acordancStateOptions}
                className="basic-multi-select"
                classNamePrefix="All"
                placeholder={t('all')}
                onChange={value => setSelectedAccordanceState(value)}
                styles={reportAccordanceStyle}
                isMulti
              />
            </div>
          </div>
          <UserBasedOptions />
          <div className={horizontalRow}>
            <div className={field}>
              <p className={label}>{t('year_of_creation_of_the_agreement')}</p>
              <Select
                id="yearOfCreation"
                components={{ DropdownIndicator }}
                value={selectedAccordanceYears}
                options={yearListOptions}
                className="basic-multi-select"
                classNamePrefix="All"
                placeholder={t('all')}
                onChange={value => setSelectedAccordanceYears(value)}
                styles={reportAccordanceStyle}
                isMulti
              />
            </div>
          </div>
          <div className={horizontalRow}>
            <SaveButton
              handleClick={() => handleSubmit()}
              disabled={selectedAccordanceType?.length !== 0 ? false : true}
              customText={t('create_report')}
            />
            <UndoButton handleClick={closeModal} customText={t('close')} customStyle={{ marginLeft: 16 }} />
          </div>
        </ReportAccordanceContext.Provider>
      </div>
    </BaseModal>
  );
};
