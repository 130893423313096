import React, { useEffect } from 'react';
import './CustomEmailsInput.scss';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import { useTranslation } from 'react-i18next';

type Props = {
  sendEmails?: any;
};

export const CustomEmailsInput = ({ sendEmails }: Props) => {
  const { t } = useTranslation();
  const [emails, setEmails] = React.useState<string[]>([]);
  const [validEmail, setValidEmail] = React.useState(true);
  useEffect(() => {
    sendEmails(emails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emails]);

  return (
    <div className="custom-email-input-container">
      <ReactMultiEmail
        emails={emails}
        onChange={(_emails: string[]) => {
          setEmails(_emails);
        }}
        validateEmail={email => {
          setValidEmail(isEmail(email));
          return isEmail(email);
        }}
        getLabel={(email: string, index: number, removeEmail: (index: number) => void) => {
          return (
            <div data-tag key={index}>
              {email}
              <span data-tag-handle onClick={() => removeEmail(index)}>
                ×
              </span>
            </div>
          );
        }}
      />
      {!validEmail && (
        <div className="validation-container">
          <i className="required-data-icon"></i>
          <span className="required-data-message"> {t('invalid_email_format')}</span>
        </div>
      )}
    </div>
  );
};
