import React from 'react';
import './ReportDownload.scss';
import { styles } from '../Styles';
import { disableEscKey } from '../Util';
import CloseCrossButton from '../../Buttons/CloseCrossButton/CloseCrossButton';
import { useTranslation } from 'react-i18next';
import ReportOptionsButton from '../../Buttons/ReportOptionsButton/ReportOptionsButton';
import CustomSelect from '../../CustomSelect/CustomSelect';
import SelectOption from '../../../../models/SelectOption';
import { downloadList, presentationOnly } from '../../../../models/DownloadList';
import { GetReportsFileTypeEnum } from '../../../../models/ReportEnums';
import { ReportType } from '../../../../models/ReportType';
import BaseModal from '../BaseModal';

type Props = {
  isOpen: boolean;
  closeModal: any;
  submit?: any;
  reportType?: any;
};
export const ReportDownload = (props: Props) => {
  const { isOpen, closeModal, submit, reportType } = props;
  const [selectedOption, setSelectedOption] = React.useState(
    reportType === ReportType.Accordance
      ? {
          value: 'excel',
          label: 'excel',
          id: GetReportsFileTypeEnum.Excel,
        }
      : reportType === ReportType.Review
      ? {
          value: 'presentation',
          label: 'presentation',
          id: GetReportsFileTypeEnum.Power_Point,
        }
      : downloadList.find(item => item.value === 'document') || {
          value: 'presentation',
          label: 'presentation',
          id: GetReportsFileTypeEnum.Power_Point,
        },
  );

  const { t } = useTranslation();

  const handleSelectedDownload = (item: SelectOption) => {
    setSelectedOption(item);
  };

  const handleDownloadFile = selectedOption => {
    submit(selectedOption.id);
  };

  return (
    <BaseModal
      isOpen={isOpen}
      onRequestClose={e => disableEscKey(e, closeModal)}
      style={styles}
      className="modal-report-download-content"
    >
      <div className="modal-report-download-container">
        <div className="close-cross-container">
          <CloseCrossButton handleClick={closeModal} buttonClass="close-cross-button-white" spanClass="white" />
        </div>

        <div className="options-list">
          <h3>{t('download_report')}</h3>
          <span className="subtitle">{t('format')}</span>
          <CustomSelect
            handleSelected={handleSelectedDownload}
            options={reportType === ReportType.Review ? presentationOnly : downloadList}
            style={{ marginBottom: 10 }}
            type="small-blue"
            selectedOption={selectedOption}
            disabled={reportType === ReportType.Review || reportType === ReportType.Accordance}
          />
        </div>
        <div className="horizontal">
          <ReportOptionsButton
            handleClick={() => handleDownloadFile(selectedOption)}
            disabled={false}
            customText={t('download')}
          />
        </div>
      </div>
    </BaseModal>
  );
};
