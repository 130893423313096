import React from 'react';
import { useTranslation } from 'react-i18next';
import CloseCrossButton from '../../../../Util/Buttons/CloseCrossButton/CloseCrossButton';

type Props = {
  title: string;
  style: string;
  label: string;
  func: Function;
};
export const ModalHeader = ({ style, title, label, func }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={style}>
        <h3>{t(title)}</h3>
        <CloseCrossButton handleClick={func} />
      </div>

      <h4>{t(label)}</h4>
    </>
  );
};
