import { capitalizeFirstLetter } from '../../../../../services/stringUtil';
import { setIndicatorBarThickness } from '../../../../Portfolio/IndicatorsHelpers/barchartsIndicatorsHelper';
import { getMonthLabels } from '../../../../Portfolio/IndicatorsHelpers/indicatorHelperUtil';
import { getGaps } from '../../../../Portfolio/IndicatorsHelpers/util';
import { getCustomOptionsChart } from '../../../../PortfolioDetail/ActualProjectedDifferenceDetail/util';

const getCustomOptionsPlannedVsRealChart = (currentIndicator, t, monthToBackgroundChart) => {
  return {
    ...getCustomOptionsChart(currentIndicator, t, monthToBackgroundChart),
    scales: {
      xAxes: [
        {
          stacked: false,
          ticks: {
            beginAtZero: true,
            fontSize: 12,
            fontStyle: 400,
            color: '#424D64',
          },
          gridLines: {
            display: true,
          },
        },
      ],
      yAxes: [
        {
          stacked: false,
          ticks: {
            beginAtZero: true,
            fontSize: 12,
            fontStyle: 400,
            display: true,
            color: '#424D64',
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
  };
};

const monthLabels = getMonthLabels().slice(0, 12);
const currentMonth = new Date().getMonth() + 1;
const getFullYearMetrics = (data, metricName: string) => {
  const filterMetrics = data.filter(m => m.metricName === metricName);
  return filterMetrics.map(m => m.metricValue);
};

const getPlannedVsRealChartObject = data => {
  const fullYearActivityPlanned = getFullYearMetrics(data, 'ACTIVITY_PLANNED');
  const fullYearActivityFull = getFullYearMetrics(data, 'ACTIVITY_ACTUAL');
  const fullYearCriticalProductsPlanned = getFullYearMetrics(data, 'PRODUCT_PLANNED');
  const fullYearCriticalProductsFull = getFullYearMetrics(data, 'PRODUCT_ACTUAL');

  const activityGaps = getGaps(fullYearActivityPlanned, fullYearActivityFull);
  const criticalProductsGaps = getGaps(fullYearCriticalProductsPlanned, fullYearCriticalProductsFull);

  return {
    dataFullYear: {
      labels: monthLabels,
      datasets: [
        {
          label: 'planned_advance_activity',
          data: fullYearActivityPlanned,
          backgroundColor: '#CBDEFE',
          borderWidth: 0,
          barThickness: setIndicatorBarThickness(3, 5),
          gap: activityGaps,
          actualMonth: currentMonth,
        },
        {
          label: 'real_advance_activity',
          data: fullYearActivityFull,
          backgroundColor: '#264377',
          borderWidth: 0,
          barThickness: setIndicatorBarThickness(3, 5),
          gap: activityGaps,
        },
        {
          label: 'planned_advance_critical_product',
          data: fullYearCriticalProductsPlanned,
          backgroundColor: '#CBDEFE',
          borderWidth: 0,
          barThickness: setIndicatorBarThickness(3, 5),
          gap: criticalProductsGaps,
          actualMonth: currentMonth,
        },
        {
          label: 'real_advance_critical_product',
          data: fullYearCriticalProductsFull,
          backgroundColor: '#264377',
          borderWidth: 0,
          barThickness: setIndicatorBarThickness(3, 5),
          gap: criticalProductsGaps,
        },
      ],
    },
  };
};

const barChartData = (card, t) => {
  return {
    ...card.dataFullYear,
    labels: card.dataFullYear.labels.map(label => {
      return capitalizeFirstLetter(t(label).toLowerCase());
    }),
  };
};

const filterOptions = t => {
  return [
    { value: `activities`, id: 0, label: t('activities') },
    { value: `critical_products`, id: 1, label: t('critical_products') },
  ];
};

export { getCustomOptionsPlannedVsRealChart, getPlannedVsRealChartObject, barChartData, filterOptions };
