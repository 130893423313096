import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SectorsFilter from '../SectorsFilter/SectorsFilter';
import DivisionFilter from '../DivisionFilter/DivisionFilter';
import CoResponsibleDivisionFilter from '../CoResponsibleDivisionFilter/CoResponsibleDivisionFilter';
import ParticipatingDivisionFilter from '../ParticipatingDivisionFilter/ParticipatingDivisionFilter';
import { CalledFrom } from '../../../models/CalledFromEnum';
import { AppMenuTabs } from '../../MenuTabs/userTabsInterface';
import SelectOption from '../../../models/SelectOption';
import { getDivisionFilterOptions } from '../UtilFunctions';
import { buildInitialStates } from './utils';

type Props = {
  calledFrom: CalledFrom;
  isEdit?: boolean;
  setSectorSelectedOnParent?: Function;
  setDivisionSelectedOnParent?: Function;
  setCoResponsibleDivisionOnParent?: Function;
  setParticipantDivisionOnParent?: Function;
};

const handleFilterChange = (value: SelectOption[], setOptionsSelectedOnParent: any, setSectorsSelected: any = null) => {
  setOptionsSelectedOnParent && setOptionsSelectedOnParent(value);
  setSectorsSelected && setSectorsSelected(value);
};

const SectorsAndDivisionsFilter = (props: Props) => {
  const { t } = useTranslation();
  const {
    calledFrom,
    isEdit,
    setSectorSelectedOnParent,
    setDivisionSelectedOnParent,
    setCoResponsibleDivisionOnParent,
    setParticipantDivisionOnParent,
  } = props;

  const menuTabs = useSelector<any, AppMenuTabs>(state => state.menuTabs);
  const filterIndicators = useSelector<any, any>(state => state.filterIndicators);
  const filterProjects = useSelector<any, any>(state => state.filter);
  const filterEarlyWarnings = useSelector<any, any>(state => state.filterEarlyWarnings);
  const sectorsState = useSelector<any, any>(state => state.sectors);
  const divisionsState = useSelector<any, any>(state => state.divisions);

  const [showSectorFilter, setShowSectorFilter] = useState<boolean>(true);
  const [showDivisionFilters, setShowDivisionFilters] = useState<boolean>(true);
  const [showHiddenFilters, setShowHiddenFilters] = useState<boolean>(false);
  const [sectorsSelected, setSectorsSelected] = useState<SelectOption[]>([]);
  const [availableDivisions, setAvailableDivisions] = useState<SelectOption[]>([]);

  useEffect(() => {
    buildInitialStates(
      calledFrom,
      menuTabs,
      { filterIndicators, filterProjects, filterEarlyWarnings },
      { setShowHiddenFilters, setShowSectorFilter, setShowDivisionFilters },
      { sectorsState, divisionsState },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sectorsSelected && sectorsSelected.length) {
      const filteredSectors = sectorsState.filter(sector =>
        sectorsSelected.map(selected => selected.id).includes(sector.id),
      );
      const divisionsFromSelected = filteredSectors.flatMap(filtered => filtered.divisions);
      const divisionsOptions = getDivisionFilterOptions(divisionsFromSelected);
      setAvailableDivisions(divisionsOptions);
    } else {
      const divisionsOptions = getDivisionFilterOptions(divisionsState);
      setAvailableDivisions(divisionsOptions);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectorsSelected]);

  const showFiltersDiv = (() => {
    setShowHiddenFilters(true);
  })

  return (
    <>
      {showSectorFilter && (
        <div className="filter-wrapper">
          <h4 className="paragraph-x-small filter-wrapper__label">{t('section')}</h4>
          <SectorsFilter
            calledFrom={calledFrom}
            setSelectedSectorsOnParent={value =>
              handleFilterChange(value, setSectorSelectedOnParent, setSectorsSelected)
            }
            isEdit={isEdit}
          />
        </div>
      )}
      {showDivisionFilters && (
        <>
          <div className="filter-wrapper">
            <h4 className="paragraph-x-small filter-wrapper__label">{t('division_capital')}</h4>
            <DivisionFilter
              calledFrom={calledFrom}
              divisionOptionsFromParent={availableDivisions}
              setSelectedDivisionOnParent={value => handleFilterChange(value, setDivisionSelectedOnParent)}
              isEdit={isEdit}
            />
            {!showHiddenFilters ?
              (<span onClick={() => setShowHiddenFilters(true)}>{t('double_booking_and_collaboration')}</span>) :
              <span onClick={() => setShowHiddenFilters(false)}>- Double booking and collaboration</span>
            }



          </div>
          {showHiddenFilters && (
            <>
              <div className="filter-wrapper">
                <h4 className="paragraph-x-small filter-wrapper__label">{t('co_responsible_division')}</h4>

                <CoResponsibleDivisionFilter
                  calledFrom={calledFrom}
                  divisionOptionsFromParent={availableDivisions}
                  setCoResponsibleDivisionOnParent={value =>
                    handleFilterChange(value, setCoResponsibleDivisionOnParent)
                  }
                  isEdit={isEdit}
                />
              </div>
              <div className="filter-wrapper">
                <h4 className="paragraph-x-small filter-wrapper__label">{t('participating_division')}</h4>

                <ParticipatingDivisionFilter
                  calledFrom={calledFrom}
                  divisionOptionsFromParent={availableDivisions}
                  setParticipantDivisionOnParent={value => handleFilterChange(value, setParticipantDivisionOnParent)}
                  isEdit={isEdit}
                />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default SectorsAndDivisionsFilter;
