import { useSelector } from 'react-redux';
import { GcmStatus, IGcmAlert } from '../models/GcmAlerts';
import { getGcmAlertToShow } from '../services/util';
import { groupBy } from 'lodash';

const useGcmAlerts = props => {
  /*Lógica: textAlert, barColor, highlightNode */
  const { calledFromTab } = props;
  const defineColorByType = alertType => {
    const alertColor = {
      0: {
        backgroundColor: '#F06D6D',
      },
      1: {
        backgroundColor: '#EF9206',
      },
      2: {
        backgroundColor: '#d0def8',
      },
    };
    return alertColor[alertType] || { backgroundColor: 'transparent' };
  };

  const gcmAlerts = useSelector<any, IGcmAlert[]>(state => state.currentProject.gcmAlerts);
  const priorityAlert: IGcmAlert = getGcmAlertToShow(gcmAlerts, calledFromTab);
  const textAlert = priorityAlert.descriptionName;
  const highlightNode = priorityAlert.highlighted;
  const barColor = defineColorByType(priorityAlert.type);

  /*Lógica: totalActiveAlerts */
  const totalActiveAlerts = gcmAlerts ? gcmAlerts.length : 0;

  /*Lógica showAlertIcons */
  const setShowAlertIconsState = () => {
    const groupedByCode = groupBy(gcmAlerts, (a: IGcmAlert) => a.type);
    let showAlertInCancellation = false;
    let showAlertWarning = false;
    let showAlertIssue = false;
    Object.keys(groupedByCode).forEach(a => {
      if (parseInt(a) === GcmStatus.InCancellation) {
        showAlertInCancellation = true;
      } else if (parseInt(a) === GcmStatus.Warning) {
        showAlertWarning = true;
      } else if (parseInt(a) === GcmStatus.Issue) {
        showAlertIssue = true;
      }
    });

    return {
      issue: {
        showAlert: showAlertIssue,
      },
      warning: {
        showAlert: showAlertWarning,
      },
      cancelation: {
        showAlert: showAlertInCancellation,
      },
    };
  };

  const showAlertIcons = setShowAlertIconsState();

  //Lógica: tooltipList
  interface ITooltipGcmAlert {
    text: string;
    className: number;
  }

  const tooltipList: ITooltipGcmAlert[] =
    gcmAlerts &&
    gcmAlerts.map((g: IGcmAlert) => {
      return {
        text: g.descriptionName,
        className: g.type,
      };
    });

  return { textAlert, barColor, highlightNode, totalActiveAlerts, showAlertIcons, tooltipList };
};

export default useGcmAlerts;
