import React, { useState, useEffect } from 'react';
import styles from './GcmTable.module.scss';
import { Table, TableBody, TableContainer, TableRow, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { renderDateOrSlash } from '../../../../services/stringUtil';
import { sortTablesByType } from '../../../../services/sortsUtil';
import {
  StyledTableCell,
  StyledTableCellHeader,
  StyledTableHeader,
  StyledTableRowHeader,
} from '../../PorfolioTableShared.Styles';

import { ReactComponent as GcmWarning } from '../../../../assets/icons/u_alert-exclamacion.svg';
import { ReactComponent as GcmInCancellation } from '../../../../assets/icons/u_alert-multyple.svg';
import { ReactComponent as GcmIssue } from '../../../../assets/icons/u_alert-not-assigned.svg';
import StatusTitle from './StatusTitle';
import { customRowStyle } from '../../PortfolioSimpleIndicatorTable/PortfolioSimpleIndicatorTable';
import { ProjectCounter } from '../../ProjectCounter/ProjectCounter';
import { IndicatorKeyEnum } from '../../../../models/IndicatorKeyEnum';
import { GcmStatus } from '../../../../models/GcmAlerts';
import { ExcelDownloadButton } from '../../../Util/ExcelDownloadButton/ExcelDownloadButton';
import DataTable from '../../../DetailDataTable/DataTable';
import useDataNormalize from '../../../../hooks/useDataNormalize';
import { ProjectsListSkeleton } from '../../../LoadersSkeletons/ProjectsListSkeleton/ProjectsListSkeleton';
import PaginationTable from '../../PaginationTable/PaginationTable';
import { Pagination } from '@material-ui/lab';
import usePaginationSort from '../../../../hooks/usePaginationSort';
import { withStyles } from '@material-ui/core/styles';
import { ReactComponent as IconSort } from '../../../../assets/icons/u_sort.svg';
import { FilterTables } from '../../../Filter/FilterTables/FilterTables';

const BlackTooltip = withStyles({
  tooltip: {
    //   color: '$ci-color-greyscale-90',
    backgroundColor: '#101319',
    fontSize: '16px',
    fontFamily: 'Rubik',
    fontWeight: '400',
    lineHeight: '20px',
    padding: '8px 12px',
    borderRadius: '8px',
  },
  arrow: {
    fontSize: 20,
    color: '#4A4A4A',
    '&::before': {
      backgroundColor: '#101319',
    },
  },
})(Tooltip);

const headersCancellation = ['id', 'last_project', 'last_operation', 'name', 'date_of_last_disbursement'];

const selectedKeysCancellation = ['id', 'code', 'loanNumber', 'title', 'field1'];
const headersWarning = ['id', 'last_project', 'last_operation', 'name'];

const selectedKeysWarning = ['id', 'code', 'loanNumber', 'title'];

const headersIssues = ['id', 'last_project', 'last_operation', 'name', 'eligibility_date'];

const selectedKeysIssues = ['id', 'code', 'loanNumber', 'title', 'field1'];

const baseTableInfo = [
  { tableHeader: 'project', rootProperty: 'code', fieldType: 'string' },
  { tableHeader: 'operation', rootProperty: 'loanNumber', fieldType: 'string' },
  { tableHeader: 'project_name', rootProperty: 'title', fieldType: 'string' },
];

const statusTitles = [
  {
    icon: <GcmInCancellation />,
    title: 'in_cancellation_en',
    color: '#F06D6D',
  },
  {
    icon: <GcmWarning />,
    title: 'warning',
    color: '#FF8C26',
  },
  {
    icon: <GcmIssue />,
    title: 'issue',
    color: '#A0ABC1',
  },
];

interface ReceivedProps {
  projects: any;
  indicatorKey?: any;
  status: GcmStatus;
  title: string;
  currentMapperKey: string;
}

type Props = ReceivedProps;

const GcmTable = ({ projects, status, title, currentMapperKey }: Props) => {
  const { t } = useTranslation();
  const [sortAsc, setSortAsc] = useState(true);
  const [isLoading, setisLoading] = React.useState(true);
  const [tableInfo, setTableInfo] = useState<any>([
    ...baseTableInfo,
    { tableHeader: '', rootProperty: '', fieldType: '' },
  ]);
  const history = useHistory();
  const { container, tableTitle, borderTable } = styles;
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [dataFilter, setDataFilter] = useState<any>(projects);

  useEffect(() => {
    setDataFilter(projects);
  }, [projects]);

  const { handlePaginationChange, handleSort, currentList, pagesCount, currentPage } = usePaginationSort({
    projects: dataFilter,
    defaultSortCriteria: 'title',
    pageCount: rowsPerPage,
  });

  useEffect(() => {
    const newTableInfo = [
      ...baseTableInfo,
      {
        tableHeader: columnKeyMapper[currentMapperKey] ? columnKeyMapper[currentMapperKey].tableHeader : '',
        rootProperty: 'field1',
        fieldType: columnKeyMapper[currentMapperKey] ? columnKeyMapper[currentMapperKey].fieldType : '',
      },
    ];
    setTableInfo(newTableInfo);
  }, [currentMapperKey]);
  const getHeaders = status => {
    switch (status) {
      case 0:
        return headersCancellation;
      case 1:
        return headersWarning;
      case 2:
        return headersIssues;
      default:
        return headersIssues;
    }
  };

  const getSelectedKeys = indicator => {
    switch (indicator) {
      case 0:
        return selectedKeysCancellation;
      case 1:
        return selectedKeysWarning;
      case 2:
        return selectedKeysIssues;
      default:
        return selectedKeysIssues;
    }
  };

  const selectedKeys = getSelectedKeys(status);
  const headers = getHeaders(status);
  const data = useDataNormalize(headers, selectedKeys, projects);

  const dataExcel = useDataNormalize(headers, selectedKeys, dataFilter);

  useEffect(() => {
    setisLoading(true);
    if (data.length > 0) {
      setTimeout(() => {
        setisLoading(false);
      }, 2000);
    }
  }, [data]);

  const sortTable = (keyToSort, fieldType) => {
    sortTablesByType(currentList, sortAsc, keyToSort, fieldType);
    setSortAsc(!sortAsc);
  };

  const handleClick = projectId => {
    history.push(`/projectdetail?id=${projectId}`);
  };

  // TODO - coordinar bien los campos (field1, field2, etc) con los datos correctos para mostrar

  return (
    <div className={container}>
      <StatusTitle statusTitle={statusTitles[status]} />
      <h4 className={tableTitle}>{title}</h4>
      <div>
        {isLoading ? (
          <ProjectsListSkeleton />
        ) : (
          <>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <ExcelDownloadButton headers={headers} selectedKeys={selectedKeys} dataExcel={dataExcel} />
              <FilterTables
                projects={projects}
                keysFilter={selectedKeys}
                fnDataFilter={setDataFilter}
                disabledNumber={currentPage}
                indicatorKey={'GCM_ALERTS'}
              />
            </div>
            {/* <DataTable headers={headers} selectedKeys={selectedKeys} hasFirstColLink data={data} key={data} /> */}

            <TableContainer className="overflow-hidden-table border-table">
              <Table stickyHeader className="custom-table " style={{ tableLayout: 'auto' }} size="small">
                <StyledTableHeader>
                  <StyledTableRowHeader>
                    {tableInfo.map(hc =>
                      hc.tableHeader && hc.fieldType ? (
                        <StyledTableCellHeader
                          key={hc.tableHeader}
                          align="left"
                          onClick={() => sortTable(hc.rootProperty, hc.fieldType)}
                          style={hc.customWidth ? hc.customWidth : {}}
                        >
                          <div className="text-head ">
                            {t(hc.tableHeader)}
                            <IconSort className="sortIcon" />
                          </div>
                        </StyledTableCellHeader>
                      ) : null,
                    )}
                  </StyledTableRowHeader>
                </StyledTableHeader>
                <TableBody>
                  {dataFilter?.length === 0
                    ? dataFilter?.map((project, index) => (
                        <TableRow
                          key={project.id}
                          // style={customRowStyle(index, project.id)}
                        >
                          <StyledTableCell
                            className="link-project"
                            style={{ width: '10%', minWidth: '80px', borderRadius: '6px' }}
                          >
                            <BlackTooltip arrow title={t('go_to_project_info')} onClick={() => handleClick(project.id)}>
                              <p className={` link-project`}>{project.code}</p>
                            </BlackTooltip>
                          </StyledTableCell>
                          <StyledTableCell style={{ width: '20%', minWidth: '80px' }}>
                            {project.loanNumber}
                          </StyledTableCell>
                          <StyledTableCell style={{ width: '55%', minWidth: '80px' }}>
                            <BlackTooltip
                              title={project.title}
                              placement="bottom"
                              arrow
                              enterDelay={500}
                              leaveDelay={100}
                            >
                              <p className="name-project-nowrap-tiny"> {project.title}</p>
                            </BlackTooltip>
                          </StyledTableCell>
                          {columnKeyMapper[currentMapperKey] &&
                          columnKeyMapper[currentMapperKey].tableHeader &&
                          columnKeyMapper[currentMapperKey].fieldType ? (
                            <StyledTableCell style={{ width: '15%', minWidth: '80px' }}>
                              {columnKeyMapper[currentMapperKey].fieldType === 'date'
                                ? renderDateOrSlash(project.field1, 'DD/MMM/YYYY')
                                : project.field1}
                            </StyledTableCell>
                          ) : null}
                        </TableRow>
                      ))
                    : currentList?.map((project, index) => (
                        <TableRow
                          key={project.id}
                          // style={customRowStyle(index, project.id)}
                        >
                          <StyledTableCell
                            className="link-project"
                            style={{ width: '10%', minWidth: '80px', borderRadius: '6px' }}
                          >
                            <BlackTooltip arrow title={t('go_to_project_info')} onClick={() => handleClick(project.id)}>
                              <p className={` link-project`}>{project.code}</p>
                            </BlackTooltip>
                          </StyledTableCell>
                          <StyledTableCell style={{ width: '20%', minWidth: '80px' }}>
                            {project.loanNumber}
                          </StyledTableCell>
                          <StyledTableCell style={{ width: '55%', minWidth: '80px' }}>
                            <BlackTooltip
                              title={project.title}
                              placement="bottom"
                              arrow
                              enterDelay={500}
                              leaveDelay={100}
                            >
                              <p className="name-project-nowrap-tiny"> {project.title}</p>
                            </BlackTooltip>
                          </StyledTableCell>
                          {columnKeyMapper[currentMapperKey] &&
                          columnKeyMapper[currentMapperKey].tableHeader &&
                          columnKeyMapper[currentMapperKey].fieldType ? (
                            <StyledTableCell style={{ width: '15%', minWidth: '80px' }}>
                              {columnKeyMapper[currentMapperKey].fieldType === 'date'
                                ? renderDateOrSlash(project.field1, 'DD/MMM/YYYY')
                                : project.field1}
                            </StyledTableCell>
                          ) : null}
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="pagination-style">
              <PaginationTable fnSetRows={setRowsPerPage} rows={rowsPerPage} numProyects={dataFilter?.length} />
              {dataFilter?.length !== 0 ? (
                <Pagination count={pagesCount} page={currentPage} onChange={handlePaginationChange} />
              ) : null}
            </div>
          </>
        )}
      </div>
      <ProjectCounter arrayToCount={dataFilter} countProjects={true} indicatorKey={IndicatorKeyEnum.GCM_ALERTS} />
    </div>
  );
};

export default GcmTable;

const baseLastDisbursement = {
  tableHeader: 'gcm_last_disbursement_date',
  fieldType: 'date',
};
const baseRatificationDate = {
  tableHeader: 'gcm_ratification_date',
  fieldType: 'date',
};

const baseNoDisbSinceEligibility = {
  tableHeader: 'gcm_no_disbursements_since_eligibility_date',
  fieldType: 'date',
};

const columnKeyMapper = {
  'Eligibility Date': {
    tableHeader: 'gcm_eligibility_date',
    fieldType: 'date',
  },
  'Last disbursement date': baseLastDisbursement,
  'Ratification Date': baseRatificationDate,
  'Signature and Eligibility Date for Bank-Executed': baseRatificationDate,
  'Signature Date': baseRatificationDate,
  'Signature Date (projects with administrative agreements)': baseRatificationDate,
  'Funds expiration': {
    tableHeader: '',
    fieldType: '',
  },
  'Inactive Team Leader': {
    tableHeader: 'gcm_team_leader',
    fieldType: 'string',
  },
  'No disbursements in one year': {
    tableHeader: 'gcm_no_disbursements_one_year',
    fieldType: 'date',
  },
  'No disbursements in over six months': {
    tableHeader: 'gcm_no_disbursements_six_months',
    fieldType: 'date',
  },
  'No disbursements since eligibility date': baseNoDisbSinceEligibility,
  'Pending justification of funds for Recipient-Executed': baseLastDisbursement,
  'TCM final report submission deadline expired': baseLastDisbursement,
  'TCM final report submission deadline in two months': baseLastDisbursement,
  'Results matrix start-up changes period will expire in 15 days': baseNoDisbSinceEligibility,
  'TCMFR submission': baseLastDisbursement,
};
