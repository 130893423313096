import React from 'react';
import Modal from 'react-modal';

type Props = {
  isOpen: boolean;
  style?: Modal.Styles;
  className?: string;
  onRequestClose?: any;
  children: any;
  shouldCloseOnOverlayClick?: boolean;
};

const BaseModal = (props: Props) => {
  const { children, isOpen } = props;
  return isOpen ? <Modal {...props}>{children}</Modal> : null;
};

export default BaseModal;
