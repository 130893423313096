import React, { useContext, useEffect, useState } from 'react';
import styles from './RelatedAccordances.module.scss';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Accordance from '../../../../../models/Accordance';
import Project from '../../../../../models/Project';
import { accordanceStateTextKeys } from '../AccordanceTrello/AccordanceListView/AccordanceListItem';
import { AccordanceState } from '../../../../../models/AccordanceState';
import { NewAccordanceContext } from '../../../../Util/Modals/NewAccordance/context/NewAccordanceContext';

type Props = {
  accordance: Accordance;
  close: Function;
};

const RelatedAccordances = ({ accordance, close }: Props) => {
  const { t } = useTranslation();
  const [accordances, setAccordances] = useState<Accordance[]>([]);
  const { accordancesGrouped } = useSelector<any, Project>(state => state.currentProject);
  const { container, title, rowContainer, label, value, headerContainer, headerSection, fw, tableRowContainer } =
    styles;
  const { state, setState } = useContext(NewAccordanceContext);

  useEffect(() => {
    if (accordance.bundleId) {
      const grouped = accordancesGrouped?.find(i => i.id === accordance.bundleId);
      setAccordances(grouped?.accordances.filter(a => a.id !== accordance.id) || []);
    }
  }, [accordance, accordancesGrouped]);

  return accordance.bundleId ? (
    <div className={container}>
      <p className={title}>{`${t('related_accordances')} (${accordances.length})`}</p>
      <div className={rowContainer}>
        <p className={label}>{`${t('critical_issue')}: `}</p>
        <p className={value}>{accordance?.criticalIssue?.name}</p>
        <p className={label}>{`${t('category')}: `}</p>
        <p className={value}>{accordance?.criticalIssue?.categoryName}</p>
      </div>
      <p className={label}>{`${t('description')}: ${accordance.criticalIssueDescription}`}</p>
      <div className={headerContainer}>
        <div className={headerSection}>
          <p className={label}>{t('accordance_about')}</p>
        </div>
        <div className={headerSection}>
          <p className={label}>{t('responsible')}</p>
        </div>
        <div className={headerSection}>
          <p className={label}>{t('accordance_status')}</p>
        </div>
      </div>
      {accordances.map(item => (
        <div className={tableRowContainer} key={item.id}>
          <div className={headerSection}>
            <p className={value}>{item.possibleAction ? item.possibleAction.name : '-'}</p>
          </div>
          <div className={headerSection}>
            <p className={value}>{`${item.personInCharge ? item.personInCharge.name : '-'} ${
              item.personInCharge ? item.personInCharge.lastName : ''
            }`}</p>
          </div>
          <div
            className={headerSection}
            style={{
              color: item.accordanceState === AccordanceState.PENDING ? '#E39CAC' : '#727E8C',
              textTransform: 'capitalize',
              justifyContent: 'space-between',
            }}
          >
            <p className={label}>{t(accordanceStateTextKeys[item.accordanceState])}</p>
            <span
              className={fw}
              onClick={() => {
                setState({ ...state, relatedAccordance: item });
                close();
              }}
            ></span>
          </div>
        </div>
      ))}
    </div>
  ) : null;
};

export default RelatedAccordances;
