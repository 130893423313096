import React from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { TrendChartInformation } from '../../../models/EarlyWarnings';
import { BulletItem } from '../../Util/BulletItem/BulletItem';
import { isMobile } from '../../../services/booleanUtil';
import styles from './TrendChart.module.scss';
import { capitalizeFirstLetter } from '../../../services/stringUtil';

const { trendChartsContainer, chartContainer, bulletsContainer, titleContainer, chartModuleContainer } = styles;

type TrendChartProps = {
  buildTrendChart: TrendChartInformation;
};

export const TrendChart = ({ buildTrendChart }: TrendChartProps) => {
  const { title, bullets, chartData } = buildTrendChart;
  const { t } = useTranslation();
  return (
    <div className={trendChartsContainer}>
      <div className={titleContainer}>
        <p>{t(title)}</p>
      </div>
      <div className={chartModuleContainer}>
        <div className={chartContainer}>
          <HorizontalBar data={chartData} options={options(t)} {...handleDimensions()} />
        </div>
        <div className={bulletsContainer}>
          {bullets.map(bullet => {
            return <BulletItem bulletColor={bullet.className} bulletText={bullet.translationKey} />;
          })}
        </div>
      </div>
    </div>
  );
};

const handleDimensions = () => (isMobile() ? { ...mobileDimensions } : dimensions);
const mobileDimensions = {
  width: 320,
  height: 100,
  cursor: 'pointer',
};

const dimensions = {
  width: 320,
  height: 80,
  cursor: 'pointer',
};

const options = t => {
  return {
    plugins: {
      datalabels: {
        display: false,
      },
    },
    legend: {
      display: false,
    },
    tooltips: {
      mode: 'nearest',
      displayColors: false,
      backgroundColor: '#101319',
      titleFontSize: 14, 
      bodyFontSize: 14,
      callbacks: {
        title: () => {},
        label(tooltipItem) {
          const totalProjects = tooltipItem.value;
          return `${totalProjects} ${capitalizeFirstLetter(t(totalProjects > 1 ? 'projects' : 'project'))}`;
        },
      },
    },
    scales: {
      xAxes: [
        {
          display: true,
          stacked: true,
          ticks: {
            beginAtZero: true,
            fontSize: 12,
            fontStyle: 400,
            color: '#101319',
            fontFamily: 'Rubik',
          },
          gridLines: {
            display: true,
          },
        },
      ],
      yAxes: [
        {
          display: true,
          stacked: true,
          ticks: {
            fontFamily: 'Rubik',
            beginAtZero: true,
            fontSize: 15,
            fontStyle: 500,
            color: '#101319',
            callback: value => value.toUpperCase(),
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
    cornerRadius: 9,
    animation: {
      duration: 1000,
    },
  };
};
