export const orderList = [
  { langKey: 'spanish', encodedName: 'es' },
  { langKey: 'english_trans', encodedName: 'en' },
  { langKey: 'portuguese_trans', encodedName: 'pt' },
  { langKey: 'french_trans', encodedName: 'fr' },
];

export interface TranslationDTO {
  id: number;
  value: string;
}

export const buttonText = (editMode: boolean) => {
  return editMode ? 'save_translations' : 'edit_texts';
};
