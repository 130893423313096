import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CustomSwitch from '../../../Util/CustomSwitch/CustomSwitch';
import { AccordionDetails, Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import { ReactComponent as CheckedIcon } from '../../../../assets/checkbox_checked_icon.svg';
import { ReactComponent as UncheckedIcon } from '../../../../assets/checkbox_unchecked_icon.svg';
import {
  HelpCenterFormAccordion,
  HelpCenterFormAccordionSummary,
} from '../../ContentManagerAccordion/ContentManagerAccordion';
import './HelpCenterModalForm.scss';
import { languagesKeys } from '../../ContentManagerKeys';
import { postFaqListItem, updateFaqListItem } from '../../../../api/contentManager';
import {
  findLanguage,
  findLanguageByValue,
  hasCompletedTranslation,
  getUncompletedTranslations,
} from '../../../Util/ContentManager/ContentManager';
import {
  defaultFormFields,
  getActionButtonTitle,
  getFormFieldsErrors,
  getOnChangeFormFields,
  mapTranslationsToFormFields,
  getPostFaqItem,
  getErrorClass,
  getDisabledClass,
  getExpanded,
} from './HelpCenterModalForm.utils';

export const useStyles = makeStyles({
  root: {
    '& .MuiFormControlLabel-label.Mui-disabled': {
      color: '#727E8C',
    },
  },
  label: {
    fontFamily: 'Rubik',
    fontWeight: 700,
  },
});

type Props = {
  closeModal: Function;
  getLanguageTranslationFromKey?: any;
  updateFaqList?: any;
  isEdit?: boolean;
  faqItem?: any;
  displayGenericErrorMessage?: any;
  languagesData?: any;
};

const getErrorMessage = (faqItem, t, type) => {
  let span: any = null;
  if (
    (faqItem.titleError && type === 'title' && type !== 'content') ||
    (faqItem.contentError && type === 'content' && type !== 'title')
  ) {
    span = <span className="uncompleted-error">{t(`validation_no_${type}`)}</span>;
  }
  return span;
};

export default function HelpCenterModalForm({
  closeModal,
  getLanguageTranslationFromKey,
  updateFaqList,
  isEdit,
  faqItem,
  displayGenericErrorMessage,
  languagesData,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState(languagesKeys.spanish);
  const [visible, setVisible] = React.useState(false);
  const [formFields, setFormFields] = React.useState(defaultFormFields);

  useEffect(() => {
    if (isEdit) {
      const translationsToFormFields = mapTranslationsToFormFields(faqItem.translations, languagesData, findLanguage);
      setFormFields(translationsToFormFields);
      setVisible(!faqItem.visible);
    }
    // eslint-disable-next-line
  }, []);

  const handleChangeVisible = nextChecked => setVisible(nextChecked);
  const handleChange = panel => (event, isExpanded) => setExpanded(isExpanded ? panel : false);
  const handleFormChange = e => {
    e.preventDefault();
    const { name, value } = e.target;
    const dataInfo = name.split('-');
    const type = dataInfo[0];
    const language = dataInfo[1];

    setFormFields(getOnChangeFormFields(formFields, type, value, languagesKeys, language));
  };

  const handleSubmitFaqItem = async () => {
    try {
      const unCompletedTranslations = getUncompletedTranslations(formFields);
      const hasUncompletedTranslations = !isEmpty(unCompletedTranslations);
      if (hasUncompletedTranslations) {
        setFormFields(getFormFieldsErrors(formFields, unCompletedTranslations));
        return;
      }

      const postFaqItem = getPostFaqItem(formFields, languagesData, visible, findLanguageByValue);

      if (isEdit) {
        await updateFaqListItem({ ...postFaqItem, id: faqItem.id });
      } else {
        await postFaqListItem(postFaqItem);
      }

      updateFaqList();
      closeModal();
    } catch {
      displayGenericErrorMessage(true);
    }
  };

  return (
    <>
      {formFields.map(faqItem => {
        return (
          <HelpCenterFormAccordion
            expanded={getExpanded(faqItem, expanded)}
            onChange={handleChange(faqItem.languageid)}
            key={faqItem.languageid}
          >
            <HelpCenterFormAccordionSummary expandIcon={<ExpandMoreIcon />}>
              <FormControlLabel
                classes={classes}
                value="end"
                control={
                  <Checkbox checked={faqItem.isChecked} icon={<UncheckedIcon />} checkedIcon={<CheckedIcon />} />
                }
                label={getLanguageTranslationFromKey(faqItem.languageid, t)}
                labelPlacement="end"
                disabled
              />
            </HelpCenterFormAccordionSummary>
            <AccordionDetails style={{ display: 'block', padding: '0px' }}>
              <div className="help-center-modal-form-container">
                <div className="horizontal-row">
                  <div className={`label-select-container text-area ${getErrorClass(faqItem.titleError)}`}>
                    <p className="label">{t('contents_helpCenter_faq_addFaq_add_question_label')}</p>
                    <input
                      value={faqItem.title}
                      name={`title-${faqItem.languageid}`}
                      onChange={e => handleFormChange(e)}
                    ></input>
                    {getErrorMessage(faqItem, t, 'title')}
                  </div>
                </div>
                <div className="horizontal-row">
                  <div className={`label-select-container text-area ${getErrorClass(faqItem.contentError)}`}>
                    <p className="label">{t('contents_helpCenter_faq_addFaq_add_answer_label')}</p>
                    <textarea
                      value={faqItem.content}
                      name={`content-${faqItem.languageid}`}
                      onChange={e => handleFormChange(e)}
                    ></textarea>
                    {getErrorMessage(faqItem, t, 'content')}
                  </div>
                </div>
              </div>
            </AccordionDetails>
          </HelpCenterFormAccordion>
        );
      })}
      <div className="content-manager-modal__options">
        <span className="content-manager-modal__options-label">
          {t('contents_helpCenter_faq_addFaq_hide_faq_switch_label')}
        </span>
        <CustomSwitch active={visible} handleChange={handleChangeVisible} />
      </div>
      <div className="content-manager-modal__button-group">
        <button
          className={`content-manager-modal__button ${getDisabledClass(formFields, hasCompletedTranslation)}`}
          onClick={() => handleSubmitFaqItem()}
        >
          {t(getActionButtonTitle(isEdit))}
        </button>
        <button
          style={{ width: '195px' }}
          className="content-manager-modal__button--secondary"
          onClick={() => closeModal()}
        >
          {t('contents_helpCenter_faq_addFaq_close_button_label')}
        </button>
      </div>
    </>
  );
}
