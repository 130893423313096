import React, { useEffect, useState } from 'react';
import './ReviewsSection.scss';
import { useDispatch, useSelector } from 'react-redux';
import { TabPanel } from '../Projects/ProjectsAll/ProjectAllUtil';
import AvailableProjects from './AvailableProjects/AvailableProjects';
import { ReviewsDate } from './ReviewsDate/ReviewsDate';
import { ManagerialPanel } from './ManagerialPanel/ManagerialPanel';
import { haveManagerialReviewSectionRole } from './ReviewsDate/Util';
import { PreviousPanel } from './PreviousPanel/PreviousPanel';
import { MultiTabsComponent } from '../Util/MultiTabsComponent/MultiTabsComponent';
import { useTheme } from '@material-ui/core';
import { setReviewNavigation } from '../../redux/actions/reviewNavigation';
import { MemoryHelpPage } from '../../pages/MemoryHelp/MemoryHelpPage';
import MemoryHelpViewer from '../MemoryHelp/MemoryHelpComponents/MemoryHelpViewer';
import MemoryHelpHeader from '../MemoryHelp/MemoryHelpComponents/MemoryHelpHeader';
// quitar importacion ManageAgreements
import ManageAgreements from './ManageAgreements/ManageAgreements';

import { useFormMemoryHelpContext } from '../../context/MemoryHelpFormContext';

export const ReviewsSection = () => {
  const [visibleComponent, setVisibleComponent] = useState('');
  const [userVisibleComponent, setUserVisibleComponent] = useState(false);

  const [projectId, setProjectId] = useState<number>();

  const reviewShowSelectProjects = useSelector<any, any>(state => state.reviewShowSelectProjects);

  const { role, country } = useSelector<any, any>(state => state.user);

  const { userCanEditRevision } = useFormMemoryHelpContext();

  const memoryHelpLocalRouter = () => {
    const pathRole = userCanEditRevision ? 'operationChief' : 'managerialPanel';
    switch (visibleComponent) {
      case 'managerialPanel':
        return <ManagerialPanel handleOnClick={setVisibleComponent} visibleComponent={setUserVisibleComponent} />;
      case 'operationChief':
        return (
          <OperationChiefView
            localRouter={setVisibleComponent}
            visibleComponent={setUserVisibleComponent}
            setProjectId={setProjectId}
            country={country}
          />
        );
      case 'memoryHelpPage':
        return (
          <>
            <MemoryHelpHeader setVisibleComponent={setVisibleComponent} navigatePath="operationChief" hasModal />
            <MemoryHelpPage handleOnClick={setVisibleComponent} />
          </>
        );
      case 'memoryHelpViewer':
        return (
          <>
            <MemoryHelpHeader setVisibleComponent={setVisibleComponent} navigatePath={pathRole} />
            <MemoryHelpViewer handleOnClick={setVisibleComponent} />;
          </>
        );

      case 'manageAgreements':
        return (
          <ManageAgreements
            projectId={projectId}
            localRouter={setVisibleComponent}
            visibleComponent={setUserVisibleComponent}
          />
        );

      default:
        return <ManagerialPanel handleOnClick={setVisibleComponent} visibleComponent={setUserVisibleComponent} />;
    }
  };

  if (userCanEditRevision) {
    return userVisibleComponent ? (
      memoryHelpLocalRouter()
    ) : reviewShowSelectProjects ? (
      userVisibleComponent ? (
        memoryHelpLocalRouter()
      ) : (
        <AvailableProjects />
      )
    ) : (
      <OperationChiefView
        localRouter={setVisibleComponent}
        visibleComponent={setUserVisibleComponent}
        setProjectId={setProjectId}
        country={country}
      />
    );
  }

  return (
    haveManagerialReviewSectionRole(role.id, country.name)
      ? (
        userVisibleComponent
          ? memoryHelpLocalRouter()
          : (
            <ManagerialPanel
              handleOnClick={setVisibleComponent}
              visibleComponent={setUserVisibleComponent}
            />
          )
      )
      : (
        <>
          {reviewShowSelectProjects
            ? <AvailableProjects />
            : <OperationChiefView localRouter={setVisibleComponent} visibleComponent={setUserVisibleComponent} country={country} />}
        </>
      )
  );
};

interface operationChiefProps {
  country: any;
  localRouter: any;
  visibleComponent?: any;
  setProjectId?: any;
}

const OperationChiefView = ({ country, localRouter, visibleComponent, setProjectId }: operationChiefProps) => {
  const dispatch = useDispatch();

  const { reviewNavigation } = useSelector<any, any>(state => state);
  const { reviewTabValue } = reviewNavigation;
  const theme = useTheme();

  const tabsTexts = ['upcoming', 'previous'];
  const handleSetMenuValue = menuValue => {
    dispatch(setReviewNavigation({ ...reviewNavigation, reviewTabValue: menuValue }));
  };
  return (
    <div className="reviews-section-container">
      <div className="reviews-section-tabs">
        <MultiTabsComponent tabValue={reviewTabValue} handleSetMenuValue={handleSetMenuValue} tabsTexts={tabsTexts} />
      </div>
      <TabPanel value={reviewTabValue} index={'upcoming'} dir={theme.direction}>
        <ReviewsDate localRouter={localRouter} visibleComponent={visibleComponent} setProjectId={setProjectId} />
      </TabPanel>
      <TabPanel value={reviewTabValue} index={'previous'} dir={theme.direction}>
        <PreviousPanel localRouter={localRouter} visibleComponent={visibleComponent} country={country} />
      </TabPanel>
      {/* Remove Manage Agreements */}
      {/* <ManageAgreements /> */}
    </div>
  );
};
