import React from 'react';
import modalStyles from './DisbursementTimeCardModal.module.scss';
import Modal from 'react-modal';
import { disableEscKey } from '../Util';
import CloseCrossButton from '../../Buttons/CloseCrossButton/CloseCrossButton';
import { useTranslation } from 'react-i18next';
import { BubbleDataCard, ModalPosition } from '../../../../models/DisbursementTimeIndicatorInterfaces';
import { useHistory } from 'react-router';
import NumberFormat from 'react-number-format';
const styles: Modal.Styles = {
  overlay: {
    right: 0,
    bottom: 0,
    justifyContent: 'left',
    alignItems: 'left',
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,0)',
    display: 'flex',
    zIndex: 10000,
  },
};

type Props = {
  isOpen: boolean;
  closeModal: Function;
  data: BubbleDataCard;
  modalPosition: ModalPosition;
};
export const DisbursementTimeCardModal = (props: Props) => {
  const { isOpen, closeModal, data, modalPosition } = props;
  const history = useHistory();
  const {
    code,
    title,
    loanNumber,
    yearsOfLegalValidity,
    currentDisbursement,
    pendingDisbursement,
    extension,
    projectId,
  } = data;
  const { t } = useTranslation();
  const {
    disbursementTimeCardModal,
    disbursementTimeCardModalContainer,
    closeCrossContainer,
    horizontal,
    halfHorizontal,
    itemLabel,
    itemData,
    title: titleStyle,
    codeProject,
    link,
  } = modalStyles;
  const handleLink = () => {
    history.push(`/projectdetail?id=${projectId}`);
  };

  const setPluralYearTransaltion = () => {
    return parseFloat(yearsOfLegalValidity) >= 2 ? t('years') : t('year');
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={e => disableEscKey(e, closeModal)}
      style={{
        ...styles,
        content: {
          ...styles.content,
          left: modalPosition.x,
          top: modalPosition.y,
        },
      }}
      className={disbursementTimeCardModal}
      shouldCloseOnOverlayClick={true}
    >
      <div className={disbursementTimeCardModalContainer}>
        <div className={closeCrossContainer}>
          <CloseCrossButton handleClick={closeModal} />
        </div>
        <div className={horizontal} style={{ justifyContent: 'left' }}>
          <span className={codeProject}>{code}</span>
        </div>
        <div className={horizontal}>
          <span className={titleStyle}>{title}</span>
        </div>

        <div className={horizontal}>
          <div className={halfHorizontal}>
            <span className={itemLabel}>{t('operation')}</span>
            <span className={itemData}>{loanNumber}</span>
          </div>
          <div className={halfHorizontal}>
            <span className={itemLabel}>{t('years_of_legal_validity')}</span>
            <span className={itemData}>
              <NumberFormat value={yearsOfLegalValidity} displayType={'text'} decimalScale={1} />
              {` ${setPluralYearTransaltion()}`}
            </span>
          </div>
        </div>
        <div className={horizontal}>
          <div className={halfHorizontal}>
            <span className={itemLabel}>{t('current_disbursement')}</span>
            <span className={itemData}>
              <NumberFormat
                value={currentDisbursement}
                displayType={'text'}
                decimalScale={hideDecimal(currentDisbursement) ? 0 : 1}
                suffix={'%'}
              />
            </span>
          </div>
          <div className={halfHorizontal}>
            <span className={itemLabel}>{t('disbursement_pending')}</span>
            <span className={itemData}>
              <NumberFormat
                value={pendingDisbursement}
                displayType={'text'}
                decimalScale={hideDecimal(pendingDisbursement) ? 0 : 1}
                suffix={'%'}
              />
            </span>
          </div>
        </div>
        <div className={horizontal}>
          <div className={halfHorizontal}>
            <span className={itemLabel}>{t('prorogation')}</span>
            <span className={itemData}>{extension ? t('yes') : t('no')}</span>
          </div>
        </div>

        <div className={horizontal}>
          <span className={link} onClick={handleLink}>
            {t('view_project_detail')}
          </span>
        </div>
      </div>
    </Modal>
  );
};

const hideDecimal = value => {
  return parseInt(value.split('.').length > 1 && value.split('.')[1].substring(0, 1)) === 0;
};
