import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Project from '../../../../../models/Project';
import { SortParameterProject } from '../../../../../models/SortParameterProjectEnum';
import { isMobile } from '../../../../../services/booleanUtil';
import PaginationTable from '../../../../PortfolioDetail/PaginationTable/PaginationTable';
import { Pagination } from '@material-ui/lab';
import usePaginationSort from '../../../../../hooks/usePaginationSort';
import { ExcelDownloadButton } from '../../../../Util/ExcelDownloadButton/ExcelDownloadButton';
import useDataNormalize from '../../../../../hooks/useDataNormalize';
import { useSelector } from 'react-redux';
import { ProjectsTableAccordanceRow } from './ProjectsTableAccordanceRow';
import './ProjectsTableAccordance.scss';
import { PmrClassification } from '../../../../../models/PmrClassification';
import { sortNumbers } from '../../../../../services/sortsUtil';
import { GcmStatus } from '../../../../../models/GcmAlerts';

const headers = ['id', 'Project Number', 'Operation', 'Name', 'Division', 'Performance'];

const selectedKeys = ['id', 'code', 'loanNumber', 'name', 'division_code', 'performanceClassification'];

interface ReceivedProps {
  projects: Project[];
  setInFollow: any;
  sortTable: any;
  isAnnual: any;
  selectable?: boolean;
  withComments?: boolean;
  reviewStyle?: boolean;
  canCreateAccordance?: boolean;
  fromReview?: boolean;
  revision?: any;
  fnPaginacion?: () => number;
  initialPage?: number;
  numberOfPages?: number;
  numberOfProjects?: number;
  setLocalRouter?: () => void;
  setVisibleComponent?: () => void;
  setProjectId?: () => void;
}

type Props = ReceivedProps;

const selectableThead = reviewStyle => {
  return reviewStyle ? 'theader-selectable' : 'theader-text uppercase-text';
};

const selectableI = reviewStyle => {
  return reviewStyle ? { marginLeft: 9 } : {};
};

const tBodyClassName = isMobile => {
  return isMobile ? 'row-responsive-text' : 'row-text';
};

const headersList = (isAnnual, reviewStyle, canCreateAccordance) => {
  const list: any = [
    { sortValue: { concept: SortParameterProject.Code }, label: 'code' },
    { sortValue: { concept: SortParameterProject.Operation }, label: 'operation' },
    { sortValue: { concept: SortParameterProject.Name }, label: 'name' },
    { sortValue: { concept: SortParameterProject.Division }, label: 'division' },
    {
      sortValue: { concept: SortParameterProject.PmrValue },
      label: 'performance',
    },
  ];

  return canCreateAccordance ? list.filter(item => !item.hideOnCanCreateAccordance) : list;
};

export const ProjectsTableAccordance = (props: Props) => {
  const { t } = useTranslation();
  const isAnnual = props.isAnnual === 'annual' ? true : false;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const isTabProject = useSelector<any, any>(state => state.tabValue);

  const {
    selectable,
    withComments,
    reviewStyle,
    canCreateAccordance,
    fromReview,
    revision,
    fnPaginacion,
    initialPage,
    numberOfPages,
    projects,
    setLocalRouter,
    setVisibleComponent,
    setProjectId,
  } = props;
  const setInFollow = (projectId: number) => {
    props.setInFollow(projectId);
  };
  const sortTable = (sortValue: {}) => {
    props.sortTable(sortValue);
  };

  const { handlePaginationChange, handleSort, currentList, pagesCount, currentPage } = usePaginationSort({
    projects: projects,
    defaultSortCriteria: 'title',
    pageCount: rowsPerPage,
  });

  const flattenObject = (obj, parentKey = '', result = {}) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        let propName = parentKey ? parentKey + '_' + key : key;

        if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
          flattenObject(obj[key], propName, result);
        } else {
          result[propName] = obj[key];
        }
      }
    }
    return result;
  };

  const projectsFlatten = props.projects.map((projects: any) => flattenObject(projects));
  const data = useDataNormalize(headers, selectedKeys, projectsFlatten);

  // Filtramos y agregamos los criterios para cada proyecto

  const alertTags = () => {
    const milisecondsToDays = 1000 * 60 * 60 * 24;

    currentList?.map((item, i) => {
      currentList[i].tags = [
        {
          value:
            item.baseInformation &&
            item.baseInformation.extensionInMonth > 0 &&
            item.baseInformation.extensionInMonth <= 24,
          label: 'extensions_until_24',
        },
        {
          value: item.baseInformation && item.baseInformation.extensionInMonth > 24,
          label: 'extensions_more_than_24',
        },
        {
          value: item.pendingElegibility !== null && item.pendingElegibility >= 180 && item.pendingElegibility < 271,
          label: 'between_180_and_270',
        },
        {
          value: item.pendingElegibility !== null && item.pendingElegibility > 270,
          label: 'more_than_270',
        },
        {
          value:
            item.baseInformation &&
            item.baseInformation.lastExtensionApprobedDate &&
            (new Date(item.baseInformation.lastExtensionApprobedDate).getTime() -
              new Date(item.revisionDate).getTime()) /
              milisecondsToDays >=
              90 &&
            (new Date(item.baseInformation.lastExtensionApprobedDate).getTime() -
              new Date(item.revisionDate).getTime()) /
              milisecondsToDays <=
              180,
          label: 'on_3_months',
        },
        {
          value:
            item.baseInformation &&
            item.baseInformation.lastExtensionApprobedDate &&
            (new Date(item.baseInformation.lastExtensionApprobedDate).getTime() -
              new Date(item.revisionDate).getTime()) /
              milisecondsToDays <
              90,
          label: 'out_of_date',
        },
        {
          value: item.financialInformation && item.financialInformation.accumuledDisbursedCurve === 1,
          label: 'filter_alert',
        },
        {
          value: item.financialInformation && item.financialInformation.accumuledDisbursedCurve === 2,
          label: 'filter_problem',
        },
        {
          value:
            item.financialInformation &&
            item.financialInformation.lastDisbursementInDays !== null &&
            item.financialInformation.lastDisbursementInDays >= 180 &&
            item.financialInformation.lastDisbursementInDays <= 365,
          label: 'without_disbursements_last_6_months',
        },
        {
          value:
            item.financialInformation &&
            item.financialInformation.lastDisbursementInDays !== null &&
            item.financialInformation.lastDisbursementInDays > 365,
          label: 'without_disbursements_last_12_months',
        },
        {
          value: item.pmrClassification === PmrClassification.Yellow,
          label: 'alert_PMR',
        },
        {
          value: item.pmrClassification === PmrClassification.Red,
          label: 'problem_PMR',
        },
        {
          value: item.pmrClassification === PmrClassification.Na,
          label: 'pmr_na',
        },
        {
          value:
            item.financialInformation &&
            item.financialInformation.daysSinceLastAdvance !== null &&
            item.financialInformation.daysSinceLastAdvance >= 180 &&
            item.financialInformation.daysSinceLastAdvance <= 360,
          label: 'between_180_and_360',
        },
        {
          value:
            item.financialInformation &&
            item.financialInformation.daysSinceLastAdvance !== null &&
            item.financialInformation.daysSinceLastAdvance > 360,
          label: 'more_than_360_days',
        },
        {
          value:
            (item.disbursementProjectionAnnual / item.portfolioProjectedAnnual) * 100 >= 5 &&
            (item.disbursementProjectionAnnual / item.portfolioProjectedAnnual) * 100 <= 10,
          label: 'between_5_and_10_percent_planned',
        },
        {
          value: (item.disbursementProjectionAnnual / item.portfolioProjectedAnnual) * 100 > 10,
          label: 'more_than_10_percent_planned',
        },
        {
          value:
            item.financialInformation &&
            item.financialInformation.efasPresentations &&
            item.financialInformation.efasPresentations.length &&
            item.financialInformation.efasPresentations.sort(sortNumbers('fiscalYear'))[0].efaComment,
          label: 'presented_obs',
        },
        {
          value:
            item.financialInformation &&
            item.financialInformation.efasPresentations &&
            item.financialInformation.efasPresentations.length &&
            (!item.financialInformation.efasPresentations.sort(sortNumbers('fiscalYear'))[0].presentationDate ||
              new Date(item.revisionDate).getFullYear() -
                new Date(
                  item.financialInformation.efasPresentations.sort(sortNumbers('fiscalYear'))[0].presentationDate,
                ).getFullYear() !==
                1),
          label: 'not_presented',
        },
        {
          value:
            item.financialInformation &&
            item.financialInformation.pariPassuDeviationPercent !== null &&
            item.financialInformation.pariPassuDeviationPercent >= 5 &&
            item.financialInformation.pariPassuDeviationPercent <= 15,
          label: 'between_5_and_15_percent',
        },
        {
          value:
            item.financialInformation &&
            item.financialInformation.pariPassuDeviationPercent !== null &&
            item.financialInformation.pariPassuDeviationPercent > 15,
          label: 'more_than_15_percent',
        },
        {
          value: item.withMici,
          label: 'with_mici',
        },
        {
          value: item.esgClasification === 'B',
          label: 'esg_b',
        },
        {
          value: item.esgClasification === 'A',
          label: 'esg_a',
        },
        {
          value: item.gcmAlerts && item.gcmAlerts.length && item.gcmAlerts.find(a => a.type === GcmStatus.Warning),
          label: 'warning_en',
        },
        {
          value:
            item.gcmAlerts && item.gcmAlerts.length && item.gcmAlerts.find(a => a.type === GcmStatus.InCancellation),
          label: 'in_cancellation_en',
        },
      ];
    });
  };

  useEffect(() => {
    alertTags();
  }, [currentList]);

  return (
    <Fragment>
      <ExcelDownloadButton headers={headers} selectedKeys={selectedKeys} dataExcel={data} />
      <div className="table-idb expand-table">
        <>
          <table style={reviewStyle ? { borderCollapse: 'collapse' } : {}}>
            {isMobile() ? null : (
              <thead className={selectableThead(reviewStyle)} style={{ height: '88px', backgroundColor: '#F1F3F8' }}>
                <tr>
                  {selectable && <th></th>}
                  {headersList(isAnnual, reviewStyle, canCreateAccordance).map(item => {
                    if (item.sortValue) {
                      return (
                        <th key={item.label} id={item.label} onClick={() => sortTable(item.sortValue || {})}>
                          <div className="text-head">
                            <div>{t(item.label)}</div>
                            <div>
                              <i style={selectableI(reviewStyle)} className="sort-icon"></i>
                            </div>
                          </div>
                        </th>
                      );
                    } else {
                      return (
                        <th key="not-sorted" className="not-sorted">
                          {t(item.label)}
                        </th>
                      );
                    }
                  })}
                  <th></th>
                  <th className="arrow"></th>
                </tr>
              </thead>
            )}
            <tbody className={tBodyClassName(isMobile())}>
              {currentList &&
                currentList.map((project: Project) => (
                  <ProjectsTableAccordanceRow
                    key={project.id}
                    project={project}
                    setInFollow={setInFollow}
                    selectable={selectable}
                    withComments={withComments}
                    canCreateAccordance={canCreateAccordance}
                    fromReview={fromReview}
                    revision={revision}
                    setLocalRouter={setLocalRouter}
                    setVisibleComponent={setVisibleComponent}
                    setProjectId={setProjectId}
                  />
                ))}
            </tbody>
          </table>
        </>
      </div>
      {isTabProject === 1 ? (
        <div className="pagination-style pagination-right">
          <Pagination count={numberOfPages} page={initialPage} onChange={fnPaginacion} />
        </div>
      ) : (
        <div className="pagination-style">
          <PaginationTable fnSetRows={setRowsPerPage} rows={rowsPerPage} numProyects={projects.length} />
          <Pagination count={pagesCount} page={currentPage} onChange={handlePaginationChange} />
        </div>
      )}
    </Fragment>
  );
};
