import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isMoney } from '../PortfolioDetailsUtil';
import SelectOption from '../../../models/SelectOption';
import { capitalizeFirstLetter } from '../../../services/stringUtil';
import { isMobile } from '../../../services/booleanUtil';
import { MultiDataNotStackedBar } from '../../Portfolio/CardIndicator/Cards/MultiDataNotStackedBar/MultiDataNotStackedBar';
import CustomSelect from '../../Util/CustomSelect/CustomSelect';
import SummaryHeaderSingleValue from '../SummaryHeaderSingleValue/SummaryHeaderSingleValue';
import styles from './ActualProjectedDifferenceDetail.module.scss';
import { filterDatasets, getCustomOptionsChart, headerValuesEmpty } from './util';
import { SelectOptionId } from '../../../models/enums/ProjectedVsCompletedIndicatorFilter';
import { getNewFilteredIndicators } from '../../../api/indicatorApi';
import { processIndicators } from '../../Portfolio/indicatorHelper';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentIndicator } from '../../../redux/actions/currentIndicator';
import {
  getProjectsSimpleTableForSupervisionPlanPlannedVersusExecuted,
  resetIndicatorProjects,
} from '../../../redux/actions/indicatorProjects';
import { ActualProjectedDifferenceTable } from './ActualProjectedDifferenceTable/ActualProjectedDifferenceTable';
import './ActualProjectedDifferenceDetail..scss';

const getLocalFilterActivities = () => (localStorage.getItem('activities') === 'true' ? true : false);

const getDefaultSelectOption = () => {
  const attributesFilter = localStorage.getItem('childCriticalProduct');
  if (attributesFilter) {
    return filterOptions[SelectOptionId[attributesFilter.toUpperCase()]];
  }
  return localStorage.getItem('activities') === 'true'
    ? filterOptions[SelectOptionId.SupervisionPlanActivity]
    : filterOptions[SelectOptionId.CriticalProduct];
};

const setDefaultSelectOption = state => state(getDefaultSelectOption());
const setFilterLabelInformationTranslation = state =>
  state(getLocalFilterActivities() ? 'activities' : 'critical_products');
const setLocalStorageItem = (itemName, value) =>
  localStorage.setItem(itemName, value === 'activities' ? 'true' : 'false');

const setLocalStorageChildItem = (itemName, value) => {
  localStorage.setItem(itemName, value);
};

export const ActualProjectedDifferenceDetail = () => {
  getDefaultSelectOption();
  const { currentIndicator } = useSelector<any, any>(state => state);
  const dispatch = useDispatch();
  const filterIsActivity = localStorage.getItem('activities') === 'true';
  const [filterdIndicator, setFilteredIndicator] = useState(
    filterDatasets(currentIndicator, 'dataFullYear', filterIsActivity),
  );
  const { t } = useTranslation();
  const headerValues = headerValuesEmpty.map((h, i) => ({ ...h, value: currentIndicator.headerData[i] }));
  const customStyle = isMobile() ? { marginLeft: 60, width: '100%', paddingBottom: 20 } : { marginLeft: 60 };
  const customValueStyle = { fontSize: 36 };
  const customSubtitleStyle = { fontSize: 16, fontWeight: 300, color: '#264377', paddingTop: 10 };
  const [selectedOption, setSelectedOption] = setDefaultSelectOption(useState);
  const [filterAppliedInformation, setFilterAppliedInformation] = setFilterLabelInformationTranslation(useState);
  const monthToBackgroundChart = filterdIndicator.dataFullYear.datasets[0].actualMonth - 1;
  const customOptionsChart = getCustomOptionsChart(filterdIndicator.dataFullYear, t, monthToBackgroundChart);

  const applyFilter = () => {
    setFilteredIndicator(filterDatasets(currentIndicator, 'dataFullYear', filterIsActivity));
  };

  useEffect(() => {
    applyFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  useEffect(() => {
    setFilteredIndicator(filterDatasets(currentIndicator, 'dataFullYear', filterIsActivity));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndicator]);

  const handleSelectedFilter = (item: SelectOption) => {
    setLocalStorageItem(
      'activities',
      item.id === SelectOptionId.SupervisionPlanActivity ? 'activities' : 'critical_products',
    );
    if (item.id !== SelectOptionId.SupervisionPlanActivity && item.id !== SelectOptionId.CriticalProduct) {
      setLocalStorageChildItem('childCriticalProduct', item.value);
    } else {
      localStorage.removeItem('childCriticalProduct');
    }
    setSelectedOption(item);
    setFilterAppliedInformation(item.value);
    getDataAndProcessOneIndicator(currentIndicator.id, item);
    dispatch(resetIndicatorProjects());
  };

  const getDataAndProcessOneIndicator = async (indicatorId, itemSelected: SelectOption) => {
    const options = {
      indicatorId,
      actualProjectedDifferenceFilters: itemSelected.id,
    };
    const currentIndicatorOnDetail = await getNewFilteredIndicators(options);
    const justCurrentIndicatorOnDetail = {
      ...currentIndicatorOnDetail,
      indicatorsData: currentIndicatorOnDetail.indicatorsData.filter(i => i.id === indicatorId),
    };

    if (justCurrentIndicatorOnDetail.indicatorsData[0].metrics.length) {
      const indicatorsProcessed = processIndicators(justCurrentIndicatorOnDetail);

      dispatch(setCurrentIndicator(indicatorsProcessed[0]));
    }
  };

  const handleBarClick = barClicked => {
    if (barClicked.length > 0) {
      const month = barClicked[0]._index + 1;
      getFilteredSimpleIndicators(dispatch, currentIndicator.id, {
        month,
        actualProjectedDifferenceFilters: selectedOption.id,
      });
      chartDataColorsUpdated(currentIndicator, month);
    }
  };

  const basePlannedColor = '#b1cbff';
  const baseActualColor = '#004ce4';
  const selectedPlannedColor = 'rgba(203, 222, 254, 0.4)';
  const selectedActualColor = 'rgba(38, 67, 119, 0.4)';
  const updateChartColors = (
    selectedMonth: number,
    labels: string[],
    baseColor: string,
    selectedPlannedColor: string,
  ) => {
    const colorsList = new Array(labels.length).fill(baseColor);

    const newColorIndex = selectedMonth - 1;
    colorsList[newColorIndex] = selectedPlannedColor;
    return colorsList;
  };
  const chartDataColorsUpdated = (chartData: any, month: number) => {
    return {
      ...chartData,
      dataFullYear: {
        ...chartData.dataFullYear,
        datasets: chartData.dataFullYear.datasets.map(i => {
          if (i.label === 'planned_advance_activity') {
            i.backgroundColor = updateChartColors(
              month,
              currentIndicator.dataFullYear.labels,
              basePlannedColor,
              selectedPlannedColor,
            );
            return i;
          } else if (i.label === 'real_advance_activity') {
            i.backgroundColor = updateChartColors(
              month,
              currentIndicator.dataFullYear.labels,
              baseActualColor,
              selectedActualColor,
            );
            return i;
          } else {
            return i;
          }
        }),
      },
    };
  };

  return (
    <div className={styles.layout}>
      <div className={styles.header}>
      <div className={`${'header-value'} ${styles.headerValues}`}>
          {headerValues.map(hv => (
            <SummaryHeaderSingleValue
              value={isMoney(hv.value, hv.thousandDot)}
              title={t(hv.text)}
              customStyle={hv.extraStyle ? { ...customStyle, ...hv.extraStyle } : customStyle}
              customValueStyle={customValueStyle}
              customSubtilteStyle={customSubtitleStyle}
            />
          ))}
        </div>
        <div className={styles.headerInformation}>{t('includes_information_of_detailed_plans_only')}</div>
      </div>
      <div className={styles.filterContainer}>
        <span className={styles.lbl}>{`${t('filter_by')}: `}</span>
        <CustomSelect
          options={filterOptions}
          selectedOption={selectedOption}
          handleSelected={handleSelectedFilter}
          type={'bold-font'}
          listItemStyles={optionsStyles}
        />
        <ul className={styles.informativeUnorderedList}>
          <li className={styles.projectedProgress}>{t('projected_progress')}</li>
          <li className={styles.actualProgress}>{t('actual_progress')}</li>
        </ul>
      </div>
      <div className={styles.filterAppliedInformation}>{`# ${t(filterAppliedInformation)}`}</div>
      <div className={styles.chartContainer}>
        {
          <MultiDataNotStackedBar
            data={barChartData(filterdIndicator, t)}
            customOption={customOptionsChart}
            customDimensions={{ desktop: { width: 270, height: 60 }, mobile: { width: 135, height: 100 } }}
            datasetClick={handleBarClick}
            indicatorKey={currentIndicator.indicatorKey}
          />
        }
      </div>
      <ActualProjectedDifferenceTable />
    </div>
  );
};

const filterOptions: SelectOption[] = [
  { value: 'activities', id: SelectOptionId.SupervisionPlanActivity, label: `activities` },
  { value: 'critical_products', id: SelectOptionId.CriticalProduct, label: `critical_products` },
  { value: 'boot_workshop', id: SelectOptionId.BOOT_WORKSHOP, label: `boot_workshop` },
  { value: 'intermediate_evaluation', id: SelectOptionId.INTERMEDIATE_EVALUATION, label: `intermediate_evaluation` },
  { value: 'closing_workshop', id: SelectOptionId.CLOSING_WORKSHOP, label: `closing_workshop` },
];

const optionsStyles: any[] = [
  { fontWeight: 700 },
  { fontWeight: 700 },
  { fontWeight: 400 },
  { fontWeight: 400 },
  { fontWeight: 400 },
];

const barChartData = (card, t) => {
  return {
    ...card.dataFullYear,
    labels: card.dataFullYear.labels.map(label => {
      return capitalizeFirstLetter(t(label).toLowerCase());
    }),
  };
};

const getFilteredSimpleIndicators = (d, currentIndicatorId, options) => {
  return d(getProjectsSimpleTableForSupervisionPlanPlannedVersusExecuted(currentIndicatorId, options));
};
