import { MenuTabSectionEnum } from '../components/MenuTabs/userTabsInterface';
import { FilterTypesEnum } from '../models/FitlerTypesEnum';
import { IncludeRegionalProjectsEnum } from '../models/IncludeRegionalProjectsEnum';
import { store } from '../redux/store';
import { isEmpty } from '../services/booleanUtil';
import {
  addMenuTabFilter,
  getProductTypesFilterAndIgConfigFromStore,
  getReturnedParamsFilters,
  getReturnedParmasIndicator,
} from './filterPortfolioAndProjectUtil';
import { postIndicatorsProjects } from './indicatorApi';
import { basicPost } from './projectApi';

/**
 * Used by indicator key PMR_PERFORMANCE_LAST_CLOSED_YEAR
 */
export const getAllProjectsIndicatorPmrDivision = (pmr, division, totalProjects, indicatorId) => {
  const returnedParamsFilters = getReturnedParamsFilters();
  const { projectProductTypes, projectProductAmounts } = getProductTypesFilterAndIgConfigFromStore();
  const internalIndicatorFilterDivision = {
    filterType: FilterTypesEnum.NumberIn,
    field: 'DivisionId',
    value: [division],
  };
  const internalIndicatorFilterPmr = {
    filterType: FilterTypesEnum.NumberIn,
    field: 'PmrClassification',
    value: [pmr],
  };

  let returnedParams = {
    pageNumber: 1,
    itemsPerPage: totalProjects,
    sortParameter: 1,

    indicatorId,
    coReponsibleDivisions: returnedParamsFilters.coReponsibleDivisionsFilter,
    participantDivisions: returnedParamsFilters.participantDivisionsFilter,
    interestIssues: returnedParamsFilters.interestIssuesFilter,
    teamLeaders: returnedParamsFilters.teamLeadersFilter,
    regions: returnedParamsFilters.regionsFilter.length > 0 ? returnedParamsFilters.regionsFilter : [],
    Filters: [] as any,
    projectProductTypes,
    projectProductAmounts,
  };
  returnedParams = getReturnedParmasIndicator(returnedParamsFilters, returnedParams);
  returnedParams = addMenuTabFilter(returnedParams, MenuTabSectionEnum.PORTFOLIO_MENU_TAB);
  const divisionIndex = returnedParams.Filters.findIndex(f => f.field === 'DivisionId');
  if (divisionIndex !== -1) {
    returnedParams.Filters[divisionIndex] = internalIndicatorFilterDivision;
  } else {
    returnedParams.Filters.push(internalIndicatorFilterDivision);
  }

  const pmrIndex = returnedParams.Filters.findIndex(f => f.field === 'PmrClassification');
  if (pmrIndex !== -1) {
    returnedParams.Filters[pmrIndex] = internalIndicatorFilterPmr;
  } else {
    returnedParams.Filters.push(internalIndicatorFilterPmr);
  }
  return postIndicatorsProjects(returnedParams);
};

export const getAllProjectsGenericDoughnutIndicator = (classification, totalProjects, indicatorId, field) => {
  const returnedParamsFilters = getReturnedParamsFilters();
  const { projectProductTypes, projectProductAmounts } = getProductTypesFilterAndIgConfigFromStore();

  let returnedParams = {
    pageNumber: 1,
    itemsPerPage: totalProjects,
    sortParameter: 1,
    indicatorId,
    regions: returnedParamsFilters.regionsFilter.length > 0 ? returnedParamsFilters.regionsFilter : [],
    coReponsibleDivisions: returnedParamsFilters.coReponsibleDivisionsFilter,
    participantDivisions: returnedParamsFilters.participantDivisionsFilter,
    interestIssues: returnedParamsFilters.interestIssuesFilter,
    teamLeaders: returnedParamsFilters.teamLeadersFilter,
    Filters: [
      {
        filterType: FilterTypesEnum.NumberIn,
        field,
        value: [classification],
      },
    ],
    projectProductTypes,
    projectProductAmounts,
  };

  returnedParams = getReturnedParmasIndicator(returnedParamsFilters, returnedParams);
  returnedParams = addMenuTabFilter(returnedParams, MenuTabSectionEnum.PORTFOLIO_MENU_TAB);
  return postIndicatorsProjects(returnedParams);
};

/*
Used to get projects on indicator detail:
 PROJECTS_SUMMARY_PORTFOLIO (Filtro por PMR alerta y problema)
 */
export const getAllProjectsByMultiplesPmr = (pmr = [], indicatorId) => {
  const filterIndicators = store.getState().filterIndicators;
  const returnedParamsFilters = getReturnedParamsFilters();
  const haveIncludeRegionalProjects = filterIndicators.optionSelectedApplied
    ? filterIndicators.optionSelectedApplied
    : IncludeRegionalProjectsEnum.NO_INCLUDE_PROJECTS;

  const { projectProductTypes, projectProductAmounts } = getProductTypesFilterAndIgConfigFromStore();

  let returnedParams = {
    pageNumber: 1,
    itemsPerPage: 10000000,
    sortParameter: 1,
    includeRegionalProjects: haveIncludeRegionalProjects,
    indicatorId,
    regions: returnedParamsFilters.regionsFilter.length > 0 ? returnedParamsFilters.regionsFilter : [],
    coReponsibleDivisions: returnedParamsFilters.coReponsibleDivisionsFilter,
    participantDivisions: returnedParamsFilters.participantDivisionsFilter,
    interestIssues: returnedParamsFilters.interestIssuesFilter,
    teamLeaders: returnedParamsFilters.teamLeadersFilter,
    Filters: [
      {
        filterType: FilterTypesEnum.NumberIn,
        field: 'PmrClassification',
        value: pmr,
      },
    ],
    projectProductTypes,
    projectProductAmounts,
  };
  returnedParams = getReturnedParmasIndicator(returnedParamsFilters, returnedParams);
  returnedParams = addMenuTabFilter(returnedParams, MenuTabSectionEnum.PORTFOLIO_MENU_TAB);
  return postIndicatorsProjects(returnedParams);
};

/*
 *Used by indicator
  DISTRIBUTION_ACCORDING_TO_LATEST_EFAS
 */
export const getAllProjectsIndicatorLatestEfas = (efaConcept, totalProjects, indicatorId, fiscalYear) => {
  const returnedParamsFilters = getReturnedParamsFilters();
  const { projectProductTypes, projectProductAmounts } = getProductTypesFilterAndIgConfigFromStore();

  let returnedParams = {
    pageNumber: 1,
    itemsPerPage: totalProjects,
    sortParameter: 1,
    indicatorId,
    regions: returnedParamsFilters.regionsFilter.length > 0 ? returnedParamsFilters.regionsFilter : [],
    coReponsibleDivisions: returnedParamsFilters.coReponsibleDivisionsFilter,
    participantDivisions: returnedParamsFilters.participantDivisionsFilter,
    interestIssues: returnedParamsFilters.interestIssuesFilter,
    teamLeaders: returnedParamsFilters.teamLeadersFilter,
    categorization: efaConcept,
    Filters: [],
    projectProductTypes,
    projectProductAmounts,
  };
  returnedParams = getReturnedParmasIndicator(returnedParamsFilters, returnedParams);
  returnedParams = addMenuTabFilter(returnedParams, MenuTabSectionEnum.PORTFOLIO_MENU_TAB);
  const addFiscalYear: any = [
    ...returnedParams.Filters,
    { filterType: FilterTypesEnum.Equal, field: 'FiscalYear', value: fiscalYear },
  ];
  returnedParams = { ...returnedParams, Filters: addFiscalYear };
  return postIndicatorsProjects(returnedParams);
};

/*
  Used by SUPERVISION_PLAN_BUDGET_STATUS indicator (Recursos Transaccionales de supervision)
*/
export const getAllProjectsIndicatorTransactionalSupervisionResources = (fundingSource: string, indicatorId) => {
  const returnedParamsFilters = getReturnedParamsFilters();
  const { projectProductTypes, projectProductAmounts } = getProductTypesFilterAndIgConfigFromStore();
  let returnedParams: any = {
    pageNumber: 1,
    itemsPerPage: 100000,
    sortParameter: 1,
    indicatorId,
    regions: returnedParamsFilters.regionsFilter.length > 0 ? returnedParamsFilters.regionsFilter : [],
    coReponsibleDivisions: returnedParamsFilters.coReponsibleDivisionsFilter,
    participantDivisions: returnedParamsFilters.participantDivisionsFilter,
    interestIssues: returnedParamsFilters.interestIssuesFilter,
    teamLeaders: returnedParamsFilters.teamLeadersFilter,
    Filters: [],
    projectProductTypes,
    projectProductAmounts,
  };

  if (fundingSource !== 'all') {
    returnedParams = { ...returnedParams, fundingSource };
  }
  returnedParams = getReturnedParmasIndicator(returnedParamsFilters, returnedParams);
  returnedParams = addMenuTabFilter(returnedParams, MenuTabSectionEnum.PORTFOLIO_MENU_TAB);
  return postIndicatorsProjects(returnedParams);
};

/*
  Used by ESG_DISTRIBUTION_ACCORDING_TO_ENVIRONMENTAL_AND_SOCIAL_CATEGORY
*/
export const getAllProjectsIndicatorEsgDistributionAccordingEnvironmentalAndSocial = (
  esgClassification,
  totalProjects,
  indicatorId,
) => {
  const returnedParamsFilters = getReturnedParamsFilters();
  const { projectProductTypes, projectProductAmounts } = getProductTypesFilterAndIgConfigFromStore();
  let returnedParams = {
    pageNumber: 1,
    itemsPerPage: totalProjects,
    sortParameter: 1,
    indicatorId,
    regions: returnedParamsFilters.regionsFilter.length > 0 ? returnedParamsFilters.regionsFilter : [],
    coReponsibleDivisions: returnedParamsFilters.coReponsibleDivisionsFilter,
    participantDivisions: returnedParamsFilters.participantDivisionsFilter,
    interestIssues: returnedParamsFilters.interestIssuesFilter,
    teamLeaders: returnedParamsFilters.teamLeadersFilter,
    Filters: [],
    projectProductTypes,
    projectProductAmounts,
  };
  returnedParams = getReturnedParmasIndicator(returnedParamsFilters, returnedParams);
  returnedParams = addMenuTabFilter(returnedParams, MenuTabSectionEnum.PORTFOLIO_MENU_TAB);
  const addFilterEsg: any = [
    ...returnedParams.Filters,
    { filterType: FilterTypesEnum.Equal, field: 'EsgClassification', value: esgClassification },
  ];
  returnedParams = { ...returnedParams, Filters: addFilterEsg };
  return postIndicatorsProjects(returnedParams);
};

/*
  Used by ESG_ENVIRONMENTAL_AND_SOCIAL_RISK_RATING
          ESG_SAFEGUARD_PERFORMANCE_RATING
*/
export const getAllProjectsIndicatorEsgSafeguardPerformance = (
  esgField,
  esgValue,
  totalProjects,
  indicatorId,
  sectorFilters,
  regionFilters,
) => {
  const returnedParamsFilters = getReturnedParamsFilters();
  const sectorFiltersIsEmpty = isEmpty(sectorFilters);
  const regionFiltersIsEmpty = isEmpty(regionFilters);
  const { projectProductTypes, projectProductAmounts } = getProductTypesFilterAndIgConfigFromStore();

  let returnedParams: any = {
    pageNumber: 1,
    itemsPerPage: totalProjects,
    sortParameter: 1,
    indicatorId,
    regions: returnedParamsFilters.regionsFilter.length > 0 ? returnedParamsFilters.regionsFilter : [],
    coReponsibleDivisions: returnedParamsFilters.coReponsibleDivisionsFilter,
    participantDivisions: returnedParamsFilters.participantDivisionsFilter,
    interestIssues: returnedParamsFilters.interestIssuesFilter,
    teamLeaders: returnedParamsFilters.teamLeadersFilter,
    Filters: [],
    projectProductTypes,
    projectProductAmounts,
  };
  returnedParams = getReturnedParmasIndicator(returnedParamsFilters, returnedParams);
  returnedParams = addMenuTabFilter(returnedParams, MenuTabSectionEnum.PORTFOLIO_MENU_TAB);
  const addFilterEsg: any = [
    ...returnedParams.Filters,
    { filterType: FilterTypesEnum.Equal, field: esgField, value: esgValue },
  ];
  returnedParams = { ...returnedParams, Filters: addFilterEsg };
  if (!sectorFiltersIsEmpty || !regionFiltersIsEmpty) {
    if (sectorFilters.sectorId) {
      const sectorFilter = { filterType: FilterTypesEnum.Equal, field: 'SectorId', value: sectorFilters.sectorId };
      returnedParams = { ...returnedParams, Filters: [...returnedParams.Filters, sectorFilter] };
    }

    if (regionFilters.regionId) {
      const updateRegionsObject = [...returnedParams.regions, parseInt(regionFilters.regionId)];
      returnedParams = { ...returnedParams, regions: updateRegionsObject };
    }
  }

  return postIndicatorsProjects(returnedParams);
};

/**
 * Used by indicator key MONTHLY_DISBURSEMENT_PROJECTION_CURRENT_YEAR_INCLUDING_REGIONALS
 */
export const getAllProjectsIndicatorByDate = (date, indicatorId, ExcludeInstrument?) => {
  const returnedParamsFilters = getReturnedParamsFilters();
  const { projectProductTypes, projectProductAmounts } = getProductTypesFilterAndIgConfigFromStore();

  let returnedParams = {
    pageNumber: 1,
    itemsPerPage: 1000000,
    sortParameter: 1,
    indicatorId,
    regions: returnedParamsFilters.regionsFilter.length > 0 ? returnedParamsFilters.regionsFilter : [],
    coReponsibleDivisions: returnedParamsFilters.coReponsibleDivisionsFilter,
    participantDivisions: returnedParamsFilters.participantDivisionsFilter,
    interestIssues: returnedParamsFilters.interestIssuesFilter,
    teamLeaders: returnedParamsFilters.teamLeadersFilter,
    WithDisbursementsInMonth: date,
    Filters: [] as any,
    assignedToMe: false,
    projectProductTypes,
    projectProductAmounts,
    parameter: ExcludeInstrument,
  };

  returnedParams = getReturnedParmasIndicator(returnedParamsFilters, returnedParams);
  returnedParams = addMenuTabFilter(returnedParams, MenuTabSectionEnum.PORTFOLIO_MENU_TAB);
  return postIndicatorsProjects(returnedParams);
};

/*
  Used by CALENDAR_EVENTS indicator
*/
export const getAllProjectsCalendarIndicator = (date, indicatorId) => {
  const returnedParamsFilters = getReturnedParamsFilters();
  const { projectProductTypes, projectProductAmounts } = getProductTypesFilterAndIgConfigFromStore();
  let returnedParams = {
    pageNumber: 1,
    itemsPerPage: 1000000,
    sortParameter: 1,
    indicatorId,
    regions: returnedParamsFilters.regionsFilter.length > 0 ? returnedParamsFilters.regionsFilter : [],
    coReponsibleDivisions: returnedParamsFilters.coReponsibleDivisionsFilter,
    participantDivisions: returnedParamsFilters.participantDivisionsFilter,
    interestIssues: returnedParamsFilters.interestIssuesFilter,
    teamLeaders: returnedParamsFilters.teamLeadersFilter,
    Filters: [
      {
        filterType: FilterTypesEnum.Equal,
        field: 'CalendarDate',
        value: date,
      },
    ],
    projectProductTypes,
    projectProductAmounts,
  };
  returnedParams = getReturnedParmasIndicator(returnedParamsFilters, returnedParams);
  returnedParams = addMenuTabFilter(returnedParams, MenuTabSectionEnum.PORTFOLIO_MENU_TAB);
  return postIndicatorsProjects(returnedParams);
};

/**
 * Used by indicator key PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS_BY_DIVISION
 */
export const getAllProjectsIndicatorDivision = (completeGroupingValue, totalProjects, indicatorId) => {
  const returnedParamsFilters = getReturnedParamsFilters();
  const { projectProductTypes, projectProductAmounts } = getProductTypesFilterAndIgConfigFromStore();
  const internalIndicatorFilter = {
    filterType: FilterTypesEnum.ValueIn,
    field: 'ResponsibleUnit',
    value: [completeGroupingValue],
  };
  let returnedParams = {
    pageNumber: 1,
    itemsPerPage: totalProjects,
    sortParameter: 1,
    indicatorId,
    regions: returnedParamsFilters.regionsFilter.length > 0 ? returnedParamsFilters.regionsFilter : [],
    Filters: [] as any,
    coReponsibleDivisions: returnedParamsFilters.coReponsibleDivisionsFilter,
    participantDivisions: returnedParamsFilters.participantDivisionsFilter,
    interestIssues: returnedParamsFilters.interestIssuesFilter,
    teamLeaders: returnedParamsFilters.teamLeadersFilter,
    projectProductTypes,
    projectProductAmounts,
  };

  returnedParams = getReturnedParmasIndicator(returnedParamsFilters, returnedParams);
  returnedParams = addMenuTabFilter(returnedParams, MenuTabSectionEnum.PORTFOLIO_MENU_TAB);
  const completeGroupingValueIndex = returnedParams.Filters.findIndex(f => f.field === 'completeGroupingValue');

  if (completeGroupingValueIndex !== -1) {
    returnedParams.Filters[completeGroupingValueIndex] = internalIndicatorFilter;
  } else {
    returnedParams.Filters.push(internalIndicatorFilter);
  }

  return postIndicatorsProjects(returnedParams);
};

/**
 * Used by indicator key PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS_BY_COUNTRY
 */
export const getAllProjectsIndicatorCountry = countryId => {
  const returnedParamsFilters = getReturnedParamsFilters();
  const { projectProductTypes, projectProductAmounts } = getProductTypesFilterAndIgConfigFromStore();
  const countryFilter = {
    filterType: FilterTypesEnum.NumberIn,
    field: 'ResponsibleCountryId',
    value: [countryId],
  };
  let returnedParams = {
    pageNumber: 1,
    itemsPerPage: 100000,
    sortParameter: 1,
    regions: returnedParamsFilters.regionsFilter.length > 0 ? returnedParamsFilters.regionsFilter : [],
    Filters: [] as any,
    coReponsibleDivisions: returnedParamsFilters.coReponsibleDivisionsFilter,
    participantDivisions: returnedParamsFilters.participantDivisionsFilter,
    interestIssues: returnedParamsFilters.interestIssuesFilter,
    teamLeaders: returnedParamsFilters.teamLeadersFilter,
    projectProductTypes,
    projectProductAmounts,
  };

  returnedParams = getReturnedParmasIndicator(returnedParamsFilters, returnedParams);
  returnedParams = addMenuTabFilter(returnedParams, MenuTabSectionEnum.PORTFOLIO_MENU_TAB);

  const responsibleCountryIdIndex = returnedParams.Filters.findIndex(f => f.field === 'ResponsibleCountryId');

  if (responsibleCountryIdIndex !== -1) {
    returnedParams.Filters[responsibleCountryIdIndex] = countryFilter;
  } else {
    returnedParams.Filters.push(countryFilter);
  }
  //Countries indicator should not include subProjects
  returnedParams.Filters.push({ filterType: FilterTypesEnum.Is, field: 'IsSubproject', value: false });

  return basicPost(returnedParams);
};
