import React, { useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import { multiSelectStyle, DropdownIndicator } from '../FilterComponent.Styles';
import { useTranslation } from 'react-i18next';
import {
  getCustomTabDefaultValueFromProps,
  getDivisionFilterOptions,
  getPortfolioOrProjectSelectedTab,
} from '../UtilFunctions';
import { CalledFrom } from '../../../models/CalledFromEnum';
import { setFilterIndicators } from '../../../redux/actions/filterIndicators';
import { setFilter } from '../../../redux/actions/filter';
import SelectOption from '../../../models/SelectOption';
import { AppMenuTabs } from '../../MenuTabs/userTabsInterface';
import useFilters from '../../../hooks/useFilters';
import { TagFilterContext } from '../../../context/TagFilterContext';

type Props = {
  setOptionSelected?: Function;
  calledFrom: CalledFrom;
  isEdit?: boolean;
  divisionOptionsFromParent?: SelectOption[];
  setParticipantDivisionOnParent?: Function;
};

const handleSelectedParticipantDivisionsOptions = (selectedDivisions, allOptions) => {
  return selectedDivisions.length ? allOptions.filter(item => selectedDivisions.includes(item.id)) : allOptions;
};

const getFilteredSelectOptions = (allOptions: SelectOption[], menuTabs: AppMenuTabs, calledFrom: CalledFrom) => {
  const portfolioSelectedTab = menuTabs.portfolioSelectedTab;
  const projectSelectedTab = menuTabs.projectSelectedTab;
  const earlyWarningsSelectedTab = menuTabs.earlyWarningsSelectedTab;

  if (calledFrom === CalledFrom.PORTFOLIO) {
    if (portfolioSelectedTab.tabId === 0) {
      return allOptions;
    } else {
      const selectedDivisions = portfolioSelectedTab.participantDivisions;
      return handleSelectedParticipantDivisionsOptions(selectedDivisions, allOptions);
    }
  } else if (calledFrom === CalledFrom.PROJECT) {
    if (projectSelectedTab.tabId === 0) {
      return allOptions;
    } else {
      const selectedDivisions = projectSelectedTab.participantDivisions;
      return handleSelectedParticipantDivisionsOptions(selectedDivisions, allOptions);
    }
  } else if (calledFrom === CalledFrom.EARLY_WARNINGS) {
    if (earlyWarningsSelectedTab.tabId === 0) {
      return allOptions;
    } else {
      const selectedDivisions = earlyWarningsSelectedTab.participantDivisions;
      return handleSelectedParticipantDivisionsOptions(selectedDivisions, allOptions);
    }
  } else {
    return allOptions;
  }
};

const handleParticipatingDivisionOptions = (
  divisionOptionsFromParent,
  menuTabs,
  calledFrom,
  allParticipatingDivisionOptions,
) => {
  return divisionOptionsFromParent
    ? getFilteredSelectOptions(divisionOptionsFromParent, menuTabs, calledFrom)
    : getFilteredSelectOptions(allParticipatingDivisionOptions, menuTabs, calledFrom);
};

const ParticipatingDivisionFilter = (props: Props) => {
  const { setOptionSelected, calledFrom, isEdit, divisionOptionsFromParent, setParticipantDivisionOnParent } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { filterEarlyWarnings, setFilterEarlyWarnings } = useFilters();

  const filterIndicators = useSelector<any, any>(state => state.filterIndicators);
  const filterProjects = useSelector<any, any>(state => state.filter);
  const divisionsFromRedux = useSelector<any, any>(state => state.divisions);
  const menuTabs = useSelector<any, any>(state => state.menuTabs);

  const selectedTab = getPortfolioOrProjectSelectedTab(calledFrom, menuTabs);
  const allParticipatingDivisionOptions = getDivisionFilterOptions(divisionsFromRedux);
  const participatingDivisionOptions = handleParticipatingDivisionOptions(
    divisionOptionsFromParent,
    menuTabs,
    calledFrom,
    allParticipatingDivisionOptions,
  );

  const handleChange = value => {
    if (value === null) {
      value = [];
    }

    setParticipantDivisionOnParent && setParticipantDivisionOnParent(value);

    const divisionsSelected = value.map(option => ({
      id: option.id,
      value: option.value,
      label: option.label + ' (Participant)',
      selectedByUser: true,
    }));

    switch (calledFrom) {
      case CalledFrom.PORTFOLIO:
        dispatch(
          setFilterIndicators({
            ...filterIndicators,
            common: {
              ...filterIndicators.common,
              participantDivisions: divisionsSelected,
            },
          }),
        );
        break;
      case CalledFrom.PROJECT:
        dispatch(
          setFilter({
            ...filterProjects,
            common: {
              ...filterProjects.common,
              participantDivisions: divisionsSelected,
            },
          }),
        );
        break;
      case CalledFrom.CUSTOM_TABS_PORTFOLIO:
      case CalledFrom.CUSTOM_TABS_PROJECT:
        setOptionSelected && setOptionSelected(value);
        break;
      case CalledFrom.EARLY_WARNINGS:
        dispatch(
          setFilterEarlyWarnings({
            ...filterEarlyWarnings,
            common: {
              ...filterEarlyWarnings.common,
              participantDivisions: divisionsSelected,
            },
          }),
        );
        break;
    }
  };

  const getDefaultValue = () => {
    const participatingDivisionOptionsAux = getDivisionFilterOptions(divisionsFromRedux);

    switch (calledFrom) {
      case CalledFrom.PORTFOLIO:
        return filterIndicators.common.participantDivisions;
      case CalledFrom.PROJECT:
        return filterProjects.common.participantDivisions;
      case CalledFrom.CUSTOM_TABS_PORTFOLIO:
      case CalledFrom.CUSTOM_TABS_PROJECT:
        return isEdit
          ? getCustomTabDefaultValueFromProps(selectedTab, 'participantDivisions', participatingDivisionOptionsAux)
          : null;
      case CalledFrom.EARLY_WARNINGS:
        return filterEarlyWarnings.common.participantDivisions.filter(division => division.selectedByUser);
      default:
        return null;
    }
  };

  useEffect(() => {
    if (isEdit) {
      const selectedOption = getDefaultValue();
      selectedOption.length && setOptionSelected && setOptionSelected(selectedOption);
      selectedOption.length && setParticipantDivisionOnParent && setParticipantDivisionOnParent(selectedOption);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { setTagOptions, optionsBySelect } = useContext(TagFilterContext);
  const getValuesSelected = getDefaultValue();
  const selector = useSelector<any, any>(state => state.filterIndicators.common);


  useEffect(() => {
    setTagOptions("participants", getValuesSelected)
  }, [getValuesSelected, setTagOptions])

  return (
    <Select
      key={selector.participantDivisions}
      id="participatingDivisionSelect"
      components={{ DropdownIndicator }}
      defaultValue={getDefaultValue()}
      name="participatingDivision"
      options={participatingDivisionOptions}
      className="basic-multi-select"
      classNamePrefix="All"
      placeholder={t('all')}
      onChange={value => handleChange(value)}
      styles={multiSelectStyle}
      isMulti
    />
  );
};

export default ParticipatingDivisionFilter;
