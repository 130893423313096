import React from 'react';
import { useTranslation } from 'react-i18next';
import RegionCountryFilter from './RegionCountryFilter/RegionAndCountryFilters';
import CTFilters from './ExecutionAndTaxonomyFilters/ExecutionAndTaxonomyFilters';
import { parseFilterToApiCall } from '../../services/util';
import { ProjectProductTypes } from '../../models/ProjectProductTypes';
import useFilters from '../../hooks/useFilters';
import AttributesFilter from './AttributesFilter/AttributesFilter';
import { CalledFrom } from '../../models/CalledFromEnum';
import SectorsAndDivisionsFilter from './SectorsAndDivisionsFilter/SectorAndDivisionFilters';
import './Filter.scss';
import TeamLeaderFilter from './TeamLeaderFilter/TeamLeaderFilter';
import PmrFilter from './PmrFilter/PmrFilter';
// import { ClipEffect } from 'html2canvas/dist/types/render/effects';

type Props = {
  indicatorCall: boolean;
  calledFrom: CalledFrom;
};
const Filters = (props: Props) => {
  const { t } = useTranslation();


  const { indicatorCall, calledFrom } = props;
  const { filterIndicators, filter } = useFilters();
  const showFilters = (typeSelected: number): boolean => {

    const projectTypeFilters = indicatorCall
      ? parseFilterToApiCall(filterIndicators.common.projectTypeFilters).projectProductTypes
      : parseFilterToApiCall(filter.common.projectTypeFilters).projectProductTypes;

    return (
      projectTypeFilters.filter(i => i !== ProjectProductTypes.GUARANTEE).length === 1 && projectTypeFilters.includes(typeSelected)
    );
  };


  return (
    <div className="filter__component__filters">
      <RegionCountryFilter calledFrom={calledFrom} />
      <SectorsAndDivisionsFilter calledFrom={calledFrom} />
      {(showFilters(ProjectProductTypes.Loan) || calledFrom === CalledFrom.EARLY_WARNINGS) && (
        <div className="filter-wrapper">
          <h4 className="paragraph-x-small filter-wrapper__label">{t('pmr_initials')}</h4>
          <PmrFilter indicatorCall={indicatorCall} calledFrom={calledFrom} />
        </div>
      )}
      <TeamLeaderFilter calledFrom={calledFrom} isEdit />
      <div className="filter-wrapper">
        <h4 className="paragraph-x-small filter-wrapper__label">{t('attributes')}</h4>
        <AttributesFilter calledFrom={calledFrom} />
      </div>
      {calledFrom !== CalledFrom.EARLY_WARNINGS && showFilters(ProjectProductTypes.CT) && (
        <CTFilters indicatorCall={indicatorCall} />
      )}
    </div>
  );
};

export default Filters;
