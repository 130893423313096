import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectExecutionTypes } from '../../../../../models/ProjectExecutionTypes';
import { addSessionFilter } from '../../../../../services/sessionFiltersIndicatorDetailHelper';
import SummaryHeaderSingleValue from '../../../SummaryHeaderSingleValue/SummaryHeaderSingleValue';
import styles from '../../SharedStyles/HeaderDrops.module.scss';
import { SummaryTcpInternalFilters } from '../SummaryTcp';
import {
  handleIsSelectableButton,
  renderTextByInternalFilterApplied,
  renderValueByInternaFilterApplied,
} from './Utils';
const { dropsTitle, dropsValues, dropsSelectableButtonsContainer } = styles;

type Props = {
  filterBy: SummaryTcpInternalFilters;
  handleModuleFilterState: any;
};

export const ExecutionModule = ({ filterBy, handleModuleFilterState }: Props) => {
  const { t } = useTranslation();
  const {
    ctsNatBidExecutionPercentage,
    ctsRegBidExecutionPercentage,
    setExecutedByBankSelected,
    executedByBankSelected,
    executionByAgencyExecutor,
    setExecutionByAgencyExecutor,
    ctsNatAgencyExecutionPercentage,
    ctsRegAgencyExecutionPercentage,
  } = handleModuleFilterState;
  return (
    <div className={dropsSelectableButtonsContainer}>
      <div className={dropsTitle}>{t('execution')}</div>
      <div className={`${dropsValues} filters-button`}>
        <SummaryHeaderSingleValue
          value={renderValueByInternaFilterApplied(
            filterBy,
            Math.round(ctsNatBidExecutionPercentage.value),
            Math.round(ctsRegBidExecutionPercentage.value),
          )}
          title={renderTextByInternalFilterApplied(
            filterBy,
            ctsNatBidExecutionPercentage.text,
            ctsRegBidExecutionPercentage.text,
            t,
          )}
          isPercentage={true}
          customStyle={{ minWidth: 100, paddingRight: 5 }}
          isButton={handleIsSelectableButton(
            filterBy,
            Math.round(ctsNatBidExecutionPercentage.value),
            Math.round(ctsRegBidExecutionPercentage.value),
          )}
          isButtonSelected={executedByBankSelected}
          callbackLink={() =>
            handleExecutionClick(ProjectExecutionTypes.BID, {
              setExecutedByBankSelected,
              executedByBankSelected,
              executionByAgencyExecutor,
              setExecutionByAgencyExecutor,
            })
          }
        />
        <SummaryHeaderSingleValue
          value={renderValueByInternaFilterApplied(
            filterBy,
            Math.round(ctsNatAgencyExecutionPercentage.value),
            Math.round(ctsRegAgencyExecutionPercentage.value),
          )}
          title={renderTextByInternalFilterApplied(
            filterBy,
            ctsNatAgencyExecutionPercentage.text,
            ctsRegAgencyExecutionPercentage.text,
            t,
          )}
          isPercentage={true}
          customStyle={{ minWidth: 100, paddingRight: 5 }}
          isButton={handleIsSelectableButton(
            filterBy,
            Math.round(ctsNatAgencyExecutionPercentage.value),
            Math.round(ctsRegAgencyExecutionPercentage.value),
          )}
          isButtonSelected={executionByAgencyExecutor}
          callbackLink={() =>
            handleExecutionClick(ProjectExecutionTypes.EXECUTING_AGENCY, {
              setExecutedByBankSelected,
              executedByBankSelected,
              executionByAgencyExecutor,
              setExecutionByAgencyExecutor,
            })
          }
        />
      </div>
    </div>
  );
};
const handleExecutionClick = (
  typePressed: ProjectExecutionTypes,
  { setExecutedByBankSelected, executedByBankSelected, executionByAgencyExecutor, setExecutionByAgencyExecutor },
) => {
  if (typePressed === ProjectExecutionTypes.BID) {
    setExecutedByBankSelected(!executedByBankSelected);
    executionByAgencyExecutor && setExecutionByAgencyExecutor(!executionByAgencyExecutor);
  } else if (typePressed === ProjectExecutionTypes.EXECUTING_AGENCY) {
    setExecutionByAgencyExecutor(!executionByAgencyExecutor);
    executedByBankSelected && setExecutedByBankSelected(!executedByBankSelected);
  }
  addSessionFilter({ ProjectExecutorEntity: typePressed });
};
