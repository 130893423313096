import { FilterIndicatorsActionTypes, SET_CHECKED_ITEMS, SET_FILTER_INDICATORS } from '../actions/types';
import FilterIndicators from '../../models/FilterIndicators';
import { startedFilter } from './filterObject';
import { menuTabValuesEnum } from '../../models/MenuTabValuesEnum';
import { getLocalStorageOptionIncludeRegionalProjects } from '../../api/indicatorApi';
import { ProjectProductTypes } from '../../models/ProjectProductTypes';

const getProjectTypeFilters = () => {
  const projectTypeFilters: number[] = [];
  const storedProjectTypeFilters =
    localStorage.projectTypeFiltersPortfolio && JSON.parse(localStorage.projectTypeFiltersPortfolio);

  storedProjectTypeFilters &&
    storedProjectTypeFilters.forEach(item => {
      projectTypeFilters.push(item);
    });

  return projectTypeFilters.length > 0 ? projectTypeFilters : [ProjectProductTypes.Loan];
};

const commonFilter = JSON.parse(JSON.stringify(startedFilter));

const getCheckboxStateFromSession = () => {
  const checkedItems = sessionStorage.getItem('checkedItems');
  return checkedItems ? JSON.parse(checkedItems) : {};
};

const initialState: FilterIndicators = {
  common: { ...commonFilter, projectTypeFilters: getProjectTypeFilters() },
  checkedItems: getCheckboxStateFromSession(), // checkboxes
  indicatorsFilter: {
    menu: [{ id: menuTabValuesEnum.global_bid, label: 'BID_GLOBAL' }],
    menuValue: menuTabValuesEnum.global_bid,
  },
  optionSelectedApplied: getLocalStorageOptionIncludeRegionalProjects(),
  detailFilters: {
    searchTerm: '',
    stateFilter: 'all',
    sourceFilter: 'all',
  },
};

const filterIndicatorsReducer = (state = initialState, action: FilterIndicatorsActionTypes): FilterIndicators => {
  switch (action.type) {
    case SET_FILTER_INDICATORS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
export default filterIndicatorsReducer;
