import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Accordance from '../../../../models/Accordance';
import { AccordanceState } from '../../../../models/AccordanceState';
import Indicator from '../../../../models/Indicator';
import { IndicatorKeyEnum } from '../../../../models/IndicatorKeyEnum';
import { sortNested } from '../../../../services/sortsUtil';
import ApproveAccordancesModal from '../../../ProjectDetail/ProjectTabs/Accordances/ApproveAccordancesModal/ApproveAccordancesModal';
import { getAccordancesFromApi } from '../../PortfolioAccordanceDetailContent/PortfolioAccordanceDetailContent';

type Props = {
  currentIndicator: Indicator;
};

const ApproveAccordances = ({ currentIndicator }: Props) => {
  const indicatorAccordances = useSelector<any, Accordance[]>(state => state.indicatorAccordances);
  const [data, setData] = useState<Accordance[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setData(
      indicatorAccordances
        .filter(acc => acc.currentUserCanApprove && acc.accordanceState === AccordanceState.PENDING)
        .sort(sortNested('code', 'asc', 'project')),
    );
  }, [indicatorAccordances]);

  return currentIndicator.indicatorKey === IndicatorKeyEnum.ACCORDANCES_WAITING_APPROVAL ? (
    <ApproveAccordancesModal
      data={data}
      onSubmit={() => {
        getAccordancesFromApi(dispatch, currentIndicator.indicatorKey);
      }}
      multiProject={true}
    />
  ) : null;
};

export default ApproveAccordances;
