import React from 'react';
import styles from './ProjectsStatusInfo.module.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  projects: any[];
};

const ProjectsStatusInfo = ({ projects }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.layoutOuter}>
      <div className={styles.layout}>
        <span className={styles.bulletConfirmed}></span>
        <span className={styles.status}>{t('confirmed_01')}</span>
        <span className={styles.projectCount}>{`${projects.filter(p => p.status === 1).length} ${t('projects')}`}</span>
      </div>
      <div className={styles.layout}>
        <span className={styles.bulletAttempt}></span>
        <span className={styles.status}>{t('attempt_01')}</span>
        <span className={styles.projectCount}>{`${projects.filter(p => p.status === 0).length} ${t('projects')}`}</span>
      </div>
    </div>
  );
};

export default ProjectsStatusInfo;
