import React from 'react';
import { useTranslation } from 'react-i18next';
import { HorizontalBar } from 'react-chartjs-2';
import styles from './GcmChart.module.scss';

const options = (maxValue, disableAnimation) => {
  return {
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
    scales: {
      xAxes: [
        {
          display: true,
          stacked: false,
          ticks: {
            beginAtZero: true,
            min: 0,
            max: maxValue,
            stepSize: 20,
            fontSize: 14,
            fontFamily: 'Rubik',
            fontColor: '#424D64',
          },
          gridLines: {
            display: true,
          },
        },
      ],

      yAxes: [
        {
          display: true,
          stacked: true,
          ticks: {
            beginAtZero: true,
            fontSize: 14,
            fontFamily: 'Rubik',
            fontColor: '#424D64',
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
    layout: {
      padding: {
        left: 0,
        right: 15,
        top: 0,
        bottom: 0,
      },
    },
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: true,
        align: 'right',
        anchor: 'end',
        color: '#424D64',
        font: { size: 14, family: 'Rubik' },
      },
    },
    animation: {
      duration: disableAnimation ? 0 : 1000,
    },
  };
};

const chartHeight = (data: any) => {
  return data && data.labels ? data.labels.length * 70 : 100;
};

const setMaxValueWithOffset = data => {
  if (data && data.datasets && data.datasets.length) {
    const max = Math.max(...data.datasets[0].data);
    return Math.ceil(max / 10) * 10 + 10;
  }
  return 100;
};

type Props = {
  data: any;
  title: string;
  handleClick: Function;
  disableAnimation?: boolean;
  customWidth?: number;
};

const GcmChart = ({ data, title, handleClick, disableAnimation, customWidth }: Props) => {
  const { container, header, headerTitle } = styles;
  const { t } = useTranslation();
  return (
    <div className={container} style={{ height: chartHeight(data) + 100, width: customWidth || 560 }}>
      <div className={header}>
        <h4 className={headerTitle}>{t(title)}</h4>
      </div>
      <div style={{ height: chartHeight(data) }}>
        <HorizontalBar
          data={data}
          options={options(setMaxValueWithOffset(data), disableAnimation)}
          getElementAtEvent={element => handleClick(element)}
        />
      </div>
    </div>
  );
};

export default GcmChart;
