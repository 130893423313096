import { cloneDeep } from 'lodash';
import moment from 'moment';

const workingHours = 8;

export const setTimeForNewProjects = currentReview => {
  const basicTimes = basicHours(currentReview);
  const newProjects = currentReview.projects.filter(
    p => p.projectRevisionStartDateTime === null || p.projectRevisionEndDateTime === null,
  );
  const projectsWithTime = currentReview.projects.filter(
    p => p.projectRevisionStartDateTime !== null && p.projectRevisionEndDateTime !== null,
  );

  return updatedList(basicTimes, projectsWithTime, newProjects);
};

const updatedList = (basicTimes, projectsWithTime, newProjects) => {
  const newHours = assignHourToProject(basicTimes, projectsWithTime);
  if (
    !newProjects.find(p => p.projectRevisionStartDateTime === null) ||
    (newHours.start === null && newHours.end === null)
  ) {
    return newProjects;
  }

  const newProject = {
    ...cloneDeep(newProjects.find(p => p.projectRevisionStartDateTime === null)),
    projectRevisionStartDateTime: newHours.start?.toISOString(),
    projectRevisionEndDateTime: newHours.end?.toISOString(),
  };
  const np = newProjects.map(p => {
    if (p.id === newProject.id) {
      return newProject;
    } else {
      return p;
    }
  });
  return updatedList(basicTimes, [...projectsWithTime, newProject], np);
};

const assignHourToProject = (basicTimes, projectsWithTime) => {
  for (let i = basicTimes.length - 1; i >= 0; i--) {
    const hours = basicTimes[i].hours;
    for (let j = hours.length - 1; j >= 0; j--) {
      const currentDate = basicTimes[i].date;
      const start = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        hours[j].h,
        hours[j].m,
        0,
        0,
      );
      const end = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        hours[j].h + 1,
        hours[j].m,
        0,
        0,
      );
      if (
        availableTimeSlot(
          projectsWithTime.map(p => {
            p.start = new Date(moment.utc(p.projectRevisionStartDateTime).local().toString());
            p.end = new Date(moment.utc(p.projectRevisionEndDateTime).local().toString());
            return p;
          }),
          start,
          end,
          -1,
        )
      ) {
        return {
          start,
          end,
        };
      }
    }
  }
  return { start: null, end: null };
};

export const basicHours = currentReview => {
  const result: any[] = [];
  const start = new Date(currentReview.startDate);
  const end = new Date(currentReview.endDate);

  for (let i = start; i <= end; i = addDays(i, 1)) {
    if (isWorkDay(i)) {
      result.push({
        date: i,
        hours: [
          { h: 9, m: 0 },
          { h: 9, m: 15 },
          { h: 9, m: 30 },
          { h: 9, m: 45 },
          { h: 10, m: 0 },
          { h: 10, m: 15 },
          { h: 10, m: 30 },
          { h: 10, m: 45 },
          { h: 11, m: 0 },
          { h: 11, m: 15 },
          { h: 11, m: 30 },
          { h: 11, m: 45 },
          { h: 12, m: 0 },
          { h: 14, m: 0 },
          { h: 14, m: 15 },
          { h: 14, m: 30 },
          { h: 14, m: 45 },
          { h: 15, m: 0 },
          { h: 15, m: 15 },
          { h: 15, m: 30 },
          { h: 15, m: 45 },
          { h: 16, m: 0 },
          { h: 16, m: 15 },
          { h: 16, m: 30 },
          { h: 16, m: 45 },
          { h: 17, m: 0 },
        ],
      });
    }
  }
  return result;
};

const addDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

const isWorkDay = date => {
  return date.getDay() !== 0 && date.getDay() !== 6;
};

const isSameDay = (revisionDate, projectStartDate) => {
  return (
    revisionDate.getFullYear() === projectStartDate.getFullYear() &&
    revisionDate.getMonth() === projectStartDate.getMonth() &&
    revisionDate.getDate() === projectStartDate.getDate()
  );
};

export const getTotalProjectsDuration = (projects, duration = 60) => {
  let result = 0;
  for (const p of projects) {
    if (p.projectRevisionStartDateTime === null || p.projectRevisionEndDateTime === null) {
      result += duration;
    } else {
      const a = moment(p.projectRevisionStartDateTime);
      const b = moment(p.projectRevisionEndDateTime);
      const diff = Math.floor(b.diff(a) / 1000 / 60);
      result += diff;
    }
  }
  return result;
};

export const getReviewDurationInMinutes = currentReview => {
  return basicHours(currentReview).length * workingHours * 60;
};

export const exceededProjectCapacity = (currentReview, duration = 60) => {
  return getReviewDurationInMinutes(currentReview) < getTotalProjectsDuration(currentReview.projects, duration);
};

const parseHoursString = date => {
  return date.getHours() < 10 ? `0${date.getHours().toString()}` : date.getHours().toString();
};

export const availableTimeSlot = (list, start, end, id) => {
  const startComplete = `${parseHoursString(start)}${start.getMinutes() === 0 ? '00' : start.getMinutes().toString()}`;
  const endComplete = `${parseHoursString(end)}${end.getMinutes() === 0 ? '00' : end.getMinutes().toString()}`;

  for (const p of list) {
    const projectStart = `${parseHoursString(p.start)}${
      p.start.getMinutes() === 0 ? '00' : p.start.getMinutes().toString()
    }`;
    const projectEnd = `${parseHoursString(p.end)}${p.end.getMinutes() === 0 ? '00' : p.end.getMinutes().toString()}`;
    if (
      id !== p.id &&
      isSameDay(start, new Date(p.start)) &&
      (startComplete === projectStart ||
        endComplete === projectEnd ||
        (startComplete > projectStart && startComplete < projectEnd) ||
        (endComplete > projectStart && endComplete < projectEnd))
    ) {
      return false;
    }
  }
  return true;
};

export const getDaysToAdd = (currentReview, timeAllocated) => {
  const diff =
    getTotalProjectsDuration(currentReview.projects, timeAllocated) - getReviewDurationInMinutes(currentReview);
  return Math.ceil(diff / 60 / 8);
};

export const getNumberOfProjectsToRemove = (currentReview, timeAllocated) => {
  const diff =
    getTotalProjectsDuration(currentReview.projects, timeAllocated) - getReviewDurationInMinutes(currentReview);
  return Math.ceil(diff / timeAllocated);
};

export const validDuration = (start, end) => {
  const a = moment(start);
  const b = moment(end);
  const duration = b.diff(a, 'minutes');
  return duration >= 45 && duration <= 90;
};

export const updateProjectsTimes = (currentReviewProjects, newList) => {
  return currentReviewProjects.map(item => {
    const newListP = newList.find(i => i.id === item.id);
    if (newListP) {
      item.projectRevisionStartDateTime = newListP.NewStartDate;
      item.projectRevisionEndDateTime = newListP.NewEndDate;
    }
    return item;
  });
};

export const centerAgenda = (currentReview, innerWidth) => {
  const days = basicHours(currentReview).length;
  const projectWidth = 120;
  const offset = 415;
  const hoursWidth = 60;
  return innerWidth - (days * projectWidth + hoursWidth) > offset;
};
