import React from 'react';
import { useSelector } from 'react-redux';
import styles from './DivisionsTableHeader.module.scss';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';
import { ProjectProductTypeFilters } from '../../../../models/ProjectProductTypeFilters';

type Props = {
  division: string;
};

const DivisionsTableHeader = ({ division }: Props) => {
  const { t } = useTranslation();

  const currentIndicator = useSelector<any, any>(state => state.currentIndicator);
  const { projectTypeFilters } = useSelector<any, any>(state => state.filterIndicators.common);
  const headerTableData = currentIndicator.headerTableData;
  const divisionCode = division === t('others_divisions') ? 'OTHERS' : division?.trim();
  const currentHeaderData = headerTableData.find(data => data.division === divisionCode);

  return (
    <div className={styles.headerContainer}>
      {currentHeaderData && currentHeaderData.data
        ? currentHeaderData.data.map(item => {
            if (hasFilterApplied(item, projectTypeFilters)) {
              return (
                <div className={styles.itemContainer} key={item.projectType}>
                  <div className={styles.quantityContainer}>
                    <div className={styles.quantity}>{item.projectsAmount}</div>
                    <div className={styles.type}>{item.projectType}</div>
                  </div>
                  <div className={styles.amount}>
                    <NumberFormat
                      value={item.approvedAmount}
                      displayType={'text'}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      decimalScale={1}
                      suffix={' MM'}
                    />
                  </div>
                  <div className={styles.subtitle}>{t('total_approved_amount')}</div>
                  <div className={styles[`colorBar${item.projectType}`]}></div>
                </div>
              );
            } else {
              return null;
            }
          })
        : null}
    </div>
  );
};

export default DivisionsTableHeader;

const hasFilterApplied = (item: any, projectTypeFilters: ProjectProductTypeFilters[]) => {
  if (item.projectType === 'LON') {
    return projectTypeFilters.includes(ProjectProductTypeFilters.Loan);
  } else if (item.projectType === 'TCP') {
    return projectTypeFilters.includes(ProjectProductTypeFilters.CT);
  } else if (item.projectType === 'IGR') {
    return (
      projectTypeFilters.includes(ProjectProductTypeFilters.IG_greater_3) ||
      projectTypeFilters.includes(ProjectProductTypeFilters.IG_lower_3)
    );
  }
  return false;
};
