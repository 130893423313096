import React from 'react';
import { groupBy } from 'lodash';
import { EWHistory } from '../../../models/EarlyWariningHistory';
import styles from './EarlyWarningHistory.module.scss';
import { AlertHistory } from './AlertHistory/AlertHistory';
import { useTranslation } from 'react-i18next';
import { hasLargeTexts } from './Util';
import InfoEarlyWarningsHistory from './InfoEarlyWarningsHistory/InfoEarlyWarningsHistory';
import { useSelector } from 'react-redux';
import { isMobile } from '../../../services/booleanUtil';

const {
  alertsHistoryContainer,
  earlyWarningHistoryContainer,
  title,
  emptyStateText,
  typeHeaderContainer,
  typeHeaderTitle,
} = styles;

type Props = {
  typeHeader?: boolean;
};
export const EarlyWarningHistory = ({ typeHeader }: Props) => {
  const { t } = useTranslation();
  const operativeAlerts = useSelector<any, EWHistory[] | []>(state => state.currentProject.operativeAlerts);
  const groupedOperativeAlerts = groupBy(operativeAlerts, 'operativeAlert');

  return typeHeader ? (
    <div className={typeHeaderContainer}>
      <p className={typeHeaderTitle}>{t('early_warnings')}</p>

      {operativeAlerts.length && groupedOperativeAlerts.length && (
        <AlertHistory
          alertData={groupedOperativeAlerts[0]}
          hasLargeTexts={hasLargeTexts(groupedOperativeAlerts[0])}
          typeHeader={true}
        />
      )}
    </div>
  ) : (
    <div className={earlyWarningHistoryContainer}>
      <p className={title}>{t('early_warnings')}</p>

      {operativeAlerts.length ? (
        <div className={alertsHistoryContainer}>
          {Object.keys(groupedOperativeAlerts).map(alert => {
            return (
              <AlertHistory
                alertData={groupedOperativeAlerts[alert]}
                hasLargeTexts={hasLargeTexts(groupedOperativeAlerts)}
              />
            );
          })}
          {!isMobile() && <InfoEarlyWarningsHistory />}
        </div>
      ) : (
        <p className={emptyStateText}>{t('detail_switch_subtitle_no_data')}</p>
      )}
    </div>
  );
};
