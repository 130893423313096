import React from 'react';
import classNames from 'classnames';

import './AddIndicatorCard.scss';
import { ReactComponent as IndicatorSelected } from '../../../../../assets/indicador_selected.svg';
import { ReactComponent as IndicatorUnselected } from '../../../../../assets/indicador_unselected.svg';
import { ReactComponent as LogoCartera } from '../../../../../assets/logo-cartera.svg';

import { urlIsImage } from '../../../../../services/booleanUtil';
import { useTranslation } from 'react-i18next';
import { EarlyWarningsIcon } from '../../../../Portfolio/CardIndicator/EarlyWarningsIcon/EarlyWarningsIcon';

type Props = {
  card: any;
  setSelected: Function;
  isSelected: boolean;
  showDescription: boolean;
  containerStyle?: any;
};

const AddIndicatorCard = (props: Props) => {
  const { card, setSelected, isSelected, showDescription, containerStyle = {} } = props;
  const year = new Date().getFullYear();
  const { t } = useTranslation();
  return (
    <div
      className={classNames('add-card-container', { selected: isSelected })}
      onClick={() => setSelected(card.id)}
      style={containerStyle}
    >
      {indicatorIsNew(card.isNew, t)}
      <div className='icon-warning'>
      <EarlyWarningsIcon indicatorKey={card.code} />
      </div>
      <div className="img">
        {card.image && urlIsImage(card.image) ? (
          <img className="img-container" alt="" src={card.image} />
        ) : (
          <LogoCartera />
        )}
      </div>
      <div className="texts-container">
        <div className="card-title-container">
          {card.subtitle && <div className="subtitle">{card.subtitle}</div>}
          <div className="title" title={card.title}>{`${card.title} ${card.year ? year : ''}`}</div>
        </div>
        {showDescription && (
          <div className="desc" title={card.description}>
            <p>{card.description}</p>
          </div>
        )}
      </div>
      <div className="button">{isSelected ? <IndicatorSelected /> : <IndicatorUnselected />}</div>
     
    </div>
  );
};
export default AddIndicatorCard;

const indicatorIsNew = (isNew, t) => {
  return (
    isNew && (
      <div className="badge">
        <span>{t('new')}</span>
      </div>
    )
  );
};
