import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectSwitch } from '../../ProjectSwitch/ProjectSwitch';
import { useDispatch, useSelector } from 'react-redux';
import Project from '../../../../models/Project';
import { PerformancePMRCollapsed } from '../../PerformancePMRCollapsed/PerformancePMRCollapsed';
import DisbursementProjectionEvolution from '../../DisbursementProjectionEvolution/DisbursementProjectionEvolution';
import { EarlyWarningHistory } from '../../EarlyWarningHistory/EarlyWarningHistory';
import { ProjectProductTypes } from '../../../../models/ProjectProductTypes';
import { ProjectProductTypeClassification } from '../../../../models/ProjectProductTypeClassification';
import { getProject } from '../../../../redux/actions/currentProject';

const Evolution = () => {
  const { t } = useTranslation();
  const currentProject = useSelector<any, Project>(state => state.currentProject);
  const dispatch = useDispatch();

  const {
    projectProductType,
    execution,
    projectProductTypeClassification,
    operativeAlerts,
    disbursementsProjectionsEvolution = [],
    pmrEvolution = [],
  } = currentProject;

  useEffect(() => {
    const projectId = parseInt(currentProject.id.toString());
    dispatch(getProject(projectId));
  }, [])

  const ifProjectTypeForRenderEvolutionDetail = () => {
    return (
      projectProductType === ProjectProductTypes.Loan ||
      (projectProductTypeClassification &&
        projectProductTypeClassification['code'] === ProjectProductTypeClassification.IGR_GREATER_THAN_3MM)
    );
  };

  return (
    <>
      <div className="project-detail-conatiner">
        {ifProjectTypeForRenderEvolutionDetail() && (
          <ProjectSwitch
            switchType="evolutionSwitchDetail"
            collapsedHeaderComponent={evolutionHeaderComponentByData(
              pmrEvolution,
              disbursementsProjectionsEvolution,
              operativeAlerts,
            )}
            disbursementsProjectionsEvolution={disbursementsProjectionsEvolution}
            hasDataHeader={pmrEvolution.length !== 0}
            operativeAlerts={operativeAlerts}
          />

        )}

      </div>


    </>

  );
};

const evolutionHeaderComponentByData = (pmrEvolution, disbursementsProjectionsEvolution, operativeAlerts) => {
  if (pmrEvolution.length) {
    return <PerformancePMRCollapsed data={pmrEvolution} />;
  } else if (disbursementsProjectionsEvolution.length) {
    return <DisbursementProjectionEvolution data={disbursementsProjectionsEvolution} typeHeader={true} />;
  } else if (operativeAlerts && operativeAlerts.length) {
    return <EarlyWarningHistory typeHeader={true} />;
  }
  return null;
};


export default Evolution;
