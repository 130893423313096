import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { trackActionClicked } from '../api/trackActionClickedApi';
import { TrackedActions } from '../models/TrackedActionsEnum';

export const useTable = () => {
  const indicatorProjects = useSelector<any, any>(state => state.indicatorProjects);
  const [projects, setProjects] = useState<any[]>([]);

  const history = useHistory();

  useEffect(() => {
    setProjects([...indicatorProjects]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indicatorProjects]);

  const handleClick = project => {
    history.push(`/projectdetail?id=${project.id}`);
    trackActionClicked(TrackedActions.projectDetailsFromListClicked);
  };

  return { handleClick, projects };
};

export default useTable;
