import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { multiSelectStyle, DropdownIndicator } from '../FilterComponent.Styles';
import { CalledFrom } from '../../../models/CalledFromEnum';
import { trackActionClicked } from '../../../api/trackActionClickedApi';
import { TrackedActions } from '../../../models/TrackedActionsEnum';
import {
  getDivisionOptions,
  getDivisionFilterOptions,
  getPortfolioOrProjectSelectedTab,
  getDivisionsDefaultOptions,
  getDivisionsSelected,
} from '../UtilFunctions';
import { useDivisions } from '../../../hooks/useDivisions';
import useFilters from '../../../hooks/useFilters';
import SelectOption from '../../../models/SelectOption';
import { AppMenuTabs } from '../../MenuTabs/userTabsInterface';


interface IProps {
  calledFrom: CalledFrom;
  setOptionSelected?: Function;
  isEdit?: boolean;
  divisionOptionsFromParent?: SelectOption[];
  setSelectedDivisionOnParent?: Function;
}

type Props = IProps;

const getSelectOptions = (
  divisionOptionsFromParent: any = null,
  allDivisionOptions: SelectOption[],
  menuTabs: AppMenuTabs,
  calledFrom: CalledFrom,
) => {
  return divisionOptionsFromParent
    ? getDivisionOptions(divisionOptionsFromParent, menuTabs, calledFrom)
    : getDivisionOptions(allDivisionOptions, menuTabs, calledFrom);
};

const DivisionFilter = (props: Props) => {
  const { calledFrom, setOptionSelected, isEdit, divisionOptionsFromParent, setSelectedDivisionOnParent } = props;
  const {
    filter,
    filterIndicators,
    filterAnalytics,
    dispatch,
    setFilter,
    setFilterIndicators,
    setFilterAnalytics,
    filterEarlyWarnings,
    setFilterEarlyWarnings,
  } = useFilters();

  const { t } = useTranslation();

  const allDivisionOptions = useDivisions();
  const sectorsFromRedux = useSelector<any, any>(state => state.sectors);
  const divisionsFromRedux = useSelector<any, any>(state => state.divisions);
  const menuTabs = useSelector<any, any>(state => state.menuTabs);
  const selectedTab = getPortfolioOrProjectSelectedTab(calledFrom, menuTabs);
  const divisionOptions = getSelectOptions(divisionOptionsFromParent, allDivisionOptions, menuTabs, calledFrom);
  const [UserDivisionsSelected, setUpdatedUserDivisions] = useState<any[]>([]);

  const userSectorSelected = filterIndicators.common.sectors;



  const getDefaultValue = () => {
    const divisionOptionsAux = getDivisionFilterOptions(divisionsFromRedux);

    switch (calledFrom) {
      case CalledFrom.PORTFOLIO:
        return filterIndicators.common.divisionId.filter(division => division.selectedByUser);
      case CalledFrom.PROJECT:
        return filter.common.divisionId.filter(division => division.selectedByUser);
      case CalledFrom.ANALYTICS:
        return filterAnalytics.divisionId.filter(division => division.selectedByUser);
      case CalledFrom.CUSTOM_TABS_PORTFOLIO:
      case CalledFrom.CUSTOM_TABS_PROJECT:
        return isEdit ? getDivisionsDefaultOptions(divisionOptionsAux, selectedTab, sectorsFromRedux) : null;
      case CalledFrom.EARLY_WARNINGS:
        return filterEarlyWarnings.common.divisionId.filter(division => division.selectedByUser);

      default:
        return null;
    }
  };



  const handleChange = value => {
    if (value === null) {
      value = [];
    }

    let divisionRemoved: any[] = UserDivisionsSelected.filter(obj => !value.some(d => d.id === obj.id));
    setUpdatedUserDivisions([]);
    setUpdatedUserDivisions(value);



    setSelectedDivisionOnParent && setSelectedDivisionOnParent(value);

    const divisionsSelected = getDivisionsSelected(
      value,
      calledFrom,
      filterIndicators,
      filter,
      filterEarlyWarnings,
      sectorsFromRedux,
    );



    switch (props.calledFrom) {
      case CalledFrom.PORTFOLIO:
        dispatch(
          setFilterIndicators({
            ...filterIndicators,
            common: {
              ...filterIndicators.common,
              divisionId: divisionRemoved.length !== 0 && value.length !== 0 ? includeUpdatedDivisions(userSectorSelected, divisionRemoved, divisionsSelected) : divisionsSelected,
            },
          }),
        );
        trackActionClicked(TrackedActions.divisionFilterChangedFromPortfolio);
        break;
      case CalledFrom.PROJECT:
        dispatch(
          setFilter({
            ...filter,
            common: {
              ...filter.common,
              divisionId: divisionsSelected,
              itSearch: true,
            },
          }),
        );
        trackActionClicked(TrackedActions.divisionFilterChangedFromProject);
        break;
      case CalledFrom.ANALYTICS:
        dispatch(
          setFilterAnalytics({
            ...filterAnalytics,
            divisionId: divisionsSelected,
          }),
        );

        break;

      case CalledFrom.CUSTOM_TABS_PORTFOLIO:
      case CalledFrom.CUSTOM_TABS_PROJECT:
        setOptionSelected && setOptionSelected(value);
        break;
      case CalledFrom.EARLY_WARNINGS:
        dispatch(
          setFilterEarlyWarnings({
            ...filterEarlyWarnings,
            common: {
              ...filterEarlyWarnings.common,
              divisionId: divisionsSelected,
            },
          }),
        );
        trackActionClicked(TrackedActions.divisionFilterChangedFromEarlyWarnings);
        break;
    }
  };

  useEffect(() => {
    if (isEdit) {
      const selectedOption = getDefaultValue();
      selectedOption.length && setOptionSelected && setOptionSelected(selectedOption);
      selectedOption.length && setSelectedDivisionOnParent && setSelectedDivisionOnParent(selectedOption);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const includeUpdatedDivisions = (sector: SelectOption[], division: any, divisionsSelected: any) => {

    let returnedDivisions: any = [];
    const sectorDeleted = sectorsFromRedux.find(sec => sec.divisions.some(d => d.id === division[0].id));
    const sectorIsSelectedForUser: boolean = sector.some(sec => sec.id === sectorDeleted.id)

    if (sectorIsSelectedForUser) {

      if (divisionsSelected && divisionsSelected.length > 0) {
        returnedDivisions = [...returnedDivisions, ...divisionsSelected]
      }
      returnedDivisions = [...returnedDivisions, ...sectorDeleted.divisions];
    }
    return returnedDivisions;

  }

  return (
    <Select
      id="divisionsSelect"
      defaultValue={getDefaultValue()}
      isMulti
      components={{ DropdownIndicator }}
      name="divisions"
      options={divisionOptions}
      className="basic-multi-select"
      classNamePrefix="All"
      placeholder={t('all')}
      onChange={value => handleChange(value)}
      styles={multiSelectStyle}
    />
  );
};

export default DivisionFilter;
