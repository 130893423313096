import { Table, TableBody, TableContainer, TableRow, Tooltip } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { replaceMomentFormat } from '../../../services/util';
import { tooltipTitle } from '../../../services/stringUtil';
import { sortAlphabetically as sortAlphabeticallyGeneral, sortTablesByType } from '../../../services/sortsUtil';
import { BidTooltip } from '../../Util/Tooltip/Tooltip';

import {
  StyledTableCell,
  StyledTableCellHeader,
  StyledTableHeader,
  StyledTableRowHeader,
} from '../PorfolioTableShared.Styles';
import { ProjectCounter } from '../ProjectCounter/ProjectCounter';
import './KnowledgeProductTable.scss';
import { ExcelDownloadButton } from '../../Util/ExcelDownloadButton/ExcelDownloadButton';
import DataTable from '../../DetailDataTable/DataTable';
import useDataNormalize from '../../../hooks/useDataNormalize';
import { Pagination } from '@material-ui/lab';
import PaginationTable from '../PaginationTable/PaginationTable';
import usePaginationSort from '../../../hooks/usePaginationSort';
import { withStyles } from '@material-ui/core/styles';
import { ReactComponent as IconSort } from '../../../assets/icons/u_sort.svg';
import { FilterTables } from '../../Filter/FilterTables/FilterTables';
const BlackTooltip = withStyles({
  tooltip: {
    //   color: '$ci-color-greyscale-90',
    backgroundColor: '#101319',
    fontSize: '16px',
    fontFamily: 'Rubik',
    fontWeight: '400',
    lineHeight: '20px',
    padding: '8px 12px',
    borderRadius: '8px',
  },
  arrow: {
    fontSize: 20,
    color: '#4A4A4A',
    '&::before': {
      backgroundColor: '#101319',
    },
  },
})(Tooltip);

const headers = ['id', 'Project', 'Type of Product', 'Name', 'Responsible Specialist', 'Dept / Div', 'Release', ' '];

const selectedKeys = ['id', 'projectCode', 'productType', 'name', 'responsible', 'department', 'availableDate', 'url'];

type Props = {
  tableData: any;
  filterBtnData: boolean;
};
const customRowStyle = (index: number) => {
  return index % 2 ? { cursor: 'pointer', background: '#fafafa' } : { cursor: 'pointer', background: 'white' };
};
const KnowledgeProductTable = ({ tableData, filterBtnData }: Props) => {
  const dateFormat = 'MMM/YYYY';
  const { t } = useTranslation();
  const [isLoading, setisLoading] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dataFilter, setDataFilter] = useState<any>(tableData);

  useEffect(() => {
    setDataFilter(tableData);
  }, [tableData]);

  const { handlePaginationChange, handleSort, currentList, pagesCount, currentPage } = usePaginationSort({
    projects: dataFilter,
    defaultSortCriteria: 'title',
    pageCount: rowsPerPage,
  });

  const [sortAsc, setSortAsc] = React.useState(true);
  const sortTable = (data, keyToSort, fieldType) => {
    currentList.sort(sortAlphabeticallyGeneral(keyToSort, sortAsc ? 'asc' : 'desc'));
    setSortAsc(!sortAsc);
  };

  const handleClick = url => {
    window.open(url, '_blank');
  };

  const renderCellHeader = (translation, sortConcept) => {
    return (
      <StyledTableCellHeader align="left" onClick={() => sortTable(dataFilter, sortConcept, 'string')}>
        <div className="text-head">
          {t(translation)}
          <IconSort className="sortIcon" />
        </div>
      </StyledTableCellHeader>
    );
  };

  const data = useDataNormalize(headers, selectedKeys, tableData);
  const key = data.length === 0 ? 1 : data[0].id;

  const dataExcel = useDataNormalize(headers, selectedKeys, dataFilter);

  useEffect(() => {
    setisLoading(true);
    if (data.length > 0) {
      setTimeout(() => {
        setisLoading(false);
      }, 2000);
    }
  }, [data]);

  return (
    <div className="knowledge-product-table-container">
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <ExcelDownloadButton headers={headers} selectedKeys={selectedKeys} dataExcel={dataExcel} />
        <FilterTables
          projects={tableData}
          keysFilter={selectedKeys}
          fnDataFilter={setDataFilter}
          disabledNumber={currentPage}
          // dataFilterBtn={dataFilterBtn}
          indicatorKey={'PROJECTS_KNOWLEDGE_PRODUCTS'}
          filterBtn={filterBtnData}
        />
      </div>
      <div className="border-table">
        {/* <DataTable headers={headers} selectedKeys={selectedKeys} hasFirstColLink data={data} hasRowDetail key={data} /> */}

        <TableContainer>
          <Table stickyHeader className="custom-table" style={{ tableLayout: 'auto' }} size="small">
            <StyledTableHeader className="container">
              <StyledTableRowHeader>
                {renderCellHeader('project', 'projectCode')}
                {renderCellHeader('type_of_product', 'productType')}
                {renderCellHeader('name', 'name')}
                {renderCellHeader('responsible_specialist', 'responsible')}
                {renderCellHeader('dept_div', 'department')}
                {renderCellHeader('release', 'availableDate')}

                <StyledTableCellHeader align="right" />
              </StyledTableRowHeader>
            </StyledTableHeader>
            <TableBody>
              {currentList &&
                currentList.map((row, index) => (
                  <TableRow key={row.id}>
                    <StyledTableCell>
                      <BlackTooltip title={t('go_to_project_info')} arrow>
                        <p className={`link-project`} onClick={() => handleClick(row.url)}>
                          {' '}
                          {row.projectCode}
                        </p>
                      </BlackTooltip>
                    </StyledTableCell>

                    <StyledTableCell>{row.productType}</StyledTableCell>
                    <StyledTableCell>
                      <BlackTooltip title={row.name} placement="bottom" enterDelay={500} leaveDelay={100} arrow>
                        <p className="name-project-nowrap-tiny">{row.name}</p>
                      </BlackTooltip>
                    </StyledTableCell>
                    <StyledTableCell>{row.responsible}</StyledTableCell>
                    <StyledTableCell>{row.department}</StyledTableCell>
                    <StyledTableCell style={{ textTransform: 'uppercase' }}>
                      {replaceMomentFormat(moment(row.availableDate).format(dateFormat))}
                    </StyledTableCell>

                    <StyledTableCell style={{ width: '5%', borderRadius: '6px' }}>
                      <BidTooltip
                        title={tooltipTitle(t, 'product_detail')}
                        placement="bottom-start"
                        enterDelay={500}
                        leaveDelay={200}
                      >
                        <span onClick={() => handleClick(row.url)} className="fw" />
                      </BidTooltip>
                    </StyledTableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <ProjectCounter arrayToCount={tableData} countProjects={false} countOnlyProduct={true} />
      <div className="pagination-style">
        <PaginationTable fnSetRows={setRowsPerPage} rows={rowsPerPage} numProyects={dataFilter?.length} />
        <Pagination count={pagesCount} page={currentPage} onChange={handlePaginationChange} />
      </div>
    </div>
  );
};
export default KnowledgeProductTable;
