import { RESET_USER, SET_USER, UPDATE_USER } from './types';
import { getUserData, updateUserData } from '../../api/userApi';
import User from '../../models/User';
import { setRequestStatus } from '../actions/requestStatus';
import { RequestStatus } from '../../models/RequestStatus';
import i18n from '../../i18n/i18n';
import { checkLanguageUpadeLocale } from '../../services/util';
import { languagesList } from '../../models/LanguagesList';
import { getRegionsAndCountries, getSectors } from '../../api/filterApi';
import { addRegions } from './regions';
import { setMenuTabs, updateCreatingOrEditing } from './menuTabs';
import { AppMenuTabs, BEMenuTabsResponse } from '../../components/MenuTabs/userTabsInterface';
import { addSectors } from './sectors';
import { getCurrentDivisions } from './divisions';
import { getCurrentInterestIssues } from './interestIssues';

export function setUser(user: User) {
  return {
    type: SET_USER,
    payload: user,
  };
}

export function getUser(isCreateEditOrDelete = false, defaultTabId: number | null = null, userEdit = false) {
  return async (
    dispatch: (arg0: { type: string; payload: User | BEMenuTabsResponse | boolean }) => void,
    getState: any,
  ) => {
    try {
      const { userTabs } = getState().menuTabs as AppMenuTabs;
      const userData = await getUserData();
      const appUserMenuTabs: BEMenuTabsResponse = {
        userTabs: [...userData.userTabs],
      };

      isCreateEditOrDelete && dispatch(updateCreatingOrEditing(true));
      (!userTabs.length || isCreateEditOrDelete || userEdit) && dispatch(setMenuTabs(appUserMenuTabs, defaultTabId));
      const currentLanguage = i18n.language;
      if (currentLanguage !== userData.language.encodedName) {
        i18n.changeLanguage(userData.language.encodedName);
        localStorage.setItem('language', userData.language.encodedName);
      } else {
        localStorage.setItem('language', userData.language.encodedName);
        checkLanguageUpadeLocale();
      }

      dispatch(setUser({ ...userData }));
    } catch (error) {}
  };
}

export function updateLocalUser(data: any) {
  return {
    type: UPDATE_USER,
    payload: data,
  };
}

export function updateUser(data: any) {
  return async (dispatch: any, getState: any) => {
    dispatch(setRequestStatus(RequestStatus.Loading));
    try {
      await updateUserData(data);

      let userData: any = {};
      if (data.acceptMailDelivery !== undefined) {
        userData = { ...userData, acceptMailDelivery: data.acceptMailDelivery };
      }
      if (data.languageCode !== undefined) {
        const regionsAndCountries = await getRegionsAndCountries();
        dispatch(addRegions(regionsAndCountries));
        localStorage.setItem('language', data.languageCode);
        checkLanguageUpadeLocale();
        const languageItem = languagesList.find(item => item.value === data.languageCode);
        userData = { ...userData, language: { name: languageItem?.label, encodedName: languageItem?.value } };
      }
      if (data.syncCalendar !== undefined) {
        userData = { ...userData, syncCalendar: data.syncCalendar };
      }

      dispatch(updateLocalUser(userData));
      dispatch(getUser(false, null, true));
      getSectors().then(sectorsData => {
        dispatch(addSectors(sectorsData));
      });
      dispatch(getCurrentDivisions());
      dispatch(getCurrentInterestIssues());
      dispatch(setRequestStatus(RequestStatus.Success));
    } catch (error) {
      dispatch(setRequestStatus(RequestStatus.Error));
      throw error;
    }
  };
}

export function resetUser() {
  return {
    type: RESET_USER,
  };
}
