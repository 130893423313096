import React from 'react';
import { useSelector } from 'react-redux';
import Indicator from '../../../models/Indicator';
import { ProjectProductTypesAvatarText } from '../../../models/ProjectProductTypesAvatarText';
import { ProjectProductTypes } from '../../../models/ProjectProductTypes';
import { CircleAvatar } from '../CircleAvatar/CircleAvatar';
import styles from './CtFilterButton.module.scss';
import { buttonsFilterConfig } from './CTFilterTabConfig';

type Props = {
  selectedButton: ProjectProductTypes;
  handleSelectedFilter: Function;
  value?: number;
  compositionButton: ProjectProductTypes;
  disabled: boolean;
  classCircleAvatar?: string;
};

const { buttonContainer, lonButton, tcpButton, igrButton, counter, buttonDisabledContainer } = styles;

export const CtFilterButton = ({
  handleSelectedFilter,
  value,
  selectedButton,
  compositionButton,
  disabled,
  classCircleAvatar,
}: Props) => {
  const mapCircleColor = ['#BE86D8', '', '#66B6B6', '#F1AF43'];
  const disabledColor = '#D7DBE4';
  const currentIndicator = useSelector<any, Indicator>(state => state.currentIndicator);
  const mapButtonColorClassName = [lonButton, '', tcpButton, igrButton];

  return showButton(currentIndicator.indicatorKey, compositionButton) ? (
    <div
      onClick={
        disableButton(disabled, value)
          ? undefined
          : () => {
              handleSelectedFilter(compositionButton);
            }
      }
      className={`${
        disableButton(disabled, value)
          ? buttonDisabledContainer
          : setButtonClassName(selectedButton, compositionButton, mapButtonColorClassName[compositionButton])
      } ${classCircleAvatar}`}
    >
      <CircleAvatar
        addClass={disableButton(disabled, value) ? disabledColor : mapCircleColor[compositionButton]}
        label={ProjectProductTypesAvatarText[compositionButton]}
        isDisabled={isNumericValue(value === 0 ? 0 : value)}
      />

      {(value || value === 0) && !disabled && <div className={counter}>({value})</div>}
    </div>
  ) : null;
};

const setButtonClassName = (buttonEnumRecived, classToCompare, classToApply) => {
  return buttonEnumRecived === classToCompare ? classToApply : buttonContainer;
};

const showButton = (indicatorKey, projectType) => {
  return buttonsFilterConfig[indicatorKey][ProjectProductTypes[projectType]];
};

const isNumericValue = value => {
  return typeof value !== 'number';
};

const disableButton = (disabled, value) => {
  return disabled || value === 0 || value === '-';
};
