/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import { multiSelectStyle, DropdownIndicator } from '../FilterComponent.Styles';
import { useTranslation } from 'react-i18next';
import {
  getCustomTabDefaultValueFromProps,
  getDivisionFilterOptions,
  getPortfolioOrProjectSelectedTab,
} from '../UtilFunctions';
import { CalledFrom } from '../../../models/CalledFromEnum';
import SelectOption from '../../../models/SelectOption';
import { setFilterIndicators } from '../../../redux/actions/filterIndicators';
import { setFilter } from '../../../redux/actions/filter';
import { AppMenuTabs } from '../../MenuTabs/userTabsInterface';
import useFilters from '../../../hooks/useFilters';
import { TagFilterContext } from '../../../context/TagFilterContext';

type Props = {
  setOptionSelected?: Function;
  calledFrom: CalledFrom;
  isEdit?: boolean;
  divisionOptionsFromParent?: SelectOption[];
  setCoResponsibleDivisionOnParent?: Function;
};

const handleSelectedDivisionsOptions = (selectedDivisions, allOptions) => {
  return selectedDivisions.length ? allOptions.filter(item => selectedDivisions.includes(item.id)) : allOptions;
};

const getFilteredSelectOptions = (allOptions: SelectOption[], menuTabs: AppMenuTabs, calledFrom: CalledFrom) => {
  const portfolioSelectedTab = menuTabs.portfolioSelectedTab;
  const projectSelectedTab = menuTabs.projectSelectedTab;
  const earlyWarningsSelectedTab = menuTabs.earlyWarningsSelectedTab;

  if (calledFrom === CalledFrom.PORTFOLIO) {
    if (portfolioSelectedTab.tabId === 0) {
      return allOptions;
    } else {
      const selectedDivisions = portfolioSelectedTab.coReponsibleDivisions;
      return handleSelectedDivisionsOptions(selectedDivisions, allOptions);
    }
  } else if (calledFrom === CalledFrom.PROJECT) {
    if (projectSelectedTab.tabId === 0) {
      return allOptions;
    } else {
      const selectedDivisions = projectSelectedTab.coReponsibleDivisions;
      return handleSelectedDivisionsOptions(selectedDivisions, allOptions);
    }
  }
  if (calledFrom === CalledFrom.EARLY_WARNINGS) {
    if (earlyWarningsSelectedTab.tabId === 0) {
      return allOptions;
    } else {
      const selectedDivisions = earlyWarningsSelectedTab.coReponsibleDivisions;
      return handleSelectedDivisionsOptions(selectedDivisions, allOptions);
    }
  } else {
    return allOptions;
  }
};

const handleCoResponsibleDivisionOptions = (
  divisionOptionsFromParent,
  menuTabs,
  calledFrom,
  allCoResponsibleDivisionOptions,
) => {
  return divisionOptionsFromParent
    ? getFilteredSelectOptions(divisionOptionsFromParent, menuTabs, calledFrom)
    : getFilteredSelectOptions(allCoResponsibleDivisionOptions, menuTabs, calledFrom);
};

const CoResponsibleDivisionFilter = (props: Props) => {
  const { setOptionSelected, calledFrom, isEdit, divisionOptionsFromParent, setCoResponsibleDivisionOnParent } = props;
  const { filterEarlyWarnings, setFilterEarlyWarnings } = useFilters();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const filterIndicators = useSelector<any, any>(state => state.filterIndicators);
  const filterProjects = useSelector<any, any>(state => state.filter);
  const divisionsFromRedux = useSelector<any, any>(state => state.divisions);
  const menuTabs = useSelector<any, any>(state => state.menuTabs);
  const selectedTab = getPortfolioOrProjectSelectedTab(calledFrom, menuTabs);
  const allCoResponsibleDivisionOptions = getDivisionFilterOptions(divisionsFromRedux);
  const coResponsibleDivisionOptions = handleCoResponsibleDivisionOptions(
    divisionOptionsFromParent,
    menuTabs,
    calledFrom,
    allCoResponsibleDivisionOptions,
  );
  const handleChange = value => {
    if (value === null) {
      value = [];
    }

    setCoResponsibleDivisionOnParent && setCoResponsibleDivisionOnParent(value);

    const divisionsSelected = value.map(option => ({
      id: option.id,
      value: option.value,
      label: option.label + ' (Co-Responsable)',
      selectedByUser: true,
    }));

    switch (calledFrom) {
      case CalledFrom.PORTFOLIO:
        dispatch(
          setFilterIndicators({
            ...filterIndicators,
            common: {
              ...filterIndicators.common,
              coReponsibleDivisions: divisionsSelected,
            },
          }),
        );
        break;
      case CalledFrom.PROJECT:
        dispatch(
          setFilter({
            ...filterProjects,
            common: {
              ...filterProjects.common,
              coReponsibleDivisions: divisionsSelected,
            },
          }),
        );
        break;
      case CalledFrom.CUSTOM_TABS_PORTFOLIO:
      case CalledFrom.CUSTOM_TABS_PROJECT:
        setOptionSelected && setOptionSelected(value);
        break;
      case CalledFrom.EARLY_WARNINGS:
        dispatch(
          setFilterEarlyWarnings({
            ...filterEarlyWarnings,
            common: {
              ...filterEarlyWarnings.common,
              coReponsibleDivisions: divisionsSelected,
            },
          }),
        );
        break;
    }
  };

  const getDefaultValue = () => {
    const coResponsibleDivisionOptionsAux = getDivisionFilterOptions(divisionsFromRedux);

    switch (calledFrom) {
      case CalledFrom.PORTFOLIO:
        return filterIndicators.common.coReponsibleDivisions;
      case CalledFrom.PROJECT:
        return filterProjects.common.coReponsibleDivisions;
      case CalledFrom.CUSTOM_TABS_PORTFOLIO:
      case CalledFrom.CUSTOM_TABS_PROJECT:
        return isEdit
          ? getCustomTabDefaultValueFromProps(selectedTab, 'coReponsibleDivisions', coResponsibleDivisionOptionsAux)
          : null;
      case CalledFrom.EARLY_WARNINGS:
        return filterEarlyWarnings.common.coReponsibleDivisions;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (isEdit) {
      const selectedOption = getDefaultValue();
      selectedOption.length && setOptionSelected && setOptionSelected(selectedOption);
      selectedOption.length && setCoResponsibleDivisionOnParent && setCoResponsibleDivisionOnParent(selectedOption);
    }
  }, []);

  const { setTagOptions } = useContext(TagFilterContext);
  const getValuesSelected = getDefaultValue();
  const selector = useSelector<any, any>(state => state.filterIndicators.common);

  useEffect(() => {
    setTagOptions("responsibles", getValuesSelected)
  }, [getValuesSelected, setTagOptions])


  return (
    <Select
      key={selector.coReponsibleDivisions}
      id="coResponsibleDivisionSelect"
      components={{ DropdownIndicator }}
      defaultValue={getDefaultValue()}
      name="coResponsibleDivision"
      options={coResponsibleDivisionOptions}
      className="basic-multi-select"
      classNamePrefix="All"
      placeholder={t('all')}
      onChange={value => handleChange(value)}
      styles={multiSelectStyle}
      isMulti
    />
  );
};

export default CoResponsibleDivisionFilter;
