import React from 'react';
import { ReactComponent as TcdiGreen } from '../../../assets/icons/u_alert-check.svg';
import { ReactComponent as TcdiYellow } from '../../../assets/icons/u_alert-exclamacion.svg';
import { ReactComponent as TcdiRed } from '../../../assets/icons/u_alert-multyple.svg';
import { ReactComponent as TcdiGray } from '../../../assets/icons/u_alert-not-assigned.svg';

export enum TcdiClassification {
  Green = 0.75,
  Yellow = 0.5,
  Red,
}

type Props = {
  clasification: number | null;
};

export const TcdiClassificationComponent = ({ clasification }: Props) => {
  if (clasification === null) {
    return <TcdiGray />;
  }
  if (clasification >= TcdiClassification.Green) {
    return <TcdiGreen />;
  } else if (clasification >= TcdiClassification.Yellow && clasification < TcdiClassification.Green) {
    return <TcdiYellow />;
  }
  return <TcdiRed />;
};
