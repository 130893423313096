export const TaxonomyOptions = [
  { id: 0, label: 'customer_support', value: 'customer_support' },
  { id: 1, label: 'operational_support', value: 'operational_support' },
  { id: 2, label: 'research', value: 'research' },
  { id: 3, label: 'diffusion', value: 'diffusion' },
];

export enum TaxonomyCode {
  NA = 'N/A',
  RED = 'RED',
  OPS = 'OPS',
  CLS = 'CLS',
}

export enum TaxonomiesTypes {
  CUSTOMER_SUPPORT = 4,
  OPERATIONAL_SUPPORT = 3,
  RESEARCH = 2,
}
