import React from 'react';
import './CustomSelectSmall.scss';

interface CustomSelectSmallOption {
  value: number;
  label: any;
}

type Props = {
  options: CustomSelectSmallOption[];
  selectValue: number;
  handleChange: any;
  label: any;
};

const CustomSelectSmall = (props: Props) => {
  const { options, selectValue, handleChange, label } = props;
  return (
    <div className="custom-select-small-container">
      <span className="text">{label}</span>
      <select
        data-testid="custom-select-small"
        value={selectValue}
        className="custom-select-small"
        onChange={e => handleChange(e && e.target ? e.target.value : e)}
      >
        {options.map(option => {
          return (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default CustomSelectSmall;
