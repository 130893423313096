import moment from 'moment';
import { GetReportsFileTypeEnum, GetSectionReportEnum } from '../../../../models/ReportEnums';
import { ReportType } from '../../../../models/ReportType';

export const parseData = (items, divisionsState, regionsState, t, resetTranslatedLabel = false) => {
  const withTranslatedDivisions = items.map(item => {
    const parseItem = item.parameters;
    item = { id: item.id, dateGenerated: item.dateGenerated, reportStatus: item.reportStatus, parameters: parseItem };
    return arrayValuesFilter(item, 'DivisionId', 'translatedDivision', divisionsState, t, resetTranslatedLabel);
  });

  const withTranslatedAges = withTranslatedDivisions.map(item => {
    const singularOrPluralText = () => {
      if (item.parameters.inYears) {
        return { singular: 'year', plural: 'years', inYears: true };
      } else {
        return { singular: 'month', plural: 'months', inYears: false };
      }
    };

    return twoValuesFilter(item, t, 'Age', 'translatedAges', singularOrPluralText(), resetTranslatedLabel);
  });

  const withTranslatedDisbursementProjection = withTranslatedAges.map(item => {
    return twoValuesFilter(
      item,
      t,
      'DisbursementProjection',
      'translatedDisbursementProjection',
      { singular: '', plural: '', inYears: false },
      resetTranslatedLabel,
    );
  });

  const withTranslatedCountries = withTranslatedDisbursementProjection.map(item => {
    return arrayValuesFilter(item, 'ResponsibleCountryId', 'translatedCountry', regionsState, t, resetTranslatedLabel);
  });

  const withTranslatedRegions = withTranslatedCountries.map(item => {
    const haveRegions = item.parameters.regions.length;
    let translatedRegions;
    if (haveRegions !== 0) {
      translatedRegions = arrayValuesFilter(
        item,
        'regions',
        'translatedRegions',
        regionsState,
        t,
        resetTranslatedLabel,
      );
    } else {
      translatedRegions = addAllValue(item, 'translatedRegions', t, resetTranslatedLabel);
      addAllValue(item, 'translatedRegions', t, resetTranslatedLabel);
    }

    return translatedRegions;
  });

  const withTranslatedLastDisbursement = withTranslatedRegions.map(item => {
    return twoValuesFilter(
      item,
      t,
      'LastDisbursementInDays',
      'translatedLastDisbursement',
      {
        singular: 'USD',
        plural: 'usd',
        inYears: false,
      },
      resetTranslatedLabel,
    );
  });

  return withTranslatedLastDisbursement.map(item => {
    return arrayValuesFilter(item, 'PmrClassification', 'translatedPmrValues', divisionsState, t, resetTranslatedLabel);
  });
};

const handleAllValuesOnTwoValuesFilter = (newProperty, t, resetTranslatedLabel = false) => {
  return newProperty === 'translatedLastDisbursement' || resetTranslatedLabel
    ? { field: newProperty, value: '' }
    : { field: newProperty, value: t('all') };
};
const twoValuesFilter = (
  item,
  t,
  keyToFilter,
  newProperty,
  unity = { singular: '', plural: '', inYears: false },
  resetTranslatedLabel = false,
) => {
  const filterObject = item.parameters.filters.filter(filter => filter.field === keyToFilter);
  let returnedFilter;
  if (filterObject.length === 0) {
    const allValues = handleAllValuesOnTwoValuesFilter(newProperty, t, resetTranslatedLabel);
    const returnedFilters = { filters: [...item.parameters.filters, allValues] };
    returnedFilter = { ...returnedFilters, filters: returnedFilters.filters };
  } else if (filterObject.length === 1) {
    const optionText = filterObject[0].filterType === 1 ? t('greater_than') : t('less_than');
    const singular = filterObject[0].value > 1 ? t(unity.plural) : t(unity.singular);
    const oneValue = {
      field: newProperty,
      value: `${optionText} ${unity.inYears ? filterObject[0].value / 12 : filterObject[0].value} ${singular}`,
    };
    const returnedFilters = { filters: [...item.parameters.filters, oneValue] };
    returnedFilter = { ...returnedFilters, filters: returnedFilters.filters };
  } else {
    const lessValue = filterObject.find(filter => filter.filterType === 1);
    const greaterValue = filterObject.find(filter => filter.filterType === 0);
    const bothValues = {
      field: newProperty,
      value: `${unity.inYears ? lessValue.value / 12 : lessValue.value} ${t('and')} ${
        unity.inYears ? greaterValue.value / 12 : greaterValue.value
      } ${t(unity.plural)}`,
    };
    const returnedFilters = { filters: [...item.parameters.filters, bothValues] };
    returnedFilter = { ...returnedFilters, filters: returnedFilters.filters };
  }
  const parameters = { ...item.parameters, filters: [...returnedFilter.filters] };
  return { ...item, parameters };
};

const arrayValuesFilter = (item, keyToFind, newProperty, stateParam, t, resetTranslatedLabel = false) => {
  let filterObject;

  switch (keyToFind) {
    case 'regions':
      filterObject = item.parameters.regions;
      break;
    case 'PmrClassification':
      filterObject = item.parameters.filters.find(filter => filter.field === keyToFind);
      break;

    default:
      filterObject =
        item.parameters.filters &&
        item.parameters.filters.find(
          filter => filter.field === keyToFind && filter.value.length !== getStateLength(stateParam, keyToFind),
        );

      break;
  }

  let returnedFilter;
  if (filterObject === undefined) {
    return addAllValue(item, newProperty, t, resetTranslatedLabel);
  } else {
    const names: any[] =
      keyToFind === 'regions'
        ? getFilterObjectRegionsNames(filterObject, keyToFind, stateParam, t)
        : getFilterObjectNames(filterObject, keyToFind, stateParam, t);

    const allValues = { field: newProperty, value: names.join(', ') };
    const returnedFilters = { filters: [...item.parameters.filters, allValues] };
    returnedFilter = { ...returnedFilters, filters: returnedFilters.filters };
    const parameters = { ...item.parameters, filters: [...returnedFilter.filters] };
    return { ...item, parameters };
  }
};

const getStateLength = (state, keyToFind) => {
  if (keyToFind === 'DivisionId') {
    return state.length;
  } else {
    const sum = state.map(countries => countries.countries.length);
    return sum.reduce((a, b) => a + b, 0);
  }
};

const getFilterObjectNames = (filterObject, keyToFind, stateParam, t) => {
  const names: any[] = [];
  filterObject?.value.map(filterId => {
    if (keyToFind === 'ResponsibleCountryId') {
      const region = stateParam.find(region => region.countries.find(country => country.id === filterId));
      const country = region && region.countries.find(country => country.id === filterId);
      return names.push(t(country?.name));
    } else if (keyToFind === 'PmrClassification') {
      let transalation;
      switch (filterId) {
        case 0:
          transalation = t('satisfactory');
          break;
        case 1:
          transalation = t('alert');
          break;
        case 2:
          transalation = t('problem');
          break;
        default:
          transalation = t('not_assigned');
          break;
      }
      names.push(transalation);

      return names;
    } else {
      return stateParam.find(param => param.id === filterId && names.push(t(param.name)));
    }
  });

  return names;
};

const getFilterObjectRegionsNames = (filterObject, keyToFind, stateParam, t) => {
  const names: any[] = [];
  filterObject.map(filterId => {
    const region = stateParam.find(region => region.id === filterId);
    return names.push(region.name);
  });
  return names;
};

const addAllValue = (item, newProperty, t, resetTranslatedLabel = false) => {
  let translatedLabel = true;
  if (newProperty === 'translatedRegions' || newProperty === 'translatedPmrValues' || resetTranslatedLabel) {
    translatedLabel = false;
  }
  const allValues = { field: newProperty, value: `${translatedLabel ? t('all') : ''} ` };
  const returnedFilter = { filters: [...item.parameters.filters, allValues] };
  const parameters = { ...item.parameters, filters: [...returnedFilter.filters] };
  return { ...item, parameters };
};

export const createReportNameFile = (reportType, formatType, selectedReport, t) => {
  if (reportType === GetSectionReportEnum.Projects_Section) {
    return `${selectedReport.project && selectedReport.project.name}-${moment(selectedReport.dateGenerated).format(
      'DD_MM_YY',
    )}${formatTypeExtension(formatType)}`;
  } else if (reportType === GetSectionReportEnum.Alert_Section) {
    return `${t('early_warning_report')}-${moment(selectedReport.dateGenerated).format('DD_MM_YY')}.xlsx`;
  } else if (reportType === GetSectionReportEnum.Accordance_Section) {
    return `${t('accordance_report')}-${moment(selectedReport.dateGenerated).format('DD_MM_YY')}.xlsx`;
  } else {
    return `${
      reportType === GetSectionReportEnum.Portfolio_Section ? t('portfolio_report') : t('review_report')
    }-${moment(selectedReport.dateGenerated).format('DD_MM_YY')}${formatTypeExtension(formatType)}`;
  }
};

export const formatTypeExtension = formatType => {
  if (formatType === GetReportsFileTypeEnum.PDF) {
    return '.pdf';
  } else if (formatType === GetReportsFileTypeEnum.Document_Word) {
    return '.docx';
  } else if (formatType === GetReportsFileTypeEnum.Excel) {
    return '.xlsx';
  } else {
    return '.pptx';
  }
};

export const getHeaderTable = reportType => {
  if (reportType === ReportType.Portfolio) {
    return [
      { id: 1, label: 'division', key: 'translatedDivision' },
      { id: 2, label: 'age', key: 'translatedAges' },
      { id: 3, label: 'projection_disbursed', key: 'translatedDisbursementProjection' },
      { id: 4, label: 'country', key: 'translatedCountry' },
      { id: 5, label: 'others', key: 'others' },
      { id: 6, label: 'date', key: 'dateGenerated' },
    ];
  } else if (reportType === ReportType.Project) {
    return [
      { id: 1, label: 'code_word', key: 'codeAndLoan' },
      { id: 2, label: 'project', key: 'name' },
      { id: 3, label: 'sections', key: 'sections' },
      { id: 4, label: 'date', key: 'dateGenerated' },
    ];
  } else if (reportType === ReportType.Accordance) {
    return [
      { id: 1, label: 'type_of_accordance', key: 'translatedAccordancesType' },
      { id: 2, label: 'year_of_creation_of_the_agreement', key: 'sortedYears' },
      { id: 3, label: 'country', key: 'translatedCountries' },
      { id: 4, label: 'division', key: 'translatedDivisions' },
      { id: 5, label: 'report_date', key: 'dateGenerated' },
    ];
  } else if (reportType === ReportType.Alert) {
    return [
      { id: 1, label: 'menu_portfolio', key: 'selectedTab' },
      { id: 2, label: 'alert', key: 'transalatedEarlyWarnings' },
      { id: 3, label: 'active_filters', key: 'translatedCommonFilters' },
      { id: 4, label: 'report_date', key: 'dateGenerated' },
    ];
  } else {
    return [
      { id: 1, label: 'revision', key: 'revisionNumber' },
      { id: 2, label: 'date', key: 'startDate' },
      { id: 3, label: 'portfolio_summary_review_report', key: 'portfolio_summary' },
      { id: 4, label: 'project_detail_review_report', key: 'projects_details' },
      { id: 5, label: 'current_revision_agreements', key: 'current_revision_agreements' },
      { id: 7, label: 'report_date', key: 'dateGenerated' },
    ];
  }
};
