import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  inputRoot: {
    border: '1px solid #ed5a4d !important',
  },
  witdh:{
    width: '100%',
  }
}));
