import React from 'react';
import './ReportsSectionsEmptyState.scss';
import { isIE11 } from '../../../../services/booleanUtil';
import { useTranslation } from 'react-i18next';

type Props = {
  message?: string;
};
export const ReportsSectionsEmptyState = ({ message = '' }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="reports-sections-empty-state-container" style={isIE11() ? { height: 180 } : {}}>
      <span className="content" />

      <div className="text-content">
        <span className="title">{t('there_are_no_reports_generated')}</span>
        <span className="subtitle">{t(message)}</span>
      </div>
    </div>
  );
};
