import React from 'react';
import './Financial.scss';
import Advance from '../../Advance/Advance';
import { useSelector, useDispatch } from 'react-redux';
import Project from '../../../../models/Project';
import LabelData from '../../../Util/LabelData/LabelData';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import DisbursementsChart from './DisbursementsChart/DisbursementsChart';
import { parseToChartData, parseToDoubleChartData } from './util';
import { getProjectFinancialInfo } from '../../../../redux/actions/currentProject';
import { replaceMomentFormat } from '../../../../services/util';
import { BudgetAllocation } from './BudgetAllocation/BudgetAllocation';
import PariPassuSection from './PariPassuSection/PariPassuSection';
import Anticipations from './Anticipations';
import EfasSection from './EfasSection';
import AnnualProjectionSection from './AnnualProjectionSection';
import { ProjectProductTypes } from '../../../../models/ProjectProductTypes';
import { ProjectExecutionTypes } from '../../../../models/ProjectExecutionTypes';
import BidSection from './BidSection/BidSection';
import { ProjectProductTypeClassification } from '../../../../models/ProjectProductTypeClassification';
import { DisbursementChartAlertBar } from './DisbursementChartAlertBar/DisbursementChartAlertBar';
import ExecutionByComponent from './ExecutionByComponent/ExecutionByComponent';
import { getProjectBaseInformation } from '../../../../api/projectApi';
import LocalCounterPart from './LocalCounterPart/LocalCounterPart';

//TODO revisar logica de IG para tipos de execution
const isExecutingAgency = (
  projectProductType = ProjectProductTypes.Loan,
  execution: ProjectExecutionTypes | undefined,
  igrGreater,
) =>
  (projectProductType === ProjectProductTypes.CT || (projectProductType === ProjectProductTypes.IG && !igrGreater)) &&
  execution !== undefined &&
  execution === ProjectExecutionTypes.EXECUTING_AGENCY;

export const isBid = (
  projectProductType = ProjectProductTypes.Loan,
  execution: ProjectExecutionTypes | undefined,
  igrGreater,
) =>
  (projectProductType === ProjectProductTypes.CT || (projectProductType === ProjectProductTypes.IG && !igrGreater)) &&
  execution !== undefined &&
  execution === ProjectExecutionTypes.BID;

const rightContainerStyle = currentProject =>
  isExecutingAgency(
    currentProject.projectProductType,
    currentProject.execution,
    haveProjectProductTypeClassification(currentProject) === ProjectProductTypeClassification.IGR_GREATER_THAN_3MM,
  )
    ? { paddingLeft: 0 }
    : {};

export const labelsTexts = (t: Function) => [
  t('january'),
  t('february'),
  t('march'),
  t('april'),
  t('may'),
  t('june'),
  t('july'),
  t('august'),
  t('september'),
  t('october'),
  t('november'),
  t('december'),
];

export const haveProjectProductTypeClassification = currentProject =>
  currentProject.projectProductTypeClassification ? currentProject.projectProductTypeClassification['code'] : '';
const Financial = () => {
  const currentProject = useSelector<any, Project>(state => state.currentProject);
  const haveLoans = useSelector<any, any>(state => state.currentProject.withLoans);
  const [baseInfo, setBaseInfo] = React.useState<any>();
  const loadBaseInformation = async () => {
    if (currentProject.id) {
      const response = await getProjectBaseInformation(currentProject.id);
      setBaseInfo(response);
    }
  };

  const dispatch = useDispatch();

  const { financialInformation } = currentProject;
  const {
    budgetCredit,
    poaAmount,
    totalDisbursementAmount,
    percentRealizedDisbursement,
    percentPendigDisbursementAmount,
    realizedDisbursementAmount,
    pendingDisbursementAmount,
    disbursements,
    componentBalances,
  } = financialInformation;
  const { t } = useTranslation();

  React.useEffect(() => {
    dispatch(getProjectFinancialInfo(currentProject.id));
    loadBaseInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject.id]);
  return isBid(
    currentProject.projectProductType,
    currentProject.execution,
    haveProjectProductTypeClassification(currentProject) === ProjectProductTypeClassification.IGR_GREATER_THAN_3MM,
  ) ? (
    <BidSection financialInformation={financialInformation} baseInformation={baseInfo} />
  ) : (
    <div className="financial-container">
      <div className="container_boxes">
        {/* Avance financiero */}
        <Advance
          data={{
            title: 'financialInformation',
            subtitle: 'sibtitle',
            total: totalDisbursementAmount,
            completed: percentRealizedDisbursement,
            pending: percentPendigDisbursementAmount,
            completedText: realizedDisbursementAmount,
            pendingText: pendingDisbursementAmount,
            unit: 'dolar_sign',
            unitSingular: 'dolar_sign',
          }}
          big={true}
          financialSection={true}
        />
        <LabelData
          label={`${t('financial_last_disbursement_current')}: `}
          data={baseInfo ? replaceMomentFormat(moment(baseInfo.lastExtensionApprobedDate).format('DD/MMM/YYYY')) : '-'}
          reverse={true}
        />
        <hr className="border-advance-container" />

        <LocalCounterPart financialInformation={financialInformation} />
        <hr className="border-advance-container" />
        <PariPassuSection financialInformation={financialInformation} />
      </div>

      <div className="container_boxes-anticipations" style={rightContainerStyle(currentProject)}>
        <Anticipations anticipation={financialInformation.advancePayment} state={currentProject.overallStage} withLoans={haveLoans} />
        <ExecutionByComponent data={componentBalances || []} />
        {/* <div>
          <Anticipations anticipation={financialInformation.advancePayment} state={currentProject.overallStage} />
          {isExecutingAgency(
            currentProject.projectProductType,
            currentProject.execution,
            haveProjectProductTypeClassification(currentProject) ===
            ProjectProductTypeClassification.IGR_GREATER_THAN_3MM,
          ) ? (
            <ExecutionByComponent data={componentBalances || []} />
          ) : null}
        </div> */}
      </div>

      <div className="container_boxes-anticipations">
        <div className={'chart-container'} >
          {financialInformation && (
            <DisbursementsChart
              financialInformation={financialInformation}
              data={
                isExecutingAgency(
                  currentProject.projectProductType,
                  currentProject.execution,
                  haveProjectProductTypeClassification(currentProject) ===
                  ProjectProductTypeClassification.IGR_GREATER_THAN_3MM,
                )
                  ? parseToChartData(new Date().getMonth() + 1, labelsTexts(t), disbursements, true)
                  : parseToDoubleChartData(labelsTexts(t), disbursements || [], t, true)
              }
              double={
                !isExecutingAgency(
                  currentProject.projectProductType,
                  currentProject.execution,
                  haveProjectProductTypeClassification(currentProject) ===
                  ProjectProductTypeClassification.IGR_GREATER_THAN_3MM,
                )
              }
            />
          )}

          <DisbursementChartAlertBar />
          <AnnualProjectionSection
            financialInfo={financialInformation}
            ctExecutingAgency={isExecutingAgency(
              currentProject.projectProductType,
              currentProject.execution,
              haveProjectProductTypeClassification(currentProject) ===
              ProjectProductTypeClassification.IGR_GREATER_THAN_3MM,
            )}
          />
        </div>

        {/*
        {isExecutingAgency(
          currentProject.projectProductType,
          currentProject.execution,
          haveProjectProductTypeClassification(currentProject) ===
          ProjectProductTypeClassification.IGR_GREATER_THAN_3MM,
        ) ? null : (
          <div className="budget-allocation">
            <div className="title-container">
              <span className="title">{t('budget_allocation')}</span>
            </div>
            <div className="form-container">
              <BudgetAllocation budgetCredit={budgetCredit} poaAmount={poaAmount} projectId={currentProject.id} />
            </div>
          </div>
        )} */}
        <EfasSection efas={financialInformation.efasPresentations} />
      </div>
    </div>
  );
};

export default Financial;
