import React from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../../../../../services/booleanUtil';
import SummaryHeaderSingleValue from '../../../SummaryHeaderSingleValue/SummaryHeaderSingleValue';
import styles from '../../SharedStyles/HeaderDrops.module.scss';
import { SummaryTcpInternalFilters } from '../SummaryTcp';
import { renderTextByInternalFilterApplied, renderValueByInternaFilterApplied } from './Utils';
const { dropsTitle, dropsValues, dropsContainer } = styles;

type Props = {
  filterBy: SummaryTcpInternalFilters;
  handleModuleFilterState: any;
};

export const PerformanceModule = ({ filterBy, handleModuleFilterState }: Props) => {
  const { t } = useTranslation();
  const {
    ctsNatPendingDisbursementAmount,
    ctsRegPendingDisbursementAmount,
    ctsNatDisbursementAmount,
    ctsRegDisbursementAmount,
    ctsNatDisbursementPercent,
    ctsRegDisbursementPercent,
    ctsNatAgeAverage,
    ctsRegAgeAverage,
  } = handleModuleFilterState;
  return (
    <div className={dropsContainer}>
      <div className={dropsTitle}>{t('performance_diff_in_portguese')}</div>
      <div className={`${dropsValues} drops-value`}>
        <SummaryHeaderSingleValue
          value={renderValueByInternaFilterApplied(
            filterBy,
            ctsNatPendingDisbursementAmount.value,
            ctsRegPendingDisbursementAmount.value,
          )}
          title={renderTextByInternalFilterApplied(
            filterBy,
            ctsNatPendingDisbursementAmount.text,
            ctsRegPendingDisbursementAmount.text,
            t,
          )}
          millionLogic={true}
        />
        <SummaryHeaderSingleValue
          value={renderValueByInternaFilterApplied(
            filterBy,
            ctsNatDisbursementAmount.value,
            ctsRegDisbursementAmount.value,
          )}
          title={renderTextByInternalFilterApplied(
            filterBy,
            ctsNatDisbursementAmount.text,
            ctsRegDisbursementAmount.text,
            t,
          )}
          millionLogic={true}
          customStyle={{ minWidth: 100, paddingRight: 5 }}
        />
        <SummaryHeaderSingleValue
          value={renderValueByInternaFilterApplied(
            filterBy,
            Math.round(ctsNatDisbursementPercent.value),
            Math.round(ctsRegDisbursementPercent.value),
          )}
          title={renderTextByInternalFilterApplied(
            filterBy,
            ctsNatDisbursementPercent.text,
            ctsRegDisbursementPercent.text,
            t,
          )}
          isPercentage={true}
          customStyle={isMobile() ? { paddingLeft: 0 } : { paddingLeft: 24 }}
        />
        <SummaryHeaderSingleValue
          value={renderValueByInternaFilterApplied(filterBy, ctsNatAgeAverage.value, ctsRegAgeAverage.value)}
          title={renderTextByInternalFilterApplied(filterBy, ctsNatAgeAverage.text, ctsRegAgeAverage.text, t)}
          isMonth={true}
          customStyle={{ paddingLeft: isMobile() ? 0 : 10, minWidth: 80 }}
        />
      </div>
    </div>
  );
};
