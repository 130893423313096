import React from 'react';
import { AccordanceType } from '../../../../../models/AccordanceType';
import CustomDatePicker from '../../../../Util/CustomDatePicker/CustomDatePicker';
import { useTranslation } from 'react-i18next';
import RequiredErrorMessage from '../../../../Util/Errors/RequiredErrorMessage/RequiredErrorMessage';
import CustomSelect from '../../../../Util/CustomSelect/CustomSelect';
import SelectOption from '../../../../../models/SelectOption';

type Props = {
  accordanceFormType: AccordanceType;
  revisionDate: any;
  setFormErrors: any;
  formErrors: any;
  setRevisionDate: any;
  expirationDate: any;
  setExpirationDate: any;
  setSemesterOption: any;
  semesterOption: SelectOption | null;
  accordanceDate: any;
  handleAccordanceDate: Function;
};

const newRevisionDateBiggerExpiration = (date: string, expirationDate: string): boolean => {
  return (expirationDate && new Date(date).getTime()) > new Date(expirationDate).getTime();
};

export const getSemesters = t => {
  const currentYear = new Date().getFullYear();
  const currentYearString = currentYear.toString();
  const nextYear = (currentYear + 1).toString();
  const previousYear = (currentYear - 1).toString();
  const semester1 = '01-01T03:00:00';
  const semester2 = '07-01T03:00:00';

  const semesters = [
    { id: 1, value: `${previousYear}-${semester1}`, label: `${t('semester')} 1 ${previousYear}` },
    { id: 2, value: `${previousYear}-${semester1}`, label: `${t('semester')} 1 ${previousYear}` },
    { id: 3, value: `${currentYearString}-${semester1}`, label: `${t('semester')} 1 ${currentYearString}` },
    { id: 4, value: `${currentYearString}-${semester2}`, label: `${t('semester')} 2 ${currentYearString}` },
    { id: 5, value: `${nextYear}-${semester1}`, label: `${t('semester')} 1 ${nextYear}` },
    { id: 6, value: `${nextYear}-${semester2}`, label: `${t('semester')} 2 ${nextYear}` },
  ];

  return currentYear !== 2021 ? semesters : semesters.slice(2);
};

const DateSelector = ({
  accordanceFormType,
  revisionDate,
  setFormErrors,
  formErrors,
  setRevisionDate,
  expirationDate,
  setExpirationDate,
  setSemesterOption,
  semesterOption,
  accordanceDate,
  handleAccordanceDate,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="horizontal-row">
        <div className="label-select-container">
          <CustomDatePicker
            date={revisionDate}
            label={t('new_portfolio_review_date_two')}
            setDate={date => {
              setFormErrors({ ...formErrors, ...{ revisionDate: false } });
              setRevisionDate(date);
              if (newRevisionDateBiggerExpiration(date, expirationDate)) {
                setExpirationDate(null);
              }
            }}
            style={{ customInput: { width: 270, boxSizing: 'border-box', justifyContent: 'flex-start' } }}
            error={formErrors?.revisionDate}
            placeholderComplete={true}
            minDate={null}
          />
          {formErrors?.revisionDate && <RequiredErrorMessage />}
        </div>
      </div>
      {/* {accordanceFormType === AccordanceType.Revision && (
        <div className="horizontal-row">
          <div className="label-select-container">
            <CustomDatePicker
              date={revisionDate}
              label={t('new_portfolio_review_date_two')}
              setDate={date => {
                setFormErrors({ ...formErrors, ...{ revisionDate: false } });
                setRevisionDate(date);
                if (newRevisionDateBiggerExpiration(date, expirationDate)) {
                  setExpirationDate(null);
                }
              }}
              style={{ customInput: { width: 270, boxSizing: 'border-box', justifyContent: 'flex-start' } }}
              error={formErrors.revisionDate}
              placeholderComplete={true}
              minDate={null}
            />
            {formErrors.revisionDate && <RequiredErrorMessage />}
          </div>
        </div>
      )}
      {accordanceFormType === AccordanceType.InterGroup && (
        <div className="horizontal-row">
          <div className="label-select-container">
            <p className="label">{t('inter_group_revision_capitalized')}</p>
            <CustomSelect
              options={getSemesters(t)}
              handleSelected={value => {
                setSemesterOption(value);
                if (newRevisionDateBiggerExpiration(value.value, expirationDate)) {
                  setExpirationDate(null);
                }
              }}
              style={{ width: 270 }}
              placeholder={t('select_semester')}
              selectedOption={semesterOption}
              error={formErrors.semesterOption}
              placeholderComplete={true}
            />
            {formErrors.semesterOption && <RequiredErrorMessage />}
          </div>
        </div>
      )}
      {accordanceFormType === AccordanceType.Monitoring && (
        <div className="horizontal-row">
          <CustomDatePicker
            date={accordanceDate}
            label={`${t('date')} ${t('of')} ${t('accordance')}`}
            setDate={handleAccordanceDate}
            error={formErrors.accordanceDate}
            minDate={revisionDate}
            disabled={false}
            placeholderComplete={true}
          />
          {formErrors.expirationDate && <RequiredErrorMessage />}
        </div>
      )} */}
    </>
  );
};

export default DateSelector;
