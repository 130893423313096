import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectCountryCount } from '../../../../../models/ProjectCountryCount';
import { TaxonomiesTypes } from '../../../../../models/TaxonomyOptions';
import { addSessionFilter } from '../../../../../services/sessionFiltersIndicatorDetailHelper';
import SummaryHeaderSingleValue from '../../../SummaryHeaderSingleValue/SummaryHeaderSingleValue';
import styles from '../../SharedStyles/HeaderDrops.module.scss';
import { SummaryTcpInternalFilters } from '../SummaryTcp';
import {
  checkIfResto,
  handleIsSelectableButton,
  renderTextByInternalFilterApplied,
  renderValueByInternaFilterApplied,
} from './Utils';
const { dropsTitle, dropsValues, dropsSelectableButtonsContainer } = styles;

type Props = {
  ctMetrics: any;
  filterBy: SummaryTcpInternalFilters;
  handleModuleFilterState: any;
};

export const TaxonomyParticipationModule = ({ filterBy, ctMetrics, handleModuleFilterState }: Props) => {
  const {
    setTaxonomyCustomerSupport,
    taxonomyCustomerSupport,
    taxonomyOperationalSupport,
    setTaxonomyOperationalSupport,
    taxonomyResearch,
    setTaxonomyResearch,
    setUpTo8Selected,
    upTo8Selected,
    moreThan8Selected,
    setMoreThan8Selected,
    udrSelected,
    setUdrSelected,
  } = handleModuleFilterState;
  const { t } = useTranslation();
  const { nationals, regionals } = ctMetrics;
  const { ctsNatClientSupportPercentage, ctsNatLonSupportPercentage, ctsNatInvPercentage } = nationals;

  const { ctsRegUdrShare, ctsRegUpTo8Share, ctsRegMoreThan8Share } = regionals;
  return (
    <div className={dropsSelectableButtonsContainer}>
      <div className={dropsTitle}>{renderTextByInternalFilterApplied(filterBy, 'taxonomy', 'participation', t)}</div>
      <div className={`${dropsValues} filters-button`}>
        <SummaryHeaderSingleValue
          value={renderValueByInternaFilterApplied(
            filterBy,
            checkIfResto(ctsNatClientSupportPercentage.value),
            Math.round(ctsRegUdrShare.value),
          )}
          title={renderTextByInternalFilterApplied(
            filterBy,
            ctsNatClientSupportPercentage.text,
            ctsRegUdrShare.text,
            t,
          )}
          isPercentage={filterBy === SummaryTcpInternalFilters.NATIONAL}
          isButtonSelected={renderValueByInternaFilterApplied(filterBy, taxonomyCustomerSupport, udrSelected)}
          isButton={handleIsSelectableButton(
            filterBy,
            checkIfResto(ctsNatClientSupportPercentage.value),
            Math.round(ctsRegUdrShare.value),
          )}
          callbackLink={renderValueByInternaFilterApplied(
            filterBy,
            () =>
              handleTaxonomyClick(TaxonomiesTypes.CUSTOMER_SUPPORT, {
                setTaxonomyCustomerSupport,
                taxonomyCustomerSupport,
                taxonomyOperationalSupport,
                setTaxonomyOperationalSupport,
                taxonomyResearch,
                setTaxonomyResearch,
              }),
            () =>
              handleParticipationClick('udr', {
                setUpTo8Selected,
                upTo8Selected,
                moreThan8Selected,
                setMoreThan8Selected,
                udrSelected,
                setUdrSelected,
              }),
          )}
        />
        <SummaryHeaderSingleValue
          customStyle={{ marginLeft: 10 }}
          value={renderValueByInternaFilterApplied(
            filterBy,
            checkIfResto(ctsNatLonSupportPercentage.value),
            Math.round(ctsRegUpTo8Share.value),
          )}
          title={renderTextByInternalFilterApplied(filterBy, ctsNatLonSupportPercentage.text, ctsRegUpTo8Share.text, t)}
          isPercentage={filterBy === SummaryTcpInternalFilters.NATIONAL}
          isButton={handleIsSelectableButton(
            filterBy,
            checkIfResto(ctsNatLonSupportPercentage.value),
            Math.round(ctsRegUpTo8Share.value),
          )}
          isButtonSelected={renderValueByInternaFilterApplied(filterBy, taxonomyOperationalSupport, upTo8Selected)}
          callbackLink={renderValueByInternaFilterApplied(
            filterBy,
            () =>
              handleTaxonomyClick(TaxonomiesTypes.OPERATIONAL_SUPPORT, {
                setTaxonomyCustomerSupport,
                taxonomyCustomerSupport,
                taxonomyOperationalSupport,
                setTaxonomyOperationalSupport,
                taxonomyResearch,
                setTaxonomyResearch,
              }),
            () =>
              handleParticipationClick(ProjectCountryCount.UP_TO_8, {
                setUpTo8Selected,
                upTo8Selected,
                moreThan8Selected,
                setMoreThan8Selected,
                udrSelected,
                setUdrSelected,
              }),
          )}
        />
        <SummaryHeaderSingleValue
          value={renderValueByInternaFilterApplied(
            filterBy,
            checkIfResto(ctsNatInvPercentage.value),
            Math.round(ctsRegMoreThan8Share.value),
          )}
          title={renderTextByInternalFilterApplied(filterBy, ctsNatInvPercentage.text, ctsRegMoreThan8Share.text, t)}
          isPercentage={filterBy === SummaryTcpInternalFilters.NATIONAL}
          customStyle={{ minWidth: 76 }}
          isButton={handleIsSelectableButton(
            filterBy,
            checkIfResto(ctsNatInvPercentage.value),
            Math.round(ctsRegMoreThan8Share.value),
          )}
          isButtonSelected={renderValueByInternaFilterApplied(filterBy, taxonomyResearch, moreThan8Selected)}
          callbackLink={renderValueByInternaFilterApplied(
            filterBy,
            () =>
              handleTaxonomyClick(TaxonomiesTypes.RESEARCH, {
                setTaxonomyCustomerSupport,
                taxonomyCustomerSupport,
                taxonomyOperationalSupport,
                setTaxonomyOperationalSupport,
                taxonomyResearch,
                setTaxonomyResearch,
              }),
            () =>
              handleParticipationClick(ProjectCountryCount.MORE_THAN_8, {
                setUpTo8Selected,
                upTo8Selected,
                moreThan8Selected,
                setMoreThan8Selected,
                udrSelected,
                setUdrSelected,
              }),
          )}
        />
      </div>
    </div>
  );
};

const handleTaxonomyClick = (
  typePressed: TaxonomiesTypes,
  {
    setTaxonomyCustomerSupport,
    taxonomyCustomerSupport,
    taxonomyOperationalSupport,
    setTaxonomyOperationalSupport,
    taxonomyResearch,
    setTaxonomyResearch,
  },
) => {
  if (typePressed === TaxonomiesTypes.CUSTOMER_SUPPORT) {
    setTaxonomyCustomerSupport(!taxonomyCustomerSupport);
    taxonomyOperationalSupport && setTaxonomyOperationalSupport(!taxonomyOperationalSupport);
    taxonomyResearch && setTaxonomyResearch(!taxonomyResearch);
  } else if (typePressed === TaxonomiesTypes.OPERATIONAL_SUPPORT) {
    setTaxonomyOperationalSupport(!taxonomyOperationalSupport);
    taxonomyCustomerSupport && setTaxonomyCustomerSupport(!taxonomyCustomerSupport);
    taxonomyResearch && setTaxonomyResearch(!taxonomyResearch);
  } else if (typePressed === TaxonomiesTypes.RESEARCH) {
    setTaxonomyResearch(!taxonomyResearch);
    taxonomyCustomerSupport && setTaxonomyCustomerSupport(!taxonomyCustomerSupport);
    taxonomyOperationalSupport && setTaxonomyOperationalSupport(!taxonomyOperationalSupport);
  }
  addSessionFilter({ TCPTaxonomyId: typePressed });
};

const handleParticipationClick = (
  typePressed: ProjectCountryCount | string,
  { setUpTo8Selected, upTo8Selected, moreThan8Selected, setMoreThan8Selected, udrSelected, setUdrSelected },
) => {
  if (typePressed === ProjectCountryCount.UP_TO_8) {
    setUpTo8Selected(!upTo8Selected);
    moreThan8Selected && setMoreThan8Selected(!moreThan8Selected);
    udrSelected && setUdrSelected(!udrSelected);
  } else if (typePressed === ProjectCountryCount.MORE_THAN_8) {
    setMoreThan8Selected(!moreThan8Selected);
    upTo8Selected && setUpTo8Selected(!upTo8Selected);
    udrSelected && setUdrSelected(!udrSelected);
  } else if (typePressed === 'udr') {
    setUdrSelected(!udrSelected);
    upTo8Selected && setUpTo8Selected(!upTo8Selected);
    moreThan8Selected && setMoreThan8Selected(!moreThan8Selected);
  }
  addSessionFilter({ participationFilter: typePressed });
};
