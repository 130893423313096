import React, { useEffect } from 'react';
import './Advance.scss';
import { useTranslation } from 'react-i18next';
import { Line } from 'rc-progress';
import FinancialAdvance from '../ProjectTabs/Financial/FinancialAdvance/FinancialAdvance';
import { checkHundreadPercent } from '../GeneralInformationTC/GeneralInformationTC';
import { useSelector } from 'react-redux';
import { initialDisbursement } from '../GeneralInformation/util';
import { getProjectBaseInformation } from '../../../api/projectApi';

export type AdvanceProps = {
  title: string;
  subtitle: string;
  completedLabel: string;
  pendingLabel: string;
  percent: number;
  completed: string;
  pending: string;
  completedOne?: any;
  completedTwo?: string;
  pendingOne: string;
  pendingTwo?: string;
  footerOne: string;
  footerTwo: string;
  dataBar?: any;
  baseInfo?: any;
  handleSeeMoreClick?: () => void;
};
const Advance = (props: AdvanceProps) => {
  const {
    title,
    subtitle,
    completedLabel,
    pendingLabel,
    percent,
    completed,
    pending,
    completedOne,
    completedTwo,
    pendingOne,
    pendingTwo,
    footerOne,
    footerTwo,
    handleSeeMoreClick,
    dataBar,
    baseInfo,
  } = props;

  const { currentProject } = useSelector<any, any>(state => state);

  const { financialInformation = initialDisbursement } = currentProject;

  const {
    realizedDisbursementAmount,
    commitedAmount,
    availableAmount,
    lastDisbursementDate,
    totalDisbursementAmount,
    percentRealizedDisbursement,
    percentCommitedAmount,
    percentAvailableAmount,
  } = financialInformation;

  const { t } = useTranslation();

  return (
    <div className="advance-container">
      {dataBar !== 0 ? (
        <>
          <div className="title-container">
            <h4>{title}</h4>
            <span>{subtitle}</span>
          </div>

          <div className="line-container">
            <div className="line__completed-label ">
              <div className="line__completed-label">{completedLabel}</div>
              <div className="line__pending-label">{pendingLabel}</div>
            </div>
            <div className="line__completed-label ">
              <Line
                className="line"
                percent={percent}
                strokeWidth={5}
                trailWidth={5}
                strokeColor="#538CFF"
                trailColor="#D0D5E0"
                aria-label={t(title)}
                strokeLinecap="square"
              />
            </div>
            <div className="line__completed-label group__line">
              <div className="line">
                <div className="line__completed line__completed-box">{completed}</div>
                <div className="line__pending line__pending-box">{pending}</div>
              </div>
              <div className="line">
                <div className="line__completed-one">{completedOne}</div>
                <div className="line__pending-one">{pendingOne}</div>
              </div>
              <div className="line">
                <div className="line__completed-two">{completedTwo}</div>
                <div className="line__pending-two">{pendingTwo}</div>
              </div>
            </div>
          </div>
          <div className="line__footer-view-more line">
            <div className="line__footer-one">{footerOne}</div>
            <div className="line__footer-two">{footerTwo}</div>
            <button className="btn-primary-text padding-none" onClick={handleSeeMoreClick}>
              {t('see_more')}
            </button>
          </div>
        </>
      ) : (
        <div className="bar-Position">
          <FinancialAdvance
            data={{
              realizedDisbursementAmount,
              commitedAmount,
              availableAmount,
              lastDisbursementDate,
              totalDisbursementAmount,
              percentRealizedDisbursement: Math.round(percentRealizedDisbursement),
              percentAvailableAmount: Math.round(percentAvailableAmount),
              percentCommitedAmount: checkHundreadPercent(
                percentRealizedDisbursement,
                percentCommitedAmount,
                percentAvailableAmount,
              ),
            }}
            baseInformation={baseInfo}
          />
          <div className="see-more-btn">
            <button className="btn-primary-text padding-none" onClick={handleSeeMoreClick}>
              {t('see_more')}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Advance;
