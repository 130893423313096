import { isMobile } from '../../services/booleanUtil';
import { isEqual, cloneDeep } from 'lodash';
import { startedFilter } from '../../redux/reducers/filterObject';
import { IndicatorKeyEnum } from '../../models/IndicatorKeyEnum';
import { MenuTabs } from '../MenuTabs/userTabsInterface';
import SelectOption from '../../models/SelectOption';
import { FilterTypesEnum } from '../../models/FitlerTypesEnum';
import { getNewFilteredIndicators } from '../../api/indicatorApi';
import { processIndicators } from './indicatorHelper';
import { updateCurrentIndicatorFromDetail } from '../../redux/actions/currentIndicator';

export const setTitleOnUpdateIndicators = (portfolioSelectedTab: MenuTabs, t) => {
  if (portfolioSelectedTab.internalId === '0_global_bid') {
    return t(portfolioSelectedTab.title);
  } else {
    return portfolioSelectedTab.title;
  }
};

export const setMarginRight = () => {
  return isMobile() ? 12 : 25;
};

export const setScrollablOrStandard = () => {
  return isMobile() ? 'scrollable' : 'standard';
};

export const setFilterButtonText = t => {
  return isMobile() ? '' : t('filter_button');
};

export const areFiltersEquals = actualFilterState => {
  //TODO Ajuste temporal hasta que se incluyan los filtros nuevos en los reportes. Al incluir los reportes se debería usar de nuevo actualFilterState
  const onlyOldFilters = cloneDeep(actualFilterState);
  onlyOldFilters.coReponsibleDivisions = [];
  onlyOldFilters.participantDivisions = [];
  onlyOldFilters.sectors = [];
  onlyOldFilters.teamLeaders = [];
  onlyOldFilters.interestIssues = [];
  //
  return isEqual(startedFilter, onlyOldFilters);
};

export const emptyProjects = totalProjects => {
  return totalProjects === 0 ? true : false;
};

export const setSelectTabValue = (setMenuValue, newCreateMenu, selectedTabState) => {
  return setMenuValue ? newCreateMenu : selectedTabState;
};

export const initialPortfolioRequestsState: any[] = [
  { key: IndicatorKeyEnum.PROJECTS_PENDING_SIGNATURE_OR_LEGISLATIVE_APPROVAL, resolved: false },
  { key: IndicatorKeyEnum.PROJECTS_PENDING_DISBURSEMENT_ELIGIBILITY, resolved: false },
  { key: IndicatorKeyEnum.PROJECTS_WITH_TRANSACTIONAL_BUDGET_DEFICIT, resolved: false },
  { key: IndicatorKeyEnum.PROJECTS_OVERALL_STAGE_CODE_DISBURSING_NO_DISBURSEMENTS_OVER_180_DAYS, resolved: false },
  { key: IndicatorKeyEnum.PROJECTS_EXTENDED_NOT_FULLY_DISBURSED, resolved: false },
  { key: IndicatorKeyEnum.PROJECTS_IN_EXECUTION_WITH_MICI_OPEN_CASES, resolved: false },
  { key: IndicatorKeyEnum.PMR_PERFORMANCE_LAST_CLOSED_YEAR, resolved: false },
  { key: 'ANNEXED_PROJECTS', resolved: false },
];

export const getFilterObject = (filterType: number, field: string, value: any[]) => {
  return value && value.length ? { filterType, field, value: value.map(v => v.id) } : null;
};

export const getCustomTabButtonText = (button: string, isEdit: boolean) => {
  if (button === 'left') {
    return isEdit ? 'save' : 'create_tab';
  } else {
    return isEdit ? 'delete_tab' : 'cancel';
  }
};

export const getDivisionFilterObject = (
  divisionsSelected: SelectOption[],
  sectorsFromRedux: any[],
  sectorsSelected: SelectOption[],
) => {
  const divisionSelectedIds = divisionsSelected.map(division => division.id);
  const divisionsIdsFromSectorsSelected = sectorsFromRedux
    .filter(sector => sectorsSelected.map(sector => sector.id).includes(sector.id))
    .flatMap(sector => sector.divisions)
    .map(division => division.id);

  return divisionSelectedIds.length
    ? {
        filterType: FilterTypesEnum.NumberIn,
        field: 'DivisionId',
        value: divisionSelectedIds,
      }
    : divisionsIdsFromSectorsSelected.length
    ? {
        filterType: FilterTypesEnum.NumberIn,
        field: 'DivisionId',
        value: divisionsIdsFromSectorsSelected,
      }
    : null;
};

export const callIndicatorFromDetail = async (indicatorKey, dispatch, customTypeFilters) => {
  const indicatorList = await getNewFilteredIndicators({}, customTypeFilters);
  const indicatorsProc = processIndicators(indicatorList);
  const selectIndicatorProc = indicatorsProc.find(item => item.indicatorKey === indicatorKey);
  selectIndicatorProc && dispatch(updateCurrentIndicatorFromDetail(selectIndicatorProc));
};

export const resetLocalStorageOnPortfolioMount = () => {
  localStorage.removeItem('bubblesAxis');
  localStorage.removeItem('childCriticalProduct');
};
