import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './FilterSection.module.scss';
import FilterButton from '../../FilterButton/FilterButton';
import { ProjectProductTypeFilters } from '../../../../../models/ProjectProductTypeFilters';

type Props = {
  dataWithCount: any;
  section: string;
  mostUsedFilters: boolean;
  projectTypeFilterState: ProjectProductTypeFilters[];
};

const isHidden = (projectTypeFilterState, subSection) => {
  const filterNotApplicable =
    projectTypeFilterState.includes(ProjectProductTypeFilters.CT) ||
    projectTypeFilterState.includes(ProjectProductTypeFilters.IG_lower_3);

  return (
    (filterNotApplicable && subSection === 'accumulated_disbursements') ||
    (filterNotApplicable && subSection === 'pmr_initials') ||
    (filterNotApplicable && subSection === 'portfolio_incidence') ||
    (filterNotApplicable && subSection === 'esg_grade')
  );
};

const FilterSection = (props: Props) => {
  const { t } = useTranslation();
  const { dataWithCount, section, mostUsedFilters, projectTypeFilterState } = props;
  const { buttonsContainer, subSectionStyle } = styles;

  return (
    <>
      <h4>{t(section)}</h4>
      <div className={buttonsContainer}>
        {Object.keys(dataWithCount[section]).map(subSection => {
          return (
            !isHidden(projectTypeFilterState, subSection) && (
              <div key={subSection}>
                {!mostUsedFilters && <h5 className={subSectionStyle}>{t(subSection)}</h5>}
                <div>
                  {dataWithCount[section][subSection]
                    .filter(item => {
                      return mostUsedFilters ? item.mostUsed : true;
                    })
                    .map(filter => {
                      return <FilterButton key={filter.id} filter={filter} mostUsedFilters={mostUsedFilters} />;
                    })}
                </div>
              </div>
            )
          );
        })}
      </div>
    </>
  );
};

export default FilterSection;
