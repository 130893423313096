const IndicatorSessionFilters = 'IndicatorSessionFilters';
export const DISBURSEMENT_PROJECTION_LAST_5_YEARS_SELECTED_YEAR = 'DISBURSEMENT_PROJECTION_LAST_5_YEARS_SELECTED_YEAR';
export const PORTFOLIO_OVERVIEW_LAST_5_YEARS_SELECTED_YEAR = 'PORTFOLIO_OVERVIEW_LAST_5_YEARS_SELECTED_YEAR';
export const PCR_NEXT_PRESENTATION = 'PCR_NEXT_PRESENTATION';
export const LAST_DISBURSEMENT_DATE_CLOSE_OR_EXPIRED = 'LAST_DISBURSEMENT_DATE_CLOSE_OR_EXPIRED';
export const PENDING_SIGNATURE_OR_LEGIS_APPROVAL = 'PENDING_SIGNATURE_OR_LEGIS_APPROVAL';
export const PROJECTS_EXTENDED_NOT_FULLY_DISBURSED = 'PROJECTS_EXTENDED_NOT_FULLY_DISBURSED';
export const PMR_ALERT_PROBLEM_LAST_3_YEARS = 'PMR_ALERT_PROBLEM_LAST_3_YEARS';
export const PROJECTS_ELIGIBLE_FIRST_UNDISBURSEMENT = 'PROJECTS_ELIGIBLE_FIRST_UNDISBURSEMENT';
export const PROJECTS_PARTIAL_CANCELLATIONS = 'PROJECTS_PARTIAL_CANCELLATIONS';
export const CLASSIFICATION_OF_ACQUISITION_PLANS = 'CLASSIFICATION_OF_ACQUISITION_PLANS';
export const MONTHLY_DISBURSEMENT_PROJECTION_CURRENT_YEAR_INCLUDING_REGIONALS =
  'MONTHLY_DISBURSEMENT_PROJECTION_CURRENT_YEAR_INCLUDING_REGIONALS';
const resetTCPModuleFilters = () => {
  deleteSessionFilter('ProjectExecutorEntity');
  deleteSessionFilter('TCPTaxonomyId');
  deleteSessionFilter('participationFilter');
};

const deleteSessionFilter = filterToCheck => {
  const filtersApplied = sessionStorage.getItem(IndicatorSessionFilters);
  if (filtersApplied) {
    const filterExist = JSON.parse(filtersApplied);
    if (filterExist.hasOwnProperty(filterToCheck)) {
      delete filterExist[filterToCheck];
      sessionStorage.removeItem(IndicatorSessionFilters);
      sessionStorage.setItem(IndicatorSessionFilters, JSON.stringify(filterExist));
    }
  }
};

const addSessionFilter = filterValue => {
  const filtersApplied = sessionStorage.getItem(IndicatorSessionFilters);
  if (filtersApplied) {
    const propertyName = Object.keys(filterValue)[0];
    const value = Object.values(filterValue)[0];
    const indicatorSessionFiltersUpdatedObject = { ...JSON.parse(filtersApplied), [propertyName]: value };
    sessionStorage.setItem(IndicatorSessionFilters, JSON.stringify(indicatorSessionFiltersUpdatedObject));

    if (filterValue && filterValue.loanFilters) {
      const objectToLocalStorage = {
        includeRegionalProjectsApplied: filterValue.loanFilters.includeRegionalProjectsApplied,
        optionSelectedApplied: filterValue.loanFilters.optionSelectedApplied,
      };
      localStorage.setItem('summaryIndicatorIncludeRegionalSetup', JSON.stringify(objectToLocalStorage));
    }
  } else {
    sessionStorage.setItem(IndicatorSessionFilters, JSON.stringify(filterValue));

    if (
      filterValue &&
      filterValue.loanFilters &&
      filterValue.loanFilters.includeRegionalProjectsApplied &&
      filterValue.loanFilters.optionSelectedApplied
    ) {
      localStorage.setItem(
        'summaryIndicatorIncludeRegionalSetup',
        JSON.stringify({
          includeRegionalProjectsApplied: filterValue.loanFilters.includeRegionalProjectsApplied,
          optionSelectedApplied: filterValue.loanFilters.optionSelectedApplied,
        }),
      );
    }
  }
};

const resetSessionFilter = () => {
  sessionStorage.removeItem(IndicatorSessionFilters);
  sessionStorage.removeItem('PMR_VALIDATION_STATE_RADIALS_SELECTED');
  sessionStorage.removeItem('PaginationIndicator');
  sessionStorage.removeItem(DISBURSEMENT_PROJECTION_LAST_5_YEARS_SELECTED_YEAR);
  sessionStorage.removeItem(PORTFOLIO_OVERVIEW_LAST_5_YEARS_SELECTED_YEAR);
  sessionStorage.removeItem('COUNTRY_INDICATOR_CODE');
  sessionStorage.removeItem(PCR_NEXT_PRESENTATION);
  sessionStorage.removeItem(LAST_DISBURSEMENT_DATE_CLOSE_OR_EXPIRED);
  sessionStorage.removeItem(PENDING_SIGNATURE_OR_LEGIS_APPROVAL);
  sessionStorage.removeItem(PROJECTS_EXTENDED_NOT_FULLY_DISBURSED);
  sessionStorage.removeItem(PMR_ALERT_PROBLEM_LAST_3_YEARS);
  sessionStorage.removeItem(PROJECTS_ELIGIBLE_FIRST_UNDISBURSEMENT);
  sessionStorage.removeItem(PROJECTS_PARTIAL_CANCELLATIONS);
  sessionStorage.removeItem(CLASSIFICATION_OF_ACQUISITION_PLANS);
  sessionStorage.removeItem(MONTHLY_DISBURSEMENT_PROJECTION_CURRENT_YEAR_INCLUDING_REGIONALS);
};

const checkIfSessionFilterExist = filterToCheck => {
  const filtersApplied = sessionStorage.getItem(IndicatorSessionFilters);
  if (filtersApplied) {
    const filterExist = JSON.parse(filtersApplied);
    return filterExist.hasOwnProperty(filterToCheck);
  } else {
    return false;
  }
};

const filterExistWithLoanFiltersOptionSelected = filterExist =>
  filterExist && filterExist.loanFilters && filterExist.loanFilters.optionSelectedApplied;
const filterExistWithLoanFiltersIncludeRegional = filterExist =>
  filterExist && filterExist.loanFilters && filterExist.loanFilters.includeRegionalProjectsApplied;
const getObjectLoanFilterStorage = loanFiltersStorage => loanFiltersStorage && { ...JSON.parse(loanFiltersStorage) };

const updateFilterExist = (filterExist, objectLoanFilterStorage) => {
  if (filterExistWithLoanFiltersOptionSelected(filterExist)) {
    filterExist.loanFilters.optionSelectedApplied = objectLoanFilterStorage.optionSelectedApplied;
  } else {
    filterExist.loanFilters.optionSelectedApplied = 0;
  }

  if (filterExistWithLoanFiltersIncludeRegional(filterExist)) {
    filterExist.loanFilters.includeRegionalProjectsApplied = objectLoanFilterStorage.includeRegionalProjectsApplied;
  } else {
    filterExist.loanFilters.includeRegionalProjectsApplied = false;
  }
  return filterExist;
};

const getFilterSessionValue = filter => {
  const filtersApplied = sessionStorage.getItem(IndicatorSessionFilters);
  if (filtersApplied) {
    let filterExist = JSON.parse(filtersApplied);
    if (filter === 'loanFilters') {
      const loanFiltersStorage = localStorage.getItem('summaryIndicatorIncludeRegionalSetup');
      const objectLoanFilterStorage = getObjectLoanFilterStorage(loanFiltersStorage);

      if (objectLoanFilterStorage) {
        filterExist = updateFilterExist({ ...filterExist }, objectLoanFilterStorage);
      } else {
        localStorage.setItem(
          'summaryIndicatorIncludeRegionalSetup',
          JSON.stringify({
            includeRegionalProjectsApplied: filterExist.loanFilters.includeRegionalProjectsApplied,
            optionSelectedApplied: filterExist.loanFilters.optionSelectedApplied,
          }),
        );
      }
    }

    return filterExist[filter];
  } else {
    return false;
  }
};

const getFilterBySessionKeyOrGetDefaultSessionObject = (sessionKey: string, defaultSessionFilterObject: any) => {
  const storage = sessionStorage.getItem(sessionKey);
  const storageObject = storage && JSON.parse(storage);
  return storageObject ? storageObject : defaultSessionFilterObject;
};

export {
  addSessionFilter,
  resetSessionFilter,
  checkIfSessionFilterExist,
  getFilterSessionValue,
  filterExistWithLoanFiltersOptionSelected,
  filterExistWithLoanFiltersIncludeRegional,
  getObjectLoanFilterStorage,
  updateFilterExist,
  getFilterBySessionKeyOrGetDefaultSessionObject,
  deleteSessionFilter,
  resetTCPModuleFilters,
};
