import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IndicatorKeyEnum } from '../../../models/IndicatorKeyEnum';
import Project from '../../../models/Project';
import { getProjectByESGBarcharts } from '../../../redux/actions/indicatorProjects';
import { getDynamicTableFormat } from '../../../services/util';
import { PortfolioSimpleIndicatorTable } from '../PortfolioSimpleIndicatorTable/PortfolioSimpleIndicatorTable';
import { ProjectCounter } from '../ProjectCounter/ProjectCounter';
import './WorkWithFilteredChart.scss';

type Props = {
  currentIndicator: any;
  conceptFilter: any;
  sectorFilters?: any;
  regionFilters?: any;
};

export const WorkWithFilterdChart = ({
  currentIndicator: { id: indicatorId, indicatorKey },
  conceptFilter,
  sectorFilters,
  regionFilters,
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const indicatorProjects = useSelector<any, Project[]>(state => state.indicatorProjects);

  useEffect(() => {
    if (conceptFilter.label !== '') {
      getFilteredProjects(dispatch, { indicatorId, indicatorKey, conceptFilter, sectorFilters, regionFilters });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conceptFilter, sectorFilters, regionFilters]);
  return (
    <div className="work-with-filtered-chart-container">
      <div className="title">{renderTitle(indicatorKey, t)}</div>
      <div className={`esg-text ${conceptFilter.label}`}>
        {conceptFilter.label === 'not_assigned' ? t('not_assigned_exp_not_set') : t(conceptFilter.label)}
      </div>
      <div className="table-container">
        {renderTableByIndicatorKey(indicatorKey, indicatorProjects)}
        <ProjectCounter arrayToCount={indicatorProjects} countProjects={true} indicatorKey={indicatorKey} />
      </div>
    </div>
  );
};

const renderTitle = (indicatorKey, t) => {
  switch (indicatorKey) {
    case IndicatorKeyEnum.ESG_SAFEGUARD_PERFORMANCE_RATING:
      return t('performance');
    case IndicatorKeyEnum.ESG_ENVIRONMENTAL_AND_SOCIAL_RISK_RATING:
      return t('risk');

    default:
      return '';
  }
};

const renderTableByIndicatorKey = (indicatorKey, indicatorProjects) => {
  return (
    <PortfolioSimpleIndicatorTable
      projects={indicatorProjects}
      tableInfo={getDynamicTableFormat(indicatorKey)}
      indicatorKey={indicatorKey}
    />
  );
};

export const getFilteredProjects = (d, indicatorInfo) => {
  let returnedDispatch;

  if (
    indicatorInfo.indicatorKey === IndicatorKeyEnum.ESG_SAFEGUARD_PERFORMANCE_RATING ||
    indicatorInfo.indicatorKey === IndicatorKeyEnum.ESG_ENVIRONMENTAL_AND_SOCIAL_RISK_RATING
  ) {
    returnedDispatch = d(
      getProjectByESGBarcharts(
        indicatorInfo.indicatorKey === IndicatorKeyEnum.ESG_SAFEGUARD_PERFORMANCE_RATING ? 'EsgSafeguard' : 'EsgRisk',
        indicatorInfo.conceptFilter.label,
        indicatorInfo.conceptFilter.totalProjects,
        indicatorInfo.indicatorId,
        indicatorInfo.sectorFilters,
        indicatorInfo.regionFilters,
      ),
    );
  }

  return returnedDispatch;
};
