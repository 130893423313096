import React from 'react';
import { ReviewsStatusEnum } from '../../../../models/ReviewsStatusEnum';
import styles from './MyEvent.module.scss';

const MyEvent = props => {
  const {
    event: { code, divisionCode, status, readonly = false },
  } = props;

  return (
    <div
      className={status === ReviewsStatusEnum.Confirmed ? styles.confirmedContainer : styles.container}
      style={readonly ? { boxShadow: 'none', border: '1px solid #f7f7f7' } : {}}
    >
      <span className={status === ReviewsStatusEnum.Confirmed ? styles.confirmedCode : styles.code}>{code}</span>
      <span className={status === ReviewsStatusEnum.Confirmed ? styles.confirmedDivisionCode : styles.divisionCode}>
        {divisionCode}
      </span>
    </div>
  );
};

export default MyEvent;
