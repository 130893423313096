import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './IndicatorsTranslations.module.scss';
import Header from '../Header/Header';
import CustomTextArea from '../CustomTextArea/CustomTextArea';
import { buttonText } from '../util';
import SaveButton from '../../../Util/Buttons/SaveButton/SaveButton';
import { sortEncoded } from '../../../../services/sortsUtil';

type Props = {
  indicator: any;
  submitData: Function;
};

const translationsRows = ['title', 'description', 'tooltip'];

const IndicatorsTranslations = ({ indicator, submitData }: Props) => {
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  useEffect(() => {
    setEditMode(false);
  }, [indicator]);

  const buttonPress = () => {
    if (editMode) {
      submitData();
    } else {
      setEditMode(true);
    }
  };

  const isRecentlyUpdated = (translation: any, row: string) => {
    return translation.updatedRows && translation.updatedRows.indexOf(row) > -1;
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <Header editMode={true} />
      </div>
      {translationsRows.map(r => (
        <div className={styles.tableRow} key={r}>
          <div className={styles.firstColumn}>{t(r)}</div>
          {indicator.translations?.sort(sortEncoded('languageEncodedName')).map(tran =>
            editMode ? (
              <CustomTextArea
                indicator={indicator}
                translation={tran}
                sectionRow={r}
                hasChanges={() => setHasChanges(true)}
              />
            ) : (
              <div className={isRecentlyUpdated(tran, r) ? styles.columnUpdated : styles.column} key={tran.id}>
                {tran[r]}
              </div>
            ),
          )}
        </div>
      ))}
      <div className={styles.footer}>
        <SaveButton
          customText={t(buttonText(editMode))}
          handleClick={buttonPress}
          customStyle={{}}
          disabled={!hasChanges && editMode}
        />
      </div>
    </div>
  );
};

export default IndicatorsTranslations;
