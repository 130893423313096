import React from 'react';
import './PmrPortfolio.scss';
import { ReactComponent as PmrGreen } from '../../../assets/icons/u_alert-check.svg';
import { ReactComponent as PmrYellow } from '../../../assets/icons/u_alert-exclamacion.svg';
import { ReactComponent as PmrRed } from '../../../assets/icons/u_alert-multyple.svg';
import { ReactComponent as PmrGray } from '../../../assets/icons/u_alert-not-assigned.svg';
import { PmrClassification } from '../../../models/PmrClassification';
import { useTranslation } from 'react-i18next';
import { IndicatorKeyEnum } from '../../../models/IndicatorKeyEnum';

interface ReceivedProps {
  pmrClassification: PmrClassification;
  indicatorKey?: IndicatorKeyEnum;
}

type Props = ReceivedProps;

export const getPmrValues = (pmrClassification, key) => {
  if (pmrClassification === PmrClassification.Green) {
    return {
      svg: <PmrGreen />,
      text: 'satisfactory',
    };
  } else if (pmrClassification === PmrClassification.Yellow) {
    return {
      svg: <PmrYellow />,
      text: 'alert',
    };
  } else if (pmrClassification === PmrClassification.Red) {
    return {
      svg: <PmrRed />,
      text: 'problem',
    };
  } else if (pmrClassification === PmrClassification.Na) {
    return {
      svg: <PmrGray />,
      text: key && key === IndicatorKeyEnum.TCP_PERFORMANCE ? 'not_applicable' : 'not_assigned',
    };
  }
  return {
    svg: <PmrRed />,
    text: 'problem',
  };
};

export const PmrPortfolio = (props: Props) => {
  const { t } = useTranslation();
  const { pmrClassification, indicatorKey } = props;

  const results = getPmrValues(pmrClassification, indicatorKey);
  return (
    <div className="pmr-classification-container">
      {results.svg} <span className={`pmr-text ${results.text}`}> {t(results.text)} </span>
    </div>
  );
};
