import React from 'react';
import { useSelector } from 'react-redux';
import { ActivityTable } from './ActivityTable/ActivityTable';
import { Acquisition, AcquisitionType } from '../../../../../models/Planning';
import NewPlanningItem, { PlanningItemType } from '../NewItem/NewPlanningItem';
import { isAuthorizedToEdit } from '../../../../../services/booleanUtil';
import User from '../../../../../models/User';
import Project from '../../../../../models/Project';

type Props = {
  acquisition: Acquisition;
};

export const Activity = ({ acquisition }: Props) => {
  const user = useSelector<any, User>(state => state.user);
  const currentProject = useSelector<any, Project>(state => state.currentProject);
  return (
    <div style={{ width: '100%' }}>
      {acquisition.acquisitionType !== AcquisitionType.Transfer && (
        <>
          <ActivityTable dataList={acquisition.activities || []} acquisition={acquisition} />

          {isAuthorizedToEdit(user, currentProject) && (
            <NewPlanningItem
              type={PlanningItemType.Activity}
              keyProductId={acquisition.keyProductId}
              acquisition={acquisition}
            />
          )}
        </>
      )}
    </div>
  );
};
