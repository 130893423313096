import React from 'react';
import './Member.scss';
import { useTranslation } from 'react-i18next';
import CustomSelect from '../../Util/CustomSelect/CustomSelect';
import DeleteIcon from '@material-ui/icons/Delete';
import { deleteExecUnit, editExecUnit } from '../../../api/projectTeamApi';
import { isAuthorizedToEdit } from '../../../services/booleanUtil';
import { useSelector } from 'react-redux';
import User from '../../../models/User';
import Project from '../../../models/Project';

type Props = {
  member: any;
  rolesList: any[];
  afterSubmit: any;
  isCt?: boolean;
};

const Member = ({ member, rolesList, afterSubmit, isCt = false }: Props) => {
  const { t } = useTranslation();
  const user = useSelector<any, User>(state => state.user);
  const currentProject = useSelector<any, Project>(state => state.currentProject);
  const removeMember = async () => {
    await deleteExecUnit(member.applicationUserId);
    afterSubmit();
  };

  const editMember = async item => {
    await editExecUnit(member.applicationUserId, { name: item.label, id: item.id });
    afterSubmit();
  };

  return (
    <div className="exec-unit-member-container">
      {isCt ? (
        <div className="small-text one-line-text">{t('role_id_13')}</div>
      ) : (
        <CustomSelect
          type={'small'}
          selectedOption={rolesList.find(item => item.id === member.roleInProjectId) || rolesList[0]}
          options={rolesList}
          handleSelected={item => editMember(item)}
          translatedFromBackend={true}
          disabled={!isAuthorizedToEdit(user, currentProject)}
        />
      )}
      <p className="name">{member.applicationUser ? member.applicationUser.name : ''}</p>
      {isAuthorizedToEdit(user, currentProject) && (
        <div className="remove-button" onClick={removeMember}>
          <span className="remove">
            <DeleteIcon style={{ color: 'white' }} fontSize={'small'} />
          </span>
          <span className="remove-text">{t('remove')}</span>
        </div>
      )}
    </div>
  );
};

export default Member;
