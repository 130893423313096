import React, { useState } from 'react';
import styles from './InfoEarlyWarningsHistory.module.scss';
import { useTranslation } from 'react-i18next';
import { BulletItem } from '../../../Util/BulletItem/BulletItem';

const { container, clickable, capitalized, header, bulletPackContainer, bulletContainer, title } = styles;
const InfoEarlyWarningsHistory = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <div className={container}>
      <div className={header}>
        <div className={clickable} onClick={() => setIsOpen(!isOpen)}>
          <u className={capitalized}>{t('alert_information')}</u>
        </div>
      </div>
      {isOpen ? <BulletPack /> : null}
    </div>
  );
};

export default InfoEarlyWarningsHistory;

const BulletPack = () => {
  const { t } = useTranslation();
  const customStyle = {
    width: '100%',
    marginTop: 'auto',
    fontFamily: 'Source Sans Pro',
    color: '#727E8C',
    fontSize: '10px',
    fontWeight: 600,
    marginBottom: '20px',
  };
  return (
    <div className={bulletContainer}>
      {bullets.map(bulletPack => {
        return (
          <div className={bulletPackContainer}>
            <p className={title}>{t(bulletPack.title)}</p>
            <BulletItem bulletColor={'bulletGreen'} bulletText={bulletPack.green} customStyle={customStyle} />
            <BulletItem bulletColor={'bulletYellow'} bulletText={bulletPack.yellow} customStyle={customStyle} />
            <BulletItem bulletColor={'bulletRed'} bulletText={bulletPack.red} customStyle={customStyle} />
          </div>
        );
      })}
    </div>
  );
};

export const bullets = [
  {
    title: 'signature_pending',
    green: 'within_the_regulation_time',
    yellow: 'up_to_one_year_extension',
    red: 'more_than_one_year_extension',
  },

  {
    title: 'eligible_pending_first_disbursement',
    green: 'less_than_one_month_after_eligibility',
    yellow: 'one_to_six_months_after_eligibility',
    red: 'more_than_six_months_after_eligibility',
  },
  {
    title: 'with_recent_alert_or_problem_classifications',
    green: 'no_alert_or_problem_in_the_last_3_years',
    yellow: 'alert_or_problem_in_one_or_the_last_two_years',
    red: 'alert_or_problem_in_the_last_three_years',
  },
  {
    title: 'with_unsatisfactory_performance_in_safeguards',
    green: 'satisfactory_partially_satisfactory',
    yellow: 'partially_unsatisfactory',
    red: 'unsatisfactory',
  },
  {
    title: 'with_partial_cancellations',
    green: 'no_partial_cancellations',
    yellow: 'with_cancellations_of_up_to_15',
    red: 'with_cancellations_higher_than_15',
  },
  {
    title: 'with_overdue_disbursement_term',
    green: 'within_the_period_for_justification_120_days',
    yellow: 'with_date_for_last_disbursement_expired_by_less_than_1_year',
    red: 'with_date_for_last_disbursement_expired_by_1_year_or_more',
  },
];
