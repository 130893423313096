import { DefaultsSelects } from '../../../../../models/EDefaultsSelects';
import { IDefaultSelectSelected } from '../../../../../models/IDefaultSelectSelected';
import Region from '../../../../../models/Region';
import Sector from '../../../../../models/Sector';
import SelectOption from '../../../../../models/SelectOption';
import { AppMenuTabs, MenuTabs } from '../../../../MenuTabs/userTabsInterface';

const getRegionsOptions = (regions: Region[]): SelectOption[] => {
  return regions.map(region => ({
    id: region.id,
    label: region.acronym,
    value: region.acronym,
  }));
};

const getCountriesOptions = (regions: Region[], selectedRegion: SelectOption[]): SelectOption[] => {
  const countriesOptions = [];
  if (selectedRegion && selectedRegion.length) {
    const selectedRegionIds = selectedRegion.map(region => region.id);
    regions.forEach(region => {
      if (selectedRegionIds.includes(region.id)) {
        region.countries.forEach(country => addValueToCountriesOption(countriesOptions, country));
      }
    });
  } else {
    regions.forEach(region => {
      region.countries.forEach(country => {
        addValueToCountriesOption(countriesOptions, country);
      });
    });
  }
  return countriesOptions.sort((a: SelectOption, b: SelectOption) => (a.label > b.label ? 1 : -1));
};

const addValueToCountriesOption = (countriesOptions, country) =>
  countriesOptions.push({
    id: country.id,
    label: country.name,
    value: country.name,
    categoryId: country.regionId,
  });

const getSectorOptions = (sectors: Sector[]): SelectOption[] => {
  return sectors.map(sector => ({
    id: sector.id,
    label: sector.description,
    value: sector.code,
  }));
};

const getDivisionsOptions = (sectors: Sector[], selectedSector: SelectOption[]): SelectOption[] => {
  const divisionsOptions = [];
  if (selectedSector && selectedSector.length) {
    const selectedSectorIds = selectedSector.map(sector => sector.id);
    sectors.forEach(sector => {
      if (selectedSectorIds.includes(sector.id)) {
        sector.divisions.forEach(division => addValueToDivisionsOption(divisionsOptions, division));
      }
    });
  } else {
    sectors.forEach(sector => {
      sector.divisions.forEach(division => {
        addValueToDivisionsOption(divisionsOptions, division);
      });
    });
  }
  return divisionsOptions.sort((a: SelectOption, b: SelectOption) => (a.label > b.label ? 1 : -1));
};

const addValueToDivisionsOption = (divisionsOptions, division) =>
  divisionsOptions.push({
    id: division.id,
    label: division.name,
    value: division.name,
  });

const getDivisionsIdsFromSelectedSectors = (sectors, selectedSector: SelectOption[]): number[] => {
  const selectedSectorDivisions = selectedSector
    ? selectedSector.map((sector: SelectOption) => {
        return sectors.find((item: Sector) => item.id === sector.id).divisions.map((item: any) => item.id);
      })
    : [];
  return selectedSectorDivisions.reduce((acc: any, val: any) => acc.concat(val), []);
};

const handleDefaultsSelectSelected = (menuTabs: AppMenuTabs): IDefaultSelectSelected => {
  const { userTabs } = menuTabs;
  const defaultTab = userTabs.find((tab: MenuTabs) => tab.default);
  const isCountry = defaultTab && defaultTab.filters.find((filter: any) => filter.field === 'ResponsibleCountryId');
  if (isCountry) {
    return { default: DefaultsSelects.Country, id: isCountry.value };
  }
  const isRegion = defaultTab && defaultTab.regions.length > 0 && defaultTab.regions;
  if (isRegion) {
    return { default: DefaultsSelects.Region, id: isRegion };
  }
  const isDivision = defaultTab && defaultTab.filters.find((filter: any) => filter.field === 'DivisionId');
  if (isDivision) {
    const divisionValues = isDivision.value.length;
    if (divisionValues === 1) {
      return { default: DefaultsSelects.Division, id: isDivision.value };
    }
    if (divisionValues > 1) {
      return { default: DefaultsSelects.Sector, id: isDivision.value };
    }
  }
  return { default: DefaultsSelects.NoneDefaults, id: [] };
};

const getDefaultCountry = (regions: Region[], defaultSelects: IDefaultSelectSelected) => {
  let defaultCountry;
  regions.forEach(region => {
    const country = region.countries.find(country => country.id === defaultSelects.id[0]);
    if (country) {
      defaultCountry = {
        id: country.id,
        label: country.name,
        value: country.name,
        categoryId: country.regionId,
      };
    }
  });
  return defaultCountry;
};

const getDefaultRegion = (regions: Region[], defaultSelects: IDefaultSelectSelected) => {
  const defaultRegion = regions.find((region: any) => region.id === defaultSelects.id[0]) || regions[0];
  return {
    id: defaultRegion.id,
    label: defaultRegion.acronym,
    value: defaultRegion.acronym,
  };
};

const getDefaultDivision = (sectors: Sector[], defaultSelects: IDefaultSelectSelected) => {
  let defaultDivision;
  sectors.forEach((sector: any) => {
    const division = sector.divisions.find((division: any) => division.id === defaultSelects.id[0]);
    if (division) {
      defaultDivision = {
        id: division.id,
        label: division.name,
        value: division.name,
      };
    }
  });
  return defaultDivision;
};

const getDefaultSector = (sectors: Sector[], defaultSelects: IDefaultSelectSelected) => {
  const defaultSector = sectors.filter((sector: any) =>
    sector.divisions.some((division: any) => division.id === defaultSelects.id[0]),
  );
  return {
    id: defaultSector[0].id,
    label: defaultSector[0].description,
    value: defaultSector[0].code,
  };
};

const handleUpdateItemsIds = state => {
  return state ? state.map((item: SelectOption) => item.id) : [];
};
export {
  getRegionsOptions,
  getCountriesOptions,
  getSectorOptions,
  getDivisionsOptions,
  getDivisionsIdsFromSelectedSectors,
  handleDefaultsSelectSelected,
  getDefaultCountry,
  getDefaultRegion,
  getDefaultDivision,
  getDefaultSector,
  handleUpdateItemsIds,
};
