import React from 'react';
import { useTranslation } from 'react-i18next';
import './DelegatePermissions.scss';
import { MultiSearchUser } from '../../Util/MultiSearchUser/MultiSearchUser';
import { PermissionToDelegateEnum } from '../../../models/PermissionToDelegateEnum';
import SelectOption from '../../../models/SelectOption';
import { RolesIdEnum } from '../../../models/RolesIdEnum';

type Props = {
  delegateReviewsUsers: SelectOption[];
  delegateAccordancesUsers: SelectOption[];
  handleDelegateReviewsUsers: any;
  handleDelegateAccordancesUsers: any;
  userRole: any;
};

export const DelegatePermissions = ({
  delegateReviewsUsers,
  delegateAccordancesUsers,
  handleDelegateReviewsUsers,
  handleDelegateAccordancesUsers,
  userRole,
}: Props) => {
  const { t } = useTranslation();

  const handleReturnedUsersSelected = (usersSelected, permissionToDelegate) => {
    permissionToDelegate === PermissionToDelegateEnum.AdminReview
      ? handleDelegateReviewsUsers(usersSelected)
      : handleDelegateAccordancesUsers(usersSelected);
  };

  return (
    <div className="delegate-permissions-container">
      <div className="title">{t('delegate_permissions_to_other_people')}</div>
      <div className='search-delegate'>
        {userRole === RolesIdEnum.Chief_of_Operations && (
          <>
            <div className="label">{t('review_record_date_select_projects_set_agenda')}</div>
            <div className="search">
              <MultiSearchUser
                delegatedUsers={delegateReviewsUsers}
                returnUsersSelected={handleReturnedUsersSelected}
                permissionToDelegate={PermissionToDelegateEnum.AdminReview}
              />
            </div>
          </>
        )}

        {(userRole === RolesIdEnum.Chief_of_Operations || userRole === RolesIdEnum.Team_Leader) && (
          <>
            <div className="label">{t('approval_of_agreements')}</div>
            <div className="search">
              <MultiSearchUser
                delegatedUsers={delegateAccordancesUsers}
                returnUsersSelected={handleReturnedUsersSelected}
                permissionToDelegate={PermissionToDelegateEnum.ApproveAccordances}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
