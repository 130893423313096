import React from 'react';
import { useTranslation } from 'react-i18next';
import { Bubble } from '../BubbleElement/BubbleElement';
import { getValidBubble, handleBubbles } from '../UtilFunctions';
import useFilters from '../../../hooks/useFilters';
import { CalledFrom } from '../../../models/CalledFromEnum';
import { IFilterBubble } from '../../../models/FilterBubble';

type Props = IFilterBubble;

const DisbursementProjectionBubbles = (props: Props) => {
  const { indicatorCall, calledFrom } = props;
  const {
    filter,
    filterIndicators,
    setFilterIndicators,
    setFilter,
    dispatch,
    setFilterEarlyWarnings,
    filterEarlyWarnings,
  } = useFilters();
  const bubbles = handleBubbles({ indicatorCall, filterIndicators, filter, filterEarlyWarnings, calledFrom });
  const { t } = useTranslation();
  const label = t('projection_disbursed');
  const defaultMaxValue = 0;
  const defaultMinValue = 0;

  const closeBubble = label => {
    if (calledFrom === CalledFrom.EARLY_WARNINGS) {
      dispatch(
        setFilterEarlyWarnings({
          ...filterEarlyWarnings,
          common: {
            ...filterEarlyWarnings.common,
            disbursementProjectionFrom: defaultMinValue,
            disbursementProjectionTo: defaultMaxValue,
          },
        }),
      );
    } else {
      if (indicatorCall) {
        dispatch(
          setFilterIndicators({
            ...filterIndicators,
            common: {
              ...filterIndicators.common,
              disbursementProjectionFrom: defaultMinValue,
              disbursementProjectionTo: defaultMaxValue,
            },
          }),
        );
      } else {
        dispatch(
          setFilter({
            ...filter,
            common: {
              ...filter.common,
              disbursementProjectionFrom: defaultMinValue,
              disbursementProjectionTo: defaultMaxValue,
              itSearch: false,
            },
          }),
        );
      }
    }
  };

  const returnedBubble = getValidBubble(
    bubbles.disbursementProjectionFrom,
    bubbles.disbursementProjectionTo,
    label,
    'dolar_sign',
    'dolar_sign',
  );
  return (
    <Bubble
      itsHidden={returnedBubble.itsHidden}
      label={returnedBubble.label}
      value={
        returnedBubble.value === 'between'
          ? t('between') +
            bubbles.disbursementProjectionFrom +
            t('and') +
            bubbles.disbursementProjectionTo +
            t('dolar_sign')
          : returnedBubble.value === 'gt'
          ? t('greater_than') + bubbles.disbursementProjectionFrom + t(returnedBubble.translationSingular)
          : t('less_than') + bubbles.disbursementProjectionTo + t(returnedBubble.translationPlural)
      }
      closeBubble={closeBubble}
    />
  );
};

export default DisbursementProjectionBubbles;
