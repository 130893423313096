import { percentage } from '../../../services/numberUtil';
import { isMobile } from '../../../services/booleanUtil';
import { getDoughnutIndicator } from './reusableIndicators';

export const metricAreZeros = (indicator, removeFromCheck = '') => {
  if (removeFromCheck !== '') {
    const filteredMetrics = indicator.metrics.filter(metric => {
      return metric.metricName !== removeFromCheck;
    });
    indicator = { ...indicator, metrics: filteredMetrics };
  }

  return indicator.metrics.every(metric => metric.metricValue === 0);
};

export enum TaskLabelsEnums {
  TASK_NAME = 'TASK_NAME',
  EXPIRATION_DATE = 'EXPIRATION_DATE',
  PROJECT_CODE = 'PROJECT_CODE',
}

export const findMetricValue = (metrics, metricName) => {
  return metrics.find(metric => metric.metricName === metricName)
    ? metrics.find(metric => metric.metricName === metricName).metricValue
    : null;
};
export const findMetricValueByGrouping = (
  metrics: any[],
  groupingName: string,
  groupingValue: string,
  metricName: string,
) => {
  const foundItem = metrics.find(
    m => m.groupingName === groupingName && m.groupingValue === groupingValue && m.metricName === metricName,
  );
  return foundItem ? foundItem.metricValue : null;
};

export const findMetricLabel = (tasks, key, LabelEnum) => {
  return tasks[key].find(task => task.metricName === LabelEnum).metricLabel;
};
export const setPercentageWeight = (projectsQty: any[]) => {
  const totalProjects = projectsQty.reduce((a, b) => a + b, 0);

  return projectsQty.map(project => (project * 100) / totalProjects);
};

export const getFormattedValue = (metrics: any[], key: string) => {
  const foundItem = metrics.find(i => i.metricName === key && i.metricValue !== undefined);
  return foundItem && foundItem.metricValue ? foundItem.metricValue : 0;
};

export const getIndicatorData = (indicators, name) => {
  const data = indicators.filter(x => x.key === name);

  return data.length > 0 ? data[0] : null;
};

export const getMonthLabels = () => {
  return [
    'january_short',
    'february_short',
    'march_short',
    'april_short',
    'may_short',
    'june_short',
    'july_short',
    'august_short',
    'september_short',
    'october_short',
    'november_short',
    'december_short',
    'january_short',
    'february_short',
    'march_short',
    'april_short',
    'may_short',
    'june_short',
  ];
};

export const setCurrentYear = (monthNumber, currentYear) => {
  if (monthNumber === 12) {
    currentYear++;
  }
  return currentYear;
};

export const metricIsEmpty = indicatorValue => {
  if (!indicatorValue.metrics || indicatorValue.metrics.length === 0) {
    return true;
  } else {
    return false;
  }
};

export const setBarThickness = () => {
  if (isMobile()) {
    return 4;
  } else {
    return 14;
  }
};

export const createArrayOfColours = (color, qty) => {
  const returnedArray: any = [];

  for (let i = 1; i <= qty; i++) {
    returnedArray.push(color);
  }

  return returnedArray;
};

export const createArrayOpacityBlues = () => {
  const returnedArray: any = [];
  let opacityFactor = 1;
  let nextLevel = true;
  let counter = 0;
  let opcaityBrake = '0.4';
  const compareFactor = 4;
  const maxDivisionsValues = 16;
  for (let i = 1; i <= maxDivisionsValues; i++) {
    returnedArray.push(`rgba(65,109,188,${opacityFactor.toFixed(1)})`);

    if (opacityFactor.toFixed(1) === opcaityBrake) {
      nextLevel = false;
      counter++;
      if (counter === compareFactor) {
        counter = 0;
        nextLevel = true;
        opcaityBrake = '0.3';
      }
    }

    if (opacityFactor.toFixed(1) === '0.2') {
      nextLevel = false;
      counter++;
      if (counter === 3) {
        counter = 0;
        nextLevel = true;
      }
    }

    if (nextLevel) {
      opacityFactor -= 0.1;
    }
  }

  return returnedArray;
};

export const sortGroupingValue = () => {
  return (a, b) => (parseInt(a.groupingValue) > parseInt(b.groupingValue) ? 1 : -1);
};

export const getBarThickness = (isMobile: boolean) => {
  return isMobile ? 4 : 8;
};

export const substractPercentages = (
  data: any,

  percentageAKey: string,
  percentageBKey: string,
  percentageTotalKey: string,
) => {
  return `${Math.round(
    percentage(getFormattedValue(data, percentageAKey), getFormattedValue(data, percentageTotalKey)) -
    percentage(getFormattedValue(data, percentageBKey), getFormattedValue(data, percentageTotalKey)),
  ).toString()}%`;
};

export const getGenericDoughnutIndicator = (indicator, type, customInfo) => {
  const { title, detailTitle, dataLabel1, dataLabel2, dataLabel3, dataLabel4 } = customInfo;

  if (!indicator) {
    return null;
  } else if (metricIsEmpty(indicator) || metricAreZeros(indicator)) {
    return {
      id: indicator.id,
      indicatorKey: indicator.key,
      type,
      title,
      detailTitle,
      data: null,
      order: indicator.order,
    };
  } else {
    const satisfactoryProjectsQty = findMetricValue(indicator.metrics, 'SATISFACTORY_OPER_CNT');
    const alertProjectsQty = findMetricValue(indicator.metrics, 'ALERT_OPER_CNT');
    const problemProjectsQty = findMetricValue(indicator.metrics, 'PROBLEM_OPER_CNT');
    const naProjectsQty = findMetricValue(indicator.metrics, 'NA_OPER_CNT');

    const projectQuantity: any = [];
    projectQuantity.push(satisfactoryProjectsQty, alertProjectsQty, problemProjectsQty, naProjectsQty);

    const dataLabelsTranslations = [dataLabel1, dataLabel2, dataLabel3, dataLabel4];
    const dataLabelsClasses = ['satisfactory', 'alert', 'problem', 'not-assigned'];
    const backgroundColor = ['#91e2a1', '#f7dd00', '#f06d6d', '#D0DEF8'];
    const hoverBackgroundColor = ['#91e2a1', '#f7dd00', '#f06d6d', '#D0DEF8'];
    const conceptTranslation = { singular: 'project', plural: 'menu_projects' };

    const options = {
      projectQuantity,
      dataLabelsTranslations,
      dataLabelsClasses,
      backgroundColor,
      hoverBackgroundColor,
      conceptTranslation,
      title,
      detailTitle,
      type,
    };

    return getDoughnutIndicator(indicator, options);
  }
};

export const earlyWarningsIndicatorKeys = {
  PROJECTS_LAST_DISBURSEMENT_DATE_CLOSE_12MONTHS_OR_EXPIRED: true,
  PROJECTS_PENDING_SIGNATURE_OR_LEGISLATIVE_APPROVAL: true,
  PMR_ALERT_PROBLEM_LAST_3_YEARS: true,
  PROJECTS_ELIGIBLE_FIRST_UNDISBURSEMENT: true,
  PROJECTS_PARTIAL_CANCELLATIONS: true,
  CLASSIFICATION_OF_ACQUISITION_PLANS: false,
  ESG_SAFEGUARD_PERFORMANCE_RATING: true,
};
