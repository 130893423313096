import React, { useEffect, useState } from 'react';
import './ManagerialPanel.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SelectFilters } from '../../Util/SelectFilters/SelectFilters';
import {
  getOptionsCountriesRelatedWithTabs,
  getSelectedTabRegionInfo,
  handleFilterCountry,
  setOptionCountries,
} from './Util';
import { ManagerialReviews } from '../ManagerialReviews/ManagerialReviews';
import { getReviews } from '../../../api/reviewsApi';
import moment from 'moment';
import { menuTabValuesEnum } from '../../../models/MenuTabValuesEnum';
import { isMobile } from '../../../services/booleanUtil';
import { sortNumbers } from '../../../services/sortsUtil';
import { AppMenuTabs, MenuTabs, MenuTabSectionEnum } from '../../MenuTabs/userTabsInterface';
import { updateSelectedTab } from '../../../redux/actions/menuTabs';
import { RegionsAcronymEnum } from '../../../models/RegionsAcronymEnum';
import Region from '../../../models/Region';
import { ManagerialMenuTabs } from './ManagerialMenuTabs/ManagerialMenuTabs';
import { setIsDelegate } from '../../../redux/actions/reviewsDelegate';

export const ManagerialPanel = ({ handleOnClick, visibleComponent }) => {
  //HOOKS
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const regionState = useSelector<any, any>(state => state.regions);
  const { managerialReviewSelectedTab } = useSelector<any, AppMenuTabs>(state => state.menuTabs);
  const user = useSelector<any, any>(state => state.user);
  const [searchedYear, setSearchedYear] = useState(moment().year());
  const [optionCountriesState, setOptionCountriesState] = useState(
    getOptionsCountriesRelatedWithTabs(parseMenuTabToEnum(regionState, managerialReviewSelectedTab), regionState),
  );
  const [optionsYearsState, setOptionsYearsState] = useState<any[]>();
  const [countryFilterSelectEnabled, setCountryFilterSelectEnabled] = useState(
    setOptionCountries(parseMenuTabToEnum(regionState, managerialReviewSelectedTab), regionState),
  );
  const [portfolioRevisionState, setPortfolioRevisionState] = useState<any[]>();
  const [portfolioCompleteRevisionState, setPortfolioCompleteRevisionState] = useState<any[]>();
  const [countryFiltered, setCountryFiltered] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setCountryFiltered(0);
    setSearchedYear(moment().year());
  };


  const getReviewsOfCurrentYearByRegion = async (year, selectedTabEnumType) => {
    const { revisionYears, portfolioRevisions } = await getReviews(
      year,
      getSelectedTabRegionInfo(selectedTabEnumType.acronym, regionState),
    );
    const withoutFirst = listWithoutFirstRevision(portfolioRevisions);
    const revisionYearsWithCurrent = updateListWithCurrentYear(revisionYears);
    checkNextYear(revisionYearsWithCurrent, year, 'all', withoutFirst);
  };

  useEffect(() => {

    const selectedTabEnumType = parseMenuTabToEnum(regionState, managerialReviewSelectedTab);
    handleApiCallOnTabChange(
      selectedTabEnumType,
      getReviewsOfCurrentYearByCountry,
      getReviewsOfCurrentYearByRegion,
      searchedYear,
      user,
    );
    setCountryFilterSelectEnabled(
      setOptionCountries(parseMenuTabToEnum(regionState, managerialReviewSelectedTab), regionState),
    );
    setOptionCountriesState(
      getOptionsCountriesRelatedWithTabs(parseMenuTabToEnum(regionState, managerialReviewSelectedTab), regionState),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managerialReviewSelectedTab]);

  const getReviewsOfCurrentYearByCountry = async (year, country) => {
    const { currentUserCanEditRevision, revisionYears, portfolioRevisions } = await getReviews(year, 'all', country);
    const withoutFirst = listWithoutFirstRevision(portfolioRevisions);
    const filteredByYear = withoutFirst.filter(p =>
      p.revisions.some(rev => rev.startDate && rev.startDate.split('-')[0] === year),
    );
    const revisionYearsWithCurrent = updateListWithCurrentYear(revisionYears);
    checkNextYear(revisionYearsWithCurrent, year, country, filteredByYear);
    dispatch(setIsDelegate(currentUserCanEditRevision))
  };


  const checkNextYear = async (revisionYears, year, country, portfolioRevisions, regions = 'all') => {
    const haveNextYear = revisionYears.findIndex(returnedYear => returnedYear === year + 1);

    if (haveNextYear === 1 || haveNextYear === 0) {
      const { revisionYears, portfolioRevisions } =
        regions !== 'all' ? await getReviews(year, regions) : await getReviews(year + 1, 'all', country);
      setSearchedYear(year + 1);
      const withoutFirst = listWithoutFirstRevision(portfolioRevisions);
      setPortfolioCompleteRevisionState(withoutFirst);
      setPortfolioRevisionState(withoutFirst);
      handleYearSelectOptions(revisionYears, searchedYear, setOptionsYearsState);
    } else {
      setPortfolioCompleteRevisionState(portfolioRevisions);
      setPortfolioRevisionState(portfolioRevisions);
      handleYearSelectOptions(revisionYears, searchedYear, setOptionsYearsState);
    }
  };

  const handleSetMenuValue = (tab: MenuTabs) => {
    dispatch(updateSelectedTab(tab, MenuTabSectionEnum.MANAGERIAL_REVIEW_TAB));
  };

  const handleCountriesFilterChange = selectedValue => {
    const selectedTabEnumType = parseMenuTabToEnum(regionState, managerialReviewSelectedTab);

    if (parseInt(selectedValue) === 0) {
      setCountryFiltered(0);
      getReviewsOfCurrentYearByRegion(searchedYear, selectedTabEnumType);
    } else {
      setCountryFiltered(parseInt(selectedValue));
      const countryFilter = handleFilterCountry(portfolioCompleteRevisionState, selectedValue);
      const filterIfNoRevisions = countryFilter.every(a => a.revisions.length === 0) ? [] : countryFilter;
      setPortfolioRevisionState(filterIfNoRevisions);
    }
  };

  const handleYearsFilterChange = selectedValue => {
    const selectedTabEnumType = parseMenuTabToEnum(regionState, managerialReviewSelectedTab);
    handleApiCallOnFilterYearChange(
      selectedTabEnumType,
      countryFiltered,
      getReviewsOfCurrentYearByCountry,
      getReviewsOfCurrentYearByRegion,
      selectedValue,
      setSearchedYear,
      user,
    );
  };

  return (
    <div className="managerial-panel-container">

      <div className="top-header">
        <ManagerialMenuTabs handleChange={handleChange} handleSetMenuValue={handleSetMenuValue} />
      </div>
      {isMobile() && (
        <div className="title-portfolio">
          {t('portfolio_reviews')}{' '}
          {setSelectedTabTitle(parseMenuTabToEnum(regionState, managerialReviewSelectedTab), user, t)}
        </div>
      )}
      <div className="filters">
        <SelectFilters
          labelValue={t('country')}
          options={optionCountriesState}
          disabled={countryFilterSelectEnabled}
          handleChange={handleCountriesFilterChange}
          selectValue={countryFiltered}
        />
        <SelectFilters
          labelValue={t('year')}
          options={optionsYearsState}
          handleChange={handleYearsFilterChange}
          showAllOption={false}
          selectValue={searchedYear}
        />
      </div>

      <ManagerialReviews
        revisions={portfolioRevisionState}
        year={searchedYear}
        selectedCountry={optionCountriesState.find(a => a.value === countryFiltered)}
        handleOnClick={handleOnClick}
        visibleComponent={visibleComponent}
      />
    </div>
  );
};


const handleApiCallOnTabChange = (
  selectedTabEnumType,
  getReviewsOfCurrentYearByCountry,
  getReviewsOfCurrentYearByRegion,
  searchedYear,
  user,
) => {
  selectedTabEnumType === menuTabValuesEnum.country
    ? getReviewsOfCurrentYearByCountry(searchedYear, user.country.id)
    : getReviewsOfCurrentYearByRegion(searchedYear, selectedTabEnumType);
};

const handleApiCallOnFilterYearChange = (
  selectedTabEnumType,
  countryFiltered,
  getReviewsOfCurrentYearByCountry,
  getReviewsOfCurrentYearByRegion,
  selectedValue,
  setSearchedYear,
  user,
) => {
  if (countryFiltered === 0 && selectedTabEnumType === menuTabValuesEnum.country) {
    getReviewsOfCurrentYearByCountry(selectedValue, user.country.id);
  } else if (countryFiltered !== 0) {
    getReviewsOfCurrentYearByCountry(selectedValue, countryFiltered);
  } else {
    getReviewsOfCurrentYearByRegion(selectedValue, selectedTabEnumType);
  }

  setSearchedYear(parseInt(selectedValue));
};

const handleYearSelectOptions = (revisionYears, searchedYear, setOptionsYearsState) => {
  const returnedOptions: any[] = [];
  revisionYears.forEach(year => {
    returnedOptions.push({ value: year, optionLabel: year, selected: searchedYear === year ? true : false });
  });
  returnedOptions.sort(sortNumbers('value', 'desc'));
  setOptionsYearsState(returnedOptions);
};

const setSelectedTabTitle = (selectedTab, user, t) => {
  if (selectedTab === menuTabValuesEnum.country) {
    return user.country.name;
  } else if (selectedTab === menuTabValuesEnum.global_bid) {
    return t(menuTabValuesEnum.global_bid);
  } else {
    return selectedTab;
  }
};

const parseMenuTabToEnum = (regionState: Region[], managerialReviewSelectedTab: MenuTabs) => {
  const region = regionState.find(region => region.name === RegionsAcronymEnum[managerialReviewSelectedTab.title]);
  if (region !== undefined) {
    return region;
  } else if (managerialReviewSelectedTab.internalId === '0_global_bid') {
    return menuTabValuesEnum.global_bid;
  } else {
    return menuTabValuesEnum.country;
  }
};

const updateListWithCurrentYear = (list: number[]) => {
  const currentYear = new Date().getFullYear();
  if (!list.includes(currentYear)) {
    return [...list, currentYear];
  }
  return [...list];
};

const listWithoutFirstRevision = (list: any[]) => {
  return list.map(rev => {
    rev.revisions = rev.revisions.filter(r => !r.firstRevision);
    return rev;
  });
};
