import {
  CurrentProjectActionTypes,
  SET_CURRENT_PROJECT,
  UPDATE_CURRENT_PROJECT,
  RESET_CURRENT_PROJECT,
} from '../actions/types';
import Project from '../../models/Project';
import { PmrClassification } from '../../models/PmrClassification';
import { RolesIdEnum } from '../../models/RolesIdEnum';
import {
  activeLaneStyle,
  boardStyle,
  horizontalLane,
  laneStyle,
} from '../../components/ProjectDetail/ProjectTabs/Accordances/AccordanceTrello/Styles';
import { cloneDeep } from 'lodash';

const initialProject: Project = {
  id: 0,
  name: '',
  code: '',
  loanNumber: 0,
  division: { id: 0, name: '', code: '' },
  financialInformation: {
    lastDisbursementInDays: 0,
    totalDisbursementAmount: 0,
    realizedDisbursementAmount: 0,
    percentRealizedDisbursement: 0,
    pendingDisbursementAmount: 0,
    percentPendigDisbursementAmount: 0,
    lastDisbursementDate: '2020-01-01T19:58:58.245Z',
    nextDisbursementDate: '2020-03-01T19:58:58.245Z',
    currentBid: 0,
    currentLocal: 0,
    contractualBid: 0,
    contractualLocal: 0,
    minimumAmountToJustify: 0,
    minimumPercentToJustify: 0,
    pariPassuDeviationAmount: 0,
    pariPassuDeviationPercent: 0,
  },
  pmrClassification: PmrClassification.Red,
  pmrValue: 2,
  temporaryAdvance: {
    totalProjectDuration: 0,
    temporaryAdvanceMade: 0,
    percentTemporaryAdvanceMade: 0,
    pendingAdvance: 0,
    percentPendingAdvance: 0,
  },
  inFollow: false,
  assignedToMe: false,
  esgClasification: '',
  userRoleInProject: RolesIdEnum.Chief_of_Operations,
  selectedYear: new Date().getFullYear().toString(),
  selectedType: '',
  boardStyle,
  operativeAlerts: [],
  accordancesData: {
    lanes: [
      {
        key: 0,
        id: '0',
        title: 'draft_accordance',
        cards: [],
        style: laneStyle,
      },
      {
        key: 1,
        id: '1',
        title: 'pending_accordance',
        cards: [],
        style: laneStyle,
      },
      {
        key: 2,
        id: '2',
        title: 'active_accordance',
        cards: [],
        style: activeLaneStyle,
      },
      {
        key: 3,
        id: '3',
        title: 'accomplished_accordance',
        cards: [],
        style: activeLaneStyle,
      },
      {
        key: 4,
        id: '4',
        title: 'cancelled_accordance',
        cards: [],
        style: horizontalLane,
      },
    ],
  },
};

const initialState = initialProject;

const currentProjectReducer = (state = initialState, action: CurrentProjectActionTypes): Project => {
  switch (action.type) {
    case SET_CURRENT_PROJECT:
      return action.payload;
    case UPDATE_CURRENT_PROJECT:
      return { ...state, ...action.payload };
    case RESET_CURRENT_PROJECT:
      return cloneDeep(initialProject);
    default:
      return state;
  }
};

export default currentProjectReducer;
