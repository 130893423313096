import { AnalyticsKeyEnum } from '../../../../models/AnalyticsKeyEnum';
import { findMetricValue, getIndicatorData } from '../../../Portfolio/IndicatorsHelpers/indicatorHelperUtil';

const addPortfolioIndicatorGeneratedReports = (analyticList, analyticIndicators) => {
  const portfolioIndicatorGeneratedReports = getIndicatorData(
    analyticList,
    AnalyticsKeyEnum.PORTFOLIO_INDICATORS_GENERATED_REPORTS,
  );

  if (portfolioIndicatorGeneratedReports) {
    const firstMetricValue = findMetricValue(portfolioIndicatorGeneratedReports.metrics, 'NON_CUSTOM');
    const secondMetricValue = findMetricValue(portfolioIndicatorGeneratedReports.metrics, 'CUSTOM');
    getDoubleTextIndicator(
      analyticIndicators,
      portfolioIndicatorGeneratedReports,
      'generated_reports',
      'predefined_reports',
      'customizable_reports',
      firstMetricValue,
      secondMetricValue,
    );
  }
};

const addProjectIndicatorGeneratedReports = (analyticList, analyticIndicators) => {
  const projectIndicatorGeneratedReports = getIndicatorData(
    analyticList,
    AnalyticsKeyEnum.PROJECT_INDICATORS_GENERATED_REPORTS,
  );

  if (projectIndicatorGeneratedReports) {
    const firstMetricValue = findMetricValue(projectIndicatorGeneratedReports.metrics, 'GENERATE_REPORT');
    const secondMetricValue = findMetricValue(projectIndicatorGeneratedReports.metrics, 'MODAL_SECTIONS');
    getDoubleTextIndicator(
      analyticIndicators,
      projectIndicatorGeneratedReports,
      'generated_reports',
      'click_on_generate_report',
      'generated_downloaded_sent_reports',
      firstMetricValue,
      secondMetricValue,
    );
  }
};

const addProjectIndicatorAgreementsNumbers = (analyticList, analyticIndicators) => {
  const projectIndicatorAgreementsNumbers = getIndicatorData(
    analyticList,
    AnalyticsKeyEnum.PROJECT_INDICATORS_AGREEMENTS_NUMBERS,
  );

  if (projectIndicatorAgreementsNumbers) {
    const firstMetricValue = findMetricValue(projectIndicatorAgreementsNumbers.metrics, 'NEW_ACCORDANCE');
    const secondMetricValue = findMetricValue(projectIndicatorAgreementsNumbers.metrics, 'CREATE_ACCORDANCE');
    getDoubleTextIndicator(
      analyticIndicators,
      projectIndicatorAgreementsNumbers,
      'accordances',
      'click_on_new_accordance',
      'accordance_created',
      firstMetricValue,
      secondMetricValue,
    );
  }
};

const getDoubleTextIndicator = (
  analyticIndicators,
  indicator,
  title,
  firstText,
  secondText,
  firstMetricValue,
  secondMetricValue,
) => {
  const indicatorData = {
    indicatorKey: indicator.key,
    type: 'doubleText',
    title,
    firstText,
    secondText,
    firstMetricValue,
    secondMetricValue,
  };

  analyticIndicators.push(indicatorData);
};

export {
  addPortfolioIndicatorGeneratedReports,
  addProjectIndicatorGeneratedReports,
  addProjectIndicatorAgreementsNumbers,
};
