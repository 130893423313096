import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { ReactComponent as Arrow } from '../../../assets/icons/u_chevron-down.svg';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReviewsStatusEnum } from '../../../models/ReviewsStatusEnum';
import { replaceMomentFormat } from '../../../services/util';
import { isMobile } from '../../../services/booleanUtil';
import { ReviewsActionLinks } from '../ReviewsActionLinks/ReviewsActionLinks';
import SelectableProjects from '../SelectableProjects/SelectableProjects';
import './PreviousPanel.scss';
import Country from '../../../models/Country';
import { getReviewById, getReviews } from '../../../api/reviewsApi';
import SelectableProjectsResponsive from '../SelectableProjectsResponsive/SelectableProjectsResponsive';
import { useDispatch, useSelector } from 'react-redux';
import { setReviewNavigation } from '../../../redux/actions/reviewNavigation';
import { useFormMemoryHelpContext } from '../../../context/MemoryHelpFormContext';
import { MemoryHelpRevisionStatus } from '../../MemoryHelp/MemoryHelpRevisionStatus';
import { ReactComponent as IconMemoryHelp } from '../../../assets/icons/u_ayudamemoria.svg';
import { ReactComponent as IconArrowRight } from '../../../assets/icons/u_arrow-right.svg';
import ModalIconInfo from '../ReviewWithProjects/MemoryHelpButtons/InfoMemoryHelpsButtons';
import { dateValidation, extractDate } from '../../MemoryHelp/utils';
import { MoonLoader } from 'react-spinners';

type Props = {
  country: Country;
  localRouter: any;
  visibleComponent?: any;
};

export const PreviousPanel = ({ country: { id, name }, localRouter, visibleComponent }: Props) => {
  const { t } = useTranslation();
  const dateFormat = 'DD/MMM/YYYY';
  const [previousReviews, setPreviousReviews] = useState<any[]>([]);
  const [listProjects, setListProjects] = useState<any[]>([]);
  const { reviewNavigation } = useSelector<any, any>(state => state);
  const { panel, isExpanded, year } = reviewNavigation;
  const [loadingProjects, setLoadingProjects] = useState(false);
  const dispatch = useDispatch();

  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [startDateFormatted, setStartDateFormatted] = useState<string | null>(null);
  const [isEqual, setIsEqual] = useState<boolean>(false);
  const [showButton, setShowButton] = useState(false);

  const {
    agreementsData,
    showConfirmContent,
    currentReview,
    userCanEditRevision,
    loading,
    triggerGetFiles,
    setCurrentReview,
    setPreviousView,
    setTrigger,
    setShowConfirmContent,
    refetch,
  } = useFormMemoryHelpContext();

  useEffect(() => {
    setCurrentReview(parseInt(panel));
    setTrigger(prev => !prev)
  }, [])

  const getProjects = useCallback(async reviewId => {
    setLoadingProjects(true);
    const data = await getReviewById(reviewId);
    setLoadingProjects(false);
    setListProjects(data.projects);
  }, []);

  useEffect(() => {
    visibleComponent(false)
  }, [])

  const onClickHandle = () => {

    if (agreementsData.status === MemoryHelpRevisionStatus.Confirm) triggerGetFiles();

    visibleComponent(true);

    if (showConfirmContent) {
      localRouter('memoryHelpViewer');
    } else {
      localRouter('memoryHelpPage');
    }
  };

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    getProjectsIfExpanded(isExpanded, getProjects, panel);
    dispatch(setReviewNavigation({ ...reviewNavigation, panel, isExpanded: setPanelIfExpanded(isExpanded, panel) }));

    const newReview = parseInt(panel, 10);

    // Refetch data from useFormMemoryHelpContext
    if (agreementsData?.status !== MemoryHelpRevisionStatus.Denied) {
      if (currentReview !== newReview) {
        setCurrentReview(newReview);
      }
      refetch();
    } else {
      setCurrentReview(newReview);
    }

    // Actualiza showConfirmContent
    if (agreementsData?.status === MemoryHelpRevisionStatus.Confirm) {
      setShowConfirmContent(true);
    } else {
      setShowConfirmContent(false);
    }
  };

  const getReviewByCurrentYearAndCountry = async (year, country) => {
    const { portfolioRevisions } = await getReviews(year, 'all', country);
    const createdPreviousReviews: any[] = portfolioRevisions.filter(reviews =>
      reviews.revisions.find(review => review.status === ReviewsStatusEnum.Ended),
    );

    if (createdPreviousReviews.length > 0) {
      setPreviousReviews(sortReviewsDesc(createdPreviousReviews));
    }
  };

  // Update local state when agreementsData is available
  useEffect(() => {
    if (agreementsData) {
      if (agreementsData.status === MemoryHelpRevisionStatus.Denied) {
        setIsDisabled(true);
        setShowButton(false);
      } else {
        setIsDisabled(false);
      }

      if (agreementsData.dataRevision) {
        const formattedDate = extractDate(agreementsData.dataRevision.startDate);
        setStartDateFormatted(formattedDate);

        const currentDay = new Date().toLocaleDateString('en-GB');
        if (formattedDate) {
          const isDateEqual = dateValidation(currentDay, formattedDate);
          setIsEqual(isDateEqual);
        }
      } else {
        setStartDateFormatted(null);
        setIsEqual(false);
      }
    }
  }, [agreementsData]);

  // Update showButton state based on isEqual
  useEffect(() => {
    setShowButton(isEqual);
  }, [isEqual]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setPreviousView(true), []);

  useEffect(() => {
    if (agreementsData && agreementsData.status === MemoryHelpRevisionStatus.Confirm) {
      setShowConfirmContent(true);
    } else {
      setShowConfirmContent(false);
    }
  }, [agreementsData, showConfirmContent, setShowConfirmContent]);

  useEffect(() => {
    getReviewByCurrentYearAndCountry(year, id);
    getProjectsIfExpanded(isExpanded, getProjects, panel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="previous-panel-container">
      <div className="title">
        <h1>
          {t('portfolio_reviews')}
          {setLineBreak()}
          <span className="portfolio-name">{name}</span>
        </h1>
      </div>
      <div className="reviews-container">
        {hasPreviousReviews(previousReviews) ? (
          previousReviews.map(revision => (
            <div className="review-list-container" key={revision.id}>
              <Accordion expanded={isExpanded === revision.id} onChange={handleChange(revision.id)} elevation={0}>
                <AccordionSummary
                  expandIcon={<Arrow />}
                  aria-controls="content"
                  id="header"
                  disabled={!revision.hasProjects}
                >
                  {renderAccordionSummary(isExpanded, revision, setSearchedYear(revision), dateFormat, t)}
                  {renderReviewsActionLinks(isExpanded, revision)}
                </AccordionSummary>
                <div className="btn-view-memory">
                  {showButton && !loading
                    ? (<button className='btn btn-primary' onClick={onClickHandle} disabled={isDisabled}>
                      <IconMemoryHelp />
                      {showButton && showConfirmContent && userCanEditRevision
                        ? t('memory_help.page_reviews.btn.see')
                        : t('memory_help.page_reviews.btn.edit')}
                      <IconArrowRight />
                    </button>)
                    : (<div>
                      <p className='date-text'>
                        {
                          isDisabled || loading
                            ? ""
                            : <>
                              {t('memory_help.button_memory_help_from')} <span>{startDateFormatted}</span> <ModalIconInfo />
                            </>
                        }
                      </p>
                    </div>
                    )}
                </div>
                <AccordionDetails className={'accordion'}>
                  {revision.hasProjects ? (
                    !loadingProjects ? (
                      isMobile() ? (
                        <SelectableProjectsResponsive
                          projects={{ list: listProjects }}
                          canCreateAccordance={revision.canCreateAccordance}
                        />
                      ) : (
                        <SelectableProjects
                          projects={{ list: listProjects }}
                          withComments={true}
                          canCreateAccordance={revision.canCreateAccordance}
                          revision={revision}
                        />
                      )
                    ) : (
                      <div className="loading-table positionSpinner">
                        <MoonLoader size={30} color={'#417ffe'} loading={true} speedMultiplier={1} />
                        <p>{t('loading_message')}</p>
                      </div>
                    )
                  ) : null}
                </AccordionDetails>
              </Accordion>
            </div>
          ))
        ) : (
          <div className="no-previous-reviews">
            <span className="title">
              {`${t('revision_number_1')} `}
              {t('review')} {year}
            </span>
            <span className="no-date-recorded">{` - ${t('no_date_recorded')} -`}</span>
          </div>
        )}
      </div>
    </div>
  );
};

const renderDatesData = (revision, dateFormat, t) => {
  if (revision.startDate === null && revision.endDate === null) {
    return <span className="no-date-recorded">{`- ${t('no_date_recorded')} -`}</span>;
  } else {
    return (
      <span className="date-recorded">{` ${revision.status === ReviewsStatusEnum.Attempt ? t('attempt_date') : t('date')
        }: 
   ${replaceMomentFormat(moment(revision.startDate).format(dateFormat))} 
   ${replaceMomentFormat(moment(revision.endDate).format(dateFormat))}
`}</span>
    );
  }
};

const setLineBreak = () => {
  return isMobile() && <br />;
};

const renderAccordionSummary = (expanded, revision, searchedYear, dateFormat, t) => {
  return isMobile() ? (
    <div className={`heading  ${expanded === revision.id ? 'expanded' : ''}`}>
      <div className="review-name">
        {`${t('revision_number_'.concat(revision.revisionNumber.toString()))} `}
        {t('review')} {searchedYear}
      </div>
      <div className="date-data">{renderDatesData(revision, dateFormat, t)}</div>
      <ReviewsActionLinks review={revision} />
    </div>
  ) : (
    <Typography className={`heading  ${expanded === revision.id ? 'expanded' : ''}`}>
      {`${t('revision_number_'.concat(revision.revisionNumber.toString()))} `}
      {t('review')} {searchedYear}
      {renderDatesData(revision, dateFormat, t)}
    </Typography>
  );
};

const renderReviewsActionLinks = (expanded, revision) => {
  return !isMobile() && expanded === revision.id && <ReviewsActionLinks review={revision} />;
};

const flattenRevisions = createdPreviousReviews => {
  const result: any[] = [];
  for (const rev of createdPreviousReviews) {
    for (const r of rev.revisions) {
      if (!r.firstRevision) {
        result.push(r);
      }
    }
  }
  return result;
};

const sortReviewsDesc = createdPreviousReviews => {
  const flattenedRevisions = flattenRevisions(createdPreviousReviews);

  return flattenedRevisions.sort((a, b) => {
    if (moment(a.startDate) > moment(b.startDate)) {
      return -1;
    } else if (moment(a.startDate) < moment(b.startDate)) {
      return 1;
    } else {
      return 0;
    }
  });
};

const getProjectsIfExpanded = (isExpanded: boolean, getProjects: Function, panel: any) => {
  if (isExpanded) {
    getProjects(panel);
  }
};

const setPanelIfExpanded = (isExpanded: boolean, panel: any) => (isExpanded ? panel : false);

const setSearchedYear = (revision: any) => (revision.startDate ? revision.startDate.split('-')[0] : '');

const hasPreviousReviews = (previousReviews: any[]) => previousReviews && previousReviews.length > 0;
