import { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getCategories, newAccordance, updateAccordance, draftToApproval, getAccordanceType, getRootCauseCategories } from '../api/accordanceApi';
import { setDefaultValues } from '../components/ProjectDetail/ProjectTabs/Accordances/AccordanceCard/AccordanceCard';
import { getCalendarEvents } from '../redux/actions/calendar';
import { trackActionClicked } from '../api/trackActionClickedApi';
import { TrackedActions } from '../models/TrackedActionsEnum';
import { getSemesters } from '../components/ProjectDetail/ProjectTabs/Accordances/NewAccordanceForm/DateSelector';
import {
  validInputs,
  noErrors,
  updatedFormErrors,
  createCriticalIssuesList,
  createPossibleActionsList,
  revisionDateString,
  expirationDateString,
  generateRevisionDate,
  setCategoryName,
  accordanceDateString,
} from '../components/ProjectDetail/ProjectTabs/Accordances/NewAccordanceForm/NewAccordanceFormUtil';
import { AccordanceType } from '../models/AccordanceType';
import SelectOption from '../models/SelectOption';
import { getSupervisionInfo } from '../api/supervisionApi';
import { SortCriteria } from '../components/ProjectDetail/ProjectTabs/Supervision/util';
import { ProjectProductTypes } from '../models/ProjectProductTypes';
import { NewAccordanceContext } from '../components/Util/Modals/NewAccordance/context/NewAccordanceContext';
import { emptyBundleAccordance } from './useMultiAccordanceFormState';

const initialErrors = {
  criticalIssue: false,
  responsible: false,
  revisionDate: false,
  expirationDate: false,
  possibleAction: false,
  // semesterOption: false,
  accordanceDate: false,
  definedAgreement: false,
  linkedProductsAccordance: false,
  rootCauseOfCritical: false,
  rootCause: false,
};

const useFormState = props => {
  const dispatch = useDispatch();
  const { accordanceFormType, displayGenericErrorsMessage, editAccordanceData, review } = props;
  const [criticalIssue, setCriticalIssue] = useState<any>(null);
  const [responsible, setResponsible] = useState<any>(null);
  const [revisionDate, setRevisionDate] = useState<any>(null);
  const [expirationDate, setExpirationDate] = useState<any>(null);
  const [category, setCategory] = useState<any>(null);
  const [possibleAction, setPossibleAction] = useState<any>(null);
  const [criticalIssueList, setCriticalIssuelist] = useState([]);
  const [possibleActionsList, setPossibleActionsList] = useState<any[]>([]);
  const [formErrors, setFormErrors] = useState<any>({ ...initialErrors });
  const [submitting, setSubmitting] = useState<any>(false);
  const [accordanceFiles, setAccordanceFiles] = useState<any[]>([]);
  const [description, setDescription] = useState('');
  const [criticalIssueDescription, setCriticalIssueDescription] = useState<any>('');
  const [semesterOption, setSemesterOption] = useState<any>(null);
  const [disableDraftButton, setDisableDraftButton] = useState(true);
  const [supervisionActionList, setSupervisionActionList] = useState<any[]>([]);
  const [supervisionAction, setSupervisionAction] = useState<any>(null);
  const [accordanceDate, setAccordanceDate] = useState<any>(null);
  const [bundleAccordances, setBundleAccordances] = useState<any[]>([]);
  
  const [definedAgreement, setDefinedAgreement] = useState<any>(null);
  const [linkedProductsAccordance, setLinkedProductsAccordance] = useState<any>(null);
  const [rootCauseOfCritical, setRootCauseOfCritical] = useState<any>(null);
  const [rootCause, setRootCause] = useState<any>(null);

  const [accordanceType, setAccordanceType] = useState<any>([])
  const [rootCauseCategories, setRootCauseCategories] = useState<any>([])
  const [rootCauseForm, setRootCauseForm] = useState<any>([])
  const [productForm, setProductForm] = useState<any>([])

  const { t } = useTranslation();

  const editAccordanceInfo = setDefaultValues(props.editAccordanceData, t);

  const { setState, state } = useContext(NewAccordanceContext);

  useEffect(() => {
    if (noErrors(formErrors)) {
      displayGenericErrorsMessage(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrors]);

  const displayErrors = () => {
    const newFormErrors = updatedFormErrors(
      criticalIssue,
      responsible,
      expirationDate,
      // possibleAction,
      revisionDate,
      props.accordanceFormType,
      // semesterOption,
      // accordanceDate,
      definedAgreement,
      linkedProductsAccordance,
      rootCauseOfCritical,
      rootCause,
      criticalIssueDescription,
      description
    );

    setFormErrors(newFormErrors);
    displayGenericErrorsMessage(true);
  };

  const closeAndRefreshData = () => {
    setSubmitting(false);
    props.close({ refreshData: true, isDraft: false });
  };

  const submitAccordance = async (publish = false) => {
    const linkedProductData = linkedProductsAccordance?.map(element => {
      return { 
        id: null,
        name: null,
        projectId: null,
        componentOrder: null,
        productOrder: null,
        keyProductId: element.keyProductId || element.id}
      })
      
    const data = {
      projectId: props.currentProject.id,
      // revisionDate:
      //   accordanceFormType === definedAgreement.id
      //     ? revisionDateString(revisionDate)
      //     : semesterOption
      //     ? semesterOption.value
      //     : null,
      revisionDate: revisionDateString(revisionDate),
      expirationDate: expirationDate ? expirationDateString(expirationDate) : null,
      accordanceType: definedAgreement.id,
      criticalIssueId: criticalIssue.id,
      personInChargeId: responsible.id,
      possibleActionId: possibleAction?.id ? possibleAction.id : null ,
      files: accordanceFiles,
      revisionId: review ? review.id : undefined,
      description,
      draft: false,
      criticalIssueDescription,
      supervisionActionId: supervisionAction && supervisionAction.id,
      accordanceDate: accordanceDate ? accordanceDateString(accordanceDate) : null,

      rootCauseCategoryId: rootCauseOfCritical?.id,
      rootCauseId: rootCause?.id,
      linkedProductsAccordance: linkedProductData === undefined ? [] : linkedProductData
    };

    setSubmitting(true);
    try {
      if (editAccordanceData && !publish) {
        await updateAccordance({ ...data, accordanceId: editAccordanceData.id });
        closeAndRefreshData();
      } else if (editAccordanceData && publish === true) {
        await updateAccordance({ ...data, accordanceId: editAccordanceData.id });
        await draftToApproval(editAccordanceData.id);
        closeAndRefreshData();
      } else {
        await newAccordance(data);
        dispatch(getCalendarEvents(props.currentProject.id));
        closeAndRefreshData();
      }
    } catch (error) {
      displayGenericErrorsMessage(true);
      setSubmitting(false);
    }
  };

  const submit = (publish = false) => {
    if (
      validInputs(
        criticalIssue,
        responsible,
        expirationDate,
        // possibleAction,
        revisionDate,
        props.accordanceFormType,
        // semesterOption,
        definedAgreement,
        linkedProductsAccordance,
        rootCauseOfCritical,
        rootCause,
        criticalIssueDescription,
        description
      )
    ) {
      submitAccordance(publish);
      trackActionClicked(TrackedActions.createAccordanceClicked);
    } else {
      displayErrors();
    }
  };

  const submitAccordanceDraft = async () => {

    const linkedProductData = linkedProductsAccordance?.map(element => {
      return { 
       id: null,
       name: null,
       projectId: null,
       componentOrder: null,
       productOrder: null,
       keyProductId: element.keyProductId || element.id}
     })

    const data = {
      projectId: props.currentProject.id,
      revisionDate: revisionDate,
      expirationDate: expirationDate ? expirationDateString(expirationDate) : null,
      accordanceType: definedAgreement?.id,
      criticalIssueId: criticalIssue ? criticalIssue.id : null,
      personInChargeId: responsible ? responsible.id : null,
      possibleActionId: possibleAction ? possibleAction.id : null,
      files: accordanceFiles,
      revisionId: review ? review.id : null,
      description,
      criticalIssueDescription,
      supervisionActionId: supervisionAction && supervisionAction.id,
      draft: true,
      accordanceDate: accordanceDate ? accordanceDateString(accordanceDate) : null,

      rootCauseCategoryId: rootCauseOfCritical?.id,
      rootCauseId: rootCause?.id,
      linkedProductsAccordance: linkedProductData === undefined ? [] : linkedProductData
    };

    try {
      if (editAccordanceData) {
        await updateAccordance({ ...data, accordanceId: editAccordanceData.id });
        setSubmitting(false);
        props.close({ refreshData: true, isDraft: false });
      } else {
        await newAccordance(data);
        dispatch(getCalendarEvents(props.currentProject.id));
        setSubmitting(false);
        props.close({ refreshData: true, isDraft: true });
      }
    } catch (error) {
      displayGenericErrorsMessage(true);
      setSubmitting(false);
      setFormErrors({ ...formErrors, ...{ definedAgreement: true, revisionDate: true } });
    }

  };

  const handleDefinedAgreementChange = async(event: any, item: any) => {
    setFormErrors({ ...formErrors, ...{ definedAgreement: false } });

    if (!item || item.id === '-') {
      setDefinedAgreement(null);
    } else {
      setDefinedAgreement(item);
      
    }
  }

  const validateTextareaCritical = (value: any) => {
    setCriticalIssueDescription(value);
    if (value.length <= 3 || !value) {
      setFormErrors({ ...formErrors, ...{ criticalIssueDescription: true } });
    } else {
      setFormErrors({ ...formErrors, ...{ criticalIssueDescription: false } });
    }
  };

  const validateTextareaDescription = (value: any) => {

    setDescription(value);
    if (value.length <= 3 || !value) {
      setFormErrors({ ...formErrors, ...{ description: true } });
    } else {
      setFormErrors({ ...formErrors, ...{ description: false } });
    }
  };
  
  const handleLinkedProduct = async(event: any, item: any) => {
    setFormErrors({ ...formErrors, ...{ linkedProductsAccordance: false } });

    if (!item || item.id === '-') {
      setLinkedProductsAccordance(null);
    } else {
      setLinkedProductsAccordance(item);
    }

  }

  const handleRootCauseOfCritical = async(event: any, item: any) => {
    setFormErrors({ ...formErrors, ...{ rootCauseOfCritical: false } });
    if (!item || item.id === '-') {
      setRootCauseOfCritical(null);
    } else {
      setRootCauseForm(rootCauseCategories?.find((val) => val.name === item.name).rootCause)
      setRootCauseOfCritical(item);
      setRootCause(null);
    }
  }

  const handleRootCause = async(event: any, item: any) => {
   
      setFormErrors({ ...formErrors, ...{ rootCause: false } });

    
    if (!item || item.id === '-') {
      setRootCause(null);
    } else {
      setRootCause(item);
    }
  }

  const handleCriticalIssueChange = async (event: any, item: any) => {
    const productTypeParam =
      props.currentProject.projectProductType === ProjectProductTypes.CT ? ProjectProductTypes.CT : undefined;
    handleDisableDraftButton(item);
    setFormErrors({ ...formErrors, ...{ criticalIssue: false } });
    const previousCriticalIssue = criticalIssue;

    if (!item || item.id === '-') {
      setCriticalIssue(null);
      setCategory('');
    } else {
      setProductForm(item.keyProducts)
      setLinkedProductsAccordance(null)
      setCriticalIssue(item);
      setCategory(setCategoryName(item));

      const categories = await getCategories(productTypeParam, props.currentProject.execution, props.currentProject.id);
      const category = categories.find(({ id }) => id === item.categoryId);

      const possibleActions = category &&
        category.criticalIssues &&
        item.value && [...category.criticalIssues.find(ci => ci.id === parseInt(item.value)).possibleActions];

      setPossibleActionsList(createPossibleActionsList(possibleActions));
    }

    previousCriticalIssue && setPossibleAction(null);
  };

  const handlePossibleActionChange = (item: SelectOption) => {
    item && item.label === '-' ? setPossibleAction(null) : setPossibleAction(item);
    setFormErrors({ ...formErrors, ...{ possibleAction: false } });
  };

  const handleSupervisionActionChange = (event: any, item: SelectOption) => {
    handleDisableDraftButton(true);
    setSupervisionAction(item);
  };

  const getData = async () => {
    const productTypeParam =
      props.currentProject.projectProductType === ProjectProductTypes.CT ? ProjectProductTypes.CT : undefined;
    const categories = await getCategories(productTypeParam, props.currentProject.execution, props.currentProject.id);

    const accordanceTypeForm = await getAccordanceType();
    setAccordanceType(accordanceTypeForm)
    
    const rootCauseCategories = await getRootCauseCategories();
    setRootCauseCategories(rootCauseCategories)

    const criticalIssuesList = createCriticalIssuesList(categories);
    setCriticalIssuelist(criticalIssuesList);
  };

  const getActivitiesAndCriticalProducts = async () => {
    const info = await getSupervisionInfo(props.currentProject.id, null, '', 1, SortCriteria.Default, false, 200);
    const infoOptions =
      info && info.paginatedItems
        ? info.paginatedItems.collection.map(activity => ({
            id: activity.id,
            label: activity.description,
            value: activity.description,
          }))
        : [];
    setSupervisionActionList(infoOptions || []);
  };

  useEffect(() => {
    getData();
    // setRevisionDate(review ? new Date(review.startDate) : null);
    getActivitiesAndCriticalProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleIemSelected = (selected: any) => {
    handleDisableDraftButton(selected);
    setResponsible(selected);
    setFormErrors({ ...formErrors, ...{ responsible: false } });
  };
  const handleExpirationDate = date => {
    setFormErrors({ ...formErrors, ...{ expirationDate: false } });
    setExpirationDate(date);
  };

  const populateForm = editAccordanceData => {
    const semesters = getSemesters(t);
    // editAccordanceData.accordanceType === AccordanceType.InterGroup
    //   ? setSemesterOption(semesters.find(({ value }) => value === editAccordanceData.revisionDate))
    //   : setRevisionDate(generateRevisionDate(editAccordanceData));

    setRevisionDate(generateRevisionDate(editAccordanceData));

    handleCriticalIssueChange(null, {
      ...editAccordanceInfo.criticalIssue,
      value: editAccordanceInfo.criticalIssue.id.toString(),
      label: editAccordanceInfo.criticalIssue.name,
    });
    handlePossibleActionChange({
      id: editAccordanceInfo.possibleAction.id,
      label: editAccordanceInfo.possibleAction.name,
      value: editAccordanceInfo.possibleAction.id.toString(),
    });
    editAccordanceData.expirationDate && setExpirationDate(new Date(editAccordanceData.expirationDate));
    setDefinedAgreement(editAccordanceData.accordanceAgreementsType || '')
    setLinkedProductsAccordance(editAccordanceData.linkedProductsAccordance || '')
    setDescription(editAccordanceData.description || '');
    setResponsible(editAccordanceData.personInCharge);
    setCriticalIssueDescription(editAccordanceData.criticalIssueDescription || '');
    setRootCauseOfCritical(editAccordanceData.rootCauseCategory || null);
    setRootCause(editAccordanceData.rootCause || null);
    handleSupervisionActionChange(
      null,
      editAccordanceData.supervisionAction && {
        id: editAccordanceData.supervisionAction.id,
        value: editAccordanceData.supervisionAction.description,
        label: editAccordanceData.supervisionAction.description,
      },
    );
  };
  const handleAccordanceDate = date => {
    setFormErrors({ ...formErrors, ...{ accordanceDate: false } });
    setAccordanceDate(date);
  };

  useEffect(() => {
    if (editAccordanceData) {
      getData();
      populateForm(editAccordanceData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editAccordanceData]);

  useEffect(() => {
    revisionDate ||
    expirationDate ||
    semesterOption ||
    description ||
    criticalIssueDescription ||
    accordanceFiles.length
      ? setDisableDraftButton(false)
      : setDisableDraftButton(true);
  }, [revisionDate, expirationDate, semesterOption, description, criticalIssueDescription, accordanceFiles]);

  const handleDisableDraftButton = (item: any) => (item ? setDisableDraftButton(false) : setDisableDraftButton(true));

  const setMultipleAccordances = () => {
    if (
      validInputs(
        criticalIssue,
        responsible,
        expirationDate,
        // possibleAction,
        revisionDate,
        props.accordanceFormType,
        // semesterOption,
        definedAgreement,
        linkedProductsAccordance,
        rootCauseOfCritical,
        rootCause,
        criticalIssueDescription,
        description
      )
    ) {
      const first = {
        internalId: new Date().getTime().toString(),
        projectId: props.currentProject.id,
        revisionDate:
          semesterOption && semesterOption.value 
            ? new Date(semesterOption.value)
            : revisionDate,
        semesterOption,
        criticalIssue,
        criticalIssueDescription,
        expirationDate,
        responsible,
        // possibleAction,
        accordanceFiles,
        definedAgreement,
        linkedProductsAccordance,
        rootCauseOfCritical,
        rootCause,
        files: [...accordanceFiles],
        review,
        description,
        draft: false,
        supervisionAction,
        formErrors: {
          criticalIssue: false,
          responsible: false,
          revisionDate: false,
          expirationDate: false,
          // possibleAction: false,
          // semesterOption: false,
          definedAgreement: false,
          linkedProductsAccordance: false,
          rootCauseOfCritical: false,
          rootCause: false,
          criticalIssueDescription: false,
          description: false,
        },
        category,
      };
      const newAccordance = {
        ...first,
        internalId: (new Date().getTime() + 10).toString(),
        ...emptyBundleAccordance,
      };
      setBundleAccordances([first, newAccordance]);
      setState({
        ...state,
        bundleAccordances: [first, newAccordance],
        accordanceFormType: definedAgreement.id,
        possibleActionsList,
        supervisionActionList,
      });
    } else {
      displayErrors();
    }
  };

  return {
    accordanceFormType,
    revisionDate,
    setFormErrors,
    formErrors,
    setRevisionDate,
    expirationDate,
    setExpirationDate,
    setSemesterOption,
    semesterOption,
    criticalIssueList,
    criticalIssue,
    handleCriticalIssueChange,
    category,
    possibleActionsList,
    possibleAction,
    handlePossibleActionChange,
    handleExpirationDate,
    responsible,
    handleIemSelected,
    accordanceFiles,
    setAccordanceFiles,
    editAccordanceInfo,
    description,
    criticalIssueDescription,
    setCriticalIssueDescription,
    setDescription,
    submit,
    disableDraftButton,
    submitting,
    submitAccordanceDraft,
    supervisionActionList,
    handleSupervisionActionChange,
    supervisionAction,
    accordanceDate,
    handleAccordanceDate,
    bundleAccordances,
    setBundleAccordances,
    setMultipleAccordances,
    definedAgreement,
    linkedProductsAccordance,
    rootCauseOfCritical,
    rootCause,
    handleDefinedAgreementChange,
    handleLinkedProduct,
    handleRootCauseOfCritical,
    handleRootCause,
    accordanceType,
    rootCauseCategories,
    rootCauseForm,
    productForm,
    validateTextareaCritical,
    validateTextareaDescription
  };
};

export default useFormState;
