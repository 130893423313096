import React from 'react';
import './WorkWithChart.scss';
import { PmrPortfolio } from '../PmrPortfolio/PmrPortfolio';
import { useTranslation } from 'react-i18next';
import Project from '../../../models/Project';
import { PmrClassification } from '../../../models/PmrClassification';
import { PortfolioProjectsTable } from '../PortfolioProjectsTable/PortfolioProjectsTable';
import { ProjectCounter } from '../ProjectCounter/ProjectCounter';
import { useSelector, useDispatch } from 'react-redux';
import {
  getProjectsByDivisionPmr,
  getProjectsByDivision,
  getProjectsByDate,
} from '../../../redux/actions/indicatorProjects';
import Division from '../../../models/Division';
import { IndicatorKeyEnum as key } from '../../../models/IndicatorKeyEnum';
import DivisionsTableHeader from './DivisionsTableHeader/DivisionsTableHeader';
import { setIndicatorDetailFilterChart } from '../../../redux/actions/indicatorDetailFilters';
import { handleLoading } from '../../utils';
import { ProjectsListSkeleton } from '../../LoadersSkeletons/ProjectsListSkeleton/ProjectsListSkeleton';

export const getFilteredProjects = (
  d,
  pmrClassification,
  division,
  totalProjects,
  indicatorKey,
  indicatorId,
  completeGroupingValue,
  setLoading,
) => {
  switch (indicatorKey) {
    case key.PMR_PERFORMANCE_LAST_CLOSED_YEAR:
      return handleLoading(
        () => d(getProjectsByDivisionPmr(pmrClassification, division, totalProjects, indicatorId)),
        setLoading,
      );
    case key.PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS_BY_DIVISION:
      return completeGroupingValue !== ''
        ? handleLoading(() => d(getProjectsByDivision(completeGroupingValue, totalProjects, indicatorId)), setLoading)
        : null;
    default:
      return null;
  }
};

export const getFilterProjectsByDate = (d, dataDate, indicatorId) => {
  return d(getProjectsByDate(dataDate.month, dataDate.year, indicatorId));
};

export const getSelectedDivision = (divisionsArray, division, t, indicatorKey = '', completeGroupingValue = '') => {
  const divisionCode = division?.trim();
  const isSelectableDivision = divisionsArray.find(obj => obj.code === divisionCode);
  return isSelectableDivision
    ? isSelectableDivision
    : indicatorKey === key.PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS_BY_DIVISION
      ? {
        id: completeGroupingValue,
        name: divisionCode,
        code: completeGroupingValue,
      }
      : undefined;
};

export const dispatchIndicatorFilterChart = (d, filterIndicators, selectedDivision, division, pmrClassification) => {
  d(
    setIndicatorDetailFilterChart({
      ...filterIndicators.indicatorCharts,
      chartTitle: selectedDivision ? selectedDivision.name : division,
      subTitle: pmrClassification,
    }),
  );
};

interface ReceivedProps {
  indicatorKey?: string;
  pmrClassification: PmrClassification;
  division?: string;
  totalProjects?: number;
  dataDate?: any;
  indicatorId?: number;
  disbursementValue?: number;
  completeGroupingValue?: string;
}
type Props = ReceivedProps;
export const WorkWithChart = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [havePmr, setHavePmr] = React.useState(true);
  const [loading, setLoading] = React.useState<boolean>(false);
  const indicatorProjects = useSelector<any, Project[]>(state => state.indicatorProjects);
  const filterIndicators = useSelector<any, any>(state => state.indicatorDetailFilter);
  const divisionsArray = useSelector<any, Division[]>(state => state.divisions);

  const {
    pmrClassification,
    division,
    totalProjects,
    indicatorKey,
    dataDate,
    indicatorId,
    disbursementValue,
    completeGroupingValue,
  } = props;

  const divisionText = 'division';
  const divisionSelected = divisionsArray
    ? getSelectedDivision(divisionsArray, division, t, indicatorKey, completeGroupingValue)
    : { name: '' };

  React.useEffect(() => {
    if (filterIndicators.indicatorCharts.chartTitle === '' && filterIndicators.indicatorCharts.firstTime) {
      dispatchIndicatorFilterChart(
        dispatch,
        filterIndicators,
        getSelectedDivision(divisionsArray, division, t),
        division,
        pmrClassification,
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (divisionSelected !== undefined) {
      filterIndicators.indicatorCharts.firstTime &&
        dispatchIndicatorFilterChart(
          dispatch,
          filterIndicators,
          getSelectedDivision(divisionsArray, division, t),
          division,
          pmrClassification,
        );
      setHavePmr(true);

      filterIndicators.indicatorCharts.firstTime &&
        getFilteredProjects(
          dispatch,
          pmrClassification,
          divisionSelected.id,
          totalProjects,
          indicatorKey,
          indicatorId,
          completeGroupingValue,
          setLoading,
        );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pmrClassification,
    division,
    dispatch,
    divisionsArray,
    totalProjects,
    indicatorKey,
    dataDate,
    indicatorId,
    filterIndicators.projectTypeFilterOnDetail,
    completeGroupingValue,
  ]);

  return (
    <div className="work-with-chart-container">
      {indicatorProjects.length !== 0 && (
        <>
          {renderTitleDescription(divisionText, indicatorKey, filterIndicators, disbursementValue, t, division)}
          {renderPmrClassification(indicatorKey, filterIndicators, t)}
          <div className="table-container">
            <PortfolioProjectsTable
              haveTcdi={indicatorKey === key.PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS_BY_DIVISION}
              havePmr={havePmr}
              projects={indicatorProjects}
              indicatorKey={indicatorKey}
            />
            <ProjectCounter arrayToCount={indicatorProjects} countProjects={true} />
          </div>
        </>
      )}
    </div>
  );
};

const renderTitleDescription = (divisionText, indicatorKey, filterIndicators, disbursementValue, t, division) => (
  <>
    <div className="title upper">
      <div className="left">{t(divisionText)} </div>
    </div>

    <div className="description">
      <div className="left">{filterIndicators.indicatorCharts.chartTitle} </div>
      {indicatorKey === key.PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS_BY_DIVISION ? (
        <DivisionsTableHeader division={division} />
      ) : null}
    </div>
  </>
);

const renderPmrClassification = (indicatorKey, filterIndicators, t) =>
  indicatorKey === key.PMR_PERFORMANCE_LAST_CLOSED_YEAR ? (
    <>
      <div className="title">{t('pmr_initials')}</div>
      <div className="pmr-classification">
        <PmrPortfolio pmrClassification={filterIndicators.indicatorCharts.subTitle} />
      </div>
    </>
  ) : null;
