import React from 'react';
import './ProjectAgeFilter.scss';
import NumberInput from '../../Util/CustomInput/NumberInput/NumberInput';
import CustomSelect from '../../Util/CustomSelect/CustomSelect';
import { ageList } from '../../../models/AgeList';
import SelectOption from '../../../models/SelectOption';
import { useTranslation } from 'react-i18next';
import RequiredErrorMessageCustomMessage from '../../Util/Errors/RequiredErrorMessageCustomMessage/RequiredErrorMessageCustomMessage';
import { trackActionClicked } from '../../../api/trackActionClickedApi';
import { TrackedActions } from '../../../models/TrackedActionsEnum';
import useFilters from '../../../hooks/useFilters';
import { CalledFrom } from '../../../models/CalledFromEnum';

interface IProps {
  indicatorCall?: boolean;
  calledFrom: CalledFrom;
}
type Props = IProps;

export const setDefaultValueFrom = (
  indicatorCall,
  filter,
  filterIndicators,
  defaultMinValue,
  calledFrom,
  filterEarlyWarnings,
) => {
  if (calledFrom === CalledFrom.EARLY_WARNINGS) {
    return filterEarlyWarnings.common.ageProjectFrom !== defaultMinValue
      ? filterEarlyWarnings.common.ageProjectFrom
      : 0;
  } else {
    if (indicatorCall) {
      return filterIndicators.common.ageProjectFrom !== defaultMinValue ? filterIndicators.common.ageProjectFrom : 0;
    } else {
      return filter.common.ageProjectFrom !== defaultMinValue ? filter.common.ageProjectFrom : 0;
    }
  }
};

export const setDefaultValueTo = (
  indicatorCall,
  filter,
  filterIndicators,
  defaultMaxValue,
  calledFrom,
  filterEarlyWarnings,
) => {
  if (calledFrom === CalledFrom.EARLY_WARNINGS) {
    return filterEarlyWarnings.common.ageProjectTo !== defaultMaxValue ? filterEarlyWarnings.common.ageProjectTo : 0;
  } else {
    if (indicatorCall) {
      return filterIndicators.common.ageProjectTo !== defaultMaxValue ? filterIndicators.common.ageProjectTo : 0;
    } else {
      return filter.common.ageProjectTo !== defaultMaxValue ? filter.common.ageProjectTo : 0;
    }
  }
};

export const getDefaultValue = (indicatorCall, from, to, earlyWarningsFromTo, calledFrom) => {
  if (calledFrom === CalledFrom.EARLY_WARNINGS) {
    return earlyWarningsFromTo;
  }
  return indicatorCall ? from : to;
};

export const setProjectAgeFrom = (indicatorCall, filterIndicators, filter, filterEarlyWarnings, calledFrom) => {
  if (calledFrom === CalledFrom.EARLY_WARNINGS) {
    return filterEarlyWarnings.common.ageProjectFrom;
  } else {
    if (indicatorCall) {
      return filterIndicators.common.ageProjectFrom;
    } else {
      return filter.common.ageProjectFrom;
    }
  }
};

export const setProjectAgeTo = (indicatorCall, filterIndicators, filter, filterEarlyWarnings, calledFrom) => {
  if (calledFrom === CalledFrom.EARLY_WARNINGS) {
    return filterEarlyWarnings.common.ageProjectTo;
  } else {
    if (indicatorCall) {
      return filterIndicators.common.ageProjectTo;
    } else {
      return filter.common.ageProjectTo;
    }
  }
};

export const setProjectAgeRange = (indicatorCall, filterIndicators, filter, filterEarlyWarnings, calledFrom) => {
  if (calledFrom === CalledFrom.EARLY_WARNINGS) {
    return filterEarlyWarnings.common?.ageProjectMonths
      ? { value: 'months', label: 'months', id: 1 }
      : { value: 'years', label: 'years', id: 2 };
  } else {
    if (indicatorCall) {
      return filterIndicators.common?.ageProjectMonths
        ? { value: 'months', label: 'months', id: 1 }
        : { value: 'years', label: 'years', id: 2 };
    } else {
      return filter.common?.ageProjectMonths
        ? { value: 'months', label: 'months', id: 1 }
        : { value: 'years', label: 'years', id: 2 };
    }
  }
};

export const setProjectMonth = selectId => {
  if (selectId === 1) {
    return true;
  } else {
    return false;
  }
};
const ProjectAgeFilter = (props: Props) => {
  const { indicatorCall, calledFrom } = props;
  const {
    filter,
    filterIndicators,
    dispatch,
    setFilter,
    setFilterIndicators,
    filterEarlyWarnings,
    setFilterEarlyWarnings,
  } = useFilters();
  const [errorMessages, handleErrorMessages] = React.useState({ isHidden: true, message: '' });
  const defaultMaxValue = 0;
  const defaultMinValue = 0;
  let ageProjectFrom = setProjectAgeFrom(indicatorCall, filterIndicators, filter, filterEarlyWarnings, calledFrom);
  let ageProjectTo = setProjectAgeTo(indicatorCall, filterIndicators, filter, filterEarlyWarnings, calledFrom);
  const ageProjectRange = setProjectAgeRange(indicatorCall, filterIndicators, filter, filterEarlyWarnings, calledFrom);

  const { t } = useTranslation();

  const handleValue = (min, value) => {
    if (min) {
      ageProjectFrom = +value;
    } else {
      ageProjectTo = +value;
    }
    if (ageProjectFrom >= ageProjectTo && ageProjectTo !== 0) {
      handleErrorMessages({ isHidden: false, message: 'right_input_validation' });

      handleDispatchAgesFromTo({
        calledFrom,
        dispatch,
        setFilterEarlyWarnings,
        filterEarlyWarnings,
        ageProjectFrom,
        ageProjectTo,
        indicatorCall,
        setFilterIndicators,
        filterIndicators,
        setFilter,
        filter,
        trackedAction: false,
      });
    } else {
      handleErrorMessages({ isHidden: true, message: '' });
      if (ageProjectTo === defaultMinValue) {
        ageProjectTo = defaultMaxValue;
      }

      handleDispatchAgesFromTo({
        calledFrom,
        dispatch,
        setFilterEarlyWarnings,
        filterEarlyWarnings,
        ageProjectFrom,
        ageProjectTo,
        indicatorCall,
        setFilterIndicators,
        filterIndicators,
        setFilter,
        filter,
        trackedAction: true,
      });
    }
  };

  const handleSelectedAge = (item: SelectOption) => {
    const ageProjectMonths = setProjectMonth(item.id);
    handleDispatch({
      calledFrom,
      dispatch,
      setFilterEarlyWarnings,
      filterEarlyWarnings,
      ageProjectMonths,
      indicatorCall,
      setFilterIndicators,
      filterIndicators,
      setFilter,
      filter,
    });
  };

  const defaultValueProjectAgeFilterProjectsFrom = setDefaultValueFrom(
    indicatorCall,
    filter,
    filterIndicators,
    defaultMinValue,
    calledFrom,
    filterEarlyWarnings,
  );

  const defaultValueProjectAgeFilterIndicatorsFrom = setDefaultValueFrom(
    indicatorCall,
    filter,
    filterIndicators,
    defaultMinValue,
    calledFrom,
    filterEarlyWarnings,
  );

  const defaultValueProjectAgeFilterEarlyWarningsFrom = setDefaultValueFrom(
    indicatorCall,
    filter,
    filterIndicators,
    defaultMinValue,
    calledFrom,
    filterEarlyWarnings,
  );

  const defaultValueProjectAgeFilterProjectsTo = setDefaultValueTo(
    indicatorCall,
    filter,
    filterIndicators,
    defaultMaxValue,
    calledFrom,
    filterEarlyWarnings,
  );
  const defaultValueProjectAgeFilterIndicatorsTo = setDefaultValueTo(
    indicatorCall,
    filter,
    filterIndicators,
    defaultMaxValue,
    calledFrom,
    filterEarlyWarnings,
  );

  const defaultValueProjectAgeFilterEarlyWarningsTo = setDefaultValueTo(
    indicatorCall,
    filter,
    filterIndicators,
    defaultMaxValue,
    calledFrom,
    filterEarlyWarnings,
  );

  const defaultValueFrom = getDefaultValue(
    indicatorCall,
    defaultValueProjectAgeFilterIndicatorsFrom,
    defaultValueProjectAgeFilterProjectsFrom,
    defaultValueProjectAgeFilterEarlyWarningsFrom,
    calledFrom,
  );
  const defaultValueTo = getDefaultValue(
    indicatorCall,
    defaultValueProjectAgeFilterIndicatorsTo,
    defaultValueProjectAgeFilterProjectsTo,
    defaultValueProjectAgeFilterEarlyWarningsTo,
    calledFrom,
  );

  return renderProjectAgeFilter({
    ageProjectRange,
    defaultValueFrom,
    defaultValueTo,
    errorMessages,
    handleSelectedAge,
    handleValue,
    t,
  });
};

export const renderProjectAgeFilter = ({
  ageProjectRange,
  defaultValueFrom,
  defaultValueTo,
  errorMessages,
  handleSelectedAge,
  handleValue,
  t,
}) => {
  return (
    <div className="project-age-filter-container">
      <div className="input-section">
        <span>{t('between')}</span>
        <NumberInput placeholderText="0" value={handleValue} minParam={true} defaultValue={defaultValueFrom} />
        <span>{t('and')}</span>
        <NumberInput placeholderText="100" value={handleValue} minParam={false} defaultValue={defaultValueTo} />
        <CustomSelect
          options={ageList}
          selectedOption={ageProjectRange}
          handleSelected={handleSelectedAge}
          disabled={false}
          style={{ width: '118px' }}
          heightCustomSelect={{ height: '33px' }}
        />
      </div>
      <div className="validation-section">
        <RequiredErrorMessageCustomMessage isHidden={errorMessages.isHidden} errorMessage={t(errorMessages.message)} />
      </div>
    </div>
  );
};

const handleDispatch = ({
  calledFrom,
  dispatch,
  setFilterEarlyWarnings,
  filterEarlyWarnings,
  ageProjectMonths,
  indicatorCall,
  setFilterIndicators,
  filterIndicators,
  setFilter,
  filter,
}) => {
  if (CalledFrom.EARLY_WARNINGS === calledFrom) {
    dispatch(
      setFilterEarlyWarnings({
        ...filterEarlyWarnings,
        common: {
          ...filterEarlyWarnings.common,
          ageProjectMonths,
        },
      }),
    );
    trackActionClicked(TrackedActions.projectAgeFilterChangedFromEarlyWarnings);
  } else {
    if (indicatorCall) {
      dispatch(
        setFilterIndicators({
          ...filterIndicators,
          common: {
            ...filterIndicators.common,
            ageProjectMonths,
          },
        }),
      );
      trackActionClicked(TrackedActions.projectAgeFilterChangedFromPortfolio);
    } else {
      dispatch(
        setFilter({
          ...filter,
          common: {
            ...filter.common,
            ageProjectMonths,
            itSearch: true,
          },
        }),
      );
      trackActionClicked(TrackedActions.projectAgeFilterChangedFromProject);
    }
  }
};

const handleDispatchAgesFromTo = ({
  calledFrom,
  dispatch,
  setFilterEarlyWarnings,
  filterEarlyWarnings,
  ageProjectFrom,
  ageProjectTo,
  indicatorCall,
  setFilterIndicators,
  filterIndicators,
  setFilter,
  filter,
  trackedAction,
}) => {
  if (CalledFrom.EARLY_WARNINGS === calledFrom) {
    dispatch(
      setFilterEarlyWarnings({
        ...filterEarlyWarnings,
        common: {
          ...filterEarlyWarnings.common,
          ageProjectFrom,
          ageProjectTo,
        },
      }),
    );
    trackedAction && trackActionClicked(TrackedActions.projectAgeFilterChangedFromEarlyWarnings);
  } else {
    if (indicatorCall) {
      dispatch(
        setFilterIndicators({
          ...filterIndicators,
          common: {
            ...filterIndicators.common,
            ageProjectFrom,
            ageProjectTo,
          },
        }),
      );
      trackedAction && trackActionClicked(TrackedActions.projectAgeFilterChangedFromPortfolio);
    } else {
      dispatch(
        setFilter({
          ...filter,
          common: {
            ...filter.common,
            ageProjectFrom,
            ageProjectTo,
            itSearch: true,
          },
        }),
      );
      trackedAction && trackActionClicked(TrackedActions.projectAgeFilterChangedFromProject);
    }
  }
};

export default ProjectAgeFilter;
