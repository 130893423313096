import React from 'react';
import { useTranslation } from 'react-i18next';
import { MultiLine } from '../../../../Portfolio/CardIndicator/Cards/MultiLine/MultiLine';
import {
  BulletsList,
  dataForDetail,
  multiLineOptionsDetail,
} from '../../../../PortfolioDetail/PmrEvolutionLast5Years/PmrEvolutionLast5Years';
import styles from './LargeImage.module.scss';

interface PmrEvolutionData {
  dataList: DataList[];
  largeImageKey: string;
}

interface DataList {
  title: string;
  data: any;
}

type Props = {
  currentIndicator: PmrEvolutionData;
};

const PmrEvolutionLastFiveYears = ({ currentIndicator }: Props) => {
  const { t } = useTranslation();
  const { dataList, largeImageKey } = currentIndicator;
  const {
    flexCenterCenteredColumn,
    pmrEvolutionGraphTitle,
    pmrEvolutionListContainer,
    graphContainer,
    pmrEvolutionBulletsContainer,
  } = styles;

  return (
    <div id={largeImageKey} className={flexCenterCenteredColumn}>
      <div className={pmrEvolutionListContainer}>
        {dataList.map(item => (
          <div className={graphContainer} key={item.title}>
            <h2 className={pmrEvolutionGraphTitle}>{t(item.title)}</h2>
            {/* {item.data && item.data.datasets ? (
              <MultiLine
                data={dataForDetail(item.data, true)}
                customDimensions={{ desktop: { width: 380, height: 240 }, mobile: { width: 300, height: 190 } }}
                options={multiLineOptionsDetail(item.data, true)}
              />
            ) : null} */}
          </div>
        ))}
      </div>
      <div className={pmrEvolutionBulletsContainer}>
        <BulletsList listStyle={{ display: 'flex' }} itemStyle={{ marginRight: 50, marginLeft: 50, fontSize: 16 }} />
      </div>
    </div>
  );
};

export default PmrEvolutionLastFiveYears;
