import React, { useState, useEffect, useRef } from 'react';
import styles from './Supervision.module.scss';
import { useSelector } from 'react-redux';
import { Pagination } from '@material-ui/lab';
import SelectOption from '../../../../models/SelectOption';
import SupervisionSelect from './SupervisionSelect/SupervisionSelect';
import { getYears, getSupervisionInfo } from '../../../../api/supervisionApi';
import { getSelectOptions, getSupervisionPlanMode, SortCriteria, SupervisionPlanMode } from './util';
import EmptySupervisionResult from '../../../Util/EmptySupervisionResult/EmptySupervisionResult';
import HeaderTags from './HeaderTags/HeaderTags';
import TableRow from './TableRow/TableRow';
import TableHeader from './TableHeader/TableHeader';
import SupervisionAccordion from './SupervisionAccordion/SupervisionAccordion';
import { getProjectBaseInformation } from '../../../../api/projectApi';
import SpinningLoader from '../Loader/SpinningLoader';
import SimpleSearchInput from '../../../Util/CustomInput/SimpleSearchInput/SimpleSearchInput';
import PaginationTable from '../../../PortfolioDetail/PaginationTable/PaginationTable';

type Props = {
  projectCode: string;
  projectId: number;
};

const Supervision = ({ projectCode, projectId }: Props) => {
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [selectedOption, setselectedOption] = useState<SelectOption>();
  const [supervisionInfo, setSupervisionInfo] = useState<any>(null);
  const [searchInput, setSearchInput] = useState<any>('');
  const [orderDesc, setOrderDesc] = useState<boolean>(false);
  const [sortCriteria, setSortCriteria] = useState<SortCriteria>(SortCriteria.Default);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const userId = useSelector<any, number>(state => state.user.id);
  const [canEdit, setCanEdit] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const initialRender = useRef(true);

  const getYearsFromApi = async () => {
    const years = await getYears(projectId);
    const yearsOptions = getSelectOptions(years);
    setOptions(yearsOptions);
    setselectedOption(fallbackOption(yearsOptions));
  };

  const loadBidMembers = async () => {
    const response = await getProjectBaseInformation(projectId);
    const projectBidMembers = response.projectTeams.filter(user => isBidMemberHasIdHasAppUser(user));
    setCanEdit(!!projectBidMembers.find(u => u.applicationUserId === userId));
  };

  const getInfoFromApi = async (search = searchInput) => {
    const info = await getSupervisionInfo(projectId, selectedOption, search, currentPage, sortCriteria, orderDesc, rowsPerPage);
    setSupervisionInfo(info);
    ifLoadingSetFalse(isLoading, setIsLoading);
  };

  const handleSearchInput = value => {
    setSearchInput(value.target.value);
    if (lengthZeroOrGreaterThanTwo(value)) {
      getInfoFromApi(value.target.value);
    }
  };

  const handlePaginationChange = (e, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    getYearsFromApi();
    loadBidMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      getInfoFromApi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption, sortCriteria, orderDesc, currentPage, rowsPerPage]);

  const handleSortClick = (criteria: SortCriteria) => {
    if (criteria === sortCriteria) {
      setOrderDesc(!orderDesc);
    }
    setSortCriteria(criteria);
  };

  return (
    <div className={styles.supervisionContainer}>
      {isLoading ? (
        <SpinningLoader />
      ) : (
        <>
          {getSupervisionPlanMode(supervisionInfo) === SupervisionPlanMode.DETAILED && (
            <SupervisionAccordion projectId={projectId} />
          )}
          <div className={styles.supervisionHeader}>
            <div className={styles.supervisionSelect}>
              <SupervisionSelect
                options={options}
                selectedOption={selectedOption}
                setselectedOption={setselectedOption}
              />
            </div>
            {getSupervisionPlanMode(supervisionInfo) === SupervisionPlanMode.DETAILED && (
              <HeaderTags supervisionInfo={supervisionInfo} />
            )}
            <SimpleSearchInput
              handleSearchInput={handleSearchInput}
              inputValue={searchInput}
              customStyle={{ marginTop: '30px' }}
            />
          </div>
          {getSupervisionPlanMode(supervisionInfo) !== SupervisionPlanMode.DETAILED ? (
            <EmptySupervisionResult
              projectCode={projectCode}
              projectId={projectId}
              mode={emptySupervisionResultMode(supervisionInfo)}
            />
          ) : (
            <>
              <div className="borderTable">
                <TableHeader onSortClick={sortCriteria => handleSortClick(sortCriteria)} />
                {supervisionInfo.paginatedItems.collection.map(act => (
                  <TableRow activity={act} key={act.id} updateData={getInfoFromApi} canEdit={canEdit} />
                ))}
              </div>
              {supervisionInfo.paginatedItems.collection.length ? (
                <div className="pagination-style-supervision">
                  <PaginationTable fnSetRows={setRowsPerPage} rows={rowsPerPage} numProyects={supervisionInfo.paginatedItems.rowCount} />
                  <Pagination
                    count={supervisionInfo.paginatedItems.pageCount}
                    page={currentPage}
                    onChange={handlePaginationChange}
                    showFirstButton={true}
                    showLastButton={true}
                  />
                </div>
              ) : null}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Supervision;

const emptySupervisionResultMode = (supervisionInfo: any) =>
  supervisionInfo !== null && supervisionInfo.mode !== undefined
    ? supervisionInfo.mode
    : SupervisionPlanMode.DETAILED_NO_DATA;

const ifLoadingSetFalse = (isLoading: boolean, setIsLoading: Function) => {
  if (isLoading) {
    setIsLoading(false);
  }
};

const lengthZeroOrGreaterThanTwo = (value: any) => value.target.value.length === 0 || value.target.value.length > 2;

const fallbackOption = (yearsOptions: SelectOption[]) => yearsOptions[0] || null;

const isBidMemberHasIdHasAppUser = (user: any) =>
  user.bidMember && user.applicationUserId !== 0 && user.applicationUser;
