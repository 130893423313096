import { cloneDeep } from 'lodash';
import moment from 'moment';

//ASSIGN TIME TO PROJECTS METHOD
export const setDatesTimeToProjects = (currentReview, timeAssignedToEachProject) => {
  const { projects, startDate } = currentReview;
  const workingProjects = cloneDeep(projects);
  let isMorning = true;
  const startTime = 9;
  const middayRestart = 13;
  const oClock = 0;
  const isSaturaday = 6;

  const projectStartingDateTime = moment(startDate).hour(startTime);

  const projectEndigDateTime = moment(startDate).hour(startTime).add(timeAssignedToEachProject, 'minutes');

  workingProjects.forEach(project => {
    if (
      projectStartingDateTime.isBefore(middayHour(projectStartingDateTime)) &&
      projectEndigDateTime.isBefore(middayHour(projectEndigDateTime)) &&
      isMorning
    ) {
      project.NewStartDate = projectStartingDateTime.toISOString();
      project.NewEndDate = projectEndigDateTime.toISOString();
      projectStartingDateTime.add(timeAssignedToEachProject, 'minutes');
      projectEndigDateTime.add(timeAssignedToEachProject, 'minutes');
    } else if (isMorning) {
      projectStartingDateTime.hour(middayRestart).minute(0);
      projectEndigDateTime.hour(middayRestart).minute(0).add(timeAssignedToEachProject, 'minutes');
      isMorning = false;
    }

    if (
      projectStartingDateTime.isAfter(middayHour(projectStartingDateTime)) &&
      projectEndigDateTime.isAfter(middayHour(projectEndigDateTime)) &&
      projectStartingDateTime.isBefore(endOfDayHour(projectStartingDateTime)) &&
      projectEndigDateTime.isBefore(endOfDayHour(projectEndigDateTime)) &&
      !isMorning
    ) {
      project.NewStartDate = projectStartingDateTime.toISOString();
      project.NewEndDate = projectEndigDateTime.toISOString();
      projectStartingDateTime.add(timeAssignedToEachProject, 'minutes');
      projectEndigDateTime.add(timeAssignedToEachProject, 'minutes');
    }

    if (projectEndigDateTime.isAfter(endOfDayHour(projectEndigDateTime))) {
      projectStartingDateTime.hour(startTime).minutes(oClock).add(1, 'day');
      projectEndigDateTime.hour(startTime).minutes(oClock).add(timeAssignedToEachProject, 'minutes').add(1, 'day');
      isMorning = true;
      if (projectStartingDateTime.day() === isSaturaday) {
        projectStartingDateTime.hour(startTime).minutes(oClock).add(2, 'day');
        projectEndigDateTime.hour(startTime).minutes(oClock).add(timeAssignedToEachProject, 'minutes').add(2, 'day');
      }
    }
  });

  return workingProjects;
};

const middayHour = projectDateTime => {
  return projectDateTime.clone().hour(12).minute(1).second(0);
};

const endOfDayHour = projectDateTime => {
  return projectDateTime.clone().hour(18).minute(1).second(0);
};

//SORTING FUNCTIONS
export const sortByExecutor = (a, b) => {
  if (a.baseInformation.executor > b.baseInformation.executor) {
    return 1;
  } else if (a.baseInformation.executor < b.baseInformation.executor) {
    return -1;
  } else {
    return 0;
  }
};
export const sortByDivision = (a, b) => {
  if (a.division.id > b.division.id) {
    return 1;
  } else if (a.division.id < b.division.id) {
    return -1;
  } else {
    return 0;
  }
};

export const sortByTeamLeader = (a, b) => {
  const aTeamLeader = a.baseInformation.projectTeams.find(user => user.roleInProject.id === 2);
  const bTeamLeader = b.baseInformation.projectTeams.find(user => user.roleInProject.id === 2);

  if (aTeamLeader === undefined || bTeamLeader === undefined) {
    return 0;
  }

  if (aTeamLeader.applicationUser.id > bTeamLeader.applicationUser.id) {
    return 1;
  } else if (aTeamLeader.applicationUser.id < bTeamLeader.applicationUser.id) {
    return -1;
  } else {
    return 0;
  }
};

export const recursiveSort = (list, criteriaList) => {
  if (!criteriaList || criteriaList.length === 0) {
    return list;
  }
  return recursiveSort(
    list.sort(criteriaList[criteriaList.length - 1]),
    criteriaList.slice(0, criteriaList.length - 1),
  );
};

export const groupProjectsAndAssignDateTime = (currentReview, criteriaToSort, timeAllocated) => {
  recursiveSort(currentReview.projects, criteriaToSort);
  return setDatesTimeToProjects(currentReview, parseInt(timeAllocated.value));
};
