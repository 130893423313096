import { PermissionsModalActionTypes, SHOW_PERMISSIONS_MODAL } from '../actions/types';

const initialState = false;

const permissionsModalReducer = (state = initialState, action: PermissionsModalActionTypes): boolean => {
  switch (action.type) {
    case SHOW_PERMISSIONS_MODAL:
      return action.payload;
    default:
      return state;
  }
};

export default permissionsModalReducer;
