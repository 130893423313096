import React from 'react';
import './ActivityTable.scss';
import { useTranslation } from 'react-i18next';
import { ActivityRow } from './ActivityRow/ActivityRow';
import { Activity, Acquisition } from '../../../../../../models/Planning';

const display = 'inline-block';

const customStyleCenter = {
  width: '10%',
  textAlign: 'center',
  display,
};

const customStyleRight = {
  width: '10%',
  textAlign: 'right',
  display,
};

const headerValues = [
  {
    title: 'activities',
    style: {
      width: '20%',
    },
    propName: 'name',
  },
  {
    title: 'plan_date',
    style: customStyleCenter,
    propName: 'plannedDate',
  },
  {
    title: 'real_date',
    style: customStyleCenter,
    propName: 'realDate',
  },
  {
    title: 'plan_amount',
    style: customStyleRight,
    propName: 'planAmount',
  },
  {
    title: 'contract_amount',
    style: customStyleRight,
    propName: 'contractAmount',
  },
  {
    title: 'paid_amount',
    style: customStyleRight,
    propName: 'paidAmount',
  },

  {
    title: 'comments',
    style: customStyleCenter,
    propName: 'comments',
  },
  {
    title: 'state',
    style: {
      width: '20%',
    },
    propName: 'state',
  },
];

type Props = {
  dataList: Activity[];
  acquisition: Acquisition;
};

export const ActivityTable = ({ dataList, acquisition }: Props) => {
  const { t } = useTranslation();

  const renderHeaderTable = headerValues =>
    headerValues.map(value => (
      <div className="title" style={value.style} key={value.title}>
        {t(value.title)}
      </div>
    ));

  return (
    <div className="activity-table-container">
      <div className="header">{renderHeaderTable(headerValues)}</div>
      {dataList.length
        ? dataList.map(data => (
            <ActivityRow styleOptions={headerValues} data={data} acquisition={acquisition} key={data.id} />
          ))
        : null}
    </div>
  );
};
