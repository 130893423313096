import React from 'react';
import styles from './RadialAnalyticsCharts.module.scss';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../../../../../../services/stringUtil';
import { Doughnut } from 'react-chartjs-2';

type Props = {
  card?: any;
  modalView?: boolean;
};

export const RadialAnalyticsCharts = ({ card, modalView }: Props) => {
  const { t } = useTranslation();
  const dimensions = modalView ? { width: 480, height: 240 } : { width: 280, height: 120 };
  const { external, externalLabel, internal, internalLabel, total } = card.data;

  const { title, subtitle } = card;
  const options = {
    cutoutPercentage: 75,
    tooltips: {
      backgroundColor: '#101319',
      bodyFontColor: '#ffffff',
      bodyFontFamily: 'Rubik',
      titleFontSize: 14, 
      bodyFontSize: 14,

      callbacks: {
        title: (tooltipItem, data) => {
          return;
        },
        label: (tooltipItem, data) => {
          return `${data['datasets'][0]['valueQuantity'][tooltipItem['index']].toFixed(0)}  ${capitalizeFirstLetter(
            t(data['datasets'][0]['dataLabelsTranslations'][tooltipItem['index']]),
          )}`;
        },
      },
    },
    legend: {
      display: false,
    },
    plugins: {
      datalabels: { display: false },
    },
  };
  return (
    <div>
      {!modalView && (
        <>
          <div className={styles.titleContainer}>
            <div className={styles.title}>{capitalizeFirstLetter(t(title))}</div>
            <div className={styles.subtitle}> {capitalizeFirstLetter(t(subtitle))}</div>
          </div>
        </>
      )}
      <div>
        <Doughnut data={card.data} options={options} {...dimensions} />
        <span className={setTotalText(modalView)}>{total}</span>
      </div>
      <div className={setListInfo(modalView)}>
        <div className={styles.unorderList}>
          <ul>
            <li className={styles.external} onClick={() => {}}>
              {`${capitalizeFirstLetter(t(externalLabel))}: `} <span> {external}</span>
            </li>
            <li className={styles.internal} onClick={() => {}}>
              {`${capitalizeFirstLetter(t(internalLabel))}: `} <span>{internal}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

const setTotalText = modalView => (modalView ? styles.totalTextModal : styles.totalText);
const setListInfo = modalView => (modalView ? styles.listInfoModal : styles.listInfo);
