/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import StateIcon from './StateIcon';
import { useFormMemoryHelpContext } from '../../../context/MemoryHelpFormContext';
import PaginationNumbering from './PaginationNumbering';
import { useTranslation } from 'react-i18next';

const StateText = ({ currentTab }) => {
    const { t } = useTranslation();

    const [localStatus, setLocalStatus] = useState<number[]>([]);

    const { formState, getStatusArray, isFormComplete } = useFormMemoryHelpContext();

    const generalStatus = getStatusArray();

    const filterStatus = generalStatus.filter(status => status !== null);

    useEffect(() => {
        const updatedStatus = filterStatus
            .map((item, i) => item === false ? i + 1 : null)
            .filter(item => item !== null) as number[];
        setLocalStatus(updatedStatus);
    }, [formState, currentTab]);

    const formatStatusText = (statusArray) => {
        if (statusArray.length === 0) return "";
        if (statusArray.length === 1) return `${statusArray[0]}`;

        const copiedStatusArray = [...statusArray];
        const lastElement = copiedStatusArray.pop();

        return `${copiedStatusArray.join(', ')} y ${lastElement}`;
    };
   
    return (
        <div className='pagination-step'>
            <PaginationNumbering currentTab={currentTab + 1} />
            <div className='alert-data'>
                <StateIcon status={isFormComplete} />
                {
                    isFormComplete &&
                    <>
                        <p><span className='completed-data'>{t('memory_help.footer_states.completed.title')}</span>
                        {t('memory_help.footer_states.completed.description')}</p>
                    </>
                }
                {
                    localStatus.length !== 0 &&
                    <p>
                        <span className='pagination-data-pending'>  {t('memory_help.footer_states.pending.title_step')} {formatStatusText(localStatus)}</span>
                        {t('memory_help.footer_states.general')}
                    </p>
                }
            </div>

        </div>
    )
}

export default StateText
