import React from 'react';
import './ButtonsGroup.scss';
import { useTranslation } from 'react-i18next';
import { PmrClassification } from '../../../../models/PmrClassification';

interface ReceivedProps {
  buttonSelected: any;
}

type Props = ReceivedProps;
export const ButtonsGroup = (props: Props) => {
  const { buttonSelected } = props;
  const [isSelected, setIsSelected] = React.useState(PmrClassification.Red);
  const { t } = useTranslation();

  const handleSetIsSelected = pmrValue => {
    setIsSelected(pmrValue);
    buttonSelected(pmrValue);
  };

  return (
    <ul className="buttons-group-container">
      <li onClick={() => handleSetIsSelected(PmrClassification.Red)}>
        <label className={`problem ${isSelected === PmrClassification.Red ? 'selected' : ''} `} htmlFor="problem">
          <input type="radio" id="problem" name="pmr" />
          {t('problem')}
        </label>
      </li>
      <li onClick={() => handleSetIsSelected(PmrClassification.Yellow)}>
        <label className={`alert ${isSelected === PmrClassification.Yellow ? 'selected' : ''} `} htmlFor="alert">
          <input type="radio" id="alert" name="pmr" />
          {t('alert')}
        </label>
      </li>
      <li onClick={() => handleSetIsSelected(PmrClassification.Green)}>
        <label
          className={`satisfactory ${isSelected === PmrClassification.Green ? 'selected' : ''} `}
          htmlFor="satisfactory"
        >
          <input type="radio" id="satisfactory" name="pmr" />
          {t('satisfactory')}
        </label>
      </li>

      <li onClick={() => handleSetIsSelected(PmrClassification.Na)}>
        <label className={`last-button na ${isSelected === PmrClassification.Na ? 'selected' : ''} `} htmlFor="na">
          <input type="radio" id="na" name="pmr" />
          {t('not_assigned')}
        </label>
      </li>
    </ul>
  );
};
