import React, { useEffect } from 'react';
import styles from './MultiDataNotStackedBar.module.scss';
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-annotation';
import { IChartDimensions } from '../BarChart/BarChart';
import { isMobile } from '../../../../../services/booleanUtil';
import { ReportImageDisplay } from '../../../../Util/Modals/CustomizableReports/CustomizableReports';
import { getElementAtEvent } from '../BarChart/Util';
import { useTranslation } from 'react-i18next';

interface IProps {
  datasets: any;
  labels: any;
}

type Props = {
  data: IProps | null;
  customDimensions?: IChartDimensions;
  customOption?: any;
  forReport?: ReportImageDisplay;
  datasetClick?: Function;
  plugins?: any[];
  indicatorKey?: string;
};

const defaultOptions = forReport => {
  return {
    plugins: {
      datalabels: { display: false },
    },
    tooltips: {
      backgroundColor: '#101319',
      titleFontSize: 14,
      bodyFontSize: 14,
    },
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          stacked: false,
          ticks: {
            beginAtZero: true,
            fontSize: isMobile() ? 10 : 12,
            fontStyle: 400,
            color: forReport && forReport === ReportImageDisplay.Detail ? 'RED' : ' #5a6a8a',
            fontColor: '#101319',
            fontFamily: 'rubik',
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          stacked: false,
          ticks: {
            beginAtZero: true,
            display: false,
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
    cornerRadius: 9,
    animation: {
      duration: forReport ? 0 : 1000,
    },
  };
};
export const MultiDataNotStackedBar = ({
  data,
  customDimensions,
  customOption,
  forReport,
  datasetClick,
  plugins,
  indicatorKey,
}: Props) => {
  const { t } = useTranslation();
  const dimensions = customDimensions
    ? isMobile()
      ? customDimensions.mobile
      : customDimensions.desktop
    : { width: 270, height: 200 };
  const options = customOption ? customOption : defaultOptions(forReport);

  useEffect(() => {
    if (data && data.datasets && data.datasets.length) {
      data?.datasets.forEach(txt => (txt.label = t(txt.label)));
    }
  }, [data]);

  return data && data.datasets && data.datasets.length ? (
    <div>
      <Bar
        data={data}
        options={options}
        {...dimensions}
        getElementAtEvent={element => getElementAtEvent(element, datasetClick, false, data, indicatorKey)}
        plugins={plugins && plugins.length ? plugins : []}
      />
    </div>
  ) : (
    <div className={styles.noDataMsjIndicator}>
      <span className={styles.valueText}>-</span>
    </div>
  );
};
