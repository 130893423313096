import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReviewsStatusEnum } from '../../../models/ReviewsStatusEnum';
import ClickableText from '../../ProjectDetail/ClickableText/ClickableText';
import { setCurrentReview as setCurrentReviewRedux } from '../../../redux/actions/currentReview';
import './ReviewsActionLinks.scss';
import AgendaListButton from '../AgendaListButton/AgendaListButton';
import { ReportSectionTabs } from '../../../models/ReportEnums';
import { setTabValue } from '../../../redux/actions/tabValue';
import { tabIndexes } from '../../../models/TabIndexes';
import { setReportSectionTabValue } from '../../../redux/actions/reportSectionTabValue';
import { ReactComponent as IconReports } from '../../../assets/icons/u_reports.svg';


type Props = {
  review: any;
  isAgenda?: boolean;
  changeView?: any;
};
export const ReviewsActionLinks = ({ review, isAgenda = false, changeView = () => { } }: Props) => {
  const { status, id } = review;
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleViewAccordances = e => {
    e.stopPropagation();
    dispatch(setCurrentReviewRedux(review));
    history.push(`/reviewaccordances?id=${id}`);
  };
  const handleViewReports = () => {
    dispatch(setTabValue(tabIndexes.reports));
    history.push('/home');
    dispatch(setReportSectionTabValue(ReportSectionTabs.REVIEWS));
  };

  return (
    <div className={`reviews-action-links-container ${status !== ReviewsStatusEnum.Ended ? 'agenda' : ''}`}>
      {status === ReviewsStatusEnum.Ended ? (
        <>
          <ClickableText
            text={` ${t('view_accordances')}`}
            onClick={e => handleViewAccordances(e)}
          />
          <div className="text-links btn-primary-text">
            <ClickableText
              text={` ${t('view_reports')}`}
              onClick={handleViewReports}
              icon={<IconReports />}
            />
          </div>
        </>
      ) : (
        <AgendaListButton isAgenda={isAgenda} swapView={changeView} stopPropagation={true} />
      )}
   
    </div>
  );
};
