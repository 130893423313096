import React, { useEffect, useState } from 'react';
import styles from './Alerts.module.scss';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { basicData, filterStatus } from '../../Reviews/Filters/BasicData';
import { basicPost } from '../../../api/projectApi';
import { basicFilters, addFilterProps } from '../AvailableProjects/util';
import User from '../../../models/User';
import { translateMultipleText } from '../../../services/stringUtil';

type Props = {
  project: any;
};

const Alerts = ({ project }: Props) => {
  const { t } = useTranslation();
  const [bubbles, setBubbles] = useState<any[]>([]);
  const currentReview = useSelector<any, any>(state => state.currentReview);
  const user = useSelector<any, User>(state => state.user);

  const getDataRenderBubbles = async () => {
    const response = await basicPost({
      ...basicFilters,
      Filters: [{ filterType: 6, field: 'ResponsibleCountryId', value: [user.country.id] }],
      RevisionId: currentReview.id,
      IncludeBaseInformation: true,
    });
    const filteredProject = addFilterProps(response, currentReview.startDate).find(i => i.id === project.id);
    const newProject = filteredProject
      ? {
          ...project,
          revisionDate: filteredProject.revisionDate,
          portfolioProjectedAnnual: filteredProject.portfolioProjectedAnnual,
        }
      : { ...project };

    createBubbleList(newProject);
  };

  useEffect(() => {
    getDataRenderBubbles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createBubbleList = project => {
    const result: any[] = [];
    Object.keys(basicData).forEach(section => {
      Object.keys(basicData[section]).forEach(subSection => {
        basicData[section][subSection].forEach(f => {
          if (f.condition(project) && f.status === filterStatus.Red) {
            result.push(f.filterText || f.label);
          }
        });
      });
    });
    setBubbles(result);
  };
  return (
    <div className={styles.container}>
      {bubbles.map(bubble => (
        <span className={styles.bubble} key={bubble}>
          {translateMultipleText(bubble, t)}
        </span>
      ))}
    </div>
  );
};

export default Alerts;
