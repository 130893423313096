import { cloneDeep } from 'lodash';
import { getNewFilteredIndicators, getSimpleIndicatorProjects } from '../../../../api/indicatorApi';
import { IndicatorKeyEnum } from '../../../../models/IndicatorKeyEnum';
import { parseFilterToApiCall } from '../../../../services/util';
import { isMobile } from '../../../../services/booleanUtil';
import { addPmrEvolutionLast5Years } from '../../../Portfolio/IndicatorsHelpers/multiLineIndicatorHelper';
import { parseToChartBubbles } from '../../../PortfolioDetail/DisbursementTime/Util';
import {
  disbursementLargeImagesNames,
  pareseFiltersForSingleRequest,
  resolveEmptyIfNoData,
  hasData,
} from './ImagesHelper';

export const getDisbursementTimeData = async (context: any, processedList: any[]) => {
  return new Promise(async (resolve, reject) => {
    const foundIndicator = processedList.find(i => i.indicatorKey === IndicatorKeyEnum.DISBURSEMENT_OVER_TIME);
    const indicatorId = foundIndicator ? foundIndicator.id : 0;
    resolveEmptyIfNoData(indicatorId, context, resolve);
    const appliedProductTypeFilters = pareseFiltersForSingleRequest(
      context.reportFilters.common.projectTypeFilters.sort(),
      IndicatorKeyEnum.DISBURSEMENT_OVER_TIME,
    );
    const promisesList: any[] = [];

    for (const applied of appliedProductTypeFilters) {
      const clonedFilter = cloneDeep(context.reportFilters);
      promisesList.push(
        getSimpleIndicatorProjects(indicatorId, {
          ...clonedFilter,
          common: { ...clonedFilter.common, projectTypeFilters: applied },
        }),
      );
    }

    const resultList: any[] = [];
    Promise.all(promisesList)
      .then(result => {
        for (let i = 0; i < result.length; i++) {
          resultList.push(parseToChartBubbles(result[i], appliedProductTypeFilters[i], isMobile() ? 10 : 100));
        }
        resolve(
          resultList
            .map((item, index) => {
              item.largeImageKey = disbursementLargeImagesNames[context.reportFilters.common.projectTypeFilters[index]];
              return item;
            })
            .filter(a => a.datasets && hasData(a.datasets)),
        );
      })
      .catch(err => {
        resolve([]);
      });
  });
};

export const getDisbProjLastFiveYearsData = async (
  context: any,
  processedList: any[],
  indicatorKey: IndicatorKeyEnum,
  processFn: Function,
  mappingNamesObj: Object,
) => {
  return new Promise(async (resolve, reject) => {
    const foundIndicator = processedList.find(i => i.indicatorKey === indicatorKey);
    const indicatorId = foundIndicator ? foundIndicator.id : 0;
    resolveEmptyIfNoData(indicatorId, context, resolve);
    const appliedProductTypeFilters = pareseFiltersForSingleRequest(
      context.reportFilters.common.projectTypeFilters.sort(),
      indicatorKey,
    );
    const promisesList: any[] = [];

    for (const applied of appliedProductTypeFilters) {
      const clonedFilter = cloneDeep(context.reportFilters);
      promisesList.push(
        getNewFilteredIndicators({}, parseFilterToApiCall(applied), {
          ...clonedFilter.common,
          projectProductTypes: applied,
          indicatorId,
        }),
      );
    }

    const resultList: any[] = [];
    Promise.all(promisesList)
      .then(result => {
        for (const r of result) {
          const list: any = [];
          const data = processFn(r.indicatorsData, list);
          resultList.push(data || null);
        }
        resolve(
          resultList
            .map((item, index) => {
              item.largeImageKey = mappingNamesObj[context.reportFilters.common.projectTypeFilters[index]];
              return item;
            })
            .filter(a => a.data),
        );
      })
      .catch(err => {
        resolve([]);
      });
  });
};

const hasDataToDisplay = (list: any[]) => {
  return (
    list.length &&
    list.some(
      item =>
        item.data &&
        item.data.datasets &&
        item.data.datasets.length &&
        item.data.datasets.some(a => a.data.some(v => v !== 0)),
    )
  );
};

export const getPmrEvolutionLastFiveYears = async (
  context: any,
  processedList: any[],
  indicatorKey: IndicatorKeyEnum,
) => {
  return new Promise(async (resolve, reject) => {
    const foundIndicator = processedList.find(i => i.indicatorKey === indicatorKey);
    const indicatorId = foundIndicator ? foundIndicator.id : 0;
    resolveEmptyIfNoData(indicatorId, context, resolve);

    const filterList = [
      { title: 'all_fem_report', filter: null },
      { title: 'after_the operation_reaches_95%_of_disbursements_report', filter: 'STAGE3' },
      { title: 'after_eligibility', filter: 'STAGE2' },
    ];
    const promisesList: any[] = [];

    for (const filterParam of filterList) {
      const clonedFilter = cloneDeep(context.reportFilters);
      promisesList.push(
        getNewFilteredIndicators({ indicatorId, parameter: filterParam.filter }, null, {
          ...clonedFilter.common,
        }),
      );
    }

    const originalList: any[] = [];
    const reclasificationList: any[] = [];

    Promise.all(promisesList)
      .then(result => {
        for (let i = 0; i < result.length; i++) {
          const list: any = [];
          const processedData = addPmrEvolutionLast5Years(result[i].indicatorsData, list);
          originalList.push({ title: filterList[i].title, data: processedData.data ? processedData.data[0] : null });
          reclasificationList.push({
            title: filterList[i].title,
            data: processedData.data ? processedData.data[1] : null,
          });
        }

        const resultList: any[] = [];

        if (hasDataToDisplay(originalList)) {
          resultList.push({ largeImageKey: `${indicatorKey}_DETAIL`, dataList: originalList });
        }
        if (hasDataToDisplay(reclasificationList)) {
          resultList.push({ largeImageKey: `${indicatorKey}_TCP_DETAIL`, dataList: reclasificationList });
        }

        resolve(resultList);
      })
      .catch(error => {
        resolve([]);
      });
  });
};
