import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import css from './CircleAvatar.module.scss';
import { useCards } from '../../../context/CardContext';
import { useDispatch } from 'react-redux';
import { setIndicatorDetailFilterProjectType } from '../../../redux/actions/indicatorDetailFilters';

type Props = {
  addClass?: string;
  label?: string;
  isDisabled?: boolean;
  projectType?: any; // LON, TCP., IGR
};

const typeMap = {
  LON: 0,
  TCP: 2,
  TCP_NAT: 2,
  IGR: 3,
};

const colors = { LON: '#be86d8', TCP: '#66B6B6', IGR: '#F1AF43' };

export const CircleAvatar = ({ addClass, label, isDisabled, projectType }: Props) => {
  const dispatch = useDispatch();
  const { setCurrentRowClicked, currentRowClicked } = useCards();


  const buttonRef = useRef<any>(null);

  /**
   * Callback setIndicator to dispatch actions based on indicatorLabel.
   * Maps label to dispatch types (e.g., 'LON' -> 0). Dispatches action
   * if valid type found. useCallback optimizes performance, only changes
   * when label or dispatch changes.
   */
  const setIndicator = useCallback(indicatorLabel => {
    const type = typeMap[indicatorLabel] ?? null;
    if (type !== null) dispatch(setIndicatorDetailFilterProjectType(type));
  },
    [dispatch],
  );

  const handleChangeFilter = useCallback(() => {
    setCurrentRowClicked(label as string);
    setIndicator(label);
  }, [label, setCurrentRowClicked, setIndicator]);

  const buttonClass = useMemo(() => {
    const isSelected = currentRowClicked === label;
    const selectedClass = isSelected ? css['selected'] : '';

    const disabledClass = isDisabled ? css['circle-avatar-disabled'] : css[addClass as string];
    const projectTypeClass = projectType ? `circle-avatar-${projectType}` : '';

    return `${css['btn-filter']} ${disabledClass} ${selectedClass} ${projectTypeClass}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRowClicked, label, isDisabled, addClass]);

  useEffect(() => {
    if (currentRowClicked === label && buttonRef.current) buttonRef.current.focus();
  }, [currentRowClicked, label]);

  return (
    <>
      <button
        ref={buttonRef}
        disabled={isDisabled}
        className={projectType ? `circle-avatar-${projectType}` : buttonClass}
        style={projectType ? { backgroundColor: colors[projectType] } : { backgroundColor: addClass }}
        onClick={handleChangeFilter}
      >
        {label ?? projectType}
      </button>
    </>
  );
};
