import React, { useState } from 'react';
import styles from './EditReviewModal.module.scss';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { styles as modalStyles } from '../../../Util/Modals/Styles';
import { disableEscKey } from '../../../Util/Modals/Util';
import SaveButton from '../../../Util/Buttons/SaveButton/SaveButton';
import UndoButton from '../../../Util/Buttons/UndoButton/UndoButton';
import { isWeekday, reviewStartEndDate } from '../DateSelector/DateSelector';
import { modifyReview } from '../../../../api/reviewsApi';
import { setCurrentReview } from '../../../../redux/actions/currentReview';
import CustomDatePicker from '../../../Util/CustomDatePicker/CustomDatePicker';
import { checkIfDates, dateIsNull } from '../Util';
import CloseCrossButton from '../../../Util/Buttons/CloseCrossButton/CloseCrossButton';
import BaseModal from '../../../Util/Modals/BaseModal';

type Props = {
  closeModal: Function;
  isOpen: boolean;
  review: any;
};

const customInputStyle = { width: 270, boxSizing: 'border-box', justifyContent: 'flex-start' };
const customLabelStyle = {
  color: '#7a8693',
  fontSize: '10px',
  marginBottom: 7,
  marginLeft: 15,
  textTransform: 'capitalize',
};

const EditReviewModal = ({ closeModal, isOpen, review }: Props) => {
  const dispatch = useDispatch();
  const minDate = new Date();
  // minDate.setFullYear(minDate.getFullYear() - 5);
  minDate.setDate(minDate.getDate() + 1);

  const { t } = useTranslation();
  const [startDateState, setStartDateState] = useState(checkIfDates(review.startDate));
  const [endDateState, setEndDateState] = useState(checkIfDates(review.endDate));

  const saveData = async () => {
    const r = {
      id: review.id,
      startDate: reviewStartEndDate(startDateState, 9),
      endDate: reviewStartEndDate(endDateState, 18),
    };
    await modifyReview(r);
    dispatch(setCurrentReview({ ...review, ...r }));
    closeModal();
  };

  return (
    <BaseModal
      isOpen={isOpen}
      onRequestClose={e => disableEscKey(e, closeModal)}
      style={modalStyles}
      className={styles.modalContent}
    >
      <div className={styles.container}>
        <div className={styles.closeCrossContainer}>
          <CloseCrossButton handleClick={() => closeModal()} />
        </div>
        <div className={styles.texts}>
          <h2 className={styles.titleBold}>{t('edit_date')}</h2>
          <h2 className={styles.title}>{review.title}</h2>
        </div>

        <div className={`${styles.row} select-modal`}>
          <CustomDatePicker
            date={startDateState}
            label={t('start')}
            setDate={date => {
              setStartDateState(date);
            }}
            style={{
              customInput: customInputStyle,
              label: customLabelStyle,
            }}
            error={false}
            placeholderComplete={true}
            minDate={minDate}
            withDate={dateIsNull(startDateState)}
            maxDate={endDateState}
            filterDate={isWeekday}
            applyCustomDisabledStyles={true}
          />
          <CustomDatePicker
            date={endDateState}
            label={t('end')}
            setDate={date => {
              setEndDateState(date);
            }}
            style={{
              customInput: customInputStyle,
              label: customLabelStyle,
            }}
            error={false}
            placeholderComplete={true}
            minDate={startDateState}
            withDate={dateIsNull(endDateState)}
            filterDate={isWeekday}
          />
        </div>
        <div className={styles.row}>
          <SaveButton handleClick={saveData} />
          <UndoButton handleClick={closeModal} customText={t('cancel')} />
        </div>
      </div>
    </BaseModal>
  );
};

export default EditReviewModal;
