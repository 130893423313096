import {
  withStyles,
  createStyles,
  Theme,
  TableRow,
  TableContainer,
  TableCell,
  IconButton,
  Typography,
} from '@material-ui/core';

const borderStyle = '0.5px dotted #AAAAAA';
const tableCellDefaultStyle = {
  border: 'none',
  fontFamily: 'Rubik',
  fontSize: '14px',
  color: '#727E8C',
};

export const ContentManagerStyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#ffffff',
      borderBottom: borderStyle,
      '&:first-child': {
        borderTop: borderStyle,
      },
    },
  }),
)(TableRow);

export const ContentManagerTableContainer = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 'auto',
      marginLeft: '15px',
      overflow: 'hidden',
    },
  }),
)(TableContainer);

export const ContentManagerTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      ...tableCellDefaultStyle,
      paddingTop: '0',
      paddingBottom: '0'
    },
  }),
)(TableCell);

export const SortableContentManagerTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      ...tableCellDefaultStyle,
      color: '#416DBC',
    },
  }),
)(TableCell);

export const ContentManagerActionsTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      ...tableCellDefaultStyle,
      textAlign: 'right',
      width: '40%',
    },
  }),
)(TableCell);

export const ContentManagerActionButton = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:hover': {
        backgroundColor: '#ffffff',
      },
    },
  }),
)(IconButton);

export const ContentManagerTableLabel = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#A5ADBB',
      color: '#ffffff',
      padding: '1px 15px',
      borderRadius: '10px',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '1.25',
    },
  }),
)(Typography);

export const ContentManagerLeavesTableTypography = withStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: '14px',
    },
  }),
)(Typography);
