import { getFormattedValue, getIndicatorData, substractPercentages } from './indicatorHelperUtil';
import { IndicatorKeyEnum as keyEnum } from '../../../models/IndicatorKeyEnum';
import { percentage } from '../../../services/numberUtil';
import { getFilteredValue } from './util';



const formattedValue = (value: number) => {
  const MILLION = 1000000;
  const THOUSAND = 1000;
  let formatted = 0;
  if (value >= MILLION) {
    formatted = value / MILLION;
    return `${formatted.toFixed(1).toString()}M`;
  } else if (value >= THOUSAND) {
    formatted = value / THOUSAND;
    return `${formatted.toFixed(0).toString()}k`;
  } else {
    return value.toFixed(0).toString();
  }
};

const addTransactionalResourcesSupervision = (indicators, cardIndicators) => {
  const indicator = getIndicatorData(indicators, keyEnum.SUPERVISION_PLAN_BUDGET_STATUS);


  const filterApplied = localStorage.getItem('transactionalSupervisionResourcesFilter');
  const metricName = getMetricName(filterApplied);




  if (!indicator) {
    return;
  }
  const basicIndicatorCard = {
    id: indicator.id,
    indicatorKey: indicator.key,
    type: 'radialToBarChart',
    title: 'transactional_supervision_resources',
    subtitle: '',
    detailTitle: 'transactional_supervision_resources',
    detailChartTitle: '',
    detailChartFoot: '',
    data: null,
    order: indicator.order,
  };
  if (!indicator.metrics || indicator.metrics.length <= 1) {
    cardIndicators.push(basicIndicatorCard);
  } else {
    const indicatorMetrics = indicator.metrics;
    const total = getFilteredValue(indicatorMetrics, metricName, filterApplied) - (getFilteredValue(indicatorMetrics, 'EXPENDITURES_A', filterApplied) + getFilteredValue(indicatorMetrics, 'COMMITMENT_B', filterApplied));
    const totalBalance = getFilteredValue(indicatorMetrics, metricName, filterApplied) - (getFilteredValue(indicatorMetrics, 'EXPENDITURES_A', filterApplied) + getFilteredValue(indicatorMetrics, 'COMMITMENT_B', filterApplied)).toFixed(3);
    const balance = Math.round(totalBalance * 100) / 100;

    const data = {
      circles: [
        {
          key: '0',
          mouseEnterColor: 'rgba(90, 141, 238, 0.5)',
          strokeColor: '#5A8DEE',
          value: getFilteredValue(indicatorMetrics, 'BALANCE_C_MINUS_B_PLUS_A', filterApplied),
          clickValue: 'balance',
          strokeWidth: 5,
          strokeWidthMobile: 2,
          trailColor: 'transparent',
          percentageValue: percentage(
            getFilteredValue(indicatorMetrics, 'BALANCE_C_MINUS_B_PLUS_A', filterApplied),
            total,
          ),
          labelText: 'balance',
          labelValue: `${formattedValue(
            getFilteredValue(indicatorMetrics, metricName, filterApplied) - (getFilteredValue(indicatorMetrics, 'EXPENDITURES_A', filterApplied) + getFilteredValue(indicatorMetrics, 'COMMITMENT_B', filterApplied))
          )} USD`,
        },
        {
          key: '1',
          mouseEnterColor: '#00CFDD',
          strokeColor: '#00CFDD',
          value: getFilteredValue(indicatorMetrics, 'COMMITMENT_B', filterApplied),
          clickValue: 'committed_supervision',
          strokeWidth: 5,
          strokeWidthMobile: 4,
          trailColor: 'transparent',
          percentageValue: percentage(getFilteredValue(indicatorMetrics, 'COMMITMENT_B', filterApplied), total),
          labelText: 'committed_supervision',
          labelValue: `${formattedValue(getFilteredValue(indicatorMetrics, 'COMMITMENT_B', filterApplied))} USD`,
        },
        {
          key: '2',
          mouseEnterColor: '#FDAC41',
          strokeColor: '#FDAC41',
          value: getFilteredValue(indicatorMetrics, 'EXPENDITURES_A', filterApplied),
          clickValue: 'spent',
          strokeWidth: 5,
          strokeWidthMobile: 3,
          trailColor: 'transparent',
          percentageValue: percentage(getFilteredValue(indicatorMetrics, 'EXPENDITURES_A', filterApplied), total),
          labelText: 'spent',
          labelValue: `${formattedValue(getFilteredValue(indicatorMetrics, 'EXPENDITURES_A', filterApplied))} USD`,
        },
      ],
      centerText: formattedValue(total).toString(),
      toBarchart: {
        data: {
          datasets: [
            {
              backgroundColor: ['#91E2A1', '#00CFDD', '#FDAC41', '#416DBC'],
              barThickness: 25,
              borderWidth: 0,
              data: [
                getFilteredValue(indicatorMetrics, metricName, filterApplied),
                getFilteredValue(indicatorMetrics, 'EXPENDITURES_A', filterApplied),
                getFilteredValue(indicatorMetrics, 'COMMITMENT_B', filterApplied),
                balance,
              ],
              label: ['supervision_plan', 'spent', 'committed_supervision', 'balance'],
            },
          ],
          labels: ['', '', ''],
          subLabels: [1, 2],
        },
        detailChartFoot: 'number_of_projects',
        detailChartTitle: '',
        detailTitle: '',
        id: 65,
        indicatorKey: 'SUPERVISION_PLAN_BUDGET_STATUS',
        order: 20,
        subtitle: '',
        title: '',
        type: 'vertical',
        key: 'SUPERVISION_PLAN_BUDGET_STATUS',
      },
      rawMetrics: indicatorMetrics,
    };
    cardIndicators.push({ ...basicIndicatorCard, data });
  }
};

const baseExternalCircle = {
  key: '0',
  mouseEnterColor: 'rgba(0, 207, 221, 0.5)',
  strokeColor: 'rgba(0, 207, 221, 1)',
  value: 0,
  clickValue: 'planned_progress_to_date',
  strokeWidth: 14,
  strokeWidthMobile: 6,
  trailColor: 'rgba(155, 160, 170, 0.2)',
  percentageValue: 0,
  labelText: 'planned_progress_to_date',
  labelValue: '',
};
const baseInternalCircle = {
  key: '1',
  mouseEnterColor: 'rgba(253, 172, 65, 0.5)',
  strokeColor: 'rgba(253, 172, 65, 1)',
  value: 0,
  clickValue: 'actual_advance_to_date',
  strokeWidth: 21,
  strokeWidthMobile: 9,
  trailColor: 'rgba(155, 160, 170, 0.2)',
  percentageValue: 0,
  labelText: 'actual_advance_to_date',
  labelValue: '',
};

const addAverageLag = (indicators, cardIndicators) => {
  const indicator = getIndicatorData(indicators, keyEnum.AVERAGE_LAG);

  if (!indicator) {
    return;
  }
  const basicIndicatorCard = {
    id: indicator.id,
    indicatorKey: indicator.key,
    type: 'radial',
    title: 'average_lag',
    subtitle: 'supervision_plans',
    detailTitle: 'average_lag_title',
    detailChartTitle: '',
    detailChartFoot: '',
    data: null,
    order: indicator.order,
  };
  if (!indicator.metrics || indicator.metrics.length <= 1) {
    cardIndicators.push(basicIndicatorCard);
  } else {
    const data = {
      circles: [
        {
          ...baseExternalCircle,
          value: getFormattedValue(indicator.metrics, 'PLANNED_ADVANCE'),
          percentageValue: percentage(
            getFormattedValue(indicator.metrics, 'PLANNED_ADVANCE'),
            getFormattedValue(indicator.metrics, 'PLANNED_TOTAL'),
          ),
        },
        {
          ...baseInternalCircle,
          value: getFormattedValue(indicator.metrics, 'ACTUAL_ADVANCE'),
          percentageValue: percentage(
            getFormattedValue(indicator.metrics, 'ACTUAL_ADVANCE'),
            getFormattedValue(indicator.metrics, 'PLANNED_TOTAL'),
          ),
        },
      ],
      totalData: [
        {
          ...baseExternalCircle,
          value: getFormattedValue(indicator.metrics, 'PLANNED_ADVANCE'),
          percentageValue: percentage(
            getFormattedValue(indicator.metrics, 'PLANNED_ADVANCE'),
            getFormattedValue(indicator.metrics, 'PLANNED_TOTAL'),
          ),
        },
        {
          ...baseInternalCircle,
          value: getFormattedValue(indicator.metrics, 'ACTUAL_ADVANCE'),
          percentageValue: percentage(
            getFormattedValue(indicator.metrics, 'ACTUAL_ADVANCE'),
            getFormattedValue(indicator.metrics, 'PLANNED_TOTAL'),
          ),
        },
      ],
      activitiesData: [
        {
          ...baseExternalCircle,
          value: getFormattedValue(indicator.metrics, 'PLANNED_ADVANCE_ACTIVITY'),
          percentageValue: percentage(
            getFormattedValue(indicator.metrics, 'PLANNED_ADVANCE_ACTIVITY'),
            getFormattedValue(indicator.metrics, 'PLANNED_TOTAL_ACTIVITY'),
          ),
        },
        {
          ...baseInternalCircle,
          value: getFormattedValue(indicator.metrics, 'ACTUAL_ADVANCE_ACTIVITY'),
          percentageValue: percentage(
            getFormattedValue(indicator.metrics, 'ACTUAL_ADVANCE_ACTIVITY'),
            getFormattedValue(indicator.metrics, 'PLANNED_TOTAL_ACTIVITY'),
          ),
        },
      ],
      criticalProductsData: [
        {
          ...baseExternalCircle,
          value: getFormattedValue(indicator.metrics, 'PLANNED_ADVANCE_PRODUCT'),
          percentageValue: percentage(
            getFormattedValue(indicator.metrics, 'PLANNED_ADVANCE_PRODUCT'),
            getFormattedValue(indicator.metrics, 'PLANNED_TOTAL_PRODUCT'),
          ),
        },
        {
          ...baseInternalCircle,
          value: getFormattedValue(indicator.metrics, 'ACTUAL_ADVANCE_PRODUCT'),
          percentageValue: percentage(
            getFormattedValue(indicator.metrics, 'ACTUAL_ADVANCE_PRODUCT'),
            getFormattedValue(indicator.metrics, 'PLANNED_TOTAL_PRODUCT'),
          ),
        },
      ],
      centerText: substractPercentages(indicator.metrics, 'PLANNED_ADVANCE', 'ACTUAL_ADVANCE', 'PLANNED_TOTAL'),
      centerTextTotal: substractPercentages(indicator.metrics, 'PLANNED_ADVANCE', 'ACTUAL_ADVANCE', 'PLANNED_TOTAL'),
      centerTextActivities: substractPercentages(
        indicator.metrics,
        'PLANNED_ADVANCE_ACTIVITY',
        'ACTUAL_ADVANCE_ACTIVITY',
        'PLANNED_TOTAL_ACTIVITY',
      ),
      centerTextProducts: substractPercentages(
        indicator.metrics,
        'PLANNED_ADVANCE_PRODUCT',
        'ACTUAL_ADVANCE_PRODUCT',
        'PLANNED_TOTAL_PRODUCT',
      ),
      centerTextStyle: { fontSize: 17, fontWeight: '700' },
      detailCenterTextStyle: { fontSize: '26px', fontWeight: '700', left: '43%', top: '59%' },
    };
    cardIndicators.push({ ...basicIndicatorCard, data });
  }
};


const getMetricName = (filter: any) => {
  switch (filter) {
    case 'VPC':
      return 'VPC_TRNS_AMNT';
    case 'VPS':
      return 'VPS_TRNS_AMNT';
    case 'ESG':
      return 'ESG_TRNS_AMNT';
    case 'FMP':
      return 'FID_AMNT';
    case 'CT':
      return 'TC_TRNS_AMNT';
    default:
      return 'TOTAL_BUDGET';
  };
}


export { addTransactionalResourcesSupervision, addAverageLag };
