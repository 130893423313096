import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setFilterIndicators } from '../../../redux/actions/filterIndicators';
import { setFilter } from '../../../redux/actions/filter';
import { Bubble } from '../BubbleElement/BubbleElement';
import { CalledFrom } from '../../../models/CalledFromEnum';
import { setFilterEarlyWarnings } from '../../../redux/actions/filterEarlyWarnings';
import { handleActualState } from '../UtilFunctions';
import { IFilterBubble } from '../../../models/FilterBubble';

type Props = IFilterBubble;

const AttributesBubbles = (props: Props) => {
  const { indicatorCall, calledFrom } = props;
  const dispatch = useDispatch();
  const filterIndicators = useSelector<any, any>(state => state.filterIndicators);
  const filterProjects = useSelector<any, any>(state => state.filter);
  const filterEarlyWarnings = useSelector<any, any>(state => state.filterEarlyWarnings);

  const bubbles = handleActualState({
    calledFrom,
    indicatorCall,
    filterIndicators,
    filterProjects,
    filterEarlyWarnings,
    commonProp: 'interestIssues',
  });

  const closeBubble = (interestIssueId: number) => {
    const actualState = handleActualState({
      calledFrom,
      indicatorCall,
      filterIndicators,
      filterProjects,
      filterEarlyWarnings,
      commonProp: 'interestIssues',
    });

    const newState = actualState.filter(interestIssue => interestIssue.id !== interestIssueId);

    if (calledFrom === CalledFrom.EARLY_WARNINGS) {
      dispatch(
        setFilterEarlyWarnings({
          ...filterEarlyWarnings,
          common: {
            ...filterEarlyWarnings.common,
            interestIssues: newState,
          },
        }),
      );
    } else {
      if (indicatorCall) {
        dispatch(
          setFilterIndicators({
            ...filterIndicators,
            common: {
              ...filterIndicators.common,
              interestIssues: newState,
            },
          }),
        );
      } else {
        dispatch(
          setFilter({
            ...filterProjects,
            common: {
              ...filterProjects.common,
              interestIssues: newState,
            },
          }),
        );
      }
    }
  };

  return (
    <>
      {bubbles.map(interestIssue => (
        <Bubble
          key={interestIssue.id}
          itsHidden={false}
          label={interestIssue.label}
          closeBubble={() => closeBubble(interestIssue.id)}
        />
      ))}
    </>
  );
};

export default AttributesBubbles;
