import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProgressChart from '../../Portfolio/CardIndicator/Cards/ProgressChart/ProgressChart';
import GcmChart from './GcmChart/GcmChart';
import styles from './GcmAlert.module.scss';
import GcmTable from './GcmTable/GcmTable';
import { getProjectsSimpleTableIndicator } from '../../../redux/actions/indicatorProjects';
import { GcmStatus } from '../../../models/GcmAlerts';

type Props = {
  currentIndicator: any;
};

const GcmAlert = ({ currentIndicator }: Props) => {
  const { container, multiChartContainer, chartTableContainer } = styles;
  const [status, setStatus] = useState<GcmStatus | null>(null);
  const [list] = useState<any>([...currentIndicator.data?.chartData]);
  const [currentChart, setCurrentChart] = useState<any>(null);
  const [currentBar, setCurrentBar] = useState<any>(null);
  const [currentMapperKey, setCurrentMapperKey] = useState<string>('');
  const indicatorProjects = useSelector<any, any[]>(state => state.indicatorProjects);
  const dispatch = useDispatch();
  const alertTypeId = 'alertTypeId';

  useEffect(() => {
    sessionStorage.removeItem(alertTypeId);
    return () => {
      sessionStorage.removeItem(alertTypeId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setAlertTypeIdInStorageUpdateCurrentMapperKey = (cChart, item) => {
    if (cChart && cChart.dataList && cChart.dataList.length && item && item[0]) {
      sessionStorage.setItem(alertTypeId, cChart.dataList[item[0]._index].id);
      setCurrentMapperKey(cChart.dataList[item[0]._index].mapperKey);
    }
    dispatch(getProjectsSimpleTableIndicator(currentIndicator.id));
  };

  const handleStatusChange = (newStatus: number) => {
    setStatus(newStatus);
    const cChart = list.find(i => i.status === newStatus);
    setCurrentChart(cChart || null);
    setCurrentBar(null);
    setAlertTypeIdInStorageUpdateCurrentMapperKey(cChart, { 0: { _index: 0 } });
  };

  const setCurrentChartAndTable = (status: GcmStatus, item: any) => {
    setStatus(status);
    const cChart = list.find(i => i.status === status);
    setCurrentChart(cChart || null);
    setCurrentBar(item);
    setAlertTypeIdInStorageUpdateCurrentMapperKey(cChart, item);
  };

  const handleClickChart = item => {
    setCurrentBar(item);
    setAlertTypeIdInStorageUpdateCurrentMapperKey(currentChart, item);
  };

  return (
    <div className={container}>
      <ProgressChart
        data={currentIndicator.data && currentIndicator.data.progressData ? currentIndicator.data.progressData : null}
        isDetail={true}
        handleClick={value => {
          handleStatusChange(value);
        }}
        indicatorKey={currentIndicator.indicatorKey}
      />
      {status === null ? (
        <div className={multiChartContainer}>
          {list.map(item => (
            <GcmChart
              data={item.data}
              title={item.title}
              key={item.status}
              handleClick={element => setCurrentChartAndTable(item.status, element)}
            />
          ))}
        </div>
      ) : (
        <div className={chartTableContainer} key={status}>
          <GcmChart
            data={currentChart.data}
            title={currentChart.title || ''}
            handleClick={item => {
              handleClickChart(item);
            }}
          />
          <GcmTable
            currentMapperKey={currentMapperKey}
            projects={indicatorProjects}
            status={status}
            title={
              currentBar &&
              currentBar.length &&
              currentBar[0]._model &&
              currentBar[0]._model.label &&
              currentBar[0]._model.label.length
                ? currentBar[0]._model.label.join(' ')
                : list &&
                  list.length &&
                  list[status].dataList &&
                  list[status].dataList.length &&
                  list[status].dataList[0].label
            }
          />
        </div>
      )}
    </div>
  );
};

export default GcmAlert;
