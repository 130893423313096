import { useState, useEffect } from 'react';
import { getDivisions } from '../api/filterApi';
import SelectOption from '../models/SelectOption';
import { getDivisionFilterOptions } from '../components/Filter/UtilFunctions';

export const useDivisions = () => {
  const [divisionOptions, setDivisionOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    (async () => {
      const divisions = await getDivisions();
      const options = getDivisionFilterOptions(divisions);
      setDivisionOptions(options);
    })();
  }, []);

  return divisionOptions;
};
