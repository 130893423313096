import React from 'react';
import { useTranslation } from 'react-i18next';
import ErrorMessage from '../../Util/ErrorMessage/ErrorMessage';
import ContentManagerAccordion from '../ContentManagerAccordion/ContentManagerAccordion';
import Leaves from './Leaves/Leaves';
import Delegations from './Delegations/Delegations';
import { loaderCustomStyles } from './../../Util/ContentManager/ContentManager';

import {
  defaultSortData,
  getLeavesService,
  defaultDelegationFilters,
  getDelegationsService,
} from './../../../api/contentManager';

import SelectOption from '../../../models/SelectOption';

import { isNil } from 'lodash';
import { getRegionsAndCountries } from '../../../api/filterApi';
import { getCountries, hasRecords, getOptionsFromKeys } from '../../Util/ContentManager/ContentManager';
import { RolesIdEnum } from '../../../models/RolesIdEnum';
import ContentManagerLoader from '../ContentManagerLoader/ContentManagerLoader';

const leaves = 'leaves';
const delegations = 'delegations';

const FunctionDelegations = () => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [errorsMessage, setErrorsMessage] = React.useState(false);
  const [countryOptions, setCountryOptions] = React.useState<SelectOption[]>();
  const [loading, setLoading] = React.useState(true);
  const [leavesList, setLeavesList] = React.useState<any>();
  const [delegationsList, setDelegationsList] = React.useState<any>();
  const [roleOptions, setRoleOptions] = React.useState<SelectOption[]>();
  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) =>
    setExpanded(isExpanded ? panel : false);

  const getLeaves = async (sortData = defaultSortData) => {
    const leavesResponse = await getLeavesService(sortData);
    setLeavesList(leavesResponse);
  };

  const getDelegations = async (filterData = defaultDelegationFilters) => {
    const delegationsResponse = await getDelegationsService(filterData);
    setDelegationsList(delegationsResponse);
  };

  const getCountriesAndRegions = async () => {
    const regionAndCountriesResponse = await getRegionsAndCountries();
    setCountryOptions(getCountries(regionAndCountriesResponse));
  };

  const getRoleOptions = () => setRoleOptions(getOptionsFromKeys(RolesIdEnum, t, null, 'role_id_'));

  React.useEffect(() => {
    if (loading) {
      hasRecords(countryOptions) || getCountriesAndRegions();
      hasRecords(roleOptions) || getRoleOptions();
      !isNil(leavesList) || getLeaves();
      hasRecords(delegationsList) || getDelegations();
    }

    if (!isNil(leavesList) && hasRecords(countryOptions) && hasRecords(delegationsList)) {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [loading, leavesList, countryOptions, roleOptions, delegationsList]);

  const commonProps = {
    t,
    displayGenericErrorMessage: value => setErrorsMessage(value),
    countryOptions,
  };

  return loading ? (
    <div className="content-manager__loader-container">
      <ContentManagerLoader styles={loaderCustomStyles} />
    </div>
  ) : (
    <>
      <h1 className="content-manager__title" style={{ marginBottom: 50 }}>
        {t('delegation_of_functionalities')}
      </h1>
      {errorsMessage && (
        <ErrorMessage
          customStyle={{ marginBottom: 30, width: 622 }}
          alertMessage={t('contents_generic_error_title')}
          descriptionMessage={t('contents_generic_error_description')}
        />
      )}
      <ContentManagerAccordion
        accordionSummary={t('content_manager_leaves')}
        onChange={handleChange(leaves)}
        expanded={expanded === leaves}
        style={{ width: '930px' }}
      >
        <Leaves {...commonProps} leavesList={leavesList} updateFunction={getLeaves} />
      </ContentManagerAccordion>
      <ContentManagerAccordion
        accordionSummary={t('content_manager_delegations')}
        onChange={handleChange(delegations)}
        expanded={expanded === delegations}
        style={{ width: 'auto' }}
      >
        <Delegations
          {...commonProps}
          delegationsList={delegationsList}
          roleOptions={roleOptions}
          updateFunction={getDelegations}
        />
      </ContentManagerAccordion>
    </>
  );
};

export default FunctionDelegations;
