import React from 'react';
import './CommentsIcon.scss';

type Props = {
  comments: number;
  hasUnreadComments: boolean;
  alwaysShowIcon?: boolean;
  onClick?: any;
};

const CommentsIcon = (props: Props) => {
  const { comments, hasUnreadComments, alwaysShowIcon, onClick } = props;
  return (
    <div className="accordance-comments-icon-container" onClick={onClick} style={onClick ? { cursor: 'pointer' } : {}}>
      {comments === 0 ? (
        alwaysShowIcon ? (
          <span className="icon"></span>
        ) : (
          <span className="empty-text">-</span>
        )
      ) : (
        <>
          <span className={`icon ${hasUnreadComments ? 'unread' : ''}`}></span>
          <span className="text">{comments}</span>
        </>
      )}
    </div>
  );
};

export default CommentsIcon;
