import React from 'react';
import { ProjectProductTypes } from '../../../../models/ProjectProductTypes';
import { useTranslation } from 'react-i18next';
import '../BaseInformation.scss';
import { useHistory } from 'react-router-dom';

type Props = {
  projectProductType: any;
  baseInfo: any;
  igrGreater: boolean;
};

const LinkedToTheLoan = ({ projectProductType, baseInfo, igrGreater }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();

  return projectProductType === ProjectProductTypes.CT ||
    (projectProductType === ProjectProductTypes.IG && !igrGreater) ? (
    <div className="ct-info-item">
      <span className="section-title">{t('linked_to_the_loan')}</span>
      <div className="links-container">
        {baseInfo.relatedProjects && baseInfo.relatedProjects.length ? (
          baseInfo.relatedProjects.map(item => (
            <span key={item.id} className="section-link" onClick={() => history.push(`/projectdetail?id=${item.id}`)}>
              {item.operationNumber}
            </span>
          ))
        ) : (
          <span className="section-text">-</span>
        )}
      </div>
    </div>
  ) : null;
};

export default LinkedToTheLoan;
