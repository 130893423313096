import moment from 'moment';
import { isMillionNumber, isValidYear } from './booleanUtil';
import { replaceMomentFormat } from './util';

const translateMultipleText = (text, t) => {
  const list = text.split(' ');
  let result = '';
  for (const a of list) {
    result += `${t(a)} `;
  }
  return result;
};

const tooltipTitle = (t: any, value: string) => {
  if (t && t(value)) {
    return t(value);
  }
  return '';
};

const isCalibri = () => {
  return "'Calibri', 'Rubik', -apple - system, BlinkMacSystemFont, 'Helvetica', 'Arial', 'Verdana', 'Tahoma', sans - serif";
};
const isRubik = () => {
  return "'Rubik', -apple - system, BlinkMacSystemFont, 'Helvetica', 'Arial', 'Verdana', 'Tahoma', sans - serif";
};

const isPercentageFieldType = fieldType => (fieldType === 'percentage' ? '%' : '');

const thousandsSeparator = value => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  // return value !== 0 ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : null;
};

const thousandsSeparatorToMillion = value => {
  if (value >= 1000000) {
    return (value / 1000000).toFixed(3) + ' MM';
  }
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

const addMillionsLabel = number => {
  return isMillionNumber(number) ? 'MM' : '';
};

const renderDateOrSlash = (date, formatDate = 'DD/MMM/YYYY') => {
  const minValidYear = 1910;
  return date && isValidYear(date, minValidYear) ? replaceMomentFormat(moment(date).format(formatDate)) : '-';
};

const capitalizeFirstLetter = phrase => {
  return phrase.charAt(0).toUpperCase() + phrase.slice(1);
};

const parseToSemester = (date, t) => {
  const semester = moment(date).month() + 1 < 7 ? 1 : 2;
  return `${t('semester')} ${semester} ${moment(date).year()}`;
};

const setTranslationFile = contentManager => (contentManager ? 'contentManager' : '');

export {
  translateMultipleText,
  tooltipTitle,
  isCalibri,
  isRubik,
  isPercentageFieldType,
  thousandsSeparator,
  thousandsSeparatorToMillion,
  addMillionsLabel,
  renderDateOrSlash,
  capitalizeFirstLetter,
  parseToSemester,
  setTranslationFile,
};
