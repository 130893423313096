/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import './EventDetailInfo.scss';
import SaveButton from '../../../Util/Buttons/SaveButton/SaveButton';
import { EventType, eventTypeProjectTab } from '../../../../models/EventType';
import DisbursementDetail from './DisbursementDetail/DisbursementDetail';
import AcquisitionDetail from './AcquisitionDetail/AcquisitionDetail';
import ActivityDetail from './ActivityDetail/ActivityDetail';
import { AccordanceType } from '../../../../models/AccordanceType';
import AccordanceDetail from './AccordanceDetail/AccordanceDetail';
import { capitalizeFirstLetter } from '../../../../services/stringUtil';
import { isMobile } from '../../../../services/booleanUtil';
import { useHistory, useLocation } from 'react-router-dom';
import { SecretaryEventDetail } from './SecretaryEventDetail/SecretaryEventDetail';
import { MissionEventDetail } from './MissionEventDetail/MissionEventDetail';
import { LifecycleEventDetail } from './LifecycleEventDetail/LifecycleEventDetail';
import { SupervisoryActionEventDetail } from './SupervisoryActionEventDetail/SupervisoryActionEventDetail';
import Project from '../../../../models/Project';
import { projectTabsByType } from '../../../../pages';

const eventTitles = [
  'disbursement_detail',
  'acquisition_detail',
  'activity_detail',
  'monitor_accordance',
  'revision_accordance',
  'mission_detail',
  'secretarial_event_detail',
  'life_cycle_detail',
  'inter_group_accordance',
  'supervisory_actions',
];

const tabByType = (currentProject, eventType) => {
  const { projectProductType, projectProductTypeClassification } = currentProject;
  const tabsList = projectTabsByType(projectProductType, projectProductTypeClassification);
  const tabIndex = tabsList.indexOf(eventTypeProjectTab[eventType]);
  return tabIndex > -1 ? tabIndex : 0;
};

export const renderDetail = (
  createReminder,
  isCalendarIndicator,
  closeModal,
  modalEditStateUpdate,
  setHideSeeMoreButton,
  eventDataObject,
) => {
  const { eventType, eventData, event } = eventDataObject;

  switch (parseInt(eventType)) {
    case EventType.Disbursement:
      return (
        <DisbursementDetail
          eventData={{
            ...eventData,
            end: event.end || null,
            projectCode: event.projectCode || null,
            projectTitle: event.projectTitle || null,
          }}
          createAccordanceReminder={createReminder}
          isCalendarIndicator={isCalendarIndicator}
        />
      );
    case EventType.Acquisition:
      return (
        <AcquisitionDetail
          eventData={{ ...eventData, projectCode: event.projectCode || null, projectTitle: event.projectTitle || null }}
          createAccordanceReminder={createReminder}
          isCalendarIndicator={isCalendarIndicator}
        />
      );
    case EventType.Activity:
      return (
        <ActivityDetail
          eventData={{ ...eventData, projectCode: event.projectCode || null, projectTitle: event.projectTitle || null }}
          createAccordanceReminder={createReminder}
          isCalendarIndicator={isCalendarIndicator}
        />
      );
    case EventType.MonitoringAccordance:
      return (
        <AccordanceDetail
          eventData={eventData}
          accordanceType={AccordanceType.Monitoring}
          createAccordanceReminder={createReminder}
          isCalendarIndicator={isCalendarIndicator}
        />
      );
    case EventType.RevisionAccordance:
      return (
        <AccordanceDetail
          eventData={eventData}
          accordanceType={AccordanceType.Revision}
          createAccordanceReminder={createReminder}
          isCalendarIndicator={isCalendarIndicator}
        />
      );
    case EventType.InterGroup:
      return (
        <AccordanceDetail
          eventData={eventData}
          accordanceType={AccordanceType.InterGroup}
          createAccordanceReminder={createReminder}
          isCalendarIndicator={isCalendarIndicator}
        />
      );

    case EventType.ExternalEvent:
      return (
        <SecretaryEventDetail
          eventData={{
            ...eventData,
            urlEvent: event.urlEvent,
          }}
          createAccordanceReminder={createReminder}
        />
      );

    case EventType.Mission:
      return <MissionEventDetail eventData={eventData} createAccordanceReminder={createReminder} />;

    case EventType.Lifecycle:
      return <LifecycleEventDetail eventData={eventData} createAccordanceReminder={createReminder} />;

    case EventType.SupervisoryAction:
      return (
        <SupervisoryActionEventDetail
          eventData={eventData}
          createReminder={createReminder}
          closeModal={closeModal}
          modalEditStateUpdate={modalEditStateUpdate}
          currentProjectId={event.projectId}
          setHideSeeMoreButton={setHideSeeMoreButton}
        />
      );

    default:
      return null;
  }
};

export const handleOpenConvergence = (projectCode: string) => {
  const url = 'https://convergence.iadb.org/Operation/{project_number}'.replace('{project_number}', projectCode);
  window.open(url, '_blank');
};

type Props = {
  event: any;
  seeMore: any;
  eventDetail: any;
  createReminder: any;
  closeModal?: Function;
  modalEditStateUpdate?: Function;
};

const EventDetailInfo = (props: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const isCalendarIndicator = location.pathname === '/portfoliodetail';
  const { event, seeMore, eventDetail, createReminder, closeModal, modalEditStateUpdate } = props;
  const [hideSeeMoreButton, setHideSeeMoreButton] = useState<boolean>(false);
  const currentProject = useSelector<any, Project>(state => state.currentProject);

  const handleOpenProjectDetail = () => {
    let projectIdNumber = 0;
    switch (parseInt(event.eventType)) {
      case EventType.Activity:
      case EventType.Disbursement:
      case EventType.Acquisition:
        projectIdNumber = event.projectId;
        break;

      default:
        projectIdNumber = eventDetail.project.id;
        break;
    }
    history.push(`/projectdetail?id=${projectIdNumber}`);
  };

  const handleOpenExternalLink = () => {
    window.open(eventDetail.urlEvent, '_blank');
  };

  const gotoProject = projectId => {
    history.push(`/projectdetail?id=${projectId}`);
  };
  const renderCalendarIndicatorButton = () => {
    switch (parseInt(event.eventType)) {
      case EventType.ExternalEvent:
        return (
          <SaveButton customStyle={{ marginTop: 40 }} handleClick={handleOpenExternalLink} customText={t('see_more')} />
        );

      case EventType.Mission:
      case EventType.Lifecycle:
        return (
          <SaveButton
            customStyle={{ marginTop: 40 }}
            handleClick={() => handleOpenConvergence(eventDetail.project.code)}
            customText={t('go_to_convergence')}
          />
        );

      case EventType.SupervisoryAction:
        return <SaveButton customStyle={{ marginTop: 40 }} handleClick={closeModal} customText={t('close')} />;
      default:
        return (
          <SaveButton
            customStyle={{ marginTop: 40 }}
            handleClick={handleOpenProjectDetail}
            customText={t('go_to_project')}
          />
        );
    }
  };

  const eventDataObject = {
    eventType: event.eventType,
    eventData: eventDetail,
    event,
  };

  return (
    <div className="event-detail-info-container">
      {setModalTopHeader(event, t, gotoProject, isCalendarIndicator)}
      {renderDetail(
        createReminder,
        isCalendarIndicator,
        closeModal,
        modalEditStateUpdate,
        setHideSeeMoreButton,
        eventDataObject,
      )}
      {!isMobile() && (
        <div className="horizontal">
          {isCalendarIndicator
            ? renderCalendarIndicatorButton()
            : !hideSeeMoreButton && (
                <SaveButton
                  handleClick={() => seeMore(tabByType(currentProject, event.eventType))}
                  customText={t('see_more')}
                />
              )}
        </div>
      )}
    </div>
  );
};

export default EventDetailInfo;

const setModalTopHeader = (event, t, gotoProject, isCalendarIndicator) => {
  return (event && event.eventType === EventType.SupervisoryAction) ||
    event.eventType === EventType.SupervisoryAction.toString() ? (
    setSupervisionModalTopHeader(event, t, gotoProject, isCalendarIndicator)
  ) : (
    <h3>{t(eventTitles[event.eventType])}</h3>
  );
};

const setSupervisionModalTopHeader = (event, t, gotoProject, isCalendarIndicator) => {
  if (event && event.eventType === EventType.SupervisoryAction.toString() && isCalendarIndicator) {
    return (
      <div>
        <span className="link" onClick={() => gotoProject(event.projectId)}>
          {capitalizeFirstLetter(t('go_to_project_info'))}
        </span>
      </div>
    );
  }
  return null;
};
