import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './AgendaListButton.module.scss';
import { ReactComponent as IconCalendar } from '../../../assets/icons/u_calendar-alt.svg';

type Props = {
  isAgenda: boolean;
  swapView: any;
  stopPropagation?: boolean;
};

const AgendaListButton = ({ isAgenda, swapView, stopPropagation }: Props) => {
  const { t } = useTranslation();
  return (
    <div
      onClick={e => {
        if (stopPropagation) {
          e.stopPropagation();
        }
        swapView();
      }}
      className={isAgenda ? styles.isAgenda : styles.isList}
    >
      <span className="btn btn-primary-text">
        <IconCalendar />
        {t('new_see_agenda')}
      </span>
    </div>
  );
};
export default AgendaListButton;
