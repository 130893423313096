import React from 'react';
import './CardProjectSkeleton.scss';
import Skeleton from '@material-ui/lab/Skeleton';
import { isMobile } from '../../../services/booleanUtil';

export const CardProjectSkeleton = () => {
  const maxWidthSkeleton = isMobile() ? 245 : 279;
  return (
    <div className="card-skeleton">
      <Skeleton animation="wave" variant="text" width={58} height={11} style={{ marginTop: 20 }} />
      <Skeleton animation="wave" variant="text" width={maxWidthSkeleton} height={11} style={{ marginTop: 19 }} />
      <Skeleton animation="wave" variant="text" width={maxWidthSkeleton} height={11} style={{ marginTop: 9 }} />
      <Skeleton animation="wave" variant="text" width={85} height={11} style={{ marginTop: 9 }} />
      <Skeleton animation="wave" variant="text" width={103} height={9} style={{ marginTop: 33 }} />
      {!isMobile() && (
        <>
          <Skeleton animation="wave" variant="text" width={241} height={11} style={{ marginTop: 5 }} />
          <Skeleton animation="wave" variant="text" width={103} height={9} style={{ marginTop: 50 }} />
          <Skeleton animation="wave" variant="text" width={241} height={11} style={{ marginTop: 5 }} />
        </>
      )}
    </div>
  );
};
