import { SectorsActionTypes, SET_SECTORS } from '../actions/types';

const initialState: [] = [];

const sectorsReducer = (state = initialState, action: SectorsActionTypes): any => {
  switch (action.type) {
    case SET_SECTORS:
      return action.payload;
    default:
      return state;
  }
};
export default sectorsReducer;
