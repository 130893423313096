import { axiosDefaultConfig } from './axiosConfig';
import * as config from './config.json';
import Division from '../models/Division';
import Region from '../models/Region';

export const getDivisions = () => {
  return new Promise<Division[]>(async (resolve, reject) => {
    const url = config.MISCELLANEOUS_DIVISIONS;
    try {
      const response = await axiosDefaultConfig.get(url);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getRegionsAndCountries = (withSede = false) => {
  return new Promise<Region[]>(async (resolve, reject) => {
    const url = `${config.MISCELLANEOUS_REGIONS}${withSede ? '/withSede' : ''} `;
    try {
      const response = await axiosDefaultConfig.get(url);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getSectors = () => {
  return new Promise<any>(async (resolve, reject) => {
    const url = config.MISCELLANEOUS_SECTORS;
    try {
      const response = await axiosDefaultConfig.get(url);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getInterestIssues = () => {
  return new Promise<any[]>(async (resolve, reject) => {
    const url = config.MISCELLANEOUS_INTEREST_ISSUES;
    try {
      const response = await axiosDefaultConfig.get(url);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};
