import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TextareaField } from '../MemoryHelpComponents/MemoryHelpsInputs';
import { useFormMemoryHelpContext } from '../../../context/MemoryHelpFormContext';

const CURRENT_STAGE = "issues";

export const OtherIssues = () => {
  const { t } = useTranslation();
  const { onInputChange, setOptionalFields } = useFormMemoryHelpContext();

  useEffect(() => {
    setOptionalFields(['aditional_info_other_issues']);
  }, [setOptionalFields]);

  return (
    <>
      <h5 className='title'>5. {t('memory_help.tabs.5')}</h5>
      <div className='bg-grey'>
        <p>{t('memory_help.container_tabs.5.description')}</p>
      </div>
      <TextareaField
        placeholder={t('memory_help.container_tabs.1.p_1_5.placeholder')}
        name='aditional_info_other_issues'
        onChange={(e) => onInputChange(e, CURRENT_STAGE)}
        currentTabId={CURRENT_STAGE} />
    </>);
};

export default OtherIssues;
