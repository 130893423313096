import React from 'react';
import { getUsersByCountryService, postLeaveListItem } from '../../../../api/contentManager';
import {
  getDefaultOption,
  getMappedUserOptions,
  getValidDate,
  hasDateError,
} from '../../../Util/ContentManager/ContentManager';
import { dataTypes } from '../../ContentManagerKeys';
import CustomDatePicker from '../../../Util/CustomDatePicker/CustomDatePicker';
import ContentManagerOrderableTable from '../../ContentManagerTable/ContentManagerOrderableTable';
import FunctionDelegationsSelects from '../FunctionDelegationsSelects';

type Props = {
  t: Function;
  displayGenericErrorMessage: Function;
  leavesList: any;
  updateFunction: any;
  countryOptions: any;
};

const currentDate = new Date();

const leavesColumns = [
  {
    id: 1,
    title: 'content_manager_country',
    sortable: true,
  },
  {
    id: 2,
    title: 'content_manager_region',
    sortable: true,
  },
  {
    id: 3,
    title: 'content_manager_delegate',
    sortable: true,
  },
  {
    id: 4,
    title: 'content_manager_from',
    sortable: false,
    width: '65px',
  },
  {
    id: 5,
    title: 'content_manager_to',
    sortable: false,
    width: '65px',
  },
  {
    id: 6,
    title: '',
    sortable: false,
    actionsColumn: true,
    width: '70px',
  },
];

const Leaves = ({ t, displayGenericErrorMessage, leavesList, updateFunction, countryOptions }: Props) => {
  const [country, setCountry] = React.useState(getDefaultOption('contents_select_placeholder', t));
  const [user, setUser] = React.useState<any>();
  const [userOptions, setUserOptions] = React.useState();
  const [errorMessage, setErrorMessage] = React.useState(false);
  const [dates, setDates] = React.useState({
    from: null,
    to: null,
  });

  const getUsersByCountry = async id => {
    const getUsersByCountryResponse = await getUsersByCountryService(id);
    setUserOptions(getMappedUserOptions(getUsersByCountryResponse));
  };

  const clearFields = () => {
    const defaultPlaceholder = getDefaultOption('contents_select_placeholder', t);
    setDates({
      from: null,
      to: null,
    });
    setCountry(defaultPlaceholder);
    setUser(defaultPlaceholder);
  };

  const handleLeaveSubmit = async () => {
    try {
      const { from, to } = dates;

      const parsedFrom = getValidDate(from);
      const parsedTo = getValidDate(to);

      if (!hasDateError(from, to)) {
        const { id: countryId }: any = country;
        const { id: delegatedUserId }: any = user;
        const leaveListItemPost = {
          countryId,
          delegatedUserId,
          from: parsedFrom,
          to: parsedTo,
        };

        await postLeaveListItem(leaveListItemPost);
        setErrorMessage(false);
        updateFunction();
        clearFields();
      } else {
        setErrorMessage(true);
      }
    } catch {
      displayGenericErrorMessage(true);
    }
  };

  React.useEffect(() => {
    if (country.id !== 0) {
      getUsersByCountry(country.id);
    }
  }, [country]);

  const renderNoOptionMsg = () => t('no_option_message');

  return (
    <>
      <div className="content-manager__container__subtitle" style={{ marginTop: '0px' }}>
        {t('content_manager_leaves_subtitle')}
      </div>
      <div className="content-manager-modal__options" style={{ marginTop: 0 }}>
        <FunctionDelegationsSelects
          idSelect1="countries_leaves"
          idSelect2="users_leaves"
          labelSelect1="content_manager_leaves_country"
          labelSelect2="content_manager_leaves_person"
          valueSelect1={country}
          valueSelect2={user}
          optionsSelect1={countryOptions}
          optionsSelect2={userOptions}
          setOptionSelect1={setCountry}
          setOptionSelect2={setUser}
          widthSelect1={310}
          widthSelect2={310}
          isDisabledSelect2={country.id === 0}
          noOptionsMessageSelect2={renderNoOptionMsg}
        />
      </div>
      <div className="content-manager__options content-manager__options--space-between">
        <div className="content-manager__date-container">
          <CustomDatePicker
            date={dates.from}
            label={t('content_manager_leaves_from')}
            setDate={date => setDates({ ...dates, from: date })}
            minDate={currentDate}
          />
          <CustomDatePicker
            date={dates.to}
            label={t('content_manager_leaves_to')}
            setDate={date => setDates({ ...dates, to: date })}
            minDate={currentDate}
            style={{ container: { marginLeft: '30px' } }}
          />
        </div>
        <button
          style={{ width: '207px', marginTop: '28px', marginRight: '200px' }}
          className="save-button-container"
          onClick={() => handleLeaveSubmit()}
        >
          {t('contents_notification_send_button_label')}
        </button>
      </div>
      {errorMessage && (
        <div className="content-manager__error-message">{t('contents_maintenance_dates_error_message')}</div>
      )}
      <div className="content-manager__container__table">
        <ContentManagerOrderableTable
          columns={leavesColumns}
          rows={leavesList}
          t={t}
          type={dataTypes.leaves}
          modalTitle={'content_manager_leaves_modal_title'}
          updateTable={updateFunction}
          setErrorMessage={displayGenericErrorMessage}
          fromButton={false}
        />
      </div>
    </>
  );
};

export default Leaves;
