import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setFilterIndicators } from '../../../redux/actions/filterIndicators';
import { setFilter } from '../../../redux/actions/filter';
import { Bubble } from '../BubbleElement/BubbleElement';
import { useTranslation } from 'react-i18next';
import { CalledFrom } from '../../../models/CalledFromEnum';
import useFilters from '../../../hooks/useFilters';
import { handleActualState } from '../UtilFunctions';

type Props = {
  indicatorCall?: boolean;
  calledFrom?: CalledFrom;
};

const CoResponsibleDivisionBubbles = (props: Props) => {
  const { indicatorCall, calledFrom } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { filterEarlyWarnings, setFilterEarlyWarnings } = useFilters();
  const filterIndicators = useSelector<any, any>(state => state.filterIndicators);
  const filterProjects = useSelector<any, any>(state => state.filter);

  const bubbles = handleActualState({
    calledFrom,
    filterEarlyWarnings,
    indicatorCall,
    filterIndicators,
    filterProjects,
    commonProp: 'coReponsibleDivisions',
  });

  const closeBubble = (divisionId: number) => {
    const actualState = handleActualState({
      calledFrom,
      filterEarlyWarnings,
      indicatorCall,
      filterIndicators,
      filterProjects,
      commonProp: 'coReponsibleDivisions',
    });

    const newState = actualState.filter(division => division.id !== divisionId);

    if (calledFrom === CalledFrom.EARLY_WARNINGS) {
      dispatch(
        setFilterEarlyWarnings({
          ...filterEarlyWarnings,
          common: {
            ...filterEarlyWarnings.common,
            coReponsibleDivisions: newState,
          },
        }),
      );
    } else {
      if (indicatorCall) {
        dispatch(
          setFilterIndicators({
            ...filterIndicators,
            common: {
              ...filterIndicators.common,
              coReponsibleDivisions: newState,
            },
          }),
        );
      } else {
        dispatch(
          setFilter({
            ...filterProjects,
            common: {
              ...filterProjects.common,
              coReponsibleDivisions: newState,
            },
          }),
        );
      }
    }
  };

  return (
    <>
      {bubbles
        .filter(division => division.selectedByUser)
        .map(division => (
          <Bubble
            key={division.id}
            itsHidden={false}
            label={`${division.label} (${t('co_responsible')})`}
            closeBubble={() => closeBubble(division.id)}
          />
        ))}
    </>
  );
};

export default CoResponsibleDivisionBubbles;
