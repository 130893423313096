import React from 'react';
import './LinkText.scss';

type Props = {
  onClick: any;
  label: any;
  customStyle?: any;
};
const LinkText = (props: Props) => {
  const { label, onClick, customStyle } = props;

  return (
    <span className="link-text-container" onClick={onClick} style={customStyle ? customStyle : {}}>
      {label}
    </span>
  );
};

export default LinkText;
