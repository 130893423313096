import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IndicatorKeyEnum } from '../../../models/IndicatorKeyEnum';
import { dispatchFilterIndicatorState } from '../../../redux/actions/filterIndicators';
import { isMobile } from '../../../services/booleanUtil';
import { DoughnutChart } from '../../Portfolio/CardIndicator/Cards/DoughnutChart/DoughnutChart';
import { getDoughnutTotalProjects } from '../PortfolioDetailsUtil';

import './DoughnutChartDetailContent.scss';

type Props = {
  currentIndicator: any;
  filterValues: any;
};
export const DoughnutChartDetailContent = ({ currentIndicator, filterValues }: Props) => {
  const { t } = useTranslation();
  const filterIndicators = useSelector<any, any>(state => state.indicatorDetailFilter);
  const dispatch = useDispatch();
  const chartDimensions = isMobile() ? { width: 150, height: 50 } : { width: 350, height: 260 };

  const datasetClick = value => {
    dispatchFilterIndicatorState(dispatch, filterIndicators, true);
    filterValues(value, getDoughnutTotalProjects(currentIndicator));
  };

  return (
    <div className="doughnut-chart-detail-container">
      <div className="chart-container">
        {
          <DoughnutChart
            card={currentIndicator}
            customStyle={setCustomStyle(currentIndicator.indicatorKey)}
            detailPage={true}
            customDimensions={chartDimensions}
            datasetClick={datasetClick}
            onFooter={setOnFooterProperty(currentIndicator.indicatorKey)}
          />
        }
      </div>
      {/* <div className="separator-line">
        <hr />
        <div className="footer-message">
          {renderFooterMessage(currentIndicator.indicatorKey, t, currentIndicator.detailExtraProps)}
        </div>
      </div> */}
    </div>
  );
};

const setCustomStyle = indicatorKey => {
  if (
    isMobile() ||
    indicatorKey === IndicatorKeyEnum.DISTRIBUTION_ACCORDING_TO_LATEST_EFAS ||
    indicatorKey === IndicatorKeyEnum.ESG_DISTRIBUTION_ACCORDING_TO_ENVIRONMENTAL_AND_SOCIAL_CATEGORY ||
    indicatorKey === IndicatorKeyEnum.PMR_STATE_DISTRIBUTION
  ) {
    return { flexFlowStyle: 'column' };
  } else {
    return { flexFlowStyle: 'row' };
  }
};

const setOnFooterProperty = indicatorKey => {
  return (
    indicatorKey === IndicatorKeyEnum.DISTRIBUTION_ACCORDING_TO_LATEST_EFAS ||
    indicatorKey === IndicatorKeyEnum.ESG_DISTRIBUTION_ACCORDING_TO_ENVIRONMENTAL_AND_SOCIAL_CATEGORY
  );
};

const renderFooterMessage = (indicatorKey, t, options: any = {}) => {
  switch (indicatorKey) {
    case IndicatorKeyEnum.PMR_STATE_DISTRIBUTION:
      return t(
        'this_chart_represents_the_distribution_of_projects_in_your_portfolio_according_to_their_last_synthetic_PMR_rating',
      );
    case IndicatorKeyEnum.DISTRIBUTION_ACCORDING_TO_LATEST_EFAS:
      return <>{t('this_chart_represents_the_projects_that_have_delivered_EFAs_in_the_last_fiscal_year')} </>;
    case IndicatorKeyEnum.ESG_DISTRIBUTION_ACCORDING_TO_ENVIRONMENTAL_AND_SOCIAL_CATEGORY:
      return t('this_graph_represents_the_distribution_of_projects_in_the_portfolio_according_to_their_last_rating');
    default:
      return '';
  }
};
