import { findMetricValue, findMetricValueByGrouping, getIndicatorData } from './indicatorHelperUtil';
import { IndicatorKeyEnum as keyEnum } from '../../../models/IndicatorKeyEnum';
import { CardTypesEnum } from '../../../models/CardTypesEnum';
import { getTabValuesFromMetrics } from './util';
import { PmrValidationStateEnum } from '../../../models/PmrValidationState';
import { PcrState } from '../../../models/PcrNextPresentation';

const getGroupingValue = (indicator, key) => {
  const filteredValues = indicator.metrics.filter(
    x => x.groupingValue === key && x.groupingName === 'ALERT_TYPE' && x.metricName === 'PROJECT_COUNT',
  );
  if (filteredValues && filteredValues.length) {
    return parseFloat(filteredValues[0].metricValue);
  } else {
    return 0;
  }
};
const haveMetrics = indicator => {
  const disbursed = indicator.metrics.find(x => x.metricName === 'OPERATION_DISBURSED_AMNT_USEQ');
  const undisbursed = indicator.metrics.find(x => x.metricName === 'OPERATION_UNDISBURSED_AMNT_USEQ');
  const total = indicator.metrics.find(x => x.metricName === 'OPERATION_CURRENT_APPROVED_AMNT_USEQ');
  if (total === undefined && undisbursed === undefined && disbursed === undefined) {
    return false;
  }
  return true;
};
const addAnnualProjDisb = (indicators, cardIndicators) => {
  const indicator = getIndicatorData(indicators, keyEnum.PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS);
  if (!indicator) {
    return;
  } else if (!indicator.metrics || indicator.metrics.length <= 1 || !haveMetrics(indicator)) {
    cardIndicators.push({
      id: indicator.id,
      indicatorKey: indicator.key,
      type: 'progress',
      title: 'disbursed_vs_pending',
      subtitle: 'annual_projection',
      detailTitle: 'disbursed_vs_pending',
      data: null,
      order: indicator.order,
    });
  } else {
    const disbursed = parseFloat(
      indicator.metrics.filter(x => x.metricName === 'OPERATION_DISBURSED_AMNT_USEQ')[0].metricValue,
    );
    const undisbursed = parseFloat(
      indicator.metrics.filter(x => x.metricName === 'OPERATION_UNDISBURSED_AMNT_USEQ')[0].metricValue,
    );
    const total = parseFloat(
      indicator.metrics.filter(x => x.metricName === 'OPERATION_CURRENT_APPROVED_AMNT_USEQ')[0].metricValue,
    );

    const percent = total === 0 ? -1 : (100 * disbursed) / total;
    const data = {
      id: indicator.id,
      indicatorKey: indicator.key,
      type: 'progress',
      title: 'disbursed_vs_pending',
      subtitle: 'annual_projection',
      detailTitle: 'disbursed_vs_pending',
      data:
        percent === -1
          ? null
          : {
              progress: parseFloat(percent.toFixed()),
              disbursed,
              pending: undisbursed,
              planned: total,
              currency: ' MM USD',
            },
      order: indicator.order,
      tabIndicatorValues: getTabValuesFromMetrics(indicator),
    };
    cardIndicators.push(data);
  }
};

const formatWords = (word: string) => {
  const wArray = word.split(' ');
  let loopValue = 0;
  let auxWord = '';
  const result: string[] = [];

  for (const element of wArray) {
    if (loopValue < 3) {
      auxWord += `${loopValue === 0 ? '' : ' '}${element}`;
      loopValue++;
    } else {
      result.push(auxWord);
      auxWord = element;
      loopValue = 1;
    }
  }
  if (auxWord !== '') {
    result.push(auxWord);
  }
  return result;
};

const getChartDataByGroupingName = (gName: string, indicatorMetrics: any[]) =>
  indicatorMetrics
    .filter(a => a.groupingName === gName && a.metricName === 'PROJECT_COUNT')
    .map(i => {
      return {
        data: i.metricValue,
        label: i.groupingValue,
        id: indicatorMetrics.find(
          a => a.groupingName === gName && a.metricName === 'ID' && a.groupingValue === i.groupingValue,
        )?.metricValue,
        mapperKey: indicatorMetrics
          .find(a => a.groupingName === gName && a.metricName === 'ID' && a.groupingValue === i.groupingValue)
          ?.metricLabel?.trim(),
      };
    });

const gcmChartData = (indicatorMetrics: any[]) => {
  const cancellationColor = '#F06D6D';
  const warningColor = '#FF8C26';
  const issueColor = '#D0DEF8';

  const datalabels = {
    align: 'end',
    anchor: 'start',
  };
  const baseDataSet = {
    label: '',
    data: [],
    borderColor: cancellationColor,
    backgroundColor: cancellationColor,
    barThickness: 18,
  };

  const cancellation = getChartDataByGroupingName('In Cancellation', indicatorMetrics);
  const warning = getChartDataByGroupingName('Warning', indicatorMetrics);
  const issue = getChartDataByGroupingName('Issue', indicatorMetrics);

  return [
    {
      status: 0,
      title: 'cts_in_cancellation',
      data: {
        labels: cancellation.map(o => formatWords(o.label)),
        datasets: [
          {
            ...baseDataSet,
            data: cancellation.map(o => o.data),
          },
        ],
        datalabels,
      },
      dataList: cancellation,
    },
    {
      status: 1,
      title: 'cts_warning',
      data: {
        labels: warning.map(o => formatWords(o.label)),
        datasets: [
          {
            ...baseDataSet,
            data: warning.map(o => o.data),
            borderColor: warningColor,
            backgroundColor: warningColor,
          },
        ],
        datalabels,
      },
      dataList: warning,
    },
    {
      status: 2,
      title: 'cts_active_issues',
      data: {
        labels: issue.map(o => formatWords(o.label)),
        datasets: [
          {
            ...baseDataSet,
            data: issue.map(o => o.data),
            borderColor: issueColor,
            backgroundColor: issueColor,
          },
        ],
        datalabels,
      },
      dataList: issue,
    },
  ];
};

const addGcmAlerts = (indicators, cardIndicators) => {
  const indicator = getIndicatorData(indicators, keyEnum.GCM_ALERTS);

  if (!indicator) {
    return;
  } else if (!indicator.metrics || indicator.metrics.length <= 1) {
    cardIndicators.push({
      id: indicator.id,
      indicatorKey: indicator.key,
      type: CardTypesEnum.PROGRESS_CHART,
      title: 'projects_with_gcm_alerts',
      subtitle: '',
      detailTitle: 'projects_with_gcm_alerts',
      data: null,
      order: indicator.order,
    });
  } else {
    const inCancellationValue = getGroupingValue(indicator, 'In Cancellation');
    const warningValue = getGroupingValue(indicator, 'Warning');
    const issueValue = getGroupingValue(indicator, 'Issue');
    const total = inCancellationValue + warningValue + issueValue;

    const inCancellation = {
      color: '#F06D6D',
      value: inCancellationValue,
      valueSuffix: inCancellationValue === 1 ? 'project' : 'menu_projects',
      label: 'in_cancellation_en',
      percentage: Math.round((inCancellationValue / total) * 100),
      status: 0,
    };

    const warning = {
      color: '#EF9206',
      value: warningValue,
      valueSuffix: warningValue === 1 ? 'project' : 'menu_projects',
      label: 'warning_en',
      percentage: Math.round((warningValue / total) * 100),
      status: 1,
    };
    const issue = {
      color: '#D0DEF8',
      value: issueValue,
      valueSuffix: issueValue === 1 ? 'project' : 'menu_projects',
      label: 'issue_en',
      percentage: Math.round((issueValue / total) * 100),
      status: 2,
    };

    cardIndicators.push({
      id: indicator.id,
      indicatorKey: indicator.key,
      type: CardTypesEnum.PROGRESS_CHART,
      title: 'projects_with_gcm_alerts',
      subtitle: '',
      detailTitle: 'projects_with_gcm_alerts',
      data: { progressData: [inCancellation, warning, issue], chartData: gcmChartData(indicator.metrics) },
      order: indicator.order,
    });
  }
};

const haveMetricsLength = indicator => {
  return !indicator.metrics || indicator.metrics.length <= 1;
};
const addPmrValidationState = (indicators, cardIndicators) => {
  const indicator = getIndicatorData(indicators, keyEnum.PMR_VALIDATION_STATE);
  if (!indicator) {
    return;
  } else if (haveMetricsLength(indicator)) {
    cardIndicators.push({
      id: indicator.id,
      indicatorKey: indicator.key,
      type: CardTypesEnum.PROGRESS_CHART,
      title: 'pmr_validation_state',
      subtitle: '',
      detailTitle: 'pmr_validation_state',
      data: null,
      order: indicator.order,
    });
  } else {
    const draftValue = findMetricValueByGrouping(
      indicator.metrics,
      'VALDTN_STG_CD',
      PmrValidationStateEnum.DRAFT,
      'PROJECT_COUNT',
    );
    const chiefOfOperationValue = findMetricValueByGrouping(
      indicator.metrics,
      'VALDTN_STG_CD',
      PmrValidationStateEnum.CHIEF_OF_OPERATIONS,
      'PROJECT_COUNT',
    );
    const chiefOfDivisionsValue = findMetricValueByGrouping(
      indicator.metrics,
      'VALDTN_STG_CD',
      PmrValidationStateEnum.CHIEF_OF_DIVISIONS,
      'PROJECT_COUNT',
    );
    const representativeValue = findMetricValueByGrouping(
      indicator.metrics,
      'VALDTN_STG_CD',
      PmrValidationStateEnum.REPRESENTATIVE,
      'PROJECT_COUNT',
    );
    const validatedValue = findMetricValueByGrouping(
      indicator.metrics,
      'VALDTN_STG_CD',
      PmrValidationStateEnum.VALIDATED,
      'PROJECT_COUNT',
    );

    const total = draftValue + chiefOfOperationValue + chiefOfDivisionsValue + representativeValue + validatedValue;
    const trailColor = '#F1F3F8';

    const trailWidth = 10;
    const strokeWidth = 10;

    const draft = {
      color: '#B1CBFF',
      value: draftValue,
      valueSuffix: draftValue === 1 ? 'project' : 'menu_projects',
      supLabelTranslation: '',
      label: 'pmr_validation_state_draft',
      percentage: Math.round((draftValue / total) * 100),
      trailColor,
      trailWidth,
      strokeWidth,
      categorization: PmrValidationStateEnum.DRAFT,
    };

    const chiefOfOperations = {
      color: '#82ABFF',
      value: chiefOfOperationValue,
      valueSuffix: chiefOfOperationValue === 1 ? 'project' : 'menu_projects',
      supLabelTranslation: 'sent_to',
      label: 'pmr_validation_state_chief_of_operations',
      percentage: Math.round((chiefOfOperationValue / total) * 100),
      trailColor,
      trailWidth,
      strokeWidth,
      categorization: PmrValidationStateEnum.CHIEF_OF_OPERATIONS,
    };
    const chiefOfDivisions = {
      color: '#538CFF',
      value: chiefOfDivisionsValue,
      valueSuffix: chiefOfDivisionsValue === 1 ? 'project' : 'menu_projects',
      supLabelTranslation: 'sent_to',
      label: 'pmr_validation_state_chief_of_divisions',
      percentage: Math.round((chiefOfDivisionsValue / total) * 100),
      trailColor,
      trailWidth,
      strokeWidth,
      categorization: PmrValidationStateEnum.CHIEF_OF_DIVISIONS,
    };

    const representative = {
      color: '#236BFF',
      value: representativeValue,
      valueSuffix: representativeValue === 1 ? 'project' : 'menu_projects',
      supLabelTranslation: 'sent_to',
      label: 'pmr_validation_state_representative',
      percentage: Math.round((representativeValue / total) * 100),
      trailColor,
      trailWidth,
      strokeWidth,
      categorization: PmrValidationStateEnum.REPRESENTATIVE,
    };
    const validated = {
      color: '#004CE4',
      value: validatedValue,
      valueSuffix: validatedValue === 1 ? 'project' : 'menu_projects',
      supLabelTranslation: 'validation',
      label: 'completed_female',
      percentage: Math.round((validatedValue / total) * 100),
      trailColor,
      trailWidth,
      strokeWidth,
      categorization: PmrValidationStateEnum.VALIDATED,
    };
    cardIndicators.push({
      id: indicator.id,
      indicatorKey: indicator.key,
      type: CardTypesEnum.PROGRESS_CHART,
      title: 'pmr_validation_state',
      subtitle: '',
      detailTitle: 'pmr_validation_state',
      data:
        total !== 0
          ? {
              progressData: [draft, chiefOfOperations, chiefOfDivisions, representative, validated],
            }
          : null,
      order: indicator.order,
    });
  }
};

const valueOrZero = value => {
  return value ? value : 0;
};

const handleSingularPlural = value => {
  return value === 1 ? 'project' : 'menu_projects';
};

const addPcrNextPresentation = (indicators, cardIndicators) => {
  const indicator = getIndicatorData(indicators, keyEnum.PCR_NEXT_PRESENTATION);
  if (!indicator) {
    return;
  } else if (haveMetricsLength(indicator)) {
    cardIndicators.push({
      id: indicator.id,
      indicatorKey: indicator.key,
      type: CardTypesEnum.DOUBLE_DATA_PROGRESS_CHART,
      title: 'upcoming_submission_of_pcr',
      subtitle: '',
      detailTitle: 'upcoming_submission_of_pcr',
      data: null,
      order: indicator.order,
    });
  } else {
    const onTimeValue = valueOrZero(findMetricValue(indicator.metrics, PcrState.OnTime.toString()));
    const pendingApprovalValue = valueOrZero(findMetricValue(indicator.metrics, PcrState.PendingApproval.toString()));
    const pendingPublicationValue = valueOrZero(
      findMetricValue(indicator.metrics, PcrState.PendindPublication.toString()),
    );

    const veryHighValue = valueOrZero(findMetricValue(indicator.metrics, PcrState.VeryHigh.toString()));
    const highValue = valueOrZero(findMetricValue(indicator.metrics, PcrState.High.toString()));
    const mediumValue = valueOrZero(findMetricValue(indicator.metrics, PcrState.Medium.toString()));

    const upcomingSubmissionTotal = onTimeValue + pendingApprovalValue + pendingPublicationValue;
    const likelyToBeSubmittedShortTermTotal = veryHighValue + highValue + mediumValue;
    const trailColor = '#F7F8FA';

    const trailWidth = 10;
    const strokeWidth = 10;

    const totalDataValue = onTimeValue + pendingApprovalValue + pendingPublicationValue;

    const onTime = {
      color: '#004CE4',
      value: onTimeValue,
      valueSuffix: handleSingularPlural(onTimeValue),
      supLabelTranslation: '',
      label: 'on_time',
      percentage: Math.round((onTimeValue / upcomingSubmissionTotal) * 100),
      trailColor,
      trailWidth,
      strokeWidth,
      categorization: PcrState.OnTime,
    };
    const pendingApproval = {
      color: '#538CFF',
      value: pendingApprovalValue,
      valueSuffix: handleSingularPlural(pendingApprovalValue),
      supLabelTranslation: '',
      label: 'vpc_mgr_approval_pending',
      percentage: Math.round((pendingApprovalValue / upcomingSubmissionTotal) * 100),
      trailColor,
      trailWidth,
      strokeWidth,
      categorization: PcrState.PendingApproval,
    };
    const pendingPublication = {
      color: '#B1CBFF',
      value: pendingPublicationValue,
      valueSuffix: handleSingularPlural(pendingPublicationValue),
      supLabelTranslation: '',
      label: 'pending_publication_on_the_internet',
      percentage: Math.round((pendingPublicationValue / upcomingSubmissionTotal) * 100),
      trailColor,
      trailWidth,
      strokeWidth,
      categorization: PcrState.PendindPublication,
    };

    const totalProyects = {
      color: '#004CE4',
      value: totalDataValue,
      valueSuffix: handleSingularPlural(totalDataValue),
      supLabelTranslation: '',
      label: 'new_PCR_in_progress',
      percentage: Math.round((totalDataValue / upcomingSubmissionTotal) * 100),
      trailColor,
      trailWidth,
      strokeWidth,
      categorization: PcrState.PendindPublication,
    };

    const veryHigh = {
      color: '#BF1313',
      value: veryHighValue,
      valueSuffix: handleSingularPlural(veryHighValue),
      supLabelTranslation: '',
      label: 'very_high',
      percentage: Math.round((veryHighValue / likelyToBeSubmittedShortTermTotal) * 100),
      trailColor,
      trailWidth,
      strokeWidth,
      categorization: PcrState.VeryHigh,
    };
    const high = {
      color: '#E92C2C',
      value: highValue,
      valueSuffix: handleSingularPlural(highValue),
      supLabelTranslation: '',
      label: 'high_pcr_next_presentation',
      percentage: Math.round((highValue / likelyToBeSubmittedShortTermTotal) * 100),
      trailColor,
      trailWidth,
      strokeWidth,
      categorization: PcrState.High,
    };
    const medium = {
      color: '#FCC0AA',
      value: mediumValue,
      valueSuffix: handleSingularPlural(mediumValue),
      supLabelTranslation: '',
      label: 'medium_pcr_next_presentation',
      percentage: Math.round((mediumValue / likelyToBeSubmittedShortTermTotal) * 100),
      trailColor,
      trailWidth,
      strokeWidth,
      categorization: PcrState.Medium,
    };

    const newVeryHigh = {
      color: '#BF1313',
      value: veryHighValue,
      valueSuffix: handleSingularPlural(veryHighValue),
      supLabelTranslation: '',
      label: 'new_PRC_very_likely_to_start_in_the_short_term',
      percentage: Math.round((veryHighValue / likelyToBeSubmittedShortTermTotal) * 100),
      trailColor,
      trailWidth,
      strokeWidth,
      categorization: PcrState.VeryHigh,
    };

    const determineCorrectCardTitle = () => {
      if (upcomingSubmissionTotal === 0 && likelyToBeSubmittedShortTermTotal !== 0) {
        return 'pcr_likely_to_be_submitted_in_the_short_term';
      }
      return 'upcoming_submission_of_pcr';
    };

    cardIndicators.push({
      id: indicator.id,
      indicatorKey: indicator.key,
      type: CardTypesEnum.DOUBLE_DATA_PROGRESS_CHART,
      title: determineCorrectCardTitle(),
      subtitle: '',
      detailTitle: 'upcoming_submission_of_pcr',
      upcomingSubmissionTotal,
      likelyToBeSubmittedShortTermTotal,
      data:
        upcomingSubmissionTotal !== 0 || likelyToBeSubmittedShortTermTotal !== 0
          ? {
              firstDataGroup: [onTime, pendingApproval, pendingPublication],
              secondDataGroup: [veryHigh, high, medium],
              thirdDataGroup: [totalProyects, newVeryHigh],
            }
          : null,

      order: indicator.order,
    });
  }
};

export {
  addAnnualProjDisb,
  addGcmAlerts,
  addPmrValidationState,
  getGroupingValue,
  haveMetrics,
  formatWords,
  getChartDataByGroupingName,
  gcmChartData,
  addPcrNextPresentation,
};
