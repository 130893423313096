import React from 'react';
import { useTranslation } from 'react-i18next';
import { BidTooltip } from '../../Tooltip/Tooltip';
import styles from './EyeButton.module.scss';

type Props = {
  follow: boolean;
  handleClick: any;
};

const { eyeFollow, eyeUnfollow } = styles;
const EyeButton = ({ follow, handleClick }: Props) => {
  const { t } = useTranslation();

  return follow ? (
    <>
      <BidTooltip arrow title={t('following').toString()} placement="bottom-start" enterDelay={500} leaveDelay={200}>
        <span onClick={handleClick} className={eyeUnfollow} />
      </BidTooltip>
    </>
  ) : (
    <>
      <BidTooltip arrow title={t('follow').toString()} placement="bottom-start" enterDelay={500} leaveDelay={200}>
        <span onClick={handleClick} className={eyeFollow} />
      </BidTooltip>
    </>
  );
};

export default EyeButton;
