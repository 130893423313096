import React from 'react';

import { ReactComponent as PmrGreen } from '../../../assets/icons/u_alert-check.svg';
import { ReactComponent as PmrYellow } from '../../../assets/icons/u_alert-exclamacion.svg';
import { ReactComponent as PmrRed } from '../../../assets/icons/u_alert-multyple.svg';
import { ReactComponent as PmrGray } from '../../../assets/icons/u_alert-not-assigned.svg';
import { PmrClassification } from '../../../models/PmrClassification';

export const PmrClassificationComponent = (props: any) => {
  const colorClassification: PmrClassification = props.classificationColor;

  return colorClassification === PmrClassification.Green ? (
    <PmrGreen />
  ) : colorClassification === PmrClassification.Yellow ? (
    <PmrYellow />
  ) : colorClassification === PmrClassification.Red ? (
    <PmrRed />
  ) : (
    <PmrGray />
  );
};
