import { getIndicatorData, metricIsEmpty } from './indicatorHelperUtil';
import { IndicatorKeyEnum as keyEnum } from '../../../models/IndicatorKeyEnum';

const addCalendarIndicator = (indicators, cardIndicators, indicatorOptions) => {
  const indicator = getIndicatorData(indicators, keyEnum.CALENDAR_EVENTS);

  if (!indicator) {
    return;
  } else if (metricIsEmpty(indicator)) {
    cardIndicators.push({
      id: indicator.id,
      indicatorKey: indicator.key,
      type: 'calendar',
      title: 'calendar',
      detailTitle: `${indicatorOptions.titleFromTab}`,
      data: null,
      order: indicator.order,
    });
  } else {
    const datesToRender = indicator.metrics.map(data => new Date(data.metricLabel.replace(' ', 'T')));

    const dataIndicator = {
      id: indicator.id,
      indicatorKey: indicator.key,
      type: 'calendar',
      title: 'calendar',
      detailTitle: `calendar_of ${indicatorOptions.titleFromTab}`,
      order: indicator.order,
      data: datesToRender,
    };

    cardIndicators.push(dataIndicator);
  }
};

export { addCalendarIndicator };
