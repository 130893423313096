import React from 'react';
import './SelectReportFilters.scss';
import { useTranslation } from 'react-i18next';
import { CustomizableReportType } from '../../../../../models/CustomizableReportType';
import { FilterReport } from './FilterReport/FilterReport';
import { CustomizableReportFilters } from '../Components/CustomizableReportFilters';
import FilterIndicators from '../../../../../models/FilterIndicators';
import SaveButton from '../../../Buttons/SaveButton/SaveButton';
import { useCustomizableReportsContext } from '../CustomizableReportsContext';
import { useSelector } from 'react-redux';

type Props = {
  generate: Function;
  next: Function;
};

export const SelectReportFilters = ({ generate, next }: Props) => {
  const { t } = useTranslation();
  const [disabledButtonWhenClick, setDisabledButtonWhenClick] = React.useState(false);
  const globalReportFilters = useSelector<any, FilterIndicators>(state => state.filterIndicators);

  const [context, setContext] = useCustomizableReportsContext();

  React.useEffect(() => {
    if (context.reportType === CustomizableReportType.Predefined) {
      setContext({ ...context, reportFilters: JSON.parse(JSON.stringify(globalReportFilters)) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNext = () => {
    if (context.reportType === CustomizableReportType.Predefined) {
      openReportModal();
    } else {
      next();
    }
  };

  const openReportModal = async () => {
    setDisabledButtonWhenClick(true);
    await generate(context.reportFilters);
  };

  return (
    <div className="select-report-filters">
      <p className="modal-title">
        {t('customizable_report_title')} / <span>{t('customizable_report_step_2')}</span>
      </p>

      <div className="modal-content">
        <h3>{t('customizable_report_step_2_title')}</h3>

        {context.reportType === CustomizableReportType.Predefined ? (
          <div className="filters-section options-list">
            <span className="subtitle">{t('a_report_will_be_generated_with_the_following_filters')}</span>
            <FilterReport
              reportFilterOriginal={globalReportFilters}
              returnedReportFilter={newReportFilters => setContext({ ...context, reportFilters: newReportFilters })}
            />
          </div>
        ) : (
          <div className="filters-section applied-filters-list">
            <CustomizableReportFilters />
          </div>
        )}

        <div className="buttons-section">
          <SaveButton
            handleClick={handleNext}
            disabled={disabledButtonWhenClick}
            customText={context.reportType === CustomizableReportType.Custom ? t('next') : t('generate')}
          />
        </div>
      </div>
    </div>
  );
};
