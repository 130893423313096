import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import '../HelpCenterModalForm/HelpCenterModalForm.scss';
import { postHelpRequestAnswer } from '../../../../api/contentManager';

type Props = {
  closeModal: Function;
  info: any;
  displayGenericErrorMessage: any;
  updateFunction: any;
};

export default function EmailQuestionModal({ closeModal, info, displayGenericErrorMessage, updateFunction }: Props) {
  const { question, sender, received, category, answer: propsAnswer, id, isNew } = info;
  const { t } = useTranslation();

  const [answer, setAnswer] = React.useState(propsAnswer);

  const handleAnswerQuestion = async () => {
    try {
      await postHelpRequestAnswer({
        answer,
        helpRequestId: id,
      });

      updateFunction();
      closeModal();
    } catch {
      displayGenericErrorMessage(true);
    }
  };

  const handleFormChange = e => {
    e.preventDefault();
    setAnswer(e.target.value);
  };

  return (
    <>
      <div className="help-center-modal-form-container">
        <div className="question-info">
          <div className="question-info__item">
            <div className="question-info__item--title">{t('contents_helpCenter_emailQuestions_category_label')}</div>
            <div className="question-info__item--text">{t(category)}</div>
          </div>
          <div className="question-info__item">
            <div className="question-info__item--title">{t('contents_helpCenter_emailQuestions_received_label')}</div>
            <div className="question-info__item--text">{`${moment
              .utc(received)
              .local()
              .format('DD/MM/YYYY - HH:mm')} hs`}</div>
          </div>
          <div className="question-info__item">
            <div className="question-info__item--title">{t('contents_helpCenter_emailQuestions_from_label')}</div>
            <div className="question-info__item--text">{sender}</div>
          </div>
        </div>
        <div className="horizontal-row">
          <div className="label-select-container text-area">
            <p className="label">{t('contents_helpCenter_emailQuestions_question_textarea_label')}</p>
            <textarea value={question} onChange={e => handleFormChange(e)} disabled />
          </div>
        </div>
        <div className="horizontal-row">
          <div className="label-select-container text-area">
            <p className="label">{t('contents_helpCenter_emailQuestions_answer_textarea_label')}</p>
            <textarea value={answer} name={`answer`} onChange={e => handleFormChange(e)} disabled={!isNew}></textarea>
          </div>
        </div>
        <div className="content-manager-modal__button-group">
          {isNew && (
            <button className="content-manager-modal__button" onClick={() => handleAnswerQuestion()}>
              {t('contents_helpCenter_emailQuestions_answer_button_label')}
            </button>
          )}
          <button
            style={{ width: '195px' }}
            className="content-manager-modal__button--secondary"
            onClick={() => closeModal()}
          >
            {t('contents_helpCenter_emailQuestions_close_button_label')}
          </button>
        </div>
      </div>
    </>
  );
}
