import React from 'react';
import { useTranslation } from 'react-i18next';
import { Bubble } from '../BubbleElement/BubbleElement';
import { getValidBubble, handleBubbles } from '../UtilFunctions';
import useFilters from '../../../hooks/useFilters';
import { CalledFrom } from '../../../models/CalledFromEnum';
import { IFilterBubble } from '../../../models/FilterBubble';

type Props = IFilterBubble;

const TimeWithoutBubbles = (props: Props) => {
  const { indicatorCall, calledFrom } = props;
  const {
    filter,
    filterIndicators,
    dispatch,
    setFilter,
    setFilterIndicators,
    setFilterEarlyWarnings,
    filterEarlyWarnings,
  } = useFilters();
  const bubbles = handleBubbles({ indicatorCall, filterIndicators, filter, filterEarlyWarnings, calledFrom });
  const { t } = useTranslation();
  const label = t('time_without_performing_disbursements');
  const defaultMaxValue = 0;
  const defaultMinValue = 0;

  const closeBubble = () => {
    handleDispatch({
      calledFrom,
      dispatch,
      setFilterEarlyWarnings,
      filterEarlyWarnings,
      defaultMinValue,
      defaultMaxValue,
      indicatorCall,
      setFilterIndicators,
      filterIndicators,
      setFilter,
      filter,
    });
  };

  const returnedBubble = getValidBubble(
    bubbles.timeWithoutDisbursementFrom,
    bubbles.timeWithoutDisbursementTo,
    label,
    'day',
    'days',
  );
  return (
    <Bubble
      itsHidden={returnedBubble.itsHidden}
      label={returnedBubble.label}
      value={
        returnedBubble.value === 'between'
          ? t('between') +
            bubbles.timeWithoutDisbursementFrom +
            t('and') +
            bubbles.timeWithoutDisbursementTo +
            t('days')
          : returnedBubble.value === 'gt'
          ? t('greater_than') +
            bubbles.timeWithoutDisbursementFrom +
            (bubbles.timeWithoutDisbursementFrom === 1
              ? t(returnedBubble.translationSingular)
              : t(returnedBubble.translationPlural))
          : t('less_than') +
            bubbles.timeWithoutDisbursementTo +
            (bubbles.timeWithoutDisbursementTo === 1
              ? t(returnedBubble.translationSingular)
              : t(returnedBubble.translationPlural))
      }
      closeBubble={closeBubble}
    />
  );
};

const handleDispatch = ({
  calledFrom,
  dispatch,
  setFilterEarlyWarnings,
  filterEarlyWarnings,
  defaultMinValue,
  defaultMaxValue,
  indicatorCall,
  setFilterIndicators,
  filterIndicators,
  setFilter,
  filter,
}) => {
  if (calledFrom === CalledFrom.EARLY_WARNINGS) {
    dispatch(
      setFilterEarlyWarnings({
        ...filterEarlyWarnings,
        common: {
          ...filterEarlyWarnings.common,
          timeWithoutDisbursementFrom: defaultMinValue,
          timeWithoutDisbursementTo: defaultMaxValue,
        },
      }),
    );
  } else {
    if (indicatorCall) {
      dispatch(
        setFilterIndicators({
          ...filterIndicators,
          common: {
            ...filterIndicators.common,
            timeWithoutDisbursementFrom: defaultMinValue,
            timeWithoutDisbursementTo: defaultMaxValue,
          },
        }),
      );
    } else {
      dispatch(
        setFilter({
          ...filter,
          common: {
            ...filter.common,
            timeWithoutDisbursementFrom: defaultMinValue,
            timeWithoutDisbursementTo: defaultMaxValue,
            itSearch: false,
          },
        }),
      );
    }
  }
};
export default TimeWithoutBubbles;
