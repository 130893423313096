import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectsWithEarlyWarnings } from '../../../api/earlyWarningApi';
import {
  EarlyWarningButtonType,
  EWProjectResponse,
  IconColorsEnum,
  IEarlyWarningsProjects,
  LoadingState,
  ModuleButton,
} from '../../../models/EarlyWarningsSection';
import {
  setEarlyWarningsProjects,
  updateLoadingStateEarlyWarningsProjects,
} from '../../../redux/actions/earlyWarningsProjects';
import { setFilterEarlyWarningsModuleButtons } from '../../../redux/actions/filterEarlyWarnings';
import { ProjectsListSkeleton } from '../../LoadersSkeletons/ProjectsListSkeleton/ProjectsListSkeleton';
import { AppMenuTabs } from '../../MenuTabs/userTabsInterface';
import { EmptySearchResult } from '../../Util/EmptySearchResult/EmptySearchResult';
import { AlertsButtonsModule } from '../AlertsButtonsModule/AlertsButtonsModule';
import {
  handleDisbursementExpired,
  handleElegibleUnisbursementAlert,
  handleLast3Pmr,
  handlePartialCancellations,
  handlePendingSignatureAlert,
  handleSafeguardPerformance,
} from '../ProjectsWithEarlyWarningsTable/IconRenderByColumn';
import { ProjectsWithEarlyWarningsTable } from '../ProjectsWithEarlyWarningsTable/ProjectsWithEarlyWarningsTable';
import {
  filterProjectsByAlert,
  sumCancelled,
  sumElegibleUnDisbursement,
  sumesgSafeguard,
  sumExpired,
  sumLastPmr,
  sumPendigSignature,
} from '../Util';
import styles from './ProjectsSection.module.scss';
import { handleFilterAlertByButtonEWTYpe, handleFilterProjects } from './Util';

const { projectSectionContainer, tableLoadingContainer } = styles;

interface IHandleButtonQuantities {
  projects: EWProjectResponse[];
  buttons: ModuleButton[];
}

const handleButtonQuantities = ({ projects, buttons }: IHandleButtonQuantities): ModuleButton[] => {
  const pendigSignatureProjects = filterProjectsByAlert(projects, EarlyWarningButtonType.PROJECTS_PENDING_SIGNATURE);
  const eligibleUnDisbursementProjects = filterProjectsByAlert(
    projects,
    EarlyWarningButtonType.ELIGIBLE_PROJECTS_PENDING_FIRST_DISBURSEMENT,
  );
  const lastPmrProjects = filterProjectsByAlert(
    projects,
    EarlyWarningButtonType.PROJECTS_WITH_RECENT_ALERT_OR_PROBLEM_CLASSIFICATIONS,
  );
  const esgSafeguardProjects = filterProjectsByAlert(
    projects,
    EarlyWarningButtonType.PROJECTS_WITH_UNSATISFACTORY_PERFORMANCE_IN_SAFEGUARDS,
  );
  const cancelledProjects = filterProjectsByAlert(projects, EarlyWarningButtonType.PROJECTS_WITH_PARTIAL_CANCELLATIONS);
  const expiredProjects = filterProjectsByAlert(
    projects,
    EarlyWarningButtonType.PROJECTS_WITH_EXPIRED_DISBURSEMENT_DEADLINE,
  );

  const { YELLOW: pendingSignatureYellow, RED: pendingSignatureRed } = sumPendigSignature(pendigSignatureProjects);
  const { YELLOW: eligibleUnDisbursementYellow, RED: eligibleUnDisbursementRed } =
    sumElegibleUnDisbursement(eligibleUnDisbursementProjects);
  const { YELLOW: lastPmrYellow, RED: lastPmrRed } = sumLastPmr(lastPmrProjects);
  const { YELLOW: esgSafeguardYellow, RED: esgSafeguardRed } = sumesgSafeguard(esgSafeguardProjects);
  const { YELLOW: cancelledYellow, RED: cancelledRed } = sumCancelled(cancelledProjects);
  const { YELLOW: expiredYellow, RED: expiredRed } = sumExpired(expiredProjects);
  const alertsQuantities = {
    pendingSignature: pendingSignatureYellow + pendingSignatureRed,
    eligibleUnDisbursement: eligibleUnDisbursementYellow + eligibleUnDisbursementRed,
    pmr: lastPmrYellow + lastPmrRed,
    esgSafeguard: esgSafeguardYellow + esgSafeguardRed,
    cancelled: cancelledYellow + cancelledRed,
    expired: expiredYellow + expiredRed,
  };

  return buttons.map(btn => {
    btn.quantity = alertsQuantities[btn.buttonEWType];
    return btn;
  });
};

type ProjectsProp = {
  waitingResponse: Function;
};

export const ProjectsSection = ({ waitingResponse }: ProjectsProp) => {
  const [loadingData, setLoadingData] = useState(false);
  const minSelectedBtnQty = 0;
  const menuTabs = useSelector<any, AppMenuTabs>(state => state.menuTabs);
  const filterEarlyWarnings = useSelector<any, any>(state => state.filterEarlyWarnings);
  const { projects: earlyWarningsProjects, loadingState } = useSelector<any, IEarlyWarningsProjects>(
    state => state.earlyWarningsProjects,
  );
  const dispatch = useDispatch();
  const { common, buttonsModule } = filterEarlyWarnings;
  const [projects, setProjects] = useState<EWProjectResponse[]>(earlyWarningsProjects);
  const getProjects = async () => {
    setLoadingData(true);
    waitingResponse(true);
    const projectsResponse = await getProjectsWithEarlyWarnings(filterEarlyWarnings, menuTabs);
    const projectsToFilter: EWProjectResponse[] = addVirtualColumns([...projectsResponse]);
    const earlyWarningsProjectsObject = {
      projects: projectsToFilter,
      loadingState: LoadingState.firstIn,
    };
    dispatch(
      setFilterEarlyWarningsModuleButtons(
        handleButtonQuantities({ projects: projectsResponse, buttons: buttonsModule }),
      ),
    );
    dispatch(setEarlyWarningsProjects(earlyWarningsProjectsObject));

    const selectedButtons = [...buttonsModule.filter(btn => btn.selected)];
    const selectedButtonsQuantity = selectedButtons.length;

    if (selectedButtonsQuantity === 0) {
      setProjects(projectsToFilter);
    } else if (selectedButtonsQuantity > 0) {
      handleFrontEndFilterProjects({
        selectedButtonsQuantity,
        minSelectedBtnQty,
        setProjects,
        earlyWarningsProjects: earlyWarningsProjectsObject,
        buttonsModule,
        projectsToFilter,
        selectedButtons,
      });
    }
    setLoadingData(false);
    waitingResponse(false);
  };
  useEffect(() => {
    if (loadingState === LoadingState.backFromProjectDetail) {
      dispatch(updateLoadingStateEarlyWarningsProjects(LoadingState.firstIn));
      const selectedButtons = [...buttonsModule.filter(btn => btn.selected)];
      const selectedButtonsQuantity = selectedButtons.length;
      if (selectedButtonsQuantity > 0) {
        const projectsToFilter: EWProjectResponse[] = [...earlyWarningsProjects];
        handleFrontEndFilterProjects({
          selectedButtonsQuantity,
          minSelectedBtnQty,
          setProjects,
          earlyWarningsProjects,
          buttonsModule,
          projectsToFilter,
          selectedButtons,
        });
      }
    } else {
      getProjects();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [common, menuTabs]);

  const handleClickAlertsButtonsModule = (buttonData: ModuleButton) => {
    const updatedButtons = buttonsModule.map(btn => {
      if (btn.buttonEWType === buttonData.buttonEWType) {
        return { ...btn, selected: !buttonData.selected };
      }
      return btn;
    });

    const selectedButtons = [...updatedButtons.filter(btn => btn.selected)];
    const selectedButtonsQuantity = selectedButtons.length;

    const projectsToFilter: EWProjectResponse[] = [...earlyWarningsProjects];

    handleFrontEndFilterProjects({
      selectedButtonsQuantity,
      minSelectedBtnQty,
      setProjects,
      earlyWarningsProjects,
      buttonsModule: updatedButtons,
      projectsToFilter,
      selectedButtons,
    });
    dispatch(setFilterEarlyWarningsModuleButtons(updatedButtons));
  };
  return (
    <div className={projectSectionContainer}>
      <AlertsButtonsModule
        buttons={buttonsModule}
        handleClick={handleClickAlertsButtonsModule}
        loadingData={loadingData}
      />
      {!loadingData ? (
        projects.length ? (
          <ProjectsWithEarlyWarningsTable projects={projects} />
        ) : (
          <EmptySearchResult customTitle="no_results" />
        )
      ) : (
        <div className={tableLoadingContainer}>
          <ProjectsListSkeleton />
        </div>
      )}
    </div>
  );
};

const handleFrontEndFilterProjects = ({
  selectedButtonsQuantity,
  minSelectedBtnQty,
  setProjects,
  earlyWarningsProjects,
  buttonsModule,
  projectsToFilter,
  selectedButtons,
}) => {
  if (selectedButtonsQuantity === minSelectedBtnQty) {
    setProjects(earlyWarningsProjects);
  } else if (selectedButtonsQuantity === 1) {
    buttonsModule.forEach(btn => {
      if (btn.selected && btn.quantity !== 0) {
        projectsToFilter = projectsToFilter.filter(
          p => p[btn.buttonEWType] !== null && handleFilterAlertByButtonEWTYpe(btn.buttonEWType, p),
        );
      }
    });

    setProjects(projectsToFilter);
  } else {
    setProjects(handleFilterProjects({ projectsToFilter, selectedButtons }));
  }
};

const normalizeIcon = (icon: IconColorsEnum | null) => {
  if (icon === IconColorsEnum.HOLLOW_YELLOW || icon === IconColorsEnum.FILLED_YELLOW) {
    return IconColorsEnum.FILLED_YELLOW;
  } else if (icon === IconColorsEnum.HOLLOW_RED || icon === IconColorsEnum.FILLED_RED) {
    return IconColorsEnum.FILLED_RED;
  } else if (icon === IconColorsEnum.GREEN) {
    return IconColorsEnum.GREEN;
  } else {
    return IconColorsEnum.NOT_APPLICABLE;
  }
};

const addVirtualColumns = projects => {
  return projects.map(p => {
    const sortablePendingSignature = normalizeIcon(
      handlePendingSignatureAlert(p.pendingSignatureWithColor, p.pendingSignature),
    );
    const sortableElegibleUndisbursement = normalizeIcon(
      handleElegibleUnisbursementAlert(p.eligibleUnDisbursementWithColor, p.eligibleUnDisbursement),
    );
    const sortablePmrColumn = handleLast3Pmr(p.pmrWithColor, p.pmr);
    const sortableSafeguardPerformance = handleSafeguardPerformance(p.esgSafeguardWithColor, p.esgSafeguard);
    const sortablePartialCancellations = normalizeIcon(handlePartialCancellations(p.cancelledWithColor, p.cancelled));
    const sortableDisbursementExpired = normalizeIcon(handleDisbursementExpired(p.expiredWithColor, p.expired));
    return {
      ...p,
      sortablePendingSignature,
      sortableElegibleUndisbursement,
      sortablePmrColumn,
      sortableSafeguardPerformance,
      sortablePartialCancellations,
      sortableDisbursementExpired,
    };
  });
};
