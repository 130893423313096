import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Header from '../../components/Header/Header';
import { ReviewAccordancesTable } from '../../components/Reviews/ReviewAccordancesTable/ReviewAccordancesTable';
import BackButton from '../../components/Util/Buttons/BackButton/BackButton';
import { AccordanceState } from '../../models/AccordanceState';
import { replaceMomentFormat } from '../../services/util';
import { getIdFromUrl } from '../../services/numberUtil';
import { isMobile } from '../../services/booleanUtil';
import { sortAlphabetically, sortNumbers, sortDates, sortNested } from '../../services/sortsUtil';
import './ReviewAccordances.scss';
import { getAccordancesOfReview, getReviewById } from '../../api/reviewsApi';
import { SortParameterAccordance } from '../../models/SortParameterAccordanceEnum';
import { useSelector } from 'react-redux';
import { ReviewAccordancesMobile } from '../../components/Reviews/ReviewAccordancesMobile/ReviewAccordancesMobile';
import { SearchRender } from '../../components/Util/CustomInput/SearchRender/SearchRender';
import { HeaderAccordancesSummary } from './HeaderAccordancesSummary/HeaderAccordancesSummary';

export const ReviewAccordances = () => {
  const dateFormat = 'DD/MMM/YYYY';
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { currentReview } = useSelector<any, any>(state => state);

  const [orderAsc, setOrderAsc] = useState(true);
  const [existPendingAccordances, setExistPendingAccordances] = useState(false);
  const [selectedReview, setSelectedReview] = useState({ revisionNumber: 0, startDate: '', endDate: '' });
  const [accordancesOfReview, setAccordancesOfReview] = useState<any[]>([]);
  const [accordancesOfReviewToSearch, setAccordancesOfReviewToSearch] = useState<any[]>([]);

  const handleExistPendigAccordances = accordancesOfReview => {
    const pending = accordancesOfReview.filter(accordance => accordance.accordanceState === AccordanceState.PENDING);
    pending.length > 0 ? setExistPendingAccordances(true) : setExistPendingAccordances(false);
  };

  useEffect(() => {
    const queryStrings = queryString.parse(location.search);
    handleQueryString(queryStrings, currentReview, setSelectedReview, getSelectedReview, getReviewccordances, history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleExistPendigAccordances(accordancesOfReview);
    accordancesOfReview.sort(sortNumbers('accordanceState', 'asc'));
  }, [accordancesOfReview]);

  const getSelectedReview = async reviewId => {
    try {
      const review = await getReviewById(reviewId);
      setSelectedReview(review);
    } catch (error) {
      history.push('/home');
    }
  };

  const getReviewccordances = async reviewId => {
    const reviewAccordances = await getAccordancesOfReview(reviewId);
    setAccordancesOfReview(reviewAccordances);
    setAccordancesOfReviewToSearch(reviewAccordances);
  };
  const goBack = () => {
    history.goBack();
  };

  const refreshTableData = () => {
    getReviewccordances(getIdFromUrl(history.location.search));
  };

  const handleSorting = conceptToSort => {
    const order = orderAsc ? 'asc' : 'desc';

    switch (conceptToSort) {
      case SortParameterAccordance.ProjectName:
        accordancesOfReview.sort(sortNested('name', order, 'project'));
        break;
      case SortParameterAccordance.CriticalIssueName:
        accordancesOfReview.sort(sortNested('name', order, 'criticalIssue'));
        break;
      case SortParameterAccordance.Description:
        accordancesOfReview.sort(sortAlphabetically('description', order));
        break;
      case SortParameterAccordance.AccordanceAbout:
        accordancesOfReview.sort(sortNested('name', order, 'possibleAction'));
        break;
      case SortParameterAccordance.ExpirationDate:
        accordancesOfReview.sort(sortDates('expirationDate', order));
        break;
      case SortParameterAccordance.Responsabile:
        accordancesOfReview.sort(sortNested('name', order, 'personInCharge'));
        break;
      case SortParameterAccordance.AccordanceState:
        accordancesOfReview.sort(sortNumbers('accordanceState', order));
        break;
      default:
        accordancesOfReview.sort(sortNested('code', order, 'project'));
        break;
    }

    setOrderAsc(!orderAsc);
  };

  const handleSearch = e => {
    const term = e.target.value;
    const searchResult: any[] = filterOnSearch(accordancesOfReviewToSearch, term);
    setAccordancesOfReview(searchResult);
  };

  return (
    <>
      <Header />
      <div className="review-accordances-container">
        <div className="header-container">
          <div className="back-button">
            <BackButton handleClick={goBack} />
          </div>
          <div className="title">
            <h1>
              {`${t('accordances')} ${t('of')} `}
              {isMobile() && <br />}
              <span className="bolder">
                {`${t('revision_number_'.concat(selectedReview.revisionNumber.toString()))} `}
                {t('review')} {moment(selectedReview.startDate).year()}
              </span>
            </h1>
          </div>
          <div className="start-grid" />
          <div className="date-range">{` ${replaceMomentFormat(
            moment(selectedReview.startDate).format(dateFormat),
          )} ${t('to')} ${replaceMomentFormat(moment(selectedReview.endDate).format(dateFormat))} `}</div>

          <HeaderAccordancesSummary data={accordancesOfReview} />

          <div id="alert-and-search">
            {alertSection(existPendingAccordances, t)}
            {!isMobile() && (
              <div className="search-container">
                <SearchRender onChangeHandler={e => handleSearch(e)} placeholder={'searchPlaceholder'} />
              </div>
            )}
          </div>
        </div>
        <div className="table-container">
          {isMobile() ? (
            <ReviewAccordancesMobile accordances={accordancesOfReview} refreshData={refreshTableData} />
          ) : (
            <ReviewAccordancesTable
              accordances={accordancesOfReview}
              sortConcept={handleSorting}
              refreshData={refreshTableData}
            />
          )}
        </div>
      </div>
    </>
  );
};

const alertSection = (existPendingAccordances, t) => {
  return (
    <div className={existPendingAccordances ? 'alert' : 'no-alert'}>
      <span>{existPendingAccordances && t('there_are_agreements_pending_approval')}</span>
    </div>
  );
};

const filterOnSearch = (accordancesOfReviewToSearch, term) => {
  return accordancesOfReviewToSearch.filter(
    accordance =>
      accordance.project.name.toLowerCase().includes(term) ||
      accordance.project.code.toLowerCase().includes(term) ||
      (accordance.project.withLoans && accordance.project.loanNumber.toLowerCase().includes(term)),
  );
};

const handleQueryString = (
  queryStrings,
  currentReview,
  setSelectedReview,
  getSelectedReview,
  getReviewccordances,
  history,
) => {
  if (queryStrings.id) {
    currentReview ? setSelectedReview(currentReview) : getSelectedReview(parseInt(queryStrings.id.toString()));
    getReviewccordances(parseInt(queryStrings.id.toString()));
  } else {
    history.push('/home');
  }
};
