import { store } from '../redux/store';
import { FilterTypesEnum } from '../models/FitlerTypesEnum';
import { setReturnedValue, getAgeFilterReturnedValue, setHaveFiltersInputsAndSelects } from './filterSharedUtil';
import { parseFilterToApiCall, duplicateToUniqueKey, getMergedFilterArray } from '../services/util';
import { AppMenuTabs, Filter, MenuTabs, MenuTabSectionEnum } from '../components/MenuTabs/userTabsInterface';
import SelectOption from '../models/SelectOption';
import { ProjectProductTypes } from '../models/ProjectProductTypes';
import {
  AttributeFilterValue,
  DiscriminatorFilterValue,
  ProjectVsCompletedIndicatorFilterField,
  SelectOptionId,
} from '../models/enums/ProjectedVsCompletedIndicatorFilter';

export const getPmrClassificationFilter = (indicatorCall, filter?: any) => {
  const pmrs = filter
    ? filter.common.pmrId
    : indicatorCall
    ? store.getState().filterIndicators.common.pmrId
    : store.getState().filter.common.pmrId;
  const actualState = pmrs.filter(pmr => pmr.selected === true);
  const actualArray = Array.from(actualState, x => x.PmrClassification);
  return {
    filterType: FilterTypesEnum.NumberIn,
    field: 'PmrClassification',
    value: actualArray,
  };
};

export const getPendingDisbursement = (indicatorCall, filter?: any) => {
  const actualStateFrom = filter
    ? filter.common.disbursementProjectionFrom
    : indicatorCall
    ? store.getState().filterIndicators.common.disbursementProjectionFrom
    : store.getState().filter.common.disbursementProjectionFrom;
  const actualStateTo = filter
    ? filter.common.disbursementProjectionTo
    : indicatorCall
    ? store.getState().filterIndicators.common.disbursementProjectionTo
    : store.getState().filter.common.disbursementProjectionTo;
  const isAnnualField = filter
    ? filter.common.isAnnual
    : indicatorCall
    ? store.getState().filterIndicators.common.isAnnual
    : store.getState().filter.common.isAnnual;

  const field = isAnnualField === 'annual' ? 'PendingDisbursementAnnualAmount' : 'DisbursementProjection';
  return setReturnedValue(actualStateFrom, actualStateTo, field);
};

export const getAgeOfProjectFilter = (indicatorCall, filter?: any) => {
  if (filter) {
    return getAgeFilterReturnedValue(
      filter.common.ageProjectFrom,
      filter.common.ageProjectTo,
      filter.common.ageProjectMonths,
    );
  }
  const actualStateMonths = indicatorCall
    ? store.getState().filterIndicators.common.ageProjectMonths
    : store.getState().filter.common.ageProjectMonths;
  const actualStateFrom = indicatorCall
    ? store.getState().filterIndicators.common.ageProjectFrom
    : store.getState().filter.common.ageProjectFrom;
  const actualStateTo = indicatorCall
    ? store.getState().filterIndicators.common.ageProjectTo
    : store.getState().filter.common.ageProjectTo;

  return getAgeFilterReturnedValue(actualStateFrom, actualStateTo, actualStateMonths);
};

export const getLastDisbursementInDays = (indicatorCall, filter?: any) => {
  const actualStateFrom = filter
    ? filter.common.timeWithoutDisbursementFrom
    : indicatorCall
    ? store.getState().filterIndicators.common.timeWithoutDisbursementFrom
    : store.getState().filter.common.timeWithoutDisbursementFrom;
  const actualStateTo = filter
    ? filter.common.timeWithoutDisbursementTo
    : indicatorCall
    ? store.getState().filterIndicators.common.timeWithoutDisbursementTo
    : store.getState().filter.common.timeWithoutDisbursementTo;
  const field = 'LastDisbursementInDays';
  return setReturnedValue(actualStateFrom, actualStateTo, field);
};

export const getSectorFromFilter = indicatorCall => {
  const actualState = indicatorCall
    ? store.getState().filterIndicators.common.sectors
    : store.getState().filter.common.sectors;

  const sectors = actualState.map(sector => {
    return sector.id;
  });
  return {
    filterType: FilterTypesEnum.NumberIn,
    field: 'SectorId',
    value: sectors,
  };
};

export const getTaxonomyFilter = (indicatorCall, filter?: any) => {
  const actualState = filter
    ? filter.common.taxonomyFilter
    : indicatorCall
    ? store.getState().filterIndicators.common.taxonomyFilter
    : store.getState().filter.common.taxonomyFilter;

  return {
    filterType: FilterTypesEnum.NumberIn,
    field: 'TCPTaxonomyId',
    value: [actualState && actualState.id],
  };
};

export const getExecutionFilter = (indicatorCall, filter?: any) => {
  const actualState = filter
    ? filter.common.executionFilter
    : indicatorCall
    ? store.getState().filterIndicators.common.executionFilter
    : store.getState().filter.common.executionFilter;

  return {
    filterType: FilterTypesEnum.NumberIn,
    field: 'ProjectExecutorEntity',
    value: [actualState && actualState.id],
  };
};

export const getExcludeRegionalFilter = () => {
  return {
    filterType: FilterTypesEnum.NumberIn,
    field: 'BeneficiaryType',
    value: [0],
  };
};

export const getDivisionFromFilter = (indicatorCall, filter?: any) => {
  let actualState;
  if (filter) {
    actualState = filter.common.divisionId;
  } else {
    actualState = indicatorCall
      ? store.getState().filterIndicators.common.divisionId
      : store.getState().filter.common.divisionId;
  }
  const divisionsId = actualState.map(division => {
    return division.id;
  });
  return {
    filterType: FilterTypesEnum.NumberIn,
    field: 'DivisionId',
    value: divisionsId,
  };
};

export const getCountriesFromFilter = (indicatorCall, filter?) => {
  const countries = filter
    ? filter.common.countryId
    : indicatorCall
    ? store.getState().filterIndicators.common.countryId
    : store.getState().filter.common.countryId;
  const actualState = Array.from(countries, (x: any) => x.id);
  return {
    filterType: FilterTypesEnum.NumberIn,
    field: 'ResponsibleCountryId',
    value: actualState,
  };
};

const getBidConsultancyIndicatorFilter = () => {
  const contractYear = sessionStorage.getItem('indicatorBidConsultancyContractYear') || '0';
  const contractType = sessionStorage.getItem('indicatorBidConsultancyContractType') || '0';
  const searchInput = sessionStorage.getItem('indicatorBidConsultancySearch');

  return [
    {
      filterType: 2,
      field: 'ContractYear',
      value: parseInt(contractYear),
    },
    {
      filterType: 2,
      field: 'ContractType',
      value: parseInt(contractType),
    },
    {
      filterType: 2,
      field: 'ContractDescription',
      value: searchInput,
    },
  ];
};

export const setValueFilter = (indicatorCall = false, key: string) => {
  const value: SelectOption[] = indicatorCall
    ? store.getState().filterIndicators.common[key]
    : store.getState().filter.common[key];
  return value !== null ? Array.from(value, x => x.id) : [];
};

const notNullNeitherUndefined = value => value !== null && value !== undefined;

export const setFilterParams = (returnedParams, stateFilterValues, indicatorCall, filter?) => {
  let pmrFilter;
  let divisionFilter;
  let countriesFilter;

  if (stateFilterValues.haveDivisionFilter) {
    divisionFilter = getDivisionFromFilter(indicatorCall, filter);
    const returnedFilters = { Filters: [...returnedParams.Filters, divisionFilter] };
    returnedParams = { ...returnedParams, Filters: returnedFilters.Filters };
  }

  if (stateFilterValues.haveCountriesFilter) {
    countriesFilter = getCountriesFromFilter(indicatorCall, filter);
    const returnedFilters = { Filters: [...returnedParams.Filters, countriesFilter] };
    returnedParams = { ...returnedParams, Filters: returnedFilters.Filters };
  }

  if (stateFilterValues.haveLastDisbursement) {
    const lastDisbursement = getLastDisbursementInDays(indicatorCall, filter);
    const returnedFilters = Array.isArray(lastDisbursement)
      ? {
          Filters: [...returnedParams.Filters, lastDisbursement[0], lastDisbursement[1]],
        }
      : { Filters: [...returnedParams.Filters, lastDisbursement] };
    returnedParams = { ...returnedParams, Filters: returnedFilters.Filters };
  }

  if (stateFilterValues.haveAgeOfProject) {
    const ageOfProject = getAgeOfProjectFilter(indicatorCall, filter);
    const returnedFilters = Array.isArray(ageOfProject)
      ? {
          Filters: [...returnedParams.Filters, ageOfProject[0], ageOfProject[1]],
        }
      : { Filters: [...returnedParams.Filters, ageOfProject] };
    returnedParams = { ...returnedParams, Filters: returnedFilters.Filters };
  }

  if (stateFilterValues.havePendingDisbursement) {
    const pendingDisbursement = getPendingDisbursement(indicatorCall, filter);
    const returnedFilters = Array.isArray(pendingDisbursement)
      ? {
          Filters: [...returnedParams.Filters, pendingDisbursement[0], pendingDisbursement[1]],
        }
      : { Filters: [...returnedParams.Filters, pendingDisbursement] };
    returnedParams = { ...returnedParams, Filters: returnedFilters.Filters };
  }

  if (handleIfHavePmrClassification(stateFilterValues)) {
    pmrFilter = getPmrClassificationFilter(indicatorCall, filter);
    const returnedFilters = { Filters: [...returnedParams.Filters, pmrFilter] };
    returnedParams = { ...returnedParams, Filters: returnedFilters.Filters };
  }

  if (notNullNeitherUndefined(stateFilterValues.haveTaxonomy)) {
    const taxonomyFilter = getTaxonomyFilter(indicatorCall);
    const returnedFilters = { Filters: [...returnedParams.Filters, taxonomyFilter] };
    returnedParams = { ...returnedParams, Filters: returnedFilters.Filters };
  }

  if (notNullNeitherUndefined(stateFilterValues.haveExecution)) {
    const execFilter = getExecutionFilter(indicatorCall);
    const returnedFilters = { Filters: [...returnedParams.Filters, execFilter] };
    returnedParams = { ...returnedParams, Filters: returnedFilters.Filters };
  }

  if (stateFilterValues.haveExcludeRegionales) {
    const excludeRegionalsFilter = getExcludeRegionalFilter();
    const returnedFilters = { Filters: [...returnedParams.Filters, excludeRegionalsFilter] };
    returnedParams = { ...returnedParams, Filters: returnedFilters.Filters };
  }

  return returnedParams;
};

const handleIfHavePmrClassification = stateFilterValues => {
  return stateFilterValues.havePmrClassification && stateFilterValues.havePmrClassification.length !== 0;
};

const checkHaveExcludeRegionals = (returnedParamsFilters, returnedParams) => {
  if (returnedParamsFilters.haveExcludeRegionales) {
    const excludeRegionalsFilter = getExcludeRegionalFilter();
    const returnedFilters = { Filters: [...returnedParams.Filters, excludeRegionalsFilter] };
    returnedParams = { ...returnedParams, Filters: returnedFilters.Filters };
  }
  return returnedParams;
};

const checkHaveTaxonomy = (returnedParamsFilters, returnedParams, customFilter?) => {
  if (returnedParamsFilters.haveTaxonomy !== null) {
    const taxonomyFilter = getTaxonomyFilter(true, customFilter);
    const returnedFilters = { Filters: [...returnedParams.Filters, taxonomyFilter] };
    returnedParams = { ...returnedParams, Filters: returnedFilters.Filters };
  }
  return returnedParams;
};

const checkHaveExecution = (returnedParamsFilters, returnedParams, customFilter?) => {
  if (returnedParamsFilters.haveExecution !== null) {
    const execFilter = getExecutionFilter(true, customFilter);
    const returnedFilters = { Filters: [...returnedParams.Filters, execFilter] };
    returnedParams = { ...returnedParams, Filters: returnedFilters.Filters };
  }
  return returnedParams;
};

const checkHavePmrClassification = (returnedParamsFilters, returnedParams, customFilter?) => {
  if (returnedParamsFilters.havePmrClassification) {
    const pmrFilter = getPmrClassificationFilter(true, customFilter);
    if (pmrFilter.value.length > 0) {
      const returnedFilters = { Filters: [...returnedParams.Filters, pmrFilter] };
      returnedParams = { ...returnedParams, Filters: returnedFilters.Filters };
    }
  }
  return returnedParams;
};

const checkHaveAgeOfProject = (returnedParamsFilters, returnedParams, customFilter?) => {
  if (returnedParamsFilters.haveAgeOfProject) {
    const ageOfProject = getAgeOfProjectFilter(true, customFilter);
    const returnedFilters = Array.isArray(ageOfProject)
      ? {
          Filters: [...returnedParams.Filters, ageOfProject[0], ageOfProject[1]],
        }
      : { Filters: [...returnedParams.Filters, ageOfProject] };
    returnedParams = { ...returnedParams, Filters: returnedFilters.Filters };
  }
  return returnedParams;
};

const checkHaveLastDisbursement = (returnedParamsFilters, returnedParams, customFilter?) => {
  if (returnedParamsFilters.haveLastDisbursement) {
    const lastDisbursement = getLastDisbursementInDays(true, customFilter);
    const returnedFilters = Array.isArray(lastDisbursement)
      ? {
          Filters: [...returnedParams.Filters, lastDisbursement[0], lastDisbursement[1]],
        }
      : { Filters: [...returnedParams.Filters, lastDisbursement] };
    returnedParams = { ...returnedParams, Filters: returnedFilters.Filters };
  }

  return returnedParams;
};

const checkHaveDivisionFilter = (returnedParamsFilters, returnedParams, customFilter?) => {
  if (returnedParamsFilters.haveDivisionFilter) {
    const divisionFilter = getDivisionFromFilter(true, customFilter);
    const returnedFilters = { Filters: [...returnedParams.Filters, divisionFilter] };
    returnedParams = { ...returnedParams, Filters: returnedFilters.Filters };
  }
  return returnedParams;
};

const checkHavePendingDisbursement = (returnedParamsFilters, returnedParams, customFilter?) => {
  if (returnedParamsFilters.havePendingDisbursement) {
    const pendingDisbursement = getPendingDisbursement(true, customFilter);
    const returnedFilters = Array.isArray(pendingDisbursement)
      ? {
          Filters: [...returnedParams.Filters, pendingDisbursement[0], pendingDisbursement[1]],
        }
      : { Filters: [...returnedParams.Filters, pendingDisbursement] };
    returnedParams = { ...returnedParams, Filters: returnedFilters.Filters };
  }
  return returnedParams;
};

const checkHavePipelineFilter = (returnedParamsFilters, returnedParams) => {
  if (returnedParamsFilters.havePipelineFilter) {
    const sessionPipelineFilter: any = sessionStorage.getItem('indicatorPipelineFilter');
    const sessionPipelineFilterArray = JSON.parse(sessionPipelineFilter);

    const pipelineFilter = {
      filterType: 8,
      field: 'PipelineCategory',
      value: sessionPipelineFilterArray,
    };
    const returnedFilters = { Filters: [...returnedParams.Filters, pipelineFilter] };
    returnedParams = { ...returnedParams, Filters: returnedFilters.Filters };
  }
  return returnedParams;
};

const checkHasAlertTypeId = (returnedParamsFilters, returnedParams) => {
  if (returnedParamsFilters.hasAlertTypeId) {
    const alertTypeId = parseInt(sessionStorage.getItem('alertTypeId') || '');
    const filter = { filterType: 2, field: 'AlertTypeId', value: alertTypeId };
    const returnedFilters = { Filters: [...returnedParams.Filters, filter] };
    returnedParams = { ...returnedParams, Filters: returnedFilters.Filters };
  }

  return returnedParams;
};

const checkHaveBidConsultancyIndicatorFilter = (returnedParamsFilters, returnedParams) => {
  if (returnedParamsFilters.haveBidConsultancyIndicatorFilter) {
    const bidConsultancyIndicatorFilter = getBidConsultancyIndicatorFilter();
    const returnedFilters = { Filters: [...returnedParams.Filters, ...bidConsultancyIndicatorFilter] };
    returnedParams = { ...returnedParams, Filters: returnedFilters.Filters };
  }
  return returnedParams;
};

const checkHaveCountriesFilter = (returnedParamsFilters, returnedParams, customFilter?) => {
  if (returnedParamsFilters.haveCountriesFilter) {
    const countriesFilterList = getCountriesFromFilter(true, customFilter);

    returnedParams = {
      ...returnedParams,
      Filters: [...returnedParams.Filters, countriesFilterList],
    };
  }
  return returnedParams;
};
export const getReturnedParmasIndicator = (returnedParamsFilters, returnedParams, customFilter?) => {
  returnedParams = checkHavePmrClassification(returnedParamsFilters, returnedParams, customFilter);
  returnedParams = checkHaveAgeOfProject(returnedParamsFilters, returnedParams, customFilter);
  returnedParams = checkHaveLastDisbursement(returnedParamsFilters, returnedParams, customFilter);
  returnedParams = checkHaveDivisionFilter(returnedParamsFilters, returnedParams, customFilter);
  returnedParams = checkHavePendingDisbursement(returnedParamsFilters, returnedParams, customFilter);
  returnedParams = checkHavePipelineFilter(returnedParamsFilters, returnedParams);
  returnedParams = checkHasAlertTypeId(returnedParamsFilters, returnedParams);
  returnedParams = checkHaveBidConsultancyIndicatorFilter(returnedParamsFilters, returnedParams);
  returnedParams = checkHaveCountriesFilter(returnedParamsFilters, returnedParams, customFilter);
  returnedParams = checkHaveTaxonomy(returnedParamsFilters, returnedParams, customFilter);
  returnedParams = checkHaveExecution(returnedParamsFilters, returnedParams, customFilter);
  returnedParams = checkHaveExcludeRegionals(returnedParamsFilters, returnedParams);

  return returnedParams;
};

export const setItsSearchCall = selectState => {
  const followedSearchValue = store.getState().filter.followedProjectsFilter.searchValue;
  const allSearchValue = store.getState().filter.allProjectsFilter.searchValue;
  return selectState ? followedSearchValue.length : allSearchValue.length;
};

export const checkReportFilters = reportFilter => {
  const haveCountriesFilter = setHaveFiltersInputsAndSelects('country', true, reportFilter.common);
  const haveDivisionFilter = setHaveFiltersInputsAndSelects('division', true, reportFilter.common);
  const havePendingDisbursement = setHaveFiltersInputsAndSelects('disbursementProjection', true, reportFilter.common);
  const haveLastDisbursement = setHaveFiltersInputsAndSelects('timeWithoutDisbursement', true, reportFilter.common);
  const haveAgeOfProject = setHaveFiltersInputsAndSelects('ageProject', true, reportFilter.common);
  return [haveCountriesFilter, haveDivisionFilter, havePendingDisbursement, haveLastDisbursement, haveAgeOfProject];
};

export const addMenuTabFilter = (returnedParams, menuTabSection: MenuTabSectionEnum) => {
  const { portfolioSelectedTab, projectSelectedTab, earlyWarningsSelectedTab }: AppMenuTabs = store.getState().menuTabs;
  const usedMenuTab =
    menuTabSection === MenuTabSectionEnum.PORTFOLIO_MENU_TAB
      ? portfolioSelectedTab
      : menuTabSection === MenuTabSectionEnum.PROJECT_MENU_TAB
      ? projectSelectedTab
      : earlyWarningsSelectedTab;
  returnedParams = {
    ...returnedParams,
    assignedToMe: usedMenuTab.assignedToMe,
    regions: [...returnedParams.regions, ...usedMenuTab.regions],
    coReponsibleDivisions:
      returnedParams.coReponsibleDivisions && returnedParams.coReponsibleDivisions.length
        ? returnedParams.coReponsibleDivisions
        : usedMenuTab.coReponsibleDivisions,
    participantDivisions:
      returnedParams.participantDivisions && returnedParams.participantDivisions.length
        ? returnedParams.participantDivisions
        : usedMenuTab.participantDivisions,
    interestIssues:
      returnedParams.interestIssues && returnedParams.interestIssues.length
        ? returnedParams.interestIssues
        : usedMenuTab.interestIssues,
    teamLeaders:
      returnedParams.teamLeaders && returnedParams.teamLeaders.length
        ? returnedParams.teamLeaders
        : usedMenuTab.teamLeaders.map(teamLeader => teamLeader.id),
    inFollow: usedMenuTab.inFollow,
  };

  if (usedMenuTab.excludeRegionals) {
    returnedParams.Filters.push({
      filterType: FilterTypesEnum.NumberIn,
      field: 'BeneficiaryType',
      value: [0],
    });
  }

  if (usedMenuTab.filters) {
    const mergeFilterArray = getMergedFilterArray(returnedParams.Filters, usedMenuTab.filters);

    const paramsFilterArray: Filter[] = duplicateToUniqueKey(mergeFilterArray);
    const returnedParamsFilterArray = paramsFilterArray.filter(filter => filter.field !== 'SectorId');

    returnedParams = {
      ...returnedParams,
      Filters: returnedParamsFilterArray,
    };
  }
  return returnedParams;
};

const handleProjectTypeFilterOnDetailIsLoan = projectTypeFilterOnDetail => {
  if (projectTypeFilterOnDetail === ProjectProductTypes.Loan) {
    return [projectTypeFilterOnDetail, ProjectProductTypes.GUARANTEE];
  }
  return [projectTypeFilterOnDetail];
};

export const getProductTypesFilterAndIgConfigFromStore = (project = false, customFilterIndicators?) => {
  const filterIndicators = customFilterIndicators
    ? customFilterIndicators
    : project
    ? store.getState().filter
    : store.getState().filterIndicators;
  const { projectTypeFilterOnDetail } = store.getState().indicatorDetailFilter;
  const parsedFilters: any = parseFilterToApiCall(filterIndicators.common.projectTypeFilters);
  const { projectProductAmounts } = parsedFilters;
  const projectProductTypes =
    projectTypeFilterOnDetail !== null && window.location.href.indexOf('portfoliodetail') > -1
      ? handleProjectTypeFilterOnDetailIsLoan(projectTypeFilterOnDetail)
      : parsedFilters.projectProductTypes;
  return { projectProductAmounts, projectProductTypes };
};

export const getReturnedParamsFilters = () => {
  const filterIndicators = store.getState().filterIndicators;
  const haveCountriesFilter = setHaveFiltersInputsAndSelects('country', true);
  const haveDivisionFilter = setHaveFiltersInputsAndSelects('division', true);
  const havePendingDisbursement = setHaveFiltersInputsAndSelects('disbursementProjection', true);
  const haveLastDisbursement = setHaveFiltersInputsAndSelects('timeWithoutDisbursement', true);
  const haveAgeOfProject = setHaveFiltersInputsAndSelects('ageProject', true);
  const havePmrClassification = filterIndicators.common.pmrId.filter(pmr => pmr.selected === true);
  const haveTaxonomy = filterIndicators.common.taxonomyFilter && filterIndicators.common.taxonomyFilter.id;
  const haveExecution =
    filterIndicators.common.executionFilter && filterIndicators.common.executionFilter.id !== undefined;
  const haveExcludeRegionales = filterIndicators.common.excludeRegionals;
  const regionsFilter = setValueFilter(true, 'regionId');
  const coReponsibleDivisionsFilter = setValueFilter(true, 'coReponsibleDivisions');
  const participantDivisionsFilter = setValueFilter(true, 'participantDivisions');
  const interestIssuesFilter = setValueFilter(true, 'interestIssues');
  const teamLeadersFilter = setValueFilter(true, 'teamLeaders');

  return {
    filterIndicators,
    regionsFilter,
    haveCountriesFilter,
    haveDivisionFilter,
    havePendingDisbursement,
    haveLastDisbursement,
    haveAgeOfProject,
    havePmrClassification,
    coReponsibleDivisionsFilter,
    participantDivisionsFilter,
    interestIssuesFilter,
    teamLeadersFilter,
    haveTaxonomy,
    haveExecution,
    haveExcludeRegionales,
  };
};
export const createNewUniqueValueArray = (returnedParams, selectedTab: MenuTabs, prop: string) => {
  if (returnedParams[prop] !== undefined && selectedTab[prop] !== undefined) {
    return Array.from(new Set([...returnedParams[prop], ...selectedTab[prop]]));
  } else {
    return [];
  }
};

export const handleActualProjectedDifferenceFilters = (returnedParams, options) => {
  const { actualProjectedDifferenceFilters, month } = options;
  let discriminatorFilter = {};
  let attributeFilter = {};
  let returnedFilters: any = {};
  switch (actualProjectedDifferenceFilters) {
    case undefined:
      discriminatorFilter = {
        filterType: FilterTypesEnum.Equal,
        field: ProjectVsCompletedIndicatorFilterField.Discriminator,
        value: getActivitiesLocalStorage()
          ? DiscriminatorFilterValue.SupervisionPlanActivity
          : DiscriminatorFilterValue.CriticalProduct,
      };
      attributeFilter = {
        filterType: FilterTypesEnum.Equal,
        field: ProjectVsCompletedIndicatorFilterField.Attributes,
        value: [],
      };
      returnedFilters = { Filters: [...returnedParams.Filters, discriminatorFilter, attributeFilter] };
      returnedParams = { ...returnedParams, Filters: returnedFilters.Filters };
      break;

    case SelectOptionId.SupervisionPlanActivity:
    case SelectOptionId.CriticalProduct:
      discriminatorFilter = {
        filterType: FilterTypesEnum.Equal,
        field: ProjectVsCompletedIndicatorFilterField.Discriminator,
        value:
          actualProjectedDifferenceFilters === SelectOptionId.SupervisionPlanActivity
            ? DiscriminatorFilterValue.SupervisionPlanActivity
            : DiscriminatorFilterValue.CriticalProduct,
      };
      attributeFilter = {
        filterType: FilterTypesEnum.Equal,
        field: ProjectVsCompletedIndicatorFilterField.Attributes,
        value: [],
      };
      returnedFilters = { Filters: [...returnedParams.Filters, discriminatorFilter, attributeFilter] };
      returnedParams = { ...returnedParams, Filters: returnedFilters.Filters };
      break;

    case SelectOptionId.BOOT_WORKSHOP:
    case SelectOptionId.INTERMEDIATE_EVALUATION:
    case SelectOptionId.CLOSING_WORKSHOP:
      discriminatorFilter = {
        filterType: FilterTypesEnum.Equal,
        field: ProjectVsCompletedIndicatorFilterField.Discriminator,
        value: DiscriminatorFilterValue.CriticalProduct,
      };
      attributeFilter = {
        filterType: FilterTypesEnum.Equal,
        field: ProjectVsCompletedIndicatorFilterField.Attributes,
        value:
          SelectOptionId.BOOT_WORKSHOP === actualProjectedDifferenceFilters
            ? AttributeFilterValue.BOOT_WORKSHOP
            : SelectOptionId.INTERMEDIATE_EVALUATION === actualProjectedDifferenceFilters
            ? AttributeFilterValue.INTERMEDIATE_EVALUATION
            : AttributeFilterValue.CLOSING_WORKSHOP,
      };
      returnedFilters = { Filters: [...returnedParams.Filters, discriminatorFilter, attributeFilter] };
      returnedParams = { ...returnedParams, Filters: returnedFilters.Filters };
      break;
  }

  if (month) {
    const monthFilter = {
      filterType: FilterTypesEnum.Equal,
      field: 'Month',
      value: month,
    };
    returnedFilters = { Filters: [...returnedParams.Filters, monthFilter] };
    returnedParams = { ...returnedParams, Filters: returnedFilters.Filters };
  }

  return returnedParams;
};

const getActivitiesLocalStorage = () => {
  const activitiesLocalStorage = localStorage.getItem('activities');
  return activitiesLocalStorage ? JSON.parse(activitiesLocalStorage) : true;
};
