/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import './BaseInformation.scss';
import { getProjectBaseInformation } from '../../../api/projectApi';
import Project from '../../../models/Project';
import { isMobile } from '../../../services/booleanUtil';
import Amounts from './Amounts';
import { getRoles } from '../../../api/projectTeamApi';
import { showSuccesMessage } from '../../../redux/actions/sucessMessage';
import { sortByRoles, bidMembersLayout } from './BaseInformationUtil';
import RoleBidMember from './RoleBidMember';
import RightContainer from './RightContainer';
import { resolveProjectReportSections } from '../../../redux/actions/projectReportSections';
import UdrTaxonomyAndExecutedBy from './CTInfoComponents/UdrTaxonomyAndExecutedBy';
import ParticipingCountries from './CTInfoComponents/ParticipingCountries';
import BeneficiaryOrExecutingTeam from './BeneficiaryOrExecutingTeam';
import { ProjectProductTypes } from '../../../models/ProjectProductTypes';
import { ProjectProductTypeClassification } from '../../../models/ProjectProductTypeClassification';
import LinkedToTheLoan from './CTInfoComponents/LinkedToTheLoan';
import SpinningLoader from '../ProjectTabs/Loader/SpinningLoader';

const rowStyle = (projectProductType: ProjectProductTypes) =>
  projectProductType !== ProjectProductTypes.Loan ? { flex: 2 } : {};

export const customStyle = () => {
  return isMobile() ? {} : { marginLeft: 0 };
};

export const BaseInformation = () => {
  const currentProject = useSelector<any, Project>(state => state.currentProject);
  const [baseInfo, setBaseInfo] = React.useState<any>();
  const [bidMembers, setBidMembers] = React.useState<any>([]);
  const [execUnit, setExecUnit] = React.useState<any>();
  const [rolesList, setRolesList] = React.useState<any[]>([]);
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const loadBaseInformation = async () => {
    const response = await getProjectBaseInformation(currentProject.id);
    const bidMembersSorted = sortByRoles(response.projectTeams);
    setBidMembers(bidMembersSorted);
    const execUnit = response.projectTeams.filter(x => !x.bidMember);
    setExecUnit(execUnit);
    setBaseInfo(response);
    dispatch(resolveProjectReportSections('baseInfo'));
  };

  const getRolesList = async () => {
    const response = await getRoles();
    setRolesList(
      response.map(item => {
        return { value: item.name, label: item.name, id: item.id };
      }),
    );
  };

  React.useEffect(() => {
    if (currentProject && currentProject.id > 0 && baseInfo == null) {
      // loadBaseInformation();
      getRolesList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject]);

  React.useEffect(() => {
    if (currentProject && currentProject.id > 0) {
      loadBaseInformation();
      getRolesList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject.id]);

  const refreshAndNotify = async () => {
    await loadBaseInformation();
    dispatch(showSuccesMessage());
  };

  return (
    <div className="base-information-container">
      {baseInfo === undefined ? (
        <div className="spinner-container">
          <SpinningLoader />
        </div>
      ) : (
        <>
          <div className="left-container colum-border">
            <div className="row">
              <div
                className="objective column colum-border"
                style={rowStyle(currentProject.projectProductType || ProjectProductTypes.Loan)}
              >
                <LinkedToTheLoan
                  projectProductType={currentProject.projectProductType}
                  baseInfo={baseInfo}
                  igrGreater={handleIsGreater(currentProject)}
                />
                {/* Informacion quemada */}
                <div className="row-border">
                  <span className="section-title">{`${t('linked_to_the_loan')}`}</span>
                  <span className="section-text">{currentProject.loanNumber}</span>
                </div>

                <div className="row-border">
                  <span className="section-title">{t('objective')}</span>
                  <span className="section-text">{baseInfo.objective}</span>
                </div>
                <div className="team">
                  <span className="section-title title-spacing ">{t('bid_team')}</span>
                  <div className="team-bid">
                    {bidMembers.length ? (
                      bidMembers.map(bidMember => <RoleBidMember roleMember={bidMember} key={bidMember.role} />)
                    ) : (
                      <span className="text-name">{t('no_bid_members')}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="column-secund">
                <div className="amount">
                  {!isMobile() && <Amounts baseInfo={baseInfo} />}
                  <UdrTaxonomyAndExecutedBy
                    projectProductType={currentProject.projectProductType}
                    baseInfo={baseInfo}
                    execution={currentProject.execution}
                    igrGreater={handleIsGreater(currentProject)}
                  />
                </div>
                <BeneficiaryOrExecutingTeam
                  projectProductType={currentProject.projectProductType}
                  baseInfo={baseInfo}
                  execUnit={execUnit}
                  rolesList={rolesList}
                  refreshAndNotify={refreshAndNotify}
                />
                <ParticipingCountries
                  projectProductType={currentProject.projectProductType}
                  baseInfo={baseInfo}
                  igrGreater={handleIsGreater(currentProject)}
                />
              </div>
              {isMobile() && <Amounts baseInfo={baseInfo} />}
            </div>

            {/* <div className="row bottom-row">
              <div
                className="objective column colum-border"
                style={rowStyle(currentProject.projectProductType || ProjectProductTypes.Loan)}
              >
                <span className="section-title title-spacing ">{t('bid_team')}</span>
                <div
                  className="team-members left"
                  style={{ flexDirection: 'column', height: bidMembersLayout(bidMembers) }}
                >
                  {bidMembers.length ? (
                    bidMembers.map(bidMember => <RoleBidMember roleMember={bidMember} key={bidMember.role} />)
                  ) : (
                    <span className="text-name">{t('no_bid_members')}</span>
                  )}
                </div>
              </div>
              <BeneficiaryOrExecutingTeam
                    projectProductType={currentProject.projectProductType}
                    baseInfo={baseInfo}
                    execUnit={execUnit}
                    rolesList={rolesList}
                    refreshAndNotify={refreshAndNotify}
                  />
               <ParticipingCountries
                projectProductType={currentProject.projectProductType}
                baseInfo={baseInfo}
                igrGreater={handleIsGreater(currentProject)}
              />
            </div> */}
          </div>
          <RightContainer
            baseInfo={baseInfo}
            isWarranty={currentProject.projectProductType === ProjectProductTypes.GUARANTEE}
          />
        </>
      )}
    </div>
  );
};

export default BaseInformation;

const handleIsGreater = currentProject => {
  return currentProject &&
    currentProject.projectProductTypeClassification &&
    currentProject.projectProductTypeClassification['code'] === ProjectProductTypeClassification.IGR_GREATER_THAN_3MM
    ? true
    : false;
};
