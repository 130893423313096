import { Checkbox, FormControlLabel, FormGroup, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { confirmProjectIntegrationMyPortfolio, getProjectIntegrationMyPortfolio } from '../../../api/projectApi';
import { RequestStatus } from '../../../models/RequestStatus';
import { getUser } from '../../../redux/actions/user';
import { isMobile } from '../../../services/booleanUtil';
import CloseCrossButton from '../../Util/Buttons/CloseCrossButton/CloseCrossButton';
import SubmitButton from '../../Util/Buttons/SubmitButton/SubmitButton';
import BaseModal from '../../Util/Modals/BaseModal';
import { disableEscKey } from '../../Util/Modals/Util';
import styles from './ModalProjectIntegration.module.scss';

const customStyles: Modal.Styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10000,
    overflowY: 'auto',
  },
  content: {
    margin: ' 0 auto',
    bottom: 'auto',
    borderRadius: isMobile() ? '0px' : '15px',
    padding: 0,
    overflow: 'auto',
    width: '600px',
  },
};

const {
  modalHeader,
  closeModal,
  modalTitle,
  modalBody,
  checkContainer,
  formControlLabel,
  checkboxPostLabel,
  ModalBottom,
} = styles;
const splitProjectCodeAndLoan = project => {
  const [code] = project.codeAndLoan.split(' ');
  return code;
};

const saveListSort = projects => {
  return [...projects].map(project => {
    const ProjectId = project.id;
    const IncludeInPortfolio = false;
    project.codeAndLoan = splitProjectCodeAndLoan(project);
    return { ...project, ProjectId, IncludeInPortfolio };
  });
};
const hasDataToOpenModal = response => response.length === 0;
const ModalProjectIntegration = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [saveList, setSaveList] = useState<any | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectAllCheckbox, setSelectAllCheckbox] = useState(false);
  const [noMoreModal, setNoMoreModal] = useState(false);
  const handlerCloseModal = () => {
    setModalOpen(!modalOpen);
  };
  const getData = async () => {
    const response: any = await getProjectIntegrationMyPortfolio();
    setModalOpen(!hasDataToOpenModal(response));
    setSaveList(saveListSort(response));
  };
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newList = saveList.map(project => {
      if (project.ProjectId === parseInt(event.target.value)) {
        project.IncludeInPortfolio = event.target.checked;
      }
      return project;
    });
    setSaveList(newList);
  };
  const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectAllCheckbox(event.target.checked);
    const allListChecked = [...saveList].map(project => {
      project.IncludeInPortfolio = event.target.checked;
      return project;
    });
    setSaveList(allListChecked);
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNoMoreModal(event.target.checked);
  };

  const saveProjectChanges = async () => {
    if (!noMoreModal) {
      const checkedProjects: any[] = saveList.filter(project => project.IncludeInPortfolio === true);
      await confirmProjectIntegrationMyPortfolio(checkedProjects);
      handlerCloseModal();
    } else {
      await confirmProjectIntegrationMyPortfolio(saveList);
      handlerCloseModal();
    }
    dispatch(getUser(true));
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <BaseModal isOpen={modalOpen} onRequestClose={e => disableEscKey(e, handlerCloseModal)} style={customStyles}>
        <div className={modalHeader}>
          <div className={closeModal}>
            <CloseCrossButton handleClick={handlerCloseModal} />
          </div>
          <div className={modalTitle}>
            <span>{t('modal_project_integration_title')}</span>
          </div>
        </div>
        <div className={modalBody}>
          <p>{t('modal_project_integration_subtitle')}</p>
          <div className={checkContainer}>
            <FormGroup>
              {saveList &&
                saveList.map((project, index) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="default"
                        size="small"
                        key={index}
                        value={project.id}
                        checked={saveList[index].IncludeInPortfolio}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label={
                      <Typography noWrap>
                        <p className={formControlLabel}>
                          {project.codeAndLoan}
                          <span className={checkboxPostLabel}>{project.name}</span>
                        </p>
                      </Typography>
                    }
                  />
                ))}
              <FormControlLabel
                control={
                  <Checkbox color="default" size="small" checked={selectAllCheckbox} onChange={handleSelectAllChange} />
                }
                label={
                  <Typography className={formControlLabel}>
                    <p className={formControlLabel}>{t('modal_project_integration_select_all')}</p>
                  </Typography>
                }
              />
            </FormGroup>
          </div>
        </div>
        <div className={ModalBottom}>
          <div className="save-container">
            <SubmitButton
              customText={t('save')}
              disabled={saveList ? !findIfSelectedCheckbox(saveList, noMoreModal) : true}
              handleClick={() => saveProjectChanges()}
              buttonState={RequestStatus.Default}
            />
          </div>
          <FormControlLabel
            control={<Checkbox color="default" size="small" checked={noMoreModal} onChange={handleChange} />}
            label={<Typography className={formControlLabel}>{t('modal_dont_show_message')}</Typography>}
          />
        </div>
      </BaseModal>
    </>
  );
};
const findIfSelectedCheckbox = (checkboxList, noMoreModal) => {
  const checked = checkboxList.find(checkbox => checkbox.IncludeInPortfolio === true);
  return checked || noMoreModal;
};
export default ModalProjectIntegration;
