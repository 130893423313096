import React from 'react';
import { CardTypesEnum } from '../../../../../../models/CardTypesEnum';

type Props = {
  listPage: boolean;
  type: string;
};
export const GradientFill = ({ listPage, type }: Props) => {
  return isListPageAndHorizontal(listPage, type) ? (
    <>
      <span className="fill-opacity-50"></span>
      <span className="fill-opacity-20"></span>
    </>
  ) : null;
};

const isListPageAndHorizontal = (listPage: boolean, type: string) => listPage && type === CardTypesEnum.HORIZONTAL;
