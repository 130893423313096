import React from 'react';
import './SearchRender.scss';
import { DebounceInput } from 'react-debounce-input';
import { useTranslation } from 'react-i18next';

type Props = {
  placeholder?: any;
  minLenght?: number;
  debounceTimeout?: number;
  onChangeHandler?: any;
  valueSearched?: any;
  onChange?: any;
};

export const SearchRender = ({
  placeholder,
  minLenght,
  debounceTimeout,
  onChangeHandler,
  onChange,
  valueSearched,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className="search-box">
      <div className="search-container">
        <span className="icon">
          <i className="search-icon"></i>
        </span>
        <DebounceInput
          id="search"
          placeholder={t(placeholder)}
          className="input"
          type="search"
          minLength={minLenght ? minLenght : 1}
          value={valueSearched}
          debounceTimeout={debounceTimeout ? debounceTimeout : 300}
          onChange={event => onChangeHandler(event)}
        />
      </div>
    </div>
  );
};
