import { ProjectProductTypes } from '../../../models/ProjectProductTypes';
import { ProjectProductTypeFilters } from '../../../models/ProjectProductTypeFilters';

export const setDefaultValueToCompositionFilter = filterState => {
  const newFilterState = [...filterState].sort();

  const firstElement = newFilterState[0];

  if (firstElement === ProjectProductTypeFilters.Loan) {
    return ProjectProductTypes.Loan;
  } else if (firstElement === ProjectProductTypeFilters.CT) {
    return ProjectProductTypes.CT;
  } else if (
    firstElement === ProjectProductTypeFilters.IG_greater_3 ||
    firstElement === ProjectProductTypeFilters.IG_lower_3
  ) {
    return ProjectProductTypes.IG;
  } else {
    return ProjectProductTypes.Loan;
  }
};

const ProjectProductTypesMappedText = {
  0: 'Loan',
  1: 'CT',
  2: 'IGBoth',
  3: 'IGBoth',
};
export const handleSelectedDefaultWithProjects = (projectTypeFilters, tabIndicatorValues) => {
  const tabIndicatorValuesWithProjects: any = [];

  projectTypeFilters.forEach(pt => {
    if (tabIndicatorValues && tabIndicatorValues[ProjectProductTypesMappedText[pt]] !== 0) {
      tabIndicatorValuesWithProjects.push(pt);
    }
  });

  return tabIndicatorValuesWithProjects;
};
