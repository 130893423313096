import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { StyledTableRow } from '../../../../../PortfolioDetail/PortfolioSimpleIndicatorTable/PortfolioSimpleIndicatorTable.Styles';
import { Table, TableBody } from '@material-ui/core';
import { styles as modalStyles } from '../../../../../Util/Modals/Styles';
import styles from './EventsList.module.scss';
import CloseCrossButton from '../../../../../Util/Buttons/CloseCrossButton/CloseCrossButton';
import moment from 'moment';
import UndoButton from '../../../../../Util/Buttons/UndoButton/UndoButton';
import { EventType } from '../../../../../../models/EventType';
import SaveButton from '../../../../../Util/Buttons/SaveButton/SaveButton';
import { getEventsListDetails } from '../../../../../../api/calendarApi';
import {
  StyledTableCell,
  StyledTableCellHeader,
  StyledTableHeader,
  StyledTableRowHeader,
  StyledTableContainer,
} from '../../../../../PortfolioDetail/PorfolioTableShared.Styles';
import { customRowStyle } from '../../../../../PortfolioDetail/PortfolioSimpleIndicatorTable/PortfolioSimpleIndicatorTable';
import { MissionStateEnum } from '../../../../../../models/MissionStateEnum';
import NumberFormat from 'react-number-format';
import { handleOpenConvergence } from '../../../EventDetailInfo/EventDetailInfo';
import BaseModal from '../../../../../Util/Modals/BaseModal';
import { sortNested, sortTablesByType } from '../../../../../../services/sortsUtil';

type Props = {
  isOpen: boolean;
  closeModal: any;
  events: any[];
  redirectToIndicator: Function;
};

export const titleTexts = [
  'projected_disbursements',
  'acquisitions',
  'activities',
  'monitor_accordances',
  'revision_accordances',
  'missions',
  'secretarial_events',
  'lifecycle',
  'inter_group_accordances',
  'supervision',
];

const accordanceStateTexts = [
  'accordance_draft',
  'pending_accordance_singular',
  'active_accordance_singular',
  'accomplished_accordance_singular',
  'cancelled_accordance_singular',
  'rejected',
];

const headerTexts = [
  'amount',
  'description',
  'description',
  'state',
  'state',
  'type',
  '',
  'description',
  'state',
  'description',
];

const fieldFourValue = (event: any, eventType: number, t: Function, fallBackDescription: string) => {
  switch (eventType) {
    case EventType.Disbursement:
      return event && event.field1 !== null ? parseInt(event.field1.split(',')[0].replaceAll(/\./g, '')) : '';
    case EventType.Acquisition:
    case EventType.Activity:
    case EventType.Lifecycle:
      return event ? event.title : fallBackDescription || '';
    case EventType.RevisionAccordance:
    case EventType.MonitoringAccordance:
    case EventType.InterGroup:
      return event && event.field1 !== null ? t(accordanceStateTexts[parseInt(event.field1)]) : '-';
    case EventType.Mission:
    case EventType.ExternalEvent:
    case EventType.SupervisoryAction:
      return event && event.field1 !== null ? event.field1 : '-';
    default:
      return '';
  }
};

const fieldFiveValue = (ev: any, t: Function) =>
  parseInt(ev.eventType) === EventType.Mission
    ? t(MissionStateEnum[ev.fieldFive])
    : t(`supervision_plan_activity_state_${ev.fieldFive}`);

const isMissionOrSupervisionAction = eventType => {
  return parseInt(eventType) === EventType.Mission || parseInt(eventType) === EventType.SupervisoryAction;
};

const isSecretary = eventType => {
  return parseInt(eventType) === EventType.ExternalEvent;
};

const isLifeCycleOrMission = (eventType: EventType) =>
  eventType === EventType.Lifecycle || eventType === EventType.Mission;

const isExternalAndHasValue = (eventType: EventType, event: any) =>
  eventType === EventType.ExternalEvent && event.fieldFour && event.fieldFour.value;

const hasLinktToIndicator = (eventType: number, t: Function, redirectToIndicator: Function) => {
  if (eventType === EventType.Disbursement) {
    return (
      <UndoButton
        handleClick={() => redirectToIndicator('MONTHLY_DISBURSEMENT_PROJECTION_CURRENT_YEAR_INCLUDING_REGIONALS')}
        customText={t('annual_projection')}
        customStyle={{ marginRight: 20 }}
      />
    );
  } else if (
    eventType === EventType.MonitoringAccordance ||
    eventType === EventType.RevisionAccordance ||
    eventType === EventType.InterGroup
  ) {
    return (
      <UndoButton
        handleClick={() => redirectToIndicator('ACCORDANCES_EXPIRING')}
        customText={t('next_to_expire_and_expired')}
        customStyle={{ marginRight: 20 }}
      />
    );
  } else if (eventType === EventType.SupervisoryAction) {
    return (
      <UndoButton
        handleClick={() => redirectToIndicator('AVERAGE_LAG')}
        customText={t('average_lag')}
        customStyle={{ marginRight: 20 }}
      />
    );
  }
  return null;
};

const fieldFourCellValue = (event: any) => {
  if (
    parseInt(event.eventType) === EventType.Disbursement &&
    event.fieldFour &&
    event.fieldFour.value !== null &&
    event.fieldFour.value !== undefined
  ) {
    return (
      <span>
        <NumberFormat
          value={Math.trunc(event.fieldFour.value)}
          displayType={'text'}
          thousandSeparator={'.'}
          decimalSeparator={','}
          decimalScale={1}
        />
      </span>
    );
  }
  return event.fieldFour ? event.fieldFour.value : '';
};

const updateListWithApiData = (
  localEvents: any[],
  list: any[],
  t: Function,
  isProjectDetail: boolean,
  currentProject: any,
) => {
  return localEvents.map(item => {
    const matchedItem = list.find(i => i.id === parseInt(item.id));
    if (!item.projectCode && matchedItem.project) {
      item.projectCode = matchedItem.project.code;
    }
    if (!item.projectTitle && isProjectDetail) {
      item.projectTitle = currentProject.name;
    }
    const lnum = matchedItem && matchedItem.project ? matchedItem.project.loanNumber : '';
    item.loanNumber = lnum;
    item.fieldFour = {
      headerText: headerTexts[parseInt(item.eventType)],
      value: fieldFourValue(matchedItem, parseInt(item.eventType), t, item.title),
    };
    item.fieldFive = matchedItem && matchedItem.field2 ? parseInt(matchedItem.field2) : null;
    return item;
  });
};

const handleClick = (event: any, location, closeModal, history) => {
  const eventType = parseInt(event.eventType);
  if (isLifeCycleOrMission(eventType)) {
    handleOpenConvergence(event.projectCode);
  } else if (isExternalAndHasValue(eventType, event)) {
    const url = 'https://events.iadb.org/calendar/event/{externalId}?lang=en'.replace(
      '{externalId}',
      event.fieldFour.value,
    );
    window.open(url, '_blank');
  } else if (event.projectId) {
    if (location.pathname.includes('projectdetail')) {
      closeModal();
    } else {
      history.push(`/projectdetail?id=${event.projectId}`);
    }
  }
};

const EventsList = (props: Props) => {
  const { isOpen, closeModal, events, redirectToIndicator } = props;
  const { t } = useTranslation();
  const [localEvents, setLocalEvents] = useState<any[]>(events);
  const [sortAsc, setSortAsc] = React.useState(true);
  const history = useHistory();
  const location = useLocation();
  const currentProject = useSelector<any, any>(state => state.currentProject);

  const getData = async () => {
    const list = await getEventsListDetails(events[0].eventType, events[0].date);
    const updatedList = updateListWithApiData(
      localEvents,
      list,
      t,
      location.pathname.includes('projectdetail'),
      currentProject,
    );
    setLocalEvents(updatedList);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events]);

  const sortTable = (keyToSort, fieldType) => {
    sortTablesByType(localEvents, sortAsc, keyToSort, fieldType);
    setSortAsc(!sortAsc);
  };

  const sortTableNested = (key: string, propertyKey: string) => {
    localEvents.sort(sortNested(key, sortAsc ? 'asc' : 'desc', propertyKey));
    setSortAsc(!sortAsc);
  };

  return localEvents ? (
    <BaseModal isOpen={isOpen} onRequestClose={() => closeModal(false)} style={modalStyles} className={styles.content}>
      <div className={styles.closeCrossContainer}>
        <CloseCrossButton handleClick={() => closeModal(false)} />
      </div>
      <div className={styles.titleContainer}>
        <h4 className={styles.date}>{moment(localEvents[0].date).local().format('LL')}</h4>
        <h3 className={styles.title}>{t(titleTexts[parseInt(localEvents[0].eventType)])}</h3>
      </div>
      <div>
        <StyledTableContainer>
          <Table stickyHeader className="custom-table" style={{ tableLayout: 'auto' }} size="small">
            <StyledTableHeader>
              <StyledTableRowHeader>
                {isSecretary(localEvents[0].eventType) ? (
                  <StyledTableCellHeader className="code" align="left" onClick={() => sortTable('title', 'string')}>
                    {t('title')}
                    <i className="sort-icon"></i>
                  </StyledTableCellHeader>
                ) : (
                  <>
                    <StyledTableCellHeader
                      className="code"
                      align="left"
                      onClick={() => sortTable('projectCode', 'string')}
                    >
                      {t('code')}
                      <i className="sort-icon"></i>
                    </StyledTableCellHeader>
                    <StyledTableCellHeader
                      className="loan"
                      align="left"
                      onClick={() => sortTable('loanNumber', 'string')}
                    >
                      {t('operation')}
                      <i className="sort-icon"></i>
                    </StyledTableCellHeader>
                    <StyledTableCellHeader
                      className="project-name"
                      align="left"
                      onClick={() => sortTable('projectTitle', 'string')}
                    >
                      {t('project_name')}
                      <i className="sort-icon"></i>
                    </StyledTableCellHeader>
                    {localEvents[0].fieldFour && (
                      <StyledTableCellHeader align="left" onClick={() => sortTableNested('value', 'fieldFour')}>
                        {t(localEvents[0].fieldFour.headerText)}
                        <i className="sort-icon"></i>
                      </StyledTableCellHeader>
                    )}
                  </>
                )}

                {isMissionOrSupervisionAction(localEvents[0].eventType) && (
                  <StyledTableCellHeader align="left" onClick={() => sortTable('fieldFive', 'string')}>
                    {t('state')}
                    <i className="sort-icon"></i>
                  </StyledTableCellHeader>
                )}
                <StyledTableCellHeader align="right" />
              </StyledTableRowHeader>
            </StyledTableHeader>
            <TableBody>
              {localEvents.map((ev, index) => (
                <StyledTableRow
                  key={`${ev.id}${ev.title}${ev.projectCode}`}
                  style={customRowStyle(index, ev.id)}
                  onClick={() => handleClick(ev, location, closeModal, history)}
                >
                  {isSecretary(ev.eventType) ? (
                    <StyledTableCell style={{ width: '100%' }}>{ev.title || '-'}</StyledTableCell>
                  ) : (
                    <>
                      <StyledTableCell style={{ minWidth: 90 }}>{ev.projectCode}</StyledTableCell>
                      <StyledTableCell style={{ minWidth: '80px' }}>{ev.loanNumber || '-'}</StyledTableCell>
                      <StyledTableCell>{ev.projectTitle}</StyledTableCell>
                      <StyledTableCell>{fieldFourCellValue(ev)}</StyledTableCell>
                      {isMissionOrSupervisionAction(ev.eventType) && ev.fieldFive !== null && (
                        <StyledTableCell>{fieldFiveValue(ev, t)}</StyledTableCell>
                      )}
                    </>
                  )}

                  <StyledTableCell className="project-detail" align="left">
                    <span className="fw" />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </div>
      <div className={styles.footer}>
        {hasLinktToIndicator(parseInt(events[0].eventType), t, redirectToIndicator)}
        <SaveButton handleClick={closeModal} customText={t('close')} />
      </div>
    </BaseModal>
  ) : null;
};

export default EventsList;
