import React from 'react';

interface Props {
  firstValue: number;
  secondValue: number;
}

function ProgressBar({ firstValue, secondValue }: Props) {
  const remainingValue = 100 - (firstValue + secondValue);

  return (
    <div className="container-linear">
      <div className="progress-bar">
        <div className="progress-segment first" style={{ width: `${firstValue}%` }}>
          <div className="percentage-label">{firstValue}%</div>
        </div>
        <div className="progress-segment second" style={{ width: `${secondValue}%` }}>
          <div className="percentage-label">{secondValue}%</div>
        </div>
        <div className="progress-segment remaining" style={{ width: `${remainingValue}%` }}>
          <div className="percentage-label">{remainingValue}%</div>
        </div>
      </div>
    </div>
  );
}

export default ProgressBar;
