import { useContext, useEffect, useRef } from 'react';
import { DimensionContext } from '../context/DimensionContext';

export const useContainerDimensions = (name?: string) => {
    const { parentDimension, setParentDimension, tagDimension, setTagDimension } = useContext(DimensionContext);
    const containerRef = useRef(null);

    useEffect(() => {
        const element = containerRef.current;

        const resizeObserver = new ResizeObserver(entries => {
            for (const entry of entries) {
                const { width, height } = entry.contentRect;
                if (name) {
                    setTagDimension(prevDimensions => {
                        return prevDimensions.map(dim => dim.name === name ? { ...dim, width, height } : dim);
                    });
                } else {
                    setParentDimension({ width, height });
                }
            }
        });

        if (element) {
            resizeObserver.observe(element);
        }

        return () => {
            if (element) {
                resizeObserver.unobserve(element);
            }
        };
    }, [name, setParentDimension, setTagDimension]);

    const dimensions = name ? tagDimension.find(dim => dim.name === name) || { width: 0, height: 0, name } : parentDimension;
    return { containerRef, dimensions };
};