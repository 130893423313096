import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomCheckbox from '../../Util/CustomCheckbox/CustomCheckbox';
import { PmrClassification } from '../../../models/PmrClassification';
import { trackActionClicked } from '../../../api/trackActionClickedApi';
import { TrackedActions } from '../../../models/TrackedActionsEnum';
import useFilters from '../../../hooks/useFilters';
import { CalledFrom } from '../../../models/CalledFromEnum';

interface IProps {
  indicatorCall?: boolean;
  calledFrom: CalledFrom;
}
type Props = IProps;

const handleItemsPmrClassification = ({ calledFrom, indicatorCall, filterIndicators, filter, filterEarlyWarnings }) => {
  if (calledFrom === CalledFrom.EARLY_WARNINGS) {
    return filterEarlyWarnings.common.pmrId;
  }
  return indicatorCall ? filterIndicators.common.pmrId : filter.common.pmrId;
};
const PmrFilter = (props: Props) => {
  const { t } = useTranslation();
  const { indicatorCall, calledFrom } = props;
  const {
    filter,
    filterIndicators,
    dispatch,
    setFilter,
    setFilterIndicators,
    filterEarlyWarnings,
    setFilterEarlyWarnings,
  } = useFilters();

  const setPmrClassificationFilter = (receivedValue, selected) => {
    const itemPmrClassification = { PmrClassification: receivedValue, selected };
    const itemsPmrClassification = handleItemsPmrClassification({
      calledFrom,
      indicatorCall,
      filterIndicators,
      filter,
      filterEarlyWarnings,
    });
    let foundIndex = 0;
    foundIndex = itemsPmrClassification.findIndex(
      pmr => pmr.PmrClassification === itemPmrClassification.PmrClassification,
    );
    itemsPmrClassification[foundIndex] = itemPmrClassification;
    return itemsPmrClassification;
  };

  const isSelected = (selected, receivedValue) => {
    const itemsPmrClassification = setPmrClassificationFilter(receivedValue, selected);
    if (calledFrom === CalledFrom.EARLY_WARNINGS) {
      dispatch(
        setFilterEarlyWarnings({
          ...filterEarlyWarnings,
          common: {
            ...filterEarlyWarnings.common,
            pmrId: itemsPmrClassification,
          },
        }),
      );
      trackActionClicked(TrackedActions.pmrFilterChangedFromEarlyWarnings);
    } else {
      if (indicatorCall) {
        dispatch(
          setFilterIndicators({
            ...filterIndicators,
            common: {
              ...filterIndicators.common,
              pmrId: itemsPmrClassification,
            },
          }),
        );
        trackActionClicked(TrackedActions.pmrFilterChangedFromPortfolio);
      } else {
        dispatch(
          setFilter({
            ...filter,
            common: {
              ...filter.common,
              pmrId: itemsPmrClassification,
              itSearch: true,
            },
          }),
        );
        trackActionClicked(TrackedActions.pmrFilterChangedFromProject);
      }
    }
  };

  return (
    <div id="pmrContainer">
      <CustomCheckbox
        isChecked={handleCheckboxSelected({
          calledFrom,
          indicatorCall,
          filterIndicators,
          filter,
          filterEarlyWarnings,
          pmrClassification: PmrClassification.Green,
        })}
        handleOnClick={isChecked => isSelected(isChecked, PmrClassification.Green)}
        labelText={t('satisfactory')}
      />
      <CustomCheckbox
        isChecked={handleCheckboxSelected({
          calledFrom,
          indicatorCall,
          filterIndicators,
          filter,
          filterEarlyWarnings,
          pmrClassification: PmrClassification.Yellow,
        })}
        handleOnClick={isChecked => isSelected(isChecked, PmrClassification.Yellow)}
        labelText={t('alert')}
      />
      <CustomCheckbox
        isChecked={handleCheckboxSelected({
          calledFrom,
          indicatorCall,
          filterIndicators,
          filter,
          filterEarlyWarnings,
          pmrClassification: PmrClassification.Red,
        })}
        handleOnClick={isChecked => isSelected(isChecked, PmrClassification.Red)}
        labelText={t('problem')}
      />
      <CustomCheckbox
        isChecked={handleCheckboxSelected({
          calledFrom,
          indicatorCall,
          filterIndicators,
          filter,
          filterEarlyWarnings,
          pmrClassification: PmrClassification.Na,
        })}
        handleOnClick={isChecked => isSelected(isChecked, PmrClassification.Na)}
        labelText={t('not_assigned')}
      />
    </div>
  );
};

export default PmrFilter;

const handleCheckboxSelected = ({
  calledFrom,
  indicatorCall,
  filterIndicators,
  filter,
  filterEarlyWarnings,
  pmrClassification,
}) => {
  if (calledFrom === CalledFrom.EARLY_WARNINGS) {
    return filterEarlyWarnings.common.pmrId[pmrClassification].selected;
  }
  return indicatorCall
    ? filterIndicators.common.pmrId[pmrClassification].selected
    : filter.common.pmrId[pmrClassification].selected;
};
