import { createReducer } from '@reduxjs/toolkit';
import { setIsDelegate } from "../actions/reviewsDelegate"


interface reviewsDelegateProps {
    isDelegate: boolean | null;
}

const initialState: reviewsDelegateProps = {
    isDelegate: null
};

const reviewsDelegateReducer = createReducer(initialState, builder => {
    builder
        .addCase(setIsDelegate, (state, action) => {
            state.isDelegate = action.payload;
        });
});

export default reviewsDelegateReducer;