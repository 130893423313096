import React from 'react';
import { useTranslation } from 'react-i18next';
import './UndoButton.scss';

type Props = {
  handleClick: any;
  customStyle?: {};
  customText?: any;
  disabled?: boolean;
};

const UndoButton = (props: Props) => {
  const { handleClick, customText, customStyle, disabled } = props;
  const { t } = useTranslation();

  return (
    <button style={customStyle || {}} className="btn btn-primary-outlined" onClick={handleClick} disabled={disabled}>
      <span className="text">{customText || t('undo_changes')}</span>
    </button>
  );
};

export default UndoButton;
