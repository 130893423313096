import React, { useEffect, useState } from 'react';
import './PortfolioSection.scss';
import { getSectionReports, deleteReport, getReportFile } from '../../../../api/reportApi';
import { TableContainer, Table, TableBody } from '@material-ui/core';
import { TableCellHeaderReport } from '../ReportsTableShared.Styles';
import { useTranslation } from 'react-i18next';
import { RowTableReportsSection } from '../RowTableReportsSection/RowTableReportsSection';
import { StyledTableHeader, StyledTableRowHeader } from '../../../PortfolioDetail/PorfolioTableShared.Styles';
import { getYearsOptions } from '../../../../services/util';
import { sortDates, customComparsion } from '../../../../services/sortsUtil';
import Division from '../../../../models/Division';
import { useSelector } from 'react-redux';
import Region from '../../../../models/Region';
import { parseData, createReportNameFile, getHeaderTable } from './Util';
import { haveAppliedFilters } from '../ReportsSection';
import { ReportDownload } from '../../../Util/Modals/ReportDownload/ReportDownload';
import { ReportsSectionsEmptyState } from '../ReportsSectionsEmptyState/ReportsSectionsEmptyState';
import { GetReportsFileTypeEnum, GetSectionReportEnum, ReportStatusEnum } from '../../../../models/ReportEnums';
import { ReportSendByMail } from '../../../Util/Modals/ReportSendByMail/ReportSendByMail';
import { SectionPreloader } from '../SectionPreloader/SectionPreloader';
import { ReportType } from '../../../../models/ReportType';

const pollingMiliseconds = 20000;

const sortAscValue = sortAsc => {
  return sortAsc ? 'asc' : 'desc';
};

type Props = {
  sendReports: any;
  filters: any;
};
export const PortfolioSection = ({ sendReports, filters }: Props) => {
  const { t } = useTranslation();
  const [portfolioReportData, setPortfolioReportData] = useState<any[]>([]);
  const [portfolioReportFiltered, setPortfolioReportFiltered] = useState<any[]>([]);
  const [openDownloadReportPortfolioModal, setOpenDownloadReportPortfolioModal] = React.useState(false);
  const [openSendByMailReportPortfolioModal, setOpenSendByMailReportPortfolioModal] = React.useState(false);
  const [fileTitle, setFileTitle] = React.useState('');
  const [reportId, setReportId] = React.useState('');
  const [selectedReport, setSelectedReport] = React.useState<any>({});
  const divisionsState = useSelector<any, Division[]>(state => state.divisions);
  const regionsState = useSelector<any, Region[]>(state => state.regions);
  const [sortAsc, setSortAsc] = React.useState(true);
  const [loading, setLoading] = useState(true);

  const getPortfolioData = () => {
    getSectionReports(GetSectionReportEnum.Portfolio_Section).then((response: any) => {
      setPortfolioReportData(parseData(response, divisionsState, regionsState, t));
      setPortfolioReportFiltered(parseData(response, divisionsState, regionsState, t));
      sendReports(getYearsOptions(response));
      setLoading(false);
    });
  };
  const filterByMonthAndYear = (projects, filters) => {
    return haveAppliedFilters(filters, projects);
  };

  useEffect(() => {
    const result = filterByMonthAndYear(portfolioReportData, filters);
    setPortfolioReportFiltered(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    getPortfolioData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!portfolioReportData.some(item => item.reportStatus === ReportStatusEnum.Generating)) {
        return () => clearInterval(interval);
      }
      getPortfolioData();
      return true;
    }, pollingMiliseconds);

    return () => clearInterval(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioReportData]);

  const sortColumn = (projects, keyToSort) => {
    if (keyToSort === 'dateGenerated') {
      projects.sort(sortDates(keyToSort, sortAscValue(sortAsc)));
    } else {
      setPortfolioReportFiltered(projects.sort(sortTranslatedFilters(keyToSort, sortAscValue(sortAsc))));
    }
    setSortAsc(!sortAsc);
  };

  const deletePortfolioReport = id => {
    deleteReport(id).then(response => {
      if (response === 200) {
        const reports = portfolioReportFiltered.filter(report => report.id !== id);
        setPortfolioReportFiltered(reports);
        setPortfolioReportData(reports);
      }
    });
  };

  const handleDownloadReport = reportId => {
    setSelectedReport(reportId);
    setOpenDownloadReportPortfolioModal(true);
  };
  const handleSendByMailReport = report => {
    setReportId(report.id);
    setFileTitle(
      createReportNameFile(GetSectionReportEnum.Portfolio_Section, GetReportsFileTypeEnum.PDF, selectedReport, t),
    );
    setOpenSendByMailReportPortfolioModal(true);
  };

  const handleReportByFormat = formatType => {
    const completeFileName = createReportNameFile(
      GetSectionReportEnum.Portfolio_Section,
      formatType,
      selectedReport,
      t,
    );
    getReportFile(selectedReport.id, formatType, completeFileName);
    setOpenDownloadReportPortfolioModal(false);
  };

  return !loading ? (
    <div className="portfolio-section-report-table-container">
      {portfolioReportFiltered.length !== 0 ? (
        <TableContainer>
          <Table>
            <StyledTableHeader>
              <StyledTableRowHeader>
                {getHeaderTable(ReportType.Portfolio).map(header => (
                  <TableCellHeaderReport
                  className='header-title'
                    key={header.id}
                    onClick={() => sortColumn(portfolioReportFiltered, header.key)}
                  >
                    {t(header.label)}

                    {header.key !== 'others' && <i className="sort-icon"></i>}
                  </TableCellHeaderReport>
                ))}
                <TableCellHeaderReport />
              </StyledTableRowHeader>
            </StyledTableHeader>
            <TableBody>
              {portfolioReportFiltered.map((report, index) => (
                <RowTableReportsSection
                  key={report.id}
                  report={report}
                  index={index}
                  deleteReport={deletePortfolioReport}
                  downloadReport={handleDownloadReport}
                  sendByMailReport={handleSendByMailReport}
                  reportType={ReportType.Portfolio}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <ReportsSectionsEmptyState message={'you_can_create_them_from_the_portfolio_section'} />
      )}

      <ReportDownload
        isOpen={openDownloadReportPortfolioModal}
        closeModal={() => {
          setOpenDownloadReportPortfolioModal(false);
        }}
        submit={handleReportByFormat}
        reportType={ReportType.Portfolio}
      />

      <ReportSendByMail
        isOpen={openSendByMailReportPortfolioModal}
        closeModal={() => {
          setOpenSendByMailReportPortfolioModal(false);
        }}
        reportTitle={fileTitle}
        reportId={reportId}
      />
    </div>
  ) : (
    <SectionPreloader />
  );
};

const sortTranslatedFilters = (key: string, order = 'desc') => {
  return (a, b) => {
    const existA = a.parameters.filters.find(fieldToFind => fieldToFind.field === key);
    const existB = b.parameters.filters.find(fieldToFind => fieldToFind.field === key);
    if (!existA || !existB) {
      return 0;
    }

    const varA = a.parameters.filters.find(fieldToFind => fieldToFind.field === key).value;
    const varB = b.parameters.filters.find(fieldToFind => fieldToFind.field === key).value;

    return customComparsion(varA, varB, order);
  };
};
