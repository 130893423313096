import React from 'react';
import './CustomRadioButton.scss';

interface IProps extends React.HTMLProps<JSX.Element> {
  labelText: string;
  handleOnClick: any;
  isChecked: boolean;
  customStyle?: any;
  customChecked?: any;
}
type Props = IProps;
export const CustomRadioButton = ({ labelText, handleOnClick, isChecked, customStyle, customChecked }: Props) => {
  return (
    <label
      className="custom-radiobutton-container "
      style={customStyle ? customStyle : {}}
      onClick={() => handleOnClick(!isChecked)}
    >
      <span className={setCheckedClasses(isChecked, customChecked)}></span>
      {labelText}
    </label>
  );
};

export default CustomRadioButton;

const setCheckedClasses = (isChecked, customChecked) => {
  if (customChecked) {
    return isChecked ? customChecked.checked : customChecked.unchecked;
  } else {
    return isChecked ? 'checked' : 'unchecked';
  }
};
