import React from 'react';
import FilterIndicators from '../../../../../models/FilterIndicators';
import { CustomCheckbox } from '../../../CustomCheckbox/CustomCheckbox';

type Props = {
  reportFilters: FilterIndicators;
  reportFiltersChanged: Function;
};
export const CutomizableExcludeRegionals = ({ reportFilters, reportFiltersChanged }: Props) => {
  const [localFilter, setLocalFilter] = React.useState(reportFilters);

  const checkboxChange = (checked: boolean) => {
    const newFilter = {
      ...localFilter,
      common: {
        ...localFilter.common,
        excludeRegionals: checked,
      },
    };
    setLocalFilter(newFilter);
    reportFiltersChanged({ excludeRegionals: checked });
  };

  return (
    <>
      <CustomCheckbox
        isChecked={localFilter.common.excludeRegionals}
        handleOnClick={(isChecked: boolean) => checkboxChange(isChecked)}
        labelText={''}
      />
    </>
  );
};
