import React from 'react';
import { useTranslation } from 'react-i18next';
import SelectableProjectsResponsive from '../SelectableProjectsResponsive/SelectableProjectsResponsive';

type Props = {
  review: any;
  displayMyPortfolio?: boolean;
};

const customTitleStyle = { color: '#A5ADBB', borderTop: '1px dashed #EBECF0', paddingTop: 15 };

const ProjectsMobile = ({ review, displayMyPortfolio }: Props) => {
  const { t } = useTranslation();

  return !displayMyPortfolio ? (
    <SelectableProjectsResponsive
      projects={{ list: review.projects, title: t('selected_revision_projects') }}
      customStyle={{ title: customTitleStyle }}
      canCreateAccordance={review.canCreateAccordance}
    />
  ) : (
    <>
      <SelectableProjectsResponsive
        projects={{ list: review.projects.filter(p => p.inPortfolio), title: t('selected_revision_my_portfolio') }}
        customStyle={{ title: customTitleStyle }}
        canCreateAccordance={review.canCreateAccordance}
        customNoResultsMessage={t('no_results_my_portfolio')}
      />
      <SelectableProjectsResponsive
        projects={{ list: review.projects.filter(p => !p.inPortfolio), title: t('selected_revision_other') }}
        customStyle={{ title: customTitleStyle }}
        canCreateAccordance={review.canCreateAccordance}
      />
    </>
  );
};

export default ProjectsMobile;
