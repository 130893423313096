import { filter, lowerCase } from 'lodash';
import { languagesKeys } from '../../ContentManagerKeys';

export const tourCommonFields = {
  title: '',
  content: '',
  attachments: [],
  isChecked: false,
  titleError: false,
  contentError: false,
};

export const tourFields = [
  {
    ...tourCommonFields,
    tourLanguageId: languagesKeys.spanish,
  },
  {
    ...tourCommonFields,
    tourLanguageId: languagesKeys.english,
  },
  {
    ...tourCommonFields,
    tourLanguageId: languagesKeys.portuguese,
  },
  {
    ...tourCommonFields,
    tourLanguageId: languagesKeys.french,
  },
];

export const getOrderOptions = (isEdit, tourOrder) =>
  isEdit ? filter(tourOrder, order => order.id !== tourOrder.length) : tourOrder;

export const hasOrderChanged = (isEdit, newTourListItem, tourOrder, tourListItem) => {
  return isEdit
    ? newTourListItem.order !== tourListItem.order
    : newTourListItem.order !== tourOrder[tourOrder.length - 1].id;
};

export const getButtonTitle = isEdit =>
  isEdit ? 'contents_helpCenter_tour_edit_button_label' : 'contents_helpCenter_tour_add_button_label';

export const isChecked = (type, value, tourItem) =>
  (type === 'title' && value !== '' && tourItem.content !== '') ||
  (type === 'content' && value !== '' && tourItem.title !== '');

export const getAttachments = tourItem => (tourItem.attachment ? [tourItem.attachment] : []);

export const getPostTourItem = (formFields, findLanguageByValue, languagesData, order) => ({
  order,
  translations: formFields.map(translation => {
    const { title, tourLanguageId, attachments, content } = translation;
    return {
      title,
      content,
      attachment: attachments[0] || null,
      languageId: findLanguageByValue(languagesData, tourLanguageId).id,
    };
  }),
});

export const mapTranslationToTours = (tourListItem, languagesData, findLanguage) =>
  tourListItem.translations.map(tourItem => ({
    ...tourItem,
    tourLanguageId: lowerCase(findLanguage(languagesData, tourItem).name),
    attachments: getAttachments(tourItem),
    isChecked: tourItem.title !== '',
  }));

export const getOnChangeUpdatedFormFields = (formFields, type, value, language) =>
  formFields.map(tourItem =>
    tourItem.tourLanguageId === languagesKeys[language]
      ? {
          ...tourItem,
          [type]: value,
          isChecked: isChecked(type, value, tourItem),
          titleError: false,
          contentError: false,
        }
      : tourItem,
  );
