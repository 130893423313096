import { EarlyWarningButtonType, IconColorsEnum, WithColorEnum } from '../../../models/EarlyWarningsSection';
import { MenuTabs } from '../../MenuTabs/userTabsInterface';
import {
  handleDisbursementExpired,
  handleElegibleUnisbursementAlert,
  handleLast3Pmr,
  handlePartialCancellations,
  handlePendingSignatureAlert,
  handleSafeguardPerformance,
} from '../ProjectsWithEarlyWarningsTable/IconRenderByColumn';

export const handleFilterAlertByButtonEWTYpe = (buttonEWType, project) => {
  switch (buttonEWType) {
    case EarlyWarningButtonType.PROJECTS_PENDING_SIGNATURE:
      return (
        handlePendingSignatureAlert(`${project[buttonEWType]}WithColor`, project[buttonEWType]) !==
          IconColorsEnum.GREEN &&
        handlePendingSignatureAlert(`${project[buttonEWType]}WithColor`, project[buttonEWType]) !==
          IconColorsEnum.NOT_APPLICABLE
      );

    case EarlyWarningButtonType.ELIGIBLE_PROJECTS_PENDING_FIRST_DISBURSEMENT:
      return (
        handleElegibleUnisbursementAlert(WithColorEnum[`${project[buttonEWType]}WithColor`], project[buttonEWType]) !==
          IconColorsEnum.GREEN &&
        handleElegibleUnisbursementAlert(WithColorEnum[`${project[buttonEWType]}WithColor`], project[buttonEWType]) !==
          IconColorsEnum.NOT_APPLICABLE
      );

    case EarlyWarningButtonType.PROJECTS_WITH_RECENT_ALERT_OR_PROBLEM_CLASSIFICATIONS:
      return (
        handleLast3Pmr(WithColorEnum[`${project[buttonEWType]}WithColor`], project[buttonEWType]) !==
          IconColorsEnum.GREEN &&
        handleLast3Pmr(WithColorEnum[`${project[buttonEWType]}WithColor`], project[buttonEWType]) !==
          IconColorsEnum.NOT_APPLICABLE
      );

    case EarlyWarningButtonType.PROJECTS_WITH_UNSATISFACTORY_PERFORMANCE_IN_SAFEGUARDS:
      return (
        handleSafeguardPerformance(WithColorEnum[`${project[buttonEWType]}WithColor`], project[buttonEWType]) !==
          IconColorsEnum.GREEN &&
        handleSafeguardPerformance(WithColorEnum[`${project[buttonEWType]}WithColor`], project[buttonEWType]) !==
          IconColorsEnum.NOT_APPLICABLE
      );

    case EarlyWarningButtonType.PROJECTS_WITH_PARTIAL_CANCELLATIONS:
      return (
        handlePartialCancellations(WithColorEnum[`${project[buttonEWType]}WithColor`], project[buttonEWType]) !==
        IconColorsEnum.GREEN
      );

    case EarlyWarningButtonType.PROJECTS_WITH_EXPIRED_DISBURSEMENT_DEADLINE:
      return (
        handleDisbursementExpired(WithColorEnum[`${project[buttonEWType]}WithColor`], project[buttonEWType]) !==
          IconColorsEnum.GREEN &&
        handleDisbursementExpired(WithColorEnum[`${project[buttonEWType]}WithColor`], project[buttonEWType]) !==
          IconColorsEnum.NOT_APPLICABLE
      );

    default:
      return null;
  }
};

const handleFilterProjects = ({ projectsToFilter, selectedButtons }) => {
  const FOUND_PROJECTS_WITH_PARTIAL_CANCELLATIONS = selectedButtons.find(
    btn => btn.buttonEWType === EarlyWarningButtonType.PROJECTS_WITH_PARTIAL_CANCELLATIONS,
  );
  const FOUND_ELIGIBLE_PROJECTS_PENDING_FIRST_DISBURSEMENT = selectedButtons.find(
    btn => btn.buttonEWType === EarlyWarningButtonType.ELIGIBLE_PROJECTS_PENDING_FIRST_DISBURSEMENT,
  );
  const FOUND_PROJECTS_WITH_RECENT_ALERT_OR_PROBLEM_CLASSIFICATIONS = selectedButtons.find(
    btn => btn.buttonEWType === EarlyWarningButtonType.PROJECTS_WITH_RECENT_ALERT_OR_PROBLEM_CLASSIFICATIONS,
  );
  const FOUND_PROJECTS_WITH_UNSATISFACTORY_PERFORMANCE_IN_SAFEGUARDS = selectedButtons.find(
    btn => btn.buttonEWType === EarlyWarningButtonType.PROJECTS_WITH_UNSATISFACTORY_PERFORMANCE_IN_SAFEGUARDS,
  );
  const FOUND_PROJECTS_WITH_EXPIRED_DISBURSEMENT_DEADLINE = selectedButtons.find(
    btn => btn.buttonEWType === EarlyWarningButtonType.PROJECTS_WITH_EXPIRED_DISBURSEMENT_DEADLINE,
  );
  const FOUND_PROJECTS_PENDING_SIGNATURE = selectedButtons.find(
    btn => btn.buttonEWType === EarlyWarningButtonType.PROJECTS_PENDING_SIGNATURE,
  );
  return projectsToFilter.filter(
    p =>
      (p.cancelled !== null &&
        handleFilterAlertByButtonEWTYpe(EarlyWarningButtonType.PROJECTS_WITH_PARTIAL_CANCELLATIONS, p) &&
        FOUND_PROJECTS_WITH_PARTIAL_CANCELLATIONS) ||
      (p.eligibleUnDisbursement !== null &&
        handleFilterAlertByButtonEWTYpe(EarlyWarningButtonType.ELIGIBLE_PROJECTS_PENDING_FIRST_DISBURSEMENT, p) &&
        FOUND_ELIGIBLE_PROJECTS_PENDING_FIRST_DISBURSEMENT) ||
      (p.pmr !== null &&
        handleFilterAlertByButtonEWTYpe(
          EarlyWarningButtonType.PROJECTS_WITH_RECENT_ALERT_OR_PROBLEM_CLASSIFICATIONS,
          p,
        ) &&
        FOUND_PROJECTS_WITH_RECENT_ALERT_OR_PROBLEM_CLASSIFICATIONS) ||
      (p.esgSafeguard !== null &&
        handleFilterAlertByButtonEWTYpe(
          EarlyWarningButtonType.PROJECTS_WITH_UNSATISFACTORY_PERFORMANCE_IN_SAFEGUARDS,
          p,
        ) &&
        FOUND_PROJECTS_WITH_UNSATISFACTORY_PERFORMANCE_IN_SAFEGUARDS) ||
      (p.expired !== null &&
        handleFilterAlertByButtonEWTYpe(EarlyWarningButtonType.PROJECTS_WITH_EXPIRED_DISBURSEMENT_DEADLINE, p) &&
        FOUND_PROJECTS_WITH_EXPIRED_DISBURSEMENT_DEADLINE) ||
      (p.pendingSignature !== null &&
        handleFilterAlertByButtonEWTYpe(EarlyWarningButtonType.PROJECTS_PENDING_SIGNATURE, p) &&
        FOUND_PROJECTS_PENDING_SIGNATURE),
  );
};

const findeCountryFilterFieldOnSelectedTab = (earlyWarningsSelectedTab: MenuTabs) => {
  const filterField = earlyWarningsSelectedTab.filters.find(filter => filter.field === 'ResponsibleCountryId');
  return filterField ? `${filterField.value[0].toString()}|ResponsibleCountryId` : earlyWarningsSelectedTab.title;
};
export { handleFilterProjects, findeCountryFilterFieldOnSelectedTab };
