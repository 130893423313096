import { TrendChartInformation } from '../../../models/EarlyWarnings';
import { EarlyWarningButtonType, EWProjectResponse } from '../../../models/EarlyWarningsSection';
import {
  filterProjectsByAlert,
  sumCancelled,
  sumElegibleUnDisbursement,
  sumesgSafeguard,
  sumExpired,
  sumLastPmr,
  sumPendigSignature,
} from '../Util';

const barThickness = [13, 8, 8, 8, 8];
const CHART_OBJECT_INIT_STATE: TrendChartInformation = {
  key: EarlyWarningButtonType.PROJECTS_PENDING_SIGNATURE,
  title: 'signature_pending',
  chartData: {
    labels: ['today'],
    datasets: [
      {
        data: [],
        backgroundColor: '#91E2A1',
        borderWidth: 0,
        barThickness,
        label: 'green',
      },
      {
        data: [],
        backgroundColor: '#EF9206',
        borderWidth: 0,
        barThickness,
        label: 'yellow',
      },
      {
        data: [],
        backgroundColor: '#F06D6D',
        borderWidth: 0,
        barThickness,
        label: 'red',
      },
    ],
  },
  bullets: [
    { className: 'bulletGreen', translationKey: 'within_the_regulation_time' },
    { className: 'bulletYellow', translationKey: 'up_to_one_year_extension' },
    { className: 'bulletRed', translationKey: 'more_than_one_year_extension' },
  ],
};

const createChartObject = options => {
  const { label, colorsData, bulletsTranslationData, key, title, initialState } = options;

  return {
    ...initialState,
    key,
    title,
    chartData: {
      ...initialState.chartData,
      labels: [label],
      datasets: [
        ...initialState.chartData.datasets.map((ds, idx) => {
          return { ...ds, data: [colorsData[idx]] };
        }),
      ],
    },
    bullets: [
      ...initialState.bullets.map((b, idx) => {
        return { ...b, translationKey: bulletsTranslationData[idx] };
      }),
    ],
  };
};

const handlePendingSignatureTodaySnapshot = (todaySnapshot: EWProjectResponse[], { label }): TrendChartInformation => {
  const pendigSignatureProjects = filterProjectsByAlert(
    todaySnapshot,
    EarlyWarningButtonType.PROJECTS_PENDING_SIGNATURE,
  );

  const { GREEN, YELLOW, RED } = sumPendigSignature(pendigSignatureProjects);

  const colorsData = [GREEN, YELLOW, RED];
  const bulletsTranslationData = [
    'within_the_regulation_time',
    'up_to_one_year_extension',
    'more_than_one_year_extension',
  ];

  return createChartObject({
    label,
    colorsData,
    bulletsTranslationData,
    key: EarlyWarningButtonType.PROJECTS_PENDING_SIGNATURE,
    title: 'signature_pending',
    initialState: CHART_OBJECT_INIT_STATE,
  });
};

const handleElegibleUnDisbursementTodaySnapshot = (
  todaySnapshot: EWProjectResponse[],
  { label },
): TrendChartInformation => {
  const elegibleUnDisbursementProjects = filterProjectsByAlert(
    todaySnapshot,
    EarlyWarningButtonType.ELIGIBLE_PROJECTS_PENDING_FIRST_DISBURSEMENT,
  );

  const { GREEN, YELLOW, RED } = sumElegibleUnDisbursement(elegibleUnDisbursementProjects);

  const colorsData = [GREEN, YELLOW, RED];
  const bulletsTranslationData = [
    'less_than_one_month_after_eligibility',
    'one_to_six_months_after_eligibility',
    'more_than_six_months_after_eligibility',
  ];
  return createChartObject({
    label,
    colorsData,
    bulletsTranslationData,
    key: EarlyWarningButtonType.ELIGIBLE_PROJECTS_PENDING_FIRST_DISBURSEMENT,
    title: 'eligible_pending_the_first_disbursement_second_phrase',
    initialState: CHART_OBJECT_INIT_STATE,
  });
};

const handleLastPmrTodaySnapshot = (todaySnapshot: EWProjectResponse[], { label }): TrendChartInformation => {
  const lastThreePmrProjects = filterProjectsByAlert(
    todaySnapshot,
    EarlyWarningButtonType.PROJECTS_WITH_RECENT_ALERT_OR_PROBLEM_CLASSIFICATIONS,
  );

  const { GREEN, YELLOW, RED } = sumLastPmr(lastThreePmrProjects);
  const colorsData = [GREEN, YELLOW, RED];
  const bulletsTranslationData = [
    'no_alert_or_problem_in_the_last_3_years',
    'alert_or_problem_in_one_or_the_last_two_years',
    'alert_or_problem_in_the_last_three_years',
  ];
  return createChartObject({
    label,
    colorsData,
    bulletsTranslationData,
    key: EarlyWarningButtonType.PROJECTS_WITH_RECENT_ALERT_OR_PROBLEM_CLASSIFICATIONS,
    title: 'with_recent_alert_or_problem_classifications',
    initialState: CHART_OBJECT_INIT_STATE,
  });
};

const handleCancelledTodaySnapshot = (todaySnapshot: EWProjectResponse[], { label }): TrendChartInformation => {
  const { GREEN, YELLOW, RED } = sumCancelled(todaySnapshot);

  const colorsData = [GREEN, YELLOW, RED];
  const bulletsTranslationData = [
    'no_partial_cancellations',
    'with_cancellations_of_up_to_15',
    'with_cancellations_higher_than_15',
  ];
  return createChartObject({
    label,
    colorsData,
    bulletsTranslationData,
    key: EarlyWarningButtonType.PROJECTS_WITH_PARTIAL_CANCELLATIONS,
    title: 'with_partial_cancellations',
    initialState: CHART_OBJECT_INIT_STATE,
  });
};

const handleExpiredTodaySnapshot = (todaySnapshot: EWProjectResponse[], { label }): TrendChartInformation => {
  const expiredProjects = filterProjectsByAlert(
    todaySnapshot,
    EarlyWarningButtonType.PROJECTS_WITH_EXPIRED_DISBURSEMENT_DEADLINE,
  );

  const { GREEN, YELLOW, RED } = sumExpired(expiredProjects);
  const colorsData = [GREEN, YELLOW, RED];
  const bulletsTranslationData = [
    'within_the_period_for_justification_120_days',
    'with_date_for_last_disbursement_expired_by_less_than_1_year',
    'with_date_for_last_disbursement_expired_by_1_year_or_more',
  ];
  return createChartObject({
    label,
    colorsData,
    bulletsTranslationData,
    key: EarlyWarningButtonType.PROJECTS_WITH_EXPIRED_DISBURSEMENT_DEADLINE,
    title: 'with_overdue_disbursement_term',
    initialState: CHART_OBJECT_INIT_STATE,
  });
};

const handleEsgSafeguardTodaySnapshot = (todaySnapshot: EWProjectResponse[], { label }): TrendChartInformation => {
  const esgSafeguardProjects = filterProjectsByAlert(
    todaySnapshot,
    EarlyWarningButtonType.PROJECTS_WITH_UNSATISFACTORY_PERFORMANCE_IN_SAFEGUARDS,
  );

  const { GREEN, LIGHT_GREEN, YELLOW, RED } = sumesgSafeguard(esgSafeguardProjects);
  const colorsData = [GREEN, LIGHT_GREEN, YELLOW, RED];
  const bulletsTranslationData = [
    'satisfactory',
    'partially_satisfactory',
    'partially_unsatisfactory',
    'unsatisfactory',
  ];
  const initialDatasets = [...CHART_OBJECT_INIT_STATE.chartData.datasets];
  const initialBullets = [...CHART_OBJECT_INIT_STATE.bullets];
  initialDatasets.splice(1, 0, {
    data: [],
    backgroundColor: '#C7EAA3',
    borderWidth: 0,
    barThickness,
    label: 'lightGreen',
  });

  initialBullets.splice(1, 0, { className: 'bulletLightGreen', translationKey: '' });
  return createChartObject({
    label,
    colorsData,
    bulletsTranslationData,
    key: EarlyWarningButtonType.PROJECTS_WITH_UNSATISFACTORY_PERFORMANCE_IN_SAFEGUARDS,
    title: 'with_unsatisfactory_performance_in_safeguards',
    initialState: {
      ...CHART_OBJECT_INIT_STATE,
      chartData: { ...CHART_OBJECT_INIT_STATE.chartData, datasets: initialDatasets },
      bullets: initialBullets,
    },
  });
};

export {
  handlePendingSignatureTodaySnapshot,
  handleElegibleUnDisbursementTodaySnapshot,
  handleLastPmrTodaySnapshot,
  handleCancelledTodaySnapshot,
  handleExpiredTodaySnapshot,
  handleEsgSafeguardTodaySnapshot,
};
