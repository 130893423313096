import React from 'react';
import './ProjectsListSkeleton.scss';
import Skeleton from '@material-ui/lab/Skeleton';

type Props = {
  hideLastColumn?: boolean;
};
export const ProjectsListSkeleton = ({ hideLastColumn }: Props) => {
  return (
    <>
      <div className="grid-skeleton">
        <div className="flex-item-header">
          <Skeleton animation="wave" variant="text" width={58} height={11} />
          <Skeleton animation="wave" variant="text" width={'100%'} height={15} style={{ marginTop: 20 }} />
          <Skeleton animation="wave" variant="text" width={'100%'} height={15} style={{ marginTop: 30 }} />
          <Skeleton animation="wave" variant="text" width={'100%'} height={15} style={{ marginTop: 30 }} />
          <Skeleton animation="wave" variant="text" width={'100%'} height={15} style={{ marginTop: 30 }} />
          <Skeleton animation="wave" variant="text" width={'100%'} height={15} style={{ marginTop: 30 }} />
          <Skeleton animation="wave" variant="text" width={'100%'} height={15} style={{ marginTop: 30 }} />
          <Skeleton animation="wave" variant="text" width={'100%'} height={15} style={{ marginTop: 30 }} />
          <Skeleton animation="wave" variant="text" width={'100%'} height={15} style={{ marginTop: 30 }} />
          <Skeleton animation="wave" variant="text" width={'100%'} height={15} style={{ marginTop: 30 }} />
        </div>
        <div className="flex-item-header">
          <Skeleton animation="wave" variant="text" width={58} height={11} />
          <Skeleton animation="wave" variant="text" width={'100%'} height={15} style={{ marginTop: 20 }} />
          <Skeleton animation="wave" variant="text" width={'100%'} height={15} style={{ marginTop: 30 }} />
          <Skeleton animation="wave" variant="text" width={'100%'} height={15} style={{ marginTop: 30 }} />
          <Skeleton animation="wave" variant="text" width={'100%'} height={15} style={{ marginTop: 30 }} />
          <Skeleton animation="wave" variant="text" width={'100%'} height={15} style={{ marginTop: 30 }} />
          <Skeleton animation="wave" variant="text" width={'100%'} height={15} style={{ marginTop: 30 }} />
          <Skeleton animation="wave" variant="text" width={'100%'} height={15} style={{ marginTop: 30 }} />
          <Skeleton animation="wave" variant="text" width={'100%'} height={15} style={{ marginTop: 30 }} />
          <Skeleton animation="wave" variant="text" width={'100%'} height={15} style={{ marginTop: 30 }} />
        </div>
        <div className="flex-item-header">
          <Skeleton animation="wave" variant="text" width={70} height={11} />
          <Skeleton animation="wave" variant="text" width={140} height={15} style={{ marginTop: 20 }} />
          <Skeleton animation="wave" variant="text" width={140} height={15} style={{ marginTop: 30 }} />
          <Skeleton animation="wave" variant="text" width={140} height={15} style={{ marginTop: 30 }} />
          <Skeleton animation="wave" variant="text" width={140} height={15} style={{ marginTop: 30 }} />
          <Skeleton animation="wave" variant="text" width={140} height={15} style={{ marginTop: 30 }} />
          <Skeleton animation="wave" variant="text" width={140} height={15} style={{ marginTop: 30 }} />
          <Skeleton animation="wave" variant="text" width={140} height={15} style={{ marginTop: 30 }} />
          <Skeleton animation="wave" variant="text" width={140} height={15} style={{ marginTop: 30 }} />
          <Skeleton animation="wave" variant="text" width={140} height={15} style={{ marginTop: 30 }} />
        </div>
        <div className="flex-item-header">
          <Skeleton animation="wave" variant="text" width={75} height={11} />
          <Skeleton animation="wave" variant="text" width={140} height={15} style={{ marginTop: 20 }} />
          <Skeleton animation="wave" variant="text" width={140} height={15} style={{ marginTop: 30 }} />
          <Skeleton animation="wave" variant="text" width={140} height={15} style={{ marginTop: 30 }} />
          <Skeleton animation="wave" variant="text" width={140} height={15} style={{ marginTop: 30 }} />
          <Skeleton animation="wave" variant="text" width={140} height={15} style={{ marginTop: 30 }} />
          <Skeleton animation="wave" variant="text" width={145} height={15} style={{ marginTop: 30 }} />
          <Skeleton animation="wave" variant="text" width={140} height={15} style={{ marginTop: 30 }} />
          <Skeleton animation="wave" variant="text" width={140} height={15} style={{ marginTop: 30 }} />
          <Skeleton animation="wave" variant="text" width={140} height={15} style={{ marginTop: 30 }} />
        </div>
        <div className="flex-item-header">
          <Skeleton animation="wave" variant="text" width={80} height={11} />
          <Skeleton animation="wave" variant="text" width={145} height={15} style={{ marginTop: 20 }} />
          <Skeleton animation="wave" variant="text" width={145} height={15} style={{ marginTop: 30 }} />
          <Skeleton animation="wave" variant="text" width={145} height={15} style={{ marginTop: 30 }} />
          <Skeleton animation="wave" variant="text" width={145} height={15} style={{ marginTop: 30 }} />
          <Skeleton animation="wave" variant="text" width={145} height={15} style={{ marginTop: 30 }} />
          <Skeleton animation="wave" variant="text" width={145} height={15} style={{ marginTop: 30 }} />
          <Skeleton animation="wave" variant="text" width={145} height={15} style={{ marginTop: 30 }} />
          <Skeleton animation="wave" variant="text" width={145} height={15} style={{ marginTop: 30 }} />
          <Skeleton animation="wave" variant="text" width={145} height={15} style={{ marginTop: 30 }} />
        </div>
        {hideLastColumn ? null : (
          <div className="flex-item-header">
            <Skeleton animation="wave" variant="text" width={65} height={11} />
            <Skeleton animation="wave" variant="text" width={145} height={15} style={{ marginTop: 20 }} />
            <Skeleton animation="wave" variant="text" width={145} height={15} style={{ marginTop: 30 }} />
            <Skeleton animation="wave" variant="text" width={145} height={15} style={{ marginTop: 30 }} />
            <Skeleton animation="wave" variant="text" width={145} height={15} style={{ marginTop: 30 }} />
            <Skeleton animation="wave" variant="text" width={145} height={15} style={{ marginTop: 30 }} />
            <Skeleton animation="wave" variant="text" width={145} height={15} style={{ marginTop: 30 }} />
            <Skeleton animation="wave" variant="text" width={145} height={15} style={{ marginTop: 30 }} />
            <Skeleton animation="wave" variant="text" width={145} height={15} style={{ marginTop: 30 }} />
            <Skeleton animation="wave" variant="text" width={145} height={15} style={{ marginTop: 30 }} />
          </div>
        )}
      </div>
    </>
  );
};
