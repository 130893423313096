import React, { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useGetAgreements } from '../hooks/useGetAgreements';
import { AgreementStatusStrings } from '../enums/accordanceStateEnum';

type DefaultValuesProps = any;

const defaultValues: DefaultValuesProps = {};

const AgreementsHelpContext = createContext(defaultValues);

export const useAgreementsHelpContext = () => useContext(AgreementsHelpContext);

export const AgreementsHelpProvider = ({ children }: { children: ReactNode }) => {
  const [currentProject, setCurrentProject] = useState<number | null>(null);
  const [postAgreementsTrigger, setPostAgreementsTrigger] = useState<boolean>(false);
  const [getAgreementsTrigger, setGetAgreementsTrigger] = useState<boolean>(false);
  const [hiddenButton, setHiddenButton] = useState<boolean>(false);
  const [extractAgreementsLength, setExtractAgreementsLength] = useState<any>();

  const [dataAgreements, setDataAgreements] = useState<any>();
  const [postDataAgreements, setPostDataAgreements] = useState<any>();
  const [postDataAgreementsValid, setPostDataAgreementsValid] = useState<any>();

  const values = useMemo(
    () => ({
      dataAgreements,
      // agreementsData,
      getAgreementsTrigger,
      postAgreementsTrigger,
      currentProject,
      setDataAgreements,
      setGetAgreementsTrigger,
      setPostAgreementsTrigger,
      setCurrentProject,
      setExtractAgreementsLength,
      extractAgreementsLength,
      hiddenButton,
      setHiddenButton,
      setPostDataAgreements,
      postDataAgreements,
      setPostDataAgreementsValid,
      postDataAgreementsValid,
    }),
    [
      postAgreementsTrigger,
      currentProject,
      getAgreementsTrigger,
      extractAgreementsLength,
      hiddenButton,
      postDataAgreements,
      postDataAgreementsValid,
    ],
  );

  return <AgreementsHelpContext.Provider value={values}>{children}</AgreementsHelpContext.Provider>;
};
