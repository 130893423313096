import React, { useState, useEffect } from 'react';
import { ReportType } from '../../../../models/ReportType';
import { useSelector } from 'react-redux';
import { haveManagerialReviewSectionRole } from '../../../Reviews/ReviewsDate/Util';
import { TableContainer, Table, TableBody } from '@material-ui/core';
import { TableCellHeaderReport } from '../ReportsTableShared.Styles';
import './ReviewSection.scss';
import { haveAppliedFilters } from '../ReportsSection';
import { RowTableReportsSection } from '../RowTableReportsSection/RowTableReportsSection';
import { StyledTableHeader, StyledTableRowHeader } from '../../../PortfolioDetail/PorfolioTableShared.Styles';
import { getYearsOptions } from '../../../../services/util';
import { sortDates, customComparsion } from '../../../../services/sortsUtil';
import { createReportNameFile, getHeaderTable } from '../PortfolioSection/Util';
import { SectionPreloader } from '../SectionPreloader/SectionPreloader';
import { useTranslation } from 'react-i18next';
import { ReportDownload } from '../../../Util/Modals/ReportDownload/ReportDownload';
import { ReportsSectionsEmptyState } from '../ReportsSectionsEmptyState/ReportsSectionsEmptyState';
import { GetReportsFileTypeEnum, GetSectionReportEnum, ReportStatusEnum } from '../../../../models/ReportEnums';
import { ReportSendByMail } from '../../../Util/Modals/ReportSendByMail/ReportSendByMail';
import { getSectionReports, deleteReport, getReportFile } from '../../../../api/reportApi';

const pollingMiliseconds = 20000;

type Props = {
  sendReports?: any;
  filters?: any;
};

export const ReviewSection = ({ sendReports, filters }: Props) => {
  const { t } = useTranslation();
  const [reviewReportData, setReviewReportData] = useState<any[]>([]);
  const [reviewReportFiltered, setReviewReportFiltered] = useState<any[]>([]);
  const [openDownloadReportModal, setOpenDownloadReportModal] = React.useState(false);
  const [openSendByMailReportModal, setOpenSendByMailReportModal] = React.useState(false);
  const [selectedReport, setSelectedReport] = React.useState<any>({});
  const { role, country } = useSelector<any, any>(state => state.user);
  const [fileTitle, setFileTitle] = React.useState('');
  const [reportId, setReportId] = React.useState('');
  const [sortAsc, setSortAsc] = React.useState(true);
  const [loading, setLoading] = useState(true);

  const getData = () => {
    getSectionReports(GetSectionReportEnum.Review_Section).then((response: any) => {
      setReviewReportData(response);
      setReviewReportFiltered(response);
      sendReports(getYearsOptions(response));
      setLoading(false);
    });
  };

  const filterByMonthAndYear = (projects, filters) => {
    return haveAppliedFilters(filters, projects);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!reviewReportData.some(item => item.reportStatus === ReportStatusEnum.Generating)) {
        return () => clearInterval(interval);
      }
      getData();
      return true;
    }, pollingMiliseconds);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewReportData]);

  useEffect(() => {
    const result = filterByMonthAndYear(reviewReportData, filters);
    setReviewReportFiltered(result);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const sortColumn = (projects, keyToSort) => {
    sortReviewReports(projects, keyToSort, sortAsc, setReviewReportFiltered);
    setSortAsc(!sortAsc);
  };

  const deleteProjectReport = id => {
    deleteReport(id).then(response => {
      if (response === 200) {
        const reports = reviewReportFiltered.filter(report => report.id !== id);
        setReviewReportFiltered(reports);
        setReviewReportData(reports);
      }
    });
  };

  const handleDownloadReport = report => {
    setSelectedReport(report);
    setOpenDownloadReportModal(true);
  };

  const handleSendByMailReport = report => {
    setSelectedReport(report);
    setReportId(report.id);
    setFileTitle(createReportNameFile(GetSectionReportEnum.Review_Section, GetReportsFileTypeEnum.PDF, report, t));
    setOpenSendByMailReportModal(true);
  };
  const handleReportByFormat = formatType => {
    const completeFileName = createReportNameFile(GetSectionReportEnum.Review_Section, formatType, selectedReport, t);
    setFileTitle(completeFileName);
    getReportFile(selectedReport.id, formatType, completeFileName);
    setOpenDownloadReportModal(false);
  };

  return !loading ? (
    <div className="review-section-report-table-container">
      {reviewReportFiltered.length !== 0 ? (
        <TableContainer>
          <Table stickyHeader style={{ tableLayout: 'auto' }} size="small">
            <StyledTableHeader>
              <StyledTableRowHeader>
                {haveManagerialReviewSectionRole(role.id, country.name) && (
                  <TableCellHeaderReport align="left" onClick={() => sortColumn(reviewReportFiltered, 'country')}>
                    {t('country')}
                    <i className="sort-icon"></i>
                  </TableCellHeaderReport>
                )}
                {getHeaderTable(ReportType.Review).map(header => (
                  <TableCellHeaderReport
                    align="left"
                    key={header.id}
                    onClick={() => sortColumn(reviewReportFiltered, header.key)}
                  >
                    {t(header.label)}

                    {showSorting(header.key) && <i className="sort-icon"></i>}
                  </TableCellHeaderReport>
                ))}

                <TableCellHeaderReport align="right" />
              </StyledTableRowHeader>
            </StyledTableHeader>
            <TableBody>
              {reviewReportFiltered.map((report, index) => (
                <RowTableReportsSection
                  key={report.id}
                  report={report}
                  index={index}
                  deleteReport={deleteProjectReport}
                  downloadReport={handleDownloadReport}
                  sendByMailReport={handleSendByMailReport}
                  reportType={ReportType.Review}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <ReportsSectionsEmptyState message={'you_can_create_them_from_the_reviews_section'} />
      )}

      <ReportDownload
        isOpen={openDownloadReportModal}
        closeModal={() => {
          setOpenDownloadReportModal(false);
        }}
        submit={handleReportByFormat}
        reportType={ReportType.Review}
      />

      <ReportSendByMail
        isOpen={openSendByMailReportModal}
        closeModal={() => {
          setOpenSendByMailReportModal(false);
        }}
        reportTitle={fileTitle}
        reportId={reportId}
      />
    </div>
  ) : (
    <SectionPreloader />
  );
};

const showSorting = key => {
  return !(key === 'portfolio_summary' || key === 'projects_details' || key === 'current_revision_agreements');
};
const sortAlpInRevisions = (key: string, order = 'desc') => {
  return (a, b) => {
    if (!a.revision.hasOwnProperty(key) || !b.revision.hasOwnProperty(key)) {
      return 0;
    }

    const varA = typeof a.revision[key] === 'string' ? a.revision[key].toUpperCase() : a.revision[key];
    const varB = typeof b.revision[key] === 'string' ? b.revision[key].toUpperCase() : b.revision[key];

    return customComparsion(varA, varB, order);
  };
};

const sortSections = (key: string, order = 'desc') => {
  return (a, b) => {
    if (!a.parameters.hasOwnProperty(key) || !b.parameters.hasOwnProperty(key)) {
      return 0;
    }

    const varA = a.parameters[key];
    const varB = b.parameters[key];

    return customComparsion(varA, varB, order);
  };
};

const sortReviewReports = (projects, keyToSort, sortAsc, setProjectReportFiltered) => {
  switch (keyToSort) {
    case 'dateGenerated':
      projects.sort(sortDates(keyToSort, sortAsc ? 'asc' : 'desc'));
      break;
    case 'sections':
      setProjectReportFiltered(projects.sort(sortSections(keyToSort, sortAsc ? 'asc' : 'desc')));
      break;
    default:
      setProjectReportFiltered(projects.sort(sortAlpInRevisions(keyToSort, sortAsc ? 'asc' : 'desc')));
      break;
  }
};
