import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import Accordance from '../../../../models/Accordance';
import AccordanceDetail from '../../../Util/Modals/AccordanceDetail/AccordanceDetail';
import AccordanceReject from '../../../Util/Modals/AccordanceReject/AccordanceReject';
import AccordanceFulfilled from '../../../Util/Modals/AccordanceFulfilled/AccordanceFulfilled';
import AccordanceCancel from '../../../Util/Modals/AccordanceCancel/AccordanceCancel';
import User from '../../../../models/User';
import { NewAccordanceContext } from '../../../Util/Modals/NewAccordance/context/NewAccordanceContext';

type Props = { accordance: Accordance; stateProps: any };

const AccordanceDetailModals = ({ accordance, stateProps }: Props) => {
  const user = useSelector<any, User>(state => state.user);
  const { state, setState } = useContext(NewAccordanceContext);

  const {
    showDetail,
    showReject,
    showConfirm,
    showCancelModal,
    showFulfilledModal,
    showConfirmDeleteBundle,
    hideModal,
    showJustificationModal,
    refreshBoard,
    deleteAcc,
    changeStatus,
    setShowReject,
    setShowConfirm,
    setShowCancelModal,
    setShowFulfilledModal,
    setShowConfirmDeleteBundle,
    deleteBundle,
  } = stateProps;

  return showDetail || showReject || showConfirm || showCancelModal || showFulfilledModal ? (
    <>
      <AccordanceDetail
        isOpen={showDetail}
        closeModal={value => {
          hideModal(value);
          setState({ ...state, relatedAccordance: null });
        }}
        accordance={accordance}
        showJustificationModal={showJustificationModal}
        onApproveHandler={refreshBoard}
        showConfirmDelete={() => setShowConfirm(true)}
        showConfirmDeleteBundle={() => setShowConfirmDeleteBundle(true)}
        changeAccordanceStatus={value => {
          changeStatus(value);
        }}
        role={user.role.id}
        closeShowRelated={hideModal}
      />
      <AccordanceReject
        isOpen={showReject}
        closeModal={() => {
          setShowReject(false);
          setState({ ...state, relatedAccordance: null });
        }}
        accordanceId={accordance.id}
        onRejectHandler={refreshBoard}
      />
      <AccordanceFulfilled
        isOpen={showConfirm || showConfirmDeleteBundle}
        closeModal={() => {
          setShowConfirm(false);
          setShowConfirmDeleteBundle(false);
          setState({ ...state, relatedAccordance: null });
        }}
        accordanceId={accordance.id}
        customTitle={'delete_accordance'}
        customSubmit={() => {
          if (showConfirm) {
            deleteAcc(accordance.id);
          } else {
            deleteBundle(accordance.bundleId);
          }
        }}
      />
      <AccordanceCancel
        isOpen={showCancelModal}
        closeModal={() => {
          setShowCancelModal(false);
          setState({ ...state, relatedAccordance: null });
        }}
        accordanceId={accordance.id}
      />
      <AccordanceFulfilled
        isOpen={showFulfilledModal}
        closeModal={() => {
          setShowFulfilledModal(false);
          setState({ ...state, relatedAccordance: null });
        }}
        accordanceId={accordance.id}
      />
    </>
  ) : null;
};

export default AccordanceDetailModals;
