import IndicatorDetailFilter, { IndicatorDetailChartFilter } from '../../models/IndicatorDetailFilter';
import {
  RESET_INDICATOR_DETAIL_FILTER,
  RESET_INDICATOR_DETAIL_FILTER_CHART,
  SET_INDICATOR_DETAIL_FILTER,
  SET_INDICATOR_DETAIL_FILTER_CHART,
  SET_INDICATOR_DETAIL_FILTER_PROJECT_TYPE,
} from './types';

export function setIndicatorDetailFilter(payload: IndicatorDetailFilter) {
  return {
    type: SET_INDICATOR_DETAIL_FILTER,
    payload,
  };
}

export function setIndicatorDetailFilterChart(payload: IndicatorDetailChartFilter) {
  return {
    type: SET_INDICATOR_DETAIL_FILTER_CHART,
    payload,
  };
}

export function setIndicatorDetailFilterProjectType(payload: any) {
  return {
    type: SET_INDICATOR_DETAIL_FILTER_PROJECT_TYPE,
    payload,
  };
}

export function resetIndicatorDetailFilterChart() {
  return { type: RESET_INDICATOR_DETAIL_FILTER_CHART };
}

export function resetIndicatorDetailFilter() {
  return { type: RESET_INDICATOR_DETAIL_FILTER };
}
