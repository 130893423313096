import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Table.module.scss';
import { renderDateOrSlash } from '../../../../services/stringUtil';
import { Pagination } from '@material-ui/lab';
import '../../../Util/Pagination/Pagination.scss';
import usePaginationSort from '../../../../hooks/usePaginationSort';
import useTable from '../../../../hooks/useTable';
import { CriteriaType } from '../../../../models/PaginationIndicator';
import { basicFirstColumns, HeaderTableColumn } from '../../../../models/HeaderTableColumn';
import { handleValueOrSlash } from '../../PortfolioDetailTablesUtil';
import { ProjectsListSkeleton } from '../../../LoadersSkeletons/ProjectsListSkeleton/ProjectsListSkeleton';
import PaginationTable from '../../PaginationTable/PaginationTable';
import { Tooltip } from '@material-ui/core';
import { ExcelDownloadButton } from '../../../Util/ExcelDownloadButton/ExcelDownloadButton';
import useDataNormalize from '../../../../hooks/useDataNormalize';
import { withStyles } from '@material-ui/core/styles';
import { FilterTables } from '../../../Filter/FilterTables/FilterTables';

const BlackTooltip = withStyles({
  tooltip: {
    //   color: '$ci-color-greyscale-90',
    backgroundColor: '#101319',
    fontSize: '16px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    lineHeight: '20px',
    padding: '8px 12px',
    borderRadius: '8px',
  },
  arrow: {
    fontSize: 20,
    color: '#4A4A4A',
    '&::before': {
      backgroundColor: '#101319',
    },
  },
})(Tooltip);

const headers = [
  'id',
  'last_project',
  'last_operation',
  'name',
  'extended_months_column_label_plural',
  'no_of_extensions',
  'current_expiration_date',
  'pending_disbursement_per',
];
const selectedKeys = ['id', 'code', 'loanNumber', 'title', 'field1', 'field4', 'field2', 'field3'];

type Props = {
  loading?: boolean;
};
export const Table = (props: Props) => {
  const { loading } = props;
  const { t } = useTranslation();
  const { handleClick, projects } = useTable();
  const [filterdProjects, setFilteredProjects] = useState<any>(projects);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [dataFilter, setDataFilter] = useState<any>(projects);

  useEffect(() => {
    setDataFilter(projects);
  }, [projects]);

  const { handlePaginationChange, handleSort, currentList, pagesCount, currentPage } = usePaginationSort({
    projects: dataFilter,
    defaultSortCriteria: 'field2',
    pageCount: rowsPerPage,
  });

  const {
    container,
    tableHeaderExpired,
    headerTitleClickableWithMargin,
    tableRow,
    headerTitle,
    rowValue,
    rowList,
    rowValueName,
    fw,
    lastColumn,
    headerTitleClickable,
    rowValueCenterd,
    rowValueCenterdUppercase,
    borderTable,
  } = styles;

  useEffect(() => {
    const filterNewProjects = [...projects];
    setFilteredProjects(filterNewProjects);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects]);

  const projectExtendedHeaderTableSetup: HeaderTableColumn[] = [
    ...basicFirstColumns(headerTitleClickable),
    {
      className: headerTitleClickableWithMargin,
      criteria: 'field1',
      criteriaType: CriteriaType.number,
      label: 'months_extended_projects_with_extension',
    },
    {
      className: headerTitleClickableWithMargin,
      criteria: 'field4',
      criteriaType: CriteriaType.number,
      label: 'no_of_extensions',
    },
    {
      className: headerTitleClickableWithMargin,
      criteria: 'field2',
      criteriaType: CriteriaType.date,
      label: 'current_expiration_date',
    },
    {
      className: headerTitleClickableWithMargin,
      criteria: 'field3',
      criteriaType: CriteriaType.number,
      label: 'pending_disbursement_per',
    },
  ];
  const dataExcel = useDataNormalize(headers, selectedKeys, dataFilter);

  return (
    <div className={container}>
      {loading ? (
        <ProjectsListSkeleton />
      ) : (
        filterdProjects.length && (
          <>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <ExcelDownloadButton headers={headers} selectedKeys={selectedKeys} dataExcel={dataExcel} />
              <FilterTables
                projects={projects}
                keysFilter={selectedKeys}
                fnDataFilter={setDataFilter}
                disabledNumber={currentPage}
                indicatorKey={'PROJECTS_EXTENDED_NOT_FULLY_DISBURSED'}
              />
            </div>
            <div className={borderTable}>
              <div className={tableHeaderExpired}>
                {projectExtendedHeaderTableSetup.map(pe => {
                  const { className, criteria, criteriaType, label } = pe;
                  return (
                    <h4
                      className={className}
                      onClick={() => handleSort(criteria, criteriaType)}
                      key={`${className}-${criteria}`}
                    >
                      {t(label)}
                    </h4>
                  );
                })}
              </div>
              <ul className={rowList}>
                {dataFilter?.length === 0
                  ? dataFilter?.map(project => {
                      const {
                        loanNumber,
                        code,
                        frontendId,
                        title,
                        field1: monthsExtended,
                        field4: numberOfExtensions,
                        field2: currentExpirationDate,
                        field3: pendingDisbursementPercentage,
                      } = project;
                      return (
                        <li className={tableRow} key={`${loanNumber}${code}${frontendId}`}>
                          <BlackTooltip title={t('go_to_project_info')} arrow>
                            <p className={`${rowValue} link-project`} onClick={() => handleClick(project)}>
                              {code}
                            </p>
                          </BlackTooltip>
                          <p className={rowValue}>{loanNumber}</p>
                          <BlackTooltip title={title || ''} placement="bottom" enterDelay={500} leaveDelay={100} arrow>
                            <p className={rowValueName}>{title}</p>
                          </BlackTooltip>
                          <p className={rowValueCenterd}>{handleValueOrSlash(monthsExtended)}</p>
                          <p className={rowValueCenterd}>{handleValueOrSlash(numberOfExtensions)}</p>
                          <p className={rowValueCenterdUppercase}>{renderDateOrSlash(currentExpirationDate)}</p>
                          <p className={rowValueCenterd}>{handleValueOrSlash(pendingDisbursementPercentage, '%')}</p>
                        </li>
                      );
                    })
                  : currentList?.map(project => {
                      const {
                        loanNumber,
                        code,
                        frontendId,
                        title,
                        field1: monthsExtended,
                        field4: numberOfExtensions,
                        field2: currentExpirationDate,
                        field3: pendingDisbursementPercentage,
                      } = project;
                      return (
                        <li className={tableRow} key={`${loanNumber}${code}${frontendId}`}>
                          <BlackTooltip title={t('go_to_project_info')} arrow>
                            <p className={`${rowValue} link-project`} onClick={() => handleClick(project)}>
                              {code}
                            </p>
                          </BlackTooltip>
                          <p className={rowValue}>{loanNumber}</p>
                          <BlackTooltip title={title || ''} placement="bottom" enterDelay={500} leaveDelay={100} arrow>
                            <p className={rowValueName}>{title}</p>
                          </BlackTooltip>
                          <p className={rowValueCenterd}>{handleValueOrSlash(monthsExtended)}</p>
                          <p className={rowValueCenterd}>{handleValueOrSlash(numberOfExtensions)}</p>
                          <p className={rowValueCenterdUppercase}>{renderDateOrSlash(currentExpirationDate)}</p>
                          <p className={rowValueCenterd}>{handleValueOrSlash(pendingDisbursementPercentage, '%')}</p>
                        </li>
                      );
                    })}
              </ul>
              {/* <ProjectCounter
                arrayToCount={filterdProjects}
                countProjects={true}
                indicatorKey={IndicatorKeyEnum.PROJECTS_EXTENDED_NOT_FULLY_DISBURSED}
              /> */}
            </div>
            <div className="pagination-style">
              <PaginationTable fnSetRows={setRowsPerPage} rows={rowsPerPage} numProyects={dataFilter?.length} />
              {dataFilter?.length !== 0 ? (
                <Pagination count={pagesCount} page={currentPage} onChange={handlePaginationChange} />
              ) : null}
            </div>
          </>
        )
      )}
    </div>
  );
};
