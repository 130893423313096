import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import LabelData from '../../../../Util/LabelData/LabelData';
import { emptyValue } from '../constants';
import LinkText from '../../../../Util/Buttons/LinkText/LinkText';

type Props = {
  eventData: any;
  createAccordanceReminder: any;
  isCalendarIndicator?: boolean;
};

const ActivityDetail = (props: Props) => {
  const { t } = useTranslation();
  let { eventData, createAccordanceReminder, isCalendarIndicator } = props;
  if (!eventData) {
    eventData = {
      description: emptyValue,
      plannedDate: emptyValue,
      acquisitionName: emptyValue,
      projectTitle: emptyValue,
      projectCode: emptyValue,
    };
  }
  return (
    <>
      {isCalendarIndicator && (
        <LabelData label={t('code')} data={`${eventData.projectCode} ${eventData.projectTitle}`} reverse={true} />
      )}
      <LabelData label={t('description')} data={eventData.name} reverse={true} />
      <div style={{ display: 'flex' }}>
        <LabelData
          label={t('planned_date')}
          data={eventData.plannedDate ? moment(eventData.plannedDate).format('DD/MM/YYYY') : emptyValue}
          reverse={true}
        />
        <LinkText
          label={t('create_reminder')}
          onClick={createAccordanceReminder}
          customStyle={{ margin: '0px 0px 3px 10px' }}
        />
      </div>
      <LabelData label={t('acivity_part_of')} data={eventData.acquisitionName} reverse={true} />
    </>
  );
};
export default ActivityDetail;
