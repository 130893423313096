import React from 'react';
import './CustomizableReports.scss';
import { styles } from '../Styles';
import { cloneDeep } from 'lodash';
import { disableEscKey } from '../Util';
import { SelectReportType } from './Steps/SelectReportType';
import { SelectReportFilters } from './Steps/SelectReportFilters';
import { CustomizableReportType } from '../../../../models/CustomizableReportType';
import { ReactComponent as BackArrow } from '../../../../assets/back-arrow-gray.svg';
import { useTranslation } from 'react-i18next';
import { areFiltersEquals } from '../../../Portfolio/PortfolioUtil';
import { useDispatch, useSelector } from 'react-redux';
import { ReportSectionTabs } from '../../../../models/ReportEnums';
import { setProjectTabValue } from '../../../../redux/actions/projectTabValue';
import { createCustomReport, createPortfolioReport } from '../../../../api/reportApi';
import CloseCrossButton from '../../Buttons/CloseCrossButton/CloseCrossButton';
import FilterIndicators from '../../../../models/FilterIndicators';
import { useCustomizableReportsContext } from './CustomizableReportsContext';
import { SelectReportIndicators } from './Steps/SelectReportIndicators';
import { OrderIndicators } from './Steps/OrderIndicators';
import { TrackedActions } from '../../../../models/TrackedActionsEnum';
import { trackActionClicked } from '../../../../api/trackActionClickedApi';
import BaseModal from '../BaseModal';
import { processIndicators } from '../../../Portfolio/indicatorHelper';
import CardIndicator from '../../../Portfolio/CardIndicator/CardIndicator';
import { generateBlob } from '../../../../services/imageService';
import SpinningLoader from '../../../ProjectDetail/ProjectTabs/Loader/SpinningLoader';
import { getNewFilteredIndicators } from '../../../../api/indicatorApi';
import LargeImage from './LargeImagesComponents/LargeImage';
import { IndicatorKeyEnum } from '../../../../models/IndicatorKeyEnum';
import {
  generateImgsIfData,
  getAlreadyProcessedLargeImagesList,
  getKey,
  getKeyOrId,
  getLargeImagesList,
  hasReportWordKey,
  indicatorsToImage,
  resetImgIndicators,
  setReportImageDisplay,
} from './ImagesHelper';
import PcrSmallCards from './SmallCardsComponents/PcrSmallCards';

export enum ReportImageDisplay {
  Detail = 1,
  Miniature = 2,
}

const PCR_NEXT_PRESENTATION_UPCOMING = 'PCR_NEXT_PRESENTATION_UPCOMING';
const listWithPcrUpcoming = (filteredList: any[]) => {
  const upcomingPcr = filteredList.find(i => i.indicatorKey === IndicatorKeyEnum.PCR_NEXT_PRESENTATION);
  return upcomingPcr
    ? [...filteredList, { ...upcomingPcr, indicatorKey: PCR_NEXT_PRESENTATION_UPCOMING }]
    : [...filteredList];
};

const isPredefinedReport = (context: any, globalReportFilters: any) => {
  return (
    context.reportType === CustomizableReportType.Predefined &&
    context.currentStep === 0 &&
    areFiltersEquals(globalReportFilters.common)
  );
};

const isOpenAndHasIndicators = (isOpen: boolean, imgIndicators: any[]) => {
  return isOpen && imgIndicators.length;
};

export const excludedIndicators = {
  PROJECTS_SUMMARY_PORTFOLIO: true,
  PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS_BY_DIVISION: true,
  MONTHLY_DISBURSEMENT_PROJECTION_CURRENT_YEAR_INCLUDING_REGIONALS: true,
  PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS: true,
  CALENDAR_EVENTS: true,
  USER_PENDING_TASKS: true,
  PMR_PERFORMANCE_LAST_CLOSED_YEAR: false,
  //Excluded CTs indicators
  BID_CONSULTANCY: true,
  //Excluded VPS
  PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS_BY_COUNTRY: true,
};

const temporaryImgContainerStyles: any = {
  width: '100%',
  height: '100%',
  zIndex: -100,
  position: 'fixed',
  top: 0,
  left: 0,
  display: 'flex',
  flexWrap: 'wrap',
  overflow: 'auto',
  textAlign: 'left',
};

type Props = {
  closeModal: Function;
  isOpen: boolean;
  submit: Function;
};

const handleNext = async (context, globalReportFilters, generateReport, setContext) => {
  if (isPredefinedReport(context, globalReportFilters)) {
    await generateReport(globalReportFilters);
    trackActionClicked(TrackedActions.predefinedReportClicked);
  } else {
    setContext({
      currentStep: context.currentStep + 1,
    });
  }
};

const setContextIfOpen = (
  isOpen: boolean,
  context: any,
  globalReportFilters: FilterIndicators,
  setContext: Function,
) => {
  if (isOpen) {
    setContext({ ...context, reportFilters: JSON.parse(JSON.stringify(globalReportFilters)) });
  }
};

const fallbackDimensions = largeImageKey => (largeImageKey ? { width: 1200, height: 900 } : undefined);

const pushDataOnList = responses => {
  const detailData: any[] = [];
  for (const response of responses) {
    detailData.push(...response);
  }
  return detailData;
};

const hasImagestoAdd = (selectedIndicatorsToImage, alreadyProcessedLargeImagesList) => {
  return Object.keys(selectedIndicatorsToImage).length || alreadyProcessedLargeImagesList.length;
};

export const CustomizableReports = ({ closeModal, isOpen, submit }: Props) => {
  const { t } = useTranslation();
  const globalReportFilters = useSelector<any, FilterIndicators>(state => state.filterIndicators);
  const dispatch = useDispatch();
  const [imgIndicators, setImgIndicators] = React.useState<any[]>([]);
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const [context, setContext] = useCustomizableReportsContext();

  const generateImagesAndSubmit = () => {
    const defaultChartjsLoadDuration = 400;
    setTimeout(() => {
      const promiseList: any[] = [];
      for (const ind of imgIndicators) {
        promiseList.push(generateBlob(`${getKey(ind)}`, fallbackDimensions(ind.largeImageKey)));
      }
      Promise.all(promiseList).then(values => {
        submitData(values);
      });
    }, defaultChartjsLoadDuration);
  };

  React.useEffect(() => {
    setContextIfOpen(isOpen, context, globalReportFilters, setContext);
    resetImgIndicators(isOpen, setImgIndicators);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  React.useEffect(() => {
    generateImgsIfData(imgIndicators, generateImagesAndSubmit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgIndicators]);

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <SelectReportType next={() => handleNext(context, globalReportFilters, generateReport, setContext)} />;
      case 1:
        return (
          <SelectReportFilters
            generate={generateReport}
            next={() => handleNext(context, globalReportFilters, generateReport, setContext)}
          />
        );
      case 2:
        return (
          <SelectReportIndicators next={() => handleNext(context, globalReportFilters, generateReport, setContext)} />
        );
      case 3:
        return <OrderIndicators generate={generateCustomReport} submitting={submitting} />;
      default:
        return 'Unknown step';
    }
  };

  const generateReport = async (filters: FilterIndicators) => {
    dispatch(setProjectTabValue(ReportSectionTabs.PORTFOLIO));
    await createPortfolioReport(filters);
    submit();
    handleClose();
  };

  const generateCustomReport = async () => {
    setSubmitting(true);
    const inds = await getNewFilteredIndicators({ includeAll: true }, null, context.reportFilters.common);
    //Proceso indicadores
    const list = processIndicators(inds, {
      projectTypeFilter: context.reportFilters.common.projectTypeFilters,
    });

    //IMAGENES DE DETALLES
    //Obtengo data de largeImages (imagenes para los detalles de indicador)
    const promisesList = [
      getLargeImagesList(context, list, IndicatorKeyEnum.DISBURSEMENT_OVER_TIME),
      getLargeImagesList(context, list, IndicatorKeyEnum.DISBURSEMENT_PROJECTION_LAST_5_YEARS),
      getLargeImagesList(context, list, IndicatorKeyEnum.PORTFOLIO_OVERVIEW_LAST_5_YEARS),
      getLargeImagesList(context, list, IndicatorKeyEnum.PMR_EVOLUTION_LAST_5_YEARS),
    ];

    Promise.all(promisesList)
      .then(responses => {
        const detailData = pushDataOnList(responses);
        //Obtengo data de largeImages ya procesada
        const alreadyProcessedLargeImagesList = getAlreadyProcessedLargeImagesList(context, list);
        //Filtro indicadores que se deben generar imagenes
        const selectedIndicatorsToImage = indicatorsToImage(context);
        //Si hay alguno, los agrego a lista para renderizar componente oculto
        if (hasImagestoAdd(selectedIndicatorsToImage, alreadyProcessedLargeImagesList)) {
          let filteredList = list
            .filter(item => selectedIndicatorsToImage.hasOwnProperty(item.indicatorKey))
            .map(a => {
              a.largeImageKey = undefined;
              return a;
            });

          //Para PCR_NEXT_PRESENTATION hay que agregar una tarjeta con valores UPCOMING
          filteredList = listWithPcrUpcoming(filteredList);

          const reportWordList = cloneDeep(filteredList).filter(i => hasReportWordKey(context.indicators, i));
          const newList = [
            ...filteredList,
            ...reportWordList.map(a => {
              a.reportWordKey = hasReportWordKey(context.indicators, a);
              return a;
            }),
            ...alreadyProcessedLargeImagesList,
            ...detailData,
          ];
          setImgIndicators(newList);
        } else {
          submitData();
        }
      })
      .catch(err => {});
  };

  const submitData = async (images?: any[]) => {
    dispatch(setProjectTabValue(ReportSectionTabs.PORTFOLIO));
    try {
      await createCustomReport(context.reportFilters, context.indicators, images);
      trackActionClicked(TrackedActions.customReportClicked);
      submit();
      handleClose();
    } catch (err) {
      setSubmitting(false);
    }
  };

  const handleBack = () => {
    setContext({
      currentStep: context.currentStep - 1,
    });
  };

  const handleRequestClose = (e: React.KeyboardEvent) => {
    handleRequestCloseLocal(e, submitting, closeModal, resetState);
  };

  const handleClose = () => {
    closeModal();
    resetState();
  };

  const resetState = () => {
    setContext({
      currentStep: 0,
      reportType: CustomizableReportType.Predefined,
      reportFilters: JSON.parse(JSON.stringify(globalReportFilters)),
      indicators: [],
    });
    setImgIndicators([]);
    setSubmitting(false);
  };

  return (
    <>
      <BaseModal
        isOpen={isOpen}
        onRequestClose={handleRequestClose}
        style={styles}
        className={`modal-customizables-report-container ${modalClass(context)}`}
      >
        <div className="modal-customizables-report-content">
          {submitting ? (
            <SpinningLoader customText={'generating_report'} customStyle={{ marginBottom: 100 }} />
          ) : (
            <>
              <div className="modal-top-buttons">
                {context.currentStep > 0 ? (
                  <div className="back-arrow-container" onClick={handleBack}>
                    <BackArrow />
                    <span className="back-text">{t('back')}</span>
                  </div>
                ) : (
                  <span></span>
                )}
                <CloseCrossButton
                  handleClick={handleClose}
                  buttonClass="close-cross-button-medium"
                  spanClass="medium"
                />
              </div>
              {getStepContent(context.currentStep)}
            </>
          )}
        </div>
      </BaseModal>
      <HiddenImagesGenerator isOpen={isOpen} imgIndicators={imgIndicators} context={context} />
    </>
  );
};

const modalClass = (context: any) => (context.currentStep > 1 ? 'wider' : '');

const handleRequestCloseLocal = (
  e: React.KeyboardEvent,
  submitting: boolean,
  closeModal: Function,
  resetState: Function,
) => {
  if (submitting) {
    return;
  }
  disableEscKey(e, closeModal);
  resetState();
};

type HiddenImagesGeneratorProps = {
  isOpen: boolean;
  imgIndicators: any[];
  context: any;
};

const HiddenImagesGenerator = ({ isOpen, imgIndicators, context }: HiddenImagesGeneratorProps) => {
  return isOpenAndHasIndicators(isOpen, imgIndicators) ? (
    <>
      <div style={{ ...temporaryImgContainerStyles, backgroundColor: '#ffffff', zIndex: -99 }}></div>
      <div style={temporaryImgContainerStyles}>
        {imgIndicators.map(item => {
          if (item.largeImageKey) {
            return <LargeImage indicator={item} key={item.largeImageKey} />;
          } else {
            if (item.indicatorKey === PCR_NEXT_PRESENTATION_UPCOMING) {
              return <PcrSmallCards card={item} forReport={setReportImageDisplay(context.indicators, item)} />;
            }
            return (
              <CardIndicator
                card={item}
                key={getKeyOrId(item)}
                forReport={setReportImageDisplay(context.indicators, item)}
                reportWordKey={item.reportWordKey}
              />
            );
          }
        })}
      </div>
    </>
  ) : null;
};
