import React from 'react';
import { useTranslation } from 'react-i18next';
import { basicData } from '../BasicData';
import styles from './FiltersContainer.module.scss';
import { cloneDeep } from 'lodash';
import FilterSection from './FilterSection/FilterSection';

type Props = {
  projects: any[];
  projectTypeFilterState: number[];
};

const basicDataWithCount = (basicData, projects) => {
  Object.keys(basicData).forEach(section => {
    Object.keys(basicData[section]).forEach(subSection => {
      basicData[section][subSection].map(f => {
        f.count = projects.filter(f.condition).length;
        return f;
      });
    });
  });
  return basicData;
};

const FiltersContainer = ({ projects, projectTypeFilterState }: Props) => {
  const { t } = useTranslation();
  const [mostUsedFilters, setMostUsedFilters] = React.useState<boolean>(true);

  const dataWithCount = basicDataWithCount(cloneDeep(basicData), projects);

  return (
    <div className={mostUsedFilters ? styles.container : styles.containerExpanded}>
      <h3 className={styles.h3}>{t('most_used_criteria_selecting')}</h3>
      <div className={styles.columnsContainer}>
        {Object.keys(dataWithCount).map(section => {
          return section === 'social_env_issues' ? (
            <div key={section}>
              <FilterSection
                dataWithCount={dataWithCount}
                section={section}
                mostUsedFilters={mostUsedFilters}
                projectTypeFilterState={projectTypeFilterState}
              />
              <FilterSection
                dataWithCount={dataWithCount}
                section={'technical_cooperation'}
                mostUsedFilters={mostUsedFilters}
                projectTypeFilterState={projectTypeFilterState}
              />
            </div>
          ) : (
            section !== 'technical_cooperation' && (
              <div key={section}>
                <FilterSection
                  dataWithCount={dataWithCount}
                  section={section}
                  mostUsedFilters={mostUsedFilters}
                  projectTypeFilterState={projectTypeFilterState}
                />
              </div>
            )
          );
        })}
      </div>
      <div className={styles.seeMoreContainer}>
        <span
          className={mostUsedFilters ? styles.seeMore : styles.seeLess}
          onClick={() => setMostUsedFilters(!mostUsedFilters)}
        >
          {t(mostUsedFilters ? 'see_more_criteria' : 'see_less_criteria')}
        </span>
      </div>
    </div>
  );
};

export default FiltersContainer;
