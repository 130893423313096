import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextareaField } from '../MemoryHelpComponents/MemoryHelpsInputs';
import { useFormMemoryHelpContext } from '../../../context/MemoryHelpFormContext';

const CURRENT_STAGE = "developmentAndSustainability";

export const DevEffectiveness = () => {
  const { t } = useTranslation();
  const { onInputChange } = useFormMemoryHelpContext();

  return (
    <>
      <h5 className='title'>6. {t('memory_help.tabs.6')}</h5>
      <div className='bg-grey'>
        <p>{t('memory_help.container_tabs.6.description')}</p>
      </div>
      <TextareaField
        placeholder={t('memory_help.container_tabs.6.placeholder')}
        name='aditional_info_dev_and_sustainability'
        onChange={(e) => onInputChange(e, CURRENT_STAGE)}
        currentTabId={CURRENT_STAGE}
        addClass='input-height'
      />
    </>
  );
};

export default DevEffectiveness;
