import React from 'react';
import { useTranslation } from 'react-i18next';
import Accordance from '../../../models/Accordance';
import { AccordanceState } from '../../../models/AccordanceState';
import { capitalizeFirstLetter } from '../../../services/stringUtil';
import styles from './HeaderAccordancesSummary.module.scss';

type Props = {
  data: Accordance[];
};

const handleHundredPercentQuantity = (data: Accordance[]): number => {
  const hundredPercentQuantity = data.filter(
    f =>
      f.accordanceState === AccordanceState.VALID ||
      f.accordanceState === AccordanceState.FULLFILLED ||
      f.accordanceState === AccordanceState.CANCELLED,
  );

  return hundredPercentQuantity.length;
};

const getValuesByAccordanceState = (data: Accordance[], accordanceState: AccordanceState) => {
  const quantity = data.filter(f => f.accordanceState === accordanceState).length;
  const hundredPercentQuantity = handleHundredPercentQuantity(data);
  const percentage = hundredPercentQuantity !== 0 ? Math.round((quantity * 100) / hundredPercentQuantity) : 0;
  return { percentage, quantity };
};
export const HeaderAccordancesSummary = ({ data }: Props) => {
  const { headerValues } = styles;
  const { percentage: activeAccordancesPercentage, quantity: activeAccordancesQuantity } = getValuesByAccordanceState(
    data,
    AccordanceState.VALID,
  );
  const { percentage: accomplishAccordancesPercentage, quantity: accomplishAccordancesQuantity } =
    getValuesByAccordanceState(data, AccordanceState.FULLFILLED);
  const { percentage: cancelledAccordancesPercentage, quantity: cancelledAccordancesQuantity } =
    getValuesByAccordanceState(data, AccordanceState.CANCELLED);

  const totalAccordancesQuantity = handleHundredPercentQuantity(data);

  return (
    <div className={headerValues}>
      <HeaderValue
        bigValue={activeAccordancesPercentage}
        littleNumber={activeAccordancesQuantity}
        label={'active_accordance'}
        postSign=" %"
      />
      <HeaderValue
        bigValue={accomplishAccordancesPercentage}
        separator
        littleNumber={accomplishAccordancesQuantity}
        label={'accomplished_accordance'}
        postSign=" %"
      />
      <HeaderValue
        bigValue={cancelledAccordancesPercentage}
        separator
        littleNumber={cancelledAccordancesQuantity}
        label={'cancelled_accordance'}
        postSign=" %"
      />
      <HeaderValue
        bigValue={totalAccordancesQuantity}
        bigValueCustomStyle
        separator
        label={'total_approved_agreements'}
      />
    </div>
  );
};

type HeaderValueProps = {
  label: string;
  postSign?: string;
  littleNumber?: number;
  separator?: boolean;
  bigValue: number;
  bigValueCustomStyle?: boolean;
};
const HeaderValue = ({
  label,
  postSign = '',
  littleNumber,
  separator,
  bigValue,
  bigValueCustomStyle,
}: HeaderValueProps) => {
  const { t } = useTranslation();
  const { container, value, subtitle, littleNumberStyle, valueCustom, containerWithSeparator } = styles;
  return (
    <div className={separator ? containerWithSeparator : container}>
      <div className={bigValueCustomStyle ? valueCustom : value}>
        {bigValue}
        <span>{postSign}</span>
      </div>
      <div className={subtitle}>
        <span className={littleNumberStyle}>{littleNumber}</span>
        {capitalizeFirstLetter(t(label))}
      </div>
    </div>
  );
};
