import { AccordanceType } from './../models/AccordanceType';
import { useTranslation } from 'react-i18next';
import SelectOption from '../models/SelectOption';
import { ProjectExecutionTypes } from '../models/ProjectExecutionTypes';
import { AccordanceState } from '../models/AccordanceState';
import { capitalizeFirstLetter } from '../services/stringUtil';

export const useFrontEndBasedSelectedsOptions = () => {
  const { t } = useTranslation();

  const executionOptions: SelectOption[] = [
    { id: ProjectExecutionTypes.BID, label: t('bid'), value: 'bid' },
    { id: ProjectExecutionTypes.EXECUTING_AGENCY, label: t('executing_agency'), value: 'executing_agency' },
  ];

  const taxonomyOptions: SelectOption[] = [
    { id: 4, label: t('taxonomy_CLS'), value: 'customer_support' },
    { id: 3, label: t('taxonomy_OPS'), value: 'operational_support' },
    { id: 2, label: t('taxonomy_RED'), value: 'research' },
  ];

  const stageOptions = t => {
    return [
      { value: '0', id: 0, label: t('after_eligibility') },
      { value: '1', id: 1, label: t('after_the operation_reaches_95%_of_disbursements') },
      { value: '2', id: 2, label: t('all_fem') },
    ];
  };
  const classificationOptions = t => {
    return [
      { value: '0', id: 0, label: t('classification_original') },
      { value: '1', id: 1, label: t('reclassification') },
    ];
  };

  const acordanceTypeOptions: SelectOption[] = [
    { value: '1', id: AccordanceType.Revision, label: t('revision_accordances') },
    {
      value: '2',
      id: AccordanceType.Monitoring,
      label: t('monitor_accordances'),
    },
    {
      value: '3',
      id: AccordanceType.InterGroup,
      label: t('inter_group_accordances'),
    },
    {
      value: '4',
      id: AccordanceType.PreparationForStart,
      label: t('new_accordances_the_preparation_for_start'),
    },
    {
      value: '5',
      id: AccordanceType.StartPreparation,
      label: t('new_accordances_the_start_preparation'),
    },
    {
      value: '6',
      id: AccordanceType.ThermalMediumMonitoring,
      label: t('new_accordances_the_thermal_medium_monitoring'),
    },
    {
      value: '7',
      id: AccordanceType.PreparationForClosing,
      label: t('new_accordances_the_preparation_for_closing'),
    },
  ];

  const acordancStateOptions: SelectOption[] = [
    { value: '0', id: AccordanceState.DRAFT, label: capitalizeFirstLetter(t('draft_accordance')) },
    {
      value: '1',
      id: AccordanceState.PENDING,
      label: t('pending_accordance'),
    },
    {
      value: '2',
      id: AccordanceState.VALID,
      label: t('active_accordance'),
    },
    {
      value: '3',
      id: AccordanceState.FULLFILLED,
      label: t('accomplished_accordance'),
    },
    {
      value: '4',
      id: AccordanceState.CANCELLED,
      label: t('cancelled_accordance'),
    },
  ];

  return {
    executionOptions,
    taxonomyOptions,
    stageOptions,
    classificationOptions,
    acordanceTypeOptions,
    acordancStateOptions,
  };
};
