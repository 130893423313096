import React from 'react';
import { styles } from '../Styles';
import './ConfirmDelete.scss';
import SaveButton from '../../Buttons/SaveButton/SaveButton';
import UndoButton from '../../Buttons/UndoButton/UndoButton';
import { useTranslation } from 'react-i18next';
import BaseModal from '../BaseModal';

type Props = {
  isOpen: boolean;
  closeModal: any;
  submit: any;
  customTitle: string;
  customMessage: string;
  submitButtonText?: string;
  cancelButtonText?: string;
};

const ConfirmDelete = (props: Props) => {
  const { isOpen, closeModal, submit, customTitle, customMessage, submitButtonText, cancelButtonText } = props;
  const { t } = useTranslation();

  return (
    <BaseModal isOpen={isOpen} onRequestClose={closeModal} style={styles} className="modal-confirm-delete-content">
      <div className="modal-confirm-delete-container">
        <h3>{t(customTitle)}</h3>
        <p>{t(customMessage)}</p>
        <div className="horizontal">
          <SaveButton
            handleClick={submit}
            customStyle={{ padding: '0px 44px' }}
            customText={t(submitButtonText || 'remove')}
          />
          <UndoButton handleClick={closeModal} customText={t(cancelButtonText || 'cancel')} />
        </div>
      </div>
    </BaseModal>
  );
};

export default ConfirmDelete;
