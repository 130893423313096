import React from 'react';
import CardsProjectList from '../CardsProjectList/CardsProjectList';
import ProjectsList from '../ProjectsList/ProjectsList';
import { useStyles } from './ProjectsFollowing.Styles';
import './ProjectsFollowing.scss';
import { BidTooltip } from '../../Util/Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as ListView } from '../../../assets/icons/u_list-ui-alt.svg';
import { ReactComponent as CardView } from '../../../assets/icons/u_apps.svg';
import SearchInput from '../../Util/CustomInput/SearchInput/SearchInput';
import { EmptySearchResult } from '../../Util/EmptySearchResult/EmptySearchResult';
import { EmptyCardsProject } from '../EmptyCardsProject/EmptyCardsProject';
import { tooltipTitle } from '../../../services/stringUtil';
import { isMobile } from '../../../services/booleanUtil';
import useFilters from '../../../hooks/useFilters';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';

const BlackTooltip = withStyles({
  tooltip: {
    //   color: '$ci-color-greyscale-90',
    backgroundColor: '#101319',
    fontSize: '16px',
    fontFamily: 'Rubik',
    lineHeight: '20px',
    padding: '8px 12px',
    borderRadius: '8px',
  },
  arrow: {
    fontSize: 20,
    color: '#4A4A4A',
    '&::before': {
      backgroundColor: '#101319',
    },
  },
})(Tooltip);

const ProjectsFollowing = () => {
  const { filter, dispatch, setFilter } = useFilters();
  const { t } = useTranslation();
  const { cardsListButton } = useStyles();
  const [cardView, setCardView] = React.useState(filter.followedProjectsFilter.cardView);
  const haveProjectsFollowed = filter.followedProjectsPagination.rowCount === 0 ? false : true;
  const itsSearch = filter.common.itSearch;

  const switchView = () => {
    dispatch(
      setFilter({
        ...filter,
        followedProjectsFilter: {
          ...filter.followedProjectsFilter,
          cardView: !filter.followedProjectsFilter.cardView,
        },
      }),
    );
    setCardView(!filter.followedProjectsFilter.cardView);
  };

  const renderOptions = () => {
    if (!haveProjectsFollowed && !itsSearch) {
      return (
        <div className="following-container-options">
          <EmptyCardsProject />
        </div>
      );
    }

    return (
      <div className="following-container-options">
        {/* <div className="title-projects">{t('following_projects_title')}</div> */}
        {!isMobile() && (
          <div className="title-options-projects-following">
            <IconButton className={cardsListButton} onClick={switchView}>
              {cardView ? (
                <BlackTooltip
                  title={tooltipTitle(t, 'view_list')}
                  placement="bottom"
                  enterDelay={500}
                  leaveDelay={200}
                  arrow
                >
                  <button className='btn-sm btn-primary-text'>
                  <ListView id="listView"  /> {t('view_list')}
                  </button>
                </BlackTooltip>
              ) : (
                <BlackTooltip
                  title={tooltipTitle(t, 'view_card')}
                  placement="bottom"
                  enterDelay={500}
                  leaveDelay={200}
                  arrow
                >
                  <button className='btn-sm btn-primary-text'>
                  <CardView id="switchView" /> {t('view_card')}
                  </button>
                </BlackTooltip>
              )}
            </IconButton>
            <SearchInput itsFollowed={true} />
          </div>
        )}
        {!haveProjectsFollowed ? <EmptySearchResult /> : null}
      </div>
    );
  };

  return (
    <div style={isMobile() ? { padding: '15px 0px 15px 0px' } : {}}>
      {renderOptions()}
      {cardView ? (
        <CardsProjectList itsSearch={itsSearch} />
      ) : (
        <div className="following-container-list">
          <ProjectsList itsSearch={itsSearch} />
        </div>
      )}
    </div>
  );
};

export default ProjectsFollowing;
