import React, { useState } from 'react';
import styles from './TransactionalResourcesFilterChart.module.scss';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { basicBorder, colourStyles } from '../../ProjectDetail/ProjectTabs/Supervision/StateSelect/StateSelect';
import { FundingSourceEnum } from '../../../models/FundingSourceEnum';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentIndicator } from '../../../redux/actions/currentIndicator';

export const TransactionalResourcesFilterChart = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentIndicator = useSelector<any, any>(state => state.currentIndicator);
  const color = 'rgba(102, 102, 102, 1)';
  const stateOptionsTransactional = [
    { value: 0, label: t('all'), color: 'rgba(102, 102, 102, 1)' },
    { value: 1, label: t('funding_source_0'), color },
    { value: 2, label: t('funding_source_1'), color },
    { value: 3, label: t('funding_source_2'), color },
    { value: 4, label: t('funding_source_3'), color },
    { value: 5, label: t('funding_source_4'), color },
  ];
  const haveLocalStorgeValue = localStorage.getItem('transactionalSupervisionResourcesFilter');
  const [optionValueTransactional, setOptionValueTransactional] = useState(
    !haveLocalStorgeValue || haveLocalStorgeValue === 'all'
      ? stateOptionsTransactional[0]
      : stateOptionsTransactional[FundingSourceEnum[haveLocalStorgeValue]],
  );

  const getFilteredValue = (metrics, metricName, source) => {
    if (!source || source === 'all') {
      source = 'NONE';
    }

    const foundedValue = metrics.find(i => i.groupingValue === source && i.metricName === metricName);
    return foundedValue ? foundedValue.metricValue : 0;
  };
  const setFilteredData = filterValue => {
    const { value, label } = filterValue;
    const filterApplied = value === 0 ? 'all' : label;
    const metricNameSupervisionPlan = getMetricName(filterApplied);
    const newDataIndicator = { ...currentIndicator };
    const newBartchartData = [
      getFilteredValue(currentIndicator.data.rawMetrics, metricNameSupervisionPlan, filterApplied),
      getFilteredValue(currentIndicator.data.rawMetrics, 'EXPENDITURES_A', filterApplied),
      getFilteredValue(currentIndicator.data.rawMetrics, 'COMMITMENT_B', filterApplied),
      getFilteredValue(currentIndicator.data.rawMetrics, metricNameSupervisionPlan, filterApplied) - (getFilteredValue(currentIndicator.data.rawMetrics, 'EXPENDITURES_A', filterApplied) + getFilteredValue(currentIndicator.data.rawMetrics, 'COMMITMENT_B', filterApplied)),
    ];
    const barchartDataArray = {
      ...newDataIndicator.data.toBarchart.data.datasets[0],
      data: newBartchartData,
    };

    newDataIndicator.data.toBarchart.data.datasets[0] = barchartDataArray;
    dispatch(setCurrentIndicator(newDataIndicator));
  };
  const handleOptionChange = value => {
    localStorage.setItem(
      'transactionalSupervisionResourcesFilter',
      value.value === 0 ? 'all' : FundingSourceEnum[value.value],
    );
    setOptionValueTransactional(value);
    setFilteredData(value);
  };

  return (
    <div className={styles.transactionalFilter}>
      <h5 className={styles.label}>{t('source')}</h5>
      <div className={styles.selectSide}>
        <Select className={styles.selectIdb}
          value={optionValueTransactional}
          label="Single select"
          options={stateOptionsTransactional}
          // styles={{
          //   ...colourStyles,
          //   control: (styles, { isFocused }) => ({
          //     ...styles,
          //     borderRadius: 20,
          //     outline: 'none',
          //     border: basicBorder,
          //     boxShadow: isFocused ? 0 : basicBorder,
          //     '&:hover': {
          //       border: basicBorder,
          //     },
          //     height: '40px',
          //     minHeight: '40px',
          //     maxWidth: '125px',
          //     cursor: 'pointer',
          //     backgroundColor: 'white',
          //   }),
          // }}
          components={{
            IndicatorSeparator: () => null,
          }}
          onChange={value => handleOptionChange(value)}
        />
      </div>
    </div>
  );
};

const getMetricName = (filter: any) => {
  switch (filter) {
    case 'VPC':
      return 'VPC_TRNS_AMNT';
    case 'VPS':
      return 'VPS_TRNS_AMNT';
    case 'ESG':
      return 'ESG_TRNS_AMNT';
    case 'FMP':
      return 'FID_AMNT';
    case 'CT':
      return 'TC_TRNS_AMNT';
    default:
      return 'TOTAL_BUDGET';
  };
}