import { isMobile } from '../../services/booleanUtil';

const sidetabsSelectedSelector = '&$selected';

export const baseStyle: any = {
  container: {
    position: 'relative',
    width: '100%',
  },
  sidebar: {
    width: '100px',
    position: 'absolute',
    height: '110%',
  },
  bodySection: {},
  rootb: {},
  tabs: {},
  root: {
    opacity: '1',
    color: '#ffffff',
    float: 'left',
    overflow: 'unset',
    position: 'relative',
    width: '94px',
    minWidth: 'auto',
    fontWeight: 600,
    fontSize: '12px',
    fontFamily: 'Source Sans Pro',
    '&$selected': {
      color: '#416EBC',
      float: 'left',
      overflow: 'unset',
      position: 'relative',
      margin: '20px 0 20px 8px',
      width: '94px',
      backgroundColor: '#ffffff',
      minWidth: 'auto',
    },
  },
  selected: {},
  indicator: {
    opacity: '0',
  },
  hiddenTab: {
    height: '94px',
    width: '94px',
    minWidth: '94px',
    display: 'none',
  },
};

export const mobileStyles: any = {
  ...baseStyle,
  container: {
    ...baseStyle.container,
    position: 'absolute',
    height: 'calc(100% - 45px)',
  },
  sidebar: {
    ...baseStyle.sidebar,
    height: '100%',
    width: '100%',
  },
  bodySelection: {
    ...baseStyle.bodySelection,
    height: '100%',
    overflowY: 'auto',
    overflowX: isMobile() ? 'hidden' : 'auto',
  },
  rootb: {
    ...baseStyle.rootb,
    position: 'fixed',
    width: '100%',
    height: '70px',
    bottom: '0px',
    zIndex: 10,
  },
  tabs: {
    ...baseStyle.tabs,
    height: '70px',
    backgroundColor: '#fff',
    borderTop: '1px solid #D7DBE4',
  },
  root: {
    ...baseStyle.root,
    color: '#D7DBE4',
    margin: 0,
    width: '25%',
    transform: isMobile() ? 'scale(0.8)' : 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    height: '70px',
    padding: 20,
    '&$selected': {
      ...baseStyle.root[sidetabsSelectedSelector],
      margin: '0',
      width: '25%',
      transform: isMobile() ? 'scale(0.8)' : 'none',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      height: '70px',
      padding: 20,
    },
  },
  hiddenTab: {
    ...baseStyle.hiddenTab,
    height: '40px',
    width: '25%',
    minWidth: '10%',
    display: 'none',
  },
};

export const standardStyles: any = {
  ...baseStyle,
  container: {
    ...baseStyle.container,
    minHeight: '1300px',
  },
  sidebar: {
    ...baseStyle.sidebar,
    height: '110%',
    float: 'left',
    margin: '0',
    padding: '0',
    width: '100px',
    background: 'linear-gradient(180deg, #417FFE 0%, #416DBC 100%)',
    position: 'absolute',
    top: '-100px',
  },
  bodySection: {
    ...baseStyle.bodySection,
    marginLeft: '125px',
    height: '100%',
  },
  rootb: {
    ...baseStyle.rootb,
    marginTop: '180px',
    display: 'flex',
  },
  root: {
    ...baseStyle.root,
    borderRadius: '35px 0 0 35px',
    height: '160px',
    width: '94px',
    margin: '20px 0 20px 8px',
    '&$selected': {
      ...baseStyle.root[sidetabsSelectedSelector],
      height: '160px',
      borderRadius: '35px 0 0 35px',
      '&:before': {
        content: "''",
        position: 'absolute',
        top: '-30px',
        height: '30px',
        width: '90px',
        right: '0',
        borderBottomRightRadius: '100px',
        boxShadow: '35px 0 0 0 #fff',
      },
      sidebar: { position: 'absolute', height: '100%', width: '100%' },
      bodySection: {
        height: '100%',
        overflowY: 'auto',
        overflowX: isMobile() ? 'hidden' : 'auto',
      },
      '&:after': {
        content: "''",
        position: 'absolute',
        bottom: '-30px',
        height: '30px',
        width: '70px',
        borderTopRightRadius: '100px',
        boxShadow: '25px 0 0 0 #fff',
        right: '0px',
      },
    },
  },
};

export const contentManagerStyles = {
  ...standardStyles,
  root: {
    ...standardStyles.root,
    height: '110px',
    '&$selected': {
      ...standardStyles.root[sidetabsSelectedSelector],
      height: '110px',
      color: '#00BAC7',
    },
  },
  sidebar: {
    ...standardStyles.sidebar,
    background: '#00BAC7',
  },
};
