import { ProjectDetailsTab } from './ProjectDetailsTabEnum';

/**
 * IGcmAlert
 *
 * grouping prop:
 * es un string 'financial' or 'baseInformation' utilizado para agrupar
 * las alertas GCM en base a las tabs utilizadas en el detalle de proyecto.
 */
export interface IGcmAlert {
  code: string;
  topicName: string;
  type: number;
  descriptionName: string;
  grouping: string;
  highlighted: null | string;
}

export enum GcmHighlighted {
  BIDTeam = 'BIDTeam', //TODO *
  eligibility = 'eligibility',
  lastDisbursement = 'lastDisbursement',
  signature = 'signature', //TODO este no iria segun Gonzalo confirmo con Natalia, pero de momento dejo la referencia porque BE informa que lo manda.
}

// * este punto se consulto a Gonzalo y aguardamos por respuesta.
// Referente algun tipo de alerta cerca de la seccion Equipo BID - tambien lo dejo por que es lo ultimo que informo Enzo que envia el BE - para tenerlo presente.

export enum GcmGrouping {
  'financial' = ProjectDetailsTab.FINANCIAL,
  'baseInformation' = ProjectDetailsTab.BASE_INFORMATION,
}

export enum GcmStatus {
  InCancellation,
  Warning,
  Issue,
}

export enum GcmBackgroundColor {
  '#E8BCBC',
  '#FAF097',
  '#D0DEF8',
}

export const EMPTY_GCM_ALERT = {
  code: '',
  descriptionName: '',
  grouping: '',
  highlighted: '',
  topicName: '',
  type: -1,
};
