import React, { useEffect, useState } from 'react';
import { ReactComponent as IconError } from '../../../assets/icons/u_multiply-circle.svg';
import { ReactComponent as SearchDropDown } from '../../../assets/icons/u_search.svg';
import styles from './FilterTables.module.scss';
import { useTranslation } from 'react-i18next';
import InfoTooltip from '../../PortfolioDetail/InfoTooltip/InfoTooltip';

interface Props {
  projects;
  keysFilter;
  fnDataFilter;
  disabledNumber;
  indicatorKey: any;
  dataFilterBtn?: [];
  filterBtn?: boolean[] | boolean;
}

export const FilterTables = ({
  projects,
  keysFilter,
  fnDataFilter,
  disabledNumber,
  indicatorKey,
  dataFilterBtn = [],
  filterBtn,
}: Props) => {
  const [search, setSearch] = useState('');

  let searchFilter;

  const { t } = useTranslation();

  const searchAndFilterData = e => {
    setSearch(e.target.value);
  };

  const cleanInput = () => {
    setSearch('');
  };

  const filterArrayObjects = (arr, keys, filterValue) => {
    if (!filterValue) {
      return arr;
    } else if (filterValue?.length >= 3) {
      return arr.filter(item =>
        keys.some(key => item[key] && item[key].toString().toLowerCase().includes(filterValue.toLowerCase())),
      );
    }
  };

  if (dataFilterBtn?.length > 0) {
    searchFilter = filterArrayObjects(dataFilterBtn, keysFilter, search);
  } else {
    searchFilter = filterArrayObjects(projects, keysFilter, search);
  }

  useEffect(() => {
    if (search.length >= 3) {
      fnDataFilter(searchFilter);
    } else if (dataFilterBtn?.length > 0) {
      fnDataFilter(dataFilterBtn);
    } else {
      fnDataFilter(projects);
    }
  }, [search]);

  useEffect(() => {
    cleanInput();
  }, [filterBtn]);

  return (
    <div
      className={`${styles['container-filter']} ${
        disabledNumber > 1 && search.length === 0 && styles['hidden-container-filter']
      }`}
    >
      <span className={`${styles['icon-glass']}`}>
        <SearchDropDown />
      </span>
      <input
        id="searchDocs"
        className={`${styles['input-filter']}`}
        value={search}
        type="text"
        placeholder={`${t('new_search_by_word')}`}
        onChange={searchAndFilterData}
        disabled={disabledNumber > 1}
      />
      {search.length > 0 ? (
        <button className={`${styles['button-clean-filter']}`} onClick={cleanInput} disabled={disabledNumber > 1}>
          <IconError />
        </button>
      ) : null}
      <div></div>
    </div>
  );
};
