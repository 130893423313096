import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './DoubleNumber.module.scss';

type Props = {
  card: any;
};

export const DoubleNumber = ({
  card: { title, firstText, firstMetricValue, secondText, secondMetricValue },
}: Props) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className={styles.title}>{t(title)}</div>
      <div className={styles.firstMetricContainer}>
        <div className={styles.number}>{firstMetricValue}</div>
        <div className={styles.text}>{t(firstText)}</div>
      </div>
      <div className={styles.secondMetricContainer}>
        <div className={styles.number}>{secondMetricValue}</div>
        <div className={styles.text}>{t(secondText)}</div>
      </div>
    </div>
  );
};
