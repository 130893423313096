import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './ConsultanciesFinancedAndExecutedByTheBankFilter.module.scss';
import { IndicatorKeyEnum } from '../../../../models/IndicatorKeyEnum';
import {
  getProjectsSimpleTableIndicatorForBidConsultancyIndicator,
  resetIndicatorProjects,
} from '../../../../redux/actions/indicatorProjects';
import { TabFilter, TabsFilter } from './TabsFilter/TabsFilter';
import Filter from '../../../../models/Filter';
import {
  addSessionFilter,
  checkIfSessionFilterExist,
  getFilterSessionValue,
} from '../../../../services/sessionFiltersIndicatorDetailHelper';
import { ProjectProductTypeFilters } from '../../../../models/ProjectProductTypeFilters';
import { SelectsFilter } from './SelectsFilter/SelectsFilter';
import Indicator from '../../../../models/Indicator';
import { ProjectProductTypes } from '../../../../models/ProjectProductTypes';
import { IFiltersBidConsultanciesDetailParams } from '../../../../api/indicatorApi';
import { setFilterIndicators } from '../../../../redux/actions/filterIndicators';
import { SearchRender } from '../../../Util/CustomInput/SearchRender/SearchRender';
import { IDetailFilters } from '../../../../models/FilterIndicators';
import { parseFilterToApiCall } from '../../../../services/util';

const determineDefaultTab = (projectTypeFilters: number[]): TabFilter => {
  if (
    projectTypeFilters.includes(ProjectProductTypeFilters.CT) ||
    projectTypeFilters.includes(ProjectProductTypeFilters.IG_lower_3)
  ) {
    return TabFilter.TECHNICAL_COOPERATIONS;
  }
  return TabFilter.SUPERVISION_PLANS;
};

const ConsultanciesFinancedAndExecutedByTheBankFilter = () => {
  const dispatch = useDispatch();
  const { id, indicatorKey } = useSelector<any, Indicator>(state => state.currentIndicator);
  const { projectTypeFilters } = useSelector<any, Filter>(state => state.filterIndicators.common);

  const { filterIndicators } = useSelector<any, any>(state => state);

  const { detailFilters } = useSelector<any, any>(state => state.filterIndicators);
  const { searchTerm } = detailFilters as IDetailFilters;

  const [tabFilterBy, setTabFilterBy] = useState<TabFilter>(
    checkIfSessionFilterExist('consultanciesTabs')
      ? getFilterSessionValue('consultanciesTabs')
      : determineDefaultTab(projectTypeFilters),
  );

  const { filterContainer, selectsContainer, searchContainer } = styles;

  useEffect(() => {
    dispatch(resetIndicatorProjects());
    const filterObject = { projectProductType: 3, amount: 3000000 };
    const projectProductTypes: ProjectProductTypes[] = [];
    const projectProductAmounts: Object[] = [];
    const { projectProductTypes: projectProduct } = parseFilterToApiCall(projectTypeFilters);

    if (tabFilterBy === TabFilter.TECHNICAL_COOPERATIONS) {
      projectProduct.includes(ProjectProductTypes.CT) && projectProductTypes.push(ProjectProductTypes.CT);
      projectProduct.includes(ProjectProductTypes.IG) &&
        projectTypeFilters.includes(ProjectProductTypeFilters.IG_lower_3) &&
        projectProductTypes.push(ProjectProductTypes.IG);
      projectProduct.includes(ProjectProductTypes.IG) &&
        projectTypeFilters.includes(ProjectProductTypeFilters.IG_lower_3) &&
        projectProductAmounts.push({ filterType: 0, ...filterObject });
    } else {
      projectProduct.includes(ProjectProductTypes.Loan) && projectProductTypes.push(ProjectProductTypes.Loan);
      projectProduct.includes(ProjectProductTypes.IG) &&
        projectTypeFilters.includes(ProjectProductTypeFilters.IG_greater_3) &&
        projectProductTypes.push(ProjectProductTypes.IG);
      projectProduct.includes(ProjectProductTypes.IG) &&
        projectTypeFilters.includes(ProjectProductTypeFilters.IG_greater_3) &&
        projectProductAmounts.push({ filterType: 1, ...filterObject });
    }
    getFilteredSimpleIndicators(dispatch, id, { projectProductTypes, projectProductAmounts });
    addSessionFilter({ consultanciesTabs: tabFilterBy });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabFilterBy]);

  const handleFilterApplied = value => {
    setTabFilterBy(value);
  };

  const handleSearchTerm = e => {
    const searchTerm = e.target.value.toLowerCase();
    dispatch(
      setFilterIndicators({
        ...filterIndicators,
        detailFilters: {
          ...filterIndicators.detailFilters,
          searchTerm,
        },
      }),
    );
  };

  return indicatorKey === IndicatorKeyEnum.BID_CONSULTANCY ? (
    <div className={filterContainer}>
      <div className={selectsContainer}>
        <SelectsFilter tabFilter={tabFilterBy} />
      </div>
      <TabsFilter filterBy={tabFilterBy} handleFilterApplied={handleFilterApplied} />
      <div className={searchContainer}>
        <SearchRender
          placeholder="look_for_a_project"
          onChangeHandler={e => handleSearchTerm(e)}
          valueSearched={searchTerm}
        />
      </div>
    </div>
  ) : null;
};

export default ConsultanciesFinancedAndExecutedByTheBankFilter;

const getFilteredSimpleIndicators = (d, currentIndicatorId, options: IFiltersBidConsultanciesDetailParams) => {
  return d(getProjectsSimpleTableIndicatorForBidConsultancyIndicator(currentIndicatorId, options));
};
