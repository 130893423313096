import React from 'react';
import './Detail.scss';
import { styles } from '../../Util/Modals/Styles';
import { useTranslation } from 'react-i18next';
import SaveButton from '../../Util/Buttons/SaveButton/SaveButton';
import CloseCrossButton from '../../Util/Buttons/CloseCrossButton/CloseCrossButton';
import {
  StyledTableHeader,
  StyledTableRowHeader,
  StyledTableCellHeader,
  StyledTableCell,
} from '../PorfolioTableShared.Styles';
import { Table, TableBody, TableContainer, TableRow } from '@material-ui/core';
import moment from 'moment';
import BaseModal from '../../Util/Modals/BaseModal';

type Props = {
  isOpen: boolean;
  closeModal: any;
  project: any;
  type: any;
  status: any;
};

enum TypeEnum {
  activity,
  criticalProduct,
}

enum StatusEnum {
  completed,
  pending,
}

const rowStyle = (width: string, lastItem: boolean) => {
  return lastItem ? { width, borderBottom: 'none' } : { width };
};

const hasItems = (project: any) => project.itemsList && project.itemsList.length > 0;

const getRightHeaderTitle = (type: number, status: number, t: Function) => {
  if (type === TypeEnum.activity) {
    if (status === StatusEnum.completed) {
      return t('completed_activities');
    } else {
      return t('pending_activities');
    }
  } else {
    if (status === StatusEnum.completed) {
      return t('completed_critical_products');
    } else {
      return t('pending_critical_products');
    }
  }
};

export const Detail = (props: Props) => {
  const { isOpen, closeModal, project, type, status } = props;
  const { t } = useTranslation();

  return (
    <BaseModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={{ overlay: { ...styles.overlay, ...{ overflowY: 'auto' } } }}
      className="average-lag-detail-modal-content"
    >
      <div className="average-lag-detail-modal-container">
        <div className="close-cross-container">
          <CloseCrossButton handleClick={closeModal} />
        </div>
        <div className="horizontal separated">
          <h3 className="title">{project.title}</h3>
          <h3 className="title">{project.code}</h3>
        </div>
        {hasItems(project) ? (
          <h4 style={{ marginTop: 20 }}>* {t('supervision_empty_state_title_simplified_2')}</h4>
        ) : null}
        {hasItems(project) ? (
          <TableContainer style={{ marginTop: 25, overflowY: 'auto', maxHeight: '35vh' }}>
            <Table stickyHeader className="custom-table" style={{ tableLayout: 'auto' }} size="small">
              <StyledTableHeader>
                <StyledTableRowHeader>
                  <StyledTableCellHeader style={{ width: '75%' }} align="left">
                    {getRightHeaderTitle(type, status, t)}
                  </StyledTableCellHeader>
                  {status === StatusEnum.pending && (
                    <StyledTableCellHeader style={{ width: '25%' }} align="center">
                      {t('plan_date')}
                    </StyledTableCellHeader>
                  )}
                </StyledTableRowHeader>
              </StyledTableHeader>
              <TableBody>
                {project.itemsList &&
                  project.itemsList.map((item, index) => (
                    <TableRow key={item.id} style={index % 2 ? { background: '#fafafa' } : { background: 'white' }}>
                      <StyledTableCell style={rowStyle('75%', index === project.itemsList.length - 1)} align="left">
                        {item.description}
                      </StyledTableCell>
                      {status === StatusEnum.pending && (
                        <StyledTableCell style={rowStyle('25%', index === project.itemsList.length - 1)} align="center">
                          {item.plannedDate ? moment(item.plannedDate).format('DD/MM/YY') : '-'}
                        </StyledTableCell>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <h4 className="empty-message">{t('supervision_empty_state_title_simplified_2')}</h4>
        )}
        {hasItems(project) ? (
          <div className="footer">{`${t('total')}: ${project && project.itemsList.length} ${type === TypeEnum.activity ? t('activities') : t('critical_products')
            }`}</div>
        ) : null}
        <div className="horizontal">
          <SaveButton handleClick={closeModal} customText={t('close')} />
        </div>
      </div>
    </BaseModal>
  );
};
