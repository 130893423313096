import { makeStyles, Tab, Tabs } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { tabStyles } from '../../../Projects/ProjectsAll/ProjectsAll.Style';

export enum TabsFiltersEnum {
  SIGNATURE_PENDING,
  PENDING_RATIFICATION,
}

type Props = {
  filterBy: TabsFiltersEnum;
  handleFilterApplied: Function;
};
export const TabsFilter = ({ filterBy, handleFilterApplied }: Props) => {
  const { t } = useTranslation();
  const classes = makeStyles({
    ...tabStyles,
    root: {
      ...tabStyles.root,
      minWidth: 'max-content',
      '&$selected': { ...tabStyles.root['&$selected'], minWidth: 'max-content' },
    },
  })();
  const tabClasses = { root: classes.root, selected: classes.selected };
  const handleSetMenuValue = (tabValue: any) => {
    handleFilterApplied(tabValue);
  };
  return (
    <div>
      <Tabs value={filterBy} classes={{ indicator: classes.indicatorColor }} variant="standard">
        <Tab
          key={TabsFiltersEnum.SIGNATURE_PENDING}
          value={TabsFiltersEnum.SIGNATURE_PENDING}
          classes={tabClasses}
          label={t('signature_pending')}
          onClick={() => handleSetMenuValue(TabsFiltersEnum.SIGNATURE_PENDING)}
        />
        <Tab
          key={TabsFiltersEnum.PENDING_RATIFICATION}
          value={TabsFiltersEnum.PENDING_RATIFICATION}
          classes={tabClasses}
          label={t('pending_ratification')}
          onClick={() => handleSetMenuValue(TabsFiltersEnum.PENDING_RATIFICATION)}
        />
      </Tabs>
    </div>
  );
};
