import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { useTranslation } from 'react-i18next';

type HeaderTableProps = {
    products: string;
    projects: string;
    operations: string;
    approvedAmount: string;
    disburments: string;
    ageAverage: string;
    actions: string;
}

export default function HeaderTable(headerData: HeaderTableProps) {
    const { products, projects, operations, approvedAmount, disburments, ageAverage, actions } = headerData;
    const { t } = useTranslation();
    return (
        <TableHead>
            <TableRow>
                <TableCell className='product-cell'>{t(products)}</TableCell>
                <TableCell className='project-cell'>{t(projects)}</TableCell>
                <TableCell className='op-cell'>{t(operations)}</TableCell>
                <TableCell className='amount-cell amount'>{t(approvedAmount)}</TableCell>
                <TableCell className='deb-cell disbursement'>{t(disburments)}</TableCell>
                <TableCell className='ant-cell'>{t(ageAverage)}</TableCell>
                <TableCell className='det-cell'>{t(actions)}</TableCell>
            </TableRow>
        </TableHead>
    )
}