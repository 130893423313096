import React from 'react';
import { useTranslation } from 'react-i18next';
import FilterIndicators from '../../../../../models/FilterIndicators';
import { PmrClassification } from '../../../../../models/PmrClassification';
import { CustomCheckbox } from '../../../CustomCheckbox/CustomCheckbox';

type Props = {
  reportFilters: FilterIndicators;
  reportFiltersChanged: Function;
};
export const CutomizablePmrFilter = ({ reportFilters, reportFiltersChanged }: Props) => {
  const { t } = useTranslation();
  const [localFilter, setLocalFilter] = React.useState(reportFilters);

  const checkboxChange = (checked: boolean, classification: PmrClassification) => {
    const newFilter = {
      ...localFilter,
      common: {
        ...localFilter.common,
        pmrId: localFilter.common.pmrId.map(pmr => {
          if (pmr.PmrClassification !== classification) {
            return pmr;
          }
          pmr.selected = checked;
          return pmr;
        }),
      },
    };
    setLocalFilter(newFilter);
    reportFiltersChanged({ pmrId: newFilter.common.pmrId });
  };

  return (
    <>
      <CustomCheckbox
        isChecked={localFilter.common.pmrId[PmrClassification.Green].selected}
        handleOnClick={(isChecked: boolean) => checkboxChange(isChecked, PmrClassification.Green)}
        labelText={t('satisfactory')}
      />
      <CustomCheckbox
        isChecked={localFilter.common.pmrId[PmrClassification.Yellow].selected}
        handleOnClick={(isChecked: boolean) => checkboxChange(isChecked, PmrClassification.Yellow)}
        labelText={t('alert')}
      />
      <CustomCheckbox
        isChecked={localFilter.common.pmrId[PmrClassification.Red].selected}
        handleOnClick={(isChecked: boolean) => checkboxChange(isChecked, PmrClassification.Red)}
        labelText={t('problem')}
      />
      <CustomCheckbox
        isChecked={localFilter.common.pmrId[PmrClassification.Na].selected}
        handleOnClick={(isChecked: boolean) => checkboxChange(isChecked, PmrClassification.Na)}
        labelText={t('not_assigned')}
      />
    </>
  );
};
