import React from 'react';
import styles from './SupervisionSelect.module.scss';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { supervisionSelect } from '../../../../Filter/FilterComponent.Styles';

type Props = {
  options: any;
  selectedOption: any;
  setselectedOption: any;
};

const SupervisionSelect = ({ options, selectedOption, setselectedOption }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.selectContainer}>
      <div className={styles.selectTitle}>{t('supervision_header_title')}</div>
      <Select
        value={selectedOption}
        options={options}
        onChange={value => setselectedOption(value)}
        styles={supervisionSelect}
        isSearchable={false}
        placeholder={t('select')}
        noOptionsMessage={() => t('no_results')}
      />
    </div>
  );
};

export default SupervisionSelect;
