import moment from 'moment';
import { SortParameterProject } from '../models/SortParameterProjectEnum';
const BID_SAFE_DATE = '1959-01-01';
enum sortDefaultValues {
  alphabetically,
  dates,
  decimal,
  number,
}
const makeComparison = (varA, varB) => {
  let comparison = 0;
  if (varA > varB) {
    comparison = 1;
  } else if (varA < varB) {
    comparison = -1;
  }
  return comparison;
};

const sortDefaultValue = (defaultOperation: sortDefaultValues, value: any) => {
  switch (defaultOperation) {
    case sortDefaultValues.alphabetically:
      return value === null ? '' : value;
    case sortDefaultValues.dates:
      return value.isValid() ? value : new Date(BID_SAFE_DATE);
    case sortDefaultValues.decimal:
      return isNaN(value) ? Number.NEGATIVE_INFINITY : value;
    case sortDefaultValues.number:
      return isNaN(value) ? Number.MIN_SAFE_INTEGER : value;
    default:
      return null;
  }
};
const sortAlphabetically = (key: string, order = 'asc') => {
  return (a, b) => {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }
    const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];
    const comparison = makeComparison(
      sortDefaultValue(sortDefaultValues.alphabetically, varA),
      sortDefaultValue(sortDefaultValues.alphabetically, varB),
    );
    return order === 'desc' ? comparison * -1 : comparison;
  };
};

const sortNumbers = (key: string, order = 'asc') => {
  return (a, b) => {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }
    const varA = parseInt(a[key]);
    const varB = parseInt(b[key]);
    const comparison = makeComparison(
      sortDefaultValue(sortDefaultValues.number, varA),
      sortDefaultValue(sortDefaultValues.number, varB),
    );
    return order === 'desc' ? comparison * -1 : comparison;
  };
};

const sortDecimalNumbers = (key: string, order = 'asc') => {
  return (a, b) => {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }
    const varA = parseFloat(a[key]);
    const varB = parseFloat(b[key]);
    const comparison = makeComparison(
      sortDefaultValue(sortDefaultValues.decimal, varA),
      sortDefaultValue(sortDefaultValues.decimal, varB),
    );
    return order === 'desc' ? comparison * -1 : comparison;
  };
};

const sortDates = (key: string, order = 'asc') => {
  return (a, b) => {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }
    const varA = moment(a[key]);
    const varB = moment(b[key]);
    const comparison = makeComparison(
      sortDefaultValue(sortDefaultValues.dates, varA),
      sortDefaultValue(sortDefaultValues.dates, varB),
    );
    return order === 'desc' ? comparison * -1 : comparison;
  };
};

const sortNested = (key: string, order = 'asc', propertyKey: string) => {
  return (a, b) => {
    if (
      !propertyKey ||
      !a[propertyKey] ||
      !b[propertyKey] ||
      !a[propertyKey].hasOwnProperty(key) ||
      !b[propertyKey].hasOwnProperty(key)
    ) {
      return 0;
    }
    const varA = a[propertyKey][key];
    const varB = b[propertyKey][key];
    const comparison = makeComparison(varA, varB);
    return order === 'desc' ? comparison * -1 : comparison;
  };
};

const sortEncoded = (key: string) => {
  enum ParseEncoded {
    'es' = 1,
    'en',
    'pt',
    'fr',
  }
  return (a, b) => {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }
    const varA = parseInt(ParseEncoded[a[key]]);
    const varB = parseInt(ParseEncoded[b[key]]);
    return makeComparison(varA, varB);
  };
};

const sortTablesByType = (data, sortAsc, keyToSort, fieldType) => {
  switch (fieldType) {
    case 'string':
      return data.sort(sortAlphabetically(keyToSort, sortAsc ? 'asc' : 'desc'));
    case 'numeric':
    case 'money':
    case 'percentage':
    case 'no_round_two_decimal':
      return data.sort(sortNumbers(keyToSort, sortAsc ? 'asc' : 'desc'));
    case 'date':
      return data.sort(sortDates(keyToSort, sortAsc ? 'asc' : 'desc'));

    default:
      return data.sort(sortAlphabetically(keyToSort, sortAsc ? 'asc' : 'desc'));
  }
};

const sortProjectsTable = (list, concept, sortAsc) => {
  const order = sortAsc ? 'asc' : 'desc';
  switch (concept) {
    case SortParameterProject.Code:
      list.sort(sortAlphabetically('code', order));
      return list;
    case SortParameterProject.Name:
      list.sort(sortAlphabetically('name', order));
      return list;
    case SortParameterProject.Division:
      list.sort(sortNested('code', order, 'division'));
      return list;
    case SortParameterProject.PercentTemporaryAdvanceMade:
      list.sort(sortNested('temporaryAdvanceMade', order, 'temporaryAdvance'));
      return list;
    case SortParameterProject.LastDisbursementInDays:
      list.sort(sortNested('lastDisbursementInDays', order, 'financialInformation'));
      return list;
    case SortParameterProject.PercentRealizedDisbursement:
      list.sort(sortNested('percentRealizedDisbursement', order, 'financialInformation'));
      return list;
    case SortParameterProject.PendingDisbursementAmount:
      list.sort(sortNested('pendingDisbursementAmount', order, 'financialInformation'));
      return list;
    case SortParameterProject.PmrValue:
      list.sort(sortNumbers('pmrValue', order));
      return list;
    default:
      return list;
  }
};

const secondCriteriaComparsion = (varA, varB) => {
  let comparsion = 0;
  if (varA < varB) {
    comparsion = -1;
  } else if (varA > varB) {
    comparsion = 1;
  } else {
    comparsion = 0;
  }
  return comparsion;
};
const secondCriteriaComparsionDecimalNumbers = (a, b, key) => {
  const varA = parseFloat(a[key]);
  const varB = parseFloat(b[key]);
  return secondCriteriaComparsion(
    sortDefaultValue(sortDefaultValues.decimal, varA),
    sortDefaultValue(sortDefaultValues.decimal, varB),
  );
};
const secondCriteriaComparsionDates = (a, b, key) => {
  const varA = moment(a[key]);
  const varB = moment(b[key]);
  return secondCriteriaComparsion(
    sortDefaultValue(sortDefaultValues.dates, varB),
    sortDefaultValue(sortDefaultValues.dates, varA),
  );
};

const secondCriteriaComparsionAlphabetically = (a, b, key) => {
  const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
  const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];
  return secondCriteriaComparsion(
    sortDefaultValue(sortDefaultValues.alphabetically, varA),
    sortDefaultValue(sortDefaultValues.alphabetically, varB),
  );
};
const varTypeFn = {
  sortablePendingSignature: secondCriteriaComparsionDates,
  sortableElegibleUndisbursement: secondCriteriaComparsionDates,
  sortablePmrColumn: secondCriteriaComparsionAlphabetically,
  sortableSafeguardPerformance: secondCriteriaComparsionAlphabetically,
  sortablePartialCancellations: secondCriteriaComparsionDecimalNumbers,
  sortableDisbursementExpired: secondCriteriaComparsionDates,
};

const sortByTwoCriteriasOnEarlyWarnings = (key: string, order = 'asc', propertyKey: string) => {
  return (a, b) => {
    let comparsion;
    if (a[key] < b[key]) {
      comparsion = -1;
    } else if (a[key] > b[key]) {
      comparsion = 1;
    } else {
      comparsion = varTypeFn[key](a, b, propertyKey);
    }
    return order === 'desc' ? comparsion * -1 : comparsion;
  };
};

const customComparsion = (varA, varB, order) => {
  const comparison = makeComparison(varA, varB);
  return order === 'desc' ? comparison * -1 : comparison;
};

export {
  sortTablesByType,
  sortNested,
  sortProjectsTable,
  sortAlphabetically,
  sortNumbers,
  sortEncoded,
  sortDates,
  sortDecimalNumbers,
  sortByTwoCriteriasOnEarlyWarnings,
  customComparsion,
};
