import React from 'react';
import './NotificationsModal.scss';
import { styles } from './NotificationsModal.Styles';
import CloseCrossButton from '../../Util/Buttons/CloseCrossButton/CloseCrossButton';
import { useTranslation } from 'react-i18next';
import NotificationsCard from '../NotificationsCard/NotificationsCard';
import InfiniteScroll from 'react-infinite-scroller';
import Notifications from '../../../models/Notifications';
import { useDispatch, useSelector } from 'react-redux';
import { getMoreNotifications } from '../../../redux/actions/notifications';
import { markReaded } from '../../../api/notificationApi';
import { NotificationsTypesEnum } from '../../../models/NotificationsTypesEnum';
import BaseModal from '../../Util/Modals/BaseModal';

interface IProps {
  isOpen: boolean;
  closeModal: any;
}
type Props = IProps;
export const NotificationModal = ({ isOpen, closeModal }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [pageNumber, handlePageNumber] = React.useState(1);
  const notificationsItems = useSelector<any, Notifications[]>(state => state.notifications);
  const [initialLoad, setInitialLoad] = React.useState(true);
  const totalPages = useSelector<any, any>(state => state.notificationsPages.totalPages);
  const [hasMore, setHasMore] = React.useState(true);

  const handleCloseModal = () => {
    markReaded();
    closeModal(false);
  };

  const loadMoreAlerts = () => {
    if (hasMore) {
      dispatch(getMoreNotifications(pageNumber));
    }
  };

  React.useEffect(() => {
    setInitialLoad(false);
  }, []);

  React.useEffect(() => {
    const diference = totalPages - pageNumber;
    if (diference > 0) {
      handlePageNumber(pageNumber + 1);
      setHasMore(true);
    } else {
      setHasMore(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationsItems]);

  return (
    <BaseModal isOpen={isOpen} style={styles} onRequestClose={closeModal} className="modal-alert-content">
      <div className="modal-alert-container">
        <div className="horizontal-right">
          <CloseCrossButton handleClick={handleCloseModal} />
        </div>
        <div className="horizontal">
          <h3>{t('notifications')}</h3>
        </div>
        <div className="horizontal-column">
          <InfiniteScroll
            className="infinite-width"
            pageStart={pageNumber}
            loadMore={loadMoreAlerts}
            hasMore={hasMore}
            useWindow={false}
            initialLoad={initialLoad}
          >
            {notificationsItems
              .filter(
                o =>
                  o.project !== null ||
                  o.type === NotificationsTypesEnum.ContentManagerNotification ||
                  o.type === NotificationsTypesEnum.EarlyWarningAutoGeneratedReport,
              )
              .map(item => (
                <NotificationsCard
                  key={item.id}
                  alertType={item.type}
                  creationDate={item.creationDate}
                  readed={item.readed}
                  projectCode={item.project ? item.project.code : null}
                  projectName={item.project ? item.project.name : null}
                  notificationType={item.type}
                  projectId={item.project ? item.project.id : null}
                  accordanceId={item.associatedEntityId}
                  dueDays={item.dueDays}
                  aditionalInformation={item.additionalInformation}
                />
              ))}
          </InfiniteScroll>
        </div>
      </div>
    </BaseModal>
  );
};

export default NotificationModal;
