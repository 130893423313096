import { IEarlyWarningsProjects, LoadingState } from '../../models/EarlyWarningsSection';
import {
  EarlyWarningsProjectsActionTypes,
  RESET_EARLY_WARNINGS_PROJECTS,
  SET_EARLY_WARNINGS_PROJECTS,
  UPDATE_LOADING_STATE,
} from '../actions/types';

const earlyWarningsProjects: IEarlyWarningsProjects = {
  projects: [],
  loadingState: LoadingState.firstIn,
};

const earlyWarningsProjectsReducer = (
  state = earlyWarningsProjects,
  action: EarlyWarningsProjectsActionTypes,
): IEarlyWarningsProjects => {
  switch (action.type) {
    case SET_EARLY_WARNINGS_PROJECTS:
      return action.payload;
    case RESET_EARLY_WARNINGS_PROJECTS:
      return earlyWarningsProjects;
    case UPDATE_LOADING_STATE:
      return { ...state, loadingState: action.payload };

    default:
      return state;
  }
};

export default earlyWarningsProjectsReducer;
