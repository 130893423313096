import { SET_ALL_PROJECTS } from './types';
import Project from '../../models/Project';
import { addRemoveProjectMyPortfolio } from '../../api/projectApi';

export function setAllProjects(data: Project[]) {
  return {
    type: SET_ALL_PROJECTS,
    payload: data,
  };
}

export function setInPortfolio(projectId: number) {
  return async (dispatch: any, getState: any) => {
    await addRemoveProjectMyPortfolio(projectId);
    const inMyPortfolio = getState().menuTabs.projectSelectedTab.assignedToMe;
    const list = inMyPortfolio
      ? getState().allProjects.filter(item => item.id !== projectId)
      : getState().allProjects.map(item => {
          if (item.id === projectId) {
            item.inPortfolio = !item.inPortfolio;
          }
          return item;
        });
    dispatch(setAllProjects(list));
  };
}
