import { createReducer } from '@reduxjs/toolkit';
import { setPayload } from '../actions/indicatorPayload';

const initialState: any = {
    payload: null
}

const indicatorPayloadReducer = createReducer(initialState, builder => {
    builder
        .addCase(setPayload, (state, action) => {
            state.indicatorPayload = action.payload;
        })
})

export default indicatorPayloadReducer;