import { SET_CURRENT_PROJECT, UPDATE_CURRENT_PROJECT, RESET_CURRENT_PROJECT } from './types';
import Project from '../../models/Project';
import { getProjectById, setFollowUnfollowProject, getProjectFinancial, getAdquisitionsData } from '../../api/projectApi';
import { getAccordances } from '../../api/accordanceApi';
import { syncCalendarOutlook } from '../../api/calendarApi';
import { isMobile, isIE11 } from '../../services/booleanUtil';
import { sortDates } from '../../services/sortsUtil';
import { filterAccordanceList } from '../../components/ProjectDetail/ProjectTabs/Accordances/AccordanceTrello/util';
import {
  laneStyle,
  activeLaneStyle,
  laneStyleIE11,
  activeLaneStyleIE11,
  horizontalLane,
  hidden,
  boardStyle,
  boardStyleCollapsed,
  hiddenDisplayNone,
  activeLaneStyleWide,
} from '../../components/ProjectDetail/ProjectTabs/Accordances/AccordanceTrello/Styles';
import { showPermissionsModal } from './permissionsModal';
import { resolveProjectReportSections } from './projectReportSections';
import { getProductForProject } from '../../api/productApi';
import { getEvolutionById } from '../../api/evolutionApi';
import Accordance from '../../models/Accordance';

export function setCurrentProject(data: Project) {
  return {
    type: SET_CURRENT_PROJECT,
    payload: data,
  };
}

export function resetCurrentProject() {
  return {
    type: RESET_CURRENT_PROJECT,
  };
}

export function updateCurrentProject(data: Project) {
  return {
    type: UPDATE_CURRENT_PROJECT,
    payload: data,
  };
}

export function getProject(id: number) {
  return async (dispatch: any, getState: any) => {
    try {
      const response = await getProjectById(id);
      const evolution = await getEvolutionById(id);

      const updatedProject = {
        ...response,
        disbursementsProjectionsEvolution: evolution.disbursementsProjectionsEvolution,
        pmrEvolution: evolution.pmrEvolution,
      };
      dispatch(updateCurrentProject(updatedProject));

    } catch (error) { }
  };
}

export function getAdquisitionData(id: number) {
  return async (dispatch: any, getState: any) => {
    try {
      const response = await getProjectById(id);
      const adquisition = await getAdquisitionsData(id);

      const updatedProject = {
        ...response,
        adquisition: adquisition
      };
      dispatch(updateCurrentProject(updatedProject));
    } catch (error) { }
  };
}

export function setFollowUnfollow(id: number) {
  return async (dispatch: any, getState: any) => {
    try {
      await setFollowUnfollowProject(id);
      const currentProject = { ...getState().currentProject };
      dispatch(updateCurrentProject({ ...currentProject, ...{ inFollow: !currentProject.inFollow } }));
    } catch (error) { }
  };
}

export const setLaneStyle = (item: any, ie11: boolean) => {
  switch (item.id) {
    case '2':
    case '3':
      return ie11 ? activeLaneStyleIE11 : activeLaneStyle;
    case '4':
      return isMobile() ? laneStyle : { ...horizontalLane };
    default:
      return ie11 ? laneStyleIE11 : laneStyle;
  }
};

const groupByBundleId = (list: Accordance[]) => {
  const grouped = {};
  const result: any[] = [];
  for (const i of list) {
    const id = i.bundleId || i.id;
    if (grouped[id]) {
      grouped[id] = [...grouped[id], i];
    } else {
      grouped[id] = [i];
    }
  }
  for (const a of Object.keys(grouped)) {
    result.push({
      categoryName: grouped[a][0].criticalIssue ? grouped[a][0].criticalIssue.categoryName : '-',
      criticalIssueName: grouped[a][0].criticalIssue ? grouped[a][0].criticalIssue.name : '-',
      criticalIssueDescription: grouped[a][0].criticalIssueDescription || '-',
      accordances: grouped[a],
      accordanceAgreementsType: grouped[a][0].accordanceAgreementsType?.name || '-',
      linkedProductsAccordance: grouped[a][0].linkedProductsAccordance?.map(data => data.nameProduct).sort(),
      description: grouped[a][0].description,
      personInCharge: grouped[a][0].personInCharge?.name || '-',
      expirationDate: grouped[a][0].expirationDate || '-',
      accordanceState: grouped[a][0].accordanceState || '-',
      id: a,
    });
  }

  return result.sort((a, b) => {
    if (a.criticalIssueDescription.toLowerCase() > b.criticalIssueDescription.toLowerCase()) {
      return 1;
    } else if (a.criticalIssueDescription.toLowerCase() < b.criticalIssueDescription.toLowerCase()) {
      return -1;
    } else {
      return 0;
    }
  });

};

export function getProjectAccordances(id: number, year?: string, accordanceType?: string) {
  return async (dispatch: any, getState: any) => {
    try {
      const response = await getAccordances(id, year, accordanceType);
      const grouped = groupByBundleId(response);
      const sortedResponse = [...response].sort(sortDates('dateCreated', 'desc'));
      const currentProject = { ...getState().currentProject };
      const newLanes = currentProject.accordancesData.lanes.map(item => {
        const cards = filterAccordanceList(sortedResponse, item.id, !isMobile() && item.id === '2');
        return {
          key: item.key,
          id: item.id,
          title: item.title,
          cards,
          style: setLaneStyle(item, isIE11()),
          droppable: item.id !== '1' && item.id !== '0',
        };
      });

      dispatch(
        updateCurrentProject({
          ...currentProject,
          boardStyle,
          ...{ accordancesData: { lanes: newLanes } },
          accordancesGrouped: grouped,
        }),
      );
    } catch (error) { }
  };
}

export function getProjectFinancialInfo(id: number, fromReport = false) {
  return async (dispatch: any, getState: any) => {
    try {
      const project = await getProjectById(id);
      const response = await getProjectFinancial(id);

      const updatedProject = {
        ...project,
        ...{ financialInformation: { ...response } }
      }
      dispatch(updateCurrentProject(updatedProject));

      if (fromReport) {
        dispatch(resolveProjectReportSections('financial'));
      }
    } catch (error) {
      if (fromReport) {
        dispatch(resolveProjectReportSections('financial'));
      }
    }
  };
}

export function getProjectProductInfo(id: number) {
  return async (dispatch: any, getState: any) => {
    try {
      const response = await getProductForProject(id);
      const currentProject = { ...getState().currentProject };
      dispatch(
        updateCurrentProject({
          ...currentProject,
          ...{ productInformation: { ...response } },
        }),
      );
    } catch (error) { }
  };
}

const displayPermissionsModal = (error: any, dispatch: any) => {
  if (error.response && error.response.status === 403) {
    localStorage.setItem('ad_scope', 'Calendars.ReadWrite');
    dispatch(showPermissionsModal(true));
  }
};

export function syncProjectCalendarOutlook(data: any) {
  return async (dispatch: any, getState: any) => {
    const currentProject = getState().currentProject;
    try {
      await syncCalendarOutlook(currentProject.id, data);
      dispatch(
        updateCurrentProject({ ...currentProject, ...{ calendarSynchronized: !currentProject.calendarSynchronized } }),
      );
    } catch (error) {
      displayPermissionsModal(error, dispatch);
    }
  };
}

const laneIsShown = (lane: any) => {
  return lane.style.opacity === undefined && (!lane.style.display || lane.style.display !== 'none');
};

const showDraft = (
  laneStyle: any,
  pendingShown: boolean,
  accomplishedShow: boolean,
  hidden: any,
  activeLaneStyle: any,
  activeBigger: boolean,
  activeLaneStyleWide: any,
  cancelledLane: any,

) => {
  return {
    0: laneStyle,
    1: pendingShown ? laneStyle : hidden,
    2: pendingShown && accomplishedShow ? activeLaneStyle : activeBigger ? activeLaneStyleWide : activeLaneStyle,
    3: accomplishedShow ? laneStyle : hidden,
    4: cancelledLane.style,
  };
};

const hideDraft = (
  hidden: any,
  pendingShown: boolean,
  accomplishedShow: boolean,
  laneStyle: any,
  activeBigger: boolean,
  activeLaneStyleWide: any,
  activeLaneStyle: any,
  cancelledLane: any,
) => {
  return {
    0: hidden,
    1: pendingShown ? laneStyle : hidden,
    2: pendingShown && accomplishedShow ? (activeBigger ? activeLaneStyleWide : activeLaneStyle) : activeLaneStyleWide,
    3: accomplishedShow ? laneStyle : hidden,
    4: cancelledLane.style,
  };
};

const showPending = (
  draftShown: boolean,
  accomplishedShow: boolean,
  laneStyle: any,
  hidden: any,
  activeLaneStyle: any,
  activeBigger: boolean,
  activeLaneStyleWide: any,
  cancelledLane: any,
) => {
  return {
    0: draftShown ? laneStyle : hidden,
    1: laneStyle,
    2: draftShown && accomplishedShow ? activeLaneStyle : activeBigger ? activeLaneStyleWide : activeLaneStyle,
    3: accomplishedShow ? laneStyle : hidden,
    4: cancelledLane.style,
  };
};

const hidePending = (
  draftShown: boolean,
  accomplishedShow: boolean,
  laneStyle: any,
  hidden: any,
  activeBigger: boolean,
  activeLaneStyleWide: any,
  activeLaneStyle: any,
  cancelledLane: any,
) => {
  return {
    0: draftShown ? laneStyle : hidden,
    1: hidden,
    2: draftShown && accomplishedShow ? (activeBigger ? activeLaneStyleWide : activeLaneStyle) : activeLaneStyleWide,
    3: accomplishedShow ? laneStyle : hidden,
    4: cancelledLane.style,
  };
};

const showAccompli = (
  draftShown: boolean,
  pendingShown: boolean,
  laneStyle: any,
  hidden: any,
  activeLaneStyle: any,
  activeBigger: boolean,
  activeLaneStyleWide: any,
  cancelledLane: any,
) => {
  return {
    0: draftShown ? laneStyle : hidden,
    1: pendingShown ? laneStyle : hidden,
    2: draftShown && pendingShown ? activeLaneStyle : activeBigger ? activeLaneStyleWide : activeLaneStyle,
    3: laneStyle,
    4: cancelledLane.style,
  };
};

const hideAccompli = (
  draftShown: boolean,
  pendingShown: boolean,
  laneStyle: any,
  hidden: any,
  activeBigger: boolean,
  activeLaneStyleWide: any,
  activeLaneStyle: any,
  cancelledLane: any,
) => {
  return {
    0: draftShown ? laneStyle : hidden,
    1: pendingShown ? laneStyle : hidden,
    2: draftShown && pendingShown ? (activeBigger ? activeLaneStyleWide : activeLaneStyle) : activeLaneStyleWide,
    3: hidden,
    4: cancelledLane.style,
  };
};

const setLaneStyleOnShowHide = (laneKey: number, show: boolean, lanes: any) => {
  const draftLane = lanes.find(i => i.key === 0);
  const draftShown = laneIsShown(draftLane);
  const pendingLane = lanes.find(i => i.key === 1);
  const pendingShown = laneIsShown(pendingLane);
  const activeCount = lanes.find(i => i.key === 2).cards.length;
  const accomplishedCount = lanes.find(i => i.key === 3).cards.length;
  const activeBigger = activeCount >= accomplishedCount;
  const currentLane = lanes.find(i => i.key === 2);
  const accomplishedLane = lanes.find(i => i.key === 3);
  const accomplishedShow = laneIsShown(accomplishedLane);
  const cancelledLane = lanes.find(i => i.key === 4);
  if (show && laneKey === 0) {
    return showDraft(
      laneStyle,
      pendingShown,
      accomplishedShow,
      hidden,
      activeLaneStyle,
      activeBigger,
      activeLaneStyleWide,
      cancelledLane,
    );
  } else if (!show && laneKey === 0) {
    return hideDraft(
      hidden,
      pendingShown,
      accomplishedShow,
      laneStyle,
      activeBigger,
      activeLaneStyleWide,
      activeLaneStyle,
      cancelledLane,
    );
  }
  else if (show && laneKey === 1) {
    return showPending(
      draftShown,
      accomplishedShow,
      laneStyle,
      hidden,
      activeLaneStyle,
      activeBigger,
      activeLaneStyleWide,
      cancelledLane,
    );
  } else if (!show && laneKey === 1) {
    return hidePending(
      draftShown,
      accomplishedShow,
      laneStyle,
      hidden,
      activeBigger,
      activeLaneStyleWide,
      activeLaneStyle,
      cancelledLane,
    );
  }
  else if (show && laneKey === 3) {
    return showAccompli(
      draftShown,
      pendingShown,
      laneStyle,
      hidden,
      activeLaneStyle,
      activeBigger,
      activeLaneStyleWide,
      cancelledLane,
    );
  } else if (!show && laneKey === 3) {
    return hideAccompli(
      draftShown,
      pendingShown,
      laneStyle,
      hidden,
      activeBigger,
      activeLaneStyleWide,
      activeLaneStyle,
      cancelledLane,
    );
  } else if (show && laneKey === 4) {
    return {
      0: draftLane.style,
      1: pendingLane.style,
      2: currentLane.style,
      3: accomplishedLane.style,
      4: horizontalLane,
    };
  } else if (!show && laneKey === 4) {
    return {
      0: draftLane.style,
      1: pendingLane.style,
      2: currentLane.style,
      3: accomplishedLane.style,
      4: hiddenDisplayNone,
    };
  } else {
    return {
      0: draftLane.style,
      1: pendingLane.style,
      2: currentLane.style,
      3: accomplishedLane.style,
      4: cancelledLane.style,
    };
  }
};

/* se crea variable para validar estilos de posición del nuevo collapse */

export let collapseNewBox: object = [
  { laneKey: 0, show: true }, { laneKey: 1, show: true }
];

export function showHideLane(laneKey: number, show: boolean) {

  if (laneKey === collapseNewBox[0].laneKey) {
    collapseNewBox[0].show = show
  } else if (laneKey === collapseNewBox[1].laneKey) {
    collapseNewBox[1].show = show
  }
  return async (dispatch: any, getState: any) => {
    const currentProject = { ...getState().currentProject };
    const updatedStyles: any = setLaneStyleOnShowHide(laneKey, show, currentProject.accordancesData.lanes);
    const newLanes = currentProject.accordancesData.lanes.map(item => {
      item.style = updatedStyles[item.key];
      return item;
    });

    dispatch(
      updateCurrentProject({
        ...currentProject,
        boardStyle: laneKey === 4 ? (show ? boardStyle : boardStyleCollapsed) : currentProject.boardStyle,
        ...{ accordancesData: { lanes: newLanes } },
      }),
    );
  };
}

export function updateAccordanceFilters(selectedYear: string, selectedType: string) {
  return async (dispatch: any, getState: any) => {
    const currentProject = { ...getState().currentProject };
    dispatch(
      updateCurrentProject({
        ...currentProject,
        selectedYear,
        selectedType,
      }),
    );
  };
}
