import { SET_NOTIFICATIONS, SET_NOTIFICATIONS_TOTAL_PAGES } from './types';
import Notifications from '../../models/Notifications';
import { getNotifications } from '../../api/notificationApi';

export function setNotificaions(data: Notifications[]) {
  return {
    type: SET_NOTIFICATIONS,
    payload: data,
  };
}

export function setNotificaionsTotalPages(totalPages: any) {
  return {
    type: SET_NOTIFICATIONS_TOTAL_PAGES,
    payload: totalPages,
  };
}

export function getNotificationsFromApi() {
  return async (dispatch: any) => {
    try {
      const response = await getNotifications(1);
      await dispatch(setNotificaions([...response]));
    } catch (error) {}
  };
}

export function getMoreNotifications(pageNumber: number) {
  return async (dispatch: any, getState: any) => {
    try {
      const response = await getNotifications(pageNumber);
      dispatch(setNotificaions([...getState().notifications, ...response]));
    } catch (error) {}
  };
}
