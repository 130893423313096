import { RegionsAcronymEnum } from '../../../models/RegionsAcronymEnum';
import { cloneDeep } from 'lodash';

const getOptionsCountriesRelatedWithTabs = (menuValue, regions) => {
  if (menuValue === 'country' || menuValue === 'my_portfolio') {
    return [];
  } else if (menuValue === 'global_bid') {
    const countriesOptions: any[] = [];

    regions.forEach(region => {
      region.countries.forEach(country => {
        countriesOptions.push({ value: country.id, optionLabel: country.name });
      });
    });
    return countriesOptions;
  } else {
    const { countries } = regions.find(region => region.acronym === RegionsAcronymEnum[menuValue.acronym]);
    const countriesOptions: any[] = [];
    countries.forEach(country => {
      countriesOptions.push({ value: country.id, optionLabel: country.name });
    });
    return countriesOptions;
  }
};

const setOptionCountries = (menuValue, regionState) => {
  const returnedCountriesOption = getOptionsCountriesRelatedWithTabs(menuValue, regionState);
  return returnedCountriesOption && returnedCountriesOption.length > 0 ? false : true;
};

const getSelectedTabRegionInfo = (selectedTabState, regionState) => {
  return selectedTabState === undefined
    ? 'all'
    : regionState.find(region => region.name === RegionsAcronymEnum[selectedTabState]).id;
};

const handleFilterCountry = (portfolioCompleteRevisionState, selectedValue) => {
  const countryFilter = cloneDeep(portfolioCompleteRevisionState);
  countryFilter &&
    countryFilter.map(
      revision =>
        (revision.revisions = revision.revisions.filter(
          revisionObject => revisionObject.country.id === parseInt(selectedValue),
        )),
    );
  return countryFilter ? countryFilter : [];
};

export { getOptionsCountriesRelatedWithTabs, setOptionCountries, getSelectedTabRegionInfo, handleFilterCountry };
