import React from 'react';
import CloseCrossButton from '../../Util/Buttons/CloseCrossButton/CloseCrossButton';
import { styles } from '../../Util/Modals/Styles';
import { useTranslation } from 'react-i18next';
import './ContentManagerModal.scss';
import BaseModal from '../../Util/Modals/BaseModal';

type Props = {
  isOpen: boolean;
  closeModal: Function;
  openModal: Function;
  modalTitle: string;
  children: any;
  buttonText?: string;
  fromButton?: boolean;
  fromDelegations?: boolean;
  buttonDisabled?: boolean;
  disabledMessage?: any;
};

export default function ContentManagerModal({
  buttonText,
  isOpen,
  closeModal,
  openModal,
  modalTitle,
  children,
  fromButton = true,
  fromDelegations = false,
  buttonDisabled = false,
  disabledMessage,
}: Props) {
  const getDisabledClass = buttonDisabled => buttonDisabled && 'disabled';
  const { t } = useTranslation('contentManager');

  return (
    <>
      {buttonDisabled && <div className="disabled-button-warning">{t(disabledMessage)}</div>}
      <div className="content-manager-modal" style={!fromButton ? { marginTop: 0 } : {}}>
        {fromButton && (
          <button
            className={`content-manager-modal__button ${getDisabledClass(buttonDisabled)}`}
            onClick={() => openModal()}
            disabled={buttonDisabled}
          >
            {buttonText}
          </button>
        )}
        <BaseModal
          isOpen={isOpen}
          style={styles}
          className={fromDelegations ? 'modal-delegations-config-content' : 'modal-report-portfolio-config-content'}
        >
          <div className="modal-report-portfolio-config-container">
            <div className="close-cross-container">
              <CloseCrossButton handleClick={() => closeModal()} />
            </div>
            <h3>{modalTitle}</h3>
            <div className="content-manager-modal__container">{children}</div>
          </div>
        </BaseModal>
      </div>
    </>
  );
}
