import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloseCrossButton from '../../../../Util/Buttons/CloseCrossButton/CloseCrossButton';
import SaveButton from '../../../../Util/Buttons/SaveButton/SaveButton';
import { styles } from '../../../../Util/Modals/Styles';
import customStyles from './ApproveAccordancesModal.module.scss';
import UndoButton from '../../../../Util/Buttons/UndoButton/UndoButton';
import { approveAccordancesList } from '../../../../../api/accordanceApi';
import { isMobile } from '../../../../../services/booleanUtil';
import BaseModal from '../../../../Util/Modals/BaseModal';
import SingleProjectList from './SingleProjectList';
import MultiProjectList from './MultiProjectList';

const displayButton = (data: any) => (data && data.length ? data.some(i => i.currentUserCanApprove) : false);

export const setEverySelectedAndVisible = (list: any[], selected: boolean) =>
  list.map(o => {
    o.selected = selected;
    o.visible = true;
    return o;
  });

export const setSelectedOnlyVisible = (list: any[], selected: boolean) =>
  list.map(a => {
    if (a.visible) {
      a.selected = selected;
    }
    return a;
  });

type Props = {
  data: any;
  onSubmit: Function;
  multiProject?: boolean;
};

const ApproveAccordancesModal = ({ data, onSubmit, multiProject }: Props) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [list, setList] = useState<any[]>([]);
  const { modalContent, closeCrossContainer, container, title, footer } = customStyles;

  useEffect(() => {
    if (data) {
      setList(setEverySelectedAndVisible(data, false));
    }
  }, [data, showModal]);

  const updateListItem = (item: any) => {
    setList(
      list.map(i => {
        if (i.id === item.id) {
          i.selected = !i.selected;
        }
        return i;
      }),
    );
  };

  const submit = async () => {
    setSubmitting(true);
    try {
      await approveAccordancesList(list.filter(o => o.selected && o.visible).map(i => i.id));
      setSubmitting(false);
      setShowModal(false);
      onSubmit();
    } catch (err) {
      setSubmitting(false);
    }
  };

  return (
    <>
      <SaveButton
        handleClick={() => setShowModal(true)}
        customText={t('approve_accordances')}
        disabled={!displayButton(data)}
        // customStyle={isMobile() ? { height: 32, margin: '10px 0px' } : { height: 32, marginLeft: -300 }}
      />
      <BaseModal
        isOpen={showModal}
        onRequestClose={e => setShowModal(false)}
        style={{
          overlay: { ...styles.overlay, ...{ overflowY: 'auto' } },
          content: { ...styles.content, width: multiProject ? 723 : 618 },
        }}
        className={modalContent}
      >
        <div className={container}>
          <div className={closeCrossContainer}>
            <CloseCrossButton handleClick={() => setShowModal(false)} />
          </div>
          <h3 className={title}>{t('approve_accordances')}</h3>
          {multiProject ? (
            <MultiProjectList list={list} setList={setList} updateListItem={updateListItem} data={data} />
          ) : (
            <SingleProjectList list={list} setList={setList} updateListItem={updateListItem} />
          )}
          <div className={footer}>
            <SaveButton
              disabled={submitting || !list.some(i => i.selected && i.visible)}
              customText={t('approve_accordances')}
              handleClick={() => submit()}
            />{' '}
            <UndoButton
              customText={t('cancel')}
              handleClick={() => setShowModal(false)}
              customStyle={isMobile() ? { marginTop: 10 } : {}}
            />
          </div>
        </div>
      </BaseModal>
    </>
  );
};

export default ApproveAccordancesModal;
