import React from 'react';
import Modal from 'react-modal';
import './CommentsModal.scss';
import { disableEscKey } from '../../../../Util/Modals/Util';
import Comments from '../../../../Util/Comments/Comments';
import { Activity } from '../../../../../models/Planning';
import CloseCrossButton from '../../../../Util/Buttons/CloseCrossButton/CloseCrossButton';
import { newComment, deleteComment } from '../../../../../api/planningApi';
import BaseModal from '../../../../Util/Modals/BaseModal';

const styles: Modal.Styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10000,
    overflowY: 'scroll',
  },
};

type Props = {
  isOpen: boolean;
  closeModal: any;
  activity: Activity;
  customHeight: number;
  keyProductId: number;
};

const CommentsModal = ({ isOpen, closeModal, activity, customHeight, keyProductId }: Props) => {
  return (
    <BaseModal
      isOpen={isOpen}
      onRequestClose={e => disableEscKey(e, closeModal)}
      style={{ ...styles, content: { top: customHeight + 20 } }}
      className="modal-planning-activity-comments-content"
    >
      <div className="modal-planning-activity-comments-container">
        <div className="close-cross-container">
          <CloseCrossButton handleClick={closeModal} />
        </div>
        <Comments
          comments={activity.comments || []}
          submitComment={comment => newComment(activity.id || 0, activity.acquisitionId || 0, comment, keyProductId)}
          submitDeleteComment={commentId =>
            deleteComment(activity.id || 0, activity.acquisitionId || 0, commentId, keyProductId)
          }
        />
      </div>
    </BaseModal>
  );
};

export default CommentsModal;
