import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './EventBubbles.module.scss';

type Props = {
  events: any;
  handleClickSingle: Function;
  handleClickMultiple: Function;
};

const oneEventTypeLessThanFiveItems = (events: any) => {
  const keys = Object.keys(events);
  return keys.length === 1 && events[keys[0]].length < 5;
};

const mappedTexts = [
  'disbursements',
  'acquisitions',
  'activities',
  'monitor_accordances',
  'revision_accordances',
  'missions',
  'secretarial_events',
  'lifecycle',
  'inter_group_accordances',
  'supervisory_actions',
];

const groupedStyle = (keys: string[]) => {
  const width = keys.length > 8 ? '30%' : keys.length > 4 ? '45%' : '100%';
  return { padding: '3px 10px', height: '20%', width, minHeight: '18px' };
};

const bubbleStyle = eventFirstKey => (eventFirstKey.length > 2 ? { padding: '3px 10px' } : {});
const bubbleSmallLineStyle = eventFirstKey => (eventFirstKey.length < 3 ? { height: 4 } : {});
const containerStyle = events => (oneEventTypeLessThanFiveItems(events) ? 'row' : 'column');

const EventBubbles = ({ events, handleClickSingle, handleClickMultiple }: Props) => {
  const { t } = useTranslation();
  const keys = Object.keys(events);

  const handleClickGrouped = (item, e) => {
    if (events[item].length === 1) {
      handleClickSingle(events[item][0], e);
    } else {
      handleClickMultiple(events[item], e);
    }
  };

  return (
    <div className={styles.container} style={{ flexDirection: containerStyle(events) }}>
      {oneEventTypeLessThanFiveItems(events)
        ? events[keys[0]].map(item => {
            return (
              <div
                className={styles[`bubble${item.eventType}`]}
                title={item.title}
                style={bubbleStyle(events[keys[0]])}
                onClick={e => handleClickSingle(item, e)}
                key={`${item.id}${item.projectCode}${item.title}`}
              >
                {item.projectCode ? (
                  <h4 className={styles.code}>
                    {item.projectCode} {item.loanNumber && ` | ${item.loanNumber}`}
                  </h4>
                ) : null}
                {!item.projectCode || (item.projectCode && events[keys[0]].length < 3) ? (
                  <p className={styles.title}>{item.title}</p>
                ) : null}
                <span
                  className={styles[`smallLine${item.eventType}`]}
                  style={bubbleSmallLineStyle(events[keys[0]])}
                ></span>
              </div>
            );
          })
        : keys.map(item => {
            return (
              <div
                className={styles[`bubble${item}`]}
                style={groupedStyle(keys)}
                onClick={e => handleClickGrouped(item, e)}
                key={item}
              >
                <p className={styles.title}>
                  <span style={{ fontWeight: 600, marginRight: 2 }}>{`(${events[item].length})`}</span>
                  {keys.length < 5 ? t(mappedTexts[item]) : '+'}
                </p>
                <span className={styles[`smallLine${item}`]}></span>
              </div>
            );
          })}
    </div>
  );
};

export default EventBubbles;
