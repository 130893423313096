import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextareaField } from '../MemoryHelpComponents/MemoryHelpsInputs';
import { useFormMemoryHelpContext } from '../../../context/MemoryHelpFormContext';

const CURRENT_STAGE = "close";

export const Close = () => {
  const { t } = useTranslation();
  const { onInputChange } = useFormMemoryHelpContext();

  return (
    <div>
      <h5 className='title'>7. {t('memory_help.tabs.7')}</h5>
      <div className='bg-grey'>
        <p>{t('memory_help.container_tabs.7.description')}</p>
      </div>
      <TextareaField
        placeholder={t('memory_help.container_tabs.7.placeholder')}
        name='aditional_info_close'
        onChange={(e) => onInputChange(e, CURRENT_STAGE)}
        currentTabId={CURRENT_STAGE}
        addClass='input-height'
      />
    </div>);
};

export default Close;
