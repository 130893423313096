import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import { TabPanel } from '../PortfolioDetailsUtil';
import PortfolioAccordanceTable from '../PortfolioAccordanceTable/PortfolioAccordanceTable';
import { ProjectCounter } from '../ProjectCounter/ProjectCounter';
import Accordance from '../../../models/Accordance';
import { WorkWithTable } from '../WorkWithTable/WorkWithTable';

interface ReceivedProps {
  indicatorAccordances: Accordance[];
  handleProject: any;
  rowSelected: any;
  selectedTab: number;
}

type Props = ReceivedProps;
export const MobileAccordanceTable = (props: Props) => {
  const { indicatorAccordances, handleProject, rowSelected, selectedTab } = props;
  const theme = useTheme();
  const [value, setValue] = React.useState(selectedTab);

  React.useEffect(() => {
    setValue(selectedTab);
  }, [selectedTab]);
  return (
    <div className="react-swipeable-view-container">
      <SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={value}>
        <TabPanel value={value} index={0} dir={theme.direction}>
          <PortfolioAccordanceTable accordances={indicatorAccordances} handleProject={handleProject} />
          <ProjectCounter arrayToCount={indicatorAccordances} countProjects={false} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <WorkWithTable rowSelected={rowSelected} />
        </TabPanel>
      </SwipeableViews>
    </div>
  );
};
