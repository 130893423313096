import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import Country from '../../../models/Country';
import { multiSelectStyle, DropdownIndicator } from '../FilterComponent.Styles';
import { CalledFrom } from '../../../models/CalledFromEnum';
import SelectOption from '../../../models/SelectOption';
import { trackActionClicked } from '../../../api/trackActionClickedApi';
import { TrackedActions } from '../../../models/TrackedActionsEnum';
import useFilters from '../../../hooks/useFilters';
import { getCustomTabFilterDefaultValue, getCountryOptions } from '../UtilFunctions';
import { AppMenuTabs } from '../../MenuTabs/userTabsInterface';

interface IProps {
  countries: Country[];
  calledFrom: CalledFrom;
  setOptionSelected?: Function;
  isEdit?: boolean;
}

type Props = IProps;

const getSelectedTab = (calledFrom: CalledFrom, menuTabs: AppMenuTabs) => {
  return calledFrom === CalledFrom.CUSTOM_TABS_PORTFOLIO ? menuTabs.portfolioSelectedTab : menuTabs.projectSelectedTab;
};

export const CountryFilter = (props: Props) => {
  const { countries, calledFrom, isEdit, setOptionSelected } = props;
  const {
    filter,
    filterIndicators,
    filterAnalytics,
    dispatch,
    setFilter,
    setFilterIndicators,
    setFilterAnalytics,
    filterEarlyWarnings,
    setFilterEarlyWarnings,
  } = useFilters();

  const { t } = useTranslation();

  const regionsFromRedux = useSelector<any, any>(state => state.regions);
  const menuTabs = useSelector<any, any>(state => state.menuTabs);
  const selectedTab = getSelectedTab(calledFrom, menuTabs);

  const getAllCountriesOptions = () => {
    const countriesOptions: SelectOption[] = [];

    regionsFromRedux.forEach(region => {
      region.countries.forEach(country => {

        countriesOptions.push({
          id: country.id,
          label: country.name,
          value: country.name,
          categoryId: country.regionId,
        });
      });
    });

    return countriesOptions;
  };

  const allCountriesOptions = getAllCountriesOptions();
  const countriesOptions = countries && getCountryOptions(countries, menuTabs, calledFrom);

  const handleChange = value => {
    if (value === null) {
      value = [];
    }

    switch (calledFrom) {
      case CalledFrom.PORTFOLIO:
        dispatch(
          setFilterIndicators({
            ...filterIndicators,
            common: {
              ...filterIndicators.common,
              countryId: value,
            },
          }),
        );
        trackActionClicked(TrackedActions.countryFilterChangedFromPortfolio);
        break;
      case CalledFrom.PROJECT:
        dispatch(
          setFilter({
            ...filter,
            common: {
              ...filter.common,
              countryId: value,
              itSearch: true,
            },
          }),
        );
        trackActionClicked(TrackedActions.countryFilterChangedFromProject);
        break;
      case CalledFrom.ANALYTICS:
        dispatch(
          setFilterAnalytics({
            ...filterAnalytics,
            countryId: value,
          }),
        );
        break;
      case CalledFrom.CUSTOM_TABS_PORTFOLIO:
      case CalledFrom.CUSTOM_TABS_PROJECT:
        setOptionSelected && setOptionSelected(value);
        break;
      case CalledFrom.EARLY_WARNINGS:
        dispatch(
          setFilterEarlyWarnings({
            ...filterEarlyWarnings,
            common: {
              ...filterEarlyWarnings.common,
              countryId: value,
            },
          }),
        );
        trackActionClicked(TrackedActions.countryFilterChangedFromEarlyWarnings);
        break;
      default:
        break;
    }
  };

  const getDefaultValue = () => {
    switch (calledFrom) {
      case CalledFrom.PORTFOLIO:
        return filterIndicators.common.countryId;
      case CalledFrom.PROJECT:
        return filter.common.countryId;
      case CalledFrom.ANALYTICS:
        return filterAnalytics.countryId;
      case CalledFrom.CUSTOM_TABS_PORTFOLIO:
      case CalledFrom.CUSTOM_TABS_PROJECT:
        return isEdit ? getCustomTabFilterDefaultValue(selectedTab, 'ResponsibleCountryId', allCountriesOptions) : null;
      case CalledFrom.EARLY_WARNINGS:
        return filterEarlyWarnings.common.countryId;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (isEdit) {
      const selectedOption = getDefaultValue();
      selectedOption && selectedOption.length && setOptionSelected && setOptionSelected(selectedOption);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Select
      id="countriesSelect"
      defaultValue={getDefaultValue()}
      isMulti
      components={{ DropdownIndicator }}
      name="countries"
      options={countriesOptions}
      className="basic-multi-select"
      classNamePrefix="All"
      placeholder={t('all')}
      onChange={value => handleChange(value)}
      styles={multiSelectStyle}
    />
  );
};

export default CountryFilter;
