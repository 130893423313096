import React from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import styles from './HeaderTags.module.scss';
import { renderDateOrSlash } from '../../../../../services/stringUtil';
import { isMobile } from '../../../../../services/booleanUtil';

type Props = {
  supervisionInfo: any;
};

const HeaderTags = ({ supervisionInfo }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.headerContainer}>
      <div className={styles.left}>
        <span className={styles.infoText}>{t('supervision_status')}</span>
        <span className={styles.infoTextBold}>{t(`supervision_plan_state_${supervisionInfo.state}`)}</span>
        {isMobile() && <br />}
        <span className={styles.infoText}>
          {!isMobile() && `${' | '}`}
          {`${t('convergence_update_date')} `}
        </span>
        <span className={styles.infoTextBold}>{renderDateOrSlash(supervisionInfo.lastUpdate, 'D MMMM YYYY')}</span>
      </div>
      <div className={styles.right}>
        <div className={styles.budgetContainer}>
          <div className={styles.budgetRow}>
            <span className={styles.infoText}>{t('supervision_total_plan_budget')}</span>
            <span className={styles.budgetText}>
              <NumberFormat
                value={supervisionInfo.totalPlanBudget}
                displayType={'text'}
                thousandSeparator={'.'}
                decimalSeparator={','}
                decimalScale={2}
                prefix={'USD '}
              />
            </span>
          </div>
          <div className={styles.budgetRow}>
            <span className={styles.infoText}>{t('supervision_total_assigned_budget')}</span>
            <span className={styles.budgetText}>
              <NumberFormat
                value={supervisionInfo.totalAssignedBudget}
                displayType={'text'}
                thousandSeparator={'.'}
                decimalSeparator={','}
                decimalScale={2}
                prefix={'USD '}
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderTags;
