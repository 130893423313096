import { isMobile } from '../../../services/booleanUtil';
import { HORIZONTAL_BAR_EXTRA_SPACE } from './barchartsIndicatorsHelper';
import { createArrayOfColours, setBarThickness } from './indicatorHelperUtil';

const getGapBetweenBars = (lonData, tcpData, igrData, gapPosition) => {
  const data: number[] = [];

  const biggestBarValue = lonData.data[0] + tcpData.data[0] + igrData.data[0];
  const gapPercentage = biggestBarValue > 100 ? 30 : 25;

  if (gapPosition === 'gap1') {
    tcpData.data.forEach(item => {
      item === 0 ? data.push(0) : data.push((biggestBarValue / 100) * gapPercentage);
    });
  } else {
    igrData.data.forEach(item => {
      item === 0 ? data.push(0) : data.push((biggestBarValue / 100) * gapPercentage);
    });
  }

  return data;
};

const gapBetweenBars = maxCountriesAndValues => {
  return {
    data: [],
    backgroundColor: createArrayOfColours('transparent', maxCountriesAndValues),
    barThickness: setBarThickness(),
    borderWidth: 0,
    label: '',
    datalabels: {
      display: false,
    },
  };
};

const commonCharDataColors = () => '#1B1F27';

const responsiveFontsize = () => (isMobile() ? 10 : 12);

const commonChartData = {
  barThickness: setBarThickness(),
  borderWidth: 0,
  datalabels: {
    display: context => context.dataset.data[context.dataIndex] !== 0,
    align: 'left',
    anchor: 'start',
    color: () => commonCharDataColors(),
    font: { size: responsiveFontsize(), weight: 400, style: 'normal', family: 'Rubik' },
    padding: { left: -8 },
  },
};

const getDatasets = (lonChartData, tcpChartData, igrChartData, maxCountriesAndValues) => [
  lonChartData,
  {
    ...gapBetweenBars(maxCountriesAndValues),
    data: getGapBetweenBars(lonChartData, tcpChartData, igrChartData, 'gap1'),
    label: 'gap1',
  },
  tcpChartData,
  {
    ...gapBetweenBars(maxCountriesAndValues),
    data: getGapBetweenBars(lonChartData, tcpChartData, igrChartData, 'gap2'),
    label: 'gap2',
  },
  igrChartData,
];

const getValues = projNumbers => projNumbers.map(f => f.metricValue);
const getTotal = projNumbers => projNumbers.reduce((tot, num) => tot + num.metricValue, 0);

const otherDivisionTranslation = () => {
  const currentLang = localStorage.getItem('i18nextLng') || localStorage.getItem('language');
  if (!currentLang || currentLang.indexOf('en') > -1) {
    return 'OTHERS';
  }
  if (currentLang.indexOf('es') > -1) {
    return 'OTROS';
  }
  if (currentLang.indexOf('pt') > -1) {
    return 'OUTROS';
  }
  if (currentLang.indexOf('fr') > -1) {
    return 'AUTRES';
  }
  return 'OTHERS';
};

const filterProjNumbers = (indicator, metricNameProjectsLON, metricNameProjectsTCP, metricNameProjectsIGR) =>
  indicator.metrics.filter(
    x =>
      x.metricName === metricNameProjectsLON ||
      x.metricName === metricNameProjectsTCP ||
      x.metricName === metricNameProjectsIGR,
  );

const filterHeadersData = (
  indicator,
  metricNameProjectsLON,
  metricNameProjectsTCP,
  metricNameProjectsIGR,
  metricNameApprovedAmountLON,
  metricNameApprovedAmountTCP,
  metricNameApprovedAmountIGR,
) =>
  indicator.metrics.filter(
    x =>
      x.metricName === metricNameProjectsLON ||
      x.metricName === metricNameProjectsTCP ||
      x.metricName === metricNameProjectsIGR ||
      x.metricName === metricNameApprovedAmountLON ||
      x.metricName === metricNameApprovedAmountTCP ||
      x.metricName === metricNameApprovedAmountIGR,
  );

const fallBackKey = key => key.split('/')[1] || key;

const formattedText = (splitedKey, division) =>
  splitedKey ? splitedKey + HORIZONTAL_BAR_EXTRA_SPACE : division.key + HORIZONTAL_BAR_EXTRA_SPACE;

const getMetricValueByProjectTypeAndMetricName = (projectType, metricName, data) => {
  const filteredData = data.filter(
    item => parseInt(item.projectType) === projectType && item.metricName === metricName,
  );
  return filteredData.length && filteredData[0].metricValue;
};

export {
  getDatasets,
  getValues,
  getTotal,
  commonChartData,
  filterProjNumbers,
  filterHeadersData,
  fallBackKey,
  formattedText,
  otherDivisionTranslation,
  getMetricValueByProjectTypeAndMetricName,
  getGapBetweenBars,
};
