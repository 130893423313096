import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { ReactComponent as Arrow } from '../../../../assets/dropdown-arrow.svg';
import { ExpansionPanel, ExpansionPanelSummary, Typography, ExpansionPanelDetails } from '@material-ui/core';
import AddIndicatorCard from './Card/AddIndicatorCard';
import './AddIndicatorsMobile.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SearchIcon } from '../../../../assets/search_blue.svg';

interface ReceivedProps {
  allIndicators: any[];
  selectedIndicators: any;
  addOrRemoveIndicator: any;
  handleSearch: any;
}

type Props = ReceivedProps;

export const AddIndicatorsMobile = (props: Props) => {
  const { allIndicators, selectedIndicators, addOrRemoveIndicator, handleSearch } = props;

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const { t } = useTranslation();
  const styles = {
    root: {
      padding: '0',
    },
    slideContainer: {
      padding: '0',
    },
    slide: {
      padding: 20,
      minHeight: 100,
      color: '#fff',
    },
  };

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={'add-indicators-mobile-container'}>
      {allIndicators &&
        allIndicators.map(menu => (
          <ExpansionPanel key={menu.name} expanded={expanded === menu.name} onChange={handleChange(menu.name)}>
            <ExpansionPanelSummary expandIcon={<Arrow />} aria-controls="content" id="header">
              <Typography className={`heading  ${expanded === menu.name ? 'expanded' : ''}`}>
                {!menu.isSearch ? menu.name : <SearchIcon />}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={'accordion'}>
              <div className="indicators-search-container-mobile">
                {menu.isSearch && (
                  <input className="indicators-search-input-mobile" placeholder={t('search')} onChange={handleSearch} />
                )}
              </div>
              <div className="indicators-cards-container">
                <SwipeableViews style={styles.root} slideStyle={styles.slideContainer}>
                  {menu.indicators &&
                    menu.indicators.map(indicator => (
                      <div key={indicator.id} style={Object.assign({}, styles.slide)}>
                        <AddIndicatorCard
                          key={indicator.id}
                          card={indicator}
                          isSelected={selectedIndicators.includes(indicator.id)}
                          setSelected={id => {
                            addOrRemoveIndicator(id);
                          }}
                          showDescription={true}
                        />
                      </div>
                    ))}
                </SwipeableViews>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}
    </div>
  );
};
