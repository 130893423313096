import { makeStyles, Tab, Tabs } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { IncludeRegionalProjectsEnum } from '../../../../../models/IncludeRegionalProjectsEnum';
import { styles } from '../../../../Filter/FilterComponent.Styles';
import { tabStyles } from '../../../../Projects/ProjectsAll/ProjectsAll.Style';
import { CustomCheckbox } from '../../../../Util/CustomCheckbox/CustomCheckbox';
import { ModalHeader } from '../../SharedUtilComponents/ModalHeader/ModalHeader';
import { SummaryLoanInternalFilter } from '../SummaryLoan';
import contentStyles from './LoanFilter.module.scss';

interface IProps {
  filterApplied: SummaryLoanInternalFilter;
  handleFilterIncludeRegionalsProjects: Function;
  handleIncludeRegionalOptionSelected: Function;
  handleFilterAlertProblemClick: Function;
  isOpen: boolean;
  closeModal: any;
  disabled: boolean;
}

const LoanFilter = (props: IProps) => {
  const classes = makeStyles(tabStyles)();
  const tabClasses = { root: classes.root, selected: classes.selected };
  const {
    handleFilterIncludeRegionalsProjects,
    handleIncludeRegionalOptionSelected,
    handleFilterAlertProblemClick,
    isOpen,
    closeModal,
    filterApplied,
    disabled,
  } = props;
  const { includeRegionalProjectsApplied, optionSelectedApplied, alertProblemSummaryFilterApplied } = filterApplied;
  const {
    modalFilterContent,
    modalFilterContainer,
    horizontalBetween,
    horizontalColumns,
    includeRegionalProjectsContainer,
    toggleSelectorContainer,
    projectsWithPmrAlertAndProblem,
    hiddenSection,
  } = contentStyles;

  const { t } = useTranslation();
  const filtersPMR = useSelector<any, any[]>(state => state.filterIndicators.common.pmrId).some(
    pmr => pmr.selected === true,
  );
  const handleCloseModal = () => {
    closeModal(false);
  };

  const handleSetMenuValue = (tabValue: any) => {
    handleIncludeRegionalOptionSelected(tabValue);
  };

  const myStyle = {
    fontSize: '12px',
    color: '#FF3333'
  };
  return (
    <Modal isOpen={isOpen} style={styles} onRequestClose={handleCloseModal} className={modalFilterContent}>
      <div className={modalFilterContainer}>
        <ModalHeader style={horizontalBetween} title={'loan_filters'} label={'loans'} func={handleCloseModal} />

        <div className={includeRegionalProjectsContainer}>
          <CustomCheckbox
            labelText={t('include_regionals_projects')}
            isChecked={includeRegionalProjectsApplied}
            handleOnClick={handleFilterIncludeRegionalsProjects}
            customStyle={{ marginBottom: 5 }}
            disabled={disabled}
          />
          {disabled && <span style={myStyle}>{t('disable_regional_filter')}</span>}
        </div>
        <div className={includeRegionalProjectsApplied ? toggleSelectorContainer : hiddenSection}>
          <Tabs
            value={optionSelectedApplied}
            classes={{ indicator: classes.indicatorColor }}
            variant="standard"
            aria-label="full width tabs example"
            disabled={disabled}
          >
            <Tab
              key={IncludeRegionalProjectsEnum.INCLUDE_COUNTRY_ADMIN}
              value={IncludeRegionalProjectsEnum.INCLUDE_COUNTRY_ADMIN}
              classes={tabClasses}
              label={t('country_admin')}
              onClick={() => handleSetMenuValue(IncludeRegionalProjectsEnum.INCLUDE_COUNTRY_ADMIN)}
              disabled={disabled}
            />
            <Tab
              key={IncludeRegionalProjectsEnum.INCLUDE_UDR}
              value={IncludeRegionalProjectsEnum.INCLUDE_UDR}
              classes={tabClasses}
              label={t('udr')}
              onClick={() => handleSetMenuValue(IncludeRegionalProjectsEnum.INCLUDE_UDR)}
              disabled={disabled}
            />
          </Tabs>
        </div>

        <div className={filtersPMR ? hiddenSection : projectsWithPmrAlertAndProblem}>
          <CustomCheckbox
            labelText={`${t('see_only_projects_with_PMR_in')} ${t('alert_and_trouble')}`}
            isChecked={alertProblemSummaryFilterApplied}
            handleOnClick={handleFilterAlertProblemClick}
          />
        </div>
        <div className={horizontalColumns}></div>
      </div>
    </Modal>
  );
};

export default LoanFilter;
