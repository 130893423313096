import React, { useEffect, useState } from 'react'
import { ReactComponent as IconBack } from '../../../assets/icons/u_arrow-left.svg'
import { useTranslation } from 'react-i18next';
import { useFormMemoryHelpContext } from '../../../context/MemoryHelpFormContext';
import { revisionNumberStrings } from '../models';
import { MemoryHelpReturnModal } from './MemoryHelpReturnModal';
import { revisionTranslationKeys } from '../utils';

interface MemoryHelpHeaderProps {
    setVisibleComponent: (path: string) => void;
    navigatePath: string;
    hasModal?: boolean
}

const MemoryHelpHeader = ({ navigatePath, hasModal, setVisibleComponent }: MemoryHelpHeaderProps) => {
    const { t } = useTranslation();

    const { agreementsData, setTrigger } = useFormMemoryHelpContext();
    const [showHeaderModal, setShowHeaderModal] = useState<boolean>(false);

    const handleCloseModal = () => setShowHeaderModal(false);
    const onHandleClick = () => {
        if (hasModal) {
            setShowHeaderModal(true)
        } else {
            setVisibleComponent(navigatePath)
        }
    }

    function getRevisionTranslation(revisionNumber: string): string {
        const translationKey = revisionTranslationKeys[revisionNumber];
        return t(translationKey);
    }

    useEffect(() => setTrigger(prev => !prev), [])

    return (
        <div className="back-title">
            <button onClick={() => onHandleClick()} className='btn btn-primary-text'><IconBack />{t('memory_help.button_back')}</button>
            <div>
                <h2>
                    {t('memory_help.page_reviews.popover_date.title')}<span> {getRevisionTranslation(agreementsData?.dataRevision?.revisionNumber)} {t('memory_help.page_reviews.popover_date.revision')} {agreementsData?.dataRevision?.year}</span>
                </h2>
            </div>
            {hasModal && <MemoryHelpReturnModal
                isOpen={showHeaderModal}
                closeModal={handleCloseModal}
                setVisibleComponent={setVisibleComponent}
                navigatePath={navigatePath} />}
        </div>
    )
}

export default MemoryHelpHeader;

