import { withStyles, createStyles, Theme, TableRow, TableContainer, TableCell } from '@material-ui/core';
import { isMobile } from '../../../services/booleanUtil';

export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      cursor: 'pointer',
    },
  }),
)(TableRow);

export const StyledTableContainer = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  }),
)(TableContainer);

const defaultRootStyledTableCellPageCounter = {
  root: {
    color: '#727e8c',
    background: '#fff',
    fontFamily: 'Rubik',
    fontSize: isMobile() ? '9px' : '12px',
    fontWeight: 500,
    cursor: 'default',
    lineHeight: isMobile() ? '0.7rem' : '1.2rem',
    borderTop: '1px solid #727e8c',
    borderBottom: '1px solid #727e8c !important',
  },
};

export const StyledTableCellPageCounter = withStyles((theme: Theme) =>
  createStyles(defaultRootStyledTableCellPageCounter),
)(TableCell);

export const StyledTableCellPageCounterAverageLag = withStyles((theme: Theme) =>
  createStyles({
    ...defaultRootStyledTableCellPageCounter,
    root: {
      ...defaultRootStyledTableCellPageCounter.root,
      padding: '16px 16px 16px 0',
    },
  }),
)(TableCell);
