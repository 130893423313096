import React from 'react';
import './AcquisitionAccordeon.scss';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { Activity } from '../Activity/Activity';
import AcquisitionItem from '../AcquisitionItem/AcquisitionItem';
import { KeyProduct } from '../../../../../models/Planning';

type Props = {
  keyProduct: KeyProduct;
};

const AcquisitionAccordeon = ({ keyProduct }: Props) => {
  return (
    <div className="acquisition-accordeon-container">
      {keyProduct && keyProduct.acquisitions && keyProduct.acquisitions.length
        ? keyProduct.acquisitions.map((item, index) => {
            return (
              <ExpansionPanel key={item.id} className="panel-root">
                <ExpansionPanelSummary
                  className="summary-root"
                  aria-controls={`panel${index + 1}a-content`}
                  id={`panel${index + 1}a-header`}
                >
                  <AcquisitionItem acquisition={item} />
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className="panel-details">
                  <Activity acquisition={item} />
                </ExpansionPanelDetails>
              </ExpansionPanel>
            );
          })
        : null}
    </div>
  );
};

export default AcquisitionAccordeon;
