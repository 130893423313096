import React from 'react';
import './Pmr.scss';
import { useTranslation } from 'react-i18next';
import { PmrClassificationBig } from '../../Util/PmrClassification/PmrClassificationBig';
import { PmrClassification } from '../../../models/PmrClassification';
import { PmrPrintValue } from '../../Util/PmrPrintValue/PmrPrintValue';
import { basicData } from '../../Reviews/Filters/BasicData';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { setProjectTabValue } from '../../../redux/actions/projectTabValue';
import { useDispatch } from 'react-redux';

const BlackTooltip = withStyles({
  tooltip: {
    //   color: '$ci-color-greyscale-90',
    backgroundColor: '#101319',
    fontSize: '16px',
    fontFamily: 'Rubik',
    // fontWeight: '400',
    lineHeight: '20px',
    padding: '8px 12px',
    borderRadius: '8px',
  },
  arrow: {
    fontSize: 20,
    color: '#4A4A4A',
    '&::before': {
      backgroundColor: '#101319',
    },
  },
})(Tooltip);

type Props = {
  classification: PmrClassification;
  value: number;
  projectCode: string;
};

const Pmr = (props: Props) => {
  const { classification, value, projectCode } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSeeMoreClick = () => {
    const targetSection = document.getElementById('project-tabs');
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: 'smooth' });
    }
    setTimeout(() => {
      dispatch(setProjectTabValue(4));
    }, 380);
  };

  return (
    <div className="project-detail-pmr-conatiner">
      <div className="pmr_container_span">
        <span className="value">
          <PmrClassificationBig classificationColor={classification} />

          <BlackTooltip arrow title={t(basicData.physical_execution.pmr_initials[classification].label)}>
            <span className="text">
              <PmrPrintValue pmrClassi={classification} pmrValue={value.toFixed(1)} />
              {` ${t('pmr_initials')}`}
            </span>
          </BlackTooltip>
        </span>
      </div>
      <div className="pmr_container_link">
        <button className="link" onClick={handleSeeMoreClick}>
          {t('see_more')}
        </button>
      </div>
    </div>
  );
};

export default Pmr;
