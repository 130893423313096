import React from 'react';
import './DateText.scss';
import { useTranslation } from 'react-i18next';
import { renderDateOrSlash } from '../../../services/stringUtil';
// import moment from 'moment';
import { DateTextTypes } from '../../../models/DateTextTypes';

type Props = {
  type: DateTextTypes;
  value: string;
  text: string;
};

const DateText = (props: Props) => {
  const { value, text, type } = props;
  const { t } = useTranslation();
  return (
    <div className="container-project-detail-date">
      <div className="project-detail-date-text-container">
        <span className="text">{t(text)}</span>
        <span className="date">
          {/* {type === DateTextTypes.Date ? (value ? moment(value).format('DD/MM/YY') : '-') : value} */}
          {type === DateTextTypes.Date ? (value ? renderDateOrSlash(value) : '-') : value}
        </span>
      </div>
    </div>
  );
};

export default DateText;
