import { updateCurrentProject } from './currentProject';
import {
  getKeyProducts,
  newKeyProduct,
  newAcquisition,
  getKeyProductById,
  removeAcquisition,
  editAcquisition,
  getAcquisitionById,
  removeActivity,
  newActivity,
  editActivity,
  removeKeyProduct,
} from '../../api/planningApi';
import { setRequestStatus } from './requestStatus';
import { RequestStatus } from '../../models/RequestStatus';
import { showSuccesMessage } from './sucessMessage';

export function getProjectKeyProducts(id: number) {
  return async (dispatch: any, getState: any) => {
    dispatch(setRequestStatus(RequestStatus.Loading));
    try {
      const response = await getKeyProducts(id);
      const currentProject = { ...getState().currentProject };
      dispatch(setRequestStatus(RequestStatus.Default));
      dispatch(updateCurrentProject({ ...currentProject, keyProducts: [...response] }));
    } catch (error) {
      dispatch(setRequestStatus(RequestStatus.Default));
    }
  };
}

export function getProjectKeyProductId(id: number) {
  return async (dispatch: any, getState: any) => {
    try {
      const response = await getKeyProductById(id);
      const currentProject = { ...getState().currentProject };
      dispatch(
        updateCurrentProject({
          ...currentProject,
          keyProducts: currentProject.keyProducts.map(kp => {
            if (response && kp.id === response.id) {
              kp = response;
            }
            return kp;
          }),
        }),
      );
    } catch (error) {}
  };
}

export function addKeyProduct(id: number) {
  return async (dispatch: any, getState: any) => {
    try {
      const response = await newKeyProduct(id);
      const currentProject = { ...getState().currentProject };
      dispatch(updateCurrentProject({ ...currentProject, keyProducts: [...currentProject.keyProducts, response] }));
    } catch (error) {}
  };
}

export function deleteKeyProduct(id: number) {
  return async (dispatch: any, getState: any) => {
    try {
      await removeKeyProduct(id);
      dispatch(showSuccesMessage());
      const currentProject = { ...getState().currentProject };
      dispatch(getProjectKeyProducts(currentProject.id));
    } catch (error) {}
  };
}

export function createAcquisition(data: any) {
  return async (dispatch: any, getState: any) => {
    try {
      await newAcquisition(data);
      dispatch(showSuccesMessage());
      const currentProject = { ...getState().currentProject };
      dispatch(getProjectKeyProducts(currentProject.id));
    } catch (error) {}
  };
}

export function deleteAcquisition(data: any) {
  return async (dispatch: any, getState: any) => {
    try {
      await removeAcquisition(data);
      dispatch(showSuccesMessage());
      const currentProject = { ...getState().currentProject };
      dispatch(getProjectKeyProducts(currentProject.id));
    } catch (error) {}
  };
}

export function updateAcquisition(data: any) {
  return async (dispatch: any, getState: any) => {
    try {
      await editAcquisition(data);
      dispatch(showSuccesMessage());
      const currentProject = { ...getState().currentProject };
      dispatch(getProjectKeyProducts(currentProject.id));
    } catch (error) {}
  };
}

export function getAcquisitionId(data: any) {
  return async (dispatch: any, getState: any) => {
    try {
      const response = await getAcquisitionById(data);
      const currentProject = { ...getState().currentProject };
      dispatch(
        updateCurrentProject({
          ...currentProject,
          keyProducts: currentProject.keyProducts.map(kp => {
            if (kp.id === data.keyProductId) {
              kp.acquisitions = kp.acquisitions.map(item => {
                if (item.id === data.id) {
                  item = response;
                }
                return item;
              });
            }
            return kp;
          }),
        }),
      );
    } catch (error) {}
  };
}

export function deleteActivity(data: any) {
  return async (dispatch: any, getState: any) => {
    try {
      await removeActivity(data);
      dispatch(showSuccesMessage());
      const currentProject = { ...getState().currentProject };
      dispatch(getProjectKeyProducts(currentProject.id));
    } catch (error) {}
  };
}

export function createActivity(data: any) {
  return async (dispatch: any, getState: any) => {
    try {
      await newActivity(data);
      dispatch(showSuccesMessage());
      const currentProject = { ...getState().currentProject };
      dispatch(getProjectKeyProducts(currentProject.id));
    } catch (error) {}
  };
}

export function updateActivity(data: any) {
  return async (dispatch: any, getState: any) => {
    try {
      await editActivity(data);
      dispatch(showSuccesMessage());
      const currentProject = { ...getState().currentProject };
      dispatch(getProjectKeyProducts(currentProject.id));
    } catch (error) {}
  };
}
