import React from 'react';
import './AcquisitionHeader.scss';
import { useTranslation } from 'react-i18next';

export const AcquisitionHeader = () => {
  const { t } = useTranslation();
  return (
    <div className="acquisition-header-container">
      <div className="title">{t('acquisitions')}</div>
      <div className="title">{t('type')}</div>
      <div className="title">{t('start_date')}</div>
      <div className="title">{t('end_date')}</div>
      <div className="title">{t('plan_amount')}</div>
      <div className="title">{t('contract_amount')}</div>
      <div className="title">{t('paid_amount')}</div>
      <div className="title">{t('revision')}</div>
      <div className="title">{t('status')}</div>
    </div>
  );
};
