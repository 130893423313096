import { TabValueActionTypes, SET_TAB_VALUE } from '../actions/types';

const initialState = 0;

const tabValueReducer = (state = initialState, action: TabValueActionTypes): number => {
  switch (action.type) {
    case SET_TAB_VALUE:
      return action.payload;
    default:
      return state;
  }
};

export default tabValueReducer;
