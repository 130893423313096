import React from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../../../../services/booleanUtil';
import { getCountProjectValue } from '../Util';

type Props = {
  arrayToCount: any;
  countProjects: boolean;
  totalProjects: number;
  widthStyle?: string;
  textValue: string;
};
export const JustProjectCounter = ({ arrayToCount, countProjects, totalProjects, widthStyle, textValue }: Props) => {
  const { t } = useTranslation();
  return (
    <div></div>
    // <div
    //   className="project-counter-container"
    //   style={isMobile() ? { width: '100%' } : { width: '100%', maxWidth: widthStyle }}
    // >
    //   <div className="values">
    //     <div className="project-value" style={{ lineHeight: '40px' }}>
    //       <span className="capitlaize-text"> {t('total')}</span>:{' '}
    //       {getCountProjectValue(countProjects, totalProjects, arrayToCount)}{' '}
    //       <span className="lowercase-text"> {t(textValue)} </span>
    //     </div>
    //   </div>
    // </div>
  );
};
