import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setFilterIndicators } from '../../../redux/actions/filterIndicators';
import { setFilter } from '../../../redux/actions/filter';
import { Bubble } from '../BubbleElement/BubbleElement';
import { CalledFrom } from '../../../models/CalledFromEnum';
import useFilters from '../../../hooks/useFilters';

type Props = {
  indicatorCall?: boolean;
  calledFrom?: CalledFrom;
};

const handleBubbles = ({ indicatorCall, filterIndicators, filterProjects, filterEarlyWarnings, calledFrom }) => {
  if (calledFrom === CalledFrom.EARLY_WARNINGS) {
    return filterEarlyWarnings.common.teamLeaders;
  }
  return indicatorCall ? filterIndicators.common.teamLeaders : filterProjects.common.teamLeaders;
};

const TeamLeaderBubbles = (props: Props) => {
  const { indicatorCall, calledFrom } = props;
  const dispatch = useDispatch();
  const { setFilterEarlyWarnings, filterEarlyWarnings } = useFilters();
  const filterIndicators = useSelector<any, any>(state => state.filterIndicators);
  const filterProjects = useSelector<any, any>(state => state.filter);

  const bubbles = handleBubbles({ indicatorCall, filterIndicators, filterProjects, filterEarlyWarnings, calledFrom });

  const closeBubble = (divisionId: number) => {
    const actualState = handleBubbles({
      indicatorCall,
      filterIndicators,
      filterProjects,
      filterEarlyWarnings,
      calledFrom,
    });

    const newState = actualState.filter(division => division.id !== divisionId);

    if (calledFrom === CalledFrom.EARLY_WARNINGS) {
      dispatch(
        setFilterEarlyWarnings({
          ...filterEarlyWarnings,
          common: {
            ...filterEarlyWarnings.common,
            teamLeaders: newState,
          },
        }),
      );
    } else {
      if (indicatorCall) {
        dispatch(
          setFilterIndicators({
            ...filterIndicators,
            common: {
              ...filterIndicators.common,
              teamLeaders: newState,
            },
          }),
        );
      } else {
        dispatch(
          setFilter({
            ...filterProjects,
            common: {
              ...filterProjects.common,
              teamLeaders: newState,
            },
          }),
        );
      }
    }
  };

  return (
    <>
      {bubbles.map(teamLeader => (
        <Bubble
          key={teamLeader.id}
          itsHidden={false}
          label={teamLeader.label}
          closeBubble={() => closeBubble(teamLeader.id)}
        />
      ))}
    </>
  );
};

export default TeamLeaderBubbles;
