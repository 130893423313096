import React from 'react';
import './CustomDatePicker.scss';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { range } from 'lodash';
import User from '../../../models/User';

import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';
import pt from 'date-fns/locale/pt-BR';

registerLocale('es', es);
registerLocale('en', en);
registerLocale('pt', pt);

export const getYears = (minDate: any) => {
  const today = new Date();
  return minDate
    ? range(minDate.getFullYear(), minDate.getFullYear() + 11, 1)
    : range(today.getFullYear() - 10, today.getFullYear() + 11, 1);
};
export const months = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

export const SelectedDate = props => {
  const { t } = useTranslation();
  const { value, onClick, small, disabled, withDate, time } = props;
  return (
    <div
      className={`${time ? 'custom-input-time' : 'custom-input'} ${small ? 'small' : ''} ${withDate ? 'with-date' : ''
        } ${value ? '' : 'no-value'} ${disabled ? 'disabled' : ''}`}
      onClick={onClick}
    >
      {value || (time ? '00:00' : t('month_year_day'))}
    </div>
  );
};

export const SelectedDateError = props => {
  const { t } = useTranslation();
  const { value, onClick, small, withDate } = props;
  return (
    <div
      className={`custom-input error ${small ? 'small error' : ''} ${withDate ? 'with-date error' : ''} ${value ? '' : 'no-value'
        }`}
      onClick={onClick}
    >
      {value || t('month_year_day')}
    </div>
  );
};

export const SelectedDateComplete = props => {
  const { t } = useTranslation();
  const { value, onClick, small, disabled, withDate } = props;
  return (
    <div
      className={`custom-input ${small ? 'small' : ''}  ${withDate ? 'with-date' : ''} ${value ? '' : 'no-value'} ${disabled ? 'disabled' : ''
        }`}
      onClick={onClick}
    >
      {value || t('month_year_day')}
    </div>
  );
};

export const SelectedDateCustomPlaceholder = props => {
  const { t } = useTranslation();
  const { value, onClick, small, disabled, withDate, placeholder } = props;

  return (
    <div
      className={`custom-input ${small ? 'small' : ''}  ${withDate ? 'with-date' : ''} ${value ? '' : 'no-value'} ${disabled ? 'disabled' : ''
        }`}
      onClick={onClick}
    >
      {value ? value : t(placeholder)}
    </div>
  );
};

export const customHeaderContainer = params => (
  <div className="custom-header-container">
    {params.prevMonthButtonDisabled ? (
      <span className="nav-button hidden"></span>
    ) : (
      <span className="nav-button back" onClick={params.decreaseMonth}></span>
    )}
    <div className="month-year">
      <div className="month-title">{params.t(months[params.date.getMonth()])}</div>
      <select
        className="select-year"
        value={params.date.getFullYear()}
        onChange={({ target: { value } }) => params.changeYear(value)}
      >
        {params.years.map(option => (
          <option style={{ backgroundColor: '#f5f6fc', color: '$raven' }} key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
    {params.nextMonthButtonDisabled ? (
      <span className="nav-button hidden"></span>
    ) : (
      <span className="nav-button next" onClick={params.increaseMonth}></span>
    )}
  </div>
);

type Props = {
  date?: any;
  label: any;
  disabled?: boolean;
  minDate?: any;
  style?: any;
  setDate: Function;
  error?: boolean;
  placeholderComplete?: boolean;
  small?: boolean;
  maxDate?: any;
  isClearable?: boolean;
  withDate?: boolean;
  filterDate?: any;
  showTimeSelect?: any;
  showTimeSelectOnly?: boolean;
  dateFormat?: any;
  customPlaceholder?: any;
  applyCustomDisabledStyles?: boolean;
};

const CustomDatePicker = (props: Props) => {
  const { t } = useTranslation();
  const inputEl = React.useRef('ref');
  const user = useSelector<any, User>(state => state.user);
  const handleChange = date => {
    props.setDate(date);
  };

  const {
    date,
    label,
    disabled,
    minDate,
    style = { label: {}, customInput: {} },
    error,
    placeholderComplete,
    small,
    maxDate,
    withDate,
    filterDate,
    showTimeSelect,
    showTimeSelectOnly,
    dateFormat,
    customPlaceholder,
    applyCustomDisabledStyles,
  } = props;
  const today = new Date();
  const years = getYears(minDate);

  return (
    <div style={style.container} className="custom-date-picker-container">
      <p className="label">
        {label}
      </p>
      <DatePicker
        placeholderText={customPlaceholder}
        customInput={
          customPlaceholder ? (
            <SelectedDateCustomPlaceholder small={small} disabled={disabled} withDate={withDate} />
          ) : error ? (
            <SelectedDateError small={small} withDate={withDate} time={showTimeSelect && showTimeSelectOnly} />
          ) : placeholderComplete ? (
            <SelectedDateComplete
              small={small}
              disabled={disabled}
              withDate={withDate}
              time={showTimeSelect && showTimeSelectOnly}
            />
          ) : (
            <SelectedDate
              small={small}
              disabled={disabled}
              withDate={withDate}
              time={showTimeSelect && showTimeSelectOnly}
            />
          )
        }
        customInputRef={inputEl}
        selected={date}
        onChange={handleChange}
        dateFormat={dateFormat || 'dd-MMM-yyyy'}
        minDate={minDate !== undefined ? minDate : today}
        disabled={disabled}
        locale={user.language && user.language.encodedName ? user.language.encodedName : 'en'}
        maxDate={maxDate}
        {...props}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) =>
          customHeaderContainer({
            prevMonthButtonDisabled,
            decreaseMonth,
            date,
            changeYear,
            years,
            nextMonthButtonDisabled,
            increaseMonth,
            t,
          })
        }
        filterDate={filterDate}
        showTimeSelect={showTimeSelect}
        showTimeSelectOnly={showTimeSelectOnly}
        className="custom-date-picker"
        dayClassName={date =>
          applyCustomDisabledStyles && date < new Date().setHours(0, 0, 0, 0)
            ? "react-datepicker__day--today"
            : undefined
        }
      />
    </div>
  );
};


export default CustomDatePicker;
