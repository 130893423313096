import React from 'react';
import './CustomizableReportFilters.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  filterCountriesOnRegionsSelect,
  handleHideRegions,
  handleShowCountries,
} from '../../../../Filter/RegionCountryFilter/RegionCountryFilter';
import { showCountryFilterStates } from '../../../../FilterV2/RegionCountryFilter/models';
import { CalledFrom } from '../../../../../models/CalledFromEnum';
import { sortAlphabetically } from '../../../../../services/sortsUtil';
import SelectOption from '../../../../../models/SelectOption';
import FilterIndicators from '../../../../../models/FilterIndicators';
import Region from '../../../../../models/Region';
import Filter from '../../../../../models/Filter';
import { updateFilterToDrawProperties } from '../Steps/FilterReport/Util';
import { useCustomizableReportsContext } from '../CustomizableReportsContext';
import { buildFilters, FilterCheckbox, renderFilter } from '../CustomizableReportsHelper';
import Country from '../../../../../models/Country';
import ProjectTypeFilterCheckbox from '../../../../Filter/ProjectTypeFilterCheckbox/ProjectTypeFilterCheckbox';
import {
  handleCheckChanges,
  igrIsDisabled,
  showIgrSubFilters,
} from '../../../../Filter/ProjectTypeFilter/UtilFunctions';
import { usePrevious } from '../../../../../hooks/usePrevious';
import { ProjectProductTypeFilters } from '../../../../../models/ProjectProductTypeFilters';
import { PmrClassification } from '../../../../../models/PmrClassification';

export const CustomizableReportFilters = () => {
  const { t } = useTranslation();
  const regionsState = useSelector<any, any>(state => state.regions);
  const [filters, setFilters] = React.useState<FilterCheckbox[]>([]);
  const [regionState, setRegionState] = React.useState<SelectOption[]>([]);
  const [countriesState, setCountriesState] = React.useState<SelectOption[]>([]);
  const [allCountries, setAllCountries] = React.useState<SelectOption[]>([]);
  const [context, setContext] = useCustomizableReportsContext();
  const menuTabs = useSelector<any, any>(state => state.menuTabs);
  const [hideRegions] = React.useState(handleHideRegions(CalledFrom.PORTFOLIO, menuTabs));
  const [showCountries] = React.useState(handleShowCountries(CalledFrom.PORTFOLIO, menuTabs));
  const [reportFilter, setReportFilter] = React.useState<FilterIndicators>(context.reportFilters);
  const [filtersToDraw] = React.useState(
    updateFilterToDrawProperties(context.reportFilters.common, context.reportFilters),
  );
  const regions = useSelector<any, Region[]>(state => state.regions);
  const prevProjectTypeFilterState = usePrevious(reportFilter.common.projectTypeFilters);

  React.useEffect(() => {
    setInitialStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    buildFilters({
      filter: filtersToDraw.common,
      reportFilter,
      handleReportFilterChange,
      countriesState,
      filterCountriesOnSelect,
      regionState,
      hideRegions,
      showCountries,
      setFilters,
      t,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countriesState]);

  React.useEffect(() => {
    if (prevProjectTypeFilterState !== reportFilter.common.projectTypeFilters) {
      buildFilters({
        filter: filtersToDraw.common,
        reportFilter,
        handleReportFilterChange,
        countriesState,
        filterCountriesOnSelect,
        regionState,
        hideRegions,
        showCountries,
        setFilters,
        t,
      });
    }
    updateSelectedFilters(filters, reportFilter, selectedReportFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, reportFilter]);

  const addValueToCountriesOption = (countriesOptions, country) =>
    countriesOptions.push({
      id: country.id,
      label: country.name,
      value: country.name,
      categoryId: country.regionId,
    });

  const filterCountriesOnSelect = regions => {
    filterCountriesOnRegionsSelect(regions, allCountries, countries => setCountriesState(countries), regionsState);
  };

  const sortCountries = (countriesList: SelectOption[]) => {
    return countriesList.sort(sortAlphabetically('value'));
  };

  const setInitialStates = () => {
    const countriesOptions: SelectOption[] = [];

    if (showCountries === showCountryFilterStates.ShowComplete) {
      const regionsOptions: SelectOption[] = [];

      regions.forEach(region => {
        regionsOptions.push({ id: region.id, label: region.acronym, value: region.acronym });
        region.countries.forEach(country => {
          addValueToCountriesOption(countriesOptions, country);
        });
      });

      setRegionState(regionsOptions);
      setCountriesState(sortCountries(countriesOptions));
      setAllCountries(sortCountries(countriesOptions));
    } else if (showCountries === showCountryFilterStates.ShowByRegion) {
      const selected = menuTabs.portfolioSelectedTab;
      const countriesOfRegion = regions
        .filter(r => selected.regions.includes(r.id))
        .reduce((a: Country[], b) => [...a, ...b.countries], []);

      if (!countriesOfRegion) {
        setCountriesState([]);
      }

      countriesOfRegion.forEach(country => {
        addValueToCountriesOption(countriesOptions, country);
      });

      setCountriesState(sortCountries(countriesOptions));
    }

    buildFilters({
      filter: filtersToDraw.common,
      reportFilter,
      handleReportFilterChange,
      countriesState,
      filterCountriesOnSelect,
      regionState,
      hideRegions,
      showCountries,
      setFilters,
      t,
    });
  };

  const handleReportFilterChange = (newReportFilter: Partial<Filter>) => {
    setReportFilter(prevReportFilter => ({
      ...prevReportFilter,
      common: {
        ...prevReportFilter.common,
        ...newReportFilter,
      },
    }));
  };

  const selectedReportFilters = (newReportFilter: Partial<Filter>) => {
    setContext({
      ...context,
      reportFilters: {
        ...reportFilter,
        common: {
          ...reportFilter.common,
        },
      },
    });
  };

  const handleChangesProjectTypeFilter = checkboxData => {
    const { projectProductType } = checkboxData;
    const updateReportFilter = [...reportFilter.common.projectTypeFilters];
    const setterFunction = (newFilters, filterState) => {
      if (newFilters.length !== filterState.length) {
        handleReportFilterChange(handleSpecifcProjectProductType(newFilters, filtersToDraw));
        selectedReportFilters(handleSpecifcProjectProductType(newFilters, filtersToDraw));
      }
    };
    handleCheckChanges(updateReportFilter, projectProductType, setterFunction);
  };

  return (
    <div className="customizable-reports-filter-container">
      <ProjectTypeFilterCheckbox
        handleChanges={checkboxData => handleChangesProjectTypeFilter(checkboxData)}
        filterState={reportFilter.common.projectTypeFilters}
        igrIsDisabled={() => igrIsDisabled(reportFilter.common.projectTypeFilters)}
        showIgrSubFilters={() => showIgrSubFilters(reportFilter.common.projectTypeFilters)}
        onModalReports={true}
      />

      {filters.filter(f => f.alreadyApplied).length > 0 && (
        <p className="subtitle">{t('customizable_report_step_2_applied_filters')}</p>
      )}
      {filters.filter(f => f.alreadyApplied).map(f => renderFilter(f))}

      {filters.filter(f => !f.alreadyApplied).length > 0 && (
        <p className="subtitle">{t('customizable_report_step_2_other_filters')}</p>
      )}
      {filters.filter(f => !f.alreadyApplied).map(f => renderFilter(f))}
    </div>
  );
};

const conditionalNumbers = (checked: boolean, value: number) => (checked ? value : 0);
const conditionalArray = (checked: boolean, value: SelectOption[]) => (checked ? value : []);

const updateSelectedFilters = (
  selectedFilters: FilterCheckbox[],
  reportFilter: FilterIndicators,
  selectedReportFilters: Function,
) => {
  selectedFilters.forEach(selectedFilter => {
    switch (selectedFilter.id) {
      case 'division':
        selectedReportFilters({
          divisionId: conditionalArray(selectedFilter.checked, reportFilter.common.divisionId),
        });
        break;
      case 'pmr':
        selectedReportFilters({
          pmrId: selectedFilter.checked
            ? reportFilter.common.pmrId
            : reportFilter.common.pmrId.map(pmr => ({ ...pmr, selected: false })),
        });
        break;
      case 'timeWithoutDisbursement':
        selectedReportFilters({
          timeWithoutDisbursementFrom: conditionalNumbers(
            selectedFilter.checked,
            reportFilter.common.timeWithoutDisbursementFrom,
          ),
          timeWithoutDisbursementTo: conditionalNumbers(
            selectedFilter.checked,
            reportFilter.common.timeWithoutDisbursementTo,
          ),
        });
        break;
      case 'ageProjects':
        selectedReportFilters({
          ageProjectFrom: conditionalNumbers(selectedFilter.checked, reportFilter.common.ageProjectFrom),
          ageProjectTo: conditionalNumbers(selectedFilter.checked, reportFilter.common.ageProjectTo),
          ageProjectMonths: selectedFilter.checked ? reportFilter.common.ageProjectMonths : false,
        });
        break;
      case 'disbursementProjection':
        selectedReportFilters({
          disbursementProjectionFrom: conditionalNumbers(
            selectedFilter.checked,
            reportFilter.common.disbursementProjectionFrom,
          ),
          disbursementProjectionTo: conditionalNumbers(
            selectedFilter.checked,
            reportFilter.common.disbursementProjectionTo,
          ),
        });
        break;
      case 'country':
        selectedReportFilters({
          countryId: conditionalArray(selectedFilter.checked, reportFilter.common.countryId),
        });
        break;
      case 'region':
        selectedReportFilters({
          regionId: conditionalArray(selectedFilter.checked, reportFilter.common.regionId),
        });
        break;
      default:
        break;
    }
  });
};

const handleSpecifcProjectProductType = (newFilters, filtersToDraw) => {
  let returnedValue: any = { projectTypeFilters: newFilters };
  const justCTProjectTypeFilterSelected = newFilters.length === 1 && newFilters[0] === ProjectProductTypeFilters.CT;
  const justLoanProjectTypeFilterSelected = newFilters.length === 1 && newFilters[0] === ProjectProductTypeFilters.Loan;

  if (!justCTProjectTypeFilterSelected) {
    returnedValue = { ...returnedValue, taxonomyFilter: null, executionFilter: null };
  } else {
    const originalTaxonomy = { ...filtersToDraw.common.taxonomyFilter };
    const originalExecutionFilter = { ...filtersToDraw.common.executionFilter };
    delete originalTaxonomy.drawSelected;
    delete originalTaxonomy.selected;
    delete originalExecutionFilter.drawSelected;
    delete originalExecutionFilter.selected;
    returnedValue = {
      ...returnedValue,
      taxonomyFilter: originalTaxonomy,
      executionFilter: originalExecutionFilter,
    };
  }

  if (!justLoanProjectTypeFilterSelected) {
    returnedValue = {
      ...returnedValue,
      pmrId: [
        { PmrClassification: PmrClassification.Green, selected: false },
        { PmrClassification: PmrClassification.Yellow, selected: false },
        { PmrClassification: PmrClassification.Red, selected: false },
        { PmrClassification: PmrClassification.Na, selected: false },
      ],
      disbursementProjectionFrom: 0,
      disbursementProjectionTo: 0,
    };
  } else {
    const originalPmrId = [...filtersToDraw.common.pmrId];
    const originalDisbursementProjectionFrom = filtersToDraw.common.disbursementProjectionFrom;
    const originalDisbursementProjectionTo = filtersToDraw.common.disbursementProjectionTo;
    originalPmrId.forEach(oPmr => oPmr.drawSelected && delete oPmr.drawSelected);

    returnedValue = {
      ...returnedValue,
      pmrId: originalPmrId,
      disbursementProjectionFrom: originalDisbursementProjectionFrom,
      disbursementProjectionTo: originalDisbursementProjectionTo,
    };
  }

  return returnedValue;
};
