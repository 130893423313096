import { buildPost, buildGet, buildDelete, buildPut } from './genericMethods';
import * as config from './config.json';
import { axiosDefaultConfig } from './axiosConfig';

const commentIdReplaceValue = '{commentId}';
const supervisionPlanItemIdReplaceValue = '{supervisionPlanItemId}';
const eventIdReplaceValue = '{eventId}';
const eventTypeReplaceValue = '{eventType}';

export const getYears = (projectId: number) => {
  const url = `${config.SUPERVISION_PLAN_YEARS}?supervisionPlanId=${projectId}`;
  return buildGet(url);
};

export const getSupervisionInfo = (
  projectId,
  year,
  searchTerm,
  pageNumber,
  sortCriteria,
  orderDesc,
  itemsPerPage = 7,
) => {
  const selectedYear = year && year.value ? parseInt(year.value) : new Date().getFullYear();
  const data = {
    year: selectedYear,
    projectId,
    sortCriteria,
    searchTerm,
    pageNumber,
    itemsPerPage,
    orderDesc,
  };

  return new Promise<any>(async (resolve, reject) => {
    try {
      const response = await axiosDefaultConfig.post(config.SUPERVISION_PLAN, data);
      if (response && response.data) {
        resolve(response.data);
      } else if (response && response.status === 204) {
        resolve(null);
      } else {
        resolve(true);
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const newActivityComment = (activityId: number, comment: string) => {
  const url = config.SUPERVISION_PLAN_ADD_COMMENT.replace(supervisionPlanItemIdReplaceValue, activityId.toString());

  return buildPost(url, { comment });
};

export const deleteActivityComment = (commentId: string) => {
  const url = config.SUPERVISION_PLAN_DELETE_COMMENT.replace(commentIdReplaceValue, commentId.toString());

  return buildDelete(url);
};

export const updateActivity = (data: any) => {
  const url = config.SUPERVISION_PLAN;

  return buildPut(url, data);
};

export const deleteAlert = (eventId: number, eventType: number) => {
  const url = config.DELETE_SUPERVISION_ACTIVITY_ALERT.replace(eventIdReplaceValue, eventId.toString()).replace(
    eventTypeReplaceValue,
    eventType.toString(),
  );

  return buildDelete(url);
};

export const getActivityEarlyWarningRelatedList = () => {
  const url = config.GET_RELATED_EARLY_WARNING_LIST;
  return new Promise<any>(async (resolve, reject) => {
    try {
      const response = await axiosDefaultConfig.get(url);
      if (response && response.data) {
        resolve([...response.data, { id: 0, code: 'none', description: 'none_fem' }]);
      } else {
        resolve(true);
      }
    } catch (error) {
      reject(error);
    }
  });
};
