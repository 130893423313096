import { SET_FOLLOWED_PROJECTS } from './types';
import Project from '../../models/Project';
import { getFollowedProjects, setFollowUnfollowProject } from '../../api/projectApi';
import { setAllProjects } from './allProjects';

export function setFollowedProjects(data: Project[]) {
  return {
    type: SET_FOLLOWED_PROJECTS,
    payload: data,
  };
}

export function getFollowedProjectsFromApi() {
  return async (dispatch: any) => {
    try {
      const response = await getFollowedProjects();
      dispatch(setFollowedProjects([...response]));
    } catch (error) {}
  };
}

export function getFollowUnfollowProjectFromApi(id: number, callFromFollowed: boolean) {
  return async (dispatch: any, getState: any) => {
    try {
      await setFollowUnfollowProject(id).then(async status => {
        if (status === 200) {
          if (callFromFollowed) {
            dispatch(getFollowedProjectsFromApi());
            const allProjects = [...getState().allProjects];
            const updateFollowStatus = allProjects.find(project => project.id === id);
            if (updateFollowStatus !== undefined) {
              updateFollowStatus.inFollow = !updateFollowStatus.inFollow;
              dispatch(setAllProjects([...allProjects]));
            }
          } else {
            const allProjects = [...getState().allProjects];
            const updateFollowStatus = allProjects.find(project => project.id === id);
            updateFollowStatus.inFollow = !updateFollowStatus.inFollow;
            dispatch(setAllProjects([...allProjects]));
            dispatch(getFollowedProjectsFromApi());
          }
        }
      });
    } catch (error) {}
  };
}
