import React from 'react';
import './Comments.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import Comment from '../../../models/Comment';
import User from '../../../models/User';
import CommentInfo from './CommentInfo';
import AccordanceButton from '../Buttons/AccordanceButton/AccordanceButton';
import { sortDates } from '../../../services/sortsUtil';
import { ActionType } from '../../Reviews/CommentsAlerts/CommentsAlerts';

const initialCommentsLength = 3;

export const listWithDeletedItem = (list: any[], commentId: number) => {
  return list.map(item => {
    if (item.id === commentId) {
      item.isDeleted = true;
      item.comment = '';
    }
    return item;
  });
};

type Props = {
  comments: Comment[];
  readonly?: boolean;
  submitComment: Function;
  submitDeleteComment: Function;
  updateParent?: Function;
  canDeleteComments?: boolean;
};

const Comments = (props: Props) => {
  const { comments, readonly, submitComment, submitDeleteComment, updateParent, canDeleteComments } = props;
  const { t } = useTranslation();
  const [comment, setComment] = React.useState('');
  const [visibleComments, setVisibleComments] = React.useState<any[]>([]);
  const [allComments, setAllComments] = React.useState<any[]>([]);
  const user = useSelector<any, User>(state => state.user);

  const handleComment = value => {
    setComment(value);
  };

  React.useEffect(() => {
    const allSortedComments = [...comments].sort(sortDates('date', 'desc'));
    setAllComments(allSortedComments);
    setVisibleComments(allSortedComments.filter((item, index) => index < initialCommentsLength));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comments]);

  const showMoreComments = () => {
    setVisibleComments(allComments.filter((item, index) => index < visibleComments.length + initialCommentsLength));
  };

  const updateAfterNewComment = response => {
    const newAlldata = [...allComments];
    newAlldata.unshift(response);
    setAllComments(newAlldata);
    const newVisibleData = [...visibleComments];
    newVisibleData.unshift(response);
    setVisibleComments(newVisibleData);
    setComment('');
  };

  const updateAfterRemovingComment = commentId => {
    const newAlldata = listWithDeletedItem(allComments, commentId);
    const newVisibleData = listWithDeletedItem(visibleComments, commentId);
    setAllComments(newAlldata);
    setVisibleComments(newVisibleData);
  };

  const publishComment = async () => {
    const response = await submitComment(comment);
    if (!response.author) {
      response.author = cloneDeep(user);
    }
    updateAfterNewComment(response);
    if (updateParent) {
      updateParent(ActionType.Create, response);
    }
  };

  const removeComment = async (commentId: number) => {
    await submitDeleteComment(commentId);
    updateAfterRemovingComment(commentId);
    if (updateParent) {
      updateParent(ActionType.Remove, commentId);
    }
  };

  return (
    <div className="accordance-detail-comments-container">
      <span className="label">
        <div className="icon-label-comments"></div>
        {t('comments')}
        {/* {` (${allComments.filter(item => !item.isDeleted).length})`} */}
      </span>
      {visibleComments &&
        visibleComments.length > 0 &&
        visibleComments.map(comment => {
          return (
            <CommentInfo
              id={comment.id}
              accordanceComment={comment}
              key={comment.id.toString()}
              onClickRemove={removeComment}
              canDelete={canDeleteComments}
            />
          );
        })}
      {visibleComments.length < allComments.length && (
        <span className="more" onClick={showMoreComments}>{`[+] ${t('see_older_comments')}`}</span>
      )}
      {!readonly && (
        <>
          <textarea
            value={comment}
            onChange={e => handleComment(e.target.value)}
            placeholder={t('write_comment')}
          ></textarea>
          <AccordanceButton
            handleClick={publishComment}
            customLabel={t('publish')}
            disabled={!comment.trim()}
            customStyle={{ alignSelf: 'flex-end', marginTop: 14, color: '#424D64' }}
          />
        </>
      )}
    </div>
  );
};

export default Comments;
