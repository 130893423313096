import { ConsultanciesTypeEnum } from '../models/ConsultanciesTypeEnum';

export const MOCK_PROJECT_CONSULTANCIES_DATA = [
  {
    type: ConsultanciesTypeEnum.CONSULTANCIES_IN_THE_HIRING_PROCESS,
    title: 'management_consulting_group_0',
    header1: 'name_description',
    header2: 'ID',
    header3: 'budget',
    header4: 'expected_start_date',
    header5: 'status',
    header6: 'last_change_of_state',
    header7: 'assigned_to',
    consultancies: [
      {
        field1:
          'A - Lorem ipsum dolor sed do sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
        field2: '3463',
        field3: 3000,
        field4: '2019-06-03',
        field5: 0,
        field6: '2020-06-15',
        field7: 'Ana Karina Weiss',
        field8: null,
      },
      {
        field1:
          'B - Lorem ipsum dolor sed do sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
        field2: '9634',
        field3: 5600,
        field4: '2019-06-03',
        field5: 0,
        field6: '2021-07-01',
        field7: 'Clara Karina Weiss',
        field8: null,
      },
      {
        field1:
          'C - Lorem ipsum dolor sed do sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
        field2: '4734',
        field3: 3400,
        field4: '2019-06-03',
        field5: 0,
        field6: '2020-12-05',
        field7: 'Natalia Karina Weiss',
        field8: null,
      },
      {
        field1:
          'D - Lorem ipsum dolor sed do sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
        field2: '6865',
        field3: 7600,
        field4: '2020-06-03',
        field5: 0,
        field6: '2021-08-25',
        field7: 'Carla Karina Weiss',
        field8: null,
      },
    ],
  },
  {
    type: ConsultanciesTypeEnum.CONSULTANCIES_IN_EXECUTION,
    title: 'management_consulting_group_1',
    header1: 'name_description',
    header2: 'transaction_number',
    header3: 'start_date',
    header4: 'end_date',
    header5: 'total_amount',
    header6: 'paid_amount',
    header7: 'payments_made',
    consultancies: [
      {
        field1:
          'Lorem ipsum dolor sed do sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
        field2: '####',
        field3: '2019-06-03',
        field4: '2019-06-03',
        field5: 1500,
        field6: 30000,
        field7: '4/10',
        field8: 'USD',
      },
      {
        field1:
          'Lorem ipsum dolor sed do sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
        field2: '####',
        field3: '2019-06-03',
        field4: '2019-06-03',
        field5: 1500,
        field6: 30000,
        field7: '4/10',
        field8: 'USD',
      },
      {
        field1:
          'Lorem ipsum dolor sed do sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
        field2: '####',
        field3: '2019-06-03',
        field4: '2019-06-03',
        field5: 1500,
        field6: 30000,
        field7: '4/10',
        field8: 'USD',
      },
      {
        field1:
          'Lorem ipsum dolor sed do sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
        field2: '####',
        field3: '2019-06-03',
        field4: '2019-06-03',
        field5: 1500,
        field6: 30000,
        field7: '4/10',
        field8: 'USD',
      },
      {
        field1:
          'Lorem ipsum dolor sed do sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
        field2: '####',
        field3: '2019-06-03',
        field4: '2019-06-03',
        field5: 1500,
        field6: 30000,
        field7: '4/10',
        field8: 'USD',
      },
    ],
  },
];
