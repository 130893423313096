import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { ReactComponent as IconSort } from '../../../../../assets/icons/u_sort.svg';
import { useTranslation } from 'react-i18next';
import './ModalPcrAccordances.scss';
import { Link } from 'react-router-dom';
import { renderDateOrSlash } from '../../../../../services/stringUtil';
import { CriteriaType } from '../../../../../models/PaginationIndicator';
import usePaginationSort from '../../../../../hooks/usePaginationSort';
import { sortTablesByType } from '../../../../../services/sortsUtil';

const accordanceStateNames = {
  0: 'draft_accordance',
  1: 'pending_accordance_singular',
  2: 'active_accordance_singular',
  3: 'accomplished_accordance_singular',
  4: 'cancelled_accordance_singular',
};

const TableModalPcrAccordances = ({ accordancesData }) => {
  const { t } = useTranslation();
  const [sortAsc, setSortAsc] = React.useState(true);

  const sortTable = (keyToSort, fieldType) => {
    sortTablesByType(accordancesData, sortAsc, keyToSort, fieldType);
    setSortAsc(!sortAsc);
  };

  return (
    <>
      <div className="custom-table">
        {accordancesData[0]?.idProjectDetail !== 0 ? (
          <p className="accordancesLink">
            {t('accordances').toUpperCase()}:{' '}
            <Link to={`/projectdetail?id=${accordancesData[0]?.projectId}`}>{t('see_project_details')}</Link>
          </p>
        ) : null}
        <div className="borderTable">
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell onClick={() => sortTable('description', 'string')}>
                    <div className="text-head">
                      <div>{`${t('accordance_description')}`} </div>
                      <div>
                        <IconSort className="sortIcon" />
                      </div>
                    </div>
                  </TableCell>
                  <TableCell align="center" onClick={() => sortTable('name', 'string')}>
                    <div className="text-head">
                      <div>{`${t('type_of_accordance')}`} </div>
                      <div>
                        <IconSort className="sortIcon" />
                      </div>
                    </div>
                  </TableCell>
                  <TableCell align="center" onClick={() => sortTable('expirationDate', 'string')}>
                    <div className="text-head">
                      <div>{`${t('expiration')}`} </div>
                      <div>
                        <IconSort className="sortIcon" />
                      </div>
                    </div>
                  </TableCell>
                  <TableCell align="center" onClick={() => sortTable('accordanceState', 'string')}>
                    <div className="text-head">
                      <div>{`${t('state')}`} </div>
                      <div>
                        <IconSort className="sortIcon" />
                      </div>
                    </div>
                  </TableCell>
                  <TableCell align="center" onClick={() => sortTable('comment', 'string')}>
                    <div className="text-head">
                      <div>{`${t('last_comment')}`} </div>
                      <div>
                        <IconSort className="sortIcon" />
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {accordancesData?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className="sizeCell">{row.description}</TableCell>
                    <TableCell align="center">{row.accordanceAgreementsType.name}</TableCell>
                    <TableCell align="center">{renderDateOrSlash(row.expirationDate)}</TableCell>
                    <TableCell align="center">{t(accordanceStateNames[row.accordanceState])}</TableCell>
                    <TableCell align="center">{row.lastComment.comment}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export default TableModalPcrAccordances;
