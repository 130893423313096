import React from 'react';
import styles from './Header.module.scss';
import { useTranslation } from 'react-i18next';
import { orderList } from '../../Recommended/util';

type Props = {
  editMode: boolean;
  handleClick?: any;
};

const Header = ({ editMode }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={`${styles.container} header-title`}>
      {orderList.map(item => (
        <div className={`${styles.listItem} item`} key={item.langKey}>
          {editMode ? (
            <div className={styles.text}>{t(item.langKey)}</div>
          ) : (
            <div className={styles.clickableText}>
              {t(item.langKey)}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Header;
