import { SET_FILTER_ANALYTICS } from './types';
import FilterAnalytics from '../../models/FilterAnalytics';

export function setFilterAnalytics(filterAnalytics: FilterAnalytics) {
    return {
        type: SET_FILTER_ANALYTICS,
        payload: filterAnalytics
    }
}

export const dispatchFilterAnalyticsState = (filterAnalytics, value) => {

    return (dispatch: any) => {
        dispatch(setFilterAnalytics({
            ...filterAnalytics,
            dataPeriod: value
        }))
    }
}
