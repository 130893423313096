export enum IndicatorKeyEnum {
  PMR_PERFORMANCE_LAST_CLOSED_YEAR = 'PMR_PERFORMANCE_LAST_CLOSED_YEAR',
  PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS_BY_DIVISION = 'PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS_BY_DIVISION',
  MONTHLY_DISBURSEMENT_PROJECTION_CURRENT_YEAR_INCLUDING_REGIONALS = 'MONTHLY_DISBURSEMENT_PROJECTION_CURRENT_YEAR_INCLUDING_REGIONALS',
  ACCORDANCES_WAITING_APPROVAL = 'ACCORDANCES_WAITING_APPROVAL',
  ACCORDANCES_EXPIRING = 'ACCORDANCES_EXPIRING',
  PROJECTS_WITH_TRANSACTIONAL_BUDGET_DEFICIT = 'PROJECTS_WITH_TRANSACTIONAL_BUDGET_DEFICIT',
  PROJECTS_IN_EXECUTION_WITH_MICI_OPEN_CASES = 'PROJECTS_IN_EXECUTION_WITH_MICI_OPEN_CASES',
  PROJECTS_PENDING_SIGNATURE_OR_LEGISLATIVE_APPROVAL = 'PROJECTS_PENDING_SIGNATURE_OR_LEGISLATIVE_APPROVAL',
  PROJECTS_PENDING_DISBURSEMENT_ELIGIBILITY = 'PROJECTS_PENDING_DISBURSEMENT_ELIGIBILITY',
  PROJECTS_OVERALL_STAGE_CODE_DISBURSING_NO_DISBURSEMENTS_OVER_180_DAYS = 'PROJECTS_OVERALL_STAGE_CODE_DISBURSING_NO_DISBURSEMENTS_OVER_180_DAYS',
  PROJECTS_EXTENDED_NOT_FULLY_DISBURSED = 'PROJECTS_EXTENDED_NOT_FULLY_DISBURSED',
  DISBURSEMENT_PROJECTION_CURRENT_YEAR_INCLUDING_REGIONALS = 'DISBURSEMENT_PROJECTION_CURRENT_YEAR_INCLUDING_REGIONALS',
  PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS = 'PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS',
  PROJECTS_SUMMARY_PORTFOLIO = 'PROJECTS_SUMMARY_PORTFOLIO',
  PMR_STATE_DISTRIBUTION = 'PMR_STATE_DISTRIBUTION',
  PROJECTS_LAST_DISBURSEMENT_DATE_CLOSE_12MONTHS_OR_EXPIRED = 'PROJECTS_LAST_DISBURSEMENT_DATE_CLOSE_12MONTHS_OR_EXPIRED',
  USER_PENDING_TASKS = 'USER_PENDING_TASKS',
  DISTRIBUTION_ACCORDING_TO_LATEST_EFAS = 'DISTRIBUTION_ACCORDING_TO_LATEST_EFAS',
  ESG_DISTRIBUTION_ACCORDING_TO_ENVIRONMENTAL_AND_SOCIAL_CATEGORY = 'ESG_DISTRIBUTION_ACCORDING_TO_ENVIRONMENTAL_AND_SOCIAL_CATEGORY',
  ESG_SAFEGUARD_PERFORMANCE_RATING = 'ESG_SAFEGUARD_PERFORMANCE_RATING',
  ESG_ENVIRONMENTAL_AND_SOCIAL_RISK_RATING = 'ESG_ENVIRONMENTAL_AND_SOCIAL_RISK_RATING',
  PROJECTS_KNOWLEDGE_PRODUCTS = 'PROJECTS_KNOWLEDGE_PRODUCTS',
  CALENDAR_EVENTS = 'CALENDAR_EVENTS',
  AVERAGE_LAG = 'AVERAGE_LAG',
  TRANSACTIONAL_SUPERVISION_RESOURCES = 'TRANSACTIONAL_SUPERVISION_RESOURCES',
  SUPERVISION_PLAN_PLANNED_VERSUS_EXECUTED = 'SUPERVISION_PLAN_PLANNED_VERSUS_EXECUTED',
  SUPERVISION_PLAN_BUDGET_STATUS = 'SUPERVISION_PLAN_BUDGET_STATUS',
  GCM_ALERTS = 'GCM_ALERTS',
  TCP_PERFORMANCE = 'TCP_PERFORMANCE',
  PROJECTS_IN_PREPARATION = 'PROJECTS_IN_PREPARATION',
  DISBURSEMENT_OVER_TIME = 'DISBURSEMENT_OVER_TIME',
  BID_CONSULTANCY = 'BID_CONSULTANCY',
  DISBURSEMENT_PROJECTION_LAST_5_YEARS = 'DISBURSEMENT_PROJECTION_LAST_5_YEARS',
  PMR_VALIDATION_STATE = 'PMR_VALIDATION_STATE',
  PORTFOLIO_OVERVIEW_LAST_5_YEARS = 'PORTFOLIO_OVERVIEW_LAST_5_YEARS',
  PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS_BY_COUNTRY = 'PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS_BY_COUNTRY',
  PMR_EVOLUTION_LAST_5_YEARS = 'PMR_EVOLUTION_LAST_5_YEARS',
  PCR_NEXT_PRESENTATION = 'PCR_NEXT_PRESENTATION',
  PMR_ALERT_PROBLEM_LAST_3_YEARS = 'PMR_ALERT_PROBLEM_LAST_3_YEARS',
  PROJECTS_ELIGIBLE_FIRST_UNDISBURSEMENT = 'PROJECTS_ELIGIBLE_FIRST_UNDISBURSEMENT',
  PROJECTS_PARTIAL_CANCELLATIONS = 'PROJECTS_PARTIAL_CANCELLATIONS',
  ACQUISITION_WITH_MILESTONES_PENDING_TO_DATE='ACQUISITION_WITH_MILESTONES_PENDING_TO_DATE',
  CLASSIFICATION_OF_ACQUISITION_PLANS='CLASSIFICATION_OF_ACQUISITION_PLANS'
}
