import {
  TranslationStatusKeysOnLikelyToBeSubmittedTable,
  TranslationStatusKeysOnUpcomingSubmissionTable,
} from '../../../models/PcrNextPresentation';

const renderUpcomingSubmissionPcrStatus = (value: string) => {
  const valueParsedNumber = parseInt(value);
  if (!valueParsedNumber || valueParsedNumber > 3 || valueParsedNumber < 1) {
    return '-';
  }

  return TranslationStatusKeysOnUpcomingSubmissionTable[valueParsedNumber];
};

const renderLikeyToBeSubmittedPcrStatus = (value: string) => {
  const valueParsedNumber = parseInt(value);
  if (!valueParsedNumber || valueParsedNumber > 6 || valueParsedNumber < 4) {
    return '-';
  }

  return TranslationStatusKeysOnLikelyToBeSubmittedTable[valueParsedNumber];
};

const percentageFormat = value => {
  const valueParsed = parseFloat(value);
  if (!valueParsed) {
    return '-';
  }
  return `${valueParsed} %`;
};

const dueDate = (date: Date): boolean => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  date.setHours(0, 0, 0, 0);
  return date <= today;
};

const betweenMonthToDueDate = (date: Date): boolean => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  date.setHours(0, 0, 0, 0);
  const oneMonthAfterToday = new Date();
  oneMonthAfterToday.setMonth(oneMonthAfterToday.getMonth() + 1);
  oneMonthAfterToday.setHours(0, 0, 0, 0);
  return date <= oneMonthAfterToday;
};

const formatMethodology = (stringValue: string | null) => {
  if (stringValue === null) {
    return '-';
  }
  const splitValue = stringValue.split('PCR_METH_');
  return splitValue[1];
};

export {
  dueDate,
  betweenMonthToDueDate,
  formatMethodology,
  renderUpcomingSubmissionPcrStatus,
  percentageFormat,
  renderLikeyToBeSubmittedPcrStatus,
};
