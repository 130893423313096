import React, { useEffect } from 'react';
import { tableRowTypeKeys, dataTypes } from '../ContentManagerKeys';
import ContentManagerModal from '../ContentManagerModal/ContentManagerModal';
import ContentManagerTable from '../ContentManagerTable/ContentManagerTable';
import MessagesFormModal from './MessagesFormModal/MessagesFormModal';
import { useTranslation } from 'react-i18next';
import { getSystemMessageListItem, defaultPaginationData } from '../../../api/contentManager';
import { getMappedItems, hasRecords, contentsTabLoaderStyles } from '../../Util/ContentManager/ContentManager';
import ContentManagerLoader from '../ContentManagerLoader/ContentManagerLoader';

export const Messages = ({ getLanguageTranslationFromKey, displayGenericErrorMessage, languagesData }) => {
  const { t } = useTranslation();
  const [showNewMessageModal, setNewMessageModal] = React.useState(false);
  const [messagesList, setMessagesList] = React.useState<any>();
  const [loading, setLoading] = React.useState(true);

  const getSystemMessages = async (paginationData = defaultPaginationData) => {
    const messagesListsResponse = await getSystemMessageListItem(paginationData);
    setMessagesList(messagesListsResponse);
  };

  useEffect(() => {
    if (loading) {
      hasRecords(messagesList) || getSystemMessages();
    }

    if (hasRecords(messagesList)) {
      setLoading(false);
    }
  }, [messagesList, loading]);

  return loading ? (
    <ContentManagerLoader styles={contentsTabLoaderStyles} />
  ) : (
    <div className="content-manager__container">
      <div className="content-manager__container__title">{t('contents_messages_title')}</div>
      <div className="content-manager__container__subtitle">{t('contents_messages_subtitles')}</div>
      <div className="content-manager__container__table">
        <ContentManagerTable
          type={tableRowTypeKeys.default}
          items={getMappedItems(dataTypes.messages, messagesList.collection, languagesData)}
          getLanguageTranslationFromKey={getLanguageTranslationFromKey}
          updateFunction={getSystemMessages}
          displayGenericErrorMessage={displayGenericErrorMessage}
          languagesData={languagesData}
          dataInfo={messagesList}
        />
      </div>
      <ContentManagerModal
        buttonText={t('contents_messages_add_button_label')}
        isOpen={showNewMessageModal}
        openModal={() => setNewMessageModal(true)}
        closeModal={() => setNewMessageModal(false)}
        modalTitle={t('contents_messages_add_modal_title')}
      >
        <MessagesFormModal
          closeModal={() => setNewMessageModal(false)}
          getLanguageTranslationFromKey={getLanguageTranslationFromKey}
          updateFunction={getSystemMessages}
          displayGenericErrorMessage={displayGenericErrorMessage}
          languagesData={languagesData}
        />
      </ContentManagerModal>
    </div>
  );
};

export default Messages;
