import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './FilterMyPortfolio.module.scss';

type Props = {
  myPortfolio: boolean;
  handleClick: any;
};

const FilterMyPortfolio = ({ myPortfolio, handleClick }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container} onClick={handleClick}>
      <span className={myPortfolio  ? styles.active : styles.inactive}></span>
      <span className={styles.text}>{t('see_only_my_portfolio')}</span>
    </div>
  );
};

export default FilterMyPortfolio;
