import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SeeConsolidatedTable from './SeeConsolidatedTable';
import CheckCurrentAgreementsTable from '../CheckCurrentAgreements/CheckCurrentAgreementsTable';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { useAgreementsHelpContext } from '../../../../../context/AgreementsContext';
import { useGetAgreements } from '../../../../../hooks/useGetAgreements';
import { AgreementStatusStrings } from '../../../../../enums/accordanceStateEnum';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { updateAccordance } from '../../../../../api/accordanceApi';

const data = [
  {
    title: 'Acuerdos vigentes',
    rows: [
      {
        description:
          'Descripción del acuerdo cronograma de ejecución proyectada con recursos BID, actualmente, se cuenta con un compromiso lorem ipsum.',
        temaCritico: 'Gestión de salvaguardas ambientales y sociales',
        fechaVencimiento: '15/Nov/2024',
        estado: 'Vigente',
      },
      {
        description:
          'Descripción del acuerdo cronograma de ejecución proyectada con recursos BID, actualmente, se cuenta con un compromiso lorem ipsum.',
        temaCritico: 'Gestión de salvaguardas ambientales y sociales',
        fechaVencimiento: '15/Nov/2023',
        estado: 'Vigente',
      },
      {
        description:
          'Descripción del acuerdo cronograma de ejecución proyectada con recursos BID, actualmente, se cuenta con un compromiso lorem ipsum.',
        temaCritico: 'Gestión de salvaguardas ambientales y sociales',
        fechaVencimiento: '15/Nov/2024',
        estado: 'Pendiente de aprobación',
      },
    ],
  },
  {
    title: 'Acuerdos creados en base a sugerencias',
    rows: [
      {
        description:
          'Descripción del acuerdo cronograma de ejecución proyectada con recursos BID, actualmente, se cuenta con un compromiso lorem ipsum.',
        temaCritico: 'Ejecución de productos claves/Ruta crítica',
        fechaVencimiento: '15/Nov/2024',
      },
      {
        description:
          'Descripción del acuerdo cronograma de ejecución proyectada con recursos BID, actualmente, se cuenta con un compromiso lorem ipsum.',
        temaCritico: 'Ejecución de productos claves/Ruta crítica',
        fechaVencimiento: '15/Nov/2023',
      },
    ],
  },
];

interface Props {
  projectIdDraft: number;
  currentProject: any;
}

interface Data {
  id: number;
  description: string;
  criticalIssue: { name: string };
  expirationDate: string;
}

const AccordionTable = ({ projectIdDraft, currentProject }: Props) => {
  const { t } = useTranslation();
  const [extractAgreementsLength, setExtractAgreementsLength] = useState<any>();
  const getCurrentReview = useSelector<any, any>(state => state.currentReview);

  const { id: idReview } = getCurrentReview;
  const [dataDraft, setDataDraft] = useState<[Data]>();
  const [dataValid, setDataValid] = useState<[Data]>();
  const [accordanceEdit, setAccordanceEdit] = useState<any>([]);
  const [editModal, setEditModal] = useState(false);
  const [deteleModal, setDeleteModal] = useState(false);
  const [editModalValid, setEditModalValid] = useState(false);

  const {
    getAgreementsTrigger,
    setGetAgreementsTrigger,
    setHiddenButton,
    setPostDataAgreements,
    postAgreementsTrigger,
  } = useAgreementsHelpContext();

  const { agreementsData: agreementsDataValid } = useGetAgreements(
    projectIdDraft,
    AgreementStatusStrings.VALID,
    idReview,
    getAgreementsTrigger,
  );
  const { agreementsData } = useGetAgreements(
    projectIdDraft,
    AgreementStatusStrings.DRAFT,
    idReview,
    getAgreementsTrigger,
  );

  const filterDataComplete = () => {
    const filterAccordancesData = agreementsData?.filter(
      data =>
        (data.accordanceAgreementsType !== null &&
          data.criticalIssue !== null &&
          data.dateCreated !== null &&
          data.description !== '' &&
          data.criticalIssueDescription !== '' &&
          data.expirationDate !== null &&
          data.personInCharge !== null &&
          data.revisionDate !== null &&
          data.rootCause !== null &&
          data.rootCauseCategory !== null &&
          data.criticalIssue.keyProducts.length === 0 &&
          data.linkedProductsAccordance.length === 0) ||
        (data.accordanceAgreementsType !== null &&
          data.criticalIssue !== null &&
          data.dateCreated !== null &&
          data.description !== '' &&
          data.criticalIssueDescription !== '' &&
          data.expirationDate !== null &&
          data.personInCharge !== null &&
          data.revisionDate !== null &&
          data.rootCause !== null &&
          data.rootCauseCategory !== null &&
          data.criticalIssue.keyProducts.length > 0 &&
          data.linkedProductsAccordance.length > 0),
    );

    const addKeyCompletedData = agreementsData?.map((data, i) => {
      if (
        data.accordanceAgreementsType !== null &&
        data.criticalIssue !== null &&
        data.criticalIssue.keyProducts.length === 0 &&
        data.linkedProductsAccordance.length === 0 &&
        data.dateCreated !== null &&
        data.description !== '' &&
        data.criticalIssueDescription !== '' &&
        data.expirationDate !== null &&
        data.personInCharge !== null &&
        data.revisionDate !== null &&
        data.rootCause !== null &&
        data.rootCauseCategory !== null
      ) {
        return { ...data, dataComplete: true };
      } else if (
        data.accordanceAgreementsType !== null &&
        data.criticalIssue !== null &&
        data.criticalIssue.keyProducts.length > 0 &&
        data.linkedProductsAccordance.length > 0 &&
        data.dateCreated !== null &&
        data.description !== '' &&
        data.criticalIssueDescription !== '' &&
        data.expirationDate !== null &&
        data.personInCharge !== null &&
        data.revisionDate !== null &&
        data.rootCause !== null &&
        data.rootCauseCategory !== null
      ) {
        return { ...data, dataComplete: true };
      } else {
        return { ...data, dataComplete: false };
      }
    });

    setDataDraft(addKeyCompletedData);
    setHiddenButton(agreementsData?.length === filterAccordancesData?.length);
  };

  const handleEdit = id => {
    setEditModal(true);
    const filterAccordance = dataDraft?.filter(data => data.id === id);
    setAccordanceEdit(filterAccordance);
  };

  useEffect(() => {
    if (editModal || deteleModal || editModalValid || postAgreementsTrigger) {
      setTimeout(() => {
        setGetAgreementsTrigger(!getAgreementsTrigger);
      }, 1000);
    } else {
      setTimeout(() => {
        setGetAgreementsTrigger(false);
      }, 1000);
    }
  }, [editModal, deteleModal, editModalValid, postAgreementsTrigger]);

  useEffect(() => {
    if (agreementsData?.length > 0 && agreementsDataValid?.length > 0) {
      setPostDataAgreements([...agreementsData, ...agreementsDataValid]);
    } else if (agreementsData?.length > 0) {
      setPostDataAgreements(agreementsData);
    } else if (agreementsDataValid?.length > 0) {
      setPostDataAgreements(agreementsDataValid);
    }

    setDataValid(agreementsDataValid?.filter(data => data.agreementManagement === true));
    filterDataComplete();
  }, [agreementsData, editModal, deteleModal, editModalValid, agreementsDataValid]);

  return (
    <div>
      {/* {data.map((section, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
          >
            <h4>{`${section.title} (${extractAgreementsLength ?? "-"})`}</h4>
          </AccordionSummary>
          <AccordionDetails>
            <div className="table-check-current table-consolidated">
              <SeeConsolidatedTable
                rows={section.rows}
                showArrowOnly={showArrowOnly[index]}
                showEstado={showEstado[index]}
              />
              <CheckCurrentAgreementsTable showCheckboxes={true} extractLength={setExtractAgreementsLength} />
            </div>
          </AccordionDetails>
        </Accordion>
      ))} */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel0-content`} id={`panel0-header`}>
          <h4>{`${t('new_current_agreements')} (${dataValid?.length === undefined ? '-' : dataValid?.length})`}</h4>
        </AccordionSummary>
        <AccordionDetails>
          <div className="table-check-current table-consolidated">
            {/* <SeeConsolidatedTable data={dataDraft} /> */}
            <CheckCurrentAgreementsTable
              showCheckboxes={true}
              extractLength={setExtractAgreementsLength}
              data={dataValid}
              fnEditModal={setEditModalValid}
            />
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel0-content`} id={`panel0-header`}>
          <h4>
            {t('new_accordances_create_revision')} {`(${dataDraft?.length === undefined ? '-' : dataDraft?.length})`}
          </h4>
        </AccordionSummary>
        <AccordionDetails>
          <div className="table-check-current table-consolidated">
            <SeeConsolidatedTable
              data={dataDraft}
              fnHandleEdit={handleEdit}
              fnSetEditModal={setEditModal}
              editModal={editModal}
              accordanceEdit={accordanceEdit}
              fnSetDeleteModal={setDeleteModal}
              deteleModal={deteleModal}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default AccordionTable;
