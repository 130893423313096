export const addColumn = ({
    tableHeader,
    rootProperty,
    fieldType,
    headerAlign,
    cellAlign,
    headerCellStyle = {},
    cellCustomStyle = {},
    customCellContent,
}) => {
    return {
        tableHeader,
        rootProperty,
        fieldType,
        headerAlign,
        cellAlign,
        headerCellStyle,
        cellCustomStyle,
        customCellContent,
    };
};
const project = addColumn({
    tableHeader: 'project',
    rootProperty: 'code',
    fieldType: null,
    headerAlign: 'left',
    cellAlign: 'left',
    headerCellStyle: { border: 'none' },
    cellCustomStyle: { borderRadius: '6px 0 0 6px', minWidth: '80px', border: 'none' },
    customCellContent: null,
});

const operation = addColumn({
    tableHeader: 'operation',
    rootProperty: 'loanNumber',
    fieldType: null,
    headerAlign: 'center',
    cellAlign: 'center',
    headerCellStyle: { border: 'none' },
    cellCustomStyle: { minWidth: '100px', border: 'none' },
    customCellContent: null,
});

const projectName = addColumn({
    tableHeader: 'name',
    rootProperty: 'title',
    fieldType: null,
    headerAlign: 'left',
    cellAlign: 'left',
    headerCellStyle: { border: 'none' },
    cellCustomStyle: { minWidth: '120px', border: 'none' },
    customCellContent: null,
});

const field1 = addColumn({
    tableHeader: 'completed_activities',
    rootProperty: 'field1',
    fieldType: null,
    headerAlign: 'left',
    cellAlign: 'left',
    headerCellStyle: { border: 'none' },
    cellCustomStyle: { minWidth: '120px', border: 'none' },
    customCellContent: null,
});

const field2 = addColumn({
    tableHeader: 'pending_activities',
    rootProperty: 'field2',
    fieldType: null,
    headerAlign: 'left',
    cellAlign: 'left',
    headerCellStyle: { border: 'none' },
    cellCustomStyle: { minWidth: '120px', border: 'none' },
    customCellContent: null,
});

const field3 = addColumn({
    tableHeader: 'completed_critical_products',
    rootProperty: 'field3',
    fieldType: null,
    headerAlign: 'left',
    cellAlign: 'left',
    headerCellStyle: { border: 'none' },
    cellCustomStyle: { minWidth: '120px', border: 'none' },
    customCellContent: null,
});

const field4 = addColumn({
    tableHeader: 'pending_critical_products',
    rootProperty: 'field4',
    fieldType: null,
    headerAlign: 'left',
    cellAlign: 'left',
    headerCellStyle: { border: 'none' },
    cellCustomStyle: { minWidth: '120px', border: 'none' },
    customCellContent: null,
});

export {
    project,
    operation,
    projectName,
    field1,
    field2,
    field3,
    field4
};

export const tableInfo = [
    project,
    projectName,
    field1,
    field2,
    field3,
    field4
];