import React from 'react';
import styles from './MultipleStackedBar.module.scss';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import {
  conditionalFunction,
  isLargeChart,
  simpleColorClasses,
  colorClasses,
  tooltipFirstLabel,
  listContainerClassName,
  colorCountriesClasses,
} from './util';
import { capitalizeFirstLetter } from '../../../../../../services/stringUtil';
import { IndicatorAnalyticFilterEnum as IndicatorFilter } from '../../../../../../models/IndicatorAnalyticFilterEnum';
import ClickableText from '../../../../../ProjectDetail/ClickableText/ClickableText';
import { CountriesCodeEnum } from '../../../../../../models/CountriesColorPalleteEnum';

const optionsPlugins = {
  datalabels: { display: false },
};

const optionsLegend = {
  display: false,
};

const optionsScales = {
  yAxes: [
    {
      stacked: true,
      ticks: {
        display: false,
      },
      gridLines: {
        display: false,
      },
    },
  ],
  xAxes: [
    {
      stacked: true,
      gridLines: {
        display: false,
      },
    },
  ],
};

const styleTooltip = {
  backgroundColor: '#101319',
  bodyFontColor: '#ffffff',
  bodyFontFamily: 'Rubik',
  titleFontSize: 14, 
  bodyFontSize: 14,
};

const getTooltipLabel = (tooltipItem, data, datesRadioSelectState, customSelectStateId, simple = false) => {
  let label: string;
  let value: number;
  if (datesRadioSelectState) {
    if (customSelectStateId === 0) {
      label =
        simple || data.datasets[tooltipItem.datasetIndex].label === 'various'
          ? data.datasets[tooltipItem.datasetIndex].label
          : `role_analytics_id_${data.datasets[tooltipItem.datasetIndex].label}`;
    } else {
      label = data.datasets[tooltipItem.datasetIndex].name;
    }
    value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
  } else {
    label = data.labels[tooltipItem.index];
    value = data.datasets[0].data[tooltipItem.index];
  }

  return { label, value };
};

const renderOtherListValue = (list, t, modalView = false, isOpen = false) => {
  let others: any;
  if (!isOpen && !modalView) {
    const valuesTosum = list.length > 4 && list.slice(4, list.length - 1);
    others = valuesTosum.reduce((a, b) => a + (!isNaN(b.totalAccumulated) ? b.totalAccumulated : 0), 0);
  }
  return !modalView ? (
    <li className={colorClasses[0]}>
      {`${capitalizeFirstLetter(t('various'))}:`}
      <span className={styles.bolder}>{others}</span>
    </li>
  ) : null;
};

const renderList = (list, t, indicatorFilterState, modalView = false, simple) => {
  let listToMap;

  if (modalView) {
    if (!simple) {
      listToMap = list.slice(0, list.length - 1);
    } else {
      listToMap = list;
    }
  } else {
    if (!simple) {
      listToMap = list.slice(0, 4);
    } else {
      listToMap = list;
    }
  }

  return listToMap
    .filter(a => a.totalAccumulated > 0)
    .map(l => {
      const colorClass = simple ? simpleColorClasses[l.label] : setLiClassesToRolesOrCountry(l);

      return (
        <li className={colorClass} key={l.label}>
          {renderListLabel(l, t, indicatorFilterState, simple)}
          <span className={styles.bolder}>{l.totalAccumulated}</span>
        </li>
      );
    });
};

const setLiClassesToRolesOrCountry = l => {
  return typeof l.label === 'string' ? colorCountriesClasses[CountriesCodeEnum[l.label]] : colorClasses[l.label];
};

const renderListLabel = (l, t, indicatorFilterState, simple) => {
  if (indicatorFilterState === IndicatorFilter.RolesDates) {
    const label = simple ? l.label : `role_analytics_id_${l.label}`;

    return `${capitalizeFirstLetter(t(label))}:`;
  }
  return `${l.name}:`;
};

type Props = {
  indicatorFilterState: any;
  simpleData: any;
  data: any;
  isOpen: boolean | undefined;
  datesRadioSelectState: any;
  customSelectState: any;
  simple: any;
  totalAccumulatedList: any;
  openComplexModal: any;
};

const Chart = ({
  indicatorFilterState,
  simpleData,
  data,
  isOpen,
  datesRadioSelectState,
  customSelectState,
  simple,
  totalAccumulatedList,
  openComplexModal,
}: Props) => {
  const { t } = useTranslation();

  const options = {
    plugins: optionsPlugins,
    legend: optionsLegend,
    scales: optionsScales,
    tooltips: {
      ...styleTooltip,
      callbacks: {
        title() {
          return;
        },
        label(tooltipItem, data) {
          const completeLabel = getTooltipLabel(tooltipItem, data, datesRadioSelectState, customSelectState.id, simple);
          const label = tooltipFirstLabel(customSelectState, completeLabel, t);
          return `${label}: ${completeLabel.value}`;
        },
      },
    },

    cornerRadius: 20,
  };

  return (
    <div
      className={conditionalFunction(
        isLargeChart(indicatorFilterState),
        styles.largeChartContainer,
        styles.chartContainer,
      )}
    >
      <Bar
        data={conditionalFunction(isLargeChart(indicatorFilterState), simpleData, data)}
        options={options}
        width={540}
        height={215}
      />
      <div className={listContainerClassName(isOpen, indicatorFilterState)}>
        <ul className={conditionalFunction(isOpen, styles.expandedList, '')}>
          {renderList(totalAccumulatedList, t, indicatorFilterState, isOpen, simple)}
          {conditionalFunction(simple, null, renderOtherListValue(totalAccumulatedList, t, isOpen))}
        </ul>
        {!isOpen && (
          <div className={styles.modalLinkContainer}>
            <ClickableText text={t('view_all')} onClick={openComplexModal} fontSize={12} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Chart;
