import Modal from 'react-modal';
import { translateMultipleText } from '../../../services/stringUtil';
export const basicFilters = {
  pageNumber: 1,
  itemsPerPage: 100,
  sortParameter: 8,
  orderDesc: true,
  inFollow: false,
  isAnnual: false,
  regions: [],
  Filters: [
    {
      filterType: 6,
      field: 'ResponsibleCountryId',
      value: [23],
    },
  ],
};

export const filterTablesByTerm = (projectTables, term) => {
  if (!projectTables || !projectTables.length) {
    return [];
  }
  const lowerCaseTerm = term.toLowerCase();
  return projectTables.map(pTable => {
    pTable.list = pTable.list.filter(p => {
      return (
        p.name.toLowerCase().includes(lowerCaseTerm) ||
        p.code.toString().toLowerCase().includes(lowerCaseTerm) ||
        (p.withLoans && p.loanNumber.toString().toLowerCase().includes(lowerCaseTerm))
      );
    });
    return pTable;
  });
};

export const combinedFilters = (selectedFilters, projects, t, first = true) => {
  if (!selectedFilters || !selectedFilters.length) {
    return first ? [] : projects;
  }
  projects.list = projects.list.filter(selectedFilters[0].condition);
  projects.title += `${first ? '' : ' + '}${
    selectedFilters[0].filterText
      ? translateMultipleText(selectedFilters[0].filterText, t)
      : t(selectedFilters[0].label)
  }`;
  return combinedFilters(selectedFilters.slice(1), projects, t, false);
};

export const updateProjectsTableList = (selectedFilters, allProjects, t, otherProjectsTitle) => {
  const result: any[] = [];
  const filteredProjects: any[] = [];
  if (selectedFilters.length > 1) {
    const combined = combinedFilters(selectedFilters, { title: '', list: allProjects }, t);
    result.push(combined);
  }

  for (const i of selectedFilters) {
    const filtered = allProjects.filter(i.condition);
    result.push({ title: i.filterText ? translateMultipleText(i.filterText, t) : t(i.label), list: filtered });

    for (const k of filtered) {
      if (!filteredProjects.find(fpr => fpr.id === k.id)) {
        filteredProjects.push(k);
      }
    }
  }
  const otherProjects = allProjects.filter(item => !filteredProjects.find(fp => fp.id === item.id));
  result.push({ title: otherProjectsTitle, list: otherProjects });

  return result;
};

export const addFilterProps = (projects, revisionDate) => {
  const portfolioProjectedAnnual = projects.reduce((a, b) => a + (b.disbursementProjectionAnnual || 0), 0);
  return projects.map(p => {
    p.revisionDate = revisionDate
      ? typeof revisionDate === 'string'
        ? revisionDate
        : revisionDate.toISOString()
      : null;
    p.portfolioProjectedAnnual = portfolioProjectedAnnual;
    return p;
  });
};

export const modalStyles: Modal.Styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflowY: 'scroll',
    backgroundColor: 'white',
    zIndex: 10000,
  },
};
