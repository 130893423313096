import React, { useEffect, useState } from 'react';
import './PortfolioDetail.scss';
import Header from '../../components/Header/Header';
import { PorfolioDetailHeader } from '../../components/PortfolioDetail/PortfolioDetailHeader/PortfolioDetailHeader';
import { PortfolioDetailContent } from '../../components/PortfolioDetail/PortfolioDetailContent/PortfolioDetailContent';
import { useSelector } from 'react-redux';
import CardRecommends from '../../components/Portfolio/CardIndicator/CardRecommends';
import { getIndicatorsRecommended } from '../../api/contentManager';
import { processIndicatorsRecommend } from '../../components/Portfolio/indicatorHelper';
import { useTranslation } from 'react-i18next';

export const PortfolioDetail = () => {
  const currentIndicator = useSelector<any, any>(state => state.currentIndicator);
  const [indicators, setIndicators] = useState<any>(null);
  const [deepTitle, setDeepTitle] = React.useState({ haveDeepTitle: false, deepTitleValue: '' });
  const [openModal, setOpenModal] = React.useState(false);
  const { t } = useTranslation();

  const headerTitle = (haveValue, valueString) => {
    setDeepTitle({ haveDeepTitle: haveValue, deepTitleValue: valueString });
  };

  const handleFilterModal = isOpen => {
    setOpenModal(isOpen);
  };

  const getRecommended = async () => {
    const tableDataResponseListId = await getIndicatorsRecommended(currentIndicator.id);
    let indicatorsProcessedRecom;

    if (tableDataResponseListId.length > 0) {
      indicatorsProcessedRecom = processIndicatorsRecommend(tableDataResponseListId);
    }
    const filtered = tableDataResponseListId
      .filter(item1 => indicatorsProcessedRecom.find(item2 => item2.id === item1.id))
      .map(item1 => {
        const matchedItem = indicatorsProcessedRecom.find(item2 => item2.id === item1.id);
        return { ...item1, ...matchedItem };
      });

    setIndicators(filtered);
  };

  React.useEffect(() => {
    getRecommended();
  }, [currentIndicator.id]);

  const closeModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header detailProject={true} />
      <div className="porfolio-detail-container">
        <PorfolioDetailHeader
          currentIndicator={currentIndicator}
          deepTitle={deepTitle}
          handleModal={handleFilterModal}
        />
        <PortfolioDetailContent
          currentIndicator={currentIndicator}
          headerTitle={headerTitle}
          openModal={openModal}
          closeModal={closeModal}
        />
        {indicators && indicators.length > 0 && (
          <div className="recommended">
            <h4>{t('last_indicators_may_interest')}</h4>
            <div className="cards">
              {indicators &&
                indicators.map((value, index) => <CardRecommends key={value.title} card={value}></CardRecommends>)}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
