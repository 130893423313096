import { withStyles, createStyles, Theme, TableCell } from '@material-ui/core';
import { isMobile } from '../../../services/booleanUtil';

//Body table
export const TableCellReport = withStyles((theme: Theme) =>
  createStyles({
    root: {
      // color: '#383B41',
      // fontFamily: 'Rubik',
      // fontSize: '12px',
      // fontWeight: 300,
      // marginTop: '0px',
    },
  }),
)(TableCell);

export const TableCellHeaderReport = withStyles((theme: Theme) =>
  createStyles({
    root: {
      // color: '#416DBC',
      // fontFamily: 'Rubik',
      // fontSize: isMobile() ? '9px' : '12px',
      // textTransform: 'uppercase',
      // fontWeight: 500,
      // cursor: 'default',
      // lineHeight: isMobile() ? '0.7rem' : '1.2rem',
      // height: 50,
      // borderBottom: 0,
    },
  }),
)(TableCell);
