import { Circle } from 'rc-progress';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import Indicator from '../../../models/Indicator';
import { PmrValidationStateEnum } from '../../../models/PmrValidationState';
import { getProjectsByCategorization } from '../../../redux/actions/indicatorProjects';
import { capitalizeFirstLetter } from '../../../services/stringUtil';
import styles from './RadialHeaderList.module.scss';

enum ElementState {
  ACTIVE_ALONE,
  DEACTIVE,
  ACTIVE_ALL,
}
const {
  radialFilterFlex,
  radialFilter,
  totalText,
  radialFilterContainer,
  radialFilterComponentContainer,
  footerLabels,
  titleLabel,
  subLabel,
  totalSign,
  centerText1Digit,
  centerText2Digit,
  centerText3Digit,
  radialFilterComponentContainerHover,
  titleLabelAlone,
  radialFilterComponentContainerDisable,
  radialFilterDisable,
  supLabel,
  emptyDiv,
  supLabelBold,
} = styles;
const handleProgressState = progressData => {
  return progressData.map(pd => {
    return {
      ...pd,
      elementState: ElementState.ACTIVE_ALL,
      containerClass: radialFilterComponentContainer,
      disableStrokeColor: '#F1F3F8',
    };
  });
};

type Props = {
  headerTitle: Function;
};

const CLEAN_TITLE = 'pmr_validation_state';
export const RadialHeaderList = ({ headerTitle }: Props) => {
  const sessionValue = sessionStorage.getItem('PMR_VALIDATION_STATE_RADIALS_SELECTED');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentIndicator = useSelector<any, Indicator>(state => state.currentIndicator);
  const [progressData, setProgressData] = useState(
    currentIndicator.data.progressData ? handleProgressState([...currentIndicator.data.progressData]) : [],
  );
  const handleCenteredValue = (percentage: number) => percentage.toString().length - 1;
  const alignedClassNameByDigitQty = [centerText1Digit, centerText2Digit, centerText3Digit];
  const [categorization, setCategorization] = useState(sessionValue ? JSON.parse(sessionValue) : null);

  useEffect(() => {
    if (sessionValue && JSON.parse(sessionValue) !== null) {
      const radToUpdate = progressData.find(rad => rad.categorization === JSON.parse(sessionValue));
      handleClick(radToUpdate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getFilteredSimpleIndicators(dispatch, currentIndicator.id, categorization);
    sessionStorage.setItem('PMR_VALIDATION_STATE_RADIALS_SELECTED', JSON.stringify(categorization));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progressData, categorization]);

  const handleClick = (rad, changeOnPage = false) => {
    if (rad.value === 0) {
      return;
    }
    setProgressData(updateRadialCharts(progressData, setCategorization, rad, headerTitle, t));
    changeOnPage && sessionStorage.removeItem('PaginationIndicator');
  };

  return (
    <div className={radialFilterContainer}>
      {progressData.map(rad => (
        <div
          className={disableStyle(rad, rad.containerClass, radialFilterComponentContainerDisable)}
          key={rad.label}
          onClick={() => handleClick(rad, true)}
        >
          <div className={radialFilterFlex}>
            <div className={disableStyle(rad, radialFilter, radialFilterDisable)}>
              <Circle
                percent={rad.percentage}
                strokeWidth={rad.strokeWidth}
                strokeColor={handleStrokeColor(rad)}
                trailColor={rad.trailColor}
                trailWidth={rad.trailWidth}
              />

              <div className={alignedClassNameByDigitQty[handleCenteredValue(rad.percentage)]}>
                <span className={totalText}>{rad.percentage}</span>
                <span className={totalSign}>%</span>
              </div>
            </div>
          </div>

          <div className={footerLabels}>
            <SuperiorLabel progressData={rad} />
            <div className={rad.elementState === ElementState.ACTIVE_ALONE ? titleLabelAlone : titleLabel}>
              {t(rad.label)}
            </div>
            <div className={subLabel}>
              <NumberFormat
                value={rad.value}
                displayType={'text'}
                className={'value'}
                thousandSeparator={'.'}
                decimalSeparator={','}
                suffix={` ${t(rad.valueSuffix)}`}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const getFilteredSimpleIndicators = (d, currentIndicatorId, categorization) => {
  return d(getProjectsByCategorization(currentIndicatorId, categorization));
};

const handleStrokeColor = rad => {
  return rad.elementState === ElementState.ACTIVE_ALL || rad.elementState === ElementState.ACTIVE_ALONE
    ? rad.color
    : rad.disableStrokeColor;
};

const disableStyle = (project, enableClass, disableClass) => {
  return project.value === 0 ? disableClass : enableClass;
};

const updateRadialCharts = (progressData, setCategorization, rad, headerTitle, t) => {
  let othersAction = ElementState.DEACTIVE;
  progressData.map(pd => {
    if (pd.label === rad.label) {
      if (pd.elementState === ElementState.ACTIVE_ALL || pd.elementState === ElementState.DEACTIVE) {
        setCategorization(pd.categorization);
        pd.elementState = ElementState.ACTIVE_ALONE;
        pd.containerClass = radialFilterComponentContainerHover;
        othersAction = ElementState.DEACTIVE;
        headerTitle(
          true,
          <span>
            {t(CLEAN_TITLE)}
            <span style={{ fontWeight: 300 }}> / {t(rad.label)}</span>
          </span>,
        );
      } else if (pd.elementState === ElementState.ACTIVE_ALONE) {
        setCategorization(null);
        pd.elementState = ElementState.ACTIVE_ALL;
        pd.containerClass = radialFilterComponentContainer;
        othersAction = ElementState.ACTIVE_ALL;
        headerTitle(true, t(CLEAN_TITLE));
      }
    }
    return pd;
  });
  return progressData.map(pd => {
    if (pd.label !== rad.label) {
      pd.elementState = othersAction;
      pd.containerClass = radialFilterComponentContainer;
    }
    return pd;
  });
};

const SuperiorLabel = props => {
  const { progressData } = props;
  const { t } = useTranslation();
  if (
    (progressData && progressData.categorization === PmrValidationStateEnum.CHIEF_OF_OPERATIONS) ||
    (progressData && progressData.categorization === PmrValidationStateEnum.CHIEF_OF_DIVISIONS) ||
    (progressData && progressData.categorization === PmrValidationStateEnum.REPRESENTATIVE)
  ) {
    return <div className={supLabel}>{capitalizeFirstLetter(t(progressData.supLabelTranslation))}</div>;
  } else if (progressData && progressData.categorization === PmrValidationStateEnum.VALIDATED) {
    return <div className={supLabelBold}>{capitalizeFirstLetter(t(progressData.supLabelTranslation))}</div>;
  }
  return <div className={emptyDiv}></div>;
};
