import React from 'react';
import useGcmAlerts from '../../../../hooks/useGcmAlerts';
import styles from './ListTooltip.module.scss';

type Props = {
  showTootltip: boolean;
};

export const ListTooltip = ({ showTootltip }: Props) => {
  const { tooltipList } = useGcmAlerts({});
  const { container, tooltiptext, issue, warning, cancelation } = styles;

  const classList = [cancelation, warning, issue];

  return (
    <div className={container}>
      <div
        className={tooltiptext}
        style={showTootltip ? { visibility: 'visible', opacity: 1 } : { visibility: 'hidden', opacity: 0 }}
      >
        <ul>
          {tooltipList.map(a => (
            <li className={classList[a.className]}> {a.text}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};
