import { getTextIndicator } from './reusableIndicators';
import { IndicatorKeyEnum as keyEnum } from '../../../models/IndicatorKeyEnum';
import { setKnowledgeTitle } from './util';

const addAccordancesExpire = (indicators, cardIndicators) => {
  const nextExpire = getTextIndicator(
    indicators,
    keyEnum.ACCORDANCES_EXPIRING,
    'accordances_on_indicator_card',
    'acc_next_expire acc_expired',
    'accordances_on_indicator_card acc_next_expire acc_expired',
  );
  if (nextExpire) {
    cardIndicators.push(nextExpire);
  }
};

const addAccordancesApprove = (indicators, cardIndicators) => {
  const nextExpire = getTextIndicator(
    indicators,
    keyEnum.ACCORDANCES_WAITING_APPROVAL,
    'accordances_on_indicator_card',
    'pending_approval',
    'accordances_on_indicator_card pending_approval',
  );
  if (nextExpire) {
    cardIndicators.push(nextExpire);
  }
};

const addProjectsWithTansbudgetDeficit = (indicators, cardIndicators) => {
  const indicator = getTextIndicator(
    indicators,
    keyEnum.PROJECTS_WITH_TRANSACTIONAL_BUDGET_DEFICIT,
    'projects_with',
    'budget_deficit',
    'projects_with budget_deficit',
  );
  if (indicator) {
    cardIndicators.push(indicator);
  }
};

const addProjectsWithMICI = (indicators, cardIndicators) => {
  const indicator = getTextIndicator(
    indicators,
    keyEnum.PROJECTS_IN_EXECUTION_WITH_MICI_OPEN_CASES,
    'projects_with',
    'MICI',
    'projects_with MICI',
  );
  if (indicator) {
    cardIndicators.push(indicator);
  }
};

const addPendingElegibility = (indicators, cardIndicators) => {
  const pendEleg = getTextIndicator(
    indicators,
    keyEnum.PROJECTS_PENDING_DISBURSEMENT_ELIGIBILITY,
    'menu_projects',
    'pending_elegibility',
    'menu_projects pending_elegibility',
  );
  if (pendEleg) {
    cardIndicators.push(pendEleg);
  }
};

const addNoDisb180 = (indicators, cardIndicators) => {
  const noDisb180 = getTextIndicator(
    indicators,
    keyEnum.PROJECTS_OVERALL_STAGE_CODE_DISBURSING_NO_DISBURSEMENTS_OVER_180_DAYS,
    'menu_projects',
    'no_disb_more_180',
    'menu_projects no_disb_more_180',
  );
  if (noDisb180) {
    cardIndicators.push(noDisb180);
  }
};

const addExtendedProj = (indicators, cardIndicators) => {
  const extendedProj = getTextIndicator(
    indicators,
    keyEnum.PROJECTS_EXTENDED_NOT_FULLY_DISBURSED,
    'projects_with',
    'extension_to_the_disbursement_period',
    'projects_with extension_to_the_disbursement_period',
  );
  if (extendedProj) {
    cardIndicators.push(extendedProj);
  }
};

const addPlannedDisb = (indicators, cardIndicators) => {
  const plannedDisb = getTextIndicator(
    indicators,
    keyEnum.DISBURSEMENT_PROJECTION_CURRENT_YEAR_INCLUDING_REGIONALS,
    'projects_with',
    'disb_planned_current_year',
    'projects_with disb_planned_current_year',
  );
  if (plannedDisb) {
    cardIndicators.push(plannedDisb);
  }
};

const addProjectsKnowledgeProducts = (indicators, cardIndicators) => {
  const rangeStored = localStorage.getItem('knowledgeIndicatorRange');

  let projectsKnowledgeProducts = getTextIndicator(
    indicators,
    keyEnum.PROJECTS_KNOWLEDGE_PRODUCTS,
    'knowledge_products',
    'published_in_the_last_30_days',
    'knowledge_products published_in_the_last_30_days',
  );

  if (projectsKnowledgeProducts) {
    if (!projectsKnowledgeProducts.data) {
      projectsKnowledgeProducts = {
        ...projectsKnowledgeProducts,
        data: { value: 0, lightText: 'knowledge_products', boldText: 'published_in_the_last_30_days' },
      };
    }
    cardIndicators.push(setKnowledgeTitle(rangeStored, projectsKnowledgeProducts, indicators));
  }
};

const addProjectsInPreparation = (indicators, cardIndicators) => {
  const ProjectsInPreparation = getTextIndicator(
    indicators,
    keyEnum.PROJECTS_IN_PREPARATION,
    'menu_projects',
    'in_preparation',
    'menu_projects in_preparation',
  );
  if (ProjectsInPreparation) {
    cardIndicators.push(ProjectsInPreparation);
  }
};

const addPmrAlertProblemLast3Years = (indicators, cardIndicators) => {
  const extendedProj = getTextIndicator(
    indicators,
    keyEnum.PMR_ALERT_PROBLEM_LAST_3_YEARS,
    'menu_projects',
    'on_alert_or_trouble_in_their_last_3_pmr',
    'menu_projects on_alert_or_trouble_in_their_last_3_pmr',
    false,
  );
  if (extendedProj) {
    cardIndicators.push(extendedProj);
  }
};

const addProjectsElegibleFirstUndisbursement = (indicators, cardIndicators) => {
  const extendedProj = getTextIndicator(
    indicators,
    keyEnum.PROJECTS_ELIGIBLE_FIRST_UNDISBURSEMENT,
    'eligible_pending_the_first_disbursement_first_phrase',
    'eligible_pending_the_first_disbursement_second_phrase',
    'eligible_pending_the_first_disbursement_first_phrase eligible_pending_the_first_disbursement_second_phrase',
    false,
  );
  if (extendedProj) {
    cardIndicators.push(extendedProj);
  }
};

const addProjectsWithPartialCancellations = (indicators, cardIndicators) => {
  const extendedProj = getTextIndicator(
    indicators,
    keyEnum.PROJECTS_PARTIAL_CANCELLATIONS,
    'menu_projects',
    'with_partial_cancellations',
    'menu_projects with_partial_cancellations',
    false,
  );
  if (extendedProj) {
    cardIndicators.push(extendedProj);
  }
};

export {
  addAccordancesExpire,
  addAccordancesApprove,
  addPlannedDisb,
  addExtendedProj,
  addNoDisb180,
  addPendingElegibility,
  addProjectsWithMICI,
  addProjectsWithTansbudgetDeficit,
  addProjectsKnowledgeProducts,
  addProjectsInPreparation,
  addPmrAlertProblemLast3Years,
  addProjectsElegibleFirstUndisbursement,
  addProjectsWithPartialCancellations,
};
