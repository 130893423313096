import React from 'react';
import { IndicatorKeyEnum } from '../../../../../../models/IndicatorKeyEnum';
import { isMobile } from '../../../../../../services/booleanUtil';
import { ReportImageDisplay } from '../../../../../Util/Modals/CustomizableReports/CustomizableReports';
import { isReportDetailImg } from '../../../CardIndicator';

type Props = {
  showSubLabels: boolean;
  indicatorKey: any;
  data: any;
  forReport?: ReportImageDisplay;
  modalChart?: boolean;
  contentManager?: any;
};
export const CustomSublabels = ({
  showSubLabels,
  indicatorKey,
  contentManager,
  data,
  forReport,
  modalChart,
}: Props) => {
  return showSubLabels &&
    indicatorKey !== IndicatorKeyEnum.PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS_BY_DIVISION &&
    indicatorKey !== IndicatorKeyEnum.PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS_BY_COUNTRY ? (
    <>
      <div className="sub-labels" style={contentManager ? { marginLeft: 5 } : {}}>
        {data.subLabels &&
          data.subLabels.map((s, i) => (
            <div
              style={
                contentManager
                  ? noColorizedDynamicMargin(data.subLabels.length, true, modalChart)
                  : dynamicMargin(data.subLabels.length, i, forReport)
              }
              key={i}
            >
              <span className={subLabelClassName(contentManager)}>{s}</span>
            </div>
          ))}
      </div>

      <div className="sub-labels-translated-text">
        {data.subTextTranslatedLabels &&
          data.subTextTranslatedLabels.map((s, i) => (
            <div style={noColorizedDynamicMargin(data.subTextTranslatedLabels.length, false, modalChart)} key={i}>
              {s}
            </div>
          ))}
      </div>
    </>
  ) : null;
};

const dynamicMargin = (countSubLabels, index, forReport) => {
  const marginBottom = [1, 38, 23, 19, 12, 8, 5];
  const paddingTop = [90, 45, 30, 20, 17, 15, 3];
  let color = '#538CFF';
  if (index === 5) {
    color = 'rgba(114, 126, 140, 0.5)';
  } else if (index === 6) {
    color = 'rgba(114, 126, 140, 0.3)';
  }

  if (isReportDetailImg(forReport)) {
    color = 'white';
  }

  return {
    marginBottom: marginBottom[countSubLabels - 1],
    paddingTop: paddingTop[countSubLabels - 1],
    color,
  };
};

const noColorizedDynamicMargin = (countSubLabels, isText, modalChart) => {
  let marginBottom: any = [];
  let paddingTop: any = [];

  if (isMobile() && !modalChart) {
    marginBottom = [2, 2, 2, 2, 2, 2, 2];
    paddingTop = isText ? [3, 3, 3, 3, 3, 3, 3] : [7, 7, 7, 7, 7, 7, 7];
  } else {
    marginBottom = [1, 38, 23, 19, 12, 8, 5];
    paddingTop = [90, 45, 30, 20, 17, 15, 12];
  }

  return {
    marginBottom: marginBottom[countSubLabels - 1],
    paddingTop: paddingTop[countSubLabels - 1],
    height: 15,
  };
};

const subLabelClassName = (contentManager: boolean) => (contentManager ? 'analytics-label' : '');
