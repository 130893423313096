import React from 'react';
import { useSelector } from 'react-redux';
import { getProductTypesFilterAndIgConfigFromStore } from '../../../api/filterPortfolioAndProjectUtil';
import { IndicatorKeyEnum } from '../../../models/IndicatorKeyEnum';
import { ProjectProductTypes } from '../../../models/ProjectProductTypes';
import {
  checkIfSessionFilterExist,
  getFilterSessionValue,
} from '../../../services/sessionFiltersIndicatorDetailHelper';
import { parseFilterToApiCall } from '../../../services/util';

import { CtFilterButton } from './CtFilterButton';
import styles from './CtFilterTab.module.scss';
import { SummaryIGInternalFilters } from '../../../enums/summary-ig-internal-filters';

type Props = {
  selectedFilter: ProjectProductTypes;
  handleSelectedFilter: Function;
};

const { optionContainer, circleLoan, circleTCP, circleIGR } = styles;

const handleFilterExistOnSessionStorage = () => {
  const filterValue = getFilterSessionValue('igLowerGreater');
  if (filterValue === SummaryIGInternalFilters.BOTH_IG || filterValue === SummaryIGInternalFilters.BOTH_IG_FALSE) {
    return 'IGBoth';
  } else if (filterValue === SummaryIGInternalFilters.GREATER_3_IG) {
    return 'IGGreater';
  } else {
    return 'IGLower';
  }
};
const setIGKey = () => {
  const { projectProductAmounts } = getProductTypesFilterAndIgConfigFromStore();
  const filterExistOnSessionStorage = checkIfSessionFilterExist('igLowerGreater');

  if (filterExistOnSessionStorage) {
    return handleFilterExistOnSessionStorage();
  } else {
    if (projectProductAmounts.length > 0) {
      if (projectProductAmounts.length === 2) {
        return 'IGBoth';
      } else {
        return projectProductAmounts[0].filterType === 1 ? 'IGGreater' : 'IGLower';
      }
    }
  }
  return undefined;
};

const isCurrentSummaryIndicator = currentIndicator => {
  return currentIndicator && currentIndicator.indicatorKey === IndicatorKeyEnum.PROJECTS_SUMMARY_PORTFOLIO;
};

export const CtFilterTab = ({ selectedFilter, handleSelectedFilter }: Props) => {
  const { currentIndicator, filterIndicators } = useSelector<any, any>(state => state);

  const { tabIndicatorValues } = currentIndicator;
  const { projectTypeFilters } = filterIndicators.common;
  const IGKey = setIGKey();

  const handleDisabledButton = filterToCheck => {
    const { projectProductTypes }: any = parseFilterToApiCall(projectTypeFilters);
    return !projectProductTypes.includes(filterToCheck);
  };
  handleDisabledButton(ProjectProductTypes.Loan);

  const renderFromTabIndicatorValues = (tabIndicatorValues, key) => {
    return (tabIndicatorValues && tabIndicatorValues[key]) || (tabIndicatorValues && tabIndicatorValues[key] === 0)
      ? tabIndicatorValues[key]
      : undefined;
  };

  const setValue = (tabIndicatorValues, key) => {
    if (isCurrentSummaryIndicator(currentIndicator)) {
      const indicatorSessionFilters = sessionStorage.getItem('IndicatorSessionFilters');

      if (indicatorSessionFilters === null) {
        return renderFromTabIndicatorValues(tabIndicatorValues, key);
      } else {
        const indicatorSessionFiltersObject = JSON.parse(indicatorSessionFilters);

        if (indicatorSessionFiltersObjectLoanFiltersLoan(indicatorSessionFiltersObject, key)) {
          return lonAmountValue(currentIndicator, indicatorSessionFiltersObject);
        } else if (indicatorSessionFiltersObjectBeneficiaryCT(indicatorSessionFiltersObject, key)) {
          if (indicatorSessionFiltersObject.beneficiaryTypes === 0) {
            return currentIndicator.data.ct.nationals.ctsNatProjectAmount.value;
          } else {
            return currentIndicator.data.ct.regionals.ctsRegProjectAmount.value;
          }
        }
        return renderFromTabIndicatorValues(tabIndicatorValues, key);
      }
    }
    return renderFromTabIndicatorValues(tabIndicatorValues, key);
  };
  
  return (
    <>
      <div className={optionContainer}>
        <CtFilterButton 
          compositionButton={ProjectProductTypes.Loan}
          handleSelectedFilter={handleSelectedFilter}
          selectedButton={selectedFilter}
          value={setValue(tabIndicatorValues, 'Loan')}
          disabled={handleDisabledButton(ProjectProductTypes.Loan)}
          classCircleAvatar={circleLoan}
        />
        <CtFilterButton
          compositionButton={ProjectProductTypes.CT}
          handleSelectedFilter={handleSelectedFilter}
          selectedButton={selectedFilter}
          value={setValue(tabIndicatorValues, 'CT')}
          disabled={handleDisabledButton(ProjectProductTypes.CT)}
          classCircleAvatar={circleTCP}
        />
        <CtFilterButton
          compositionButton={ProjectProductTypes.IG}
          handleSelectedFilter={handleSelectedFilter}
          selectedButton={selectedFilter}
          value={setValue(tabIndicatorValues, 'IGBoth')}
          disabled={handleDisabledButton(ProjectProductTypes.IG)}
          classCircleAvatar={circleIGR}
        />
      </div>
    </>
  );
};

const indicatorSessionFiltersObjectLoanFiltersLoan = (indicatorSessionFiltersObject, key) =>
  indicatorSessionFiltersObject && indicatorSessionFiltersObject.loanFilters && key === 'Loan';
const indicatorSessionFiltersObjectBeneficiaryCT = (indicatorSessionFiltersObject, key) =>
  indicatorSessionFiltersObject && indicatorSessionFiltersObject.beneficiaryTypes && key === 'CT';
const alertFalseRegionalFalse = indicatorSessionFiltersObject =>
  indicatorSessionFiltersObject.loanFilters.alertProblemSummaryFilterApplied === false &&
  indicatorSessionFiltersObject.loanFilters.includeRegionalProjectsApplied === false;
const alertTrueRegionalFalse = indicatorSessionFiltersObject =>
  indicatorSessionFiltersObject.loanFilters.alertProblemSummaryFilterApplied === true &&
  indicatorSessionFiltersObject.loanFilters.includeRegionalProjectsApplied === false;
const alertFalseRegionalTrue = indicatorSessionFiltersObject =>
  indicatorSessionFiltersObject.loanFilters.alertProblemSummaryFilterApplied === false &&
  indicatorSessionFiltersObject.loanFilters.includeRegionalProjectsApplied === true;
const alertTrueRegionalTrue = indicatorSessionFiltersObject =>
  indicatorSessionFiltersObject.loanFilters.alertProblemSummaryFilterApplied === true &&
  indicatorSessionFiltersObject.loanFilters.includeRegionalProjectsApplied === true;

const lonAmountValue = (currentIndicator, indicatorSessionFiltersObject) => {
  if (alertFalseRegionalFalse(indicatorSessionFiltersObject) || alertFalseRegionalTrue(indicatorSessionFiltersObject)) {
    return currentIndicator.data.lo.lonProjectAmount.value;
  } else if (
    alertTrueRegionalFalse(indicatorSessionFiltersObject) ||
    alertTrueRegionalTrue(indicatorSessionFiltersObject)
  ) {
    return currentIndicator.data.lo.amountProjectProblem.value;
  }
  return 0;
};
