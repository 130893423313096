import React, { useState, useEffect, useContext } from 'react';
import './../ContentManager.scss';
import { useTranslation } from 'react-i18next';
import { Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { contentManagerTabStyles } from '../../Projects/ProjectsAll/ProjectsAll.Style';
import { TabPanel } from '../../Projects/ProjectsAll/ProjectAllUtil';
import { getTranslationsGroups, updateIndicatorsTranslations, updateTranslations } from '../../../api/contentManager';
import { getLanguages } from '../../../api/userApi';
import SimpleTextEditor from './SimpleTextEditor/SimpleTextEditor';
import { TranslationsContext } from './context/TranslationsContext';
import ConfirmDelete from '../../Util/Modals/ConfirmDelete/ConfirmDelete';
import IndicatorsTextEditor from './IndicatorsTextEditor/IndicatorsTextEditor';

const Translations = () => {
  const { t } = useTranslation();
  const classes = makeStyles(contentManagerTabStyles)();
  const tabClasses = { root: classes.root, selected: classes.selected };
  const [tabsList, setTabsList] = useState<any>([]);
  const [activeTab, setActiveTab] = useState<any>(1);
  const { state, dispatch } = useContext(TranslationsContext);
  const [showConfirm, setShowConfirm] = useState<number>(0);
  const [temporaryTab, setTemporaryTab] = useState<any>(1);

  const getData = async () => {
    const data = await getTranslationsGroups();
    setTabsList(data);
  };

  const getLanguagesList = async () => {
    const payload = await getLanguages();
    dispatch({ type: 'SET_LANGUAGES', payload });
  };

  useEffect(() => {
    getData();
    getLanguagesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabClick = (item: any) => {
    const confirmSubmitSection =
      activeTab === 1 ? (state.indicatorToUpdate ? activeTab : 0) : state.listToUpdate.length ? activeTab : 0;
    if (confirmSubmitSection) {
      setTemporaryTab(item.id);
      setShowConfirm(confirmSubmitSection);
    } else {
      setActiveTab(item.id);
    }
  };

  const closeModalResetDataMoveTab = (confirmSection: number) => {
    setShowConfirm(0);
    const resetValue = confirmSection === 1 ? 'RESET_INDICATOR' : 'RESET_LIST';
    dispatch({ type: resetValue });
    setActiveTab(temporaryTab);
  };

  const submitConfirm = () => {
    if (showConfirm === 1) {
      updateIndicatorsTranslations([state.indicatorToUpdate]);
      closeModalResetDataMoveTab(showConfirm);
    } else {
      updateTranslations(state.listToUpdate);
      closeModalResetDataMoveTab(showConfirm);
    }
  };

  const cancelConfirm = () => {
    closeModalResetDataMoveTab(showConfirm);
  };

  return (
    <>
      <div>
        <h1 className="content-manager__title" style={{ marginBottom: 50 }}>
          {t('menu_cm_translations')}
        </h1>
        <Tabs
          value={activeTab}
          classes={{
            indicator: classes.indicatorColor,
            root: classes.root,
          }}
          aria-label="full width tabs"
        >
          {tabsList.map(item => (
            <Tab key={item.id} value={item.id} classes={tabClasses} label={item.name} onClick={() => tabClick(item)} />
          ))}
        </Tabs>
        <div>
          {tabsList.map((i, index) => (
            <TabPanel value={activeTab} index={i.id} key={i.id}>
              {index === 0 ? <IndicatorsTextEditor /> : <SimpleTextEditor groupId={i.id} />}
            </TabPanel>
          ))}
        </div>
      </div>
      <ConfirmDelete
        isOpen={showConfirm > 0}
        closeModal={cancelConfirm}
        submit={submitConfirm}
        customTitle={'changes_not_saved'}
        customMessage={''}
        cancelButtonText={'discard'}
        submitButtonText={'save'}
      />
    </>
  );
};

export default Translations;
