import { ADD_INDICATORS, AddIndicatorActionTypes } from '../actions/types';
import Indicator from '../../models/Indicator';

const initialState: Indicator[] = [];

const addIndicatorsReducer = (state = initialState, action: AddIndicatorActionTypes): Indicator[] => {
  switch (action.type) {
    case ADD_INDICATORS:
      return action.payload;
    default:
      return state;
  }
};

export default addIndicatorsReducer;
