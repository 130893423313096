import React, { useEffect } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';

import './AddIndicator.scss';
import AddIndicators from '../../Util/Modals/AddIndicators/AddIndicators';
import { trackActionClicked } from '../../../api/trackActionClickedApi';
import { TrackedActions } from '../../../models/TrackedActionsEnum';

const AddIndicator = () => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const { t } = useTranslation();

  const handleOpenModal = () => {
    setIsOpen(true);
    trackActionClicked(TrackedActions.addIndicatorClicked);
  };

  useEffect(() => {
    document.querySelectorAll('.MuiSvgIcon-root').forEach(element => {
      element.removeAttribute('aria-hidden');
    });
  }, []);

  return (
    <>
      <button className="add-indicator" onClick={handleOpenModal} tabIndex={30}>
        <div className="add-indicator-container">
          <div className="plus">
            <AddIcon fontSize={'inherit'} />
          </div>
          <span className="plus-text">{t('add_indicator')}</span>
        </div>
      </button>
      <AddIndicators isOpen={isOpen} closeModal={() => setIsOpen(false)} />
    </>
  );
};
export default AddIndicator;
