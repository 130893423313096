import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEarlyWarningsFilters } from '../../../hooks/useEarlyWarningsFilters';
import { FilterConfigValues, IconCode, TabFilterOption } from '../../../models/EarlyWarnings';
import { getProjectsSimpleTableIndicator } from '../../../redux/actions/indicatorProjects';
import { PENDING_SIGNATURE_OR_LEGIS_APPROVAL } from '../../../services/sessionFiltersIndicatorDetailHelper';
import { EarlyWarningsFilters as PendingSignatureEarlyWarningsFilters } from '../EarlyWarningsFilters/EarlyWarningsFilters';
import { createFilterConfigComponent } from '../EarlyWarningsFilters/Util';
import styles from './PendigSignatureOrLegisApproval.module.scss';
import { PendingSignatureTable } from './Table/Table';
import { TabsFilter as PendigSignatureTabFilter } from './TabsFilter/TabsFilter';
import { showEarlyWarningModule } from './Util';
import { handleLoading } from '../../utils';

const filterConfigValues: FilterConfigValues[] = [
  { id: 0, filterIcon: IconCode.GREEN, filterLabel: 'within_the_regulation_time' },
  { id: 1, filterIcon: IconCode.YELLOW, filterLabel: 'up_to_one_year_extension' },
  { id: 2, filterIcon: IconCode.RED, filterLabel: 'more_than_one_year_extension' },
];
const filterConfigComponent: TabFilterOption[] = createFilterConfigComponent(filterConfigValues);

const defaultSessionFilterObject = {
  selectedTab: 0,
  filterConfigState: filterConfigComponent,
  changeProjectType: true,
};

export const PendigSignatureOrLegisApproval = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { currentIndicator } = useSelector<any, any>(state => state);
  const { projectTypeFilterOnDetail } = useSelector<any, any>(state => state.indicatorDetailFilter);
  const { filterConfigState, handleEarlyWarningsFilter, handleProjectsQuantities, selectedTab, setSelectedTab } =
    useEarlyWarningsFilters({
      sessionKey: PENDING_SIGNATURE_OR_LEGIS_APPROVAL,
      defaultSessionFilterObject,
      projectTypeFilterOnDetail,
    });
  const dispatch = useDispatch();

  const { container, tabsContainer, filtersEarlyWarningsContainer, tableContainer } = styles;

  useEffect(() => {
    getFilteredSimpleIndicators(dispatch, currentIndicator.id, currentIndicator.indicatorKey, setLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndicator.id, dispatch, projectTypeFilterOnDetail]);

  useEffect(() => {
    if (!sessionStorage.getItem(PENDING_SIGNATURE_OR_LEGIS_APPROVAL)) {
      sessionStorage.setItem(PENDING_SIGNATURE_OR_LEGIS_APPROVAL, JSON.stringify(defaultSessionFilterObject));
    }
  }, []);

  const handleFilterApplied = filter => {
    setSelectedTab(filter);
  };

  return (
    <div className={container}>
      <div className={tabsContainer}>
        <PendigSignatureTabFilter filterBy={selectedTab} handleFilterApplied={handleFilterApplied} />
      </div>
      {showEarlyWarningModule(projectTypeFilterOnDetail, selectedTab) && (
        <div className={filtersEarlyWarningsContainer}>
          <PendingSignatureEarlyWarningsFilters
            filterSet={filterConfigState}
            handleFilter={handleEarlyWarningsFilter}
          />
        </div>
      )}
      <div className={tableContainer}>
        <PendingSignatureTable
          selectedTab={selectedTab}
          earlyWarningsTab={[
            filterConfigState[0].selected,
            filterConfigState[1].selected,
            filterConfigState[2].selected,
          ]}
          projectsQuantities={handleProjectsQuantities}
          loading={loading}
        />
      </div>
    </div>
  );
};

const getFilteredSimpleIndicators = (d, currentIndicatorId, currentIndicatorKey, setLoading) => {
  return handleLoading(() => d(getProjectsSimpleTableIndicator(currentIndicatorId, currentIndicatorKey)), setLoading);
};
