import React from 'react';
import styles from './EarlyWarningsFilters.module.scss';
import './EarlyIcons.scss';
import { ReactComponent as Green } from '../../../assets/icons/u_check-circle.svg';
import { ReactComponent as Yellow } from '../../../assets/icons/u_exclamation-circle.svg';
import { ReactComponent as Red } from '../../../assets/icons/u_multiply-circle.svg';
import { ReactComponent as Satisfactory } from '../../../assets/icons/u_check-circle.svg';
import { ReactComponent as PartiallySatisfactory } from '../../../assets/icons/u_check-circle.svg';
import { ReactComponent as IconFilter } from '../../../assets/icons/u_filters-v.svg';
import { useTranslation } from 'react-i18next';
import { IconCode, TabFilterOption } from '../../../models/EarlyWarnings';
import { useSelector } from 'react-redux';
import { isDiferentToIGLower } from '../EarlyWarningsTimeLapse/Util';

type Props = {
  filterSet: TabFilterOption[];
  handleFilter: Function;
};
export const EarlyWarningsFilters = ({ filterSet, handleFilter }: Props) => {
  const { projectTypeFilterOnDetail } = useSelector<any, any>(state => state.indicatorDetailFilter);
  const { container, buttonsContainer, igrMessage } = styles;
  const { t } = useTranslation();
  return (
    <div className={container}>
      <div className={buttonsContainer}>
        {filterSet.map(f => {
          return <FilterButton filterObject={f} handleFilter={handleFilter} key={f.id} />;
        })}
      </div>

      {!isDiferentToIGLower(projectTypeFilterOnDetail) && (
        <div className={igrMessage}>{t('only_igr_of_more_than_usd_3_million_are_included_in_this_traffic_light')}</div>
      )}
    </div>
  );
};

type FilterButtonProps = {
  filterObject: TabFilterOption;
  handleFilter: Function;
};

const handleSelectedStyle = (
  selected,
  id = 0,
  buttonContainerSelectedRed,
  buttonContainerSelectedGreen,
  buttonContainerSelectedYellow,
  noSelectedStyle,
) => {
  if (selected && id === 0) {
    return buttonContainerSelectedGreen;
  } else if (selected && id === 1) {
    return buttonContainerSelectedYellow;
  } else if (selected && id === 2) {
    return buttonContainerSelectedRed;
  } else {
    return noSelectedStyle;
  }
};
const handleIcon = filterIcon => {
  const iconsComponent = {
    GREEN: <Green className="green-icon" />,
    YELLOW: <Yellow className="yellow-icon" />,
    RED: <Red className="red-icon" />,
    SATISFACTORY: <Satisfactory className="check-icon" />,
    PARTIALLY_SATISFACTORY: <PartiallySatisfactory className="check-icon" />,
  };
  return iconsComponent[IconCode[filterIcon]];
};
const FilterButton = ({ filterObject, handleFilter }: FilterButtonProps) => {
  const { t } = useTranslation();
  const {
    projectQuantityText,
    projectLabelText,
    buttonContainer,
    buttonContainerDisableClick,
    filterLabelText,
    buttonContainerSelected,
    buttonContainerSelectedRed,
    buttonContainerSelectedGreen,
    buttonContainerSelectedYellow,
    filterLabelTextSelected,
    projectQuantityTextSelected,
    projectLabelTextSelected,
    iconContainer,
    iconLinkContainer,
    topContainer,
    iconFilter,
    greenIcon,
  } = styles;
  const { projectQuantity, projectLabel, filterIcon, filterLabel, selected, id } = filterObject;
  const projectLabelToRender = projectQuantity > 1 ? projectLabel.plural : projectLabel.singular;

  const handleEnableStyle = (disable, enableStyle) => {
    return projectQuantity === 0 ? disable : enableStyle;
  };

  return (
    <div
      className={`${handleEnableStyle(
        buttonContainerDisableClick,
        handleSelectedStyle(
          selected,
          id,
          buttonContainerSelectedRed,
          buttonContainerSelectedGreen,
          buttonContainerSelectedYellow,
          buttonContainer,
        ),
      )}`}
      onClick={() => projectQuantity > 0 && handleFilter(filterObject)}
    >
      <div className={iconContainer}>
        <p> {handleIcon(filterIcon)}</p>
      </div>
      <div>
        <div className={topContainer}>
          <p className={selected ? projectQuantityTextSelected : projectQuantityText}>{projectQuantity}</p>
          <p className={selected ? projectLabelTextSelected : projectLabelText}>{t(projectLabelToRender.trim())}</p>
        </div>
        <p className={selected ? filterLabelTextSelected : filterLabelText}>{t(filterLabel)}</p>
      </div>
      <div className={iconFilter}>
        <IconFilter />
      </div>
    </div>
  );
};
