import { withStyles } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { isMobile } from '../../../../../services/booleanUtil';

export const AccordionDetails = withStyles(theme => ({
  root: {
    padding: 0,
    borderRadius: 4,
    borderTop: 'unset',
    display: 'flex',
    flexDirection: 'column',
  },
}))(MuiAccordionDetails);

export const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    padding: '0',
    border: '1px solid #F3F4F6',
    borderRadius: 4,
    '&$expanded': {
      marginBottom: 7,
      paddingBottom: 7,
    },
    '&::before': {
      content: '',
      opacity: 0,
    },
    marginBottom: 7,
  },
  expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    padding: '0px 10px',
    '&$focused': {
      backgroundColor: '#f1f3f8', //$ci-color-greyscale-20
    },
    backgroundColor: '#f1f3f8', //$ci-color-greyscale-20
  },
  content: {
    margin: 0,
    fontFamily: 'Rubik',
    color: '#101319', //$ci-color-greyscale-90
    textTransform: 'uppercase',
    fontSize: 16,
    fontWeight: 400,
  },
  expanded: { backgroundColor: '#F1F3F8', borderBottom: 'unset' },
  expandIcon: {
    position: 'absolute',
    right: 15,
    top: isMobile() ? 10 : 'unset',
  },
  focused: {
    backgroundColor: '#FFF',
  },
})(MuiAccordionSummary);
