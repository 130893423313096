/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { ReactComponent as IconArrowLeft } from '../../assets/icons/u_arrow-left.svg';
import { ReactComponent as IconArrowRight } from '../../assets/icons/u_arrow-right.svg';
import { ReactComponent as IconCheck } from '../../assets/icons/u_check-circle.svg';
import { ReactComponent as IconError } from '../../assets/icons/u_multiply-circle.svg';
import {
  GeneralData,
  GeneralAspects,
  AffairsTransversal,
  AgreementsReached,
  OtherIssues,
  DevEffectiveness,
  Close,
  Annexes
} from './Stages/index'
import TabPanel from './MemoryHelpComponents/TabPanel';
import { a11yProps } from "../Util/Accesibility/Accesibility";
import { useFormMemoryHelpContext } from '../../context/MemoryHelpFormContext';
import './MemoryHelp.scss';
import TabText from './MemoryHelpComponents/TabText';
import StateIcon from './MemoryHelpComponents/StateIcon';
import StateText from './MemoryHelpComponents/StateText';
import useSetMemoryHelpFile from '../../hooks/useSetMemoryHelpFile';
import { templateNonNormalize } from './utils';

const timer = 5000;

export default function MemoryHelp({ navigate }) {
  const [processingData, setProcessingData] = useState<any>(null);
  const [value, setValue] = useState<number>(0);
  const [showSaveMessage, setShowSaveMessage] = useState<boolean>(false);

  const { t } = useTranslation();

  const {
    formState,
    isFormComplete,
    agreementsData,
    currentReview,
    triggerGetFiles,
    setAttachmentTrigger,
    onTabClick,
    getStatusArray,
  } = useFormMemoryHelpContext();

  const { saveRevision, loading, error } = useSetMemoryHelpFile();

  const statusArray = getStatusArray();

  const getStatusClass = (status) => {
    if (status === null) return "";
    if (status === true) return "success";
    if (status === false) return "warning";
    return "";
  };

  /**
  * Handles the change event for tab navigation.
  * @param {React.ChangeEvent<{}>} event - The event object.
  * @param {number} newValue - The new tab index.
  */
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    onTabClick(newValue);
  };

  /**
   * Handles the click event for navigating to the next tab.
   */
  const handleNext = () => {
    setValue((prevValue) => {
      const newValue = Math.min(prevValue + 1, statusArray.length - 1);
      onTabClick(newValue);
      return newValue;
    });
  };

  /**
   * Handles the click event for navigating to the previous tab.
   */
  const handlePrev = () => {
    setValue((prevValue) => {
      const newValue = Math.max(prevValue - 1, 0);
      onTabClick(newValue);
      return newValue;
    });
  };

  /**
   * Merges the updated state back to the endpoint data.
   * @param {any[]} updatedState - The updated state of the form.
   * @param {any[]} endpointData - The original data from the endpoint.
   * @returns {any[]} The merged data.
   */
  const mergeUpdatedStateBackToEndpoint = (updatedState: any, endpointData: any[]) => {
    if (endpointData.length === 0) return updatedState;

    return endpointData.map(endpointSection => {
      const updatedSection = updatedState.find(section => section.type === endpointSection.type);

      if (updatedSection) {
        let resultSection = { ...endpointSection };

        Object.keys(updatedSection).forEach(key => {
          if (updatedSection[key] !== undefined) {
            resultSection[key] = updatedSection[key];
          }
        });

        if (Array.isArray(endpointSection.projectDescriptions) && Array.isArray(updatedSection.projectDescription)) {
          resultSection.projectDescriptions = updatedSection.projectDescription.map(desc => ({
            ...desc,
            idProjectDescription: desc.id,
            helpMemoryId: 0,
            status: desc.status
          }));
        }

        delete resultSection.projectDescription;

        return resultSection;
      }

      return endpointSection;
    });
  };

  /**
   * Assigns the revision ID to the data.
   * @param {any[]} data - The data to update.
   * @param {number | null} revisionId - The revision ID to assign.
   * @returns {any[]} The updated data with the revision ID.
   */
  const assignRevisionId = (data: any[], revisionId: number | null) => {
    if (revisionId === null) return data;
    return data.map(item => {
      item.revisionId = revisionId;
      if (Array.isArray(item.projectDescriptions)) {
        item.projectDescriptions = item.projectDescriptions.map(proj => ({
          ...proj,
          helpMemoryId: revisionId
        }));
      }
      return item;
    });
  };

  /**
   * Handles the save changes action, saving the current review and updating the trigger state.
   */
  const handleSaveChanges = async () => {
    if (currentReview && processingData) await saveRevision(currentReview, processingData);
    setAttachmentTrigger(prev => !prev);
  };

  /**
   * Handles the click event to save changes and show a save message.
   */
  const handleClick = () => {
    handleSaveChanges();
    setShowSaveMessage(true);
  };

  /**
  * Handles the preview draft action, saving the current review and navigating to the memory help viewer.
  */
  const handlePreviewDraft = async () => {
    if (currentReview && processingData) await saveRevision(currentReview, processingData);

    if (error) return
    triggerGetFiles();
    navigate('memoryHelpViewer');
  };

  // Effect to format data and prepare it for backend processing whenever formState, agreementsData, or currentReview changes.
  useEffect(() => {
    let dataToFormat = (agreementsData && agreementsData.revisionHelpMemory && agreementsData.revisionHelpMemory.length > 0)
      ? agreementsData.revisionHelpMemory
      : templateNonNormalize;

    dataToFormat = assignRevisionId(dataToFormat, currentReview);

    const newStateToSendToBackend = mergeUpdatedStateBackToEndpoint(formState, dataToFormat);

    setProcessingData(newStateToSendToBackend);
  }, [formState, agreementsData, currentReview]);

  // Effect to hide the save message after a certain duration.
  useEffect(() => {
    const timerId = setTimeout(() => {
      setShowSaveMessage(false);
    }, timer);

    return () => clearTimeout(timerId);
  }, [showSaveMessage]);

  // Effect to update the status of the first tab when the component mounts or value changes.
  useEffect(() => onTabClick(value), [value]);

  return (
    <>
      <div className='memory-head'>
        <div className='title-head-memory'>
          <h4 className='ci-type-display-4'>{t('memory_help.header.description.title')}</h4>
          <p className='ci-type-text-8'>{t('memory_help.header.description.p')}</p>
        </div>
        <div className='button-header-memory'>
          {!showSaveMessage && <button className='btn btn-primary-outlined' onClick={() => handleClick()} disabled={loading}>{t('memory_help.button_save_changes')}</button>}
          {
            showSaveMessage &&
            <div className={`alerts ${error ? 'error' : 'success'}`}>
              {error
                ?
                <>
                  <IconError /> {t('memory_help.save_changes_error')}
                </>
                :
                <>
                  <IconCheck /> {t('memory_help.save_changes_alert')}
                </>
              }
            </div>
          }
          <button className='btn btn-primary' onClick={() => handlePreviewDraft()} disabled={!isFormComplete}>{t('memory_help.preview_draft')}</button>
        </div>
      </div>

      <div className='memory'>
        <Tabs
          orientation='vertical'
          variant='scrollable'
          value={value}
          onChange={handleChange}
          aria-label='Vertical tabs'
          className='memory-tabs'>
          {statusArray.map((status, index) => (
            <Tab
              key={index}
              label={
                <>
                  <TabText index={index} />
                  <StateIcon status={status} tabIndex={index} />
                </>
              }
              className={getStatusClass(status)}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>

        <div className='tab-panel'>
          <TabPanel value={value} index={0}>
            <GeneralData />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <GeneralAspects />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <AffairsTransversal />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <AgreementsReached />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <OtherIssues />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <DevEffectiveness />
          </TabPanel>
          <TabPanel value={value} index={6}>
            <Close />
          </TabPanel>
          <TabPanel value={value} index={7}>
            <Annexes />
          </TabPanel>
          <div className='steps'>
            <StateText currentTab={value} />
            <div className='steps-button'>
              <button className='btn btn-primary-text' onClick={handlePrev} disabled={value === 0}><IconArrowLeft /> {t('memory_help.button_prev')}</button>
              <button className='btn btn-primary-text' onClick={handleNext} disabled={value === statusArray.length - 1}> {t('memory_help.button_next')} <IconArrowRight /></button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}