import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TranslationsContextProvider } from '../Translations/context/TranslationsContext';
import { useHistory } from 'react-router-dom';

/* Material Imports */
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';

/*Assets imported */
import { ReactComponent as ContentsIcon } from '../../../assets/contents-tab-selected.svg';
import { ReactComponent as ContentsIconUnselected } from '../../../assets/contents-tab-unselected.svg';
import { ReactComponent as TranslationsIconSelected } from '../../../assets/translations-tab-selected.svg';
import { ReactComponent as TranslationsIconUnselected } from '../../../assets/translations-tab-unselected.svg';
import { ReactComponent as FunctionDelegationsIconSelected } from '../../../assets/function-delegations-tab-selected.svg';
import { ReactComponent as FunctionDelegationsIconUnselected } from '../../../assets/function-delegations-tab-unselected.svg';
import { ReactComponent as AddIconSelected } from '../../../assets/add-tab-selected.svg';
import { ReactComponent as AddIconUnselected } from '../../../assets/add-tab-unselected.svg';
import { ReactComponent as AnalyticsIconSelected } from '../../../assets/analytics-tab-selected.svg';
import { ReactComponent as AnalyticsIconUnselected } from '../../../assets/analytics-tab-unselected.svg';
import Analytics from '../Analytics/Analytics';
import { ReactComponent as BackToCarteraIcon } from '../../../assets/content-manager-back-to-cartera.svg';
import { contentManagerStyles } from './../../Sidetabs/Sidetabs.Styles';

/*Custom Components */
import { TabPanel } from './../../Sidetabs/Sidetabs';
import { contentManagerTabIndexes as tabIndexes } from '../../../models/TabIndexes';
import Contents from '../Contents/Contents';
import AddEntities from '../AddEntities/AddEntities';
import Translations from '../Translations/Translations';
import FunctionDelegations from '../FunctionDelegations/FunctionDelegations';

export const SidetabsBase = () => {
  const classes = makeStyles(contentManagerStyles)();
  const history = useHistory();

  const { t } = useTranslation();
  const tabClasses = { root: classes.root, selected: classes.selected };
  const hiddenTab = { root: classes.hiddenTab };

  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (newValue === tabIndexes.backToCartera) {
      history.push('/home');
      return;
    }
    setTabValue(newValue);
  };

  return (
    <div className={classes.container}>
      <div className={classes.sidebar}>
        <div className={classes.rootb}>
          <Tabs
            classes={{ indicator: classes.indicator, root: classes.tabs }}
            orientation={'vertical'}
            value={tabValue}
            onChange={handleChange}
            aria-label="Menu"
          >
            <Tab
              classes={tabClasses}
              label={t('menu_cm_contents')}
              icon={tabValue === tabIndexes.contents ? <ContentsIcon /> : <ContentsIconUnselected />}
            />
            <Tab
              classes={tabClasses}
              label={t('menu_cm_translations')}
              icon={
                tabValue === tabIndexes.translations ? <TranslationsIconSelected /> : <TranslationsIconUnselected />
              }
            />
            <Tab
              classes={tabClasses}
              label={t('menu_cm_functionDelegations')}
              icon={
                tabValue === tabIndexes.functionDelegations ? (
                  <FunctionDelegationsIconSelected />
                ) : (
                  <FunctionDelegationsIconUnselected />
                )
              }
            />
            <Tab
              classes={tabClasses}
              label={t('menu_cm_add')}
              icon={tabValue === tabIndexes.add ? <AddIconSelected /> : <AddIconUnselected />}
            />
            <Tab
              classes={tabClasses}
              label={t('menu_cm_analytics')}
              icon={tabValue === tabIndexes.analytics ? <AnalyticsIconSelected /> : <AnalyticsIconUnselected />}
            ></Tab>
            <Tab classes={tabClasses} label={t('back_to_cartera')} icon={<BackToCarteraIcon />} />
            <Tab id="menu-hidden-tab" classes={hiddenTab} label={''} />
          </Tabs>
        </div>
      </div>
      <div className={classes.bodySection}>
        <TabPanel value={tabValue} index={tabIndexes.contents}>
          <Contents />
        </TabPanel>
        <TabPanel value={tabValue} index={tabIndexes.translations}>
          <TranslationsContextProvider>
            <Translations />
          </TranslationsContextProvider>
        </TabPanel>
        <TabPanel value={tabValue} index={tabIndexes.functionDelegations}>
          <FunctionDelegations />
        </TabPanel>
        <TabPanel value={tabValue} index={tabIndexes.add}>
          <AddEntities />
        </TabPanel>
        <TabPanel value={tabValue} index={tabIndexes.analytics}>
          <Analytics />
        </TabPanel>
      </div>
    </div>
  );
};

const ContentManagerSidetabs = SidetabsBase;

export { ContentManagerSidetabs, TabPanel };
