export enum RolesIdEnum {
  Chief_of_Operations = 1,
  Team_Leader = 2,
  Operations_Analyst = 3,
  Fiduciaries = 4,
  Managerial = 5,
  Strategists = 6,
  VPS_VPC_etc = 7,
  ProceduremenManager = 8,
  ESG_Specialist = 9,
  Financial_Specialist = 10,
  Borrower = 11,
  Executing_Agency = 12,
  Coordinator = 13,
  Financial_Fiduciary = 14,
  VPF_Vice_Presidency_for_Finance_and_Administration = 15,
  Consultant = 16,
  ESG_Reviewer = 17,
  ESG_TeamMember = 18,
  ESG_Primary_TeamMember = 19,
  Alternate_Team_Leader = 20,
  Financial_Management = 21,
  COS_pecialist = 22,
  Procurement_Fiduciary_Specialist = 23,
}
