import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import './ManagerialReviews.scss';
import { ReactComponent as ArrowDown } from '../../../assets/icons/u_chevron-down.svg';
import { ReactComponent as ArrowRight } from '../../../assets/icons/u_chevron-right.svg';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { replaceMomentFormat } from '../../../services/util';
import { isMobile } from '../../../services/booleanUtil';
import { ReviewsStatusEnum } from '../../../models/ReviewsStatusEnum';
import SelectableProjects from '../SelectableProjects/SelectableProjects';
import { ReviewsActionLinks } from '../ReviewsActionLinks/ReviewsActionLinks';
import SelectableProjectsResponsive from '../SelectableProjectsResponsive/SelectableProjectsResponsive';
import { getReviewById } from '../../../api/reviewsApi';
import Agenda from '../Agenda/Agenda';
import { ReactComponent as IconMemory } from '../../../assets/icons/u_ayudamemoria.svg';
import { useFormMemoryHelpContext } from '../../../context/MemoryHelpFormContext';
import { MemoryHelpRevisionStatus } from '../../MemoryHelp/MemoryHelpRevisionStatus';
import { setCurrentReview } from '../../../redux/actions/currentReview';
import { MoonLoader } from 'react-spinners';

type Props = {
  revisions?: any;
  year?: number;
  selectedCountry?: any;
  handleOnClick: any;
  visibleComponent: any;
};

export const ManagerialReviews = ({ revisions, year, selectedCountry, handleOnClick, visibleComponent }: Props) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState<string | false>(false);
  const [listProjects, setListProjects] = useState<any[]>([]);
  const [isAgenda, setIsAgenda] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [showButton, setShowButton] = useState<boolean>(false);
  const [loadingProjects, setLoadingProjects] = useState(false);

  const {
    agreementsData,
    showConfirmContent,
    triggerGetFiles,
    setTrigger,
    setCurrentReview: aliasSetCurrentReview,
  } = useFormMemoryHelpContext();

  const getCurrentReview = useSelector<any, any>(state => state.currentReview);
  const getCurrentReviewID = getCurrentReview?.id ?? null;

  useEffect(() => aliasSetCurrentReview(getCurrentReviewID), []);

  const handleChange = (review: any) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    aliasSetCurrentReview(review.id);
    setTrigger(prev => !prev);
    getProjects(review);
    setExpanded(getReviewIdIfExpanded(isExpanded, review));
  };

  const onHandleClick = () => {
    triggerGetFiles();
    visibleComponent(true);
    handleOnClick('memoryHelpViewer');
  };

  const getProjects = async review => {
    setLoadingProjects(true);
    setListProjects([]);
    const data = await getReviewById(review.id);
    setLoadingProjects(false);
    dispatch(setCurrentReview({ ...data, title: t('revision_number_'.concat(review.revisionNumber)) }));
    setListProjects(data.projects);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsAgenda(false);
    }, 1000);
  }, [expanded]);

  useEffect(() => {
    // agreementsData is defined and the status is not Denied, activate the button
    if (agreementsData && agreementsData.status !== MemoryHelpRevisionStatus.Denied) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  }, [agreementsData, getCurrentReviewID]);

  return (
    <div className="managerial-reviews-container">
      {hasRevisions(revisions) ? (
        revisions.map(revision => (
          <div key={revision.revisionNumber}>
            <div className="title">
              {`${t('revision_number_'.concat(revision.revisionNumber))} `}
              {t('review')} {year}
            </div>

            {revision.revisions.map(revision => (
              <div className="country-list-container" key={revision.country.name}>
                <Accordion expanded={expanded === revision.id} onChange={handleChange(revision)} elevation={0}>
                  <AccordionSummary
                    expandIcon={<IconoAcordeon expanded={expanded === revision.id} />}
                    aria-controls="content"
                    id="header"
                    disabled={!revision.hasProjects}
                  >
                    <SummaryContent expanded={expanded} t={t} revision={revision} />

                    {expanded === revision.id && (
                      <div className="buttons-memor">
                        <ReviewsActionLinks
                          review={revision}
                          isAgenda={isAgenda}
                          changeView={() => setIsAgenda(!isAgenda)}
                        />
                        {showButton ? (
                          <button onClick={() => onHandleClick()} className="btn btn-sm btn-primary-outlined">
                            <IconMemory />
                            {showConfirmContent
                              ? t('memory_help.page_reviews.btn.see')
                              : t('memory_help.page_reviews.btn.draft_memory')}
                          </button>
                        ) : (
                          ''
                        )}
                      </div>
                    )}
                  </AccordionSummary>
                  <AccordionDetails className={'accordion'}>
                    {revision.hasProjects ? (
                      !loadingProjects ? (
                        isAgenda ? (
                          <Agenda closeAgenda={() => {}} />
                        ) : isMobile() ? (
                          <SelectableProjectsResponsive projects={{ list: listProjects }} />
                        ) : (
                          <SelectableProjects projects={{ list: listProjects }} withComments={true} />
                        )
                      ) : (
                        <div className="loading-table positionSpinner">
                          <MoonLoader size={30} color={'#417ffe'} loading={true} speedMultiplier={1} />
                          <p>{t('loading_message')}</p>
                        </div>
                      )
                    ) : null}
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
          </div>
        ))
      ) : (
        <>
          <div className="title">
            {`${t('revision_number_1')} `}
            {t('review')} {year}
          </div>
          <div style={{ marginTop: 10 }}>
            <span className="country-label">{customCountryLabel(selectedCountry)}</span>
            <span className="no-date-recorded">{` - ${t('no_date_recorded')} -`}</span>
          </div>
        </>
      )}
    </div>
  );
};
const IconoAcordeon = ({ expanded }) => {
  // Utiliza el componente Arrow o el nuevo ícono según el estado del acordeón
  return expanded ? <ArrowDown /> : <ArrowRight />;
};
const getReviewIdIfExpanded = (isExpanded: boolean, review: any) => (isExpanded ? review.id : false);

const revisionHasNoDates = (revision: any) => revision.startDate === null && revision.endDate === null;

const revisionStatusText = (revision: any, t: Function) =>
  revision.status === ReviewsStatusEnum.Attempt ? t('attempt_date') : t('date');

const expandedClass = (expanded: string | false, revision: any) => (expanded === revision.id ? 'expanded' : '');

const customCountryLabel = (selectedCountry: any) => (selectedCountry ? selectedCountry.optionLabel : '');

const hasRevisions = (revisions: any) => revisions && revisions.length;

const renderDatesData = (revision: any, t: Function) => {
  const dateFormat = 'DD/MMM/YYYY';
  if (revisionHasNoDates(revision)) {
    return <span className="no-date-recorded">{`- ${t('no_date_recorded')} -`}</span>;
  } else {
    return (
      <span className="date-recorded">{`${revisionStatusText(revision, t)}:
 ${replaceMomentFormat(moment(revision.startDate).format(dateFormat))}
 ${replaceMomentFormat(moment(revision.endDate).format(dateFormat))}
`}</span>
    );
  }
};

type SummaryContentProps = {
  expanded: string | false;
  revision: any;
  t: Function;
};

const SummaryContent = ({ expanded, revision, t }: SummaryContentProps) => {
  return isMobile() ? (
    <div className={`heading  ${expandedClass(expanded, revision)}`}>
      <div className="country-name"> {revision.country.name}</div>
      <div className="date-data">{renderDatesData(revision, t)}</div>
    </div>
  ) : (
    <Typography className={`heading  ${expandedClass(expanded, revision)}`}>
      {revision.country.name}
      {renderDatesData(revision, t)}
    </Typography>
  );
};
