import React from 'react';
import styles from './SimpleSearchInput.module.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  handleSearchInput: any;
  inputValue: string;
  customStyle?: any;
};

const SimpleSearchInput = ({ handleSearchInput, inputValue, customStyle }: Props) => {
  const { t } = useTranslation();
  const { searchContainer, icon, input } = styles;

  return (
    <div className={searchContainer} style={customStyle ? customStyle : {}}>
      <div className={icon}></div>
      <input
        type="text"
        className={input}
        onChange={value => handleSearchInput(value)}
        placeholder={t('supervision_search_placeholder')}
        value={inputValue}
      />
    </div>
  );
};

export default SimpleSearchInput;
