import React from "react"
import { useFormMemoryHelpContext } from "../../../context/MemoryHelpFormContext";
import Radio from '@material-ui/core/Radio';
import '../MemoryHelp.scss'

export const TextInputField = ({ placeholder, name, onChange, addClass = '', currentTabId }) => {

    const { formState, errors } = useFormMemoryHelpContext();
    const currentTabData = formState.find(section => section.type === currentTabId) || {};
    const value = currentTabData[name] || '';
    const errorKey = `${name}-${currentTabId}`;
    const error = errors[errorKey];

    return (
        <>
            <input
                className={`${addClass} ${error && "warning-message"}`}
                type="text"
                placeholder={placeholder}
                name={name}
                value={value}
                onChange={onChange} />
            {error && <span className="warning-message">{error}</span>}
        </>
    )
}

export const TextareaField = ({ placeholder, name, onChange, addClass = '', currentTabId }) => {
    const { formState, errors, optionalFields } = useFormMemoryHelpContext();
    const currentTabData = formState.find(section => section.type === currentTabId) || {};
    const value = currentTabData[name] || '';
    const errorKey = `${name}-${currentTabId}`;
    const error = errors[errorKey];

    return (
        <>
            <textarea
                className={`${addClass} ${error && !optionalFields.includes(name) && "warning-message"}`}
                placeholder={placeholder}
                name={name}
                onChange={onChange}
                value={value} />
            {/* Show warning message only if field is not optional */}
            {error && !optionalFields.includes(name) && <span className="warning-message">{error}</span>}
        </>
    );
};

export const RadioInputField = ({ name, value, onChange, formState, currentStage }) => {
    const getCurrentInputOptions = formState.find(section => section.type === currentStage) || {};
    const isChecked = getCurrentInputOptions[name] === value;

    return <Radio name={name} value={value} onChange={onChange} checked={isChecked} />;
};
