import React, { CSSProperties, useState } from 'react';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { ExcelDownloadModal } from '../Modals/ExcelDownloadModal/ExcelDownloadModal';
import useDataNormalize from '../../../hooks/useDataNormalize';
import { ReactComponent as IconDownloadAlt } from '../../../assets/icons/u_download-alt.svg';
import './ExcelDownloadButton.scss';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';


interface Props {
    selectedRow?: string;
    headers: string[];
    selectedKeys: string[];
    addClass?: CSSProperties;
    dataExcel: any;
}

const errorMsg = {
    title: "Ha ocurrido un error",
    subtitleMsg: {
        sub1: "No hemos podido descargar el documento",
        sub2: "Por favor intente nuevamente"
    }
}

const currentDay = new Date().toDateString();

function findProjectsByLabel(data, label) {
    let foundProjects: any[] = [];

    data.forEach(item => {
        // Case 1: 'LON' y 'TCP'
        if ((label === 'LON' || label === 'TCP') && item.label === label && Array.isArray(item.details)) {
            item.details.forEach(subItem => {
                if (Array.isArray(subItem.details)) {
                    foundProjects.push(...subItem.details);
                }
            });
        }
        // Case 2: 'GUA' e 'IGR'
        else if ((label === 'GUA' || label === 'IGR') && item.label === label && Array.isArray(item.details)) {
            foundProjects.push(...item.details);
        }

        else if (Array.isArray(item.projects)) {
            item.details.forEach(subItem => {
                if (subItem.label === label && Array.isArray(subItem.details)) {
                    foundProjects.push(...subItem.projects);
                }
                // Inherit additional cases
                else if (Array.isArray(subItem.projects)) {
                    subItem.details.forEach(innerItem => {
                        if (innerItem.label === label && Array.isArray(innerItem.details)) {
                            foundProjects.push(...innerItem.details);
                        }
                    });
                }
            });
        }
    });

    return foundProjects;
}

export const ExcelDownloadButton: React.FC<Props> = ({ selectedRow, headers, selectedKeys, dataExcel }) => {
    const [error, setError] = useState<string | boolean | null>(null);
    const [showModal, setShowModal] = useState<boolean>(false);
    const { t } = useTranslation();
    // const detailedProjectData = useSelector((state: any) => state.dataTableInfo.detailedProject);
    // const selectedProjects = findProjectsByLabel(detailedProjectData, selectedRow);
    // const data = useDataNormalize(headers, selectedKeys, selectedProjects as any);
    const data = dataExcel;

    // TODO: pendiente funcion para extraer nombre
    const cancelDownload = () => setError(null);

    const transformDataForExcel = (data) => {
        const translatedHeaders = headers.map(header => t(header));

        return data.map(item => {
            let transformedItem = {};
            translatedHeaders.forEach((header, index) => {
                const originalHeader = headers[index];
                transformedItem[header] = item[originalHeader] ?? 'N/A';
            });
            return transformedItem;
        });
    };

    const handleDownload = () => {
        try {
            const wsData = transformDataForExcel(data);
            const ws = XLSX.utils.json_to_sheet(wsData);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "project name");

            const binaryString = XLSX.write(wb, { bookType: 'xls', type: 'binary' });
            const buffer = new ArrayBuffer(binaryString.length);
            const view = new Uint8Array(buffer);

            for (let i = 0; i < binaryString.length; i++) {
                view[i] = binaryString.charCodeAt(i) & 0xFF;
            }

            const blob = new Blob([view], { type: 'application/vnd.ms-excel' });
            saveAs(blob, `${currentDay}.xls`);
            setError(null);
        } catch (error) {
            if (error instanceof Error) {
                setError(errorMsg.title);
            } else {
                setError("Ocurrió un error desconocido al descargar el archivo.");
            }
            setShowModal(true)
        };
    };

    const initiateDownload = () => {
        setShowModal(false);
        setTimeout(() => {
            handleDownload();
        }, 500);
    };

    const retryDownload = () => {
        setError(null);
        initiateDownload();
    };

    const handleCloseModal = () => {
        setShowModal(false)
        setError(null)
    }

    return (
        <>
            <div className="c-donwload-btn c-donwload-btn-prov">
                <button className="btn-primary-text download-excel" onClick={handleDownload}>
                    <IconDownloadAlt /> <span> {t('download_excel')}</span>
                </button>
                {error && (
                    <ExcelDownloadModal
                        isOpen={showModal}
                        closeModal={handleCloseModal}
                        retryDownload={retryDownload}
                        cancelDownload={cancelDownload}
                        errorMsg={errorMsg}
                    />
                )}
            </div>
        </>
    );
}
