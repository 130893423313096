import { axiosDefaultConfig } from './axiosConfig';
import * as config from './config.json';
import Notifications from '../models/Notifications';
import { store } from '../redux/store';
import { SET_NOTIFICATIONS_TOTAL_PAGES } from '../redux/actions/types';
import { getNotificationsFromApi } from '../redux/actions/notifications';

export const markReaded = () => {
  return new Promise<any>(async (resolve, reject) => {
    const url = config.NOTIFICATIONS_MARK_READED;
    try {
      const response = await axiosDefaultConfig.put(url);
      if (response.status === 200) {
        getNotificationsFromApi();
      }
    } catch (error) {}
  });
};

export const getNotifications = (pageNumber: number) => {
  return new Promise<Notifications[]>(async (resolve, reject) => {
    const url = config.NOTIFICATIONS;
    try {
      const notificationParam = {
        pageNumber,
        itemsPerPage: 5,
      };
      const response = await axiosDefaultConfig.post(url, notificationParam);
      store.dispatch({
        type: SET_NOTIFICATIONS_TOTAL_PAGES,
        payload: {
          ...store.getState().notificationsPages,
          totalPages: response.data.pageCount,
        },
      });

      resolve(response.data.collection);
    } catch (error) {
      reject(error);
    }
  });
};
