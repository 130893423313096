import React from 'react';
import { useDispatch } from 'react-redux';
import styles from './CustomHeader.module.scss';
import { showHideLane } from '../../../../../../redux/actions/currentProject';

const isCollapsable = (id: string) => {
  return parseInt(id) !== 2;
};

const CustomHeader = ({ ...data }) => {
  const { title, id } = data;
  const dispatch = useDispatch();

  const handleClick = () => {
    if (isCollapsable(id)) {
      dispatch(showHideLane(parseInt(id), false));
    }
  };
  return (
    <header
      onClick={handleClick}
      className={
        parseInt(id) !== 2 ? styles.clickableLight : parseInt(id) > 3 ? styles.clickableDark : styles.container
      }
    >
      {title}
    </header>
  );
};

export default CustomHeader;
