import React from 'react';
import useGcmAlerts from '../../../../../hooks/useGcmAlerts';
import { ProjectDetailsTab } from '../../../../../models/ProjectDetailsTabEnum';
import styles from './DisbursementChartAlertBar.module.scss';
export const DisbursementChartAlertBar = () => {
  const calledFromTab = ProjectDetailsTab.FINANCIAL;
  const { textAlert, barColor } = useGcmAlerts({ calledFromTab });
  const { alertBar, textAlertFooter } = styles;
  return (
    <div className={alertBar} style={barColor}>
      <div className={textAlertFooter}>{textAlert}</div>
    </div>
  );
};
