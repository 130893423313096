import { EarlyWarningsSectionViewsEnum } from '../../models/EarlyWarnings';
import {
  EarlyWarningButtonType,
  EWProjectResponse,
  PMR_LABELS_ENUM,
  SAFEGUARD_LABELS_ENUM,
  WithColorEnum,
} from '../../models/EarlyWarningsSection';
import {
  dateIsAfterToday,
  filterProjectsBetweenLimitsByDays,
  getSubstractedDateByDays,
  getSubstractedDateByMonths,
  substractDays,
  sumDays,
} from '../PortfolioDetail/EarlyWarningsFilters/Util';
const EARLY_WARNINGS_VIEW = 'early_warnings_view';
const getFilterFromSession = () => {
  const storage = sessionStorage.getItem(EARLY_WARNINGS_VIEW);
  return storage ? storage : EarlyWarningsSectionViewsEnum.PROJECTS;
};

const handleTextOrSlash = value => {
  if (!value) {
    return '-';
  }
  return value;
};

const isRedPmrAlert = (pmrValue: string) => {
  return pmrValue === PMR_LABELS_ENUM.PROBLEM || pmrValue === PMR_LABELS_ENUM.ALERT;
};

const isGreenPmrAlert = (pmrValue: string) => {
  return pmrValue === PMR_LABELS_ENUM.SATISFACTORY;
};

const filterProjectsByAlert = (projects: EWProjectResponse[], earlyWarningType: EarlyWarningButtonType) => {
  return projects.filter(project => {
    return project[earlyWarningType] !== null;
  });
};

const sumPendigSignature = pendigSignatureProjects => {
  let GREEN = 0;
  let YELLOW = 0;
  let RED = 0;
  pendigSignatureProjects.forEach(project => {
    if (project.pendingSignature === null) {
      return;
    }
    if (dateIsAfterToday(new Date(project.pendingSignature))) {
      GREEN++;
    } else if (
      filterProjectsBetweenLimitsByDays(
        new Date(project.pendingSignature),
        getSubstractedDateByDays(364),
        getSubstractedDateByDays(0),
      )
    ) {
      YELLOW++;
    } else if (filterProjectsBetweenLimitsByDays(new Date(project.pendingSignature), getSubstractedDateByDays(365))) {
      RED++;
    }
  });

  return { GREEN, YELLOW, RED };
};

const sumElegibleUnDisbursement = elegibleUnDisbursementProjects => {
  let GREEN = 0;
  let YELLOW = 0;
  let RED = 0;
  elegibleUnDisbursementProjects.forEach(project => {
    if (project.eligibleUnDisbursement === null) {
      return;
    }
    if (
      filterProjectsBetweenLimitsByDays(
        new Date(project.eligibleUnDisbursement),
        sumDays(getSubstractedDateByMonths(1), 1),
        new Date(),
      )
    ) {
      GREEN++;
    } else if (
      filterProjectsBetweenLimitsByDays(
        new Date(project.eligibleUnDisbursement),
        getSubstractedDateByMonths(6),
        getSubstractedDateByMonths(1),
      )
    ) {
      YELLOW++;
    } else if (
      filterProjectsBetweenLimitsByDays(
        new Date(project.eligibleUnDisbursement),
        substractDays(getSubstractedDateByMonths(6), 1),
      )
    ) {
      RED++;
    }
  });

  return { GREEN, YELLOW, RED };
};

const sumLastPmr = lastThreePmrProjects => {
  let GREEN = 0;
  let YELLOW = 0;
  let RED = 0;
  lastThreePmrProjects.forEach(project => {
    const pmrLabelsArray: string[] = project.pmr ? project.pmr.split(',') : [];
    const greenAlert = pmrLabelsArray.some(isGreenPmrAlert) && !pmrLabelsArray.some(isRedPmrAlert);
    if (greenAlert) {
      GREEN++;
    }
    const redAlert = pmrLabelsArray.length === 3 && pmrLabelsArray.every(isRedPmrAlert);
    if (redAlert) {
      RED++;
    }
    const yellowAlert = !redAlert && pmrLabelsArray.some(isRedPmrAlert);
    if (yellowAlert) {
      YELLOW++;
    }
  });

  return { GREEN, YELLOW, RED };
};

const sumesgSafeguard = esgSafeguardProjects => {
  let GREEN = 0;
  let LIGHT_GREEN = 0;
  let YELLOW = 0;
  let RED = 0;
  esgSafeguardProjects.forEach(project => {
    if (project.esgSafeguard === null) {
      return;
    }
    if (project.esgSafeguard === SAFEGUARD_LABELS_ENUM.Satisfactory) {
      GREEN++;
    } else if (project.esgSafeguard === SAFEGUARD_LABELS_ENUM.Partially_Satisfactory) {
      LIGHT_GREEN++;
    } else if (project.esgSafeguard === SAFEGUARD_LABELS_ENUM.Partially_Unsatisfactory) {
      YELLOW++;
    } else if (project.esgSafeguard === SAFEGUARD_LABELS_ENUM.Unsatisfactory) {
      RED++;
    }
  });
  return { GREEN, LIGHT_GREEN, YELLOW, RED };
};

const sumCancelled = cancelledProjects => {
  let GREEN = 0;
  let YELLOW = 0;
  let RED = 0;

  const yellowPercentageLimit = 15;
  cancelledProjects.forEach(project => {
    const percentageNumber = project.cancelled !== null ? parseFloat(project.cancelled) : 0;

    if (project.cancelledWithColor === WithColorEnum.NOT_APPLICABLE) {
      GREEN++;
    }
    if (percentageNumber <= yellowPercentageLimit && percentageNumber > 0) {
      YELLOW++;
    }

    if (percentageNumber > yellowPercentageLimit) {
      RED++;
    }
  });

  return { GREEN, YELLOW, RED };
};

const sumExpired = expiredProjects => {
  let GREEN = 0;
  let YELLOW = 0;
  let RED = 0;

  expiredProjects.forEach(project => {
    if (project.expired === null) {
      return;
    }
    if (filterProjectsBetweenLimitsByDays(new Date(project.expired), getSubstractedDateByDays(120), new Date())) {
      GREEN++;
    } else if (
      filterProjectsBetweenLimitsByDays(
        new Date(project.expired),
        getSubstractedDateByDays(364),
        getSubstractedDateByDays(121),
      )
    ) {
      YELLOW++;
    } else if (filterProjectsBetweenLimitsByDays(new Date(project.expired), getSubstractedDateByDays(365))) {
      RED++;
    }
  });

  return { GREEN, YELLOW, RED };
};

export {
  getFilterFromSession,
  EARLY_WARNINGS_VIEW,
  handleTextOrSlash,
  isRedPmrAlert,
  isGreenPmrAlert,
  filterProjectsByAlert,
  sumPendigSignature,
  sumElegibleUnDisbursement,
  sumLastPmr,
  sumesgSafeguard,
  sumCancelled,
  sumExpired,
};
