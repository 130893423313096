import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Line } from 'rc-progress';
import NumberFormat from 'react-number-format';
import Project from '../../../../../models/Project';
import { Link, useHistory } from 'react-router-dom';
import { showDisbursement } from '../../../../../services/booleanUtil';
import { trackActionClicked } from '../../../../../api/trackActionClickedApi';
import { TrackedActions } from '../../../../../models/TrackedActionsEnum';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import AlertTags from '../AlertTags/AlertTags';

const BlackTooltip = withStyles({
  tooltip: {
    backgroundColor: '#101319',
    fontSize: '16px',
    fontFamily: 'Rubik',
    lineHeight: '20px',
    padding: '8px 12px',
    borderRadius: '8px',
    transformOrigin: 'center',
  },
  arrow: {
    color: '#101319',
    '&::before': {
      borderWidth: '0.5em 0.5em 0 0.5em',
      borderColor: 'transparent transparent transparent #101319',
    },
    transform: 'translateX(-50%)',
    top: '100%',
  },
})(Tooltip);

const renderLastDisbursementInDays = (lastDisbursementInDays, t) => {
  return lastDisbursementInDays ? (lastDisbursementInDays === 1 ? t('day') : t('days')) : '-';
};

const selectableTr = selectable => {
  return selectable ? 'selectable-project-row' : '';
};

interface ReceivedProps {
  project: Project;
  setInFollow: any;
  selectable?: boolean;
  canCreateAccordance?: boolean;
  fromReview?: boolean;
}

type Props = ReceivedProps;

const selectedStyle = (selected = false) => {
  return selected ? { backgroundColor: 'rgba(235, 236, 240, 0.5)' } : {};
};

const fromReviewObj = (fromReview = false) => (fromReview ? { fromReview: true } : {});

export const TableRowExpanded = (props: Props) => {
  const { project, selectable, canCreateAccordance, fromReview } = props;
  const reviewProjectsSelectedRows = useSelector<any, any>(state => state.reviewProjectsSelectedRows);
  const { filter } = useSelector<any, any>(state => state);
  const [isSelected, setIsSelected] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const history = useHistory();

  const { tags } = project;

  const handleClick = () => {
    const state = fromReviewObj(fromReview);
    history.push(`/projectdetail?id=${project.id}`, state);
    trackActionClicked(TrackedActions.projectDetailsFromListClicked);
  };

  React.useEffect(() => {
    setIsSelected(reviewProjectsSelectedRows.includes(project.id));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewProjectsSelectedRows]);

  return (
    <>
      <table className="row-expanded">
        <tr>
          <td>{t('temporary_advance')}</td>
          <td>{t('amount_disbursed')}</td>
          <td>{t('filter_alert')}</td>
        </tr>
        <tr key={project.id} onClick={handleClick} className={selectableTr(selectable)} style={{ cursor: 'pointer' }}>
          <td style={selectedStyle(isSelected)} className="project-aligned">
            {renderTemporaryAdvance(project, t)}
          </td>
          {!canCreateAccordance && (
            <td style={selectedStyle(isSelected)} className="project-aligned">
              {project.financialInformation.lastDisbursementInDays}
              {renderLastDisbursementInDays(project.financialInformation.lastDisbursementInDays, t)}
            </td>
          )}
          <td style={selectedStyle(isSelected)} className="project-aligned">
            {renderDisbursementAmount(project, filter, t)}
          </td>
          {showPendingDisbursementAmount(canCreateAccordance) && (
            <td style={selectedStyle(isSelected)} className="project-aligned">
              <NumberFormat
                value={project.financialInformation.pendingDisbursementAmount}
                displayType={'text'}
                thousandSeparator={'.'}
                decimalSeparator={','}
                suffix={t('dolar_sign')}
              />
            </td>
          )}
          <td className="tags">
            <AlertTags tags={tags} />
          </td>
        </tr>
      </table>
    </>
  );
};

const renderTemporaryAdvance = (project, t) => {
  return project.temporaryAdvance ? (
    <BlackTooltip
      title={
        project.temporaryAdvance.temporaryAdvanceMade +
        (project.temporaryAdvance.temporaryAdvanceMade === 1 ? t('month') : t('months'))
      }
      placement="bottom-start"
      arrow
    >
      <div className="line-container">
        <Line
          className="adjust-project-graphs"
          percent={project.temporaryAdvance.percentTemporaryAdvanceMade}
          strokeWidth={12}
          trailWidth={12}
          strokeColor="#538CFF"
          trailColor="#D0D5E0"
        />
        <span className="ci-type-text-8">
          {project.temporaryAdvance.temporaryAdvanceMade +
            (project.temporaryAdvance.temporaryAdvanceMade === 1 ? t('month') : t('months'))}
        </span>
      </div>
    </BlackTooltip>
  ) : (
    '-'
  );
};

const renderDisbursementAmount = (project, filter, t) => {
  return showDisbursement(project, filter) ? (
    <BlackTooltip
      title={project.financialInformation.percentRealizedDisbursement + t('percentage_sign')}
      placement="bottom-start"
      enterDelay={500}
      leaveDelay={200}
      arrow
    >
      <div className="line-container">
        <Line
          className="adjust-project-graphs"
          percent={project.financialInformation.percentRealizedDisbursement}
          strokeWidth={12}
          trailWidth={12}
          strokeColor="#538CFF"
          trailColor="#D0D5E0"
        />
        <span className="ci-type-text-8">
          {project.financialInformation.percentRealizedDisbursement + t('percentage_sign')}
        </span>
      </div>
    </BlackTooltip>
  ) : (
    '-'
  );
};

const showPendingDisbursementAmount = canCreateAccordance => {
  if (!canCreateAccordance) {
    return true;
  }
  return false;
};
