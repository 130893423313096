import React from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import FilterIndicators from '../../../../../models/FilterIndicators';
import { multiSelectStyle, DropdownIndicator } from '../../../../Filter/FilterComponent.Styles';
import { useDivisions } from '../../../../../hooks/useDivisions';

type Props = {
  reportFilters: FilterIndicators;
  reportFiltersChanged: Function;
};
export const ParticipantDivisionFilter = ({ reportFilters, reportFiltersChanged }: Props) => {
  const { t } = useTranslation();
  const [localFilter, setLocalFilter] = React.useState(reportFilters);
  const divisionOptions = useDivisions();

  const handleChange = value => {
    if (value === null) {
      value = [];
    }

    const newFilter = {
      ...localFilter,
      common: {
        ...localFilter.common,
        participantDivisions: value,
      },
    };

    setLocalFilter(newFilter);
    reportFiltersChanged({ participantDivisions: newFilter.common.participantDivisions });
  };

  return (
    <Select
      id="participantDivisionsSelect"
      defaultValue={localFilter.common.participantDivisions}
      isMulti
      components={{ DropdownIndicator }}
      name="participantDivisions"
      options={divisionOptions}
      className="basic-multi-select"
      classNamePrefix="All"
      placeholder={t('all')}
      onChange={value => handleChange(value)}
      styles={multiSelectStyle}
    />
  );
};
