import { isMobile } from './../../../services/booleanUtil';
import { capitalizeFirstLetter } from '../../../services/stringUtil';

const getPercentageValue = (i, currentIndicator, t) => {
  let valueIndex;
  if (i) {
    valueIndex = currentIndicator.labels.findIndex(l => capitalizeFirstLetter(t(l).toLowerCase()) === i[0]);
  }
  return currentIndicator.datasets[0].gap[valueIndex];
};

const getTooltipTitle = (title, t) => t(title);

export const getCustomOptionsChart = (currentIndicator, t, monthToBackgroundChart) => {
  return {
    onHover: (event, chartElement) => {
      if (chartElement.length) {
        event.target.style.cursor = 'pointer';
      } else {
        event.target.style.cursor = 'default';
      }
    },
    annotation: {
      annotations: [
        {
          backgroundColor: '#F1F3FF',
          drawTime: 'beforeDatasetsDraw',
          cornerRadius: 0,
          type: 'box',
          xScaleID: 'x-axis-0',
          xMin: monthToBackgroundChart - 0.5,
          xMax: monthToBackgroundChart + 0.5,
        },
      ],
    },
    plugins: {
      datalabels: { display: false },
    },
    tooltips: {
      enabled: false,
      backgroundColor: '#101319',
      titleFontSize: 14, 
      bodyFontSize: 14,
      custom: tooltipModel => customTooltip(tooltipModel, currentIndicator, t),
    },
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          stacked: false,
          ticks: {
            beginAtZero: true,
            fontSize: 16,
            fontStyle: 400,
          },
          gridLines: {
            display: true,
          },
        },
      ],
      yAxes: [
        {
          stacked: false,
          ticks: {
            beginAtZero: true,
            fontSize: 16,
            fontStyle: 400,
            display: true,
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
    cornerRadius: 9,
  };
};

export interface IHeaderSingleValue {
  value: number;
  text: string;
  extraStyle?: Object;
  isMoney?: boolean;
  thousandDot?: boolean;
}

export const headerValuesEmpty: IHeaderSingleValue[] = [
  {
    value: 0,
    text: 'planned_activities',
    thousandDot: true,
  },
  {
    value: 0,
    text: 'activities_completed',
    extraStyle: isMobile() ? {} : { borderRight: '1px solid #CED1D8' },
    thousandDot: true,
  },
  {
    value: 0,
    text: 'planned_critical_products',
    thousandDot: true,
  },
  {
    value: 0,
    text: 'critical_products_finished',
    extraStyle: isMobile() ? {} : { borderRight: '1px solid #CED1D8' },
    thousandDot: true,
  },
  {
    value: 0,
    text: 'planned_budget',
    thousandDot: true,
  },
  {
    value: 0,
    text: 'budget_spent',
    thousandDot: true,
  },
];

export const setInnerHtml = (titleLines, bodyLines, tooltipModel, currentIndicator, t) => {
  let innerHtml = '<div ">';
  titleLines.forEach(function () {
    innerHtml += `<div style="background:#264377; color:#fff; font-weight:600; font-size: 11px; border-radius: 4px 4px 0 0;
          height:19px; border: 1px solid #979797; text-align: center; padding-top: 3px; ">${getTooltipTitle(
            'gap',
            t,
          )}</div>`;
  });
  innerHtml += '</div><div>';
  bodyLines.forEach(function () {
    innerHtml += ` <div style="background:#fff; color:#666666; font-weight:700; font-size: 45px;
          border-radius: 0 0 4px 4px;border: 1px solid #979797; border-top: none;text-align: center; ">${getPercentageValue(
            tooltipModel.title,
            currentIndicator,
            t,
          )}<span style="font-size: 18px; vertical-align: middle; ">%</span></div>`;
  });
  innerHtml += '</div>';

  return innerHtml;
};

export const customTooltip = (tooltipModel, currentIndicator, t) => {
  let tooltipEl = document.getElementById('chartjs-tooltip');
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'chartjs-tooltip';
    tooltipEl.innerHTML = '<div style="width:80px; height: 70px; font-family: "Source Sans Pro" sans-serif;  "></div>';
    document.body.appendChild(tooltipEl);
  }

  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = '0';
    return;
  }

  tooltipEl.classList.remove('above', 'below', 'no-transform');
  if (tooltipModel.yAlign) {
    tooltipEl.classList.add(tooltipModel.yAlign);
  } else {
    tooltipEl.classList.add('no-transform');
  }

  function getBody(bodyItem) {
    return bodyItem.lines;
  }

  if (tooltipModel.body) {
    const titleLines = tooltipModel.title || [];
    const bodyLines = tooltipModel.body.map(getBody);

    const innerHtml = setInnerHtml(titleLines, bodyLines, tooltipModel, currentIndicator, t);

    const checkNotNull = tooltipEl => {
      if (tooltipEl.querySelector('div')) {
        return tooltipEl.querySelector('div');
      }
      return null;
    };

    const tableRoot = checkNotNull(tooltipEl);
    tableRoot.innerHTML = innerHtml;
  }

  const position = document.getElementsByClassName('chartjs-render-monitor')[0].getBoundingClientRect();
  tooltipEl.style.opacity = getPercentageValue(tooltipModel.title, currentIndicator, t) === 100 ? '0' : '1';
  setTooltipElStyles(tooltipEl, tooltipModel, currentIndicator, t, position);
};

export const setTooltipElStyles = (tooltipEl, tooltipModel, currentIndicator, t, position) => {
  tooltipEl.style.position = 'absolute';
  tooltipEl.style.left = `${position.left + window.pageXOffset + tooltipModel.caretX}px`;
  tooltipEl.style.top = `${position.top + window.pageYOffset + tooltipModel.caretY}px`;
  tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
  tooltipEl.style.fontSize = `${tooltipModel.bodyFontSize}px`;
  tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
  tooltipEl.style.padding = `${tooltipModel.yPadding}px ${tooltipModel.xPadding}px}`;
  tooltipEl.style.pointerEvents = 'none';

  return tooltipEl;
};

export const filterDatasets = (currentIndicator, key, filterIsActivity) => {
  let validDatasets;

  if (!currentIndicator.data) {
    return [];
  }

  if (filterIsActivity) {
    validDatasets = [...currentIndicator[key].datasets.slice(0, 2)];
  } else {
    validDatasets = [...currentIndicator[key].datasets.slice(2, 4)];
  }
  return {
    ...currentIndicator,
    [key]: {
      ...currentIndicator[key],
      datasets: validDatasets,
    },
  };
};
