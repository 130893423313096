import React from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { map, find } from 'lodash';
import { postDelegationListItem, updateDelegationListItem } from '../../../../api/contentManager';
import { getDefaultOption } from '../../../Util/ContentManager/ContentManager';
import { multiSelectStyle, DropdownIndicator } from '../../../Filter/FilterComponent.Styles';
import { MultiSearchUser } from '../../../Util/MultiSearchUser/MultiSearchUser';

type Props = {
  handleCloseModal: any;
  setErrorMessage: any;
  updateTable: any;
  isEdit?: any;
  row?: any;
};

const getFunctionalities = t => [
  { id: 1, value: '1', label: `${t('content_manager_delegations_accordances')}` },
  { id: 2, value: '2', label: `${t('content_manager_delegations_revisions')}` },
];

const getModalButtonText = isEdit =>
  isEdit ? 'content_manager_delegations_modal_title_edit' : 'content_manager_delegations_modal_title';

const DelegationsFormModal = ({ handleCloseModal, setErrorMessage, updateTable, isEdit, row }: Props) => {
  const { t } = useTranslation();
  const [user, setUser] = React.useState<any>(
    isEdit
      ? {
          id: row.userId,
          value: `${row.userId}`,
          label: row.userName,
        }
      : getDefaultOption('contents_select_placeholder', t),
  );
  const [delegatedUser, setDelegatedUser] = React.useState<any>(
    isEdit
      ? {
          id: row.delegatedUserId,
          value: `${row.delegatedUserId}`,
          label: row.delegatedUserName,
        }
      : getDefaultOption('contents_select_placeholder', t),
  );
  const [functionality, setFunctionality] = React.useState<any>(
    isEdit
      ? row.permissions.map(
          permission =>
            ({
              id: permission.id,
              value: `${permission.id}`,
              label: find(getFunctionalities(t), auxPermission => auxPermission.id === permission.id),
            }.label),
        )
      : null,
  );

  const handleDelegationSubmit = async () => {
    try {
      const postItem = {
        fromUserId: user.id,
        toUserId: delegatedUser.id,
        permissionIds: map(functionality, permission => permission.id),
      };

      if (isEdit) {
        await updateDelegationListItem({ ...postItem, id: row.id });
      } else {
        await postDelegationListItem(postItem);
      }

      updateTable();
      handleCloseModal(false);
    } catch {
      setErrorMessage(true);
    }
  };

  const getDisabledClass = () => (user.id === 0 || delegatedUser.id === 0 || !functionality) && 'disabled';

  return (
    <>
      <div className="content-manager-modal__options" style={{ marginTop: 0 }}>
        <div className="label-select-container" style={{ width: '250px' }}>
          <p className="label">{t('content_manager_delegations_from')}</p>
          <MultiSearchUser
            delegatedUsers={isEdit ? user : null}
            isMulti={false}
            countryFiltered={false}
            returnUsersSelected={user => {
              setUser(user);
            }}
          />
        </div>
        <div className="label-select-container" style={{ width: '250px', marginLeft: '5px' }}>
          <p className="label">{t('content_manager_delegations_to')}</p>
          <MultiSearchUser
            delegatedUsers={isEdit ? delegatedUser : null}
            isMulti={false}
            countryFiltered={false}
            returnUsersSelected={user => {
              setDelegatedUser(user);
            }}
            isDisabled={user.id === 0}
          />
        </div>
        <div className="label-select-container" style={{ width: '250px', marginLeft: '5px' }}>
          <p className="label">{t('content_manager_functionality')}</p>
          <Select
            id="functionality"
            components={{ DropdownIndicator }}
            value={functionality}
            options={getFunctionalities(t)}
            className="basic-multi-select"
            classNamePrefix="All"
            placeholder={t('contents_select_placeholder_search')}
            onChange={value => setFunctionality(value)}
            styles={multiSelectStyle}
            isMulti
            noOptionsMessage={() => t('no_option_message')}
          />
        </div>
      </div>
      <div className="content-manager-modal__button-group">
        <button
          className={`content-manager-modal__button ${getDisabledClass()}`}
          onClick={() => handleDelegationSubmit()}
          disabled={user.id === 0 || delegatedUser.id === 0 || !functionality}
        >
          {t(getModalButtonText(isEdit))}
        </button>
        <button
          style={{ width: '195px' }}
          className="content-manager-modal__button--secondary"
          onClick={() => handleCloseModal()}
        >
          {t('content_manager_leaves_close_button_label')}
        </button>
      </div>
    </>
  );
};

export default DelegationsFormModal;
