import React from 'react';
import './KeyProductHeader.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  forReport?: boolean;
};
export const KeyProductHeader = ({ forReport }: Props) => {
  const { t } = useTranslation();
  return (
    <div className={`key-product-header-container ${forReport ? 'for-report' : ''}`}>
      <div className="title">{t('key_product')}</div>
      <div className="title">{t('annual_physical_goal')} </div>
      <div className="title">{t('annual_financial_goal')} </div>
    </div>
  );
};

export const NoData = () => {
  const { t } = useTranslation();
  return (
    <div className="kp-no-data-container">
      <span className="text principal">{t('key_product_no_data_1')}</span>
      <span className="text">{t('key_product_no_data_2')}</span>
    </div>
  );
};
