import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { useDispatch } from 'react-redux';
import MyWeek from './MyWeek';
import MyEvent from './MyEvent';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss';
import { editAgendaTime } from '../../../../api/reviewsApi';
import { ReviewsStatusEnum } from '../../../../models/ReviewsStatusEnum';
import { setCurrentReview } from '../../../../redux/actions/currentReview';
import { availableTimeSlot, validDuration } from '../util';

const localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(Calendar);

type Props = {
  events: any[];
  currentReview: any;
};

const DndCalendar = ({ events, currentReview }: Props) => {
  const [reviewEvents, setEvents] = useState<any[]>(events);
  const dispatch = useDispatch();

  useEffect(() => {
    setEvents(events);
  }, [events]);

  const updateProjectsTime = nextEvents => {
    const eventsToEdit = nextEvents.map(ev => {
      return { id: ev.id, newStartDate: ev.start.toISOString(), newEndDate: ev.end.toISOString() };
    });
    editAgendaTime(currentReview.id, eventsToEdit);
  };

  const editEventTime = async ({ event, start, end }) => {
    if (availableTimeSlot(reviewEvents, start, end, event.id) && validDuration(start, end)) {
      const nextEvents = reviewEvents.map(existingEvent => {
        return existingEvent.id === event.id ? { ...existingEvent, start, end } : existingEvent;
      });
      setEvents(nextEvents);
      updateProjectsTime(nextEvents);
    }
  };

  const customSlotPropGetter = date => {
    return date.getHours() === 18
      ? {
          style: { zIndex: -1, border: 'none' },
        }
      : {};
  };

  const changeEventStatus = async event => {
    const newStatus =
      event.status === ReviewsStatusEnum.Attempt ? ReviewsStatusEnum.Confirmed : ReviewsStatusEnum.Attempt;

    const nextEvents = reviewEvents.map(existingEvent => {
      if (existingEvent.id === event.id) {
        existingEvent.status = newStatus;
      }
      return existingEvent;
    });

    setEvents(nextEvents);

    const response = await editAgendaTime(currentReview.id, [
      {
        id: event.id,
        status: newStatus,
        newStartDate: event.start.toISOString(),
        newEndDate: event.end.toISOString(),
      },
    ]);
    dispatch(setCurrentReview({ ...response, title: currentReview.title }));
  };

  return (
    <DragAndDropCalendar
      toolbar={false}
      selectable={false}
      localizer={localizer}
      events={reviewEvents}
      onEventDrop={editEventTime}
      onEventResize={editEventTime}
      onDragStart={console.log}
      defaultView={Views.WEEK}
      views={{ month: false, week: MyWeek }}
      defaultDate={new Date(currentReview.startDate)}
      popup={true}
      min={new Date(2010, 9, 19, 9, 0, 0)}
      max={new Date(2050, 9, 28, 18, 0, 0)}
      step={15}
      slotPropGetter={customSlotPropGetter}
      components={{ event: MyEvent }}
      onSelectEvent={changeEventStatus}
      formats={{ timeGutterFormat: date => localizer.format(date, 'h A') }}
    />
  );
};

export default DndCalendar;
