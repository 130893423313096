import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import Indicator from '../../../models/Indicator';
import { showChart, mapClassification, TabPanel } from '../PortfolioDetailsUtil';
import { WorkWithChart } from '../WorkWithChart/WorkWithChart';
import './MobileSimpleChart.scss';

interface ReceivedProps {
  currentIndicator: Indicator;
  filterValues: any;
  showDisbursed: any;
  setActiveTab: number;
  pmrClassification: any;
  division: any;
  totalProjects: any;
  indicatorKey: any;
  dataDate: any;
  indicatorId: any;
  dataIndex?: number;
  selectedCircle?: any;
}

export default function MobileSimpleChart(props: ReceivedProps) {
  const {
    currentIndicator,
    filterValues,
    showDisbursed,
    setActiveTab,
    pmrClassification,
    division,
    totalProjects,
    dataDate,
    selectedCircle,
  } = props;

  const theme = useTheme();
  const [value, setValue] = React.useState(setActiveTab);

  React.useEffect(() => {
    setValue(setActiveTab);
  }, [setActiveTab]);

  return (
    <div className="react-swipeable-view-container">
      <SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={value}>
        <TabPanel value={value} index={0} dir={theme.direction}>
          {showChart(currentIndicator, filterValues, showDisbursed, selectedCircle)}
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <WorkWithChart
            pmrClassification={mapClassification(pmrClassification)}
            division={division}
            totalProjects={totalProjects}
            indicatorKey={currentIndicator.indicatorKey}
            dataDate={dataDate}
            indicatorId={currentIndicator.id}
          />
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}
