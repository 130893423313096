import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { parseFilterToApiCall } from '../../../../services/util';
import { translateMultipleText } from '../../../../services/stringUtil';
import { useTranslation } from 'react-i18next';
import {
  getProjectsByAlertProblemPmr,
  getProjectsSimpleTableIndicatorForSummaryIndicator,
} from '../../../../redux/actions/indicatorProjects';
import { PmrClassification } from '../../../../models/PmrClassification';
import { IncludeRegionalProjectsEnum } from '../../../../models/IncludeRegionalProjectsEnum';
import { HeaderDrops } from './HeaderDrops/HeaderDrops';
import { TableData } from './TableData/TableData';
import { handleTitleValue } from './Util';
import { ProjectCounter } from '../../ProjectCounter/ProjectCounter';
import { ProjectProductTypes } from '../../../../models/ProjectProductTypes';
import {
  addSessionFilter,
  checkIfSessionFilterExist,
  getFilterSessionValue,
} from '../../../../services/sessionFiltersIndicatorDetailHelper';
import LoanFilter from './LoanFilter/LoanFilter';
import { getNewFilteredIndicators } from '../../../../api/indicatorApi';
import { processIndicators } from '../../../Portfolio/indicatorHelper';
import { updateCurrentIndicatorFromDetail } from '../../../../redux/actions/currentIndicator';
import { store } from '../../../../../src/redux/store';

export interface SummaryLoanInternalFilter {
  alertProblemSummaryFilterApplied: boolean;
  includeRegionalProjectsApplied: boolean;
  optionSelectedApplied: IncludeRegionalProjectsEnum;
}
type Props = {
  currentIndicator: any;
  headerTitle: any;
  openModal?: boolean;
  closeModal?: Function;
};
export const SummaryLoan = ({ currentIndicator, headerTitle, openModal = false, closeModal = () => {} }: Props) => {
  const { id: currentIndicatorId, indicatorKey } = currentIndicator;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { indicatorProjects, filterIndicators } = useSelector<any, any>(state => state);
  const filterState = store.getState().filterIndicators.common;
  const disabledFilter = filterState.excludeRegionals;

  const loanFiltersStorage = localStorage.getItem('summaryIndicatorIncludeRegionalSetup');
  const objectLoanFilterStorage = loanFiltersStorage && { ...JSON.parse(loanFiltersStorage) };

  // Validation considering the exclude regional filter.
  const [filterApplied, setFilterApplied] = useState(
    checkIfSessionFilterExist('loanFilters')
      ? { loanFilters: getFilterSessionValue('loanFilters') }
      : {
          loanFilters: {
            alertProblemSummaryFilterApplied: false,
            includeRegionalProjectsApplied:
              objectLoanFilterStorage &&
              objectLoanFilterStorage.includeRegionalProjectsApplied &&
              !filterState.excludeRegionals
                ? objectLoanFilterStorage.includeRegionalProjectsApplied
                : objectLoanFilterStorage &&
                  objectLoanFilterStorage.includeRegionalProjectsApplied &&
                  filterState.excludeRegionals
                ? false
                : objectLoanFilterStorage &&
                  !objectLoanFilterStorage.includeRegionalProjectsApplied &&
                  !filterState.excludeRegionals
                ? true
                : null,
            optionSelectedApplied:
              objectLoanFilterStorage && objectLoanFilterStorage.optionSelectedApplied && !filterState.excludeRegionals
                ? objectLoanFilterStorage.optionSelectedApplied
                : IncludeRegionalProjectsEnum.INCLUDE_UDR,
          },
        },
  );

  useEffect(() => {
    headerTitle(
      true,
      translateMultipleText(
        `${t('loans')} ${handleTitleValue(
          filterApplied.loanFilters.alertProblemSummaryFilterApplied
            ? filterApplied.loanFilters.alertProblemSummaryFilterApplied
            : false,
        )}`,
        t,
      ),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getData(filterApplied.loanFilters.alertProblemSummaryFilterApplied, {
      dispatch,
      currentIndicatorId,
      filterApplied,
    });
    headerUpdateData(
      filterApplied,
      {
        dispatch,
        indicatorKey,
      },
      filterIndicators,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterApplied]);

  const handleFilterIncludeRegionalsProjects = () => {
    const newFilterValues = {
      ...filterApplied.loanFilters,
      includeRegionalProjectsApplied: !filterApplied.loanFilters.includeRegionalProjectsApplied,
      optionSelectedApplied: filterApplied.loanFilters.includeRegionalProjectsApplied
        ? IncludeRegionalProjectsEnum.NO_INCLUDE_PROJECTS
        : IncludeRegionalProjectsEnum.INCLUDE_COUNTRY_ADMIN,
    };

    setFilterApplied({ loanFilters: newFilterValues });
    addSessionFilter({ loanFilters: newFilterValues });
    localStorage.setItem(
      'summaryIndicatorIncludeRegionalSetup',
      JSON.stringify({
        includeRegionalProjectsApplied: newFilterValues.includeRegionalProjectsApplied,
        optionSelectedApplied: newFilterValues.optionSelectedApplied,
      }),
    );
  };

  const handleIncludeRegionalOptionSelected = selectedValue => {
    const newFilterValues = {
      ...filterApplied.loanFilters,
      optionSelectedApplied: selectedValue,
    };
    setFilterApplied({ loanFilters: newFilterValues });
    addSessionFilter({ loanFilters: newFilterValues });
  };

  const handleFilterAlertProblemClick = () => {
    const newFilterValues = {
      ...filterApplied.loanFilters,
      alertProblemSummaryFilterApplied: !filterApplied.loanFilters.alertProblemSummaryFilterApplied,
    };
    setFilterApplied({ loanFilters: newFilterValues });
    addSessionFilter({ loanFilters: newFilterValues });
    headerTitle(
      true,
      translateMultipleText(
        `${t('loans')} ${filterApplied.loanFilters.alertProblemSummaryFilterApplied ? '' : 'on_alert_and_problem'}`,
        t,
      ),
    );
  };

  return (
    <div>
      <LoanFilter
        handleFilterIncludeRegionalsProjects={handleFilterIncludeRegionalsProjects}
        handleIncludeRegionalOptionSelected={handleIncludeRegionalOptionSelected}
        handleFilterAlertProblemClick={handleFilterAlertProblemClick}
        filterApplied={filterApplied.loanFilters}
        closeModal={closeModal}
        isOpen={openModal}
        disabled={disabledFilter}
      />

      <HeaderDrops currentIndicator={currentIndicator} filterApplied={filterApplied.loanFilters} />
      <TableData tableHeaderLabelToChange={'operation'} />
      {/* <ProjectCounter
        arrayToCount={indicatorProjects}
        countProjects={true}
        indicatorKey={indicatorKey}
        widthStyle={'95%'}
      /> */}
    </div>
  );
};

const getData = (filterAlertProblem, { dispatch, currentIndicatorId, filterApplied }) => {
  !filterAlertProblem
    ? getFilteredSimpleIndicators(dispatch, currentIndicatorId, filterApplied)
    : getFilteredPmrProjects(dispatch, currentIndicatorId, filterApplied);
};

const getFilteredPmrProjects = (d, currentIndicatorId, filterApplied) => {
  const { optionSelectedApplied } = filterApplied.loanFilters;
  return d(
    getProjectsByAlertProblemPmr(
      [PmrClassification.Red, PmrClassification.Yellow],
      currentIndicatorId,
      optionSelectedApplied,
    ),
  );
};

const headerUpdateData = async (filterApplied, { dispatch, indicatorKey }, filterIndicators) => {
  const { optionSelectedApplied } = filterApplied.loanFilters;
  const options = { optionSelectedApplied };
  const indicators = await getNewFilteredIndicators(
    options,
    parseFilterToApiCall(filterIndicators.common.projectTypeFilters),
  );
  const indicatorsProc = processIndicators(indicators, {
    titleFromTab: '',
    projectTypeFilter: [0, 2, 3],
  });
  const selectIndicatorProc = indicatorsProc.find(item => item.indicatorKey === indicatorKey);
  selectIndicatorProc && dispatch(updateCurrentIndicatorFromDetail(selectIndicatorProc));
};
const getFilteredSimpleIndicators = (d, currentIndicatorId, filterApplied) => {
  const { optionSelectedApplied } = filterApplied.loanFilters;
  return d(
    getProjectsSimpleTableIndicatorForSummaryIndicator(currentIndicatorId, {
      projectProductTypes: [ProjectProductTypes.Loan],
      beneficiaryTypes: [],
      igLowerGreater: [],
      includeRegionalProjects: optionSelectedApplied,
    }),
  );
};
