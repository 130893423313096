import { IndicatorProjectsActionTypes, SET_INDICATOR_PROJECTS, RESET_INDICATOR_PROJECTS } from '../actions/types';
import Project from '../../models/Project';

const initialIndicatorProjects: Project[] = [];

const indicatorProjectsReducer = (
  state = initialIndicatorProjects,
  action: IndicatorProjectsActionTypes,
): Project[] => {
  switch (action.type) {
    case SET_INDICATOR_PROJECTS:
      return action.payload;
    case RESET_INDICATOR_PROJECTS:
      return initialIndicatorProjects;
    default:
      return state;
  }
};

export default indicatorProjectsReducer;
