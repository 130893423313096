import React from 'react';
import styles from '../SupervisionDetailForm.module.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  isEdit: boolean;
  formHasChanges: boolean;
  handleActivitySubmit: Function;
  handleCloseModal: Function;
  showCloseButton?: boolean;
};

const SupervisionModalFooter = ({
  isEdit,
  formHasChanges,
  handleActivitySubmit,
  handleCloseModal,
  showCloseButton,
}: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.modalFooter}>
        {isEdit && (
          <button
            style={{ width: '174px' }}
            className={formHasChanges ? styles.saveButton : styles.saveButtonDisabled}
            onClick={() => handleActivitySubmit()}
            disabled={!formHasChanges}
          >
            {t('save')}
          </button>
        )}

        {showCloseButton && (
          <button style={{ width: '146px' }} className={styles.closebutton} onClick={() => handleCloseModal()}>
            {t('close')}
          </button>
        )}
      </div>
    </>
  );
};

export default SupervisionModalFooter;
