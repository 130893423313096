import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './PmrValidationStateTable.module.scss';
import { PmrValidationStateEnum } from '../../../../models/PmrValidationState';
import { capitalizeFirstLetter, renderDateOrSlash } from '../../../../services/stringUtil';
import { PmrClassificationComponent } from '../../../Util/PmrClassification/PmrClassification';
import { PmrPrintValue } from '../../../Util/PmrPrintValue/PmrPrintValue';
import { Pagination } from '@material-ui/lab';
import '../../../Util/Pagination/Pagination.scss';
import usePaginationSort from '../../../../hooks/usePaginationSort';
import { BidTooltip } from '../../../Util/Tooltip/Tooltip';
import useTable from '../../../../hooks/useTable';
import { CriteriaType } from '../../../../models/PaginationIndicator';
import { Tooltip } from '@material-ui/core';
import { ExcelDownloadButton } from '../../../Util/ExcelDownloadButton/ExcelDownloadButton';
import useDataNormalize from '../../../../hooks/useDataNormalize';
import PaginationTable from '../../PaginationTable/PaginationTable';
import { withStyles } from '@material-ui/core/styles';
import { FilterTables } from '../../../Filter/FilterTables/FilterTables';

const BlackTooltip = withStyles({
  tooltip: {
    //   color: '$ci-color-greyscale-90',
    backgroundColor: '#101319',
    fontSize: '16px',
    fontFamily: 'Rubik',
    fontWeight: '400',
    lineHeight: '20px',
    padding: '8px 12px',
    borderRadius: '8px',
  },
  arrow: {
    fontSize: 20,
    color: '#4A4A4A',
    '&::before': {
      backgroundColor: '#101319',
    },
  },
})(Tooltip);

const headers = [
  'id',
  'last_project',
  'last_operation',
  'name',
  'status',
  'date_of_last_status_change',
  'pmr_validation_initials',
];

const selectedKeys = ['id', 'code', 'loanNumber', 'title', 'field2', 'field3', 'field5'];

export const PmrValidationStateTable = () => {
  const { t } = useTranslation();
  const { handleClick, projects } = useTable();
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [dataFilter, setDataFilter] = useState<any>(projects);

  useEffect(() => {
    setDataFilter(projects);
  }, [projects]);

  const { handlePaginationChange, handleSort, currentList, pagesCount, currentPage } = usePaginationSort({
    projects: dataFilter,
    defaultSortCriteria: 'field2',
    pageCount: rowsPerPage,
  });

  //TODO - Faltan datos - estados de checklist validation
  // const [modalIsOpen, setModalIsOpen] = useState(false);
  // const [selectedProject, setSelectedProject] = useState({ code: 0, id: 0 });

  const {
    container,
    tableHeader,
    tableRow,
    headerTitle,
    rowValue,
    rowValueCentered,
    rowList,
    rowValueName,
    fw,
    lastColumn,
    rowValueDate,
    pmrColumnAdjustSign,
    pmrColumnAdjustValue,
    //checklistValidationColumnAdjustValue,
    headerTitleClickable,
    headerTitleClickableCentered,
    borderTable,
  } = styles;

  //TODO - Faltan datos columna checklist validation - esta función se utiliza para abrir el modal en dicha columna.
  // const handleCheklistValidationClick = project => {
  //   if (
  //     parseInt(project.field4) === ChecklistValidationEnum.Success ||
  //     project.field4 === null ||
  //     parseInt(project.field4) > ChecklistValidationEnum.Forbidden
  //   ) {
  //     return;
  //   }
  //   setSelectedProject(project);
  //   setModalIsOpen(true);
  // };
  const data = useDataNormalize(headers, selectedKeys, dataFilter);

  return projects.length ? (
    <div className={container}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <ExcelDownloadButton headers={headers} selectedKeys={selectedKeys} dataExcel={data} />
        <FilterTables
          projects={projects}
          keysFilter={selectedKeys}
          fnDataFilter={setDataFilter}
          disabledNumber={currentPage}
          indicatorKey={'PMR_VALIDATION_STATE'}
        />
      </div>
      <div className={borderTable}>
        <div className={tableHeader}>
          <h4 className={headerTitleClickable} onClick={() => handleSort('code', CriteriaType.string)}>
            {t('project')}
          </h4>
          <h4 className={headerTitleClickable} onClick={() => handleSort('loanNumber', CriteriaType.string)}>
            {t('operation')}
          </h4>
          <h4 className={headerTitleClickable} onClick={() => handleSort('loanNumber', CriteriaType.string)}>
            {t('name')}
          </h4>
          {/* <h4 className={headerTitleClickable} onClick={() => handleSort('field1')}>
          {t('focal_point_review')}
        </h4> */}
          <h4 className={headerTitleClickable} onClick={() => handleSort('field2', CriteriaType.string)}>
            {t('status')}
          </h4>
          <h4 className={headerTitleClickable} onClick={() => handleSort('field3', CriteriaType.string)}>
            {t('date_of_last_status_change')}
          </h4>
          {/*TODO -Faltan datos columna checklist validation
         <h4 className={headerTitleClickable} onClick={() => handleSort('field4')}>
          {t('validation_checklist')}
        </h4> */}
          <h4 className={headerTitleClickableCentered} onClick={() => handleSort('field5', CriteriaType.string)}>
            {t('pmr_validation_initials')}
          </h4>
        </div>
        <ul className={rowList}>
          {currentList &&
            currentList.map(project => (
              <li className={tableRow} key={`${project.loanNumber}${project.code}${project.frontendId}`}>
                <BlackTooltip arrow title={t('go_to_project_info')}>
                  <p className={`${rowValue} link-project`} onClick={() => handleClick(project)}>
                    {project.code}
                  </p>
                </BlackTooltip>
                <p className={rowValue}>{project.loanNumber}</p>
                <BlackTooltip arrow title={project.title || ''} placement="bottom" enterDelay={500} leaveDelay={100}>
                  <p className={rowValueName}>{project.title}</p>
                </BlackTooltip>
                <p className={rowValue}>{translationState(project.field2, t)}</p>
                <p className={rowValueDate}>{renderDateOrSlash(project.field3)}</p>

                {/*
              TODO - Faltan datos columna checklist validation
              <p
                className={checklistValidationColumnAdjustValue}
                onClick={() => handleCheklistValidationClick(project)}
              >
                <CheckoutValidation classificationValue={project.field4} />
              </p> */}

                <p className={rowValueCentered}>
                  <span className={pmrColumnAdjustSign}>
                    <PmrClassificationComponent classificationColor={parseInt(project.field6)} />
                  </span>
                  <span className={pmrColumnAdjustValue}>
                    <PmrPrintValue pmrClassi={parseInt(project.field6)} pmrValue={parseFloat(project.field5)} />
                  </span>
                </p>
              </li>
            ))}
        </ul>
      </div>
      {/*
      TODO - Faltan datos - checklist validation - MODAL
      <ChecklistValidationModal
        isOpen={modalIsOpen}
        closeModal={val => setModalIsOpen(val)}
        selectedProject={selectedProject}
      /> */}
      <div className="pagination-style">
        <PaginationTable fnSetRows={setRowsPerPage} rows={rowsPerPage} numProyects={dataFilter?.length} />
        <Pagination count={pagesCount} page={currentPage} onChange={handlePaginationChange} />
      </div>
    </div>
  ) : null;
};

const translationState = (state: string, t: Function) => {
  switch (state) {
    case PmrValidationStateEnum.CHIEF_OF_DIVISIONS:
      return `${capitalizeFirstLetter(t('sent_to'))} ${t('pmr_validation_state_chief_of_divisions')}`;

    case PmrValidationStateEnum.CHIEF_OF_OPERATIONS:
      return `${capitalizeFirstLetter(t('sent_to'))} ${t('pmr_validation_state_chief_of_operations')}`;

    case PmrValidationStateEnum.REPRESENTATIVE:
      return `${capitalizeFirstLetter(t('sent_to'))} ${t('pmr_validation_state_representative')}`;

    case PmrValidationStateEnum.DRAFT:
      return t('pmr_validation_state_draft');

    case PmrValidationStateEnum.VALIDATED:
      return t('pmr_validation_state_validated');

    default:
      return '-';
  }
};
