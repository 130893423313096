import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import BaseModal from '../../Util/Modals/BaseModal';
import { styles } from '../../Filter/FilterComponent.Styles';
import classes from './CustomTabPanel.module.scss';
import CloseCrossButton from '../../Util/Buttons/CloseCrossButton/CloseCrossButton';
import RegionCountryFilter from '../../Filter/RegionCountryFilter/RegionCountryFilter';
import { MultiSearchUser } from '../../Util/MultiSearchUser/MultiSearchUser';
import { RolesIdEnum } from '../../../models/RolesIdEnum';
import AttributesFilter from '../../Filter/AttributesFilter/AttributesFilter';
import CustomCheckbox from '../../Util/CustomCheckbox/CustomCheckbox';
import { createCustomTab, editCustomTab, deleteCustomTab } from '../../../api/userApi';
import { getFilterObject, getDivisionFilterObject } from '../PortfolioUtil';
import { getUser } from '../../../redux/actions/user';
import ConfirmDelete from '../../Util/Modals/ConfirmDelete/ConfirmDelete';
import CustomTabPanelButtons from './CustomTabPanelButtons/CustomTabPanelButtons';
import { MenuTabs } from '../../MenuTabs/userTabsInterface';
import { CalledFrom } from '../../../models/CalledFromEnum';
import SelectOption from '../../../models/SelectOption';
import SectorsAndDivisionsFilter from '../../Filter/SectorsAndDivisionsFilter/SectorsAndDivisionsFilter';
import { FilterTypesEnum } from '../../../models/FitlerTypesEnum';

type Props = {
  isOpen: boolean;
  closePanel: Function;
  isEdit: boolean;
  calledFrom: CalledFrom;
  selectedTab: MenuTabs;
};

const checkboxLabelStyle = {
  fontFamily: 'rubik',
  fontSize: '18px',
  fontWeight: '500',
  color: '#727E8C',
};

const getCustomTabTitle = (isEdit: boolean, tabNameInput: string, menuTabs) => {
  if (!isEdit) {
    const userCustomTabs = menuTabs.userTabs.filter(tab => tab.tabId !== 0);

    if (tabNameInput === '') {
      return userCustomTabs.length ? `Custom Tab ${userCustomTabs.length + 1}` : 'Custom Tab';
    }
  }

  return tabNameInput;
};

const getFilterIdsArray = (values: SelectOption[]) => {
  return values.map(value => value.id);
};

const getTeamLeaderObjects = (teamLeaders: SelectOption[]) => {
  return teamLeaders.map(teamLeader => ({
    id: teamLeader.id,
    name: teamLeader.label,
  }));
};

const CustomTabPanel = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isOpen, closePanel, isEdit, calledFrom, selectedTab } = props;
  const { nameInput } = classes;

  const menuTabs = useSelector<any, any>(state => state.menuTabs);
  const sectorsFromRedux = useSelector<any, any>(state => state.sectors);
  const isCreateEditOrDelete = true;

  const [tabNameInput, setTabNameInput] = useState<string>('');
  const [sectorSelected, setSectorSelected] = useState<any>([]);
  const [divisionSelected, setDivisionSelected] = useState<any>([]);
  const [coResponsibleDivisionSelected, setCoResponsibleDivisionSelected] = useState<any>([]);
  const [participatingDivisionSelected, setParticipatingDivisionSelected] = useState<any>([]);
  const [regionSelected, setRegionSelected] = useState<any>([]);
  const [countrySelected, setCountrySelected] = useState<any>([]);
  const [teamLeaderSelected, setTeamLeaderSelected] = useState<any>([]);
  const [attributesSelected, setAttributesSelected] = useState<any>([]);
  const [followingIsChecked, setFollowingIsChecked] = useState<boolean>(false);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState<boolean>(false);
  const [excludeRegionalsIsChecked, setExcludeRegionalsIsChecked] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen && isEdit) {
      const selectedTeamLeaders: SelectOption[] = selectedTab.teamLeaders.map(tl => ({
        id: tl.id,
        label: tl.name,
        value: tl.name,
      }));

      setTabNameInput(selectedTab.title);
      setTeamLeaderSelected(selectedTeamLeaders);
      setFollowingIsChecked(selectedTab.inFollow);
      setExcludeRegionalsIsChecked(selectedTab.excludeRegionals);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleRightButton = async () => {
    isEdit ? setConfirmationModalIsOpen(true) : handleClose();
  };

  const handleLeftButton = async () => {
    const filtersArray: any = [];

    const divisionSelectedFilter = getDivisionFilterObject(divisionSelected, sectorsFromRedux, sectorSelected);
    const sectorSelectedFilter = getFilterObject(FilterTypesEnum.NumberIn, 'SectorId', sectorSelected);
    const countrySelectedFilter = getFilterObject(FilterTypesEnum.NumberIn, 'ResponsibleCountryId', countrySelected);

    filtersArray.push(sectorSelectedFilter);
    filtersArray.push(divisionSelectedFilter);
    filtersArray.push(countrySelectedFilter);

    const filters = filtersArray.filter(item => item !== null && item !== undefined);

    const data = {
      title: getCustomTabTitle(isEdit, tabNameInput, menuTabs),
      regions: getFilterIdsArray(regionSelected),
      coReponsibleDivisions: getFilterIdsArray(coResponsibleDivisionSelected),
      participantDivisions: getFilterIdsArray(participatingDivisionSelected),
      teamLeaders: getTeamLeaderObjects(teamLeaderSelected),
      interestIssues: getFilterIdsArray(attributesSelected),
      inFollow: followingIsChecked,
      assignedToMe: false,
      default: false,
      filters,
      excludeRegionals: excludeRegionalsIsChecked,
    };

    if (isEdit) {
      await editCustomTab(data, selectedTab.tabId);
      dispatch(getUser(isCreateEditOrDelete, selectedTab.tabId));
      handleClose();
    } else {
      const tabs: any = await createCustomTab(data);
      dispatch(
        getUser(
          isCreateEditOrDelete,
          tabs.data.reduce((acc, tab) => (acc > tab.tabId ? acc : tab.tabId), 0),
        ),
      );
      handleClose();
    }
  };

  const handleClose = () => {
    setTabNameInput('');
    setSectorSelected([]);
    setDivisionSelected([]);
    setCoResponsibleDivisionSelected([]);
    setParticipatingDivisionSelected([]);
    setRegionSelected([]);
    setCountrySelected([]);
    setTeamLeaderSelected([]);
    setAttributesSelected([]);
    setFollowingIsChecked(false);
    setExcludeRegionalsIsChecked(false);
    closePanel();
  };

  const handleDelete = async () => {
    await deleteCustomTab(selectedTab.tabId);
    setConfirmationModalIsOpen(false);
    dispatch(getUser(isCreateEditOrDelete));
    handleClose();
  };

  const handleTeamLeaderChange = (value: SelectOption[]) => {
    if (value === null) {
      value = [];
    }
    setTeamLeaderSelected(value);
  };

  return (
    <>
      <BaseModal isOpen={isOpen} style={styles} onRequestClose={() => handleClose()} className="modal-filter-content">
        <div className="modal-filter-container">
          <div className="horizontal-right">
            <CloseCrossButton handleClick={() => handleClose()} />
          </div>
          <input
            className={nameInput}
            placeholder={t('name')}
            value={tabNameInput}
            onChange={value => setTabNameInput(value.target.value)}
          />
          <SectorsAndDivisionsFilter
            calledFrom={calledFrom}
            isOpen={isOpen}
            isEdit={isEdit}
            setSectorSelectedOnParent={setSectorSelected}
            setDivisionSelectedOnParent={setDivisionSelected}
            setCoResponsibleDivisionOnParent={setCoResponsibleDivisionSelected}
            setParticipantDivisionOnParent={setParticipatingDivisionSelected}
          />
          <RegionCountryFilter
            calledFrom={calledFrom}
            setRegionSelected={setRegionSelected}
            setCountrySelected={setCountrySelected}
            isEdit={isEdit}
          />
          <h4>{t('team_leader')}</h4>
          <div className="horizontal-columns">
            <MultiSearchUser
              countryFiltered={false}
              returnUsersSelected={user => {
                handleTeamLeaderChange(user);
              }}
              filteredRoles={[RolesIdEnum.Team_Leader]}
              clearStyle
              delegatedUsers={teamLeaderSelected}
            />
          </div>
          <h4>{t('attributes')}</h4>
          <div className="horizontal-columns">
            <AttributesFilter setOptionsSelected={setAttributesSelected} calledFrom={calledFrom} isEdit={isEdit} />
          </div>
          <div className="horizontal-columns">
            <CustomCheckbox
              labelText={t('following_projects_title')}
              handleOnClick={() => setFollowingIsChecked(!followingIsChecked)}
              isChecked={followingIsChecked}
              customStyle={checkboxLabelStyle}
            />
          </div>
          <div className="horizontal-columns">
            <CustomCheckbox
              labelText={t('exclude_regional')}
              handleOnClick={() => setExcludeRegionalsIsChecked(!excludeRegionalsIsChecked)}
              isChecked={excludeRegionalsIsChecked}
              customStyle={checkboxLabelStyle}
            />
          </div>
          <div className="horizontal-columns">
            <CustomTabPanelButtons
              isEdit={isEdit}
              handleLeftButton={handleLeftButton}
              handleRightButton={handleRightButton}
            />
          </div>
        </div>
      </BaseModal>
      <ConfirmDelete
        isOpen={confirmationModalIsOpen}
        closeModal={() => setConfirmationModalIsOpen(false)}
        submit={() => handleDelete()}
        customTitle={'delete_custom_tab_confirmation_message'}
        customMessage={''}
        submitButtonText={'delete_custom_tab_confirmation_button_text'}
      />
    </>
  );
};

export default CustomTabPanel;
