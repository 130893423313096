import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Bubble } from '../BubbleElement/BubbleElement';
import { useTranslation } from 'react-i18next';
import { CTFiltersEnum } from '../../../models/CTFilters';
import { dispatchCTFiltersState } from '../../../redux/actions/filter';

type Props = {
  indicatorCall?: boolean;
};

const CTFiltersBubbles = (props: Props) => {
  const { indicatorCall } = props;
  const filter = useSelector<any, any>(state => (indicatorCall ? state.filterIndicators : state.filter));
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const bubblesExecution = filter.common.executionFilter;
  const bubblesTaxonomy = filter.common.taxonomyFilter;
  const bubblesExcludeRegional = filter.common.excludeRegionals;

  const closeBubble = (filterType: number) => {
    switch (filterType) {
      case CTFiltersEnum.EXECUTION_FILTER:
        dispatch(dispatchCTFiltersState(filter, null, CTFiltersEnum.EXECUTION_FILTER, indicatorCall));
        break;
      case CTFiltersEnum.TAXONOMY_FILTER:
        dispatch(dispatchCTFiltersState(filter, null, CTFiltersEnum.TAXONOMY_FILTER, indicatorCall));
        break;
      default:
        dispatch(dispatchCTFiltersState(filter, false, CTFiltersEnum.EXCLUDE_REGIONAL, indicatorCall));
        break;
    }
  };

  return (
    <>
      {bubblesExecution && (
        <Bubble
          key={bubblesExecution.value}
          itsHidden={false}
          label={bubblesExecution.label}
          closeBubble={() => closeBubble(CTFiltersEnum.EXECUTION_FILTER)}
        />
      )}
      {bubblesTaxonomy && (
        <Bubble
          key={bubblesTaxonomy.value}
          itsHidden={false}
          label={bubblesTaxonomy.label}
          closeBubble={() => closeBubble(CTFiltersEnum.TAXONOMY_FILTER)}
        />
      )}
      {bubblesExcludeRegional && (
        <Bubble
          key={'exclude_regional'}
          itsHidden={false}
          label={t('exclude_regional')}
          closeBubble={() => closeBubble(CTFiltersEnum.EXCLUDE_REGIONAL)}
        />
      )}
    </>
  );
};

export default CTFiltersBubbles;
