import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { groupBy } from 'lodash';
import Select from 'react-select';
import customStyles from './ApproveAccordancesModal.module.scss';
import CustomCheckbox from '../../../../Util/CustomCheckbox/CustomCheckbox';
import { setSelectedOnlyVisible } from './ApproveAccordancesModal';
import { AccordanceType } from '../../../../../models/AccordanceType';
import SelectOption from '../../../../../models/SelectOption';
import { translateMultipleText } from '../../../../../services/stringUtil';
import { multiSelectStyle } from '../../../../Filter/FilterComponent.Styles';

const baseFontStyle = { color: '#424D64', fontSize: '14px', fontFamily: 'Rubik' };

export const approveStyle = {
  ...multiSelectStyle,
  container: styles => ({ ...styles, width: 280, marginBottom: 40 }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  singleValue: base => ({
    ...base,
    ...baseFontStyle,
  }),
  menu: base => ({
    ...base,
    ...baseFontStyle,
  }),
};

type Props = {
  list: any[];
  setList: Function;
  updateListItem: Function;
  data: any[];
};

const MultiProjectList = ({ list, setList, updateListItem, data }: Props) => {
  const { t } = useTranslation();
  const { checkBoxListContainer, header, column, tableRow, selectLabel } = customStyles;
  const [options, setOptions] = useState<SelectOption[]>([{ value: 'all', label: `${t('all')}`, id: 0 }]);
  const [selectedOption, setSelectedOption] = useState<SelectOption>({ value: 'all', label: `${t('all')}`, id: 0 });

  const handleChange = (option: SelectOption) => {
    setSelectedOption(option);
    const newList =
      option.value === 'all'
        ? list.map(a => {
          a.visible = true;
          return a;
        })
        : list.map(item => {
          if (item.project.code === option.value) {
            item.visible = true;
          } else {
            item.visible = false;
          }
          return item;
        });
    setList(newList);
  };

  useEffect(() => {
    const groupedByCode = groupBy(data, a => a.project.code);
    const auxOptions = Object.keys(groupedByCode).map((item, index) => {
      return { value: item, label: item, id: index };
    });
    setOptions([{ value: 'all', label: `${t('all')}`, id: 0 }, ...auxOptions]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <h3 className={selectLabel}>{translateMultipleText('select project', t)}</h3>
      <Select
        value={selectedOption}
        options={options}
        onChange={value => handleChange(value)}
        styles={approveStyle}
        isSearchable={false}
        placeholder={t('all')}
        noOptionsMessage={() => t('no_results')}
      />
      <div className={header}>
        <h4 className={column}>{t('project')}</h4>
        <h4 className={column}>{t('accordance')}</h4>
        {/* <h4 className={column}>{t('type')}</h4> */}
      </div>
      <ul className={checkBoxListContainer} style={{ marginTop: 10 }}>
        {list
          .filter(a => a.visible)
          .map(item => (
            <li key={item.id} style={{ width: '100%' }}>
              <CustomCheckbox
                labelText={''}
                isChecked={item.selected}
                handleOnClick={() => updateListItem(item)}
                customStyle={{ margin: 0, paddingTop: 0, fontFamily: 'Rubik', fontSize: 12, fontWeight: 500 }}
              />
              <div className={tableRow}>
                <p className={column}>{item.project.code}</p>
                {/* <p className={column}>{item.possibleAction.name}</p> */}
                <p className={column}>
                  {t(
                    item.accordanceType === AccordanceType.Monitoring
                      ? 'monitor_accordance'
                      : item.accordanceType === AccordanceType.Revision
                        ? 'revision_accordance'
                        : 'inter_group_accordance',
                  )}
                  {' - '}
                  {(item.description?.substring(0, 45) || '')}
                </p>
              </div>
            </li>
          ))}
      </ul>
      <CustomCheckbox
        labelText={t('select_all')}
        isChecked={list.filter(l => l.visible).every(i => i.selected)}
        handleOnClick={() =>
          setList(
            setSelectedOnlyVisible(
              list,
              list.some(o => o.visible && !o.selected),
            ),
          )
        }
        customStyle={{ paddingTop: 0, marginBottom: 20 }}
      />
    </>
  );
};

export default MultiProjectList;
