import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ReviewsStatusEnum } from '../../../../models/ReviewsStatusEnum';
import { replaceMomentFormat } from '../../../../services/util';
import ClickableText from '../../../ProjectDetail/ClickableText/ClickableText';
import SaveButton from '../../../Util/Buttons/SaveButton/SaveButton';
import { SelectDateButton } from '../../../Util/Buttons/SelectDateButton/SelectDateButton';
import { DateSelector } from '../DateSelector/DateSelector';
import { setShowSelectProjects } from '../../../../redux/actions/reviewShowSelectProjects';

import './FormDates.scss';

type Props = {
  title: string;
  review: any;
  isActive: boolean;
};
export const FormDates = ({ title, review: { id, status, startDate, endDate }, isActive }: Props) => {
  const { t } = useTranslation();
  const dateFormat = 'DD/MMM/YYYY';
  const active: boolean = isActive;
  const attempt: boolean | undefined = status === ReviewsStatusEnum.Attempt ? true : false;
  const [openDateSelector, setOpenDateSelector] = useState(false);
  const [isEditDatesAndStatus, setIsEditDatesAndStatus] = useState(false);
  const dispatch = useDispatch();

  const handleOpenDateSelector = (isEdit = false) => {
    isEdit && setIsEditDatesAndStatus(true);
    setOpenDateSelector(true);
  };

  const handleChooseProjects = () => {
    dispatch(setShowSelectProjects(true));
  };

  const resolveFunctions = {
    handleChooseProjects,
    handleOpenDateSelector,
  };

  return (
    <div className="form-dates-container">
      <div className="title">{title}</div>
      {openDateSelector ? (
        <DateSelector
          review={{ id, startDate, endDate, title }}
          isEdit={isEditDatesAndStatus}
          showProjects={() => dispatch(setShowSelectProjects(true))}
        />
      ) : (
        <div>
          <div className="subtitle">
            {handleFlow(resolveFunctions, active, attempt, t, 'dateInfo', { startDate, endDate, dateFormat })}
          </div>
          <div className="select-date">{handleFlow(resolveFunctions, active, attempt, t, 'buttonChooseDate')}</div>
        </div>
      )}
    </div>
  );
};

const handleFlow = (
  resolveFunctions,
  active,
  attempt,
  t,
  element,
  dateInfo = { startDate: '', endDate: '', dateFormat: 'DD/MMM/YYYY' },
) => {
  if (!active) {
    return element === 'buttonChooseDate' ? (
      <SelectDateButton submit={resolveFunctions.handleOpenDateSelector} />
    ) : (
      <span className="undefined-revision-message"> {t('there_is_no_set_date_for_the_next_portfolio_review')}</span>
    );
  } else {
    return element === 'buttonChooseDate' ? (
      <SaveButton
        handleClick={resolveFunctions.handleChooseProjects}
        disabled={false}
        customText={t('choose_projects')}
      />
    ) : (
      <div className="date-container">
        <div className="date-confirmed">
          {replaceMomentFormat(moment(dateInfo.startDate).format(dateInfo.dateFormat))}
          <span className="lowercase"> {` ${t('to_the')} `}</span>
          {replaceMomentFormat(moment(dateInfo.endDate).format(dateInfo.dateFormat))}
        </div>

        {attempt && (
          <div className="date-edit">
            <span className="attempt-label">{`- ${t('attempt')} - `}</span>

            <ClickableText
              text={t('edit')}
              onClick={() => resolveFunctions.handleOpenDateSelector(true)}
              fontSize={12}
            />
          </div>
        )}
      </div>
    );
  }
};
