import { Tab, Tabs } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { tabStyles } from '../../Projects/ProjectsAll/ProjectsAll.Style';
import { useTranslation } from 'react-i18next';

type Props = {
  tabValue: any;
  handleSetMenuValue: any;
  tabsTexts: string[];
};

export const MultiTabsComponent = ({ tabValue, handleSetMenuValue, tabsTexts }: Props) => {
  tabStyles.root.backgroundColor = 'transparent';
  tabStyles.root['&$selected'].backgroundColor = 'transparent';
  const classes = makeStyles(tabStyles)();

  const tabClasses = { root: classes.root, selected: classes.selected };
  const { t } = useTranslation();
  return (
    <div className="tabs">
    <Tabs
      value={tabValue}
      classes={{
        indicator: classes.indicatorColor,
        root: classes.root,
      }}
      aria-label="full width tabs"
    >
      {tabsTexts.map(item => {
        return (
         
          <Tab key={item} value={item} classes={tabClasses} label={t(item)} onClick={() => handleSetMenuValue(item)} />
          
        );
      })}
    </Tabs>
    </div>
  );
};
