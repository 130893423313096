import React from 'react';
import { useSelector } from 'react-redux';
import { ProjectProductTypes } from '../../../models/ProjectProductTypes';
import { SummaryIg } from './SummaryIg/SummaryIg';
import { SummaryLoan } from './SummaryLoan/SummaryLoan';
import { SummaryTcp } from './SummaryTcp/SummaryTcp';

type Props = {
  currentIndicator: any;
  headerTitle: any;
  openModal: any;
  closeModal: any;
};

export const PortfolioSummaryIndicator = ({ currentIndicator, headerTitle, openModal, closeModal }: Props) => {
  const { projectTypeFilterOnDetail } = useSelector<any, any>(state => state.indicatorDetailFilter);

  const renderByProjectTypeFilter = () => {
    if (projectTypeFilterOnDetail === ProjectProductTypes.Loan) {
      return (
        <SummaryLoan
          currentIndicator={currentIndicator}
          headerTitle={headerTitle}
          openModal={openModal}
          closeModal={closeModal}
        />
      );
    } else if (projectTypeFilterOnDetail === ProjectProductTypes.CT) {
      return <SummaryTcp currentIndicator={currentIndicator} headerTitle={headerTitle} />;
    } else if (projectTypeFilterOnDetail === ProjectProductTypes.IG) {
      return <SummaryIg currentIndicator={currentIndicator} headerTitle={headerTitle} />;
    } else {
      return null;
    }
  };

  return renderByProjectTypeFilter();
};
