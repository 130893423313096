import React, { useEffect, useState } from 'react';
import './Financial.scss';
import { useTranslation } from 'react-i18next';
import LabelData from '../../../Util/LabelData/LabelData';
import { AdvancePayment } from '../../../../models/FinancialInformation';
import { renderDateOrSlash } from '../../../../services/stringUtil';
import { Line } from 'rc-progress';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { barIndicatorWidth } from './ExecutionByComponent/util';
import { ReactComponent as IconCheck } from '../../../../assets/icons/u_alert-check.svg';
import { ReactComponent as IconAlert } from '../../../../assets/icons/u_alert-multyple.svg';
import { useSelector } from 'react-redux';
import Project from '../../../../models/Project';

const CustomTooltip = (color, left = '') =>
  withStyles({
    tooltip: {
      backgroundColor: '#ffffff',
      fontSize: '16px',
      fontFamily: 'Rubik',
      lineHeight: '20px',
      padding: '8px 12px',
      borderRadius: '8px',
      left: left,
      color: color,
      boxShadow: '0px 4px 8px 0px rgba(38, 67, 119, 0.25)',
    },
    arrow: {
      fontSize: 20,
      color: '#4A4A4A',
      '&::before': {
        backgroundColor: '#ffffff',
      },
    },
  })(Tooltip);

type Props = {
  anticipation?: AdvancePayment | null;
  state?: string;
  withLoans: boolean;
};

const emptyAdvancePayment = {
  amount: null,
  transactionDate: null,
  financialPlanDays: null,
  percentToJustify: null,
  lastJustify: null,
  justifyDate: null,
  advanceBalance: null,
  minToNewDisbursement: null,
  daysPassed: null,
};

const Anticipations = ({ anticipation, state, withLoans }: Props) => {
  const RedTooltip = CustomTooltip('#E92C2C');
  const { t } = useTranslation();
  const data = anticipation || emptyAdvancePayment;
  const currentProject = useSelector<any, Project>(state => state.currentProject);
  const loanNumber = useSelector<any, Project>(state => state.currentProject.loanNumber);
  const [hasLoands, setHasLoands] = useState(false)
  const [calcLoanNumber, setCalcLoanNumber] = useState(0)

  const { daysPassed, financialPlanDays } = data;

  const [planDays, setPlanDays] = useState();
  const [timePassed, setTimePassed] = useState(0);

  const percentage_days = ((timePassed || 0) * 100) / (financialPlanDays || 0);
  const BlackTooltip = CustomTooltip('#101319', percentage_days >= 90 ? '90%' : `${percentage_days}%`);

  const addDaysToTransactionDate = (date, daysToAdd) => {
    let currentDate = new Date();
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + daysToAdd);

    if (currentDate instanceof Date && newDate instanceof Date) {
      const differenceDays = newDate.getTime() - currentDate.getTime();
      setTimePassed(Math.ceil(differenceDays / (1000 * 60 * 60 * 24)) + 1);
    } else {
      console.error('Una de las fechas no es válida');
    }
    date !== null ? setPlanDays(renderDateOrSlash(newDate)) : setPlanDays(renderDateOrSlash(null));

  };
  const proyectHaveLoans = () => {
    if (currentProject.loanNumber?.split('-').length >= 3 && currentProject.withLoans === true) {
      setHasLoands(true)
    } else {
      setHasLoands(false)
    }

  }

  useEffect(() => {
    setCalcLoanNumber(currentProject.loanNumber?.split('-').length)
    proyectHaveLoans();

  }, [currentProject.id]);

  useEffect(() => {
    addDaysToTransactionDate(data.transactionDate, data.financialPlanDays || '0');
  }, []);

  return (
    <>
      {(!hasLoands && currentProject.withLoans === true) ? (
        <div className="card-datail-anticipations">
          <div className="title">
            <h3>{t('anticipations')}</h3>
          </div>
          <div className="detail-text">
            <p> {`${t('have_loans')}`}</p>
          </div>
        </div>
      ) : state !== 'AP' && state !== 'EF' && state !== 'EL' ? (
        <div className="project-detail-anticipations-container">
          <div className="financial-title-container">
            <div className="title">
              <span className="title">{t('anticipations')}</span>
            </div>
            <div className="detail">
              <span>{t('last_anticipation_made')}</span>
            </div>
          </div>
          <div className="basic-row-display">
            <div className="basic-display-column last-anticipes ">
              <div className="first-width">
                <LabelData
                  label={t('amount')}
                  data={data.amount || '-'}
                  reverse={true}
                  formatNumber={true}
                  reverseUsd={true}
                />
              </div>
              <div className="last-width">
                <div className="line-anticipations">
                  <div className="container-line">
                    <div className="bar-progress">
                      <div className="ralative">
                        <BlackTooltip
                          arrow
                          title={
                            timePassed > 0 ? (
                              <span className="ci-type-text-7">{`${timePassed} ${t('remaining_days')}`}</span>
                            ) : (
                              ''
                            )
                          }
                          placement="top"
                        >
                          <Line
                            percent={percentage_days >= 0 ? percentage_days : 100}
                            strokeWidth={5}
                            trailWidth={5}
                            strokeColor={true ? '#538CFF' : '#538CFF  '}
                            strokeLinecap="square"
                            trailColor="#D0D5E0"
                          />
                        </BlackTooltip>
                        <div className="line-1"></div>
                        <div className="line-100"></div>
                      </div>
                      {timePassed < 0 && (
                        <RedTooltip
                          arrow
                          title={
                            <span>
                              {Math.abs(timePassed)}{' '}
                              <span className="ci-type-text-7">
                                {t('days')} {t('expired')}
                              </span>
                            </span>
                          }
                          placement="top"
                        >
                          <div
                            className="disbursedProgress"
                            style={{
                              width: barIndicatorWidth(60, 30),
                            }}
                          ></div>
                        </RedTooltip>
                      )}
                    </div>
                  </div>
                </div>
                <div className="basic-display-column financial-plan">
                  <LabelData label={t('date')} data={renderDateOrSlash(data.transactionDate)} reverse={true} />
                  <div className="plan">
                    <LabelData label={t('plan')} data={data.financialPlanDays || '-'} reverse={true} />
                    <div className="data-text"> {planDays}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="basic-display-column">
            </div>
          </div>
          <span className="subtitle">{t('justified_to_date')}</span>
          <div className="basic-display-column last-anticipes">
            <div className="first-width">
              <LabelData
                label={t('last_justify')}
                data={data.lastJustify || '-'}
                reverse={true}
                reverseUsd={true}
                formatNumber={true}
              />
            </div>
            <div className="last-width justified-date">
              <LabelData label={t('date')} data={renderDateOrSlash(data.justifyDate)} reverse={true} />

              <LabelData
                label={t('anticipation_remain')}
                data={data.advanceBalance || '-'}
                reverse={true}
                reverseUsd={true}
                formatNumber={true}
              />
            </div>
          </div>
          <div className="basic-row-display">
            <LabelData label={t('justify_percentage')} data={data.percentToJustify || '-'} reverse={true} />
          </div>
          <div className="basic-row-display">
            <div className="basic-display-column new-disbursement">
              <div className="firs-child">
                <LabelData
                  label={t('new_disbursement_minimum')}
                  data={data.minToNewDisbursement || '-'}
                  reverse={true}
                  reverseUsd={true}
                  formatNumber={true}
                />
              </div>
              <div className="last-width ">
                <div className="line-chart">
                  {(data.minToNewDisbursement || 0) === 0 ? (
                    <div className="tag enabled">
                      {t('enabled')} <IconCheck />
                    </div>
                  ) : (
                    <div className="tag no-enabled">
                      {t('no_enabled')} <IconAlert />
                    </div>
                  )}
                </div>

              </div>
            </div>
          </div>
        </div>
      ) :

        (
          <div className="card-datail-anticipations">
            <div className="title">
              <h3>{t('anticipations')}</h3>
            </div>
            <div className="detail-text">
              <span className="tag">{t(state)}</span>
              <p>{t(state + '_text')}</p>
            </div>
          </div>
        )}
    </>
  );
};

export default Anticipations;
