import React, { useContext, useEffect, useState } from 'react';
import styles from './UserBasedOptions.module.scss';
import Select from 'react-select';
import { DropdownIndicator, reportAccordanceStyle } from '../../../../Filter/FilterComponent.Styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  getRegionsOptions,
  getCountriesOptions,
  getSectorOptions,
  getDivisionsOptions,
  getDivisionsIdsFromSelectedSectors,
  handleDefaultsSelectSelected,
  getDefaultCountry,
  getDefaultRegion,
  getDefaultDivision,
  getDefaultSector,
  handleUpdateItemsIds,
} from './Util';
import { ReportAccordanceContext } from '../ReportAccordance';
import Sector from '../../../../../models/Sector';
import { DefaultsSelects } from '../../../../../models/EDefaultsSelects';

const { horizontalRow, field, label } = styles;
export const UserBasedOptions = () => {
  const regions = useSelector<any, any>(state => state.regions);

  const menuTabs = useSelector<any, any>(state => state.menuTabs);
  const sectors = useSelector<any, Sector[]>(state => state.sectors);
  const { t } = useTranslation();
  const [selectedRegion, setSelectedRegion] = useState<any>([]);
  const [selectedCountry, setSelectedCountry] = useState<any>([]);
  const [selectedSector, setSelectedSector] = useState<any>([]);
  const [selectedDivision, setSelectedDivision] = useState<any>([]);
  const [countriesOptions, setCountriesOptions] = useState<any>(getCountriesOptions(regions, selectedRegion));
  const [divisionsOptions, setDivisionsOptions] = useState<any>(getDivisionsOptions(sectors, selectedSector));
  const [selectedParamsToEndPoint, setSelectedParamsToEndPoint] = useContext<any>(ReportAccordanceContext);

  useEffect(() => {
    const defaultSelects = handleDefaultsSelectSelected(menuTabs);
    if (defaultSelects.default === DefaultsSelects.Country) {
      setSelectedCountry([getDefaultCountry(regions, defaultSelects)]);
    } else if (defaultSelects.default === DefaultsSelects.Region) {
      setSelectedRegion([getDefaultRegion(regions, defaultSelects)]);
    } else if (defaultSelects.default === DefaultsSelects.Sector) {
      setSelectedSector([getDefaultSector(sectors, defaultSelects)]);
    } else if (defaultSelects.default === DefaultsSelects.Division) {
      setSelectedDivision([getDefaultDivision(sectors, defaultSelects)]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCountriesOptions(getCountriesOptions(regions, selectedRegion));
    setSelectedParamsToEndPoint({
      ...selectedParamsToEndPoint,
      region: handleUpdateItemsIds(selectedRegion),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRegion]);

  useEffect(() => {
    setDivisionsOptions(getDivisionsOptions(sectors, selectedSector));
    setSelectedParamsToEndPoint({
      ...selectedParamsToEndPoint,
      sector: handleUpdateItemsIds(selectedSector),
      division: Array.from(
        new Set([
          ...getDivisionsIdsFromSelectedSectors(sectors, selectedSector),
          ...handleUpdateItemsIds(selectedDivision),
        ]),
      ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSector]);

  useEffect(() => {
    setSelectedParamsToEndPoint({
      ...selectedParamsToEndPoint,
      country: handleUpdateItemsIds(selectedCountry),
      division: handleUpdateItemsIds(selectedDivision),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry, selectedDivision]);

  return (
    <>
      <div className={horizontalRow}>
        <div className={field}>
          <p className={label}>{t('region')}</p>
          <Select
            id="region"
            components={{ DropdownIndicator }}
            value={selectedRegion}
            options={getRegionsOptions(regions)}
            className="basic-multi-select"
            classNamePrefix="All"
            placeholder={t('all')}
            onChange={value => setSelectedRegion(value)}
            styles={reportAccordanceStyle}
            isMulti
          />
        </div>
        <div className={field}>
          <p className={label}>{t('country')}</p>
          <Select
            id="country"
            components={{ DropdownIndicator }}
            value={selectedCountry}
            options={countriesOptions}
            className="basic-multi-select"
            classNamePrefix="All"
            placeholder={t('all')}
            onChange={value => setSelectedCountry(value)}
            styles={reportAccordanceStyle}
            isMulti
          />
        </div>
      </div>
      <div className={horizontalRow}>
        <div className={field}>
          <p className={label}>{t('sector')}</p>
          <Select
            id="sector"
            components={{ DropdownIndicator }}
            value={selectedSector}
            options={getSectorOptions(sectors)}
            className="basic-multi-select"
            classNamePrefix="All"
            placeholder={t('all')}
            onChange={value => setSelectedSector(value)}
            styles={reportAccordanceStyle}
            isMulti
          />
        </div>
        <div className={field}>
          <p className={label}>{t('division')}</p>
          <Select
            id="division"
            components={{ DropdownIndicator }}
            value={selectedDivision}
            options={divisionsOptions}
            className="basic-multi-select"
            classNamePrefix="All"
            placeholder={t('all')}
            onChange={value => setSelectedDivision(value)}
            styles={reportAccordanceStyle}
            isMulti
          />
        </div>
      </div>
    </>
  );
};
