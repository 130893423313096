import { ReportSectionTabs } from '../../models/ReportEnums';
import { ReportSectionTabValueActionTypes, SET_REPORT_SECTION_TAB_VALUE } from '../actions/types';

const initialState = ReportSectionTabs.PORTFOLIO;

const reportSectionTabValueReducer = (state = initialState, action: ReportSectionTabValueActionTypes): number => {
  switch (action.type) {
    case SET_REPORT_SECTION_TAB_VALUE:
      return action.payload;
    default:
      return state;
  }
};

export default reportSectionTabValueReducer;
