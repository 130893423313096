import Modal from 'react-modal';

export const disableEscKey = (e: any, callback: Function) => {
  if (e.type === 'keydown' && e.keyCode === 27) {
    return false;
  } else {
    callback();
    return true;
  }
};
export const modalSetAppElement = nodeEnv => {
  if (nodeEnv !== 'test') {
    Modal.setAppElement('#root');
  }
};
