import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

/* Material Imports */
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

/*Assets imported */
import { ReactComponent as PortfolioIcon } from '../../assets/icons/u_portfolio.svg';
import { ReactComponent as PortfolioIconUnselectedMobile } from '../../assets/portfolio-tab-unselected-mobile.svg';
import { ReactComponent as ProjectsIcon } from '../../assets/icons/u_project.svg';
import { ReactComponent as ProjectsIconUnselectedMobile } from '../../assets/projects-tab-unselected-mobile.svg';
import { ReactComponent as ReportsIcon } from '../../assets/icons/u_reports.svg';
import { ReactComponent as ReportsIconUnselectedMobile } from '../../assets/reports-tab-unselected-mobile.svg';
import { ReactComponent as ConfigIcon } from '../../assets/config-tab-selected.svg';
import { ReactComponent as ConfigIconUnselectedMobile } from '../../assets/config-tab-unselected-mobile.svg';
import { mobileStyles, standardStyles } from './Sidetabs.Styles';
import { ReactComponent as ReviewsIcon } from '../../assets/icons/u_reviews.svg';
import { ReactComponent as ReviewsIconUnselectedMobile } from '../../assets/reviews-tab-unselected-mobile.svg';
import { ReactComponent as ContentManagerTabIcon } from '../../assets/content-manager-analytics-tab-icon.svg';
import { ReactComponent as EarlyWarningsIcon } from '../../assets/icons/u_early_warnings.svg';
import { ReactComponent as EarlyWarningsIconUnselectedMobile } from '../../assets/early-warnings-tab-unselected-mobile.svg';

/*Custom Components */
import Projects from '../Projects/Projects';
import UserSettings from '../UserSettings/UserSettings';
import Portfolio from '../Portfolio/Portfolio';
import { tabIndexes } from '../../models/TabIndexes';
import { isMobile } from '../../services/booleanUtil';
import { ReviewsSection } from '../Reviews/ReviewsSection';
import { ReportsSection } from '../Reports/ReportsSection/ReportsSection';
import { setAgendaSelectedByDefault } from '../../redux/actions/agendaSelectedByDefault';
import { trackActionClicked } from '../../api/trackActionClickedApi';
import { TrackedActions } from '../../models/TrackedActionsEnum';
import User from '../../models/User';
import { resetReviewNavigation } from '../../redux/actions/reviewNavigation';
import { setTabValue } from '../../redux/actions/tabValue';
import { setShowSelectProjects } from '../../redux/actions/reviewShowSelectProjects';
import { setNoneReviewSelectedProject } from '../../redux/actions/reviewProjectsSelectedRows';
import EarlyWarnings from '../EarlyWarnings/EarlyWarnings';
import './Sidetabs.scss';
import { AgreementsHelpProvider } from '../../context/AgreementsContext';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const boxPadding = (index: number) => {
  if (
    index === tabIndexes.userSettings ||
    (index === tabIndexes.projects && isMobile()) ||
    (index === tabIndexes.portfolio && isMobile())
  ) {
    return 0;
  }
  return 3;
};

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography component="div" role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} {...other}>
      {value === index && <Box p={boxPadding(index)}>{children}</Box>}
    </Typography>
  );
};

const unselectedIcon = tabValue => {
  switch (tabValue) {
    case tabIndexes.projects:
      return isMobile() ? <ProjectsIconUnselectedMobile /> : <ProjectsIcon />;
    case tabIndexes.reports:
      return isMobile() ? <ReportsIconUnselectedMobile /> : <ReportsIcon />;
    case tabIndexes.reviews:
      return isMobile() ? <ReviewsIconUnselectedMobile /> : <ReviewsIcon />;
    case tabIndexes.settings:
      return isMobile() ? <ConfigIconUnselectedMobile /> : <ConfigIcon />;
    case tabIndexes.earlyWarnings:
      return isMobile() ? <EarlyWarningsIconUnselectedMobile /> : <EarlyWarningsIcon />;
    default:
      return isMobile() ? <PortfolioIconUnselectedMobile /> : <PortfolioIcon />;
  }
};

const getIconByKey = (tabIndex, tabIndexes) => {
  switch (tabIndex) {
    case tabIndexes.portfolio:
      return <PortfolioIcon />;
    case tabIndexes.projects:
      return <ProjectsIcon />;
    case tabIndexes.reports:
      return <ReportsIcon />;
    case tabIndexes.reviews:
      return <ReviewsIcon />;
    case tabIndexes.settings:
      return <ConfigIcon />;
    case tabIndexes.contentManager:
      return <ContentManagerTabIcon />;
    case tabIndexes.earlyWarnings:
      return <EarlyWarningsIcon />;
    default:
      return null;
  }
};

const getTabIcon = (tabIndex, tabValue, tabIndexes, unselectedIcon, getIconByKey) =>
  tabValue === tabIndex ? getIconByKey(tabIndex, tabIndexes) : unselectedIcon(tabIndex);

const handleTextOnMobile = (t: Function, label: string) => {
  return isMobile() ? '' : t(label);
};

const Sidetabs = () => {
  const user = useSelector<any, User>(state => state.user);
  const tabValue = useSelector<any, number>(state => state.tabValue);
  const tabStyles = isMobile() ? mobileStyles : standardStyles;
  const classes = makeStyles(tabStyles)();
  const { t } = useTranslation();
  const tabClasses = { root: classes.root, selected: classes.selected };
  const hiddenTab = { root: classes.hiddenTab };
  const dispatch = useDispatch();
  const history = useHistory();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (newValue === tabIndexes.contentManager) {
      history.push('/ContentManager');
      return;
    }

    dispatch(setTabValue(newValue));
    if (newValue !== tabIndexes.reviews) {
      dispatch(setAgendaSelectedByDefault(false));
      dispatch(resetReviewNavigation());
      dispatch(setShowSelectProjects(false));
      dispatch(setNoneReviewSelectedProject());
    }

    if (tabValue !== newValue) {
      if (newValue === tabIndexes.portfolio) {
        trackActionClicked(TrackedActions.portfolioClicked);
      } else if (newValue === tabIndexes.projects) {
        trackActionClicked(TrackedActions.projectsClicked);
      } else if (newValue === tabIndexes.reports) {
        trackActionClicked(TrackedActions.reportsClicked);
      } else if (newValue === tabIndexes.reviews) {
        trackActionClicked(TrackedActions.reviewsClicked);
      } else if (newValue === tabIndexes.earlyWarnings) {
        trackActionClicked(TrackedActions.earlyWarningsClicked);
      }
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.sidebar}>
        <div className={`${classes.rootb} menu-tabs`}>
          <Tabs
            classes={{ indicator: classes.indicator, root: classes.tabs }}
            orientation={isMobile() ? 'horizontal' : 'vertical'}
            value={tabValue}
            onChange={handleChange}
            aria-label="Menu"
          >
            <Tab
              classes={tabClasses}
              label={handleTextOnMobile(t, 'menu_portfolio')}
              icon={getTabIcon(tabIndexes.portfolio, tabValue, tabIndexes, unselectedIcon, getIconByKey)}
              className="sidetabs__tab"
              tabIndex={11}
            />
            <Tab
              classes={tabClasses}
              label={handleTextOnMobile(t, 'menu_projects')}
              icon={getTabIcon(tabIndexes.projects, tabValue, tabIndexes, unselectedIcon, getIconByKey)}
              className="sidetabs__tab"
              tabIndex={12}
            />
            <Tab
              classes={tabClasses}
              label={handleTextOnMobile(t, 'menu_early_warnings')}
              icon={getTabIcon(tabIndexes.earlyWarnings, tabValue, tabIndexes, unselectedIcon, getIconByKey)}
              className="sidetabs__tab"
              tabIndex={13}
            />
            <Tab
              classes={tabClasses}
              label={handleTextOnMobile(t, 'menu_reports')}
              icon={getTabIcon(tabIndexes.reports, tabValue, tabIndexes, unselectedIcon, getIconByKey)}
              className="sidetabs__tab"
              tabIndex={14}
            />
            <Tab
              classes={tabClasses}
              label={handleTextOnMobile(t, 'menu_reviews')}
              icon={getTabIcon(tabIndexes.reviews, tabValue, tabIndexes, unselectedIcon, getIconByKey)}
              className="sidetabs__tab"
              tabIndex={15}
            />
            <Tab
              classes={hiddenTab}
              label={handleTextOnMobile(t, 'menu_config')}
              icon={getTabIcon(tabIndexes.settings, tabValue, tabIndexes, unselectedIcon, getIconByKey)}
              className="sidetabs__tab"
              tabIndex={16}
            />
            <Tab id="menu-hidden-tab" classes={hiddenTab} label={''} />
            {user.hasAccessToContentManagement && (
              <Tab
                classes={tabClasses}
                label={t('menu_content_manager')}
                icon={<ContentManagerTabIcon />}
                className="sidetabs__tab"
                tabIndex={17}
              />
            )}
          </Tabs>
        </div>
      </div>
      <div className={classes.bodySection}>
        <TabPanel value={tabValue} index={tabIndexes.portfolio}>
          <Portfolio />
        </TabPanel>
        <TabPanel value={tabValue} index={tabIndexes.projects}>
          <Projects />
        </TabPanel>
        <TabPanel value={tabValue} index={tabIndexes.earlyWarnings}>
          <EarlyWarnings />
        </TabPanel>
        <TabPanel value={tabValue} index={tabIndexes.reports}>
          <ReportsSection />
        </TabPanel>
        <TabPanel value={tabValue} index={tabIndexes.reviews}>
          <AgreementsHelpProvider>
            <ReviewsSection />
          </AgreementsHelpProvider>
        </TabPanel>
        <TabPanel value={tabValue} index={tabIndexes.settings}>
          Ajustes
        </TabPanel>
        <TabPanel value={tabValue} index={tabIndexes.userSettings}>
          <UserSettings />
        </TabPanel>
      </div>
    </div>
  );
};

export { Sidetabs, TabPanel };
