import React from 'react';
import moment from 'moment';
import { map, isNil } from 'lodash';
import { Table, TableBody, TableHead, TableRow, Typography } from '@material-ui/core';

import {
  ContentManagerTableContainer,
  SortableContentManagerTableCell,
  ContentManagerActionButton,
  ContentManagerStyledTableRow,
  ContentManagerTableCell,
  ContentManagerActionsTableCell,
  ContentManagerLeavesTableTypography,
} from './ContentMangerTable.Styles';

import ContentManagerModal from '../ContentManagerModal/ContentManagerModal';
import LeavesFormModal from './../../ContentManager/FunctionDelegations/Leaves/LeavesFormModal';

import { ReactComponent as OrderIcon } from '../../../assets/content_manager_order_copy.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/contents-delete-icon.svg';
import { ReactComponent as EditIcon } from '../../../assets/contents-edit-icon.svg';
import { ReactComponent as DelegationsWarning } from '../../../assets/content-manager-delegations-warning.svg';

import { dataTypes } from '../ContentManagerKeys';

import {
  deleteLeaveListItem,
  defaultSortData,
  deleteDelegationListItem,
  defaultPaginationData,
} from '../../../api/contentManager';
import DelegationsFormModal from '../FunctionDelegations/Delegations/DelegationsFormModal';
import { Pagination } from '@material-ui/lab';
import { isEmpty } from '../../../services/booleanUtil';
import { BidTooltip } from '../../Util/Tooltip/Tooltip';

type Props = {
  rows: any;
  columns: any;
  t: any;
  type: any;
  modalTitle: any;
  updateTable: any;
  setErrorMessage: any;
  fromButton?: any;
  buttonText?: any;
  paginationData?: any;
};

const getRowsByType = ({ rows, type, handleEdit, handleOpenModal, handleDelete, setErrorMessage, updateTable, t }) => {
  switch (type) {
    case dataTypes.leaves:
      return getLeavesRows({ rows, handleEdit, type, handleOpenModal, handleDelete, setErrorMessage, updateTable });
    case dataTypes.delegations:
      return getDelegationRows({
        rows,
        handleEdit,
        type,
        handleOpenModal,
        handleDelete,
        setErrorMessage,
        updateTable,
        t,
      });
    default:
      return null;
  }
};

const getModalByType = (type, row, handleOpenModal, setErrorMessage, updateTable) => {
  switch (type) {
    case dataTypes.leaves:
      return (
        <LeavesFormModal
          id={row.id}
          from={row.from}
          to={row.to}
          delegatedUserName={row.delegatedUserName}
          handleOpenModal={handleOpenModal}
          setErrorMessage={setErrorMessage}
          updateTable={updateTable}
        />
      );
    case dataTypes.delegations:
      return (
        <DelegationsFormModal
          handleCloseModal={handleOpenModal}
          setErrorMessage={setErrorMessage}
          updateTable={updateTable}
          isEdit
          row={row}
        />
      );
    default:
      return null;
  }
};

const deleteItem = async (type, id) => {
  switch (type) {
    case dataTypes.leaves:
      return deleteLeaveListItem(id);
    case dataTypes.delegations:
      return deleteDelegationListItem(id);
    default:
      return () => null;
  }
};

const rowActionButtonsStyle = {
  marginTop: '-14px',
  marginBottom: '-14px',
};

const getActions = ({ handleEdit, row, type, handleOpenModal, handleDelete, setErrorMessage, updateTable }) => {
  return (
    <>
      <ContentManagerActionButton
        style={rowActionButtonsStyle}
        onClick={() => handleEdit(getModalByType(type, row, handleOpenModal, setErrorMessage, updateTable))}
      >
        <EditIcon />
      </ContentManagerActionButton>
      <ContentManagerActionButton
        style={rowActionButtonsStyle}
        onClick={async () => {
          await deleteItem(type, row.id);
          handleDelete();
        }}
      >
        <DeleteIcon />
      </ContentManagerActionButton>
    </>
  );
};

const getLeavesRows = ({ rows, handleEdit, type, handleOpenModal, handleDelete, setErrorMessage, updateTable }) =>
  map(rows, row => (
    <ContentManagerStyledTableRow>
      <ContentManagerTableCell>
        <ContentManagerLeavesTableTypography noWrap>{`${row.countryName}`}</ContentManagerLeavesTableTypography>
      </ContentManagerTableCell>
      <ContentManagerTableCell>
        <ContentManagerLeavesTableTypography noWrap>{`${row.regionName}`}</ContentManagerLeavesTableTypography>
      </ContentManagerTableCell>
      <ContentManagerTableCell>
        <ContentManagerLeavesTableTypography noWrap>{`${row.delegatedUserName}`}</ContentManagerLeavesTableTypography>
        <Typography variant="caption" noWrap>{`${row.delegatedUserRoleName}`}</Typography>
      </ContentManagerTableCell>
      <ContentManagerTableCell>
        <ContentManagerLeavesTableTypography noWrap>{`${moment(row.from).format(
          'DD/MM/YYYY',
        )}`}</ContentManagerLeavesTableTypography>
      </ContentManagerTableCell>
      <ContentManagerTableCell>
        <ContentManagerLeavesTableTypography noWrap>{`${moment(row.to).format(
          'DD/MM/YYYY',
        )}`}</ContentManagerLeavesTableTypography>
      </ContentManagerTableCell>
      <ContentManagerActionsTableCell>
        {getActions({ handleEdit, row, type, handleOpenModal, handleDelete, setErrorMessage, updateTable })}
      </ContentManagerActionsTableCell>
    </ContentManagerStyledTableRow>
  ));

const getDelegationRows = ({
  rows,
  handleEdit,
  type,
  handleOpenModal,
  handleDelete,
  setErrorMessage,
  updateTable,
  t,
}) =>
  map(rows, row => (
    <ContentManagerStyledTableRow>
      <ContentManagerTableCell>
        <BidTooltip  arrow title={row.userName} placement="bottom-start" enterDelay={500} leaveDelay={200}>
          <Typography noWrap>{`${row.userName}`}</Typography>
        </BidTooltip>

        <Typography variant="caption" noWrap>{`${row.userRoleName || '-'}`}</Typography>
      </ContentManagerTableCell>
      <ContentManagerTableCell style={{ display: 'flex', marginTop: '12px' }}>
        <Typography noWrap>{`${row.countryName}`}</Typography>
        {row.showCountryWarning && <DelegationsWarning style={{ marginLeft: '10px', marginTop: '6px' }} />}
      </ContentManagerTableCell>
      <ContentManagerTableCell>
        <BidTooltip arrow title={row.delegatedUserName} placement="bottom-start" enterDelay={500} leaveDelay={200}>
          <Typography noWrap>{`${row.delegatedUserName}`}</Typography>
        </BidTooltip>

        <Typography variant="caption" noWrap>{`${row.delegatedUserRoleName || '-'}`}</Typography>
      </ContentManagerTableCell>
      <ContentManagerTableCell>
        {row.permissions.map(permission => t(`delegations_function_${permission.id}`)).join(', ')}
      </ContentManagerTableCell>
      <ContentManagerActionsTableCell>
        {getActions({ handleEdit, row, type, handleOpenModal, handleDelete, setErrorMessage, updateTable })}
      </ContentManagerActionsTableCell>
    </ContentManagerStyledTableRow>
  ));

export default function ContentManagerOrderableTable({
  columns,
  rows,
  t,
  type,
  modalTitle,
  updateTable,
  setErrorMessage,
  fromButton,
  buttonText,
  paginationData = {},
}: Props) {
  const [openModal, setOpenModal] = React.useState(false);
  const [editForm, setEditForm] = React.useState<any>();
  const [order, setOrder] = React.useState<any>(defaultSortData);
  const [currentPagination, setCurrentPagination] = React.useState<any>();

  const updateOrder = column => {
    let newOrder;
    if (order.sortField === column.id) {
      newOrder = { ...order, orderDesc: !order.orderDesc };
    } else {
      newOrder = { sortField: column.id, orderDesc: true };
    }
    setOrder(newOrder);
  };

  React.useEffect(() => {
    updateTable({ ...currentPagination, ...order });
    // eslint-disable-next-line
  }, [order]);

  const handleOpenModal = value => setOpenModal(value);

  const handleEdit = editForm => {
    setEditForm(editForm);
    setOpenModal(true);
  };

  const handleDelete = () => {
    updateTable();
  };

  const paginate = pageNumber => {
    const filters = {
      ...defaultPaginationData,
      ...order,
      pageNumber,
    };
    updateTable(filters);
    setCurrentPagination(filters);
  };

  const handlePageChange = (e, value) => paginate(value);

  return (
    <div>
      {isEmpty(rows) ? (
        <div className="content-manager__no-records">{t('no_records_default')}</div>
      ) : (
        <ContentManagerTableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {rows.length > 0 &&
                  map(columns, column => (
                    <SortableContentManagerTableCell
                      key={column.id}
                      style={{ width: column.width || '110px', paddingBottom: '0' }}
                      onClick={() => column.sortable && updateOrder(column)}
                    >
                      {`${column.title ? t(column.title).toUpperCase() : ''}`}
                      {column.sortable && (
                        <ContentManagerActionButton style={{ marginBottom: '3px' }}>
                          <OrderIcon />
                        </ContentManagerActionButton>
                      )}
                    </SortableContentManagerTableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {getRowsByType({
                rows,
                type,
                handleEdit,
                handleOpenModal,
                handleDelete,
                setErrorMessage,
                updateTable,
                t,
              })}
            </TableBody>
          </Table>
          <div className="pagination-style-content-manager">
            {!isNil(paginationData) && paginationData.pageCount > 0 ? (
              <Pagination
                count={paginationData.pageCount}
                page={paginationData.currentPage}
                onChange={handlePageChange}
                style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '15px' }}
              />
            ) : null}
            <ContentManagerModal
              isOpen={openModal}
              closeModal={() => handleOpenModal(false)}
              openModal={() => handleOpenModal(true)}
              modalTitle={t(modalTitle)}
              buttonText={t(buttonText)}
              fromButton={fromButton}
              fromDelegations={true}
            >
              {editForm}
            </ContentManagerModal>
          </div>
        </ContentManagerTableContainer>
      )}
    </div>
  );
}
