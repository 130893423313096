import React from 'react';
import { useTranslation } from 'react-i18next';
import DynamicInputField from '../MemoryHelpComponents/DynamicInputField';

export const AffairsTransversal = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h3 className='title'>3. {t('memory_help.tabs.3')}</h3>
      <p>{t('memory_help.container_tabs.3.description')}</p>
      <div className='input-dinamic'>
        <DynamicInputField />
      </div>
    </div>);
};

export default AffairsTransversal;
