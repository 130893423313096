import React, { useEffect, useState } from 'react';
import './Agenda.scss';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import DndCalendar from './Calendar/DndCalendar';
import ReadonlyCalendar from './Calendar/ReadonlyCalendar';
import { ReviewsStatusEnum } from '../../../models/ReviewsStatusEnum';
import { setTimeForNewProjects, exceededProjectCapacity, updateProjectsTimes, centerAgenda } from './util';
import { editAgendaTime } from '../../../api/reviewsApi';
import { setCurrentReview } from '../../../redux/actions/currentReview';
import ScheduleWarning from '../../Util/Modals/ScheduleWarning/ScheduleWarning';
import { groupProjectsAndAssignDateTime } from '../AgendaOrderProjects/agendaOrderProjects';
import ProjectsStatusInfo from './ProjectsStatusInfo';
import ResponsiveAgenda from '../ResponsiveAgenda/ResponsiveAgenda';
import { isMobile } from '../../../services/booleanUtil';

type Props = {
  editable?: boolean;
  filterByMyPortfolio?: boolean;
  closeAgenda: Function;
};

const filterEventsByMyPortfolio = (filterByMyPortfolio, currentReview) => {
  return filterByMyPortfolio ? currentReview.projects.filter(a => a.inPortfolio === true) : currentReview.projects;
};

const containerClassName = editable => {
  return `review-agenda-container ${editable ? 'editable' : ''}`;
};

const containerStyle = currentReview => {
  return centerAgenda(currentReview, window.innerWidth)
    ? { justifyContent: 'center' }
    : { justifyContent: 'flex-start' };
};

const parseEvents = list => {
  return list.map(p => {
    return {
      id: p.id,
      title: '',
      start: new Date(moment.utc(p.projectRevisionStartDateTime).local().toString()),
      end: new Date(moment.utc(p.projectRevisionEndDateTime).local().toString()),
      code: p.code,
      divisionCode: p.division ? p.division.code : '',
      status: p.status || ReviewsStatusEnum.Attempt,
      readonly: false,
      assignedToMe: p.assignedToMe,
      inPortfolio: p.inPortfolio,
      allDay: false,
    };
  });
};
const Agenda = ({ editable, filterByMyPortfolio, closeAgenda }: Props) => {
  const currentReview = useSelector<any, any>(state => state.currentReview);
  const [events, setEvents] = useState<any[]>([]);
  const [exceededCapacity, setExceededCapacity] = useState(false);
  const dispatch = useDispatch();

  const updateTimesAndCurrentReview = async () => {
    const list = setTimeForNewProjects(currentReview);
    const response = await editAgendaTime(
      currentReview.id,
      list.map(p => {
        return {
          id: p.id,
          status: p.status,
          newStartDate: p.projectRevisionStartDateTime,
          newEndDate: p.projectRevisionEndDateTime,
        };
      }),
    );
    dispatch(setCurrentReview({ ...response, title: currentReview.title }));
  };

  const closeAgendaBackToProjects = () => {
    setExceededCapacity(false);
    closeAgenda();
  };

  const checkCapacityClose = () => {
    if (exceededProjectCapacity(currentReview)) {
      closeAgendaBackToProjects();
    } else {
      setExceededCapacity(false);
    }
  };

  const handleAssignSchedules = async timeAllocated => {
    if (timeAllocated) {
      const sortedAndTimeAssignedProjects = groupProjectsAndAssignDateTime(currentReview, null, timeAllocated);
      await editAgendaTime(currentReview.id, sortedAndTimeAssignedProjects);
      const newCurrentReview = {
        ...currentReview,
        projects: updateProjectsTimes(currentReview.projects, sortedAndTimeAssignedProjects),
      };
      dispatch(setCurrentReview(newCurrentReview));
      checkCapacityClose();
    } else {
      checkCapacityClose();
    }
  };

  useEffect(() => {
    if (exceededProjectCapacity(currentReview)) {
      setExceededCapacity(true);
    } else {
      if (currentReview.projects.some(p => p.projectRevisionStartDateTime === null)) {
        updateTimesAndCurrentReview();
      } else {
        setEvents(parseEvents(currentReview.projects));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReview]);

  useEffect(() => {
    setEvents(parseEvents(filterEventsByMyPortfolio(filterByMyPortfolio, currentReview)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterByMyPortfolio]);

  return (
    <>
      {!exceededCapacity ? (
        <Calendars editable={editable} currentReview={currentReview} events={events} />
      ) : (
        <ScheduleWarning
          isOpen={exceededCapacity}
          closeModal={() => closeAgendaBackToProjects()}
          timeAllocated={60}
          reSchedule={value => handleAssignSchedules(value)}
        />
      )}
    </>
  );
};

export default Agenda;

type CalendarsProps = {
  editable?: boolean;
  currentReview: any;
  events: any[];
};

const Calendars = ({ editable, currentReview, events }: CalendarsProps) => {
  return isMobile() ? (
    <ResponsiveAgenda events={events} />
  ) : (
    <div style={{ display: 'flex', flexDirection: 'row', position: 'relative', width: '100%' }}>
      <div className={containerClassName(editable)} style={containerStyle(currentReview)}>
        {editable ? (
          <DndCalendar currentReview={currentReview} events={events} />
        ) : (
          <ReadonlyCalendar
            currentReview={currentReview}
            events={events.map(e => {
              e.readonly = true;
              return e;
            })}
          />
        )}
      </div>
      <ProjectsStatusInfo projects={currentReview.projects} />
    </div>
  );
};
