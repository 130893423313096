import React from 'react';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import { ReactComponent as IconWarning } from '../../../../assets/icons/u_check-circle.svg';

export default function AlertSaveChanges() {
  const [open, setOpen] = React.useState(true);

  return (
    <div className='alert-save'>
      <Collapse  in={open}>
        <Alert className='alerts success'>
          <IconWarning/> El estado se ha actualizado correctamente.
        </Alert>
      </Collapse>
    </div>
  );
}
