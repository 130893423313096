import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Project from '../../../../../../models/Project';
import styles from './AccordanceListView.module.scss';
import AccordanceListItem from './AccordanceListItem';
import { sortTablesByType } from '../../../../../../services/sortsUtil';

const headersList = [
  'new_agreement_defined_in',
  // 'description_of_the_critical_issue',
  'critical_issue',
  // 'category',
  'new_linked_product',
  'accordance_description',
  'responsible',
  // 'accordance_about',
  'due_date',
  // 'comments',
  'accordance_status',
  'actions',
];

enum ListViewSortCriteria {
  accordanceAgreementsType,
  criticalIssueName,
  linkedProductsAccordance,
  description,
  personInCharge,
  expirationDate,
  accordanceState,
  default,
}

const AccordanceListView = () => {
  const { t } = useTranslation();
  const currentProject = useSelector<any, Project>(state => state.currentProject);
  const { accordancesGrouped } = currentProject;
  const { container, rowView, rowLeft, rowRight, basicText, headerView, tableContainer, rowData } = styles;
  const [sortCriteria, setSortCriteria] = useState<ListViewSortCriteria>(ListViewSortCriteria.default);
  const [orderDesc, setOrderDesc] = useState<boolean>(false);

  const onSortClick = (criteria: ListViewSortCriteria) => {
    if (criteria === sortCriteria) {
      setOrderDesc(!orderDesc);
      sortTablesByType(accordancesGrouped, orderDesc, ListViewSortCriteria[criteria]?.toString(), 'string');
    } else if (criteria === 5) {
      setOrderDesc(!orderDesc);
      sortTablesByType(accordancesGrouped, orderDesc, ListViewSortCriteria[criteria]?.toString(), 'date');
    } else if (criteria === 6) {
      setOrderDesc(!orderDesc);
      sortTablesByType(accordancesGrouped, orderDesc, ListViewSortCriteria[criteria]?.toString(), 'number');
    }
    setSortCriteria(criteria);
  };

  return (
    <section className={container}>
      <div className="border-table">
        <div className={headerView}>
          {headersList.map((i, index) => (
            <div className={rowData} key={i} onClick={index <= 6 ? () => onSortClick(index) : () => {}}>
              <span className={index <= 2 ? styles.sortingText : ''}>{t(i)}</span>
              {index <= 6 && <span className={styles.sortIcon}></span>}
            </div>
          ))}
        </div>
        <div className={tableContainer}>
          {accordancesGrouped?.map((item, index) => (
            <div key={item.id} className={rowView}>
              {/* <div className={rowLeft}>
                <div className={basicText} style={{ flex: 2, paddingLeft: 10 }}>
                  {item.criticalIssueDescription}
                </div>
                <div className={basicText} style={{ flex: 1 }}>
                  {item.criticalIssueName}
                </div>
                <div className={basicText} style={{ flex: 1 }}>
                  {item.categoryName}
                </div>
              </div> */}
              <div className={rowRight}>
                {item.accordances.map(acc => (
                  <AccordanceListItem key={acc.id} accordance={acc} />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AccordanceListView;
