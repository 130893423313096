
import { CalledFrom } from '../../../models/CalledFromEnum';
import SelectOption from '../../../models/SelectOption';
import { AppMenuTabs } from '../../MenuTabs/userTabsInterface';

const isSelectedTabDiffThanZero = selectedTab => selectedTab.tabId !== 0;
const getTeamLeadersOptions = teamLeaders => {
  return teamLeaders.map(tl => ({
    id: tl.id,
    label: tl.name,
    value: tl.name,
  }));
};
const handleEarlyWarningsFilterPanelBehaivor = ({ selectedTab, filterEarlyWarnings }) => {
  let selectedTeamLeaders;
  if (isSelectedTabDiffThanZero(selectedTab)) {
    selectedTeamLeaders = getTeamLeadersOptions(selectedTab.teamLeaders);
  } else {
    selectedTeamLeaders = filterEarlyWarnings.common.teamLeaders;
  }
  return selectedTeamLeaders;
};
export const buildInitialStates = (calledFrom: CalledFrom, menuTabsState: AppMenuTabs, filters: any) => {
  const { filterIndicators, filter, filterEarlyWarnings } = filters;
  let selectedTab;
  let selectedTeamLeaders;

  if (calledFrom === CalledFrom.PORTFOLIO) {
    selectedTab = menuTabsState.portfolioSelectedTab;
    if (isSelectedTabDiffThanZero(selectedTab)) {
      selectedTeamLeaders = getTeamLeadersOptions(selectedTab.teamLeaders);
    } else {
      selectedTeamLeaders = filterIndicators.common.teamLeaders;
    }
  } else if (calledFrom === CalledFrom.PROJECT) {
    selectedTab = menuTabsState.projectSelectedTab;
    if (isSelectedTabDiffThanZero(selectedTab)) {
      selectedTeamLeaders = getTeamLeadersOptions(selectedTab.teamLeaders);
    } else {
      selectedTeamLeaders = filter.common.teamLeaders;
    }
  } else if (calledFrom === CalledFrom.EARLY_WARNINGS) {
    selectedTab = menuTabsState.earlyWarningsSelectedTab;
    selectedTeamLeaders = handleEarlyWarningsFilterPanelBehaivor({
      selectedTab,
      filterEarlyWarnings,
    });
  }

  return selectedTeamLeaders;
};

export const handleTeamLeaderChange = (
  teamLeaders: SelectOption[],
  calledFrom: CalledFrom,
  dispatch: any,
  filters,
  setters,
) => {
  const { filterIndicators, filterProjects, filterEarlyWarnings } = filters;
  const { setFilterIndicators, setFilter, setFilterEarlyWarnings } = setters;

  if (teamLeaders === null) {
    teamLeaders = [];
  }


  if (calledFrom === CalledFrom.PORTFOLIO) {
    dispatch(
      setFilterIndicators({
        ...filterIndicators,
        common: {
          ...filterIndicators.common,
          teamLeaders,
        },
      }),
    );
  } else if (calledFrom === CalledFrom.PROJECT) {
    dispatch(
      setFilter({
        ...filterProjects,
        common: {
          ...filterProjects.common,
          teamLeaders,
        },
      }),
    );
  } else if (calledFrom === CalledFrom.EARLY_WARNINGS) {
    dispatch(
      setFilterEarlyWarnings({
        ...filterEarlyWarnings,
        common: {
          ...filterEarlyWarnings.common,
          teamLeaders,
        },
      }),
    );
  }
};




export const buildSelectedTab = (calledFrom, menuTabs) => {
  if (calledFrom === CalledFrom.PORTFOLIO) {
    return menuTabs.portfolioSelectedTab;
  } else if (calledFrom === CalledFrom.EARLY_WARNINGS) {
    return menuTabs.earlyWarningsSelectedTab;
  }
  return menuTabs.projectSelectedTab;
};
