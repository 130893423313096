import { isOnCtTab } from '../../EarlyWarningsFilters/Util';
import { TabsFiltersEnum } from '../TabsFilter/TabsFilter';

const isCtOrPendingRatificationTabSelected = (selectedTab, projectTypeFilterOnDetail) => {
  if (selectedTab === TabsFiltersEnum.PENDING_RATIFICATION || isOnCtTab(projectTypeFilterOnDetail)) {
    return true;
  }
  return false;
};

const handleClassName = (
  selectedTab,
  projectTypeFilterOnDetail,
  pendingRatification,
  signaturePending,
  ctSignaturePending,
) => {
  if (selectedTab === TabsFiltersEnum.SIGNATURE_PENDING && isOnCtTab(projectTypeFilterOnDetail)) {
    return ctSignaturePending;
  }

  return isCtOrPendingRatificationTabSelected(selectedTab, projectTypeFilterOnDetail)
    ? pendingRatification
    : signaturePending;
};

export { isCtOrPendingRatificationTabSelected, handleClassName };
