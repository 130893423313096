import React from 'react';
import './NewAccordanceForm.scss';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Checkbox, TextField } from '@material-ui/core';
import CustomSelect from '../../../../Util/CustomSelect/CustomSelect';
import RequiredErrorMessage from '../../../../Util/Errors/RequiredErrorMessage/RequiredErrorMessage';
import CustomDatePicker from '../../../../Util/CustomDatePicker/CustomDatePicker';
import SearchUsers from '../../../../Util/SearchUsers/SearchUsers';
// import FileUploader from '../../../../FileUploader/FileUploader';
import { AccordanceType } from '../../../../../models/AccordanceType';
// import * as config from './../../../../../api/config.json';
import { AccordanceState } from '../../../../../models/AccordanceState';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { useStyles } from './NewAccordanceForm.Styles';

type Props = {
  isMulti?: boolean;
  criticalIssue?: any;
  formErrors?: any;
  possibleActionsList?: any;
  possibleAction?: any;
  handlePossibleActionChange?: any;
  expirationDate?: any;
  handleExpirationDate: Function;
  responsible?: any;
  handleIemSelected: Function;
  filesList?: any;
  addFileToList?: Function;
  removeFileFromList?: Function;
  editAccordanceData?: any;
  accordanceType: AccordanceType;
  handleDescription?: any;
  description?: string;
  revisionDate?: any;
  accordanceState?: number;
  supervisionActionList?: any;
  handleSupervisionActionChange?: any;
  supervisionAction?: any;
  removeAccordance?: Function;
  addNewAccordance?: Function;
  internalId?: string;
  rootCauseOfCritical?: any;
  rootCause?: any;
  handleRootCauseOfCritical?: any;
  handleRootCause?: any;
  rootCauseCategoriesForm?: any;
  rootCauseForm?: any;
  validateTextareaDescription?: any;
  accordanceDate?: any;
  criticalIssueListShared?: any;
  criticalIssueShared?: any;
  handleCriticalIssueChangeShared?: any;
  linkedProductShared?: any;
  handleLinkedProductShared?: any;
  productFormShared?: any;
  criticalIssueDescriptionShared?: any;
  handleCriticalIssueDescriptionShared?: any;
  validateTextareaCriticalShared?: any;
};

const displayLabel = (option: any) => {
  return option.label || '';
};

const displayName = (option: any) => {
  return option.name || option.nameProduct;
};

const getLabelAccordanceType = (accordanceType: number, t) => {
  return t('new_agreement_classification');
  // return accordanceType === AccordanceType.Revision || accordanceType === AccordanceType.InterGroup
  //   ? t('new_agreement_classification')
  //   : t('action');
};

const SingleInputs = (props: Props) => {
  const { t } = useTranslation();

  const {
    isMulti,
    criticalIssue,
    formErrors,
    possibleActionsList,
    possibleAction,
    handlePossibleActionChange,
    expirationDate,
    handleExpirationDate,
    responsible,
    handleIemSelected,
    filesList,
    addFileToList,
    removeFileFromList,
    editAccordanceData,
    accordanceType,
    handleDescription,
    description,
    revisionDate,
    accordanceState,
    supervisionActionList,
    handleSupervisionActionChange,
    supervisionAction,
    removeAccordance,
    addNewAccordance,
    internalId,
    rootCauseOfCritical,
    rootCause,
    handleRootCauseOfCritical,
    handleRootCause,
    rootCauseCategoriesForm,
    rootCauseForm,
    validateTextareaDescription,
    accordanceDate,
    criticalIssueListShared,
    criticalIssueShared,
    handleCriticalIssueChangeShared,
    linkedProductShared,
    handleLinkedProductShared,
    productFormShared,
    criticalIssueDescriptionShared,
    handleCriticalIssueDescriptionShared,
    validateTextareaCriticalShared,
  } = props;

  const classes = useStyles();

  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <>
      {!isMulti && (
        <h3 style={{ fontWeight: 'bold', margin: '25px 0 -10px 0' }}>
          {t('new_context_of_the_critical_issue_and_root_rause')}
        </h3>
      )}

      {!isMulti ? (
        <div className="horizontal-row">
          <div className="label-select-container">
            <p className="label">{t('critical_issue')}</p>
            <Autocomplete
              id="search-criticl-issues"
              options={criticalIssueListShared?.filter(critical => critical.categoryName !== null)}
              getOptionLabel={displayLabel}
              value={criticalIssue}
              onChange={handleCriticalIssueChangeShared}
              renderInput={params => <TextField placeholder={t('select_item')} {...params} fullWidth />}
              noOptionsText={t('no_results')}
              classes={formErrors?.criticalIssue ? classes : {}}
              getOptionSelected={(option, value) => option.id === value.id}
            />
            {formErrors?.criticalIssue && <RequiredErrorMessage />}
          </div>
          <div className="label-select-container MuiAutocomplete-inputRoot-prueba" style={{ width: '100%' }}>
            {/* <p className="label">{t('category')}</p>
          <span className="category-value">{category || '-'}</span> */}
            {productFormShared?.length !== 0 ? (
              <>
                <p className="label">{t('new_linked_product')}</p>
                <Autocomplete
                  groupBy={option => `${t('new_component')} ${option.componentOrder}`}
                  multiple
                  value={linkedProductShared}
                  id="checkboxes-tags-demo"
                  options={productFormShared}
                  onChange={handleLinkedProductShared}
                  disableCloseOnSelect
                  noOptionsText={t('no_results')}
                  getOptionSelected={(option, value) => option.id === value.id}
                  getOptionLabel={displayName}
                  renderOption={(option, { selected }) => (
                    <>
                      <Checkbox
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 28 }}
                        checked={
                          !selected
                            ? linkedProductShared.find(element => element.nameProduct === option.name)
                            : selected
                        }
                      />
                      {option.name}
                    </>
                  )}
                  renderInput={params => (
                    <TextField
                      {...params}
                      placeholder={linkedProductShared.length !== 0 ? ' ' : t('products')}
                      fullWidth
                      variant="outlined"
                    />
                  )}
                  classes={formErrors?.linkedProductsAccordance ? classes : {}}
                />
                {formErrors?.linkedProductsAccordance && <RequiredErrorMessage />}
              </>
            ) : null}
          </div>
        </div>
      ) : null}

      <div className="horizontal-row">
        <div className="label-select-container">
          <p className="label">{t('new_root_cause_of_critical_issue')}</p>
          <Autocomplete
            id="checkboxes-tags-demo"
            onChange={handleRootCauseOfCritical}
            value={rootCauseOfCritical}
            options={rootCauseCategoriesForm}
            getOptionLabel={option => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            renderInput={params => (
              <TextField {...params} variant="outlined" label="" placeholder={t('new_all_categories')} />
            )}
            classes={formErrors?.rootCauseOfCritical ? classes : {}}
            noOptionsText={t('no_results')}
          />
          {formErrors?.rootCauseOfCritical && <RequiredErrorMessage />}
        </div>
        <div className="label-select-container category" style={{ width: '100%' }}>
          {/* <p className="label">{t('category')}</p>
          <span className="category-value">{category || '-'}</span> */}
          <Autocomplete
            id="checkboxes-tags-demo"
            options={rootCauseForm}
            onChange={handleRootCause}
            value={rootCause}
            getOptionLabel={option => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            renderInput={params => (
              <TextField {...params} variant="outlined" label="" placeholder={t('new_sub_categories')} />
            )}
            classes={formErrors?.rootCause ? classes : {}}
            noOptionsText={t('no_results')}
          />
          {formErrors?.rootCause && <RequiredErrorMessage />}
        </div>
      </div>

      {!isMulti ? (
        <div className="horizontal-row">
          <div className="label-select-container text-area">
            <div className="label-selec-number-caracteres">
              <p className="label">{`${t('description_of_the_critical_issue')}`}</p>
              <p>{`${
                criticalIssueDescriptionShared?.length === undefined ? 0 : criticalIssueDescriptionShared?.length
              }/250`}</p>
            </div>
            <textarea
              {...(formErrors?.criticalIssueDescription && { style: { border: '1px solid #ed5a4d' } })}
              maxLength={250}
              value={criticalIssueDescriptionShared}
              onChange={e => {
                validateTextareaCriticalShared(e.target.value);
              }}
              onBlur={e => handleCriticalIssueDescriptionShared(e.target.value)}
              placeholder={t('new_enter_more_details_about_the_critical_issue_that_the_new_agreement_will_resolve_two')}
            ></textarea>
            {formErrors?.criticalIssueDescription && <RequiredErrorMessage />}
          </div>
        </div>
      ) : (
        <div className="horizontal-row">
          <div className="label-select-container text-area">
            <div className="label-selec-number-caracteres">
              <p className="label">{`${t('accordance_description')}`}</p>
              <p>{`${description?.length === undefined ? 0 : description?.length}/250`}</p>
            </div>
            <textarea
              {...(formErrors?.description && { style: { border: '1px solid #ed5a4d' } })}
              maxLength={250}
              value={description}
              onChange={e => {
                validateTextareaDescription(e.target.value);
              }}
              onBlur={e => handleDescription(e.target.value)}
              placeholder={t('new_describe_in_detail_what_the_agreement_is_about_two')}
            ></textarea>
            {formErrors?.description && <RequiredErrorMessage />}
          </div>
        </div>
      )}

      {/* <div className="horizontal-row">
        <div className="label-select-container label-container-accorrdance-select">
          <p className="label">{getLabelAccordanceType(accordanceType, t)}</p>
          <CustomSelect
            options={possibleActionsList?.filter(possibleAction => possibleAction.label !== null)}
            selectedOption={possibleAction}
            handleSelected={handlePossibleActionChange}
            disabled={!criticalIssue}
            translatedFromBackend={true}
            style={{ width: 730 }}
            placeholder={t('select_accordance')}
            error={formErrors?.possibleAction}
          />
          {formErrors?.possibleAction && <RequiredErrorMessage />}
        </div>
        <div className="label-select-container category">
          <CustomDatePicker
            date={expirationDate}
            label={t('due_date')}
            setDate={handleExpirationDate}
            error={formErrors.expirationDate}
            minDate={revisionDate}
            disabled={
              (accordanceType === AccordanceType.Revision || accordanceType === AccordanceType.InterGroup) &&
              !revisionDate
            }
            placeholderComplete={true}
          />
          {formErrors.expirationDate && <RequiredErrorMessage />}
        </div>
      </div> */}

      {!isMulti ? null : (
        <>
          <div className="horizontal-row">
            <div className="label-select-container">
              <p className="label">{t('new_bid_responsible')}</p>
              {editAccordanceData && accordanceState === AccordanceState.DRAFT ? (
                <span className="label">
                  {editAccordanceData.personInCharge.name} {editAccordanceData.personInCharge.lastName}
                </span>
              ) : (
                <SearchUsers selected={responsible} itemSelected={handleIemSelected} error={formErrors?.responsible} />
              )}
              <p className="label-observation">{t('new_assign_to_the_manager')}</p>
              {formErrors?.responsible && <RequiredErrorMessage />}
            </div>
          </div>
          <div className="horizontal-row">
            <div className="label-select-container category-date">
              <CustomDatePicker
                date={expirationDate}
                label={t('due_date')}
                setDate={handleExpirationDate}
                error={formErrors?.expirationDate}
                minDate={revisionDate}
                disabled={!revisionDate}
                placeholderComplete={true}
              />
              {formErrors?.expirationDate && <RequiredErrorMessage />}
            </div>
          </div>
        </>
      )}

      {/* {accordanceType === AccordanceType.Monitoring && (
        <div className="horizontal-row">
          <div className="label-select-container">
            <p className="label">{`${t('supervision_action')} (${t('optional')})`}</p>
            <Autocomplete
              id="search-supervision-actions"
              options={supervisionActionList || []}
              getOptionLabel={displayLabel}
              onChange={handleSupervisionActionChange}
              renderInput={params => <TextField placeholder={t('select_activity')} {...params} fullWidth />}
              noOptionsText={t('no_results')}
              getOptionSelected={(option, value) => option.id === value.id}
              value={supervisionAction}
            />
          </div>
        </div>
      )} */}
      {/* <div className="horizontal-row">
        <div className="label-select-container " style={{ width: '100%' }}>
          <FileUploader
            filesList={filesList}
            addFile={addFileToList}
            removeFile={removeFileFromList}
            uploadUrl={config.ACCORDANCE_ATTACHEMENTS}
            customId={internalId}
          />
        </div>
      </div> */}

      {removeAccordance && addNewAccordance ? (
        <div className="horizontal-column">
          <div className="label-select-container ">
            <button
              className="multi-accordances-link delete multi-accordances-link-delete"
              onClick={() => removeAccordance()}
              style={{ position: 'relative', bottom: 'unset' }}
            >
              {t('delete')}
            </button>
          </div>
          <div className="label-select-container container-multi-accordances-button">
            <button
              className="multi-accordances-link "
              onClick={() => addNewAccordance()}
              style={{ position: 'relative', bottom: 'unset' }}
            >
              <div className="multi-accordances-buttton"></div>
              {t('save_and_add_to_bundle')}
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default SingleInputs;
