import React, { useEffect, useState } from 'react';
import CountryFilter from '../CountryFilter/CountryFilter';
import RegionFilter from '../RegionFilter/RegionFilter';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CalledFrom } from '../../../models/CalledFromEnum';
import { MenuTabs } from '../../MenuTabs/userTabsInterface';
import {
  buildShowCountryFilterState,
  buildShowRegionFilter,
  buildCountriesWhenRegionsChange,
  buildInitialStates,
} from './utils';
import { showCountryFilterStates } from './models';
import Country from '../../../models/Country';

type Props = {
  calledFrom: CalledFrom;
  setRegionSelected?: Function;
  setCountrySelected?: Function;
  isEdit?: boolean;
};

const RegionAndCountryFilters = (props: Props) => {
  const { calledFrom, setRegionSelected, setCountrySelected, isEdit } = props;
  const { t } = useTranslation();
  const menuTabs = useSelector<any, MenuTabs>(state => state.menuTabs);
  const regionsState = useSelector<any, any>(state => state.regions);
  const [regionState, setRegionState] = useState<any>();
  const [countriesState, setCountriesState] = useState<Country[]>([]);
  const [allCountries, setAllCountries] = useState<any>([]);
  const [showRegionFilter, setShowRegionFilter] = useState<boolean>(!buildShowRegionFilter(calledFrom, menuTabs));
  const [showCountryFilterState] = useState<showCountryFilterStates>(buildShowCountryFilterState(calledFrom, menuTabs));

  const addValueToCountriesOption = (countriesOptions, country) =>
    countriesOptions.push({
      id: country.id,
      label: country.name,
      value: country.name,
      categoryId: country.regionId,
    });

  useEffect(() => {
    buildInitialStates({
      showCountryFilterState,
      addValueToCountriesOption,
      setRegionState,
      setCountriesState,
      setAllCountries,
      regionsState,
      calledFrom,
      menuTabs,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setShowRegionFilter(!buildShowRegionFilter(calledFrom, menuTabs));
  }, [menuTabs]);

  const onRegionSelectChange = value => {
    const countriesAux = buildCountriesWhenRegionsChange(value, regionsState);
    setCountriesState(countriesAux);
  };

  return (
    <>
      {showRegionFilter && (
        <div className="filter-wrapper">
          <h4 className="paragraph-x-small filter-wrapper__label">{t('region')}</h4>
          <RegionFilter
            regions={regionState}
            filterCountriesOnSelect={value => onRegionSelectChange(value)}
            calledFrom={calledFrom}
            setOptionSelected={setRegionSelected}
            isEdit={isEdit}
          />
        </div>
      )}
      {menuTabs.portfolioSelectedTab.internalId === '0_global_bid' && (
        <div className="filter-wrapper">
          <h4 className="paragraph-x-small filter-wrapper__label">{t('country')}</h4>
          <CountryFilter
            countries={countriesState}
            calledFrom={calledFrom}
            setOptionSelected={setCountrySelected}
            isEdit={isEdit}
          />
        </div>
      )}

      {showCountryFilterState === showCountryFilterStates.ShowComplete ||
        (showCountryFilterState === showCountryFilterStates.ShowByRegion && (
          <div className="filter-wrapper">
            <h4 className="paragraph-x-small filter-wrapper__label">{t('country')}</h4>
            <CountryFilter
              countries={countriesState}
              calledFrom={calledFrom}
              setOptionSelected={setCountrySelected}
              isEdit={isEdit}
            />
          </div>
        ))}
    </>
  );
};

export default RegionAndCountryFilters;
