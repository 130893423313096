import React, { useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import { multiSelectStyle, DropdownIndicator } from '../FilterComponent.Styles';
import { useTranslation } from 'react-i18next';
import { getCustomTabDefaultValueFromProps } from '../UtilFunctions';
import { CalledFrom } from '../../../models/CalledFromEnum';
import SelectOption from '../../../models/SelectOption';
import { setFilterIndicators } from '../../../redux/actions/filterIndicators';
import { setFilter } from '../../../redux/actions/filter';
import { AppMenuTabs } from '../../MenuTabs/userTabsInterface';
import { setFilterEarlyWarnings } from '../../../redux/actions/filterEarlyWarnings';
import { TagFilterContext } from '../../../context/TagFilterContext';

type Props = {
  setOptionsSelected?: Function;
  calledFrom: CalledFrom;
  isEdit?: boolean;
};

const handleInterestIssuesOptions = (selectedInterestIssues, allOptions) => {
  return selectedInterestIssues.length
    ? allOptions.filter(item => selectedInterestIssues.includes(item.id))
    : allOptions;
};

const getFilteredSelectOptions = (allOptions: any[], menuTabs: AppMenuTabs, calledFrom: CalledFrom) => {
  const portfolioSelectedTab = menuTabs.portfolioSelectedTab;
  const projectSelectedTab = menuTabs.projectSelectedTab;
  const earlyWarningsSelectedTab = menuTabs.earlyWarningsSelectedTab;

  if (calledFrom === CalledFrom.PORTFOLIO) {
    if (portfolioSelectedTab.tabId === 0) {
      return allOptions;
    } else {
      const selectedInterestIssues = portfolioSelectedTab.interestIssues;
      return handleInterestIssuesOptions(selectedInterestIssues, allOptions);
    }
  } else if (calledFrom === CalledFrom.PROJECT) {
    if (projectSelectedTab.tabId === 0) {
      return allOptions;
    } else {
      const selectedInterestIssues = projectSelectedTab.interestIssues;
      return handleInterestIssuesOptions(selectedInterestIssues, allOptions);
    }
  }
  if (calledFrom === CalledFrom.EARLY_WARNINGS) {
    if (earlyWarningsSelectedTab.tabId === 0) {
      return allOptions;
    } else {
      const selectedInterestIssues = earlyWarningsSelectedTab.interestIssues;
      return handleInterestIssuesOptions(selectedInterestIssues, allOptions);
    }
  } else {
    return allOptions;
  }
};

const AttributesFilter = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setOptionsSelected, calledFrom, isEdit } = props;

  const [attributesOptions, setAttributesOptions] = useState<SelectOption[]>([]);
  const menuTabs = useSelector<any, any>(state => state.menuTabs);
  const allInterestIssues = useSelector<any, any>(state => state.interestIssues);
  const interestIssues = getFilteredSelectOptions(allInterestIssues, menuTabs, calledFrom);
  const filterIndicators = useSelector<any, any>(state => state.filterIndicators);
  const filter = useSelector<any, any>(state => state.filter);
  const filterEarlyWarnings = useSelector<any, any>(state => state.filterEarlyWarnings);

  const selectedTab =
    calledFrom === CalledFrom.CUSTOM_TABS_PORTFOLIO ? menuTabs.portfolioSelectedTab : menuTabs.projectSelectedTab;

  const handleChange = value => {
    if (value === null) {
      value = [];
    }

    switch (calledFrom) {
      case CalledFrom.EARLY_WARNINGS:
        dispatch(
          setFilterEarlyWarnings({
            ...filterEarlyWarnings,
            common: {
              ...filterEarlyWarnings.common,
              interestIssues: value,
            },
          }),
        );
        break;
      case CalledFrom.PORTFOLIO:
        dispatch(
          setFilterIndicators({
            ...filterIndicators,
            common: {
              ...filterIndicators.common,
              interestIssues: value,
            },
          }),
        );
        break;
      case CalledFrom.PROJECT:
        dispatch(
          setFilter({
            ...filter,
            common: {
              ...filter.common,
              interestIssues: value,
            },
          }),
        );
        break;
      case CalledFrom.CUSTOM_TABS_PORTFOLIO:
      case CalledFrom.CUSTOM_TABS_PROJECT:
        setOptionsSelected && setOptionsSelected(value);
        break;
    }
  };

  const getDefaultValue = () => {
    const options = interestIssues.map(i => ({ id: i.id, value: i.name, label: i.name }));

    switch (calledFrom) {
      case CalledFrom.EARLY_WARNINGS:
        return filterEarlyWarnings.common.interestIssues;
      case CalledFrom.PORTFOLIO:
        return filterIndicators.common.interestIssues;
      case CalledFrom.PROJECT:
        return filter.common.interestIssues;
      case CalledFrom.CUSTOM_TABS_PORTFOLIO:
      case CalledFrom.CUSTOM_TABS_PROJECT:
        return isEdit ? getCustomTabDefaultValueFromProps(selectedTab, 'interestIssues', options) : null;
      default:
        return null;
    }
  };

  /* tag logic */
  const { setTagOptions } = useContext(TagFilterContext);
  const getValuesSelected = getDefaultValue();

  useEffect(() => {
    setTagOptions("Attributes", getValuesSelected)
  }, [getValuesSelected, setTagOptions])

  useEffect(() => {
    const options = interestIssues.map(i => ({ id: i.id, value: i.name, label: i.name }));
    setAttributesOptions(options);

    if (isEdit) {
      const selectedOption = getDefaultValue();
      setOptionsSelected && setOptionsSelected(selectedOption);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const selector = useSelector<any, any>(state => state.filterIndicators.common);

  return (
    <Select
      key={selector.interestIssues}
      id="attributesSelect"
      isMulti
      // components={{ DropdownIndicator }}
      defaultValue={getDefaultValue()}
      name="attributes"
      options={attributesOptions}
      className="basic-multi-select"
      classNamePrefix="All"
      placeholder={t('all')}
      onChange={value => handleChange(value)}
      styles={multiSelectStyle}
    />
  );
};

export default AttributesFilter;
