import React from 'react';
import { useTranslation } from 'react-i18next';
import Project from '../../../models/Project';
import { ProjectProductTypeClassification } from '../../../models/ProjectProductTypeClassification';
import { showIndexTCDI } from '../../../services/booleanUtil';
import { PmrClassificationComponent } from '../../Util/PmrClassification/PmrClassification';
import { PmrPrintValue } from '../../Util/PmrPrintValue/PmrPrintValue';
import { TcdiClassificationComponent } from '../../Util/TcdiClassification/TcdiClassification';
import styles from './CardIndex.module.scss';

type Props = {
  project: Project;
};

export const CardIndex = ({ project }: Props) => {
  const { pmrClassification, pmrValue, projectProductType, projectProductTypeClassification } = project;
  const { pmr, value, flag, title, tcdi, adjustedFlag } = styles;
  const { t } = useTranslation();

  const renderPmrIndex = () => {
    return (
      <div className={pmr}>
        <span className={value}>
          <PmrPrintValue pmrClassi={pmrClassification} pmrValue={pmrValue} />
        </span>
        <div className={flag}>
          <div className={title}>{t('pmr_initials')}</div>
          <PmrClassificationComponent classificationColor={pmrClassification} />
        </div>
      </div>
    );
  };

  const renderTCDIIndex = () => {
    return (
      <div className={tcdi}>
        <div className={value}>{project.tcdiValue || t('not_assigned_initials')}</div>
        <div className={adjustedFlag}>
        <div className={title}>{t('tcdi_initials')}</div>
          <TcdiClassificationComponent clasification={project.tcdiValue || null} />
          
        </div>
      </div>
    );
  };

  return showIndexTCDI(
    projectProductType,
    projectProductTypeClassification &&
      projectProductTypeClassification['code'] === ProjectProductTypeClassification.IGR_GREATER_THAN_3MM,
  )
    ? renderTCDIIndex()
    : renderPmrIndex();
};
