import { IndicatorKeyEnum } from '../../../models/IndicatorKeyEnum';
import IndicatorMetric from '../../../models/IndicatroMetric';
import { MonthsEnum } from '../../../models/MonthsEnum';
import { ProjectProductTypes } from '../../../models/ProjectProductTypes';
import { ProjectProductTypesAvatarText } from '../../../models/ProjectProductTypesAvatarText';
import { ShortMonthsEnum } from '../../../models/ShortMonthEnum';
import { parseFilterToApiCall } from '../../../services/util';
import { emptyValue } from '../../ProjectDetail/Calendar/EventDetailInfo/constants';
import { findMetricValue, findMetricValueByGrouping } from './indicatorHelperUtil';
import { ImetricsData } from './interfaces';

const PROJECT_PRODUCT_TYPE = 'PROJECT_PRODUCT_TYPE';
const LON = 'LON';
const IGR = 'IGR';
const GUA = 'GUA';
const PROJECT_COUNT = 'PROJECT_COUNT';
const APPROVED_AMOUNT = 'APPROVED_AMOUNT';
const DISBURSEMENT_AMOUNT = 'DISBURSEMENT_AMOUNT';
const PENDING_DISBURSEMENT_AMOUNT = 'PENDING_DISBURSEMENT_AMOUNT';
const DISBURSEMENT_PERCENTAGE = 'DISBURSEMENT_PERCENTAGE';
const AMOUNT = 'AMOUNT';
const APPROVED_INVERSION_AMOUNT = 'APPROVED_INVERSION_AMOUNT';
const APPROVED_PBP_AMOUNT = 'APPROVED_PBP_AMOUNT';
const AGE_AVERAGE = 'AGE_AVERAGE';
const PENDING_SIGNATURE_AMOUNT = 'PENDING_SIGNATURE_AMOUNT';
const PENDING_RATIFICATION_AMOUNT = 'PENDING_RATIFICATION_AMOUNT';
const PROBLEM_PERCENTAGE_AMOUNT = 'PROBLEM_PERCENTAGE_AMOUNT';
const AMOUNT_PROJECT_PROBLEM = 'AMOUNT_PROJECT_PROBLEM';
const AMOUNT_PROBLEM = 'AMOUNT_PROBLEM';
const AMOUNT_APPROVED_PROBLEM = 'AMOUNT_APPROVED_PROBLEM';
const AMOUNT_DISURSEMENT_PROBLEM = 'AMOUNT_DISURSEMENT_PROBLEM';
const AMOUNT_WITHOUT_DISBURSEMENT_PROBLEM = 'AMOUNT_WITHOUT_DISBURSEMENT_PROBLEM';
const AMOUNT_DISBURSEMENT_PERCENT_PROBLEM = 'AMOUNT_DISBURSEMENT_PERCENT_PROBLEM';
const AVERAGE_AGE_PROBLEM = 'AVERAGE_AGE_PROBLEM';
const PROJECT_PRODUCT_TYPE_AMOUNT = 'PROJECT_PRODUCT_TYPE-AMOUNT';
const IGR_GREATER = 'IGR_GREATER';
const IGR_LOWER = 'IGR_LOWER';
const PROJECT_PRODUCT_TYPE_BENEFICIARY_TYPE = 'PROJECT_PRODUCT_TYPE-BENEFICIARY_TYPE';
const TCP_NAT = 'TCP_NAT';
const DISBURSEMENT_PERCENT = 'DISBURSEMENT_PERCENT';
const BID_EXECUTION_PERCENTAGE = 'BID_EXECUTION_PERCENTAGE';
const AGENCY_EXECUTION_PERCENTAGE = 'AGENCY_EXECUTION_PERCENTAGE';
const LON_SUPPORT_PERCENTAGE = 'LON_SUPPORT_PERCENTAGE';
const INV_PERCENTAGE = 'INV_PERCENTAGE';
const CLIENT_SUPPORT_PERCENTAGE = 'CLIENT_SUPPORT_PERCENTAGE';
const TCP_REG = 'TCP_REG';
const UDR_SHARE = 'UDR_SHARE';
const UP_TO_8_SHARE = 'UP_TO_8_SHARE';
const MORE_THAN_8_SHARE = 'MORE_THAN_8_SHARE';
const TCP = 'TCP';

export const setKnowledgeTitle = (rangeStored, projectsKnowledgeProducts, indicators) => {
  const metrics = indicators.find(m => m.key === IndicatorKeyEnum.PROJECTS_KNOWLEDGE_PRODUCTS).metrics;
  const allValuesAre0 = metrics.every(m => m.metricValue === 0);

  let value;
  let label;
  switch (rangeStored) {
    case '180':
      label = 'published_in_the_last_6_months';
      value = getSelectedRangeValue('Less6Months', metrics);
      break;
    case '365':
      label = 'published_in_the_last_year';
      value = getSelectedRangeValue('Less12Months', metrics);
      break;
    case '0':
      label = 'all_published';
      value = getSelectedRangeValue('AllProducts', metrics);
      break;
    default:
      label = 'published_in_the_last_30_days';
      value = getSelectedRangeValue('Less30Days', metrics);
      break;
  }

  projectsKnowledgeProducts = {
    ...projectsKnowledgeProducts,
    data: allValuesAre0 ? null : { ...projectsKnowledgeProducts.data, boldText: label, value },
    subtitle: allValuesAre0 ? '' : label,
  };

  return projectsKnowledgeProducts;
};

const getSelectedRangeValue = (range, metrics) => {
  const foundValue = metrics.find(v => v.metricName === range);
  return foundValue ? foundValue.metricValue : '';
};

export const setSevenMonthsToCard = (currentMonth: number, monthLabels: string[]) => {
  const currentMonthIndex = currentMonth - 1;
  if (currentMonth > MonthsEnum.SEPTEMBER) {
    return monthLabels.slice(5, 12);
  } else if (currentMonth < MonthsEnum.APRIL) {
    return monthLabels.slice(0, 7);
  }
  return monthLabels.slice(currentMonthIndex - 3, currentMonthIndex + 4);
};

export const getSevenMonthMetrics = (metrics: ImetricsData[], sevenMonthsLabels: string[], metricName: string) => {
  const filteredByMetricName = metrics.filter(i => i.metricName === metricName);
  const from = ShortMonthsEnum[sevenMonthsLabels[0]];
  const to = ShortMonthsEnum[sevenMonthsLabels[6]];
  const slicedMetricsName = filteredByMetricName.slice(parseInt(from) - 1, parseInt(to));
  return slicedMetricsName.map(m => m.metricValue);
};

export const getFilteredValue = (metrics, metricName, source) => {
  if (!source || source === 'all') {
    source = 'NONE';
  }
  const foundedValue = metrics.find(i => i.groupingValue === source && i.metricName === metricName);
  return foundedValue ? foundedValue.metricValue : 0;
};
export const getGaps = (fullYearDataPlannedAdvance, fullYearDataRealAdvance) =>
  fullYearDataPlannedAdvance.map((v, i) => {
    return fullYearDataRealAdvance[i] > v
      ? Math.round((v * 100) / fullYearDataRealAdvance[i] - 100)
      : Math.round(Math.abs((fullYearDataRealAdvance[i] * 100) / v - 100));
  });

export const getMetricByQuadrant = (metrics, quadrant, metricName) => {
  return metrics.filter(f => f.groupingValue === quadrant && f.metricName === metricName);
};

export const getRealativeRadio = (maxRadioInPixels, maxQuadraticRadio, quadraticRadio) => {
  return (quadraticRadio * maxRadioInPixels) / maxQuadraticRadio;
};

const concatByProductType = (label, metric, suffix) => {
  return label.concat(`${metric.length ? metric[0].metricValue : 0} ${suffix}`);
};

const handleActiveMetricValue = activeMetric => (activeMetric.length ? activeMetric[0].metricValue : 0);

export const setLabels = (projectTypeFilter = [0], metrics, quadrant) => {
  const filterProjectTypes = [...projectTypeFilter];
  const filtersApplied = parseFilterToApiCall(filterProjectTypes);
  const { projectProductTypes } = filtersApplied;

  const lonMetric = getMetricByQuadrant(metrics, quadrant, 'LON_PROJECT_COUNT');
  const tcpMetric = getMetricByQuadrant(metrics, quadrant, 'TCP_PROJECT_COUNT');
  const igrMetric = getMetricByQuadrant(metrics, quadrant, 'IGR_PROJECT_COUNT');

  let label = '';
  if (projectProductTypes.length > 1) {
    if (projectProductTypes.includes(ProjectProductTypes.Loan)) {
      label = concatByProductType(label, lonMetric, 'lon');
    }
    if (projectProductTypes.includes(ProjectProductTypes.CT)) {
      if (label !== '') {
        label = label.concat(' / ');
      }
      label = concatByProductType(label, tcpMetric, 'tcp');
    }
    if (projectProductTypes.includes(ProjectProductTypes.IG)) {
      if (label !== '') {
        label = label.concat(' / ');
      }
      label = concatByProductType(label, igrMetric, 'igr');
    }
  } else {
    const activeFilterToMetric = ProjectProductTypesAvatarText[projectProductTypes[0]];
    const activeMetric = getMetricByQuadrant(metrics, quadrant, `${activeFilterToMetric}_PROJECT_COUNT`);
    const metricValue = handleActiveMetricValue(activeMetric);
    label = `${metricValue} projects`;
  }

  return label;
};

const haveDataOrIsEmpty = value => (value ? value : emptyValue);

export const getIgValues = metrics => {
  const igBothProjectAmount = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE, IGR, PROJECT_COUNT),
    pluralText: 'investment_grants',
    singularText: 'investment_grant',
    pluralTextDetail: 'projects',
    singularTextDetail: 'project',
  };
  const igGreaterProjectAmount = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE_AMOUNT, IGR_GREATER, PROJECT_COUNT),
    pluralText: 'investment_grants',
    singularText: 'investment_grant',
    pluralTextDetail: 'projects',
    singularTextDetail: 'project',
  };
  const igLowerProjectAmount = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE_AMOUNT, IGR_LOWER, PROJECT_COUNT),
    pluralText: 'investment_grants',
    singularText: 'investment_grant',
    pluralTextDetail: 'projects',
    singularTextDetail: 'project',
  };

  const igBothApprovedAmount = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE, IGR, APPROVED_AMOUNT),
    text: 'current_approved_amount_usd_complete',
  };
  const igGreaterApprovedAmount = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE_AMOUNT, IGR_GREATER, APPROVED_AMOUNT),
    text: 'current_approved_amount_usd_complete',
  };
  const igLowerApprovedAmount = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE_AMOUNT, IGR_LOWER, APPROVED_AMOUNT),
    text: 'current_approved_amount_usd_complete',
  };

  const igBothPendingDisbursementAmount = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE, IGR, PENDING_DISBURSEMENT_AMOUNT),
    text: 'pending_disbursement_dollar_sign',
  };
  const igGreaterPendingDisbursementAmount = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE_AMOUNT, IGR_GREATER, PENDING_DISBURSEMENT_AMOUNT),
    text: 'pending_disbursement_dollar_sign',
  };
  const igLowerPendingDisbursementAmount = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE_AMOUNT, IGR_LOWER, PENDING_DISBURSEMENT_AMOUNT),
    text: 'pending_disbursement_dollar_sign',
  };

  const igBothDisbursementAmount = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE, IGR, DISBURSEMENT_AMOUNT),
    text: 'summary_indicator_total_disbursed_usd',
  };
  const igGreaterDisbursementAmount = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE_AMOUNT, IGR_GREATER, DISBURSEMENT_AMOUNT),
    text: 'summary_indicator_total_disbursed_usd',
  };
  const igLowerDisbursementAmount = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE_AMOUNT, IGR_LOWER, DISBURSEMENT_AMOUNT),
    text: 'summary_indicator_total_disbursed_usd',
  };

  const igBothDisbursementPercent = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE, IGR, DISBURSEMENT_PERCENTAGE),
    text: 'disbursed',
  };
  const igGreaterDisbursementPercent = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE_AMOUNT, IGR_GREATER, DISBURSEMENT_PERCENTAGE),
    text: 'disbursed',
  };
  const igLowerDisbursementPercent = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE_AMOUNT, IGR_LOWER, DISBURSEMENT_PERCENTAGE),
    text: 'disbursed',
  };

  const igBothAgeAverage = {
    value: monthToYears(findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE, IGR, AGE_AVERAGE)),
    text: 'average_age',
  };
  const igGreaterAgeAverage = {
    value: monthToYears(findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE_AMOUNT, IGR_GREATER, AGE_AVERAGE)),
    text: 'average_age',
  };
  const igLowerAgeAverage = {
    value: monthToYears(findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE_AMOUNT, IGR_LOWER, AGE_AVERAGE)),
    text: 'average_age',
  };

  const igBothPendingSignatureAmount = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE, IGR, PENDING_SIGNATURE_AMOUNT),
    text: 'pending_signing_of_contracts',
  };
  const igGreaterPendingSignatureAmount = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE_AMOUNT, IGR_GREATER, PENDING_SIGNATURE_AMOUNT),
    text: 'pending_signing_of_contracts',
  };
  const igLowerPendingSignatureAmount = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE_AMOUNT, IGR_LOWER, PENDING_SIGNATURE_AMOUNT),
    text: 'pending_signing_of_contracts',
  };

  const igBothPendingRatificationAmount = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE, IGR, PENDING_RATIFICATION_AMOUNT),
    text: 'pending_legislative_ratification',
  };
  const igGreaterPendingRatificationAmount = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE_AMOUNT, IGR_GREATER, PENDING_RATIFICATION_AMOUNT),
    text: 'pending_legislative_ratification',
  };
  const igLowerPendingRatificationAmount = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE_AMOUNT, IGR_LOWER, PENDING_RATIFICATION_AMOUNT),
    text: 'pending_legislative_ratification',
  };

  return {
    igBothProjectAmount,
    igGreaterProjectAmount,
    igLowerProjectAmount,
    igBothApprovedAmount,
    igGreaterApprovedAmount,
    igLowerApprovedAmount,
    igBothPendingDisbursementAmount,
    igGreaterPendingDisbursementAmount,
    igLowerPendingDisbursementAmount,
    igBothDisbursementAmount,
    igGreaterDisbursementAmount,
    igLowerDisbursementAmount,
    igBothDisbursementPercent,
    igGreaterDisbursementPercent,
    igLowerDisbursementPercent,
    igBothAgeAverage,
    igGreaterAgeAverage,
    igLowerAgeAverage,
    igBothPendingSignatureAmount,
    igGreaterPendingSignatureAmount,
    igLowerPendingSignatureAmount,
    igBothPendingRatificationAmount,
    igGreaterPendingRatificationAmount,
    igLowerPendingRatificationAmount,
  };
};

export const getCtValues = metrics => {
  const ctsBothProjectAmount = {
    value: haveDataOrIsEmpty(findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE, TCP, PROJECT_COUNT)),
    pluralText: 'technical_cooperation',
    singularText: 'technical_cooperation_singular',
    pluralTextDetail: 'projects',
    singularTextDetail: 'project',
  };

  const ctsBothApprovedAmount = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE, TCP, APPROVED_AMOUNT),
    text: 'current_approved_amount_usd_complete',
  };

  const ctsBothDisbursementPercent = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE, TCP, DISBURSEMENT_PERCENTAGE),
    text: 'disbursed',
  };
  const ctsNatProjectAmount = {
    value: haveDataOrIsEmpty(
      findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE_BENEFICIARY_TYPE, TCP_NAT, PROJECT_COUNT),
    ),
    pluralText: 'technical_cooperation',
    singularText: 'technical_cooperation_singular',
    pluralTextDetail: 'projects',
    singularTextDetail: 'project',
  };

  const ctsNatApprovedAmount = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE_BENEFICIARY_TYPE, TCP_NAT, APPROVED_AMOUNT),
    text: 'current_approved_amount_usd_complete',
  };

  const ctsNatPendingDisbursementAmount = {
    value: findMetricValueByGrouping(
      metrics,
      PROJECT_PRODUCT_TYPE_BENEFICIARY_TYPE,
      TCP_NAT,
      PENDING_DISBURSEMENT_AMOUNT,
    ),
    text: 'pending_disbursement_dollar_sign',
  };

  const ctsNatDisbursementAmount = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE_BENEFICIARY_TYPE, TCP_NAT, DISBURSEMENT_AMOUNT),
    text: 'summary_indicator_total_disbursed_usd',
  };

  const ctsNatDisbursementPercent = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE_BENEFICIARY_TYPE, TCP_NAT, DISBURSEMENT_PERCENT),
    text: 'disbursed',
  };

  const ctsNatAgeAverage = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE_BENEFICIARY_TYPE, TCP_NAT, AGE_AVERAGE),
    text: 'average_age',
  };

  const ctsNatBidExecutionPercentage = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE_BENEFICIARY_TYPE, TCP_NAT, BID_EXECUTION_PERCENTAGE),
    text: 'executed_by_the_bank',
  };

  const ctsNatAgencyExecutionPercentage = {
    value: findMetricValueByGrouping(
      metrics,
      PROJECT_PRODUCT_TYPE_BENEFICIARY_TYPE,
      TCP_NAT,
      AGENCY_EXECUTION_PERCENTAGE,
    ),
    text: 'executed_by_the_executing_agency',
  };

  const ctsNatLonSupportPercentage = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE_BENEFICIARY_TYPE, TCP_NAT, LON_SUPPORT_PERCENTAGE),
    text: 'loan_portfolio_support',
  };
  const ctsNatInvPercentage = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE_BENEFICIARY_TYPE, TCP_NAT, INV_PERCENTAGE),
    text: 'research_and_dissemination',
  };
  const ctsNatClientSupportPercentage = {
    value: findMetricValueByGrouping(
      metrics,
      PROJECT_PRODUCT_TYPE_BENEFICIARY_TYPE,
      TCP_NAT,
      CLIENT_SUPPORT_PERCENTAGE,
    ),
    text: 'customer_support_2',
  };

  const ctsRegProjectAmount = {
    value: haveDataOrIsEmpty(
      findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE_BENEFICIARY_TYPE, TCP_REG, PROJECT_COUNT),
    ),
    pluralText: 'technical_cooperation',
    singularText: 'technical_cooperation_singular',
    pluralTextDetail: 'projects',
    singularTextDetail: 'project',
  };

  const ctsRegApprovedAmount = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE_BENEFICIARY_TYPE, TCP_REG, APPROVED_AMOUNT),
    text: 'current_approved_amount_usd_complete',
  };

  const ctsRegPendingDisbursementAmount = {
    value: findMetricValueByGrouping(
      metrics,
      PROJECT_PRODUCT_TYPE_BENEFICIARY_TYPE,
      TCP_REG,
      PENDING_DISBURSEMENT_AMOUNT,
    ),
    text: 'pending_disbursement_dollar_sign',
  };

  const ctsRegDisbursementAmount = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE_BENEFICIARY_TYPE, TCP_REG, DISBURSEMENT_AMOUNT),
    text: 'summary_indicator_total_disbursed_usd',
  };

  const ctsRegDisbursementPercent = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE_BENEFICIARY_TYPE, TCP_REG, DISBURSEMENT_PERCENT),
    text: 'disbursed',
  };

  const ctsRegAgeAverage = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE_BENEFICIARY_TYPE, TCP_REG, AGE_AVERAGE),
    text: 'average_age',
  };

  const ctsRegBidExecutionPercentage = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE_BENEFICIARY_TYPE, TCP_REG, BID_EXECUTION_PERCENTAGE),
    text: 'executed_by_the_bank',
  };

  const ctsRegAgencyExecutionPercentage = {
    value: findMetricValueByGrouping(
      metrics,
      PROJECT_PRODUCT_TYPE_BENEFICIARY_TYPE,
      TCP_REG,
      AGENCY_EXECUTION_PERCENTAGE,
    ),
    text: 'executed_by_the_executing_agency',
  };
  const ctsRegUdrShare = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE_BENEFICIARY_TYPE, TCP_REG, UDR_SHARE),
    text: 'udr_in_my_office',
  };
  const ctsRegUpTo8Share = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE_BENEFICIARY_TYPE, TCP_REG, UP_TO_8_SHARE),
    text: 'participant_up_to_8_countries',
  };
  const ctsRegMoreThan8Share = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE_BENEFICIARY_TYPE, TCP_REG, MORE_THAN_8_SHARE),
    text: 'participant_more_8_countries',
  };

  return {
    both: { ctsBothProjectAmount, ctsBothApprovedAmount, ctsBothDisbursementPercent },
    nationals: {
      ctsNatProjectAmount,
      ctsNatApprovedAmount,
      ctsNatPendingDisbursementAmount,
      ctsNatDisbursementAmount,
      ctsNatDisbursementPercent,
      ctsNatAgeAverage,
      ctsNatBidExecutionPercentage,
      ctsNatAgencyExecutionPercentage,
      ctsNatLonSupportPercentage,
      ctsNatInvPercentage,
      ctsNatClientSupportPercentage,
    },
    regionals: {
      ctsRegProjectAmount,
      ctsRegApprovedAmount,
      ctsRegPendingDisbursementAmount,
      ctsRegDisbursementAmount,
      ctsRegDisbursementPercent,
      ctsRegAgeAverage,
      ctsRegBidExecutionPercentage,
      ctsRegAgencyExecutionPercentage,
      ctsRegUdrShare,
      ctsRegUpTo8Share,
      ctsRegMoreThan8Share,
    },
  };
};

export const getLoanValues = metrics => {
  const lonProjectAmount = {
    value: haveDataOrIsEmpty(
      additionedMetrics([
        findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE, LON, PROJECT_COUNT),
        findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE, GUA, PROJECT_COUNT),
      ]),
    ),
    pluralText: 'projects',
    singularText: 'project',
  };
  const lonApprovedAmount = {
    value: additionedMetrics([
      findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE, LON, APPROVED_AMOUNT),
      findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE, GUA, APPROVED_AMOUNT),
    ]),
    text: 'total_amount_usd',
  };
  const lonDisbursementAmount = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE, LON, DISBURSEMENT_AMOUNT),
    text: 'total_disbursed_usd',
  };
  const lonPendingDisbursementAmount = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE, LON, PENDING_DISBURSEMENT_AMOUNT),
    text: 'pending_disbursement_dollar_sign',
  };

  const lonDisbursementPercent = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE, LON, DISBURSEMENT_PERCENTAGE),
    text: 'disbursed',
  };

  const lonAmount = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE, LON, AMOUNT),
    pluralText: 'loans',
    singularText: 'loan',
  };

  const lonApprovedInversionAmount = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE, LON, APPROVED_INVERSION_AMOUNT),
    text: 'investment',
  };

  const lonApprovedPbpAmount = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE, LON, APPROVED_PBP_AMOUNT),
    text: 'pbp_usd',
  };

  const lonAgeAverage = {
    value: monthToYears(findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE, LON, AGE_AVERAGE)),
    text: 'average_age',
  };

  const lonPendingSignatureAmount = {
    value: additionedMetrics([
      findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE, LON, PENDING_SIGNATURE_AMOUNT),
      findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE, GUA, PENDING_SIGNATURE_AMOUNT),
    ]),
    text: 'pending_signing_of_contracts',
  };

  const lonPendingRatificationAmount = {
    value: additionedMetrics([
      findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE, LON, PENDING_RATIFICATION_AMOUNT),
      findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE, GUA, PENDING_RATIFICATION_AMOUNT),
    ]),
    text: 'pending_legislative_ratification',
  };

  const amountProblemPercentage = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE, LON, PROBLEM_PERCENTAGE_AMOUNT),
    text: 'of_the_total_portfolio',
  };

  const amountProjectProblem = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE, LON, AMOUNT_PROJECT_PROBLEM),
    pluralText: 'projects',
    singularText: 'project',
  };

  const amountLoanProblem = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE, LON, AMOUNT_PROBLEM),
    pluralText: 'loans',
    singularText: 'loan',
  };
  const amountApprovedProblem = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE, LON, AMOUNT_APPROVED_PROBLEM),
    text: 'total_amount_usd',
  };
  const amountDisursementProblem = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE, LON, AMOUNT_DISURSEMENT_PROBLEM),
    text: 'total_disbursed_usd',
  };
  const amountWithoutDisbursementProblem = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE, LON, AMOUNT_WITHOUT_DISBURSEMENT_PROBLEM),
    text: 'pending_disbursement_dollar_sign',
  };
  const amountDisbursementPercentProblem = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE, LON, AMOUNT_DISBURSEMENT_PERCENT_PROBLEM),
    text: 'disbursed',
  };
  const averageAgeProblem = {
    value: monthToYears(findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE, LON, AVERAGE_AGE_PROBLEM)),
    text: 'average_age',
  };

  const guarantee = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE, GUA, AMOUNT),
    text: 'guarantee',
  };
  const guaranteeProblem = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE, GUA, AMOUNT_PROBLEM),
    text: 'guarantee',
  };
  const approvedWarrantyProblem = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE, GUA, AMOUNT_APPROVED_PROBLEM),
    text: 'guarantee',
  };
  const approvedWarranty = {
    value: findMetricValueByGrouping(metrics, PROJECT_PRODUCT_TYPE, GUA, APPROVED_AMOUNT),
    text: 'guarantee',
  };

  return {
    lonProjectAmount,
    lonApprovedAmount,
    lonDisbursementAmount,
    lonPendingDisbursementAmount,
    lonDisbursementPercent,
    lonAmount,
    lonApprovedInversionAmount,
    lonApprovedPbpAmount,
    lonAgeAverage,
    lonPendingSignatureAmount,
    lonPendingRatificationAmount,
    amountProblemPercentage,
    amountProjectProblem,
    amountLoanProblem,
    amountApprovedProblem,
    amountDisursementProblem,
    amountWithoutDisbursementProblem,
    amountDisbursementPercentProblem,
    averageAgeProblem,
    guarantee,
    guaranteeProblem,
    approvedWarrantyProblem,
    approvedWarranty,
  };
};

export const processProjectTypeHeaderData = (data, projectsMetricName, approvedAmountMetricName) => {
  const filteredDataProjectsMetricName = data.filter(item => item.metricName === projectsMetricName);
  const filteredDataApprovedAmountMetricName = data.filter(item => item.metricName === approvedAmountMetricName);
  return {
    projectType: projectsMetricName.substring(0, 3),
    projectsAmount: filteredDataProjectsMetricName.length ? filteredDataProjectsMetricName[0].metricValue : 0,
    approvedAmount: filteredDataApprovedAmountMetricName.length
      ? filteredDataApprovedAmountMetricName[0].metricValue
      : 0,
  };
};

export const monthToYears = month => {
  return parseFloat(month) / 12;
};

export const getTabValuesFromMetrics = (indicator, byGroupingName = false) => {
  const Loan = !byGroupingName
    ? parseInt(findMetricValue(indicator.metrics, 'LON_PROJECT_COUNT'))
    : findMetricValueByGrouping(indicator.metrics, 'PROJECT_PRODUCT_TYPE', 'LON', 'PROJECT_COUNT');
  const CT = !byGroupingName
    ? parseInt(findMetricValue(indicator.metrics, 'TCP_PROJECT_COUNT'))
    : findMetricValueByGrouping(indicator.metrics, 'PROJECT_PRODUCT_TYPE', 'TCP', 'PROJECT_COUNT');
  const IGBoth = !byGroupingName
    ? parseInt(findMetricValue(indicator.metrics, 'IGR_PROJECT_COUNT'))
    : findMetricValueByGrouping(indicator.metrics, 'PROJECT_PRODUCT_TYPE', 'IGR', 'PROJECT_COUNT');

  return {
    Loan: Loan !== null && !isNaN(Loan) ? Loan : 0,
    CT: CT !== null && !isNaN(CT) ? CT : 0,
    IGBoth: IGBoth !== null && !isNaN(IGBoth) ? IGBoth : 0,
  };
};

export const getTabValues = (indicator, groupingValue, metricName) => {
  const Loan =
    findMetricValueByGrouping(indicator.metrics, groupingValue, '0', metricName) +
    findMetricValueByGrouping(indicator.metrics, groupingValue, '1', metricName);

  const CT = findMetricValueByGrouping(indicator.metrics, groupingValue, '2', metricName);

  const IGBoth = findMetricValueByGrouping(indicator.metrics, groupingValue, '3', metricName);
  return {
    Loan: Loan !== null ? Loan : 0,
    CT: CT !== null ? CT : 0,
    IGBoth: IGBoth !== null ? IGBoth : 0,
  };
};
export const yearsList = (metrics: IndicatorMetric[]) => {
  const result: string[] = [];
  for (const metric of metrics) {
    if (!result.includes(metric.groupingValue)) {
      result.push(metric.groupingValue);
    }
  }
  return result.sort((a, b) => {
    if (parseInt(a) < parseInt(b)) {
      return -1;
    } else if (parseInt(a) > parseInt(b)) {
      return 1;
    } else {
      return 0;
    }
  });
};

export const additionedMetrics = (values: any[]) => {
  if (!values.length || values.every(i => i === null)) {
    return null;
  }
  return values.reduce((partialSum, current) => partialSum + current, 0);
};
