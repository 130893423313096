import React from 'react';
import { getDefaultOption } from '../../../Util/ContentManager/ContentManager';
import { defaultDelegationFilters } from '../../../../api/contentManager';
import { dataTypes } from '../../ContentManagerKeys';
import ContentManagerOrderableTable from '../../ContentManagerTable/ContentManagerOrderableTable';
import ContentManagerModal from '../../ContentManagerModal/ContentManagerModal';
import DelegationsFormModal from './DelegationsFormModal';
import FunctionDelegationsSelects from '../FunctionDelegationsSelects';

type Props = {
  t: Function;
  displayGenericErrorMessage: Function;
  delegationsList: any;
  countryOptions: any;
  roleOptions: any;
  updateFunction: any;
};

const delegationsColumns = [
  {
    id: 0,
    title: 'content_manager_user',
    sortable: true,
  },
  {
    id: 1,
    title: 'content_manager_country',
    sortable: true,
    width: '70px',
  },
  {
    id: 3,
    title: 'content_manager_delegate',
    sortable: true,
  },
  {
    id: 4,
    title: 'content_manager_functionality',
    sortable: true,
    width: '200px',
  },
  {
    id: 5,
    title: '',
    sortable: false,
    actionsColumn: true,
    width: '70px',
  },
];

const Delegations = ({
  t,
  displayGenericErrorMessage,
  delegationsList,
  countryOptions,
  roleOptions,
  updateFunction,
}: Props) => {
  const [country, setCountry] = React.useState(getDefaultOption('contents_select_placeholder', t));
  const [role, setRole] = React.useState(getDefaultOption('contents_select_placeholder', t));
  const [user, setUser] = React.useState<any>();
  const [filter, setFilter] = React.useState<any>(defaultDelegationFilters);
  const [modal, setModal] = React.useState(false);

  React.useEffect(() => {
    if (country.id !== 0) {
      setFilter({
        ...filter,
        countryId: country.id,
      });
    }
    // eslint-disable-next-line
  }, [country]);

  React.useEffect(() => {
    if (role.id !== 0) {
      setFilter({
        ...filter,
        roleId: role.id,
      });
    }
    // eslint-disable-next-line
  }, [role]);

  React.useEffect(() => {
    setFilter({
      ...filter,
      userName: user,
    });
    // eslint-disable-next-line
  }, [user]);

  React.useEffect(() => {
    updateFunction(filter);
    // eslint-disable-next-line
  }, [filter]);

  return (
    <>
      <div className="content-manager__container__subtitle" style={{ marginTop: '0px' }}>
        {t('content_manager_delegations_filters')}
      </div>
      <div className="content-manager-modal__options" style={{ marginTop: 0 }}>
        <FunctionDelegationsSelects
          idSelect1="countries_delegations"
          idSelect2="roles_delegations"
          labelSelect1="content_manager_leaves_country"
          labelSelect2="content_manager_delegations_filters_roles"
          valueSelect1={country}
          valueSelect2={role}
          optionsSelect1={countryOptions}
          optionsSelect2={roleOptions}
          setOptionSelect1={setCountry}
          setOptionSelect2={setRole}
          widthSelect1={250}
          widthSelect2={310}
        />
        <div className="label-select-container" style={{ width: '310px', marginLeft: '60px' }}>
          <p className="label">{t('content_manager_delegations_filters_person')}</p>
          <div className="seacrh-input">
            <input
              className="content-manager inputClass"
              value={user}
              onChange={e => setUser(e.target.value)}
              placeholder={t('contents_select_placeholder_search')}
            />
          </div>
        </div>
      </div>
      <div className="content-manager__container__table">
        <ContentManagerOrderableTable
          columns={delegationsColumns}
          rows={delegationsList.collection}
          t={t}
          type={dataTypes.delegations}
          modalTitle={'content_manager_delegations_modal_title_edit'}
          updateTable={updateFunction}
          setErrorMessage={displayGenericErrorMessage}
          fromButton={false}
          buttonText={'content_manager_delegations_modal_title_edit'}
          paginationData={delegationsList}
        />
      </div>
      <ContentManagerModal
        isOpen={modal}
        closeModal={() => setModal(false)}
        openModal={() => setModal(true)}
        modalTitle={t('content_manager_delegations_modal_title')}
        buttonText={t('content_manager_delegations_modal_title')}
        fromButton={true}
        fromDelegations={true}
      >
        <DelegationsFormModal
          handleCloseModal={() => setModal(false)}
          setErrorMessage={displayGenericErrorMessage}
          updateTable={updateFunction}
        />
      </ContentManagerModal>
    </>
  );
};

export default Delegations;
