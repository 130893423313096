import { axiosDefaultConfig } from './axiosConfig';
import * as config from './config.json';
import moment from 'moment';
import FilterAnalytics from '../models/FilterAnalytics';
import { AnalyticsDataPeriodEnum } from '../models/AnalyticsDataPeriodEnum';

export const getAllAnalyticsIndicators = (filterAnalytics: FilterAnalytics) => {
  const url = config.ANALYTICS_INDICATORS;

  const setDateFrom = period => {
    let newPeriod;

    switch (period) {
      case AnalyticsDataPeriodEnum.LAST_WEEK:
        newPeriod = moment().subtract(1, 'w').add(1, 'd');
        break;
      case AnalyticsDataPeriodEnum.LAST_MONTH:
        newPeriod = moment().subtract(1, 'M');
        break;
      case AnalyticsDataPeriodEnum.LAST_TRIMESTER:
        newPeriod = moment().subtract(3, 'M');
        break;
      case AnalyticsDataPeriodEnum.LAST_YEAR:
        newPeriod = moment().subtract(1, 'y');
        break;
    }

    return moment(newPeriod).utcOffset(0).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  };

  const data = {
    regionsIds: filterAnalytics.regionId.map(regions => regions.id),
    divisionsIds: filterAnalytics.divisionId.map(divisions => divisions.id),
    countriesIds: filterAnalytics.countryId.map(countries => countries.id),
    rolesIds: filterAnalytics.roleId.map(roles => roles.id),
    dateFrom: setDateFrom(filterAnalytics.dataPeriod.value),
  };

  return new Promise<any>(async (resolve, reject) => {
    try {
      const responseAPI = await axiosDefaultConfig.post(url, data);
      resolve(responseAPI.data);
    } catch (error) {
      reject(error);
    }
  });
};
