import * as config from './config.json';
import { buildGet } from './genericMethods';
const idReplacement = '{id}';

export const getProductForProject = (id: number) => {
  const url = config.PROJECT_TAB_PRODUCTS.replace(idReplacement, id.toString());
  return buildGet(url);
};

export const getProductFileStateData = (id: number) => {
  const url = config.PRODUCT_MODAL_DATA.replace(idReplacement, id.toString());
  return buildGet(url);
};
