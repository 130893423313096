import React from 'react';
import { styles } from '../Styles';
import './AccordanceCancel.scss';
import SaveButton from '../../Buttons/SaveButton/SaveButton';
import UndoButton from '../../Buttons/UndoButton/UndoButton';
import { useTranslation } from 'react-i18next';
import { disableEscKey } from '../Util';
import { cancelAccordance } from '../../../../api/accordanceApi';
import { useSelector, useDispatch } from 'react-redux';
import { getProjectAccordances } from '../../../../redux/actions/currentProject';
import BaseModal from '../BaseModal';

type Props = {
  isOpen: boolean;
  closeModal: any;
  accordanceId: number;
};

const AccordanceCancel = (props: Props) => {
  const { isOpen, closeModal, accordanceId } = props;
  const { t } = useTranslation();
  const [reason, setReason] = React.useState('');
  const currentProject = useSelector<any, any>(state => state.currentProject);
  const dispatch = useDispatch();

  const resetData = () => {
    setReason('');
    closeModal(true);
  };

  const saveData = async () => {
    try {
      await cancelAccordance(accordanceId, reason);
      setReason('');
      closeModal(false);
      dispatch(getProjectAccordances(currentProject.id));
    } catch (error) {}
  };

  return (
    <BaseModal
      isOpen={isOpen}
      onRequestClose={e => disableEscKey(e, resetData)}
      style={styles}
      className="modal-accordance-cancel-content"
    >
      <div className="modal-accordance-cancel-container">
        <h3>{t('accordance_was_cancelled')}</h3>
        <div className="textarea-section">
          <span>{t('please_write_motivation')}:</span>
          <textarea value={reason} onChange={e => setReason(e.target.value)}></textarea>
        </div>
        <div className="horizontal">
          <SaveButton handleClick={saveData} disabled={!reason.trim()} />
          <UndoButton handleClick={resetData} />
        </div>
      </div>
    </BaseModal>
  );
};

export default AccordanceCancel;
