import React from 'react';
import { useTranslation } from 'react-i18next';
import './ReportButton.scss';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { isMobile } from '../../../../services/booleanUtil';
import ReportProjectConfig from '../../Modals/ReportProjectConfig/ReportProjectConfig';
import { ReportType } from '../../../../models/ReportType';
import { SuccessModal } from '../../Modals/SuccessModal/SuccessModal';
import { setTabValue } from '../../../../redux/actions/tabValue';
import { tabIndexes } from '../../../../models/TabIndexes';
import ReportReviewConfig from '../../Modals/ReportReviewConfig/ReportReviewConfig';
import { createPortfolioReport, createReviewAgendaReport } from '../../../../api/reportApi';
import { CustomizableReports } from '../../Modals/CustomizableReports/CustomizableReports';
import { CustomizableReportsContextProvider } from '../../Modals/CustomizableReports/CustomizableReportsContext';
import FilterIndicators from '../../../../models/FilterIndicators';
import { startedFilter } from '../../../../redux/reducers/filterObject';
import { trackActionClicked } from '../../../../api/trackActionClickedApi';
import { TrackedActions } from '../../../../models/TrackedActionsEnum';
import { ReactComponent as IconReport } from '../../../../assets/icons/u_reports.svg';

type Props = {
  handleClick?: any;
  customStyle?: {};
  reportType: ReportType;
  disabled?: any;
  revision?: any;
};

const buttonText = (isMobile, t, reportType) => {
  return isMobile ? '' : reportType === ReportType.Review_Agenda ? t('download_agenda') : t('generate_report');
};

const ReportButton = (props: Props) => {
  const { reportType, handleClick, disabled, revision } = props;
  const filterIndicator = useSelector<any, FilterIndicators>(state => state.filterIndicators);

  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showConfig, setShowConfig] = React.useState(false);
  const [showModalGeneralOptions, setShowModalGeneralOptions] = React.useState(false);
  const [disabledButtonWhenClick, setDisabledButtonWhenClick] = React.useState(false);
  const handleModalFlow = () => {
    setShowConfig(false);
    setShowModalGeneralOptions(true);
  };

  const handleShowConfigModal = value => {
    setShowConfig(value);
    setDisabledButtonWhenClick(value);
  };

  const renderModal = (reportType: ReportType) => {
    switch (reportType) {
      case ReportType.Project:
        return (
          <ReportProjectConfig
            isOpen={showConfig}
            closeModal={() => {
              handleShowConfigModal(false);
            }}
            submit={handleModalFlow}
          />
        );

      case ReportType.Review:
        return (
          <ReportReviewConfig
            isOpen={showConfig}
            closeModal={() => {
              handleShowConfigModal(false);
            }}
            submit={handleModalFlow}
          />
        );
      case ReportType.Customizable:
        return (
          <CustomizableReportsContextProvider>
            <CustomizableReports
              closeModal={() => {
                handleShowConfigModal(false);
              }}
              isOpen={showConfig}
              submit={handleModalFlow}
            />
          </CustomizableReportsContextProvider>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <button
        
        className={`${
          disabled || disabledButtonWhenClick ? 'btn btn-primary-outlined disabled' : 'btn btn-primary-outlined'
        } report-button-container--indexable`}
        onClick={() =>
          handleOnClick(
            reportType,
            revision,
            handleShowConfigModal,
            handleClick,
            filterIndicator,
            setShowModalGeneralOptions,
          )
        }
        disabled={disabled || disabledButtonWhenClick}
        tabIndex={30}
      >
        <span className="report">
          {/* <span
            className={
              reportType === ReportType.Review || reportType === ReportType.Review_Agenda ? 'icon-white' : 'icon'
            }
          ></span> */}
            {reportType === ReportType.Review || reportType === ReportType.Review_Agenda ? (
              <IconReport className="icon-white" />
            ) : (
              <IconReport className="icon" />
            )}
          <span>{buttonText(isMobile(), t, reportType)}</span>
        </span>
      </button>

      {renderModal(reportType)}

      <SuccessModal
        isOpen={showModalGeneralOptions}
        closeModal={() => {
          setShowModalGeneralOptions(false);
        }}
        buttonAction={() => {
          setShowModalGeneralOptions(false);
          dispatch(setTabValue(tabIndexes.reports));
          history.push('/home');
        }}
        title={t('report_generated_correctly')}
        subTitle={`${t('the_report_you_have_created_was_saved_in_the_section')} ${t('menu_reports')}`}
        buttonText={t('menu_reports')}
      />
    </>
  );
};

export default ReportButton;

const handleOnClick = async (
  reportType,
  revision,
  handleShowConfigModal,
  handleClick,
  filterIndicator,
  setShowModalGeneralOptions,
) => {
  if (reportType === ReportType.Review_Agenda && revision.id) {
    await createReviewAgendaReport(revision.id);
  } else if (reportType === ReportType.Portfolio && handleClick) {
    handleClick();
  } else if (reportType === ReportType.Portfolio && !handleClick && isEqual(filterIndicator.common, startedFilter)) {
    await createPortfolioReport(filterIndicator);
    setShowModalGeneralOptions(true);
  } else {
    handleShowConfigModal(true);
  }
  trackActionClicked(TrackedActions.generateReportButtonClicked);
};
