import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Search.module.scss';
import { isMobile } from '../../../services/booleanUtil';
import { setTextSearch } from '../../../redux/actions/textSearch';
import { useDispatch, useSelector } from 'react-redux';

type Props = {
  onSearch: Function;
};

const Search = ({ onSearch }: Props) => {
  const dispatch = useDispatch();
  const { textSearch } = useSelector<any, any>(state => state);
  const { t } = useTranslation();

  const handleChange = (e: any) => {
    dispatch(setTextSearch(e.target.value));
    onSearch(e.target.value);
  };

  return (
    <div className={styles.container}>
      <input
        className={styles.searchInput}
        placeholder={t(isMobile() ? 'search_project_placeholder_short' : 'search_project_placeholder')}
        value={textSearch}
        onChange={handleChange}
      />
      <span className={styles.icon}></span>
    </div>
  );
};

export default Search;
