import { axiosDefaultConfig } from './axiosConfig';

export const buildMockedGet = mock => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const response = mock;
      if (response && response.data) {
        resolve(response.data);
      } else {
        resolve(true);
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const buildGet = (url: string) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const response = await axiosDefaultConfig.get(url);
      if (response && response.data) {
        resolve(response.data);
      } else {
        resolve(true);
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const buildPut = (url: string, data?: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const response = await axiosDefaultConfig.put(url, data);
      if (response && response.data) {
        resolve(response.data);
      } else {
        resolve(true);
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const buildPost = (url: string, data) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const response = await axiosDefaultConfig.post(url, data);
      if (response && response.data) {
        resolve(response.data);
      } else {
        resolve(true);
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const buildDelete = (url: string) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const response = await axiosDefaultConfig.delete(url);
      if (response && response.data) {
        resolve(response.data);
      } else {
        resolve(true);
      }
    } catch (error) {
      reject(error);
    }
  });
};
