import React from 'react';
import { Circle } from 'rc-progress';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../../../../../services/booleanUtil';

import './RadialBarChart.scss';
import { IndicatorKeyEnum } from '../../../../../models/IndicatorKeyEnum';
import { ReportImageDisplay } from '../../../../Util/Modals/CustomizableReports/CustomizableReports';

const totalTextStyle = (listPage, detailCenterTextStyle, centerTextStyle, forReport) => {
  const customColor = forReport && forReport === ReportImageDisplay.Detail ? { color: 'white' } : {};
  return !listPage && detailCenterTextStyle
    ? { ...detailCenterTextStyle, ...customColor }
    : { ...centerTextStyle, ...customColor } || { ...customColor };
};

interface RadialBarChartData {
  centerText: string;
  circles: any[];
  centerTextStyle?: any;
  detailCenterTextStyle?: any;
}

type Props = {
  data?: RadialBarChartData;
  listPage?: boolean;
  selectedCircle?: any;
  isClickable?: boolean;
  indicatorKey?: IndicatorKeyEnum | string;
  forReport?: ReportImageDisplay;
};

const RadialBarChart = ({ data, listPage, selectedCircle, isClickable = true, indicatorKey, forReport }: Props) => {
  const { t } = useTranslation();
  const [circlesColors, setCirclesColors] = React.useState<any[]>(data?.circles.map(item => item.strokeColor) || []);

  if (!data) {
    return (
      <div className="no-data-msj-indicator">
        <span className="value-text">-</span>
      </div>
    );
  }

  const { centerText, circles, centerTextStyle, detailCenterTextStyle } = data;

  const handleClick = (value, label) => {
    if (isClickable) {
      selectedCircle(value, label);
    }
  };

  return (
    <div className={`radial-bar-chart-container ${radialBarChartContainerClass(listPage)}`}>
      <div className={'circles'}>
        {circles.map((item, index) => (
          <div
            key={item.key}
            className={`circle-size-${circles.length}-index-${index}`}
            onMouseEnter={() => {
              if (isClickable) {
                setCirclesColors(
                  circlesColors.map((o, i) => {
                    return i === index ? item.mouseEnterColor : circles[i].strokeColor;
                  }),
                );
              }
            }}
            onMouseLeave={() => {
              if (isClickable) {
                setCirclesColors(circles.map(item => item.strokeColor));
              }
            }}
            onClick={() => handleClick(item.value, item.clickValue)}
          >
            <Circle
              percent={item.percentageValue}
              strokeWidth={responsiveStrokeWidth(item)}
              strokeColor={circlesColors[index]}
              trailColor={item.trailColor}
              trailWidth={responsiveStrokeWidth(item)}
            />
          </div>
        ))}

        <span
          className="total-text"
          style={totalTextStyle(listPage, detailCenterTextStyle, centerTextStyle, forReport)}
        >
          {centerText}
        </span>
      </div>
      <div className="list-info">
        <ul>
          {listInfo(circles, indicatorKey).map((item, index) => {
            return (
              <li
                key={item.key}
                className={`label-size-${circles.length}-index-${index}`}
                onClick={() => handleClick(item.value, item.clickValue)}
               
              >{`${t(item.labelText)}${' '}${item.labelValue}`}</li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export { RadialBarChart };

const listInfo = (circles: any[], indicatorKey?: IndicatorKeyEnum) => {
  return indicatorKey && indicatorKey === IndicatorKeyEnum.AVERAGE_LAG
    ? [...circles].sort((a, b) => {
        if (a.key > b.key) {
          return -1;
        } else if (a.key < b.key) {
          return 1;
        } else {
          return 0;
        }
      })
    : circles;
};

const liCustomStyle = forReport =>
  forReport && forReport === ReportImageDisplay.Detail ? { color: 'white' } : { color: '#727e8c' };
const responsiveStrokeWidth = item => (isMobile() ? item.strokeWidthMobile : item.strokeWidth);
const radialBarChartContainerClass = listPage => (listPage ? '' : 'list-page');
