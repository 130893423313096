// import { isJSDocCallbackTag } from 'typescript';
import { AccordanceState } from '../../../../../models/AccordanceState';
import { AccordanceType } from '../../../../../models/AccordanceType';
import { getSemesters } from './DateSelector';

export const validInputs = (
  criticalIssue,
  responsible,
  expirationDate,
  // possibleAction,
  revisionDate,
  accordanceFormType,
  definedAgreement,
  linkedProductsAccordance,
  rootCauseOfCritical,
  rootCause,
  criticalIssueDescription,
  description
) => {

  let validData
  
  const linkedProductValidate = criticalIssue?.keyProducts?.length

  if(linkedProductValidate === 0){
    validData = 
    criticalIssue !== null && responsible !== null && expirationDate !== null && definedAgreement !== null && rootCauseOfCritical !== null && rootCause !== null && criticalIssueDescription !== '' && description !== '';
  }else{
    validData = 
    criticalIssue !== null && responsible !== null && expirationDate !== null && definedAgreement !== null && rootCauseOfCritical !== null && rootCause !== null && linkedProductsAccordance !== null && criticalIssueDescription !== '' && description !== '';
  }

  return accordanceFormType
    ? validData && revisionDate !== null
    : accordanceFormType === AccordanceType.InterGroup
    // ? validData && semesterOption !== null
    // : validData
    ? validData && criticalIssue.keyProducts?.length === 0 
    : validData
    ? validData && criticalIssueDescription?.length <= 3
    : validData
    ? validData && description?.length <= 3
    : validData
    ? validData && rootCauseOfCritical === null
    : validData;
};

export const noErrors = formErrors => {
  return (
    !formErrors.criticalIssue &&
    !formErrors.responsible &&
    !formErrors.revisionDate &&
    !formErrors.expirationDate &&
    !formErrors.possibleAction &&
    !formErrors.definedAgreement &&
    !formErrors.linkedProductsAccordance &&
    !formErrors.rootCauseOfCritical &&
    !formErrors.rootCause &&
    !formErrors.criticalIssueDescription &&
    !formErrors.description
  );
};

export const updatedFormErrors = (
  criticalIssue,
  responsible,
  expirationDate,
  // possibleAction,
  revisionDate,
  accordanceFormType,
  // semesterOption,
  // accordanceDate,
  definedAgreement,
  linkedProductsAccordance,
  rootCauseOfCritical,
  rootCause,
  criticalIssueDescription,
  description
) => {

  let newFormErrors = {
    criticalIssue: criticalIssue === null,
    responsible: responsible === null,
    expirationDate: expirationDate === null,
    // possibleAction: possibleAction === null,
    revisionDate: revisionDate === null,
    // semesterOption: false,
    // accordanceDate: accordanceDate === null,
    definedAgreement: definedAgreement === null,
    linkedProductsAccordance: criticalIssue?.keyProducts.length !== 0 && linkedProductsAccordance === null,
    rootCauseOfCritical: rootCauseOfCritical === null || rootCauseOfCritical === undefined,
    rootCause: rootCause === null,
    criticalIssueDescription: criticalIssueDescription === '',
    description: description === '',
  };

  if (accordanceFormType === AccordanceType.Revision) {
    newFormErrors = { ...newFormErrors, ...{ revisionDate: revisionDate === null } };
  } 
  // else if (accordanceFormType === AccordanceType.InterGroup) {
  //   newFormErrors = { ...newFormErrors, ...{ semesterOption: semesterOption === null } };
  // }
  return newFormErrors;
};

export const createCriticalIssuesList = categories => {
  return categories
    .reduce((accumulator: any, currentValue: any) => [...accumulator, ...currentValue.criticalIssues], [])
    .map(item => {
      return {
        id: item.id,
        value: item.id.toString(),
        label: item.name,
        categoryName: item.categoryName,
        categoryId: item.categoryId,
        possibleActions: item.possibleActions,
        keyProducts: item?.keyProducts
      };
    });
};

interface possibleActionParam {
  name: string;
  id: number;
}

export const createPossibleActionsList = (item?: possibleActionParam[] | null) => {
  if (!item) {
    return [];
  }
  return item.map(p => {
    return { id: p.id, value: p.id.toString(), label: p.name };
  });
};

export const revisionDateString = revisionDate => {
  return revisionDate ? revisionDate.toISOString() : null;
};

export const expirationDateString = expirationDate => {
  return expirationDate ? expirationDate.toISOString() : '';
};

export const generateRevisionDate = editAccordanceData => {
  // return editAccordanceData.accordanceType === AccordanceType.Revision
  //   ? editAccordanceData.revisionDate && new Date(editAccordanceData.revisionDate)
  //   : null;
  return editAccordanceData.revisionDate && new Date(editAccordanceData.revisionDate)
};

export const generateCustomText = (editAccordanceData, t, accordanceType) => {
  if (accordanceType === AccordanceType.InterGroup) {
    return t('save_as_draft');
  }
  return editAccordanceData ? t('save_changes') : t('create_accordance');
};

export const setCategoryName = item => {
  return item.categoryName || '';
};

export const isDraftOrPending = (accordanceState?: AccordanceState) => {
  return accordanceState === AccordanceState.DRAFT || accordanceState === AccordanceState.PENDING;
};

export const formatDataForSubmit = (
  projectId: number,
  accordanceType,
  accordance: any,
  draft = false,
) => {
  // revisionDate:
  //       accordance.definedAgreement.id
  //         ? revisionDateString(accordance.revisionDate)
  //         : accordance.semesterOption
  //         ? accordance.semesterOption.value
  //         : null,

  const linkedProductData = accordance.linkedProductsAccordance?.map(element => {

    return { 
     id: null,
     name: null,
     projectId: null,
     componentOrder: null,
     productOrder: null,
     keyProductId: element.keyProductId || element.id}
   })
  return {
    projectId,
    revisionDate:revisionDateString(accordance.revisionDate),
    expirationDate: accordance.expirationDate ? expirationDateString(accordance.expirationDate) : null,
    accordanceType: accordanceType,
    criticalIssueId: accordance.criticalIssue ? accordance.criticalIssue.id : null,
    personInChargeId: accordance.responsible
      ? accordance.responsible.id
      : accordance.personInCharge
      ? accordance.personInCharge.id
      : null,
    possibleActionId: accordance.possibleAction ? accordance.possibleAction.id : null,
    files: accordance.accordanceFiles,
    revisionId: accordance.review ? accordance.review.id : undefined,
    description: accordance.description,
    draft,
    criticalIssueDescription: accordance.criticalIssueDescription,
    supervisionActionId: accordance.supervisionAction && accordance.supervisionAction.id,
    accordanceId: accordance.id ? accordance.id : undefined,
    rootCauseCategoryId: accordance.rootCauseOfCritical?.id || accordance.rootCauseCategoryId,
    rootCauseId: accordance.rootCause?.id || accordance.rootCauseId,
    linkedProductsAccordance: linkedProductData === undefined ? [] : linkedProductData
  };
};



export const accordanceDateString = accordanceDate => {
  return accordanceDate ? accordanceDate.toISOString() : '';
};

export const semesterOptionValue = (revisionDate, t) => {
  const dateString = revisionDate.toISOString().split('T')[0];
  const semesters = getSemesters(t);
  const item = semesters.find(({ value }) => {
    return value.split('T')[0] === dateString;
  });
  return item || null;
};
