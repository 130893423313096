import React from 'react';
import '../Financial.scss';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import MultiProgress from './MultiProgress';
import LabelData from '../../../../Util/LabelData/LabelData';
import { replaceMomentFormat } from '../../../../../services/util';
import moment from 'moment';

interface IFinancialAdvanceData {
  realizedDisbursementAmount: any;
  commitedAmount: number;
  availableAmount: number;
  lastDisbursementDate: string;
  totalDisbursementAmount: number;
  percentRealizedDisbursement: number;
  percentAvailableAmount: number;
  percentCommitedAmount: number;
}

type Props = {
  data: IFinancialAdvanceData;
  baseInformation: any;
};

const FinancialAdvance = ({ data, baseInformation }: Props) => {
  const {
    realizedDisbursementAmount: disbursed,
    commitedAmount: commited,
    availableAmount: available,
    lastDisbursementDate: disbursementDate,
    totalDisbursementAmount: total,
    percentRealizedDisbursement,
    percentAvailableAmount,
    percentCommitedAmount,
  } = data;
  const { t } = useTranslation();
  return (
    <>
      <div>
        <div className="title-container">
          <h4 className="title">{t('financial_advance')}</h4>
          <span style={{ margin: '3px 0px 15px 0px' }} className="subtitle">
            <span>{t('approved_current_amount')}</span>{' '}
            <NumberFormat value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />
            <span style={{ fontSize: 10 }}>{t('dolar_sign')}</span>
          </span>
        </div>
        <MultiProgress
          data={{
            list: [
              { id: 1, value: disbursed, color: '#538CFF', label: 'disbursed', percent: percentRealizedDisbursement },
              {
                id: 2,
                value: commited,
                color: '#FDAC41',
                label: 'committed_financial',
                percent: percentCommitedAmount,
              },
              { id: 3, value: available, color: '#91E2A1', label: 'available', percent: percentAvailableAmount },
            ],
          }}
        />
        <LabelData
          label={`${t('financial_last_disbursement_date')}: `}
          data={
            baseInformation
              ? replaceMomentFormat(moment(baseInformation.lastExtensionApprobedDate).format('DD/MMM/YYYY'))
              : '-'
          }
        />
      </div>
    </>
  );
};

export default FinancialAdvance;
