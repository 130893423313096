import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import './FilterFocus.scss';
import { ReactComponent as IconFocus } from '../../../assets/icons/u_focus-target.svg';
import { ReactComponent as IconChevronDown } from '../../../assets/icons/u_chevron-down.svg';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ReactComponent as IconClose } from '../../../assets/icons/u_multiply-circle.svg';
import { useSelector } from 'react-redux';
import { getNewFilteredIndicators } from '../../../api/indicatorApi';
import { getIndicatorsList } from '../../../redux/actions/indicators';
import { current } from '@reduxjs/toolkit';
import { Tooltip, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const BlackTooltip = withStyles({
  tooltip: {
    //   color: '$ci-color-greyscale-90',
    backgroundColor: '#101319',
    fontSize: '16px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    lineHeight: '20px',
    padding: '8px 12px',
    borderRadius: '8px',
  },
  arrow: {
    fontSize: 20,
    color: '#4A4A4A',
    '&::before': {
      backgroundColor: '#101319',
    },
  },
})(Tooltip);

// export default function FilterFocus({ setIndicator, setLoading }: any) {
export default function FilterFocus({ setIndicator, setLoading, selectedText, setSelectedText, value, setValue }: any) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const indicatorTypes: any = useSelector<any>(state => state.allIndicators);

  const { t } = useTranslation();

  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleChange = (event, groupId) => {
    setValue(event.target.value);
    setSelectedText(event.target.labels[0]?.innerText || '');
    getIndicatorsByGroup(groupId);
    handleClose();
  };

  const getIndicatorsByGroup = async (id: any) => {
    const options = { groupIndicatorFocused: id };
    try {
      setLoading(true);
      const indicatorsToProcess = await getNewFilteredIndicators(options);
      setIndicator(indicatorsToProcess);
    } catch (err) {
      console.error(err, 'Error fetching data from indicators');
    } finally {
      setLoading(false);
    }
  };

  const onClearFilter = async () => {
    setSelectedText('');
    setValue('');
    const options = { groupIndicatorFocused: null };
    try {
      setLoading(true);
      const indicatorsToProcess = await getNewFilteredIndicators(options);
      setIndicator(indicatorsToProcess);
    } catch (err) {
      console.error(err, 'Error fetching data from indicators');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => setValue(''), []);

  return (
    <div className="focus">
      <div className="title">
        <h3>{`${t('indicators')}`} </h3>
      </div>
      <Button
        className={`focus-menu ${isMenuOpen ? 'active' : ''}`}
        aria-controls="focus-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <IconFocus />{' '}
        <span className="text-button">
          {' '}
          {`${t('new_focus_on')}`}
          {selectedText && (
            <span className="tag-filter">
              <BlackTooltip title={selectedText} arrow>
                <span className="ellipsis-text">{selectedText}</span>
              </BlackTooltip>
              <IconClose onClick={onClearFilter} />
            </span>
          )}
        </span>{' '}
        <IconChevronDown />
      </Button>
      <Menu
        id="focus-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={5}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        className="focus-menu-item"
      >
        {indicatorTypes?.map((item: any) => (
          <RadioGroup aria-label="indicator" name="indicator" value={value} onChange={e => handleChange(e, item.id)}>
            <MenuItem>
              <FormControlLabel value={item.name} control={<Radio />} label={item.name} />
            </MenuItem>
          </RadioGroup>
        ))}
      </Menu>
    </div>
  );
}
