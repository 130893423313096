export const renderValues = (filterAlertProblemApplied, filteredValue, normalValue) => {
  return filterAlertProblemApplied ? filteredValue : normalValue;
};

export const handleTitleValue = alertProblemSummaryFilterApplied => {
  if (!alertProblemSummaryFilterApplied || alertProblemSummaryFilterApplied === undefined) {
    return '';
  } else {
    return 'on_alert_and_problem';
  }
};
