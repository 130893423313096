import React, { createContext, useState, useCallback } from 'react';

type DimensionType = {
  width: number;
  height: number;
};

export type TagDimensionType = DimensionType & { name: string }

type ParentDimensionContextType = {
  parentDimension: DimensionType;
  setParentDimension: (dimensions: DimensionType) => void;
};

type SetTagDimensionType = React.Dispatch<React.SetStateAction<TagDimensionType[]>>;

type TagDimensionContextType = {
  tagDimension: TagDimensionType[];
  setTagDimension: SetTagDimensionType;
  extractText: (ref: React.RefObject<HTMLDivElement>) => void;
}

type DimensionContextType = ParentDimensionContextType & TagDimensionContextType;

export const DimensionContext = createContext<DimensionContextType>({
  parentDimension: { width: 0, height: 0 },
  setParentDimension: () => { },
  tagDimension: [],
  setTagDimension: () => { },
  extractText: () => { },
});

export const DimensionProvider: React.FC = ({ children }) => {

  const [parentDimension, setParentDimension] = useState<DimensionType>({ width: 0, height: 0 });
  const [tagDimension, setTagDimension] = useState<TagDimensionType[]>([]);

  const extractText = useCallback((ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      const name = ref.current.textContent || ref.current.innerText;
      setTagDimension(prevDimensions => {
        const existingIndex = prevDimensions.findIndex(dim => dim.name === name.trim());
        if (existingIndex !== -1) {
          const newDimensions = [...prevDimensions];
          newDimensions[existingIndex] = { ...newDimensions[existingIndex], name: name.trim() };
          return newDimensions;
        } else {
          return [...prevDimensions, { width: 0, height: 0, name: name.trim() }];
        }
      });
    }
  }, []);

  const value = { parentDimension, setParentDimension, tagDimension, setTagDimension, extractText };

  return <DimensionContext.Provider value={value}>{children}</DimensionContext.Provider>;
};
