import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ProjectProductTypes } from '../../../models/ProjectProductTypes';
import './BaseInformation.scss';
import { customStyle } from './BaseInformation';
import Member from './Member';
import { isAuthorizedToEdit } from '../../../services/booleanUtil';
import AddMember from './AddMember';
import Project from '../../../models/Project';
import { ProjectProductTypeClassification } from '../../../models/ProjectProductTypeClassification';
import { ProjectExecutionTypes } from '../../../models/ProjectExecutionTypes';
import User from '../../../models/User';

type Props = {
  projectProductType: any;
  baseInfo: any;
  execUnit: any;
  rolesList: any;
  refreshAndNotify: any;
};

const isCt = projectProductType => {
  return projectProductType === ProjectProductTypes.CT;
};

const renderBorrowerBenficiary = (baseInfo, projectProductType) => {
  if (isCt(projectProductType)) {
    return '-';
  } else {
    return baseInfo.borrower || '-';
  }
};

const isCtOrIGLowerAgency = (
  projectProductType: ProjectProductTypes,
  projectProductTypeClassification: any | undefined,
  execution: ProjectExecutionTypes | undefined,
) => {
  return (
    projectProductType === ProjectProductTypes.CT ||
    (projectProductType === ProjectProductTypes.IG &&
      projectProductTypeClassification &&
      projectProductTypeClassification['code'] === ProjectProductTypeClassification.IGR_LESSER_THAN_3MM &&
      execution === ProjectExecutionTypes.EXECUTING_AGENCY)
  );
};

const isCtBidAndIGLowerBid = (executionType, projectProductType, projectProductTypeClassification) => {
  return (
    (executionType === ProjectExecutionTypes.BID && projectProductType === ProjectProductTypes.CT) ||
    (projectProductType === ProjectProductTypes.IG &&
      projectProductTypeClassification &&
      projectProductTypeClassification['code'] !== ProjectProductTypeClassification.IGR_GREATER_THAN_3MM &&
      executionType === ProjectExecutionTypes.BID)
  );
};

const BeneficiaryOrExecutingTeam = ({ projectProductType, baseInfo, execUnit, rolesList, refreshAndNotify }: Props) => {
  const currentProject = useSelector<any, Project>(state => state.currentProject);
  const { t } = useTranslation();
  const { projectProductTypeClassification } = currentProject;
  const executionType = currentProject.execution;
  const user = useSelector<any, User>(state => state.user);

  return isCtBidAndIGLowerBid(
    executionType,
    currentProject.projectProductType,
    projectProductTypeClassification,
  ) ? null : (
    <div className="objective column">
      <span className="section-title" style={customStyle()}>
        {t('team_executing_unit')}
      </span>
      <div className="team-members">
        {currentProject.projectProductType === ProjectProductTypes.GUARANTEE ? null : (
          <div className="title-text-group" style={customStyle()}>
            <span className="small-text one-line-text">
              {isCt(projectProductType) ? t('beneficiary') : t('borrower')}
            </span>
            <span className="text-name">{renderBorrowerBenficiary(baseInfo, projectProductType)}</span>
          </div>
        )}
        <div className="title-text-group" style={customStyle()}>
          <span className="small-text one-line-text">{t('executor')}</span>
          <span className="text-name">{baseInfo.executor || '-'}</span>
        </div>
        {/* <div className="title-text-group" style={customStyle()}>
          <span className="small-text one-line-text">{t('role_id_13')}</span>
          <span className="text-name">{baseInfo.executor || '-'}</span>
        </div>
        <div className="title-text-group" style={customStyle()}>
          <span className="small-text one-line-text">{t('role_id_8')}</span>
          <span className="text-name">{baseInfo.executor || '-'}</span>
        </div>
        <div className="title-text-group" style={customStyle()}>
          <span className="small-text one-line-text">{t('role_id_26')}</span>
          <span className="text-name">{baseInfo.executor || '-'}</span>
        </div> */}
        {execUnit &&
          execUnit.map(unit => (
            <Member
              key={unit.applicationUserId}
              member={unit}
              rolesList={rolesList}
              afterSubmit={refreshAndNotify}
              isCt={isCtOrIGLowerAgency(projectProductType, projectProductTypeClassification, executionType)}
            />
          ))}

        {/* {isAuthorizedToEdit(user, currentProject) && (
          <div style={customStyle()}>
            <AddMember
              rolesList={rolesList}
              projectId={currentProject.id}
              afterSubmit={refreshAndNotify}
              isCt={isCtOrIGLowerAgency(projectProductType, projectProductTypeClassification, executionType)}
            />
          </div>
        )} */}
      </div>
    </div>
  );
};

export default BeneficiaryOrExecutingTeam;
