import { MenuTabSectionEnum } from '../components/MenuTabs/userTabsInterface';
import { axiosDefaultConfig } from './axiosConfig';
import * as config from './config.json';
import {
  addMenuTabFilter,
  getReturnedParamsFilters,
  getReturnedParmasIndicator,
} from './filterPortfolioAndProjectUtil';
import { buildGet } from './genericMethods';

export const getProductTypes = () => {
  const url = config.GET_PRODUCT_TYPES;
  return buildGet(url);
};

export const getDepartmentDivision = () => {
  const url = config.GET_DEPARTMENT_DIVISION;
  return buildGet(url);
};

/**
 * Used by indicator key PROJECTS_KNOWLEDGE_PRODUCTS
 */
export const headerDetailData = (modalFilters: any = { divisions: [] }) => {
  const { divisions, productTypeIds, from, to, countriesOnAdvancedFilter } = modalFilters;
  const {
    regionsFilter,
    haveCountriesFilter,
    haveDivisionFilter,
    havePendingDisbursement,
    haveLastDisbursement,
    haveAgeOfProject,
    havePmrClassification,
  } = getReturnedParamsFilters();
  let returnedParams = {
    sortParameter: 1,
    regions: regionsFilter.length > 0 ? regionsFilter : [],
    Filters: [] as any,
    assignedToMe: false,
    divisions,
    productTypeIds,
    from,
    to,
  };

  const returnedParamsFilters = {
    haveCountriesFilter,
    haveDivisionFilter,
    havePendingDisbursement,
    haveLastDisbursement,
    haveAgeOfProject,
    havePmrClassification,
  };
  returnedParams = getReturnedParmasIndicator(returnedParamsFilters, returnedParams);
  returnedParams = addMenuTabFilter(returnedParams, MenuTabSectionEnum.PORTFOLIO_MENU_TAB);

  const haveCountryGlobalFilters = returnedParams.Filters.find(c => c.field === 'ResponsibleCountryId');
  let newCountryForReturnedParams = [];
  if (haveCountryGlobalFilters && countriesOnAdvancedFilter && countriesOnAdvancedFilter.length) {
    newCountryForReturnedParams = matchGlobalCountriesWithAdvancedFilter(countriesOnAdvancedFilter, returnedParams);
  } else {
    newCountryForReturnedParams = countriesOnAdvancedFilter;
  }

  if (
    (haveCountryGlobalFilters && countriesOnAdvancedFilter && countriesOnAdvancedFilter.length) ||
    (countriesOnAdvancedFilter && countriesOnAdvancedFilter.length)
  ) {
    const removeCountryFlilterFromFilterParams = returnedParams.Filters.filter(f => f.field !== 'ResponsibleCountryId');
    removeCountryFlilterFromFilterParams.push({
      filterType: 6,
      field: 'ResponsibleCountryId',
      value: newCountryForReturnedParams,
    });
    returnedParams = {
      ...returnedParams,
      Filters: removeCountryFlilterFromFilterParams,
    };
  }

  const url = config.KNOWLEDGE_PRODUCT_GET_HEADER_DETAIL;
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosDefaultConfig.post(url, returnedParams);

      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

const matchGlobalCountriesWithAdvancedFilter = (countriesToMatch, returnedParams) => {
  const returnedParamsCountries = returnedParams.Filters.find(c => c.field === 'ResponsibleCountryId').value;
  const newCountriesAddToFilter =
    countriesToMatch && countriesToMatch.filter(c => c !== returnedParamsCountries.find(rp => rp === c));
  const updateCountryList =
    returnedParamsCountries &&
    returnedParamsCountries.concat(
      newCountriesAddToFilter && newCountriesAddToFilter.filter(item => returnedParamsCountries.indexOf(item) < 0),
    );

  return updateCountryList.filter(x => x !== undefined);
};

/**
 * Used by indicator key PROJECTS_KNOWLEDGE_PRODUCTS
 */
export const tableDetailData = (rangeDate, searchTerm = '', productTypeId = null) => {
  const {
    regionsFilter,
    haveCountriesFilter,
    haveDivisionFilter,
    havePendingDisbursement,
    haveLastDisbursement,
    haveAgeOfProject,
    havePmrClassification,
  } = getReturnedParamsFilters();
  let returnedParams = {
    sortParameter: 1,
    regions: regionsFilter.length > 0 ? regionsFilter : [],
    Filters: [] as any,
    assignedToMe: false,
    from: rangeDate.from,
    to: rangeDate.to,
    productTypeId,
    pageNumber: 1,
    itemsPerPage: 100000,
  };

  const returnedParamsFilters = {
    haveCountriesFilter,
    haveDivisionFilter,
    havePendingDisbursement,
    haveLastDisbursement,
    haveAgeOfProject,
    havePmrClassification,
  };
  returnedParams = getReturnedParmasIndicator(returnedParamsFilters, returnedParams);
  returnedParams = addMenuTabFilter(returnedParams, MenuTabSectionEnum.PORTFOLIO_MENU_TAB);

  const url = config.KNOWLEDGE_PRODUCT_GET_TABLE_DATA;
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosDefaultConfig.post(url, returnedParams);

      resolve(response.data.collection);
    } catch (error) {
      reject(error);
    }
  });
};
