import React from 'react';
import './RadialChartDetailContent.scss';
import { RadialBarChart } from '../../Portfolio/CardIndicator/Cards';
import Indicator from '../../../models/Indicator';
import { IndicatorKeyEnum } from '../../../models/IndicatorKeyEnum';

interface ReceivedProps {
  currentIndicator: Indicator;
  selectedCircle?: any;
}

type Props = ReceivedProps;

export const RadialChartDetailContent = (props: Props) => {
  const { currentIndicator, selectedCircle } = props;

  const listPage = false;

  return (
    <div className="radial-chart-detail-container">
      <div
        className="chart-container"
        style={currentIndicator.indicatorKey === IndicatorKeyEnum.AVERAGE_LAG ? { height: 360 } : {}}
      >
        <RadialBarChart
          data={currentIndicator.data}
          listPage={listPage}
          selectedCircle={selectedCircle}
          isClickable={currentIndicator.indicatorKey !== IndicatorKeyEnum.AVERAGE_LAG}
          indicatorKey={currentIndicator.indicatorKey}
        />
      </div>
    </div>
  );
};
