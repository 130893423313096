export const buttonsFilterConfig = {
  //Se ve el menu de los filtros y aparentemente todos las opciones
  PROJECTS_PENDING_SIGNATURE_OR_LEGISLATIVE_APPROVAL: {
    Loan: true,
    CT: true,
    IG: true,
  },
  PROJECTS_PENDING_DISBURSEMENT_ELIGIBILITY: {
    Loan: true,
    CT: true,
    IG: true,
  },
  DISTRIBUTION_ACCORDING_TO_LATEST_EFAS: {
    Loan: true,
    CT: true,
    IG: true,
  },
  PROJECTS_EXTENDED_NOT_FULLY_DISBURSED: {
    Loan: true,
    CT: true,
    IG: true,
  },
  PROJECTS_OVERALL_STAGE_CODE_DISBURSING_NO_DISBURSEMENTS_OVER_180_DAYS: {
    Loan: true,
    CT: true,
    IG: true,
  },
  PROJECTS_LAST_DISBURSEMENT_DATE_CLOSE_12MONTHS_OR_EXPIRED: {
    Loan: true,
    CT: true,
    IG: true,
  },
  ACCORDANCES_EXPIRING: {
    Loan: true,
    CT: true,
    IG: true,
  },
  ACCORDANCES_WAITING_APPROVAL: {
    Loan: true,
    CT: true,
    IG: true,
  },
  PROJECTS_IN_EXECUTION_WITH_MICI_OPEN_CASES: {
    Loan: true,
    CT: true,
    IG: true,
  },
  PROJECTS_SUMMARY_PORTFOLIO: {
    Loan: true,
    CT: true,
    IG: true,
  },
  DISBURSEMENT_OVER_TIME: {
    Loan: true,
    CT: true,
    IG: true,
  },
  PROJECTS_IN_PREPARATION: {
    Loan: true,
    CT: true,
    IG: true,
  },
  //No se ve ningun filtro pero tiene la combinacion de los 4 tipos de proyectos en el resultado.
  USER_PENDING_TASKS: {
    Loan: false,
    CT: false,
    IG: false,
  },
  CALENDAR_EVENTS: {
    Loan: false,
    CT: false,
    IG: false,
  },
  PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS_BY_DIVISION: {
    Loan: false,
    CT: false,
    IG: false,
  },
  PROJECTS_KNOWLEDGE_PRODUCTS: {
    Loan: false,
    CT: false,
    IG: false,
  },
  //Se ve el menu de los filtros para LOAN e IG

  DISBURSEMENT_PROJECTION_LAST_5_YEARS: {
    Loan: true,
    CT: false,
    IG: true,
  },
  AVERAGE_LAG: {
    Loan: false,
    CT: false,
    IG: false,
  },
  TRANSACTIONAL_SUPERVISION_RESOURCES: {
    Loan: true,
    CT: false,
    IG: true,
  },
  SUPERVISION_PLAN_PLANNED_VERSUS_EXECUTED: {
    Loan: false,
    CT: false,
    IG: false,
  },
  PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS: {
    Loan: true,
    CT: false,
    IG: true,
  },
  MONTHLY_DISBURSEMENT_PROJECTION_CURRENT_YEAR_INCLUDING_REGIONALS: {
    Loan: true,
    CT: false,
    IG: true,
  },
  DISBURSEMENT_PROJECTION_CURRENT_YEAR_INCLUDING_REGIONALS: {
    Loan: true,
    CT: false,
    IG: true,
  },
  PROJECTS_WITH_TRANSACTIONAL_BUDGET_DEFICIT: {
    Loan: true,
    CT: false,
    IG: true,
  },
  //No se ve menu de filtros y muestra solo data de LOAN
  SUPERVISION_PLAN_BUDGET_STATUS: {
    Loan: false,
    CT: false,
    IG: false,
  },
  //TODO Estan en duda - si se muestra el filtro en el detalle || POR AHORA LOS DEJO QUE SE VEAN LOS 2 EN EL DETALLE
  // Data de LOAN e IG
  PMR_PERFORMANCE_LAST_CLOSED_YEAR: {
    Loan: false,
    CT: false,
    IG: false,
  },
  PMR_STATE_DISTRIBUTION: {
    Loan: false,
    CT: false,
    IG: false,
  },
  ESG_DISTRIBUTION_ACCORDING_TO_ENVIRONMENTAL_AND_SOCIAL_CATEGORY: {
    Loan: false,
    CT: false,
    IG: false,
  },
  ESG_SAFEGUARD_PERFORMANCE_RATING: {
    Loan: false,
    CT: false,
    IG: false,
  },
  ESG_ENVIRONMENTAL_AND_SOCIAL_RISK_RATING: {
    Loan: false,
    CT: false,
    IG: false,
  },
  //Acumula datos de IG y CT pero no muestra filtros
  GCM_ALERTS: {
    Loan: false,
    CT: false,
    IG: false,
  },
  //TODO EN DUDA VER CON GONZALO PARA DEFINICIONES DE SI SE VISUALIZAN O NO LOS FILTROS
  // Acumula IG y CT
  TCP_PERFORMANCE: {
    Loan: false,
    CT: false,
    IG: false,
  },
  BID_CONSULTANCY: {
    Loan: false,
    CT: false,
    IG: false,
  },
  PMR_VALIDATION_STATE: {
    Loan: false,
    CT: false,
    IG: false,
  },
  PORTFOLIO_OVERVIEW_LAST_5_YEARS: {
    Loan: true,
    CT: true,
    IG: true,
  },
  PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS_BY_COUNTRY: {
    Loan: false,
    CT: false,
    IG: false,
  },
  PMR_ALERT_PROBLEM_LAST_3_YEARS: {
    Loan: false,
    CT: false,
    IG: false,
  },
  PMR_EVOLUTION_LAST_5_YEARS: {
    Loan: false,
    CT: false,
    IG: false,
  },
  PCR_NEXT_PRESENTATION: {
    Loan: false,
    CT: false,
    IG: false,
  },
  PROJECTS_ELIGIBLE_FIRST_UNDISBURSEMENT: {
    Loan: true,
    CT: true,
    IG: true,
  },
  PROJECTS_PARTIAL_CANCELLATIONS: {
    Loan: true,
    CT: true,
    IG: true,
  },
  ACQUISITION_WITH_MILESTONES_PENDING_TO_DATE: {
    Loan: false,
    CT: false,
    IG: false,
  },
  CLASSIFICATION_OF_ACQUISITION_PLANS: {
    Loan: false,
    CT: false,
    IG: false,
  },
  default_indicator: {
    Loan: true,
    CT: true,
    IG: true,
  }
};
