import React from 'react';
import './Advance.scss';
import { useTranslation } from 'react-i18next';
import { Line } from 'rc-progress';
import NumberFormat from 'react-number-format';
import ProgressBar from '../../Portfolio/PortfolioProductTable/ProgressBar';

type Props = {
  data: any;
  big?: boolean;
  forReport?: any;
  financialSection?: boolean;
  titleOnTop?: boolean;
};

export const getText = (value, title) => {
  if (title === 'financialInformation') {
    return <NumberFormat value={value} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />;
  } else {
    return value;
  }
};

const Title = ({ big, forReport, title, total, unit, unitSingular }) => {
  const { t } = useTranslation();
  return (
    <div className={`horizontal ${containerStyle(forReport)}`}>
      <h3 className="left" style={big ? { color: '#416DBC' } : {}}>
        {t(title)}
      </h3>
      <div className="right" id="advance-right" style={big ? { fontSize: 12, marginTop: 2 } : {}}>
        {forReport?.right ? t('total') : t('total').toUpperCase()} {getText(total, title)}{' '}
        {total === 1 ? t(unitSingular) : t(unit)}
      </div>
    </div>
  );
};

export const FinancialTitle = ({ total }) => {
  const { t } = useTranslation();
  return (
    <div className="financial-title-container">
      <div className="title">{t('financial_advance')}</div>
      <div className="detail">
        <span>{t('approved_current_amount')}</span> {getText(total, 'financialInformation')} USD
      </div>
    </div>
  );
};

const containerStyle = forReport => {
  if (!forReport) {
    return '';
  }
  return forReport.right ? 'for-report' : 'for-report-left';
};

const Advance = (props: Props) => {
  const { data, big, forReport, financialSection, titleOnTop } = props;
  const { title, total = 0, completed = 0, pending = 0, completedText = 0, pendingText = 0, unit, unitSingular } = data;
  const { t } = useTranslation();

  const setTitleOnTop = () => {
    if ((forReport?.right && !financialSection) || titleOnTop) {
      return (
        <Title big={big} forReport={forReport} title={title} total={total} unit={unit} unitSingular={unitSingular} />
      );
    }
    return null;
  };
  return (
    <div className={`project-detail-advance-container ${containerStyle(forReport)}`}>
      {setTitleOnTop()}
      {financialSection && <FinancialTitle total={total} />}
      <div>
        <div className="horizontal">
          <div className="detail-info">
            <div className="top">{title === 'temporary_advance' ? t('actual') : t('disbursed')}</div>
          </div>
          <div className="detail-info right">
            <div className="top" style={big ? { marginBottom: 7 } : {}}>
              {t('pending')}
            </div>
          </div>
        </div>

        <div className="line-container">
          <Line
            percent={completed}
            strokeWidth={5}
            trailWidth={5}
            strokeColor={forReport?.right ? '#44bcef' : '#538CFF'}
            strokeLinecap="square"
            trailColor="#D0D5E0"
            aria-label={t(title)}
          />
          <div className="horizontal">
            <div className="porcent">{completed}%</div>
            <div className="porcent">{100 - completed}%</div>
          </div>
        </div>
      </div>

      <div className="horizontal">
        <div className="detail-info margin-top">
          <div className="bottom" id="advance-bottom-completed">
            {getText(completedText, title)} {completedText === 1 ? t(unitSingular) : t(unit)}
          </div>
        </div>
        <div className="detail-info right margin-top">
          <div className="bottom" id="advance-bottom-pending">
            {getText(pendingText, title)} {pendingText === 1 ? t(unitSingular) : t(unit)}
          </div>
        </div>
      </div>
      {!forReport?.right && !financialSection && !titleOnTop && (
        <Title big={big} forReport={forReport} title={title} total={total} unit={unit} unitSingular={unitSingular} />
      )}
    </div>
  );
};

export default Advance;
