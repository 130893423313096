import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { AccordanceType } from '../../../../../../models/AccordanceType';
import { replaceMomentFormat } from '../../../../../../services/util';
import { parseToSemester } from '../../../../../../services/stringUtil';
import AccordanceInfoItem from '../../AccordanceDetailInfo/AccordanceInfoItem';
import { ReactComponent as EditIcon } from '../../../../../../assets/icons/u_edit-blue.svg';

const accordanceTypeData = (accordance, acordanceType: AccordanceType, t: Function) => {
  return (
    <div className="horizontal-row separated">
      <AccordanceInfoItem
        label={t('new_portfolio_review_date_two')}
        data={replaceMomentFormat(moment(accordance.revisionDate).format('DD/MMM/YYYY')) || '-'}
        fullWidth
      />
    </div>
  );
  // if (acordanceType === AccordanceType.InterGroup) {
  //   return (
  //     <div className="horizontal-row separated">
  //       <AccordanceInfoItem
  //         label={t('inter_group_revision')}
  //         data={parseToSemester(accordance.revisionDate, t) || '-'}
  //         fullWidth
  //       />
  //     </div>
  //   );
  // } else if (acordanceType === AccordanceType.Revision) {
  //   return (
  //     <div className="horizontal-row separated">
  //       <AccordanceInfoItem
  //         label={t('new_portfolio_review_date_two')}
  //         data={replaceMomentFormat(moment(accordance.revisionDate).format('DD/MMM/YYYY')) || '-'}
  //         fullWidth
  //       />
  //     </div>
  //   );
  // } else {
  //   return null;
  // }
};

type Props = {
  accordance: any;
  accordanceFormType: AccordanceType;
  handleClick: Function;
};

const ReadonlyShared = (props: Props) => {
  const { t } = useTranslation();
  const { accordance, accordanceFormType, handleClick } = props;

  return (
    <>
      <div className="accordance-edit-control" onClick={() => handleClick()}>
        <span className="accordance-edit">
          <div className="accordance-edit-icon">
            <EditIcon />
          </div>
          {t('edit')}
        </span>
      </div>
      <div className="horizontal-row separated">
        <AccordanceInfoItem
          label={t('new_agreement_defined_in')}
          data={
            accordance.accordanceAgreementsType?.name
              ? accordance.accordanceAgreementsType?.name
              : accordance.definedAgreement?.name
          }
        />
        {accordanceTypeData(accordance, accordanceFormType, t)}
      </div>
      <div className="horizontal-row separated">
        <AccordanceInfoItem
          label={t('critical_issue')}
          data={accordance.criticalIssue.label || accordance.criticalIssue.name}
        />
        {/* <AccordanceInfoItem label={t('products')} data={accordance?.linkedProduct?.map(d => `${d.name} - `)} /> */}
      </div>
      <div className="horizontal-row separated">
        <AccordanceInfoItem
          label={t('description_of_the_critical_issue')}
          data={accordance.criticalIssueDescription || '-'}
          fullWidth
        />
      </div>
    </>
  );
};

export default ReadonlyShared;
