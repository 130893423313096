import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './CurrentProjectionTable.module.scss';
import { Pagination } from '@material-ui/lab';
import '../../../Util/Pagination/Pagination.scss';
import usePaginationSort from '../../../../hooks/usePaginationSort';
import useTable from '../../../../hooks/useTable';
import { ProjectCounter } from '../../ProjectCounter/ProjectCounter';
import { CriteriaType } from '../../../../models/PaginationIndicator';
import { basicFirstColumns, HeaderTableColumn } from '../../../../models/HeaderTableColumn';
import { IHeaderTable } from '../HeaderTable/HeaderTable';
import NumberFormat from 'react-number-format';
import { ProjectsListSkeleton } from '../../../LoadersSkeletons/ProjectsListSkeleton/ProjectsListSkeleton';
import { Tooltip } from '@material-ui/core';
import { ExcelDownloadButton } from '../../../Util/ExcelDownloadButton/ExcelDownloadButton';
import useDataNormalize from '../../../../hooks/useDataNormalize';
import PaginationTable from '../../PaginationTable/PaginationTable';
import { withStyles } from '@material-ui/core/styles';
import { FilterTables } from '../../../Filter/FilterTables/FilterTables';

const BlackTooltip = withStyles({
  tooltip: {
    //   color: '$ci-color-greyscale-90',
    backgroundColor: '#101319',
    fontSize: '16px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    lineHeight: '20px',
    padding: '8px 12px',
    borderRadius: '8px',
  },
  arrow: {
    fontSize: 20,
    color: '#4A4A4A',
    '&::before': {
      backgroundColor: '#101319',
    },
  },
})(Tooltip);

const headers = ['id', 'last_project', 'last_operation', 'name', 'current_projection', 'amount_disbursed'];

const selectedKeys = ['id', 'code', 'loanNumber', 'title', 'field1', 'field2'];

type Props = {
  data: IHeaderTable;
  loading?: boolean;
};
export const CurrentProjectionTable = ({ data, loading }: Props) => {
  const { month, year } = data;
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const isFuture = getIsFuture(month, year);
  const { t } = useTranslation();
  const { handleClick, projects } = useTable();
  const [dataFilter, setDataFilter] = useState<any>(projects);

  useEffect(() => {
    setDataFilter(projects);
  }, [projects]);

  const { handlePaginationChange, handleSort, currentList, pagesCount, currentPage } = usePaginationSort({
    projects: dataFilter,
    defaultSortCriteria: 'code',
    pageCount: rowsPerPage,
  });

  const {
    container,
    headerTitle,
    rowValue,
    rowList,
    rowValueName,
    fw,
    lastColumn,
    headerTitleClickable,
    tableHeaderPast,
    tableRowPast,
    tableHeaderFuture,
    tableRowFuture,
    rowValueRight,
    headerTitleClickableRight,
    borderTable,
  } = styles;

  const handleClickOnProject = project => {
    handleClick(project);
  };

  const currentProjectionHeaderTableSetup: HeaderTableColumn[] = [
    ...basicFirstColumns(headerTitleClickable),
    {
      className: headerTitleClickableRight,
      criteria: 'field1',
      criteriaType: CriteriaType.decimalNumber,
      label: 'current_projection',
    },
    {
      className: headerTitleClickableRight,
      criteria: 'field2',
      criteriaType: CriteriaType.decimalNumber,
      label: 'amount_disbursed',
      hide: isFuture,
    },
  ];

  const dataExcel = useDataNormalize(headers, selectedKeys, projects);

  return loading ? (
    <ProjectsListSkeleton />
  ) : (
    projects.length && (
      <div className={container}>
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <ExcelDownloadButton headers={headers} selectedKeys={selectedKeys} dataExcel={dataExcel} />
          <FilterTables
            projects={projects}
            keysFilter={selectedKeys}
            fnDataFilter={setDataFilter}
            disabledNumber={currentPage}
            indicatorKey={'MONTHLY_DISBURSEMENT_PROJECTION_CURRENT_YEAR_INCLUDING_REGIONALS'}
          />
        </div>

        <div className={borderTable}>
          <div className={isFuture ? tableHeaderFuture : tableHeaderPast}>
            {currentProjectionHeaderTableSetup.map(cp => {
              return handleHideColumnHeader(cp, handleSort, t);
            })}
          </div>
          <ul className={rowList}>
            {dataFilter?.length === 0
              ? dataFilter?.map(project => (
                  <li
                    className={isFuture ? tableRowFuture : tableRowPast}
                    key={`${project.loanNumber}${project.code}${project.frontendId}`}
                  >
                    {project.id && project.loanNumber !== null ? (
                      <BlackTooltip title={t('go_to_project_info')} arrow>
                        <p className={`${rowValue} link-project`} onClick={() => handleClickOnProject(project)}>
                          {project.code}
                        </p>
                      </BlackTooltip>
                    ) : (
                      <p className={rowValue}>{project.code}</p>
                    )}

                    <p className={rowValue}>{project.loanNumber}</p>
                    <BlackTooltip
                      title={project.title || ''}
                      placement="bottom"
                      enterDelay={500}
                      leaveDelay={100}
                      arrow
                    >
                      <p className={rowValueName}>{project.title}</p>
                    </BlackTooltip>
                    <p className={rowValueRight}>
                      <NumberFormat
                        value={handleNumberFormat(project.field1)}
                        displayType={'text'}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                      />
                    </p>
                    {!isFuture && (
                      <p className={rowValueRight}>
                        {
                          <NumberFormat
                            value={handleNumberFormat(project.field2)}
                            displayType={'text'}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            style={handleUnfulfilledAmount(project.field1, project.field2)}
                          />
                        }
                      </p>
                    )}
                  </li>
                ))
              : currentList?.map(project => (
                  <li
                    className={isFuture ? tableRowFuture : tableRowPast}
                    key={`${project.loanNumber}${project.code}${project.frontendId}`}
                  >
                    {project.id && project.loanNumber !== null ? (
                      <BlackTooltip title={t('go_to_project_info')} arrow>
                        <p className={`${rowValue} link-project`} onClick={() => handleClickOnProject(project)}>
                          {project.code}
                        </p>
                      </BlackTooltip>
                    ) : (
                      <p className={rowValue}>{project.code}</p>
                    )}

                    <p className={rowValue}>{project.loanNumber}</p>
                    <BlackTooltip
                      title={project.title || ''}
                      placement="bottom"
                      enterDelay={500}
                      leaveDelay={100}
                      arrow
                    >
                      <p className={rowValueName}>{project.title}</p>
                    </BlackTooltip>
                    <p className={rowValueRight}>
                      <NumberFormat
                        value={handleNumberFormat(project.field1)}
                        displayType={'text'}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                      />
                    </p>
                    {!isFuture && (
                      <p className={rowValueRight}>
                        {
                          <NumberFormat
                            value={handleNumberFormat(project.field2)}
                            displayType={'text'}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            style={handleUnfulfilledAmount(project.field1, project.field2)}
                          />
                        }
                      </p>
                    )}
                  </li>
                ))}
          </ul>
        </div>
        <ProjectCounter arrayToCount={projects} showOnlyProjects={true} countProjects={true} />
        <div className="pagination-style">
          <PaginationTable fnSetRows={setRowsPerPage} rows={rowsPerPage} numProyects={dataFilter?.length} />
          {dataFilter?.length !== 0 ? (
            <Pagination count={pagesCount} page={currentPage} onChange={handlePaginationChange} />
          ) : null}
        </div>
      </div>
    )
  );
};

const getIsFuture = (month, year) => {
  const today = new Date();
  const currentMonth = today.getMonth() + 1;
  const currentYear = today.getFullYear();

  if (year > currentYear || (year === currentYear && month > currentMonth)) {
    return true;
  }
  return false;
};

const handleHideColumnHeader = (column, handleSort, t) => {
  const { className, criteria, criteriaType, label, hide } = column;
  return (
    !hide && (
      <h4
        className={className}
        onClick={() => handleSort(criteria, criteriaType)}
        key={`${className}-${criteria}-${label}`}
      >
        {t(label)}
      </h4>
    )
  );
};

const handleNumberFormat = field => {
  return field === '0.00' ? 0 : parseFloat(field);
};

const handleUnfulfilledAmount = (field1, field2) => {
  const amountToBeDisbursed = parseFloat(field1);
  const amountDisbursed = parseFloat(field2);
  if (amountToBeDisbursed > amountDisbursed) {
    return { color: '#E092A3', fontWeight: 'bold' as 'bold' };
  } else if (amountToBeDisbursed < amountDisbursed) {
    return { fontWeight: 'bold' as 'bold' };
  }
  return {};
};
