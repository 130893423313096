import { capitalizeFirstLetter } from './../../../../../services/stringUtil';
export const getResponsibleIds = responsibles => {
  return responsibles && responsibles.map(responsible => responsible.id);
};

export const getResponsibleOptions = responsibles => {
  return responsibles.map(p => ({
    id: p.applicationUser.id,
    label: `${p.applicationUser.name} ${p.applicationUser.lastName}`,
    value: `${p.applicationUser.name} ${p.applicationUser.lastName}`,
  }));
};

export const getNoneRelatedEarlyWarningOption = t => {
  return { id: 0, label: capitalizeFirstLetter(t('none_fem')), value: 'none' };
};
export const getEarlyWarningOptions = (earlyWarnings, t) => {
  if (earlyWarnings.length === 0) {
    return [getNoneRelatedEarlyWarningOption(t)];
  }
  return earlyWarnings.map(ew => ({
    id: ew.id,
    label: ew.code === 'none' ? capitalizeFirstLetter(t(ew.description)) : ew.description,
    value: ew.code,
  }));
};

export const responsiblesAreEqual = (oldReponsibles, newResponsibles) => {
  const oldReponsiblesIds = oldReponsibles.map(responsible => responsible.applicationUser.id).sort();
  const newResponsiblesIds = newResponsibles && getResponsibleIds(newResponsibles).sort();

  return (
    oldReponsiblesIds.length === (newResponsibles && newResponsiblesIds.length) &&
    oldReponsiblesIds.every((val, ind) => val === newResponsiblesIds[ind])
  );
};
