import React from 'react';
import { Line } from 'react-chartjs-2';
import { isMobile } from '../../../../../services/booleanUtil';
import { IChartDimensions } from '../BarChart/BarChart';

interface ReceivedProps {
  data: any;
  customDimensions: IChartDimensions;
  options: any;
}

type Props = ReceivedProps;

export const MultiLine = ({ data, customDimensions, options }: Props) => {
  const dimensions = isMobile() ? customDimensions.mobile : customDimensions.desktop;
  return <Line options={options} data={data} datasetKeyProvider={datasetKeyProvider} {...dimensions} />;
};

const datasetKeyProvider = () => {
  return btoa(Math.random().toString()).substring(0, 12);
};
