import React from 'react';
import './EfasPortfolio.scss';
import { useTranslation } from 'react-i18next';
import { EfasConcepts } from '../../../models/EfasConcepts';

export const getEfasValues = efasClassification => {
  if (efasClassification === EfasConcepts.clean) {
    return {
      text: 'clean',
    };
  } else if (efasClassification === EfasConcepts.disclaimer_of_opinion) {
    return {
      text: 'disclaimer_of_opinion',
    };
  } else if (efasClassification === EfasConcepts.adverse_opinion) {
    return {
      text: 'adverse_opinion',
    };
  } else if (efasClassification === EfasConcepts.qualified_opinion) {
    return {
      text: 'qualified_opinion',
    };
  }
  return {
    text: 'pending',
  };
};

type Props = {
  efasConcept: any;
};
export const EfasPortfolio = ({ efasConcept }: Props) => {
  const { t } = useTranslation();

  const results = getEfasValues(efasConcept);

  return (
    <div className="efas-portfolio-container">
      <span className={`efas-text ${results.text}`}> {t(results.text)} </span>
    </div>
  );
};
