import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReportImageDisplay } from '../../../../../Util/Modals/CustomizableReports/CustomizableReports';
import { BidTooltipGrayAndWhite } from '../../../../../Util/Tooltip/Tooltip';
import styles from './GcmAlertProgressChartElement.module.scss';

type Props = {
  data: any[];
  isDetail?: boolean;
  handleClick?: Function;
  forReport?: ReportImageDisplay;
};

const reportStyle = (forReport: ReportImageDisplay | undefined) =>
  forReport && forReport === ReportImageDisplay.Detail ? { color: 'white' } : {};

const detailCondition = (isDetail, yes, no) => (isDetail ? yes : no);
const GcmAlertProgressChartElement = ({ isDetail, handleClick, data, forReport }: Props) => {
  const { t } = useTranslation();
  const { itemClass, progress, textContainer, label, value, itemDetail, noData, container } = styles;
  return (
    <div className={container} style={detailCondition(isDetail, { flexDirection: 'row' }, {})}>
      {data ? (
        data.map(item => (
          <div
            style={isDetail && item.value === 0 ? { cursor: 'default' } : {}}
            className={detailCondition(isDetail, itemDetail, itemClass)}
            onClick={handleClick && item.value !== 0 ? () => handleClick(item.status) : () => { }}
            key={item.status}
          >
            <BidTooltipGrayAndWhite
              title={`${t(item.label)}: ${item.percentage}%`}
              placement="top"
              enterDelay={500}
              leaveDelay={200}
              arrow
            >
              <div className={textContainer}>
                <p className={label} style={reportStyle(forReport)}>
                  {t(item.label)}
                  {`${detailCondition(isDetail, '', ':')}`}
                </p>
                <p className={value} style={reportStyle(forReport)}>{`${item.value} ${t(item.valueSuffix)}`}</p>
              </div>

            </BidTooltipGrayAndWhite>
            <span
              className={progress}
              style={{
                backgroundColor: item.color,
                width: detailCondition(isDetail, `${item.percentage * 3}px`, `${item.percentage}%`),
              }}
            ></span>
          </div>
        ))
      ) : (
        <div className={noData}>-</div>
      )}
    </div>
  );
};

export default GcmAlertProgressChartElement;
