import React from 'react';
import './CustomToolbar.scss';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setCalendarDate } from '../../../../../redux/actions/calendar';

const CustomToolbar = (toolbar: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const goToBack = () => {
    toolbar.date.setMonth(toolbar.date.getMonth() - 1, 1);
    toolbar.onNavigate('prev');
    dispatch(setCalendarDate(toolbar.date));
  };

  const goToNext = () => {
    toolbar.date.setMonth(toolbar.date.getMonth() + 1, 1);
    toolbar.onNavigate('next');
    dispatch(setCalendarDate(toolbar.date));
  };

  return (
    <div className="calendar-custom-toolbar-container">
      <button className="nav-button back" onClick={goToBack}></button>
      <div className="title">
        {/* <h4>{t('key_dates')}</h4>  */}
        <h3>{moment(toolbar.date).format('MMMM YYYY')}</h3>
      </div>
      <button className="nav-button next" onClick={goToNext}></button>
    </div>
  );
};

export default CustomToolbar;
