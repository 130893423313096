import { findMetricValueByGrouping, getIndicatorData, metricAreZeros } from './indicatorHelperUtil';
import { IndicatorKeyEnum as keyEnum } from '../../../models/IndicatorKeyEnum';
import { CardTypesEnum } from '../../../models/CardTypesEnum';
import { yearsList } from './util';

const percentageCalculator = (satisfactoryLineData, problemLineData, alertLineData) => {
  const satisfactoryLineDataPercentages: number[] = [];
  const problemLineDataPercentages: number[] = [];
  const alertLineDataPercentages: number[] = [];

  satisfactoryLineData.forEach((element, idx) => {
    if (!element && !problemLineData[idx] && !alertLineData[idx]) {
      satisfactoryLineDataPercentages.push(0);
      problemLineDataPercentages.push(0);
      alertLineDataPercentages.push(0);
    } else {
      const hundredPercentForYear = element + problemLineData[idx] + alertLineData[idx];
      const satisfactoryPercentage = Math.round((element * 100) / hundredPercentForYear);
      const problemPercentage = Math.round((problemLineData[idx] * 100) / hundredPercentForYear);
      let alertPercentage = Math.round((alertLineData[idx] * 100) / hundredPercentForYear);
      if (satisfactoryPercentage + problemPercentage + alertPercentage > 100) {
        const diff = satisfactoryPercentage + problemPercentage + alertPercentage - 100;
        alertPercentage = alertPercentage - diff;
      } else if (satisfactoryPercentage + problemPercentage + alertPercentage < 100) {
        const diff = 100 - (satisfactoryPercentage + problemPercentage + alertPercentage);

        alertPercentage = alertPercentage + diff;
      }
      satisfactoryLineDataPercentages.push(satisfactoryPercentage);
      problemLineDataPercentages.push(problemPercentage);
      alertLineDataPercentages.push(alertPercentage);
    }
  });

  return { satisfactoryLineDataPercentages, problemLineDataPercentages, alertLineDataPercentages };
};

/*PMR_EVOLUTION_LAST_5_YEARS */
const addPmrEvolutionLast5Years = (indicators, cardIndicators) => {
  const indicator = getIndicatorData(indicators, keyEnum.PMR_EVOLUTION_LAST_5_YEARS);
  const SATISFACTORY_OPER_CNT = 'SATISFACTORY_OPER_CNT';
  const PROBLEM_OPER_CNT = 'PROBLEM_OPER_CNT';
  const ALERT_OPER_CNT = 'ALERT_OPER_CNT';
  const SATISFACTORY_OPER_CNT_FINAL = 'SATISFACTORY_OPER_CNT_FINAL';
  const PROBLEM_OPER_CNT_FINAL = 'PROBLEM_OPER_CNT_FINAL';
  const ALERT_OPER_CNT_FINAL = 'ALERT_OPER_CNT_FINAL';

  if (!indicator) {
    return null;
  } else if (metricAreZeros(indicator)) {
    const metricZeroCard = {
      id: indicator.id,
      indicatorKey: indicator.key,
      type: CardTypesEnum.MIXED_VERTICAL_BAR_LINE,
      title: 'evolution_of_the_pmr_in_the_last_5_years',
      subtitle: '',
      detailTitle: '',
      detailChartTitle: '',
      detailChartFoot: '',
      data: null,
      order: indicator.order,
    };
    cardIndicators.push(metricZeroCard);
    return metricZeroCard;
  } else {
    const labels = yearsList(indicator.metrics);
    const satisfactoryLineData: number[] = [];
    const problemLineData: number[] = [];
    const alertLineData: number[] = [];
    const satisfactoryLineFinalData: number[] = [];
    const problemLineFinalData: number[] = [];
    const alertLineFinalData: number[] = [];

    for (const label of labels) {
      const satisfactoryLine = findMetricValueByGrouping(
        indicator.metrics,
        'TRANSACTION_YEAR',
        label,
        SATISFACTORY_OPER_CNT,
      );
      const problemLine = findMetricValueByGrouping(indicator.metrics, 'TRANSACTION_YEAR', label, PROBLEM_OPER_CNT);
      const alertLine = findMetricValueByGrouping(indicator.metrics, 'TRANSACTION_YEAR', label, ALERT_OPER_CNT);

      const satisfactoryLineFinal = findMetricValueByGrouping(
        indicator.metrics,
        'TRANSACTION_YEAR',
        label,
        SATISFACTORY_OPER_CNT_FINAL,
      );
      const alertLineFinal = findMetricValueByGrouping(
        indicator.metrics,
        'TRANSACTION_YEAR',
        label,
        ALERT_OPER_CNT_FINAL,
      );
      const problemLineFinal = findMetricValueByGrouping(
        indicator.metrics,
        'TRANSACTION_YEAR',
        label,
        PROBLEM_OPER_CNT_FINAL,
      );

      satisfactoryLineData.push(satisfactoryLine);
      problemLineData.push(problemLine);
      alertLineData.push(alertLine);
      satisfactoryLineFinalData.push(satisfactoryLineFinal);
      problemLineFinalData.push(problemLineFinal);
      alertLineFinalData.push(alertLineFinal);
    }

    const { satisfactoryLineDataPercentages, problemLineDataPercentages, alertLineDataPercentages } =
      percentageCalculator(satisfactoryLineData, problemLineData, alertLineData);
    const {
      satisfactoryLineDataPercentages: satisfactoryLineFinalDataPercentages,
      problemLineDataPercentages: problemLineFinalDataPercentages,
      alertLineDataPercentages: alertLineDataFinalPercentages,
    } = percentageCalculator(satisfactoryLineFinalData, problemLineFinalData, alertLineFinalData);

    const satisfactory = {
      data: satisfactoryLineDataPercentages,
      borderColor: '#91E2A1',
      backgroundColor: 'transparent',
      borderWidth: 2,
      type: 'line',
      pointRadius: 0,
      lineTension: 0,
      order: 1,
      label: 'line',
    };
    const alert = {
      data: alertLineDataPercentages,
      borderColor: '#F7DD00',
      backgroundColor: 'transparent',
      borderWidth: 2,
      type: 'line',
      pointRadius: 0,
      lineTension: 0,
      order: 2,
      label: 'line',
    };
    const problem = {
      data: problemLineDataPercentages,
      borderColor: '#F06D6D',
      backgroundColor: 'transparent',
      borderWidth: 2,
      type: 'line',
      pointRadius: 0,
      lineTension: 0,
      order: 3,
      label: 'line',
    };
    const satisfactoryFinal = { ...satisfactory, data: satisfactoryLineFinalDataPercentages };
    const alertFinal = { ...alert, data: alertLineDataFinalPercentages };
    const problemFinal = { ...problem, data: problemLineFinalDataPercentages };

    const datasets = [satisfactory, alert, problem];
    const datasetsFinal = [satisfactoryFinal, alertFinal, problemFinal];
    const dataChart: any = {
      id: indicator.id,
      indicatorKey: indicator.key,
      type: CardTypesEnum.MULTI_LINE,
      title: 'evolution_of_the_pmr_in_the_last_5_years',
      subtitle: '',
      detailTitle: 'evolution_of_the_pmr_in_the_last_5_years',
      detailChartTitle: '',
      detailChartFoot: '',
      data: [
        {
          labels,
          datasets,
          subLabels: [],
        },
        {
          labels,
          datasets: datasetsFinal,
          subLabels: [],
        },
      ],
      order: indicator.order,
    };

    cardIndicators.push(dataChart);

    return dataChart;
  }
};

const multiLineOptions = forReport => ({
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        gridLines: {
          display: true,
          drawBorder: false,
          borderDash: [3, 3],
          color: '#A0ABC1',
        },
        ticks: {
          display: false,
          fontColor: '#101319',
          fontFamily: 'rubik',
          fontSize: 12,
          fontStyle: 400,
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        ticks: {
          fontColor: '#101319',
          fontFamily: 'rubik',
          fontSize: 12,
          fontStyle: 400,
        },
      },
    ],
  },
  cornerRadius: 3,
  plugins: { datalabels: { display: false } },
  animation: {
    duration: forReport ? 0 : 1000,
  },
});
export { addPmrEvolutionLast5Years, multiLineOptions };
