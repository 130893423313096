export enum NotificationsTypesEnum {
  AccordanceCreate = 1,
  AccordanceCanceled,
  AccordanceComment,
  AccordanceApproved,
  AccordanceToApprove,
  AccordanceReject,
  AccordanceCompleted,
  PortfolioReviewDetails,
  PortfolioReviewDate,
  CalendarDisbursement,
  CalendarActivity,
  CalendarAcquisition,
  CalendarAccordance,
  DisbursementReminder,
  AcquisitionReminder,
  ActivityReminder,
  MonitoringAccordanceReminder,
  RevisionAccordanceReminder,
  PortfolioReviewProjectRemoved,
  PortfolioReviewPublished,
  PortfolioReviewCalendarConfirmed,
  ContentManagerNotification,
  NewGcmAlert,
  NewEarlyWarningAlert,
  EarlyWarningAutoGeneratedReport,
}
