import React from 'react';
import { useSelector } from 'react-redux';
import Project from '../../../../../models/Project';
import { SummaryIndicatorTable } from '../../SummaryIndicatorTable/SummaryIndicatorTable';

import styles from './TableData.module.scss';
import { getDynamicTableFormatByInternalFilter } from './Util';

export const TableData = () => {
  const indicatorProjects = useSelector<any, Project[]>(state => state.indicatorProjects);

  return (
    <div className={styles.portfolioProjectsDetailContentContainer}>
      <div className={indicatorProjects.length === 0 ? styles.wrapper : styles.adjustHeight}>
        <SummaryIndicatorTable projects={indicatorProjects} tableInfo={getDynamicTableFormatByInternalFilter()} />
      </div>
    </div>
  );
};
