import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ProjectTypeFilterCheckbox.module.scss';
import CustomCheckbox from '../../Util/CustomCheckbox/CustomCheckbox';
import { ProjectProductTypeFilters } from '../../../models/ProjectProductTypeFilters';
import IndicatorsTable from '../../../assets/json/IndicatorsTable';
import DropMenu from './DropMenu/DropMenu';
import { useSelector } from 'react-redux';

type Props = {
  filterTitle?: string;
  handleChanges: Function;
  igrIsDisabled: Function;
  showIgrSubFilters: Function;
  isVertical?: boolean;
  isRight?: boolean;
  filterState: number[];
  onModalReports?: boolean;
};

const customStyleMainFilter = {
  display: 'flex',
  alignItems: 'center',
  width: 'auto',
  height: '50px',
  marginRight: '25px',
};
const onModalReportCustomMainFilter = {
  ...customStyleMainFilter,
  width: '60px',
};

const customStyleMainSubFilter = {
  display: 'flex',
  alignItems: 'center',
  width: 'auto',
  height: '10px',
  marginRight: '90px',
};

const verticalCustomStyle = (isVertical = false) => {
  return { ...customStyleMainSubFilter, marginRight: isVertical ? '0px' : '90px' };
};

const projectTypeFilterIsChecked = (projectType: number, filterState: number[]): boolean => {
  switch (projectType) {
    case ProjectProductTypeFilters.Loan:
      return filterState.includes(ProjectProductTypeFilters.Loan);
    case ProjectProductTypeFilters.CT:
      return filterState.includes(ProjectProductTypeFilters.CT);
    case ProjectProductTypeFilters.IG_greater_3:
      return filterState.includes(ProjectProductTypeFilters.IG_greater_3);
    case ProjectProductTypeFilters.IG_lower_3:
      return filterState.includes(ProjectProductTypeFilters.IG_lower_3);
    case -1:
      return (
        filterState.includes(ProjectProductTypeFilters.IG_greater_3) ||
        filterState.includes(ProjectProductTypeFilters.IG_lower_3)
      );
    default:
      return false;
  }
};

const ProjectTypeFilterCheckbox = (props: Props) => {
  const { t } = useTranslation();

  const {
    filterTitle,
    handleChanges,
    igrIsDisabled,
    showIgrSubFilters,
    isVertical,
    isRight,
    filterState,

    onModalReports,
  } = props;
  const {
    filterContainerVertical,
    filterContainer,
    mainFilterContainerRight,
    mainFilterContainerLeft,
    onModalReportFilterContainer,
  } = styles;

  const isLoadingState = useSelector<any, any>(state => state.dataTableInfo.loading);
  // const projectTypeFilters = useSelector<any, any>(state => state.filterIndicators.common.projectTypeFilters);

  return (
    <div
      className={handleFilterContainer(
        isVertical,
        filterContainerVertical,
        filterContainer,
        onModalReports,
        onModalReportFilterContainer,
      )}
    >
      <div className={isRight ? mainFilterContainerRight : mainFilterContainerLeft}>
        {/* <DropMenu /> */}
        <div>
          <CustomCheckbox
            labelText={''}
            handleOnClick={value => handleChanges({ value, projectProductType: ProjectProductTypeFilters.Loan })}
            isChecked={projectTypeFilterIsChecked(ProjectProductTypeFilters.Loan, filterState)}
            customStyle={onModalReports ? onModalReportCustomMainFilter : { ...customStyleMainFilter }}
            showProductTypeIcon
            productTypeIconText={'LON'}
            customCheckboxAligment={isVertical}
            disabled={isLoadingState || (filterState.length === 1 && filterState.includes(0))}
          />
        </div>
        <div>
          <CustomCheckbox
            labelText={''}
            handleOnClick={value => handleChanges({ value, projectProductType: ProjectProductTypeFilters.CT })}
            isChecked={projectTypeFilterIsChecked(ProjectProductTypeFilters.CT, filterState)}
            customStyle={onModalReports ? onModalReportCustomMainFilter : { ...customStyleMainFilter }}
            showProductTypeIcon
            productTypeIconText={'TCP'}
            customCheckboxAligment={isVertical}
            disabled={isLoadingState || (filterState.length === 1 && filterState.includes(1))}
          />
        </div>
        <div>
          <CustomCheckbox
            labelText={''}
            handleOnClick={value => handleChanges({ value, projectProductType: -1 })}
            isChecked={projectTypeFilterIsChecked(-1, filterState)}
            customStyle={onModalReports ? onModalReportCustomMainFilter : customStyleMainFilter}
            showProductTypeIcon
            productTypeIconText={'IGR'}
            customCheckboxAligment={isVertical}
            disabled={isLoadingState || (filterState.length === 2 && filterState.includes(2))}
          />
        </div>
      </div>
      {/* {showIgrSubFilters() && (
        <div className={handleSubFilterStyle(isRight, styles)}>
          <CustomCheckbox
            labelText={'IGR > USD 3MM'}
            handleOnClick={value =>
              handleChanges({ value, projectProductType: ProjectProductTypeFilters.IG_greater_3 })
            }
            isChecked={projectTypeFilterIsChecked(ProjectProductTypeFilters.IG_greater_3, filterState)}
            customStyle={verticalCustomStyle(isVertical)}
            isSubFilter
            customCheckboxAligment={isVertical}
          />
          <CustomCheckbox
            labelText={'IGR < USD 3MM'}
            handleOnClick={value => handleChanges({ value, projectProductType: ProjectProductTypeFilters.IG_lower_3 })}
            isChecked={projectTypeFilterIsChecked(ProjectProductTypeFilters.IG_lower_3, filterState)}
            customStyle={verticalCustomStyle(isVertical)}
            isSubFilter
            customCheckboxAligment={isVertical}
          />
        </div>
      )} */}
    </div>
  );
};

export default ProjectTypeFilterCheckbox;

const handleFilterContainer = (
  isVertical,
  filterContainerVertical,
  filterContainer,
  onModalReports,
  onModalReportFilterContainer,
) => {
  if (onModalReports) {
    return onModalReportFilterContainer;
  }

  return isVertical ? filterContainerVertical : filterContainer;
};

const handleSubFilterStyle = (isRight, styles) => {
  return isRight ? styles.subFilterContainerRight : styles.subFilterContainer;
};
