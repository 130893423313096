import React from 'react';
import './ProjectDetail.scss';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';

import Header from '../../components/Header/Header';
import ProjectDetailHeader from '../../components/ProjectDetail/ProjectDetailHeader/ProjectDetailHeader';
import GeneralInformation from '../../components/ProjectDetail/GeneralInformation/GeneralInformation';
import ProjectTabs from '../../components/ProjectDetail/ProjectTabs/ProjectTabs';
import Financial from '../../components/ProjectDetail/ProjectTabs/Financial/Financial';
import Risks from '../../components/ProjectDetail/ProjectTabs/Risks/Risks';
import BaseInformation from '../../components/ProjectDetail/BaseInformation/BaseInformation';
import { getAccordanceById } from '../../api/accordanceApi';
import AccordanceDetail from '../../components/Util/Modals/AccordanceDetail/AccordanceDetail';
import {
  replaceMomentFormat,
  showCorrespondingComponent,
  ShowComponentEnum,
  getGcmAlertToShow,
} from '../../services/util';
import { getIdFromUrl } from '../../services/numberUtil';
import { isMobile } from '../../services/booleanUtil';
import ProjectDetailMobile from '../../components/ProjectDetail/ProjectDetailMobile/ProjectDetailMobile';
import AccordanceReject from '../../components/Util/Modals/AccordanceReject/AccordanceReject';
import Planning from '../../components/ProjectDetail/ProjectTabs/Planning/Planning';
import SelectReviewProject from './SelectReviewProject';
import { SwitchCalendarView } from '../../components/ProjectDetail/SwitchCalendarViews/SwitchCalendarView';
import Supervision from '../../components/ProjectDetail/ProjectTabs/Supervision/Supervision';
import { ProjectProductTypes } from '../../models/ProjectProductTypes';
import { GeneralInformationTC } from '../../components/ProjectDetail/GeneralInformationTC/GeneralInformationTC';
import { ProjectDetailsTab } from '../../models/ProjectDetailsTabEnum';
import { getProjectAccordances, getProject, resetCurrentProject } from '../../redux/actions/currentProject';
import { ReactComponent as GcmIssue } from '../../assets/gcm-issue.svg';
import { ReactComponent as GcmAlert } from '../../assets/gcm-alert.svg';
import { ReactComponent as GcmCancelation } from '../../assets/gcm-cancelation.svg';
import Project from '../../models/Project';
import { IGcmAlert } from '../../models/GcmAlerts';
import { ProjectProductTypeClassification } from '../../models/ProjectProductTypeClassification';
import { ProjectSwitch } from '../../components/ProjectDetail/ProjectSwitch/ProjectSwitch';
import { PerformancePMRCollapsed } from '../../components/ProjectDetail/PerformancePMRCollapsed/PerformancePMRCollapsed';
import DisbursementProjectionEvolution from '../../components/ProjectDetail/DisbursementProjectionEvolution/DisbursementProjectionEvolution';
import Products from '../../components/ProjectDetail/ProjectTabs/Products/Products';
import { setProjectTabValue } from '../../redux/actions/projectTabValue';
import { EarlyWarningHistory } from '../../components/ProjectDetail/EarlyWarningHistory/EarlyWarningHistory';
import Acquisitions from '../../components/ProjectDetail/ProjectTabs/Acquisitions/Acquisitions';
import AccordanceTrelloWithContext from '../../components/ProjectDetail/ProjectTabs/Accordances/AccordanceTrello/AccordanceTrelloWithContext';
import Evolution from '../../components/ProjectDetail/ProjectTabs/Evolution/Evolution';

const getMessageDate = (currentProject, currentReview) => {
  if (currentProject.currentRevision && currentProject.currentRevision.startDate) {
    return currentProject.currentRevision.startDate;
  } else if (currentReview && currentReview.startDate) {
    return currentReview.startDate;
  } else {
    return new Date().toISOString();
  }
};

const projectAlreadySelected = (currentProject, reviewProjectsSelectedRows, currentReview) => {
  return (
    (currentProject.currentRevision && currentProject.currentRevision.startDate) ||
    reviewProjectsSelectedRows.indexOf(currentProject.id) !== -1 ||
    (currentReview && currentReview.projects && currentReview.projects.find(p => currentProject.id === p.id))
  );
};

const projectNotSelected = (currentProject, reviewProjectsSelectedRows, location, currentReview) => {
  return (
    currentProject.id !== 0 &&
    !currentProject.currentRevision &&
    reviewProjectsSelectedRows.indexOf(currentProject.id) === -1 &&
    location &&
    location.state &&
    location.state['fromReview'] &&
    currentReview &&
    currentReview.projects &&
    !currentReview.projects.find(p => currentProject.id === p.id)
  );
};

const updateStorageRedirect = queryStrings => {
  if (queryStrings.redirect && queryStrings.id) {
    sessionStorage.setItem('redirect', `/projectdetail?id=${queryStrings.id.toString()}`);
  } else {
    if (sessionStorage.getItem('redirect')) {
      sessionStorage.removeItem('redirect');
    }
  }
};

const getAlertIcon = (gcmAlerts: IGcmAlert[] = [], tab) => {
  const iconTabStyle = { marginBottom: '0px', marginRight: '5px' };
  const priorityAlert: IGcmAlert = getGcmAlertToShow(gcmAlerts, tab);
  const alertIcons = {
    0: <GcmCancelation width={13} height={13} style={iconTabStyle} />,
    1: <GcmAlert width={13} height={13} style={iconTabStyle} />,
    2: <GcmIssue width={13} height={13} style={iconTabStyle} />,
  };
  return alertIcons[priorityAlert.type] || <></>;
};

export const projectTabsByType = (
  projectProductType: ProjectProductTypes,
  projectProductTypeClassification: ProjectProductTypeClassification,
) => {
  if (projectProductType === ProjectProductTypes.GUARANTEE) {
    return [
      ProjectDetailsTab.BASE_INFORMATION,
      ProjectDetailsTab.SUPERVISION,
      ProjectDetailsTab.AGREEMENTS,
      ProjectDetailsTab.RISK,
    ];
  } else if (projectProductType === ProjectProductTypes.CT) {
    return [
      ProjectDetailsTab.BASE_INFORMATION,
      ProjectDetailsTab.FINANCIAL,
      ProjectDetailsTab.AGREEMENTS,
      ProjectDetailsTab.PRODUCTS,
    ];
  } else if (
    projectProductType === ProjectProductTypes.IG &&
    projectProductTypeClassification['code'] !== ProjectProductTypeClassification.IGR_GREATER_THAN_3MM
  ) {
    return [ProjectDetailsTab.BASE_INFORMATION, ProjectDetailsTab.AGREEMENTS, ProjectDetailsTab.FINANCIAL, ProjectDetailsTab.ACQUISITIONS];
  } else if (
    projectProductType === ProjectProductTypes.Loan ||
    (projectProductType === ProjectProductTypes.IG &&
      projectProductTypeClassification['code'] === ProjectProductTypeClassification.IGR_GREATER_THAN_3MM)
  ) {
    return [
      ProjectDetailsTab.BASE_INFORMATION,
      ProjectDetailsTab.FINANCIAL,
      ProjectDetailsTab.AGREEMENTS,
      ProjectDetailsTab.RISK,
      ProjectDetailsTab.EVOLUTION,
      // ProjectDetailsTab.PLANNING,
      ProjectDetailsTab.SUPERVISION,
      ProjectDetailsTab.ACQUISITIONS,
    ];
  }
  return [];
};

const getProjectTabs = (currentProject, t) => {
  const { code, id, projectProductType, projectProductTypeClassification, gcmAlerts } = currentProject;
  const tabsList: any[] = [];

  const tabOptions = {
    [ProjectDetailsTab.BASE_INFORMATION]: {
      id: 0,
      label: t('base_information'),
      panel: <BaseInformation />,
      icon: getAlertIcon(gcmAlerts, ProjectDetailsTab.BASE_INFORMATION),
    },
    [ProjectDetailsTab.PLANNING]: {
      id: 0,
      label: t('planning'),
      panel: <Planning />,
    },
    [ProjectDetailsTab.SUPERVISION]: {
      id: 0,
      label: t('supervision'),
      panel: <Supervision projectCode={code} projectId={id} />,
    },
    [ProjectDetailsTab.AGREEMENTS]: {
      id: 0,
      label: t('accordances'),
      panel: <AccordanceTrelloWithContext />,
    },
    [ProjectDetailsTab.FINANCIAL]: {
      id: 0,
      label: t('financial'),
      panel: <Financial />,
      icon: getAlertIcon(gcmAlerts, ProjectDetailsTab.FINANCIAL),
    },
    [ProjectDetailsTab.RISK]: {
      id: 0,
      label: t('risk'),
      panel: <Risks />,
    },
    [ProjectDetailsTab.PRODUCTS]: {
      id: 0,
      label: t('products'),
      panel: <Products />,
    },
    [ProjectDetailsTab.MANAGEMENT]: {
      // id: 0,
      // label: t('management'),
      // panel: <Management />,
    },
    [ProjectDetailsTab.EVOLUTION]: {
      id: 0,
      label: t('evolution'),
      panel: <Evolution />,
    },
    [ProjectDetailsTab.ACQUISITIONS]: {
      id: 0,
      label: t('last_adquisitions'),
      panel: <Acquisitions />
    },
  };

  const tabsByType = projectTabsByType(projectProductType, projectProductTypeClassification);

  for (const tab of tabsByType) {
    tabsList.push(tabOptions[tab]);
  }

  tabsList.forEach((tab, index) => {
    tab.id = index;
  });
  return tabsList;
};

const processQueryStrings = (queryStrings: any, dispatch: Function, setAccordance: Function, showModal: Function) => {
  if (queryStrings.accordance) {
    if (queryStrings.id) {
      const projectId = parseInt(queryStrings.id.toString());
      dispatch(getProject(projectId));
    }

    getAccordanceById(parseInt(queryStrings.accordance.toString())).then(accor => {
      setAccordance(accor);
      showModal();
    });
  }
};

export const LoginContext = React.createContext(() => { });
export const ProjectDetail = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const history = useHistory();
  const currentProject = useSelector<any, Project>(state => state.currentProject);
  const currentReview = useSelector<any, any>(state => state.currentReview);
  const reviewProjectsSelectedRows = useSelector<any, any>(state => state.reviewProjectsSelectedRows);
  const [showDetail, setShowDetail] = React.useState(false);
  const [accordance, setAccordance] = React.useState<any>({});
  const [showReject, setShowReject] = React.useState(false);
  const dispatch = useDispatch();
  const {
    projectProductType,
    execution,
    projectProductTypeClassification,
    operativeAlerts,
    disbursementsProjectionsEvolution = [],
    pmrEvolution = [],
  } = currentProject;

  const showModal = () => {
    setShowDetail(true);
  };

  const hideModal = () => {
    setShowDetail(false);
  };

  const refreshBoard = () => {
    dispatch(getProjectAccordances(getIdFromUrl(history.location.search)));
  };

  const showJustificationModal = () => {
    setShowReject(true);
  };

  const itemsList = getProjectTabs(currentProject, t);

  React.useEffect(() => {
    const queryStrings = queryString.parse(history.location.search);
    updateStorageRedirect(queryStrings);
    processQueryStrings(queryStrings, dispatch, setAccordance, showModal);
    window.scrollTo(0, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.search]);

  React.useEffect(() => {
    return () => {
      dispatch(resetCurrentProject());
      dispatch(setProjectTabValue(ProjectDetailsTab.BASE_INFORMATION));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderGeneralInformation = () => {
    if (
      showCorrespondingComponent(
        projectProductType,
        execution,
        projectProductTypeClassification &&
        projectProductTypeClassification['code'] === ProjectProductTypeClassification.IGR_GREATER_THAN_3MM,
      ) === ShowComponentEnum.LOAN_IGR_GREATER
    ) {
      return <GeneralInformation />;
    } else {
      return <GeneralInformationTC executedBy={execution} />;
    }
  };
  const ifProjectTypeForRenderEvolutionDetail = () => {
    return (
      projectProductType === ProjectProductTypes.Loan ||
      (projectProductTypeClassification &&
        projectProductTypeClassification['code'] === ProjectProductTypeClassification.IGR_GREATER_THAN_3MM)
    );
  };
  return (
    <>
      {projectAlreadySelected(currentProject, reviewProjectsSelectedRows, currentReview) && (
        <div className="project-detail-selected-for-revision-conatiner">{`${t(
          'project_selected_for_revision',
        )} ${replaceMomentFormat(moment(getMessageDate(currentProject, currentReview)).format('DD/MMM/YYYY'))}`}</div>
      )}
      {projectNotSelected(currentProject, reviewProjectsSelectedRows, location, currentReview) && (
        <SelectReviewProject projectId={currentProject.id} />
      )}
      <Header detailProject={true} />
      <div className="project-detail-conatiner">
        <ProjectDetailHeader />
        {isMobile() ? (
          <ProjectDetailMobile />
        ) : (
          <>
            {renderGeneralInformation()}
            <SwitchCalendarView />
            {/* {ifProjectTypeForRenderEvolutionDetail() && (
              <ProjectSwitch
                switchType="evolutionSwitchDetail"
                collapsedHeaderComponent={evolutionHeaderComponentByData(
                  pmrEvolution,
                  disbursementsProjectionsEvolution,
                  operativeAlerts,
                )}
                disbursementsProjectionsEvolution={disbursementsProjectionsEvolution}
                hasDataHeader={pmrEvolution.length !== 0}
                operativeAlerts={operativeAlerts}
              />
            )} */}
            <ProjectTabs
              // defaultTab={0}
              itemsList={itemsList}
              externalTabs={true}
              underline={true}
              customStyle={{ container: { marginBottom: 250 } }}
              showHistorical={true}
              fromProjectDetails={true}
            />
          </>
        )}
        {showDetail ? (
          <AccordanceDetail
            showJustificationModal={showJustificationModal}
            onApproveHandler={refreshBoard}
            isOpen={showDetail}
            closeModal={hideModal}
            accordance={accordance}
            changeAccordanceStatus={() => { }}
          />
        ) : null}
      </div>
      <AccordanceReject
        isOpen={showReject}
        closeModal={() => setShowReject(false)}
        accordanceId={accordance.id}
        onRejectHandler={refreshBoard}
      />
    </>
  );
};
const evolutionHeaderComponentByData = (pmrEvolution, disbursementsProjectionsEvolution, operativeAlerts) => {
  if (pmrEvolution.length) {
    return <PerformancePMRCollapsed data={pmrEvolution} />;
  } else if (disbursementsProjectionsEvolution.length) {
    return <DisbursementProjectionEvolution data={disbursementsProjectionsEvolution} typeHeader={true} />;
  } else if (operativeAlerts && operativeAlerts.length) {
    return <EarlyWarningHistory typeHeader={true} />;
  }
  return null;
};
