import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReportImageDisplay } from '../../../../../Util/Modals/CustomizableReports/CustomizableReports';
import { BidTooltipGrayAndWhite } from '../../../../../Util/Tooltip/Tooltip';
import styles from './PcrNextPresentationChartElement.module.scss';
const { itemClass, progress, textContainer, label, value, itemDetail, noData, container, zeroValueBullet } = styles;
type Props = {
  data: any[];
  isDetail?: boolean;
  forReport?: ReportImageDisplay;
};

const customFontStyle = forReport => (forReport && forReport === ReportImageDisplay.Detail ? { color: 'white' } : {});
const detailCondition = (isDetail, yes, no) => (isDetail ? yes : no);
const PcrNextPresentationChartElement = ({ isDetail, data, forReport }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={container} style={detailCondition(isDetail, { paddingTop: '50px' }, {})}>
      {data ? (
        data.map(item => (
          <div className={detailCondition(isDetail, itemDetail, itemClass)} key={item.label}>
            {!isDetail && (
              <div className={textContainer}>
                <p className={label} style={customFontStyle(forReport)}>
                  {t(item.label)}:
                </p>
                <p className={value} style={customFontStyle(forReport)}>
                  <b>{`${item.value} ${t(item.valueSuffix)}`}</b>
                </p>
              </div>
            )}
            <BidTooltipGrayAndWhite
              title={`${t(item.label)}: ${item.percentage}%`}
              placement="top"
              enterDelay={500}
              leaveDelay={200}
              arrow
            >
              <span
                className={progress}
                style={{
                  backgroundColor: item.color,
                  width: detailCondition(isDetail, `${item.percentage * 3}px`, `${item.percentage}%`),
                }}
              ></span>
            </BidTooltipGrayAndWhite>
            {isDetail && (
              <div className={textContainer}>
                {item.value === 0 && (
                  <p className={zeroValueBullet} style={{ color: item.color }}>
                    -
                  </p>
                )}
                <p className={label}>{` ${item.value} ${t(item.valueSuffix)}`}</p>
              </div>
            )}
          </div>
        ))
      ) : (
        <div className={noData}>-</div>
      )}
    </div>
  );
};

export default PcrNextPresentationChartElement;
