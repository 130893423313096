import { axiosDefaultConfig } from './axiosConfig';
import * as config from './config.json';
import Accordance from '../models/Accordance';
import { store } from '../redux/store';
import { UPDATE_CURRENT_PROJECT } from '../redux/actions/types';
import { buildPut } from './genericMethods';
import {
  setValueFilter,
  getReturnedParmasIndicator,
  addMenuTabFilter,
  getProductTypesFilterAndIgConfigFromStore,
} from './filterPortfolioAndProjectUtil';
import { setHaveFiltersInputsAndSelects } from './filterSharedUtil';
import { MenuTabSectionEnum } from '../components/MenuTabs/userTabsInterface';
import { ProjectProductTypes } from '../models/ProjectProductTypes';
import { ProjectExecutionTypes } from '../models/ProjectExecutionTypes';

const accordanceIdReplaceValue = '{accordanceId}';
const commentIdReplaceValue = '{commentId}';

export const basicGet = async (url: string, resolve: Function, reject: Function) => {
  try {
    const response = await axiosDefaultConfig.get(url);
    resolve(response.data);
  } catch (error) {
    reject(error);
  }
};

const basicPut = async (url: string, resolve: Function, reject: Function, data: any) => {
  try {
    const response = await axiosDefaultConfig.put(url, data);
    resolve(response);
  } catch (error) {
    reject(error);
  }
};

export const basicPost = async (url: string, resolve: Function, reject: Function, data: any) => {
  try {
    const response = await axiosDefaultConfig.post(url, data);
    resolve(response);
  } catch (error) {
    reject(error);
  }
};

export const getAccordances = (id: number, year = '', accordanceType = '') => {
  let url = `${config.ACCORDANCE}?projectId=${id}`;
  if (year) {
    url = `${url}&year=${year}`;
  }
  if (accordanceType) {
    url = `${url}&type=${accordanceType}`;
  }

  return new Promise<Accordance[]>(async (resolve, reject) => {
    basicGet(url, resolve, reject);
  });
};

export const getYears = (id: number) => {
  const url = `${config.ACCORDANCE_YEARS}?projectId=${id}`;
  return new Promise<number[]>(async (resolve, reject) => {
    basicGet(url, resolve, reject);
  });
};

export const updateAccordance = (data: any) => {

  const url = config.ACCORDANCE_ID.replace('{id}', data.accordanceId.toString());

  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosDefaultConfig.put(url, data);
      if (response && response.data) {
        const currentProject = { ...store.getState().currentProject };
        const newLanes = currentProject.accordancesData.lanes.map(lane => {
          lane.cards.map(card => {
            if (card.accordance.id === response.data.id) {
              card.accordance = { ...response.data };
            }
            return card;
          });
          return lane;
        });
        store.dispatch({
          type: UPDATE_CURRENT_PROJECT,
          payload: { ...currentProject, ...{ accordancesData: { lanes: newLanes } } },
        });
        resolve();
      } else {
        reject('error');
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const getAccordanceType = () => {
  const url = config.ACCORDANCE_ACCORDANCETYPE;
  return new Promise<any[]>(async (resolve, reject) => {
    basicGet(url, resolve, reject);
  })
}

export const getRootCauseCategories= () => {
  const url = config.ACCORDANCE_ROOTCAUSECATEGORIES;
  return new Promise<any[]>(async (resolve, reject) => {
    basicGet(url, resolve, reject);
  })
}

export const getCategories = (projectProductType?: ProjectProductTypes, execution?: ProjectExecutionTypes, id?:number) => {
  // const projectProductTypeParam =
  //   projectProductType !== undefined
  //     ? `?projectProductType=${projectProductType.toString()}&projectExecutorEntity=${execution?.toString()}`
  //     : `/${id}`;
  const url = `${config.ACCORDANCE_CATEGORIES}/${id}`;
  return new Promise<any[]>(async (resolve, reject) => {
    basicGet(url, resolve, reject);
  });
};

export const newAccordance = (data: any) => {
  const url = config.ACCORDANCE;
  return new Promise(async (resolve, reject) => {
    basicPost(url, resolve, reject, data);
  });
};

export const cancelAccordance = (accordanceId: number, justification: string) => {
  const url = config.ACCORDANCE_CANCEL.replace(accordanceIdReplaceValue, accordanceId.toString());
  const data = { justification };
  return new Promise(async (resolve, reject) => {
    basicPut(url, resolve, reject, data);
  });
};

export const approveAccordance = (accordanceId: number) => {
  const url = config.ACCORDANCE_APPROVE.replace(accordanceIdReplaceValue, accordanceId.toString());

  return new Promise(async (resolve, reject) => {
    basicPut(url, resolve, reject, null);
  });
};

export const rejectAccordance = (accordanceId: number, justification: string) => {
  const url = config.ACCORDANCE_REJECT.replace(accordanceIdReplaceValue, accordanceId.toString());
  const data = { justification };
  return new Promise(async (resolve, reject) => {
    basicPut(url, resolve, reject, data);
  });
};

export const completeAccordance = (accordanceId: number) => {
  const url = config.ACCORDANCE_COMPLETE.replace(accordanceIdReplaceValue, accordanceId.toString());
  return new Promise(async (resolve, reject) => {
    basicPut(url, resolve, reject, null);
  });
};

export const newAccordanceComment = (accordanceId: number, comment: string) => {

  const url = config.ACCORDANCE_COMMENTS.replace(accordanceIdReplaceValue, accordanceId.toString());
  return new Promise<any>(async (resolve, reject) => {
    try {
      const response = await axiosDefaultConfig.post(url, { comment });
      if (response && response.data) {
        const currentProject = { ...store.getState().currentProject };
        const newLanes = currentProject.accordancesData.lanes.map(lane => {
          lane.cards.map(card => {
            if (card.accordance.id === accordanceId) {
              card.accordance.accordanceComments = [...card.accordance.accordanceComments, response.data];
            }
            return card;
          });
          return lane;
        });
        store.dispatch({
          type: UPDATE_CURRENT_PROJECT,
          payload: { ...currentProject, ...{ accordancesData: { lanes: newLanes } } },
        });

        resolve(response.data);
      } else {
        reject('error');
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const setLastCommentRead = (accordanceId: number) => {
  const url = config.ACCORDANCE_SET_LAST_COMMENT_READ.replace(accordanceIdReplaceValue, accordanceId.toString());
  return new Promise<any>(async (resolve, reject) => {
    try {
      await axiosDefaultConfig.put(url);

      const currentProject = { ...store.getState().currentProject };
      const newLanes = currentProject.accordancesData.lanes.map(lane => {
        lane.cards.map(card => {
          if (card.accordance.id === accordanceId) {
            card.accordance.hasUnreadComments = false;
          }
          return card;
        });
        return lane;
      });
      store.dispatch({
        type: UPDATE_CURRENT_PROJECT,
        payload: { ...currentProject, ...{ accordancesData: { lanes: newLanes } } },
      });
      resolve();
    } catch (error) {
      reject();
    }
  });
};

export const deleteAccordanceComment = (accordanceId: number, commentId: number) => {
  const url = config.ACCORDANCE_COMMENT_DELETE.replace(commentIdReplaceValue, commentId.toString());
  return new Promise<any>(async (resolve, reject) => {
    try {
      await axiosDefaultConfig.put(url);
      const currentProject = { ...store.getState().currentProject };
      const newLanes = currentProject.accordancesData.lanes.map(lane => {
        lane.cards.map(card => {
          if (card.accordance.id === accordanceId) {
            card.accordance.accordanceComments = card.accordance.accordanceComments.map(item => {
              if (item.id === commentId) {
                item.isDeleted = true;
                item.comment = '';
              }
              return item;
            });
          }
          return card;
        });
        return lane;
      });
      store.dispatch({
        type: UPDATE_CURRENT_PROJECT,
        payload: { ...currentProject, ...{ accordancesData: { lanes: newLanes } } },
      });
      resolve();
    } catch (error) {
      reject();
    }
  });
};

export const getAccordanceById = (eventId: number) => {
  let url = '';
  url = config.ACCORDANCE_ID.replace('{id}', eventId.toString());
  return new Promise<Accordance>(async (resolve, reject) => {
    basicGet(url, resolve, reject);
  });
};

export const deleteAccordance = (accordanceId: number) => {
  const url = config.ACCORDANCE_ID.replace('{id}', accordanceId.toString());
  return new Promise<Accordance>(async (resolve, reject) => {
    try {
      await axiosDefaultConfig.delete(url);
      const currentProject = { ...store.getState().currentProject };
      const newLanes = currentProject.accordancesData.lanes.map(lane => {
        lane.cards = lane.cards.filter(card => card.accordance.id !== accordanceId);
        return lane;
      });
      store.dispatch({
        type: UPDATE_CURRENT_PROJECT,
        payload: { ...currentProject, ...{ accordancesData: { lanes: newLanes } } },
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * Used by indicator key  USER_PENDING_TASKS
 */
export const getPendingTasks = () => {
  const { returnedParams } = getIndicatorAccordanceFilterSetup();
  return new Promise<any[]>(async (resolve, reject) => {
    const url = config.ACCORDANCE_PENDING_TASKS;
    try {
      const response = await axiosDefaultConfig.post(url, returnedParams);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * Used by indicator key ACCORDANCES_WAITING_APPROVAL ( Acuerdos pendientes de aprobación )
 *                       ACCORDANCES_EXPIRING ( Acuerdos próximos a vencer y vencidos )
 *
 */
export const getIndicatorAccordance = pending => {
  const { returnedParams } = getIndicatorAccordanceFilterSetup(pending);
  return new Promise<Accordance[]>(async (resolve, reject) => {
    const url = config.ACCORDANCE_FILTER;
    try {
      const response = await axiosDefaultConfig.post(url, returnedParams);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * Used by indicator key ACCORDANCES_WAITING_APPROVAL ( Acuerdos pendientes de aprobación )
 *                       ACCORDANCES_EXPIRING ( Acuerdos próximos a vencer y vencidos )
 *                       USER_PENDING_TASKS
 *
 */
const getIndicatorAccordanceFilterSetup = (pending = null) => {
  const filterIndicators = store.getState().filterIndicators;
  const regionsFilter = setValueFilter(true, 'regionId');
  const haveCountriesFilter = setHaveFiltersInputsAndSelects('country', true);
  const haveDivisionFilter = setHaveFiltersInputsAndSelects('division', true);
  const havePendingDisbursement = setHaveFiltersInputsAndSelects('disbursementProjection', true);
  const haveLastDisbursement = setHaveFiltersInputsAndSelects('timeWithoutDisbursement', true);
  const haveAgeOfProject = setHaveFiltersInputsAndSelects('ageProject', true);
  const havePmrClassification = filterIndicators.common.pmrId.filter(pmr => pmr.selected === true);
  const haveTaxonomy = filterIndicators.common.taxonomyFilter && filterIndicators.common.taxonomyFilter.id;
  const haveExecution = filterIndicators.common.executionFilter && filterIndicators.common.executionFilter.id;
  const haveExcludeRegionales = filterIndicators.common.excludeRegionals;
  const { projectProductAmounts, projectProductTypes } = getProductTypesFilterAndIgConfigFromStore();
  let returnedParams = {};
  if (pending !== null) {
    returnedParams = {
      pageNumber: 1,
      itemsPerPage: 1000000,
      sortParameter: 0,
      pendingApproval: pending,
      expiring: !pending,
      Filters: [],
      orderDesc: true,
      regions: regionsFilter.length > 0 ? regionsFilter : [],
      projectProductTypes,
      projectProductAmounts,
    };
  } else {
    returnedParams = {
      pageNumber: 1,
      itemsPerPage: 1000000,
      sortParameter: 0,
      Filters: [],
      orderDesc: true,
      regions: regionsFilter.length > 0 ? regionsFilter : [],
      projectProductTypes,
      projectProductAmounts,
    };
  }

  const returnedParamsFilters = {
    haveCountriesFilter,
    haveDivisionFilter,
    havePendingDisbursement,
    haveLastDisbursement,
    haveAgeOfProject,
    havePmrClassification,
    haveTaxonomy,
    haveExecution,
    haveExcludeRegionales,
  };

  returnedParams = getReturnedParmasIndicator(returnedParamsFilters, returnedParams);
  returnedParams = addMenuTabFilter(returnedParams, MenuTabSectionEnum.PORTFOLIO_MENU_TAB);
  return { returnedParams, returnedParamsFilters, filterIndicators };
};

export const draftToApproval = (accordanceId: number) => {
  const url = config.ACCORDANCE_DRAFT_TO_APPROVAL.replace('{accordanceId}', accordanceId.toString());

  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosDefaultConfig.put(url);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const approveAccordancesList = (accordanceIds: number[]) => {
  return new Promise(async (resolve, reject) => {
    basicPut(config.ACCORDANCE_BULK_APPORVE, resolve, reject, { accordanceIds });
  });
};

export const newBundleAccordances = (data: any) => {
  const url = config.ACCORDANCE_BUNDLE;
  return new Promise<Accordance[]>(async (resolve, reject) => {
    basicPost(url, resolve, reject, data);
  });
};
interface IUpdateBundleAccordances {
  bundleId: string;
  accordances: any[];
}
export const updateBundleAccordances = (data: IUpdateBundleAccordances) => {
  const url = config.ACCORDANCE_BUNDLE;
  return buildPut(url, data);
};
interface IPublishBundleAccordances {
  accordanceIds: number[];
}
export const publishBundleAccordances = (data: IPublishBundleAccordances) => {
  const url = config.ACCORDANCE_BUNDLE_PUBLISH;
  return buildPut(url, data);
};
