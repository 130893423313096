import React from 'react';
import './CustomizableProjectAgeFilter.scss';
import { useTranslation } from 'react-i18next';
import { renderProjectAgeFilter } from '../../../../Filter/ProjectAgeFilter/ProjectAgeFilter';
import FilterIndicators from '../../../../../models/FilterIndicators';
import SelectOption from '../../../../../models/SelectOption';

type Props = {
  reportFilters: FilterIndicators;
  reportFiltersChanged: Function;
};

export const setProjectMonth = selectId => {
  if (selectId === 1) {
    return true;
  } else {
    return false;
  }
};

export const CustomizableProjectAgeFilter = ({ reportFilters, reportFiltersChanged }: Props) => {
  const { t } = useTranslation();
  const [localFilter, setLocalFilter] = React.useState(reportFilters);
  const [errorMessages, handleErrorMessages] = React.useState({ isHidden: true, message: '' });
  const [from, setFrom] = React.useState(reportFilters.common.timeWithoutDisbursementFrom);
  const [to, setTo] = React.useState(reportFilters.common.timeWithoutDisbursementTo);

  const defaultMaxValue = 0;
  const defaultMinValue = 0;
  const defaultValueFrom = from !== defaultMinValue ? from : 0;
  const defaultValueTo = to !== defaultMinValue ? to : 0;
  const ageProjectRange = localFilter.common?.ageProjectMonths
    ? { value: 'months', label: 'months', id: 1 }
    : { value: 'years', label: 'years', id: 2 };

  const handleValue = (min, value) => {
    let ageProjectFrom = from;
    let ageProjectTo = to;

    if (min) {
      setFrom(+value);
      ageProjectFrom = +value;
    } else {
      setTo(+value);
      ageProjectTo = +value;
    }

    if (ageProjectFrom >= ageProjectTo && ageProjectTo !== 0) {
      handleErrorMessages({ isHidden: false, message: 'right_input_validation' });
    } else {
      handleErrorMessages({ isHidden: true, message: '' });
      if (ageProjectTo === defaultMinValue) {
        ageProjectTo = defaultMaxValue;
        setTo(defaultMaxValue);
      }

      const newFilter = {
        ...localFilter,
        common: {
          ...localFilter.common,
          ageProjectFrom,
          ageProjectTo,
        },
      };
      setLocalFilter(newFilter);
      reportFiltersChanged({
        ageProjectFrom: newFilter.common.ageProjectFrom,
        ageProjectTo: newFilter.common.ageProjectTo,
      });
    }
  };

  const handleSelectedAge = (item: SelectOption) => {
    const ageProjectMonths = setProjectMonth(item.id);

    const newFilter = {
      ...localFilter,
      common: {
        ...localFilter.common,
        ageProjectMonths,
      },
    };
    setLocalFilter(newFilter);
    reportFiltersChanged({ ageProjectMonths: newFilter.common.ageProjectMonths });
  };

  return renderProjectAgeFilter({
    ageProjectRange,
    defaultValueFrom,
    defaultValueTo,
    errorMessages,
    handleSelectedAge,
    handleValue,
    t,
  });
};
