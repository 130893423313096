import React, { useEffect, useState } from 'react';
import './ScheduleWarning.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CloseCrossButton from '../../Buttons/CloseCrossButton/CloseCrossButton';
import { styles } from '../Styles';
import { disableEscKey } from '../Util';
import { ReactComponent as ModalAlert } from '../../../../assets/alerta-modal.svg';
import { getDaysToAdd, getNumberOfProjectsToRemove, exceededProjectCapacity } from '../../../Reviews/Agenda/util';
import EditReview from './EditReview';
import BaseModal from '../BaseModal';

const durations = [45, 60, 75, 90];

export enum EditType {
  None = 0,
  Dates,
  Projects,
}

type Props = {
  isOpen: boolean;
  closeModal: any;
  timeAllocated: number;
  reSchedule: any;
};

const checkLowerDurations = (currentReview, timeAllocated) => {
  if (durations[timeAllocated] === 0) {
    return 0;
  }
  for (let i = durations.indexOf(timeAllocated); i >= 0; i--) {
    if (!exceededProjectCapacity(currentReview, durations[i])) {
      return durations[i];
    }
  }
  return 0;
};

const contentStyle = editType => {
  if (editType === EditType.Projects) {
    return 'projects';
  }
  if (editType === EditType.Dates) {
    return 'dates';
  }
  return '';
};

const ScheduleWarning = ({ isOpen, closeModal, timeAllocated, reSchedule }: Props) => {
  const { t } = useTranslation();
  const currentReview = useSelector<any, any>(state => state.currentReview);
  const [daysToAdd, setDaysToAdd] = useState(0);
  const [projectsToRemove, setProjectsToRemove] = useState(0);
  const [correctDuration, setCorrectDuration] = useState(0);
  const [editType, setEditType] = useState(EditType.None);

  useEffect(() => {
    setDaysToAdd(getDaysToAdd(currentReview, timeAllocated));
    setProjectsToRemove(getNumberOfProjectsToRemove(currentReview, timeAllocated));
    setCorrectDuration(checkLowerDurations(currentReview, timeAllocated));
    setEditType(EditType.None);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <BaseModal
      isOpen={isOpen}
      onRequestClose={e => disableEscKey(e, () => closeModal())}
      style={styles}
      className={`modal-schedule-warning-content ${contentStyle(editType)}`}
    >
      <div className="modal-schedule-warning-container">
        <div className="close-cross-container">
          <CloseCrossButton
            handleClick={() => {
              closeModal();
            }}
          />
        </div>
        {editType === EditType.None ? (
          <>
            <div className="horizontal-center">
              <ModalAlert />
              <h2>{t('cannot_assign_schedule')}</h2>
              <span className="sub-title">{t('too_many_projects')}</span>
              <span className="sub-title small">{t('too_fix_choose')}</span>
            </div>
            <div className="option-row">
              <span className="icon-schedule"></span>
              <span className="clickable-link" onClick={() => setEditType(EditType.Dates)}>
                <span className="underline">{`${t('add')} ${daysToAdd} ${t(daysToAdd > 1 ? 'days' : 'day')} `}</span>
                <span className="text">{t('to_the_review')}</span>
              </span>
            </div>
            <div className="option-row">
              <span className="icon-list"></span>
              <span className="clickable-link" onClick={() => setEditType(EditType.Projects)}>
                <span className="underline">{`${t('remove_action')} ${projectsToRemove} ${t(
                  projectsToRemove > 1 ? 'projects' : 'project',
                )} `}</span>
                <span className="text">{t('from_the_list')}</span>
              </span>
            </div>
            {correctDuration !== 0 ? (
              <div className="option-row">
                <span className="icon-clock"></span>
                <span className="clickable-link" onClick={() => reSchedule(correctDuration)}>
                  <span className="underline">{`${t('decrease_to')} ${correctDuration}min ${t('the_duration')} `}</span>
                  <span className="text">{t('of_the_meetings')}</span>
                </span>
              </div>
            ) : null}
          </>
        ) : (
          <EditReview type={editType} handleSubmit={() => reSchedule()} handleCancel={() => closeModal()} />
        )}
      </div>
    </BaseModal>
  );
};

export default ScheduleWarning;
