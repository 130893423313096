/* eslint-disable array-callback-return */
import React from 'react';
import { useSelector } from 'react-redux';
import { viewPortWatcher, handleCardsOnPaginate, NavigationCardsEnum, swipeCards } from './CardsProjectListUtil';
import { CardProject } from '../CardProject/CardProject';
import WindowSizeListener from 'react-window-size-listener';
import Project from '../../../models/Project';
import { getFollowedProjectsFromApi, getFollowUnfollowProjectFromApi } from '../../../redux/actions/followedProjects';
import { CardProjectSkeletonList } from '../../LoadersSkeletons/CardProjectSkeletonList/CardProjectSkeletonList';
import { Pagination } from '@material-ui/lab';
import '../../Util/Pagination/Pagination.scss';
import { Swipeable } from 'react-swipeable';
import useFilters from '../../../hooks/useFilters';

interface IProps {
  itsSearch: boolean;
}
type PropsProjectLists = IProps;

const CardsProjectList = (props: PropsProjectLists) => {
  const followedProjects = useSelector<any, Project[]>(state => state.followedProjects);
  const { itsSearch } = props;
  const { filter, dispatch, setFilter } = useFilters();
  const [viewportSize, setViewportSize] = React.useState<any>(window.innerWidth);
  const projectsPerPage = filter.common.started ? filter.followedProjectsPagination.pageSize : 1;
  const initialPage = filter.followedProjectsPagination?.currentPage;
  const numberOfPages = filter.followedProjectsPagination?.pageCount;
  const searchValue = filter.followedProjectsFilter.searchValue;
  const haveProjectsFollowed = filter.followedProjectsPagination.rowCount;
  const [navigationCards, setNavigationCards] = React.useState(NavigationCardsEnum.onePage);

  const handleChange = (event, value) => {
    handleCardsOnPaginate(value, numberOfPages, setNavigationCards);
    paginate(value);
  };

  React.useEffect(() => {
    setNavigationCards(numberOfPages > 1 ? NavigationCardsEnum.first : NavigationCardsEnum.onePage);
  }, [numberOfPages]);

  const paginate = (pageNumber: any) => {
    dispatch(
      setFilter({
        ...filter,
        followedProjectsPagination: {
          ...filter.followedProjectsPagination,
          currentPage: pageNumber,
        },
      }),
    );
    dispatch(getFollowedProjectsFromApi());
  };
  const handleFilterPageSize = (pageSize: number) => {
    dispatch(
      setFilter({
        ...filter,

        followedProjectsPagination: {
          ...filter.followedProjectsPagination,
          pageSize,
        },
      }),
    );
  };
  React.useEffect(() => {
    const filterPerPageResponse = viewPortWatcher(viewportSize, projectsPerPage);
    handleFilterPageSize(filterPerPageResponse);
    if (itsSearch) {
      paginate(1);
    } else {
      dispatch(getFollowedProjectsFromApi());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewportSize, searchValue, filter.common]);

  const setInFollow = (projectId: number) => {
    dispatch(getFollowUnfollowProjectFromApi(projectId, true));
    if (followedProjects.length === 1 && initialPage !== 1) {
      paginate(initialPage - 1);
    }
  };

  const handleSwipeRight = () => swipeCards(setNavigationCards, true, initialPage, numberOfPages, paginate);
  const handleSwipeLeft = () => swipeCards(setNavigationCards, false, initialPage, numberOfPages, paginate);

  return (
    <WindowSizeListener
      onResize={windowSize => {
        setViewportSize(windowSize.windowWidth);
      }}
    >
      <div className="following-container-cards">
        <Swipeable onSwipedRight={handleSwipeRight} onSwipedLeft={handleSwipeLeft}>
          <div className="card-container">
            {followedProjects.length !== 0 ? (
              followedProjects.map((project: Project) => (
                <CardProject
                  key={project.id}
                  project={project}
                  setInFollow={setInFollow}
                  navigationCards={navigationCards}
                />
              ))
            ) : haveProjectsFollowed === -1 ? (
              <CardProjectSkeletonList viewportSize={viewportSize} />
            ) : null}
          </div>
        </Swipeable>

        <div className="pagination-style">
          {numberOfPages > 1 ? (
            <Pagination count={numberOfPages} page={initialPage} onChange={handleChange} boundaryCount={1} />
          ) : null}
        </div>
      </div>
    </WindowSizeListener>
  );
};

export default CardsProjectList;
