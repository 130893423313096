import { useSelector, useDispatch } from 'react-redux';
import FilterProjects from '../models/FilterProjects';
import FilterIndicators from '../models/FilterIndicators';
import { setFilter } from '../redux/actions/filter';
import { setFilterIndicators } from '../redux/actions/filterIndicators';
import { setFilterAnalytics } from '../redux/actions/filterAnalytics';
import { setFilterEarlyWarnings } from '../redux/actions/filterEarlyWarnings';
import FilterEarlyWarnings from '../models/FilterEarlyWarnings';

const useFilters = () => {
  const dispatch = useDispatch();
  const filter = useSelector<any, FilterProjects>(state => state.filter);
  const filterIndicators = useSelector<any, FilterIndicators>(state => state.filterIndicators);
  const filterAnalytics = useSelector<any, any>(state => state.filterAnalytics);
  const filterEarlyWarnings = useSelector<any, FilterEarlyWarnings>(state => state.filterEarlyWarnings);

  return {
    dispatch,
    filter,
    filterIndicators,
    filterAnalytics,
    filterEarlyWarnings,
    setFilter,
    setFilterAnalytics,
    setFilterIndicators,
    setFilterEarlyWarnings,
  };
};

export default useFilters;
