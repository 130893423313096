export const orderList = [
  { langKey: 'last_add_recommende_1' },
  { langKey: 'last_add_recommende_2' },
  { langKey: 'last_add_recommende_3' },
  { langKey: 'last_add_recommende_4' },
];


export const buttonText = (editMode: boolean) => {
  return editMode ? 'save' : 'edit_recommended';
};
