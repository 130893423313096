import React from 'react';
import './Maintenance.scss';
import { useHistory } from 'react-router-dom';

import { ReactComponent as MaintenanceImage } from './../../assets/maintenance_image.svg';
import { ReactComponent as LogoCartera } from './../../assets/logo-cartera.svg';
import { checkMaintenance } from '../../api/userApi';

const translations = [
  {
    id: 'es',
    logo: 'Cartera Inteligente',
    title: 'En estos momentos estamos mejorando el sitio',
    subtitle: 'Lamentamos los inconvenientes, Cartera Inteligente estará disponible en breve.',
  },
  {
    id: 'en',
    logo: 'Smart Portfolio',
    title: 'We are currently improving the site',
    subtitle: 'Sorry for the inconvenience, Smart Portfolio will be available shortly.',
  },
  {
    id: 'fr',
    logo: 'Portefeuille intelligent',
    title: 'Nous améliorons actuellement le site',
    subtitle: 'Désolé pour le désagrément, Smart Portfolio sera bientôt disponible.',
  },
  {
    id: 'pt',
    logo: 'Carteira Inteligente',
    title: 'Atualmente estamos melhorando o site',
    subtitle: 'Desculpe o transtorno, a Smart Portfolio estará disponível em breve.',
  },
];

const Maintenance = () => {
  const [translation, setTranslation] = React.useState<any>(translations[1]);
  const history = useHistory();

  const checkMaintenanceRedirect = async () => {
    try {
      const result = await checkMaintenance();
      if (!result) {
        history.push('/home');
      }
    } catch (err) { }
  };

  React.useEffect(() => {
    const language = localStorage.getItem('language') || navigator.language.split('-')[0];
    const translationFound = language ? translations.find(translation => translation.id === language) : null;
    if (translationFound) {
      setTranslation(translationFound);
    }
    checkMaintenanceRedirect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="maintenance">
        <div className="maintenance__sidebar" />
        <div className="maintenance__layout">
          <div className="logo-maintenance">
            <LogoCartera className="logo" />
            <div className="text-container">
              <h3>{translation.logo}</h3>
            </div>
          </div>
          <div className="maintenance__content">
            <div className="maintenance__image">
              <MaintenanceImage />
            </div>
            <div className="maintenance__title">{translation.title}</div>
            <div className="maintenance__subtitle">{translation.subtitle}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Maintenance;
