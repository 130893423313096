import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LinkText from '../../../../Util/Buttons/LinkText/LinkText';
import LabelData from '../../../../Util/LabelData/LabelData';
import { emptyValue } from '../constants';

type Props = {
  eventData: any;
  createAccordanceReminder: any;
  isCalendarIndicator?: boolean;
};
export const LifecycleEventDetail = ({ eventData, createAccordanceReminder, isCalendarIndicator }: Props) => {
  const { t } = useTranslation();
  if (!eventData) {
    eventData = {
      project: { code: emptyValue, name: emptyValue },
      typeDescription: emptyValue,
      startDate: emptyValue,
    };
  }
  const { project, typeDescription, startDate } = eventData;
  return (
    <>
      <LabelData label={t('code')} data={`${project.code} ${project.name}`} reverse={true} />
      <LabelData label={t('description')} data={typeDescription} reverse={true} />
      <div style={{ display: 'flex' }}>
        <LabelData label={t('start_date')} data={moment(startDate).format('DD/MM/YYYY')} reverse={true} />
        <LinkText
          label={t('create_reminder')}
          onClick={createAccordanceReminder}
          customStyle={{ margin: '0px 0px 3px 10px' }}
        />
      </div>
    </>
  );
};
