import { BEMenuTabsResponse, MenuTabs, MenuTabSectionEnum } from '../../components/MenuTabs/userTabsInterface';
import {
  SET_MENU_TABS,
  UPDATE_CREATION_EDITION_VALUE,
  UPDATE_MANAGERIAL_REVIEW_SELECTED_TAB,
  UPDATE_MENUTABS_FIRST_LOAD,
  UPDATE_PORTFOLIO_SELECTED_TAB,
  UPDATE_PROJECT_SELECTED_TAB,
  UPDATE_EARLY_WARNINGS_SELECTED_TAB,
} from './types';

export function setMenuTabs(menuTabs: BEMenuTabsResponse, defaultTabId: number | null = null) {
  if (defaultTabId) {
    menuTabs.userTabs = menuTabs.userTabs.map(ut => {
      ut.default = false;
      if (ut.tabId === defaultTabId) {
        ut.default = true;
      }
      return ut;
    });
  }

  return {
    type: SET_MENU_TABS,
    payload: menuTabs,
  };
}

export function updateSelectedTab(menuTabs: MenuTabs, menuTabSection: MenuTabSectionEnum) {
  let type;
  switch (menuTabSection) {
    case MenuTabSectionEnum.PORTFOLIO_MENU_TAB:
      type = UPDATE_PORTFOLIO_SELECTED_TAB;
      break;
    case MenuTabSectionEnum.PROJECT_MENU_TAB:
      type = UPDATE_PROJECT_SELECTED_TAB;
      break;
    case MenuTabSectionEnum.EARLY_WARNINGS_TAB:
      type = UPDATE_EARLY_WARNINGS_SELECTED_TAB;
      break;
    case MenuTabSectionEnum.MANAGERIAL_REVIEW_TAB:
      type = UPDATE_MANAGERIAL_REVIEW_SELECTED_TAB;
      break;

    default:
      type = UPDATE_PORTFOLIO_SELECTED_TAB;
      break;
  }

  return {
    type,
    payload: menuTabs,
  };
}

export function updateCreatingOrEditing(creatingOrEditing: boolean) {
  return {
    type: UPDATE_CREATION_EDITION_VALUE,
    payload: creatingOrEditing,
  };
}

export function updateMenuTabsFirstLoad(payload: boolean) {
  return {
    type: UPDATE_MENUTABS_FIRST_LOAD,
    payload,
  };
}
