/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { ReactComponent as IconMemoryHelp } from '../../../../assets/icons/u_ayudamemoria.svg';
import { ReactComponent as IconArrowRight } from '../../../../assets/icons/u_arrow-right.svg';
import '../../../MemoryHelp/MemoryHelp.scss';
import ModalIconInfo from './InfoMemoryHelpsButtons';
import { useFormMemoryHelpContext } from '../../../../context/MemoryHelpFormContext';
import { MemoryHelpRevisionStatus } from '../../../MemoryHelp/MemoryHelpRevisionStatus';
import { useTranslation } from 'react-i18next';
import { dateValidation, extractDate } from '../../../MemoryHelp/utils';

const MemoryHelpButtons = ({ localRouter, visibleComponent }) => {
  const { t } = useTranslation();
  const { agreementsData, showConfirmContent, userCanEditRevision, triggerGetFiles, setTrigger } = useFormMemoryHelpContext();

  const [showButton, setShowButton] = useState(false);
  const [startDateFormatted, setStartDateFormatted] = useState<string | null>(null);
  const [isEqual, setIsEqual] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  useEffect(() => setTrigger(prev => !prev), [])

  // Update local state when agreementsData is available
  useEffect(() => {
    if (agreementsData) {
      if (agreementsData.status === MemoryHelpRevisionStatus.Denied) {
        setIsDisabled(true);
        setShowButton(false);
      } else {
        setIsDisabled(false);
      }

      if (agreementsData.dataRevision) {
        const formattedDate = extractDate(agreementsData.dataRevision.startDate);
        setStartDateFormatted(formattedDate);

        const currentDay = new Date().toLocaleDateString('en-GB');
        if (formattedDate) {
          const isDateEqual = dateValidation(currentDay, formattedDate);
          setIsEqual(isDateEqual);
        }
      } else {
        setStartDateFormatted(null);
        setIsEqual(false);
      }
    }
  }, [agreementsData]);

  // Update showButton state based on isEqual
  useEffect(() => setShowButton(isEqual), [isEqual]);

  const onClickHandle = () => {
    visibleComponent(true);
    triggerGetFiles()
    if (showConfirmContent) {
      localRouter('memoryHelpViewer');
    } else {
      localRouter('memoryHelpPage');
    }
  };

  return (
    <div>
      {showButton ? (
        <button className='btn btn-primary' onClick={onClickHandle} disabled={isDisabled}>
          <IconMemoryHelp />
          {
            showButton && showConfirmContent && userCanEditRevision
              ? t('memory_help.page_reviews.btn.see')
              : t('memory_help.page_reviews.btn.edit')
          } <IconArrowRight />
        </button>
      ) : (
        <div className='date-memory-help'>
          <p className='btn btn-primary-text date-text'>
            {isDisabled ? "" : (
              <>
                {t('memory_help.button_memory_help_from')} <span>{startDateFormatted}</span> <ModalIconInfo />
              </>
            )}
          </p>
        </div>
      )}
    </div>
  );
};

export default MemoryHelpButtons;