import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getProjectKeyProducts } from '../../../../redux/actions/planification';
import KeyProductAccordeon from './KeyProductAccordeon/KeyProductAccordeon';
import { KeyProductHeader, NoData } from './KeyProductHeader/KeyProductHeader';
import Project from '../../../../models/Project';
import { RequestStatus } from '../../../../models/RequestStatus';
import { AddKeyProduct } from './AddKeyProduct/AddKeyProduct';
import { isAuthorizedToEdit } from '../../../../services/booleanUtil';
import User from '../../../../models/User';

const Planning = () => {
  const currentProject = useSelector<any, Project>(state => state.currentProject);
  const user = useSelector<any, User>(state => state.user);
  const requestStatus = useSelector<any, any>(state => state.requestStatus);
  const { keyProducts } = currentProject;
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getProjectKeyProducts(currentProject.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <KeyProductHeader />
      {keyProducts && keyProducts.length ? (
        <>
          <KeyProductAccordeon keyProducts={keyProducts} />
        </>
      ) : requestStatus === RequestStatus.Loading ? null : (
        <NoData />
      )}
      {isAuthorizedToEdit(user, currentProject) && <AddKeyProduct currentProject={currentProject} />}
    </div>
  );
};

export default Planning;
