import Disbursement from '../../../../models/Disbursement';

import { cloneDeep } from 'lodash';
import { thousandsSeparator, isRubik } from '../../../../services/stringUtil';
import { getMonthLabels } from '../../../Portfolio/IndicatorsHelpers/indicatorHelperUtil';

export const getCurrentYearList = (list: Disbursement[]) => {
  return list.filter(disbursement => new Date(disbursement.transactionDate).getFullYear() === new Date().getFullYear());
};

export const getMonthValueList = (currentYearList: any[], onlyDisbursements: boolean) => {
  return currentYearList.map(disbursement => {
    const month = new Date(disbursement.transactionDate).getMonth(); // 0 = january
    return {
      x: month,
      y: onlyDisbursements
        ? disbursement.disbursedAmountUseq
        : disbursement.isProjection
          ? disbursement.actualProjectionAmountUseq
          : disbursement.disbursedAmountUseq,
    };
  });
};

export const getGroupedValueList = monthValueList => {
  const helper = {};
  return monthValueList
    .reduce(function (r: any[], o) {
      const key = o.x;

      if (!helper[key]) {
        helper[key] = Object.assign({}, o); // create a copy of o
        r.push(helper[key]);
      } else {
        helper[key].y += o.y;
      }
      return r;
    }, [])
    .sort(compareXvalues)
    .map(item => {
      return {
        month: item.x,
        y: item.y,
      };
    });
};

const getEveryMonthList = labelsTexts => {
  const list: any[] = [];

  for (let i = 0; i < 12; i++) {
    list.push({ month: i, x: labelsTexts[i], y: 0 });
  }
  return list;
};

const getChartData = (everyMonthList, groupedValuesList) => {
  return everyMonthList.map(item => {
    const foundItem = groupedValuesList.find(element => element.month === item.month);
    if (foundItem) {
      item.y = foundItem.y;
    }
    item.y = item.y.toFixed(0);
    return item;
  });
};

const getColorsList = (chartData, currentMonth, onlyDisbursements: boolean) => {
  const pastPresentColor = '#538CFF';
  const futureColor = '#E7EDF3';
  return chartData.map(disbursement => {
    return onlyDisbursements ? pastPresentColor : disbursement.month > currentMonth ? futureColor : pastPresentColor;
  });
};

export const parseToChartData = (
  currentMonth: number,
  labelsTexts: any[],
  list?: Disbursement[],
  onlyDisbursements = false,
) => {
  const currentYearList = getCurrentYearList(list || []);

  const monthValueList = getMonthValueList(currentYearList, onlyDisbursements);

  const groupedValuesList = getGroupedValueList(monthValueList);
  const everyMonthList = getEveryMonthList(labelsTexts);
  const chartData = getChartData(everyMonthList, groupedValuesList);
  const colorsList = getColorsList(chartData, currentMonth, onlyDisbursements);

  return {
    labels: labelsTexts.map(text => text.substring(0, 1)),
    datasets: [
      {
        data: chartData,
        backgroundColor: colorsList,
        borderWidth: 0,
        barThickness: 14,
        fill: true,
      },
    ],
  };
};

const getMonthDisbPlannedList = (currentYearList, planned) => {
  return currentYearList.map(disbursement => {
    const month = new Date(disbursement.transactionDate).getMonth(); // 0 = january
    return {
      x: month,
      y: planned ? disbursement.actualProjectionAmountUseq : disbursement.disbursedAmountUseq,
    };
  });
};

export const parseToDoubleChartData = (labelsTexts: any[], list: Disbursement[], t: any, financialSection = false) => {
  const yearList = getCurrentYearList(list);
  const monthDisbList = getMonthDisbPlannedList(yearList, false);
  const monthPlannedList = getMonthDisbPlannedList(yearList, true);

  const groupedValuesListDisb = getGroupedValueList(monthDisbList);
  const groupedValuesListPlanned = getGroupedValueList(monthPlannedList);

  const everyMonthList = getEveryMonthList(labelsTexts);

  const chartDataDisb = getChartData(cloneDeep(everyMonthList), groupedValuesListDisb);
  const chartDataPlanned = getChartData(cloneDeep(everyMonthList), groupedValuesListPlanned);
  const mergeDataSets = [...chartDataPlanned, ...chartDataDisb];

  const monthLabels = getMonthLabels();

  const maxNumber = mergeDataSets.reduce((max, p) => (parseInt(p.y) > parseInt(max) ? p.y : max), mergeDataSets[0].y);

  return {
    labels: financialSection ? labelsTexts.map(text => text.substring(0, 1)) : monthLabels.slice(0, 12).map(a => t(a)),
    maxYHeigth: Math.round(maxNumber),
    datasets: [
      {
        type: 'bar',
        label: `${t('disbursed')} USD`,
        data: chartDataDisb.map(m => m.y),
        fill: true,
        backgroundColor: financialSection ? '#538CFF' : '#00CFDD',
        barThickness: 14,
        yAxisID: 'y-axis-1',
        datalabels: {
          formatter: (value, ctx) => {
            formatterDataChart(ctx);
            return thousandsSeparator(Math.round(chartDataDisb.map(m => m.y)[ctx.dataIndex]));
          },
          // anchor: 'end',
          clamp: true,
          // align: 'end',
          // padding: 1,
          backgroundColor: 'transparent',
          borderRadius: 4,
          color: '#236BFF',
          clip: false,
          font: {
            size: 8,
            family: isRubik(),
          },
        },
      },
      {
        label: `Projection USD`,
        type: 'bar',
        data: chartDataPlanned.map(m => m.y),
        fill: true,
        backgroundColor: financialSection ? '#D0D5E0' : '#727E8C',
        barThickness: 14,
        yAxisID: 'y-axis-2',
        datalabels: {
          formatter: (value, ctx) => {
            formatterDataChart(ctx);
            return thousandsSeparator(Math.round(chartDataPlanned.map(m => m.y)[ctx.dataIndex]));
          },
          // clip: true,
          // anchor: 'end',
          clamp: true,
          // align: 'end',
          // padding: 1,
          backgroundColor: 'transparent',
          borderRadius: 4,
          color: '#424D64',
          titleFontSize: 9,
          bodyFontSize: 9,
          font: {
            size: 5,
            family: isRubik(),
          },
        },
        borderRadius: 8,

      },

    ],
  };
};

export const formatterDataChart = ctx => {
  const chartArea = ctx.chart.chartArea;
  chartArea.right = chartArea.right + 15;
};

export const compareXvalues = (a, b) => {
  if (a.x > b.x) {
    return 1;
  } else if (a.x < b.x) {
    return -1;
  } else {
    return 0;
  }
};
