import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Tab, Tabs } from '@material-ui/core';
import { TabPanel } from '../../Projects/ProjectsAll/ProjectAllUtil';
import { makeStyles } from '@material-ui/core/styles';
import { contentManagerTabStyles } from '../../Projects/ProjectsAll/ProjectsAll.Style';
import { contentsTabIndexes as tabIndexes } from '../../../models/TabIndexes';
import { tableRowTypeKeys, dataTypes } from '../ContentManagerKeys';
import { getRegionsAndCountries } from '../../../api/filterApi';
import ContentManagerAccordion from '../ContentManagerAccordion/ContentManagerAccordion';
import ContentManagerTable from '../ContentManagerTable/ContentManagerTable';
import ContentManagerSortable from '../ContentManagerSortable/ContentManagerSortable';
import ContentManagerModal from '../ContentManagerModal/ContentManagerModal';
import HelpCenterModalForm from '../HelpCenter/HelpCenterModalForm/HelpCenterModalForm';
import TutorialsModalForm from '../HelpCenter/TutorialsModalForm/TutorialsModalForm';
import './../ContentManager.scss';
import TourModalForm from '../HelpCenter/TourModalForm/TourModalForm';
import Messages from '../Messages/Messages';
import Maintenance from '../Maintenance/Maintenance';
import Notifications from '../Notifications/Notifications';
import SelectOption from '../../../models/SelectOption';
import {
  getFaqListService,
  getTourListService,
  getTutorialListService,
  deleteTourListItem,
  updateTourListItem,
  getHelpRequestsService,
  defaultPaginationData,
} from '../../../api/contentManager';
import { filter } from 'lodash';
import ErrorMessage from '../../Util/ErrorMessage/ErrorMessage';
import { ContentsLayout } from './ContentsLayout';
import {
  getLanguageTranslationKey,
  getCountries,
  hasRecords,
  getMappedItems,
  loaderCustomStyles,
} from '../../Util/ContentManager/ContentManager';
import { getLanguages } from '../../../api/userApi';
import ContentManagerLoader from '../ContentManagerLoader/ContentManagerLoader';
import Recommended from '../Recommended/Recommended';


const moveTours = async (oldIndex, newIndex, tourList, updateTourListItem, getToursList) => {
  const movingItem = tourList[oldIndex];
  const itemToSwitchPositionsWith = tourList[newIndex];
  await updateTourListItem({ ...movingItem, order: itemToSwitchPositionsWith.order });
  await updateTourListItem({ ...itemToSwitchPositionsWith, order: movingItem.order });
  await getToursList();
};

const getTourOrder = tourList => [
  ...tourList.map(tourItem => ({ id: tourItem.order, value: `${tourItem.order}`, label: `Orden ${tourItem.order}` })),
  { id: tourList.length + 1, value: `${tourList.length + 1}`, label: `Orden ${tourList.length + 1}` },
];

const deleteAndReOrderTours = async (item, getToursList, tourList, deleteTourListItem) => {
  const toursToUpdate = filter(tourList, tourItem => tourItem.order > item.order);
  Promise.all(
    toursToUpdate.map(async tour => {
      await updateTourListItem({ ...tour, order: tour.order - 1 });
    }),
  );
  await deleteTourListItem(item);
  await getToursList();
};

const handleTourModal = (setTourModal, setTourEdit, state) => {
  setTourModal(state);
  setTourEdit(state);
};

const getContentManagerTabs = (tabValue, tabIndexes, classes, tabClasses, handleSetMenuValue, t, setTabValue) => (
  <Tabs
    value={tabValue}
    classes={{
      indicator: classes.indicatorColor,
      root: classes.root,
    }}
    aria-label="full width tabs"
    className='tabs-contents'
  >
    <Tab
      value={tabIndexes.helpCenter}
      classes={tabClasses}
      label={t('contents_tab_helpCenter')}
      onClick={() => handleSetMenuValue(tabIndexes.helpCenter, setTabValue)}
    />
    <Tab
      value={tabIndexes.messages}
      classes={tabClasses}
      label={t('contents_tab_messages')}
      onClick={() => handleSetMenuValue(tabIndexes.messages, setTabValue)}
    />
    <Tab
      value={tabIndexes.maintenance}
      classes={tabClasses}
      label={t('contents_tab_maintenance')}
      onClick={() => handleSetMenuValue(tabIndexes.maintenance, setTabValue)}
    />
    <Tab
      value={tabIndexes.notifications}
      classes={tabClasses}
      label={t('contents_tab_notifications')}
      onClick={() => handleSetMenuValue(tabIndexes.notifications, setTabValue)}
    />
    <Tab
      value={tabIndexes.recommended}
      classes={tabClasses}
      label={t('contents_tab_recommended')}
      onClick={() => handleSetMenuValue(tabIndexes.recommended, setTabValue)}
    />
  </Tabs>
);

const getLanguageTranslationFromKey = (languageKey, t) => t(getLanguageTranslationKey(languageKey));

const handleSetMenuValue = (menuValue, setTabValue) => setTabValue(menuValue);

const getTabPanels = (tabValue, commonProps, getLanguageTranslationFromKey, countryList) => (
  <>
    <TabPanel value={tabValue} index={tabIndexes.messages}>
      <Messages getLanguageTranslationFromKey={getLanguageTranslationFromKey} {...commonProps} />
    </TabPanel>
    <TabPanel value={tabValue} index={tabIndexes.maintenance}>
      <Maintenance {...commonProps} />
    </TabPanel>
    <TabPanel value={tabValue} index={tabIndexes.notifications}>
      <Notifications countryList={countryList} {...commonProps} />
    </TabPanel>
    <TabPanel value={tabValue} index={tabIndexes.recommended}>
      <Recommended />
    </TabPanel>
  </>
);

const p1 = 'p1';
const p2 = 'p2';
const p3 = 'p3';
const p4 = 'p4';

function Contents() {
  const { t } = useTranslation();
  const classes = makeStyles(contentManagerTabStyles)();
  const tabClasses = { root: classes.root, selected: classes.selected };
  // General functions
  const [loading, setLoading] = React.useState(true);
  const [languagesData, setLanguagesData] = React.useState<any>();
  const [countryList, setCountryList] = React.useState<SelectOption[]>();
  const [auxFormData, setAuxFormData] = React.useState<any>();
  const [errorsMessage, setErrorsMessage] = React.useState(false);
  // Data
  const [faqList, setFaqList] = React.useState<any>();
  const [helpCenterModal, setHelpCenterModal] = useState(false);
  const [tutorialsList, setTutorialsList] = React.useState<any>();
  const [tutorialsModal, setTutorialsModal] = useState(false);
  const [tourList, setTourList] = React.useState<any>();
  const [tourEdit, setTourEdit] = React.useState<any>();
  const [tourOrder, setTourOrder] = React.useState<any>();
  const [tourModal, setTourModal] = useState(false);
  const [helpRequests, setHelpRequests] = React.useState<any>();
  // Component view
  const [tabValue, setTabValue] = useState(0);
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) =>
    setExpanded(isExpanded ? panel : false);
  // Fetching data
  const getCountriesAndRegions = async () => {
    const regionAndCountriesResponse = await getRegionsAndCountries();
    setCountryList(getCountries(regionAndCountriesResponse));
  };
  const getFaqList = async (paginationData = defaultPaginationData) => {
    const faqListResponse = await getFaqListService(paginationData);
    setFaqList(faqListResponse);
  };
  const getTutorialsList = async (paginationData = defaultPaginationData) => {
    const tutorialsListResponse = await getTutorialListService(paginationData);
    setTutorialsList(tutorialsListResponse);
  };
  const getToursList = async (paginationData = defaultPaginationData) => {
    const tourListResponse = await getTourListService(paginationData);
    setTourList(tourListResponse);
    setTourOrder(getTourOrder(tourListResponse.collection));
  };
  const getLanguagesData = async () => {
    const languages = await getLanguages();
    setLanguagesData(languages);
  };
  const getHelpRquestsList = async (paginationData = defaultPaginationData) => {
    const helpRequests = await getHelpRequestsService(paginationData);
    setHelpRequests(helpRequests);
  };
  useEffect(() => {
    if (loading) {
      getLanguagesData();
      getCountriesAndRegions();
      hasRecords(faqList) || getFaqList();
      hasRecords(tutorialsList) || getTutorialsList();
      hasRecords(tourList) || getToursList();
      hasRecords(helpRequests) || getHelpRquestsList();
    }
    if (hasRecords(faqList) && hasRecords(tutorialsList) && hasRecords(tourList) && hasRecords(helpRequests)) {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [faqList, loading, tutorialsList, tourList, helpRequests]);
  const commonProps = {
    displayGenericErrorMessage: value => setErrorsMessage(value),
    languagesData,
  };
  return (
    <>
      {loading ? (
        <div className="content-manager__loader-container">
          <ContentManagerLoader styles={loaderCustomStyles} />
        </div>
      ) : (
        <div>
          <h1 className="content-manager__title with-margin">{t('contents_title')}</h1>
          <div>
            {getContentManagerTabs(tabValue, tabIndexes, classes, tabClasses, handleSetMenuValue, t, setTabValue)}
            <div className="content-manager__body">
              {errorsMessage && (
                <ErrorMessage
                  customStyle={{ marginBottom: 30, width: 622 }}
                  alertMessage={t('contents_generic_error_title')}
                  descriptionMessage={t('contents_generic_error_description')}
                />
              )}
              <TabPanel value={tabValue} index={tabIndexes.helpCenter}>
                <ContentManagerAccordion
                  accordionSummary={t('contents_helpCenter_faq')}
                  onChange={handleChange(p1)}
                  expanded={expanded === p1}
                >
                  <ContentsLayout
                    type={tableRowTypeKeys.default}
                    dataType={dataTypes.faq}
                    items={getMappedItems(dataTypes.faq, faqList.collection, languagesData)}
                    getLanguageTranslationFromKey={getLanguageTranslationFromKey}
                    updateFunction={getFaqList}
                    buttonText={t('contents_helpCenter_faq_addFaq')}
                    isOpen={helpCenterModal}
                    openModal={() => setHelpCenterModal(true)}
                    closeModal={() => setHelpCenterModal(false)}
                    modalTitle={t('contents_helpCenter_faq_addFaq_modal_title')}
                    dataInfo={faqList}
                    {...commonProps}
                  >
                    <HelpCenterModalForm
                      closeModal={() => setHelpCenterModal(false)}
                      getLanguageTranslationFromKey={getLanguageTranslationFromKey}
                      updateFaqList={getFaqList}
                      {...commonProps}
                    />
                  </ContentsLayout>
                </ContentManagerAccordion>
                <ContentManagerAccordion
                  accordionSummary={t('contents_helpCenter_tutorials')}
                  onChange={handleChange(p2)}
                  expanded={expanded === p2}
                >
                  <ContentsLayout
                    type={tableRowTypeKeys.labelActions}
                    dataType={dataTypes.tutorials}
                    items={getMappedItems(dataTypes.tutorials, tutorialsList.collection, languagesData)}
                    getLanguageTranslationFromKey={getLanguageTranslationFromKey}
                    updateFunction={getTutorialsList}
                    buttonText={t('contents_helpCenter_tutorial_add_button_label')}
                    isOpen={tutorialsModal}
                    openModal={() => setTutorialsModal(true)}
                    closeModal={() => setTutorialsModal(false)}
                    modalTitle={t('contents_helpCenter_tutorial_modal_title')}
                    dataInfo={tutorialsList}
                    {...commonProps}
                  >
                    <TutorialsModalForm
                      closeModal={() => setTutorialsModal(false)}
                      getLanguageTranslationFromKey={getLanguageTranslationFromKey}
                      updateTutorialsList={getTutorialsList}
                      {...commonProps}
                    />
                  </ContentsLayout>
                </ContentManagerAccordion>
                <ContentManagerAccordion
                  accordionSummary={t('contents_helpCenter_emailQuestions')}
                  onChange={handleChange(p3)}
                  expanded={expanded === p3}
                >
                  <ContentManagerTable
                    type={tableRowTypeKeys.arrowActions}
                    dataType={dataTypes.emailQuestions}
                    items={getMappedItems(dataTypes.emailQuestions, helpRequests.collection)}
                    dataInfo={helpRequests}
                    updateFunction={getHelpRquestsList}
                    {...commonProps}
                  />
                </ContentManagerAccordion>
                <ContentManagerAccordion
                  accordionSummary={t('contents_helpCenter_tour')}
                  onChange={handleChange(p4)}
                  expanded={expanded === p4}
                >
                  <ContentManagerSortable
                    listItems={getMappedItems(dataTypes.tours, tourList.collection, languagesData)}
                    openModal={() => handleTourModal(setTourModal, setTourEdit, true)}
                    setFormData={setAuxFormData}
                    deleteItem={deleteAndReOrderTours}
                    updateFunction={getToursList}
                    moveTours={moveTours}
                    updateTourListItem={updateTourListItem}
                    deleteTourListItem={deleteTourListItem}
                    dataInfo={tourList}
                    dataType={dataTypes.tours}
                    showPagination={false}
                  />
                  <ContentManagerModal
                    buttonText={t('contents_helpCenter_tour_add_button_label')}
                    isOpen={tourModal}
                    openModal={() => setTourModal(true)}
                    closeModal={() => handleTourModal(setTourModal, setTourEdit, false)}
                    modalTitle={t('contents_helpCenter_tour_modal_title')}
                    buttonDisabled={tourList.rowCount === 6}
                    disabledMessage={'max_tours_warning'}
                  >
                    <TourModalForm
                      closeModal={() => handleTourModal(setTourModal, setTourEdit, false)}
                      getLanguageTranslationFromKey={getLanguageTranslationFromKey}
                      tourListItem={auxFormData}
                      isEdit={tourEdit}
                      updateTourList={getToursList}
                      tourOrder={tourOrder}
                      tourList={tourList}
                      {...commonProps}
                    />
                  </ContentManagerModal>
                </ContentManagerAccordion>
              </TabPanel>
              {getTabPanels(tabValue, commonProps, getLanguageTranslationFromKey, countryList)}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Contents;
