import React from 'react';
import styles from './ReviewStatus.module.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  status: number;
};

const ReviewStatus = ({ status }: Props) => {
  const { t } = useTranslation();

  return status === 0 ? (
    <div className={styles.attempt}>{t('attempt_agenda')}</div>
  ) : (
    <div className={styles.confirmed}>{t('confirmed_agenda')}</div>
  );
};

export default ReviewStatus;
