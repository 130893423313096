import React, { useEffect, useState } from 'react';
import BaseModal from '../../../../Util/Modals/BaseModal';
import { styles } from '../../../../Util/Modals/Styles';
import './ModalPcr.scss';
import CloseCrossButton from '../../../../Util/Buttons/CloseCrossButton/CloseCrossButton';
import Timeline from './GraficPcr';
import TableModalPcr from './TableModalPcr';
import * as dates from 'date-arithmetic';
import { useTranslation } from 'react-i18next';

type Props = {
  isOpen: boolean;
  style?: ReactModal.Styles;
  className?: string;
  onRequestClose?: () => void;
  shouldCloseOnOverlayClick?: boolean;
  closeModal: any;
  data: any;
  convergeData: any;
};

const ModalPCR = (props: Props) => {
  const { isOpen, closeModal, data, convergeData } = props;
  const { t } = useTranslation();
  const [dates, setDates] = useState<any>();

  useEffect(() => {
    if (data) {
      const { data: finalData } = data;
      setDates(finalData);
    }
  }, [data]);

  return (
    <BaseModal isOpen={isOpen} style={styles} className="modal-pcr">
      <div className="close">
        <CloseCrossButton handleClick={closeModal} />
      </div>
      <h2 className="ci-type-text-1 title">{t('new_estimated_PCR_milestone_timeline_as_per_guidance')}</h2>
      <Timeline dates={dates} />
      <p className="timeline-note ci-type-text-7">
        {t('new_GUIDELINES')}{' '}
        <a
          href="https://idbg.sharepoint.com/teams/ez-SPD/SDV/PD/15%20Project%20Closing%20Report/015.%20Technical%20Documents/2020%20guias%20y%20checklists/2020-Update-to-2018-PCR-Principles-and-Guidelines.pdf"
          target="_blank"
        >
          {t('new_guide')}
        </a>
      </p>

      {/* <h3 className="ci-type-text-3 mb16">{t('new_current_status_pcr')} </h3>

      <div className="table-pcr">
        <TableModalPcr convergeData={convergeData} />
        <div className="status-note">
          <p className="ci-type-text-7">{t('new_data_pcr')}</p>
        </div>
      </div> */}
    </BaseModal>
  );
};

export default ModalPCR;
