import { SET_CHECKED_ITEMS, SET_FILTER_INDICATORS } from './types';
import FilterIndicators from '../../models/FilterIndicators';
import { setIndicatorDetailFilterChart } from './indicatorDetailFilters';

export function setCheckedItems(checkedItems: { [key: string]: boolean }) {
  return {
    type: SET_CHECKED_ITEMS,
    payload: checkedItems,
  };
}

export function setFilterIndicators(partialFilterIndicators: Partial<FilterIndicators>) {

  return {
    type: SET_FILTER_INDICATORS,
    payload: partialFilterIndicators,
  };
}

export const dispatchFilterIndicatorState = (d, filterIndicators, value) => {
  d(
    setIndicatorDetailFilterChart({
      ...filterIndicators.indicatorCharts,
      firstTime: value,
    }),
  );
};


/*
export function setFilterIndicators(filterIndicators: FilterIndicators) {
  return {
    type: SET_FILTER_INDICATORS,
    payload: filterIndicators,
  };
}
*/