import { Icon } from '@material-ui/core';
import React, { useState } from 'react';
import styles from './AnalyticCard.module.scss';
import { ReactComponent as ZoomIcon } from '../../../../assets/ic_zoom.svg';
import { AnalyticsKeyEnum } from '../../../../models/AnalyticsKeyEnum';
import { TextIndicator } from '../../../Portfolio/CardIndicator/Cards/TextIndicator/TextIndicator';
import { RadialAnalyticsCharts } from './Cards/RadialAnalyticsCharts/RadialAnalyticsCharts';
import { BarChart, IChartDimensions } from '../../../Portfolio/CardIndicator/Cards/BarChart/BarChart';
import { barChartData } from '../../../Portfolio/CardIndicator/CardIndicator';
import { capitalizeFirstLetter } from '../../../../services/stringUtil';
import { isMobile } from '../../../../services/booleanUtil';
import { useTranslation } from 'react-i18next';
import { DoubleNumber } from './Cards/DoubleNumber/DoubleNumber';
import { MultipleStackedBar } from './Cards/MultipleStackedBar/MultipleStackedBar';
import { CardAnalyticSkeletonNumber } from '../../../LoadersSkeletons/CardAnalyticSkeleton/CardAnalyticSkeleton';
import { AnalyticsCardSizeEnum } from '../../../../models/AnalyticsCardSizeEnum';

type Props = {
  data?: any;
  openModal?: any;
  openComplexModal?: any;
  updateSessionIndFilter?: any;
  isLoadingData?: boolean;
  size?: AnalyticsCardSizeEnum;
};

export const AnalyticCard = ({
  data,
  openModal,
  openComplexModal,
  updateSessionIndFilter,
  isLoadingData,
  size = AnalyticsCardSizeEnum.SMALL,
}: Props) => {
  const [mouseEnter, setMosuseEnter] = useState(false);
  const { t } = useTranslation();
  const handleMouseHover = inOut => {
    enableDetailModal(data) && setMosuseEnter(inOut);
  };

  const renderData = data => (
    <div
      className={containerClassName(size, mouseEnter, data)}
      onMouseEnter={() => handleMouseHover(true)}
      onMouseLeave={() => handleMouseHover(false)}
      onClick={canOpenModal(data) ? openModal : null}
    >
      {canOpenModal(data) && enableDetailModal(data) && showZoomIcon(size, mouseEnter)}
      {renderAnalyticCard(data, false, t, openComplexModal, updateSessionIndFilter)}
    </div>
  );

  return data && data.turnOff ? (
    <></>
  ) : isLoadingData ? (
    <div className={containerClassName(size, mouseEnter, data)}>
      <CardAnalyticSkeletonNumber />
    </div>
  ) : (
    renderData(data)
  );
};

export const renderAnalyticCard = (card, modalView = false, t, openComplexModal = false, updateSessionIndFilter) => {
  switch (card && card.indicatorKey) {
    case AnalyticsKeyEnum.AGGREGATE_INDICATORS_LOGINS_COMPLEX:
    case AnalyticsKeyEnum.AGGREGATE_INDICATORS_USERS_WHO_ENTERED_THE_TOOL_COMPLEX:
      return multipleStackedHaveData(card, openComplexModal, updateSessionIndFilter, t);
    case AnalyticsKeyEnum.PROJECT_INDICATORS_USE_OF_SECTIONS_COMPLEX:
      return multipleStackedHaveData(card, openComplexModal, updateSessionIndFilter, t);

    case AnalyticsKeyEnum.AGGREGATE_INDICATORS_LOGINS:
    case AnalyticsKeyEnum.SUPPORT_INDICATORS_INQUIRIES_TO_SUPPORT_ABOUT_DATA_ERRORS:
    case AnalyticsKeyEnum.SUPPORT_INDICATORS_REQUESTS_CUSTOMIZATION_BY_COUNTRY:
    case AnalyticsKeyEnum.SUPPORT_INDICATORS_SATISFACTION_SURVEY_WITHIN_THE_ONLINE_HELP:
    case AnalyticsKeyEnum.AGGREGATE_INDICATORS_USERS_WHO_ENTERED_THE_TOOL:
      return <TextIndicator card={card} analyticIndicator={true} />;

    case AnalyticsKeyEnum.PORTFOLIO_INDICATORS_INDICATOR_CUSTOMIZATION:
    case AnalyticsKeyEnum.PROJECT_INDICATORS_MY_PROJECTS:
    case AnalyticsKeyEnum.PROJECT_INDICATORS_CALENDAR_SYNCHRONIZATION_WITH_OUTLOOK:
    case AnalyticsKeyEnum.PROJECT_INDICATORS_DISBURSEMENT_DATA:
      return <RadialAnalyticsCharts card={card} modalView={modalView} />;
    case AnalyticsKeyEnum.PORTFOLIO_INDICATORS_USE_OF_TYPE_PROJECT_FILTER:
      return card.data ? (
        <div style={barChartVerticalStyles(modalView)}>
          {!modalView && <div className={styles.titleBarChart}>{capitalizeFirstLetter(t(card.title))}</div>}
          <div className={barChartVerticalSimpleContainerStyles(modalView)}>
            <BarChart
              type={card.type}
              data={{ ...card.data }}
              showSubLabels={true}
              listPage={false}
              datasetClick={() => {}}
              contentManager={false}
              modalChart={modalView}
            />
          </div>

          {modalView && (
            <div className={styles.useOfProjectFiltersModalListVerticalChart}>
              <ul className={styles.unorderedListColumnsVerticalChart}>
                {card.projectTypeMetrics.map((item, key) => {
                  return (
                    <li key={`projectType_${item.name}_${key}`}>
                      <span className={styles.verticalDiskIndicator}></span>
                      {item.name}: <span className={styles.bolderVerticalChart}>{item.metricValue}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      ) : (
        emptyState(t, card)
      );
    case AnalyticsKeyEnum.PORTFOLIO_INDICATORS_MOST_USED_INDICATORS:
    case AnalyticsKeyEnum.PROJECT_INDICATORS_AGREEMENTS_BAR_GRAPH:
      return (
        <div style={barChartVerticalStyles(modalView)}>
          {!modalView && <div className={styles.titleBarChart}>{capitalizeFirstLetter(t(card.dataChart.title))}</div>}
          {!modalView && (
            <div className={styles.subtitleBarChart}> {capitalizeFirstLetter(t(card.dataChart.subtitle))}</div>
          )}
          <div className={setBarChartContainerClass(modalView)}>
            <BarChart
              type={setBarChartType(modalView, card)}
              data={barChartData(modalView ? card : card.dataChart, t, false)}
              showSubLabels={!isMobile()}
              listPage={true}
              datasetClick={() => {}}
              indicatorKey={setBarChartIndicatorKey(modalView, card)}
              modalChart={modalView}
              contentManager={true}
            />
          </div>
        </div>
      );
    case AnalyticsKeyEnum.PROJECT_INDICATORS_GENERATED_REPORTS:
    case AnalyticsKeyEnum.PROJECT_INDICATORS_AGREEMENTS_NUMBERS:
    case AnalyticsKeyEnum.PORTFOLIO_INDICATORS_GENERATED_REPORTS:
      return <DoubleNumber card={card} />;

    case AnalyticsKeyEnum.PORTFOLIO_INDICATORS_USE_OF_FILTERS:
    case AnalyticsKeyEnum.PROJECT_INDICATORS_USE_OF_FILTERS:
      const { subTextlabels, subLabels } = card.data;

      return (
        <div style={barChartHorizontalStyles(modalView)}>
          {!modalView && <div className={styles.titleBarChart}>{capitalizeFirstLetter(t(card.title))}</div>}
          <div className={barChartHorizontalContainerStyles(modalView)}>
            <BarChart
              type={card.type}
              data={translateHorizontalLabels(card, t)}
              showSubLabels={true}
              listPage={false}
              datasetClick={() => {}}
              contentManager={true}
              modalChart={modalView}
            />
          </div>
          {modalView && (
            <div className={styles.useOfFiltersModalList}>
              <ul className={styles.unorderedListColumns}>
                {renderListHorizontalBarChart(subTextlabels, subLabels, t)}
              </ul>
            </div>
          )}
        </div>
      );

    case AnalyticsKeyEnum.AGGREGATE_INDICATORS_MOST_USED_SECTION:
      const dimensions: IChartDimensions = {
        mobile: { width: 300, height: 300 },
        desktop: { width: 300, height: 180 },
      };
      return (
        <div style={{ marginLeft: '20px', height: 300 }}>
          <div className={styles.titleBarChart}>{capitalizeFirstLetter(t(card.title))}</div>
          <div className={styles.subtitleBarChart}> {capitalizeFirstLetter(t(card.subtitle))}</div>
          <div className={styles.mediumContainer}>
            <div className={styles.barchartMediumContainer}>
              <BarChart
                type={card.type}
                data={barChartData(card, t, false)}
                showSubLabels={!isMobile()}
                listPage={true}
                datasetClick={() => {}}
                indicatorKey={card.indicatorKey}
                modalChart={modalView}
                contentManager={true}
                chartDimensions={dimensions}
              />
            </div>
            <div className={styles.listMediumContainer}>
              <ul>
                {card &&
                  card.data &&
                  card.data.dataListTranslationLabels.map((label, ind) => {
                    return (
                      <li className={styles[`_${ind}`]}>
                        {t(label)}:<span className={styles.bold}> {card.data.dataQtyProjects[ind]}</span>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
          <div className={styles.footerInfo}>
            {t('the_portfolio_section_is_counted_once_the_user_interacts_with_it')}
          </div>
        </div>
      );
    default:
      return null;
  }
};

const multipleStackedHaveData = (card, openComplexModal, updateSessionIndFilter, t) => {
  if (
    card.indicatorKey === AnalyticsKeyEnum.AGGREGATE_INDICATORS_LOGINS_COMPLEX ||
    card.indicatorKey === AnalyticsKeyEnum.AGGREGATE_INDICATORS_USERS_WHO_ENTERED_THE_TOOL_COMPLEX
  ) {
    return card.data ? (
      <MultipleStackedBar
        card={card}
        openComplexModal={openComplexModal}
        updateSessionIndFilter={item => updateSessionIndFilter(item)}
      />
    ) : (
      emptyState(t, card)
    );
  }

  return card.data ? (
    <MultipleStackedBar card={card} openComplexModal={openComplexModal} simple={true} />
  ) : (
    emptyState(t, card)
  );
};

const setBarChartContainerClass = modalView => (modalView ? styles.barchartModalContainer : styles.barchartContainer);
const setBarChartType = (modalView, card) => (modalView ? card.type : card.dataChart.type);
const setBarChartIndicatorKey = (modalView, card) => (modalView ? card.indiatorKey : card.dataChart.indicatorKey);

const renderListHorizontalBarChart = (subTextlabels, subLabels, t) => {
  const colorClasses = [
    styles.horizontalBarChartList_0,
    styles.horizontalBarChartList_1,
    styles.horizontalBarChartList_2,
    styles.horizontalBarChartList_3,
    styles.horizontalBarChartList_4,
    styles.horizontalBarChartList_5,
    styles.horizontalBarChartList_6,
  ];
  return subLabels.map((labelNumber, ind) => {
    const colorClass = colorClasses[ind];
    return (
      <li className={colorClass}>
        {`${capitalizeFirstLetter(t(subTextlabels[ind]))}:`} <span className={styles.bolder}>{labelNumber}</span>
      </li>
    );
  });
};

const translateHorizontalLabels = (card, t) => {
  return {
    ...card.data,
    subTextTranslatedLabels: card.data.subTextlabels.map(label => {
      return `${t(label)}`;
    }),
  };
};

const barChartVerticalStyles = modalView => {
  if (modalView) {
    return { marginLeft: '20px', top: '240px', height: 300, width: 600 };
  } else {
    return { marginLeft: '20px', height: 300 };
  }
};

const barChartVerticalSimpleContainerStyles = modalView =>
  !modalView ? styles.barChartContainerSimpleVertical : styles.barChartModalContainerSimpleVertical;

const barChartHorizontalStyles = modalView => {
  if (modalView) {
    return { marginLeft: '20px', top: '260px', height: 300, width: 600 };
  } else {
    return { marginLeft: '70px', height: 300, width: 300 };
  }
};
const barChartHorizontalContainerStyles = modalView =>
  modalView ? styles.barchartHorizontalModalContainer : styles.barchartHorizontalContainer;

const isLargeSize = size => {
  return size === AnalyticsCardSizeEnum.LARGE;
};

const showZoomIcon = (size, mouseEnter) =>
  mouseEnter && (
    <div className={isLargeSize(size) ? styles.showOpacityBigSize : styles.showOpacity}>
      <Icon className={isLargeSize(size) ? styles.complexZoomIcon : styles.simpleZoomIcon}>
        <ZoomIcon />
      </Icon>
    </div>
  );

const canOpenModal = data => {
  return data ? data?.dataChart?.data || data?.data : false;
};

const enableDetailModal = card => {
  switch (card && card.indicatorKey) {
    case AnalyticsKeyEnum.AGGREGATE_INDICATORS_LOGINS:
    case AnalyticsKeyEnum.SUPPORT_INDICATORS_INQUIRIES_TO_SUPPORT_ABOUT_DATA_ERRORS:
    case AnalyticsKeyEnum.SUPPORT_INDICATORS_REQUESTS_CUSTOMIZATION_BY_COUNTRY:
    case AnalyticsKeyEnum.SUPPORT_INDICATORS_SATISFACTION_SURVEY_WITHIN_THE_ONLINE_HELP:
    case AnalyticsKeyEnum.PROJECT_INDICATORS_GENERATED_REPORTS:
    case AnalyticsKeyEnum.PORTFOLIO_INDICATORS_GENERATED_REPORTS:
    case AnalyticsKeyEnum.PROJECT_INDICATORS_AGREEMENTS_NUMBERS:
    case AnalyticsKeyEnum.AGGREGATE_INDICATORS_LOGINS_COMPLEX:
    case AnalyticsKeyEnum.PROJECT_INDICATORS_USE_OF_SECTIONS_COMPLEX:
    case AnalyticsKeyEnum.AGGREGATE_INDICATORS_USERS_WHO_ENTERED_THE_TOOL:
    case AnalyticsKeyEnum.AGGREGATE_INDICATORS_USERS_WHO_ENTERED_THE_TOOL_COMPLEX:
    case AnalyticsKeyEnum.AGGREGATE_INDICATORS_MOST_USED_SECTION:
      return false;

    default:
      return true;
  }
};

const renderCardStyle = (mouseEnter, data, normalStyle, hoverStyle) => {
  if (mouseEnter && canOpenModal(data)) {
    return hoverStyle;
  }
  return normalStyle;
};

const containerClassName = (size: AnalyticsCardSizeEnum, mouseEnter, data) => {
  if (size === AnalyticsCardSizeEnum.MEDIUM) {
    return renderCardStyle(mouseEnter, data, styles.mediumCard, styles.mediumCardHover);
  } else if (size === AnalyticsCardSizeEnum.LARGE) {
    return renderCardStyle(mouseEnter, data, styles.complexCard, styles.complexCardHover);
  }
  return renderCardStyle(mouseEnter, data, styles.simpleCard, styles.simpleCardHover);
};

const emptyState = (t, card) => {
  return (
    <>
      <span className={styles.titleBarChart}> {t(card.title)}</span>
      <span className={styles.emptyState}> -</span>
    </>
  );
};
