import { FilterActionTypes, SET_FILTER } from '../actions/types';
import FilterProjects from '../../models/FilterProjects';
import { SortParameterProject } from '../../models/SortParameterProjectEnum';
import { startedFilter } from './filterObject';
import { menuTabValuesEnum } from '../../models/MenuTabValuesEnum';

const commonFilter = JSON.parse(JSON.stringify(startedFilter));

const initialState: FilterProjects = {
  common: commonFilter,
  followedProjectsFilter: {
    inFollow: true,
    sortParamenter: SortParameterProject.Default,
    orderDesc: true,
    cardView: true,
    searchValue: '',
  },
  followedProjectsPagination: {
    currentPage: 1,
    pageSize: 0,
    pageCount: 0,
    rowCount: -1,
    firstRowOnPage: 0,
    lastRowOnPage: 0,
  },
  allProjectsFilter: {
    inFollow: false,
    sortParamenter: SortParameterProject.Default,
    orderDesc: true,
    menu: [{ id: menuTabValuesEnum.global_bid, label: 'BID_GLOBAL' }],
    menuValue: menuTabValuesEnum.global_bid,
    searchValue: '',
  },
  allProjectsPagination: {
    currentPage: 1,
    pageSize: 0,
    pageCount: 0,
    rowCount: -1,
    firstRowOnPage: 0,
    lastRowOnPage: 0,
  },
};

const filterReducer = (state = initialState, action: FilterActionTypes): FilterProjects => {
  switch (action.type) {
    case SET_FILTER:
      return action.payload;
    default:
      return state;
  }
};
export default filterReducer;
