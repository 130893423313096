import React, { useEffect } from 'react';
import { map, lowerCase, some } from 'lodash';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CustomSwitch from '../../../Util/CustomSwitch/CustomSwitch';
import { AccordionDetails, Checkbox, FormControlLabel } from '@material-ui/core';
import { ReactComponent as CheckedIcon } from '../../../../assets/checkbox_checked_icon.svg';
import { ReactComponent as UncheckedIcon } from '../../../../assets/checkbox_unchecked_icon.svg';
import {
  HelpCenterFormAccordion,
  HelpCenterFormAccordionSummary,
} from '../../ContentManagerAccordion/ContentManagerAccordion';
import '../../HelpCenter/HelpCenterModalForm/HelpCenterModalForm.scss';
import { useStyles } from '../../HelpCenter/HelpCenterModalForm/HelpCenterModalForm';
import { languagesKeys } from '../../ContentManagerKeys';
import { postSystemMessageListItem, updateSystemMessageListItem } from '../../../../api/contentManager';
import { findLanguage, findLanguageByValue } from '../../../Util/ContentManager/ContentManager';

type Props = {
  closeModal: any;
  getLanguageTranslationFromKey: any;
  updateFunction: any;
  displayGenericErrorMessage: any;
  isEdit?: boolean;
  messageItem?: any;
  languagesData?: any;
};

const defaultFormFields = [
  {
    messageLanguageId: languagesKeys.spanish,
    content: '',
    isChecked: false,
  },
  {
    messageLanguageId: languagesKeys.english,
    content: '',
    isChecked: false,
  },
  {
    messageLanguageId: languagesKeys.portuguese,
    content: '',
    isChecked: false,
  },
  {
    messageLanguageId: languagesKeys.french,
    content: '',
    isChecked: false,
  },
];

export const hasCompletedTour = translations => some(translations, translation => translation.content.trim() !== '');
export const getDisabledClass = formFields => !hasCompletedTour(formFields) && 'disabled';

export default function HelpCenterModalForm({
  closeModal,
  getLanguageTranslationFromKey,
  updateFunction,
  displayGenericErrorMessage,
  isEdit,
  messageItem,
  languagesData,
}: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [formFields, setFormFields] = React.useState(defaultFormFields);
  const [expanded, setExpanded] = React.useState(languagesKeys.spanish);
  const [active, setActive] = React.useState(false);
  const handleChangeActiveMessage = nextChecked => setActive(nextChecked);
  const handleChange = panel => (event, isExpanded) => setExpanded(isExpanded ? panel : false);
  const getButtonTitle = () => t(isEdit ? 'contents_messages_edit_button_label' : 'contents_messages_add_button_label');
  useEffect(() => {
    if (isEdit) {
      setFormFields(
        messageItem.translations.map(item => {
          return {
            ...item,
            isChecked: item.content !== '',
            messageLanguageId: lowerCase(findLanguage(languagesData, item).name),
          };
        }),
      );
      setActive(messageItem.active);
    }
    // eslint-disable-next-line
  }, []);

  const handleFormChange = e => {
    e.preventDefault();
    const { name, value } = e.target;
    const dataInfo = name.split('-');
    const type = dataInfo[0];
    const language = dataInfo[1];

    const updatedList = map(formFields, messageItem => {
      if (messageItem.messageLanguageId === languagesKeys[language]) {
        const isChecked = value !== '';

        return { ...messageItem, [type]: value, isChecked };
      }
      return messageItem;
    });

    setFormFields(updatedList);
  };

  const handleSubmitForm = async () => {
    try {
      const messagesPostItem = {
        active,
        translations: formFields.map(translation => ({
          ...translation,
          languageId: findLanguageByValue(languagesData, translation.messageLanguageId).id,
        })),
      };

      if (isEdit) {
        await updateSystemMessageListItem({ ...messagesPostItem, id: messageItem.id });
      } else {
        await postSystemMessageListItem(messagesPostItem);
      }

      updateFunction();
      closeModal();
    } catch {
      displayGenericErrorMessage(true);
    }
  };

  return (
    <>
      {formFields.map(messageItem => {
        return (
          <HelpCenterFormAccordion
            expanded={expanded === messageItem.messageLanguageId}
            onChange={handleChange(messageItem.messageLanguageId)}
            key={messageItem.messageLanguageId}
          >
            <HelpCenterFormAccordionSummary expandIcon={<ExpandMoreIcon />}>
              <FormControlLabel
                classes={classes}
                value="end"
                control={
                  <Checkbox checked={messageItem.isChecked} icon={<UncheckedIcon />} checkedIcon={<CheckedIcon />} />
                }
                label={getLanguageTranslationFromKey(messageItem.messageLanguageId, t)}
                labelPlacement="end"
                disabled
              />
            </HelpCenterFormAccordionSummary>
            <AccordionDetails style={{ display: 'block', padding: '0px' }}>
              <div className="help-center-modal-form-container">
                <div className="horizontal-row">
                  <div className="label-select-container text-area">
                    <p className="label">{t('contents_messages_message_textarea')}</p>
                    <textarea
                      value={messageItem.content}
                      name={`content-${messageItem.messageLanguageId}`}
                      onChange={e => handleFormChange(e)}
                    ></textarea>
                  </div>
                </div>
              </div>
            </AccordionDetails>
          </HelpCenterFormAccordion>
        );
      })}
      <div className="content-manager-modal__options">
        <span className="content-manager-modal__options-label">
          {t('contents_messages_switch_active_message_label')}
        </span>
        <CustomSwitch active={active} handleChange={handleChangeActiveMessage} />
      </div>
      <div className="content-manager-modal__button-group">
        <button
          className={`content-manager-modal__button ${getDisabledClass(formFields)}`}
          onClick={() => handleSubmitForm()}
        >
          {getButtonTitle()}
        </button>
        <button
          style={{ width: '195px' }}
          className="content-manager-modal__button--secondary"
          onClick={() => closeModal()}
        >
          {t('contents_messages_close_button_label')}
        </button>
      </div>
    </>
  );
}
