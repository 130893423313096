import React from 'react';
import { Chip, Grid } from '@material-ui/core';
import './AlertTags.scss';
import { useTranslation } from 'react-i18next';

const AlertTags = ({ tags }) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={1}>
      {tags.map((tag, index) =>
        tag.value === true || tag.value > 0 ? (
          <Grid item key={index}>
            <Chip label={t(tag.label)} />
          </Grid>
        ) : null,
      )}
    </Grid>
  );
};

export default AlertTags;
