import React from 'react';
import styles from './MultipleStackedBar.module.scss';
import CustomRadioButton from '../../../../../Util/CustomRadioButton/CustomRadioButton';
import CustomSelect from '../../../../../Util/CustomSelect/CustomSelect';
import { useTranslation } from 'react-i18next';

type Props = {
  handleFirstRadio: Function;
  firstRadioChecked: boolean;
  handleSecondRadio: Function;
  secondRadioChecked: boolean;
  secondRadioLabel: string;
  options: any;
  handleSelect: any;
  selectedOption: any;
};

const Filters = ({
  handleFirstRadio,
  firstRadioChecked,
  handleSecondRadio,
  secondRadioChecked,
  secondRadioLabel,
  options,
  handleSelect,
  selectedOption,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.filtersOptions}>
      <div className={styles.filterOptionsContainer}>
        <div className={styles.filterOptionLabel}> {t('show_by')}:</div>
        <div className={styles.firstRadioContainer}>
          <CustomRadioButton
            customStyle={{ paddingLeft: 0, fontFamily: 'Source Sans Pro', fontWeight: 400, lineHeight: '17px' }}
            customChecked={{ checked: 'analytic-checked', unchecked: 'analytic-unchecked' }}
            labelText={t('dates')}
            handleOnClick={handleFirstRadio}
            isChecked={firstRadioChecked}
          />
        </div>
        <div className={styles.secondRadioContainer}>
          <CustomRadioButton
            customStyle={{ paddingLeft: 0, fontFamily: 'Source Sans Pro', fontWeight: 400, lineHeight: '17px' }}
            customChecked={{ checked: 'analytic-checked', unchecked: 'analytic-unchecked' }}
            labelText={secondRadioLabel}
            handleOnClick={handleSecondRadio}
            isChecked={secondRadioChecked}
          />
        </div>
        <div className={styles.selectContainer}>
          <CustomSelect
            options={options}
            handleSelected={handleSelect}
            selectedOption={selectedOption}
            style={{ width: 145, textTransform: 'capitalize' }}
            type={'review-list-white'}
          />
        </div>
      </div>
    </div>
  );
};

export default Filters;
