import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/pt';
import 'moment/locale/fr';
import 'moment/locale/en-ca';
import { ProjectExecutionTypes } from '../models/ProjectExecutionTypes';
import { ProjectProductTypes } from '../models/ProjectProductTypes';
import { ProjectProductTypeFilters } from '../models/ProjectProductTypeFilters';
import { Filter, FilterSingleValue } from '../components/MenuTabs/userTabsInterface';
import { EMPTY_GCM_ALERT, GcmGrouping, IGcmAlert } from '../models/GcmAlerts';
import { sortAlphabetically } from './sortsUtil';
import { BID_CONSULTANCY, indicatorColumns } from './tableHeaderHelperSetup';
import { ProjectVsCompletedIndicatorFilterField } from '../models/enums/ProjectedVsCompletedIndicatorFilter';
import { thousandsSeparator, thousandsSeparatorToMillion } from './stringUtil';

export const checkCharacterNumber = (characterNumber, e) => {
  if (characterNumber >= 0 && characterNumber <= 9) {
    if (e.currentTarget.value && e.currentTarget.value.length) {
      return;
    }
  } else if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
    return;
  } else {
    e.preventDefault();
  }
};

export const parseFilterToApiCall = projectTypeFilter => {
  const filterObject = { projectProductType: 3, amount: 3000000 };
  const projectProductTypes: any[] = [];
  const projectProductAmounts: any[] = [];

  if (projectTypeFilter.includes(ProjectProductTypeFilters.Loan)) {
    projectProductTypes.push(ProjectProductTypes.Loan);
    projectProductTypes.push(ProjectProductTypes.GUARANTEE);
  }
  if (projectTypeFilter.includes(ProjectProductTypeFilters.CT)) {
    projectProductTypes.push(ProjectProductTypes.CT);
  }
  if (projectTypeFilter.includes(ProjectProductTypeFilters.IG_greater_3)) {
    projectProductTypes.push(ProjectProductTypes.IG);
  }
  if (
    projectTypeFilter.includes(ProjectProductTypeFilters.IG_lower_3) &&
    !projectProductTypes.includes(ProjectProductTypes.IG)
  ) {
    projectProductTypes.push(ProjectProductTypes.IG);
  }

  //Handle IG Filter
  if (projectTypeFilter.includes(ProjectProductTypeFilters.IG_greater_3)) {
    projectProductAmounts.push({ filterType: 1, ...filterObject });
  }
  if (projectTypeFilter.includes(ProjectProductTypeFilters.IG_lower_3)) {
    projectProductAmounts.push({ filterType: 1, ...filterObject });
    projectProductAmounts.push({ filterType: 0, ...filterObject });
  }

  return { projectProductTypes, projectProductAmounts };
};

export const checkLanguageUpadeLocale = () => {
  const storedLanguage = localStorage.getItem('language');
  if (storedLanguage && storedLanguage !== 'en') {
    moment.locale(storedLanguage);
  } else {
    moment.locale('en');
  }
};
export const getNumberFormatter = () => new Intl.NumberFormat('es-ES');

export const replaceMomentFormat = value => {
  const selectedLanguage = localStorage.getItem('language');
  const list = [
    { current: 'ene.', formatted: 'ENE' },
    { current: 'feb.', formatted: 'FEB' },
    { current: 'mar.', formatted: 'MAR' },
    { current: 'abr.', formatted: 'ABR' },
    { current: 'may.', formatted: 'MAY' },
    { current: 'jun.', formatted: 'JUN' },
    { current: 'jul.', formatted: 'JUL' },
    { current: 'ago.', formatted: 'AGO' },
    { current: 'sep.', formatted: 'SEP' },
    { current: 'oct.', formatted: 'OCT' },
    { current: 'nov.', formatted: 'NOV' },
    { current: 'dic.', formatted: 'DIC' },
  ];

  const frenchList = [
    { current: 'janv.', formatted: 'JAN' },
    { current: 'févr.', formatted: 'FÉV' },
    { current: 'mars', formatted: 'MARS' },
    { current: 'avr.', formatted: 'AVR' },
    { current: 'mai.', formatted: 'PEU' },
    { current: 'juin', formatted: 'JUIN' },
    { current: 'juil.', formatted: 'JUIL' },
    { current: 'août', formatted: 'AOÛ' },
    { current: 'sept.', formatted: 'SEP' },
    { current: 'oct.', formatted: 'OCT' },
    { current: 'nov.', formatted: 'NOV' },
    { current: 'déc.', formatted: 'DÉC' },
  ];

  const engList = [
    { current: 'Jan', formatted: 'JAN' },
    { current: 'Feb', formatted: 'FEB' },
    { current: 'Mar', formatted: 'MAR' },
    { current: 'Apr', formatted: 'APR' },
    { current: 'May', formatted: 'MAY' },
    { current: 'Jun', formatted: 'JUN' },
    { current: 'Jul', formatted: 'JUL' },
    { current: 'Aug', formatted: 'AUG' },
    { current: 'Sep', formatted: 'SEP' },
    { current: 'Oct', formatted: 'OCT' },
    { current: 'Nov', formatted: 'NOV' },
    { current: 'Dec', formatted: 'DEC' },
  ];

  const ptList = [
    { current: 'jan', formatted: 'JAN' },
    { current: 'fev', formatted: 'FEV' },
    { current: 'mar', formatted: 'MAR' },
    { current: 'abr', formatted: 'ABR' },
    { current: 'mai', formatted: 'MAI' },
    { current: 'jun', formatted: 'JUN' },
    { current: 'jul', formatted: 'JUL' },
    { current: 'ago', formatted: 'AGO' },
    { current: 'set', formatted: 'SET' },
    { current: 'out', formatted: 'OUT' },
    { current: 'nov', formatted: 'NOV' },
    { current: 'dez', formatted: 'DEZ' },
  ];

  let loopList

  if (selectedLanguage === 'fr') {
    loopList = frenchList;
  } else if (selectedLanguage === 'en') {
    loopList = engList;
  }
  else if (selectedLanguage === 'es') {
    loopList = list;
  } else if (selectedLanguage === 'pt') {
    loopList = ptList;
  }

  for (const i of loopList) {
    if (value.indexOf(i.current) > -1) {
      return value.replace(i.current, i.formatted);
    }
  }
  return value;
};

export const getTomorrow = () => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow;
};

export const getDynamicTableFormat = indicatorKey => {
  if (indicatorColumns.hasOwnProperty(indicatorKey)) {
    return indicatorColumns[indicatorKey.toString()];
  }
  return [];
};

export const getYearsOptions = reports => {
  const yearsGroup = reports
    .map(item => moment(item.dateGenerated).format('YYYY'))
    .filter((value, index, self) => self.indexOf(value) === index);
  const returnedYears: any[] = [];
  yearsGroup.map((year, ind) => returnedYears.push({ value: ind + 1, optionLabel: moment(year).format('YYYY') }));
  return returnedYears.sort(sortAlphabetically('optionLabel', 'desc'));
};

export const expiredDate = date => {
  const today = new Date();
  return moment(date).isSameOrBefore(today, 'day');
};

export const basicYAxes = (data: any) => {
  return [
    {
      ticks: {
        beginAtZero: true,
        min: 0,
        max: data && data.maxYHeigth,
        fontColor: '#727E8C',
        fontSize: 14,
        fontWeight: 600,
        fontFamily: 'Rubik',
        callback(value) {
          return `${thousandsSeparatorToMillion(value)} `;
        },
      },
      display: true,
      id: 'y-axis-1',
      gridLines: {
        display: true,
      },
      labels: {
        show: false,
      },
    },
    {
      ticks: {
        beginAtZero: true,
        min: 0,
        max: data && data.maxYHeigth,
      },
      display: false,
      id: 'y-axis-2',
      gridLines: {
        display: false,
      },
      labels: {
        show: false,
      },
    },
  ];
};

export enum ShowComponentEnum {
  LOAN_IGR_GREATER,
  TCP_BID_IGR_LOWER,
  TCP_AGENCY,
  GUARANTEE,
}

export const showCorrespondingComponent = (filter, executionType, igGreater3) => {
  if (ProjectProductTypes.Loan === filter || ProjectProductTypes.GUARANTEE === filter) {
    return ShowComponentEnum.LOAN_IGR_GREATER;
  } else if (ProjectProductTypes.CT === filter || (ProjectProductTypes.IG === filter && !igGreater3)) {
    return executionType === ProjectExecutionTypes.BID
      ? ShowComponentEnum.TCP_BID_IGR_LOWER
      : ShowComponentEnum.TCP_AGENCY;
  } else if (ProjectProductTypes.IG === filter) {
    return ShowComponentEnum.LOAN_IGR_GREATER;
  } else {
    return null;
  }
};

export const bidConsultancyIndicatorTableColumns = internalFilter => {
  if (internalFilter === 0) {
    return BID_CONSULTANCY;
  } else {
    return [
      ...BID_CONSULTANCY,
      {
        tableHeader: 'source',
        rootProperty: 'field9',
      },
    ];
  }
};
export const duplicateToUniqueKey = (filterArray: Filter[]) => {
  const returnedParamsFilterArray: Filter[] = [];
  filterArray.forEach((item: FilterSingleValue) => {
    const existing = returnedParamsFilterArray.filter((v: FilterSingleValue) => {
      return v.field === item.field && v.filterType === item.filterType;
    });
    if (existing.length) {
      const existingIndex = returnedParamsFilterArray.indexOf(existing[0]);
      returnedParamsFilterArray[existingIndex].value = returnedParamsFilterArray[existingIndex].value.concat(
        item.value,
      );
    } else {
      if (
        typeof item.value === 'string' &&
        (item.value === ProjectVsCompletedIndicatorFilterField.Attributes ||
          item.field !== ProjectVsCompletedIndicatorFilterField.Discriminator)
      ) {
        item.value = [item.value];
      }
      returnedParamsFilterArray.push(item);
    }
  });
  return returnedParamsFilterArray;
};

export const getMergedFilterArray = (filters, selectedTabFilters) => {
  const returnedFilters = filters;
  const priorityFilters = ['DivisionId', 'ResponsibleCountryId', 'BeneficiaryType'];

  priorityFilters.forEach(pf => {
    const filterInFilter = filters.find(filter => filter.field === pf);
    if (!filterInFilter) {
      const filterInTab = selectedTabFilters.find(filter => filter.field === pf);
      filterInTab && returnedFilters.push(filterInTab);
    }
  });

  return returnedFilters;
};

/**Filter GcmAlerts
 * Recibe la prop gcmAlert del proyecto y las agrupa por el contexto del tab que la utiliza.
 */
export const getAlertsGcmByGrouping = (gcmAlerts: IGcmAlert[] = [], calledFromTab) => {
  return gcmAlerts.filter((ga: IGcmAlert) => ga.grouping === GcmGrouping[calledFromTab]);
};

/**
 * Retorna una alerta con la prioridad mas alta dentro de un mismo grupo (propiedad grouping de IGcmAlert).
 */
export const getGcmAlertToShow = (gcmAlerts: IGcmAlert[] = [], calledFromTab): IGcmAlert => {
  const alertByTab = getAlertsGcmByGrouping(gcmAlerts, calledFromTab);
  if (alertByTab.length) {
    return alertByTab.reduce((prev, current) => {
      return prev.type < current.type ? prev : current;
    });
  }
  return EMPTY_GCM_ALERT;
};

export const setBulletColor = projectType => {
  if (projectType === ProjectProductTypes.Loan || projectType === ProjectProductTypes.GUARANTEE) {
    return 'icon-lon';
  } else if (projectType === ProjectProductTypes.CT) {
    return 'icon-ct';
  }
  return 'icon-igr';
};

export const handlePCRProjectResponse = response => {
  const seen = new Set();
  return response.filter(project => {
    const projectSeen = `${project.code}-${project.field2}`;
    const isDuplicate: boolean = seen.has(projectSeen);
    seen.add(projectSeen);
    return !isDuplicate;
  });
};
