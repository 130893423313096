import React from 'react';
import './BarChartDetailContent.scss';
import { BarChart, IChartDimensions } from '../../Portfolio/CardIndicator/Cards';
import Indicator from '../../../models/Indicator';
import { useTranslation } from 'react-i18next';
import { IndicatorKeyEnum, IndicatorKeyEnum as key } from '../../../models/IndicatorKeyEnum';
import { useSelector, useDispatch } from 'react-redux';
import { dispatchFilterIndicatorState } from '../../../redux/actions/filterIndicators';
import { thousandsSeparator } from '../../../services/stringUtil';
import { isMobile } from '../../../services/booleanUtil';
import { PmrClassification } from '../../../models/PmrClassification';
import NumberFormat from 'react-number-format';
import { processBarChartData } from '../PortfolioDetailsUtil';

interface ReceivedProps {
  currentIndicator: Indicator;
  filterValues: any;
  pmrValueSelected?: PmrClassification;
  chartDimensions?: IChartDimensions;
}

export const reStyleChartPmrDivisionChart = (currentIndicator, label) => {
  const elementsIndex = currentIndicator.data.datasets.findIndex(element => element.label === label);
  const reRenderChartStyle = { ...currentIndicator.data.datasets };
  reRenderChartStyle[elementsIndex] = {
    ...reRenderChartStyle[elementsIndex],
    barThickness: 12,
    datalabels: { display: false },
  };
  return reRenderChartStyle[elementsIndex];
};

export const reStyleChartPmrDivisionChartMobile = (currentIndicator, label, selected) => {
  const elementsIndex = currentIndicator.data.datasets.findIndex(element => element.label === label);
  const elementsValues = currentIndicator.data.datasets.find(element => element.label === label);

  const reRenderChartStyle = { ...currentIndicator.data.datasets };

  reRenderChartStyle[elementsIndex] = {
    ...reRenderChartStyle[elementsIndex],
    barThickness: 14,
    backgroundColor: selected ? elementsValues.backgroundColor : '#F5F6FC',
  };
  return reRenderChartStyle[elementsIndex];
};

export const getTotalProjects = currentIndicator => {
  let totalProjects = 0;
  currentIndicator.data.subLabels.map(projects => (totalProjects += projects));
  return totalProjects;
};

type Props = ReceivedProps;

export const detailDataset = (currentIndicator, pmrValueSelected) => {
  switch (currentIndicator.indicatorKey) {
    case key.PMR_PERFORMANCE_LAST_CLOSED_YEAR:
      return {
        ...currentIndicator,
        data: {
          ...currentIndicator.data,
          datasets: isMobile()
            ? [
              reStyleChartPmrDivisionChartMobile(
                currentIndicator,
                'alertChartdata',
                pmrValueSelected === PmrClassification.Yellow ? true : false,
              ),
              reStyleChartPmrDivisionChartMobile(
                currentIndicator,
                'problemChartData',
                pmrValueSelected === PmrClassification.Red ? true : false,
              ),
              reStyleChartPmrDivisionChartMobile(
                currentIndicator,
                'satisChartData',
                pmrValueSelected === PmrClassification.Green ? true : false,
              ),
              reStyleChartPmrDivisionChartMobile(
                currentIndicator,
                'naChartData',
                pmrValueSelected === PmrClassification.Na ? true : false,
              ),
            ]
            : [
              reStyleChartPmrDivisionChart(currentIndicator, 'problemChartData'),
              reStyleChartPmrDivisionChart(currentIndicator, 'alertChartdata'),
              reStyleChartPmrDivisionChart(currentIndicator, 'satisChartData'),
              reStyleChartPmrDivisionChart(currentIndicator, 'naChartData'),
            ],
        },
      };

    case key.PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS_BY_DIVISION:
      return {
        ...currentIndicator,
        data: {
          ...currentIndicator.data,
          datasets: [
            reStyleChartPmrDivisionChart(currentIndicator, 'lonChartData'),
            reStyleChartPmrDivisionChart(currentIndicator, 'tcpChartData'),
            reStyleChartPmrDivisionChart(currentIndicator, 'igrChartData'),
          ],
        },
      };

    default:
      return currentIndicator;
  }
};

export const BarChartDetailContent = (props: Props) => {
  const { t } = useTranslation();

  const filterIndicators = useSelector<any, any>(state => state.indicatorDetailFilter);
  const dispatch = useDispatch();
  const { currentIndicator, filterValues, pmrValueSelected, chartDimensions } = props;

  const listPage = false;
  const detailPageDatasets = detailDataset(currentIndicator, pmrValueSelected);

  const haveDivisionProjectVariables = currentIndicator.type === 'horizontal' ? true : false;

  const totalProjects = haveDivisionProjectVariables && getTotalProjects(currentIndicator);

  const qtyDivision = haveDivisionProjectVariables && detailPageDatasets.data.labels.length;

  const datasetClick = value => {
    dispatchFilterIndicatorState(dispatch, filterIndicators, true);
    filterValues(value, totalProjects);
  };

  const barChartData = processBarChartData(detailPageDatasets, t);

  const transctionalSupervisionResourcesOptions = {
    plugins: {
      datalabels: {
        display: false,
      },
    },
    legend: {
      display: false,
    },
    tooltips: {
      enabled: true,
      displayColors: false,
      backgroundColor: '#101319',
      titleFontSize: 14, 
      bodyFontSize: 14,
      callbacks: {
        label(tooltipItem, data) {
          return `${thousandsSeparator(tooltipItem.value)} USD`;
        },
      },
    },

    scales: {
      xAxes: [
        {
          stacked: false,
          ticks: {
            beginAtZero: true,
            fontSize: 16,
            fontStyle: 400,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          display: true,
          stacked: false,

          ticks: {
            beginAtZero: true,
            display: true,
            callback: value => {
              return value <= 1 && value >= 0 ? value : value / 1000000;
            },
          },
          gridLines: {
            display: true,
          },
        },
      ],
    },
    cornerRadius: 9,

  };

  return (
    <div className="bar-chart-detail-container">
      <div className="title">{t(detailPageDatasets.detailChartTitle)}</div>
      {haveDivisionProjectVariables && (
        <>
          <div className="sub-title">
            <span className="capitalize-word"> {t('total')}</span>: {` ${qtyDivision} `}
            {t('divisions')}
          </div>
        </>
      )}
      {currentIndicator.indicatorKey === IndicatorKeyEnum.SUPERVISION_PLAN_BUDGET_STATUS && (
        <div className="chart-y-title">{`${t('dolar_sign')} (M)`}</div>
      )}

      {currentIndicator.indicatorKey === IndicatorKeyEnum.SUPERVISION_PLAN_BUDGET_STATUS ? (
        <div className="chart-container-supervision-plan">
          <BarChart
            type={detailPageDatasets.type}
            data={barChartData}
            showSubLabels={false}
            listPage={listPage}
            datasetClick={datasetClick}
            chartDimensions={chartDimensions}
            chartOptions={transctionalSupervisionResourcesOptions}
            indicatorKey={IndicatorKeyEnum.SUPERVISION_PLAN_BUDGET_STATUS}
          />
        </div>
      ) : (
        <div className="chart-container">
          {currentIndicator.indicatorKey ===
            IndicatorKeyEnum.PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS_BY_DIVISION ? (
            <BarChart
              type={detailPageDatasets.type}
              data={barChartData}
              showSubLabels={false}
              listPage={listPage}
              datasetClick={datasetClick}
              chartDimensions={handleDynamicChartDimensions(barChartData)}
            />
          ) : (
            <BarChart
              type={detailPageDatasets.type}
              data={barChartData}
              showSubLabels={false}
              listPage={listPage}
              datasetClick={datasetClick}
            />
          )}
        </div>
      )}

      {supervisioPlanBudgetList(currentIndicator.indicatorKey, barChartData, t)}

      {haveDivisionProjectVariables && (
        <>
          <div className="x-description">{t(detailPageDatasets.detailChartFoot)}</div>
          <div className="sub-title-footer">
            <span className="capitalize-word"> {t('total')}</span>: {totalProjects} {t('projects')}
          </div>
        </>
      )}
    </div>
  );
};

const handleDynamicChartDimensions = barChartData => {
  const quantity = barChartData && barChartData.completeGroupingValue && barChartData.completeGroupingValue.length;
  return {
    mobile: { width: 320, height: quantity ? quantity * 20 : 320 },
    desktop: { width: 320, height: quantity ? quantity * 35 : 550, cursor: 'pointer' },
  };
};

const supervisioPlanBudgetList = (indicatorKey, barChartData, t) => {
  if (indicatorKey === IndicatorKeyEnum.SUPERVISION_PLAN_BUDGET_STATUS) {
    const data = barChartData.datasets[0].data;
    const label = barChartData.datasets[0].label;
    const spent = { data: data[1], text: t(label[0]) };
    const commitedSupervision = { data: data[2], text: t(label[1]) };
    const available = { data: data[3], text: t(label[2]) };
    const balance = { data: data[4], text: t(label[3]) };
    return (
      <div>
        <ul className="list-info">
          <li className="spent">
            {`${spent.text} `}
            <NumberFormat
              value={spent.data}
              displayType={'text'}
              thousandSeparator={'.'}
              decimalSeparator={','}
              decimalScale={2}
            />
            {`${t('dolar_sign')}`}
          </li>
          <li className="commited-supervision">
            {`${commitedSupervision.text} `}
            <NumberFormat
              value={commitedSupervision.data}
              displayType={'text'}
              thousandSeparator={'.'}
              decimalSeparator={','}
              decimalScale={2}
            />
            {`${t('dolar_sign')}`}
          </li>
          <li className="available">
            {`${available.text} `}
            <NumberFormat
              value={available.data}
              displayType={'text'}
              thousandSeparator={'.'}
              decimalSeparator={','}
              decimalScale={2}
            />
            {`${t('dolar_sign')}`}
          </li>
          <li className="balance">
            {`${balance.text} `}
            <NumberFormat
              value={balance.data}
              displayType={'text'}
              thousandSeparator={'.'}
              decimalSeparator={','}
              decimalScale={2}
            />
            {`${t('dolar_sign')}`}
          </li>
        </ul>
      </div>
    );
  } else {
    return null;
  }
};
