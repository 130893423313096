import { SET_REVIEW_NAVIGATION, RESET_REVIEW_NAVIGATION } from './types';

export function setReviewNavigation(tabValue: object) {
  return {
    type: SET_REVIEW_NAVIGATION,
    payload: tabValue,
  };
}

export function resetReviewNavigation() {
  return {
    type: RESET_REVIEW_NAVIGATION,
  };
}
