import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './SelectFilters.scss';
import moment from 'moment';

type Props = {
  labelValue: any;
  options?: any;
  disabled?: boolean;
  handleChange?: any;
  showAllOption?: boolean;
  selectValue?: any;
  dataFilter?: any;
  data?: any;
  fnFilterOption?: any;
  fnSetValue?: any;
  detailProject?: boolean;
  btnDataFilterPublic?: any;
};
export const SelectFilters = ({
  labelValue,
  options,
  disabled,
  handleChange,
  showAllOption = true,
  selectValue,
  data,
  fnSetValue,
  detailProject = false,
  btnDataFilterPublic,
}: Props) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (selectValue === 'top100') {
      const topFilter = data?.sort((a, b) => b.amount - a.amount)?.slice(0, 100);
      handleChange(topFilter); // Aplica el filtro de top 100
    } else if (selectValue === '0') {
      handleChange(data); // Muestra todos los datos
    } else if (selectValue === 'dateStimated') {
      const dateStimatedFilter = data?.filter(
        data => moment(data?.estimatedPublicationDate).year() === moment(new Date()).year(),
      );
      handleChange(dateStimatedFilter); // Aplica el filtro de fechas estimadas
    } else if (selectValue === 'dateHiring') {
      const dateHiringFilter = data?.filter(
        data => moment(data?.estimatedContractDate).year() === moment(new Date()).year(),
      );
      handleChange(dateHiringFilter);
    }
  }, []);

  // funcion para filtrar desde la vista del indicador hitos
  const selectHandler = (event: any) => {
    const value = event.target.value;
    if (value === 'top100' && btnDataFilterPublic.length > 0) {
      const topFilter = btnDataFilterPublic?.sort((a, b) => b.field3 - a.field3)?.slice(0, 100);
      handleChange(topFilter);
      fnSetValue('top100');
    } else if (value === 'top100') {
      const topFilter = data?.sort((a, b) => b.field3 - a.field3)?.slice(0, 100);
      handleChange(topFilter);
      fnSetValue('top100');
    } else if (value === '0' && btnDataFilterPublic.length > 0) {
      handleChange(btnDataFilterPublic);
      fnSetValue('Todos');
    } else if (value === '0') {
      handleChange(data);
      fnSetValue('Todos');
    }
  };

  // funcion para filtrar desde la vista de la pestaña
  const selectHandlerDetailProject = (event: any) => {
    const value = event.target.value;
    fnSetValue(value);
    if (value === 'top100') {
      const topFilter = data?.sort((a, b) => b.amount - a.amount)?.slice(0, 100);
      handleChange(topFilter);
    } else if (value === '0') {
      handleChange(data);
    } else if (value === 'dateStimated') {
      const dateStimatedFilter = data?.filter(
        data => moment(data?.estimatedPublicationDate).year() === moment(new Date()).year(),
      );
      handleChange(dateStimatedFilter);
    } else if (value === 'dateHiring') {
      const dateStimatedFilter = data?.filter(
        data => moment(data?.estimatedContractDate).year() === moment(new Date()).year(),
      );
      handleChange(dateStimatedFilter);
    }
  };

  return (
    <div className="select-filter-container">
      <span className="select-filter-data-text">{labelValue}</span>
      <select
        data-testid="select"
        value={selectValue}
        className={'select-css select-width-large'}
        onChange={detailProject ? selectHandlerDetailProject : selectHandler}
        disabled={disabled}
      >
        {showAllOption && <option value="0">{t('all')}</option>}
        {options &&
          options.map(option => (
            <option key={option.value} value={option.value} className="capitalize">
              {t(option.optionLabel)}
            </option>
          ))}
      </select>
    </div>
  );
};
