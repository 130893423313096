import { PmrClassification } from './../../../../models/PmrClassification';
import { ProjectProductTypeClassification } from '../../../../models/ProjectProductTypeClassification';
import { showIndexTCDI } from '../../../../services/booleanUtil';

const addSortableColumn = projects => {
  return projects.map(project => {
    return showIndexTCDI(
      project.projectProductType,
      project &&
        project.projectProductTypeClassification &&
        project.projectProductTypeClassification.code === ProjectProductTypeClassification.IGR_GREATER_THAN_3MM,
    )
      ? { ...project, tcdiPmrValue: project.tcdiValue !== null ? project.tcdiValue : -1 }
      : { ...project, tcdiPmrValue: project.pmrClassification === PmrClassification.Na ? -1 : project.pmrValue };
  });
};

const handleTcdiValueOnTcdiComponent = tcdiValue => {
  return tcdiValue === 0 ? 0.0 : tcdiValue || null;
};

export { addSortableColumn, handleTcdiValueOnTcdiComponent };
