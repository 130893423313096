import Project from '../../../models/Project';

export const initialDisbursement: Project['financialInformation'] = {
  lastDisbursementInDays: 0,
  totalDisbursementAmount: 0,
  realizedDisbursementAmount: 0,
  percentRealizedDisbursement: 0,
  pendingDisbursementAmount: 0,
  percentPendigDisbursementAmount: 0,
  lastDisbursementDate: '',
  nextDisbursementDate: '',
  availableAmount: 0,
  commitedAmount: 0,
  percentAvailableAmount: 0,
  PercentCommitedAmount: 0,
  currentBid: 0,
  currentLocal: 0,
  contractualBid: 0,
  contractualLocal: 0,
  minimumAmountToJustify: 0,
  minimumPercentToJustify: 0,
  pariPassuDeviationAmount: 0,
  pariPassuDeviationPercent: 0,
};
export const initialTemporaryAdvance = {
  totalProjectDuration: 0,
  temporaryAdvanceMade: 0,
  percentTemporaryAdvanceMade: 0,
  pendingAdvance: 0,
  percentPendingAdvance: 0,
};

export const initialDivision = { name: '', id: 0, code: '' };
