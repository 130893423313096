import React, { useEffect, useState } from 'react';
import './PortfolioEsgBarchartDetailContent.scss';
import { IndicatorKeyEnum } from '../../../models/IndicatorKeyEnum';
import { WorkWithFilterdChart } from '../WorkWithFilterdChart/WorkWithFilterdChart';
import { FilteredChartDetailContent } from '../FilteredChartDetailContent/FilteredChartDetailContent';
import { EsgFilterChart } from '../EsgFilterChart/EsgFilterChart';
import { useDispatch, useSelector } from 'react-redux';
import { setIndicatorDetailFilterChart } from '../../../redux/actions/indicatorDetailFilters';
import { EarlyWarningsFilters } from '../EarlyWarningsFilters/EarlyWarningsFilters';
import { IconCode, TabFilterOption } from '../../../models/EarlyWarnings';

type Props = {
  currentIndicator: any;
  headerTitle: any;
};

const filterConfigComponent: TabFilterOption[] = [
  {
    id: 0,
    projectQuantity: 0,
    projectLabel: { singular: 'project', plural: 'projects' },
    filterIcon: IconCode.SATISFACTORY,
    filterLabel: 'satisfactory',
    selected: false,
  },
  {
    id: 1,
    projectQuantity: 0,
    projectLabel: { singular: 'project', plural: 'projects' },
    filterIcon: IconCode.PARTIALLY_SATISFACTORY,
    filterLabel: 'partially_satisfactory',
    selected: false,
  },
  {
    id: 3,
    projectQuantity: 0,
    projectLabel: { singular: 'project', plural: 'projects' },
    filterIcon: IconCode.YELLOW,
    filterLabel: 'partially_unsatisfactory',
    selected: false,
  },
  {
    id: 2,
    projectQuantity: 0,
    projectLabel: { singular: 'project', plural: 'projects' },
    filterIcon: IconCode.RED,
    filterLabel: 'unsatisfactory',
    selected: false,
  },
];

const handleQuantityProjectsEarlyWarningFilter = (
  dataQtyProjects: number[],
  filterEarlyConfigState: TabFilterOption[],
  firstTime = false,
): TabFilterOption[] => {
  const firstIndexWithValues = dataQtyProjects && dataQtyProjects.findIndex(item => item !== 0);
  return filterEarlyConfigState.map(tfo => {
    if (firstTime && tfo.id === firstIndexWithValues) {
      tfo.selected = true;
    }
    tfo.projectQuantity = dataQtyProjects && dataQtyProjects[tfo.id];
    return tfo;
  });
};

const handleEarlyWarningsSelectedButton = (
  barSelected: string,
  filterEarlyConfigState: TabFilterOption[],
): TabFilterOption[] => {
  return filterEarlyConfigState.map(tfo => {
    tfo.selected = tfo.filterLabel === barSelected;
    return tfo;
  });
};

export const PortfolioEsgBarchartDetailContent = ({ currentIndicator, headerTitle }: Props) => {
  const dispatch = useDispatch();
  const [filterConfigState, setFilterConfigState] = useState(
    handleQuantityProjectsEarlyWarningFilter(
      currentIndicator.data && currentIndicator.data.dataQtyProjects,
      filterConfigComponent,
    ),
  );
  const [sectorFiltersState, setSectorFiltersState] = useState({});
  const [regionFiltersState, setRegionFiltersState] = useState({});
  const [filterTable, setFilterTable] = useState({ label: '', totalProjects: 0 });
  const filterIndicators = useSelector<any, any>(state => state.indicatorDetailFilter);

  useEffect(() => {
    const [firstLabelWithValues, totalProjects, sectors, regions]: any = setFirstConceptWithInfo(
      currentIndicator,
      filterIndicators,
      dispatch,
    );
    setFilterConfigState(
      handleQuantityProjectsEarlyWarningFilter(
        currentIndicator.data.dataQtyProjects,
        filterConfigState,
        filterIndicators.indicatorCharts.firstTime,
      ),
    );
    sectors !== undefined && setSectorFiltersState(sectors);
    regions !== undefined && setRegionFiltersState(regions);

    setFilterTable({ label: firstLabelWithValues, totalProjects });
    headerTitle(true, setDetailTitle(currentIndicator.indicatorKey));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterValues = (value, totalProjects) => {
    setFilterConfigState(handleEarlyWarningsSelectedButton(value.label, filterConfigState));
    setFilterTable({ label: value.label, totalProjects });
    dispatchIndicatorFilterChartFirstTime(
      dispatch,
      filterIndicators,
      { label: value.label, totalProjects },
      currentIndicator.indicatorKey,
      false,
    );
  };
  const sectorFiltersValues = filters => {
    setSectorFiltersState(filters);
    dispatchIndicatorFilterSector(dispatch, filterIndicators, filters);
  };

  const regionFiltersValues = filters => {
    setRegionFiltersState(filters);
    dispatchIndicatorFilterRegion(dispatch, filterIndicators, filters);
  };

  const handleEarlyWarningsFilter = (filter: TabFilterOption) => {
    const value = { label: filter.filterLabel };
    filterValues(value, filter.projectQuantity);
  };

  return (
    <div className="portfolio-esg-barchart-detail-content-container">
      {currentIndicator.indicatorKey === IndicatorKeyEnum.ESG_SAFEGUARD_PERFORMANCE_RATING && (
        <div className="early-warnings-filter">
          <EarlyWarningsFilters filterSet={filterConfigState} handleFilter={handleEarlyWarningsFilter} />
        </div>
      )}
      <div className="chart-and-filter-content">
        <div className="filter-container">
          <EsgFilterChart
            indicatorKey={currentIndicator.indicatorKey}
            sectorFilter={sectorFiltersValues}
            regionFilter={regionFiltersValues}
          />
          <FilteredChartDetailContent currentIndicator={currentIndicator} filterValues={filterValues} />
        </div>
      </div>
      <div className="table-content">
        <WorkWithFilterdChart
          currentIndicator={currentIndicator}
          conceptFilter={filterTable}
          sectorFilters={sectorFiltersState}
          regionFilters={regionFiltersState}
        />
      </div>
    </div>
  );
};

const setDetailTitle = indicatoKey => {
  switch (indicatoKey) {
    case IndicatorKeyEnum.ESG_SAFEGUARD_PERFORMANCE_RATING:
      return 'distribution_according_to_environmental_and_social_performance_SPR';
    case IndicatorKeyEnum.ESG_ENVIRONMENTAL_AND_SOCIAL_RISK_RATING:
      return 'distribution_according_to_environmental_and_social_risk_ESRR';

    default:
      return '';
  }
};

const setFirstConceptWithInfo = (currentIndicator, filterIndicators, dispatch) => {
  let sectors = undefined;
  let regions = undefined;
  switch (currentIndicator.indicatorKey) {
    case IndicatorKeyEnum.ESG_SAFEGUARD_PERFORMANCE_RATING:
    case IndicatorKeyEnum.ESG_ENVIRONMENTAL_AND_SOCIAL_RISK_RATING:
      const firstIndexWithValues = currentIndicator.data.dataQtyProjects.findIndex(item => item !== 0);
      const firstLabelWithValues = filterIndicators.indicatorCharts.firstTime
        ? currentIndicator.data.dataListTranslationLabels[firstIndexWithValues]
        : filterIndicators.indicatorCharts.subTitle.label;
      const totalProjects = currentIndicator.data.dataQtyProjects.reduce((a, b) => a + b, 0);

      if (!filterIndicators.indicatorCharts.firstTime) {
        if (filterIndicators.indicatorCharts.sector) {
          sectors = filterIndicators.indicatorCharts.sector;
        }

        if (filterIndicators.indicatorCharts.region) {
          regions = filterIndicators.indicatorCharts.region;
        }
      }

      if (filterIndicators.indicatorCharts.firstTime) {
        dispatchIndicatorFilterChartFirstTime(
          dispatch,
          filterIndicators,
          { label: firstLabelWithValues, totalProjects },
          currentIndicator.indicatorKey,
          false,
        );
      }

      return [firstLabelWithValues, totalProjects, sectors, regions];

    default:
      return '';
  }
};

const dispatchIndicatorFilterChartFirstTime = (d, filterIndicators, conceptFilter, indicatorKey, firstTime) => {
  d(
    setIndicatorDetailFilterChart({
      ...filterIndicators.indicatorCharts,
      chartTitle: indicatorKey,
      subTitle: conceptFilter,
      firstTime,
    }),
  );
};

const dispatchIndicatorFilterSector = (d, filterIndicators, sector) => {
  d(
    setIndicatorDetailFilterChart({
      ...filterIndicators.indicatorCharts,
      sector,
    }),
  );
};
const dispatchIndicatorFilterRegion = (d, filterIndicators, region) => {
  d(
    setIndicatorDetailFilterChart({
      ...filterIndicators.indicatorCharts,
      region,
    }),
  );
};
