import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next';
import { useFormMemoryHelpContext } from '../../../context/MemoryHelpFormContext';
import { extractDate } from '../utils';

const TabText = ({ index }) => {
    const { t } = useTranslation();

    const { agreementsData } = useFormMemoryHelpContext();

    const [formattedStartDate, setFormattedStartDate] = useState('');

    useEffect(() => {
        if (agreementsData) {
            const startDate = agreementsData?.dataRevision?.startDate
            setFormattedStartDate(extractDate(startDate));
        }
    }, [agreementsData]);

    const arraySteps = [
        t('memory_help.tabs.1'),
        <Trans i18nKey="memory_help.tabs.2">
            {{ value1: formattedStartDate }}
        </Trans>,
        t('memory_help.tabs.3'),
        t('memory_help.tabs.4'),
        t('memory_help.tabs.5'),
        t('memory_help.tabs.6'),
        t('memory_help.tabs.7'),
        t('memory_help.tabs.8'),
    ];

    const text = arraySteps[index];

    return <label>{text}</label>;
};

export default TabText
