import { axiosDefaultConfig } from './axiosConfig';
import * as config from './config.json';

const projectIdReplaceValue = '{projectId}';
const memberIdReplaceValue = '{memberId}';

export const getRoles = () => {
  const url = config.PROJECT_TEAM_ROLES;
  return new Promise<any>(async (resolve, reject) => {
    try {
      const response = await axiosDefaultConfig.get(url);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const newExecUnit = (projectId, data) => {
  const url = config.PROJECT_TEAM_CREATE.replace(projectIdReplaceValue, projectId.toString());
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosDefaultConfig.post(url, data);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const editExecUnit = (memberId, data) => {
  const url = config.PROJECT_TEAM_UPDATE.replace(memberIdReplaceValue, memberId.toString());
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosDefaultConfig.put(url, data);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const deleteExecUnit = memberId => {
  const url = config.PROJECT_TEAM_DELETE.replace(memberIdReplaceValue, memberId.toString());
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosDefaultConfig.delete(url);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};
