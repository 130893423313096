import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setConvergenceModal } from '../../../../redux/actions/convergenceModal';
import { getActiveMessageService } from '../../../../api/contentManager';
import Language from '../../../../models/Language';
import './ConvergenceModal.scss';

const ConvergenceModal = () => {
  const dispatch = useDispatch();
  const [activeMessage, setActiveMessage] = React.useState<string>('');
  const encodedName = useSelector<any, Language>(state => state.user.language.encodedName);

  const getActiveMessage = async () => {
    setActiveMessage(await getActiveMessageService(encodedName));
  };

  useEffect(() => {
    getActiveMessage();
    // eslint-disable-next-line
  }, [encodedName]);

  return activeMessage ? (
    <div className="modal-convergence-container">
      <p>{activeMessage}</p>
      <span className="close-button" onClick={() => dispatch(setConvergenceModal(false))}></span>
    </div>
  ) : null;
};

export default ConvergenceModal;
