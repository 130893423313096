import { NotificationsActionTypes, SET_NOTIFICATIONS, SET_NOTIFICATIONS_TOTAL_PAGES } from '../actions/types';
import Notifications from '../../models/Notifications';
import { sortDates } from '../../services/sortsUtil';

const initialNotifications: Notifications[] = [];
const initialPage: any = { pageLoaded: 1, totalPages: 1 };

const notificationsReducer = (state = initialNotifications, action: NotificationsActionTypes): Notifications[] => {
  switch (action.type) {
    case SET_NOTIFICATIONS:
      return action.payload.sort(sortDates('creationDate', 'desc'));
    default:
      return state;
  }
};

export const notificationsTotalPagesReducer = (state = initialPage, action: NotificationsActionTypes): any => {
  switch (action.type) {
    case SET_NOTIFICATIONS_TOTAL_PAGES:
      return action.payload;
    default:
      return state;
  }
};

export default notificationsReducer;
