import React, { useEffect, useState } from 'react';
import styles from './MultipleStackedBar.module.scss';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { IndicatorAnalyticFilterEnum as IndicatorFilter } from '../../../../../../models/IndicatorAnalyticFilterEnum';
import Filters from './Filters';
import {
  conditionalFunction,
  translateRenderSimpleDataBars,
  handleButtonsClassName,
  setRenderDataBarsModal,
} from './util';
import Chart from './Chart';

type Props = {
  card?: any;
  openComplexModal?: any;
  isOpen?: boolean;
  simple?: boolean;
  sessionIndFilter?: any;
  updateSessionIndFilter?: any;
};

export const MultipleStackedBar = ({
  card,
  openComplexModal,
  isOpen,
  simple,
  sessionIndFilter,
  updateSessionIndFilter,
}: Props) => {
  const { title, subtitle } = card;
  const { t } = useTranslation();
  const [indicatorFilterState, setIndicatorFilterState] = useState(IndicatorFilter.RolesDates);
  const [customSelectState, setCustomSelectState] = useState<any>(
    handleSetCustomSelectState(isOpen, simple, sessionIndFilter, t),
  );
  const [datesRadioSelectState, setDatesRadioSelectState] = useState(true);
  const [dinamicRadioText, setDinamicRadioText] = useState('roles');
  const [xRange, setXRange] = useState(cloneDeep(card.data.xRange));
  const [renderXRange, setRenderXrange] = useState(cloneDeep(card.data.xRange));
  const [sliceFrom, setSliceFrom] = useState(xRange.length - 7);
  const [sliceTo, setSliceTo] = useState(xRange.length);
  const [totalAccumulatedList, setTotalAccumulatedList] = useState(cloneDeep(card.data.rolesAndDatesDataSetObject));
  const [dataBars, setDataBars] = useState(
    cloneDeep(setRenderDataBarsModal(card.data.rolesAndDatesDataSetObject, isOpen, simple)),
  );
  const [renderDataBars, setRenderDataBars] = useState(
    cloneDeep(setRenderDataBarsModal(card.data.rolesAndDatesDataSetObject, isOpen, simple)),
  );
  const [renderSimpleXRange, setRenderSimpleXrange] = useState(handleSetRenderDataBars(card));
  const [renderSimpleDataBars, setRenderSimpleDataBars] = useState(card.data.countriesDataSetObject);
  const [prevButtonDisabled, setPrevButtonDisabled] = useState(true);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);

  const changeDataset = indiatorFilter => {
    switch (indiatorFilter) {
      case IndicatorFilter.CountriesDates:
        setDataBars(cloneDeep(setRenderDataBarsModal(card.data.countriesAndDatesDataSetObject, isOpen, simple)));
        setRenderDataBars(cloneDeep(setRenderDataBarsModal(card.data.countriesAndDatesDataSetObject, isOpen, simple)));
        setTotalAccumulatedList(cloneDeep(card.data.countriesAndDatesDataSetObject));
        setXRange(cloneDeep(card.data.xRange));
        setSlices(card.data.xRange, setPrevButtonDisabled, setNextButtonDisabled, setSliceFrom, setSliceTo);
        break;
      case IndicatorFilter.Countries:
        setRenderSimpleXrange(card.data.countriesDataSetObject[0].xRange.map(obj => obj.name));
        setRenderSimpleDataBars(card.data.countriesDataSetObject);
        break;
      case IndicatorFilter.Roles:
        setRenderSimpleXrange(card.data.rolesDataSetObject[0].xRange);
        setRenderSimpleDataBars(card.data.rolesDataSetObject);
        break;
      default:
        setDataBars(cloneDeep(setRenderDataBarsModal(card.data.rolesAndDatesDataSetObject, isOpen, simple)));
        setRenderDataBars(cloneDeep(setRenderDataBarsModal(card.data.rolesAndDatesDataSetObject, isOpen, simple)));
        setTotalAccumulatedList(cloneDeep(card.data.rolesAndDatesDataSetObject));
        setXRange(cloneDeep(card.data.xRange));
        setSlices(card.data.xRange, setPrevButtonDisabled, setNextButtonDisabled, setSliceFrom, setSliceTo);
        break;
    }
  };

  const handleCustomSelectChange = item => {
    handleSetDinamicRadioText(item, setDinamicRadioText);
    setCustomSelectState(item);
    updateSessionIndFilter(item);
  };

  const handleRadioButtonState = () => setDatesRadioSelectState(!datesRadioSelectState);

  const handleRanges = (sliceFrom, sliceTo) => setRenderXrange(xRange.slice(sliceFrom, sliceTo));

  const handleDataBars = (sliceFrom, sliceTo) => {
    const slicedRoles = dataBars.map(r => r.data.slice(sliceFrom, sliceTo));
    renderDataBars.forEach((element, ind) => {
      element.data = slicedRoles[ind];
    });
    setRenderDataBars(renderDataBars);
  };

  useEffect(() => {
    setTotalAccumulatedList(
      totalAccumulatedList
        .slice(0, totalAccumulatedList.length - 1)
        .sort((a, b) => (a.totalAccumulated > b.totalAccumulated ? -1 : 1))
        .concat(totalAccumulatedList.slice(totalAccumulatedList.length - 1, totalAccumulatedList.length)),
    );
    handleRanges(sliceFrom, sliceTo);
    handleDataBars(sliceFrom, sliceTo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataBars]);

  useEffect(() => {
    getIndicatorFilterState(datesRadioSelectState, customSelectState, changeDataset, setIndicatorFilterState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customSelectState, datesRadioSelectState, card]);

  const handlePrev = () => {
    setSliceFrom(sliceFrom - 1);
    setSliceTo(sliceTo - 1);
    handleRanges(sliceFrom - 1, sliceTo - 1);
    handleDataBars(sliceFrom - 1, sliceTo - 1);

    if (sliceFrom === 1) {
      setPrevButtonDisabled(true);
    }

    if (nextButtonDisabled) {
      setNextButtonDisabled(false);
    }
  };

  const handleNext = () => {
    setSliceFrom(sliceFrom + 1);
    setSliceTo(sliceTo + 1);
    handleRanges(sliceFrom + 1, sliceTo + 1);
    handleDataBars(sliceFrom + 1, sliceTo + 1);

    if (sliceTo === card.data.xRange.length - 1) {
      setNextButtonDisabled(true);
    }

    if (prevButtonDisabled) {
      setPrevButtonDisabled(false);
    }
  };

  const data = {
    labels: renderXRange,
    datasets: renderDataBars,
  };

  const simpleData = {
    labels: translateRenderSimpleDataBars(renderSimpleXRange, t),
    datasets: renderSimpleDataBars,
  };

  return (
    <div>
      {!isOpen && (
        <>
          <div className={styles.title}>{t(title)}</div>
          <div className={styles.subtitle}>{t(subtitle)}</div>
          {!simple && (
            <Filters
              handleFirstRadio={handleRadioButtonState}
              firstRadioChecked={datesRadioSelectState}
              handleSecondRadio={handleRadioButtonState}
              secondRadioChecked={!datesRadioSelectState}
              secondRadioLabel={t(dinamicRadioText)}
              options={filterCustomSelectOptions(t)}
              handleSelect={handleCustomSelectChange}
              selectedOption={customSelectState}
            />
          )}
        </>
      )}
      <div className={handleButtonsClassName(isOpen)}>
        <div
          onClick={handlePrev}
          className={conditionalFunction(prevButtonDisabled, styles.prevButtonDisabled, styles.prevButton)}
        ></div>
        <div
          onClick={handleNext}
          className={conditionalFunction(nextButtonDisabled, styles.nextButtonDisabled, styles.nextButton)}
        ></div>
      </div>
      <Chart
        indicatorFilterState={indicatorFilterState}
        simpleData={simpleData}
        data={data}
        isOpen={isOpen}
        datesRadioSelectState={datesRadioSelectState}
        customSelectState={customSelectState}
        simple={simple}
        totalAccumulatedList={totalAccumulatedList}
        openComplexModal={openComplexModal}
      />
    </div>
  );
};

const getIndicatorFilterState = (datesRadioSelectState, customSelectState, changeDataset, setIndicatorFilterState) => {
  if (datesRadioSelectState) {
    if (customSelectState.id === 0) {
      setIndicatorFilterState(IndicatorFilter.RolesDates);
      changeDataset(IndicatorFilter.RolesDates);
    } else {
      setIndicatorFilterState(IndicatorFilter.CountriesDates);
      changeDataset(IndicatorFilter.CountriesDates);
    }
  } else {
    if (customSelectState.id === 0) {
      setIndicatorFilterState(IndicatorFilter.Roles);
      changeDataset(IndicatorFilter.Roles);
    } else {
      setIndicatorFilterState(IndicatorFilter.Countries);
      changeDataset(IndicatorFilter.Countries);
    }
  }
};

const filterCustomSelectOptions = t => {
  return [
    { value: '0', id: 0, label: t('role') },
    { value: '1', id: 1, label: t('countries') },
  ];
};

const handleSetCustomSelectState = (isOpen, simple, sessionIndFilter, t) => {
  return isOpen && !simple ? sessionIndFilter : filterCustomSelectOptions(t)[0];
};

const setSlices = (range, setPrevButtonDisabled, setNextButtonDisabled, setSliceFrom, setSliceTo) => {
  if (range.length <= 7) {
    setPrevButtonDisabled(true);
    setNextButtonDisabled(true);
    setSliceFrom(range.length - 7);
    setSliceTo(range.length);
  } else if (range.length <= 12) {
    setPrevButtonDisabled(true);
    setNextButtonDisabled(true);
    setSliceFrom(range.length - 12);
    setSliceTo(range.length);
  } else {
    setPrevButtonDisabled(false);
    setNextButtonDisabled(true);
    setSliceFrom(range.length - 12);
    setSliceTo(range.length);
  }
};

const handleSetRenderDataBars = card => {
  return card.data.countriesDataSetObject ? card.data.countriesDataSetObject[0].xRange : card.data.xRange;
};

const handleSetDinamicRadioText = (item, setDinamicRadioText) => {
  return item.id === 0 ? setDinamicRadioText('roles') : setDinamicRadioText('countries');
};
