import React, { useEffect, useState } from 'react';
import BaseModal from '../../../../Util/Modals/BaseModal';
import { styles } from '../../../../Util/Modals/Styles';
import './ModalPcr.scss';
import CloseCrossButton from '../../../../Util/Buttons/CloseCrossButton/CloseCrossButton';
import TableModalPcrAccordances from './TableModalPcrAccordances';
import { useTranslation } from 'react-i18next';

type Props = {
  isOpen: boolean;
  style?: ReactModal.Styles;
  className?: string;
  onRequestClose?: () => void;
  shouldCloseOnOverlayClick?: boolean;
  closeModal: any;
  data: any;
};

const ModalPcrAccordances = (props: Props) => {
  const { isOpen, closeModal, data } = props;
  const { t } = useTranslation();

  const [accordancesData, setAccordancesData] = useState<any>();

  useEffect(() => {
    if (data) {
      setAccordancesData(data);
    }
  }, [data]);

  return (
    <BaseModal isOpen={isOpen} style={styles} className="modal-pcr">
      <div className="close">
        <CloseCrossButton handleClick={closeModal} />
      </div>
      {accordancesData ? (
        <h2 className="ci-type-text-1">{`${accordancesData[0]?.code}: ${accordancesData[0]?.title}`}</h2>
      ) : null}
      <p className="title">{t('new_subtitle_accordance_pcr')}</p>

      <div className="table-pcr">
        <TableModalPcrAccordances accordancesData={accordancesData} />
      </div>
    </BaseModal>
  );
};

export default ModalPcrAccordances;
