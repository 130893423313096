import React from 'react';
import { components } from 'react-select';
import { ReactComponent as ArrowDropDown } from '../../assets/select-arrow-down-black.svg';
import { isMobile } from '../../services/booleanUtil';

export const styles = {
  content: {
    marginLeft: 'auto',
    marginRight: 0,
  },
  overlay: {
    backgroundColor: 'none',
    zIndex: 4,
  },
};

const optionObject = (styles, isDisabled, isSelected, isFocused, data, customSelectedColor = '') => {
  return {
    ...styles,
    backgroundColor: isDisabled ? null : isSelected ? data.color : isFocused ? '#F1F3FF' : null,
    color: isSelected ? customSelectedColor || '#264377' : data.color,
    cursor: isDisabled ? 'not-allowed' : 'default',
  };
};
const multiValueObject = (styles, isDisabled, data) => {
  return {
    ...styles,
    backgroundColor: isDisabled || data.isFixed ? 'white' : '#F1F3FF',
    borderRadius: '20px',
    borderWidth: 1,
  };
};

const multiValueLabelObject = (styles, data) => {
  return {
    ...styles,
    color: '#424d64', //$ci-color-greyscale-70
    lineHeight: '16px', //.ci-type-text-8
    fontSize: '12px', //.ci-type-text-8
    fontWeight: 400, //.ci-type-text-8
  };
};

const multiValueRemoveObject = (styles, isDisabled, data) => {
  return {
    ...styles,
    display: data.isFixed ? 'none' : 'flex',
    color: isDisabled ? 'transparent' : data.color,
    backgroundColor: isDisabled || data.isFixed ? 'white' : data.color,
    ':hover': {
      backgroundColor: data.color,
      color: 'black',
    },
  };
};

export const multiSelectStyle = {
  control: styles => ({
    ...styles,
    cursor: 'pointer',
    backgroundColor: 'white',
    borderRadius: '8px',
    borderWidth: 1,
    borderColor: '#7a8aa9', //$ci-color-greyscale-50
    boxShadow: 'none',
    height: 'min-content',
    minHeight: 38,
    maxWidth: '100%',
  }),
  multiValueLabel: (styles, { data }) => multiValueLabelObject(styles, data),
  multiValueRemove: (styles, { data, isDisabled }) => multiValueRemoveObject(styles, isDisabled, data),
  multiValue: (styles, { data, isDisabled }) => multiValueObject(styles, isDisabled, data),
  option: (styles, { data, isDisabled, isFocused, isSelected }) =>
    optionObject(styles, isDisabled, isSelected, isFocused, data),
  valueContainer: styles => ({
    ...styles,
    height: 'min-content',
    minHeight: 38,
    '.css-b8ldur-Input': {
      display: 'none',
    },
  }),
};

export const multiSelectDelegationStyle = {
  ...multiSelectStyle,
  placeholder: styles => ({
    ...styles,
    fontSize: '14px',
  }),
};

export const supervisionResponsibleSelectStyle = {
  ...multiSelectDelegationStyle,

  control: styles => ({
    ...styles,
    outline: 'none',
    width: '197px',
    minHeight: '28px',
    height: 'auto',
    borderRadius: 20,
    borderColor: '#C1C7D0',

    '&:hover': {
      cursor: 'pointer',
    },
  }),

  valueContainer: styles => ({
    ...styles,
    minHeight: '28px',
    paddingTop: '0px',
    paddingBottom: '0px',
  }),

  indicatorsContainer: styles => ({
    ...styles,
    height: '28px',
  }),

  indicatorSeparator: () => ({
    display: 'none',
  }),
};

export const multiSelectRequiredStyle = {
  ...multiSelectStyle,
  control: styles => ({
    ...styles,
    cursor: 'pointer',
    borderColor: '#ed5a4d',
    borderRadius: '20px',
    borderWidth: 1,
    boxShadow: 'none',
    '&:hover': { borderColor: '#ed5a4d' },
  }),
};

export const multiSelectDarkBackgroundStyle = {
  control: styles => ({
    ...styles,
    backgroundColor: '#f7f8fc',
    borderRadius: '20px',
    boxShadow: 'none',
    borderWidth: 1,
    cursor: 'pointer',
    borderColor: '#c1c7d0',
    '&:hover': { borderColor: '#c1c7d0' },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) =>
    optionObject(styles, isDisabled, isSelected, isFocused, data),
  multiValue: (styles, { data, isDisabled }) => multiValueObject(styles, isDisabled, data),
  multiValueLabel: (styles, { data }) => multiValueLabelObject(styles, data),
  multiValueRemove: (styles, { data, isDisabled }) => multiValueRemoveObject(styles, isDisabled, data),
};

export const supervisionSelect = {
  control: base => ({
    ...base,
    display: 'flex',
    alignContent: 'center',
    width: isMobile() ? '100px' : '118px',
    minHeight: isMobile() ? '24px' : '34px',
    height: isMobile() ? '24px' : '34px',
    borderRadius: '24px',
    borderColor: '#C1C7D0',
    boxShadow: 0,
    fontSize: isMobile() ? '12px' : '20px',
    backgroundColor: isMobile() ? '#F2F3F7' : '#ffffff',
    '&:hover': {
      borderColor: '#E2EDFF',
    },
  }),

  valueContainer: base => ({
    ...base,
    padding: '0',
  }),

  singleValue: base => ({
    ...base,
    color: '#264377',
    fontSize: isMobile() ? '12px' : '20px',
    fontWeight: '600',
    marginLeft: '18px',
  }),

  indicatorSeparator: () => ({
    display: 'none',
  }),

  dropdownIndicator: base => ({
    ...base,
    color: '#264377',

    '&:hover': {
      color: '#264377',
    },
  }),

  menu: base => ({
    ...base,
    width: '100%',
    backgroundColor: '#FBFBFF',
    borderRadius: '10px',
    textAlign: 'center',
    borderColor: '#C1C7D0',
    boxShadow: 0,
    margin: 0,
  }),

  menuList: base => ({
    ...base,
    alignContent: 'center',
    padding: 0,
    margin: '6px',
  }),

  option: (base, state) => ({
    ...base,
    borderRadius: '5px',
    color: '#666666',
    backgroundColor: state.isSelected ? '#E2EDFF' : '#FBFBFF',
    fontSize: '20px',

    '&:hover': {
      backgroundColor: '#E2EDFF',
    },
  }),
};

export const plannedVsRealDetailProjectSelect = {
  ...supervisionSelect,
  singleValue: base => ({
    ...base,
    color: '#999999',
    fontSize: '9px',
    fontWeight: '400',
    marginLeft: '18px',
  }),
  option: (base, state) => ({
    ...base,
    borderRadius: '5px',
    color: '#666666',
    backgroundColor: state.isSelected ? '#E2EDFF' : '#FBFBFF',
    fontSize: '9px',

    '&:hover': {
      backgroundColor: '#E2EDFF',
    },
  }),
};

export const multiSelectAddEntityStyle = {
  ...multiSelectStyle,
  placeholder: styles => ({
    ...styles,
    fontSize: '14px',
  }),
  singleValue: styles => ({
    ...styles,
    color: '#727e8c',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) =>
    optionObject(styles, isDisabled, isSelected, isFocused, data, '#727e8c'),
};

export const reportAccordanceStyle = {
  ...styles,
  control: base => ({
    ...base,
    display: 'flex',
    alignContent: 'center',
    borderRadius: '24px',
    borderColor: '#C1C7D0',
    boxShadow: 0,
    fontSize: isMobile() ? '12px' : '20px',
    backgroundColor: isMobile() ? '#F2F3F7' : '#ffffff',
    width: '200px',
    '&:hover': {
      borderColor: '#E2EDFF',
    },
  }),
  placeholder: styles => ({
    ...styles,
    fontSize: '14px',
  }),
  singleValue: base => ({
    ...base,
    color: '#727E8C',
    fontSize: '12px',
    fontWeight: '400',
    marginLeft: '18px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),

  option: (base, state) => ({
    ...base,
    borderRadius: '5px',
    color: '#727E8C',
    backgroundColor: state.isSelected ? '#E2EDFF' : '#FBFBFF',
    fontSize: '12px',

    '&:hover': {
      backgroundColor: '#E2EDFF',
    },
  }),

  multiValue: styles => ({
    ...styles,
    backgroundColor: '#F1F3FF',
    borderRadius: '20px',
    borderWidth: 1,
    fontSize: '14px',
  }),
  multiValueRemove: (styles, { data, isDisabled }) => multiValueRemoveObject(styles, isDisabled, data),
};

export const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDropDown />
    </components.DropdownIndicator>
  );
};
