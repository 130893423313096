import React from 'react';
import './ActivityState.scss';

import { ActivityStateEnum } from '../../../../../../models/ActivityStateEnum';
import { useTranslation } from 'react-i18next';

type Props = {
  activityState: ActivityStateEnum;
};
export const ActivityState = ({ activityState }: Props) => {
  const { t } = useTranslation();

  const getActivityClass = () => {
    switch (activityState) {
      case ActivityStateEnum.pending:
        return 'pending';
      case ActivityStateEnum.in_progress:
        return 'in-action';
      case ActivityStateEnum.completed:
        return 'complete';
      case ActivityStateEnum.out_of_time:
        return 'out-of-time';
      default:
        return 'pending';
    }
  };
  return (
    <div className="activity-state-container">
      <div className={`icon ${getActivityClass()}`}></div>
      <div className="description">{t(ActivityStateEnum[activityState])}</div>
    </div>
  );
};
