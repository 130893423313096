import React from 'react';
import styles from './EarlyWarningsTimeLapse.module.scss';
import { handleEarlyWarningTimeLapseText } from '../EarlyWarningsFilters/Util';
import { useTranslation } from 'react-i18next';
import { isDiferentToIGLower } from './Util';

interface FilterToApplyOnEarlyWarningTimeLapse {
  green: boolean;
  yellow: boolean;
  red: boolean;
}

type Props = {
  projectStringDate: string;
  projectProductType: string;
  filterToApply: FilterToApplyOnEarlyWarningTimeLapse;
  showTimeLapsText?: boolean;
  indicatorKey?: string;
};

export const EarlyWarningsTimeLapse = ({
  projectStringDate,
  projectProductType,
  filterToApply,
  showTimeLapsText = true,
  indicatorKey = '',
}: Props) => {
  const { green, yellow, red } = filterToApply;
  const { t } = useTranslation();
  const { timeLapseContainer, iconContainer, greenIcon, yellowIcon, redIcon, textLapseContainer } = styles;
  const today = new Date();
  const projectDate = new Date(projectStringDate);

  const haveValidDate = () => {
    return projectStringDate !== '-';
  };

  return haveValidDate() && isDiferentToIGLower(projectProductType) ? (
    <div className={timeLapseContainer}>
      <div className={iconContainer}>
        {green && <span className={greenIcon} />}
        {yellow && <span className={yellowIcon} />}
        {red && <span className={redIcon} />}
      </div>
      {showTimeLapsText && (
        <div className={textLapseContainer}>
          {handleEarlyWarningTimeLapseText(today, projectDate, t, yellow ? indicatorKey : '')}
        </div>
      )}
    </div>
  ) : (
    <>-</>
  );
};
