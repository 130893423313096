import React from 'react';
import { Box } from '@material-ui/core';

const TabPanel = ({ children, value, index, ...props }) => {
    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...props}
        >
            {
                value === index &&
                <Box p={8}>
                    <div>{children}</div>
                </Box>
            }
        </div>
    );
}

export default TabPanel;
