import React from 'react';
import Accordance from '../../../../../../models/Accordance';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { replaceMomentFormat } from '../../../../../../services/util';
import { capitalizeFirstLetter } from '../../../../../../services/stringUtil';
import CommentsIcon from '../../CommentsIcon/CommentsIcon';
import styles from './AccordanceListView.module.scss';
import { AccordanceState } from '../../../../../../models/AccordanceState';
import useAccordanceDetailModalsState from '../../../../../../hooks/useAccordanceDetailModalsState';
import AccordanceDetailModals from '../../AccordanceDetailModals';

export const accordanceStateTextKeys = [
  'draft_accordance',
  'pending_accordance_singular',
  'valid',
  'accomplished_accordance_singular',
  'cancelled_word',
  'rejected',
];

type Props = { accordance: Accordance };

const AccordanceListItem = ({ accordance }: Props) => {
  const { t } = useTranslation();

  const { basicText, accordanceRow, fw } = styles;

  const stateProps = useAccordanceDetailModalsState();
  const { showModal } = stateProps;

  return (
    <>
      <div className={accordanceRow}>
        <div className={basicText} style={{ width: '14%' }}>
          {accordance.accordanceAgreementsType?.name ? accordance.accordanceAgreementsType?.name : '-'}
        </div>
        <div className={basicText} style={{ width: '11%' }}>
          {accordance.criticalIssue?.name ? accordance.criticalIssue?.name : '-'}
        </div>
        <div className={basicText} style={{ width: '13%', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          {accordance.linkedProductsAccordance.length !== 0 ? (
            accordance.linkedProductsAccordance?.map(item => {
              return <p>{item.nameProduct}</p>;
            })
          ) : (
            <p>-</p>
          )}
        </div>
        <div className={basicText} style={{ width: '21%' }}>
          {accordance?.description ? accordance?.description : '-'}
        </div>
        <div className={basicText} style={{ width: '17%' }}>
          {accordance.personInCharge
            ? `${accordance.personInCharge?.name} ${accordance.personInCharge?.lastName}`
            : '-'}
        </div>
        <div className={basicText} style={{ width: '11%' }}>
          {accordance.expirationDate
            ? replaceMomentFormat(moment(accordance.expirationDate).format('DD/MMM/YYYY'))
            : '-'}
        </div>
        <div
          className={basicText}
          style={{
            width: '7%',
            color: accordance.accordanceState === AccordanceState.PENDING ? '#F06D6D' : '#727E8C',
            fontWeight: 500,
          }}
        >
          {capitalizeFirstLetter(t(accordanceStateTextKeys[accordance.accordanceState]))}
        </div>
        <div className={basicText} style={{ width: '3%', justifyContent: 'center' }}>
          <CommentsIcon
            hasUnreadComments={accordance.hasUnreadExecutingUnitComments || accordance.hasUnreadIadbComments}
            comments={
              accordance.accordanceComments ? accordance.accordanceComments.filter(item => !item.isDeleted).length : 0
            }
          />
        </div>
        <div className={basicText} style={{ flex: 0.25, cursor: 'pointer' }} onClick={showModal}>
          <span className={fw}></span>
        </div>
      </div>
      <AccordanceDetailModals accordance={accordance} stateProps={stateProps} />
    </>
  );
};

export default AccordanceListItem;
