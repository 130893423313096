import React from 'react';
import './SelectReportIndicators.scss';
import { useTranslation } from 'react-i18next';
import SaveButton from '../../../Buttons/SaveButton/SaveButton';
import { useCustomizableReportsContext } from '../CustomizableReportsContext';
import { useSelector } from 'react-redux';
import { makeStyles, Tab, Tabs } from '@material-ui/core';
import { TabPanel } from '../../AddIndicators/AddIndicatorsUtil';
import { tabStyles } from '../../../../Projects/ProjectsAll/ProjectsAll.Style';
import AddIndicatorCard from '../../AddIndicators/Card/AddIndicatorCard';
import { isIE11 } from '../../../../../services/booleanUtil';
import { excludedIndicators } from '../CustomizableReports';
import Indicator from '../../../../../models/Indicator';

type Props = {
  next: Function;
};

export const SelectReportIndicators = ({ next }: Props) => {
  const { t } = useTranslation();

  const allIndicators = useSelector<any, any[]>(state => state.allIndicators);
  const currentIndicators = useSelector<any, Indicator[]>(state => state.indicators);
  const [indicatorsLists, setIndicatorsLists] = React.useState<any[]>([]);
  const [indicatorsTab, setIndicatorsTab] = React.useState<number>(0);
  const [selectedIndicatorsId, setSelectedIndicatorsId] = React.useState<number[]>([]);

  const [context, setContext] = useCustomizableReportsContext();

  React.useEffect(() => {
    const indicators = getIndicators();
    setIndicatorsLists(indicators);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allIndicators]);

  React.useEffect(() => {
    setSelectedIndicatorsId(context.indicators.map(i => i.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.indicators]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setIndicatorsTab(newValue);
  };

  const getIndicators = () => {
    const currentIndicatorsDictionary = {};
    currentIndicators.forEach(indicator => (currentIndicatorsDictionary[indicator.id] = true));
    return allIndicators
      .map(i => {
        return {
          ...i,
          indicators: i.indicators.filter(indicator => {
            return currentIndicatorsDictionary[indicator.id] && !excludedIndicators.hasOwnProperty(indicator.code);
          }),
        };
      })
      .filter(i => i.indicators.length > 0);
  };

  const updateIndicatorSelection = (indicatorId: number) => {
    if (selectedIndicatorsId.includes(indicatorId)) {
      const newSelectedIndicators = context.indicators.filter(i => i.id !== indicatorId);

      setContext({
        ...context,
        indicators: newSelectedIndicators,
      });
    } else {
      const newIndicator = allIndicators
        .reduce((items, i) => [...items, ...i.indicators], [])
        .find(indicator => indicator.id === indicatorId);

      if (!newIndicator) {
        return;
      }

      newIndicator.includeDetails = false;

      setContext({
        ...context,
        indicators: [...context.indicators, newIndicator],
      });
    }
  };

  const classes = makeStyles(tabStyles)();
  const tabClasses = { root: classes.root, selected: classes.selected };

  return (
    <div className="select-report-indicators">
      <p className="modal-title">
        {t('customizable_report_title')} / <span>{t('customizable_report_step_3')}</span>
      </p>

      <div className="modal-content">
        <h3>{t('customizable_report_step_3_title')}</h3>
        <p>{t('customizable_report_step_3_subtitle')}</p>

        <Tabs
          value={indicatorsTab}
          onChange={handleTabChange}
          classes={{
            indicator: classes.indicatorColor,
            root: classes.root,
          }}
          variant="standard"
          aria-label="Custom report indicators"
        >
          {indicatorsLists.map(indicatorsList => (
            <Tab key={indicatorsList.name} classes={tabClasses} label={indicatorsList.name} />
          ))}
        </Tabs>

        <div className="cards-container">
          {indicatorsLists.map((indicatorsList, index) => (
            <TabPanel key={indicatorsList.name} value={indicatorsTab} index={index}>
              <div className="cards-inner-container" style={isIE11() ? { width: '70vw' } : {}}>
                {indicatorsList.indicators.map(indicator => (
                  <AddIndicatorCard
                    key={indicator.id}
                    card={indicator}
                    isSelected={selectedIndicatorsId.includes(indicator.id)}
                    setSelected={id => updateIndicatorSelection(id)}
                    showDescription={false}
                    containerStyle={{ maxHeight: 191, minHeight: 191 }}
                  />
                ))}
              </div>
            </TabPanel>
          ))}
        </div>

        <div className="buttons-section">
          <SaveButton handleClick={next} customText={t('next')} disabled={selectedIndicatorsId.length === 0} />
        </div>
      </div>
    </div>
  );
};
