import { ReviewShowSelectProjectsActionTypes, SET_SHOW_SELECT_PROJECTS } from '../actions/types';

const initialState = false;

const reviewShowSelectProjectsReducer = (
  state = initialState,
  action: ReviewShowSelectProjectsActionTypes,
): boolean => {
  switch (action.type) {
    case SET_SHOW_SELECT_PROJECTS:
      return action.payload;
    default:
      return state;
  }
};

export default reviewShowSelectProjectsReducer;
