import React, { useEffect, useState } from 'react';
import styles from './SupervisionDetailForm.module.scss';
import { SupervisionPlanActivity } from '../../../../ProjectDetail/ProjectTabs/Supervision/util';
import Comments from '../../../../Util/Comments/Comments';
import {
  newActivityComment,
  deleteActivityComment,
  updateActivity,
  getActivityEarlyWarningRelatedList,
} from '../../../../../api/supervisionApi';
import SupervisionModalHeader from './SupervisionModalHeader/SupervisionModalHeader';
import SupervisionModalContent from './SupervisionModalContent/SupervisionModalContent';
import SupervisionModalFooter from './SupervisionModalFooter/SupervisionModalFooter';
import { getEarlyWarningOptions, getResponsibleIds, getResponsibleOptions, responsiblesAreEqual } from './util';
import { getProjectBaseInformation } from '../../../../../api/projectApi';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

type Props = {
  activity: SupervisionPlanActivity;
  modalEditStateUpdate: Function;
  closeModal: Function;
  currentProjectId: number;
  showCloseButton?: boolean;
  customStyle?: any;
  createReminder?: Function;
  editFromPopover?: any;
  setHideSeeMoreButton?: Function;
};
export const SupervisionDetailForm = ({
  activity,
  modalEditStateUpdate,
  closeModal,
  currentProjectId,
  showCloseButton,
  createReminder,
  editFromPopover,
  setHideSeeMoreButton,
}: Props) => {
  const {
    id,
    description,
    type,
    state,
    personsInCharge,
    consultant,
    complianceDate,
    location,
    comments = [],
    operativeAlerts,
  } = activity;

  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState<boolean>(editFromPopover);
  const [stateEdit, setStateEdit] = useState<any>(state);
  const [projectResponsiblesList, setProjectResponsiblesList] = useState<any[]>([]);
  const [responsibleEdit, setResponsibleEdit] = useState<any[]>(getResponsibleOptions(personsInCharge));
  const [locationEdit, setLocationEdit] = useState<any>(location);
  const [consultantEdit, setConsultantEdit] = useState<any>(consultant);
  const [formHasChanges, setFormHasChanges] = useState<boolean>(false);
  const [canEditForm, setCanEditForm] = useState<boolean>(false);
  const userId = useSelector<any, number>(state => state.user.id);
  const [earlyWarningList, setEarlyWarningList] = useState<any[]>([]);
  const [earlyWarningEdit, setEarlyWarningEdit] = useState<any[]>(setEarlyWarningOptions(operativeAlerts, t));

  const handleCloseModal = (refreshData = false) => {
    closeModal(refreshData);
    setIsEdit(false);
    setStateEdit(state);
    setLocationEdit(location);
    setConsultantEdit(consultant);
    setFormHasChanges(false);
    setHideSeeMoreButton && setHideSeeMoreButton(false);
  };

  const checkFormChanges = () => {
    if (
      stateEdit === state &&
      locationEdit === location &&
      consultantEdit === consultant &&
      earlyWarningEdit === getEarlyWarningOptions(operativeAlerts, t) &&
      responsiblesAreEqual(personsInCharge, responsibleEdit)
    ) {
      setFormHasChanges(false);
    } else {
      setFormHasChanges(true);
    }
  };

  const handleActivitySubmit = async () => {
    const data = {
      id,
      personsInCharge: getResponsibleIds(responsibleEdit),
      state: stateEdit,
      complianceDate,
      location: locationEdit,
      consultant: consultantEdit,
      operativeAlerts: setSelectedEarlyWarningOption(earlyWarningEdit),
    };

    try {
      await updateActivity(data);
      handleCloseModal(true);
    } catch {}
  };

  const handleSelectedUsers = selectedUsers => {
    const selectedUsersEdit = selectedUsers ? selectedUsers : [];
    setResponsibleEdit(selectedUsersEdit);
  };
  const handleSelectedEarlyWarning = earlyWarningAlert => {
    const selectedEarlyWarningEdit = earlyWarningAlert ? [earlyWarningAlert] : [];
    setEarlyWarningEdit(selectedEarlyWarningEdit);
  };

  const loadResponsiblesList = async () => {
    const response = await getProjectBaseInformation(currentProjectId);
    const responsiblesList = response.projectTeams.filter(
      user => user.bidMember && user.applicationUserId !== 0 && user.applicationUser,
    );

    setProjectResponsiblesList(getResponsibleOptions(responsiblesList));
    setCanEditForm(responsiblesList.find(u => u.applicationUserId === userId));
  };

  const loadEarlyWarningList = async () => {
    const response = await getActivityEarlyWarningRelatedList();
    setEarlyWarningList(getEarlyWarningOptions(response, t));
  };

  useEffect(() => {
    checkFormChanges();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateEdit, responsibleEdit, locationEdit, consultantEdit, earlyWarningEdit]);

  useEffect(() => {
    loadResponsiblesList();
    loadEarlyWarningList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    modalEditStateUpdate(isEdit);
    setHideSeeMoreButton && setHideSeeMoreButton(isEdit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  return (
    <div className={styles.supervisonModalContainer}>
      <SupervisionModalHeader
        handleCloseModal={handleCloseModal}
        activityDescription={description}
        activityType={type}
        activityState={state}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        canEditForm={canEditForm}
      />
      <SupervisionModalContent
        isEdit={isEdit}
        stateEdit={stateEdit}
        setStateEdit={setStateEdit}
        handleSelectedUsers={handleSelectedUsers}
        locationEdit={locationEdit}
        setLocationEdit={setLocationEdit}
        consultantEdit={consultantEdit}
        setConsultantEdit={setConsultantEdit}
        activity={activity}
        projectResponsibles={projectResponsiblesList}
        createReminder={createReminder}
        handleSelectedEarlyWarning={handleSelectedEarlyWarning}
        earlyWarningList={earlyWarningList}
      />

      <Comments
        comments={comments}
        submitComment={comment => newActivityComment(id, comment)}
        submitDeleteComment={comment => deleteActivityComment(comment)}
      />
      <SupervisionModalFooter
        isEdit={isEdit}
        formHasChanges={formHasChanges}
        handleActivitySubmit={handleActivitySubmit}
        handleCloseModal={handleCloseModal}
        showCloseButton={showCloseButton}
      />
    </div>
  );
};

const setEarlyWarningOptions = (operativeAlerts, t) => {
  return operativeAlerts.length > 0 ? getEarlyWarningOptions(operativeAlerts, t) : [];
};

const setSelectedEarlyWarningOption = earlyWarningEdit => {
  return earlyWarningEdit && earlyWarningEdit[0] && earlyWarningEdit[0].value === 'none'
    ? []
    : [earlyWarningEdit[0].id];
};
