import React from 'react';
import CloseCrossButton from '../../Util/Buttons/CloseCrossButton/CloseCrossButton';
import './BubbleElement.scss';
import { useSelector } from 'react-redux';
import Indicator from '../../../models/Indicator';
import { initialIndicator } from '../../../redux/reducers/currentIndicator';
import { isEqualDefaultAndActualState } from '../../../services/booleanUtil';
import { useContainerDimensions } from '../../../hooks/useContainerDimension';

interface IPropsBubble {
  itsHidden: boolean;
  label: string;
  value?: string;
  closeBubble?: any;
}
type PropsBubble = IPropsBubble;
export const Bubble = (props: PropsBubble) => {
  const { itsHidden, label, value } = props;
  const currentIndicator = useSelector<any, Indicator>(state => state.currentIndicator);
  const [hiddenCross, setHiddenCross] = React.useState(false);
  const { containerRef } = useContainerDimensions(label);

  React.useEffect(() => {
    setHiddenCross(isEqualDefaultAndActualState(currentIndicator, initialIndicator));
  }, [currentIndicator]);

  const handleClick = () => {
    props.closeBubble(true);
  };

  return itsHidden ? (
    null
  ) : (
    <div ref={containerRef}>
      <div className={`filter-bubble ${!hiddenCross ? 'adjust-pading' : ''}`}>
        {label} {value ? ':' : ''} {value}
        {hiddenCross ? (
          <CloseCrossButton buttonClass="close-cross-button-little" spanClass={'little'} handleClick={handleClick} />
        ) : null}
      </div>
    </div>
  );
};
