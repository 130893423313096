import React from 'react';
import './RoleBidMember.scss';

type Props = {
  roleMember: any;
  forReport?: boolean;
};

const RoleBidMember = ({ roleMember, forReport }: Props) => {
  const { list } = roleMember;

  return (
    <div className={`role-bid-member-container ${forReport ? 'for-report' : ''}`}>
      <h4>{list[0].roleInProject.name}</h4>
      {list.map(item => {
        return (
          <div
            className="text"
            key={item.applicationUser.id}
          >{`${item.applicationUser.name} ${item.applicationUser.lastName}`}</div>
        );
      })}
    </div>
  );
};

export default RoleBidMember;
