import { groupBy } from 'lodash';
import { isMobile } from '../../../services/booleanUtil';
import {
  createArrayOfColours,
  findMetricValue,
  findMetricValueByGrouping,
  getIndicatorData,
  getMonthLabels,
  metricAreZeros,
  metricIsEmpty,
  setBarThickness,
  setCurrentYear,
} from './indicatorHelperUtil';
import { IndicatorKeyEnum as keyEnum } from '../../../models/IndicatorKeyEnum';
import { CardTypesEnum } from '../../../models/CardTypesEnum';
import {
  getSevenMonthMetrics,
  setSevenMonthsToCard,
  getGaps,
  processProjectTypeHeaderData,
  getTabValuesFromMetrics,
  getTabValues,
} from './util';

import { ProjectProductTypes } from '../../../models/ProjectProductTypes';
import {
  commonChartData,
  fallBackKey,
  filterHeadersData,
  filterProjNumbers,
  formattedText,
  getDatasets,
  getGapBetweenBars,
  getMetricValueByProjectTypeAndMetricName,
  getTotal,
  getValues,
  otherDivisionTranslation,
} from './utilDivisionsAndCountry';
import { setArraysFromMetrics, setupCardData, setupDataFullYearData } from './addDisbProjectMonthlyUtil';

export const HORIZONTAL_BAR_EXTRA_SPACE = '    ';

/**ESG ESG_SAFEGUARD_PERFORMANCE_RATING */
/**STACKED */
const addEsgSafeGuardPerformaceRatingSPR = (indicators, cardIndicators) => {
  const indicator = getIndicatorData(indicators, keyEnum.ESG_SAFEGUARD_PERFORMANCE_RATING);

  if (!indicator) {
    return;
  } else if (metricAreZeros(indicator)) {
    cardIndicators.push({
      id: indicator.id,
      indicatorKey: indicator.key,
      type: CardTypesEnum.VERTICAL,
      title: 'environmental_and_social_performance',
      subtitle: '',
      detailTitle: '',
      detailChartTitle: '',
      detailChartFoot: '',
      data: null,
      order: indicator.order,
    });
  } else {
    const satisfactoryValue = findMetricValue(indicator.metrics, 'Satisfactory');
    const partiallySatisfactoryValue = findMetricValue(indicator.metrics, 'Partially_Satisfactory');
    const unsatisfactoryValue = findMetricValue(indicator.metrics, 'Unsatisfactory');
    const partiallUnsatisfactoryValue = findMetricValue(indicator.metrics, 'Partially_Unsatisfactory');
    const notAssignedValue = findMetricValue(indicator.metrics, 'Not_Assigned');

    const labels: string[] = ['', '', ''];
    const firstLevelStack = {
      data: [satisfactoryValue, unsatisfactoryValue, notAssignedValue],
      backgroundColor: ['#50AC32', '#E92C2C', '#D0DEF8'],
      borderWidth: 0,
      barThickness: 25,
      label: 'firstStack',
    };
    const secondLevelStack = {
      data: [partiallySatisfactoryValue, partiallUnsatisfactoryValue],
      backgroundColor: ['#D9F9B8', '#FF8C26'],
      borderWidth: 0,
      barThickness: 25,
      label: 'secondStack',
    };

    const datasets = [firstLevelStack, secondLevelStack];
    const dataChart: any = {
      id: indicator.id,
      indicatorKey: indicator.key,
      type: CardTypesEnum.HALF_SIZE_VERTICAL,
      title: 'environmental_and_social_performance',
      subtitle: '',
      detailTitle: '',
      detailChartTitle: '',
      detailChartFoot: '',
      data: labels.length
        ? {
          labels,
          datasets,
          subLabels: [],
          cornerRadius: 3,
          gridLines: true,
          dataListTranslationLabels: [
            'satisfactory',
            'partially_satisfactory',
            'unsatisfactory',
            'partially_unsatisfactory',
            'not_assigned',
          ],
          dataQtyProjects: [
            satisfactoryValue,
            partiallySatisfactoryValue,
            unsatisfactoryValue,
            partiallUnsatisfactoryValue,
            notAssignedValue,
          ],
        }
        : null,
      order: indicator.order,
    };

    cardIndicators.push(dataChart);
  }
};

/**ESG ESG_ENVIRONMENTAL_AND_SOCIAL_RISK_RATING */
const addEsgEnvironmentalAndSocialRiskRatingESRR = (indicators, cardIndicators) => {
  const indicator = getIndicatorData(indicators, keyEnum.ESG_ENVIRONMENTAL_AND_SOCIAL_RISK_RATING);

  if (!indicator) {
    return;
  } else if (metricAreZeros(indicator)) {
    cardIndicators.push({
      id: indicator.id,
      indicatorKey: indicator.key,
      type: CardTypesEnum.VERTICAL,
      title: 'environmental_and_social_risk',
      subtitle: '',
      detailTitle: '',
      detailChartTitle: '',
      detailChartFoot: '',
      data: null,
      order: indicator.order,
    });
  } else {
    const highValue = findMetricValue(indicator.metrics, 'High');
    const subtantialValue = findMetricValue(indicator.metrics, 'Substantial');
    const moderateValue = findMetricValue(indicator.metrics, 'Moderate');
    const lowValue = findMetricValue(indicator.metrics, 'Low');
    const notAssignedValue = findMetricValue(indicator.metrics, 'Not_Assigned');

    const labels: string[] = ['', '', '', '', ''];
    const firstLevelStack = {
      data: [highValue, subtantialValue, moderateValue, lowValue, notAssignedValue],
      backgroundColor: ['#F06D6D', '#EF9206', '#F7DD00', '#91E2A1', '#D0DEF8'],
      borderWidth: 0,
      barThickness: 25,
      label: 'firstStack',
    };

    const datasets = [firstLevelStack];
    const dataChart: any = {
      id: indicator.id,
      indicatorKey: indicator.key,
      type: CardTypesEnum.HALF_SIZE_VERTICAL,
      title: 'environmental_and_social_risk',
      subtitle: '',
      detailTitle: '',
      detailChartTitle: '',
      detailChartFoot: '',
      data: labels.length
        ? {
          labels,
          datasets,
          subLabels: [],
          cornerRadius: 3,
          gridLines: true,
          dataListTranslationLabels: ['high', 'substantial', 'moderate', 'low', 'not_assigned'],
          dataQtyProjects: [highValue, subtantialValue, moderateValue, lowValue, notAssignedValue],
        }
        : null,
      order: indicator.order,
    };

    cardIndicators.push(dataChart);
  }
};

const createMonthArray = (months, currentMonth) => {
  for (let i = currentMonth; i <= currentMonth + 6; i++) {
    if (i > 12) {
      months.push((i - 12).toString());
    } else {
      months.push(i.toString());
    }
  }

  return months;
};

const addDisbProjMonthly = (indicators, cardIndicators) => {
  const monthlyDisbProj = getIndicatorData(
    indicators,
    keyEnum.MONTHLY_DISBURSEMENT_PROJECTION_CURRENT_YEAR_INCLUDING_REGIONALS,
  );

  if (!monthlyDisbProj) {
    return;
  } else if (metricAreZeros(monthlyDisbProj)) {
    cardIndicators.push({
      id: monthlyDisbProj.id,
      indicatorKey: monthlyDisbProj.key,
      type: CardTypesEnum.DOUBLE_DATA_NOT_STACKED,
      title: 'annual_disbursements_proj',
      subtitle: '',
      detailTitle: 'annual_disbursements_proj',
      detailChartTitle: 'months',
      detailChartFoot: '',
      data: null,
      order: monthlyDisbProj.order,
    });
  } else {
    const currentMonth = new Date().getMonth() + 1;
    let currentYear = new Date().getFullYear();

    const monthLabels = getMonthLabels();
    const months: string[] = [];
    const year: number[] = [];
    createMonthArray(months, currentMonth);

    for (let i = parseInt(months[0]); i <= parseInt(months[0]) + 6; i++) {
      year.push(currentYear);
      currentYear = setCurrentYear(i, currentYear);
    }

    const { actualProjection, nextYearProjection, disbursed, sumarizeMetricValue } = setArraysFromMetrics(
      monthlyDisbProj.metrics.filter(m => m.metricName.indexOf('_REPORT') === -1),
    );

    const {
      cardActualProjectionValuesWithNextYear,
      cardActualProjectionColorsWithNextYear,
      cardDisbursedValues,
      cardDisbursedColors,
    } = setupCardData(actualProjection, nextYearProjection, disbursed);
    const {
      fullYearActualWithNextYear,
      fullYearDisbursed,
      fullYearActualWithNextYearColors,
      fullYearDisbursedColors,
      fullYearMonthLabels,
      fullYearMonthNumbers,
      yearsList,
      completeGroupingValue,
    } = setupDataFullYearData(actualProjection.concat(nextYearProjection), disbursed);

    const disbProjData = sumarizeMetricValue
      ? {
        id: monthlyDisbProj.id,
        indicatorKey: monthlyDisbProj.key,
        type: CardTypesEnum.DOUBLE_DATA_NOT_STACKED_MOUNTED_BARS,
        title: 'current_projection_of_disbursements',
        subtitle: '',
        detailTitle: 'current_projection_of_disbursements',
        detailChartTitle: 'months',
        detailChartFoot: '',
        months,
        yearNumber: year,
        data: {
          labels: monthLabels.slice(currentMonth - 1, currentMonth + 6),
          datasets: [
            {
              label: 'disbursement',
              data: cardDisbursedValues,
              backgroundColor: cardDisbursedColors,
              barThickness: 10,
            },
            {
              label: 'current_projection',
              data: cardActualProjectionValuesWithNextYear,
              backgroundColor: cardActualProjectionColorsWithNextYear,
              barThickness: 10,
            },
          ],
        },
        dataFullYear: {
          labels: fullYearMonthLabels,
          months: fullYearMonthNumbers,
          yearsList,
          completeGroupingValue,
          datasets: [
            {
              label: 'disbursement',
              data: fullYearDisbursed,
              backgroundColor: fullYearDisbursedColors,
              barThickness: 10,
            },
            {
              label: 'current_projection',
              data: fullYearActualWithNextYear,
              backgroundColor: fullYearActualWithNextYearColors,
              barThickness: 10,
            },
          ],
        },
        order: monthlyDisbProj.order,
        translateChartLabels: true,
        tabIndicatorValues: getTabValuesFromMetrics(monthlyDisbProj),
      }
      : {
        id: monthlyDisbProj.id,
        indicatorKey: monthlyDisbProj.key,
        type: CardTypesEnum.DOUBLE_DATA_NOT_STACKED_MOUNTED_BARS,
        title: 'current_projection_of_disbursements',
        subtitle: '',
        detailTitle: 'current_projection_of_disbursements',
        detailChartTitle: 'months',
        detailChartFoot: '',
        data: null,
        order: monthlyDisbProj.order,
      };
    cardIndicators.push(disbProjData);
  }
};

const addCountries = (indicators, cardIndicators) => {
  const indicator = getIndicatorData(indicators, keyEnum.PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS_BY_COUNTRY);

  if (!indicator) {
    return;
  } else if (!indicator.metrics || indicator.metrics.length === 0) {
    cardIndicators.push({
      id: indicator.id,
      indicatorKey: indicator.key,
      type: CardTypesEnum.HORIZONTAL,
      title: 'countries',
      subtitle: 'total : 0 menu_projects',
      data: null,
      order: indicator.order,
    });
  } else {
    const metricNameProjects = 'NUMBER_OF_PROJECT_RELATIONSHIPS_WITH_REGIONALS';
    const metricNameApprovedAmount = 'OPERATION_CURRENT_APPROVED_AMNT_USEQ';

    const projNumbers = indicator.metrics.filter(m => m.metricName === metricNameProjects);

    const headersData = indicator.metrics.filter(
      m => m.metricName === metricNameProjects || m.metricName === metricNameApprovedAmount,
    );

    const labels: any = [];
    const completeGroupingValue: any = [];
    const lon: any = [];
    const tcp: any = [];
    const igr: any = [];

    const customGroupBy = (projects, key) => {
      return projects.reduce((countriesGroupedMetrics, project) => {
        const countryCode = project[key].split('/')[0];
        const countryId = project[key].split('/')[1];
        const projectType = project[key].split('/')[2];
        countriesGroupedMetrics[countryCode] = countriesGroupedMetrics[countryCode] || [];
        countriesGroupedMetrics[countryCode].push({
          ...project,
          countryCode,
          countryId,
          projectType,
        });

        return countriesGroupedMetrics;
      }, {});
    };

    const groups = customGroupBy(projNumbers, 'groupingValue');

    const headersDataGroups = customGroupBy(headersData, 'groupingValue');

    const sortedCountryList = Object.keys(groups)
      .map(key => {
        return {
          list: groups[key],
          total: groups[key].reduce((acc, a) => acc + a.metricValue, 0),
          key,
          countryId: groups[key][0].countryId,
        };
      })
      .sort(function (a, b) {
        return b.total - a.total;
      });

    sortedCountryList.forEach(country => {
      const splitedKey = country.key.split('/')[1];

      labels.push(formattedText(splitedKey, country));
      completeGroupingValue.push(country.countryId);
    });

    const processProjectTypeHeaderData = (data, projectsMetricName, approvedAmountMetricName, projectType) => {
      return {
        projectType,
        projectsAmount: getMetricValueByProjectTypeAndMetricName(projectType, projectsMetricName, data),
        approvedAmount: getMetricValueByProjectTypeAndMetricName(projectType, approvedAmountMetricName, data),
      };
    };

    const maxCountriesValues = labels && labels.length;

    const headerTableData = Object.keys(headersDataGroups).map(key => {
      const dataLON = processProjectTypeHeaderData(
        headersDataGroups[key],
        metricNameProjects,
        metricNameApprovedAmount,
        ProjectProductTypes.Loan,
      );

      const dataTCP = processProjectTypeHeaderData(
        headersDataGroups[key],
        metricNameProjects,
        metricNameApprovedAmount,
        ProjectProductTypes.CT,
      );

      const dataIGR = processProjectTypeHeaderData(
        headersDataGroups[key],
        metricNameProjects,
        metricNameApprovedAmount,
        ProjectProductTypes.IG,
      );

      return { country: key, data: [dataLON, dataTCP, dataIGR] };
    });


    for (const countryData of sortedCountryList) {

      countryData.list.forEach((item) => {

        if (parseInt(item.projectType) === ProjectProductTypes.Loan) {
          lon.push(item.metricValue);
        }

        if (parseInt(item.projectType) === ProjectProductTypes.GUARANTEE) {
          var index = lon.length - 1;
          if (lon[index] !== undefined) {
            lon[index] += item.metricValue;
          }
        }

        if (parseInt(item.projectType) === ProjectProductTypes.CT) {
          tcp.push(item.metricValue);
        }

        if (parseInt(item.projectType) === ProjectProductTypes.IG) {
          igr.push(item.metricValue)
        }

      });

      const maxLength = Math.max(lon.length, tcp.length, igr.length);

      while (lon.length < maxLength) {
        lon.push(0);
      }
      while (tcp.length < maxLength) {
        tcp.push(0);
      }
      while (igr.length < maxLength) {
        igr.push(0);
      }
    }




    const lonChartData = {
      data: lon,
      backgroundColor: createArrayOfColours('#BE86D8', maxCountriesValues),
      label: 'lonChartData',
      ...commonChartData,
    };

    const tcpChartData = {
      data: tcp,
      backgroundColor: createArrayOfColours('#66B6B6', maxCountriesValues),
      label: 'tcpChartData',
      ...commonChartData,
    };

    const igrChartData = {
      data: igr,
      backgroundColor: createArrayOfColours('#F1AF43', maxCountriesValues),
      label: 'igrChartData',
      ...commonChartData,
    };

    const datasets = getDatasets(lonChartData, tcpChartData, igrChartData, maxCountriesValues);
    const values = getValues(projNumbers);
    const total = getTotal(projNumbers);

    const data = {
      id: indicator.id,
      indicatorKey: indicator.key,
      type: 'horizontal',
      title: 'countries',
      subtitle: `total : ${total} menu_projects`,
      detailTitle: 'countries',
      detailChartTitle: 'countries',
      detailChartFoot: 'number_of_projects',
      data: labels.length
        ? {
          labels,
          datasets,
          subLabels: values,
          completeGroupingValue,
        }
        : null,
      order: indicator.order,
      headerTableData,
      totalProjects: total,
    };

    cardIndicators.push(data);
  }
};

const commonCharDataColors = contextDataIndex =>
  contextDataIndex > 5
    ? 'rgba(38, 67, 119, 0.2)'
    : 'rgba(38, 67, 119, 1)';

const responsiveFontsize = () => (isMobile() ? 8 : 12);

const addDivisions = (indicators, cardIndicators) => {
  const indicator = getIndicatorData(indicators, keyEnum.PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS_BY_DIVISION);
  if (!indicator) {
    return;
  } else if (!indicator.metrics || indicator.metrics.length === 0) {
    cardIndicators.push({
      id: indicator.id,
      indicatorKey: indicator.key,
      type: CardTypesEnum.HORIZONTAL,
      title: 'divisions',
      subtitle: 'total : 0 menu_projects',
      data: null,
      order: indicator.order,
    });
  } else {
    const metricNameProjectsLON = 'LON_NUMBER_OF_PROJECT_RELATIONSHIPS_WITH_REGIONALS';
    const metricNameProjectsTCP = 'TCP_NUMBER_OF_PROJECT_RELATIONSHIPS_WITH_REGIONALS';
    const metricNameProjectsIGR = 'IGR_NUMBER_OF_PROJECT_RELATIONSHIPS_WITH_REGIONALS';
    const metricNameApprovedAmountLON = 'LON_OPERATION_CURRENT_APPROVED_AMNT_USEQ';
    const metricNameApprovedAmountTCP = 'TCP_OPERATION_CURRENT_APPROVED_AMNT_USEQ';
    const metricNameApprovedAmountIGR = 'IGR_OPERATION_CURRENT_APPROVED_AMNT_USEQ';

    const projNumbers = filterProjNumbers(
      indicator,
      metricNameProjectsLON,
      metricNameProjectsTCP,
      metricNameProjectsIGR,
    );

    const headersData = filterHeadersData(
      indicator,
      metricNameProjectsLON,
      metricNameProjectsTCP,
      metricNameProjectsIGR,
      metricNameApprovedAmountLON,
      metricNameApprovedAmountTCP,
      metricNameApprovedAmountIGR,
    );

    const labels: any = [];
    const completeGroupingValue: any = [];
    const lon: any = [];
    const tcp: any = [];
    const igr: any = [];

    const groups = groupBy(projNumbers, 'groupingValue');
    const headersDataGroups = groupBy(headersData, 'groupingValue');

    const sortedDivisionList = Object.keys(groups)
      .map(key => {
        return { list: groups[key], total: groups[key].reduce((acc, a) => acc + a.metricValue, 0), key };
      })
      .sort(function (a, b) {
        return b.total - a.total;
      });

    sortedDivisionList.forEach(division => {
      let splitedKey = division.key.split('/')[1];
      if (splitedKey === 'OTH') {
        splitedKey = otherDivisionTranslation();
      }
      labels.push(formattedText(splitedKey, division));
      completeGroupingValue.push(division.key);
    });

    const maxDivisionsValues = labels && labels.length;
    const headerTableData = Object.keys(headersDataGroups).map(key => {
      const dataLON = processProjectTypeHeaderData(
        headersDataGroups[key],
        metricNameProjectsLON,
        metricNameApprovedAmountLON,
      );

      const dataTCP = processProjectTypeHeaderData(
        headersDataGroups[key],
        metricNameProjectsTCP,
        metricNameApprovedAmountTCP,
      );

      const dataIGR = processProjectTypeHeaderData(
        headersDataGroups[key],
        metricNameProjectsIGR,
        metricNameApprovedAmountIGR,
      );

      return { division: fallBackKey(key), data: [dataLON, dataTCP, dataIGR] };
    });

    for (const divisionData of sortedDivisionList) {
      divisionData.list.forEach(item => {
        item.metricName === metricNameProjectsLON && lon.push(item.metricValue);
        item.metricName === metricNameProjectsTCP && tcp.push(item.metricValue);
        item.metricName === metricNameProjectsIGR && igr.push(item.metricValue);
      });
    }

    const commonChartData = {
      barThickness: setBarThickness(),
      borderWidth: 0,
      datalabels: {
        display: context => context.dataset.data[context.dataIndex] !== 0,
        align: 'left',
        anchor: 'start',
        color: context => commonCharDataColors(context.dataIndex),
        font: { size: responsiveFontsize(), weight: 400, style: 'normal', family: 'Rubik' },
        padding: { left: -8 },
        margin: { right: 10, },

      },
    };

    const lonChartData = {
      data: lon,
      backgroundColor: createArrayOfColours('#BE86D8', maxDivisionsValues),
      label: 'lonChartData',
      ...commonChartData,
    };

    const tcpChartData = {
      data: tcp,
      backgroundColor: createArrayOfColours('#66B6B6', maxDivisionsValues),
      label: 'tcpChartData',
      ...commonChartData,
    };

    const igrChartData = {
      data: igr,
      backgroundColor: createArrayOfColours('#F1AF43', maxDivisionsValues),
      label: 'igrChartData',
      ...commonChartData,
    };

    const gapBetweenBars = {
      data: [],
      backgroundColor: createArrayOfColours('#FFF', maxDivisionsValues),
      barThickness: setBarThickness(),
      borderWidth: 0,
      label: '',
      datalabels: {
        display: false,
      },
    };

    const datasets = [
      lonChartData,
      { ...gapBetweenBars, data: getGapBetweenBars(lonChartData, tcpChartData, igrChartData, 'gap1'), label: 'gap1' },
      tcpChartData,
      { ...gapBetweenBars, data: getGapBetweenBars(lonChartData, tcpChartData, igrChartData, 'gap2'), label: 'gap2' },
      igrChartData,
    ];

    const values = projNumbers.map(f => f.metricValue);
    const total = projNumbers.reduce((tot, num) => tot + num.metricValue, 0);

    const data = {
      id: indicator.id,
      indicatorKey: indicator.key,
      type: 'horizontal',
      title: 'divisions',
      subtitle: `total : ${total} menu_projects`,
      detailTitle: 'divisions',
      detailChartTitle: 'divisions',
      detailChartFoot: 'number_of_projects',
      data: labels.length
        ? {
          labels,
          datasets,
          subLabels: values,
          completeGroupingValue,
        }
        : null,
      order: indicator.order,
      headerTableData,
    };
    cardIndicators.push(data);
  }
};

const fallBackMetricValue = value => (value ? value.metricValue : 0);

const addDivisionsPMR = (indicators, cardIndicators) => {
  const indicator = getIndicatorData(indicators, keyEnum.PMR_PERFORMANCE_LAST_CLOSED_YEAR);
  const minDivisionsValues = 0;
  const maxDivisionsValues = 16;
  if (!indicator) {
    return;
  } else if (metricIsEmpty(indicator)) {
    cardIndicators.push({
      id: indicator.id,
      indicatorKey: indicator.key,
      type: CardTypesEnum.HORIZONTAL,
      title: 'division_pmr',
      subtitle: '',
      detailTitle: 'division_pmr',
      detailChartTitle: 'divisions',
      detailChartFoot: 'number_of_projects',
      data: null,
      order: indicator.order,
    });
  } else {
    const list = indicator.metrics.filter(x => x.metricName === 'OPER_CNT' && x.groupingValue !== 'NONE');
    list.sort((a, b) => (a.metricValue > b.metricValue ? -1 : 1));
    const divisionsData = list.slice(minDivisionsValues, maxDivisionsValues);
    const groups = groupBy(indicator.metrics, 'groupingValue');

    const alert: any = [];
    const problem: any = [];
    const satisfactory: any = [];
    const notAssigned: any = [];

    const labels: string[] = [];
    for (const item of divisionsData) {
      labels.push(item.groupingValue.split('/')[1] + HORIZONTAL_BAR_EXTRA_SPACE);
      const group = groups[item.groupingValue];
      const alertFiltered = group.filter(x => x.metricName === 'ALERT_OPER_CNT')[0];
      const alrt = fallBackMetricValue(alertFiltered);
      const probFiltered = group.filter(x => x.metricName === 'PROBLEM_OPER_CNT')[0];
      const prob = fallBackMetricValue(probFiltered);
      const satisFiltered = group.filter(x => x.metricName === 'SATISFACTORY_OPER_CNT')[0];
      const satis = fallBackMetricValue(satisFiltered);
      const naFiltered = group.filter(x => x.metricName === 'NA_OPER_CNT')[0];
      const na = naFiltered ? naFiltered.metricValue : 0;

      alert.push(alrt);
      problem.push(prob);
      satisfactory.push(satis);

      notAssigned.push(na);
    }
    const barSpacing = 5
    const alertChartdata = {
      data: alert,
      backgroundColor: createArrayOfColours('#E7CE00', maxDivisionsValues),
      borderWidth: 0,
      barThickness: setBarThickness(),
      label: 'alertChartdata',
    };

    const problemChartData = {
      data: problem,
      backgroundColor: createArrayOfColours('#F06D6D', maxDivisionsValues),
      borderWidth: 0,
      barThickness: setBarThickness(),
      label: 'problemChartData',
    };

    const satisChartData = {
      data: satisfactory,
      backgroundColor: createArrayOfColours('#91E2A1', maxDivisionsValues),
      borderWidth: 0,
      barThickness: setBarThickness(),
      label: 'satisChartData',
    };

    const naChartData = {
      data: notAssigned,
      backgroundColor: createArrayOfColours('#D7DBE4', maxDivisionsValues),
      borderWidth: 0,
      barThickness: setBarThickness(),
      label: 'naChartData',
    };

    const datasets = [problemChartData, alertChartdata, satisChartData, naChartData];

    const dataChart: any = {
      id: indicator.id,
      indicatorKey: indicator.key,
      type: 'horizontal',
      title: 'division_pmr',
      subtitle: '',
      detailTitle: 'division_pmr',
      detailChartTitle: 'divisions',
      detailChartFoot: 'number_of_projects',
      data: labels.length
        ? {
          labels,
          datasets,
          subLabels: divisionsData.map(m => m.metricValue),
        }
        : null,
      order: indicator.order,
    };

    cardIndicators.push(dataChart);
  }
};

const setIndicatorTitle = () => {
  if (localStorage.getItem('activities') === 'true') {
    return 'supervisory_activities';
  } else {
    return 'critical_monitoring_products';
  }
};

export const setIndicatorBarThickness = (mobile, desktop) => (isMobile() ? mobile : desktop);

const cardDatasetObject = (label, data, backgroundColor) => {
  return {
    label,
    data,
    backgroundColor,
    borderWidth: 0,
    barThickness: setIndicatorBarThickness(4, 8),
  };
};

const addSupervisionPlanPlannedVersusExecutedByMonth = (indicators, cardIndicators) => {
  const indicator = getIndicatorData(indicators, keyEnum.SUPERVISION_PLAN_PLANNED_VERSUS_EXECUTED);
  const PLANNED = 'PLANNED';
  const ACTUAL = 'ACTUAL';

  if (!indicator) {
    return;
  } else if (metricAreZeros(indicator)) {
    cardIndicators.push({
      id: indicator.id,
      indicatorKey: indicator.key,
      type: CardTypesEnum.DOUBLE_DATA_NOT_STACKED,
      title: 'projected_vs_finished',
      subtitle: 'Actividades de Supervisión',
      data: null,
      order: indicator.order,
    });
  } else {
    const metricsForChart = indicator.metrics.filter(m => m.groupingName === 'MONTH');
    const metricsForHeader = indicator.metrics.filter(m => m.groupingName === 'NONE');

    const headerData = [
      findMetricValue(metricsForHeader, 'PLANNED_ADVANCE_ACTIVITY'),
      findMetricValue(metricsForHeader, 'ACTUAL_ADVANCE_ACTIVITY'),
      findMetricValue(metricsForHeader, 'PLANNED_ADVANCE_PRODUCT'),
      findMetricValue(metricsForHeader, 'ACTUAL_ADVANCE_PRODUCT'),
      findMetricValue(metricsForHeader, 'REVISED_BUDGET_C'),
      findMetricValue(metricsForHeader, 'EXPENDITURES_A'),
    ];

    const currentMonth = new Date().getMonth() + 1;
    const monthLabels = getMonthLabels().slice(0, 12);

    const sevenMonthsLabels = setSevenMonthsToCard(currentMonth, monthLabels);

    const getFullYearMetrics = (metricName: string) => {
      const filterMetrics = metricsForChart.filter(m => m.metricName === metricName);
      return filterMetrics.map(m => m.metricValue);
    };

    const fullYearActivityPlanned = getFullYearMetrics(PLANNED);
    const fullYearActivityFull = getFullYearMetrics(ACTUAL);
    const fullYearCriticalProductsPlanned = getFullYearMetrics(PLANNED);
    const fullYearCriticalProductsFull = getFullYearMetrics(ACTUAL);

    const activityGaps = getGaps(fullYearActivityPlanned, fullYearActivityFull);
    const criticalProductsGaps = getGaps(fullYearCriticalProductsPlanned, fullYearCriticalProductsFull);

    const dataChart: any = {
      id: indicator && indicator.id,
      indicatorKey: indicator && indicator.key,
      type: CardTypesEnum.DOUBLE_DATA_NOT_STACKED,
      title: 'projected_vs_finished',
      detailTitle: 'projected_vs_finished',
      headerData,
      subtitle: setIndicatorTitle(),
      tooltips: {
        backgroundColor: '#101319',
        titleFontSize: 14,
        bodyFontSize: 14,
      },
      data: {
        labels: sevenMonthsLabels,
        datasets: [
          cardDatasetObject(
            'planned_advance_activity',
            getSevenMonthMetrics(metricsForChart, sevenMonthsLabels, PLANNED),
            '#B1CBFF',
          ),
          cardDatasetObject(
            'real_advance_activity',
            getSevenMonthMetrics(metricsForChart, sevenMonthsLabels, ACTUAL),
            '#004CE4',
          ),
          cardDatasetObject(
            'planned_advance_critical_product',
            getSevenMonthMetrics(metricsForChart, sevenMonthsLabels, PLANNED),
            '#B1CBFF',
          ),
          cardDatasetObject(
            'real_advance_critical_product',
            getSevenMonthMetrics(metricsForChart, sevenMonthsLabels, ACTUAL),
            '#004CE4',
          ),
        ],
      },
      dataFullYear: {
        labels: monthLabels,
        datasets: [
          {
            label: 'planned_advance_activity',
            data: fullYearActivityPlanned,
            backgroundColor: '#B1CBFF',
            borderWidth: 0,
            barThickness: setIndicatorBarThickness(9, 18),
            gap: activityGaps,
            actualMonth: currentMonth,
          },
          {
            label: 'real_advance_activity',
            data: fullYearActivityFull,
            backgroundColor: '#004CE4',
            borderWidth: 0,
            barThickness: setIndicatorBarThickness(9, 18),
            gap: activityGaps,
          },
          {
            label: 'planned_advance_critical_product',
            data: fullYearCriticalProductsPlanned,
            backgroundColor: '#B1CBFF',
            borderWidth: 0,
            barThickness: setIndicatorBarThickness(9, 18),
            gap: criticalProductsGaps,
            actualMonth: currentMonth,
          },
          {
            label: 'real_advance_critical_product',
            data: fullYearCriticalProductsFull,
            backgroundColor: '#004CE4',
            borderWidth: 0,
            barThickness: setIndicatorBarThickness(9, 18),
            gap: criticalProductsGaps,
          },
        ],
      },
      translateChartLabels: true,
      order: indicator.order,
    };

    cardIndicators.push(dataChart);
  }
};

const createStringRangeArray = (start, step, arrayLength) =>
  Array.from({ length: arrayLength }, (_, i) => (start - i * step).toString());

const metricValueOrZero = metricValue => (metricValue ? metricValue : 0);
/**PORTFOLIO_OVERVIEW_LAST_5_YEARS */
const addPortfolioOverviewLast5Years = (indicators, cardIndicators) => {
  const indicator = getIndicatorData(indicators, keyEnum.PORTFOLIO_OVERVIEW_LAST_5_YEARS);
  const YEAR = 'YEAR';
  const CURRENT_YEAR = new Date().getFullYear();
  if (!indicator) {
    return null;
  } else if (metricAreZeros(indicator)) {
    const returnData = {
      id: indicator.id,
      indicatorKey: indicator.key,
      type: CardTypesEnum.VERTICAL_SKYBLUE_ORANGE_BAR,
      title: 'approvals_in_the_last_5_years',
      subtitle: '',
      detailTitle: 'approvals_in_the_last_5_years',
      data: null,
      order: indicator.order,
    };

    cardIndicators.push(returnData);
    return returnData;
  } else {
    const labels: string[] = createStringRangeArray(CURRENT_YEAR, 1, 5).reverse();
    const barsData: number[] = [];
    const projQtyData: number[] = [];
    for (const label of labels) {
      const bar = findMetricValueByGrouping(indicator.metrics, YEAR, label, 'OPERATION_CURRENT_APPROVED_AMNT_USEQ');
      const projectsQty = findMetricValueByGrouping(
        indicator.metrics,
        YEAR,
        label,
        'NUMBER_OF_PROJECT_RELATIONSHIPS_WITH_REGIONALS',
      );
      barsData.push(metricValueOrZero(bar));
      projQtyData.push(metricValueOrZero(projectsQty));
    }

    const barsBackgroundColors = barsData.map((bar, index, list) => {
      if (index < list.length - 1) {
        return '#00CFDD';
      } else {
        return '#FDAC41';
      }
    });
    const bars = {
      data: barsData,
      backgroundColor: barsBackgroundColors,
      borderWidth: 0,
      barThickness: isMobile() ? 10 : 25,
      order: 2,
      label: 'bar',
      projQtyData,
    };

    const dataChart: any = {
      id: indicator.id,
      indicatorKey: indicator.key,
      type: CardTypesEnum.VERTICAL_SKYBLUE_ORANGE_BAR,
      title: 'approvals_in_the_last_5_years',
      subtitle: '',
      detailTitle: 'approvals_in_the_last_5_years',
      detailChartTitle: '',
      detailChartFoot: '',
      data: {
        labels,
        datasets: [bars],
        subLabels: [],
        cornerRadius: 3,
        gridLines: true,
        suggestedMax: Math.round(Math.max(...barsData) * 1.2),
      },
      order: indicator.order,
      tabIndicatorValues: getTabValues(indicator, 'PROJECT_PRODUCT_TYPE', 'PROJECT_COUNT'),
    };
    cardIndicators.push(dataChart);
    return dataChart;
  }
};

const skyBlueOrangeVerticalOptions = {
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        gridLines: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        ticks: {
          fontColor: '#101319',
          fontFamily: 'rubik',
          fontSize: 12,
          fontStyle: 400,
        },
      },
    ],
  },
  cornerRadius: 3,
  plugins: { datalabels: { display: false } },
};
export {
  addDisbProjMonthly,
  addDivisions,
  addDivisionsPMR,
  addEsgSafeGuardPerformaceRatingSPR,
  addEsgEnvironmentalAndSocialRiskRatingESRR,
  addSupervisionPlanPlannedVersusExecutedByMonth,
  addPortfolioOverviewLast5Years,
  skyBlueOrangeVerticalOptions,
  addCountries,
};
