import React, { useState, useEffect } from 'react';
import { ReportType } from '../../../../models/ReportType';
import { TableContainer, Table, TableBody } from '@material-ui/core';
import { TableCellHeaderReport } from '../ReportsTableShared.Styles';
import './AccordancesSection.scss';
import { RowTableReportsSection } from '../RowTableReportsSection/RowTableReportsSection';
import { StyledTableHeader, StyledTableRowHeader } from '../../../PortfolioDetail/PorfolioTableShared.Styles';
import { sortDates, customComparsion } from '../../../../services/sortsUtil';
import { createReportNameFile, getHeaderTable } from '../PortfolioSection/Util';
import { SectionPreloader } from '../SectionPreloader/SectionPreloader';
import { useTranslation } from 'react-i18next';
import { ReportDownload } from '../../../Util/Modals/ReportDownload/ReportDownload';
import { ReportsSectionsEmptyState } from '../ReportsSectionsEmptyState/ReportsSectionsEmptyState';
import { GetReportsFileTypeEnum, GetSectionReportEnum, ReportStatusEnum } from '../../../../models/ReportEnums';
import { ReportSendByMail } from '../../../Util/Modals/ReportSendByMail/ReportSendByMail';
import { getSectionReports, getReportFile, createAccordancesReport, deleteReport } from '../../../../api/reportApi';
import SaveButton from '../../../Util/Buttons/SaveButton/SaveButton';
import { ReportAccordance } from '../../../Util/Modals/ReportAccordance/ReportAccordance';
import { IAccordanceReportParams } from '../../../../models/AccordanceReportParams';
import { useSelector } from 'react-redux';
import Division from '../../../../models/Division';
import Region from '../../../../models/Region';
import { parseTranslatedFields } from './Util';

const pollingMiliseconds = 20000;

export const AccordancesSection = () => {
  const { t } = useTranslation();
  const divisionsState = useSelector<any, Division[]>(state => state.divisions);
  const regionsState = useSelector<any, Region[]>(state => state.regions);
  const [accordanceReportData, setAccordanceReportData] = useState<any[]>([]);
  const [openDownloadReportModal, setOpenDownloadReportModal] = React.useState(false);
  const [openSendByMailReportModal, setOpenSendByMailReportModal] = React.useState(false);
  const [selectedReport, setSelectedReport] = React.useState<any>({});
  const [fileTitle, setFileTitle] = React.useState('');
  const [reportId, setReportId] = React.useState('');
  const [sortAsc, setSortAsc] = React.useState(true);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  const getData = () => {
    getSectionReports(GetSectionReportEnum.Accordance_Section).then((response: any) => {
      setAccordanceReportData(parseTranslatedFields(response, t, divisionsState, regionsState));
      setLoading(false);
    });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionsState, divisionsState]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!accordanceReportData.some(item => item.reportStatus === ReportStatusEnum.Generating)) {
        return () => clearInterval(interval);
      }
      getData();
      return true;
    }, pollingMiliseconds);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accordanceReportData]);

  const sortColumn = (projects, keyToSort) => {
    sortReports(projects, keyToSort, sortAsc, setAccordanceReportData);
    setSortAsc(!sortAsc);
  };

  const handleDownloadReport = report => {
    setSelectedReport(report);
    setOpenDownloadReportModal(true);
  };

  const handleSendByMailReport = report => {
    setSelectedReport(report);
    setReportId(report.id);
    setFileTitle(
      createReportNameFile(GetSectionReportEnum.Accordance_Section, GetReportsFileTypeEnum.Excel, report, t),
    );
    setOpenSendByMailReportModal(true);
  };
  const handleReportByFormat = formatType => {
    const completeFileName = createReportNameFile(
      GetSectionReportEnum.Accordance_Section,
      formatType,
      selectedReport,
      t,
    );
    setFileTitle(completeFileName);
    getReportFile(selectedReport.id, formatType, completeFileName);
    setOpenDownloadReportModal(false);
  };

  const generateReport = async (accordanceParams: IAccordanceReportParams) => {
    setIsOpen(false);
    await createAccordancesReport(accordanceParams);
    getData();
  };

  const createReport = () => {
    setIsOpen(true);
  };

  const deleteAccordanceReport = id => {
    deleteReport(id).then(response => {
      if (response === 200) {
        const reports = accordanceReportData.filter(report => report.id !== id);
        setAccordanceReportData(reports);
      }
    });
  };
  return !loading ? (
    <>
      <div className="accordances-section-report-table-container">
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <SaveButton
            handleClick={() => createReport()}
            customText={t('create_report')}
            customStyle={{ marginLeft: 20 }}
          />
        </div>
        {accordanceReportData.length !== 0 ? (
          <TableContainer>
            <Table stickyHeader style={{ tableLayout: 'auto' }} size="small">
              <StyledTableHeader>
                <StyledTableRowHeader>
                  {getHeaderTable(ReportType.Accordance).map(header => (
                    <TableCellHeaderReport
                      align="left"
                      key={header.id}
                      onClick={() => sortColumn(accordanceReportData, header.key)}
                    >
                      {t(header.label)}

                      <i className="sort-icon"></i>
                    </TableCellHeaderReport>
                  ))}

                  <TableCellHeaderReport align="right" />
                </StyledTableRowHeader>
              </StyledTableHeader>
              <TableBody>
                {accordanceReportData.map((report, index) => (
                  <RowTableReportsSection
                    key={report.id}
                    report={report}
                    index={index}
                    downloadReport={handleDownloadReport}
                    sendByMailReport={handleSendByMailReport}
                    reportType={ReportType.Accordance}
                    deleteReport={deleteAccordanceReport}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <ReportsSectionsEmptyState message={'you_can_create_them_from_buttons'} />
        )}

        <ReportDownload
          isOpen={openDownloadReportModal}
          closeModal={() => {
            setOpenDownloadReportModal(false);
          }}
          submit={handleReportByFormat}
          reportType={ReportType.Accordance}
        />

        <ReportSendByMail
          isOpen={openSendByMailReportModal}
          closeModal={() => {
            setOpenSendByMailReportModal(false);
          }}
          reportTitle={fileTitle}
          reportId={reportId}
          reportFileType={GetReportsFileTypeEnum.Excel}
        />
      </div>
      <ReportAccordance closeModal={() => setIsOpen(false)} isOpen={isOpen} submit={generateReport} />
    </>
  ) : (
    <SectionPreloader />
  );
};

const sortAccordanceTypes = (key: string, order = 'desc') => {
  return (a, b) => {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      return 0;
    }
    const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];
    return customComparsion(varA, varB, order);
  };
};

const sortReports = (projects, keyToSort, sortAsc, setProjectReportFiltered) => {
  if (keyToSort === 'dateGenerated') {
    projects.sort(sortDates(keyToSort, sortAsc ? 'asc' : 'desc'));
  } else {
    projects.sort(sortAccordanceTypes(keyToSort, sortAsc ? 'asc' : 'desc'));
  }
};
