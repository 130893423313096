import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectsSimpleTableIndicator } from '../../../redux/actions/indicatorProjects';
import styles from './ProjectExtendedNotFullyDisbursed.module.scss';
import { Table } from './Table/Table';
import { handleLoading } from '../../utils';

export const ProjectExtendedNotFullyDisbursed = () => {
  const [loading, setLoading] = useState(false);
  const { currentIndicator } = useSelector<any, any>(state => state);
  const { projectTypeFilterOnDetail } = useSelector<any, any>(state => state.indicatorDetailFilter);
  const dispatch = useDispatch();
  const { container, bodyContainer } = styles;

  useEffect(() => {
    handleLoading(
      () => getFilteredSimpleIndicators(dispatch, currentIndicator.id, currentIndicator.indicatorKey),
      setLoading,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndicator.id, dispatch, projectTypeFilterOnDetail]);

  return (
    <div className={container}>
      <div className={bodyContainer}>
        <Table loading={loading} />
      </div>
    </div>
  );
};

const getFilteredSimpleIndicators = (d, currentIndicatorId, currentIndicatorKey) => {
  return d(getProjectsSimpleTableIndicator(currentIndicatorId, currentIndicatorKey));
};
