import IndicatorDetailFilter from '../../models/IndicatorDetailFilter';
import { PmrClassification } from '../../models/PmrClassification';
import {
  IndicatorDetailFiltersActionTypes,
  RESET_INDICATOR_DETAIL_FILTER,
  RESET_INDICATOR_DETAIL_FILTER_CHART,
  SET_INDICATOR_DETAIL_FILTER,
  SET_INDICATOR_DETAIL_FILTER_CHART,
  SET_INDICATOR_DETAIL_FILTER_PROJECT_TYPE,
} from '../actions/types';

const initialState: IndicatorDetailFilter = {
  indicatorCharts: {
    chartTitle: '',
    subTitle: PmrClassification.Green,
    firstTime: true,
  },
  projectTypeFilterOnDetail: null,
};

const indicatorDetailFilterReducer = (
  state = initialState,
  action: IndicatorDetailFiltersActionTypes,
): IndicatorDetailFilter => {
  switch (action.type) {
    case SET_INDICATOR_DETAIL_FILTER:
      return action.payload;
    case SET_INDICATOR_DETAIL_FILTER_CHART:
      return { ...state, indicatorCharts: action.payload };
    case SET_INDICATOR_DETAIL_FILTER_PROJECT_TYPE:
      return { ...state, projectTypeFilterOnDetail: action.payload };
    case RESET_INDICATOR_DETAIL_FILTER_CHART:
      return { ...state, indicatorCharts: { chartTitle: '', subTitle: PmrClassification.Green, firstTime: true } };
    case RESET_INDICATOR_DETAIL_FILTER:
      return {
        projectTypeFilterOnDetail: null,
        indicatorCharts: { chartTitle: '', subTitle: PmrClassification.Green, firstTime: true },
      };
    default:
      return state;
  }
};
export default indicatorDetailFilterReducer;
