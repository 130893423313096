import {
  SET_REVIEW_SELECTED_PROJECT,
  SET_LIST_REVIEW_SELECTED_PROJECT,
  SET_NONE_REVIEW_SELECTED_PROJECT,
} from './types';

export function setReviewSelectedProject(id: number) {
  return {
    type: SET_REVIEW_SELECTED_PROJECT,
    payload: id,
  };
}

export function setListReviewSelectedProject(list: number[]) {
  return {
    type: SET_LIST_REVIEW_SELECTED_PROJECT,
    payload: list,
  };
}

export function setNoneReviewSelectedProject() {
  return {
    type: SET_NONE_REVIEW_SELECTED_PROJECT,
  };
}
