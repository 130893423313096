import React, { useState, useEffect } from 'react';
import './ProjectSection.scss';
import { useTranslation } from 'react-i18next';
import { getSectionReports, deleteReport, getReportFile } from '../../../../api/reportApi';
import { TableContainer, Table, TableBody } from '@material-ui/core';
import { TableCellHeaderReport } from '../ReportsTableShared.Styles';
import { RowTableReportsSection } from '../RowTableReportsSection/RowTableReportsSection';
import { StyledTableHeader, StyledTableRowHeader } from '../../../PortfolioDetail/PorfolioTableShared.Styles';
import { getYearsOptions } from '../../../../services/util';
import { sortDates, customComparsion } from '../../../../services/sortsUtil';
import { haveAppliedFilters } from '../ReportsSection';
import { ReportDownload } from '../../../Util/Modals/ReportDownload/ReportDownload';
import { ReportsSectionsEmptyState } from '../ReportsSectionsEmptyState/ReportsSectionsEmptyState';
import { GetReportsFileTypeEnum, GetSectionReportEnum, ReportStatusEnum } from '../../../../models/ReportEnums';
import { ReportSendByMail } from '../../../Util/Modals/ReportSendByMail/ReportSendByMail';
import { createReportNameFile, getHeaderTable } from '../PortfolioSection/Util';
import { SectionPreloader } from '../SectionPreloader/SectionPreloader';
import { ReportType } from '../../../../models/ReportType';

const pollingMiliseconds = 20000;

type Props = {
  sendReports?: any;
  filters?: any;
};

export const ProjectSection = ({ sendReports, filters }: Props) => {
  const { t } = useTranslation();
  const [projectReportData, setProjectReportData] = useState<any[]>([]);
  const [projectReportFiltered, setProjectReportFiltered] = useState<any[]>([]);
  const [openDownloadReportModal, setOpenDownloadReportModal] = React.useState(false);
  const [openSendByMailReportModal, setOpenSendByMailReportModal] = React.useState(false);
  const [selectedReport, setSelectedReport] = React.useState<any>({});
  const [fileTitle, setFileTitle] = React.useState('');
  const [reportId, setReportId] = React.useState('');
  const [sortAsc, setSortAsc] = React.useState(true);
  const [loading, setLoading] = useState(true);

  const getProjectData = () => {
    getSectionReports(GetSectionReportEnum.Projects_Section).then((response: any) => {
      setProjectReportData(response);
      setProjectReportFiltered(response);
      sendReports(getYearsOptions(response));
      setLoading(false);
    });
  };

  const filterByMonthAndYear = (projects, filters) => {
    return haveAppliedFilters(filters, projects);
  };

  useEffect(() => {
    getProjectData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!projectReportData.some(item => item.reportStatus === ReportStatusEnum.Generating)) {
        return () => clearInterval(interval);
      }
      getProjectData();
      return true;
    }, pollingMiliseconds);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectReportData]);

  useEffect(() => {
    const result = filterByMonthAndYear(projectReportData, filters);
    itSearch(filters, setProjectReportFiltered, result, searchProject);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const searchProject = valueToSearch => {
    setProjectReportFiltered(searchReportProject(projectReportData, valueToSearch));
  };

  const sortColumn = (projects, keyToSort) => {
    sortProjectReports(projects, keyToSort, sortAsc, setProjectReportFiltered);
    setSortAsc(!sortAsc);
  };

  const deleteProjectReport = id => {
    deleteReport(id).then(response => {
      if (response === 200) {
        const reports = projectReportFiltered.filter(report => report.id !== id);
        setProjectReportFiltered(reports);
        setProjectReportData(reports);
      }
    });
  };

  const handleDownloadReport = report => {
    setSelectedReport(report);
    setOpenDownloadReportModal(true);
  };

  const handleSendByMailReport = report => {
    setSelectedReport(report);
    setReportId(report.id);
    setFileTitle(createReportNameFile(GetSectionReportEnum.Projects_Section, GetReportsFileTypeEnum.PDF, report, t));
    setOpenSendByMailReportModal(true);
  };
  const handleReportByFormat = formatType => {
    const completeFileName = createReportNameFile(GetSectionReportEnum.Projects_Section, formatType, selectedReport, t);
    setFileTitle(completeFileName);
    getReportFile(selectedReport.id, formatType, completeFileName);
    setOpenDownloadReportModal(false);
  };

  return !loading ? (
    <div className="project-section-report-table-container">
      {projectReportFiltered.length !== 0 ? (
        <TableContainer>
          <Table stickyHeader style={{ tableLayout: 'auto' }} size="small">
            <StyledTableHeader>
              <StyledTableRowHeader>
                {getHeaderTable(ReportType.Project).map(header => (
                  <TableCellHeaderReport
                    align="left"
                    key={header.id}
                    onClick={() => sortColumn(projectReportFiltered, header.key)}
                  >
                    {t(header.label)}
                    <i className="sort-icon"></i>
                  </TableCellHeaderReport>
                ))}
                <TableCellHeaderReport align="right" />
              </StyledTableRowHeader>
            </StyledTableHeader>
            <TableBody>
              {projectReportFiltered.map((report, index) => (
                <RowTableReportsSection
                  key={report.id}
                  report={report}
                  index={index}
                  deleteReport={deleteProjectReport}
                  downloadReport={handleDownloadReport}
                  sendByMailReport={handleSendByMailReport}
                  reportType={ReportType.Project}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <ReportsSectionsEmptyState message={'you_can_create_them_from_the_project_section'} />
      )}

      <ReportDownload
        isOpen={openDownloadReportModal}
        closeModal={() => {
          setOpenDownloadReportModal(false);
        }}
        submit={handleReportByFormat}
      />

      <ReportSendByMail
        isOpen={openSendByMailReportModal}
        closeModal={() => {
          setOpenSendByMailReportModal(false);
        }}
        reportTitle={fileTitle}
        reportId={reportId}
      />
    </div>
  ) : (
    <SectionPreloader />
  );
};

const sortAlpInProjects = (key: string, order = 'desc') => {
  return (a, b) => {
    if (!a.project.hasOwnProperty(key) || !b.project.hasOwnProperty(key)) {
      return 0;
    }

    const varA = typeof a.project[key] === 'string' ? a.project[key].toUpperCase() : a.project[key];
    const varB = typeof b.project[key] === 'string' ? b.project[key].toUpperCase() : b.project[key];

    return customComparsion(varA, varB, order);
  };
};

const sortSections = (key: string, order = 'desc') => {
  return (a, b) => {
    if (!a.parameters.hasOwnProperty(key) || !b.parameters.hasOwnProperty(key)) {
      return 0;
    }

    const varA = a.parameters[key];
    const varB = b.parameters[key];

    return customComparsion(varA, varB, order);
  };
};

const searchReportProject = (projectReportFiltered, valueToSearch) => {
  return projectReportFiltered.filter(project => {
    if (
      project.project.codeAndLoan.toLowerCase().search(valueToSearch.toLowerCase()) !== -1 ||
      project.project.name.toLowerCase().search(valueToSearch.toLowerCase()) !== -1
    ) {
      return project;
    } else {
      return '';
    }
  });
};

const itSearch = (filters, setProjectReportFiltered, result, searchProject) => {
  if (filters.searchValue !== '') {
    searchProject(filters.searchValue);
  } else {
    setProjectReportFiltered(result);
  }
};

const sortProjectReports = (projects, keyToSort, sortAsc, setProjectReportFiltered) => {
  switch (keyToSort) {
    case 'dateGenerated':
      projects.sort(sortDates(keyToSort, sortAsc ? 'asc' : 'desc'));
      break;
    case 'sections':
      setProjectReportFiltered(projects.sort(sortSections(keyToSort, sortAsc ? 'asc' : 'desc')));
      break;
    default:
      setProjectReportFiltered(projects.sort(sortAlpInProjects(keyToSort, sortAsc ? 'asc' : 'desc')));
      break;
  }
};
