import React from 'react';
import './BaseInformation.scss';
import { useTranslation } from 'react-i18next';
import { renderDateOrSlash } from '../../../services/stringUtil';
import { ProjectDetailsTab } from '../../../models/ProjectDetailsTabEnum';
import useGcmAlerts from '../../../hooks/useGcmAlerts';
import { GcmHighlighted } from '../../../models/GcmAlerts';
import { DateNodeSegment } from './DateNodeSegment';

const dateFormat = 'DD/MMM/YYYY';

export const validDate = date => {
  return renderDateOrSlash(date, dateFormat);
};

export const extraText = (date, value) => {
  return validDate(date) !== '-' ? `(${value})` : '';
};

type Props = {
  baseInfo: any;
  isWarranty?: boolean;
};

const RightContainer = ({ baseInfo, isWarranty }: Props) => {
  const { t } = useTranslation();

  const calledFromTab = ProjectDetailsTab.BASE_INFORMATION;
  const { textAlert, barColor, highlightNode } = useGcmAlerts({ calledFromTab });

  return (
    <div className="right-container">
      <div
        className="dates"
        style={barColor.backgroundColor === 'transparent' ? { borderRadius: '8px' } : { borderRadius: '8px 8px 0 0' }}
      >
        <span className="section-title">{t('dates')}</span>
        <ul>
          <DateNodeSegment nodeTitle={t('approval')} nodeSubtitle={validDate(baseInfo.approvalDate)} />
          <DateNodeSegment nodeTitle={t('effectiveness')} nodeSubtitle={validDate(baseInfo.contractDate)} />
          <DateNodeSegment
            nodeTitle={t('eligibility')}
            nodeSubtitle={validDate(baseInfo.eligibilityDate)}
            highlightInfo={{ highlightNode, barColor, highlightEnumReference: GcmHighlighted.eligibility }}
          />

          {isWarranty ? null : (
            <>
              <DateNodeSegment
                nodeTitle={t('first_disbursement')}
                nodeSubtitle={validDate(baseInfo.firstDisbursementDate)}
              />
            </>
          )}
          <DateNodeSegment
            nodeTitle={t('last_disbursement')}
            nodeSubtitle={`${validDate(baseInfo.originalDisbursementDate)} ${extraText(
              baseInfo.originalDisbursementDate,
              t('original'),
            )}`}
            highlightInfo={{ highlightNode, barColor, highlightEnumReference: GcmHighlighted.lastDisbursement }}
            dividerInfo={{ firstDivider: 'dashed-divider' }}
          />
          <DateNodeSegment
            nodeTitle={`${validDate(baseInfo.lastExtensionApprobedDate)} ${extraText(
              baseInfo.lastExtensionApprobedDate,
              t('current'),
            )}`}
            nodeSubtitle={`${t('extension')}: ${baseInfo.extensionInMonth} ${t('months')}`}
            highlightInfo={{ highlightNode, barColor, highlightEnumReference: GcmHighlighted.lastDisbursement }}
            last
          />
        </ul>
      </div>
      {textAlert && (
        <div className="date-footer" style={barColor}>
          <div className="date-footer-message">{textAlert}</div>
        </div>
      )}
    </div>
  );
};

export default RightContainer;
