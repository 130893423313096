import { isMobile } from '../../../services/booleanUtil';


export const tabStyles = {
  root: {
    color: '#424D64',
    fontFamily: 'rubik',
    fontSize: '14px',
    lineHeight: '24px',
    backgroundColor: '#FFFFFF',
    fontWeight: 500,
    width: 'auto',
    textDecoration: 'uppercase',
    minWidth: 'fit-content',
    opacity: '1',
    minHeight: '42px',
    '&$selected': {
      color: '#236BFF',
      lineHeight: '24px',
      backgroundColor: '#FFFFFF',
      fontWeight: 500,
      width: 'auto',
      textDecoration: 'uppercase',
      minWidth: 'fit-content',
    },
  },
  selected: {},
  indicatorColor: {
    backgroundColor: '#236BFF',
    height: '4px',
  },
  scrollButtons: {
    color: '#979797',
  },
};

export const projectAllCustomStyle = {
  ...tabStyles,
  root: {
    ...tabStyles.root,
    marginLeft: '0.2em',
  },
};

const contentManagerTabCommonProps = {
  backgroundColor: 'transparent',
  marginLeft: '0px',
};

export const contentManagerTabStyles = {
  ...tabStyles,
  root: {
    ...tabStyles.root,
    '&$selected': {
      ...tabStyles.root['&$selected'],
      ...contentManagerTabCommonProps,
      color: '#00BAC7',
    },
    ...contentManagerTabCommonProps,
  },
  indicatorColor: {
    ...tabStyles.indicatorColor,
    backgroundColor: '#00BAC7',
  },
};
