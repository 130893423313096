import React from 'react';
import './ReviewReportOption.scss';
import { useTranslation } from 'react-i18next';
import { ReviewReportSectionsEnum } from '../../../../models/ReportEnums';
import CustomCheckbox from '../../CustomCheckbox/CustomCheckbox';
import CustomRadioButton from '../../CustomRadioButton/CustomRadioButton';

const customStyle = { fontSize: '12px', lineHeight: '15px', maxWidth: 150, wordBreak: 'break-word' };

type Props = {
  option: any;
  onChange: any;
};

const ReviewReportOption = ({ option, onChange }: Props) => {
  const { buttonImg, parent, children, radioButton } = option;
  const { t } = useTranslation();

  return (
    <div className="review-report-option-container">
      <div
        onClick={() => onChange(!parent.value, parent.key, radioButton, parent.key)}
        style={
          parent.value
            ? { border: '1px solid #475F7B', borderRadius: 5, boxShadow: '0px 3px 10px rgba(65, 127, 254, 0.33)' }
            : { border: 'none' }
        }
      >
        <img
          src={buttonImg}
          alt=""
          style={{ boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.1)', borderRadius: 5, cursor: 'pointer' }}
        ></img>
      </div>
      <h5>{t(ReviewReportSectionsEnum[parent.key])}</h5>
      {parent.value && children && children.length
        ? children.map(child => {
            return (
              <div className="inputs-list" key={child.id}>
                {child.list.map(item =>
                  radioButton ? (
                    <CustomRadioButton
                      key={item.key}
                      isChecked={item.value}
                      handleOnClick={isChecked => {
                        onChange(isChecked, item.key, radioButton, parent.key);
                      }}
                      labelText={t(ReviewReportSectionsEnum[item.key])}
                      customStyle={customStyle}
                    />
                  ) : (
                    <CustomCheckbox
                      key={item.key}
                      isChecked={item.value}
                      handleOnClick={isChecked => {
                        onChange(isChecked, item.key, radioButton, parent.key);
                      }}
                      labelText={t(ReviewReportSectionsEnum[item.key])}
                      customStyle={customStyle}
                    />
                  ),
                )}
              </div>
            );
          })
        : null}
    </div>
  );
};

export default ReviewReportOption;
