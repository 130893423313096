import { GcmStatus } from '../../../models/GcmAlerts';
import { PmrClassification } from '../../../models/PmrClassification';
import { TaxonomyCode } from '../../../models/TaxonomyOptions';
import { sortNumbers } from '../../../services/sortsUtil';

export enum filterStatus {
  Green = 0,
  Yellow,
  Red,
  Na,
}

const milisecondsToDays = 1000 * 60 * 60 * 24;

export const basicData = {
  project_life_cycle: {
    extensions: [
      {
        status: filterStatus.Green,
        label: 'no_extensions',
        count: 0,
        id: 1,
        condition: p => p.baseInformation && p.baseInformation.extensionInMonth === 0,
      },
      {
        status: filterStatus.Yellow,
        label: 'extensions_until_24',
        count: 0,
        id: 2,
        condition: p =>
          p.baseInformation && p.baseInformation.extensionInMonth > 0 && p.baseInformation.extensionInMonth <= 24,
      },
      {
        status: filterStatus.Red,
        label: 'extensions_more_than_24',
        count: 0,
        id: 3,
        condition: p => p.baseInformation && p.baseInformation.extensionInMonth > 24,
        mostUsed: true,
        collapsedText: 'extensions_more_than_24',
      },
    ],
    elegibility_pending: [
      {
        status: filterStatus.Green,
        label: 'on_schedule_180',
        count: 0,
        id: 4,
        condition: p => p.pendingElegibility !== null && p.pendingElegibility < 180,
        filterText: 'elegibility_pending on_schedule_180',
      },
      {
        status: filterStatus.Yellow,
        label: 'between_180_and_270',
        count: 0,
        id: 5,
        condition: p => p.pendingElegibility !== null && p.pendingElegibility >= 180 && p.pendingElegibility < 271,
        filterText: 'elegibility_pending between_180_and_270',
      },
      {
        status: filterStatus.Red,
        label: 'more_than_270',
        count: 0,
        id: 6,
        condition: p => p.pendingElegibility !== null && p.pendingElegibility > 270,
        mostUsed: true,
        collapsedText: 'collapsed_more_than_270',
        filterText: 'elegibility_pending more_than_270',
      },
    ],
    filter_last_disbursement_date: [
      {
        status: filterStatus.Green,
        label: 'more_than_6_months',
        count: 0,
        id: 7,
        condition: p => {
          return (
            p.baseInformation &&
            p.baseInformation.lastExtensionApprobedDate &&
            (new Date(p.baseInformation.lastExtensionApprobedDate).getTime() - new Date(p.revisionDate).getTime()) /
              milisecondsToDays >
              180
          );
        },
        filterText: 'filter_last_disbursement_date more_than_6_months',
      },
      {
        status: filterStatus.Yellow,
        label: 'on_3_months',
        count: 0,
        id: 8,
        condition: p => {
          return (
            p.baseInformation &&
            p.baseInformation.lastExtensionApprobedDate &&
            (new Date(p.baseInformation.lastExtensionApprobedDate).getTime() - new Date(p.revisionDate).getTime()) /
              milisecondsToDays >=
              90 &&
            (new Date(p.baseInformation.lastExtensionApprobedDate).getTime() - new Date(p.revisionDate).getTime()) /
              milisecondsToDays <=
              180
          );
        },
        filterText: 'filter_last_disbursement_date on_3_months',
      },
      {
        status: filterStatus.Red,
        label: 'out_of_date',
        count: 0,
        id: 9,
        condition: p => {
          return (
            p.baseInformation &&
            p.baseInformation.lastExtensionApprobedDate &&
            (new Date(p.baseInformation.lastExtensionApprobedDate).getTime() - new Date(p.revisionDate).getTime()) /
              milisecondsToDays <
              90
          );
        },
        mostUsed: true,
        collapsedText: 'collapsed_out_of_date',
        filterText: 'filter_last_disbursement_date out_of_date',
      },
    ],
  },
  financial_execution: {
    accumulated_disbursements: [
      {
        status: filterStatus.Green,
        label: 'filter_satisfactory',
        count: 0,
        id: 10,
        condition: p => p.financialInformation && p.financialInformation.accumuledDisbursedCurve === 0,
        filterText: 'accumulated_disbursements filter_satisfactory',
      },
      {
        status: filterStatus.Yellow,
        label: 'filter_alert',
        count: 0,
        id: 11,
        condition: p => p.financialInformation && p.financialInformation.accumuledDisbursedCurve === 1,
        filterText: 'accumulated_disbursements filter_alert',
      },
      {
        status: filterStatus.Red,
        label: 'filter_problem',
        count: 0,
        id: 12,
        condition: p => p.financialInformation && p.financialInformation.accumuledDisbursedCurve === 2,
        mostUsed: true,
        collapsedText: 'collapsed_filter_problem',
        filterText: 'accumulated_disbursements filter_problem',
      },
      {
        status: filterStatus.Na,
        label: 'not_assigned_initials',
        count: 0,
        id: 37,
        condition: p => p.financialInformation && p.financialInformation.accumuledDisbursedCurve === 3,
        collapsedText: 'not_assigned_initials',
        filterText: 'accumulated_disbursements not_assigned_initials',
      },
    ],
    year_disbursements: [
      {
        status: filterStatus.Green,
        label: 'with_disbursements',
        count: 0,
        id: 13,
        condition: p => {
          return (
            p.financialInformation &&
            p.financialInformation.lastDisbursementInDays !== null &&
            p.financialInformation.lastDisbursementInDays < 180
          );
        },
      },
      {
        status: filterStatus.Yellow,
        label: 'without_disbursements_last_6_months',
        count: 0,
        id: 14,
        condition: p => {
          return (
            p.financialInformation &&
            p.financialInformation.lastDisbursementInDays !== null &&
            p.financialInformation.lastDisbursementInDays >= 180 &&
            p.financialInformation.lastDisbursementInDays <= 365
          );
        },
      },
      {
        status: filterStatus.Red,
        label: 'without_disbursements_last_12_months',
        count: 0,
        id: 15,
        condition: p => {
          return (
            p.financialInformation &&
            p.financialInformation.lastDisbursementInDays !== null &&
            p.financialInformation.lastDisbursementInDays > 365
          );
        },
        mostUsed: true,
        collapsedText: 'without_disbursements_last_12_months',
      },
    ],
  },
  physical_execution: {
    pmr_initials: [
      {
        status: filterStatus.Green,
        label: 'satisfactory_PMR',
        count: 0,
        id: 16,
        condition: p => p.pmrClassification === PmrClassification.Green,
      },
      {
        status: filterStatus.Yellow,
        label: 'alert_PMR',
        count: 0,
        id: 17,
        condition: p => p.pmrClassification === PmrClassification.Yellow,
        mostUsed: true,
        collapsedText: 'alert_PMR',
      },
      {
        status: filterStatus.Red,
        label: 'problem_PMR',
        count: 0,
        id: 18,
        condition: p => p.pmrClassification === PmrClassification.Red,
        mostUsed: true,
        collapsedText: 'problem_PMR',
      },
      {
        status: filterStatus.Na,
        label: 'pmr_na',
        count: 0,
        id: 36,
        condition: p => p.pmrClassification === PmrClassification.Na,
        collapsedText: 'pmr_na',
      },
    ],
  },
  fiduciary_issues: {
    last_advance_time: [
      {
        status: filterStatus.Green,
        label: 'less_than_180_days',
        count: 0,
        id: 19,
        condition: p =>
          p.financialInformation &&
          p.financialInformation.daysSinceLastAdvance !== null &&
          p.financialInformation.daysSinceLastAdvance < 180,
        filterText: 'last_advance_time less_than_180_days',
      },
      {
        status: filterStatus.Yellow,
        label: 'between_180_and_360',
        count: 0,
        id: 20,
        condition: p =>
          p.financialInformation &&
          p.financialInformation.daysSinceLastAdvance !== null &&
          p.financialInformation.daysSinceLastAdvance >= 180 &&
          p.financialInformation.daysSinceLastAdvance <= 360,
        filterText: 'last_advance_time between_180_and_360',
      },
      {
        status: filterStatus.Red,
        label: 'more_than_360_days',
        count: 0,
        id: 21,
        condition: p =>
          p.financialInformation &&
          p.financialInformation.daysSinceLastAdvance !== null &&
          p.financialInformation.daysSinceLastAdvance > 360,
        mostUsed: true,
        collapsedText: 'collapsed_more_than_360_days',
        filterText: 'last_advance_time more_than_360_days',
      },
    ],
    portfolio_incidence: [
      {
        status: filterStatus.Green,
        label: 'less_than_5_percent_planned',
        count: 0,
        id: 22,
        condition: p => (p.disbursementProjectionAnnual / p.portfolioProjectedAnnual) * 100 < 5,
        filterText: 'portfolio_incidence less_than_5_percent_planned',
      },
      {
        status: filterStatus.Yellow,
        label: 'between_5_and_10_percent_planned',
        count: 0,
        id: 23,
        condition: p => {
          const percent = (p.disbursementProjectionAnnual / p.portfolioProjectedAnnual) * 100;
          return percent >= 5 && percent <= 10;
        },
        filterText: 'portfolio_incidence between_5_and_10_percent_planned',
      },
      {
        status: filterStatus.Red,
        label: 'more_than_10_percent_planned',
        count: 0,
        id: 24,
        condition: p => (p.disbursementProjectionAnnual / p.portfolioProjectedAnnual) * 100 > 10,
        mostUsed: true,
        collapsedText: 'collapsed_more_than_10_percent_planned',
        filterText: 'portfolio_incidence more_than_10_percent_planned',
      },
    ],
    efas: [
      {
        status: filterStatus.Green,
        label: 'presented',
        count: 0,
        id: 25,
        condition: p =>
          p.financialInformation &&
          p.financialInformation.efasPresentations &&
          p.financialInformation.efasPresentations.length &&
          p.financialInformation.efasPresentations.sort(sortNumbers('fiscalYear'))[0].presentationDate &&
          new Date(p.revisionDate).getFullYear() -
            new Date(
              p.financialInformation.efasPresentations.sort(sortNumbers('fiscalYear'))[0].presentationDate,
            ).getFullYear() ===
            1,
        filterText: 'efas presented',
      },
      {
        status: filterStatus.Yellow,
        label: 'presented_obs',
        count: 0,
        id: 26,
        condition: p =>
          p.financialInformation &&
          p.financialInformation.efasPresentations &&
          p.financialInformation.efasPresentations.length &&
          p.financialInformation.efasPresentations.sort(sortNumbers('fiscalYear'))[0].efaComment,
        filterText: 'efas presented_obs',
      },
      {
        status: filterStatus.Red,
        label: 'not_presented',
        count: 0,
        id: 27,
        condition: p =>
          p.financialInformation &&
          p.financialInformation.efasPresentations &&
          p.financialInformation.efasPresentations.length &&
          (!p.financialInformation.efasPresentations.sort(sortNumbers('fiscalYear'))[0].presentationDate ||
            new Date(p.revisionDate).getFullYear() -
              new Date(
                p.financialInformation.efasPresentations.sort(sortNumbers('fiscalYear'))[0].presentationDate,
              ).getFullYear() !==
              1),
        mostUsed: true,
        collapsedText: 'collapsed_not_presented',
        filterText: 'efas not_presented',
      },
    ],
    pari_passu_gap: [
      {
        status: filterStatus.Green,
        label: 'less_than_5_percent',
        count: 0,
        id: 28,
        condition: p =>
          p.financialInformation &&
          p.financialInformation.pariPassuDeviationPercent !== null &&
          p.financialInformation.pariPassuDeviationPercent < 5,
        filterText: 'pari_passu_gap less_than_5_percent',
      },
      {
        status: filterStatus.Yellow,
        label: 'between_5_and_15_percent',
        count: 0,
        id: 29,
        condition: p =>
          p.financialInformation &&
          p.financialInformation.pariPassuDeviationPercent !== null &&
          p.financialInformation.pariPassuDeviationPercent >= 5 &&
          p.financialInformation.pariPassuDeviationPercent <= 15,
        filterText: 'pari_passu_gap between_5_and_15_percent',
      },
      {
        status: filterStatus.Red,
        label: 'more_than_15_percent',
        count: 0,
        id: 30,
        condition: p =>
          p.financialInformation &&
          p.financialInformation.pariPassuDeviationPercent !== null &&
          p.financialInformation.pariPassuDeviationPercent > 15,
        mostUsed: true,
        collapsedText: 'collapsed_more_than_15_percent',
        filterText: 'pari_passu_gap more_than_15_percent',
      },
    ],
  },
  social_env_issues: {
    mici: [
      {
        status: filterStatus.Green,
        label: 'without_mici',
        count: 0,
        id: 31,
        condition: p => !p.withMici,
      },
      {
        status: filterStatus.Red,
        label: 'with_mici',
        count: 0,
        id: 32,
        condition: p => p.withMici,
        mostUsed: true,
        collapsedText: 'with_mici',
      },
    ],
    esg_grade: [
      {
        status: filterStatus.Green,
        label: 'esg_c',
        count: 0,
        id: 33,
        condition: p => p.esgClasification === 'C',
        filterText: 'esg_grade esg_c',
      },
      {
        status: filterStatus.Yellow,
        label: 'esg_b',
        count: 0,
        id: 34,
        condition: p => p.esgClasification === 'B',
        filterText: 'esg_grade esg_b',
      },
      {
        status: filterStatus.Red,
        label: 'esg_a',
        count: 0,
        id: 35,
        condition: p => p.esgClasification === 'A',
        mostUsed: true,
        collapsedText: 'collapsed_esg_a',
        filterText: 'esg_grade esg_a',
      },
    ],
  },
  technical_cooperation: {
    execution: [
      {
        status: filterStatus.Na,
        label: 'bank',
        count: 0,
        id: 38,
        condition: p => p.execution === 0,
        mostUsed: true,
        collapsedText: 'bank',
      },
      {
        status: filterStatus.Na,
        label: 'executing_agency',
        count: 0,
        id: 39,
        condition: p => p.execution === 1,
      },
    ],
    taxonomy: [
      {
        status: filterStatus.Na,
        label: 'taxonomy_N/A',
        count: 0,
        id: 40,
        condition: p => p.tcpTaxonomy && p.tcpTaxonomy.code === TaxonomyCode.NA,
      },
      {
        status: filterStatus.Na,
        label: 'taxonomy_RED',
        count: 0,
        id: 41,
        condition: p => p.tcpTaxonomy && p.tcpTaxonomy.code === TaxonomyCode.RED,
      },
      {
        status: filterStatus.Na,
        label: 'taxonomy_OPS',
        count: 0,
        id: 42,
        condition: p => p.tcpTaxonomy && p.tcpTaxonomy.code === TaxonomyCode.OPS,
        mostUsed: true,
        collapsedText: 'taxonomy_OPS',
      },
      {
        status: filterStatus.Na,
        label: 'taxonomy_CLS',
        count: 0,
        id: 43,
        condition: p => p.tcpTaxonomy && p.tcpTaxonomy.code === TaxonomyCode.CLS,
        mostUsed: true,
        collapsedText: 'taxonomy_CLS',
      },
    ],
    gcm_alert: [
      {
        status: filterStatus.Green,
        label: 'issue_en',
        count: 0,
        id: 44,
        condition: p => p.gcmAlerts && p.gcmAlerts.length && p.gcmAlerts.find(a => a.type === GcmStatus.Issue),
      },
      {
        status: filterStatus.Yellow,
        label: 'warning_en',
        count: 0,
        id: 45,
        condition: p => p.gcmAlerts && p.gcmAlerts.length && p.gcmAlerts.find(a => a.type === GcmStatus.Warning),
      },
      {
        status: filterStatus.Red,
        label: 'in_cancellation_en',
        count: 0,
        id: 46,
        condition: p => p.gcmAlerts && p.gcmAlerts.length && p.gcmAlerts.find(a => a.type === GcmStatus.InCancellation),
        mostUsed: true,
        collapsedText: 'in_cancellation_en',
      },
    ],
  },
};
