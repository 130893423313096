import React from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import FilterIndicators from '../../../../../models/FilterIndicators';
import { multiSelectStyle, DropdownIndicator } from '../../../../Filter/FilterComponent.Styles';
import SelectOption from '../../../../../models/SelectOption';

type Props = {
  filterCountriesOnSelect: Function;
  regions: SelectOption[];
  reportFilters: FilterIndicators;
  reportFiltersChanged: Function;
};
export const CustomizableRegionFilter = ({
  filterCountriesOnSelect,
  regions,
  reportFilters,
  reportFiltersChanged,
}: Props) => {
  const { t } = useTranslation();
  const [localFilter, setLocalFilter] = React.useState(reportFilters);

  const handleChange = value => {
    if (value === null) {
      value = [];
    }

    const newFilter = {
      ...localFilter,
      common: {
        ...localFilter.common,
        regionId: value,
      },
    };
    setLocalFilter(newFilter);
    reportFiltersChanged({ regionId: newFilter.common.regionId });
    filterCountriesOnSelect(value);
  };

  return (
    <Select
      id="regionsSelect"
      defaultValue={localFilter.common.regionId}
      value={localFilter.common.regionId}
      isMulti
      components={{ DropdownIndicator }}
      name="regions"
      options={regions}
      className="basic-multi-select"
      classNamePrefix="All"
      placeholder={t('all')}
      onChange={value => handleChange(value)}
      styles={multiSelectStyle}
    />
  );
};
