import React from 'react';
import { AccordanceType } from '../../../../../models/AccordanceType';
import AccordanceInfoItem from './AccordanceInfoItem';
import Comments from '../../../../Util/Comments/Comments';
import AccordanceFiles from '../AccordanceFiles/AccordanceFiles';
import { newAccordanceComment, deleteAccordanceComment } from '../../../../../api/accordanceApi';
import { isDraftOrPending } from '../NewAccordanceForm/NewAccordanceFormUtil';
import { useTranslation } from 'react-i18next';

type Props = {
  accordance: any;
};

const SupervisionActionAndComments = ({ accordance }: Props) => {
  const { t } = useTranslation();
  const { id, accordanceFiles, accordanceComments, accordanceState } = accordance;

  return (
    <>
      {/* {accordance.accordanceType === AccordanceType.Monitoring && (
        <div className="horizontal-row">
          <AccordanceInfoItem
            label={t('supervision_action')}
            data={
              accordance.supervisionAction && accordance.supervisionAction.description
                ? accordance.supervisionAction.description
                : '-'
            }
            fullWidth
          />
        </div>
      )} */}
      {accordanceFiles && accordanceFiles.length > 0 && <AccordanceFiles files={accordanceFiles} />}
      <Comments
        comments={accordanceComments || []}
        submitComment={comment => newAccordanceComment(id, comment)}
        submitDeleteComment={commentId => deleteAccordanceComment(id, commentId)}
        canDeleteComments={isDraftOrPending(accordanceState)}
      />
    </>
  );
};

export default SupervisionActionAndComments;
