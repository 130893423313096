import { AnalyticsKeyEnum } from '../../../../models/AnalyticsKeyEnum';
import { isMobile } from '../../../../services/booleanUtil';
import { createArrayOpacityBlues, getIndicatorData } from '../../../Portfolio/IndicatorsHelpers/indicatorHelperUtil';

const HORIZONTAL_BAR_EXTRA_SPACE = '                    ';

const addPortfolioIndicatorsUseOfFilters = (analyticList, analyticIndicators) => {
  const analyticIndicator = getIndicatorData(analyticList, AnalyticsKeyEnum.PORTFOLIO_INDICATORS_USE_OF_FILTERS);
  analyticIndicator && addUseOfFilters(analyticIndicator, analyticIndicators);
};
const addProjectIndicatorsUseOfFilters = (analyticList, analyticIndicators) => {
  const analyticIndicator = getIndicatorData(analyticList, AnalyticsKeyEnum.PROJECT_INDICATORS_USE_OF_FILTERS);
  analyticIndicator && addUseOfFilters(analyticIndicator, analyticIndicators);
};

const addUseOfFilters = (analyticIndicator, analyticIndicators) => {
  const filtersNumbers = analyticIndicator.metrics;
  filtersNumbers.sort((a, b) => (a.metricValue > b.metricValue ? -1 : 1));
  const labels = filtersNumbers.map(f => f.metricName.toLowerCase());
  const values = filtersNumbers.map(f => f.metricValue);

  const data = {
    indicatorKey: analyticIndicator.key,
    type: 'horizontal',
    title: 'use_of_filters',
    data: {
      labels: [
        HORIZONTAL_BAR_EXTRA_SPACE,
        HORIZONTAL_BAR_EXTRA_SPACE,
        HORIZONTAL_BAR_EXTRA_SPACE,
        HORIZONTAL_BAR_EXTRA_SPACE,
        HORIZONTAL_BAR_EXTRA_SPACE,
        HORIZONTAL_BAR_EXTRA_SPACE,
        HORIZONTAL_BAR_EXTRA_SPACE,
      ],
      subTextlabels: labels,
      subLabels: values,
      datasets: [
        {
          data: values,
          backgroundColor: createArrayOpacityBlues(),
          borderWidth: 0,
          barThickness: isMobile() ? 4 : 8,
        },
      ],
    },
  };

  analyticIndicators.push(data);
};
export { addPortfolioIndicatorsUseOfFilters, addProjectIndicatorsUseOfFilters };
