import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getPendingTasks } from '../../../api/accordanceApi';
import { AccordanceType } from '../../../models/AccordanceType';
import { EventType } from '../../../models/EventType';
import { IndicatorKeyEnum } from '../../../models/IndicatorKeyEnum';
import SupervisionModal from '../../ProjectDetail/ProjectTabs/Supervision/SupervisionModal/SupervisionModal';
import AccordanceDetail from '../../Util/Modals/AccordanceDetail/AccordanceDetail';
import LinkEventOutlook from '../../Util/Modals/LinkProjectOutlook/LinkEventOutlook';
import PortfolioAccordanceTable from '../PortfolioAccordanceTable/PortfolioAccordanceTable';
import { ProjectCounter } from '../ProjectCounter/ProjectCounter';
import './PendingTasksDetail.scss';
import { ReactComponent as IconFilter } from '../../../assets/icons/u_filters-v.svg';

type Props = {
  currentIndicator: any;
};

export const PendingTasksDetail = ({ currentIndicator }: Props) => {
  const { t } = useTranslation();
  const [revisionAccordancesTotal, setRevisionAccordancesTotal] = useState(0);
  const [monitorAccordancesTotal, setMonitorAccordancesTotal] = useState(0);
  const [intergoupAccordancesTotal, setIntergoupAccordancesTotal] = useState(0);
  const [pendigTasksList, setPendigTasksList] = useState<any[]>([]);
  const [showAccordanceDetail, setShowAccordanceDetail] = useState(false);
  const [showCreateReminder, setShowCreateReminder] = useState(false);
  const [selectedProject, setSelectedProject] = useState<any>({});
  const [supervisionActivitiesTotal, setSupervisionActivitiesTotal] = useState(0);
  const [showActivityDetail, setShowActivityDetail] = useState(false);

  const [customEvent, setCustomEvent] = useState<any>({});

  const handleGetPendingTasks = async () => {
    const response: any = await getPendingTasks();
    setRevisionAccordancesTotal(response.revisionCount);
    setMonitorAccordancesTotal(response.monitoringCount);
    setIntergoupAccordancesTotal(response.intergroupCount);
    setPendigTasksList(
      addSortValues([...response.accordances, ...parseActivitiesToAccordanceStructure(response.supervisionPlanItems)]),
    );
    setSupervisionActivitiesTotal(response.supervisionCount);
  };

  const handleProject = (accordanceObject: any, isLinkEvent = false) => {
    if (isLinkEvent) {
      setCustomEvent({
        id: accordanceObject.id,
        eventType:
          accordanceObject.supervisionPlanId !== undefined
            ? EventType.SupervisoryAction
            : accordanceObject.accordanceType === AccordanceType.Monitoring
            ? EventType.MonitoringAccordance
            : EventType.RevisionAccordance,
        projectId: accordanceObject.project.id,
        title:
          accordanceObject.supervisionPlanId !== undefined
            ? accordanceObject.description
            : accordanceObject.project.name,
        multiple: [],
      });
      setShowCreateReminder(true);
    } else {
      setSelectedProject(accordanceObject);
      if (accordanceObject.supervisionPlanId !== undefined) {
        setShowActivityDetail(true);
      } else {
        setShowAccordanceDetail(true);
      }
    }
  };

  const hideModal = () => {
    setShowAccordanceDetail(false);
  };

  useEffect(() => {
    handleGetPendingTasks();
  }, []);

  const handleCloseSupervisionModal = (refreshData: boolean) => {
    setShowActivityDetail(false);
    if (refreshData) {
      handleGetPendingTasks();
    }
  };

  return (
    <div className="pending-tasks-detail-container">
      <div className="pending-tasks-header-content">
        <div className="pending-tasks-values-container">
          <div className='date-filter'>
          <div className="value">{revisionAccordancesTotal}</div>
          <div className="subtitle">{t('revision_accordances')}</div>
          </div>
    
        </div>
        <div className="pending-tasks-values-container">
          <div className='date-filter'>
            <div className="value">{monitorAccordancesTotal}</div>
            <div className="subtitle">{t('monitor_accordances')}</div>
            </div>
           
        </div>
        <div className="pending-tasks-values-container">
          <div className='date-filter'>
            <div className="value">{intergoupAccordancesTotal}</div>
            <div className="subtitle">{t('inter_group_accordances')}</div>
           </div>
         
        </div>
        <div className="pending-tasks-values-container">
        <div className='date-filter'>
          <div className="value">{supervisionActivitiesTotal}</div>
          <div className="subtitle">{t('supervisory_activities')}</div>
          </div>
          
        </div>
      </div>
      <div className="table-container">
        <PortfolioAccordanceTable
          accordances={pendigTasksList}
          handleProject={handleProject}
          indicatorKey={currentIndicator.indicatorKey}
        />
        <ProjectCounter
          arrayToCount={pendigTasksList}
          countProjects={true}
          indicatorKey={IndicatorKeyEnum.USER_PENDING_TASKS}
          widthStyle={'1270px'}
          nestedProject={'project'}
        />
      </div>
      <AccordanceDetail
        isOpen={showAccordanceDetail}
        closeModal={hideModal}
        accordance={selectedProject}
        showJustificationModal={() => {}}
        onApproveHandler={() => {}}
        changeAccordanceStatus={() => {}}
      />
      <LinkEventOutlook
        isOpen={showCreateReminder}
        closeModal={() => setShowCreateReminder(false)}
        event={customEvent}
        currentProjectId={customEvent.projectId}
      />
      <SupervisionModal
        modalIsOpen={showActivityDetail}
        openModal={() => setShowActivityDetail(true)}
        closeModal={(refreshData: boolean) => handleCloseSupervisionModal(refreshData)}
        activity={selectedProject}
        currentProjectId={selectedProject.projectId}
      />
    </div>
  );
};

const parseActivitiesToAccordanceStructure = (list: any[]) => {
  return list.map(item => {
    item.project = { code: item.projectCode, loanNumber: item.loan, name: item.projectName, id: item.projectId };
    item.accordanceType = item.type;
    item.expirationDate = item.plannedDate;
    return item;
  });
};

export const addSortValues = list => {
  return list.map(i => {
    i.typeSortValue = i.supervisionPlanId !== undefined ? i.type + 10 : i.accordanceType;
    return i;
  });
};
