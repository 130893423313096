import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { styles } from '../Styles';
import './LinkProjectOutlook.scss';
import SaveButton from '../../Buttons/SaveButton/SaveButton';
import CustomSelect from '../../CustomSelect/CustomSelect';
import CloseCrossButton from '../../Buttons/CloseCrossButton/CloseCrossButton';
import { syncProjectCalendarOutlook } from '../../../../redux/actions/currentProject';
import { options } from './util';
import { trackActionClicked } from '../../../../api/trackActionClickedApi';
import { TrackedActions } from '../../../../models/TrackedActionsEnum';
import BaseModal from '../BaseModal';

type Props = {
  isOpen: boolean;
  closeModal: any;
  resetSwitch: any;
};

const initialReminder = {
  reminderActivityEvent: 7,
  reminderMonitoringAccordanceEvent: 7,
  remindersRevisionAccordanceEvent: 7,
  reminderAcquisitionsEvent: 7,
  reminderDisbursementsEvent: 7,
  remindersIntergroupAccordanceEvent: 7,
  remindersSupervisionActionEvent: 7,
  reminderMissionEvent: 7,
  reminderExternalEvent: 7,
  reminderLifeCyclesEvent: 7,
};

const LinkProjectOutlook = (props: Props) => {
  const { t } = useTranslation();
  const { isOpen, closeModal, resetSwitch } = props;
  const dispatch = useDispatch();
  const [reminderEvent, setReminderEvent] = React.useState<any>({ ...initialReminder });

  React.useEffect(() => {
    setReminderEvent({ ...initialReminder });
  }, [isOpen]);

  const syncData = () => {
    dispatch(syncProjectCalendarOutlook(reminderEvent));
    closeModal();
    trackActionClicked(TrackedActions.projectLinkedOutlook);
  };

  const selectDisbursement = value => {
    const newEvent = { ...reminderEvent, reminderDisbursementsEvent: value.id };
    setReminderEvent(newEvent);
  };
  const selectAcquisition = value => {
    const newEvent = { ...reminderEvent, reminderAcquisitionsEvent: value.id };
    setReminderEvent(newEvent);
  };
  const selectActivity = value => {
    const newEvent = { ...reminderEvent, reminderActivityEvent: value.id };
    setReminderEvent(newEvent);
  };
  const selectMonitor = value => {
    const newEvent = { ...reminderEvent, reminderMonitoringAccordanceEvent: value.id };
    setReminderEvent(newEvent);
  };
  const selectRevision = value => {
    const newEvent = { ...reminderEvent, remindersRevisionAccordanceEvent: value.id };
    setReminderEvent(newEvent);
  };
  const selectInterGroup = value => {
    const newEvent = { ...reminderEvent, remindersIntergroupAccordanceEvent: value.id };
    setReminderEvent(newEvent);
  };
  const selectSupervisionActions = value => {
    const newEvent = { ...reminderEvent, remindersSupervisionActionEvent: value.id };
    setReminderEvent(newEvent);
  };
  const selectMission = value => {
    const newEvent = { ...reminderEvent, reminderMissionEvent: value.id };
    setReminderEvent(newEvent);
  };
  const selectExternal = value => {
    const newEvent = { ...reminderEvent, reminderExternalEvent: value.id };
    setReminderEvent(newEvent);
  };
  const selectLifeCycle = value => {
    const newEvent = { ...reminderEvent, reminderLifeCyclesEvent: value.id };
    setReminderEvent(newEvent);
  };
  const closeAndResetSwitch = () => {
    resetSwitch();
    closeModal();
  };

  return (
    <BaseModal
      isOpen={isOpen}
      onRequestClose={closeAndResetSwitch}
      style={{ overlay: { ...styles.overlay, ...{ overflowY: 'auto' } } }}
      className="modal-link-project-outlook-content"
    >
      <div className="modal-link-project-outlook-container">
        <div className="cross-container">
          <CloseCrossButton handleClick={closeAndResetSwitch} />
        </div>
        <h3>{t('link_calendar_outlook')}</h3>
        <h5>{t('sync_outlook_message')}</h5>
        <div className="list">
          <div className="label-select">
            <span className="label">{t('disbursements')}</span>
            <CustomSelect
              data-testid={1}
              style={{ width: 185 }}
              handleSelected={selectDisbursement}
              options={options}
              selectedOption={options.find(item => item.id === reminderEvent.reminderDisbursementsEvent)}
            />
          </div>
          <div className="label-select">
            <span className="label">{t('acquisitions')}</span>
            <CustomSelect
              data-testid={2}
              style={{ width: 185 }}
              handleSelected={selectAcquisition}
              options={options}
              selectedOption={options.find(item => item.id === reminderEvent.reminderAcquisitionsEvent)}
            />
          </div>
          <div className="label-select">
            <span className="label">{t('activities')}</span>
            <CustomSelect
              data-testid={3}
              style={{ width: 185 }}
              handleSelected={selectActivity}
              options={options}
              selectedOption={options.find(item => item.id === reminderEvent.reminderActivityEvent)}
            />
          </div>
          <div className="label-select">
            <span className="label">{t('monitor_accordances')}</span>
            <CustomSelect
              data-testid={4}
              style={{ width: 185 }}
              handleSelected={selectMonitor}
              options={options}
              selectedOption={options.find(item => item.id === reminderEvent.reminderMonitoringAccordanceEvent)}
            />
          </div>
          <div className="label-select">
            <span className="label">{t('revision_accordances')}</span>
            <CustomSelect
              data-testid={5}
              style={{ width: 185 }}
              handleSelected={selectRevision}
              options={options}
              selectedOption={options.find(item => item.id === reminderEvent.remindersRevisionAccordanceEvent)}
            />
          </div>
          <div className="label-select">
            <span className="label">{t('inter_group_accordances')}</span>
            <CustomSelect
              data-testid={6}
              style={{ width: 185 }}
              handleSelected={selectInterGroup}
              options={options}
              selectedOption={options.find(item => item.id === reminderEvent.remindersIntergroupAccordanceEvent)}
            />
          </div>
          <div className="label-select">
            <span className="label">{t('supervisory_actions')}</span>
            <CustomSelect
              data-testid={10}
              style={{ width: 185 }}
              handleSelected={selectSupervisionActions}
              options={options}
              selectedOption={options.find(item => item.id === reminderEvent.remindersSupervisionActionEvent)}
            />
          </div>
          <div className="label-select">
            <span className="label">{t('missions')}</span>
            <CustomSelect
              data-testid={7}
              style={{ width: 185 }}
              handleSelected={selectMission}
              options={options}
              selectedOption={options.find(item => item.id === reminderEvent.reminderMissionEvent)}
            />
          </div>
          <div className="label-select">
            <span className="label">{t('external')}</span>
            <CustomSelect
              data-testid={8}
              style={{ width: 185 }}
              handleSelected={selectExternal}
              options={options}
              selectedOption={options.find(item => item.id === reminderEvent.reminderExternalEvent)}
            />
          </div>
          <div className="label-select">
            <span className="label">{t('lifecycle')}</span>
            <CustomSelect
              data-testid={9}
              style={{ width: 185 }}
              handleSelected={selectLifeCycle}
              options={options}
              selectedOption={options.find(item => item.id === reminderEvent.reminderLifeCyclesEvent)}
            />
          </div>
        </div>
        <div className="horizontal">
          <SaveButton handleClick={syncData} customText={t('link')} />
        </div>
      </div>
    </BaseModal>
  );
};

export default LinkProjectOutlook;
