import React from 'react';
import { useTranslation } from 'react-i18next';
import { SortCriteria } from '../util';
import styles from './TableHeader.module.scss';

type Props = {
  onSortClick: any;
};

const TableHeader = ({ onSortClick }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.description}>
        <span className={styles.text} onClick={() => onSortClick(SortCriteria.Description)}>
          {t('description')}
        </span>
        <span className={styles.sortIcon} onClick={() => onSortClick(SortCriteria.Description)}></span>
      </div>
      <div className={styles.type} onClick={() => onSortClick(SortCriteria.Type)}>
        <span className={styles.text}>{t('type')}</span>
        <span className={styles.sortIcon}></span>
      </div>
      <div className={styles.responsible} onClick={() => onSortClick(SortCriteria.PersonInCharge)}>
        <span className={styles.text}>{t('responsible')}</span>
        <span className={styles.sortIcon}></span>
      </div>
      <div className={styles.plannedDate} onClick={() => onSortClick(SortCriteria.PlannedDate)}>
        <span className={styles.text}>{t('planned_date')}</span>
        <span className={styles.sortIcon}></span>
      </div>
      <div className={styles.status} onClick={() => onSortClick(SortCriteria.State)}>
        <span className={styles.text}>{t('status')}</span>
        <span className={styles.sortIcon}></span>
      </div>
    </div>
    
  );
};

export default TableHeader;
