import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LinkText from '../../../../Util/Buttons/LinkText/LinkText';
import LabelData from '../../../../Util/LabelData/LabelData';
import { emptyValue } from '../constants';

type Props = {
  eventData: any;
  createAccordanceReminder: any;
  isCalendarIndicator?: boolean;
};
export const SecretaryEventDetail = ({ eventData, createAccordanceReminder, isCalendarIndicator }: Props) => {
  const { t } = useTranslation();

  if (!eventData) {
    eventData = {
      name: emptyValue,
      country: { name: emptyValue },
      type: emptyValue,
      startDate: emptyValue,
      endDate: emptyValue,
      city: emptyValue,
    };
  }

  const { name, country, type, startDate, endDate, city } = eventData;
  return (
    <>
      <LabelData label={t('title')} data={name} reverse={true} />
      <LabelData label={t('location')} data={country && country.name ? country.name : emptyValue} reverse={true} />
      <LabelData label={t('type')} data={type ? type : emptyValue} reverse={true} />

      <div style={{ display: 'flex' }}>
        <LabelData label={t('date')} data={moment(startDate).format('DD/MM/YYYY')} reverse={true} />
        <LinkText
          label={t('create_reminder')}
          onClick={createAccordanceReminder}
          customStyle={{ margin: '0px 0px 3px 10px' }}
        />
      </div>
      <LabelData
        label={t('schedule')}
        data={`${moment(startDate).format('hh:mm a')} - ${moment(endDate).format('hh:mm a')} ${showCityLocalTime(
          city,
          t,
        )}`}
        reverse={true}
      />
    </>
  );
};

const showCityLocalTime = (city, t) => (city ? `(   ${city} ${t('local_time')}    )` : '');
