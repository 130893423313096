import { isMillionNumber } from './booleanUtil';

const responsiveWidth = 768;

const getIdFromUrl = (search: string) => {
  return parseInt(search.replace('?id=', ''));
};

const percentage = (value: number, total: number) => {
  return total !== 0 ? (value / total) * 100 : 0;
};

const formatedValueProperty = (fieldType, propValue) => {
  let returnValue;
  if (fieldType === 'money') {
    returnValue = Math.trunc(propValue);
  } else if (fieldType === 'percentage') {
    returnValue = Math.round(propValue);
  } else if (fieldType === 'numeric') {
    returnValue = propValue;
  } else if (fieldType === 'pmrType') {
    returnValue = parseFloat(propValue);
  } else if (fieldType === 'ceil') {
    returnValue = Math.round(propValue);
  } else if (fieldType === 'two_decimal') {
    returnValue = toFixedX(propValue, 2);
  } else if (fieldType === 'ceil_percentage') {
    returnValue = Math.ceil(propValue);
  } else if (fieldType === 'no_round_two_decimal') {
    returnValue = toFixedX(propValue, 2);
  }

  return returnValue;
};

const toFixedX = (value, x) => {
  return parseFloat(parseFloat(value).toFixed(x));
};

const formatMillionNumber = number => {
  return isMillionNumber(number) ? number / 1000000 : number;
};
const substract = (minuend: number, subtracting: number) => {
  return minuend - subtracting;
};

export { responsiveWidth, getIdFromUrl, percentage, formatedValueProperty, toFixedX, formatMillionNumber, substract };
