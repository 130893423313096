import React from 'react';
import './RemoveEdit.scss';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

type Props = {
  onDelete: any;
  onEdit: any;
  hideRemove?: boolean;
  hideEdit?: boolean;
};

const RemoveEdit = ({ onDelete, onEdit, hideRemove, hideEdit }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      {!hideRemove && (
        <div className="remove-edit" onClick={onDelete}>
          <span className="icon-container">
            <DeleteIcon style={{ color: 'white' }} fontSize={'small'} />
          </span>
          <span className="text">{t('remove')}</span>
        </div>
      )}
      {!hideEdit && (
        <div className="remove-edit edit" onClick={onEdit}>
          <span className="icon-container">
            <EditIcon style={{ color: 'white' }} fontSize={'small'} />
          </span>
          <span className="text">{t('edit')}</span>
        </div>
      )}
    </>
  );
};

export default RemoveEdit;
