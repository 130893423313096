import React from 'react';
import { useTranslation } from 'react-i18next';
import SummaryHeaderSingleValue from '../../../SummaryHeaderSingleValue/SummaryHeaderSingleValue';
import {
  renderSingularOrPluralByInternalFilterApplied,
  renderTextByInternalFilterApplied,
  renderValueByInternaFilterApplied,
} from './Utils';
import styles from '../../SharedStyles/HeaderDrops.module.scss';
import { SummaryTcpInternalFilters } from '../SummaryTcp';

const { firstDropContainer, dropsTitle, dropsValues } = styles;

type Props = {
  filterBy: SummaryTcpInternalFilters;
  handleModuleFilterState: any;
};

export const CompositionModule = ({ filterBy, handleModuleFilterState }: Props) => {
  const { t } = useTranslation();

  const { ctsNatProjectAmount, ctsRegProjectAmount, ctsNatApprovedAmount, ctsRegApprovedAmount } =
    handleModuleFilterState;
  return (
    <div className={firstDropContainer}>
      <div className={dropsTitle}>{t('composition')}</div>
      <div className={`${dropsValues} drops-value`}>
        <SummaryHeaderSingleValue
          value={renderValueByInternaFilterApplied(filterBy, ctsNatProjectAmount.value, ctsRegProjectAmount.value)}
          title={renderSingularOrPluralByInternalFilterApplied(filterBy, ctsNatProjectAmount, ctsRegProjectAmount, t)}
        />
        <SummaryHeaderSingleValue
          value={renderValueByInternaFilterApplied(filterBy, ctsNatApprovedAmount.value, ctsRegApprovedAmount.value)}
          title={renderTextByInternalFilterApplied(filterBy, ctsNatApprovedAmount.text, ctsRegApprovedAmount.text, t)}
          millionLogic={true}
        />
      </div>
    </div>
  );
};
