import GeneralIndicatorsImg from '../../../../assets/button_icon_01.svg';
import AnnualProjectionImg from '../../../../assets/button_icon_02.svg';
import ProjectDetailImg from '../../../../assets/button_icon_03.svg';
import AccordancesImg from '../../../../assets/button_icon_04.svg';
import { ReviewReportSectionsEnum } from '../../../../models/ReportEnums';
import { ReportType } from '../../../../models/ReportType';

export const initialValues = [
  {
    buttonImg: GeneralIndicatorsImg,
    parent: { key: ReviewReportSectionsEnum.general_indicators_of_the_portfolio, value: false },
    children: [],
    radioButton: false,
  },
  {
    buttonImg: AnnualProjectionImg,
    parent: { key: ReviewReportSectionsEnum.annual_disbursements_proj, value: false },
    children: [
      {
        id: 1,
        list: [
          { key: ReviewReportSectionsEnum.original_disbursements_annual_projection, value: true },
          { key: ReviewReportSectionsEnum.adjusted_disbursements_annual_projection, value: false },
        ],
      },
    ],
    radioButton: true,
  },
  {
    buttonImg: ProjectDetailImg,
    parent: { key: ReviewReportSectionsEnum.detail_of_each_selected_project, value: false },
    children: [
      {
        id: 2,
        list: [
          { key: ReviewReportSectionsEnum.base_information, value: true },
          { key: ReviewReportSectionsEnum.planning, value: true },
          { key: ReviewReportSectionsEnum.financial, value: true },
          { key: ReviewReportSectionsEnum.risk, value: false },
        ],
      },
    ],
    radioButton: false,
  },
  {
    buttonImg: AccordancesImg,
    parent: { key: ReviewReportSectionsEnum.revision_accordances, value: false },
    children: [
      {
        id: 3,
        list: [
          { key: ReviewReportSectionsEnum.active_accordance, value: true },
          { key: ReviewReportSectionsEnum.accomplished_accordance, value: true },
          { key: ReviewReportSectionsEnum.cancelled_accordance, value: false },
        ],
      },
      {
        id: 4,
        list: [
          { key: ReviewReportSectionsEnum.previous_revision_accordances, value: true },
          { key: ReviewReportSectionsEnum.current_revision_acordances, value: false },
        ],
      },
      {
        id: 5,
        list: [{ key: ReviewReportSectionsEnum.include_monitoring_accordances, value: false }],
      },
    ],
    radioButton: false,
  },
];

export const updateChildren = (isChecked, key, radioButton, parentKey, item) => {
  return item.children.map(child => {
    child.list.map(o => {
      if (o.key === key) {
        o.value = isChecked;
      }
      if (radioButton && o.key !== key && parentKey === item.parent.key) {
        o.value = !isChecked;
      }
      return o;
    });

    return child;
  });
};

export const elementsChecked = (children, key) => {
  let count = 0;
  for (const child of children) {
    count += child.list.filter(gc => gc.key !== key && gc.value).length;
  }
  return count;
};

export const lastToUncheck = (isChecked, key, radioButton, parentKey, reviewReportSections) => {
  const parent = reviewReportSections.find(section => section.parent && section.parent.key === parentKey);
  return (
    !isChecked &&
    !radioButton &&
    parent &&
    parent.children &&
    parent.children.length &&
    elementsChecked(parent.children, key) === 0
  );
};

export const createParamsForSubmit = (reviewReportSections, currentReview) => {
  const sections: ReviewReportSectionsEnum[] = [];

  for (const option of reviewReportSections) {
    if (!option.children.length && option.parent.value) {
      sections.push(option.parent.key);
    } else if (option.parent.value) {
      for (const child of option.children) {
        addGrandChild(child, sections);
      }
    }
  }

  return {
    ReportType: ReportType.Review,
    year: new Date(currentReview.startDate).getFullYear(),
    sortParameter: 0,
    orderDesc: true,
    isAnnual: false,
    regions: [currentReview.country.id],
    assignedToMe: false,
    Filters: [],
    includeAll: false,
    RevisionId: currentReview.id,
    Sections: sections,
  };
};

export const addGrandChild = (child, sections) => {
  for (const grandChild of child.list) {
    if (grandChild.value) {
      sections.push(grandChild.key);
    }
  }
  return sections;
};
