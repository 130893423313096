import React from 'react';
import { useTranslation } from 'react-i18next';
import './SaveButton.scss';

type Props = {
  handleClick: any;
  customStyle?: {};
  disabled?: boolean;
  customText?: any;
  isAccordance?: boolean;
  closeModalEditReviewer?: any;
};

const SaveButton = (props: Props) => {
  const { handleClick, disabled, customText, isAccordance, closeModalEditReviewer } = props;
  const { t } = useTranslation();
  return (
    <>
      {closeModalEditReviewer ? (
        <button
          style={props.customStyle || {}}
          className={`btn btn-primary ${disabled ? 'disabled' : ''}`}
          onClick={() => {
            handleClick();
            closeModalEditReviewer(false);
          }}
          disabled={disabled}
        >
          {isAccordance === true ? (
            <span className="text">{customText || t('CONFIRM')}</span>
          ) : (
            <span className="text">{customText || t('save')}</span>
          )}
        </button>
      ) : (
        <button
          style={props.customStyle || {}}
          className={`btn btn-primary ${disabled ? 'disabled' : ''}`}
          onClick={() => {
            handleClick();
          }}
          disabled={disabled}
        >
          {isAccordance === true ? (
            <span className="text">{customText || t('CONFIRM')}</span>
          ) : (
            <span className="text">{customText || t('save')}</span>
          )}
        </button>
      )}
      {/* <button
        style={props.customStyle || {}}
        className={`btn btn-primary ${disabled ? 'disabled' : ''}`}
        onClick={() => {
          handleClick();
          closeModalEditReviewer(false);
        }}
        disabled={disabled}
      >
        {isAccordance === true ? (
          <span className="text">{customText || t('CONFIRM')}</span>
        ) : (
          <span className="text">{customText || t('save')}</span>
        )}
      </button> */}
    </>
  );
};

export default SaveButton;
