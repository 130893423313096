import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectsSimpleTableIndicatorForSummaryIndicator } from '../../../../redux/actions/indicatorProjects';
import {
  checkIfSessionFilterExist,
  getFilterSessionValue,
} from '../../../../services/sessionFiltersIndicatorDetailHelper';
import { ProjectCounter } from '../../ProjectCounter/ProjectCounter';
import { HeaderDrops } from './HeaderDrops/HeaderDrops';
import { TableData } from './TableData/TableData';
import { setInheritedIGFilter } from './Util';
import { SummaryIGInternalFilters } from '../../../../enums/summary-ig-internal-filters';

type Props = {
  currentIndicator: any;
  headerTitle: any;
};

export const SummaryIg = ({ currentIndicator, headerTitle }: Props) => {
  const { indicatorProjects } = useSelector<any, any>(state => state);

  const [filterApplied] = useState(
    checkIfSessionFilterExist('igLowerGreater') ? getFilterSessionValue('igLowerGreater') : setInheritedIGFilter(),
  );

  const { indicatorKey, id } = currentIndicator;
  const dispatch = useDispatch();

  useEffect(() => {
    headerTitle(true, 'investment_grants');
    getFilteredSimpleIndicators(dispatch, id, getFilterSessionArray(filterApplied));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterApplied]);

  const getFilterSessionArray = filter => {
    if (filter === SummaryIGInternalFilters.BOTH_IG || filter === SummaryIGInternalFilters.BOTH_IG_FALSE) {
      return [
        { filterType: 1, projectProductType: 3, amount: 3000000 },
        { filterType: 0, projectProductType: 3, amount: 3000000 },
      ];
    } else if (filter === SummaryIGInternalFilters.GREATER_3_IG) {
      return [{ filterType: 1, projectProductType: 3, amount: 3000000 }];
    } else {
      return [{ filterType: 0, projectProductType: 3, amount: 3000000 }];
    }
  };

  return (
    <div>
      <HeaderDrops igMetrics={currentIndicator.data.ig} />
      <TableData />
      <ProjectCounter
        arrayToCount={indicatorProjects}
        countProjects={true}
        indicatorKey={indicatorKey}
        showOnlyProjects={true}
        widthStyle={'1420px'}
      />
    </div>
  );
};

const getFilteredSimpleIndicators = (d, currentIndicatorId, projectProductAmounts) => {
  return d(
    getProjectsSimpleTableIndicatorForSummaryIndicator(currentIndicatorId, {
      beneficiaryTypes: [],
      projectProductAmounts,
    }),
  );
};
