import React from 'react';
import { useTranslation } from 'react-i18next';
import './ReportOptionsButton.scss';

type Props = {
  handleClick: any;
  customStyle?: {};
  disabled?: boolean;
  customText?: any;
};

const ReportOptionsButton = (props: Props) => {
  const { handleClick, disabled, customText } = props;
  const { t } = useTranslation();

  return (
    <button
      style={props.customStyle || {}}
      className={`report-option-button-container ${disabled ? 'disabled' : ''}`}
      onClick={handleClick}
      disabled={disabled}
    >
      <span className="text">{customText || t('save')}</span>
    </button>
  );
};

export default ReportOptionsButton;
