import React from 'react';
import './ProgressChartDetailContent.scss';
import { ProgressBarIndicator } from '../../Portfolio/CardIndicator/Cards';
import Indicator from '../../../models/Indicator';

interface ReceivedProps {
  currentIndicator: Indicator;
  showDisbursed?: any;
}

type Props = ReceivedProps;

export const ProgressChartDetailContent = (props: Props) => {
  const { currentIndicator, showDisbursed } = props;

  const itsDisbursed = itsDisbursed => {
    showDisbursed(itsDisbursed);
  };
  return (
    <div className="progress-chart-detail-container">
      <div className="chart-container">
        <ProgressBarIndicator data={currentIndicator.data} showDisbursed={itsDisbursed} />
      </div>
    </div>
  );
};
