import { withStyles, createStyles, Theme, TableCell, TableRow } from '@material-ui/core';

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      color: '#727E8C',
      fontFamily: 'Rubik',
      fontSize: '12px',
      fontWeight: 400,
      marginTop: '0px',
    },
  }),
)(TableCell);

export const StyledTableRowHeader = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 'fit-content',
    },
  }),
)(TableRow);

export const StyledTableCellHeader = withStyles((theme: Theme) =>
  createStyles({
    root: {
      fontWeight: 500,
      color: '#A5ADBB',
      background: '#fff',
      fontFamily: 'Rubik',
      fontSize: '10px',
      cursor: 'default',
      lineHeight: '1.2rem',
    },
  }),
)(TableCell);
