import SelectOption from '../../../../models/SelectOption';

export enum SupervisionStateEnum {
  COMPLETED,
  IN_PROCESS,
  PENDING,
  WITH_DELAYS,
  CANCELLED,
}

export const getSelectOptions = data => {
  const options: SelectOption[] = [];

  data
    .sort((a, b) => b - a)
    .forEach((year, ind) => {
      const option = {
        id: ind,
        label: `${year}`,
        value: `${year}`,
      };
      options.push(option);
    });

  return options;
};

export enum SupervisionPlanItemState {
  Pending = 0,
  InProgress,
  WithDelays,
  Completed,
  Cancelled,
}

export enum SupervisionPlanItemType {
  CriticalProduct,
  AcquisitionTrustConsulting,
  FinancialTrustConsulting,
  TechnicalConsultancy,
  TrainingWithExecutingAgencies,
  AdministrativeMission,
  ProcurementSupervisionVisit,
  EnvironmentalSupervisionVisit,
  FinancialSupervisionVisit,
  TechnicalSupervisionVisit,
  OtherActivities,
}

export interface SupervisionPlanActivity {
  id: number;
  description: string;
  plannedDate: string;
  personsInCharge?: any[];
  state: SupervisionPlanItemState;
  type: SupervisionPlanItemType;
  criticalProducts: any[] | null;
  hasAlert: boolean;
  complianceDate: string;
  location: string;
  consultant: string;
  comments: any;
  plannedBudget: number;
  fundingSource: number;
  operativeAlerts: any[];
}

export enum SupervisionPlanMode {
  SIMPLIFIED,
  DETAILED,
  DETAILED_NO_DATA,
}

export const getSupervisionPlanMode = data => {
  if (data === null) {
    return SupervisionPlanMode.DETAILED_NO_DATA;
  } else if (data && data.mode === SupervisionPlanMode.SIMPLIFIED) {
    return SupervisionPlanMode.SIMPLIFIED;
  } else {
    return SupervisionPlanMode.DETAILED;
  }
};

export enum SortCriteria {
  Default,
  Description,
  Type,
  PersonInCharge,
  PlannedDate,
  State,
}
