export enum BarChartColorPalleteEnum {
  '#416DBC',
  '#00CFDD',
  '#91E2A1',
  '#FDAC41',
  '#F7DD00',
  '#ECDBC0',
  '#F9C268',
  '#D3E0C5',
  '#A6D277',
  '#DED6EA',
  '#417FFE',
  '#7A629B',
  '#C2B0DB',
  '#B1EDF1',
  '#ED5A4D',
  '#E4CDD2',
  '#E092A3',
  '#CCE4DE',
  '#91DECD',
  '#CCB0F5',
  '#00BAC7',
  '#8DA8D7',
  '#9CBFFD',
  '#C70048',
  '#9C395D',
  '#91D0DE',
  '#9CA6FD',
}
