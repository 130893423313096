import React, { useState } from 'react';
import { ListTooltip } from '../../../Util/Tooltip/ListTooltip/ListTooltip';
import { AlertsGcm } from '../AlertsGcm/AlertsGcm';
import styles from './SimpleValue.module.scss';

type Props = {
  firstLineTitle: string;
  secondLineTitle?: string;
  value: string;
  maxWidth: number;
  alerts?: any;
  hide?: boolean;
};

export const SimpleValue = ({ firstLineTitle, secondLineTitle, value, maxWidth, alerts, hide }: Props) => {
  const { container, title, value: valueStyle, alertsStyle, alertValue, hideStyle } = styles;
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <div className={hide ? hideStyle : container} style={{ maxWidth }}>
      <div className={title}>
        <span>{firstLineTitle}</span>
        <span>{secondLineTitle}</span>
      </div>

      {alerts && (
        <div
          className={alertsStyle}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          <AlertsGcm />
        </div>
      )}
      <div
        className={alerts ? alertValue : valueStyle}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {value}
      </div>
      {alerts && <ListTooltip showTootltip={showTooltip} />}
    </div>
  );
};
