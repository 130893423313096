import React from 'react';
import styles from './InfoTooltipBlueOnModal.module.scss';

type Props = {
  message: string;
};

export const InfoTooltipBlueOnModal = ({ message }: Props) => {
  return (
    <div className={styles.helpTooltipContainer}>
      <div className={styles.tooltip}>
        <span className={styles.supervisionPlanInfoTooltip} />
        <div className={styles.tooltiptext}>
          <p>{message}</p>
        </div>
      </div>
    </div>
  );
};
