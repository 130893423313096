import React from 'react';
import './KeyProductAccordeon.scss';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Acquisitions from '../Acquisitions/Acquisitions';
import { KeyProduct } from '../../../../../models/Planning';
import KeyProductItem from './KeyProductItem';

type Props = {
  keyProducts: KeyProduct[];
};

const KeyProductAccordeon = ({ keyProducts }: Props) => {
  return (
    <div className="key-product-accordeon-container">
      {keyProducts.map((item, index) => {
        return (
          <ExpansionPanel key={item.id} className="panel-root">
            <ExpansionPanelSummary
              className="summary-root"
              aria-controls={`panel${index + 1}a-content`}
              id={`panel${index + 1}a-header`}
            >
              <KeyProductItem keyProduct={item} />
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className="panel-details">
              <Acquisitions keyProduct={item} />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      })}
    </div>
  );
};

export default KeyProductAccordeon;
