import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { EfasConcepts } from '../../../models/EfasConcepts';
import { QCalificationInitials } from '../../../models/EsgQCalification';
import { IndicatorKeyEnum } from '../../../models/IndicatorKeyEnum';
import Project from '../../../models/Project';
import { setIndicatorDetailFilterChart } from '../../../redux/actions/indicatorDetailFilters';
import {
  getProjectByESGCategory,
  getProjectsByEfasOpinion,
  getProjectsByPmr,
  getProjectsByCTPerfomanceRating,
} from '../../../redux/actions/indicatorProjects';
import { getDynamicTableFormat } from '../../../services/util';
import { translateMultipleText } from '../../../services/stringUtil';
import { EfasPortfolio } from '../EfasPortfolio/EfasPortfolio';
import { EsgCategoryPortfolio } from '../EsgCategoryPortfolio/EsgCategoryPortfolio';
import { PmrPortfolio } from '../PmrPortfolio/PmrPortfolio';
import { mapEfaConceptClassification, mapClassification } from '../PortfolioDetailsUtil';
import { PortfolioProjectsTable } from '../PortfolioProjectsTable/PortfolioProjectsTable';
import { PortfolioSimpleIndicatorTable } from '../PortfolioSimpleIndicatorTable/PortfolioSimpleIndicatorTable';
import { ProjectCounter } from '../ProjectCounter/ProjectCounter';
import './WorkWithDoughnutChart.scss';
import { sortTablesByType } from '../../../services/sortsUtil';

type Props = {
  currentIndicator: any;
  conceptFilter: any;
  fiscalYearFilters?: any;
};
export const WorkWithDoughnutChart = ({
  currentIndicator: { id: indicatorId, indicatorKey },
  conceptFilter,
  fiscalYearFilters,
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const indicatorProjects = useSelector<any, Project[]>(state => state.indicatorProjects);
  const filterIndicators = useSelector<any, any>(state => state.indicatorDetailFilter);
  const [tableTitle, setTableTitle] = useState('');

  useEffect(() => {
    if (conceptFilter.label !== '' && filterIndicators.indicatorCharts.firstTime) {
      getFilteredProjects(dispatch, { indicatorId, indicatorKey, conceptFilter, fiscalYearFilters });
      setTableTitle(conceptFilter.label);
      dispatchIndicatorFilterChart(dispatch, filterIndicators, conceptFilter, indicatorKey);
    } else {
      const conceptFilter = filterIndicators.indicatorCharts.subTitle;
      getFilteredProjects(dispatch, { indicatorId, indicatorKey, conceptFilter, fiscalYearFilters });
      setTableTitle(conceptFilter.label);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conceptFilter, fiscalYearFilters, filterIndicators]);

  return (
    <div className="work-with-doughnut-chart-container">
      <div className="title">{renderTitle(indicatorKey, t)}</div>
      <div>{renderSelectedTitle(indicatorKey, tableTitle)}</div>
      <div className="table-container">
        {renderTableByIndicatorKey(indicatorKey, indicatorProjects, conceptFilter.label)}
        <ProjectCounter arrayToCount={indicatorProjects} countProjects={true} indicatorKey={indicatorKey} />
      </div>
    </div>
  );
};

export const dispatchIndicatorFilterChart = (d, filterIndicators, conceptFilter, indicatorKey) => {
  d(
    setIndicatorDetailFilterChart({
      ...filterIndicators.indicatorCharts,
      chartTitle: indicatorKey,
      subTitle: conceptFilter,
      firstTime: false,
    }),
  );
};

export const getFilteredProjects = (d, indicatorInfo) => {
  let returnedDispatch;
  const lastYear = moment().year() - 1;
  if (
    indicatorInfo.indicatorKey === IndicatorKeyEnum.PMR_STATE_DISTRIBUTION ||
    indicatorInfo.indicatorKey === IndicatorKeyEnum.TCP_PERFORMANCE
  ) {
    const classificationValue = mapClassification(indicatorInfo.conceptFilter.label);

    returnedDispatch =
      indicatorInfo.indicatorKey === IndicatorKeyEnum.PMR_STATE_DISTRIBUTION
        ? d(getProjectsByPmr(classificationValue, indicatorInfo.conceptFilter.totalProjects, indicatorInfo.indicatorId))
        : d(
            getProjectsByCTPerfomanceRating(
              classificationValue,
              indicatorInfo.conceptFilter.totalProjects,
              indicatorInfo.indicatorId,
            ),
          );
  } else if (indicatorInfo.indicatorKey === IndicatorKeyEnum.DISTRIBUTION_ACCORDING_TO_LATEST_EFAS) {
    returnedDispatch = d(
      getProjectsByEfasOpinion(
        mapEfaConceptClassification(EfasConcepts[indicatorInfo.conceptFilter.label]),
        indicatorInfo.conceptFilter.totalProjects,
        indicatorInfo.indicatorId,
        indicatorInfo.fiscalYearFilters && indicatorInfo.fiscalYearFilters.fiscalYear
          ? indicatorInfo.fiscalYearFilters.fiscalYear
          : lastYear,
      ),
    );
  } else if (
    indicatorInfo.indicatorKey === IndicatorKeyEnum.ESG_DISTRIBUTION_ACCORDING_TO_ENVIRONMENTAL_AND_SOCIAL_CATEGORY
  ) {
    const esgClassification =
      indicatorInfo.conceptFilter.label === 'not_assigned'
        ? QCalificationInitials.Not_Assigned
        : QCalificationInitials[indicatorInfo.conceptFilter.label];

    if (esgClassification) {
      returnedDispatch = d(
        getProjectByESGCategory(
          esgClassification,
          indicatorInfo.conceptFilter.totalProjects,
          indicatorInfo.indicatorId,
        ),
      );
    }
  }
  return returnedDispatch;
};

const renderTitle = (indicatorKey, t) => {
  switch (indicatorKey) {
    case IndicatorKeyEnum.DISTRIBUTION_ACCORDING_TO_LATEST_EFAS:
      return translateMultipleText('efa_opinion efas', t);
    case IndicatorKeyEnum.PMR_STATE_DISTRIBUTION:
      return t('pmr_initials');
    case IndicatorKeyEnum.ESG_DISTRIBUTION_ACCORDING_TO_ENVIRONMENTAL_AND_SOCIAL_CATEGORY:
      return t('category');

    default:
      return '';
  }
};

const renderSelectedTitle = (indicatorKey, label) => {
  switch (indicatorKey) {
    case IndicatorKeyEnum.DISTRIBUTION_ACCORDING_TO_LATEST_EFAS:
      return <EfasPortfolio efasConcept={EfasConcepts[label]} />;
    case IndicatorKeyEnum.PMR_STATE_DISTRIBUTION:
    case IndicatorKeyEnum.TCP_PERFORMANCE:
      return <PmrPortfolio pmrClassification={mapClassification(label)} indicatorKey={indicatorKey} />;
    case IndicatorKeyEnum.ESG_DISTRIBUTION_ACCORDING_TO_ENVIRONMENTAL_AND_SOCIAL_CATEGORY:
      return <EsgCategoryPortfolio esgCategoryConcept={label} />;

    default:
      return '';
  }
};

const renderTableByIndicatorKey = (indicatorKey, indicatorProjects, label) => {
  if (
    indicatorKey === IndicatorKeyEnum.DISTRIBUTION_ACCORDING_TO_LATEST_EFAS ||
    indicatorKey === IndicatorKeyEnum.ESG_DISTRIBUTION_ACCORDING_TO_ENVIRONMENTAL_AND_SOCIAL_CATEGORY
  ) {
    return (
      <PortfolioSimpleIndicatorTable
        projects={
          indicatorKey === IndicatorKeyEnum.DISTRIBUTION_ACCORDING_TO_LATEST_EFAS
            ? sortTablesByType(indicatorProjects, true, 'code', 'string')
            : indicatorProjects
        }
        tableInfo={getDynamicTableFormat(indicatorKey)}
        indicatorKey={indicatorKey}
        label={label}
      />
    );
  } else if (indicatorKey === IndicatorKeyEnum.TCP_PERFORMANCE) {
    return <PortfolioProjectsTable haveTcdi={true} projects={indicatorProjects} indicatorKey={indicatorKey} />;
  } else {
    return <PortfolioProjectsTable havePmr={true} projects={indicatorProjects} indicatorKey={indicatorKey} />;
  }
};
