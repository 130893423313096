export enum tabIndexes {
  portfolio,
  projects,
  earlyWarnings,
  reports,
  reviews,
  settings,
  userSettings,
  contentManager,
}

export enum contentManagerTabIndexes {
  contents,
  translations,
  functionDelegations,
  add,
  analytics,
  backToCartera,
}

export enum contentsTabIndexes {
  helpCenter,
  messages,
  maintenance,
  notifications,
  recommended
}
