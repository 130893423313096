import { SetAllIndicatorActionTypes, SET_ALL_INDICATORS } from '../actions/types';

const initialAll: any[] = [];

const initialState = initialAll;
const allIndicatorsReducer = (state = initialState, action: SetAllIndicatorActionTypes): any[] => {
  switch (action.type) {
    case SET_ALL_INDICATORS:
      return action.payload;

    default:
      return state;
  }
};

export default allIndicatorsReducer;
