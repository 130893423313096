import React from 'react';
import './CustomEvent.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import EventDetail from '../../../../Util/Modals/EventDetail/EventDetail';
import { getEventDetail } from '../../../../../api/calendarApi';
import LinkEventOutlook from '../../../../Util/Modals/LinkProjectOutlook/LinkEventOutlook';
import { isMobile } from '../../../../../services/booleanUtil';
import EventBubbles from './EventBubbles/EventBubbles';
import EventsList from './EventsList/EventsList';
import Indicator from '../../../../../models/Indicator';
import { setCurrentIndicator } from '../../../../../redux/actions/currentIndicator';
import { trackActionClicked } from '../../../../../api/trackActionClickedApi';
import { TrackedActions } from '../../../../../models/TrackedActionsEnum';
import { resetIndicatorProjects } from '../../../../../redux/actions/indicatorProjects';

export const isMultipleAndHasValues = multiple => multiple && multiple.length > 1;
export const isDesktopAndHasTitle = title => !isMobile() && title;
export const isSingleEvent = item => !item.multiple || item.multiple.length === 0;
const fallbackProjectId = item => (item.projectId ? item.projectId : undefined);
const eventWithMultiple = event => event && event.multiple;

const CustomEvent = props => {
  const { event } = props;

  const location = useLocation();
  const isCalendarIndicator = location.pathname === '/portfoliodetail';
  const [eventDetail, setEventDetail] = React.useState<any>(null);
  const [currentEvent, setCurrentEvent] = React.useState<any>({ event: null, showModal: false });
  const [showCreateReminder, setShowCreateReminder] = React.useState<any>(false);
  const [showEventsList, setShowEventsList] = React.useState<any>(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const indicators = useSelector<any, Indicator[]>(state => state.indicators);

  const getEventData = async item => {
    if (item.id) {
      const data = await getEventDetail(
        parseInt(item.id),
        parseInt(item.eventType),
        fallbackProjectId(item),
        isCalendarIndicator,
      );
      setEventDetail(data);
    } else {
      setEventDetail({
        project: { code: item.projectCode, name: '' },
        typeDescription: item.title,
        startDate: item.date,
      });
    }
  };

  const handleEventClick = (item, e) => {
    setCurrentEvent({ event: { ...item }, showModal: true });
    getEventData(item);
    e.stopPropagation();
  };

  const handleEventListClick = (item, e) => {
    setShowEventsList(item);
  };
  const closeModal = (showReminder = false) => {
    setCurrentEvent({ ...currentEvent, showModal: false });
    setEventDetail(null);
    if (showReminder) {
      setShowCreateReminder(true);
    }
  };

  const redirect = (indicatorKey: string) => {
    const indicator = indicators.find(i => i.indicatorKey === indicatorKey);

    if (indicator) {
      trackActionClicked(TrackedActions.indicatorDetailsClicked, `[${indicator.id}]`);
      setShowEventsList(null);
      dispatch(resetIndicatorProjects());
      setTimeout(() => {
        dispatch(setCurrentIndicator(indicator));
        if (!isCalendarIndicator) {
          history.push(`/portfoliodetail`);
        }
      }, 500);
    }
  };

  React.useEffect(() => {
    setCurrentEvent({ event: { ...event }, showModal: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return eventWithMultiple(event) ? (
    <>
      <div className="calendar-custom-event-container">
        <EventBubbles
          events={event.multiple}
          handleClickSingle={handleEventClick}
          handleClickMultiple={handleEventListClick}
        />
      </div>
      <EventDetail
        isOpen={currentEvent.showModal}
        closeModal={closeModal}
        event={currentEvent.event}
        eventDetail={eventDetail}
      />
      <LinkEventOutlook
        isOpen={showCreateReminder}
        closeModal={() => setShowCreateReminder(false)}
        event={currentEvent.event}
        currentProjectId={currentEvent.projectId}
      />
      {showEventsList !== null && (
        <EventsList
          isOpen={showEventsList !== null}
          closeModal={() => setShowEventsList(null)}
          events={showEventsList}
          redirectToIndicator={redirect}
        />
      )}
    </>
  ) : null;
};

export default CustomEvent;
