import React from 'react';
import SelectOption from '../../../models/SelectOption';
import { useTranslation } from 'react-i18next';
import { CustomCheckbox } from '../../Util/CustomCheckbox/CustomCheckbox';
import { useDispatch, useSelector } from 'react-redux';
import { dispatchCTFiltersState } from '../../../redux/actions/filter';
import { CTFiltersEnum } from '../../../models/CTFilters';

type Props = {
  indicatorCall?: boolean;
};

const ExcludeRegionalsFilter = (props: Props) => {
  const { indicatorCall } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filter = useSelector<any, any>(state => (indicatorCall ? state.filterIndicators : state.filter));

  const handleSelectChange = (value: SelectOption, filterType: number) => {
    dispatch(dispatchCTFiltersState(filter, value, filterType, indicatorCall));
  };

  return (
    <CustomCheckbox
      labelText={t('exclude_regional')}
      isChecked={filter.common.excludeRegionals}
      handleOnClick={value => handleSelectChange(value, CTFiltersEnum.EXCLUDE_REGIONAL)}
      customStyle={{ marginBottom: 20 }}
    />
  );
};

export default ExcludeRegionalsFilter;
