import React from 'react';
import { PortfolioProjectsChartsDetailContent } from '../PortfolioProjectsChartsDetailContent/PortfolioProjectsChartsDetailContent';
import Indicator from '../../../models/Indicator';
import { PortfolioAccordanceDetailContent } from '../PortfolioAccordanceDetailContent/PortfolioAccordanceDetailContent';
import { PortfolioProjectsDetailContent } from '../PortfolioProjectsDetailContent/PortfolioProjectsDetailContent';
import { useHistory } from 'react-router-dom';
import { IndicatorKeyEnum as key } from '../../../models/IndicatorKeyEnum';
import { PortfolioProjectsDoughnutDetailContent } from '../PortfolioProjectsDoughnutDetailContent/PortfolioProjectsDoughnutDetailContent';
import { PendingTasksDetail } from '../PendingTasksDetail/PendingTasksDetail';
import { PortfolioEsgBarchartDetailContent } from '../PortfolioEsgBarchartDetailContent/PortfolioEsgBarchartDetailContent';
import { CalendarDetail } from '../CalendarDetail/CalendarDetail';
import KnowledgeProductDetail from '../KnowledgeProductsDetail/KnowledgeProductDetail';
import { ActualProjectedDifferenceDetail } from '../ActualProjectedDifferenceDetail/ActualProjectedDifferenceDetail';
import GcmAlert from '../GcmAlert/GcmAlert';
import { DisbursementTime } from '../DisbursementTime/DisbursementTime';
import { PortfolioSummaryIndicator } from '../PortfolioSummaryIndicator/PortfolioSummaryIndicator';
import ProjectsInPreparationContent from '../ProjectsInPreparationContent/ProjectsInPreparationContent';
import PortfolioConsFinaAndExecutedBank from '../PortfolioConsFinaAndExecutedBank/PortfolioConsFinaAndExecutedBank';
import { PmrValidationState } from '../PmrValidationState/PmrValidationState';
import LastFiveYearsDisbProjApp from '../LastFiveYearsDisbProjApp/LastFiveYearsDisbProjApp';
import LastFiveYearsPortfolioOverview from '../LastFiveYearsPortfolioOverview/LastFiveYearsPortfolioOverview';
import { ProjectsByCountry } from '../ProjectsByCountry/ProjectsByCountry';
import { PmrEvolutionLast5Years } from '../PmrEvolutionLast5Years/PmrEvolutionLast5Years';
import { PcrNextPresentation } from '../PcrNextPresentation/PcrNextPresentation';
import { LastDisbursementDateCloseOrExpired } from '../LastDisbursementDateCloseOrExpired/LastDisbursementDateCloseOrExpired';
import { PendigSignatureOrLegisApproval } from '../PendigSignatureOrLegisApproval/PendigSignatureOrLegisApproval';
import { ProjectExtendedNotFullyDisbursed } from '../ProjectExtendedNotFullyDisbursed/ProjectExtendedNotFullyDisbursed';
import { PmrAlertProblemLast3Years } from '../PmrAlertProblemLast3Years/PmrAlertProblemLast3Years';
import { ProjectsWithPartialCancellations } from '../ProjectsWithPartialCancellations/ProjectsWithPartialCancellations';
import { ProjectsEligibleFirstUndisbursement } from '../ProjectsEligibleFirstUndisbursement/ProjectsEligibleFirstUndisbursement';
import { CurrentProjectionOfDisbursements } from '../CurrentProjectionOfDisbursements/CurrentProjectionOfDisbursements';
import { AcquisitionsMilestonesPending } from '../AcquisitionsMilestonesPending/AcquisitionsMilestonesPending';
import { ClassificationOfAcquisitionPlans } from '../ClassificationOfAcquisitionPlans/ClassificationOfAcquisitionPlans';

interface ReceivedProps {
  currentIndicator: Indicator;
  headerTitle: any;
  openModal?: boolean;
  closeModal?: Function;
}

type Props = ReceivedProps;
export const PortfolioDetailContent = (props: Props) => {
  const { currentIndicator, headerTitle, openModal, closeModal } = props;

  const history = useHistory();

  if (currentIndicator.title === 'initialState') {
    history.push('/home');
  }

  return (
    <div className="portfolio-detail-content-container">
      {openComponent(currentIndicator, headerTitle, openModal, closeModal)}
    </div>
  );
};

export const openComponent = (currentInd, headerTitle, openModal, closeModal) => {
  switch (currentInd.indicatorKey) {
    case key.PMR_PERFORMANCE_LAST_CLOSED_YEAR:
    case key.PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS_BY_DIVISION:
    case key.PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS:
    case key.AVERAGE_LAG:
    case key.SUPERVISION_PLAN_BUDGET_STATUS:
      return <PortfolioProjectsChartsDetailContent currentIndicator={currentInd} headerTitle={headerTitle} />;
    case key.ACCORDANCES_WAITING_APPROVAL:
      return <PortfolioAccordanceDetailContent currentIndicator={currentInd} waitingApproval={true} />;
    case key.ACCORDANCES_EXPIRING:
      return <PortfolioAccordanceDetailContent currentIndicator={currentInd} />;
    case key.PROJECTS_WITH_TRANSACTIONAL_BUDGET_DEFICIT:
    case key.PROJECTS_IN_EXECUTION_WITH_MICI_OPEN_CASES:
    case key.PROJECTS_PENDING_DISBURSEMENT_ELIGIBILITY:
    case key.PROJECTS_OVERALL_STAGE_CODE_DISBURSING_NO_DISBURSEMENTS_OVER_180_DAYS:
    case key.DISBURSEMENT_PROJECTION_CURRENT_YEAR_INCLUDING_REGIONALS:
      return <PortfolioProjectsDetailContent currentIndicator={currentInd} indicatorKey={currentInd.indicatorKey} />;
    case key.PROJECTS_SUMMARY_PORTFOLIO:
      return (
        <PortfolioSummaryIndicator
          currentIndicator={currentInd}
          headerTitle={headerTitle}
          openModal={openModal}
          closeModal={closeModal}
        />
      );
    case key.PMR_STATE_DISTRIBUTION:
    case key.DISTRIBUTION_ACCORDING_TO_LATEST_EFAS:
    case key.ESG_DISTRIBUTION_ACCORDING_TO_ENVIRONMENTAL_AND_SOCIAL_CATEGORY:
    case key.TCP_PERFORMANCE:
      return <PortfolioProjectsDoughnutDetailContent currentIndicator={currentInd} headerTitle={headerTitle} />;
    case key.USER_PENDING_TASKS:
      return <PendingTasksDetail currentIndicator={currentInd} />;
    case key.ESG_SAFEGUARD_PERFORMANCE_RATING:
    case key.ESG_ENVIRONMENTAL_AND_SOCIAL_RISK_RATING:
      return <PortfolioEsgBarchartDetailContent currentIndicator={currentInd} headerTitle={headerTitle} />;
    case key.CALENDAR_EVENTS:
      return <CalendarDetail currentIndicator={currentInd} indicatorKey={currentInd.indicatorKey} />;
    case key.PROJECTS_KNOWLEDGE_PRODUCTS:
      return <KnowledgeProductDetail headerTitle={headerTitle} />;
    case key.SUPERVISION_PLAN_PLANNED_VERSUS_EXECUTED:
      return <ActualProjectedDifferenceDetail />;
    case key.GCM_ALERTS:
      return <GcmAlert currentIndicator={currentInd} />;
    case key.DISBURSEMENT_OVER_TIME:
      return <DisbursementTime />;
    case key.BID_CONSULTANCY:
      return <PortfolioConsFinaAndExecutedBank indicatorKey={currentInd.indicatorKey} />;
    case key.PROJECTS_IN_PREPARATION:
      return <ProjectsInPreparationContent indicatorKey={currentInd.indicatorKey} currentIndicator={currentInd} />;
    case key.PMR_VALIDATION_STATE:
      return <PmrValidationState headerTitle={headerTitle} />;
    case key.DISBURSEMENT_PROJECTION_LAST_5_YEARS:
      return <LastFiveYearsDisbProjApp />;
    case key.PORTFOLIO_OVERVIEW_LAST_5_YEARS:
      return <LastFiveYearsPortfolioOverview />;
    case key.PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS_BY_COUNTRY:
      return <ProjectsByCountry />;
    case key.PMR_EVOLUTION_LAST_5_YEARS:
      return <PmrEvolutionLast5Years />;
    case key.PCR_NEXT_PRESENTATION:
      return <PcrNextPresentation headerTitle={headerTitle} />;
    case key.PROJECTS_LAST_DISBURSEMENT_DATE_CLOSE_12MONTHS_OR_EXPIRED:
      return <LastDisbursementDateCloseOrExpired />;
    case key.PROJECTS_PENDING_SIGNATURE_OR_LEGISLATIVE_APPROVAL:
      return <PendigSignatureOrLegisApproval />;
    case key.PROJECTS_EXTENDED_NOT_FULLY_DISBURSED:
      return <ProjectExtendedNotFullyDisbursed />;
    case key.PMR_ALERT_PROBLEM_LAST_3_YEARS:
      return <PmrAlertProblemLast3Years />;
    case key.PROJECTS_ELIGIBLE_FIRST_UNDISBURSEMENT:
      return <ProjectsEligibleFirstUndisbursement />;
    case key.PROJECTS_PARTIAL_CANCELLATIONS:
      return <ProjectsWithPartialCancellations />;
    case key.MONTHLY_DISBURSEMENT_PROJECTION_CURRENT_YEAR_INCLUDING_REGIONALS:
      return <CurrentProjectionOfDisbursements />;
    case key.ACQUISITION_WITH_MILESTONES_PENDING_TO_DATE:
      return <AcquisitionsMilestonesPending />;
    case key.CLASSIFICATION_OF_ACQUISITION_PLANS:
      return <ClassificationOfAcquisitionPlans />;

    default:
      return null;
  }
};
