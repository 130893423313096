import React, { useEffect, useState } from 'react';
import {
  StyledTableHeader,
  StyledTableRowHeader,
  StyledTableCellHeader,
  StyledTableCell,
} from '../PorfolioTableShared.Styles';
import { Table, TableBody, TableContainer, TableRow, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../../../services/booleanUtil';
import { Detail } from './Detail';
import { getAverageLagDetailInfo } from '../../../api/indicatorApi';
import { sortTablesByType } from '../../../services/sortsUtil';
//Added
import { tableInfo } from './Util';
import styles from './AverageLagTable.module.scss';
import './AverageLagTable.scss';

import { translateMultipleText } from '../../../services/stringUtil';
import { ProjectsListSkeleton } from '../../LoadersSkeletons/ProjectsListSkeleton/ProjectsListSkeleton';
import { ExcelDownloadButton } from '../../Util/ExcelDownloadButton/ExcelDownloadButton';
import DataTable from '../../DetailDataTable/DataTable';
import useDataNormalize from '../../../hooks/useDataNormalize';
import PaginationTable from '../PaginationTable/PaginationTable';
import { Pagination } from '@material-ui/lab';
import usePaginationSort from '../../../hooks/usePaginationSort';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { FilterTables } from '../../Filter/FilterTables/FilterTables';

const BlackTooltip = withStyles({
  tooltip: {
    //   color: '$ci-color-greyscale-90',
    backgroundColor: '#101319',
    fontSize: '16px',
    fontFamily: 'Rubik',
    fontWeight: '400',
    lineHeight: '20px',
    padding: '8px 12px',
    borderRadius: '8px',
  },
  arrow: {
    fontSize: 20,
    color: '#4A4A4A',
    '&::before': {
      backgroundColor: '#101319',
    },
  },
})(Tooltip);

const headers = [
  'id',
  'last_project',
  'last_name_project',
  'last_Completed Activities',
  'last_pending_activities',
  'last_completed_critical',
  'last_pending_critical_products',
];

const selectedKeys = ['id', 'code', 'title', 'field1', 'field2', 'field3', 'field4'];

const hStyle = {
  color: '#416DBC',
  background: '#fff',
  fontFamily: 'Source Sans Pro',
  fontSize: isMobile() ? '9px' : '12px',
  fontWeight: 600,
  cursor: 'default',
  lineHeight: isMobile() ? '0.7rem' : '1.2rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '6px 20px 6px 0px',
  margin: 0,
};

type Props = {
  projects: any[];
  loading?: boolean;
};
const AverageLagTable = ({ projects, loading }: Props) => {
  const { t } = useTranslation();
  const [activeItem, setActiveItem] = useState<any>(null);
  const [type, setType] = useState<any>(null);
  const [status, setStatus] = useState<any>(null);
  const [sortAsc, setSortAsc] = React.useState(true);
  const [isLoading, setisLoading] = React.useState(true);

  const { container, sortIcon } = styles;

  const handleOnClick = async (project: any, type: number, status: number) => {
    setType(type);
    setStatus(status);
    const response = await getAverageLagDetailInfo(project.id, type, status);
    setActiveItem({ ...project, itemsList: response });
  };
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [dataFilter, setDataFilter] = useState<any>(projects);

  useEffect(() => {
    setDataFilter(projects);
  }, [projects]);

  const { handlePaginationChange, handleSort, currentList, pagesCount, currentPage } = usePaginationSort({
    projects: dataFilter,
    defaultSortCriteria: 'title',
    pageCount: rowsPerPage,
  });

  const sortTable = (keyToSort, fieldType) => {
    sortTablesByType(currentList, sortAsc, keyToSort, fieldType);
    setSortAsc(!sortAsc);
  };

  let data = useDataNormalize(headers, selectedKeys, projects);

  let dataExcel = useDataNormalize(headers, selectedKeys, dataFilter);

  useEffect(() => {
    if (data.length > 0) return setisLoading(false);
  }, [data]);

  return isLoading ? (
    <div className="portfolio-detail-content__loading__container">
      <ProjectsListSkeleton />
    </div>
  ) : (
    <>
      <div className={container}>
        {
          <div
            className="title-head"
            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', height: '40px' }}
          >
            <h5 style={{ paddingLeft: '48%' }}>{t('activities').toUpperCase()}</h5>
            <h5 style={{ paddingLeft: '10%' }}>{t('critical_products').toUpperCase()}</h5>
            <ExcelDownloadButton headers={headers} selectedKeys={selectedKeys} dataExcel={dataExcel} />
            <FilterTables
              projects={projects}
              keysFilter={selectedKeys}
              fnDataFilter={setDataFilter}
              disabledNumber={currentPage}
              indicatorKey={'AVERAGE_LAG'}
            />
          </div>
        }

        <TableContainer>
          {/* <DataTable headers={headers} selectedKeys={selectedKeys} data={data} /> */}

          <Table stickyHeader className="custom-table" style={{ tableLayout: 'auto' }} size="small">
            <StyledTableHeader>
              <StyledTableRowHeader>
                {tableInfo.map(th => (
                  <StyledTableCellHeader
                    key={th.tableHeader}
                    align={th.headerAlign}
                    onClick={() => sortTable(th.rootProperty, th.fieldType)}
                    style={th.headerCellStyle ? th.headerCellStyle : {}}
                  >
                    <div className="text-head">
                      <div>{translateMultipleText(th.tableHeader, t)}</div>
                      <div className="icon">
                        <i className={sortIcon}></i>
                      </div>
                    </div>
                  </StyledTableCellHeader>
                ))}

                <StyledTableCellHeader align="right" style={{ border: 'none' }} />
              </StyledTableRowHeader>
            </StyledTableHeader>
            <TableBody>
              {dataFilter?.length === 0
                ? dataFilter?.map((project, index) => (
                    <TableRow
                      key={project.frontendId}
                      style={index % 2 ? { background: '#fafafa' } : { background: 'white' }}
                    >
                      <StyledTableCell style={{ width: '14%', minWidth: 'unset' }} align="left">
                        <BlackTooltip
                          title={t('go_to_project_info')}
                          placement="bottom"
                          enterDelay={500}
                          leaveDelay={100}
                          arrow
                        >
                          {/* <p>{project.code}</p> */}
                          <Link style={{ color: '#3D3E40' }} to={`/projectdetail?id=${project.id}`}>
                            {project.code}
                          </Link>
                        </BlackTooltip>
                        {/* <p className="decoration-text">{project.code}</p> */}
                      </StyledTableCell>

                      <StyledTableCell style={{ width: '26%' }} align="left">
                        <BlackTooltip title={project.title} placement="bottom" enterDelay={500} leaveDelay={100} arrow>
                          <p className=" name-project-nowrap"> {project.title}</p>
                        </BlackTooltip>
                      </StyledTableCell>
                      <StyledTableCell style={{ width: '15%' }} align="center">
                        {displayValue(project, project.field1, handleOnClick, 0, 0)}
                      </StyledTableCell>
                      <StyledTableCell style={{ width: '15%' }} align="center">
                        {displayValue(project, project.field2, handleOnClick, 0, 1)}
                      </StyledTableCell>
                      <StyledTableCell style={{ width: '15%' }} align="center">
                        {displayValue(project, project.field3, handleOnClick, 1, 0)}
                      </StyledTableCell>
                      <StyledTableCell style={{ width: '15%' }} align="center">
                        {displayValue(project, project.field4, handleOnClick, 1, 1)}
                      </StyledTableCell>
                    </TableRow>
                  ))
                : currentList?.map((project, index) => (
                    <TableRow
                      key={project.frontendId}
                      style={index % 2 ? { background: '#fafafa' } : { background: 'white' }}
                    >
                      <StyledTableCell style={{ width: '14%', minWidth: 'unset' }} align="left">
                        <BlackTooltip
                          title={t('go_to_project_info')}
                          placement="bottom"
                          enterDelay={500}
                          leaveDelay={100}
                          arrow
                        >
                          {/* <p>{project.code}</p> */}
                          <Link style={{ color: '#3D3E40' }} to={`/projectdetail?id=${project.id}`}>
                            {project.code}
                          </Link>
                        </BlackTooltip>
                        {/* <p className="decoration-text">{project.code}</p> */}
                      </StyledTableCell>

                      <StyledTableCell style={{ width: '26%' }} align="left">
                        <BlackTooltip title={project.title} placement="bottom" enterDelay={500} leaveDelay={100} arrow>
                          <p className=" name-project-nowrap"> {project.title}</p>
                        </BlackTooltip>
                      </StyledTableCell>
                      <StyledTableCell style={{ width: '15%' }} align="center">
                        {displayValue(project, project.field1, handleOnClick, 0, 0)}
                      </StyledTableCell>
                      <StyledTableCell style={{ width: '15%' }} align="center">
                        {displayValue(project, project.field2, handleOnClick, 0, 1)}
                      </StyledTableCell>
                      <StyledTableCell style={{ width: '15%' }} align="center">
                        {displayValue(project, project.field3, handleOnClick, 1, 0)}
                      </StyledTableCell>
                      <StyledTableCell style={{ width: '15%' }} align="center">
                        {displayValue(project, project.field4, handleOnClick, 1, 1)}
                      </StyledTableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="pagination-style">
          <PaginationTable fnSetRows={setRowsPerPage} rows={rowsPerPage} numProyects={dataFilter?.length} />
          {dataFilter?.length !== 0 ? (
            <Pagination count={pagesCount} page={currentPage} onChange={handlePaginationChange} />
          ) : null}
        </div>
      </div>
      {activeItem !== null ? (
        <Detail
          isOpen={activeItem !== null}
          project={activeItem}
          closeModal={() => setActiveItem(null)}
          type={type}
          status={status}
        />
      ) : null}
    </>
  );
};

const displayValue = (project: any, value: string, handleOnClick: Function, type: number, status: number) => {
  return parseInt(value) > 0 ? (
    <span
      style={{
        display: 'inline-block',
        width: 20,
        cursor: 'pointer',
        color: '#417FFE',
        textDecoration: 'underline',
        fontWeight: 400,
      }}
      onClick={() => handleOnClick(project, type, status)}
    >
      {value}
    </span>
  ) : (
    '-'
  );
};

export default AverageLagTable;
