import React, { Fragment } from 'react';
import ProjectsHeader from './ProjectsHeader/ProjectsHeader';
import ProjectsFollowing from './ProjectsFollowing/ProjectsFollowing';
import ProjectsAll from './ProjectsAll/ProjectsAll';

const Projects = () => {
  return (
    <Fragment>
      <ProjectsHeader />
      <ProjectsFollowing />
      <ProjectsAll />
    </Fragment>
  );
};

export default Projects;