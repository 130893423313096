/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import './PortfolioProductTable.scss';
import HeaderTable from './HeaderTable';
import Row from './Row';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectsListSkeleton } from '../../LoadersSkeletons/ProjectsListSkeleton/ProjectsListSkeleton';
import { sortByLabel, transformData } from '../../Util/MappingData/DataHomeTableMap';
import { setHomeSummaryData } from '../../../redux/actions/dataTable';
import FilterIndicators from '../../../models/FilterIndicators';

const infoHeader = {
  products: 'products',
  projects: 'menu_projects',
  operations: 'operations',
  approvedAmount: 'approved_amount',
  disburments: 'disbursements',
  ageAverage: 'average_age',
  actions: 'actions',
};

export default function PortfolioProductTable() {
  const dispatch = useDispatch();

  const [openRow, setOpenRow] = useState(null);
  const [summaryData, setSummaryData] = useState<any>();

  const isLoading = useSelector((state: any) => state.dataTableInfo.loading);
  const summaryRedux = useSelector((state: any) => state.dataTableInfo.indicatorResponse);

  // Check if there are country filters selected through the selects
  const hasCountryFilterFromSelectsNotEmpty = useSelector<any, boolean>(
    state =>
      Array.isArray(state.filterIndicators?.common?.countryId) && state.filterIndicators?.common?.countryId.length > 0,
  );

  // Check if there is a filter applied across the tabs
  const hasCountryFilterFromTabs =
    useSelector<any, boolean>(state =>
      state.menuTabs.portfolioSelectedTab.filters?.some(filter => filter.field === 'ResponsibleCountryId'),
    ) ?? false;

  useEffect(() => {
    if (Object.keys(summaryRedux).length) {
      // Determines which data set to filter based on the applied filters
      const shouldUseTCPFilter = hasCountryFilterFromSelectsNotEmpty || hasCountryFilterFromTabs;
      const initialFilteredData = shouldUseTCPFilter
        ? transformData(summaryRedux).filter(data => data.label !== 'TCP')
        : transformData(summaryRedux).filter(data => data.label !== 'TCP_NAT');

      let orderedData = initialFilteredData.sort(sortByLabel);

      // ! Se capturan los valores
      const GUAProjectCount = orderedData.find(item => item.label === 'GUA')?.projectCount;
      const GUACurrentApprovedAmount = orderedData.find(item => item.label === 'GUA')?.currentApprovedAmount;
      const LONProjectCount = orderedData.find(item => item.label === 'LON')?.projectCount;
      const LONOperations = orderedData.find(item => item.label === 'LON')?.operations;
      const LONCurrentApprovedAmount = orderedData.find(item => item.label === 'LON')?.currentApprovedAmount;
      const LONOriginalApprovedAmount = orderedData.find(item => item.label === 'LON')?.originalApprovedAmount;

      const LONData = orderedData.find(item => item.label === 'LON');

      // ! Modificamos los valores del objeto Lon
      const mergedData = {
        ...LONData,
        projectCount: GUAProjectCount === undefined ? LONProjectCount : GUAProjectCount + LONProjectCount,
        operations: GUAProjectCount === undefined ? LONOperations : GUAProjectCount + LONOperations,
        currentApprovedAmount:
          GUACurrentApprovedAmount === undefined
            ? LONCurrentApprovedAmount
            : GUACurrentApprovedAmount + LONCurrentApprovedAmount,
        originalApprovedAmount:
          GUACurrentApprovedAmount === undefined
            ? LONOriginalApprovedAmount
            : GUACurrentApprovedAmount + LONOriginalApprovedAmount,
      };

      orderedData = orderedData.filter(item => item.label !== 'LON' && item.label !== 'GUA');
      if (mergedData.projectCount !== undefined) orderedData.push(mergedData);

      orderedData = orderedData.sort(sortByLabel);

      setSummaryData(orderedData.filter(item => item.label !== 'GUA'));
    }
  }, [summaryRedux, hasCountryFilterFromSelectsNotEmpty, hasCountryFilterFromTabs]);

  useEffect(() => {
    dispatch(setHomeSummaryData(summaryData));
  }, [summaryData]);

  const handleRowClick = rowId => {
    if (openRow === rowId) {
      setOpenRow(null);
    } else {
      setOpenRow(rowId);
    }
  };

  return (
    <TableContainer className='table-admin'>
      {isLoading ? (
        <div className="work-with-chart__loading__container">
          <ProjectsListSkeleton />
        </div>
      ) : (
        <Table aria-label="collapsible table">
          <HeaderTable {...infoHeader} />
          <TableBody>
            {summaryData?.map(item => (
              <Row key={item.label} row={item} onRowClick={handleRowClick} openRow={false} />
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
}
