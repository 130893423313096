import React, { useState, useEffect } from 'react';
import { deleteAccordance } from '../../../../api/accordanceApi';
import AccordanceDetail from '../AccordanceDetail/AccordanceDetail';
import AccordanceFulfilled from '../AccordanceFulfilled/AccordanceFulfilled';
import AccordanceReject from '../AccordanceReject/AccordanceReject';

type Props = {
  accordance: any;
  accordanceDetailSetOpen: boolean;
  accordanceDetailIsClosed: any;
  refreshData: any;
};

export const AccordanceModalsFlow = ({
  accordance,
  accordanceDetailSetOpen,
  accordanceDetailIsClosed,
  refreshData,
}: Props) => {
  const [showAccordanceDetailModal, setShowAccordanceDetailModal] = useState(accordanceDetailSetOpen);
  const [showAccordanceRejectModal, setShowAccordanceRejectModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  useEffect(() => {
    if (accordanceDetailSetOpen) {
      setShowAccordanceDetailModal(accordanceDetailSetOpen);
    }
  }, [accordanceDetailSetOpen]);

  const hideAccordanceDetailModal = () => {
    accordanceDetailIsClosed();
    setShowAccordanceDetailModal(false);
  };

  const showJustificationModal = () => {
    setShowAccordanceRejectModal(true);
  };

  const handleShowConfirmDelete = () => {
    setShowConfirmDeleteModal(true);
  };

  const handleDeleteAccordance = async (id: number) => {
    await deleteAccordance(id);
    refreshData();
    setShowConfirmDeleteModal(false);
  };
  return (
    <>
      <AccordanceDetail
        isOpen={showAccordanceDetailModal}
        closeModal={hideAccordanceDetailModal}
        accordance={accordance}
        showJustificationModal={showJustificationModal}
        onApproveHandler={refreshData}
        showConfirmDelete={handleShowConfirmDelete}
        changeAccordanceStatus={{}}
        fromRevision={true}
      />

      <AccordanceReject
        isOpen={showAccordanceRejectModal}
        closeModal={() => setShowAccordanceRejectModal(false)}
        accordanceId={accordance.id}
        onRejectHandler={refreshData}
      />

      <AccordanceFulfilled
        isOpen={showConfirmDeleteModal}
        closeModal={() => setShowConfirmDeleteModal(false)}
        accordanceId={accordance.id}
        customTitle={'deleted_accordance'}
        customSubmit={() => handleDeleteAccordance(accordance.id)}
      />
    </>
  );
};
