import { AppMenuTabs, Filter } from '../components/MenuTabs/userTabsInterface';
import FilterEarlyWarnings from '../models/FilterEarlyWarnings';
import SelectOption from '../models/SelectOption';
import { duplicateToUniqueKey, getMergedFilterArray } from '../services/util';
import { axiosDefaultConfig } from './axiosConfig';
import * as config from './config.json';
import { createNewUniqueValueArray, setFilterParams } from './filterPortfolioAndProjectUtil';
import { handleHaveFiltersInputsAndSelects } from './filterSharedUtil';
import { buildGet } from './genericMethods';

const handleResponse = (resolve, reject, response) => {
  if (response && response.data) {
    resolve(response.data);
  } else {
    reject('error');
  }
};

export const setValueFilter = (filter, key: string) => {
  const value: SelectOption[] = filter.common[key];
  return value !== null ? Array.from(value, x => x.id) : [];
};

const handleEarlyWarningsPayload = (filter: FilterEarlyWarnings, menuTabs: AppMenuTabs) => {
  const haveCountriesFilter = handleHaveFiltersInputsAndSelects('country', filter);
  const haveDivisionFilter = handleHaveFiltersInputsAndSelects('division', filter);
  const havePendingDisbursement = handleHaveFiltersInputsAndSelects('disbursementProjection', filter);
  const haveLastDisbursement = handleHaveFiltersInputsAndSelects('timeWithoutDisbursement', filter);
  const haveAgeOfProject = handleHaveFiltersInputsAndSelects('ageProject', filter);
  const havePmrClassification = filter.common.pmrId.filter(pmr => pmr.selected === true);

  const stateFilterValues = {
    haveCountriesFilter,
    haveDivisionFilter,
    havePendingDisbursement,
    haveLastDisbursement,
    haveAgeOfProject,
    havePmrClassification,
  };
  let returnedParams = {
    inFollow: menuTabs.earlyWarningsSelectedTab.inFollow,
    assignedToMe: menuTabs.earlyWarningsSelectedTab.assignedToMe,
    Filters: [] as any,
    coReponsibleDivisions: [] as any,
    participantDivisions: [] as any,
    interestIssues: [] as any,
    teamLeaders: [] as any,
    regions: [] as any,
  };

  if (menuTabs.earlyWarningsSelectedTab.filters) {
    const mergeFilterArray = getMergedFilterArray(returnedParams.Filters, menuTabs.earlyWarningsSelectedTab.filters);
    const returnedParamsFilterArray: Filter[] = duplicateToUniqueKey(mergeFilterArray);
    returnedParams = {
      ...returnedParams,
      Filters: returnedParamsFilterArray,
    };
  }
  returnedParams = setFilterParams(returnedParams, stateFilterValues, true, filter);
  returnedParams = handleMergeBetweenMainFilterAndMenuTabs({ returnedParams, filter, menuTabs });
  return returnedParams;
};

const getProjectsWithEarlyWarnings = (filter: FilterEarlyWarnings, menuTabs: AppMenuTabs) => {
  const payload = handleEarlyWarningsPayload(filter, menuTabs);
  return new Promise<any>(async (resolve, reject) => {
    try {
      const url = config.PROJECTS_WITH_EARLY_WARNINGS;
      const response = await axiosDefaultConfig.post(url, payload);
      handleResponse(resolve, reject, response);
    } catch (error) {
      reject(error);
    }
  });
};

const handleMergeBetweenMainFilterAndMenuTabs = ({ returnedParams, filter, menuTabs }) => {
  const regionsFromFilter = setValueFilter(filter, 'regionId');
  const coReponsibleDivisionsFromFilter = setValueFilter(filter, 'coReponsibleDivisions');
  const participantDivisionsFromFilter = setValueFilter(filter, 'participantDivisions');
  const interestIssuesFromFilter = setValueFilter(filter, 'interestIssues');
  const teamLeadersFromFilter = setValueFilter(filter, 'teamLeaders');
  const regionsFromTab = createNewUniqueValueArray(returnedParams, menuTabs.earlyWarningsSelectedTab, 'regions');
  const coReponsibleDivisionsFromTab = createNewUniqueValueArray(
    returnedParams,
    menuTabs.earlyWarningsSelectedTab,
    'coReponsibleDivisions',
  );
  const participantDivisionsFromTab = createNewUniqueValueArray(
    returnedParams,
    menuTabs.earlyWarningsSelectedTab,
    'participantDivisions',
  );
  const interestIssuesFromTab = createNewUniqueValueArray(
    returnedParams,
    menuTabs.earlyWarningsSelectedTab,
    'interestIssues',
  );
  const teamLeadersFromTab = createNewUniqueValueArray(
    returnedParams,
    menuTabs.earlyWarningsSelectedTab,
    'teamLeaders',
  );
  const regions = regionsFromFilter.length ? regionsFromFilter : regionsFromTab;
  const coReponsibleDivisions = coReponsibleDivisionsFromFilter.length
    ? coReponsibleDivisionsFromFilter
    : coReponsibleDivisionsFromTab;
  const participantDivisions = participantDivisionsFromFilter.length
    ? participantDivisionsFromFilter
    : participantDivisionsFromTab;
  const interestIssues = interestIssuesFromFilter.length ? interestIssuesFromFilter : interestIssuesFromTab;
  const teamLeaders = teamLeadersFromFilter.length ? teamLeadersFromFilter : teamLeadersFromTab;

  return {
    ...returnedParams,
    regions,
    coReponsibleDivisions,
    participantDivisions,
    interestIssues,
    teamLeaders,
  };
};

const getAccordancesFromProject = (projectNumber: string, alertNumber: number) => {
  const url = config.GET_ACCORDANCES_FROM_PROJECTS_WITH_EARLY_WARNING.replace('{projectNumber}', projectNumber).replace(
    '{alertNumber}',
    alertNumber.toString(),
  );

  return buildGet(url);
};

const getSupervisionPlansFromProject = (projectNumber: string, alertNumber: number) => {
  const url = config.GET_SUPERVISION_PLANS_FROM_PROJECTS_WITH_EARLY_WARNING.replace(
    '{projectNumber}',
    projectNumber,
  ).replace('{alertNumber}', alertNumber.toString());

  return buildGet(url);
};

const getEarlyWarningsTrends = (filter: FilterEarlyWarnings, menuTabs: AppMenuTabs) => {
  const payload = handleEarlyWarningsPayload(filter, menuTabs);
  return new Promise<any>(async (resolve, reject) => {
    try {
      const url = config.EARLY_WARNINGS_QUARTERS_TRENDS;
      const response = await axiosDefaultConfig.post(url, payload);
      handleResponse(resolve, reject, response);
    } catch (error) {
      reject(error);
    }
  });
};

export {
  getProjectsWithEarlyWarnings,
  getAccordancesFromProject,
  getSupervisionPlansFromProject,
  getEarlyWarningsTrends,
};
