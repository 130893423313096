import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { ReactComponent as IconEdit } from '../../../../../assets/icons/u_edit.svg';
import { ReactComponent as IconTrash } from '../../../../../assets/icons/u_trash.svg';
import { ReactComponent as IconSort } from '../../../../../assets/icons/u_sort.svg';
import { ReactComponent as PmrYellow } from '../../../../../assets/icons/u_alert-exclamacion.svg';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { renderDateOrSlash } from '../../../../../services/stringUtil';
import BaseModal from '../../../../Util/Modals/BaseModal';
import { styles } from '../../../../Help/HelpTutorialModal/HelpTutorialModal.Styles';
import { AccordanceState } from '../../../../../models/AccordanceState';
import CloseCrossButton from '../../../../Util/Buttons/CloseCrossButton/CloseCrossButton';
import ErrorMessage from '../../../../Util/ErrorMessage/ErrorMessage';
import NewAccordanceForm from '../../../../ProjectDetail/ProjectTabs/Accordances/NewAccordanceForm/NewAccordanceForm';
import UndoButton from '../../../../Util/Buttons/UndoButton/UndoButton';
import SaveButton from '../../../../Util/Buttons/SaveButton/SaveButton';
import { deleteAccordance } from '../../../../../api/accordanceApi';
import { Tooltip, withStyles } from '@material-ui/core';

const SeeConsolidatedTable = ({
  data,
  fnHandleEdit,
  fnSetEditModal,
  editModal,
  accordanceEdit,
  fnSetDeleteModal,
  deteleModal,
}) => {
  const { t } = useTranslation();
  const [errorsMessage, setErrorsMesssge] = useState(false);
  const [editForm, setEditForm] = useState<boolean>(false);
  const [catchId, setCatchId] = useState<number>(0);

  const isDateExpired = dateString => {
    const date = new Date(dateString);
    return date < new Date();
  };

  const BlackTooltip = withStyles({
    tooltip: {
      //   color: '$ci-color-greyscale-90',
      width: '55%',
      backgroundColor: '#101319',
      fontSize: '16px',
      fontFamily: 'Rubik',
      lineHeight: '20px',
      padding: '8px 12px',
      borderRadius: '8px',
    },
    arrow: {
      fontSize: 20,
      color: '#4A4A4A',
      '&::before': {
        backgroundColor: '#101319',
      },
    },
  })(Tooltip);

  const colorText = () => {
    switch (accordanceEdit[0]?.accordanceState) {
      case 1:
        return 'pendding';
      case 3:
        return 'compliment';
      default:
        return 'current';
    }
  };

  const openDeleteModal = id => {
    fnSetDeleteModal(true);
    setCatchId(id);
  };

  const deleteAcc = async (id: number) => {
    await deleteAccordance(id);
  };

  const accordanceStateNames = {
    0: 'draft_accordance',
    1: 'pending_accordance_singular',
    2: 'active_accordance_singular',
    3: 'accomplished_accordance_singular',
    4: 'cancelled_accordance_singular',
  };

  return (
    <>
      <TableContainer>
        <Table className="no-hover">
          <TableHead>
            <TableRow>
              <TableCell className="width-3">{t('accordance_description')}</TableCell>
              <TableCell className="width-2">{t('critical_issue')}</TableCell>
              <TableCell className="width-1">
                <div className="sort">
                  <p>{t('due_date')}</p>
                  <div>
                    <IconSort />
                  </div>
                </div>
              </TableCell>
              <TableCell className="width-1">{t('actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                <TableCell>{row?.description}</TableCell>
                <TableCell>{row?.criticalIssue?.name}</TableCell>
                <TableCell className={clsx({ expired: isDateExpired(row?.fechaVencimiento) })}>
                  {renderDateOrSlash(row?.expirationDate)} {isDateExpired(row?.expirationDate) && '(vencido)'}
                </TableCell>
                <TableCell>
                  <div className="icons">
                    <IconEdit onClick={() => fnHandleEdit(row.id)} />
                    <IconTrash onClick={() => openDeleteModal(row.id)} />
                    {!row?.dataComplete ? (
                      <BlackTooltip
                        title={t('please_check_required_fields')}
                        placement="bottom"
                        enterDelay={500}
                        leaveDelay={100}
                        arrow
                      >
                        <span className="alert-icon">
                          <PmrYellow />
                        </span>
                      </BlackTooltip>
                    ) : null}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {accordanceEdit?.length ? (
        <BaseModal
          isOpen={editModal}
          shouldCloseOnOverlayClick={false}
          onRequestClose={() => fnSetEditModal(false)}
          style={{ overlay: { ...styles.overlay, ...{ overflowY: 'auto' } } }}
          className={`modal-accordance-detail-content ${
            accordanceEdit[0]?.accordanceState === AccordanceState.REJECT ? 'reject' : ''
          }`}
        >
          <div className="modal-accordance-detail-container">
            <div className="close-cross-container">
              <CloseCrossButton handleClick={() => fnSetEditModal(false)} />
            </div>
            <div className="accordance-header-container">
              <div className="accordance-header-container-info">
                <h3>
                  {t('accordance_detail')} / {t('state')}:{' '}
                  <span className={colorText()}>{t(accordanceStateNames[accordanceEdit[0]?.accordanceState])}</span>
                </h3>
                <p>
                  <b>{accordanceEdit[0]?.project.code}</b> {t('LON_table')}: {accordanceEdit[0]?.project.loanNumber}
                </p>
              </div>
            </div>
            {errorsMessage && (
              <ErrorMessage
                customStyle={{ position: 'relative', left: -44, width: 622 }}
                alertMessage={t('could_not_edit_accordance')}
                descriptionMessage={t('please_check_required_fields')}
              />
            )}
            <NewAccordanceForm
              displayGenericErrorsMessage={value => setErrorsMesssge(value)}
              close={setEditForm}
              closeModalEditReviewer={fnSetEditModal}
              editAccordanceData={accordanceEdit[0]}
              accordanceFormType={accordanceEdit[0]?.accordanceType}
              currentUserCanApprove={accordanceEdit[0]?.currentUserCanApprove}
            />
          </div>
        </BaseModal>
      ) : null}
      <BaseModal
        isOpen={deteleModal}
        shouldCloseOnOverlayClick={false}
        onRequestClose={() => fnSetDeleteModal(false)}
        style={styles}
        className="modal-accordance-fulfilled-content"
      >
        <div className="modal-accordance-fulfilled-container">
          <div className="modal-accordance-fullfilled-info">
            <div className="fullfilled-icon"></div>
            <h3>{t('fulfilled_accordance')}</h3>
            <div className="fullfilled-info">
              <p className="subtitule">{t('new_text_deleted')}</p>
              <p className="subtitule-two">{t('new_question_deleted')}</p>
            </div>
          </div>
          <div className="horizontal">
            <UndoButton handleClick={() => fnSetDeleteModal(false)} />
            <SaveButton
              handleClick={() => {
                deleteAcc(catchId);
                fnSetDeleteModal(false);
              }}
              customStyle={{ padding: '0px 44px' }}
              isAccordance={true}
            />
          </div>
        </div>
      </BaseModal>
    </>
  );
};

export default SeeConsolidatedTable;
