import { useTheme } from '@material-ui/core/styles';
import { Pagination } from '@material-ui/lab';
import React from 'react';
import { useSelector } from 'react-redux';
import { isMobile } from '../../../../services/booleanUtil';
import { AppMenuTabs } from '../../../MenuTabs/userTabsInterface';
import { EmptySearchResult } from '../../../Util/EmptySearchResult/EmptySearchResult';
import { ProjectsTable } from '../../ProjectsTable/ProjectsTable';
import { TabPanel } from '../ProjectAllUtil';
import '../../../Util/Pagination/Pagination.scss';

type Props = {
  projects: any;
  numberOfPages: any;
  numberOfProjects: any;
  setInFollow: any;
  sortTable: any;
  initialPage: any;
  handleChangePaginate: any;
};

export const ProjectsAllTable = ({
  projects,
  numberOfPages,
  numberOfProjects,
  setInFollow,
  sortTable,
  initialPage,
  handleChangePaginate,
}: Props) => {
  const theme = useTheme();
  const { isAnnual } = useSelector<any, any>(state => state.filter.common);
  const { projectSelectedTab } = useSelector<any, AppMenuTabs>(state => state.menuTabs);
  return projects.length > 0 ? (
    <TabPanel
      key={projectSelectedTab.internalId}
      value={projectSelectedTab.internalId}
      index={projectSelectedTab.internalId}
      dir={theme.direction}
    >
      <ProjectsTable
        projects={projects}
        setInFollow={setInFollow}
        sortTable={sortTable}
        isAnnual={isAnnual}
        fnPaginacion={handleChangePaginate}
        initialPage={initialPage}
        numberOfPages={numberOfPages}
        numberOfProjects={numberOfProjects}
      />
      {/* <div className={isMobile() ? 'pagination-mobile' : 'pagination-style'}>
        {numberOfPages > 1 ? (
          <Pagination count={numberOfPages} page={initialPage} onChange={handleChangePaginate} boundaryCount={2} />
        ) : null}
      </div> */}
    </TabPanel>
  ) : (
    <EmptySearchResult />
  );
};
