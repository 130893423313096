import React from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { formatMillionNumber } from '../../../../../services/numberUtil';
import { renderDecimalNumber } from '../../../../../services/booleanUtil';
import './DoubleNumberWithTitle.scss';

type Props = {
  card: any;
};

const DoubleNumberWithTitle = ({ card }: Props) => {
  const { t } = useTranslation();

  const { technicalCooperations, supervisionPlans } = card.data;
  return (
    <div className="summary-indicator-container">
      {card ? (
        <>
          <div className="horizontal">
            <div className="value">{technicalCooperations.value}</div>
            <div className="subtitle">{t(technicalCooperations.text)}</div>
          </div>
          <div className="horizontal">
            <div className="value">{supervisionPlans.value}</div>
            <div className="subtitle">{t(supervisionPlans.text)}</div>
          </div>
        </>
      ) : (
        <div className="empty-data">
          <p className="bold-text">-</p>
        </div>
      )}
    </div>
  );
};

export { DoubleNumberWithTitle };

export const formatNumberSummaryIndicator = number => {
  return (
    <NumberFormat
      value={formatMillionNumber(number)}
      displayType={'text'}
      className={'value'}
      thousandSeparator={'.'}
      decimalSeparator={','}
      decimalScale={renderDecimalNumber(formatMillionNumber(number)) ? 1 : 0}
    />
  );
};
