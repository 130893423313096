import React from 'react';
import './CustomizableDisbursementProjectionFilter.scss';
import { useTranslation } from 'react-i18next';
import FilterIndicators from '../../../../../models/FilterIndicators';
import { renderDisbursementProjectionFilter } from '../../../../Filter/DisbursementProjectionFilter/DisbursementProjectionFilter';

type Props = {
  reportFilters: FilterIndicators;
  reportFiltersChanged: Function;
};

export const CustomizableDisbursementProjectionFilter = ({ reportFilters, reportFiltersChanged }: Props) => {
  const { t } = useTranslation();
  const [errorMessages, handleErrorMessages] = React.useState({ isHidden: true, message: '' });
  const [localFilter, setLocalFilter] = React.useState(reportFilters);
  const [from, setFrom] = React.useState(reportFilters.common.timeWithoutDisbursementFrom);
  const [to, setTo] = React.useState(reportFilters.common.timeWithoutDisbursementTo);

  const defaultMaxValue = 0;
  const defaultMinValue = 0;
  const defaultValueFrom = from !== defaultMinValue ? from : 0;
  const defaultValueTo = to !== defaultMinValue ? to : 0;

  const handleValue = (min, value) => {
    let disbursementProjectionFrom = from;
    let disbursementProjectionTo = to;

    if (min) {
      setFrom(+value);
      disbursementProjectionFrom = +value;
    } else {
      setTo(+value);
      disbursementProjectionTo = +value;
    }

    if (disbursementProjectionFrom >= disbursementProjectionTo && disbursementProjectionTo !== defaultMaxValue) {
      handleErrorMessages({ isHidden: false, message: 'right_input_validation' });
    } else {
      handleErrorMessages({ isHidden: true, message: '' });
      if (disbursementProjectionTo === defaultMinValue) {
        disbursementProjectionTo = defaultMaxValue;
        setTo(defaultMaxValue);
      }

      const newFilter = {
        ...localFilter,
        common: {
          ...localFilter.common,
          disbursementProjectionFrom,
          disbursementProjectionTo,
        },
      };
      setLocalFilter(newFilter);
      reportFiltersChanged({
        disbursementProjectionFrom: newFilter.common.disbursementProjectionFrom,
        disbursementProjectionTo: newFilter.common.disbursementProjectionTo,
      });
    }
  };

  return renderDisbursementProjectionFilter(defaultValueFrom, defaultValueTo, errorMessages, handleValue, t);
};
