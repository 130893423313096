import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Table.module.scss';
import { renderDateOrSlash } from '../../../../services/stringUtil';
import { Pagination } from '@material-ui/lab';
import '../../../Util/Pagination/Pagination.scss';
import usePaginationSort from '../../../../hooks/usePaginationSort';
import useTable from '../../../../hooks/useTable';
import { LAST_DISBURSEMENT_DATE_CLOSE_OR_EXPIRED } from '../../../../services/sessionFiltersIndicatorDetailHelper';
import { TabsFiltersEnum } from '../TabsFilter/TabsFilter';
import {
  addFilterConditionOnIgSection,
  filterProjectsBetweenLimitsByDays,
  getSubstractedDateByDays,
  getUniqueCodeProjectsList,
  isOnCtTab,
} from '../../EarlyWarningsFilters/Util';
import { EarlyWarningsTimeLapse } from '../../EarlyWarningsTimeLapse/EarlyWarningsTimeLapse';
import { ProjectCounter } from '../../ProjectCounter/ProjectCounter';
import { CriteriaType } from '../../../../models/PaginationIndicator';
import { useSelector } from 'react-redux';
import { isDiferentToIGLower } from '../../EarlyWarningsTimeLapse/Util';
import { basicFirstColumns, HeaderTableColumn } from '../../../../models/HeaderTableColumn';
import { handleValueOrSlash } from '../../PortfolioDetailTablesUtil';
import { ProjectsListSkeleton } from '../../../LoadersSkeletons/ProjectsListSkeleton/ProjectsListSkeleton';
import PaginationTable from '../../PaginationTable/PaginationTable';
import { Tooltip } from '@material-ui/core';
import { ExcelDownloadButton } from '../../../Util/ExcelDownloadButton/ExcelDownloadButton';
import useDataNormalize from '../../../../hooks/useDataNormalize';

import { withStyles } from '@material-ui/core/styles';
import { IndicatorKeyEnum } from '../../../../models/IndicatorKeyEnum';
import { FilterTables } from '../../../Filter/FilterTables/FilterTables';

const BlackTooltip = withStyles({
  tooltip: {
    //   color: '$ci-color-greyscale-90',
    backgroundColor: '#101319',
    fontSize: '16px',
    fontFamily: 'Rubik',
    fontWeight: '400',
    lineHeight: '20px',
    padding: '8px 12px',
    borderRadius: '8px',
  },
  arrow: {
    fontSize: 20,
    color: '#4A4A4A',
    '&::before': {
      backgroundColor: '#101319',
    },
  },
})(Tooltip);

const headers = [
  'id',
  'last_project',
  'last_operation',
  'name',
  'gcm_team_leader',
  'filter_last_disbursement_date',
  'no_of_extensions',
  'months_extended',
  'pending_disbursement_per',
  'early_warnings',
];

const selectedKeys = ['id', 'code', 'loanNumber', 'title', 'field5', 'field1', 'field2', 'field3', 'field1'];

enum EXPIRED_FLAG {
  'EXPIRED',
  'NOT_EXPIRED',
}

enum FilterColorSelected {
  NONE_SELECTED = -1,
  GREEN = 0,
  YELLOW = 1,
  RED = 2,
}

const handleDaysDiff = (filterColor: FilterColorSelected, projectD: string) => {
  const today = new Date();
  if (filterColor === FilterColorSelected.NONE_SELECTED) {
    return true;
  }
  const projectDate = new Date(projectD);
  if (filterColor === FilterColorSelected.GREEN) {
    return filterProjectsBetweenLimitsByDays(projectDate, getSubstractedDateByDays(120), today);
  } else if (filterColor === FilterColorSelected.YELLOW) {
    return filterProjectsBetweenLimitsByDays(projectDate, getSubstractedDateByDays(364), getSubstractedDateByDays(121));
  }
  return filterProjectsBetweenLimitsByDays(projectDate, getSubstractedDateByDays(365));
};

const getProjectsQuantityByEarlyWarningType = projectsExpired => {
  const uniqueProjectsExpired = getUniqueCodeProjectsList(projectsExpired);
  let green = 0;
  let yellow = 0;
  let red = 0;
  const today = new Date();

  for (const p of uniqueProjectsExpired) {
    if (filterProjectsBetweenLimitsByDays(new Date(p.field1), getSubstractedDateByDays(120), today)) {
      green++;
    } else if (
      filterProjectsBetweenLimitsByDays(
        new Date(p.field1),
        getSubstractedDateByDays(364),
        getSubstractedDateByDays(121),
      )
    ) {
      yellow++;
    } else if (filterProjectsBetweenLimitsByDays(new Date(p.field1), getSubstractedDateByDays(365))) {
      red++;
    }
  }
  return [green, yellow, red];
};

const calculateProjectsQuatities = projects => {
  const projectsExpired = projects.filter(p => p.field4 === EXPIRED_FLAG[0] && isDiferentToIGLower(p.field7));
  return getProjectsQuantityByEarlyWarningType(projectsExpired);
};

type Props = {
  selectedTab: TabsFiltersEnum;
  earlyWarningsTab: boolean[];
  projectsQuantities: Function;
  loading?: boolean;
};
export const LastDisbursementTable = ({ selectedTab, earlyWarningsTab, projectsQuantities, loading }: Props) => {
  const { t } = useTranslation();
  const { handleClick, projects } = useTable();
  const [filterdProjects, setFilteredProjects] = useState<any>(projects);
  const { projectTypeFilterOnDetail } = useSelector<any, any>(state => state.indicatorDetailFilter);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [dataFilter, setDataFilter] = useState<any>(projects);
  const [dataFilterBtn, setDataFilterBtn] = useState<any>();

  useEffect(() => {
    setDataFilter(projects);
  }, [projects]);

  const { handlePaginationChange, handleSort, currentList, pagesCount, currentPage } = usePaginationSort({
    projects: dataFilter,
    defaultSortCriteria: 'field2',
    pageCount: rowsPerPage,
  });

  const {
    container,
    tableHeaderExpired,
    tableHeaderNoExpired,
    tableRowExpired,
    tableRowNoExpired,
    headerTitle,
    rowValue,
    rowList,
    rowValueName,
    fw,
    lastColumn,
    rowValueDate,
    headerTitleClickable,
    rowValueCenterd,
    rowValueCapitalized,
    rowValueSpan,
    headerTitleClickableCentered,
    borderTable,
  } = styles;

  useEffect(() => {
    projectsQuantities(calculateProjectsQuatities(projects));
    setDataFilter(projects.filter(p => p.field4 === EXPIRED_FLAG[0]));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects]);

  useEffect(() => {
    setDataFilter(
      projects.filter(p => {
        return (
          p.field4 === EXPIRED_FLAG[selectedTab] &&
          handleDaysDiff(earlyWarningsTab.indexOf(true), p.field1) &&
          addFilterConditionOnIgSection(projectTypeFilterOnDetail, earlyWarningsTab, p.field7)
        );
      }),
    );
    if (earlyWarningsTab.includes(true)) {
      setDataFilterBtn(
        projects.filter(p => {
          return (
            p.field4 === EXPIRED_FLAG[selectedTab] &&
            handleDaysDiff(earlyWarningsTab.indexOf(true), p.field1) &&
            addFilterConditionOnIgSection(projectTypeFilterOnDetail, earlyWarningsTab, p.field7)
          );
        }),
      );
    } else {
      setDataFilterBtn([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, earlyWarningsTab]);

  const handleClickOnProject = project => {
    const storage = sessionStorage.getItem(LAST_DISBURSEMENT_DATE_CLOSE_OR_EXPIRED);
    storage &&
      sessionStorage.setItem(
        LAST_DISBURSEMENT_DATE_CLOSE_OR_EXPIRED,
        JSON.stringify({ ...JSON.parse(storage), changeProjectType: false }),
      );
    handleClick(project);
  };

  const isCtOrNotExpiredTabSelected = () => {
    if (selectedTab === TabsFiltersEnum.NO_EXPIRED || isOnCtTab(projectTypeFilterOnDetail)) {
      return true;
    }
    return false;
  };

  const lastDisbursementHeaderTableSetup: HeaderTableColumn[] = [
    ...basicFirstColumns(headerTitleClickable),
    {
      className: headerTitleClickable,
      criteria: 'field5',
      criteriaType: CriteriaType.string,
      label: 'project_manager',
    },
    {
      className: headerTitleClickableCentered,
      criteria: 'field1',
      criteriaType: CriteriaType.date,
      label: 'last_disbursement_date',
    },
    {
      className: headerTitleClickableCentered,
      criteria: 'field6',
      criteriaType: CriteriaType.number,
      label: 'no_of_extensions',
    },
    {
      className: headerTitleClickableCentered,
      criteria: 'field2',
      criteriaType: CriteriaType.number,
      label: 'months_extended',
    },
    {
      className: headerTitleClickableCentered,
      criteria: 'field3',
      criteriaType: CriteriaType.number,
      label: 'pending_disbursement_per',
    },
    {
      className: headerTitleClickableCentered,
      criteria: 'field1',
      criteriaType: CriteriaType.date,
      label: 'early_warnings',
      hide: isCtOrNotExpiredTabSelected(),
    },
  ];
  const dataExcel = useDataNormalize(headers, selectedKeys, dataFilter);

  return loading ? (
    <div className="portfolio-detail-content__loading__container">
      <ProjectsListSkeleton />
    </div>
  ) : projects.length ? (
    <div className={container}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <ExcelDownloadButton headers={headers} selectedKeys={selectedKeys} dataExcel={dataExcel} />
        <FilterTables
          projects={projects.filter(p => {
            return (
              p.field4 === EXPIRED_FLAG[selectedTab] &&
              handleDaysDiff(earlyWarningsTab.indexOf(true), p.field1) &&
              addFilterConditionOnIgSection(projectTypeFilterOnDetail, earlyWarningsTab, p.field7)
            );
          })}
          keysFilter={selectedKeys}
          fnDataFilter={setDataFilter}
          disabledNumber={currentPage}
          dataFilterBtn={dataFilterBtn}
          indicatorKey={IndicatorKeyEnum.PROJECTS_LAST_DISBURSEMENT_DATE_CLOSE_12MONTHS_OR_EXPIRED}
          filterBtn={earlyWarningsTab}
        />
      </div>
      <div className={borderTable}>
        <div className={isCtOrNotExpiredTabSelected() ? tableHeaderNoExpired : tableHeaderExpired}>
          {lastDisbursementHeaderTableSetup.map(ld => {
            const { className, criteria, criteriaType, label, hide } = ld;
            return (
              !hide && (
                <h4
                  className={className}
                  onClick={() => handleSort(criteria, criteriaType)}
                  key={`${className}-${criteria}-${label}`}
                >
                  {t(label)}
                </h4>
              )
            );
          })}
        </div>
        <ul className={rowList}>
          {dataFilter?.length === 0
            ? dataFilter?.map(project => (
                <li
                  className={isCtOrNotExpiredTabSelected() ? tableRowNoExpired : tableRowExpired}
                  key={`${project.loanNumber}${project.code}${project.frontendId}`}
                >
                  <BlackTooltip title={t('go_to_project_info')} arrow>
                    <p className={`${rowValue} link-project`} onClick={() => handleClickOnProject(project)}>
                      {project.code}
                    </p>
                  </BlackTooltip>
                  <p className={rowValue}>{project.loanNumber}</p>
                  <BlackTooltip title={project.title || ''} placement="bottom" enterDelay={500} leaveDelay={100} arrow>
                    <p className={rowValueName}>{project.title}</p>
                  </BlackTooltip>
                  <p className={rowValueCapitalized}>{handleValueOrSlash(project.field5).toLowerCase()}</p>
                  <p className={rowValueDate}>{renderDateOrSlash(project.field1)}</p>
                  <p className={rowValueCenterd}>{handleValueOrSlash(project.field6)}</p>
                  <p className={rowValueCenterd}>{handleValueOrSlash(project.field2)}</p>
                  <p className={rowValueCenterd}>{handleValueOrSlash(project.field3)}</p>

                  {!isCtOrNotExpiredTabSelected() && (
                    <span className={rowValueSpan}>
                      <EarlyWarningsTimeLapse
                        filterToApply={{
                          green: filterProjectsBetweenLimitsByDays(
                            new Date(project.field1),
                            getSubstractedDateByDays(120),
                            new Date(),
                          ),
                          yellow: filterProjectsBetweenLimitsByDays(
                            new Date(project.field1),
                            getSubstractedDateByDays(364),
                            getSubstractedDateByDays(121),
                          ),
                          red: filterProjectsBetweenLimitsByDays(
                            new Date(project.field1),
                            getSubstractedDateByDays(365),
                          ),
                        }}
                        projectStringDate={handleValueOrSlash(project.field1)}
                        projectProductType={project.field7}
                      />
                    </span>
                  )}
                </li>
              ))
            : currentList?.map(project => (
                <li
                  className={isCtOrNotExpiredTabSelected() ? tableRowNoExpired : tableRowExpired}
                  key={`${project.loanNumber}${project.code}${project.frontendId}`}
                >
                  <BlackTooltip title={t('go_to_project_info')} arrow>
                    <p className={`${rowValue} link-project`} onClick={() => handleClickOnProject(project)}>
                      {project.code}
                    </p>
                  </BlackTooltip>
                  <p className={rowValue}>{project.loanNumber}</p>
                  <BlackTooltip title={project.title || ''} placement="bottom" enterDelay={500} leaveDelay={100} arrow>
                    <p className={rowValueName}>{project.title}</p>
                  </BlackTooltip>
                  <p className={rowValueCapitalized}>{handleValueOrSlash(project.field5).toLowerCase()}</p>
                  <p className={rowValueDate}>{renderDateOrSlash(project.field1)}</p>
                  <p className={rowValueCenterd}>{handleValueOrSlash(project.field6)}</p>
                  <p className={rowValueCenterd}>{handleValueOrSlash(project.field2)}</p>
                  <p className={rowValueCenterd}>{handleValueOrSlash(project.field3)}</p>

                  {!isCtOrNotExpiredTabSelected() && (
                    <span className={rowValueSpan}>
                      <EarlyWarningsTimeLapse
                        filterToApply={{
                          green: filterProjectsBetweenLimitsByDays(
                            new Date(project.field1),
                            getSubstractedDateByDays(120),
                            new Date(),
                          ),
                          yellow: filterProjectsBetweenLimitsByDays(
                            new Date(project.field1),
                            getSubstractedDateByDays(364),
                            getSubstractedDateByDays(121),
                          ),
                          red: filterProjectsBetweenLimitsByDays(
                            new Date(project.field1),
                            getSubstractedDateByDays(365),
                          ),
                        }}
                        projectStringDate={handleValueOrSlash(project.field1)}
                        projectProductType={project.field7}
                      />
                    </span>
                  )}
                </li>
              ))}
        </ul>
      </div>
      <ProjectCounter
        arrayToCount={dataFilter}
        countProjects={true}
        indicatorKey={IndicatorKeyEnum.PROJECTS_LAST_DISBURSEMENT_DATE_CLOSE_12MONTHS_OR_EXPIRED}
      />
      <div className="pagination-style">
        <PaginationTable fnSetRows={setRowsPerPage} rows={rowsPerPage} numProyects={dataFilter?.length} />
        {dataFilter?.length !== 0 ? (
          <Pagination count={pagesCount} page={currentPage} onChange={handlePaginationChange} />
        ) : null}
      </div>
    </div>
  ) : null;
};
