import React from 'react';
import { useTranslation } from 'react-i18next';
import { PcrBullets } from '../../../../models/PcrNextPresentation';
import styles from './PcrBulletsComponent.module.scss';

type Props = {
  bulletsData: PcrBullets[];
};
export const PcrBulletsComponent = ({ bulletsData }: Props) => {
  const { bulletsContainer, item, dot, itemText } = styles;
  const { t } = useTranslation();
  return (
    <div className={bulletsContainer}>
      {bulletsData.map(d => {
        return (
          <div className={item} key={d.bulletText}>
            <i className={dot} style={{ backgroundColor: d.bulletColor }} />
            <p className={itemText}> {t(d.bulletText)}</p>
          </div>
        );
      })}
    </div>
  );
};
