import { findMetricValueByGrouping, getIndicatorData, metricAreZeros, metricIsEmpty } from './indicatorHelperUtil';
import { IndicatorKeyEnum as keyEnum } from '../../../models/IndicatorKeyEnum';
import { getTabValuesFromMetrics } from './util';

const addProjectsWhereLastDisbursementDateIsClose12MonthOrExpired = (indicators, cardIndicators) => {
  const lastDisbursementDateClose12MonthsOrExpired = 'expired_and_expiring_projects';
  const indicator = getIndicatorData(indicators, keyEnum.PROJECTS_LAST_DISBURSEMENT_DATE_CLOSE_12MONTHS_OR_EXPIRED);

  if (!indicator) {
    return;
  } else if (metricIsEmpty(indicator) || metricAreZeros(indicator)) {
    cardIndicators.push({
      id: indicator.id,
      indicatorKey: indicator.key,
      type: 'text_double',
      title: lastDisbursementDateClose12MonthsOrExpired,
      detailTitle: lastDisbursementDateClose12MonthsOrExpired,
      data: null,
      order: indicator.order,
    });
  } else {
    const projectsCloseToExpiration = findMetricValueByGrouping(
      indicator.metrics,
      'PROJECT_PRODUCT_TYPE',
      'NONE',
      'PROJECT_COUNT_UNEXPIRED',
    );
    const expiredProjects = findMetricValueByGrouping(
      indicator.metrics,
      'PROJECT_PRODUCT_TYPE',
      'NONE',
      'PROJECT_COUNT_EXPIRED',
    );

    const indicatorCardData = {
      id: indicator.id,
      indicatorKey: keyEnum.PROJECTS_LAST_DISBURSEMENT_DATE_CLOSE_12MONTHS_OR_EXPIRED,
      type: 'text_double',
      title: lastDisbursementDateClose12MonthsOrExpired,
      detailTitle: lastDisbursementDateClose12MonthsOrExpired,
      data: {
        projectsCloseToExpiration,
        expiredProjects,
        list: [
          { id: 1, value: projectsCloseToExpiration, text: 'projects_close_to_expiration' },
          { id: 2, value: expiredProjects, text: 'expired_projects' },
        ],
      },

      order: indicator.order,
      tabIndicatorValues: getTabValuesFromMetrics(indicator, true),
    };

    cardIndicators.push(indicatorCardData);
  }
};

const addPendingSignature = (indicators, cardIndicators) => {
  const pendingLegaltitle = 'menu_projects pending_legal_validity';
  const indicator = getIndicatorData(indicators, keyEnum.PROJECTS_PENDING_SIGNATURE_OR_LEGISLATIVE_APPROVAL);
  if (!indicator) {
    return;
  } else if (metricIsEmpty(indicator) || metricAreZeros(indicator)) {
    cardIndicators.push({
      id: indicator.id,
      indicatorKey: indicator.key,
      type: 'text_double',
      title: pendingLegaltitle,
      detailTitle: pendingLegaltitle,
      data: null,
      order: indicator.order,
    });
  } else {
    const pSignature = findMetricValueByGrouping(
      indicator.metrics,
      'PROJECT_PRODUCT_TYPE',
      'NONE',
      'AMOUNT_PENDING_SIGNATURE',
    );
    const pRatification = findMetricValueByGrouping(
      indicator.metrics,
      'PROJECT_PRODUCT_TYPE',
      'NONE',
      'AMOUNT_PENDING_RATIFICATION',
    );
    const loanBlueBoxSignature = findMetricValueByGrouping(
      indicator.metrics,
      'PROJECT_PRODUCT_TYPE',
      'LON',
      'AMOUNT_PENDING_SIGNATURE',
    );
    const loanBlueBoxRatification = findMetricValueByGrouping(
      indicator.metrics,
      'PROJECT_PRODUCT_TYPE',
      'LON',
      'AMOUNT_PENDING_RATIFICATION',
    );

    const tcpBlueBoxSignature = findMetricValueByGrouping(
      indicator.metrics,
      'PROJECT_PRODUCT_TYPE',
      'TCP',
      'AMOUNT_PENDING_SIGNATURE',
    );
    const tcpBlueBoxRatification = findMetricValueByGrouping(
      indicator.metrics,
      'PROJECT_PRODUCT_TYPE',
      'TCP',
      'AMOUNT_PENDING_RATIFICATION',
    );

    const igrBlueBoxSignature = findMetricValueByGrouping(
      indicator.metrics,
      'PROJECT_PRODUCT_TYPE',
      'IGR',
      'AMOUNT_PENDING_SIGNATURE',
    );
    const igrBlueBoxRatification = findMetricValueByGrouping(
      indicator.metrics,
      'PROJECT_PRODUCT_TYPE',
      'IGR',
      'AMOUNT_PENDING_RATIFICATION',
    );

    const portfolioSummaryData = {
      id: indicator.id,
      indicatorKey: keyEnum.PROJECTS_PENDING_SIGNATURE_OR_LEGISLATIVE_APPROVAL,
      type: 'text_double',
      title: pendingLegaltitle,
      detailTitle: pendingLegaltitle,
      data: {
        pendingSignature: pSignature,
        pendingRatification: pRatification,
        list: [
          { id: 1, value: pSignature, text: 'pending_signing_of_contracts' },
          { id: 2, value: pRatification, text: 'pending_legislative_ratification' },
        ],
      },

      order: indicator.order,
      blueBoxValues: {
        loanBlueBoxSignature,
        loanBlueBoxRatification,
        tcpBlueBoxSignature,
        tcpBlueBoxRatification,
        igrBlueBoxSignature,
        igrBlueBoxRatification,
      },
      tabIndicatorValues: getTabValuesFromMetrics(indicator, true),
    };

    cardIndicators.push(portfolioSummaryData);
  }
};

const addAcquisitionWithMilestones = (indicators, cardIndicators) => {
  const pendingLegaltitle = 'new_acquisition_with_milestones_pending_to_date';
  const indicator = getIndicatorData(indicators, keyEnum.ACQUISITION_WITH_MILESTONES_PENDING_TO_DATE);
  if (!indicator) {
    return;
  } else if (metricIsEmpty(indicator) || metricAreZeros(indicator)) {
    cardIndicators.push({
      id: indicator.id,
      indicatorKey: indicator.key,
      type: 'text_double',
      title: pendingLegaltitle,
      detailTitle: pendingLegaltitle,
      data: null,
      order: indicator.order,
    });
  } else {
    const pPost = findMetricValueByGrouping(indicator.metrics, 'NONE', 'NONE', 'PUBLICATION_PROCESSES');

    const pAward = findMetricValueByGrouping(indicator.metrics, 'NONE', 'NONE', 'CONTRACT_PROCESSES');

    const portfolioSummaryData = {
      id: indicator.id,
      indicatorKey: keyEnum.ACQUISITION_WITH_MILESTONES_PENDING_TO_DATE,
      type: 'text_double',
      title: pendingLegaltitle,
      detailTitle: pendingLegaltitle,
      data: {
        pendingSignature: pPost,
        pendingRatification: pAward,
        list: [
          { id: 1, value: pPost, text: 'new_processes_that_should_be_published' },
          { id: 2, value: pAward, text: 'new_processes_that_should_be_contracted' },
        ],
      },

      order: indicator.order,
      tabIndicatorValues: getTabValuesFromMetrics(indicator, true),
    };

    cardIndicators.push(portfolioSummaryData);
  }
};

export {
  addPendingSignature,
  addProjectsWhereLastDisbursementDateIsClose12MonthOrExpired,
  addAcquisitionWithMilestones,
};
