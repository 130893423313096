import { IndicatorAnalyticFilterEnum as IndicatorFilter } from '../../../../../../models/IndicatorAnalyticFilterEnum';
import styles from './MultipleStackedBar.module.scss';

export const conditionalFunction = (condition, first, second) => {
  return condition ? first : second;
};

export const isLargeChart = indicatorFilterState => {
  return indicatorFilterState === IndicatorFilter.Countries || indicatorFilterState === IndicatorFilter.Roles;
};

export const simpleColorClasses = {
  base_information: styles._1,
  planning: styles._2,
  accordances: styles._3,
  financial: styles._4,
  risk: styles._5,
};

export const colorClasses = [
  styles.others,
  styles._1,
  styles._2,
  styles._3,
  styles._4,
  styles._5,
  styles._6,
  styles._7,
  styles._8,
  styles._9,
  styles._10,
  styles._11,
  styles._12,
  styles._13,
  styles._14,
  styles._15,
  styles._16,
  styles._17,
  styles._18,
  styles._19,
  styles._20,
  styles._21,
  styles._22,
  styles._23,
  styles._24,
  styles._25,
  styles._26,
  styles._27,
  styles._28,
  styles._29,
];

export const colorCountriesClasses = [...colorClasses.slice(1)];

export const tooltipFirstLabel = (customSelectState, completeLabel, t) => {
  return customSelectState.id === 0 ? t(completeLabel.label) : completeLabel.label;
};

export const listContainerClassName = (isOpen, indicatorFilterState) => {
  return isLargeChart(indicatorFilterState)
    ? styles.hideListOptions
    : isOpen
    ? styles.listOptionsModal
    : styles.listOptions;
};

export const handleButtonsClassName = isOpen => {
  return isOpen ? styles.prevNextContainer : styles.prevNextContainerSimple;
};

export const translateRenderSimpleDataBars = (renderSimpleXRange, t) => {
  return renderSimpleXRange.map(r => t(r));
};

export const setRenderDataBarsModal = (data, isOpen, simple) => {
  if (isOpen) {
    if (!simple) {
      return data.slice(0, data.length - 1);
    } else {
      return data;
    }
  } else {
    if (!simple) {
      return data.slice(0, 4).concat(data[data.length - 1]);
    } else {
      return data;
    }
  }
};
