import { SET_INDICATOR_PROJECTS, RESET_INDICATOR_PROJECTS } from './types';
import {
  getAllProjectsIndicatorPmrDivision,
  getAllProjectsIndicatorByDate,
  getAllProjectsIndicatorLatestEfas,
  getAllProjectsIndicatorEsgDistributionAccordingEnvironmentalAndSocial,
  getAllProjectsIndicatorEsgSafeguardPerformance,
  getAllProjectsByMultiplesPmr,
  getAllProjectsCalendarIndicator,
  getAllProjectsIndicatorTransactionalSupervisionResources,
  getAllProjectsGenericDoughnutIndicator,
  getAllProjectsIndicatorDivision,
  getAllProjectsIndicatorCountry,
} from '../../api/indicatorProjectsApi';
import {
  getByCategorizationIndicatorProjects,
  getSimpleIndicatorProjects,
  getSimpleIndicatorProjectsBidConsultancyIndicator,
  getSimpleIndicatorProjectsSummaryIndicator,
  getSimpleIndicatorProjectsWithDiscriminatorAndAttribute,
  IFiltersBidConsultanciesDetailParams,
} from '../../api/indicatorApi';
import { setCalendarEvents } from './calendar';
import { IncludeRegionalProjectsEnum } from '../../models/IncludeRegionalProjectsEnum';
import { IndicatorKeyEnum } from '../../models/IndicatorKeyEnum';
import { PCRCategorizationValues } from '../../models/PcrNextPresentation';
import { handlePCRProjectResponse } from '../../services/util';

export function setIndicatorProjects(data: any[]) {
  const dataWithFrontendId = data.map((item, index) => {
    item.frontendId = `${index.toString()}-${Math.floor(Math.random() * 100).toString()}`;
    return item;
  });
  return {
    type: SET_INDICATOR_PROJECTS,
    payload: dataWithFrontendId,
  };
}

export function resetIndicatorProjects() {
  return {
    type: RESET_INDICATOR_PROJECTS,
  };
}

/**
 * Used by indicator key PMR_PERFORMANCE_LAST_CLOSED_YEAR
 */
export function getProjectsByDivisionPmr(pmrClassification, division, totalProjects, indicatorId) {
  return async (dispatch: any) => {
    try {
      const response = await getAllProjectsIndicatorPmrDivision(
        pmrClassification,
        division,
        totalProjects,
        indicatorId,
      );
      await dispatch(setIndicatorProjects([...response]));
    } catch (error) { }
  };
}

/**
 * Used by indicator key PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS_BY_DIVISION
 */
export function getProjectsByDivision(completeGroupingValue, totalProjects, indicatorId) {
  return async (dispatch: any) => {
    try {
      const response = await getAllProjectsIndicatorDivision(completeGroupingValue, totalProjects, indicatorId);
      await dispatch(setIndicatorProjects([...response]));
    } catch (error) { }
  };
}

/**
 * Used by indicator key PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS_BY_COUNTRY
 */
export function getProjectsByCountry(countryId) {
  return async (dispatch: any) => {
    try {
      const response = await getAllProjectsIndicatorCountry(countryId);
      await dispatch(setIndicatorProjects([...response]));
    } catch (error) { }
  };
}

/**
 * Used by indicator key MONTHLY_DISBURSEMENT_PROJECTION_CURRENT_YEAR_INCLUDING_REGIONALS
 */
export function getProjectsByDate(month, year, indicatorId, pblParam?) {
  return async (dispatch: any) => {
    try {
      const newDate = new Date(year, month - 1, 1);
      const response = await getAllProjectsIndicatorByDate(newDate, indicatorId, pblParam);
      await dispatch(setIndicatorProjects([...response]));
    } catch (error) { }
  };
}

/*
 *Used by indicators
 *PROJECTS_WITH_TRANSACTIONAL_BUDGET_DEFICIT
 *PROJECTS_IN_EXECUTION_WITH_MICI_OPEN_CASES
 *PROJECTS_PENDING_SIGNATURE_OR_LEGISLATIVE_APPROVAL
 *PROJECTS_PENDING_DISBURSEMENT_ELIGIBILITY
 *PROJECTS_OVERALL_STAGE_CODE_DISBURSING_NO_DISBURSEMENTS_OVER_180_DAYS
 *PROJECTS_EXTENDED_NOT_FULLY_DISBURSED
 *DISBURSEMENT_PROJECTION_CURRENT_YEAR_INCLUDING_REGIONALS
 *PROJECTS_LAST_DISBURSEMENT_DATE_CLOSE_12MONTHS_OR_EXPIRED
 *PROJECTS_SUMMARY_PORTFOLIO (sin filtro de pmr)
 *PMR_ALERT_PROBLEM_LAST_3_YEARS
 *PROJECTS_ELIGIBLE_FIRST_UNDISBURSEMENT
 
 */
export function getProjectsSimpleTableIndicator(currentIndicatorId, currentIndicatorKey = undefined) {
  return async (dispatch: any) => {
    try {
      let response: any[] = await getSimpleIndicatorProjects(currentIndicatorId);
      //HOT FIX para ordenar en tabla de detalle de indicador
      if (
        currentIndicatorKey &&
        currentIndicatorKey === IndicatorKeyEnum.PROJECTS_PENDING_SIGNATURE_OR_LEGISLATIVE_APPROVAL
      ) {
        response = response.map(item => {
          if (item.field3 === 'APPFS') {
            item.field3 = 'APPS';
          }
          return item;
        });
      }
      await dispatch(setIndicatorProjects([...response]));
    } catch (error) { }
  };
}

//*SUPERVISION_PLAN_PLANNED_VERSUS_EXECUTED
export function getProjectsSimpleTableForSupervisionPlanPlannedVersusExecuted(currentIndicatorId, options) {
  return async (dispatch: any) => {
    try {
      const response: any[] = await getSimpleIndicatorProjectsWithDiscriminatorAndAttribute(
        currentIndicatorId,
        options,
      );
      await dispatch(setIndicatorProjects([...response]));
    } catch (error) { }
  };
}

export function getProjectsSimpleTableIndicatorForSummaryIndicator(
  currentIndicatorId,
  { beneficiaryTypes = [], projectProductAmounts, includeRegionalProjects, moduleFilterState }: any,
) {
  return async (dispatch: any) => {
    try {
      const response = await getSimpleIndicatorProjectsSummaryIndicator(currentIndicatorId, {
        beneficiaryTypes,
        projectProductAmounts,
        includeRegionalProjects,
        moduleFilterState,
      });
      await dispatch(setIndicatorProjects([...response]));
    } catch (error) { }
  };
}

export function getProjectsSimpleTableIndicatorForBidConsultancyIndicator(
  currentIndicatorId,
  options: IFiltersBidConsultanciesDetailParams,
) {
  return async (dispatch: any) => {
    try {
      const response = await getSimpleIndicatorProjectsBidConsultancyIndicator(currentIndicatorId, options);

      await dispatch(setIndicatorProjects([...response]));
    } catch (error) { }
  };
}

/*
 *Used by indicators
  PMR_STATE_DISTRIBUTION
 */
export function getProjectsByPmr(pmrClassification, totalProjects, indicatorId) {
  return async (dispatch: any) => {
    try {
      const response = await getAllProjectsGenericDoughnutIndicator(
        pmrClassification,
        totalProjects,
        indicatorId,
        'PmrClassification',
      );
      await dispatch(setIndicatorProjects([...response]));
    } catch (error) { }
  };
}

export function getProjectsByCTPerfomanceRating(classification, totalProjects, indicatorId) {
  return async (dispatch: any) => {
    try {
      const response = await getAllProjectsGenericDoughnutIndicator(
        classification,
        totalProjects,
        indicatorId,
        'PerformanceClassification',
      );
      await dispatch(setIndicatorProjects([...response]));
    } catch (error) { }
  };
}

/*
 *Used by indicators
 PROJECTS_SUMMARY_PORTFOLIO (Filtro por PMR alerta y problema)
 */
export function getProjectsByAlertProblemPmr(
  pmrClassification,
  indicatorId,
  includeRegionalProjects = IncludeRegionalProjectsEnum.NO_INCLUDE_PROJECTS,
) {
  return async (dispatch: any) => {
    try {
      const response = await getAllProjectsByMultiplesPmr(pmrClassification, indicatorId, includeRegionalProjects);
      await dispatch(setIndicatorProjects([...response]));
    } catch (error) { }
  };
}

/*
 *Used by indicator
  DISTRIBUTION_ACCORDING_TO_LATEST_EFAS
 */
export function getProjectsByEfasOpinion(efaConcept, totalProjects, indicatorId, fiscalYear) {
  return async (dispatch: any) => {
    try {
      const response = await getAllProjectsIndicatorLatestEfas(efaConcept, totalProjects, indicatorId, fiscalYear);

      await dispatch(setIndicatorProjects([...response]));
    } catch (error) { }
  };
}

/*
  Used by ESG_DISTRIBUTION_ACCORDING_TO_ENVIRONMENTAL_AND_SOCIAL_CATEGORY
*/
export function getProjectByESGCategory(esgClassification, totalProjects, indicatorId) {
  return async (dispatch: any) => {
    try {
      const response = await getAllProjectsIndicatorEsgDistributionAccordingEnvironmentalAndSocial(
        esgClassification,
        totalProjects,
        indicatorId,
      );

      await dispatch(setIndicatorProjects([...response]));
    } catch (error) { }
  };
}

/*
  Used by ESG_ENVIRONMENTAL_AND_SOCIAL_RISK_RATING
          ESG_SAFEGUARD_PERFORMANCE_RATING
*/
export function getProjectByESGBarcharts(
  esgField,
  esgValue,
  totalProjects,
  indicatorId,
  sectorFilters = null,
  regionFilters = null,
) {
  return async (dispatch: any) => {
    try {
      const response = await getAllProjectsIndicatorEsgSafeguardPerformance(
        esgField,
        esgValue,
        totalProjects,
        indicatorId,
        sectorFilters,
        regionFilters,
      );

      await dispatch(setIndicatorProjects([...response]));
    } catch (error) { }
  };
}

/*
  Used by CALENDAR_EVENTS indicator
*/
export function getProjectsCalendarIndicator(indicatorId, date = new Date()) {
  return async (dispatch: any) => {
    try {
      const response: any[] = await getAllProjectsCalendarIndicator(date, indicatorId);
      const eventsList = response.map(e => ({
        id: e.field1,
        title: e.field3,
        date: e.field2,
        eventType: e.field4,
        projectId: e.id,
        projectCode: e.code,
        projectTitle: e.title,
        loanNumber: e.loanNumber,
      }));

      dispatch(setCalendarEvents(eventsList));
      await dispatch(setIndicatorProjects([...response]));
    } catch (error) { }
  };
}

export function getProjectsByFundingSource(fundingSource, indicatorId) {
  return async (dispatch: any) => {
    try {
      const response = await getAllProjectsIndicatorTransactionalSupervisionResources(fundingSource, indicatorId);
      await dispatch(setIndicatorProjects([...response]));
    } catch (error) { }
  };
}

/*
 *Used by indicator
  PMR_VALIDATION_STATE
  PCR_NEXT_PRESENTATION
 */
export function getProjectsByCategorization(indicatorId, categorization, options: any = {}) {
  const { indicatorKey } = options;
  return async (dispatch: any) => {
    try {
      const response = await getByCategorizationIndicatorProjects(indicatorId, categorization);
      if (
        indicatorKey === IndicatorKeyEnum.PCR_NEXT_PRESENTATION &&
        categorization === PCRCategorizationValues.UPCOMING_SUBMISSION
      ) {
        await dispatch(setIndicatorProjects([...handlePCRProjectResponse(response)]));
      } else {
        await dispatch(setIndicatorProjects([...response]));
      }
    } catch (error) { }
  };
}
