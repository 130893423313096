import React from 'react';
import styles from './EditCustomTabButton.module.scss';
import { MenuTabs } from '../../../MenuTabs/userTabsInterface';
import './EditCustomTabButton.scss';

type Props = {
  handleButtonClick: () => void;
  selectedTab: MenuTabs;
};

const EditCustomTabButton = (props: Props) => {
  const { handleButtonClick, selectedTab } = props;
  const { container, icon } = styles;

  return selectedTab.tabId !== 0 ? (
    <button className={`${container} edit-custom-tab-button`} onClick={handleButtonClick} tabIndex={30}>
      <span className={icon}></span>
    </button>
  ) : null;
};

export default EditCustomTabButton;
