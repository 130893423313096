import { makeStyles, Tab, Tabs } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { tabStyles } from '../../../../Projects/ProjectsAll/ProjectsAll.Style';
import { SummaryTcpInternalFilters } from '../SummaryTcp';
import styles from './TcpFilters.module.scss';

type Props = {
  filterBy: SummaryTcpInternalFilters;
  handleFilterApplied: Function;
};
export const TcpFilters = ({ filterBy, handleFilterApplied }: Props) => {
  const { t } = useTranslation();
  const classes = makeStyles(tabStyles)();
  const tabClasses = { root: classes.root, selected: classes.selected };
  const { filterContainer } = styles;
  const handleSetMenuValue = (tabValue: any) => {
    handleFilterApplied(tabValue);
  };
  return (
    <div className={filterContainer}>
      <Tabs
        value={filterBy}
        classes={{ indicator: classes.indicatorColor }}
        variant="standard"
        aria-label="full width tabs example"
      >
        <Tab
          key={SummaryTcpInternalFilters.NATIONAL}
          value={SummaryTcpInternalFilters.NATIONAL}
          classes={tabClasses}
          label={t('national')}
          onClick={() => handleSetMenuValue(SummaryTcpInternalFilters.NATIONAL)}
        />
        <Tab
          key={SummaryTcpInternalFilters.REGIONALS}
          value={SummaryTcpInternalFilters.REGIONALS}
          classes={tabClasses}
          label={t('regional')}
          onClick={() => handleSetMenuValue(SummaryTcpInternalFilters.REGIONALS)}
        />
      </Tabs>
    </div>
  );
};
