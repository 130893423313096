import React from 'react';
import { MenuTabSectionEnum } from '../userTabsInterface';
import styles from './SeparatorMenu.module.scss';

type Props = {
  menuTabSection?: MenuTabSectionEnum;
};
export const SeparatorMenu = ({ menuTabSection }: Props) => {
  const { separatorMenuContainer, separatorBar, separatorMenuContainerProjects } = styles;

  return (
    <div
    className={
      menuTabSection === MenuTabSectionEnum.PROJECT_MENU_TAB ? separatorMenuContainerProjects : separatorMenuContainer
      }
    >
      {<div className={separatorBar}>|</div>}
    </div>
  );
};
