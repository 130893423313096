import React, { useEffect } from 'react';
import './CalendarFilters.scss';
import { useTranslation } from 'react-i18next';
import CustomSwitch from '../../../Util/CustomSwitch/CustomSwitch';
import CustomCheckbox from '../../../Util/CustomCheckbox/CustomCheckbox';
import { useDispatch, useSelector } from 'react-redux';
import { resetFilters, setCalendarFilters } from '../../../../redux/actions/calendar';
import { syncProjectCalendarOutlook } from '../../../../redux/actions/currentProject';
import LinkProjectOutlook from '../../../Util/Modals/LinkProjectOutlook/LinkProjectOutlook';
import Project from '../../../../models/Project';
import { isMobile } from '../../../../services/booleanUtil';
import CalendarFiltersMobile from './CalendarFiltersMobile';

type Props = {
  disbursements: boolean;
  acquisitions: boolean;
  activities: boolean;
  accordancesMonitor: boolean;
  accordancesRevision: boolean;
  supervisoryAction: boolean;
  secretarialEvents?: boolean;
  lifecycle?: boolean;
  missions?: boolean;
  interGroup: boolean;
  linkOutlook: boolean;
  isIndicatorCalendar?: boolean;
};

const CalendarFilters = (props: Props) => {
  const { t } = useTranslation();
  const {
    disbursements,
    acquisitions,
    // activities,
    accordancesMonitor,
    accordancesRevision,
    secretarialEvents,
    lifecycle,
    missions,
    interGroup,
    supervisoryAction,
    isIndicatorCalendar,
  } = props;
  const [showOutlookModal, setShowOutlookModal] = React.useState(false);
  const [activeSwitch, setActiveSwitch] = React.useState(false);
  const currentProject = useSelector<any, Project>(state => state.currentProject);
  const { eventCounter } = useSelector<any, any>(state => state.calendar);

  const countHiddenEvents = eventCounter.filter(e => !e.active).reduce((acc, cur) => acc + cur.value, 0);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetFilters(isIndicatorCalendar));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSwitchChange = () => {
    if (!showOutlookModal && !currentProject.calendarSynchronized) {
      setShowOutlookModal(true);
      setActiveSwitch(true);
    } else {
      dispatch(
        syncProjectCalendarOutlook({
          reminderActivityEvent: 0,
          reminderMonitoringAccordanceEvent: 0,
          remindersRevisionAccordanceEvent: 0,
          reminderAcquisitionsEvent: 0,
          reminderDisbursementsEvent: 0,
        }),
      );
      setActiveSwitch(false);
    }
  };
  const handleDisbursement = () => {
    dispatch(setCalendarFilters('disbursements', isIndicatorCalendar));
  };
  const handleAcquisitions = () => {
    dispatch(setCalendarFilters('acquisitions', isIndicatorCalendar));
  };
  // const handleActivities = () => {
  //   dispatch(setCalendarFilters('activities', isIndicatorCalendar));
  // };
  const handleAccordanceMonitor = () => {
    dispatch(setCalendarFilters('accordancesMonitor', isIndicatorCalendar));
  };
  const handleAccordanceRevision = () => {
    dispatch(setCalendarFilters('accordancesRevision', isIndicatorCalendar));
  };

  const handleSecretarialEvents = () => {
    dispatch(setCalendarFilters('secretarialEvents', isIndicatorCalendar));
  };
  const handleLifecycle = () => {
    dispatch(setCalendarFilters('lifecycle', isIndicatorCalendar));
  };
  const handleMissions = () => {
    dispatch(setCalendarFilters('missions', isIndicatorCalendar));
  };

  const handleInterGroup = () => {
    dispatch(setCalendarFilters('interGroup', isIndicatorCalendar));
  };

  const handleSupervisoryAction = () => {
    dispatch(setCalendarFilters('supervisoryAction', isIndicatorCalendar));
  };

  return isMobile() ? (
    <CalendarFiltersMobile
      disbursements={disbursements}
      acquisitions={acquisitions}
      // activities={activities}
      accordancesMonitor={accordancesMonitor}
      accordancesRevision={accordancesRevision}
      interGroup={interGroup}
      supervisoryAction={supervisoryAction}
      clickDisbursements={handleDisbursement}
      clickAcquisitions={handleAcquisitions}
      // clickActivities={handleActivities}
      clickMonitor={handleAccordanceMonitor}
      clickRevision={handleAccordanceRevision}
      clickInterGroup={handleInterGroup}
      clickSupervisoryAction={handleSupervisoryAction}
    />
  ) : (
    <>
      <div className={setClassnameOfCalendarFilters(isIndicatorCalendar)}>
        <CustomCheckbox
          labelText={t('disbursements')}
          isChecked={disbursements}
          handleOnClick={handleDisbursement}
          bulletColor={'#91DECD'}
          data-testid="disbursements"
          customStyle={setMarginBottomOnIndicatorCalendar(isIndicatorCalendar)}
        />
        <CustomCheckbox
          labelText={t('acquisitions')}
          isChecked={acquisitions}
          handleOnClick={handleAcquisitions}
          bulletColor={'#CCB0F5'}
          data-testid="acquisitions"
          customStyle={setMarginBottomOnIndicatorCalendar(isIndicatorCalendar)}
        />
        {/*<CustomCheckbox
          labelText={t('activities')}
          isChecked={activities}
          handleOnClick={handleActivities}
          bulletColor={'#A6D277'}
          data-testid="activities"
          customStyle={setMarginBottomOnIndicatorCalendar(isIndicatorCalendar)}
        /> */}
        <CustomCheckbox
          labelText={t('monitor_accordances')}
          isChecked={accordancesMonitor}
          handleOnClick={handleAccordanceMonitor}
          bulletColor={'#F9C268'}
          data-testid="monitor_accordances"
          customStyle={setMarginBottomOnIndicatorCalendar(isIndicatorCalendar)}
        />
        <CustomCheckbox
          labelText={t('revision_accordances')}
          isChecked={accordancesRevision}
          handleOnClick={handleAccordanceRevision}
          bulletColor={'#E092A3'}
          data-testid="revision_accordances"
          customStyle={setMarginBottomOnIndicatorCalendar(isIndicatorCalendar)}
        />
        <CustomCheckbox
          labelText={t('inter_group_accordances')}
          isChecked={interGroup}
          handleOnClick={handleInterGroup}
          bulletColor={'#7A629B'}
          data-testid="inter_group_accordances"
          customStyle={setMarginBottomOnIndicatorCalendar(isIndicatorCalendar)}
        />
        <CustomCheckbox
          labelText={t('supervisory_actions')}
          isChecked={supervisoryAction}
          handleOnClick={handleSupervisoryAction}
          bulletColor={'#79B9FF'}
          data-testid="supervisory_action"
          customStyle={setMarginBottomOnIndicatorCalendar(isIndicatorCalendar)}
        />
        {isIndicatorCalendar && (
          <>
            <CustomCheckbox
              labelText={t('secretarial_events')}
              isChecked={secretarialEvents ? secretarialEvents : false}
              handleOnClick={handleSecretarialEvents}
              bulletColor={'#FF79A9'}
              customStyle={setMarginBottomOnIndicatorCalendar(isIndicatorCalendar)}
            />
            <CustomCheckbox
              labelText={t('lifecycle')}
              isChecked={lifecycle ? lifecycle : false}
              handleOnClick={handleLifecycle}
              bulletColor={'#EDD402'}
              customStyle={setMarginBottomOnIndicatorCalendar(isIndicatorCalendar)}
            />
            <CustomCheckbox
              labelText={t('missions')}
              isChecked={missions ? missions : false}
              handleOnClick={handleMissions}
              bulletColor={'#B479FF'}
              customStyle={setMarginBottomOnIndicatorCalendar(isIndicatorCalendar)}
            />
          </>
        )}
        {!isIndicatorCalendar && <>{renderHideEventLabel(countHiddenEvents, t)}</>}
      </div>
      <div className="switch-container">
        <CustomSwitch
          handleChange={handleSwitchChange}
          active={currentProject.calendarSynchronized || activeSwitch}
          disabled={false}
        />
        <p>{t('link_outlook_calendar')}</p>
      </div>
      <LinkProjectOutlook
        isOpen={showOutlookModal}
        closeModal={() => setShowOutlookModal(false)}
        resetSwitch={() => setActiveSwitch(false)}
      />
    </>
  );
};

export default CalendarFilters;

const setMarginBottomOnIndicatorCalendar = isIndicatorCalendar => (isIndicatorCalendar ? { marginBottom: '25px' } : {});
const setClassnameOfCalendarFilters = isIndicatorCalendar =>
  isIndicatorCalendar ? 'calendar-indicator-filters-container' : 'calendar-filters-container';

const renderHideEventLabel = (countHiddenEvents, t) => {
  return (
    <p></p>
    // <p className={countHiddenEvents !== 0 ? 'showHiddenEventslabel' : 'hideHiddenEventsLabel'}>{`(${t(
    //   'there_are',
    // )} ${countHiddenEvents} ${t('hidden_events')})`}</p>
  );
};
