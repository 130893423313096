export const viewPortWatcher = (viewportSize: number, projectsPerPage: any): number => {
  const twoCardsPerRow = 1042;
  const fourCardsPerRow = 1468;
  const sixCardsPerRow = 1894;
  if (viewportSize < twoCardsPerRow && projectsPerPage !== 2) {
    return 2;
  }
  if (viewportSize >= twoCardsPerRow && viewportSize < fourCardsPerRow && projectsPerPage !== 4) {
    return 4;
  }
  if (viewportSize >= fourCardsPerRow && viewportSize < sixCardsPerRow && projectsPerPage !== 6) {
    return 6;
  }
  if (viewportSize >= sixCardsPerRow && projectsPerPage !== 8) {
    return 8;
  }
  return projectsPerPage;
};

export enum NavigationCardsEnum {
  first = 1,
  center,
  last,
  onePage,
}

export const swipeCards = (setNavigationCards, toRight, initialPage, numberOfPages, paginate) => {
  if (numberOfPages > 1) {
    switch (toRight) {
      case true:
        swipeRight(initialPage, numberOfPages, paginate, setNavigationCards);
        break;
      case false:
        swipeLeft(initialPage, numberOfPages, paginate, setNavigationCards);
        break;
      default:
        return;
    }
  } else {
    return;
  }
};

const swipeRight = (initialPage, numberOfPages, paginate, setNavigationCards) => {
  if (initialPage === 2) {
    paginate(initialPage - 1);
    setNavigationCards(NavigationCardsEnum.first);
  }
  if (initialPage === 1) {
    setNavigationCards(NavigationCardsEnum.first);
    return;
  }
  if (initialPage >= 3) {
    paginate(initialPage - 1);
    setNavigationCards(NavigationCardsEnum.center);
  }
  if (initialPage === numberOfPages) {
    setNavigationCards(NavigationCardsEnum.center);
  }
};

const swipeLeft = (initialPage, numberOfPages, paginate, setNavigationCards) => {
  if (initialPage < numberOfPages) {
    setNavigationCards(NavigationCardsEnum.center);
    paginate(initialPage + 1);
  }
  if (initialPage === numberOfPages - 1) {
    paginate(initialPage + 1);
    setNavigationCards(NavigationCardsEnum.last);
  }
  if (initialPage === numberOfPages) {
    setNavigationCards(NavigationCardsEnum.last);
  }
};

export const handleCardsOnPaginate = (value, numberOfPages, setNavigationCards) => {
  if (value <= 1) {
    setNavigationCards(NavigationCardsEnum.first);
  } else if (value > 1 && value < numberOfPages) {
    setNavigationCards(NavigationCardsEnum.center);
  } else {
    setNavigationCards(NavigationCardsEnum.last);
  }
};
