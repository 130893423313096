import React from 'react';
import { ModuleButton } from '../../../models/EarlyWarningsSection';
import styles from './AlertsButtonsModule.module.scss';
import { Button } from './Button/Button';

const { alertsButtonsModule } = styles;

type Props = {
  buttons: ModuleButton[];
  handleClick: Function;
  loadingData: boolean;
};
export const AlertsButtonsModule = ({ buttons, handleClick, loadingData }: Props) => {
  return (
    <div className={alertsButtonsModule}>
      {buttons.map(button => (
        <Button buttonData={button} handleClick={handleClick} key={button.buttonEWType} loadingData={loadingData} />
      ))}
    </div>
  );
};
