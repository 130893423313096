import { TableRow } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';

import { StyledTableCellPageCounterAverageLag } from '../PortfolioSimpleIndicatorTable/PortfolioSimpleIndicatorTable.Styles';
import { countTotalProjects, setPlurals } from '../ProjectCounter/ProjectCounter';

type Props = {
  footerColumnsInformation?: any;
};

export const AverageLagCounter = ({ footerColumnsInformation }: Props) => {
  const { columnData1, columnData2, columnData3, columnData4, arrayToCount, countProjects } = footerColumnsInformation;
  const { t } = useTranslation();
  const totalProjects = countTotalProjects(arrayToCount);
  const textValue = setPlurals(arrayToCount, countProjects);

  return (
    <TableRow style={{ background: 'white' }}>
      <StyledTableCellPageCounterAverageLag style={{ width: '40%' }} align="left">
        <span style={{ textTransform: 'capitalize' }}>
          {`${t('total')}: `}
          {totalProjects}
          {` ${t(textValue)}`}
        </span>
      </StyledTableCellPageCounterAverageLag>
      <StyledTableCellPageCounterAverageLag style={{ width: '0%' }} align="left" />
      <StyledTableCellPageCounterAverageLag style={{ width: '15%', paddingRight: '5%' }} align="center">
        {renderFormattedNumber(columnData1)}
      </StyledTableCellPageCounterAverageLag>
      <StyledTableCellPageCounterAverageLag style={{ width: '15%', paddingRight: '5%', color: '#E092A3', fontWeight: 700 }} align="center">
        {renderFormattedNumber(columnData2)}
      </StyledTableCellPageCounterAverageLag>
      <StyledTableCellPageCounterAverageLag style={{ width: '15%', paddingRight: '5%' }} align="center">
        {renderFormattedNumber(columnData3)}
      </StyledTableCellPageCounterAverageLag>
      <StyledTableCellPageCounterAverageLag style={{ width: '15%', paddingRight: '5%', color: '#E092A3', fontWeight: 700 }} align="center">
        {renderFormattedNumber(columnData4)}
      </StyledTableCellPageCounterAverageLag>
    </TableRow>
  );
};

const renderFormattedNumber = value => {
  return (
    <NumberFormat
      value={value}
      displayType={'text'}
      className={'value'}
      thousandSeparator={'.'}
      decimalSeparator={','}
      decimalScale={0}
    />
  );
};
