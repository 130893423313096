import {
  project,
  operation,
  projectName,
  instrument,
  approvalDate,
  currentDisbursementExpirationDate,
  field1Lon,
  field2Lon,
  field3LonAndPTcpRegional,
  field4Lon,
  overallStage,
  field5Lon

} from '../../Util';

export const tableInfo = [
  project,
  operation,
  projectName,
  instrument,
  approvalDate,
  currentDisbursementExpirationDate,
  field1Lon,
  field2Lon,
  field3LonAndPTcpRegional,
  field4Lon,
  overallStage,
  field5Lon
];


