import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableContainer } from '@material-ui/core';
import {
  StyledTableHeader,
  StyledTableRowHeader,
  StyledTableCellHeader,
  StyledTableCell,
} from '../PorfolioTableShared.Styles';
import { StyledTableRow } from '../PortfolioSimpleIndicatorTable/PortfolioSimpleIndicatorTable.Styles';
import { formatedValueProperty } from '../../../services/numberUtil';
import { renderDateOrSlash } from '../../../services/stringUtil';
import { sortTablesByType } from '../../../services/sortsUtil';
import { IndicatorKeyEnum } from '../../../models/IndicatorKeyEnum';
import { customRowStyle } from '../PortfolioSimpleIndicatorTable/PortfolioSimpleIndicatorTable';
import { useHistory } from 'react-router-dom';
import '../PortfolioSimpleIndicatorTable/PortfolioSimpleIndicatorTable.scss';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { getFilterSessionValue } from '../../../services/sessionFiltersIndicatorDetailHelper';

type Props = {
  projects: any[];
  indicatorKey: string;
  tableInfo: any[];
};

const customValueStyle = data => {
  const currentDate = moment();
  const endDate = moment(data.field4);
  const dateDiff = endDate.diff(currentDate, 'days');
  const realAmount = data.field5;
  const paidAmount = data.field6;

  return dateDiff <= 30 && Math.sign(dateDiff) === 1 && realAmount !== paidAmount
    ? { color: '#E092A3', fontWeight: '700' }
    : {};
};

const getStatusText = value => {
  return value === 'EXEC_PH' ? 'supervision_plan_state_1' : 'in_progress';
};

const ConsultanciesFinancesAndExecutedByTheBankTable = (props: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [sortAsc, setSortAsc] = React.useState(true);

  const { projects, indicatorKey, tableInfo } = props;

  const sortTable = (keyToSort, fieldType) => {
    sortTablesByType(projects, sortAsc, keyToSort, fieldType);
    setSortAsc(!sortAsc);
  };

  const handleClick = projectdIdSelected => {
    history.push(`/projectdetail?id=${projectdIdSelected}`);
  };

  return indicatorKey === IndicatorKeyEnum.BID_CONSULTANCY ? (
    <div className="portfolio-simple-indicator-table-container">
      <TableContainer>
        <Table className="custom-table" style={{ tableLayout: 'auto' }} size="small">
          <StyledTableHeader>
            <StyledTableRowHeader>
              {tableInfo.map(th => (
                <StyledTableCellHeader
                  key={th.tableHeader}
                  align="left"
                  onClick={() => sortTable(th.rootProperty, th.fieldType)}
                  style={th.customWidth ? th.customWidth : {}}
                  className="code"
                >
                  {t(th.tableHeader)}
                  <i className="sort-icon"></i>
                </StyledTableCellHeader>
              ))}
            </StyledTableRowHeader>
          </StyledTableHeader>
          <TableBody>
            {projects.map((project, index) => (
              <StyledTableRow
                key={project.id}
                // style={customRowStyle(index, project.id)}
                onClick={() => handleClick(project.id)}
              >
                <StyledTableCell>{project.field1}</StyledTableCell>
                <StyledTableCell>{project.field2}</StyledTableCell>
                <StyledTableCell>{project.code}</StyledTableCell>
                <StyledTableCell>{renderDateOrSlash(project.field3, 'DD/MM/YYYY')}</StyledTableCell>
                <StyledTableCell 
                // style={customValueStyle(project)}
                >
                  {renderDateOrSlash(project.field4, 'DD/MM/YYYY')}
                </StyledTableCell>
                <StyledTableCell 
                // style={customValueStyle(project)}
                >
                  <NumberFormat
                    value={formatedValueProperty('numeric', parseFloat(project.field5))}
                    displayType={'text'}
                    className={'value'}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                  />
                </StyledTableCell>
                <StyledTableCell 
                // style={customValueStyle(project)}
                >
                  <NumberFormat
                    value={formatedValueProperty('numeric', parseFloat(project.field6))}
                    displayType={'text'}
                    className={'value'}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                  />
                </StyledTableCell>
                <StyledTableCell>{project.field7}</StyledTableCell>
                <StyledTableCell>{t(getStatusText(project.field8))}</StyledTableCell>

                {getFilterSessionValue('consultanciesTabs') === 1 && (
                  <StyledTableCell>{project.field9}</StyledTableCell>
                )}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  ) : null;
};

export default ConsultanciesFinancesAndExecutedByTheBankTable;
