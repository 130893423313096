import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getNextEvents } from '../../../api/calendarApi';
import { EventType } from '../../../models/EventType';
import Project from '../../../models/Project';
import { getIdFromUrl } from '../../../services/numberUtil';
import { sortDates } from '../../../services/sortsUtil';
import { CalendarBubble } from '../CalendarBubble/CalendarBubble';

type Props = {
  haveData: Function;
};

export const CalendarCollapsed = ({ haveData }: Props) => {
  const history = useHistory();
  const [events, setEvents] = useState<any>([]);
  const currentProject = useSelector<any, Project>(state => state.currentProject);
  const getData = async () => {
    const now = `${moment().second(0).toISOString().split('.')[0]}Z`;
    const response: any = await getNextEvents(getIdFromUrl(history.location.search), now);
    haveData(response.length);
    response.sort(sortDates('date', 'asc'));
    const datesGroup = response.reduce((r, a) => {
      r[moment(a.date).format('YYYY-MM-D')] = [...(r[moment(a.date).format('YYYY-MM-D')] || []), a];
      return r;
    }, {});
    const groupedEventsByDay: IDay[] = [];

    Object.keys(datesGroup).forEach(d => {
      const day: IDay = {
        date: d,
        eventQty: datesGroup[d].length,
        highLightEvent: datesGroup[d].reduce((a, b) => (a.date < b.date ? a : b)),
      };
      groupedEventsByDay.push(day);
    });

    setEvents(groupedEventsByDay);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject]);

  return (
    <>
      {events.map(day => (
        <CalendarBubble day={day} key={day.date} />
      ))}
    </>
  );
};

interface IHighLightEvent {
  title: string;
  eventType: EventType;
}

export interface IDay {
  date: string;
  eventQty: number;
  highLightEvent: IHighLightEvent;
}
