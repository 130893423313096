import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { MultiDataNotStackedBar } from '../../Portfolio/CardIndicator/Cards/MultiDataNotStackedBar/MultiDataNotStackedBar';
import ButtonPrevNext from '../../Util/Buttons/ButtonPrevNext/ButtonPrevNext';
import styles from './CurrentProjectionOfDisbursements.module.scss';
import { cloneDeep } from 'lodash';
import {
  monthlyDisbursementProjectionCurrentYearIncludingRegionalsOptions,
  offsetBarPlugin,
} from '../../Portfolio/IndicatorsHelpers/addDisbProjectMonthlyUtil';
import { CurrentProjectionTable } from './CurrentProjectionTable/CurrentProjectionTable';
import { getProjectsByDate } from '../../../redux/actions/indicatorProjects';
import { HeaderTable } from './HeaderTable/HeaderTable';
import { MONTHLY_DISBURSEMENT_PROJECTION_CURRENT_YEAR_INCLUDING_REGIONALS } from '../../../services/sessionFiltersIndicatorDetailHelper';
import { handleLoading } from '../../utils';
const {
  container,
  chartContainer,
  chartTitle,
  chart,
  chartContent,
  tableContainer,
  bullets,
  currentProjectionBullet,
  disbursedBullet,
  nextYearProjectionBullet,
} = styles;

const defaultSessionFilterObject = {
  month: '1',
  amountToBeDisbursed: 0,
  year: 0,
  isBackFromDetail: false,
};

export const CurrentProjectionOfDisbursements = () => {
  const dispatch = useDispatch();
  const currentIndicator = useSelector<any, any>(state => state.currentIndicator);
  const { dataFullYear } = currentIndicator;

  const { t } = useTranslation();
  const currentMonth = new Date().getMonth() + 1;
  const [indexMonth, setIndexMonth] = React.useState<number>(currentMonth);
  const [loading, setLoading] = React.useState<boolean>(false);
  const prevLimit = currentMonth - 6;
  const nextLimit = currentMonth;
  const [chartDataToDisplay, setChartDataToDisplay] = useState(cloneDeep(dataFullYear));
  const [prevDisabled, setPrevDisabled] = useState(false);
  const [nextDisabled, setNextDisabled] = useState(false);
  const [yearLimitForVerticalLine, setYearLimitForVerticalLine] = useState(0);
  const [calledFromPrevNext, setCalledFromPrevNext] = useState(false);
  const [headerTableData, setHeaderTableData] = useState<any>({
    month: '1',
    amountToBeDisbursed: 0,
    year: 0,
    isBackFromDetail: false,
  });

  useEffect(() => {
    if (!sessionStorage.getItem(MONTHLY_DISBURSEMENT_PROJECTION_CURRENT_YEAR_INCLUDING_REGIONALS)) {
      sessionStorage.setItem(
        MONTHLY_DISBURSEMENT_PROJECTION_CURRENT_YEAR_INCLUDING_REGIONALS,
        JSON.stringify(defaultSessionFilterObject),
      );
    }
  }, []);

  useEffect(() => {
    setPrevDisabled(indexMonth <= prevLimit);
    setNextDisabled(indexMonth >= nextLimit);
    const dataToDisplay = handleDataToDisplay(cloneDeep(dataFullYear), indexMonth);
    setChartDataToDisplay(dataToDisplay);
    setYearLimitForVerticalLine(
      dataToDisplay.yearsList.findIndex((year: any) => year === new Date().getFullYear() + 1),
    );
    const { month, year, value } = dataToDisplay.completeGroupingValue[0];
    const storage = sessionStorage.getItem(MONTHLY_DISBURSEMENT_PROJECTION_CURRENT_YEAR_INCLUDING_REGIONALS);
    const storageObject = storage && JSON.parse(storage);
    storage &&
      sessionStorage.setItem(
        MONTHLY_DISBURSEMENT_PROJECTION_CURRENT_YEAR_INCLUDING_REGIONALS,
        JSON.stringify({ ...JSON.parse(storage), month, year, amountToBeDisbursed: value, isBackFromDetail: true }),
      );

    tableUpdateOnFirstRenderAndBackFromDetail({
      dispatch,
      currentIndicator,
      calledFromPrevNext,
      setCalledFromPrevNext,
      storageObject,
      setHeaderTableData,
      month,
      year,
      amountToBeDisbursed: value,
      setLoading,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexMonth]);

  const handleClickBar = evt => {
    const { month, year, value } = evt.completeGroupingValue;
    setHeaderTableData({ month, amountToBeDisbursed: value, year });
    getFilterProjectsByDate(dispatch, { month, year }, currentIndicator.id, setLoading);
    const storage = sessionStorage.getItem(MONTHLY_DISBURSEMENT_PROJECTION_CURRENT_YEAR_INCLUDING_REGIONALS);
    storage &&
      sessionStorage.setItem(
        MONTHLY_DISBURSEMENT_PROJECTION_CURRENT_YEAR_INCLUDING_REGIONALS,
        JSON.stringify({ ...JSON.parse(storage), month, year, amountToBeDisbursed: value }),
      );
  };

  const handleClickPrevNext = (prev: boolean) => {
    setCalledFromPrevNext(true);
    if (prev) {
      setIndexMonth(indexMonth - 1);
    } else {
      setIndexMonth(indexMonth + 1);
    }
  };

  return (
    <div className={container}>
      <div className={chartContainer}>
        <div className={chartTitle}>{t('months')}</div>
        <div className={chartContent}>
          <ButtonPrevNext isDisabled={prevDisabled} isPrev={true} handleClick={() => handleClickPrevNext(true)} />
          <div className={chart}>
            <MultiDataNotStackedBar
              data={translateLabels(chartDataToDisplay, t)}
              customDimensions={{ desktop: { width: 270, height: 350 }, mobile: { width: 110, height: 80 } }}
              customOption={monthlyDisbursementProjectionCurrentYearIncludingRegionalsOptions(
                t,
                yearLimitForVerticalLine,
              )}
              datasetClick={e => handleClickBar(e)}
              plugins={[offsetBarPlugin]}
            />
          </div>
          <ButtonPrevNext isDisabled={nextDisabled} handleClick={() => handleClickPrevNext(false)} />
        </div>

        <div className={bullets}>
          <ul>
            <li className={currentProjectionBullet}>{t('current_projection')}</li>
            <li className={disbursedBullet}>{t('disbursement')}</li>
            <li className={nextYearProjectionBullet}>{t('next_year_projection')}</li>
          </ul>
        </div>
      </div>
      <div className={tableContainer}>
        <HeaderTable data={headerTableData} />
        <CurrentProjectionTable data={headerTableData} loading={loading} />
      </div>
    </div>
  );
};

const handleDataToDisplay = (newChartData, indexMonth) => {
  const index = newChartData.months.findIndex((month: any) => month === indexMonth);
  const SLICE_END = 7;
  newChartData.labels = newChartData.labels.slice(index, index + SLICE_END);
  newChartData.yearsList = newChartData.yearsList.slice(index, index + SLICE_END);
  newChartData.completeGroupingValue = newChartData.completeGroupingValue.slice(index, index + SLICE_END);
  newChartData.datasets[0].data = newChartData.datasets[0].data.slice(index, index + SLICE_END);
  newChartData.datasets[1].data = newChartData.datasets[1].data.slice(index, index + SLICE_END);
  newChartData.datasets[0].backgroundColor = newChartData.datasets[0].backgroundColor.slice(index, index + SLICE_END);
  newChartData.datasets[1].backgroundColor = newChartData.datasets[1].backgroundColor.slice(index, index + SLICE_END);
  return newChartData;
};

const translateLabels = (chartDataToDisplay, t) => {
  return {
    ...chartDataToDisplay,
    labels: chartDataToDisplay.labels.map(label => {
      return t(label);
    }),
  };
};

const getFilterProjectsByDate = (d, dataDate, indicatorId, setLoading) => {
  return handleLoading(() => d(getProjectsByDate(dataDate.month, dataDate.year, indicatorId)), setLoading);
};

const tableUpdateOnFirstRenderAndBackFromDetail = props => {
  const {
    dispatch,
    currentIndicator,
    calledFromPrevNext,
    setCalledFromPrevNext,
    storageObject,
    setHeaderTableData,
    month,
    year,
    amountToBeDisbursed,
    setLoading,
  } = props;
  if (!calledFromPrevNext) {
    if (storageObject.isBackFromDetail) {
      getFilterProjectsByDate(
        dispatch,
        { month: storageObject.month, year: storageObject.year },
        currentIndicator.id,
        setLoading,
      );
      setHeaderTableData({
        month: storageObject.month,
        amountToBeDisbursed: storageObject.amountToBeDisbursed,
        year: storageObject.year,
      });
    } else {
      getFilterProjectsByDate(dispatch, { month, year }, currentIndicator.id, setLoading);
      setHeaderTableData({ month, amountToBeDisbursed, year });
    }
    setCalledFromPrevNext(false);
  }
};
