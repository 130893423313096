import React from 'react';
import { useTranslation } from 'react-i18next';
import { CardTypesEnum } from '../../../models/CardTypesEnum';
import { IndicatorKeyEnum } from '../../../models/IndicatorKeyEnum';
import { isMobile } from '../../../services/booleanUtil';
import { BarChart } from '../../Portfolio/CardIndicator/Cards/BarChart/BarChart';
import './FilteredChartDetailContent.scss';

type Props = {
  currentIndicator: any;
  filterValues?: any;
};
export const FilteredChartDetailContent = ({ currentIndicator, filterValues }: Props) => {
  const { t } = useTranslation();
  const datasetClick = value => {
    const labelTotalProjectObject = getFilterdValue(currentIndicator, value);

    filterValues(labelTotalProjectObject, 10000);
  };
  return (
    <div className="filtered-chart-detail-content-container">
      <div className="project-title">{` # ${t('projects')}`}</div>
      <div className="chart-container">
        <BarChart
          type={CardTypesEnum.HALF_SIZE_VERTICAL}
          data={currentIndicator.data}
          showSubLabels={isMobile() ? false : true}
          listPage={false}
          datasetClick={datasetClick}
          indicatorKey={currentIndicator.indicatorKey}
        />
      </div>
    </div>
  );
};

const getFilterdValue = (currentIndicator, value) => {
  const totalProjects = getEsgTotalProjects(currentIndicator, value.datasetLabel);
  let returnedObject = {};
  if (currentIndicator.indicatorKey === IndicatorKeyEnum.ESG_SAFEGUARD_PERFORMANCE_RATING) {
    returnedObject = getSafeguardFilterValue(value, returnedObject, totalProjects);
  } else if (
    currentIndicator.indicatorKey === IndicatorKeyEnum.ESG_ENVIRONMENTAL_AND_SOCIAL_RISK_RATING &&
    value.datasetLabel === 'firstStack'
  ) {
    returnedObject = getRiskRatingFilterValue(value, returnedObject, totalProjects);
  }
  return returnedObject;
};

const getRiskRatingFilterValue = (value, returnedObject, totalProjects) => {
  if (value.dataIndex === 0) {
    returnedObject = { label: 'high', totalProjects };
  } else if (value.dataIndex === 1) {
    returnedObject = { label: 'substantial', totalProjects };
  } else if (value.dataIndex === 2) {
    returnedObject = { label: 'moderate', totalProjects };
  } else if (value.dataIndex === 3) {
    returnedObject = { label: 'low', totalProjects };
  } else {
    returnedObject = { label: 'not_assigned', totalProjects };
  }
  return returnedObject;
};

const getSafeguardFilterValue = (value, returnedObject, totalProjects) => {
  if (value.datasetLabel === 'firstStack') {
    if (value.dataIndex === 0) {
      returnedObject = { label: 'satisfactory', totalProjects };
    } else if (value.dataIndex === 1) {
      returnedObject = { label: 'unsatisfactory', totalProjects };
    } else {
      returnedObject = { label: 'not_assigned', totalProjects };
    }
  } else {
    if (value.dataIndex === 0) {
      returnedObject = { label: 'partially_satisfactory', totalProjects };
    } else {
      returnedObject = { label: 'partially_unsatisfactory', totalProjects };
    }
  }
  return returnedObject;
};

const getEsgTotalProjects = (currentIndicator, stackLabelSelected = 'firstStack') => {
  if (
    currentIndicator.indicatorKey === IndicatorKeyEnum.ESG_SAFEGUARD_PERFORMANCE_RATING ||
    currentIndicator.indicatorKey === IndicatorKeyEnum.ESG_ENVIRONMENTAL_AND_SOCIAL_RISK_RATING
  ) {
    const itemSelected = currentIndicator.data.datasets.find(item => item.label === stackLabelSelected);
    return itemSelected.data.reduce((a, b) => parseInt(a) + parseInt(b), 0);
  } else {
    return {};
  }
};
