import { withStyles } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

const Accordion = withStyles({
  root: {
    backgroundColor: '#f2f3f7',
    padding: '0 40px 0 40px',
    '&$expanded': {
      margin: '0 0 0 0',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    fontSize: '22px',
    color: '#264377',
    fontWeight: 700,
    lineHeight: '22px',
    paddingTop: 20,
  },
  content: {},
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(theme => ({
  root: {
    flexWrap: 'wrap',
    borderBottom: '1px solid #C1C7D0',
    maxWidth: '1600px',
    borderBottomLeftRadius: '270px 1px',
    borderBottomRightRadius: '270px 1px',
    paddingTop: 0,
  },
}))(MuiAccordionDetails);

export { Accordion, AccordionSummary, AccordionDetails };
