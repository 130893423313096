import { SummaryTcpInternalFilters } from '../SummaryTcp';

const renderTextByInternalFilterApplied = (filter, nationalLabel, regionalLabel, t) => {
  return filter === SummaryTcpInternalFilters.NATIONAL ? t(nationalLabel) : t(regionalLabel);
};

const renderValueByInternaFilterApplied = (filter, nationalValue, regionalValue) => {
  return filter === SummaryTcpInternalFilters.NATIONAL ? nationalValue : regionalValue;
};

const checkIfResto = value => {
  return !value ? 0 : value % 2 !== 1 ? value.toFixed(1) : value;
};
const handleIsSelectableButton = (filterBy, natValue, regValue) => {
  return renderValueByInternaFilterApplied(filterBy, Math.round(natValue), Math.round(regValue)) !== 0;
};

const renderSingularOrPluralByInternalFilterApplied = (filter, nationalMetric, regionalMetric, t) => {
  if (filter === SummaryTcpInternalFilters.NATIONAL) {
    return nationalMetric.value > 1 ? t(nationalMetric.pluralTextDetail) : t(nationalMetric.singularTextDetail);
  }
  return regionalMetric.value > 1 ? t(regionalMetric.pluralTextDetail) : t(regionalMetric.singularTextDetail);
};

export {
  renderTextByInternalFilterApplied,
  renderValueByInternaFilterApplied,
  checkIfResto,
  handleIsSelectableButton,
  renderSingularOrPluralByInternalFilterApplied,
};
