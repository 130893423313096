// import React, { Suspense, useEffect, useState } from 'react';
// import './App.scss';
// import { Route, Switch, Redirect } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import { ConnectedRouter } from 'connected-react-router';
// import { Home, Landing, ProjectDetail, PortfolioDetail, ContentManager, LoadingComponent } from './pages';
// import { history } from './redux/store';
// import { checkLanguageUpadeLocale } from './services/util';
// import TelemetryProvider from './services/appInsights/telemetry-provider';
// import SuccessMessage from './components/Util/SuccessMessage/SuccessMessage';
// import { ReviewAccordances } from './pages/ReviewAccordances/ReviewAccordances';
// import User from './models/User';
// import Maintenance from './components/Maintenance/Maintenance';
// import { modalSetAppElement } from './components/Util/Modals/Util';

// // MSAL imports
// import { MsalProvider } from '@azure/msal-react';
// import { IPublicClientApplication } from '@azure/msal-browser';
// import i18n from './i18n/i18n';
// import { CardsProvider } from './context/CardContext';
// import { MemoryHelpProvider } from './context/MemoryHelpFormContext';

// export const Loader: React.FC = () => <LoadingComponent />;

// modalSetAppElement(process.env.NODE_ENV);

// checkLanguageUpadeLocale();

// type Props = { pca: IPublicClientApplication };
// const App = ({ pca }: Props) => {
//   const succesMessage = useSelector<any, any>(state => state.succesMessage);
//   const user = useSelector<any, User>(state => state.user);

//   //Wait until 10 seconds to check if i18n has loaded languages
//   const [currentCount, setCount] = useState(0);
//   const timer = () => setCount(currentCount + 1);
//   useEffect(() => {
//     const {
//       store: { data },
//     } = i18n;
//     if (
//       data &&
//       Object.keys(data).length === 0 &&
//       data.constructor === Object &&
//       !sessionStorage.getItem('loadingLanguageFile')
//     ) {
//       window.location.reload();
//     } else if (currentCount >= 10 || (data && Object.keys(data).length > 0 && data.constructor === Object)) {
//       const lng = localStorage.getItem('i18nextLng') || 'es';
//       document.documentElement.lang = lng;
//       return () => clearInterval(id);
//     }
//     const id = setInterval(timer, 1000);

//     const lng = localStorage.getItem('i18nextLng') || 'es';
//     document.documentElement.lang = lng;
//     return () => clearInterval(id);
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [currentCount]);

//   return (
//     <MsalProvider instance={pca}>
//       <Suspense fallback={<Loader />}>
//         <ConnectedRouter history={history}>
//           <TelemetryProvider instrumentationKey={process.env.REACT_APP_INSIGHTS_KEY} after={() => { }} history={history}>
//             <CardsProvider>
//               <MemoryHelpProvider>
//                 <Switch>
//                   <Route exact path="/" component={Landing}></Route>
//                   <Route path="/home" component={Home}></Route>
//                   <Route path="/projectdetail" render={routerProps => <ProjectDetail {...routerProps} />}></Route>
//                   <Route path="/portfoliodetail" component={PortfolioDetail}></Route>
//                   <Route path="/reviewaccordances" component={ReviewAccordances}></Route>
//                   <Route
//                     path="/contentmanager"
//                     render={() => (user.hasAccessToContentManagement ? <ContentManager /> : <Redirect to={'/home'} />)}
//                   ></Route>
//                   <Route path="/maintenance" component={Maintenance}></Route>
//                   <Route render={() => <Redirect to={{ pathname: '/home' }} />} />
//                 </Switch>
//                 {succesMessage && succesMessage.show && <SuccessMessage textMessage={succesMessage.message} />}
//               </MemoryHelpProvider>
//             </CardsProvider>
//           </TelemetryProvider>
//         </ConnectedRouter>
//       </Suspense>
//     </MsalProvider>
//   );
// };

// export default App;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import React, { Suspense, useEffect, useState } from 'react';
import './App.scss';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Home, Landing, ProjectDetail, PortfolioDetail, ContentManager, LoadingComponent } from './pages';
import { history } from './redux/store';
import { checkLanguageUpadeLocale } from './services/util';
import TelemetryProvider from './services/appInsights/telemetry-provider';
import SuccessMessage from './components/Util/SuccessMessage/SuccessMessage';
import { ReviewAccordances } from './pages/ReviewAccordances/ReviewAccordances';
import User from './models/User';
import Maintenance from './components/Maintenance/Maintenance';
import { modalSetAppElement } from './components/Util/Modals/Util';

// MSAL imports
import { MsalProvider, useMsal } from '@azure/msal-react';
import { IPublicClientApplication } from '@azure/msal-browser';
import i18n from './i18n/i18n';
import { CardsProvider } from './context/CardContext';
import { MemoryHelpProvider } from './context/MemoryHelpFormContext';
import { DimensionProvider } from './context/DimensionContext';
import { TagFilterProvider } from './context/TagFilterContext';
import { EventProvider } from './context/EventContext';

export const Loader: React.FC = () => <LoadingComponent />;

modalSetAppElement(process.env.NODE_ENV);

checkLanguageUpadeLocale();

type Props = { pca: IPublicClientApplication };

const App = ({ pca }: Props) => {
  const successMessage = useSelector<any, any>(state => state.successMessage);
  const user = useSelector<any, User>(state => state.user);

  const [currentCount, setCount] = useState(0);
  const timer = () => setCount(currentCount + 1);

  useEffect(() => {
    const {
      store: { data },
    } = i18n;
    if (
      data &&
      Object.keys(data).length === 0 &&
      data.constructor === Object &&
      !sessionStorage.getItem('loadingLanguageFile')
    ) {
      window.location.reload();
    } else if (currentCount >= 10 || (data && Object.keys(data).length > 0 && data.constructor === Object)) {
      const lng = localStorage.getItem('i18nextLng') || 'es';
      document.documentElement.lang = lng;
      return () => clearInterval(id);
    }
    const id = setInterval(timer, 1000);

    const lng = localStorage.getItem('i18nextLng') || 'es';
    document.documentElement.lang = lng;
    return () => clearInterval(id);
  }, [currentCount]);

  //Add fix auth state management with MSAL
  useEffect(() => {
    const handleAuth = async () => {
      try {
        const accounts = pca.getAllAccounts();
        if (accounts.length === 0) {
          await pca.loginRedirect({ scopes: ['User.Read'] });
        } else {
          pca.setActiveAccount(accounts[0]);
        }
      } catch (error) {
        console.error('Authentication error:', error);
      }
    };

    pca
      .handleRedirectPromise()
      .then(response => {
        if (response) {
          pca.setActiveAccount(response.account);
        } else {
          handleAuth();
        }
      })
      .catch(error => {
        console.error('Redirect error:', error);
        handleAuth();
      });
  }, [pca]);

  return (
    <MsalProvider instance={pca}>
      <Suspense fallback={<Loader />}>
        <ConnectedRouter history={history}>
          <TelemetryProvider instrumentationKey={process.env.REACT_APP_INSIGHTS_KEY} after={() => {}} history={history}>
            <DimensionProvider>
              <EventProvider>
                <TagFilterProvider>
                  <CardsProvider>
                    <MemoryHelpProvider>
                      <Switch>
                        <Route exact path="/" component={Landing}></Route>
                        <Route path="/home" component={Home}></Route>
                        <Route path="/projectdetail" render={routerProps => <ProjectDetail {...routerProps} />}></Route>
                        <Route path="/portfoliodetail" component={PortfolioDetail}></Route>
                        <Route path="/reviewaccordances" component={ReviewAccordances}></Route>
                        <Route
                          path="/contentmanager"
                          render={() =>
                            user.hasAccessToContentManagement ? <ContentManager /> : <Redirect to={'/home'} />
                          }
                        ></Route>
                        <Route path="/maintenance" component={Maintenance}></Route>
                        <Route render={() => <Redirect to={{ pathname: '/home' }} />} />
                      </Switch>
                      {successMessage && successMessage.show && <SuccessMessage textMessage={successMessage.message} />}
                    </MemoryHelpProvider>
                  </CardsProvider>
                </TagFilterProvider>
              </EventProvider>
            </DimensionProvider>
          </TelemetryProvider>
        </ConnectedRouter>
      </Suspense>
    </MsalProvider>
  );
};

export default App;
