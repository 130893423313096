import React from 'react';
import './TimeWithoutDisbursementsFilter.scss';
import { useTranslation } from 'react-i18next';
import NumberInput from '../../Util/CustomInput/NumberInput/NumberInput';
import RequiredErrorMessageCustomMessage from '../../Util/Errors/RequiredErrorMessageCustomMessage/RequiredErrorMessageCustomMessage';
import { trackActionClicked } from '../../../api/trackActionClickedApi';
import { TrackedActions } from '../../../models/TrackedActionsEnum';
import useFilters from '../../../hooks/useFilters';
import { CalledFrom } from '../../../models/CalledFromEnum';

interface IProps {
  indicatorCall: boolean;
  calledFrom: CalledFrom;
}
type Props = IProps;

export const setTimeWithoutDisbursementsFrom = (
  indicatorCall,
  filterIndicators,
  filter,
  calledFrom,
  filterEarlyWarnings,
) => {
  if (calledFrom === CalledFrom.EARLY_WARNINGS) {
    return filterEarlyWarnings.common.timeWithoutDisbursementFrom;
  } else {
    if (indicatorCall) {
      return filterIndicators.common.timeWithoutDisbursementFrom;
    } else {
      return filter.common.timeWithoutDisbursementFrom;
    }
  }
};

export const setTimeWithoutDisbursementsTo = (
  indicatorCall,
  filterIndicators,
  filter,
  calledFrom,
  filterEarlyWarnings,
) => {
  if (calledFrom === CalledFrom.EARLY_WARNINGS) {
    return filterEarlyWarnings.common.timeWithoutDisbursementTo;
  } else {
    if (indicatorCall) {
      return filterIndicators.common.timeWithoutDisbursementTo;
    } else {
      return filter.common.timeWithoutDisbursementTo;
    }
  }
};

export const setDefaultValueFrom = (
  indicatorCall,
  filter,
  filterIndicators,
  defaultMinValue,
  filterEarlyWarnings,
  calledFrom,
) => {
  if (calledFrom === CalledFrom.EARLY_WARNINGS) {
    return filterEarlyWarnings.common.timeWithoutDisbursementFrom !== defaultMinValue
      ? filterEarlyWarnings.common.timeWithoutDisbursementFrom
      : 0;
  } else {
    if (indicatorCall) {
      return filterIndicators.common.timeWithoutDisbursementFrom !== defaultMinValue
        ? filterIndicators.common.timeWithoutDisbursementFrom
        : 0;
    } else {
      return filter.common.timeWithoutDisbursementFrom !== defaultMinValue
        ? filter.common.timeWithoutDisbursementFrom
        : 0;
    }
  }
};

export const setDefaultValueTo = (
  indicatorCall,
  filter,
  filterIndicators,
  defaultMaxValue,
  filterEarlyWarnings,
  calledFrom,
) => {
  if (calledFrom === CalledFrom.EARLY_WARNINGS) {
    return filterEarlyWarnings.common.timeWithoutDisbursementTo !== defaultMaxValue
      ? filterEarlyWarnings.common.timeWithoutDisbursementTo
      : 0;
  } else {
    if (indicatorCall) {
      return filterIndicators.common.timeWithoutDisbursementTo !== defaultMaxValue
        ? filterIndicators.common.timeWithoutDisbursementTo
        : 0;
    } else {
      return filter.common.timeWithoutDisbursementTo !== defaultMaxValue ? filter.common.timeWithoutDisbursementTo : 0;
    }
  }
};

export const getDefaultValue = (indicatorCall, from, to, earlyWarningsFromTo, calledFrom) => {
  if (calledFrom === CalledFrom.EARLY_WARNINGS) {
    return earlyWarningsFromTo;
  }

  return indicatorCall ? from : to;
};

const TimeWithoutDisbursementsFilter = (props: Props) => {
  const [errorMessages, handleErrorMessages] = React.useState({ isHidden: true, message: '' });
  const { indicatorCall, calledFrom } = props;
  const {
    filter,
    filterIndicators,
    dispatch,
    setFilter,
    setFilterIndicators,
    filterEarlyWarnings,
    setFilterEarlyWarnings,
  } = useFilters();

  const defaultMaxValue = 0;
  const defaultMinValue = 0;
  let timeWithoutDisbursementFrom = setTimeWithoutDisbursementsFrom(
    indicatorCall,
    filterIndicators,
    filter,
    calledFrom,
    filterEarlyWarnings,
  );
  let timeWithoutDisbursementTo = setTimeWithoutDisbursementsTo(
    indicatorCall,
    filterIndicators,
    filter,
    calledFrom,
    filterEarlyWarnings,
  );

  const { t } = useTranslation();

  const handleValue = (min, value) => {
    if (min === true) {
      timeWithoutDisbursementFrom = +value;
    } else {
      timeWithoutDisbursementTo = +value;
    }
    if (timeWithoutDisbursementFrom >= timeWithoutDisbursementTo && timeWithoutDisbursementTo !== 0) {
      handleErrorMessages({ isHidden: false, message: 'right_input_validation' });
      handleDispatch({
        calledFrom,
        dispatch,
        setFilterEarlyWarnings,
        filterEarlyWarnings,
        timeWithoutDisbursementFrom,
        timeWithoutDisbursementTo,
        indicatorCall,
        filterIndicators,
        setFilterIndicators,
        setFilter,
        filter,
        trackedAction: false,
      });
    } else {
      handleErrorMessages({ isHidden: true, message: '' });
      if (timeWithoutDisbursementTo === defaultMinValue) {
        timeWithoutDisbursementTo = defaultMaxValue;
      }
      handleDispatch({
        calledFrom,
        dispatch,
        setFilterEarlyWarnings,
        filterEarlyWarnings,
        timeWithoutDisbursementFrom,
        timeWithoutDisbursementTo,
        indicatorCall,
        filterIndicators,
        setFilterIndicators,
        setFilter,
        filter,
        trackedAction: true,
      });
    }
  };

  const defaultValueTimeWithoutDisbursementFilterProjectsFrom = setDefaultValueFrom(
    indicatorCall,
    filter,
    filterIndicators,
    defaultMinValue,
    filterEarlyWarnings,
    calledFrom,
  );

  const defaultValueTimeWithoutDisbursementFilterIndicatorsFrom = setDefaultValueFrom(
    indicatorCall,
    filter,
    filterIndicators,
    defaultMinValue,
    filterEarlyWarnings,
    calledFrom,
  );

  const defaultValueTimeWithoutDisbursementFilterEarlyWarningsFrom = setDefaultValueFrom(
    indicatorCall,
    filter,
    filterIndicators,
    defaultMinValue,
    filterEarlyWarnings,
    calledFrom,
  );

  const defaultValueTimeWithoutDisbursementFilterProjectsTo = setDefaultValueTo(
    indicatorCall,
    filter,
    filterIndicators,
    defaultMaxValue,
    filterEarlyWarnings,
    calledFrom,
  );
  const defaultValueTimeWithoutDisbursementFilterIndicatorsTo = setDefaultValueTo(
    indicatorCall,
    filter,
    filterIndicators,
    defaultMaxValue,
    filterEarlyWarnings,
    calledFrom,
  );
  const defaultValueTimeWithoutDisbursementFilterEarlyWarningsTo = setDefaultValueTo(
    indicatorCall,
    filter,
    filterIndicators,
    defaultMaxValue,
    filterEarlyWarnings,
    calledFrom,
  );

  const defaultValueFrom = getDefaultValue(
    indicatorCall,
    defaultValueTimeWithoutDisbursementFilterIndicatorsFrom,
    defaultValueTimeWithoutDisbursementFilterProjectsFrom,
    defaultValueTimeWithoutDisbursementFilterEarlyWarningsFrom,
    calledFrom,
  );
  const defaultValueTo = getDefaultValue(
    indicatorCall,
    defaultValueTimeWithoutDisbursementFilterIndicatorsTo,
    defaultValueTimeWithoutDisbursementFilterProjectsTo,
    defaultValueTimeWithoutDisbursementFilterEarlyWarningsTo,
    calledFrom,
  );

  return renderTimeWithoutDisbursementsFilter(defaultValueFrom, defaultValueTo, errorMessages, handleValue, t);
};

export const renderTimeWithoutDisbursementsFilter = (
  defaultValueFrom,
  defaultValueTo,
  errorMessages,
  handleValue,
  t,
) => {
  return (
    <div className="time-without-disbursements-container">
      <div className="input-section">
        <span>{t('between')}</span>
        <NumberInput
          placeholderText="0"
          value={handleValue}
          minParam={true}
          defaultValue={defaultValueFrom}
          name="timeFrom"
        />
        <span>{t('and')}</span>
        <NumberInput
          placeholderText="1000"
          value={handleValue}
          minParam={false}
          defaultValue={defaultValueTo}
          name="timeTo"
        />
        <span>{t('days')}</span>
      </div>
      <div className="validation-section">
        <RequiredErrorMessageCustomMessage isHidden={errorMessages.isHidden} errorMessage={t(errorMessages.message)} />
      </div>
    </div>
  );
};

const handleDispatch = ({
  calledFrom,
  dispatch,
  setFilterEarlyWarnings,
  filterEarlyWarnings,
  timeWithoutDisbursementFrom,
  timeWithoutDisbursementTo,
  indicatorCall,
  filterIndicators,
  setFilterIndicators,
  setFilter,
  filter,
  trackedAction,
}) => {
  if (calledFrom === CalledFrom.EARLY_WARNINGS) {
    dispatch(
      setFilterEarlyWarnings({
        ...filterEarlyWarnings,
        common: {
          ...filterEarlyWarnings.common,
          timeWithoutDisbursementFrom,
          timeWithoutDisbursementTo,
        },
      }),
    );
    trackedAction && trackActionClicked(TrackedActions.timeWithoutDisbursementFilterChangedFromEarlyWarnings);
  } else {
    if (indicatorCall) {
      dispatch(
        setFilterIndicators({
          ...filterIndicators,
          common: {
            ...filterIndicators.common,
            timeWithoutDisbursementFrom,
            timeWithoutDisbursementTo,
          },
        }),
      );
      trackedAction && trackActionClicked(TrackedActions.timeWithoutDisbursementFilterChangedFromPortfolio);
    } else {
      dispatch(
        setFilter({
          ...filter,
          common: {
            ...filter.common,
            timeWithoutDisbursementFrom,
            timeWithoutDisbursementTo,
            itSearch: true,
          },
        }),
      );
      trackedAction && trackActionClicked(TrackedActions.timeWithoutDisbursementFilterChangedFromProject);
    }
  }
};

export default TimeWithoutDisbursementsFilter;
