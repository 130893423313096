// src/redux/reducers/dataTableInfoReducer.ts
import { createReducer } from '@reduxjs/toolkit';
import {
  setCards,
  setDetailedProject,
  setLoading,
  setIndicatorResponse,
  setHomeSummaryData,
  setIndicatorLoading,
} from '../actions/dataTable';

interface ProjectDataState {
  cards: any[];
  detailedProject: any[];

  loading: boolean;
  indicatorResponse: any[];
  homeSummaryData: any[];
  indicatorLoading: boolean;
}

// Estado inicial
const initialState: ProjectDataState = {
  cards: [],
  detailedProject: [],

  loading: false,
  indicatorResponse: [],
  homeSummaryData: [],
  indicatorLoading: false,
};

// Crear el reducer
const dataTableReducer = createReducer(initialState, builder => {
  builder
    .addCase(setCards, (state, action) => {
      state.cards = action.payload;
    })
    .addCase(setDetailedProject, (state, action) => {
      state.detailedProject = action.payload;
    })
    .addCase(setLoading, (state, action) => {
      state.loading = action.payload;
    })
    .addCase(setIndicatorResponse, (state, action) => {
      state.indicatorResponse = action.payload;
    })
    .addCase(setHomeSummaryData, (state, action) => {
      state.homeSummaryData = action.payload;
    })
    .addCase(setIndicatorLoading, (state, action) => {
      state.indicatorLoading = action.payload;
    });
});

export default dataTableReducer;
