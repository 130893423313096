import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next';

const TabTitle = ({ index }) => {
    const { t } = useTranslation();
    const arraySteps = [
        t('reviews_agreements.tab_title.1'),
        t('reviews_agreements.tab_title.2'),
        t('reviews_agreements.tab_title.3'),
        t('reviews_agreements.tab_title.4'),
    ];

    const title = arraySteps[index];

    return <label>{title}</label>;
};

export default TabTitle
