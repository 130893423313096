import React from 'react';
import styles from './ProjectCard.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Pie } from 'react-chartjs-2';
import { setReviewSelectedProject } from '../../../redux/actions/reviewProjectsSelectedRows';
import ReviewAccordance from '../ReviewAccordance/ReviewAccordance';

type Props = {
  project: any;
  clickable?: boolean;
  canCreateAccordance?: boolean;
};

const options = {
  plugins: {
    datalabels: {
      formatter: (value, ctx) => {
        return '';
      },
    },
  },
  legend: {
    display: false,
  },
  tooltips: {
    enabled: false,
  },
};

const colorsList = [styles.green, styles.yellow, styles.red, styles.na];

const advancePie = (t, percent, label) => {
  return (
    <div className={styles.amountsContainer}>
      <span className={styles.label}>{t(label)}:</span>
      <div style={{ width: 15, height: 15, marginLeft: 10, border: '1px #C4C4C4 solid', borderRadius: 8 }}>
        <Pie
          data={{
            datasets: [
              {
                data: [percent, 100 - percent],
                backgroundColor: ['#A8C7FD', '#FFFFFF'],
                borderWidth: 0,
                label: '',
              },
            ],
            labels: [],
          }}
          width={25}
          height={25}
          options={options}
        />
      </div>
    </div>
  );
};

const codeLoansText = (code, withLoans, loanNumber) => {
  const loanNumberText = withLoans ? `| ${loanNumber}` : '';
  return `${code} ${loanNumberText}`;
};

const ProjectCard = ({ project, clickable, canCreateAccordance }: Props) => {
  const {
    name,
    code,
    pmrValue,
    pmrClassification,
    division,
    financialInformation,
    temporaryAdvance,
    id,
    withLoans,
    loanNumber,
  } = project;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isSelected, setIsSelected] = React.useState<boolean>(false);
  const reviewProjectsSelectedRows = useSelector<any, any>(state => state.reviewProjectsSelectedRows);

  React.useEffect(() => {
    setIsSelected(reviewProjectsSelectedRows.includes(project.id));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewProjectsSelectedRows]);

  const handleClick = () => {
    if (!clickable) {
      return;
    }
    dispatch(setReviewSelectedProject(id));
  };

  return (
    <div
      className={canCreateAccordance ? styles.containerAccordance : styles.container}
      style={isSelected ? { backgroundColor: '#EBECF0' } : {}}
      onClick={handleClick}
    >
      <div className={styles.header}>
        <h5 className={styles.title}>{codeLoansText(code, withLoans, loanNumber)}</h5>
        <div className={styles.pmrContainer}>
          <span className={styles.label}>{t('pmr_initials')}</span>
          <span className={colorsList[pmrClassification]}>
            {pmrClassification === 3 ? t('not_assigned_initials') : ''}
          </span>
          <span className={styles.pmrValue}>{pmrValue.toFixed(1)}</span>
        </div>
      </div>
      <h5 className={styles.title}>{name}</h5>
      <div className={styles.divisionContainer}>
        <span className={styles.label}>{t('division')}:</span>
        <h5 style={{ marginLeft: 5 }} className={styles.title}>
          {division.code}
        </h5>
      </div>
      <div className={styles.footer}>
        {advancePie(t, financialInformation.percentRealizedDisbursement, 'amount_disbursed')}
        {advancePie(
          t,
          temporaryAdvance && temporaryAdvance.percentTemporaryAdvanceMade
            ? temporaryAdvance.percentTemporaryAdvanceMade
            : 0,
          'temporary_advance',
        )}
      </div>
      {canCreateAccordance && <ReviewAccordance project={project} />}
    </div>
  );
};

export default ProjectCard;
