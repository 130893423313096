import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FollowButton from '../../Util/Buttons/FollowButton/FollowButton';
import './ProjectDetailHeader.scss';
import ReportButton from '../../Util/Buttons/ReportButton/ReportButton';
import BackButton from '../../Util/Buttons/BackButton/BackButton';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { isMobile } from '../../../services/booleanUtil';
import { ReportType } from '../../../models/ReportType';
import { trackActionClicked } from '../../../api/trackActionClickedApi';
import { TrackedActions } from '../../../models/TrackedActionsEnum';
import { ProjectProductTypes } from '../../../models/ProjectProductTypes';
import { setFollowUnfollow } from '../../../redux/actions/currentProject';
import ConvergenceButton from '../../Util/Buttons/ConvergenceButton/ConvergenceButton';

export const ProjectDetailHeader = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { currentProject } = useSelector<any, any>(state => state);

  const handleFollow = () => {
    dispatch(setFollowUnfollow(currentProject.id));
    if (!currentProject.inFollow) {
      trackActionClicked(TrackedActions.followProjectFromDetailsClicked);
    }
  };
  const handleReport = () => {};
  const goBack = () => {
    history.goBack();
  };

  const renderHeaderTitle = () => {
    return currentProject.projectProductType === ProjectProductTypes.IG ? t('igr_detail') : t('project_detail');
  };

  return (
    <div className="project-detail-header-container">
      <div className="back-title">
        <BackButton handleClick={goBack} />
        {!isMobile() && <h2>{renderHeaderTitle()}</h2>}
      </div>
      <div className="buttons">
        <FollowButton follow={currentProject.inFollow} handleClick={handleFollow} />
        <ReportButton
          handleClick={handleReport}
          customStyle={{ marginLeft: isMobile() ? 10 : 17 }}
          reportType={ReportType.Project}
        />
        <ConvergenceButton
          customStyle={{ marginLeft: isMobile() ? '10px' : '17px' }}
          projectCode={currentProject.code}
        />
      </div>
    </div>
  );
};

export default ProjectDetailHeader;
