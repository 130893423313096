import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { ReactComponent as IconSort } from '../../../../../assets/icons/u_sort.svg';
import { ReactComponent as IconEdit } from '../../../../../assets/icons/u_edit.svg';
import { ReactComponent as IconDelete } from '../../../../../assets/icons/u_trash.svg';
import { useTranslation } from 'react-i18next';
import { renderDateOrSlash } from '../../../../../services/stringUtil';
import { useGetAgreements } from '../../../../../hooks/useGetAgreements';
import { AgreementStatusStrings } from '../../../../../enums/accordanceStateEnum';
import NewAccordance from '../../../../Util/Modals/NewAccordance/NewAccordance';
import { useAgreementsHelpContext } from '../../../../../context/AgreementsContext';
import NewAccordanceForm from '../../../../ProjectDetail/ProjectTabs/Accordances/NewAccordanceForm/NewAccordanceForm';
import ErrorMessage from '../../../../Util/ErrorMessage/ErrorMessage';
import BaseModal from '../../../../Util/Modals/BaseModal';
import { styles } from '../../../../Help/HelpTutorialModal/HelpTutorialModal.Styles';
import { AccordanceState } from '../../../../../models/AccordanceState';
import CloseCrossButton from '../../../../Util/Buttons/CloseCrossButton/CloseCrossButton';
import { deleteAccordance } from '../../../../../api/accordanceApi';
import UndoButton from '../../../../Util/Buttons/UndoButton/UndoButton';
import SaveButton from '../../../../Util/Buttons/SaveButton/SaveButton';
import { useSelector } from 'react-redux';

function createData(description, issue, date, action) {
  return { description, issue, date, action };
}

interface Data {
  id: number;
  description: string;
  criticalIssue: { name: string };
  expirationDate: string;
}

export default function CreateDraftNewAgreementsTable({ projectId, openModal, setCloseModal, currentProject }) {
  const { t } = useTranslation();

  const accordanceStateNames = {
    0: 'draft_accordance',
    1: 'pending_accordance_singular',
    2: 'active_accordance_singular',
    3: 'accomplished_accordance_singular',
    4: 'cancelled_accordance_singular',
  };

  const [dataDraft, setDataDraft] = useState<[Data]>();
  const [editForm, setEditForm] = useState<boolean>(false);
  const [accordanceEdit, setAccordanceEdit] = useState<any>([]);
  const [errorsMessage, setErrorsMesssge] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deteleModal, setDeleteModal] = useState(false);
  const [catchId, setCatchId] = useState<number>(0);
  const { getAgreementsTrigger, setGetAgreementsTrigger } = useAgreementsHelpContext();
  const getCurrentReview = useSelector<any, any>(state => state.currentReview);

  const { id: idReview } = getCurrentReview;

  useEffect(() => {
    if (openModal || editModal || deteleModal) {
      setTimeout(() => {
        setGetAgreementsTrigger(!getAgreementsTrigger);
      }, 1000);
    } else {
      setTimeout(() => {
        setGetAgreementsTrigger(false);
      }, 1000);
    }
  }, [openModal, editModal, deteleModal]);

  const { agreementsData } = useGetAgreements(projectId, AgreementStatusStrings.DRAFT, idReview, getAgreementsTrigger);

  const handleSetState = () => {
    setDataDraft(agreementsData);
  };

  const handleEdit = id => {
    setEditModal(true);
    const filterAccordance = dataDraft?.filter(data => data.id === id);
    setAccordanceEdit(filterAccordance);
  };

  const openDeleteModal = id => {
    setDeleteModal(true);
    setCatchId(id);
  };

  const deleteAcc = async (id: number) => {
    await deleteAccordance(id);
    //  refreshBoard();
    //  setShowConfirm(false);
  };

  useEffect(() => {
    handleSetState();
  }, [agreementsData, openModal, editModal, deteleModal]);

  const colorText = () => {
    switch (accordanceEdit[0]?.accordanceState) {
      case 1:
        return 'pendding';
      case 3:
        return 'compliment';
      default:
        return 'current';
    }
  };

  return (
    <>
      <div>
        <TableContainer>
          <Table aria-label="table" className="no-hover">
            <TableHead>
              <TableRow>
                <TableCell className="width-3">{t('accordance_description')}</TableCell>
                <TableCell className="width-2">{t('critical_issue')}</TableCell>
                <TableCell className="width-1">
                  <div className="sort">
                    <p>{t('due_date')}</p>
                    <div>
                      <IconSort />
                    </div>
                  </div>
                </TableCell>
                <TableCell className="width-1">{t('actions')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataDraft?.map(row => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row?.description}
                  </TableCell>
                  <TableCell>{row?.criticalIssue?.name}</TableCell>
                  <TableCell>{renderDateOrSlash(row?.expirationDate)}</TableCell>
                  <TableCell>
                    <div className="icons">
                      <IconEdit onClick={() => handleEdit(row.id)} />
                      <IconDelete onClick={() => openDeleteModal(row.id)} />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <NewAccordance
        accordanceCreateRevision={true}
        isOpen={openModal}
        closeModal={() => setCloseModal(false)}
        canCreateInterGroupAccordances={currentProject.canCreateInterGroupAccordances}
        canCreateMonitoringAccordances={currentProject.canCreateMonitoringAccordances}
        canCreatePortfolioReviewAccordances={currentProject.canCreatePortfolioReviewAccordances}
      />
      <BaseModal
        isOpen={editModal}
        shouldCloseOnOverlayClick={false}
        onRequestClose={() => setEditModal(false)}
        style={{ overlay: { ...styles.overlay, ...{ overflowY: 'auto' } } }}
        className={`modal-accordance-detail-content ${
          accordanceEdit[0]?.accordanceState === AccordanceState.REJECT ? 'reject' : ''
        }`}
      >
        <div className="modal-accordance-detail-container">
          <div className="close-cross-container">
            <CloseCrossButton handleClick={() => setEditModal(false)} />
          </div>
          <div className="accordance-header-container">
            <div className="accordance-header-container-info">
              <h3>
                {t('accordance_detail')} / {t('state')}:{' '}
                <span className={colorText()}>{t(accordanceStateNames[accordanceEdit[0]?.accordanceState])}</span>
              </h3>
              <p>
                <b>{accordanceEdit[0]?.project.code}</b> {t('LON_table')}: {accordanceEdit[0]?.project.loanNumber}
              </p>
            </div>
          </div>
          {errorsMessage && (
            <ErrorMessage
              customStyle={{ position: 'relative', left: -44, width: 622 }}
              alertMessage={t('could_not_edit_accordance')}
              descriptionMessage={t('please_check_required_fields')}
            />
          )}
          <NewAccordanceForm
            displayGenericErrorsMessage={value => setErrorsMesssge(value)}
            close={setEditForm}
            closeModalEditReviewer={setEditModal}
            editAccordanceData={accordanceEdit[0]}
            accordanceFormType={accordanceEdit[0]?.accordanceType}
            currentUserCanApprove={accordanceEdit[0]?.currentUserCanApprove}
          />
        </div>
      </BaseModal>
      <BaseModal
        isOpen={deteleModal}
        shouldCloseOnOverlayClick={false}
        onRequestClose={() => setDeleteModal(false)}
        style={styles}
        className="modal-accordance-fulfilled-content"
      >
        <div className="modal-accordance-fulfilled-container">
          <div className="modal-accordance-fullfilled-info">
            <div className="fullfilled-icon"></div>
            <h3>{t('fulfilled_accordance')}</h3>
            <div className="fullfilled-info">
              <p className="subtitule">{t('new_text_deleted')}</p>
              <p className="subtitule-two">{t('new_question_deleted')}</p>
            </div>
          </div>
          <div className="horizontal">
            <UndoButton handleClick={() => setDeleteModal(false)} />
            <SaveButton
              handleClick={() => {
                deleteAcc(catchId);
                setDeleteModal(false);
              }}
              customStyle={{ padding: '0px 44px' }}
              isAccordance={true}
            />
          </div>
        </div>
      </BaseModal>
    </>
  );
}
