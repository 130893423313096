import React, { createContext, useState, useCallback, useEffect, useMemo } from 'react';
import useFilters from '../hooks/useFilters';
import { useDispatch } from 'react-redux';

type TagFilterOption = {
  id: number;
  label: string;
  value: string;
  categoryID?: number;
  selectedByUser?: boolean;
};

type FlatTagFilterOption = TagFilterOption & { category: string };

type OptionSelectedType = {
  optionsBySelect: Record<string, TagFilterOption[] | TagFilterOption>;
  flattenedOptions: FlatTagFilterOption[];
  setTagOptions: (selectId: string, options: TagFilterOption[] | TagFilterOption) => void;
  labels: string[];
  deleteTagFromContext: any;
  setLabels?: any;
  setFlattenedOptions?: any;
};

export const TagFilterContext = createContext<OptionSelectedType>({
  optionsBySelect: {},
  flattenedOptions: [],
  setTagOptions: () => {},
  labels: [],
  deleteTagFromContext: () => {},
});

export const TagFilterProvider: React.FC = ({ children }) => {
  const [optionsBySelect, setOptionsBySelect] = useState<Record<string, TagFilterOption[] | TagFilterOption>>({});
  const [flattenedOptions, setFlattenedOptions] = useState<FlatTagFilterOption[]>([]);
  const [labels, setLabels] = useState<string[]>([]);

  const {
    filter,
    filterIndicators,
    filterAnalytics,
    setFilter,
    setFilterIndicators,
    setFilterAnalytics,
    filterEarlyWarnings,
    setFilterEarlyWarnings,
  } = useFilters();

  const dispatch = useDispatch();

  const flattenOptions = useCallback(
    (options: Record<string, TagFilterOption[] | TagFilterOption>): FlatTagFilterOption[] => {
      const flatOptions: FlatTagFilterOption[] = [];
      Object.entries(options).forEach(([category, optionsEntry]) => {
        if (Array.isArray(optionsEntry)) {
          optionsEntry.forEach(option => flatOptions.push({ ...option, category }));
        } else if (optionsEntry && typeof optionsEntry === 'object') {
          flatOptions.push({ ...optionsEntry, category });
        }
      });
      return flatOptions;
    },
    [],
  );

  useEffect(() => {
    setFlattenedOptions(flattenOptions(optionsBySelect));
  }, [optionsBySelect, flattenOptions]);

  const setTagOptions = useCallback((selectId: string, newOptions: TagFilterOption[] | TagFilterOption) => {
    setOptionsBySelect(prevOptions => ({
      ...prevOptions,
      [selectId]: newOptions,
    }));
  }, []);

  const deleteTagFromContext = useCallback(tagToDelete => {
    setOptionsBySelect(prevOptions => {
      const newOptions = { ...prevOptions };
      Object.keys(newOptions).forEach(selectId => {
        const options = newOptions[selectId];
        if (Array.isArray(options)) {
          newOptions[selectId] = options.filter(option => option.label !== tagToDelete);
        } else if (options && typeof options === 'object' && options.label === tagToDelete) {
          delete newOptions[selectId];
        }
      });

      return newOptions;
    });

    setFlattenedOptions(prevOptions => prevOptions.filter(option => option.label !== tagToDelete));
  }, []);

  useEffect(() => {
    setLabels(flattenedOptions.map(option => option.label));
  }, [flattenedOptions]);

  const value = useMemo(
    () => ({
      optionsBySelect,
      flattenedOptions,
      setTagOptions,
      deleteTagFromContext,
      labels,
      setLabels,
      setFlattenedOptions,
    }),
    [optionsBySelect, flattenedOptions, setTagOptions, labels, deleteTagFromContext, setLabels, setFlattenedOptions],
  );

  return <TagFilterContext.Provider value={value}>{children}</TagFilterContext.Provider>;
};
