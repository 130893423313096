import React from 'react';
import { useTranslation } from 'react-i18next';
import './SubmitButton.scss';
import { RequestStatus } from '../../../../models/RequestStatus';

interface IProps extends React.HTMLProps<JSX.Element> {
  style?: object;
  handleClick: () => void;
  buttonState: RequestStatus;
  customText?: any;
  disabled?: boolean;
}

const SubmitButton = ({ handleClick, style, buttonState, customText, disabled }: IProps) => {
  const { t } = useTranslation();

  switch (buttonState) {
    case RequestStatus.Error:
      return (
        <button className="btn-primary error" disabled={true}>
          <span>{t('error_on_save')}</span>
        </button>
      );

    case RequestStatus.Success:
      return (
        <button className="btn-primary success" disabled={true}>
          <span>{t('changes_saved')}</span>
        </button>
      );

    case RequestStatus.Loading:
      return (
        <button className="btn-primary loading" disabled={true}>
          <span>{t('saving')}...</span>
        </button>
      );

    default:
      return (
        <button
          style={style}
          className={`btn-primary ${disabled ? 'disabled' : ''}`}
          onClick={handleClick}
          disabled={buttonState !== RequestStatus.Default || disabled}
        >
          {<span>{customText ? customText : t('save_changes')}</span>}
        </button>
      );
  }
};

export default SubmitButton;
