import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

export interface IStatusTitle {
  icon: ReactElement<any, any>;
  title: string;
  color: string;
}

type Props = {
  statusTitle: IStatusTitle;
};

const StatusTitle = ({ statusTitle }: Props) => {
  const { icon, color, title } = statusTitle;
  const { t } = useTranslation();

  return (
    <div style={{ display: 'flex', margin: '0px 0px 16px 0px' }}>
      {icon}{' '}
      <h3 style={{ margin: '0px 0px 0px 12px', color  , fontFamily: 'Rubik', fontSize: 24, fontWeight: 400 }}>
        {t(title)}
      </h3>
    </div>
  );
};

export default StatusTitle;
