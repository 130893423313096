import React, { useState, useEffect } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LinksAndDocs from '../ProjectTabs/LinksAndDocs/LinksAndDocs';
import Financial from '../ProjectTabs/Financial/Financial';
import Risks from '../ProjectTabs/Risks/Risks';
import BaseInformation from '../BaseInformation/BaseInformation';
import Project from '../../../models/Project';
import AdvanceMobile from '../AdvanceMobile/AdvanceMobile';
import Calendar from '../Calendar/Calendar';
import AccordanceTrello from '../ProjectTabs/Accordances/AccordanceTrello/AccordanceTrello';
import advanceImg from '../../../assets/mobile_advance.svg';
import calendarImg from '../../../assets/mobile_calendar.svg';
import baseInfoImg from '../../../assets/mobile_base_info.svg';
import accordancesImg from '../../../assets/mobile_accordances.svg';
import supervisionImg from '../../../assets/mobile_supervision.svg';
import financialImg from '../../../assets/mobile_financial.svg';
import risksImg from '../../../assets/mobile_risks.svg';
import linksImg from '../../../assets/mobile_links_docs.svg';
import evolutionImg from '../../../assets/mobile_evolution.svg';
import Supervision from '../ProjectTabs/Supervision/Supervision';
import { ProjectProductTypes } from '../../../models/ProjectProductTypes';
import { ProjectExecutionTypes } from '../../../models/ProjectExecutionTypes';
import { ProjectProductTypeClassification } from '../../../models/ProjectProductTypeClassification';
import { ProjectSwitch } from '../ProjectSwitch/ProjectSwitch';
import { PerformancePMRCollapsed } from '../PerformancePMRCollapsed/PerformancePMRCollapsed';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    expanded: {
      backgroundColor: '#F2F3F7',
    },
    heading: {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightRegular,
      fontFamily: 'Rubik',
      color: '#727E8C',
    },
    panelRoot: {
      padding: 0,
    },

    summaryRoot: {
      minHeight: 59,
    },
    summaryRootSupervisionExpanded: {
      backgroundColor: '#F2F3F7',
    },
    panelRootSupervisionExpanded: {
      padding: 0,
      backgroundColor: '#F2F3F7',
    },
  }),
);

type Props = {
  currentProject: Project;
};

const getProjectAccordeons = currentProject => {
  const accordeonItems: any[] = [];
  const {
    projectProductType,
    execution,
    projectProductTypeClassification,
    disbursementsProjectionsEvolution,
    pmrEvolution,
  } = currentProject;

  const advanceAccordeon = {
    imgSrc: advanceImg,
    imgStyle: { marginRight: 10 },
    title: 'advance',
    component: <AdvanceMobile />,
  };

  const calendarAccordeon = {
    imgSrc: calendarImg,
    imgStyle: { marginRight: 10 },
    title: 'key_dates',
    component: <Calendar getData={true} />,
    noPadding: true,
  };
  const evolutionAccordeon = {
    imgSrc: evolutionImg,
    imgStyle: { marginRight: 10 },
    title: 'evolution',
    component: (
      <ProjectSwitch
        switchType="evolutionSwitchDetail"
        collapsedHeaderComponent={<PerformancePMRCollapsed data={pmrEvolution} />}
        disbursementsProjectionsEvolution={disbursementsProjectionsEvolution}
        hasDataHeader={pmrEvolution?.length !== 0}
      />
    ),
    noPadding: true,
  };
  const baseInfoAccordeon = {
    imgSrc: baseInfoImg,
    imgStyle: { marginRight: 10, marginBottom: -2 },
    title: 'base_information',
    component: <BaseInformation />,
  };

  const supervisionAccordeon = {
    imgSrc: supervisionImg,
    imgStyle: { marginRight: 10 },
    title: 'supervision',
    component: <Supervision projectCode={currentProject.code} projectId={currentProject.id} />,
    customSummaryExpanded: 'summaryRootSupervisionExpanded',
    noPadding: true,
  };

  const accordanceAccordeon = {
    imgSrc: accordancesImg,
    imgStyle: { marginRight: 10 },
    title: 'accordances',
    component: <AccordanceTrello />,
    noPadding: true,
  };

  const financialAccordeon = {
    imgSrc: financialImg,
    imgStyle: { marginRight: 10 },
    title: 'financial',
    component: <Financial />,
  };

  const riskAccordeon = {
    imgSrc: risksImg,
    imgStyle: { marginRight: 10, marginBottom: -2 },
    title: 'risk',
    component: <Risks />,
  };

  const linkAndDocsAccordeon = {
    imgSrc: linksImg,
    imgStyle: { marginRight: 10 },
    title: 'project_links',
    component: <LinksAndDocs />,
    noPadding: true,
  };

  if (projectProductType === ProjectProductTypes.GUARANTEE) {
    accordeonItems.push(advanceAccordeon, calendarAccordeon, baseInfoAccordeon, accordanceAccordeon, riskAccordeon);
  } else {
    if (projectProductType === ProjectProductTypes.CT) {
      if (execution === ProjectExecutionTypes.BID) {
        accordeonItems.push(
          advanceAccordeon,
          calendarAccordeon,
          baseInfoAccordeon,
          // productAccordeon,
          // managementAccordeon,
          accordanceAccordeon,
          financialAccordeon,
          linkAndDocsAccordeon,
        );
      } else {
        accordeonItems.push(
          advanceAccordeon,
          calendarAccordeon,
          baseInfoAccordeon,
          // productAccordeon,
          accordanceAccordeon,
          financialAccordeon,
        );
      }
    } else if (
      projectProductType === ProjectProductTypes.Loan ||
      (projectProductType === ProjectProductTypes.IG &&
        projectProductTypeClassification === ProjectProductTypeClassification.IGR_GREATER_THAN_3MM)
    ) {
      accordeonItems.push(
        advanceAccordeon,
        calendarAccordeon,
        evolutionAccordeon,
        baseInfoAccordeon,
        supervisionAccordeon,
        accordanceAccordeon,
        financialAccordeon,
        riskAccordeon,
        linkAndDocsAccordeon,
      );
    } else if (
      projectProductType === ProjectProductTypes.IG &&
      projectProductTypeClassification !== ProjectProductTypeClassification.IGR_GREATER_THAN_3MM
    ) {
      accordeonItems.push(
        advanceAccordeon,
        calendarAccordeon,
        baseInfoAccordeon,
        // productAccordeon,
        // managementAccordeon,
        accordanceAccordeon,
        financialAccordeon,
        linkAndDocsAccordeon,
      );
    }
  }

  accordeonItems.forEach((acc, index) => {
    acc.id = index;
  });

  return accordeonItems;
};

const Accordeon = ({ currentProject }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [accordeonItems, setAccordeonItems] = useState<any[]>([]);
  const [expanded, setExpanded] = useState('');
  const handleEnabled = title => {
    setExpanded(title === expanded ? '' : title);
  };

  useEffect(() => {
    setAccordeonItems(getProjectAccordeons(currentProject));
  }, [currentProject]);

  return (
    <div className={classes.root}>
      {accordeonItems.map((item, index) => {
        return item.title === 'project_links' ? null : (
          <ExpansionPanel key={item.title} expanded={expanded === item.title}>
            <ExpansionPanelSummary
              className={
                expanded === 'supervision' && item.customSummaryExpanded
                  ? classes.summaryRootSupervisionExpanded
                  : classes.summaryRoot
              }
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index + 1}a-content`}
              id={`panel${index + 1}a-header`}
              onClick={() => handleEnabled(item.title)}
            >
              <h2 className={classes.heading}>
                <img src={item.imgSrc} alt="" style={item.imgStyle} />
                {t(item.title)}
              </h2>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
              classes={
                item.noPadding
                  ? expanded === 'supervision'
                    ? { root: classes.panelRootSupervisionExpanded }
                    : { root: classes.panelRoot }
                  : {}
              }
            >
              {item.component}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      })}
    </div>
  );
};

export default Accordeon;
