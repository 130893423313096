import { makeStyles, Tab, Tabs } from '@material-ui/core';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedTab } from '../../redux/actions/menuTabs';
import { tabStyles } from '../Projects/ProjectsAll/ProjectsAll.Style';
import { AppMenuTabs, MenuTabs, MenuTabSectionEnum } from './userTabsInterface';
import { AddMenuTab } from './AddMenuTab/AddMenuTab';
import { SeparatorMenu } from './SeparetorMenu/SeparatorMenu';
import { TrackedActions } from '../../models/TrackedActionsEnum';
import { setFilterIndicators } from '../../redux/actions/filterIndicators';
import { trackActionClicked } from '../../api/trackActionClickedApi';
import { mockObjFilter } from '../../mockData/mockObjFilter';
import { TagFilterContext } from '../../context/TagFilterContext';
import './MenuTabsComponent.scss';

type Props = {
  menuTabSection: MenuTabSectionEnum;
  selectedTab: MenuTabs;
  handleAddTabButton?: () => void;
};

export const MenuTabsComponent = ({ menuTabSection, selectedTab, handleAddTabButton }: Props) => {
  tabStyles.root.backgroundColor = 'transparent';
  tabStyles.root['&$selected'].backgroundColor = 'transparent';
  const classes = makeStyles(tabStyles)();
  const tabClasses = { root: classes.root, selected: classes.selected };
  const { labels, setLabels, setFlattenedOptions, deleteTagFromContext } = useContext(TagFilterContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const maxNumberOfCustomTabs = 4;
  const isLoading = useSelector<any, any>(state => state.dataTableInfo.loading);
  const { userTabs } = useSelector<any, AppMenuTabs>(state => state.menuTabs);
  const roleBasedMenu: MenuTabs[] = userTabs.filter(m => m.tabId === 0);
  const customUserMenu: MenuTabs[] = userTabs.filter(m => m.tabId !== 0);

  const filterIndicators = useSelector<any, any>(state => state.filterIndicators);

  const handleSetMenuValue = (menuValue: MenuTabs) => {
    trackActionClicked(TrackedActions.menuTabClicked);
    if (menuValue.internalId === selectedTab.internalId) {
      return;
    }
    dispatch(updateSelectedTab(menuValue, menuTabSection));
    if (menuValue.tabId !== 0) {
      dispatch(
        setFilterIndicators({
          ...filterIndicators,
          common: {
            ...filterIndicators.common,
            sectors: [],
            divisionId: [],
            coReponsibleDivisions: [],
            participantDivisions: [],
            regionId: [],
            countryId: [],
            teamLeaders: [],
            interestIssues: [],
          },
        }),
      );
    }
    setRegionTrackOnMenuTab(
      menuValue,
      selectedTab,
      menuTabSection === MenuTabSectionEnum.PORTFOLIO_MENU_TAB
        ? TrackedActions.regionFilterChangedFromPortfolio
        : TrackedActions.regionFilterChangedFromProject,
    );
  };

  const clearFilterTag = () => {
    labels.forEach(label => deleteTagFromContext(label));

    setLabels([]);
    setFlattenedOptions([]);
    dispatch(
      setFilterIndicators({
        ...mockObjFilter.common,
        common: {
          ...mockObjFilter.common,
        },
      }),
    );
  };

  return (
    <>
      <Tabs
        value={selectedTab.internalId || 0}
        classes={{ indicator: classes.indicatorColor, scrollButtons: classes.scrollButtons }}
        variant="scrollable"
        aria-label="full width tabs example"
        scrollButtons="on"
      >
        {roleBasedMenu.map((menu: MenuTabs, i: number) => (
          <Tab
            key={menu.internalId}
            value={menu.internalId}
            classes={tabClasses}
            label={t(menu.title)}
            onClick={() => {
              handleSetMenuValue(menu);
            }}
            disabled={isLoading}
            tabIndex={20 + i}
            className="menu-tabs__tab"
          />
        ))}
        <SeparatorMenu menuTabSection={menuTabSection} />
        {customUserMenu.map((menu: MenuTabs) => (
          <Tab
            key={menu.internalId}
            value={menu.internalId}
            classes={tabClasses}
            label={sliceText(menu.title)}
            onClick={() => {
              handleSetMenuValue(menu);
              clearFilterTag();
            }}
            tabIndex={30}
            disabled={isLoading}
            className="menu-tabs__tab"
          />
        ))}
      </Tabs>
      {menuTabSection === MenuTabSectionEnum.PORTFOLIO_MENU_TAB && (
        <AddMenuTab
          disable={customUserMenu.length >= maxNumberOfCustomTabs}
          handleButtonClick={handleAddTabButton ? handleAddTabButton : () => { }}
        />
      )}
    </>
  );
};

const sliceText = (text: string) => {
  return text.length >= 10 ? `${text.slice(0, 10)}...` : text;
};

const setRegionTrackOnMenuTab = (
  userApplyedTabValueSelected: MenuTabs,
  reducerTabValueSelected: MenuTabs,
  trackedAction: TrackedActions,
) => {
  if (
    !userApplyedTabValueSelected.assignedToMe &&
    userApplyedTabValueSelected.internalId !== '0_global_bid' &&
    reducerTabValueSelected !== userApplyedTabValueSelected &&
    userApplyedTabValueSelected.tabId === 0 &&
    userApplyedTabValueSelected.filters.length === 0 &&
    userApplyedTabValueSelected.regions.length > 0
  ) {
    trackActionClicked(trackedAction);
  }
};
