import { moduleButtonsInitialState } from '../../models/EarlyWarningsSection';
import FilterEarlyWarnings from '../../models/FilterEarlyWarnings';
import {
  FilterEarlyWarningsActionTypes,
  SET_FILTER_EARLY_WARNINGS,
  SET_FILTER_EARLY_WARNINGS_BUTTONS_MODULES,
} from '../actions/types';
import { startedFilter } from './filterObject';

const commonFilter = JSON.parse(JSON.stringify(startedFilter));
const initialState: FilterEarlyWarnings = {
  common: { ...commonFilter },
  buttonsModule: moduleButtonsInitialState,
};

const filterEarlyWarningsReducer = (
  state = initialState,
  action: FilterEarlyWarningsActionTypes,
): FilterEarlyWarnings => {
  switch (action.type) {
    case SET_FILTER_EARLY_WARNINGS:
      return action.payload;
    case SET_FILTER_EARLY_WARNINGS_BUTTONS_MODULES:
      return { ...state, buttonsModule: action.payload };
    default:
      return state;
  }
};
export default filterEarlyWarningsReducer;
