
import React from "react";
import { useFormMemoryHelpContext } from "../../../context/MemoryHelpFormContext";
import { useTranslation } from "react-i18next";
import { DynamicInputSetProps, isCrossCuttingIssues } from "../models";


const InputSet: React.FC<DynamicInputSetProps> = ({ id, onRemove }) => {
    const { t } = useTranslation();
    const { formState, onTabClickDynamicInputs, errors } = useFormMemoryHelpContext();

    let project = { id: 0, title: '', description: '', agreement: '', status: false };
    let disableDeleteBtn;

    const section = formState.find(isCrossCuttingIssues);
    if (section) {
        const foundProject = section.projectDescription.find(p => p.id === id);
        disableDeleteBtn = section?.projectDescription.length || undefined;
        if (foundProject) project = foundProject;
    }

    return (
        <ul>
            <div className="delete-button">
                <button className="btn btn-primary-text" disabled={disableDeleteBtn < 2} onClick={() => onRemove(id)}>{t('memory_help.container_tabs.3.delete_btn')}</button>
            </div>
            <li>
                <span>{t('memory_help.container_tabs.3.p_3_1.p1.title')}</span>
                <input
                    className={errors[`title-${id}`] ? 'warning-message' : ''}
                    type="text"
                    name={`title-${id}`}
                    placeholder="Title"
                    value={project.title}
                    onChange={(e) => onTabClickDynamicInputs(e, project.id)}
                />
                {errors[`title-${id}`] && <span className="warning-message">{errors[`title-${id}`]}</span>}
            </li>
            <li>
                <span>{t('memory_help.container_tabs.3.p_3_1.p2.title')}</span>
                <input
                    className={errors[`description-${id}`] ? 'warning-message' : ''}
                    type="text"
                    name={`description-${id}`}
                    placeholder="Description"
                    value={project.description}
                    onChange={(e) => onTabClickDynamicInputs(e, project.id)}
                />
                {errors[`description-${id}`] && <span className="warning-message">{errors[`description-${id}`]}</span>}
            </li>
            <li>
                <span>{t('memory_help.container_tabs.3.p_3_1.p3.title')}</span>
                <input
                    className={errors[`agreement-${id}`] ? 'warning-message' : ''}
                    type="text"
                    name={`agreement-${id}`}
                    placeholder="Agreement"
                    value={project.agreement}
                    onChange={(e) => onTabClickDynamicInputs(e, project.id)}
                />
                {errors[`agreement-${id}`] && <span className="warning-message">{errors[`agreement-${id}`]}</span>}
            </li>
        </ul>
    );
};

const DynamicInputField: React.FC = () => {
    const { t } = useTranslation();
    const { formState, setFormState, generateUniqueId, setErrors } = useFormMemoryHelpContext();

    // Find the section of type 'transversalIssues'
    const affairsTransversalSection = formState.find(isCrossCuttingIssues);

    const addInputSet = () => {
        const newId = generateUniqueId();
        const newState = formState.map(section => {
            if (section.type === 'transversalIssues') {
                // Add a new project with a unique ID and empty fields
                const newProject = { id: newId, title: '', description: '', agreement: '', status: false };
                // Update the section to include the new project, marking the status as false
                return { ...section, projectDescription: [...section.projectDescription, newProject], status: false };
            }
            return section;
        });

        setFormState(newState);
    };

    const removeInputSet = (idToRemove) => {
        const newState = formState.map(section => {
            if (section.type === 'transversalIssues') {
                // Filter the projects to remove the one specified by idToRemove
                const updatedProjectDescription = section.projectDescription.filter(project => project.id !== idToRemove);
                // Determine new status based on validity of remaining projects
                const newStatus = updatedProjectDescription.every(project => project.status);
                return { ...section, projectDescription: updatedProjectDescription, status: newStatus };
            }
            return section;
        });

        setFormState(newState);

        // Eliminate errors associated with idToRemove
        setErrors(prevErrors => {
            const updatedErrors = { ...prevErrors };
            Object.keys(updatedErrors).forEach(key => {
                if (key.includes(`-${idToRemove}`)) delete updatedErrors[key]; // If the key contains the idToRemove, it is removed from errors
            });
            return updatedErrors;
        });
    };

    return (
        <>
            {affairsTransversalSection && 'projectDescription' in affairsTransversalSection &&
                affairsTransversalSection.projectDescription.map(project => (
                    <InputSet key={project.id} id={project.id} onRemove={removeInputSet} />
                ))
            }
            <button className="btn btn-primary" onClick={addInputSet}>{t('memory_help.container_tabs.3.add_btn')}</button>
        </>
    );
};

export default DynamicInputField;
