export const validateFieldsCorrectly = ({
  formFields,
  setMessageError,
  setSwitchError,
  t,
}) => {
  const { emailNotification, appNotification, message } = formFields;
  message === '' ? setMessageError(t('required_data')) : setMessageError('');
  !emailNotification && !appNotification
    ? setSwitchError(t('at_least_one_notification_option_must_be_selected'))
    : setSwitchError('');
    
  if (message && (emailNotification || appNotification)) {
    return true;
  } else {
    return false;
  }
};

export const getOptionsIds = (optionsSelected: any[], optionsList: any[]): number[] => {
  return optionsSelected.length === 0 ? optionsList.map(o => o.id) : optionsSelected?.map(o => o.id);
};
