import React, { useState } from 'react';
import { styles as modalStyles } from '../../../../Util/Modals/Styles';
import { SupervisionPlanActivity } from '../../../../ProjectDetail/ProjectTabs/Supervision/util';
import { SupervisionDetailForm } from './SupervisionDetailForm';
import BaseModal from '../../../../Util/Modals/BaseModal';
import styles from './SupervisionModal.module.scss';
type Props = {
  modalIsOpen: boolean;
  openModal: Function;
  closeModal: Function;
  activity: SupervisionPlanActivity;
  currentProjectId: number;
  editFromPopover?: boolean;
};

const SupervisionModal = ({ modalIsOpen, closeModal, activity, currentProjectId, editFromPopover }: Props) => {
  const [isEditState, setIsEditState] = useState(editFromPopover);
  const { supervisionPlanModalContent, supervisionPlanModalEditContent } = styles;

  return (
    <BaseModal
      isOpen={modalIsOpen}
      style={{ overlay: { ...modalStyles.overlay, ...{ overflowY: 'auto' } } }}
      className={!isEditState ? supervisionPlanModalContent : supervisionPlanModalEditContent}
    >
      <SupervisionDetailForm
        activity={activity}
        modalEditStateUpdate={setIsEditState}
        closeModal={(refreshData: boolean) => closeModal(refreshData)}
        showCloseButton={true}
        currentProjectId={currentProjectId}
        editFromPopover={editFromPopover}
      />
    </BaseModal>
  );
};

export default SupervisionModal;
