import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowDown } from '../../../../../assets/accordion_arrow_down.svg';
import { ReactComponent as ArrowUp } from '../../../../../assets/accordion_arrow_up.svg';

import styles from './SupervisionAccordion.module.scss';

import { IndicatorPlannedVsReal } from '../IndicatorPlannedVsReal/IndicatorPlannedVsReal';
import { IndicatorTransactionalSupervisionResources } from '../IndicatorTransactionalSupervisionResources/IndicatorTransactionalSupervisionResources';
import { getIndicatorsFromProjectDetail } from '../../../../../api/indicatorApi';
import { IndicatorKeyEnum } from '../../../../../models/IndicatorKeyEnum';

export interface ISupervisionPlanMetric {
  groupingName: string;
  groupingValue: string;
  metricName: string;
  metricValue: number;
  metricLabel: string;
}

const emptySupervisionPlantMetricObject = {
  groupingName: '',
  groupingValue: '',
  metricName: '',
  metricValue: 0,
  metricLabel: '',
};
type Props = {
  projectId: number;
};

const getIndicatorMetrics = (indicatorsData, indicatorKey) => {
  const foundIndicator = indicatorsData.indicatorsData.find(m => m.key === indicatorKey);
  return foundIndicator ? foundIndicator.metrics : [];
};

const SupervisionAccordion = ({ projectId }: Props) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState<boolean>(true);
  const [supervisionPlanIndicatorState, setSupervisionPlanIndicatorState] = useState<ISupervisionPlanMetric[]>([
    emptySupervisionPlantMetricObject,
  ]);
  const [plannedVsRealState, setPlannedVsRealState] = useState<any>([]);

  const getSupervisionIndicatorData = async () => {
    const indicatorsData = await getIndicatorsFromProjectDetail(projectId);
    setSupervisionPlanIndicatorState(
      getIndicatorMetrics(indicatorsData, IndicatorKeyEnum.SUPERVISION_PLAN_BUDGET_STATUS),
    );
    setPlannedVsRealState(
      getIndicatorMetrics(indicatorsData, IndicatorKeyEnum.SUPERVISION_PLAN_PLANNED_VERSUS_EXECUTED),
    );
  };

  useEffect(() => {
    getSupervisionIndicatorData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.accordionContainer}>
      <div className={expanded ? styles.accordionExpanded : styles.accordionUnexpanded}>
        <div className={styles.indicatorContainer}>
          <IndicatorPlannedVsReal data={plannedVsRealState} />
          <IndicatorTransactionalSupervisionResources data={supervisionPlanIndicatorState} />
        </div>
      </div>
      <div className={styles.accordionSummary}>
        <div
          className={styles.summaryControls}
          style={expanded ? { marginTop: 40 } : {}}
          onClick={() => setExpanded(!expanded)}
        >
          <span className={styles.summaryTitle}>
            {expanded ? t('supervision_accordion_hide_indicators') : t('supervision_accordion_show_indicators')}
          </span>
          {expanded ? <ArrowUp /> : <ArrowDown />}
        </div>
      </div>
    </div>
  );
};

export default SupervisionAccordion;
