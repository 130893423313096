import React from 'react';
import './Home.scss';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../components/Header/Header';
import { Sidetabs } from '../../components/Sidetabs/Sidetabs';
import { isMobile } from '../../services/booleanUtil';
import { useHistory } from 'react-router-dom';
import { getUser } from '../../redux/actions/user';
import User from '../../models/User';
import queryString from 'query-string';
import { setTabValue } from '../../redux/actions/tabValue';
import { tabIndexes } from '../../models/TabIndexes';
import { useIsAuthenticated } from '@azure/msal-react';
import { getMaintenanceService } from '../../api/contentManager';
import moment from 'moment';

const Home = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector<any, User>(state => state.user);
  const isAuthenticated = useIsAuthenticated();

  React.useEffect(() => {
    dispatch(getUser());
    checkGoToRevisionTab(history, dispatch, isAuthenticated);
    if (sessionStorage.getItem('redirect')) {
      history.push(sessionStorage.getItem('redirect') || '/home');
      sessionStorage.removeItem('redirect');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    checkMaintenance(user.hasAccessToContentManagement, history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return isMobile() ? (
    user.id && user.userId ? (
      <div>
        <Header />
        <Sidetabs />
      </div>
    ) : null
  ) : (
    <React.Fragment>
      <div className="content-home">
        <div className="header">
          <Header />
        </div>
      </div>
      {user.id && user.userId ? (
        <div>
          <Sidetabs />
        </div>
      ) : null}
    </React.Fragment>
  );
};

export { Home };

const checkGoToRevisionTab = (history, dispatch, isAuthenticated) => {
  const queryStrings = queryString.parse(history.location.search);
  if (localStorage.getItem('revisiontab') && isAuthenticated) {
    dispatch(setTabValue(tabIndexes.reviews));
    localStorage.removeItem('revisiontab');
  } else {
    if (queryStrings.revision) {
      if (isAuthenticated) {
        dispatch(setTabValue(tabIndexes.reviews));
      } else {
        localStorage.setItem('revisiontab', 'true');
      }
    }
  }
};

const checkMaintenance = async (hasAccessToCm = false, history: any) => {
  if (hasAccessToCm) {
    const { startDate, endDate } = await getMaintenanceService();
    if (startDate && endDate && moment(new Date()).isBetween(startDate, endDate, 'minutes', '[]')) {
      history.push('/contentmanager');
    }
  }
};
