import React, { useEffect, useState } from 'react';
import styles from './WorkWithRadialToBarchart.module.scss';
import { PortfolioSimpleIndicatorTable } from '../PortfolioSimpleIndicatorTable/PortfolioSimpleIndicatorTable';
import { ProjectCounter } from '../ProjectCounter/ProjectCounter';
import { IndicatorKeyEnum } from '../../../models/IndicatorKeyEnum';
import { getProjectsByFundingSource } from '../../../redux/actions/indicatorProjects';
import { useDispatch, useSelector } from 'react-redux';
import Indicator from '../../../models/Indicator';
import { handleLoading } from '../../utils';

export const WorkWithRadialToBarchart = () => {
  const currentFilter = localStorage.getItem('transactionalSupervisionResourcesFilter');
  const { id } = useSelector<any, Indicator>(state => state.currentIndicator);
  const d = useDispatch();
  const projects = useSelector<any, any>(state => state.indicatorProjects);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getFilteredProjects(d, id, currentFilter, setLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilter]);

  return (
    <div className={styles.workWithRadialToBarchartContainer}>
      <PortfolioSimpleIndicatorTable
        projects={projects}
        tableInfo={tableFormat}
        indicatorKey={IndicatorKeyEnum.SUPERVISION_PLAN_BUDGET_STATUS}
        loading={loading}
      />
      <ProjectCounter
        arrayToCount={projects}
        countProjects={true}
        indicatorKey={IndicatorKeyEnum.SUPERVISION_PLAN_BUDGET_STATUS}
      />
    </div>
  );
};

const tableFormat = [
  {
    tableHeader: 'spent_usd',
    rootProperty: 'field1',
    fieldType: 'money',
    customWidth: { minWidth: '140px' },
  },
  {
    tableHeader: 'committed_supervision_usd',
    rootProperty: 'field2',
    fieldType: 'money',
    customWidth: { minWidth: '140px' },
  },
  {
    tableHeader: 'balance',
    rootProperty: 'field4',
    fieldType: 'money',
    customWidth: { minWidth: '140px' },
  },
];

const getFilteredProjects = (d, currentIndicatorId, fundingSource, setLoading) => {
  return handleLoading(() => d(getProjectsByFundingSource(fundingSource, currentIndicatorId)), setLoading);
};
