import React, { useContext, useEffect, useState } from 'react';

import './AccordanceDetailInfo.scss';
import { useTranslation } from 'react-i18next';
import { AccordanceType } from '../../../../../models/AccordanceType';
import { setLastCommentRead } from '../../../../../api/accordanceApi';
import { isMobile } from '../../../../../services/booleanUtil';
import CustomSelect from '../../../../Util/CustomSelect/CustomSelect';
import SelectOption from '../../../../../models/SelectOption';
import { AccordanceState } from '../../../../../models/AccordanceState';
import { setDefaultValues } from '../AccordanceCard/AccordanceCard';
import AccordanceInfoItem from './AccordanceInfoItem';
import SupervisionActionAndComments from './SupervisionActionAndComments';
import RelatedAccordances from './RelatedAccordances';

//Added
import DatePicker from 'react-datepicker';
import moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import SearchUsers from '../../../../Util/SearchUsers/SearchUsers';
import User from '../../../../../models/User';

import { replaceMomentFormat } from '../../../../../services/util';
import ChronologyAccordance from '../../../../Util/Modals/AccordanceDetail/ChronologyAccordance';

import AddEvent from './AddEvent';
import { showEditButton } from '../../../../Util/Modals/AccordanceDetail/AccordanceDetail';
import { deleteEvent } from '../AccordanceTrello/util';
import { useEventContext } from '../../../../../context/EventContext';

type Props = {
  accordance: any;
  changeStatus: Function;
  fromRevision?: boolean;
  onNewValue: Function;
  onNewExpirationDateValue: Function;
  OnNewResponsibleValue: Function;
  close: Function;
  newValue: any;
  submitAccordance: any;
};

const accordanceStateNames = {
  0: 'accordance_draft',
  1: 'pending_accordance_singular',
  2: 'active_accordance_singular',
  3: 'accomplished_accordance_singular',
  4: 'cancelled_accordance_singular',
};

const AccordanceDetailInfo = (props: Props) => {
  const { t } = useTranslation();

  const {
    accordance,
    changeStatus,
    fromRevision,
    onNewValue,
    onNewExpirationDateValue,
    OnNewResponsibleValue,
    close,
    newValue,
    submitAccordance,
  } = props;

  const {
    accordanceState,
    personInCharge,
    accordanceType,
    id,
    description,
    criticalIssueDescription,
    newExpirationDate,
  } = accordance;

  // const dataEventContext: any = useContext(eventContext);
  // const { events, validity, getDataEvent } = dataEventContext;

  const { events, validity, getDataEvent } = useEventContext();

  const [showDatepicker, setShowDatepicker] = useState(false);
  const [showLabel, setShowLabel] = useState(true);
  const [showLabelNewValue, setshowLabelNewValue] = useState(true);
  const [isHovering, setIsHovering] = useState(false);
  const [isHoveringDate, setIsHoveringDate] = useState(false);
  const [isHoveringNewDate, setIsHoveringNewDate] = useState(false);
  const [isHoveringContainerNewDate, setIsHoveringContainerNewDate] = useState(false);
  const [newExpirationDateValue, setNewExpirationDateValue] = useState(newExpirationDate);
  const [isHoveringResponsible, setIsHoveringResponsible] = useState(false);
  const [showSearchResponsile, setShowSearchResponsible] = useState(false);
  const [newResponsible, setNewResponsible] = useState<User>();

  const currentDate = new Date();
  const [selected, setSelected] = useState<any>(new Date());
  let responsible: any;

  const dateValidity = validity?.filter(date => date.subsection === 'DateCurrent');
  const dateCompleted = validity?.filter(date => date.subsection === 'DateCompleted');
  const dateCancel = validity?.filter(date => date.subsection === 'DateCancel');

  const onChange = date => {
    setSelected(date);
    setNewExpirationDateValue(date);
    setShowDatepicker(!showDatepicker);
    setshowLabelNewValue(true);

    if (
      accordanceInfo.newExpirationDate.dateFormatedDetails !== null &&
      accordanceInfo.newExpirationDate.dateFormatedDetails !== undefined
    ) {
      setShowLabel(false);
    } else {
      setShowLabel(true);
    }

    onNewValue(true);
    onNewExpirationDateValue(moment(date).format('YYYY-MM-DD'));
  };

  const handleMouseEnter = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  const handleMousedate = () => {
    setIsHoveringDate(true);
  };
  const handleMouseLeaveDate = () => {
    setIsHoveringDate(false);
  };
  const handleMouseNewdate = () => {
    setIsHoveringNewDate(true);
  };
  const handleMouseLeaveNewDate = () => {
    setIsHoveringNewDate(false);
  };
  const handleMouseContainerNewdate = () => {
    setIsHoveringContainerNewDate(true);
  };
  const handleMouseLeaveContainerNewDate = () => {
    setIsHoveringContainerNewDate(false);
  };
  const handleMouseResponsible = () => {
    setIsHoveringResponsible(true);
  };
  const handleMouseLeaveResponsible = () => {
    setIsHoveringResponsible(false);
  };

  const handleShowNewExpirationDateLabel = () => {
    if (
      accordanceInfo.newExpirationDate.dateFormatedDetails !== null &&
      accordanceInfo.newExpirationDate.dateFormatedDetails !== undefined
    ) {
      setShowLabel(true);
    } else {
      setShowLabel(false);
    }
    setshowLabelNewValue(false);
    onNewValue(false);
  };

  const haldleShowDatePicker = () => {
    setShowDatepicker(!showDatepicker);
    setShowLabel(false);
    setshowLabelNewValue(false);
  };

  const haldleShowSearResponsible = () => {
    setShowSearchResponsible(true);
  };

  const handleIemSelected = (selected: User) => {
    setShowSearchResponsible(false);
    setNewResponsible(selected);
    OnNewResponsibleValue(selected);
  };

  const statusList = [
    { id: 3, value: '3', label: t('accomplished_accordance_singular') },
    { id: 4, value: '4', label: t('cancelled_accordance_singular') },
  ];

  const handleStatusChange = (item: SelectOption) => {
    changeStatus(item.id);
  };

  const dateColor = () => {
    let currentDate = new Date();
    let dateToExpire = accordance.newExpirationDate !== null ? accordance.newExpirationDate : accordance.expirationDate;
    let diference = moment(new Date(dateToExpire)).diff(currentDate, 'days');
    if (new Date(dateToExpire) < currentDate) {
      return '#FF0000';
    } else if (diference <= 7) {
      return '#000000';
    } else {
      return '#727e8c';
    }
  };

  const deletedEvent = async id => {
    await deleteEvent(id);
    await getDataEvent();
  };

  React.useEffect(() => {
    setLastCommentRead(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const accordanceInfo = setDefaultValues(accordance, t);

  return isMobile() ? (
    <div className="accordance-detail-info-container">
      <div className="horizontal-row">
        {accordanceState === AccordanceState.VALID && !fromRevision ? (
          <CustomSelect
            options={statusList}
            handleSelected={handleStatusChange}
            style={setCustomSelectStyle(accordanceType)}
            placeholder={t('active_accordance_singular')}
          />
        ) : (
          <AccordanceInfoItem label={t('accordance_status')} data={t(accordanceStateNames[accordanceState])} />
        )}
      </div>
      <div className="horizontal-row">
        <AccordanceInfoItem label={t('critical_issue')} data={accordanceInfo.criticalIssue.name} />
      </div>
      <div className="horizontal-row">
        <AccordanceInfoItem
          label={t('description_of_the_critical_issue')}
          data={criticalIssueDescription || '-'}
          fullWidth
        />
      </div>
      {/* <div className="horizontal-row">
        <AccordanceInfoItem
          label={setLabelAccordanceOrAction(accordanceType, t)}
          data={accordanceInfo.possibleAction.name}
        />
      </div> */}
      <div className="horizontal-row">
        <AccordanceInfoItem label={t('due_date')} data={accordanceInfo.expirationDate.dateFormatedDetails} />
      </div>

      <div className="horizontal-row">
        {accordanceType === AccordanceType.Revision && (
          <AccordanceInfoItem label={t('revision_accordance')} data={accordanceInfo.revisionDate.dateFormated} />
        )}
      </div>
      <div className="horizontal-row">
        <AccordanceInfoItem label={t('category')} data={accordanceInfo.criticalIssue.categoryName} />
      </div>
      <div className="horizontal-row">
        <AccordanceInfoItem
          label={t('bid_responsible')}
          data={`${accordanceInfo.personInCharge.name} ${accordanceInfo.personInCharge.lastName}`}
        />
      </div>
      <div className="horizontal-row">
        <AccordanceInfoItem label={t('description')} data={description || '-'} fullWidth />
      </div>
      <SupervisionActionAndComments accordance={accordance} />
    </div>
  ) : (
    <div className="container-form">
      <div className="accordance-detail-info-container-left">
        <div className="horizontal-row title-horizontal">{t('new_general_information_of_the_agreement')}</div>
        {/* <div className="horizontal-row">
        <AccordanceInfoItem label={t('project')} data={accordanceInfo.project} />
      </div> */}
        <div className="horizontal-row division">
          <AccordanceInfoItem
            label={t('new_agreement_defined_in')}
            data={accordanceInfo.accordanceAgreementsType.name}
          />
          {accordanceState === AccordanceState.VALID ||
          accordanceState === AccordanceState.PENDING ||
          accordanceState === AccordanceState.FULLFILLED ||
          accordanceState === AccordanceState.CANCELLED ? (
            <div className="container-event">
              <p className={'label-event'}>{t('new_events_associated_with_this_agreement')}</p>
              {events?.length !== 0
                ? events?.map(data => {
                    return (
                      <div className={'container-label-event-list'}>
                        <p className={'label-event-list'}>{data?.accordanceAgreementsType.name}</p>
                        {accordanceState === AccordanceState.FULLFILLED ||
                        accordanceState === AccordanceState.CANCELLED ? null : (
                          <button onClick={() => deletedEvent(data.id)}>x</button>
                        )}
                      </div>
                    );
                  })
                : '-'}

              {accordanceState === AccordanceState.FULLFILLED ||
              accordanceState === AccordanceState.CANCELLED ? null : (
                <AddEvent
                  accordanceId={accordance.id}
                  creationDate={accordance.dateCreated}
                  expirationDate={accordance.expirationDate}
                />
              )}
            </div>
          ) : null}
        </div>
        <div className="horizontal-row">
          {/* <AccordanceInfoItem
          label={t('accordance_status')}
          data={t(accordanceStateNames[accordanceState])}
          renderdFont={dateColor()}
        /> */}
          <AccordanceInfoItem label={t('accordance_description')} data={accordance.description || '-'} />
        </div>
        {/* <div className="horizontal-row">

        {accordanceType === AccordanceType.Revision && (
          <AccordanceInfoItem label={t('revision_accordance')} data={accordanceInfo.revisionDate.dateFormated} />
        )}
        {accordanceType === AccordanceType.Monitoring && (
          <AccordanceInfoItem
            label={`${t('date')} ${t('of')} ${t('accordance')}`}
            data={accordanceInfo.accordanceDate.dateFormatedDetails}
          />
        )}
      </div> */}

        {/* <div className="horizontal-row">
        <AccordanceInfoItem label={t('critical_issue')} data={accordanceInfo.criticalIssue.name} />
        <AccordanceInfoItem label={t('category')} data={accordanceInfo.criticalIssue.categoryName} />
      </div> */}

        <div className="horizontal-row">
          <div className={`item-label-data`}>
            <div
              className={`new-data`}
              style={{ backgroundColor: isHovering ? '#F5F5F5' : '', color: isHovering ? 'white' : '' }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <span className="item-label">
                {accordance.newExpirationDate !== null ? t('original_expiration_date') : t('due_date')}
                {isHovering && accordance.newExpirationDate === null && (
                  <EditIcon
                    onClick={haldleShowDatePicker}
                    style={{ color: '#727e8c', width: '15px', height: '15px', paddingLeft: '12px' }}
                  />
                )}
              </span>
              <span
                className="item-data"
                style={{
                  backgroundColor: isHoveringDate ? '#E8E8E8' : '',
                }}
                onMouseEnter={handleMousedate}
                onMouseLeave={handleMouseLeaveDate}
              >
                {accordanceInfo.expirationDate.dateFormatedDetails}
              </span>
            </div>
          </div>

          {accordanceInfo.newExpirationDate.dateFormatedDetails !== null &&
          accordanceInfo.newExpirationDate.dateFormatedDetails !== undefined &&
          showLabel &&
          !showDatepicker ? (
            <div className={`item-label-data`}>
              <div
                className={`new-date`}
                style={{
                  backgroundColor: isHoveringContainerNewDate ? '#F5F5F5' : '',
                  color: isHoveringContainerNewDate ? 'white' : '',
                }}
                onMouseEnter={handleMouseContainerNewdate}
                onMouseLeave={handleMouseLeaveContainerNewDate}
              >
                <span className="item-label">
                  {t('rescheduled_due_date')}
                  {isHoveringContainerNewDate && accordance.newExpirationDate !== null ? (
                    <EditIcon
                      onClick={haldleShowDatePicker}
                      style={{ color: '#727e8c', width: '15px', height: '15px', paddingLeft: '12px' }}
                    />
                  ) : (
                    isHoveringContainerNewDate && (
                      <CloseIcon
                        onClick={handleShowNewExpirationDateLabel}
                        style={{ color: '#727e8c', width: '15px', height: '15px', paddingLeft: '18px' }}
                      ></CloseIcon>
                    )
                  )}
                </span>
                <span
                  className="item-data-count"
                  style={{ backgroundColor: isHoveringNewDate ? '#E8E8E8' : '', color: dateColor() }}
                  onMouseEnter={handleMouseNewdate}
                  onMouseLeave={handleMouseLeaveNewDate}
                >
                  {accordanceInfo.newExpirationDate.dateFormatedDetails}
                </span>
                <span
                  className="item-data"
                  style={{ backgroundColor: isHoveringNewDate ? '#E8E8E8' : '', fontSize: '10px' }}
                >
                  {`${t('re_scheduling')} ${accordanceInfo.countNewExpirationDate}`}
                </span>
              </div>
            </div>
          ) : (
            <>
              {newExpirationDateValue !== null && newExpirationDateValue !== undefined && showLabelNewValue && (
                <div className={`item-label-data`} style={{ paddingBottom: '4%' }}>
                  {!showDatepicker && (
                    <div
                      className={`new-date`}
                      style={{
                        backgroundColor: isHoveringContainerNewDate ? '#F5F5F5' : '',
                        color: isHoveringContainerNewDate ? 'white' : '',
                      }}
                      onMouseEnter={handleMouseContainerNewdate}
                      onMouseLeave={handleMouseLeaveContainerNewDate}
                    >
                      <span className="item-label">
                        {t('rescheduled_due_date')}
                        {isHoveringContainerNewDate && (
                          <CloseIcon
                            onClick={handleShowNewExpirationDateLabel}
                            style={{ color: '#727e8c', width: '15px', height: '15px', paddingLeft: '18px' }}
                          ></CloseIcon>
                        )}
                      </span>
                      <span
                        className="item-data-count"
                        style={{ backgroundColor: isHoveringNewDate ? '#E8E8E8' : '' }}
                        onMouseEnter={handleMouseNewdate}
                        onMouseLeave={handleMouseLeaveNewDate}
                      >
                        {replaceMomentFormat(moment(newExpirationDateValue).format('DD/MMM/YYYY'))}
                      </span>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
          {showDatepicker && (
            <>
              <div className="date-picker-container">
                <DatePicker
                  selected={selected}
                  onChange={onChange}
                  inline={true}
                  disabled={true}
                  formatWeekDay={nameOfDay => nameOfDay.substr(0, 1)}
                  minDate={currentDate}
                  style={{ container: { marginLeft: '30px' } }}
                />
              </div>
            </>
          )}
          {showEditButton(accordance, newValue, submitAccordance, t)}
        </div>
        {/* <div className="horizontal-row">
        <AccordanceInfoItem
          label={t('description_of_the_critical_issue')}
          data={criticalIssueDescription || '-'}
          fullWidth
        />
      </div> */}
        <div className="horizontal-row">
          {/* <AccordanceInfoItem
          label={setLabelAccordanceOrAction(accordanceType, t)}
          data={accordanceInfo.possibleAction.name}
        /> */}
          <>
            {!showSearchResponsile && (
              <div className={`item-label-data`}>
                <div
                  className={`new-data`}
                  style={{
                    backgroundColor: isHoveringResponsible ? '#F5F5F5' : '',
                    color: isHoveringResponsible ? 'white' : '',
                  }}
                  onMouseEnter={handleMouseResponsible}
                  onMouseLeave={handleMouseLeaveResponsible}
                >
                  <span className="item-label">
                    {t('responsible')}
                    {isHoveringResponsible && (
                      <EditIcon
                        onClick={haldleShowSearResponsible}
                        style={{ color: '#727e8c', width: '15px', height: '15px', paddingLeft: '12px' }}
                      />
                    )}
                  </span>
                  <span className="item-data">
                    {newResponsible
                      ? `${newResponsible.name} ${newResponsible.lastName}`
                      : personInCharge
                      ? `${personInCharge.name} ${personInCharge.lastName}`
                      : '-'}
                  </span>
                </div>
              </div>
            )}
            {showSearchResponsile && (
              <SearchUsers selected={responsible} itemSelected={handleIemSelected}></SearchUsers>
            )}
          </>
        </div>

        <div className="horizontal-row title-horizontal">{t('new_context_of_the_critical_issue_and_root_cause')}</div>

        <div className="horizontal-row">
          <AccordanceInfoItem label={t('critical_issue')} data={accordanceInfo.criticalIssue.name} />
        </div>

        {accordanceInfo?.linkedProductsAccordance.length !== 0 ? (
          <div className="container-product">
            <div className={`item-label-data`}>
              <span className="item-label">{t('new_linked_product')}</span>
            </div>
            {accordanceInfo?.linkedProductsAccordance.map(linked => (
              <div className={`item-label-data`}>
                <p className="item-data-detail">{linked.nameProduct}</p>
              </div>
            ))}
          </div>
        ) : null}

        <div className="horizontal-row">
          <AccordanceInfoItem
            label={t('new_root_cause_of_critical_issue')}
            data={accordanceInfo.rootCauseCategory?.name}
          />
        </div>
        <div className="horizontal-row">
          <AccordanceInfoItem
            label={t('description_of_the_critical_issue')}
            data={accordanceInfo.criticalIssueDescription}
          />
        </div>
        {/* <div className="horizontal-row">
        <AccordanceInfoItem label={t('new_agreement_classification')} data={accordance.possibleAction?.name} />
      </div> */}

        <SupervisionActionAndComments accordance={accordance} />
        <RelatedAccordances accordance={accordance} close={close} />
        {/* <div className="horizontal-row">
        <AccordanceInfoItem
          label={`${t('date')} ${t('of')} ${t('record')}`}
          data={accordanceInfo.creationDate.dateFormatedDetails}
        />
      </div> */}
      </div>
      <div className="accordance-detail-info-container-right">
        <ChronologyAccordance
          data={accordanceInfo}
          dataEvent={events}
          dataValidity={dateValidity}
          dataCompleted={dateCompleted}
          dataCancel={dateCancel}
          accordanceState={accordanceState}
        />
      </div>
    </div>
  );
};

export default AccordanceDetailInfo;

const setCustomSelectStyle = accordanceType => {
  return accordanceType === AccordanceType.Revision ? { width: 140, marginRight: 20 } : { width: 270 };
};

const setLabelAccordanceOrAction = (accordanceType, t) => {
  return accordanceType === AccordanceType.Revision ? t('accordance') : t('action');
};
