import React from 'react';
import './BaseInformation.scss';
import { GcmHighlighted } from '../../../models/GcmAlerts';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

interface IHighlightInfo {
  highlightNode: string | null;
  barColor: CSSProperties;
  highlightEnumReference: GcmHighlighted | null;
}

interface IDividerInfo {
  firstDivider: string;
}
const EMPTY_HIGHLIGHT_INFO: IHighlightInfo = {
  highlightNode: null,
  barColor: {},
  highlightEnumReference: null,
};

const DEFAULT_DIVIDER_INFO: IDividerInfo = { firstDivider: 'small-divider grey' };

type PropsDateNodeSegment = {
  nodeTitle: string;
  nodeSubtitle: string;
  highlightInfo?: IHighlightInfo;
  dividerInfo?: IDividerInfo;
  last?: boolean;
};
export const DateNodeSegment = ({
  nodeTitle,
  nodeSubtitle,
  highlightInfo = EMPTY_HIGHLIGHT_INFO,
  dividerInfo = DEFAULT_DIVIDER_INFO,
  last = false,
}: PropsDateNodeSegment) => {
  const { highlightNode, barColor, highlightEnumReference } = highlightInfo;
  const { firstDivider } = dividerInfo;
  return last ? (
    <>
      <li>
        <div
          className="node grey"
          style={highlightNode?.toString() === highlightEnumReference?.toString() ? barColor : {}}
        ></div>
        <span className="last-item">{nodeTitle}</span>
      </li>
      <li>
        <span className="last-role-text">{nodeSubtitle}</span>
      </li>
    </>
  ) : (
    <>
      <li>
        <div
          className="node grey"
          style={highlightNode?.toString() === highlightEnumReference?.toString() ? barColor : {}}
        ></div>
        <span className="role-text">{nodeTitle}</span>
      </li>
      <li>
        <div className={firstDivider}></div>
        <span className="date-text">{nodeSubtitle}</span>
      </li>
    </>
  );
};
