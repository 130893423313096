import { createStore, applyMiddleware, compose } from 'redux';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import rootReducer from './reducers/rootReducer';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { AppActions } from './actions';

export const history = createBrowserHistory();

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
  key: 'root-cartera-inteligente',
  storage,
  whitelist: ['user', 'projectReportSections', 'regions', 'portfolioReportsRequests'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer(history));

function configureStore() {
  return createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(thunk as ThunkMiddleware<AppState, AppActions>, routerMiddleware(history))),
  );
}

const store = configureStore();
const persistor = persistStore(store);

export { store, persistor };

export type AppState = ReturnType<typeof persistedReducer>;
