import React from 'react'
import { ReactComponent as IconWarning } from '../../../assets/icons/u_warning.svg';
import { ReactComponent as IconCheck } from '../../../assets/icons/u_check.svg';
import { useTranslation } from 'react-i18next';
import { useFormMemoryHelpContext } from '../../../context/MemoryHelpFormContext';

interface StateIconProps {
    status: boolean | null;
    tabIndex?: number;
}

const StateIcon = ({ status, tabIndex }: StateIconProps) => {
    const { t } = useTranslation();
    const { visitedTabs } = useFormMemoryHelpContext();

    if (tabIndex === undefined || !visitedTabs.includes(tabIndex)) return null;

    const iconMap = {
        true: <small className='icon-success ci-type-text-8'><IconCheck /></small>,
        false: <small className='icon-warning ci-type-text-8'><span className='pending-data'>{t('memory_help.label_pending_data')}</span> <IconWarning /></small>,
        null: null
    };

    return iconMap[`${status}`];
};

export default StateIcon;
