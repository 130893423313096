import React from 'react';
import styles from './ButtonPrevNext.module.scss';

type Props = {
  handleClick?: any;
  isPrev?: boolean;
  isDisabled?: boolean;
};

const { buttonPrevNextContainer, prevArrow, nextArrow, buttonIsDisabled } = styles;
const ButtonPrevNext = ({ handleClick, isPrev, isDisabled }: Props) => {
  return (
    <>
      <button
        className={isDisabled ? buttonIsDisabled : buttonPrevNextContainer}
        onClick={handleClick}
        disabled={isDisabled}
      >
        <span className={isPrev ? prevArrow : nextArrow}></span>
      </button>
    </>
  );
};

export default ButtonPrevNext;
