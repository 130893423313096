import {
  ReviewProjectsSelectedFiltersActionTypes,
  SET_REVIEW_SELECTED_FILTER,
  RESET_REVIEW_SELECTED_FILTER,
} from '../actions/types';

const initialState: any[] = [];

const reviewProjectsSelectedFiltersReducer = (
  state = initialState,
  action: ReviewProjectsSelectedFiltersActionTypes,
): any => {
  switch (action.type) {
    case SET_REVIEW_SELECTED_FILTER:
      if (state.find(f => f.id === action.payload.id)) {
        return state.filter(i => i.id !== action.payload.id);
      } else {
        return [...state, action.payload];
      }
    case RESET_REVIEW_SELECTED_FILTER:
      return [];
    default:
      return state;
  }
};
export default reviewProjectsSelectedFiltersReducer;
