import React, { useEffect, useState } from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './CalendarDetail.scss';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import CalendarDetailToolbar from './CalendarDetailToolbar/CalendarDetailToolbar';
import { getProjectsCalendarIndicator } from '../../../redux/actions/indicatorProjects';
import Indicator from '../../../models/Indicator';
import { useDispatch, useSelector } from 'react-redux';
import CustomEvent from '../../ProjectDetail/Calendar/CustomCalendar/CustomEvent/CustomEvent';
import { parseEventsForCalendar } from '../../ProjectDetail/Calendar/Calendar';
import CalendarFilters from '../../ProjectDetail/Calendar/CalendarFilters/CalendarFilters';
import { SearchRender } from '../../Util/CustomInput/SearchRender/SearchRender';
import { useTranslation } from 'react-i18next';
import { EventType } from '../../../models/EventType';
import { setCalendarEvents } from '../../../redux/actions/calendar';

type Props = {
  currentIndicator: Indicator;
  indicatorKey: string;
};
const localizer = momentLocalizer(moment);
export const CalendarDetail = ({ currentIndicator, indicatorKey }: Props) => {

  //This component includes validations to not display inactive CT's and Disbursements.

  const calendar = useSelector<any, any>(state => state.calendar);
  const user = useSelector<any, any>(state => state.user);
  const { filters, events } = calendar;

  const [eventOfMonthState, setEventOfMonthState] = useState<any[]>([]);
  const [searchTermState, setSearchTermState] = useState('');

  const { t } = useTranslation();

  const dispatch = useDispatch();
  useEffect(() => {
    getFilteredSimpleIndicators(dispatch, currentIndicator.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndicator.id, dispatch]);

  useEffect(() => {
    setEventOfMonthState(events);
    onFilterAndSearchChangeHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events]);

  useEffect(() => {
    onFilterAndSearchChangeHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    onFilterAndSearchChangeHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTermState]);

  const onChangeHandler = (event: any) => {
    setSearchTermState(event.target.value.toLowerCase());
  };
  const onFilterAndSearchChangeHandler = () => {
    setEventOfMonthState(filterAndSearchEvents(events, searchTermState, filters));
  };

  useEffect(() => {
    return () => {
      dispatch(setCalendarEvents([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="calendar-detail-container">
      <div className="left-container">
        <Calendar
          localizer={localizer}
          startAccessor={'start'}
          endAccessor={'end'}
          events={parseEventsForCalendar(eventOfMonthState)}
          defaultView="month"
          formats={{
            weekdayFormat: date => localizer.format(date, 'ddd').substr(0, 3),
          }}
          components={{ toolbar: CalendarDetailToolbar, event: CustomEvent }}
          culture={'en'}
          defaultDate={new Date()}
        />
      </div>

      <div className="right-container">
        <div className="search-input">
          <SearchRender placeholder="search_for_an_event_or_project" onChangeHandler={onChangeHandler} />
        </div>
        <div className="title">{t('to_view')}:</div>
        <CalendarFilters
          disbursements={filters.disbursements}
          acquisitions={filters.acquisitions}
          activities={filters.activities}
          accordancesMonitor={filters.accordancesMonitor}
          accordancesRevision={filters.accordancesRevision}
          supervisoryAction={filters.supervisoryAction}
          secretarialEvents={filters.secretarialEvents}
          lifecycle={filters.lifecycle}
          missions={filters.missions}
          interGroup={filters.interGroup}
          linkOutlook={user.syncCalendar}
          isIndicatorCalendar={true}
        />
      </div>
    </div>
  );
};

const getFilteredSimpleIndicators = async (d, currentIndicatorId) => {
  return d(getProjectsCalendarIndicator(currentIndicatorId));
};

const filterAndSearchEvents = (events, searchTermState, filters) => {
  return events.filter(ev => {
    return (
      ((ev.title && ev.title.toLowerCase().search(searchTermState) !== -1) ||
        ev.projectCode.toLowerCase().search(searchTermState) !== -1) &&
      filterEvents(ev, filters)
    );
  });
};

const filterEvents = (ev, filters) => {
  return activityFilters(ev, filters) || accordanceFilters(ev, filters);
};

const activityFilters = (ev, filters) =>
  (parseInt(ev.eventType) === EventType.Disbursement && filters.disbursements) ||
  (parseInt(ev.eventType) === EventType.Acquisition && filters.acquisitions) ||
  (parseInt(ev.eventType) === EventType.Activity && filters.activities) ||
  (parseInt(ev.eventType) === EventType.Mission && filters.missions) ||
  (parseInt(ev.eventType) === EventType.ExternalEvent && filters.secretarialEvents) ||
  (parseInt(ev.eventType) === EventType.Lifecycle && filters.lifecycle) ||
  (parseInt(ev.eventType) === EventType.SupervisoryAction && filters.supervisoryAction);

const accordanceFilters = (ev, filters) =>
  (parseInt(ev.eventType) === EventType.MonitoringAccordance && filters.accordancesMonitor) ||
  (parseInt(ev.eventType) === EventType.RevisionAccordance && filters.accordancesRevision) ||
  (parseInt(ev.eventType) === EventType.InterGroup && filters.interGroup);
