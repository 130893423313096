import { useState } from 'react'
import { getMemoryHelpFileRevision } from '../api/projectApi';
import { UseGetMemoryHelpFileReturn } from '../components/MemoryHelp/models';

const pdfMIME = 'application/pdf';
const wordMIME = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'

const useGetMemoryHelpFile = (agreementId: number | null, shouldFetchFileData?: boolean): UseGetMemoryHelpFileReturn => {
    const [fileRevision, setFileRevision] = useState<{ pdfBlob: Blob | null, wordBlob: Blob | null, pdfFile: string | null }>({ pdfBlob: null, wordBlob: null, pdfFile: null });
    const [loadingHelpFile, setLoadingHelpFile] = useState<boolean>(true);
    const [loadingHelpError, setLoadingHelpError] = useState<Error | null>(null);

    const base64ToBlob = (base64: string, contentType: string) => {
        const cleanBase64 = base64.replace(/^data:.+;base64,/, '');
        const binaryString = window.atob(cleanBase64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return new Blob([bytes], { type: contentType });
    };

    const fetchFiles = async () => {
        if (agreementId === null || !shouldFetchFileData) {
            setFileRevision({ pdfBlob: null, wordBlob: null, pdfFile: null });
            setLoadingHelpFile(false);
            setLoadingHelpError(null);
            return;
        }

        setLoadingHelpFile(true);
        try {
            const data: any = await getMemoryHelpFileRevision(agreementId);
            const pdfBlob = data?.filepdf ? base64ToBlob(data.filepdf, pdfMIME) : null;
            const wordBlob = data?.fileWord ? base64ToBlob(data.fileWord, wordMIME) : null;
            const pdfFile = data?.filepdf;
            setFileRevision({ pdfBlob, wordBlob, pdfFile });
            setLoadingHelpError(null);
        } catch (err) {
            console.log('Error al obtener los ficheros del acuerdo en memory help:', err);
            setFileRevision({ pdfBlob: null, wordBlob: null, pdfFile: null });
            setLoadingHelpError(err as Error);
        } finally {
            setLoadingHelpFile(false);
        }
    };

    const triggerFileFetch = () => {
        fetchFiles();
    };

    return { fileRevision, loadingHelpFile, loadingHelpError, triggerFileFetch };
};

export default useGetMemoryHelpFile;
