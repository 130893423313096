import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './EmptySupervisionResult.module.scss';
import { createSuggestedPlan } from '../../../api/reportApi';
import { SupervisionPlanMode } from '../../ProjectDetail/ProjectTabs/Supervision/util';

type Props = {
  projectCode: string;
  projectId: number;
  mode: SupervisionPlanMode;
};

const EmptySupervisionResult = ({ projectCode, projectId, mode }: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  const handleButtonClick = async () => {
    setLoading(true);
    try {
      await createSuggestedPlan(projectId);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const url = `https://convergence.iadb.org/Operation/${projectCode}`;

  return (
    <div>
      <span className={styles.emptySupervisionBackground} />
      <div className={styles.contentContainer}>
        {/* {mode === SupervisionPlanMode.SIMPLIFIED ? (
          <>
            <span className={styles.title}>{t(`supervision_empty_state_title_simplified_1`)}</span>
            <span className={styles.subtitleContainer}>
              <span className={styles.subtitle}>{t('supervision_empty_state_title_simplified_2')}</span>
            </span>
          </>
        ) : (
          <span className={styles.title}>{t(`supervision_empty_state_title`)}</span>
        )} */}
        <span className={styles.title}>{t(`supervision_empty_state_title`)}</span>
        {/* {mode === SupervisionPlanMode.SIMPLIFIED ? null : ( */}
        <>
          <span className={styles.subtitleContainer}>
            <span className={styles.subtitle}>{t('supervision_empty_state_subtitle')}</span>
            <a className={styles.link} href={url} target="_blank" rel="noopener noreferrer external">
              {t('supervision_empty_state_subtitle_link')}
            </a>
          </span>
          <button
            className={loading ? styles.buttonDisabled : styles.button}
            onClick={handleButtonClick}
            disabled={loading}
          >
            {loading ? t('loading') : t('supervision_empty_state_button_text')}
          </button>
        </>
        {/* )} */}
      </div>
    </div>
  );
};

export default EmptySupervisionResult;
