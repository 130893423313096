import React, { useEffect, useState } from 'react';
import ProgressBar from './ProgressBar';
import ComponentsAvanceMatriz from './ComponentsAvanceMatriz';
import divisionsReducer from '../../../../../../redux/reducers/divisions';
import { useTranslation } from 'react-i18next';

interface Props {
  avanceData: {
    acquisitionProcessBalancesCompenent: [];
  };
}

const AvanceProcessMatriz = ({ avanceData }: Props) => {
  const { t } = useTranslation();
  const { acquisitionProcessBalancesCompenent } = avanceData;
  const [dataBox, setDataBox] = useState<[]>();

  useEffect(() => {
    if (acquisitionProcessBalancesCompenent.length > 0) {
      setDataBox(acquisitionProcessBalancesCompenent);
    }
  }, []);

  return (
    <>
      <div className="avance-process">
        <div className="header-title">
          <h2>{t('last_advance_matriz_title')}</h2>
          <div className="porcent-text">
            <p>{t('last_cost_percetage_per_product')} : </p>
            <div className="point hired">
              <p>{t('last_contracted')}</p>
            </div>
            <div className="point pending">
              <p>{t('pending')}</p>
            </div>
            <div className="point">
              <p>{t('last_not_assigned_in_plan')}</p>
            </div>
          </div>
        </div>
        <div className="container-box">
          {dataBox ? (
            <ComponentsAvanceMatriz data={dataBox} />
          ) : (
            <div className="clean-data-container">
              <h3>{t('new_no_information_by_product')}</h3>
              <p>{t('new_the_project_does_not')}</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AvanceProcessMatriz;
