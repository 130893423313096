import { DivisionsActionTypes, SET_DIVISIONS } from '../actions/types';
import Division from '../../models/Division';

const initialState: Division[] = [];

const divisionsReducer = (state = initialState, action: DivisionsActionTypes): any => {
  switch (action.type) {
    case SET_DIVISIONS:
      return action.payload;
    default:
      return state;
  }
};
export default divisionsReducer;
