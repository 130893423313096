import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styles from './IndicatorsTextEditor.module.scss';
import { getTranslationsIndicator, updateIndicatorsTranslations } from '../../../../api/contentManager';
import { TranslationsIndicatorAccordionSummary } from '../../ContentManagerAccordion/ContentManagerAccordion';
import IndicatorsTranslations from '../IndicatorsTranslations/IndicatorsTranslations';
import { TranslationsContext } from '../context/TranslationsContext';
import ContentManagerLoader from '../../ContentManagerLoader/ContentManagerLoader';
import { contentsTabLoaderStyles } from '../../../Util/ContentManager/ContentManager';

const IndicatorsTextEditor = () => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const [indicatorsList, setIndicatorsList] = useState<any[]>([]);
  const { t } = useTranslation();
  const { state, dispatch } = useContext(TranslationsContext);

  const getData = async () => {
    const list = await getTranslationsIndicator();
    setIndicatorsList(list);
  };

  const submit = async () => {
    await updateIndicatorsTranslations([state.indicatorToUpdate]);
    const updatedTranslations = state.indicatorToUpdate.translations.map(item => {
      return { id: item.id, updatedRows: item.updatedRows };
    });
    const list = await getTranslationsIndicator();
    const updated = list.map(indicator => {
      indicator.translations = indicator.translations.map(tran => {
        const found = updatedTranslations.find(ut => ut.id === tran.id);
        if (found) {
          tran.updatedRows = found.updatedRows;
        }
        return tran;
      });
      return indicator;
    });
    setIndicatorsList(updated);
    dispatch({ type: 'RESET_INDICATOR' });
  };

  useEffect(() => {
    getData();
  }, [expanded]);

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return indicatorsList.length ? (
    <div className={styles.container}>
      {indicatorsList.map(indicator => (
        <Accordion
          expanded={expanded === indicator.code}
          onChange={handleChange(indicator.code)}
          style={{ boxShadow: 'none' }}
          className={styles.accordionRoot}
          key={indicator.code}
        >
          <TranslationsIndicatorAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <div className={styles.textSummary}>{t(indicator.code.toLowerCase())}</div>
          </TranslationsIndicatorAccordionSummary>
          <AccordionDetails className={styles.accordionDetails}>
            <IndicatorsTranslations indicator={indicator} submitData={submit} />
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  ) : (
    <ContentManagerLoader styles={contentsTabLoaderStyles} />
  );
};

export default IndicatorsTextEditor;
