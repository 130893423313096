import { FollowedProjectsActionTypes, SET_FOLLOWED_PROJECTS, SET_FOLLOW_UNFOLLOW_PROJECT } from '../actions/types';
import Project from '../../models/Project';

const initialFollowedProjects: Project[] = [];

const initialState = initialFollowedProjects;
const followedProjectsReducer = (state = initialState, action: FollowedProjectsActionTypes): Project[] => {
  switch (action.type) {
    case SET_FOLLOWED_PROJECTS:
      return action.payload;
    case SET_FOLLOW_UNFOLLOW_PROJECT:
      return action.payload;
    default:
      return state;
  }
};

export default followedProjectsReducer;
