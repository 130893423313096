import React from 'react';
import { CardPortfolioSkeletonChart, CardPortfolioSkeletonNumber } from '../CardPotfolioSkeleton/CardPortfolioSkeleton';
import { cards } from '../commonFunctions';

export const CardPortfolioSkeletonList = () => {
  const [cardsSkeletonsNumber, setCardsSkeletonsNumber] = React.useState([<CardPortfolioSkeletonNumber key="1" />]);
  const [cardsSkeletonsChart, setCardsSkeletonsChart] = React.useState([<CardPortfolioSkeletonChart key="1" />]);

  React.useEffect(() => {
    setCardsSkeletonsNumber(cards(4, CardPortfolioSkeletonNumber, cardsSkeletonsNumber));
    setCardsSkeletonsChart(cards(3, CardPortfolioSkeletonChart, cardsSkeletonsChart));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {cardsSkeletonsNumber}
      {cardsSkeletonsChart}
    </>
  );
};
