import React, { useEffect, useState } from 'react';
import styles from '../../SharedStyles/HeaderDrops.module.scss';
import { SummaryTcpInternalFilters } from '../SummaryTcp';
import { ProjectExecutionTypes } from '../../../../../models/ProjectExecutionTypes';
import {
  checkIfSessionFilterExist,
  deleteSessionFilter,
  getFilterSessionValue,
} from '../../../../../services/sessionFiltersIndicatorDetailHelper';
import { TaxonomiesTypes } from '../../../../../models/TaxonomyOptions';
import { ProjectCountryCount } from '../../../../../models/ProjectCountryCount';
import { TaxonomyParticipationModule } from './TaxonomyParticipationModule';
import { ExecutionModule } from './ExecutionModule';
import { PerformanceModule } from './PerformanceModule';
import { CompositionModule } from './CompositionModule';

type Props = {
  ctMetrics: any;
  filterBy: SummaryTcpInternalFilters;
  handleModuleFilters: Function;
  resetFilters: boolean;
};

const handleExecutionSessionFilterSetted = (executorType: ProjectExecutionTypes) => {
  const isSessionFilterApplied = checkIfSessionFilterExist('ProjectExecutorEntity');
  if (isSessionFilterApplied) {
    return getFilterSessionValue('ProjectExecutorEntity') === executorType;
  }

  return false;
};

const handleTaxonomySessionFilterSetted = (taxonomyId: TaxonomiesTypes) => {
  const isSessionFilterApplied = checkIfSessionFilterExist('TCPTaxonomyId');
  if (isSessionFilterApplied) {
    return getFilterSessionValue('TCPTaxonomyId') === taxonomyId;
  }

  return false;
};

const handleParticipationSessionFilterSetted = (participationFilter: ProjectCountryCount | string) => {
  const isSessionFilterApplied = checkIfSessionFilterExist('participationFilter');
  if (isSessionFilterApplied) {
    return getFilterSessionValue('participationFilter') === participationFilter;
  }

  return false;
};

export const HeaderDrops = ({ ctMetrics, filterBy, handleModuleFilters, resetFilters }: Props) => {
  const { nationals, regionals } = ctMetrics;
  const {
    ctsNatProjectAmount,
    ctsNatApprovedAmount,
    ctsNatPendingDisbursementAmount,
    ctsNatDisbursementAmount,
    ctsNatDisbursementPercent,
    ctsNatAgeAverage,
    ctsNatBidExecutionPercentage,
    ctsNatAgencyExecutionPercentage,
  } = nationals;

  const {
    ctsRegProjectAmount,
    ctsRegApprovedAmount,
    ctsRegPendingDisbursementAmount,
    ctsRegDisbursementAmount,
    ctsRegDisbursementPercent,
    ctsRegAgeAverage,
    ctsRegBidExecutionPercentage,
    ctsRegAgencyExecutionPercentage,
  } = regionals;
  const [executedByBankSelected, setExecutedByBankSelected] = useState(
    handleExecutionSessionFilterSetted(ProjectExecutionTypes.BID),
  );
  const [executionByAgencyExecutor, setExecutionByAgencyExecutor] = useState(
    handleExecutionSessionFilterSetted(ProjectExecutionTypes.EXECUTING_AGENCY),
  );

  const [taxonomyCustomerSupport, setTaxonomyCustomerSupport] = useState(
    handleTaxonomySessionFilterSetted(TaxonomiesTypes.CUSTOMER_SUPPORT),
  );

  const [taxonomyOperationalSupport, setTaxonomyOperationalSupport] = useState(
    handleTaxonomySessionFilterSetted(TaxonomiesTypes.OPERATIONAL_SUPPORT),
  );

  const [taxonomyResearch, setTaxonomyResearch] = useState(handleTaxonomySessionFilterSetted(TaxonomiesTypes.RESEARCH));

  const [udrSelected, setUdrSelected] = useState(handleParticipationSessionFilterSetted('udr'));
  const [upTo8Selected, setUpTo8Selected] = useState(
    handleParticipationSessionFilterSetted(ProjectCountryCount.UP_TO_8),
  );
  const [moreThan8Selected, setMoreThan8Selected] = useState(
    handleParticipationSessionFilterSetted(ProjectCountryCount.MORE_THAN_8),
  );

  const { dropsLayout } = styles;

  useEffect(() => {
    if (resetFilters) {
      setExecutedByBankSelected(false);
      setExecutionByAgencyExecutor(false);
      setTaxonomyCustomerSupport(false);
      setTaxonomyOperationalSupport(false);
      setTaxonomyResearch(false);
      setUdrSelected(false);
      setUpTo8Selected(false);
      setMoreThan8Selected(false);
    }
  }, [resetFilters]);

  useEffect(() => {
    handleFilterModuleEffect({
      executedByBankSelected,
      executionByAgencyExecutor,
      taxonomyCustomerSupport,
      taxonomyOperationalSupport,
      taxonomyResearch,
      upTo8Selected,
      moreThan8Selected,
      udrSelected,
      handleModuleFilters,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    executedByBankSelected,
    executionByAgencyExecutor,
    taxonomyCustomerSupport,
    taxonomyOperationalSupport,
    taxonomyResearch,
    udrSelected,
    upTo8Selected,
    moreThan8Selected,
  ]);

  return (
    <div className={dropsLayout}>
      <CompositionModule
        filterBy={filterBy}
        handleModuleFilterState={{
          ctsNatProjectAmount,
          ctsRegProjectAmount,
          ctsNatApprovedAmount,
          ctsRegApprovedAmount,
        }}
      />
      <PerformanceModule
        filterBy={filterBy}
        handleModuleFilterState={{
          ctsNatPendingDisbursementAmount,
          ctsRegPendingDisbursementAmount,
          ctsNatDisbursementAmount,
          ctsRegDisbursementAmount,
          ctsNatDisbursementPercent,
          ctsRegDisbursementPercent,
          ctsNatAgeAverage,
          ctsRegAgeAverage,
        }}
      />
      <ExecutionModule
        filterBy={filterBy}
        handleModuleFilterState={{
          ctsNatBidExecutionPercentage,
          ctsRegBidExecutionPercentage,
          setExecutedByBankSelected,
          executedByBankSelected,
          executionByAgencyExecutor,
          setExecutionByAgencyExecutor,
          ctsNatAgencyExecutionPercentage,
          ctsRegAgencyExecutionPercentage,
        }}
      />
      <TaxonomyParticipationModule
        ctMetrics={ctMetrics}
        filterBy={filterBy}
        handleModuleFilterState={{
          setTaxonomyCustomerSupport,
          taxonomyCustomerSupport,
          taxonomyOperationalSupport,
          setTaxonomyOperationalSupport,
          taxonomyResearch,
          setTaxonomyResearch,
          setUpTo8Selected,
          upTo8Selected,
          moreThan8Selected,
          setMoreThan8Selected,
          udrSelected,
          setUdrSelected,
        }}
      />
    </div>
  );
};

const handleFilterModuleEffect = ({
  executedByBankSelected,
  executionByAgencyExecutor,
  taxonomyCustomerSupport,
  taxonomyOperationalSupport,
  taxonomyResearch,
  upTo8Selected,
  moreThan8Selected,
  udrSelected,
  handleModuleFilters,
}) => {
  if (!executedByBankSelected && !executionByAgencyExecutor) {
    deleteSessionFilter('ProjectExecutorEntity');
  }
  if (!taxonomyCustomerSupport && !taxonomyOperationalSupport && !taxonomyResearch) {
    deleteSessionFilter('TCPTaxonomyId');
  }
  if (!upTo8Selected && !moreThan8Selected && !udrSelected) {
    deleteSessionFilter('participationFilter');
  }
  handleModuleFilters({
    executedByBankSelected,
    executionByAgencyExecutor,
    taxonomyCustomerSupport,
    taxonomyOperationalSupport,
    taxonomyResearch,
    udrSelected,
    upTo8Selected,
    moreThan8Selected,
  });

  sessionStorage.setItem(
    'filterObject',
    JSON.stringify({
      executedByBankSelected,
      executionByAgencyExecutor,
      taxonomyCustomerSupport,
      taxonomyOperationalSupport,
      taxonomyResearch,
      udrSelected,
      upTo8Selected,
      moreThan8Selected,
    }),
  );
};
