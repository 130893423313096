import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ChartLabels,
  dataForDetail,
  optionsforDetail,
} from '../../../../PortfolioDetail/LastFiveYearsDisbProjApp/LastFiveYearsDisbProjApp';
import {
  dataForDetail as dataForDetailPortfolioOverview,
  optionsforDetail as optionsforDetailPortfolioOverview,
} from '../../../../PortfolioDetail/LastFiveYearsPortfolioOverview/LastFiveYearsPortfolioOverview';
import { BarChart } from '../../../../Portfolio/CardIndicator/Cards/BarChart/BarChart';
import styles from './LargeImage.module.scss';
import { IndicatorKeyEnum } from '../../../../../models/IndicatorKeyEnum';

type Props = {
  currentIndicator: any;
};

const LastFiveYears = ({ currentIndicator }: Props) => {
  const { t } = useTranslation();
  const { flexCenter, noDataText, flexCenterCentered } = styles;

  return (
    <section
      className={currentIndicator.data ? flexCenter : flexCenterCentered}
      id={currentIndicator.largeImageKey}
      style={
        currentIndicator.indicatorKey === IndicatorKeyEnum.PORTFOLIO_OVERVIEW_LAST_5_YEARS
          ? { alignItems: 'center' }
          : {}
      }
    >
      {currentIndicator.data ? (
        <>
          {currentIndicator.indicatorKey === IndicatorKeyEnum.PORTFOLIO_OVERVIEW_LAST_5_YEARS ? null : <ChartLabels />}
          <BarChart
            type={currentIndicator.type}
            data={
              currentIndicator.indicatorKey === IndicatorKeyEnum.PORTFOLIO_OVERVIEW_LAST_5_YEARS
                ? dataForDetailPortfolioOverview(currentIndicator.data, t)
                : dataForDetail(currentIndicator.data)
            }
            showSubLabels={true}
            listPage={true}
            datasetClick={e => {}}
            indicatorKey={currentIndicator.indicatorKey}
            chartOptions={
              currentIndicator.indicatorKey === IndicatorKeyEnum.PORTFOLIO_OVERVIEW_LAST_5_YEARS
                ? optionsforDetailPortfolioOverview(t, currentIndicator.data.suggestedMax)
                : optionsforDetail(t)
            }
            chartDimensions={{
              mobile: { width: 300, height: 200 },
              desktop: { width: 1000, height: 500 },
            }}
          />
        </>
      ) : (
        <h2 className={noDataText}>{t('no_data_available')}</h2>
      )}
    </section>
  );
};

export default LastFiveYears;
