import React from 'react';
import './FilterAccordion.scss';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { ReactComponent as Arrow } from '../../../../../assets/arrow-accordion-analytics.svg';

type Props = {
  children?: React.ReactNode;
  title: React.ReactNode;
  hideAccordion?: React.ReactNode;
};

export const FilterAccordion = ({ children, title, hideAccordion = false }: Props) => {
  const [expanded, setExpanded] = React.useState(false);
  return hideAccordion ? null : (
    <>
      <Accordion className="accordion" elevation={0} expanded={expanded} onChange={() => setExpanded(!expanded)}>
        <AccordionSummary
          aria-controls="content"
          children={title}
          className="accordion-summary"
          expandIcon={<Arrow />}
          id="header"
        ></AccordionSummary>
        <AccordionDetails className="accordion-details">{children}</AccordionDetails>
      </Accordion>
    </>
  );
};
