const reStyleBarChart = (datasets, label) => {
  const elementsIndex = datasets.findIndex(element => element.label === label);
  const reRenderChartStyle = { ...datasets };
  reRenderChartStyle[elementsIndex] = {
    ...reRenderChartStyle[elementsIndex],
    barThickness: 12,
    datalabels: { display: false },
  };
  return reRenderChartStyle[elementsIndex];
};

const processLabels = restyledCurrentIndicator => {
  return {
    ...restyledCurrentIndicator.data,
    labels: restyledCurrentIndicator.data.labels.map(label => {
      return label.trim();
    }),
  };
};

const handleDataInDetail = currentIndicator => {
  const restyledCurrentIndicator = {
    ...currentIndicator,
    data: {
      ...currentIndicator.data,
      datasets: [
        reStyleBarChart(currentIndicator.data.datasets, 'lonChartData'),
        reStyleBarChart(currentIndicator.data.datasets, 'tcpChartData'),
        reStyleBarChart(currentIndicator.data.datasets, 'igrChartData'),
      ],
    },
  };

  return processLabels(restyledCurrentIndicator);
};

export { handleDataInDetail };
