import React from 'react';
import styles from './ReviewFormHeader.module.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const ReviewFormHeader = () => {
  const currentProject = useSelector<any, any>(state => state.currentProject);
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{t('new_accordance')}</h3>
      <span className={styles.label}>{t('project')}</span>
      <div className={styles.codeNameContainer}>
        <span className={styles.codeName}>{currentProject.code}</span>
        <span className={styles.name}>{currentProject.name}</span>
      </div>
    </div>
  );
};

export default ReviewFormHeader;
