import React from 'react';
import styles from './LoadingModal.module.scss';
import { styles as sharedStyles } from '../Styles';
import { disableEscKey } from '../Util';
import BaseModal from '../BaseModal';
import SpinningLoader from '../../../ProjectDetail/ProjectTabs/Loader/SpinningLoader';

type Props = {
  isOpen: boolean;
  closeModal: any;
  submit?: any;
  title?: any;
  subTitle?: any;
  buttonText?: any;
  buttonAction?: Function;
  backgroundImg?: any;
};
export const LoadingModal = (props: Props) => {
  const { isOpen, closeModal } = props;

  const { loadingModalContent } = styles;
  return (
    <BaseModal
      isOpen={isOpen}
      onRequestClose={e => disableEscKey(e, closeModal)}
      style={sharedStyles}
      className={loadingModalContent}
    >
      <SpinningLoader customText={'loading'} customStyle={{ marginBottom: 100 }} />
    </BaseModal>
  );
};
