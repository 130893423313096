import React, { useEffect } from 'react';
import { find, orderBy, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AccordionDetails, Checkbox, FormControlLabel } from '@material-ui/core';
import { ReactComponent as CheckedIcon } from '../../../../assets/checkbox_checked_icon.svg';
import { ReactComponent as UncheckedIcon } from '../../../../assets/checkbox_unchecked_icon.svg';
import {
  HelpCenterFormAccordion,
  HelpCenterFormAccordionSummary,
} from '../../ContentManagerAccordion/ContentManagerAccordion';
import CustomSelect from '../../../Util/CustomSelect/CustomSelect';
import '../HelpCenterModalForm/HelpCenterModalForm.scss';
import { useStyles } from './../HelpCenterModalForm/HelpCenterModalForm';
import FileUploader from '../../../FileUploader/FileUploader';
import { postTourListItem, updateTourListItem } from '../../../../api/contentManager';
import {
  findLanguage,
  findLanguageByValue,
  getDefaultOption,
  getUncompletedTranslations,
  hasCompletedTranslation,
} from '../../../Util/ContentManager/ContentManager';
import { getDisabledClass, getErrorClass, getFormFieldsErrors } from '../HelpCenterModalForm/HelpCenterModalForm.utils';
import {
  tourFields,
  getOrderOptions,
  hasOrderChanged,
  getButtonTitle,
  getPostTourItem,
  mapTranslationToTours,
  getOnChangeUpdatedFormFields,
} from './TourModalForm.util';
import { addAttachment, removeAttachments } from '../TutorialsModalForm/TutorialsModalFrom.util';
import * as config from './../../../../api/config.json';

type Props = {
  closeModal?: any;
  getLanguageTranslationFromKey?: any;
  displayGenericErrorMessage?: any;
  isEdit?: any;
  tourListItem?: any;
  updateTourList?: any;
  tourOrder?: any;
  tourList?: any;
  languagesData?: any;
};

const updateOrders = async (isEdit, tourList, tourOrder, tourItem, tourItemOrder?) => {
  await updateTourListItem({
    ...find(tourList.collection, tourListItem =>
      isEdit ? tourListItem.order === tourItemOrder.id : tourListItem.order === tourItem.order,
    ),
    order: isEdit ? tourItem.oldOrder : tourOrder[tourOrder.length - 1].id,
  });
};

const getDefaultTourOrder = (isEdit, tourOrder, tourItem, t) =>
  isEdit
    ? find(getOrderOptions(isEdit, tourOrder), auxTourItem => tourItem.order === auxTourItem.id)
    : getDefaultOption('contents_select_placeholder', t);

const getErrorMessage = (faqItem, t, type) => {
  let span: any = null;
  if (
    (faqItem.titleError && type === 'title_tour' && type !== 'content_tour') ||
    (faqItem.contentError && type === 'content_tour' && type !== 'title_tour')
  ) {
    span = <span className="uncompleted-error">{t(`validation_no_${type}`)}</span>;
  }
  return span;
};

export default function TourModalForm({
  closeModal,
  getLanguageTranslationFromKey,
  displayGenericErrorMessage,
  isEdit,
  tourListItem,
  updateTourList,
  tourOrder,
  tourList,
  languagesData,
}: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [tourItemOrder, setTourItemOrder] = React.useState(getDefaultTourOrder(isEdit, tourOrder, tourListItem, t));
  const [formFields, setFormFields] = React.useState([...tourFields]);
  const [orderError, setOrderError] = React.useState(false);
  const handleChange = panel => (event, isExpanded) => setExpanded(isExpanded ? panel : false);
  const handleTourOrderChange = item => {
    setTourItemOrder(item);
    setOrderError(false);
  };
  const setAttachment = (tourLanguageId, attachment) =>
    setFormFields(addAttachment(formFields, tourLanguageId, attachment, 'tourLanguageId'));
  const removeAttachment = (tourLanguageId, attachmentUrl) =>
    setFormFields(removeAttachments(formFields, tourLanguageId, attachmentUrl, 'tourLanguageId'));
  const handleSubmitTourItem = async () => {
    try {
      const unCompletedTranslations = getUncompletedTranslations(formFields);
      const hasUncompletedTranslations = !isEmpty(unCompletedTranslations);
      if (hasUncompletedTranslations || tourItemOrder.id === 0) {
        setFormFields(getFormFieldsErrors(formFields, unCompletedTranslations, 'tourLanguageId'));
        setOrderError(tourItemOrder.id === 0);
        return;
      }

      let postTourItem: any = getPostTourItem(formFields, findLanguageByValue, languagesData, tourItemOrder.id);

      if (isEdit) {
        postTourItem = { ...postTourItem, id: tourListItem.id };
        await updateTourListItem({ ...postTourItem, id: tourListItem.id });
      } else {
        await postTourListItem(postTourItem);
      }
      if (hasOrderChanged(isEdit, postTourItem, tourOrder, tourListItem)) {
        await updateOrders(
          isEdit,
          tourList,
          tourOrder,
          { ...postTourItem, oldOrder: tourListItem && tourListItem.order },
          tourItemOrder,
        );
      }

      updateTourList();
      closeModal();
    } catch {
      displayGenericErrorMessage(true);
    }
  };
  const handleFormChange = e => {
    e.preventDefault();
    const { name, value } = e.target;
    const dataInfo = name.split('-');
    const type = dataInfo[0];
    const language = dataInfo[1];
    const updatedList = getOnChangeUpdatedFormFields(formFields, type, value, language);
    setFormFields(updatedList);
  };
  useEffect(() => {
    if (isEdit) {
      setFormFields(mapTranslationToTours(tourListItem, languagesData, findLanguage));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {formFields.map(tourItem => {
        return (
          <HelpCenterFormAccordion
            expanded={tourItem.titleError || tourItem.contentError || expanded === tourItem.tourLanguageId}
            onChange={handleChange(tourItem.tourLanguageId)}
            key={tourItem.tourLanguageId}
          >
            <HelpCenterFormAccordionSummary expandIcon={<ExpandMoreIcon />}>
              <FormControlLabel
                classes={classes}
                value="end"
                control={
                  <Checkbox checked={tourItem.isChecked} icon={<UncheckedIcon />} checkedIcon={<CheckedIcon />} />
                }
                label={getLanguageTranslationFromKey(tourItem.tourLanguageId, t)}
                labelPlacement="end"
                disabled
              />
            </HelpCenterFormAccordionSummary>
            <AccordionDetails style={{ display: 'block', padding: '0px' }}>
              <div className="help-center-modal-form-container">
                <div className="horizontal-row">
                  <div className={`label-select-container ${getErrorClass(tourItem.titleError)}`}>
                    <p className="label">{t('contents_helpCenter_tour_title_label')}</p>
                    <input
                      value={tourItem.title}
                      name={`title-${tourItem.tourLanguageId}`}
                      onChange={e => handleFormChange(e)}
                      style={{ width: 220, marginRight: 20 }}
                      placeholder={t('contents_helpCenter_tour_placeholder')}
                    ></input>
                    {getErrorMessage(tourItem, t, 'title_tour')}
                  </div>
                  <div className={`label-select-container ${getErrorClass(tourItem.contentError)}`}>
                    <p className="label">{t('contents_helpCenter_tour_sub_title_label')}</p>
                    <input
                      value={tourItem.content}
                      name={`content-${tourItem.tourLanguageId}`}
                      onChange={e => handleFormChange(e)}
                      style={{ width: 220, marginRight: 20 }}
                      placeholder={t('contents_helpCenter_tour_placeholder')}
                    ></input>
                    {getErrorMessage(tourItem, t, 'content_tour')}
                  </div>
                </div>
                <div className="horizontal-row">
                  <FileUploader
                    filesList={tourItem.attachments}
                    addFile={attachment => setAttachment(tourItem.tourLanguageId, attachment)}
                    removeFile={attachment => removeAttachment(tourItem.tourLanguageId, attachment.url)}
                    customFileTypes={'.gif, .mp4, .avi'}
                    customId={tourItem.tourLanguageId}
                    uploadUrl={config.ACCORDANCE_ATTACHEMENTS}
                    singleFile
                  />
                </div>
              </div>
            </AccordionDetails>
          </HelpCenterFormAccordion>
        );
      })}
      <div className="content-manager-modal__options">
        <div className="horizontal-row">
          <p className="label">{t('contents_helpCenter_tour_slide_order')}</p>
          <CustomSelect
            options={orderBy(getOrderOptions(isEdit, tourOrder), ['id'], ['asc'])}
            style={{ width: 220, marginRight: 20 }}
            handleSelected={handleTourOrderChange}
            selectedOption={tourItemOrder}
            translatedFromBackend
            error={orderError}
          />
          {orderError && <span className="uncompleted-error">{t(`validation_no_order`)}</span>}
        </div>
      </div>
      <div className="content-manager-modal__button-group">
        <button
          style={{ width: '195px' }}
          className={`content-manager-modal__button ${getDisabledClass(formFields, hasCompletedTranslation)}`}
          onClick={() => handleSubmitTourItem()}
        >
          {t(getButtonTitle(isEdit))}
        </button>
        <button
          style={{ width: '195px' }}
          className="content-manager-modal__button--secondary"
          onClick={() => closeModal()}
        >
          {t('contents_helpCenter_tour_close_button_label')}
        </button>
      </div>
    </>
  );
}
