import React from 'react';
import { useTranslation } from 'react-i18next';

type RenderTextByProjectTypeFilterDetailProps = {
  arrayToCount: any;
  projectTypeFilterOnDetail: any;
};
export const RenderTextByProjectTypeFilterDetail = ({
  arrayToCount,
  projectTypeFilterOnDetail,
}: RenderTextByProjectTypeFilterDetailProps) => {
  const singularAndPluralTranslations = [
    ['operations', 'operation'],
    [],
    ['operations', 'operation'],
    ['operations', 'operation'],
  ];
  const { t } = useTranslation();
  return (
    <div className="loan-value" style={{ lineHeight: '40px' }}>
      {arrayToCount.length}{' '}
      <span className="lowercase-text">
        {' '}
        {arrayToCount.length > 1
          ? t(singularAndPluralTranslations[projectTypeFilterOnDetail ? projectTypeFilterOnDetail : 0][0])
          : t(singularAndPluralTranslations[projectTypeFilterOnDetail ? projectTypeFilterOnDetail : 0][1])}
      </span>
    </div>
  );
};
