import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './SimpleTextEditor.module.scss';
import SaveButton from '../../../Util/Buttons/SaveButton/SaveButton';
import { getTranslationsByGroup, updateTranslations } from '../../../../api/contentManager';
import TranslationsList from '../TranslationsList/TranslationsList';
import Header from '../Header/Header';
import { Pagination } from '@material-ui/lab';
import { TranslationsContext } from '../context/TranslationsContext';
import { buttonText } from '../util';
import i18n from '../../../../i18n/i18n';
import ContentManagerLoader from '../../ContentManagerLoader/ContentManagerLoader';
import { contentsTabLoaderStyles } from '../../../Util/ContentManager/ContentManager';
import SubmitButton from '../../../Util/Buttons/SubmitButton/SubmitButton';
import { RequestStatus } from '../../../../models/RequestStatus';


type Props = {
  groupId: number;
};

const itemsPerPage = 10;
const spanishLanguage = 'es';

const SimpleTextEditor = ({ groupId }: Props) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const { t } = useTranslation();
  const [list, setList] = useState<any>([]);
  const { state, dispatch } = useContext(TranslationsContext);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [numberOfPages, setNumberOfPages] = useState<number>(1);
  const [orderDesc, setOrderDesc] = useState<boolean>(false);
  const [currentLanguage, setCurrentLanguage] = useState<string>(spanishLanguage);
  const [inputHasChanges, setInputHasChanges] = useState<boolean>(false);

  //Added
  const [txtInputSearch, setTxtInputSearch] = useState<string>("");
  const [restoreSearch, setRestoreSearch] = useState<boolean>(false);
  const [search, setSearch] = useState<boolean>(false);
  const [responseDoesntHaveResults, setResponseDoesntHaveResults] = useState<boolean>(false);



  const getData = async () => {
    try {
      const data = {
        groupId,
        pageNumber: restoreSearch || search === true ? 1 : currentPage,
        itemsPerPage,
        languageId: state.languages.find(i => i.encodedName === currentLanguage).id,
        orderDesc,
        word: restoreSearch === true || responseDoesntHaveResults ? "" : txtInputSearch
      };

      const response = await getTranslationsByGroup(data);

      if (response.collection.length > 0) {
        setList([]);
        setNumberOfPages(response.pageCount);

        if (!search) {
          setCurrentPage(response.currentPage);
        }


        setList(response.collection);
      }
      else { setResponseDoesntHaveResults(true); }
      setRestoreSearch(false);
      setSearch(false);

    } catch (error) { }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId, currentLanguage, currentPage, orderDesc]);

  React.useEffect(() => {

    if (restoreSearch) {
      getData();
    }
    if (search) {

      getData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restoreSearch, search]);




  const submitData = async () => {
    await updateTranslations(state.listToUpdate);
    const updatedList = list.map(i => {
      i.values = i.values.map(item => {
        const foundUpdated = state.listToUpdate.find(updated => updated.id === item.id);
        if (foundUpdated) {
          item.recentlyUpdated = true;
          item.value = foundUpdated.value;
        }
        return item;
      });
      return i;
    });
    setList(updatedList);
    setEditMode(false);
    dispatch({ type: 'RESET_LIST' });
    i18n.reloadResources();
  };

  const buttonPress = () => {
    if (editMode) {
      submitData();
    } else {
      setEditMode(true);
    }
  };

  const sort = (encodedName: string) => {
    if (encodedName !== currentLanguage) {
      setCurrentLanguage(encodedName);
    } else {
      setOrderDesc(!orderDesc);
    }
  };

  const handlePagination = (event, value) => {
    setCurrentPage(value);
  };

  const searchWord = () => {
    setResponseDoesntHaveResults(false);
    setSearch(true);
  };

  const cleanSearch = () => {
    setResponseDoesntHaveResults(false);
    setTxtInputSearch("");
    setRestoreSearch(true);
  };

  return !list.length ? (
    <ContentManagerLoader styles={contentsTabLoaderStyles} />
  ) : (
    <div className={styles.container}>

      <div style={{ marginBottom: '1%', marginTop: 10, backgroundColor: '#f7f7f7', borderRadius: '20px', maxWidth: '40%', minHeight: '100px', flexWrap: 'wrap', justifyContent: 'center' }}>
        {<div style={{ display: 'flex' }} >

          <input type='text' value={txtInputSearch} onChange={e => setTxtInputSearch(e.target.value)} style={{ marginLeft: '1%', marginTop: '2%', borderRadius: '8px', marginBottom: '4%' }}></input>
          <SubmitButton customText={t('search')} handleClick={searchWord} buttonState={RequestStatus.Default} style={{ marginLeft: '4%', marginTop: '2%', marginBottom: '4%' }} ></SubmitButton>
          <SubmitButton customText={t('RESET')} handleClick={cleanSearch} buttonState={RequestStatus.Default} style={{ marginLeft: '4%', marginTop: '2%', marginBottom: '4%' }} ></SubmitButton>
        </div>}
        {responseDoesntHaveResults && <span style={{ marginLeft: '1%', fontFamily: 'Rubik', fontSize: '14px', color: '#ff4000' }}> {t('SEARCH_WITHOUT_RESULT')}</span>}
      </div>

      <Header editMode={editMode} handleClick={sort} />
      <TranslationsList list={list} editMode={editMode} inputHasChanges={() => setInputHasChanges(true)} />
      <Pagination
        count={numberOfPages}
        page={currentPage}
        onChange={handlePagination}
        disabled={editMode}
        style={{ position: 'absolute', bottom: 30, left: 30 }}
      />
      <SaveButton
        customText={t(buttonText(editMode))}
        handleClick={buttonPress}
        customStyle={{ position: 'absolute', bottom: 30, right: 30 }}
        disabled={!inputHasChanges && editMode}
      />
    </div>
  );
};

export default SimpleTextEditor;
