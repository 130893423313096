import { axiosDefaultConfig } from './axiosConfig';
import * as config from './config.json';
import { buildGet, buildPut, buildPost } from './genericMethods';

export const getReviews = (year, region: string | number = 'all', country: string | number = 'all') => {
  let urlParams;
  if (region !== 'all') {
    urlParams = `/year/${year}/region/${region}`;
  } else if (country !== 'all') {
    urlParams = `/year/${year}/country/${country}`;
  } else {
    urlParams = `/year/${year}`;
  }
  const url = `${config.REVIEWS_BY_YEAR}${urlParams}`;
  return buildGet(url);
};

export const createReview = params => {
  const url = config.REVIEW_CREATE;
  return new Promise<any>(async (resolve, reject) => {
    try {
      const response = await axiosDefaultConfig.post(url, params);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const modifyReview = review => {
  const { id, startDate, endDate, status, published } = review;
  const url = config.REVIEW_UPDATE.replace('{id}', id.toString());
  const data = { startDate, endDate, status, published };
  return buildPut(url, data);
};

export const getReviewById = reviewId => {
  let url = config.REVIEW_BY_ID;
  url = url.replace('{id}', reviewId.toString());
  return buildGet(url);
};

export const updateReviewProjects = (id: number, data: number[]) => {
  const url = config.REVIEW_PROJECTS.replace('{id}', id.toString());
  return buildPut(url, data);
};

export const deleteComment = (commentId: number) => {
  const url = config.REVIEW_DELETE_COMMENT.replace('{commentId}', commentId.toString());
  return buildPut(url);
};

export const createComment = (revisionId: number, projectId: number, comment: string) => {
  const url = config.REVIEW_CREATE_COMMENT.replace('{revisionId}', revisionId.toString()).replace(
    '{projectId}',
    projectId.toString(),
  );
  return buildPost(url, { comment });
};

export const getAccordancesOfReview = (reviewId: number) => {
  let url = config.REVIEW_ACCORDANCES;
  url = url.replace('{id}', reviewId.toString());
  return buildGet(url);
};

export const editAgendaTime = (reviewId, projects) => {
  let url = config.REVIEW_ASSIGN_AGENDA;
  url = url.replace('{revisionId}', reviewId.toString());
  return buildPut(
    url,
    projects.map(item => {
      return {
        NewStartDate: item.NewStartDate || item.newStartDate,
        NewEndDate: item.NewEndDate || item.newEndDate,
        id: item.id,
        status: item.status,
      };
    }),
  );
};
