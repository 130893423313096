import React from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import FilterIndicators from '../../../../../models/FilterIndicators';
import { multiSelectStyle, DropdownIndicator } from '../../../../Filter/FilterComponent.Styles';
import { useSelector } from 'react-redux';

type Props = {
  reportFilters: FilterIndicators;
  reportFiltersChanged: Function;
};
export const InterestIssueFilter = ({ reportFilters, reportFiltersChanged }: Props) => {
  const { t } = useTranslation();
  const [localFilter, setLocalFilter] = React.useState(reportFilters);
  const allInterestIssues = useSelector<any, any>(state => state.interestIssues);

  const handleChange = value => {
    if (value === null) {
      value = [];
    }

    const newFilter = {
      ...localFilter,
      common: {
        ...localFilter.common,
        interestIssues: value,
      },
    };

    setLocalFilter(newFilter);
    reportFiltersChanged({ divisionId: newFilter.common.interestIssues });
  };

  return (
    <Select
      id="interestIssuesSelect"
      isMulti
      components={{ DropdownIndicator }}
      defaultValue={localFilter.common.interestIssues}
      name="interestIssues"
      options={allInterestIssues.map(i => ({ id: i.id, value: i.name, label: i.name }))}
      className="basic-multi-select"
      classNamePrefix="All"
      placeholder={t('all')}
      onChange={value => handleChange(value)}
      styles={multiSelectStyle}
    />
  );
};
