import { RolesIdEnum } from '../../../models/RolesIdEnum';
import { dataTypes, languagesKeys, tutorialsCategory } from '../../ContentManager/ContentManagerKeys';
import SelectOption from '../../../models/SelectOption';
import { find, isNil, orderBy, isEmpty, lowerCase, filter, some } from 'lodash';
import moment from 'moment';

export const getNoRecordKey = (dataType, dataTypes) => {
  switch (dataType) {
    case dataTypes.faq:
      return 'faq';
    case dataTypes.tutorials:
      return 'tutorials';
    case dataTypes.emailQuestions:
      return 'email_questions';
    case dataTypes.tours:
      return 'tours';
    default:
      return 'default';
  }
};

export const getTranslationKey = roleKey => {
  switch (roleKey) {
    case RolesIdEnum.Chief_of_Operations:
      return 'contents_helpCenter_roles_chief_of_operations';
    case RolesIdEnum.Team_Leader:
      return 'contents_helpCenter_roles_team_leader';
    case RolesIdEnum.Operations_Analyst:
      return 'contents_helpCenter_roles_operations_analyst';
    case RolesIdEnum.Fiduciaries:
      return 'contents_helpCenter_roles_fiduciaries';
    case RolesIdEnum.Managerial:
      return 'contents_helpCenter_roles_managerial';
    case RolesIdEnum.Strategists:
      return 'contents_helpCenter_roles_strategists';
    case RolesIdEnum.VPS_VPC_etc:
      return 'contents_helpCenter_roles_vps_vpc_etc';
    case RolesIdEnum.ProceduremenManager:
      return 'contents_helpCenter_roles_proceduremen_manager';
    case RolesIdEnum.ESG_Specialist:
      return 'contents_helpCenter_roles_esg_specialist';
    case RolesIdEnum.Financial_Specialist:
      return 'contents_helpCenter_roles_financial_specialist';
    case RolesIdEnum.Borrower:
      return 'contents_helpCenter_roles_borrower';
    case RolesIdEnum.Executing_Agency:
      return 'contents_helpCenter_roles_executing_agency';
    case RolesIdEnum.Coordinator:
      return 'contents_helpCenter_roles_coordinator';
    case RolesIdEnum.Financial_Fiduciary:
      return 'contents_helpCenter_roles_financial_fiduciary';
    case RolesIdEnum.VPF_Vice_Presidency_for_Finance_and_Administration:
      return 'contents_helpCenter_roles_vpf';
    case RolesIdEnum.Consultant:
      return 'contents_helpCenter_roles_consultant';
    case RolesIdEnum.ESG_Reviewer:
      return 'contents_helpCenter_roles_esg_reviewer';
    case RolesIdEnum.ESG_TeamMember:
      return 'contents_helpCenter_roles_esg_team_member';
    case RolesIdEnum.ESG_Primary_TeamMember:
      return 'contents_helpCenter_roles_esg_primary_team_member';
    case RolesIdEnum.Alternate_Team_Leader:
      return 'contents_helpCenter_roles_alternate_team_leader';
    case RolesIdEnum.Financial_Management:
      return 'contents_helpCenter_roles_financial_management';
    case RolesIdEnum.COS_pecialist:
      return 'contents_helpCenter_roles_cos_specialist';
    case RolesIdEnum.Procurement_Fiduciary_Specialist:
      return 'contents_helpCenter_roles_procurement_fiduciary_specialist';
    default:
      return '';
  }
};

export const getLanguageTranslationKey = languageKey => {
  switch (languageKey) {
    case languagesKeys.spanish:
    case 'es':
      return 'contents_helpCenter_language_spanish';
    case languagesKeys.english:
    case 'en':
      return 'contents_helpCenter_language_english';
    case languagesKeys.portuguese:
    case 'pt':
      return 'contents_helpCenter_language_portuguese';
    case languagesKeys.french:
    case 'fr':
      return 'contents_helpCenter_language_french';
    default:
      return '';
  }
};

export const findLanguage = (languagesData, currentLanguage, field = 'languageId') =>
  find(languagesData, language => currentLanguage[field] === language.id);

export const findLanguageByValue = (languagesData, value) =>
  find(languagesData, language => value === language.name.toLowerCase());

export const getCountries = (regionAndCountriesResponse: any) => {
  const newCountryList: SelectOption[] = [];
  regionAndCountriesResponse.forEach(region => {
    region.countries.forEach(country => {
      newCountryList.push({ id: country.id, label: country.name, value: country.name });
    });
  });
  return orderBy(newCountryList, ['label'], ['asc']);
};

export const getPositionFromStringEnum = (keys, key) => Object.keys(keys).indexOf(key);

export const sortTranslations = (translations, languagesData) => {
  if (languagesData === undefined) {
    return translations;
  }
  const keys = Object.keys(languagesKeys);
  return translations.sort((a, b) => {
    const parsedA = { ...a, languageIdString: lowerCase(findLanguage(languagesData, a).name) };
    const parsedB = { ...b, languageIdString: lowerCase(findLanguage(languagesData, b).name) };
    return keys.indexOf(parsedA.languageIdString) - keys.indexOf(parsedB.languageIdString);
  });
};

export const getFirstTranslation = translations => {
  return find(translations, translation => translation.title !== '');
};

export const getFirstContentTranslation = translations =>
  find(translations, translation => translation.content.trim() !== '');

export const getMappedFaqList = (faqList, languagesData) =>
  faqList.map(faqItem => ({
    ...faqItem,
    type: dataTypes.faq,
    text: getFirstTranslation(sortTranslations(faqItem.translations, languagesData)).title,
  }));

export const getMappedTutorialsList = (tutorialList, languagesData) =>
  tutorialList.map(tutorialItem => ({
    ...tutorialItem,
    type: dataTypes.tutorials,
    category: tutorialsCategory[tutorialItem.category],
    categoryId: tutorialItem.category,
    text: getFirstTranslation(sortTranslations(tutorialItem.translations, languagesData)).title,
  }));

export const getMappedMessagesList = (messagesList, languagesData) =>
  messagesList.map(messageItem => ({
    ...messageItem,
    type: dataTypes.messages,
    text: getFirstContentTranslation(sortTranslations(messageItem.translations, languagesData)).content,
  }));

export const getMappedToursList = (tourList, languagesData) =>
  tourList.map(tourListItem => ({
    ...tourListItem,
    type: dataTypes.tours,
    text: getFirstTranslation(sortTranslations(tourListItem.translations, languagesData)).title,
  }));

export const getMappedHelpRequests = helpRequestsList =>
  helpRequestsList.map(helpRequestListItem => ({
    ...helpRequestListItem,
    type: dataTypes.emailQuestions,
    question: helpRequestListItem.consultation,
    text: helpRequestListItem.consultation,
    answeredBy: helpRequestListItem.helper,
    received: helpRequestListItem.receptiondDate,
    answeredDate: helpRequestListItem.answerDate,
    isNew: isNil(helpRequestListItem.helper) ? true : false,
  }));

export const canGetByDataType = type =>
  type === dataTypes.faq ||
  type === dataTypes.tutorials ||
  type === dataTypes.messages ||
  type === dataTypes.emailQuestions ||
  type === dataTypes.catergories ||
  type === dataTypes.criticalIssue ||
  type === dataTypes.possibleAction;

export const move = (array, moveIndex, toIndex) => {
  const item = array[moveIndex];
  const length = array.length;
  const diff = moveIndex - toIndex;
  let newArray: any = [];

  if (diff > 0) {
    // move left
    newArray = [
      ...array.slice(0, toIndex),
      item,
      ...array.slice(toIndex, moveIndex),
      ...array.slice(moveIndex + 1, length),
    ];
  } else if (diff < 0) {
    // move right
    const targetIndex = toIndex + 1;
    newArray = [
      ...array.slice(0, moveIndex),
      ...array.slice(moveIndex + 1, targetIndex),
      item,
      ...array.slice(targetIndex, length),
    ];
  } else {
    newArray = array;
  }
  return newArray;
};

export const hasRecords = list => !isEmpty(list) || !isNil(list);

export const getMappedItems = (dataType, collection, languagesData?) => {
  if (!hasRecords(collection)) {
    return [];
  }

  switch (dataType) {
    case dataTypes.emailQuestions:
      return getMappedHelpRequests(collection);
    case dataTypes.faq:
      return getMappedFaqList(collection, languagesData);
    case dataTypes.tutorials:
      return getMappedTutorialsList(collection, languagesData);
    case dataTypes.tours:
      return orderBy(getMappedToursList(collection, languagesData), ['order'], ['asc']);
    case dataTypes.messages:
      return getMappedMessagesList(collection, languagesData);
    default:
      return [];
  }
};

export const getModalTitle = type => {
  switch (type) {
    case dataTypes.faq:
      return 'contents_helpCenter_faq_editFaq_modal_title';
    case dataTypes.tutorials:
      return 'contents_helpCenter_tutorial_edit_modal_title';
    case dataTypes.messages:
      return 'contents_messages_edit_modal_title';
    case dataTypes.emailQuestions:
      return 'contents_helpCenter_emailQuestions_modal_title';
    default:
      return '';
  }
};

export const getText = (type, category, text) => {
  const categoryText = type === dataTypes.emailQuestions ? `${category} - ` : '';
  return `${categoryText}${text}`;
};

export const getOptionsFromKeys = (keys, t, translationKeyFunction?, prefix?) =>
  orderBy(
    Object.values(keys)
      .filter(value => typeof value === 'number')
      .map((key: any) => ({
        id: key as number,
        value: `${keys[key]}`,
        label: prefix ? t(`${prefix}${key}`) : `${t(translationKeyFunction(key))}`,
      })),
    ['label'],
    ['asc'],
  );

export const getDefaultOption = (type, t) => ({ id: 0, value: '0', label: t(type) });

export const getMappedUserOptions = users =>
  users.map(user => ({ id: user.id, value: `${user.id}`, label: `${user.name} ${user.lastName}` }));

export const getValidDate = date => (date ? new Date(date) : null);

export const hasDateError = (startDate, endDate) =>
  moment(endDate).isSameOrBefore(startDate) || isNil(endDate) || isNil(startDate);

export const getUncompletedTranslations = translations =>
  filter(
    translations,
    translation =>
      (translation.title.trim() === '' && translation.content.trim() !== '') ||
      (translation.title.trim() !== '' && translation.content.trim() === ''),
  );

export const hasCompletedTranslation = translations =>
  some(translations, translation => translation.title.trim() !== '' && translation.content.trim() !== '');

export const hasErrorsInTranslations = translations => some(translations, translation => translation.error);

const commonLoaderStyles = `display: inline-block; padding-left: 50%;`;

export const contentsTabLoaderStyles = `${commonLoaderStyles} padding-top: 10%;`;

export const loaderCustomStyles = `${commonLoaderStyles} padding-left: 0%;`;
