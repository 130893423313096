import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AccordanceState } from '../../../models/AccordanceState';
import {
  LinkedActionTableTypeEnum,
  TableData,
  TableRowDataAccordances,
  TableRowDataSupervisionPlan,
} from '../../../models/EarlyWarningsSection';
import { ProjectDetailsTab } from '../../../models/ProjectDetailsTabEnum';
import { ProjectProductTypes } from '../../../models/ProjectProductTypes';
import { setProjectTabValue } from '../../../redux/actions/projectTabValue';
import { renderDateOrSlash } from '../../../services/stringUtil';
import { isMobile } from '../../../services/booleanUtil';
import SpinningLoader from '../../ProjectDetail/ProjectTabs/Loader/SpinningLoader';
import { SupervisionPlanItemState, SupervisionPlanItemType } from '../../ProjectDetail/ProjectTabs/Supervision/util';
import LinkText from '../../Util/Buttons/LinkText/LinkText';
import { InfoTooltipBlueOnModal } from '../../Util/Tooltip/InfoTooltipBlueOnModal/InfoTooltipBlueOnModal';
import { ParagraphTooltipOnModal } from '../../Util/Tooltip/ParagraphTooltipOnModal/ParagraphTooltipOnModal';
import { handleTextOrSlash } from '../Util';

import styles from './LinkedActionsTable.module.scss';

type Props = {
  projectId: number;
  title: string;
  tableData: TableData;
  projectProductType: ProjectProductTypes;
};
const {
  container,
  titleContainer,
  tableTitle,
  linkText,
  tableContainer,
  tableHeader,
  rowList,
  headerTitle,
  tableRow,
  rowValue,
  emptyState,
  badgeYellow,
  badgeBlue,
  adjustSpinning,
  mobileTableTitle,
  cardRow,
  mobileTableTextValue,
} = styles;

export const LinkedActionsTable = ({ projectId, title, tableData, projectProductType }: Props) => {
  const { tHeader, tRows, linkedActionTableType, tableState } = tableData;
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleClick = (title: string) => {
    dispatch(
      setProjectTabValue(title === 'accordances' ? ProjectDetailsTab.AGREEMENTS : ProjectDetailsTab.SUPERVISION),
    );
    history.push(`/projectdetail?id=${projectId}`);
  };

  const tableRender = {
    0: <TableReady tHeader={tHeader} tRows={tRows} linkedActionTableType={linkedActionTableType} />,
    1: (
      <div className={adjustSpinning}>
        <SpinningLoader customStyle={{ marginTop: '15px' }} />
      </div>
    ),
    2: <EmptyState linkedActionTableType={linkedActionTableType} />,
  };

  return (
    <div className={container}>
      <div className={titleContainer}>
        <p className={tableTitle}>{t(title)}</p>
        <p className={linkText}>
          <LinkText onClick={() => handleClick(title)} label={t('see_project_details')} />
        </p>
        {linkedActionTableType === LinkedActionTableTypeEnum.SUPERVISION_PLAN && (
          <InfoTooltipBlueOnModal message={t('available_only_if_the_project_has_a_detailed_monitoring_plan')} />
        )}
      </div>
      {tableRender[tableState]}
    </div>
  );
};

const handleAccordanceTypeTranslation = {
  1: 'revision_accordance',
  2: 'monitor_accordance',
  3: 'inter_group_accordance',
};

const handleAccordanceStateTranslations = {
  0: 'draft',
  1: 'pending_accordance',
  2: 'active_accordance',
  3: 'accomplished_accordance',
  4: 'cancelled_accordance',
  5: 'rejected',
};

const DATE_FORMAT = 'DD/MM/YYYY';

type TableReadyProps = {
  tHeader: any;
  linkedActionTableType: any;
  tRows: any;
};

const TableReady = ({ tHeader, linkedActionTableType, tRows }: TableReadyProps) => {
  const { t } = useTranslation();
  return (
    <div className={tableContainer}>
      {!isMobile() && (
        <div className={tableHeader}>
          {tHeader.map(header => (
            <h4 className={headerTitle} key={header.text}>
              {t(header.text)}
            </h4>
          ))}
        </div>
      )}
      {linkedActionTableType === LinkedActionTableTypeEnum.ACCORDANCES ? (
        <div className={rowList}>
          {tRows.map((project: TableRowDataAccordances) => {
            const { description, accordanceState, accordanceType, expirationDate, lastComment } = project;
            const comment = lastComment && lastComment.comment;
            return isMobile() ? (
              <MobileTable project={project} tHeader={tHeader} linkedActionTableType={linkedActionTableType} />
            ) : (
              <div className={tableRow}>
                <div className={rowValue}>
                  <ParagraphTooltipOnModal message={handleTextOrSlash(description)} />
                </div>
                <div className={rowValue}>{t(handleAccordanceTypeTranslation[accordanceType])}</div>
                <div className={rowValue} style={fillAccordancesRedIfWithDelays(accordanceState, expirationDate)}>
                  {renderDateOrSlash(expirationDate, DATE_FORMAT)}
                </div>
                <div className={rowValue}>{t(handleAccordanceStateTranslations[accordanceState])}</div>
                <div className={rowValue}>
                  <ParagraphTooltipOnModal message={handleTextOrSlash(comment)} />
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className={rowList}>
          {tRows.map((project: TableRowDataSupervisionPlan) => {
            const { description, type, plannedDate, state, lastComment, plannedQuarter } = project;
            const comment = lastComment && lastComment.comment;
            return isMobile() ? (
              <MobileTable project={project} tHeader={tHeader} linkedActionTableType={linkedActionTableType} />
            ) : (
              <div className={tableRow}>
                <div className={rowValue}>
                  <ParagraphTooltipOnModal message={handleTextOrSlash(description)} />
                </div>
                <div className={rowValue}>
                  <div className={type === SupervisionPlanItemType.CriticalProduct ? badgeYellow : badgeBlue}>
                    {t(`plan_item_type_${type}`)}
                  </div>
                </div>
                <div className={rowValue}>{handlePlannedDate(plannedDate, plannedQuarter)}</div>
                <div className={rowValue} style={fillSupervisionPlansRedIfWithDelays(state)}>
                  {t(`supervision_plan_activity_state_${state}`)}
                </div>
                <div className={rowValue}>
                  <ParagraphTooltipOnModal message={handleTextOrSlash(comment)} />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const MobileTable = ({ project, tHeader, linkedActionTableType }) => {
  const { t } = useTranslation();
  const {
    description,
    type,
    plannedDate,
    state,
    plannedQuarter,
    accordanceState,
    accordanceType,
    expirationDate,
    lastComment,
  } = project;
  const comment = lastComment && lastComment.comment;
  return (
    <div className={cardRow}>
      <div className={mobileTableTitle}>{t(tHeader[0].text)}</div>
      <div className={mobileTableTextValue}>{handleTextOrSlash(description)}</div>
      <div className={mobileTableTitle}>{t(tHeader[1].text)}</div>
      <div>
        {linkedActionTableType === LinkedActionTableTypeEnum.ACCORDANCES ? (
          <div className={mobileTableTextValue}>{t(handleAccordanceTypeTranslation[accordanceType])}</div>
        ) : (
          <div className={type === SupervisionPlanItemType.CriticalProduct ? badgeYellow : badgeBlue}>
            {t(`plan_item_type_${type}`)}
          </div>
        )}
      </div>
      <div className={mobileTableTitle}>{t(tHeader[2].text)}</div>
      <div className={mobileTableTextValue}>
        {linkedActionTableType === LinkedActionTableTypeEnum.ACCORDANCES
          ? renderDateOrSlash(expirationDate, DATE_FORMAT)
          : handlePlannedDate(plannedDate, plannedQuarter)}
      </div>
      <div className={mobileTableTitle}>{t(tHeader[3].text)}</div>

      {linkedActionTableType === LinkedActionTableTypeEnum.ACCORDANCES ? (
        <div className={mobileTableTextValue}>{t(handleAccordanceStateTranslations[accordanceState])}</div>
      ) : (
        <div className={mobileTableTextValue} style={fillSupervisionPlansRedIfWithDelays(state)}>
          {t(`supervision_plan_activity_state_${state}`)}
        </div>
      )}

      <div className={mobileTableTitle}>{t(tHeader[4].text)}</div>
      <div className={mobileTableTextValue}>{comment}</div>
    </div>
  );
};

type EmptyStateProps = {
  linkedActionTableType: LinkedActionTableTypeEnum;
};
const EmptyState = ({ linkedActionTableType }: EmptyStateProps) => {
  const { t } = useTranslation();
  const translationKey =
    linkedActionTableType === LinkedActionTableTypeEnum.ACCORDANCES
      ? 'no_agreements_related_to_this_project_have_been_found'
      : 'no_products_related_to_this_project_have_been_found';

  return <div className={emptyState}>{t(translationKey)}</div>;
};

const handlePlannedDate = (plannedDate, plannedQuarter) => {
  if (plannedDate === null && plannedQuarter === null) {
    return '-';
  } else if (plannedDate) {
    return renderDateOrSlash(plannedDate, DATE_FORMAT);
  }
  return plannedQuarter;
};

const fillAccordancesRedIfWithDelays = (accordanceState, expirationDate) => {
  const dateToCheck = new Date(expirationDate);
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  dateToCheck.setHours(0, 0, 0, 0);
  const dateIsDue = dateToCheck <= today;
  const accordandeIsValid = AccordanceState.VALID === accordanceState;
  return accordandeIsValid && dateIsDue ? { color: '#F06D6D' } : {};
};
const fillSupervisionPlansRedIfWithDelays = state =>
  state === SupervisionPlanItemState.WithDelays ? { color: '#F06D6D' } : {};
