import React from 'react';
import { isMobile } from '../../../../services/booleanUtil';
import { ReactComponent as Icon } from '../../../../assets/early_warnings.svg';
import styles from './EarlyWarningsIcon.module.scss';
import { earlyWarningsIndicatorKeys } from '../../IndicatorsHelpers/indicatorHelperUtil';
type Props = {
  indicatorKey?: string;
};
export const EarlyWarningsIcon = ({ indicatorKey = '' }: Props) => {
  const { earlyWarningsIconContainer } = styles;
  return earlyWarningsIndicatorKeys[indicatorKey] ? (
    <div className={earlyWarningsIconContainer}>
      <Icon width={isMobile() ? '13px' : '26px'} height={isMobile() ? '14px' : '29px'} />
    </div>
  ) : null;
};
