import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { isMobile } from '../../../services/booleanUtil';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#236BFF',
      color: '#fff',
      fontFamily: 'Rubik',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '14px',
      textAlign: 'center',
      borderRadius: '16px',
      height: '40px',
      width: isMobile() ? '40px' : '121px',
      minWidth: isMobile() ? '40px' : '48px',
      padding: isMobile() ? '0' : '0px 16px',
      boxShadow: '0 1px 4px 0px rgba(65,109,188,0.5)',
      '&:hover': {
        backgroundColor: '#416DBC',
        textDecoration: 'underline',
      },
      '&:active': {
        backgroundColor: '#416DBC',
        textDecoration: 'underline',
        boxShadow: '0 1px 4px 0px rgba(65,109,188,0.5)',
      },
    },

    extendedIcon: {
      marginRight: isMobile() ? '0' : theme.spacing(1),
    },
  }),
);
