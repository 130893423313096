export enum AnalyticsKeyEnum {
  AGGREGATE_INDICATORS_LOGINS = 'AGGREGATE_INDICATORS_LOGINS',
  AGGREGATE_INDICATORS_LOGINS_COMPLEX = 'AGGREGATE_INDICATORS_LOGINS_COMPLEX',
  AGGREGATE_INDICATORS_LOGINS_COMPLEX_BY_ROLE = 'AGGREGATE_INDICATORS_LOGINS_COMPLEX_BY_ROLE',
  AGGREGATE_INDICATORS_LOGINS_COMPLEX_BY_COUNTRY = 'AGGREGATE_INDICATORS_LOGINS_COMPLEX_BY_COUNTRY',
  PORTFOLIO_INDICATORS_MOST_USED_INDICATORS = 'PORTFOLIO_INDICATORS_MOST_USED_INDICATORS',
  PORTFOLIO_INDICATORS_USE_OF_FILTERS = 'PORTFOLIO_INDICATORS_USE_OF_FILTERS',
  PORTFOLIO_INDICATORS_GENERATED_REPORTS = 'PORTFOLIO_INDICATORS_GENERATED_REPORTS',
  PORTFOLIO_INDICATORS_INDICATOR_CUSTOMIZATION = 'PORTFOLIO_INDICATORS_INDICATOR_CUSTOMIZATION',
  PROJECT_INDICATORS_USE_OF_SECTIONS_COMPLEX = 'PROJECT_INDICATORS_USE_OF_SECTIONS_COMPLEX',
  PROJECT_INDICATORS_GENERATED_REPORTS = 'PROJECT_INDICATORS_GENERATED_REPORTS',
  PROJECT_INDICATORS_MY_PROJECTS = 'PROJECT_INDICATORS_MY_PROJECTS',
  PROJECT_INDICATORS_CALENDAR_SYNCHRONIZATION_WITH_OUTLOOK = 'PROJECT_INDICATORS_CALENDAR_SYNCHRONIZATION_WITH_OUTLOOK',
  PROJECT_INDICATORS_DISBURSEMENT_DATA = 'PROJECT_INDICATORS_DISBURSEMENT_DATA',
  PROJECT_INDICATORS_USE_OF_FILTERS = 'PROJECT_INDICATORS_USE_OF_FILTERS',
  PROJECT_INDICATORS_AGREEMENTS_NUMBERS = 'PROJECT_INDICATORS_AGREEMENTS_NUMBERS',
  PROJECT_INDICATORS_AGREEMENTS_BAR_GRAPH = 'PROJECT_INDICATORS_AGREEMENTS_BAR_GRAPH',
  SUPPORT_INDICATORS_REQUESTS_CUSTOMIZATION_BY_COUNTRY = 'SUPPORT_INDICATORS_REQUESTS_CUSTOMIZATION_BY_COUNTRY',
  SUPPORT_INDICATORS_INQUIRIES_TO_SUPPORT_ABOUT_DATA_ERRORS = 'SUPPORT_INDICATORS_INQUIRIES_TO_SUPPORT_ABOUT_DATA_ERRORS',
  SUPPORT_INDICATORS_SATISFACTION_SURVEY_WITHIN_THE_ONLINE_HELP = 'SUPPORT_INDICATORS_SATISFACTION_SURVEY_WITHIN_THE_ONLINE_HELP',
  AGGREGATE_INDICATORS_USERS_WHO_ENTERED_THE_TOOL = 'AGGREGATE_INDICATORS_USERS_WHO_ENTERED_THE_TOOL',
  AGGREGATE_INDICATORS_USERS_WHO_ENTERED_THE_TOOL_COMPLEX = 'AGGREGATE_INDICATORS_USERS_WHO_ENTERED_THE_TOOL_COMPLEX',
  AGGREGATE_INDICATORS_USERS_WHO_ENTERED_THE_TOOL_COMPLEX_BY_ROLE = 'AGGREGATE_INDICATORS_USERS_WHO_ENTERED_THE_TOOL_COMPLEX_BY_ROLE',
  AGGREGATE_INDICATORS_USERS_WHO_ENTERED_THE_TOOL_COMPLEX_BY_COUNTRY = 'AGGREGATE_INDICATORS_USERS_WHO_ENTERED_THE_TOOL_COMPLEX_BY_COUNTRY',
  PORTFOLIO_INDICATORS_USE_OF_TYPE_PROJECT_FILTER = 'PORTFOLIO_INDICATORS_USE_OF_TYPE_PROJECT_FILTER',
  AGGREGATE_INDICATORS_MOST_USED_SECTION = 'AGGREGATE_INDICATORS_MOST_USED_SECTION',
}
