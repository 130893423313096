import { RequestStatusActionTypes, SET_REQUEST_STATUS } from '../actions/types';
import { RequestStatus } from '../../models/RequestStatus';

const initialState = RequestStatus.Default;

const requestStatusReducer = (state = initialState, action: RequestStatusActionTypes): RequestStatus => {
  switch (action.type) {
    case SET_REQUEST_STATUS:
      return action.payload;
    default:
      return state;
  }
};

export default requestStatusReducer;
