import React, { useEffect } from 'react';
import { defaults } from 'react-chartjs-2';
import '../../../../Util/RounderedBars/RounderedBars.ts';
import './BarChart.scss';
import { isMobile } from '../../../../../services/booleanUtil';
import { setDimensions, getDefaultFontSize, useBasicProperties, haveDataSetLength, noValueClassName } from './Util';
import { ReportImageDisplay } from '../../../../Util/Modals/CustomizableReports/CustomizableReports';
import { BarChartTypes } from './BarChartTypes/BarChartTypes';
import { FooterList } from './FooterList/FooterList';
import { GradientFill } from './GradientFill/GradientFill';
import { CustomSublabels } from './CustomSublabels/CustomSublabels';
import { isReportDetailImg } from '../../CardIndicator';

const updateFontColor = forReport => {
  if (isReportDetailImg(forReport)) {
    defaults.global.defaultFontColor = 'white';
  } else {
    defaults.global.defaultFontColor = '#424d64';
  }
};

defaults.global.defaultFontColor = '#727E8C';

export interface IChartDimensions {
  mobile: { width: number; height: number };
  desktop: { width: number; height: number };
}

interface ReceivedProps {
  type: string;
  data: any;
  showSubLabels: boolean;
  listPage: boolean;
  datasetClick?: Function;
  report?: boolean;
  modalChart?: boolean;
  indicatorKey?: any;
  contentManager?: any;
  chartDimensions?: IChartDimensions;
  chartOptions?: any;
  forReport?: ReportImageDisplay;
}

type Props = ReceivedProps;
const options = {
  tooltips: {
    backgroundColor: '#101319',
    titleFontSize: 14,
    bodyFontSize: 14,
  },
};
const BarChart = (props: Props) => {
  const {
    type,
    data,
    showSubLabels,
    listPage,
    datasetClick,
    report,
    modalChart,
    indicatorKey,
    contentManager,
    chartDimensions,
    chartOptions,
    forReport,
  } = props;

  useEffect(() => {
    updateFontColor(forReport);
  }, [forReport]);

  defaults.global.defaultFontSize = getDefaultFontSize(listPage, isMobile());
  const dimensions = chartDimensions
    ? isMobile()
      ? chartDimensions.mobile
      : chartDimensions.desktop
    : setDimensions(
        isMobile(),
        listPage,
        data && data.subLabels && data.subLabels.length,
        report,
        type,
        modalChart,
        contentManager,
      );

  const { listTranslationLabels, dataQtyProjects, backgroundColorId } = useBasicProperties(data);

  return haveDataSetLength(data) ? (
    <div style={dimensions} className="bar-chart-container">
      <GradientFill type={type} listPage={listPage} />
      <CustomSublabels
        showSubLabels={showSubLabels}
        indicatorKey={indicatorKey}
        data={data}
        forReport={forReport}
        modalChart={modalChart}
        contentManager={contentManager}
      />

      <BarChartTypes
        dimensions={dimensions}
        setOfProps={{
          type,
          data,
          showSubLabels,
          listPage,
          datasetClick,
          report,
          modalChart,
          indicatorKey,
          contentManager,
          chartDimensions,
          chartOptions,
          forReport,
        }}
      />
      <FooterList
        setOfProps={{
          type,
          listTranslationLabels,
          listPage,
          dataQtyProjects,
          indicatorKey,
          datasetClick,
          backgroundColorId,
          contentManager,
          modalChart,
          forReport,
        }}
      />
    </div>
  ) : (
    <div className={noValueClassName(contentManager)}>
      <span className="value-text">-</span>
    </div>
  );
};

export { BarChart };
