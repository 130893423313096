import React, { useState } from 'react';
import './ResponsiveAgenda.scss';
import { useSelector } from 'react-redux';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import MyEvent from '../Agenda/Calendar/MyEvent';

const localizer = momentLocalizer(moment);

type Props = {
  events: any[];
};

const ResponsiveAgenda = ({ events }: Props) => {
  const currentReview = useSelector<any, any>(state => state.currentReview);
  const user = useSelector<any, any>(state => state.user);
  const [selected, setSelected] = useState<any>(new Date(currentReview.startDate));

  const onChange = date => {
    if (
      date.getTime() >= new Date(currentReview.startDate).getTime() &&
      date.getTime() <= new Date(currentReview.endDate).getTime()
    ) {
      setSelected(date);
    }
  };

  return (
    <div className="responsive-agenda-container">
      <DatePicker
        selected={selected}
        onChange={onChange}
        startDate={new Date(currentReview.startDate)}
        endDate={new Date(currentReview.endDate)}
        selectsRange
        inline
        renderCustomHeader={() => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          ></div>
        )}
        locale={user.language && user.language.encodedName ? user.language.encodedName : 'en'}
      />
      <Calendar
        toolbar={false}
        selectable={false}
        localizer={localizer}
        events={events}
        defaultView={Views.DAY}
        date={selected}
        popup={true}
        min={new Date(2010, 9, 19, 9, 0, 0)}
        max={new Date(2050, 9, 28, 18, 0, 0)}
        step={15}
        components={{ event: MyEvent }}
        formats={{ timeGutterFormat: date => localizer.format(date, 'h A') }}
        onNavigate={() => {}}
      />
    </div>
  );
};

export default ResponsiveAgenda;
