import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './BulletItem.module.scss';

const { bulletItem } = styles;
type BulletItemProps = {
  bulletColor: string;
  bulletText: string;
  customStyle?: CSSProperties;
};
export const BulletItem = ({ bulletColor, bulletText, customStyle }: BulletItemProps) => {
  const { t } = useTranslation();
  return (
    <div className={bulletItem} style={customStyle ? customStyle : {}}>
      <span className={styles[bulletColor]} />
      {t(bulletText)}
    </div>
  );
};
