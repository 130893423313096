import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import LabelData from '../../../../Util/LabelData/LabelData';
import { AccordanceType } from '../../../../../models/AccordanceType';
import Comments from '../../../../Util/Comments/Comments';
import { emptyValue } from '../constants';
import LinkText from '../../../../Util/Buttons/LinkText/LinkText';
import { newAccordanceComment, deleteAccordanceComment } from '../../../../../api/accordanceApi';
import { parseToSemester } from '../../../../../services/stringUtil';

type Props = {
  eventData: any;
  accordanceType: AccordanceType;
  createAccordanceReminder: any;
  isCalendarIndicator?: boolean;
};

const AccordanceDetail = (props: Props) => {
  const { accordanceType, createAccordanceReminder, isCalendarIndicator } = props;
  let eventData = props.eventData;

  if (!eventData) {
    eventData = {
      description: emptyValue,
      criticalIssueDescription: emptyValue,
      revisionDate: emptyValue,
      criticalIssue: {
        name: emptyValue,
        categoryName: emptyValue,
      },
      possibleAction: { name: emptyValue },
      personInCharge: { name: emptyValue, lastName: emptyValue },
      expirationDate: emptyValue,
      accordanceComments: [],
      id: 0,
      project: { code: emptyValue, name: emptyValue, loanNumber: emptyValue },
    };
  }

  const {
    description,
    criticalIssueDescription,
    revisionDate,
    criticalIssue,
    possibleAction,
    personInCharge,
    expirationDate,
    accordanceComments,
    id,
    project,
    supervisionAction,
  } = eventData;
  const { t } = useTranslation();

  return (
    <>
      {isCalendarIndicator && <LabelData label={t('code')} data={`${project.code} ${project.name}`} reverse={true} />}
      <LabelData label={t('operation')} data={project.loanNumber} reverse={true} />
      {renderDescriptionValues(t, 'accordance_description', description)}
      <LabelData label={t('critical_issue')} data={criticalIssue.name} reverse={true} />
      {renderDescriptionValues(t, 'description_of_the_critical_issue', criticalIssueDescription)}
      <LabelData label={t('category')} data={criticalIssue.categoryName} reverse={true} />
      <LabelData
        label={
          accordanceType === AccordanceType.Revision || accordanceType === AccordanceType.InterGroup
            ? t('accordance')
            : t('action')
        }
        data={possibleAction?.name}
        reverse={true}
      />
      <div style={{ display: 'flex' }}>
        <LabelData
          label={t('due_date')}
          data={expirationDate ? moment(expirationDate).format('DD/MM/YYYY') : emptyValue}
          reverse={true}
        />
        <LinkText
          label={t('create_reminder')}
          onClick={createAccordanceReminder}
          customStyle={{ margin: '0px 0px 3px 10px' }}
        />
      </div>
      <div>
        {accordanceType === AccordanceType.Monitoring && (
          <LabelData
            label={t('supervision_action')}
            data={supervisionAction && supervisionAction.description ? supervisionAction.description : '-'}
            reverse={true}
          />
        )}
      </div>
      <div
        style={
          accordanceType === AccordanceType.Revision || accordanceType === AccordanceType.InterGroup
            ? { display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }
            : {}
        }
      >
        <LabelData
          label={t('bid_responsible')}
          data={`${personInCharge.name} ${personInCharge.lastName}`}
          reverse={true}
        />
        {(accordanceType === AccordanceType.Revision || accordanceType === AccordanceType.InterGroup) &&
          addLastHorizontalFields(accordanceType, revisionDate, t)}
      </div>
      <Comments
        comments={accordanceComments || []}
        readonly={true}
        submitComment={comment => newAccordanceComment(id, comment)}
        submitDeleteComment={commentId => deleteAccordanceComment(id, commentId)}
      />
    </>
  );
};
export default AccordanceDetail;

const addLastHorizontalFields = (accordanceType, revisionDate, t) => {
  return (
    <>
      <div style={{ width: 50, height: 10, display: 'flex' }} />
      <LabelData
        label={
          accordanceType === AccordanceType.Revision
            ? t('revision_accordance')
            : `${t('just_revision_accordance')} ${t('inter_group')}`
        }
        data={setRevisionDataByRevisionType(accordanceType, revisionDate, t)}
        reverse={true}
      />
    </>
  );
};

const setRevisionDataByRevisionType = (accordanceType, revisionDate, t) => {
  if (revisionDate) {
    return accordanceType === AccordanceType.Revision ? (
      moment(revisionDate).format('DD/MM/YYYY')
    ) : (
      <span style={{ textTransform: 'capitalize' }}>{parseToSemester(revisionDate, t)}</span>
    );
  }
  return emptyValue;
};

const renderDescriptionValues = (t, translationLabel, data) => {
  return <LabelData label={t(translationLabel)} data={data ? data : '-'} reverse={true} />;
};
