import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Table.module.scss';
import './Table.scss';
import { renderDateOrSlash } from '../../../../services/stringUtil';
import { Pagination } from '@material-ui/lab';
import '../../../Util/Pagination/Pagination.scss';
import usePaginationSort from '../../../../hooks/usePaginationSort';
import useTable from '../../../../hooks/useTable';
import { PROJECTS_PARTIAL_CANCELLATIONS } from '../../../../services/sessionFiltersIndicatorDetailHelper';
import { CriteriaType } from '../../../../models/PaginationIndicator';
import { ProjectCounter } from '../../ProjectCounter/ProjectCounter';
import { IndicatorKeyEnum } from '../../../../models/IndicatorKeyEnum';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { EarlyWarningsTrafficLight, TrafficLightEnum } from '../../EarlyWarningsTrafficLight/EarlyWarningsTrafficLight';
import { basicFirstColumns, HeaderTableColumn } from '../../../../models/HeaderTableColumn';
import { showEarlyWarning } from '../../PortfolioDetailTablesUtil';
import { addFilterConditionOnIgSection, getUniqueCodeProjectsList } from '../../EarlyWarningsFilters/Util';
import { isDiferentToIGLower } from '../../EarlyWarningsTimeLapse/Util';
import { ProjectsListSkeleton } from '../../../LoadersSkeletons/ProjectsListSkeleton/ProjectsListSkeleton';
import { Tooltip } from '@material-ui/core';
import PaginationTable from '../../PaginationTable/PaginationTable';
import { withStyles } from '@material-ui/core/styles';
import { ExcelDownloadButton } from '../../../Util/ExcelDownloadButton/ExcelDownloadButton';
import useDataNormalize from '../../../../hooks/useDataNormalize';
import { FilterTables } from '../../../Filter/FilterTables/FilterTables';

const BlackTooltip = withStyles({
  tooltip: {
    //   color: '$ci-color-greyscale-90',
    backgroundColor: '#101319',
    fontSize: '16px',
    fontFamily: 'Rubik',
    lineHeight: '20px',
    padding: '8px 12px',
    borderRadius: '8px',
  },
  arrow: {
    fontSize: 20,
    color: '#4A4A4A',
    '&::before': {
      backgroundColor: '#101319',
    },
  },
})(Tooltip);

enum FilterColorSelected {
  NONE_SELECTED = -1,
  GREEN = 0,
  YELLOW = 1,
  RED = 2,
}

const headersPartialCancellation = [
  'id',
  'last_project',
  'last_operation',
  'name',
  'original_idb_amount',
  'current_idb_amount',
  'percentage_cancelled',
  'total_amount_cancelled',
  'financial_last_disbursement_current',
];

const selectedKeysCancellation = [
  'id',
  'code',
  'loanNumber',
  'title',
  'field1',
  'field2',
  'field3',
  'field4',
  'field5',
];

const {
  container,
  tableHeaderWithoutEarlyAlerts,
  tableHeaderWithEarlyAlerts,
  headerTitleClickableWithCentered,
  tableRowWithoutEarlyAlerts,
  tableRowWithEarlyAlerts,
  headerTitle,
  rowValue,
  rowList,
  rowValueName,
  fw,
  lastColumn,
  headerTitleClickable,
  rowValueCenterd,
  rowValueSpan,
  rowValueCenterdUppercase,
  borderTable,
} = styles;

const handleMonthDiff = (filterColor: FilterColorSelected, percentageCancelled: string) => {
  const percentageNumber = parseFloat(percentageCancelled);
  if (filterColor === FilterColorSelected.NONE_SELECTED) {
    return true;
  }
  if (filterColor === FilterColorSelected.YELLOW) {
    return percentageNumber > 0 && percentageNumber <= 15;
  } else if (filterColor === FilterColorSelected.RED) {
    return percentageNumber > 15;
  }
  return percentageNumber <= 0;
};

const getProjectsQuantityByEarlyWarningType = (projectsExpired, projectTypeFilterOnDetail) => {
  const filterCondition = isDiferentToIGLower(projectTypeFilterOnDetail);
  const uniqueProjectsExpired = filterCondition
    ? getUniqueCodeProjectsList(projectsExpired)
    : getUniqueCodeProjectsList(projectsExpired).filter(p => isDiferentToIGLower(p.field6));
  let green = 0;
  let yellow = 0;
  let red = 0;

  for (const p of uniqueProjectsExpired) {
    if (parseFloat(p.field3) <= 0) {
      green++;
    } else if (parseFloat(p.field3) > 0 && parseFloat(p.field3) <= 15) {
      yellow++;
    } else if (parseFloat(p.field3) > 15) {
      red++;
    }
  }
  return [green, yellow, red];
};

const handleEarlyWarningColor = (percentageCancelled: string) => {
  const percentageNumber = parseFloat(percentageCancelled);

  if (percentageNumber > 0 && percentageNumber <= 15) {
    return TrafficLightEnum.YELLOW;
  } else if (percentageNumber > 15) {
    return TrafficLightEnum.RED;
  }
  return TrafficLightEnum.GREEN;
};

type Props = {
  earlyWarningsTab: boolean[];
  projectsQuantities: Function;
  loading?: boolean;
};
export const WithPartialCancellationsTable = ({ projectsQuantities, earlyWarningsTab, loading }: Props) => {
  const { t } = useTranslation();
  const { handleClick, projects } = useTable();

  const { projectTypeFilterOnDetail } = useSelector<any, any>(state => state.indicatorDetailFilter);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [dataFilter, setDataFilter] = useState<any>(projects);
  const [dataFilterBtn, setDataFilterBtn] = useState<any>();

  useEffect(() => {
    setDataFilter(projects);
  }, [projects]);

  const { handlePaginationChange, handleSort, currentList, pagesCount, currentPage } = usePaginationSort({
    projects: dataFilter,
    defaultSortCriteria: 'field2',
    pageCount: rowsPerPage,
  });

  useEffect(() => {
    const filterNewProjects = [...projects];
    projectsQuantities(getProjectsQuantityByEarlyWarningType(projects, projectTypeFilterOnDetail));
    setDataFilter(filterNewProjects);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects]);

  useEffect(() => {
    setDataFilter(
      projects.filter(p => {
        return (
          handleMonthDiff(earlyWarningsTab.indexOf(true), p.field3) &&
          addFilterConditionOnIgSection(projectTypeFilterOnDetail, earlyWarningsTab, p.field6)
        );
      }),
    );
    if (earlyWarningsTab.includes(true)) {
      setDataFilterBtn(
        projects.filter(p => {
          return (
            handleMonthDiff(earlyWarningsTab.indexOf(true), p.field3) &&
            addFilterConditionOnIgSection(projectTypeFilterOnDetail, earlyWarningsTab, p.field6)
          );
        }),
      );
    } else {
      setDataFilterBtn([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [earlyWarningsTab]);

  const handleClickOnProject = project => {
    const storage = sessionStorage.getItem(PROJECTS_PARTIAL_CANCELLATIONS);
    storage &&
      sessionStorage.setItem(
        PROJECTS_PARTIAL_CANCELLATIONS,
        JSON.stringify({ ...JSON.parse(storage), changeProjectType: false }),
      );
    handleClick(project);
  };

  const withPartialCancellationsHeaderTableSetup: HeaderTableColumn[] = [
    ...basicFirstColumns(headerTitleClickable),
    {
      className: headerTitleClickableWithCentered,
      criteria: 'field1',
      criteriaType: CriteriaType.number,
      label: 'original_idb_amount',
    },
    {
      className: headerTitleClickableWithCentered,
      criteria: 'field2',
      criteriaType: CriteriaType.number,
      label: 'current_idb_amount',
    },
    {
      className: headerTitleClickableWithCentered,
      criteria: 'field3',
      criteriaType: CriteriaType.decimalNumber,
      label: 'percentage_cancelled',
    },
    {
      className: headerTitleClickableWithCentered,
      criteria: 'field4',
      criteriaType: CriteriaType.number,
      label: 'total_amount_cancelled',
    },
    {
      className: headerTitleClickableWithCentered,
      criteria: 'field5',
      criteriaType: CriteriaType.date,
      label: 'deadline_for_final_disbursement',
    },
    {
      className: headerTitleClickableWithCentered,
      criteria: 'field3',
      criteriaType: CriteriaType.number,
      label: 'early_warnings',
      hide: !showEarlyWarning(projectTypeFilterOnDetail),
    },
  ];

  const dataExcel = useDataNormalize(headersPartialCancellation, selectedKeysCancellation, dataFilter);

  return loading ? (
    <div className="with-partial-cancellations-table__loading__container">
      <ProjectsListSkeleton />
    </div>
  ) : projects?.length ? (
    <div className={container}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <ExcelDownloadButton
          headers={headersPartialCancellation}
          selectedKeys={selectedKeysCancellation}
          dataExcel={dataExcel}
        />
        <FilterTables
          projects={projects.filter(p => {
            return (
              handleMonthDiff(earlyWarningsTab.indexOf(true), p.field3) &&
              addFilterConditionOnIgSection(projectTypeFilterOnDetail, earlyWarningsTab, p.field6)
            );
          })}
          keysFilter={selectedKeysCancellation}
          fnDataFilter={setDataFilter}
          disabledNumber={currentPage}
          dataFilterBtn={dataFilterBtn}
          indicatorKey={IndicatorKeyEnum.PROJECTS_PARTIAL_CANCELLATIONS}
          filterBtn={earlyWarningsTab}
        />
      </div>

      <div className={borderTable}>
        <div
          className={
            showEarlyWarning(projectTypeFilterOnDetail) ? tableHeaderWithEarlyAlerts : tableHeaderWithoutEarlyAlerts
          }
        >
          {withPartialCancellationsHeaderTableSetup.map(wpc => {
            const { className, criteria, criteriaType, label, hide } = wpc;
            return (
              !hide && (
                <h4
                  className={className}
                  onClick={() => handleSort(criteria, criteriaType)}
                  key={`${className}-${criteria}-${label}`}
                >
                  {t(label)}
                </h4>
              )
            );
          })}
        </div>

        <ul className={rowList}>
          {dataFilter?.length === 0
            ? dataFilter?.map(project => {
                const {
                  loanNumber,
                  code,
                  frontendId,
                  title,
                  field1: originalIdbAmount,
                  field2: currentIdbAmount,
                  field3: percentageCancelled,
                  field4: totalAmountCancelled,
                  field5: deadlineForFinalDisbursement,
                  field6: projectProductType,
                } = project;
                return (
                  <li
                    className={
                      showEarlyWarning(projectTypeFilterOnDetail) ? tableRowWithEarlyAlerts : tableRowWithoutEarlyAlerts
                    }
                    key={`${loanNumber}${code}${frontendId}`}
                  >
                    <BlackTooltip title={t('go_to_project_info')} arrow>
                      <p className={`${rowValue} link-project`} onClick={() => handleClickOnProject(project)}>
                        {code}
                      </p>
                    </BlackTooltip>
                    <p className={rowValue}>{loanNumber}</p>
                    <BlackTooltip title={title || ''} placement="bottom" enterDelay={500} leaveDelay={100} arrow>
                      <p className={rowValueName}>{title}</p>
                    </BlackTooltip>
                    <p className={rowValueCenterd}>{handleFloatNumberValueOrSlash(originalIdbAmount)}</p>
                    <p className={rowValueCenterd}>{handleFloatNumberValueOrSlash(currentIdbAmount)}</p>
                    <p className={rowValueCenterd}>{handlePercentageNumberValueOrSlash(percentageCancelled, '%')}</p>
                    <p className={rowValueCenterd}>{handleFloatNumberValueOrSlash(totalAmountCancelled)}</p>
                    <p className={rowValueCenterdUppercase}>{renderDateOrSlash(deadlineForFinalDisbursement)}</p>

                    {showEarlyWarning(projectTypeFilterOnDetail) && (
                      <span className={rowValueSpan}>
                        <EarlyWarningsTrafficLight
                          earlyWarningColor={handleEarlyWarningColor(percentageCancelled)}
                          projectProductType={projectProductType}
                        />
                      </span>
                    )}
                  </li>
                );
              })
            : currentList?.map(project => {
                const {
                  loanNumber,
                  code,
                  frontendId,
                  title,
                  field1: originalIdbAmount,
                  field2: currentIdbAmount,
                  field3: percentageCancelled,
                  field4: totalAmountCancelled,
                  field5: deadlineForFinalDisbursement,
                  field6: projectProductType,
                } = project;
                return (
                  <li
                    className={
                      showEarlyWarning(projectTypeFilterOnDetail) ? tableRowWithEarlyAlerts : tableRowWithoutEarlyAlerts
                    }
                    key={`${loanNumber}${code}${frontendId}`}
                  >
                    <BlackTooltip title={t('go_to_project_info')} arrow>
                      <p className={`${rowValue} link-project`} onClick={() => handleClickOnProject(project)}>
                        {code}
                      </p>
                    </BlackTooltip>
                    <p className={rowValue}>{loanNumber}</p>
                    <BlackTooltip title={title || ''} placement="bottom" enterDelay={500} leaveDelay={100} arrow>
                      <p className={rowValueName}>{title}</p>
                    </BlackTooltip>
                    <p className={rowValueCenterd}>{handleFloatNumberValueOrSlash(originalIdbAmount)}</p>
                    <p className={rowValueCenterd}>{handleFloatNumberValueOrSlash(currentIdbAmount)}</p>
                    <p className={rowValueCenterd}>{handlePercentageNumberValueOrSlash(percentageCancelled, '%')}</p>
                    <p className={rowValueCenterd}>{handleFloatNumberValueOrSlash(totalAmountCancelled)}</p>
                    <p className={rowValueCenterdUppercase}>{renderDateOrSlash(deadlineForFinalDisbursement)}</p>

                    {showEarlyWarning(projectTypeFilterOnDetail) && (
                      <span className={rowValueSpan}>
                        <EarlyWarningsTrafficLight
                          earlyWarningColor={handleEarlyWarningColor(percentageCancelled)}
                          projectProductType={projectProductType}
                        />
                      </span>
                    )}
                  </li>
                );
              })}
        </ul>
      </div>
      <ProjectCounter
        arrayToCount={dataFilter}
        countProjects={true}
        indicatorKey={IndicatorKeyEnum.PROJECTS_PARTIAL_CANCELLATIONS}
      />
      <div className="pagination-style">
        <PaginationTable fnSetRows={setRowsPerPage} rows={rowsPerPage} numProyects={dataFilter?.length} />
        {dataFilter?.length !== 0 ? (
          <Pagination count={pagesCount} page={currentPage} onChange={handlePaginationChange} />
        ) : null}
      </div>
    </div>
  ) : null;
};

const handleFloatNumberValueOrSlash = value => {
  if (value === '0' || value === '0.00' || !value) {
    return '-';
  }
  return (
    <NumberFormat
      value={parseFloat(value)}
      displayType={'text'}
      className={'value'}
      thousandSeparator={'.'}
      decimalSeparator={','}
    />
  );
};

const handlePercentageNumberValueOrSlash = (value, suffix) => {
  if (value === '0' || value === '0.0000' || !value) {
    return '-';
  }
  return (
    <NumberFormat
      value={parseFloat(value)}
      displayType={'text'}
      className={'value'}
      thousandSeparator={','}
      decimalSeparator={'.'}
      suffix={suffix}
      decimalScale={2}
      fixedDecimalScale={true}
    />
  );
};
