import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ClickableText from '../../ProjectDetail/ClickableText/ClickableText';
import { setCurrentReview as setCurrentReviewRedux } from '../../../redux/actions/currentReview';
import './ReviewsActionLinks.scss';
import { getAccordancesOfReview } from '../../../api/reviewsApi';

export const SeeAccordances = () => {
  const currentReview = useSelector<any, any>(state => state.currentReview);
  const { id } = currentReview;
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [hasAccordances, setHasAccordances] = useState<boolean>(false);

  const getReviewccordances = async reviewId => {
    const reviewAccordances = await getAccordancesOfReview(reviewId);
    setHasAccordances(reviewAccordances.length > 0);
  };
  useEffect(() => {
    getReviewccordances(currentReview.id);
  }, [currentReview]);

  const handleViewAccordances = () => {
    dispatch(setCurrentReviewRedux(currentReview));
    history.push(`/reviewaccordances?id=${id}`);
  };

  return hasAccordances ? (
    <div className="reviews-action-links-container see-accordances">
      <ClickableText
        text={` ${t('view_accordances')}`}
        onClick={handleViewAccordances}
        fontSize={12}
        textTransform="lowercase"
      />
    </div>
  ) : null;
};
