import React from 'react';
import './SearchUsers.scss';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTranslation } from 'react-i18next';
import { searchUser } from '../../../api/userApi';
import User from '../../../models/User';
import { makeStyles, Theme } from '@material-ui/core/styles';
const useStyles = makeStyles((theme: Theme) => ({
  inputRoot: {
    border: '1px solid #ed5a4d !important',
  },
}));

type Props = {
  selected: any;
  itemSelected: Function;
  error?: boolean;
  projectId?: number;
};

export const displayLabel = (option: any) => {
  if (option.role !== null) {
    // return `${option.name} ${option.lastName} - ${option?.role?.name}`;
    return `${option.name} ${option.lastName}`;
  }
  return `${option.name} ${option.lastName}`;
};

const SearchUsers = (props: Props) => {
  const { t } = useTranslation();
  const [options, setOptions] = React.useState<User[]>([]);
  const { selected, itemSelected, error, projectId } = props;
  const classes = useStyles();

  const handleSelected = (e: Object, item: any) => {
    itemSelected(item);
  };

  const handleInputChange = async (e: Object, value: string, reason: string) => {
    if (value && value.length > 1) {
      try {
        const data = await searchUser(value, projectId);
        setOptions(data);
      } catch (err) {}
    }
  };

  return (
    <div className="search-users-container">
      <Autocomplete
        id="search-users"
        options={options}
        getOptionLabel={displayLabel}
        value={selected}
        onChange={handleSelected}
        renderInput={params => <TextField placeholder={t('new_select_user_two')} {...params} fullWidth />}
        onInputChange={handleInputChange}
        noOptionsText={t('no_results')}
        classes={error ? classes : {}}
        getOptionSelected={(option, value) => option.id === value.id}
      />
    </div>
  );
};

export default SearchUsers;
