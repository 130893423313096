import React from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { multiSelectStyle, DropdownIndicator } from '../FilterComponent.Styles';
import { sortAlphabetically } from '../../../services/sortsUtil';
import SelectOption from '../../../models/SelectOption';
import { RolesAnalyticsIdEnum } from '../../../models/RolesAnalyticsIdEnum';
import useFilters from '../../../hooks/useFilters';

const RoleFilter = () => {
  const { filterAnalytics, dispatch, setFilterAnalytics } = useFilters();
  const { t } = useTranslation();

  const rolesOptions = (): SelectOption[] =>
    Object.keys(RolesAnalyticsIdEnum)
      .filter(k => typeof RolesAnalyticsIdEnum[k as any] === 'string')
      .map(r => {
        return { id: parseInt(r), label: t(`role_analytics_id_${r}`), value: `role_analytics_id_${r}` };
      })
      .sort(sortAlphabetically('label', 'asc'));

  const selectHandler = value => {
    if (value === null) {
      value = [];
    }
    dispatch(
      setFilterAnalytics({
        ...filterAnalytics,
        roleId: value,
      }),
    );
  };

  return (
    <div>
      <Select
        id="rolesSelect"
        components={{ DropdownIndicator }}
        name="roles"
        options={rolesOptions()}
        className="basic-multi-select"
        classNamePrefix="All"
        placeholder={t('all')}
        onChange={value => selectHandler(value)}
        styles={multiSelectStyle}
        defaultValue={filterAnalytics.roleId}
        isMulti
      />
    </div>
  );
};

export default RoleFilter;
