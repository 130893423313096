import { SET_FILTER } from './types';
import FilterProjects from '../../models/FilterProjects';
import { CTFiltersEnum } from '../../models/CTFilters';
import { ProjectProductTypeFilters } from '../../models/ProjectProductTypeFilters';
import { PmrClassification } from '../../models/PmrClassification';
import { setFilterIndicators } from './filterIndicators';

export function setFilter(filter: FilterProjects) {
  return {
    type: SET_FILTER,
    payload: filter,
  };
}

const ctFiltersDefaultValues = {
  executionFilter: null,
  taxonomyFilter: null,
  excludeRegionals: false,
};

const pmrDefaultValue = [
  { PmrClassification: PmrClassification.Green, selected: false },
  { PmrClassification: PmrClassification.Yellow, selected: false },
  { PmrClassification: PmrClassification.Red, selected: false },
  { PmrClassification: PmrClassification.Na, selected: false },
];

const disbursementProjectionValues = {
  disbursementProjectionFrom: 0,
  disbursementProjectionTo: 0,
};

const resetCTFiltersState = (indicatorCall, filter, value) => {
  return (dispatch: any) => {
    indicatorCall
      ? dispatch(
          setFilterIndicators({
            ...filter,
            common: {
              ...filter.common,
              projectTypeFilters: value,
              executionFilter: null,
              taxonomyFilter: null,
              excludeRegionals: false,
            },
          }),
        )
      : dispatch(
          setFilter({
            ...filter,
            common: {
              ...filter.common,
              projectTypeFilters: value,
              executionFilter: null,
              taxonomyFilter: null,
              excludeRegionals: false,
            },
          }),
        );
  };
};

const resetPmrAndDisbursementProjection = (indicatorCall, filter, value) => {
  return (dispatch: any) => {
    indicatorCall
      ? dispatch(
          setFilterIndicators({
            ...filter,
            common: {
              ...filter.common,
              projectTypeFilters: value,
              pmrId: [...pmrDefaultValue],
              ...disbursementProjectionValues,
            },
          }),
        )
      : dispatch(
          setFilter({
            ...filter,
            common: {
              ...filter.common,
              projectTypeFilters: value,
              pmrId: [...pmrDefaultValue],
              ...disbursementProjectionValues,
            },
          }),
        );
  };
};

const resetAllSubFilters = (indicatorCall, filter, value) => {
  return (dispatch: any) => {
    indicatorCall
      ? dispatch(
          setFilterIndicators({
            ...filter,
            common: {
              ...filter.common,
              projectTypeFilters: value,
              ...ctFiltersDefaultValues,
              pmrId: [...pmrDefaultValue],
              ...disbursementProjectionValues,
            },
          }),
        )
      : dispatch(
          setFilter({
            ...filter,
            common: {
              ...filter.common,
              projectTypeFilters: value,
              ...ctFiltersDefaultValues,
              pmrId: [...pmrDefaultValue],
              ...disbursementProjectionValues,
            },
          }),
        );
  };
};

const resetSubFiltersAndSetCTFilters = (filterToReset, indicatorCall, filter, value) => {
  if (filterToReset === ProjectProductTypeFilters.Loan) {
    return resetCTFiltersState(indicatorCall, filter, value);
  } else if (filterToReset === ProjectProductTypeFilters.CT) {
    return resetPmrAndDisbursementProjection(indicatorCall, filter, value);
  }
  return resetAllSubFilters(indicatorCall, filter, value);
};

const setProjectTypeFilterWithoutReset = (indicatorCall, filter, value) => {
  return (dispatch: any) => {
    indicatorCall
      ? dispatch(
          setFilterIndicators({
            ...filter,
            common: {
              ...filter.common,
              projectTypeFilters: value,
            },
          }),
        )
      : dispatch(
          setFilter({
            ...filter,
            common: {
              ...filter.common,
              projectTypeFilters: value,
            },
          }),
        );
  };
};

export const dispatchProjectTypeFilterState = (filter, value, reset, filterToReset, indicatorCall) => {
  const lowerOrGreaterWasChecked =
    filterToReset === ProjectProductTypeFilters.IG_lower_3 || filterToReset === ProjectProductTypeFilters.IG_greater_3;

  if (reset) {
    if (!lowerOrGreaterWasChecked) {
      return resetSubFiltersAndSetCTFilters(filterToReset, indicatorCall, filter, value);
    } else {
      return setProjectTypeFilterWithoutReset(indicatorCall, filter, value);
    }
  } else {
    return setProjectTypeFilterWithoutReset(indicatorCall, filter, value);
  }
};

export const dispatchCTFiltersState = (filter, value, ctFilterType, indicatorCall) => {
  let newFilterState;
  switch (ctFilterType) {
    case CTFiltersEnum.EXECUTION_FILTER:
      newFilterState = { executionFilter: value };
      break;
    case CTFiltersEnum.TAXONOMY_FILTER:
      newFilterState = { taxonomyFilter: value };
      break;
    case CTFiltersEnum.EXCLUDE_REGIONAL:
      newFilterState = { excludeRegionals: value };
      break;
  }

  return (dispatch: any) => {
    indicatorCall
      ? dispatch(
          setFilterIndicators({
            ...filter,
            common: {
              ...filter.common,
              ...newFilterState,
            },
          }),
        )
      : dispatch(
          setFilter({
            ...filter,
            common: {
              ...filter.common,
              ...newFilterState,
            },
          }),
        );
  };
};
