import React from 'react';
import { urlIsImage } from '../../../../../services/booleanUtil';
import { ReactComponent as LogoCartera } from '../../../../../assets/logo-cartera.svg';
import CustomCheckbox from '../../../CustomCheckbox/CustomCheckbox';
import './CustomSortableItem.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  indicator: any;
  onIncludeDetailsChange: any;
};

const CustomSortableItem = ({ indicator, onIncludeDetailsChange }: Props) => {
  const year = new Date().getFullYear();
  const { t } = useTranslation();

  return (
    <div className="custom-card-container" key={indicator.id}>
      <div className="custom-img">
        {indicator.image && urlIsImage(indicator.image) ? (
          <img className="custom-img-container" alt="" src={indicator.image} />
        ) : (
          <LogoCartera />
        )}
      </div>
      <div className="custom-texts-container">
        <div className="custom-card-title-container">
          {indicator.subtitle && <div className="custom-subtitle">{indicator.subtitle}</div>}
          <div className="custom-title" title={indicator.title}>{`${indicator.title} ${
            indicator.year ? year : ''
          }`}</div>
        </div>
      </div>
      <CustomCheckbox
        handleOnClick={includeDetails => onIncludeDetailsChange(indicator.id, includeDetails)}
        isChecked={indicator.includeDetails}
        labelText={t('customizable_report_step_4_include_detail')}
      />
    </div>
  );
};

export default CustomSortableItem;
