import React from 'react';
import { useTranslation } from 'react-i18next';
import { PcrBullets } from '../../../../models/PcrNextPresentation';
import PcrNextPresentationChartElement from '../../../Portfolio/CardIndicator/Cards/ProgressChart/PcrNextPresentationChartElement/PcrNextPresentationChartElement';
import { PcrBulletsComponent } from '../PcrBulletsComponent/PcrBulletsComponent';
import styles from './PcrNextPresentationChart.module.scss';

type Props = {
  data?: any;
  title: string;
  handleClick: Function;
  selected?: boolean;
  bulletsData: PcrBullets[];
  unselectable?: boolean;
};
const { container, header, headerTitle, unselectedContainer, unselectableContainer } = styles;

const PcrNextPresentationChart = ({ data, title, selected, handleClick, bulletsData, unselectable }: Props) => {
  const { t } = useTranslation();
  return (
    <div
      className={unselectable ? unselectableContainer : selected ? container : unselectedContainer}
      onClick={!unselectable ? () => handleClick() : () => {}}
    >
      <div className={header}>{<h4 className={headerTitle}>{t(title)}</h4>}</div>
      <div style={{ height: '155px' }}>
        <PcrNextPresentationChartElement isDetail={true} data={data} />
      </div>
      <PcrBulletsComponent bulletsData={bulletsData} />
    </div>
  );
};

export default PcrNextPresentationChart;
