import React from 'react';
import { useTranslation } from 'react-i18next';
import User from '../../../models/User';
import './LabelSelect.scss';
import CustomSelect from '../../Util/CustomSelect/CustomSelect';
import SelectOption from '../../../models/SelectOption';
import { languagesList } from '../../../models/LanguagesList';

interface IProps extends React.HTMLProps<JSX.Element> {
  user: User;
  readonly?: boolean;
  type: string;
  disabled?: boolean;
  setOption?: (arg0: SelectOption) => void;
}

const LabelSelect = ({ user, readonly, type, setOption, disabled }: IProps) => {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = React.useState(
    languagesList.find(item => item.value === user.language.encodedName) || { value: 'es', label: 'spanish', id: 1 },
  );

  const handleSelected = (item: SelectOption) => {
    setSelectedOption(item);
    if (setOption) {
      setOption(item);
    }
  };
  return (
    <div className="label-select-container">
      <p className="label">{t(type)}</p>
      {readonly ? (
        <div className="readonly-select">
          <p>{type === 'role' ? user.role.name : user.country.name}</p>
        </div>
      ) : (
        <CustomSelect
          options={languagesList}
          selectedOption={selectedOption}
          handleSelected={handleSelected}
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default LabelSelect;
