import React from 'react';
import { Bar, HorizontalBar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { CardTypesEnum } from '../../../../../../models/CardTypesEnum';
import { isCalibri, isRubik } from '../../../../../../services/stringUtil';
import { getElementAtEvent, renderGridAndTicks, renderTooltips, setXAxesDisplay, useBasicProperties } from '../Util';
import { renderAnalyticsTooltips } from '../UtilAnalytics';
import { IndicatorKeyEnum, IndicatorKeyEnum as key } from '../../../../../../models/IndicatorKeyEnum';

type Props = {
  setOfProps: any;
  dimensions: any;
};

export const BarChartTypes = ({ setOfProps, dimensions }: Props) => {
  const { t } = useTranslation();

  const { type, data, listPage, datasetClick, report, indicatorKey, contentManager, chartOptions, forReport } =
    setOfProps;

  const { cornerRadius, haveGridLines } = useBasicProperties(data);
  const options = chartOptions
    ? {
      ...chartOptions,
      animation: {
        duration: forReport ? 0 : 1000,
      },
      ...(indicatorKey !== IndicatorKeyEnum.SUPERVISION_PLAN_BUDGET_STATUS && {
        tooltips: {
          displayColors: false,
          backgroundColor: '#101319',
          titleFontSize: 14,
          bodyFontSize: 14,
        },
      }),
    }
    : {
      plugins:
        report && type === CardTypesEnum.VERTICAL
          ? {
            datalabels: {
              labels: {
                totalApproved: {
                  formatter: (value, ctx) => {
                    let sum = 0;
                    const dataArr = ctx.chart.data.datasets[0].data;
                    dataArr.map(data => (sum += data));
                    return (value / 1000).toFixed(1);
                  },
                  anchor: 'end',
                  clamp: true,
                  align: 'end',
                  offset: 0,
                  padding: 1,
                  backgroundColor: 'white',
                  borderRadius: 4,
                  color: 'black',
                  clip: true,
                  font: {
                    size: 8,
                    family: isCalibri(),
                  },
                },

                qtyProjects: {
                  formatter: (value, ctx) => {
                    return ctx.chart.data.datasets[0].quantityProjects[ctx.dataIndex];
                  },
                  anchor: 'start',
                  align: 'bottom',
                  offset: 15,
                  padding: 1.5,
                  color: '#727E8C',

                  font: {
                    size: 8,
                    family: isCalibri(),
                  },
                },
              },
            },
          }
          : {
            datalabels:
              type === CardTypesEnum.VERTICAL_SIMPLE
                ? {
                  labels: {
                    typeProject: {
                      formatter: (value, ctx) => ctx.chart.data.datasets[0].label[ctx.dataIndex],
                      rotation: 270,
                      anchor: 'start',
                      padding: 2,
                      align: 'left',
                      offset: 2,
                      color: '#727E8C',
                      font: {
                        size: 9,
                        family: isRubik(),
                        weight: 600,
                      },
                    },
                  },
                }
                : { display: false },
          },
      legend: {
        display: false,
      },
      tooltips: contentManager ? renderAnalyticsTooltips(indicatorKey, t) : renderTooltips(indicatorKey, t),

      scales: {
        xAxes: [
          {
            display: setXAxesDisplay(type),
            stacked: true,
            ticks: {
              beginAtZero: true,
            },
            gridLines: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            display: type === CardTypesEnum.HORIZONTAL,
            stacked: true,
            ticks: {
              beginAtZero: true,
              fontColor: '#101319',
              fontSize: 10,

            },
            gridLines: {
              display: false,
            },
          },
          renderGridAndTicks(type, listPage, haveGridLines, data),
        ],
      },

      cornerRadius,
      animation: {
        duration: forReport ? 0 : 1000,
      },
    };

  switch (type) {
    case CardTypesEnum.VERTICAL:
    case CardTypesEnum.HALF_SIZE_VERTICAL:
    case CardTypesEnum.MIXED_VERTICAL_BAR_LINE:
    case CardTypesEnum.VERTICAL_SIMPLE:
    case CardTypesEnum.VERTICAL_SKYBLUE_ORANGE_BAR:
      return (
        <Bar
          data={data}
          options={options}
          {...dimensions}
          getElementAtEvent={element => getElementAtEvent(element, datasetClick)}
        />
      );
    case CardTypesEnum.HORIZONTAL:
      return (
        <HorizontalBar
          data={data}
          options={options}
          {...dimensions}
          getElementAtEvent={element => getElementAtEvent(element, datasetClick, false, data)}
        />
      );
    default:
      return null;
  }
};
