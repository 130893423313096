import React from 'react';
import './ProjectDetail.scss';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import SaveButton from '../../components/Util/Buttons/SaveButton/SaveButton';
import { setReviewSelectedProject } from '../../redux/actions/reviewProjectsSelectedRows';

type Props = {
  projectId: number;
};

const SelectReviewProject = ({ projectId }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const selectAndGoBack = () => {
    dispatch(setReviewSelectedProject(projectId));
    history.goBack();
  };

  return (
    <div className="project-detail-selected-for-revision-conatiner selectable">
      <SaveButton customText={t('select_this_project')} handleClick={selectAndGoBack} />
    </div>
  );
};

export default SelectReviewProject;
