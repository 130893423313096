export enum PmrValidationStateEnum {
  DRAFT = 'PMI_DRAFT',
  CHIEF_OF_OPERATIONS = 'PMI_COO',
  CHIEF_OF_DIVISIONS = 'PMI_DC',
  REPRESENTATIVE = 'PMI_CR',
  VALIDATED = 'PMI_VALIDATED',
}

export enum ChecklistValidationEnum {
  Success = 0,
  Alert = 1,
  Worry = 2,
  Forbidden = 3,
}
