export enum FilterTypesEnum {
  LessThanOrEqual,
  GreaterThanOrEqual,
  Equal,
  Compare,
  StartsWith,
  Year,
  NumberIn,
  Search,
  ValueIn,
  Is,
}
