import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { replaceMomentFormat } from '../../../services/util';
import CommentsIcon from '../../ProjectDetail/ProjectTabs/Accordances/CommentsIcon/CommentsIcon';
import './AccordanceCardMobile.scss';

type Props = {
  accordance: any;
  selectedCard: any;
};

export const AccordanceCardMobile = ({
  accordance: {
    id,
    project: { code, loanNumber, withLoans, name: projectName },
    criticalIssue: { categoryName, name: criticalIssueName },
    possibleAction: { name: accordanceAbout },
    personInCharge: { name: responsabileName, lastName: responsabileLastName },
    expirationDate,
    hasUnreadComments,
    accordanceComments,
  },
  selectedCard,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className="accordance-card-mobile-container" onClick={() => selectedCard(id)}>
      <div className="card-header">
        <div className="project-code">
          <span>{`${code} ${withLoans ? loanNumber : ''}`}</span>
        </div>
        <div className="category-name">
          <span>{categoryName}</span>
        </div>
      </div>

      <div className="project-name">
        <span>{projectName}</span>
      </div>

      <div className="label-text">
        <div className="label">{t('critical_issue')}:</div>
        <div className="text">{criticalIssueName}</div>
      </div>
      <div className="label-text">
        <div className="label">{t('accordance_about')}:</div>
        <div className="text">{accordanceAbout}</div>
      </div>
      <div className="card-footer">
        <div className="label-text">
          <div className="label"> {t('responsible')}:</div>
          <div className="text">{`${responsabileName} ${responsabileLastName}`}</div>
        </div>
        <div className="label-text">
          <div className="label"> {t('date')}:</div>
          <div className="text">{replaceMomentFormat(moment(expirationDate).format('MMM/YYYY'))}</div>
        </div>
        <div className="label-text">
          <div className="label">{t('comments')}:</div>
          <div className="comment">
            <CommentsIcon
              hasUnreadComments={hasUnreadComments}
              comments={accordanceComments ? accordanceComments.filter(item => !item.isDeleted).length : 0}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
