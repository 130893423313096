import { ProjectProductTypeFilters } from '../../../models/ProjectProductTypeFilters';

const isDiferentToIGLower = (projectProductType: string) => {
  return parseInt(projectProductType) !== ProjectProductTypeFilters.IG_lower_3;
};

const filterTableOnIg = (projectTypeFilterOnDetail, earlyWarningsTab) => {
  return isDiferentToIGLower(projectTypeFilterOnDetail) || earlyWarningsTab.indexOf(true) === -1;
};
export { isDiferentToIGLower, filterTableOnIg };
