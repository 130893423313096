import moment from 'moment';
import React from 'react';
import { expiredDate, replaceMomentFormat } from '../../../../../services/util';
import { sortDates } from '../../../../../services/sortsUtil';
import './ListIndicator.scss';

type Props = {
  data: any;
};

export const ListIndicator = ({ data }: Props) => {
  const dateFormat = 'DD MMM';
  data && data.sort(sortDates('expirationDate', 'asc'));

  return (
    <div className="list-indicator-container">
      {data ? (
        data.slice(0, 4).map(item => (
          <div className="item-list" key={item.id}>
            <div className="title">{item.title}</div>
            <div className={`date ${expiredDate(item.expirationDate) && 'expired'}`}>
              {replaceMomentFormat(moment(item.expirationDate).format(dateFormat))}
            </div>
            <div className="code">{item.code}</div>
          </div>
        ))
      ) : (
        <div className="empty-data">
          <p className="bold-text">-</p>
        </div>
      )}
    </div>
  );
};
