export enum SortParameterProject {
  Code,
  Operation,
  Name,
  Division,
  PercentTemporaryAdvanceMade,
  LastDisbursementInDays,
  PercentRealizedDisbursement,
  PendingDisbursementAmount,
  PmrValue,
  Default,
  PercentRealizedAnnualDisbursement,
  PendingDisbursementAnnualAmount,
}
