import React from 'react';
import './ProjectsTableRow.scss';
import { useTranslation } from 'react-i18next';
import Project from '../../../models/Project';
import { BidTooltip } from '../../Util/Tooltip/Tooltip';
import { tooltipTitle } from '../../../services/stringUtil';
import { useDispatch, useSelector } from 'react-redux';
import User from '../../../models/User';
import { setInPortfolio } from '../../../redux/actions/allProjects';
import { trackActionClicked } from '../../../api/trackActionClickedApi';
import { TrackedActions } from '../../../models/TrackedActionsEnum';

type Props = {
  project: Project;
};

const AddToPortfolioButton = ({ project }: Props) => {
  const dispatch = useDispatch();
  const user = useSelector<any, User>(state => state.user);

  const handleSetInPortfolio = (e, projectId) => {
    e.stopPropagation();
    dispatch(setInPortfolio(projectId));
    trackActionClicked(TrackedActions.addToPorftfolioClicked);
  };

  const { t } = useTranslation();
  return user.displayMyPortfolio ? (
    project.inPortfolio ? (
      <BidTooltip
        title={tooltipTitle(t, 'remove_from_my_portfolio')}
        placement="bottom-start"
        enterDelay={500}
        leaveDelay={200}
        arrow
      >
        <span onClick={e => handleSetInPortfolio(e, project.id)} className="with-portfolio" id="withPortfolioProject" />
      </BidTooltip>
    ) : (
      <BidTooltip
        title={tooltipTitle(t, 'add_to_my_portfolio')}
        placement="bottom-start"
        enterDelay={500}
        leaveDelay={200}
        arrow
      >
        <span
          onClick={e => handleSetInPortfolio(e, project.id)}
          className="with-portfolio inactive"
          id="withoutPortfolioProject"
        />
      </BidTooltip>
    )
  ) : null;
};

export default AddToPortfolioButton;
