export const YEARS_OF_TOLERANCE = 1.5;
const getBiggerX = datasets => {
  let maxX = 0;
  datasets.forEach(ds => {
    const maxIn = Math.max(...ds.data.map(i => i.x));
    if (maxX < maxIn) {
      maxX = maxIn;
    }
  });
  return Math.round(maxX) + YEARS_OF_TOLERANCE;
};
enum DisbursementOverTimeLargeKeyToProductType {
  'DISBURSEMENT_OVER_TIME_DETAIL' = 0,
  'DISBURSEMENT_OVER_TIME_TCP_DETAIL' = 2,
  'DISBURSEMENT_OVER_TIME_IGR_DETAIL' = 3,
}
export { getBiggerX, DisbursementOverTimeLargeKeyToProductType };
