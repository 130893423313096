import React from 'react';
import { useTranslation } from 'react-i18next';
import { styles } from '../../Filter/FilterComponent.Styles';
import CloseCrossButton from '../../Util/Buttons/CloseCrossButton/CloseCrossButton';
import RegionCountryFilter from '../RegionCountryFilter/RegionCountryFilter';
import DivisionFilter from '../DivisionFilter/DivisionFilter';
import RoleFilter from '../RoleFilter/RoleFilter';
import { CalledFrom } from '../../../models/CalledFromEnum';
import BaseModal from '../../Util/Modals/BaseModal';

interface IProps {
  isOpen: boolean;
  closeModal: any;
}

const AnalyticsFilter = (props: IProps) => {
  const { t } = useTranslation();

  const closeModal = () => {
    props.closeModal(false);
  };

  const isOpen = props.isOpen;

  return (
    <BaseModal isOpen={isOpen} style={styles} onRequestClose={closeModal} className="modal-filter-content">
      <div className="modal-filter-container">
        <div className="horizontal-right">
          <CloseCrossButton handleClick={closeModal} />
        </div>

        <h3>{t('analytics_modal_title')}</h3>
        <RegionCountryFilter calledFrom={CalledFrom.ANALYTICS} />

        <h4>{t('division')}</h4>
        <div className="horizontal-columns">
          <DivisionFilter calledFrom={CalledFrom.ANALYTICS} />
        </div>

        <h4>{t('role')}</h4>
        <div className="horizontal-columns">
          <RoleFilter />
        </div>
      </div>
    </BaseModal>
  );
};

export default AnalyticsFilter;
