import React from 'react';
import { styles } from '../Styles';
import './AccordanceFulfilled.scss';
import SaveButton from '../../Buttons/SaveButton/SaveButton';
import UndoButton from '../../Buttons/UndoButton/UndoButton';
import { useTranslation } from 'react-i18next';
import { disableEscKey } from '../Util';
import { completeAccordance } from '../../../../api/accordanceApi';
import { useSelector, useDispatch } from 'react-redux';
import { getProjectAccordances } from '../../../../redux/actions/currentProject';
import BaseModal from '../BaseModal';

type Props = {
  isOpen: boolean;
  closeModal: any;
  accordanceId: number;
  customSubmit?: any;
  customTitle?: string;
  changeState?: Function;
};

const AccordanceFulfilled = (props: Props) => {
  const { isOpen, closeModal, accordanceId, customSubmit, customTitle, changeState } = props;
  const { t } = useTranslation();
  const currentProject = useSelector<any, any>(state => state.currentProject);
  const dispatch = useDispatch();

  const resetData = () => {
    closeModal(true);
  };

  const saveData = async () => {
    if (customSubmit) {
      customSubmit();
    } else {
      try {
        await completeAccordance(accordanceId);
        closeModal(false);
        dispatch(getProjectAccordances(currentProject.id));

        if (changeState !== undefined) changeState(true);
      } catch (error) {}
    }
  };

  return (
    <BaseModal
      isOpen={isOpen}
      onRequestClose={e => disableEscKey(e, resetData)}
      style={styles}
      className="modal-accordance-fulfilled-content"
    >
      <div className="modal-accordance-fulfilled-container">
        <div className="modal-accordance-fullfilled-info">
          <div className="fullfilled-icon"></div>
          <h3>{customTitle ? t(customTitle) : t('fulfilled_accordance')}</h3>
          <div className='fullfilled-info'>
            {customTitle ? (
              <>
                <p className='subtitule'>{t('new_text_deleted')}</p>
                <p className='subtitule-two'>{t('new_question_deleted')}</p>
              </>
            ) : null}
          </div>
        </div>
        <div className="horizontal">
          <UndoButton handleClick={resetData} />
          <SaveButton handleClick={saveData} customStyle={{ padding: '0px 44px' }} isAccordance={true} />
        </div>
      </div>
    </BaseModal>
  );
};

export default AccordanceFulfilled;
