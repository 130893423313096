import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SelectOption from '../../../../models/SelectOption';
import ProductCard from './ProductCard/ProductCard';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { getSelectOptions } from '../Supervision/util';
import { useDispatch, useSelector } from 'react-redux';
import Project from '../../../../models/Project';
import { getProjectProductInfo } from '../../../../redux/actions/currentProject';
import { ProductInformation } from '../../../../models/ProductInformation';
import styles from './Products.module.scss';

const {
  productCardsContainer,
  productsContainer,
  productsHeader,
  advanceHeader,
  advanceText,
  divider,
  tableHeader,
  tableHeaderDescription,
  tableHeaderItem,
  tableLastHeader,
} = styles;

const itemsDestructureInformation = products => {
  const years = {};
  const productForYear = {};
  products.map(product => {
    productForYear[product.id] = {};
    return product.items.forEach(item => {
      productForYear[product.id][item.year] = item;
      years[item.year] = [];
    });
  });
  const arrYears = Object.keys(years);
  const sortedYears = [...arrYears].sort().reverse();
  return { sortedYears, productForYear };
};

const Products = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [yearSelected, setYearSelected] = useState<SelectOption>();
  const [products, setProducts] = useState<any>([]);
  const [productsYear, setProductsYear] = useState<any>(null);
  const currentProject = useSelector<any, Project>(state => state.currentProject);
  const { productInformation } = currentProject;

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const sortedProducts = arrayMove(products, oldIndex, newIndex);
    setProducts(sortedProducts);
  };

  const yearInformationFromProducts = years => {
    const yearOptions = getSelectOptions(years);
    setYearSelected(yearOptions[0] || null);
  };

  const setYearInformation = (productInformation: ProductInformation, year: SelectOption) => {
    const arrProducts = Object.values(productInformation);
    const filteredProductsForYear = productInformation
      ? arrProducts.filter(product => !!productsYear[product.id][year.value])
      : null;
    setProducts(filteredProductsForYear);
  };

  useEffect(() => {
    if (currentProject && currentProject.id > 0) {
      dispatch(getProjectProductInfo(currentProject.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (productInformation) {
      const arrProducts = Object.values(productInformation);
      const { sortedYears, productForYear } = itemsDestructureInformation(arrProducts);
      setProductsYear(productForYear);
      yearInformationFromProducts(sortedYears);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [productInformation]);

  useEffect(() => {
    yearSelected && setYearInformation(productInformation, yearSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearSelected]);

  return (
    <div className={productsContainer}>
      <div className={productsHeader}>
        <div className={advanceHeader}>
          <span className={advanceText}>{t('phisical_advance')}</span>
          <span className={advanceText}>{t('financial_advance')}</span>
        </div>
        <div className={divider}></div>
        <div className={tableHeader}>
          <div className={tableHeaderDescription}>{t('description')}</div>
          <div className={tableHeaderItem}>{yearSelected && yearSelected.label}</div>
          <div className={tableHeaderItem}>{t('end_of_project')}</div>
          <div className={tableHeaderItem}>{yearSelected && yearSelected.label}</div>
          <div className={tableLastHeader}>
            <div className={tableHeaderItem}>{t('end_of_project')}</div>
          </div>
        </div>
        <div className={divider}></div>
      </div>
      {products && (
        <SortableList
          products={products}
          productsYear={productsYear}
          yearSelected={yearSelected}
          onSortEnd={onSortEnd}
          lockAxis="y"
          pressDelay={200}
        />
      )}
    </div>
  );
};

const SortableItem = SortableElement(({ product, productItem }) => (
  <ProductCard product={product} productItem={productItem} />
));

const SortableList = SortableContainer(({ products, productsYear, yearSelected }) => {
  return (
    <div className={productCardsContainer}>
      {products.map(
        (value, index) =>
          productsYear[value.id][yearSelected.value] && (
            <SortableItem
              key={`item-${value.id}`}
              index={index}
              product={value}
              productItem={productsYear[value.id][yearSelected.value]}
            />
          ),
      )}
    </div>
  );
});

export default Products;
