import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { isMobile } from '../../../../services/booleanUtil';

const basicRoot = {
  backgroundColor: '#00BAC7',
  color: '#fff',
  fontFamily: 'Rubik',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '14px',
  height: '40px',
  width: isMobile() ? '40px' : '108px',
  minWidth: isMobile() ? '40px' : '48px',
  padding: isMobile() ? '0' : '0px 16px',
  boxShadow: '0 1px 4px 0px rgba(65,109,188,0.5)',
  '&:hover': {
    backgroundColor: '#16959e',
    textDecoration: 'underline',
  },
  '&:active': {
    backgroundColor: '#16959e',
    textDecoration: 'underline',
    boxShadow: '0 1px 4px 0px rgba(65,109,188,0.5)',
  },
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      ...basicRoot,
    },

    buttonStyle: {
      ...basicRoot,
      width: '140px',
      borderRadius: '20px',
      marginLeft: '15px',
    },

    extendedIcon: {
      marginRight: isMobile() ? '0' : theme.spacing(1),
    },
  }),
);
