import React from 'react';
import { backgroundColorCustomStyle } from '../../../../Portfolio/CardIndicator/CardIndicator';
import ProgressChart from '../../../../Portfolio/CardIndicator/Cards/ProgressChart/ProgressChart';
import { ReportImageDisplay } from '../CustomizableReports';
import styles from './PcrSmallCards.module.scss';

type Props = {
  card: any;
  forReport?: ReportImageDisplay;
};

const PcrSmallCards = ({ card, forReport }: Props) => {
  const { container } = styles;
  return (
    <div
      className={container}
      id={card.reportWordKey ? 'PCR_NEXT_PRESENTATION_UPCOMING_WORD' : 'PCR_NEXT_PRESENTATION_UPCOMING'}
      style={backgroundColorCustomStyle(forReport)}
    >
      <ProgressChart
        data={
          card.data && card.data.secondDataGroup && card.likelyToBeSubmittedShortTermTotal !== 0
            ? card.data.secondDataGroup
            : null
        }
        indicatorKey={'PCR_NEXT_PRESENTATION'}
        forReport={forReport}
      />
    </div>
  );
};

export default PcrSmallCards;
