import React, { useContext, useEffect, useState } from 'react';
import './PortfolioDetailHeader.scss';
import css from '../../Portfolio/Filter/FilterBar.module.scss';
import BackButton from '../../Util/Buttons/BackButton/BackButton';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Indicator from '../../../models/Indicator';
import { translateMultipleText } from '../../../services/stringUtil';
import { isMobile } from '../../../services/booleanUtil';
import { IndicatorKeyEnum as key, IndicatorKeyEnum } from '../../../models/IndicatorKeyEnum';
import { useDispatch, useSelector } from 'react-redux';
import { CtFilterTab } from '../../Util/CtFilterTab/CtFilterTab';
import PipelineFilter from './PipelineFilter/PipelineFilter';
import ConsultanciesFinancedAndExecutedByTheBankFilter from './ConsultanciesFinancedAndExecutedByTheBankFilter/ConsultanciesFinancedAndExecutedByTheBankFilter';
import { handleSelectedDefaultWithProjects, setDefaultValueToCompositionFilter } from './Util';
import ApproveAccordances from './ApproveAccordances/ApproveAccordances';
import { ProjectProductTypes } from '../../../models/ProjectProductTypes';
import { buttonsFilterConfig } from '../../Util/CtFilterTab/CTFilterTabConfig';
import IndicatorDetailFilter from '../../../models/IndicatorDetailFilter';
import { setIndicatorDetailFilterProjectType } from '../../../redux/actions/indicatorDetailFilters';
import { DISBURSEMENT_PROJECTION_LAST_5_YEARS_SELECTED_YEAR } from '../../../services/sessionFiltersIndicatorDetailHelper';
import Filter from '../../Portfolio/Filter/Filter';
import InfoTooltip from '../InfoTooltip/InfoTooltip';
import { useDynamicTagLayout } from '../../../hooks/useDynamicTagLayout';
import { useContainerDimensions } from '../../../hooks/useContainerDimension';
import { TagFilterContext } from '../../../context/TagFilterContext';

interface ReceivedProps {
  currentIndicator: Indicator;
  deepTitle: any;
  handleModal?: Function;
}

type Props = ReceivedProps;
export const PorfolioDetailHeader = (props: Props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { visibleTags } = useDynamicTagLayout();
  const { dimensions } = useContainerDimensions();

  const { filterIndicators } = useSelector<any, any>(state => state);
  const { projectTypeFilterOnDetail } = useSelector<any, IndicatorDetailFilter>(state => state.indicatorDetailFilter);
  const menuTabs = useSelector<any, AppMenuTabs>(state => state.menuTabs);
  const portfolioSelectedTab = menuTabs.portfolioSelectedTab;
  const { currentIndicator, deepTitle, handleModal = () => {} } = props;
  const { tabIndicatorValues } = currentIndicator;
  const { projectTypeFilters } = filterIndicators.common;
  const showPipelineFilter =
    currentIndicator.indicatorKey === IndicatorKeyEnum.PROJECTS_IN_PREPARATION &&
    projectTypeFilterOnDetail === ProjectProductTypes.Loan;

  const arrIndicators = [5, 12, 16, 19, 20, 30, 32, 25, 19, 24, 26, 23, 31, 40, 28, 35, 37, 13, 7, 36, 22, 6, 41, 42];
  const { setTagOptions, optionsBySelect } = useContext(TagFilterContext);

  useEffect(() => {
    if (hasFilterTabs(currentIndicator.indicatorKey) && projectTypeFilterOnDetail === null) {
      dispatch(
        setIndicatorDetailFilterProjectType(
          setDefaultValueToCompositionFilter(handleSelectedDefaultWithProjects(projectTypeFilters, tabIndicatorValues)),
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [portfolioCompositionFilter, setPortfolioCompositionFilter] = useState<any>(
    !projectTypeFilterOnDetail
      ? setDefaultValueToCompositionFilter(handleSelectedDefaultWithProjects(projectTypeFilters, tabIndicatorValues))
      : projectTypeFilterOnDetail,
  );

  const goBack = () => {
    history.push('/home');
  };

  const handleSelectedPortfolioCompositionFilter = selectedValue => {
    sessionStorage.removeItem(DISBURSEMENT_PROJECTION_LAST_5_YEARS_SELECTED_YEAR);
    localStorage.removeItem('bubblesAxis');
    setPortfolioCompositionFilter(selectedValue);
    dispatch(setIndicatorDetailFilterProjectType(selectedValue));
  };

  return (
    <>
      <div className="portfolio-detail-header-container">
        <div className="back-title">
          {!isMobile() ? <BackButton handleClick={goBack} /> : <span className="back-arrow" onClick={goBack}></span>}

          {isMobile() && deepTitle.haveDeepTitle ? (
            <div>
              <h2>{renderDeepTitle(deepTitle, currentIndicator, t)}</h2>
              <h5>{renderSubTitle(currentIndicator, t)}</h5>{' '}
            </div>
          ) : (
            <div>
              <div className="title-portfolio">
                <h2>{renderDeepTitle(deepTitle, currentIndicator, t)}</h2>
                <InfoTooltip indicatorKey={currentIndicator.indicatorKey} />
              </div>
              <h5>{t(portfolioSelectedTab.title)}</h5>
              <h5>{renderSubTitle(currentIndicator, t)}</h5>
            </div>
          )}
        </div>
        <div className="approve-accordances-button">
          <ApproveAccordances currentIndicator={currentIndicator} />
        </div>
      </div>

      {(!arrIndicators.includes(currentIndicator.id || 0) || visibleTags.length > 0) && (
        <div className="filters-bar">
          <div className="filters-appiled">
            {visibleTags.length > 0 && `${t('filters_applied')}:`}
            {dimensions && visibleTags.map(tag => <span key={tag.name}> {tag.name}</span>)}
          </div>

          {/* Se agrega prop y logica al componente Filter para mostrar Button Filter desplegable */}
          {/* {!arrIndicators.includes(currentIndicator.id || 0) && (
            <Filter hideFilter={!arrIndicators.includes(currentIndicator.id || 0)} />
          )} */}
          <CtFilterTab
            selectedFilter={portfolioCompositionFilter}
            handleSelectedFilter={handleSelectedPortfolioCompositionFilter}
          />
        </div>
      )}

      <div className="cts-filters">{showPipelineFilter && <PipelineFilter />}</div>

      {currentIndicator.indicatorKey === IndicatorKeyEnum.BID_CONSULTANCY && (
        <ConsultanciesFinancedAndExecutedByTheBankFilter />
      )}
    </>
  );
};

export const haveReportButton = currentInd => {
  switch (currentInd.indicatorKey) {
    case key.PMR_PERFORMANCE_LAST_CLOSED_YEAR:
    case key.PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS_BY_DIVISION:
    case key.MONTHLY_DISBURSEMENT_PROJECTION_CURRENT_YEAR_INCLUDING_REGIONALS:
      return false;
    default:
      return true;
  }
};

const renderDeepTitle = (deepTitle, currentIndicator, t) => {
  return deepTitle && deepTitle.haveDeepTitle
    ? customStyledDeepTitle(currentIndicator.indicatorKey, deepTitle, t)
    : currentIndicator.detailTitle && translateMultipleText(currentIndicator.detailTitle, t);
};

const customStyledDeepTitle = (indicatorKey, deepTitle, t) => {
  if (
    indicatorKey === IndicatorKeyEnum.PMR_VALIDATION_STATE ||
    indicatorKey === IndicatorKeyEnum.PCR_NEXT_PRESENTATION
  ) {
    return deepTitle.deepTitleValue;
  }
  return t(deepTitle.deepTitleValue);
};

const hasFilterTabs = (indicatorKey?: string) => {
  return (
    indicatorKey &&
    (buttonsFilterConfig[indicatorKey].Loan ||
      buttonsFilterConfig[indicatorKey].CT ||
      buttonsFilterConfig[indicatorKey].IG)
  );
};

const renderSubTitle = (currentIndicator, t) => {
  if (
    currentIndicator.indicatorKey === IndicatorKeyEnum.MONTHLY_DISBURSEMENT_PROJECTION_CURRENT_YEAR_INCLUDING_REGIONALS
  ) {
    return t('the_current_projection_of_disbursements_is_displayed');
  } else if (currentIndicator.indicatorKey === IndicatorKeyEnum.PMR_PERFORMANCE_LAST_CLOSED_YEAR) {
    t('this_includes_only_the_projects_that_participated');
  } else {
    return '';
  }
};
