import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styles, multiSelectStyle, DropdownIndicator } from './FilterComponent.Styles';
import './FilterComponent.scss';
import { useTranslation } from 'react-i18next';
import CloseCrossButton from '../Util/Buttons/CloseCrossButton/CloseCrossButton';
import PmrFilter from './PmrFilter/PmrFilter';
import TimeWithoutDisbursementsFilter from './TimeWithoutDisbursementsFilter/TimeWithoutDisbursementsFilter';
import ProjectAgeFilter from './ProjectAgeFilter/ProjectAgeFilter';
import DisbursementProjectionFilter from './DisbursementProjectionFilter/DisbursementProjectionFilter';
import RegionCountryFilter from './RegionCountryFilter/RegionCountryFilter';
import { parseFilterToApiCall } from '../../services/util';
import { isMobile } from '../../services/booleanUtil';
import ProjectTypeFilter from './ProjectTypeFilter/ProjectTypeFilter';
import CTFilters from './CTFilters/CTFilters';
import { ProjectProductTypes } from '../../models/ProjectProductTypes';
import BaseModal from '../Util/Modals/BaseModal';
import useFilters from '../../hooks/useFilters';
import { MultiSearchUser } from '../Util/MultiSearchUser/MultiSearchUser';
import AttributesFilter from './AttributesFilter/AttributesFilter';
import { CalledFrom } from '../../models/CalledFromEnum';
import { AppMenuTabs } from '../MenuTabs/userTabsInterface';
import { RolesIdEnum } from '../../models/RolesIdEnum';
import Select from 'react-select';
import { handleTeamLeaderChange, manageFilterPanelBehavior } from './UtilFunctions';
import SectorsAndDivisionsFilter from './SectorsAndDivisionsFilter/SectorsAndDivisionsFilter';
import ExcludeRegionalsFilter from './ExcludeRegionalsFilter/ExcludeRegionalsFilter';

interface IProps {
  isOpen: boolean;
  closeModal: any;
  indicatorCall: boolean;
  calledFrom: CalledFrom;
}
type Props = IProps;

const handleSelectedTab = (calledFrom, menuTabs) => {
  if (calledFrom === CalledFrom.PORTFOLIO) {
    return menuTabs.portfolioSelectedTab;
  } else if (calledFrom === CalledFrom.EARLY_WARNINGS) {
    return menuTabs.earlyWarningsSelectedTab;
  }
  return menuTabs.projectSelectedTab;
};

const renderTitle = (calledFrom, t) => {
  if (calledFrom === CalledFrom.PORTFOLIO) {
    return t('portfolios_filters');
  } else if (calledFrom === CalledFrom.PROJECT) {
    return t('projects_filters');
  }
  return t('early_warnings_filters');
};

const FilterComponent = (props: Props) => {
  const { isOpen, indicatorCall, calledFrom, closeModal } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [teamLeaderSelected, setTeamLeaderSelected] = useState<any>([]);
  const [showInFollowMessage, setInFollowMessage] = useState<boolean>(true);
  const menuTabs = useSelector<any, AppMenuTabs>(state => state.menuTabs);
  const { filterIndicators, filter, filterEarlyWarnings, setFilter, setFilterIndicators, setFilterEarlyWarnings } =
    useFilters();
  const selectedTab = handleSelectedTab(calledFrom, menuTabs);

  const showFilters = (typeSelected: number): boolean => {
    const projectTypeFilters = indicatorCall
      ? parseFilterToApiCall(filterIndicators.common.projectTypeFilters).projectProductTypes
      : parseFilterToApiCall(filter.common.projectTypeFilters).projectProductTypes;

    return (
      projectTypeFilters.filter(i => i !== ProjectProductTypes.GUARANTEE).length === 1 &&
      projectTypeFilters.includes(typeSelected)
    );
  };

  useEffect(() => {
    manageFilterPanelBehavior(
      isOpen,
      calledFrom,
      menuTabs,
      { setInFollowMessage, setTeamLeaderSelected },
      { filterIndicators, filter, filterEarlyWarnings },
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <BaseModal isOpen={isOpen} style={styles} onRequestClose={() => closeModal(false)} className="modal-filter-content">
      <div className="modal-filter-container">
        <div className="horizontal-right">
          <CloseCrossButton handleClick={() => closeModal(false)} />
        </div>
        <h3> {renderTitle(calledFrom, t)}</h3>

        {calledFrom !== CalledFrom.EARLY_WARNINGS && (
          <>
            <h4>{t('instrument')}</h4>

            <div className="horizontal">
              <ProjectTypeFilter filterTitle="the_portfolio_consists_of" isVertical indicatorCall={indicatorCall} />
            </div>
          </>
        )}

        {calledFrom !== CalledFrom.EARLY_WARNINGS && showFilters(ProjectProductTypes.CT) && (
          <div className="horizontal-columns">
            <CTFilters indicatorCall={indicatorCall} />
          </div>
        )}
        <ExcludeRegionalsFilter indicatorCall={indicatorCall} />
        <RegionCountryFilter calledFrom={calledFrom} />
        <SectorsAndDivisionsFilter calledFrom={calledFrom} isOpen={isOpen} />

        <h4>{t('team_leader')}</h4>
        {teamLeaderSelected.length && selectedTab.tabId !== 0 ? (
          <div className="horizontal-columns">
            <Select
              id="teamLeadersSelect"
              components={{ DropdownIndicator }}
              name="teamLeaders"
              options={teamLeaderSelected}
              className="basic-multi-select"
              classNamePrefix="All"
              placeholder={t('all')}
              onChange={value =>
                handleTeamLeaderChange(
                  value,
                  calledFrom,
                  dispatch,
                  { filterIndicators, filter, filterEarlyWarnings },
                  { setFilterIndicators, setFilter, setFilterEarlyWarnings },
                )
              }
              styles={multiSelectStyle}
              isMulti
            />
          </div>
        ) : (
          <div className="horizontal-columns">
            <MultiSearchUser
              countryFiltered={false}
              returnUsersSelected={user => {
                handleTeamLeaderChange(
                  user,
                  calledFrom,
                  dispatch,
                  { filterIndicators, filter, filterEarlyWarnings },
                  { setFilterIndicators, setFilter, setFilterEarlyWarnings },
                );
              }}
              filteredRoles={[RolesIdEnum.Team_Leader]}
              clearStyle
              delegatedUsers={teamLeaderSelected}
            />
          </div>
        )}
        <h4>{t('attributes')}</h4>
        <div className="horizontal-columns">
          <AttributesFilter calledFrom={calledFrom} />
        </div>
        {(showFilters(ProjectProductTypes.Loan) || calledFrom === CalledFrom.EARLY_WARNINGS) && (
          <>
            <h4 style={{ padding: '0px 0px 6px 0px' }}>{t('pmr_initials')}</h4>
            <div className="horizontal-columns">
              <PmrFilter indicatorCall={indicatorCall} calledFrom={calledFrom} />
            </div>
          </>
        )}
        <h4>{t('time_without_performing_disbursements')}</h4>
        <div className="horizontal" style={{ padding: '8px 0px 50px 0px' }}>
          <TimeWithoutDisbursementsFilter indicatorCall={indicatorCall} calledFrom={calledFrom} />
        </div>
        <h4>{t('project_age')}</h4>
        <span style={{ marginTop: '0px' }}>{t('time_from_date_of_eligibility')}</span>
        <div className="horizontal" style={{ padding: '6px 0px 50px 0px' }}>
          <ProjectAgeFilter indicatorCall={indicatorCall} calledFrom={calledFrom} />
        </div>
        {(showFilters(ProjectProductTypes.Loan) || calledFrom === CalledFrom.EARLY_WARNINGS) && (
          <>
            <h4>{t('projection_disbursed')}</h4>
            <div className="horizontal" style={{ padding: '10px 0px 35px 0px' }}>
              <DisbursementProjectionFilter indicatorCall={indicatorCall} calledFrom={calledFrom} />
            </div>
          </>
        )}
        {showInFollowMessage && (
          <div className="horizontal-columns">
            <span className="is-follow-message">{t('is_follow_message')}</span>
          </div>
        )}
        {isMobile() && <div style={{ height: 50, width: '100%' }} />}
      </div>
    </BaseModal>
  );
};

export default FilterComponent;
