import React from 'react';

import './SuccessMessage.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Check } from '../../../assets/check.svg';

type Props = {
  textMessage?: string;
};

const SuccessMessage = ({ textMessage }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="success-message-container">
      <Check />
      <span>{textMessage ? t(textMessage) : t('changes_saved')}</span>
    </div>
  );
};

export default SuccessMessage;
