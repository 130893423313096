import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ContentManagerAccordion from '../ContentManagerAccordion/ContentManagerAccordion';
import { getLanguages } from '../../../api/userApi';

import './../ContentManager.scss';
import AddEntity from './AddEntity';
import {
  getCategoryListService,
  getCriticalIssueListService,
  getPossibleActionListService,
} from '../../../api/contentManager';
import { AddEntityOptionsType } from './util';

const spanishLanguage = 'es';
const p1 = 'p1';
const p2 = 'p2';
const p3 = 'p3';

const AddEntities = () => {
  const { t } = useTranslation();
  const [languageId, setLanguageId] = useState(0);
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getLanguageId = async () => {
    const result = await getLanguages();
    setLanguageId(result.find(i => i.encodedName === spanishLanguage).id);
  };

  useEffect(() => {
    getLanguageId();
  }, []);

  return (
    <div>
      <h1 className="content-manager__title with-margin">{t('add_cat_critical_accordance')}</h1>
      <ContentManagerAccordion
        accordionSummary={t('add_category')}
        customStyle={{ paddingLeft: 40 }}
        onChange={handleChange(p1)}
        expanded={expanded === p1}
      >
        <AddEntity
          title={t('add_category')}
          subtitle={t('add_cat_subtitle')}
          inputLabel={t('new_category')}
          languageId={languageId}
          expanded={expanded === p1}
          apiCall={getCategoryListService}
        />
      </ContentManagerAccordion>
      <ContentManagerAccordion
        accordionSummary={t('add_critial_issue')}
        customStyle={{ paddingLeft: 40 }}
        onChange={handleChange(p2)}
        expanded={expanded === p2}
      >
        <AddEntity
          title={t('add_critial_issue')}
          subtitle={t('add_critical_issue_subtitle')}
          inputLabel={t('new_critical_issue')}
          optionsData={{ label: t('linked_category'), type: AddEntityOptionsType.CriticalIssue }}
          languageId={languageId}
          expanded={expanded === p2}
          apiCall={getCriticalIssueListService}
        />
      </ContentManagerAccordion>
      <ContentManagerAccordion
        accordionSummary={t('add_accordance')}
        customStyle={{ paddingLeft: 40 }}
        onChange={handleChange(p3)}
        expanded={expanded === p3}
      >
        <AddEntity
          title={t('add_accordance')}
          subtitle={t('add_accordance_subtitle')}
          inputLabel={t('new_accordance')}
          optionsData={{ label: t('linked_critical_issue'), type: AddEntityOptionsType.PossibleAction }}
          languageId={languageId}
          expanded={expanded === p3}
          apiCall={getPossibleActionListService}
        />
      </ContentManagerAccordion>
    </div>
  );
};

export default AddEntities;
