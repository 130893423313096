import React from 'react';
import { BarChartDetailContent } from './BarChartDetailContent/BarChartDetailContent';
import { ProgressChartDetailContent } from './ProgressChartDetailContent/ProgressChartDetailContent';
import { PmrClassification } from '../../models/PmrClassification';
import { Typography, Box } from '@material-ui/core';
import { RadialChartDetailContent } from './RadialChartDetailContent/RadialChartDetailContent';
import { EfasConcepts } from '../../models/EfasConcepts';
import NumberFormat from 'react-number-format';
import { IndicatorKeyEnum } from '../../models/IndicatorKeyEnum';
import { CriteriaType } from '../../models/PaginationIndicator';

export const showChart = (currentIndicator, filterValues, showDisbursed, selectedCircle) => {
  switch (currentIndicator.type) {
    case 'horizontal':
    case 'vertical':
      return <BarChartDetailContent currentIndicator={currentIndicator} filterValues={filterValues} />;

    case 'radialToBarChart':
      const transactionalDimensions = {
        mobile: {
          width: 300,
          height: 200,
        },
        desktop: {
          width: 250,
          height: 300,
        },
      };
      return (
        <BarChartDetailContent
          currentIndicator={addSimetricBlankSpaceOnBarChart(currentIndicator.data.toBarchart)}
          filterValues={filterValues}
          chartDimensions={transactionalDimensions}
        />
      );
    case 'progress':
      return <ProgressChartDetailContent currentIndicator={currentIndicator} showDisbursed={showDisbursed} />;
    case 'radial':
      return <RadialChartDetailContent currentIndicator={currentIndicator} selectedCircle={selectedCircle} />;
    default:
      return null;
  }
};

export const mapClassification = value => {
  switch (value) {
    case 'alertChartdata':
    case 'alert':
    case 1:
      return PmrClassification.Yellow;
    case 'problemChartData':
    case 'problem':
    case 2:
      return PmrClassification.Red;
    case 'satisChartData':
    case 'satisfactory':
    case 0:
      return PmrClassification.Green;
    case 'naChartData':
    case 'not_assigned_initials':
    case 'not_applicable':
    case 3:
      return PmrClassification.Na;
    default:
      return PmrClassification.Yellow;
  }
};

export const mapEfaConceptClassification = value => {
  switch (value) {
    case 0:
    case EfasConcepts.clean:
      return 'clean';
    case 1:
    case EfasConcepts.qualified_opinion:
      return 'qualified opinion';
    case 2:
    case EfasConcepts.adverse_opinion:
      return 'adverse opinion';
    case 3:
    case EfasConcepts.disclaimer_of_opinion:
      return 'disclaimer of opinion';

    default:
      return 'pending';
  }
};

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

export const getDoughnutTotalProjects = currentIndicator => {
  let totalProjects = 0;
  currentIndicator.data.datasets[0].projectQuantity.map(projects => (totalProjects += projects));
  return totalProjects;
};

export const isMoney = (number, isMoney) => {
  return isMoney ? (
    <NumberFormat
      value={number}
      displayType={'text'}
      className={'value'}
      thousandSeparator={'.'}
      decimalSeparator={','}
    />
  ) : (
    number
  );
};

const addSimetricBlankSpaceOnBarChart = data => {
  const backgorundArray = [...data.data.datasets[0].backgroundColor];
  const dataArray = [...data.data.datasets[0].data];
  const labelsArray = [...data.data.labels];
  backgorundArray.unshift('');
  backgorundArray.push('');
  dataArray.unshift(0);
  dataArray.push(0);
  labelsArray.unshift('');
  labelsArray.push('');

  return {
    ...data,
    data: {
      ...data.data,
      datasets: [{ ...data.data.datasets[0], backgroundColor: backgorundArray, data: dataArray }],

      labels: labelsArray,
    },
  };
};

export const processBarChartData = (data, t) => {
  if (data.indicatorKey === IndicatorKeyEnum.PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS_BY_DIVISION) {
    const HORIZONTAL_BAR_EXTRA_SPACE = '    ';
    return {
      ...data.data,
      labels: data.data.labels.map(label => {
        const auxLabel = label.trim().toLowerCase();
        return auxLabel === 'others' ? t('others_divisions') + HORIZONTAL_BAR_EXTRA_SPACE : label;
      }),
    };
  } else {
    return data.translateChartLabels
      ? {
        ...data.data,
        labels: data.data.labels.map(label => {
          return t(label);
        }),
      }
      : data.data;
  }
};

export const baseHeaderLast5YearsTables = headerTitleClickable => {
  return { classNameStyle: headerTitleClickable };
};

export const commonHeader = ({ headerTitleClickable, t, handleSort, field3Translation, field5Translation }) => {
  return [
    {
      ...baseHeaderLast5YearsTables(headerTitleClickable),
      translation: t('country'),
      sortFunction: () => handleSort('field2', CriteriaType.string),
    },
    {
      ...baseHeaderLast5YearsTables(headerTitleClickable),
      translation: t('code'),
      sortFunction: () => handleSort('code', CriteriaType.string),
    },
    {
      ...baseHeaderLast5YearsTables(headerTitleClickable),
      translation: t('operation'),
      sortFunction: () => handleSort('loanNumber', CriteriaType.string),
    },
    {
      ...baseHeaderLast5YearsTables(headerTitleClickable),
      translation: t('name'),
      sortFunction: () => handleSort('title', CriteriaType.string),
    },
    {
      ...baseHeaderLast5YearsTables(headerTitleClickable),
      translation: field3Translation,
      sortFunction: () => handleSort('field3', CriteriaType.string),
    },
    {
      ...baseHeaderLast5YearsTables(headerTitleClickable),
      translation: `${t('original_projection')} ${t('dolar_sign_parentesis')}`,
      sortFunction: () => handleSort('field4', CriteriaType.number),
    },
    {
      ...baseHeaderLast5YearsTables(headerTitleClickable),
      translation: field5Translation,
      sortFunction: () => handleSort('field5', CriteriaType.number),
    },
  ];
};
