import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import User from '../../models/User';
import LabelSelect from './LabelSelect/LabelSelect';
import './UserSettings.scss';
import CustomSwitch from '../Util/CustomSwitch/CustomSwitch';
import SubmitButton from '../Util/Buttons/SubmitButton/SubmitButton';
import SelectOption from '../../models/SelectOption';
import { isMobile } from '../../services/booleanUtil';
import { RequestStatus } from '../../models/RequestStatus';
import { languagesList } from '../../models/LanguagesList';
import ErrorMessage from '../Util/ErrorMessage/ErrorMessage';
import { DelegatePermissions } from './DelegatePermissions/DelegatePermissions';
import { RolesIdEnum } from '../../models/RolesIdEnum';
import { updateUser } from '../../redux/actions/user';
import { setRequestStatus } from '../../redux/actions/requestStatus';
import i18n from '../../i18n/i18n';

const UserSettings = () => {
  const { t } = useTranslation();
  const user = useSelector<any, User>(state => state.user);
  const dispatch = useDispatch();
  const requestStatus = useSelector<any, RequestStatus>(state => state.requestStatus);
  const [sendEmail, setSendEmail] = React.useState(user.acceptMailDelivery);
  const userLanguage = languagesList.find((item: any) => item.value === user.language.encodedName);
  const [currentLanguage, setCurrentLanguage] = React.useState(
    userLanguage ? userLanguage : { value: 'es', label: '', id: 1 },
  );
  const userRole = user.role;

  const getDelegatedUsers = permissionToDelegate => {
    const usersToDelegate = permissionToDelegate === 'review' ? user.delegateRevision : user.delegateAccordances;
    return (
      usersToDelegate &&
      usersToDelegate.map(user => ({
        id: user.id,
        label: `${user.name} ${user.lastName}`,
        value: `${user.name} ${user.lastName}`,
      }))
    );
  };
  const [delegateReviewsUsers, setDelegateReviewsUsers] = useState<any[]>(getDelegatedUsers('review'));
  const [delegateApproveAccorcandesUsers, setDelegateApproveAccorcandesUsers] = useState(
    getDelegatedUsers('accordances'),
  );

  React.useEffect(() => {
    return () => {
      dispatch(setRequestStatus(RequestStatus.Default));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = () => {
    setSendEmail(!sendEmail);
    dispatch(setRequestStatus(RequestStatus.Default));
  };

  const handleLanguageChange = (selectedOption: SelectOption) => {
    setCurrentLanguage(selectedOption);
    dispatch(setRequestStatus(RequestStatus.Default));
  };

  const submit = async () => {
    const delegateReviewUsersIds = delegateReviewsUsers ? delegateReviewsUsers.map((user: any) => user.id) : [];
    const delegateAccordanceApproveUsersIds = delegateApproveAccorcandesUsers
      ? delegateApproveAccorcandesUsers.map((user: any) => user.id)
      : [];

    dispatch(setRequestStatus(RequestStatus.Loading));
    try {
      dispatch(
        updateUser({
          acceptMailDelivery: sendEmail,
          languageCode: currentLanguage.value,
          delegateRevision: delegateReviewUsersIds,
          delegateAccordances: delegateAccordanceApproveUsersIds,
        }),
      );
      dispatch(setRequestStatus(RequestStatus.Success));
      i18n.changeLanguage(currentLanguage.value);
      localStorage.setItem('language', currentLanguage.value);
    } catch (error) {
      dispatch(setRequestStatus(RequestStatus.Error));
    }
  };

  const handleDelegateReviewsUsers = reviewUsers => {
    dispatch(setRequestStatus(RequestStatus.Default));
    setDelegateReviewsUsers(reviewUsers);
  };
  const handleDelegateAccordancesUsers = reviewUsers => {
    dispatch(setRequestStatus(RequestStatus.Default));
    setDelegateApproveAccorcandesUsers(reviewUsers);
  };

  return (
    <div className="user-settings-container">
      <h1>
        {t('settings_of')}
        {user.name} {user.lastName}
      </h1>
      {requestStatus === RequestStatus.Error && <ErrorMessage />}

      <div className="content-container">
        <div className="form-container">
          <LabelSelect type={'country'} user={user} readonly={true} />
          <LabelSelect
            type={'language'}
            user={user}
            setOption={selectedOption => {
              handleLanguageChange(selectedOption);
            }}
            disabled={requestStatus === RequestStatus.Loading}
          />

          <div className="switch-container">
            <p>{t('send_email_notifications')}</p>
            <CustomSwitch
              handleChange={handleChange}
              active={sendEmail}
              disabled={requestStatus === RequestStatus.Loading}
            />
          </div>
        </div>
        {(userRole.id === RolesIdEnum.Chief_of_Operations || userRole.id === RolesIdEnum.Team_Leader) && (
          <div className="delegate-container">
            <DelegatePermissions
              delegateReviewsUsers={delegateReviewsUsers}
              handleDelegateReviewsUsers={handleDelegateReviewsUsers}
              delegateAccordancesUsers={delegateApproveAccorcandesUsers}
              handleDelegateAccordancesUsers={handleDelegateAccordancesUsers}
              userRole={userRole.id}
            />
          </div>
        )}
      </div>
      <div className="button-container">
        <SubmitButton buttonState={requestStatus} handleClick={submit} />
        {isMobile() && <div style={{ width: '100%', height: 120 }} />}
      </div>
    </div>
  );
};

export default UserSettings;
