import React from "react";
import { useTranslation } from "react-i18next";
import { useFormMemoryHelpContext } from "../../../context/MemoryHelpFormContext";
import { ReactComponent as Iconcheck } from '../../../assets/icons/u_check-circle.svg';
import { ReactComponent as IconDownload } from '../../../assets/icons/u_download-alt.svg';

const time = Date.now()
const currentDate = new Date(time).toLocaleDateString();

const ConfirmContentMemoryHelp = ({ onClickHandle }) => {
    const { t } = useTranslation();

    const { fileRevision, userCanEditRevision } = useFormMemoryHelpContext();

    const handleDownload = () => {
        const blob: any = fileRevision?.pdfBlob;
        const filename = `${t('memory_help.container_tabs.completed.donwload_memory_files.confirm')} - ${currentDate}`;

        if (!blob) {
            console.error(t('memory_help.container_tabs.completed.error'));
            return;
        }

        const url = URL.createObjectURL(blob);

        const element = document.createElement("a");
        element.href = url;
        element.download = filename;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        URL.revokeObjectURL(url);
    };

    const handleRole = () => {
        if (userCanEditRevision) {
            onClickHandle('operationChief')
        } else {
            onClickHandle('managerialPanel')
        };
    }


    return (
        <section className='confirmed-memory-help'>
            <div className='title'>
                <Iconcheck />
                <h1 className='ci-type-display-3'>{t('memory_help.container_tabs.completed.info_success.title')}</h1>
            </div>
            <div className='download-memory-help'>
                <p>
                    {t('memory_help.container_tabs.completed.info_success.description')}
                </p>
            </div>
            <div className='button'>
                <button className="btn btn-primary" onClick={() => handleDownload()}> <IconDownload />{t('memory_help.container_tabs.completed.info_success.download_content')}</button>
                <button className='btn btn-primary-text' onClick={() => handleRole()}>{t('memory_help.container_tabs.completed.info_success.return_revisions')}</button>
            </div>
        </section>
    )
}

export default ConfirmContentMemoryHelp;