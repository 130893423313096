import React from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { isMobile } from '../../../../../../services/booleanUtil';
import styles from './PmrValidationStateChartElement.module.scss';

type Props = {
  data: any[];
  isDetail?: boolean;
  handleClick?: Function;
};
const PmrValidationStateChartElement = ({ data, handleClick }: Props) => {
  const { t } = useTranslation();
  const { itemClass, progress, textContainer, label, value, container, noData } = styles;
  return (
    <div className={container}>
      {data ? (
        data.map(item => (
          <div className={itemClass} key={item.categorization}>
            {!isMobile() && (
              <div className={textContainer}>
                <p className={label}>{t(item.label)}:</p>
                <p className={value}>
                  <NumberFormat
                    value={item.value}
                    displayType={'text'}
                    className={'value'}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                  />
                  {` ${t(item.valueSuffix)}`}
                </p>
              </div>
            )}

            <span
              className={progress}
              style={{
                backgroundColor: item.color,
                width: `${item.percentage}%`,
                marginBottom: isMobile() ? 8 : 0,
              }}
            >
              {item.percentage === 0 && '-'}
            </span>
          </div>
        ))
      ) : (
        <div className={noData}>-</div>
      )}
    </div>
  );
};

export default PmrValidationStateChartElement;
