import React from 'react';
import { useTranslation } from 'react-i18next';

export const Annexes = () => {
    const { t } = useTranslation();
    return (
        <div>
            <h5 className='title'>8. {t('memory_help.tabs.8')}</h5>
            <div className='bg-grey'>{t('memory_help.container_tabs.8.description')}</div>
            <ul className='check-annexes'>
                <li><b>{t('memory_help.container_tabs.8.appendix')}</b> {t('memory_help.container_tabs.8.appendix_description')}</li>
                <li><b>{t('memory_help.container_tabs.8.options.label_annex')} 1:</b> {t('memory_help.container_tabs.8.options.1')}</li>
                <li><b>{t('memory_help.container_tabs.8.options.label_annex')} 2:</b> {t('memory_help.container_tabs.8.options.2')}</li>
                <li><b>{t('memory_help.container_tabs.8.options.label_annex')} 3:</b> {t('memory_help.container_tabs.8.options.3')} </li>
                <li><b>{t('memory_help.container_tabs.8.options.label_annex')} 4a:</b> {t('memory_help.container_tabs.8.options.4a')}</li>
                <li><b>{t('memory_help.container_tabs.8.options.label_annex')} 4b:</b> {t('memory_help.container_tabs.8.options.4b')}</li>
                <li><b>{t('memory_help.container_tabs.8.options.label_annex')} 5:</b> {t('memory_help.container_tabs.8.options.5')}</li>
                <li><b>{t('memory_help.container_tabs.8.options.label_annex')} 6:</b> {t('memory_help.container_tabs.8.options.6')}</li>
            </ul>

        </div>);
};

export default Annexes;
