import React from 'react';
import './WorkWithTable.scss';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Comments from '../../Util/Comments/Comments';
import Accordance from '../../../models/Accordance';
import { replaceMomentFormat } from '../../../services/util';
import moment from 'moment';
import {
  getAccordanceById,
  approveAccordance,
  newAccordanceComment,
  deleteAccordanceComment,
} from '../../../api/accordanceApi';
import Comment from '../../../models/Comment';
import { AccordanceType } from '../../../models/AccordanceType';
import SaveButton from '../../Util/Buttons/SaveButton/SaveButton';
import UndoButton from '../../Util/Buttons/UndoButton/UndoButton';
import AccordanceReject from '../../Util/Modals/AccordanceReject/AccordanceReject';
import { getPendingApprovalAccordances } from '../../../redux/actions/indicatorAccordances';

const isWaitingCurrentUser = (waitingApproval, lazyProps) => {
  return waitingApproval && lazyProps && lazyProps.currentUserCanApprove;
};

const hasRowSelectedWithProject = rowSelected => {
  return rowSelected && rowSelected.project && rowSelected.project.id;
};

const getRowSelecetdId = rowSelected => {
  return rowSelected ? rowSelected.id : 0;
};

const renderDate = (rowSelected, dateData) => {
  return rowSelected ? replaceMomentFormat(moment(dateData).format('DD/MMM/YY')) : '-';
};

const renderAccordanceLabel = (rowSelected, t) => {
  return rowSelected?.accordanceType === AccordanceType.Revision ? t('accordance') : t('action');
};

const expirationDateStyle = lazyProps => {
  const currentDate = new Date();
  const expirationDate = new Date(lazyProps.expirationDate);

  return currentDate > expirationDate ? 'expired' : 'data';
};

const renderValueOrEmpty = value => {
  return value || '-';
};

const renderValueOrEmptyNested = (parent, child) => {
  return parent && child ? parent[child] : '-';
};

const renderSupervisionAction = (supervisionAction: any) => {
  return supervisionAction ? supervisionAction.description : '-';
};

interface ReceivedProps {
  rowSelected: Accordance | undefined;
  waitingApproval?: boolean;
  clearDetail?: Function;
}

type Props = ReceivedProps;
export const WorkWithTable = (props: Props) => {
  const { t } = useTranslation();
  const { rowSelected, waitingApproval, clearDetail } = props;
  const [lazyProps, setLazyProps] = React.useState<Accordance>();
  const [showReject, setShowReject] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const [accordanceComments, setAccordanceComments] = React.useState<Comment[]>([]);

  const refreshDataClearDetail = () => {
    dispatch(getPendingApprovalAccordances(true));
    if (clearDetail) {
      clearDetail();
    }
  };

  const gotoProject = () => {
    if (hasRowSelectedWithProject(rowSelected)) {
      history.push(`/projectdetail?id=${rowSelected?.project.id}`);
    }
  };

  const approveAcc = async () => {
    if (rowSelected) {
      await approveAccordance(rowSelected.id);
      refreshDataClearDetail();
    }
  };

  const rejectAcc = async () => {
    setShowReject(true);
  };

  React.useEffect(() => {
    if (rowSelected !== undefined) {
      getAccordanceById(rowSelected.id).then(accor => {
        setLazyProps(accor);
        accor.accordanceComments && setAccordanceComments(accor.accordanceComments);
      });
    }
  }, [rowSelected]);

  const renderAccordanceDetail = () =>
    lazyProps ? (
      <>
        <div className="work-with-table-container adjust-height">
          {renderAccordanceTypeName(rowSelected, t)}
          <div className="label">{t('critical_issue')}</div>
          <div className="data">{renderValueOrEmptyNested(lazyProps.criticalIssue, 'name')}</div>
          <div className="label">{t('category')}</div>
          <div className="data">{renderValueOrEmptyNested(lazyProps.criticalIssue, 'categoryName')}</div>
          <div className="label">{renderAccordanceLabel(rowSelected, t)}</div>
          <div className="data">{renderValueOrEmptyNested(lazyProps.possibleAction, 'name')}</div>
          <div className="label">{t('description')}</div>
          <div className="data">{renderValueOrEmpty(lazyProps.description)}</div>
          <div className="label">{t('due_date')}</div>
          <div className={expirationDateStyle(lazyProps)}>{renderDate(lazyProps, lazyProps.expirationDate)}</div>
          {lazyProps.accordanceType === AccordanceType.Monitoring && (
            <>
              <div className="label">{t('supervision_action')}</div>
              <div className="data">{renderSupervisionAction(lazyProps.supervisionAction)}</div>
            </>
          )}
          <div className="label">{t('description_of_the_critical_issue')}</div>
          <div className="data">{renderValueOrEmpty(lazyProps.criticalIssueDescription)}</div>
          <div className="label">{t('bid_responsible')}</div>
          <div className="data">
            {`${renderValueOrEmptyNested(lazyProps.personInCharge, 'name')} ${renderValueOrEmptyNested(
              lazyProps.personInCharge,
              'lastName',
            )}`}
          </div>
          <div className="comments-component">
            <Comments
              comments={accordanceComments || []}
              submitComment={comment => newAccordanceComment(getRowSelecetdId(rowSelected), comment)}
              submitDeleteComment={commentId => deleteAccordanceComment(getRowSelecetdId(rowSelected), commentId)}
            />
          </div>
          {isWaitingCurrentUser(waitingApproval, lazyProps) && (
            <div className="approve-buttons">
              <SaveButton
                handleClick={approveAcc}
                customText={`${t('approve')} ${t('accordance')}`}
                customStyle={{ padding: '0px 40px' }}
              />
              <UndoButton handleClick={rejectAcc} customText={t('reject')} customStyle={{ padding: '0px 40px' }} />
            </div>
          )}
          <span className="link" onClick={gotoProject}>
            {t('go_to_project_info')}
          </span>
        </div>
        <AccordanceReject
          isOpen={showReject}
          closeModal={() => setShowReject(false)}
          accordanceId={getRowSelecetdId(rowSelected)}
          onRejectHandler={refreshDataClearDetail}
        />
      </>
    ) : null;

  return rowSelected !== undefined ? renderAccordanceDetail() : <div className="work-with-table-container"></div>;
};

const renderAccordanceTypeName = (rowSelected, t) => {
  if (rowSelected?.accordanceType === AccordanceType.Revision) {
    return (
      <>
        <div className="label">{t('portfolio_review_date')}</div>
        <div className="data">{renderDate(rowSelected, rowSelected.revisionDate)}</div>
      </>
    );
  } else if (rowSelected?.accordanceType === AccordanceType.InterGroup) {
    return <div className="label capitalize">{t('inter_group_accordance')}</div>;
  } else {
    return <div className="label">{t('monitor_accordance')}</div>;
  }
};
