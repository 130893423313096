import { RegionActionTypes, SET_REGIONS } from '../actions/types';
import Region from '../../models/Region';

const initialState: Region[] = [];

const regionsReducer = (state = initialState, action: RegionActionTypes): any => {
  switch (action.type) {
    case SET_REGIONS:
      return action.payload;
    default:
      return state;
  }
};
export default regionsReducer;
