import { FilterConfigValues, TabFilterOption } from '../../../models/EarlyWarnings';
import { IndicatorKeyEnum } from '../../../models/IndicatorKeyEnum';
import { ProjectProductTypes } from '../../../models/ProjectProductTypes';
import { filterTableOnIg, isDiferentToIGLower } from '../EarlyWarningsTimeLapse/Util';

const getSubstractedDateByDays = (daysToSubtract: number): Date => {
  const substractedDate = new Date();
  return new Date(substractedDate.setDate(substractedDate.getDate() - daysToSubtract));
};

const sumDays = (dateToOperate: Date, days: number): Date => {
  return new Date(dateToOperate.setDate(dateToOperate.getDate() + days));
};
const substractDays = (dateToOperate: Date, days: number): Date => {
  return new Date(dateToOperate.setDate(dateToOperate.getDate() - days));
};

const getSubstractedDateByMonths = (monthsToSubstract: number): Date => {
  const substractedDate = new Date();
  return new Date(substractedDate.setMonth(substractedDate.getMonth() - monthsToSubstract));
};
const cleanTimeFromDate = (dateToClean): Date => {
  return new Date(dateToClean.getFullYear(), dateToClean.getMonth(), dateToClean.getDate());
};

const dateIsAfterToday = (date: Date): boolean => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return date > today;
};

const filterProjectsBetweenLimitsByDays = (
  projectTime: Date,
  downLimitTime: Date,
  upperLimitTime: Date | null = null,
): boolean => {
  const cleanedProjectTime = cleanTimeFromDate(projectTime);
  const cleanedDownLimitTime = cleanTimeFromDate(downLimitTime);
  const cleanedUpperLimitTime = upperLimitTime && cleanTimeFromDate(upperLimitTime);

  return cleanedUpperLimitTime
    ? cleanedProjectTime >= cleanedDownLimitTime && cleanedProjectTime <= cleanedUpperLimitTime
    : cleanedProjectTime <= cleanedDownLimitTime;
};

const renderYearText = (value, t): string => {
  if (value === 0) {
    return '';
  }
  return value > 1 ? t('years') : t('year');
};

const renderMonthText = (value, t, withYear, indicatorKey): string => {
  const monthSingularTranslationKey =
    indicatorKey === IndicatorKeyEnum.PROJECTS_PENDING_SIGNATURE_OR_LEGISLATIVE_APPROVAL
      ? 'extended_months_column_label_singular'
      : 'month';
  const monthPluralTranslationKey =
    indicatorKey === IndicatorKeyEnum.PROJECTS_PENDING_SIGNATURE_OR_LEGISLATIVE_APPROVAL
      ? 'extended_months_column_label_plural'
      : 'months';
  if (value === 0) {
    return '';
  }
  return value > 1
    ? `${withYear ? ',' : ''} ${value} ${t(monthPluralTranslationKey)}`
    : `${withYear ? ',' : ''}${value} ${t(monthSingularTranslationKey)}`;
};

const renderDaysText = (value, t): string => {
  if (value === 0) {
    return '';
  }
  return value > 1 ? `${value} ${t('days')}` : `${value} ${t('day')}`;
};

const handleEarlyWarningTimeLapseText = (today: Date, projectDate: Date, t: Function, indicatorKey = ''): string => {
  let years = 0;
  let months = 0;
  let text = '';
  const monthsQty = today.getMonth() - projectDate.getMonth() + 12 * (today.getFullYear() - projectDate.getFullYear());
  if (monthsQty >= 12) {
    years = monthsQty / 12;
    const monthsToYearsString = years.toString();
    const monthsToYearsSplit = monthsToYearsString.split('.');
    years = parseInt(monthsToYearsSplit[0]);
    months = monthsToYearsSplit[1] ? parseInt(monthsToYearsSplit[1].substring(0, 1)) : 0;
    text = `${years} ${renderYearText(years, t)}${renderMonthText(months, t, true, indicatorKey)}`;
  } else if (monthsQty === 0) {
    const differenceInDays = today.getTime() - projectDate.getTime();
    const days = Math.ceil(differenceInDays / (1000 * 3600 * 24));
    text = renderDaysText(days, t);
  } else {
    text = renderMonthText(monthsQty, t, false, indicatorKey);
  }
  return text;
};
const isOnCtTab = (projectTypeFilterOnDetail: number): boolean => {
  return projectTypeFilterOnDetail === ProjectProductTypes.CT;
};

const createFilterConfigComponent = (filterConfigValues: FilterConfigValues[]): TabFilterOption[] => {
  return filterConfigValues.map(configValue => {
    const { id, filterIcon, filterLabel } = configValue;
    return {
      id,
      projectQuantity: 0,
      projectLabel: { singular: 'project', plural: 'projects' },
      filterIcon,
      filterLabel,
      selected: false,
    };
  });
};
const createFilterConfigComponentPlans = (filterConfigValues: FilterConfigValues[]): TabFilterOption[] => {
  return filterConfigValues.map(configValue => {
    const { id, filterIcon, filterLabel } = configValue;
    return {
      id,
      projectQuantity: 0,
      projectLabel: { singular: 'last_adquisition_plans', plural: 'last_adquisition_plans' },
      filterIcon,
      filterLabel,
      selected: false,
    };
  });
};

const getUniqueCodeProjectsList = (projects: any[]) => {
  const uniqueCode: any = [];
  return projects.filter((project: any) => {
    const isDuplicate = uniqueCode.includes(project.code);

    if (!isDuplicate) {
      uniqueCode.push(project.code);
      return true;
    }
    return false;
  });
};

const addFilterConditionOnIgSection = (projectTypeFilterOnDetail, earlyWarningsTab, field) => {
  return !filterTableOnIg(projectTypeFilterOnDetail, earlyWarningsTab) ? isDiferentToIGLower(field) : () => { };
};

export {
  renderYearText,
  renderMonthText,
  renderDaysText,
  handleEarlyWarningTimeLapseText,
  filterProjectsBetweenLimitsByDays,
  getSubstractedDateByDays,
  isOnCtTab,
  getSubstractedDateByMonths,
  sumDays,
  substractDays,
  dateIsAfterToday,
  createFilterConfigComponent,
  getUniqueCodeProjectsList,
  addFilterConditionOnIgSection,
  createFilterConfigComponentPlans
};
