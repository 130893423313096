import { SET_SUCCESS_MESSAGE } from './types';

export function setSuccessMessage(value: { show: boolean; message: string }) {
  return {
    type: SET_SUCCESS_MESSAGE,
    payload: value,
  };
}

export function showSuccesMessage(message = '') {
  return (dispatch: any) => {
    dispatch(setSuccessMessage({ show: true, message }));
    setTimeout(() => {
      dispatch(setSuccessMessage({ show: false, message: '' }));
    }, 3000);
  };
}
