import { EventType } from './../models/EventType';
import { axiosDefaultConfig } from './axiosConfig';
import * as config from './config.json';
import CalendarEvent from '../models/CalendarEvent';
import { store } from '../redux/store';
import { buildGet } from './genericMethods';

const projectIdVariable = '{projectId}';
const eventIdVariable = '{eventId}';
const eventTypeVariable = '{eventType}';

const basicGet = async (url: string, resolve: Function, reject: Function) => {
  try {
    const response = await axiosDefaultConfig.get(url);
    if (response && response.data) {
      resolve(response.data);
    } else {
      reject('error');
    }
  } catch (error) {
    reject(error);
  }
};

const basicPostEmptyResolve = (url, data) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      await axiosDefaultConfig.post(url, data);
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const getEvents = (projectId: number, month: string, eventTypes: string) => {
  let url = config.PROJECT_CALENDAR.replace(projectIdVariable, projectId.toString());
  url = `${url}?month=${month}${eventTypes}`;
  return new Promise<CalendarEvent[]>(async (resolve, reject) => {
    basicGet(url, resolve, reject);
  });
};

export const getNextEvents = (projectId: number, date) => {
  let url = config.NEXT_CALENDAR_EVENTS.replace(projectIdVariable, projectId.toString());
  url = `${url}?date=${date}`;
  return buildGet(url);
};

export const getEventDetail = (eventId: number, eventType: number, itemProjectId, isCalendarIndicator = false) => {
  let url = '';
  switch (eventType) {
    case EventType.Disbursement:
      const projectId = isCalendarIndicator ? itemProjectId : store.getState().currentProject.id;
      if (!projectId) {
        url = '';
        break;
      }
      url = config.DISBURSEMENT.replace(projectIdVariable, projectId.toString()).replace(
        '{disbursementId}',
        eventId.toString(),
      );
      break;
    case EventType.MonitoringAccordance:
    case EventType.RevisionAccordance:
    case EventType.InterGroup:
      url = config.ACCORDANCE_ID.replace('{id}', eventId.toString());
      break;
    case EventType.Acquisition:
      url = config.AQCUISITION.replace('{id}', eventId.toString());
      break;
    case EventType.Activity:
      url = config.ACTIVITY.replace('{id}', eventId.toString());
      break;
    case EventType.ExternalEvent:
      url = config.EXTERNAL_EVENT.replace('{id}', eventId.toString());
      break;
    case EventType.Mission:
      url = config.MISSION_EVENT.replace('{id}', eventId.toString());
      break;
    case EventType.Lifecycle:
      url = config.LIFECYCLE_EVENT.replace('{id}', eventId.toString());
      break;
    case EventType.SupervisoryAction:
      url = config.SUPERVISION_EVENT.replace('{supervisionPlanItemId}', eventId.toString());
      break;
    default:
      url = '';
      break;
  }
  return new Promise<CalendarEvent>(async (resolve, reject) => {
    basicGet(url, resolve, reject);
  });
};

export const syncCalendarOutlook = (projectId: number, data: any) => {
  const url = config.SYNC_CALENDAR.replace(projectIdVariable, projectId.toString());
  return basicPostEmptyResolve(url, data);
};

export const syncCalendarEventOutlook = (projectId: number, eventId: number, data: any) => {
  let url = '';
  if (parseInt(data.eventType) !== EventType.ExternalEvent) {
    url = config.SYNC_CALENDAR_EVENT.replace(projectIdVariable, projectId.toString()).replace(
      eventIdVariable,
      eventId.toString(),
    );
  } else {
    url = config.SYNC_CALENDAR_EXTERNAL_EVENT.replace('{eventId}', eventId.toString());
  }
  return basicPostEmptyResolve(url, data);
};

export const getCalendarEventInfo = (eventId: number, eventType: EventType) => {
  const projectId = store.getState().currentProject.id;
  const url = config.GET_CALENDAR_EVENT.replace(projectIdVariable, projectId.toString())
    .replace(eventIdVariable, eventId.toString())
    .replace(eventTypeVariable, eventType.toString());
  return new Promise<any>(async (resolve, reject) => {
    try {
      const response = await axiosDefaultConfig.get(url);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getEventsListDetails = (eventType: string, date: string) => {
  const dateToParse = new Date(date);
  const year = dateToParse.getFullYear().toString();
  const month = (dateToParse.getMonth() + 1).toString();
  const day = dateToParse.getDate().toString();
  const url = config.EVENTS_DETAIL.replace('{eventType}', eventType)
    .replace('{year}', year)
    .replace('{month}', month)
    .replace('{day}', day);

  return buildGet(url);
};
