import React from 'react';
import { useTranslation } from 'react-i18next';
import { sortDates } from '../../../../services/sortsUtil';
import { ReactComponent as Green } from '../../../../assets/early_warning_green.svg';
import { ReactComponent as Yellow } from '../../../../assets/early_warning_yellow.svg';
import { ReactComponent as Red } from '../../../../assets/early_warning_red.svg';
import styles from './AlertHistory.module.scss';
import { EarlyWarningTypeLabel } from '../../../../models/EarlyWarnings';
import { EWHistory } from '../../../../models/EarlyWariningHistory';
import { renderDateOrSlash } from '../../../../services/stringUtil';
import { bullets } from '../InfoEarlyWarningsHistory/InfoEarlyWarningsHistory';
import { Bullet } from '../../../../models/Bullet';

const {
  alertHistoryContainer,
  title,
  largeTitle,
  timeLapseContainer,
  alertHistoryLineSection,
  alertHistoryDataContainer,
  dataAlertHistory,
  dataDate,
  alertHistoryContainerTypeHeader,
} = styles;

type Props = {
  alertData: EWHistory[];
  hasLargeTexts: boolean;
  typeHeader?: boolean;
};
export const AlertHistory = ({ alertData, hasLargeTexts, typeHeader }: Props) => {
  const { t } = useTranslation();
  const titleId = alertData && alertData[0].operativeAlert;

  const translationsKeys = {
    [EarlyWarningTypeLabel.pendingSignature]: 'signature_pending',
    [EarlyWarningTypeLabel.expired]: 'with_overdue_disbursement_term',
    [EarlyWarningTypeLabel.esgSafeguard]: 'with_unsatisfactory_performance_in_safeguards',
    [EarlyWarningTypeLabel.eligibleUnDisbursement]: 'eligible_pending_first_disbursement',
    [EarlyWarningTypeLabel.cancelled]: 'with_partial_cancellations',
    [EarlyWarningTypeLabel.pmr]: 'with_recent_alert_or_problem_classifications',
  };

  const bulletsFilter = bullets.filter(bullet => bullet.title === translationsKeys[titleId]);
  const bullet =
    bulletsFilter.length > 0
      ? bulletsFilter[0]
      : {
          title: '',
          green: '',
          yellow: '',
          red: '',
        };

  return (
    <div className={typeHeader ? alertHistoryContainerTypeHeader : alertHistoryContainer}>
      <div className={hasLargeTexts ? largeTitle : title}>{t(translationsKeys[titleId])}</div>
      <div className={timeLapseContainer}>
        {alertData && <TimeLapseAlertHistory alerts={alertData.sort(sortDates('date'))} bullet={bullet} />}
      </div>
    </div>
  );
};

type TimeLapseAlertHistoryProps = {
  alerts: EWHistory[];
  bullet: Bullet;
};
export const TimeLapseAlertHistory = ({ alerts, bullet }: TimeLapseAlertHistoryProps) => {
  const mapIcons = {
    0: <Green />,
    1: <Yellow />,
    2: <Red />,
  };
  const mapIconsString = {
    0: 'green',
    1: 'yellow',
    2: 'red',
  };
  return (
    <>
      <ul className={alertHistoryLineSection}>
        {alerts.map(alert => {
          return (
            <li className={alertHistoryDataContainer}>
              <div className={dataAlertHistory}>{mapIcons[alert.color]}</div>
              <span role="list" aria-label={bullet[mapIconsString[alert.color]]} className={dataDate}>
                {renderDateOrSlash(alert.date, 'MM/YYYY')}
              </span>
            </li>
          );
        })}
      </ul>
    </>
  );
};
