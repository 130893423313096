import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEarlyWarningsFilters } from '../../../hooks/useEarlyWarningsFilters';
import { FilterConfigValues, IconCode, TabFilterOption } from '../../../models/EarlyWarnings';
import { getProjectsSimpleTableIndicator } from '../../../redux/actions/indicatorProjects';
import { PROJECTS_PARTIAL_CANCELLATIONS } from '../../../services/sessionFiltersIndicatorDetailHelper';
import { EarlyWarningsFilters as WithPartialCancellationsEarlyWarningsFilters } from '../EarlyWarningsFilters/EarlyWarningsFilters';
import styles from './ProjectsWithPartialCancellations.module.scss';
import { WithPartialCancellationsTable } from './Table/Table';
import { createFilterConfigComponent } from '../EarlyWarningsFilters/Util';
import { showEarlyWarning } from '../PortfolioDetailTablesUtil';
import { handleLoading } from '../../utils';

const filterConfigValues: FilterConfigValues[] = [
  { id: 0, filterIcon: IconCode.GREEN, filterLabel: 'no_partial_cancellations' },
  { id: 1, filterIcon: IconCode.YELLOW, filterLabel: 'with_cancellations_of_up_to_15' },
  { id: 2, filterIcon: IconCode.RED, filterLabel: 'with_cancellations_higher_than_15' },
];
const filterConfigComponent: TabFilterOption[] = createFilterConfigComponent(filterConfigValues);

const defaultSessionFilterObject = {
  filterConfigState: filterConfigComponent,
  changeProjectType: true,
};

export const ProjectsWithPartialCancellations = () => {
  const dispatch = useDispatch();
  const { container, earlyAlertsFilterContainer, bodyContainer } = styles;
  const { currentIndicator } = useSelector<any, any>(state => state);
  const { projectTypeFilterOnDetail } = useSelector<any, any>(state => state.indicatorDetailFilter);
  const { filterConfigState, handleEarlyWarningsFilter, handleProjectsQuantities } = useEarlyWarningsFilters({
    sessionKey: PROJECTS_PARTIAL_CANCELLATIONS,
    defaultSessionFilterObject,
    projectTypeFilterOnDetail,
  });
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getFilteredSimpleIndicators(dispatch, currentIndicator.id, currentIndicator.indicatorKey, setLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndicator.id, dispatch, projectTypeFilterOnDetail]);

  useEffect(() => {
    if (!sessionStorage.getItem(PROJECTS_PARTIAL_CANCELLATIONS)) {
      sessionStorage.setItem(PROJECTS_PARTIAL_CANCELLATIONS, JSON.stringify(defaultSessionFilterObject));
    }
  }, []);
  return (
    <div className={container}>
      {showEarlyWarning(projectTypeFilterOnDetail) && (
        <div className={earlyAlertsFilterContainer}>
          <WithPartialCancellationsEarlyWarningsFilters
            filterSet={filterConfigState}
            handleFilter={handleEarlyWarningsFilter}
          />
        </div>
      )}
      <div className={bodyContainer}>
        <WithPartialCancellationsTable
          projectsQuantities={handleProjectsQuantities}
          earlyWarningsTab={[
            filterConfigState[0].selected,
            filterConfigState[1].selected,
            filterConfigState[2].selected,
          ]}
          loading={loading}
        />
      </div>
    </div>
  );
};

const getFilteredSimpleIndicators = (d, currentIndicatorId, currentIndicatorKey, setLoading) => {
  return handleLoading(() => d(getProjectsSimpleTableIndicator(currentIndicatorId, currentIndicatorKey)), setLoading);
};
