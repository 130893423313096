/* eslint-disable array-callback-return */
import React, { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { projectAllCustomStyle } from './ProjectsAll.Style';
import { useTranslation } from 'react-i18next';
import './ProjectsAll.scss';
import Project from '../../../models/Project';
import SearchInput from '../../Util/CustomInput/SearchInput/SearchInput';
import { getFollowUnfollowProjectFromApi } from '../../../redux/actions/followedProjects';
import { setFilter } from '../../../redux/actions/filter';
import { ProjectsListSkeleton } from '../../LoadersSkeletons/ProjectsListSkeleton/ProjectsListSkeleton';
import '../../Util/Pagination/Pagination.scss';
import { isMobile } from '../../../services/booleanUtil';
import ProjectTypeFilter from '../../Filter/ProjectTypeFilter/ProjectTypeFilter';
import FilterProjects from '../../../models/FilterProjects';
import { MenuTabsComponent } from '../../MenuTabs/MenuTabsComponent';
import { AppMenuTabs, MenuTabSectionEnum } from '../../MenuTabs/userTabsInterface';
import { getNewFilteredProjects } from '../../../api/projectApi';
import { ProjectsAllTable } from './ProjectsAllTable/ProjectsAllTable';
import { handleLoading } from '../../utils';

import { setLoading as reduxLoading } from '../../../redux/actions/dataTable';

const ProjectsAll = () => {
  const projects = useSelector<any, Project[]>(state => state.allProjects);
  const filter = useSelector<any, FilterProjects>(state => state.filter);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const classes = makeStyles(projectAllCustomStyle)();
  const { projectSelectedTab } = useSelector<any, AppMenuTabs>(state => state.menuTabs);
  const initialPage = filter.allProjectsPagination.currentPage;
  const numberOfProjects = filter.allProjectsPagination.rowCount;
  const numberOfPages = filter.allProjectsPagination?.pageCount;
  const searchValue = filter.allProjectsFilter.searchValue;
  const [firstTime, setFirstTime] = React.useState(true);
  const itsSearch = filter.common.itSearch;
  const [loading, setLoading] = useState(true);

  const handleChangePaginate = (event, value) => {
    paginate(value);
  };

  React.useEffect(() => {
    dispatch(
      setFilter({
        ...filter,
        allProjectsFilter: {
          ...filter.allProjectsFilter,
          searchValue: '',
        },
        common: {
          ...filter.common,
          itSearch: false,
        },
        allProjectsPagination: {
          ...filter.allProjectsPagination,
          pageSize: 9,
          currentPage: 1,
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectSelectedTab]);

  React.useEffect(() => {
    if (firstTime) {
      dispatch(
        setFilter({
          ...filter,
          allProjectsPagination: {
            ...filter.allProjectsPagination,
            pageSize: 9,
            currentPage: initialPage,
          },
        }),
      );

      handleLoading(getNewFilteredProjects, setLoading);
      setFirstTime(false);
    }

    if (itsSearch) {
      paginate(1);
    } else if (!itsSearch && !firstTime) {
      handleLoading(getNewFilteredProjects, setLoading);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, filter.common]);

  React.useEffect(() => {
    dispatch(reduxLoading(loading));
  }, [loading]);

  const paginate = (pageNumber: any) => {
    dispatch(
      setFilter({
        ...filter,
        allProjectsPagination: {
          ...filter.allProjectsPagination,
          currentPage: pageNumber,
        },
      }),
    );
    handleLoading(getNewFilteredProjects, setLoading);
  };

  const setInFollow = (projectId: number) => {
    dispatch(getFollowUnfollowProjectFromApi(projectId, false));
  };

  const sortTable = (sortValue: any) => {
    dispatch(
      setFilter({
        ...filter,
        allProjectsFilter: {
          ...filter.allProjectsFilter,
          sortParamenter: sortValue.concept,
          orderDesc: !filter.allProjectsFilter?.orderDesc,
        },
      }),
    );
    handleLoading(getNewFilteredProjects, setLoading);
  };

  return (
    <Fragment>
      <div className="all-container">
        <div className="all-container-options">
          <div className="title-all-projects">{t('all_projects_title')}</div>

          {!isMobile() && (
            <div className="title-options-projects-all">
              <SearchInput itsFollowed={false} />
            </div>
          )}
        </div>
        <div className="all-projects-filter">
          <div className={classes.root}>
            <MenuTabsComponent selectedTab={projectSelectedTab} menuTabSection={MenuTabSectionEnum.PROJECT_MENU_TAB} />
          </div>
        </div>
        <div className="project-type-filter-container">
          <ProjectTypeFilter filterTitle={'see'} isRight />
        </div>
       
        {loading ? (
          <ProjectsListSkeleton />
        ) : (
          <ProjectsAllTable
            projects={projects}
            numberOfPages={numberOfPages}
            numberOfProjects={numberOfProjects}
            setInFollow={setInFollow}
            sortTable={sortTable}
            initialPage={initialPage}
            handleChangePaginate={handleChangePaginate}
          />
        )}
      </div>
    </Fragment>
  );
};

export default ProjectsAll;
