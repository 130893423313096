import React from 'react';
import { useTranslation } from 'react-i18next';
import * as config from '../../../../api/config.json';
import styles from './ConvergenceButton.module.scss';
import CSS from 'csstype';
import { ReactComponent as LogoConvergencia } from '../../../../assets/logo_convergencia.svg';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
const BlackTooltip = withStyles({
  tooltip: {
    backgroundColor: '#101319',
    fontSize: '16px',
    fontFamily: 'Rubik',
    lineHeight: '20px',
    padding: '8px 12px',
    borderRadius: '8px',
  },
  arrow: {
    fontSize: 20,
    color: '#4A4A4A',
    '&::before': {
      backgroundColor: '#101319',
    },
  },
})(Tooltip);

type Props = {
  projectCode: string;
  customStyle?: CSS.Properties;
};

const ConvergenceButton: React.FC<Props> = ({ projectCode = '', customStyle = {} }: Props) => {
  const { container } = styles;
  const { t } = useTranslation();

  const handleClick = () => {
    const url = `${config.CONVERGENCIA_URL.replace('{project_number}', projectCode.toString().trim())}`;
    window.open(url, '_blank');
  };
  return (
    <BlackTooltip title={t('go_to_convergence') || ''} placement="bottom-start" enterDelay={500} leaveDelay={200} arrow>
      <button className={container} onClick={handleClick}>
        <LogoConvergencia />
      </button>
    </BlackTooltip>
  );
};

export default ConvergenceButton;
