import React from 'react';
import './CardPortfolioSkeleton.scss';
import Skeleton from '@material-ui/lab/Skeleton';
import { isMobile } from '../../../services/booleanUtil';


export const CardPortfolioSkeletonChart = () => {
  const skeletonValues = [
    {
      id: 1,
      width: '80%',
    },
    {
      id: 2,
      width: '50%',
    },
    {
      id: 3,
      width: '60%',
    },

    {
      id: 4,
      width: '40%',
    },

    {
      id: 5,
      width: '25%',
    },

    {
      id: 6,
      width: '20%',
    },
  ];
  return (
    <div className="card-portfolio-skeleton-chart">
      <div className="title">
        <Skeleton animation="wave" variant="text" width={'70%'} height={isMobile() ? 11 : 25} />
      </div>
      <div className="chart-container-horizontal">
        {skeletonValues.map(skeleton => (
          <Skeleton
            key={skeleton.id}
            animation="wave"
            variant="text"
            width={skeleton.width}
            height={isMobile() ? 6 : 13}
            style={{ marginTop: isMobile() ? 9 : 20 }}
          />
        ))}
      </div>
    </div>
  );
};

export const CardPortfolioSkeletonNumber = () => {
  return (
    <div className="card-portfolio-skeleton-number">
      <div className="number">
        <Skeleton animation="wave" variant="circle" width={isMobile() ? 27 : 58} height={isMobile() ? 27 : 58} />
      </div>
      <div className="number-container-horizontal">
        <div className="number-container-horizontal-inner">
          <Skeleton
            animation="wave"
            variant="text"
            width={isMobile() ? 48 : 103}
            height={isMobile() ? 7 : 15}
            style={{ marginTop: isMobile() ? 11 : 25 }}
          />
        </div>
        <div className="number-container-horizontal-inner">
          <Skeleton animation="wave" variant="text" width={isMobile() ? 112 : 241} height={isMobile() ? 11 : 24} />
        </div>
      </div>
    </div>
  );
};
