import { AnalyticsKeyEnum } from '../../../../models/AnalyticsKeyEnum';
import { getFormattedValue, getIndicatorData } from '../../../Portfolio/IndicatorsHelpers/indicatorHelperUtil';
import { getCirclePercentage } from '../util';

const addIndicatorsCustomization = (analyticList, analyticIndicators) => {
  const analyticIndicator = getIndicatorData(
    analyticList,
    AnalyticsKeyEnum.PORTFOLIO_INDICATORS_INDICATOR_CUSTOMIZATION,
  );

  const returnedInd = analyticsRadials({
    ...analyticIndicator,
    title: 'indicator_customization',
    internalLabel: 'deleted',
    externalLabel: 'added',
  });
  returnedInd && returnedInd.data && analyticIndicators.push(returnedInd);
};
const addMyProjects = (analyticList, analyticIndicators) => {
  const analyticIndicator = getIndicatorData(analyticList, AnalyticsKeyEnum.PROJECT_INDICATORS_MY_PROJECTS);

  const returnedInd = analyticsRadials({
    ...analyticIndicator,
    title: 'following_projects_visualization',
    subtitle: 'click_on_buttons',
    internalLabel: 'list_mode',
    externalLabel: 'card_mode',
  });
  returnedInd && returnedInd.data && analyticIndicators.push(returnedInd);
};

const addCalendarSynchronizationWithOutlook = (analyticList, analyticIndicators) => {
  const analyticIndicator = getIndicatorData(
    analyticList,
    AnalyticsKeyEnum.PROJECT_INDICATORS_CALENDAR_SYNCHRONIZATION_WITH_OUTLOOK,
  );

  const returnedInd = analyticsRadials({
    ...analyticIndicator,
    title: 'calendar_sync_with_outlook',
    internalLabel: 'not_synchronized',
    externalLabel: 'synchronized',
  });
  returnedInd && returnedInd.data && analyticIndicators.push(returnedInd);
};
const addDisbursementData = (analyticList, analyticIndicators) => {
  const analyticIndicator = getIndicatorData(analyticList, AnalyticsKeyEnum.PROJECT_INDICATORS_DISBURSEMENT_DATA);

  const returnedInd = analyticsRadials({
    ...analyticIndicator,
    title: 'disbursement_data',
    subtitle: 'disbursement_data_indicator_subtitle',
    internalLabel: 'accumulated',
    externalLabel: 'annual',
  });
  returnedInd && returnedInd.data && analyticIndicators.push(returnedInd);
};

const analyticsRadials = analyticIndicator => {
  const { key, title, internalLabel, externalLabel, subtitle } = analyticIndicator;
  const basicIndicatorCard = {
    indicatorKey: key,
    type: 'radial',
    title,
    subtitle,
    data: null,
  };

  if (!analyticIndicator.metrics || analyticIndicator.metrics.length <= 1) {
    return basicIndicatorCard;
  } else {
    const total =
      getFormattedValue(analyticIndicator.metrics, 'INTERNAL') +
      getFormattedValue(analyticIndicator.metrics, 'EXTERNAL');
    const internal = getFormattedValue(analyticIndicator.metrics, 'INTERNAL');
    const external = getFormattedValue(analyticIndicator.metrics, 'EXTERNAL');
    const data = {
      total,
      internal,
      external,
      internalLabel,
      externalLabel,
      datasets: [
        {
          backgroundColor: ['#00cfdd', '#5a8dee'],
          data: [getCirclePercentage(total, internal, external), getCirclePercentage(total, external, internal)],
          dataLabelsTranslations: [internalLabel, externalLabel],
          valueQuantity: [internal, external],
          borderWidth: 2,
          hoverBackgroundColor: ['#00cfdd', '#5a8dee'],
        },
      ],
    };

    return { ...basicIndicatorCard, data };
  }
};

export { addIndicatorsCustomization, addMyProjects, addCalendarSynchronizationWithOutlook, addDisbursementData };
