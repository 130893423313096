import React, { useState, useEffect } from 'react';
import styles from './AvailableProjects.module.scss';
import SelectableProjects from '../SelectableProjects/SelectableProjects';
import { basicPost } from '../../../api/projectApi';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import User from '../../../models/User';
import SelectAll from '../SelectAll/SelectAll';
import Search from '../Search/Search';
import { cloneDeep } from 'lodash';
import FiltersContainer from '../Filters/FiltersContainer/FiltersContainer';
import { basicFilters, filterTablesByTerm, updateProjectsTableList, modalStyles, addFilterProps } from './util';
import SaveButton from '../../Util/Buttons/SaveButton/SaveButton';
import { parseFilterToApiCall } from '../../../services/util';
import { isMobile } from '../../../services/booleanUtil';
import { ReactComponent as FilterButton } from '../../../assets/filter.svg';
import CloseCrossButton from '../../Util/Buttons/CloseCrossButton/CloseCrossButton';
import SelectableProjectsResponsive from '../SelectableProjectsResponsive/SelectableProjectsResponsive';
import { updateReviewProjects } from '../../../api/reviewsApi';
import { setShowSelectProjects } from '../../../redux/actions/reviewShowSelectProjects';
import { setNoneReviewSelectedProject } from '../../../redux/actions/reviewProjectsSelectedRows';
import BaseModal from '../../Util/Modals/BaseModal';
import ProjectTypeFilterCheckbox from '../../Filter/ProjectTypeFilterCheckbox/ProjectTypeFilterCheckbox';
import { ProjectProductTypeFilters } from '../../../models/ProjectProductTypeFilters';
import { showIgrSubFilters, igrIsDisabled, handleCheckChanges } from '../../Filter/ProjectTypeFilter/UtilFunctions';

const currentReviewTitle = currentReview => {
  return currentReview ? currentReview.title : '';
};

const setAllProjectsTitle = (user, t) => {
  return user.language && user.language.encodedName === 'en'
    ? `${t('every_projects_from')} ${user.country.name} ${t('menu_portfolio')}`
    : `${t('every_projects_from')} ${user.country.name}`;
};

const selectedText = (count: number, t: any) => {
  return count === 1 ? t('selected_project') : t('selected_projects');
};

const getFilterOrTextSearch = (selectedfilters: any, textSearch: any) =>
  selectedfilters.length || textSearch.length ? true : false;

const differentFilters = (newFilters, filterState) => newFilters.length !== filterState.length;
const setTables = (textSearch: any, setProjectsTables: Function, newList: any[]) =>
  textSearch.length
    ? setProjectsTables(filterTablesByTerm(cloneDeep(newList), textSearch))
    : setProjectsTables(newList);

const getProjectTypeFiltersReviewStorage = () => {
  const projectTypeFiltersReviews = localStorage.getItem('projectTypeFiltersReviews');
  if (projectTypeFiltersReviews === null) {
    localStorage.setItem('projectTypeFiltersReviews', JSON.stringify([ProjectProductTypeFilters.Loan]));
    return [ProjectProductTypeFilters.Loan];
  } else {
    return JSON.parse(projectTypeFiltersReviews);
  }
};

const AvailableProjects = () => {
  const currentReview = useSelector<any, any>(state => state.currentReview);
  const [loading, setLoading] = useState<boolean>(true);
  const [projectsTables, setProjectsTables] = useState<any>([]);
  const [projectsTableToSearchUse, setProjectsTableToSearchUse] = useState<any>([]);
  const user = useSelector<any, User>(state => state.user);
  const reviewProjectsSelectedRows = useSelector<any, any>(state => state.reviewProjectsSelectedRows);
  const [allProjects, setAllProjects] = useState<any[]>([]);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedfilters = useSelector<any, any>(state => state.reviewProjectsSelectedFilters);
  const allProjectsTitle = setAllProjectsTitle(user, t);
  const otherProjectsTitle = `${t('other_projects_from')} ${user.country.name}`;
  const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);
  const { textSearch } = useSelector<any, any>(state => state);
  const existAtLeastOneFilterOrTextSearch = getFilterOrTextSearch(selectedfilters, textSearch);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [projectTypeFilterState, setProjectTypeFilterState] = useState<number[]>(getProjectTypeFiltersReviewStorage());

  const getData = async () => {
    try {
      const response = await basicPost({
        ...basicFilters,
        Filters: [{ filterType: 6, field: 'ResponsibleCountryId', value: [user.country.id] }],
        IncludeBaseInformation: true,
        RevisionId: currentReview.id,
        projectProductAmounts: parseFilterToApiCall(projectTypeFilterState).projectProductAmounts,
        projectProductTypes: parseFilterToApiCall(projectTypeFilterState).projectProductTypes,
      });
      const result = addFilterProps(response, currentReview.startDate);
      setAllProjects(result);
      getFilteredData(result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getFilteredData = result => {
    if (existAtLeastOneFilterOrTextSearch) {
      const newList = updateProjectsTableList(selectedfilters, result, t, otherProjectsTitle);
      setTables(textSearch, setProjectsTables, newList);
      setProjectsTableToSearchUse(cloneDeep(newList));
    } else {
      setProjectsTables([{ title: allProjectsTitle, list: cloneDeep(result) }]);
      setProjectsTableToSearchUse([{ title: allProjectsTitle, list: cloneDeep(result) }]);
    }
  };

  useEffect(() => {
    getFilteredData(allProjects);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedfilters]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectTypeFilterState]);

  const handleSearch = term => {
    if (!term) {
      setProjectsTables(projectsTableToSearchUse);
    } else if (term.length > 1) {
      const newTables = filterTablesByTerm(cloneDeep(projectsTableToSearchUse), term);
      setProjectsTables(newTables);
    }
  };

  const updateProjects = async () => {
    setSubmitting(true);
    try {
      await updateReviewProjects(currentReview.id, reviewProjectsSelectedRows);
      dispatch(setShowSelectProjects(false));
      dispatch(setNoneReviewSelectedProject());
      setSubmitting(false);
    } catch (err) {
      setSubmitting(false);
    }
  };

  const setterFunction = (newFilters, filterState) => {
    if (differentFilters(newFilters, filterState)) {
      localStorage.setItem('projectTypeFiltersReviews', JSON.stringify(newFilters));
      setProjectTypeFilterState(newFilters);
    }
  };

  const handleProjectTypeFilterChanges = (checkboxData: any) => {
    const { projectProductType } = checkboxData;
    handleCheckChanges(projectTypeFilterState, projectProductType, setterFunction);
  };

  return (
    <>
      <div className={styles.container}>
        <h2 className={styles.title}>{`${t('select_projects_for')} ${currentReviewTitle(currentReview)} `}</h2>
        <div className={styles.searchContainer}>
          <Search onSearch={handleSearch} />
          <button className={styles.fiterButton} onClick={() => setShowFiltersModal(true)}>
            <FilterButton />
          </button>
        </div>
        {isMobile() ? null : (
          <div>
            <ProjectTypeFilterCheckbox
              filterTitle={'see'}
              handleChanges={handleProjectTypeFilterChanges}
              igrIsDisabled={() => igrIsDisabled(projectTypeFilterState)}
              showIgrSubFilters={() => showIgrSubFilters(projectTypeFilterState)}
              isRight
              filterState={projectTypeFilterState}
            />
            <FiltersContainer projects={allProjects} projectTypeFilterState={projectTypeFilterState} />
            <div className={styles.selectAllContainer}>
              <SelectAll allProjects={allProjects} />
            </div>
          </div>
        )}
        <SelectableProjectsSection loading={loading} projectsTables={projectsTables} />
        {reviewProjectsSelectedRows.length > 0 && (
          <div className={styles.saveContainer}>
            <span className={styles.selectedCount}>
              {reviewProjectsSelectedRows.length} {selectedText(reviewProjectsSelectedRows.length, t)}
            </span>
            <SaveButton handleClick={updateProjects} customText={t('save_selected')} disabled={submitting} />
          </div>
        )}
      </div>
      {isMobile() ? (
        <BaseModal
          isOpen={showFiltersModal}
          style={modalStyles}
          onRequestClose={() => setShowFiltersModal(false)}
          className={styles.modalFilterContent}
        >
          <div className={styles.closeCrossContainer}>
            <CloseCrossButton handleClick={() => setShowFiltersModal(false)} />
          </div>
          <FiltersContainer projects={allProjects} projectTypeFilterState={projectTypeFilterState} />
        </BaseModal>
      ) : null}
    </>
  );
};

export default AvailableProjects;

type Props = {
  loading: boolean;
  projectsTables: any;
};

const SelectableProjectsSection = ({ loading, projectsTables }: Props) => {
  return !loading ? (
    <div>
      {projectsTables.map(projectList => {
        return isMobile() ? (
          <SelectableProjectsResponsive key={projectList.title} projects={projectList} clickable={true} />
        ) : (
          <SelectableProjects key={projectList.title} projects={projectList} selectable={true} />
        );
      })}
    </div>
  ) : null;
};
