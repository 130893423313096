import { SET_DIVISIONS } from './types';
import Division from '../../models/Division';
import { getDivisions } from '../../api/filterApi';

export function setDivisions(divisions: Division[]) {
  return {
    type: SET_DIVISIONS,
    payload: divisions,
  };
}

export function getCurrentDivisions() {
  return async (dispatch: any) => {
    try {
      const response = await getDivisions();
      dispatch(setDivisions(response));
    } catch (error) {}
  };
}
