import React from 'react';
import { useTranslation } from 'react-i18next';
import './AccordanceDetail.scss';
import AccordanceDetailInfo from '../../../ProjectDetail/ProjectTabs/Accordances/AccordanceDetailInfo/AccordanceDetailInfo';
import CloseCrossButton from '../../Buttons/CloseCrossButton/CloseCrossButton';
import { approveAccordance, draftToApproval } from '../../../../api/accordanceApi';
import { AccordanceState } from '../../../../models/AccordanceState';
import RejectedAccordanceDetail from '../../../ProjectDetail/ProjectTabs/Accordances/RejectedAccordanceDetail/RejectedAccordanceDetail';
import NewAccordanceForm from '../../../ProjectDetail/ProjectTabs/Accordances/NewAccordanceForm/NewAccordanceForm';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import { getIdFromUrl } from '../../../../services/numberUtil';
import { ReactComponent as EditIcon } from '../../../../assets/icons/u_edit-blue.svg';
import { isDraftOrPending } from '../../../ProjectDetail/ProjectTabs/Accordances/NewAccordanceForm/NewAccordanceFormUtil';
import { getProjectAccordances } from '../../../../redux/actions/currentProject';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FooterButtons from './FooterButtons';

type Props = {
  isOpen: boolean;
  closeModal: any;
  accordance: any;
  showJustificationModal: Function;
  onApproveHandler: Function;
  showConfirmDelete?: Function;
  changeAccordanceStatus: any;
  fromRevision?: boolean;
  role?: number;
  closeShowRelated?: Function;
  onNewValue: Function;
  onNewExpirationDateValue: Function;
  OnNewResponsibleValue: Function;
  hideEdit?: boolean;
  // accordance: any;
  newValue: any;
  submitAccordance: any;
};

const SingleAccordance = (props: Props) => {
  const {
    isOpen,
    closeModal,
    accordance,
    showJustificationModal,
    onApproveHandler,
    showConfirmDelete,
    changeAccordanceStatus,
    fromRevision,
    closeShowRelated = () => {},
    onNewValue,
    onNewExpirationDateValue,
    OnNewResponsibleValue,
    hideEdit,
    newValue,
    submitAccordance,
  } = props;

  const { accordanceState } = accordance;

  const accordanceStateNames = {
    0: 'draft_accordance',
    1: 'pending_accordance_singular',
    2: 'active_accordance_singular',
    3: 'accomplished_accordance_singular',
    4: 'cancelled_accordance_singular',
  };

  const { t } = useTranslation();
  const [editForm, setEditForm] = React.useState(false);
  const [errorsMessage, setErrorsMesssge] = React.useState(false);

  const yearApplied = useSelector<any, any>(state => state.currentProject.accordancesData.selectedYear);
  const accordanceTypeApplied = useSelector<any, any>(state => state.currentProject.accordancesData.selecetdType);

  const [hideApproveButtons, setHideApproveButtons] = React.useState(false);

  const approveAcc = async () => {
    await approveAccordance(accordance.id);
    onApproveHandler();
    closeModal();
  };

  const rejectAcc = async () => {
    closeModal();
    showJustificationModal();
  };

  const deleteAcc = async () => {
    closeModal();
    if (showConfirmDelete) {
      showConfirmDelete();
    }
  };

  React.useEffect(() => {
    if (!isOpen) {
      setEditForm(false);
      setErrorsMesssge(false);
    }
  }, [isOpen]);

  const accWasEdited = (value: boolean) => {
    onNewValue(value);
    if (value) {
      setHideApproveButtons(true);
    } else {
      setHideApproveButtons(false);
    }
  };

  const dispatch = useDispatch();
  const history = useHistory();

  const submitDraftToApproval = async () => {
    try {
      await draftToApproval(accordance.id);
      dispatch(getProjectAccordances(getIdFromUrl(history.location.search), yearApplied, accordanceTypeApplied));
      props.closeModal();
    } catch (error) {}
  };

  const colorText = () => {
    switch (accordance.accordanceState) {
      case 1:
        return 'pendding';
      case 3:
        return 'compliment';
      default:
        return 'current';
    }
  };

  return (
    <>
      <div className="modal-accordance-detail-container">
        <div className="close-cross-container">
          <CloseCrossButton handleClick={closeModal} />
        </div>

        {accordance.accordanceState === AccordanceState.REJECT && !editForm ? (
          <h3 style={{ color: '#FF0000', marginTop: 20 }}>{t('accordance_was_rejected')}</h3>
        ) : (
          <>
            <div className="accordance-header-container">
              <div className="accordance-header-container-info">
                <h3>
                  {t('accordance_detail')} / {t('state')}:{' '}
                  <span className={colorText()}>{t(accordanceStateNames[accordanceState])}</span>
                </h3>
                <p>
                  <b>{accordance.project.code}</b> {t('LON_table')}: {accordance.project.loanNumber}
                </p>
              </div>
              <div className="accordance-header-container-buttons">
                {isDraftOrPending(accordance.accordanceState) &&
                  !editForm &&
                  accordance.currentUserCanEdit &&
                  hideEdit !== true && (
                    <div className="accordance-edit-control" onClick={() => setEditForm(true)}>
                      <span className="accordance-edit">
                        <div className="accordance-edit-icon">
                          <EditIcon />
                        </div>
                        {t('edit')}
                      </span>
                    </div>
                  )}
                {renderDelete(accordance, editForm, deleteAcc, t)}
              </div>
            </div>
          </>
        )}
        {errorsMessage && (
          <ErrorMessage
            customStyle={{ position: 'relative', left: -44, width: 622 }}
            alertMessage={t('could_not_edit_accordance')}
            descriptionMessage={t('please_check_required_fields')}
          />
        )}
        {editForm ? (
          <NewAccordanceForm
            displayGenericErrorsMessage={value => setErrorsMesssge(value)}
            close={closeModal}
            editAccordanceData={accordance}
            accordanceFormType={accordance.accordanceType}
            currentUserCanApprove={accordance.currentUserCanApprove}
          />
        ) : accordance.accordanceState === AccordanceState.REJECT ? (
          <RejectedAccordanceDetail accordance={accordance} />
        ) : (
          <AccordanceDetailInfo
            accordance={accordance}
            changeStatus={value => {
              changeAccordanceStatus(value);
            }}
            fromRevision={fromRevision}
            close={closeShowRelated}
            onNewValue={value => {
              accWasEdited(value);
            }}
            onNewExpirationDateValue={onNewExpirationDateValue}
            OnNewResponsibleValue={OnNewResponsibleValue}
            newValue={newValue}
            submitAccordance={submitAccordance}
          />
        )}
      </div>
      <div className="button-footer">
        {!editForm && accordance ? (
          <FooterButtons
            accordance={accordance}
            approveAcc={approveAcc}
            rejectAcc={rejectAcc}
            deleteAcc={deleteAcc}
            setEditForm={setEditForm}
            submitDraftToApproval={submitDraftToApproval}
            closeModal={closeModal}
            changeAccordanceStatus={changeAccordanceStatus}
            hideApproveButtons={hideApproveButtons}
          />
        ) : null}
      </div>
    </>
  );
};

const renderDelete = (accordance, editForm, deleteAcc, t) => {
  return (accordance.accordanceState === AccordanceState.PENDING ||
    accordance.accordanceState === AccordanceState.DRAFT) &&
    !editForm &&
    accordance.currentUserCanDelete ? (
    <div className={'accordance-edit-control'} onClick={deleteAcc}>
      <span className="accordance-edit">
        <div className="accordance-edit-icon">
          <EditIcon />
        </div>
        <p className="accordance-edit">{t('delete_accordance')}</p>
      </span>
    </div>
  ) : null;
};

export default SingleAccordance;
