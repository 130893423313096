import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './AdvanceMobile.scss';
import AdvanceData from '../GeneralInformation/AdvanceData';
import DateText from '../DateText/DateText';
import { DateTextTypes } from '../../../models/DateTextTypes';
import Pmr from '../Pmr/Pmr';
import { useHistory } from 'react-router-dom';
import { getIdFromUrl } from '../../../services/numberUtil';
import { PmrClassification } from '../../../models/PmrClassification';
import { initialDisbursement, initialTemporaryAdvance } from '../GeneralInformation/util';
import { ProjectExecutionTypes } from '../../../models/ProjectExecutionTypes';
import { DisbursementChart } from '../GeneralInformationTC/DisbursementChart/DisbursementChart';
import { ProjectProductTypes } from '../../../models/ProjectProductTypes';
import { SimpleValue } from '../GeneralInformationTC/SimpleValue/SimpleValue';
import { useTranslation } from 'react-i18next';
import { getProject } from '../../../redux/actions/currentProject';
import { ProjectProductTypeClassification } from '../../../models/ProjectProductTypeClassification';

export const AdvanceMobile = () => {
  const { currentProject } = useSelector<any, any>(state => state);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    code = '',
    temporaryAdvance = initialTemporaryAdvance,
    financialInformation = initialDisbursement,
    pmrClassification = PmrClassification.Green,
    pmrValue = 0,
    esgClasification = '',
    projectProductType,
    projectProductTypeClassification,
  } = currentProject;
  const history = useHistory();

  React.useEffect(() => {
    dispatch(getProject(getIdFromUrl(history.location.search)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderMobileProjects = t => {
    const PROJECT_TYPE_MOCK: any = ProjectProductTypes.CT;
    const EXECUTOR_MOCK = ProjectExecutionTypes.BID;
    if (
      PROJECT_TYPE_MOCK === ProjectProductTypes.Loan ||
      (PROJECT_TYPE_MOCK === ProjectProductTypes.IG &&
        projectProductTypeClassification === ProjectProductTypeClassification.IGR_GREATER_THAN_3MM)
    ) {
      return (
        <div
          className="advance-mobile-container"
          style={projectProductType === ProjectProductTypes.GUARANTEE ? { height: 190 } : {}}
        >
          <AdvanceData financialInformation={financialInformation} temporaryAdvance={temporaryAdvance} />

          {projectProductType === ProjectProductTypes.GUARANTEE ? null : (
            <div className="horizontal">
              <DateText
                type={DateTextTypes.Date}
                value={financialInformation.lastDisbursementDate}
                text={'last_accomplished_disbursement_complete'}
              />

              <DateText
                type={DateTextTypes.Date}
                value={financialInformation.nextDisbursementDate}
                text={'next_projected_disbursement'}
              />
            </div>
          )}
          <div className="horizontal">
            <Pmr classification={pmrClassification} value={pmrValue} projectCode={code} />
            <DateText type={DateTextTypes.Esg} value={esgClasification} text={'esg_grade'} />
          </div>
        </div>
      );
    } else {
      const MOCKED_DISBURSED = {
        //TODO este mock es para la grafica de donna
        // Integrar esta prop cuando venga de BE
        total: 36000000,
        disbursed: {
          title: 'disbursed',
          percentage: 70,
          total: 25200000,
        },
        commited: {
          title: 'committed_supervision',
          percentage: 20,
          total: 10800000,
        },
        available: {
          title: 'available',
          percentage: 10,
          total: 10800000,
        },
      };

      const MOCK_SIMPLE_VALUES_DATA = {
        //TODO Integrar esta prop cuando venga de BE
        extensionNumbers: 2,
        daysFromLastDisbursement: 25,
        activeAlerts: 1,
      };

      return (
        <div
          className="advance-mobile-container"
          style={projectProductType === ProjectProductTypes.GUARANTEE ? { height: 190 } : {}}
        >
          {EXECUTOR_MOCK === ProjectExecutionTypes.BID ? (
            <>
              <DisbursementChart
                total={MOCKED_DISBURSED.total}
                disbursed={MOCKED_DISBURSED.disbursed}
                commited={MOCKED_DISBURSED.commited}
                available={MOCKED_DISBURSED.available}
              />
              <AdvanceData
                temporaryAdvance={temporaryAdvance}
                titleOnTop={true}
                isWarranty={projectProductType === ProjectProductTypes.GUARANTEE}
              />
            </>
          ) : (
            <AdvanceData
              financialInformation={financialInformation}
              temporaryAdvance={temporaryAdvance}
              titleOnTop={true}
              isWarranty={projectProductType === ProjectProductTypes.GUARANTEE}
            />
          )}
          <div className="horizontal">
            <SimpleValue
              firstLineTitle={t('number_of_extensions_ct')}
              value={MOCK_SIMPLE_VALUES_DATA.extensionNumbers.toString()}
              maxWidth={85}
            />

            <SimpleValue
              firstLineTitle={t('days_from_last_disbursement_done')}
              value={`${MOCK_SIMPLE_VALUES_DATA.daysFromLastDisbursement} ${t('days')}`}
              maxWidth={170}
            />
          </div>
          <div className="horizontal">
            <SimpleValue
              firstLineTitle={t('detail of')}
              secondLineTitle={t('active_alerts')}
              value={`${MOCK_SIMPLE_VALUES_DATA.activeAlerts} ${t('active_alerts')}`}
              maxWidth={220}
              alerts={true}
              hide={MOCK_SIMPLE_VALUES_DATA.activeAlerts === 0}
            />
          </div>
        </div>
      );
    }
  };

  return renderMobileProjects(t);
};

export default AdvanceMobile;
