import React from 'react';
import { IconColorsEnum } from '../../../../models/EarlyWarningsSection';
import { OperativeAlertsEnum } from '../../../../models/LinkedActions';
import { IconsRender } from '../../IconsRender/IconsRender';
import styles from '../Styles.module.scss';

const { rowValueResetMargin } = styles;

type AddColumnProps = {
  handleModal: Function;
  linkedActionsBase: any;
  text: string;
  icon: IconColorsEnum | null;
  alertName: OperativeAlertsEnum;
  percentageFormat?: boolean;
  safeguardFormat?: boolean;
  pmrFormat?: boolean;
};

export const AddColumn = ({
  handleModal,
  linkedActionsBase,
  text,
  icon,
  alertName,
  percentageFormat,
  safeguardFormat,
  pmrFormat,
}: AddColumnProps) => {
  return (
    <div className={rowValueResetMargin}>
      <IconsRender
        handleModal={() =>
          handleModal({
            ...linkedActionsBase,
            alertDetail: {
              alertName,
              alertDetailText: text,
              alertLight: icon,
            },
          })
        }
        iconValue={icon}
        textValue={text}
        percentageFormat={percentageFormat}
        safeguardFormat={safeguardFormat}
        pmrFormat={pmrFormat}
      />
    </div>
  );
};
