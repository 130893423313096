import React from 'react';
import Indicator from '../../../models/Indicator';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import { BarChartDetailContent } from '../BarChartDetailContent/BarChartDetailContent';
import './MobilePmrChart.scss';
import { useTranslation } from 'react-i18next';
import { ButtonsGroup } from '../../Util/Buttons/ButtonsGroup/ButtonsGroup';

import { PmrClassification } from '../../../models/PmrClassification';
import { WorkWithChart } from '../WorkWithChart/WorkWithChart';
import { TabPanel } from '../../Projects/ProjectsAll/ProjectAllUtil';
import { IndicatorKeyEnum } from '../../../models/IndicatorKeyEnum';

interface ReceivedProps {
  currentIndicator: Indicator;
  filterValues: any;
  setActiveTab: number;
  division: any;
  totalProjects: any;
}

type Props = ReceivedProps;
export const MobilePMRChart = (props: Props) => {
  const { currentIndicator, filterValues, setActiveTab, division, totalProjects } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const [pmrValueSelected, setPmrValueSelected] = React.useState(PmrClassification.Red);
  const [value, setValue] = React.useState(setActiveTab);

  React.useEffect(() => {
    setValue(setActiveTab);
  }, [setActiveTab]);

  const buttonSelected = value => {
    setPmrValueSelected(value);
  };

  return (
    <div className="mobile-pmr-chart-container">
      <div className="menu-pmr">
        <span className="title">{t('pmr_initials')}</span>
        <ButtonsGroup buttonSelected={buttonSelected} />
      </div>

      <div className="react-swipeable-view-container">
        <SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={value}>
          <TabPanel value={value} index={0} dir={theme.direction}>
            <BarChartDetailContent
              currentIndicator={currentIndicator}
              filterValues={filterValues}
              pmrValueSelected={pmrValueSelected}
            />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <WorkWithChart
              pmrClassification={pmrValueSelected}
              division={division}
              totalProjects={totalProjects}
              indicatorKey={currentIndicator.indicatorKey}
              indicatorId={currentIndicator.id}
            />
          </TabPanel>
        </SwipeableViews>
      </div>
      {currentIndicator.indicatorKey === IndicatorKeyEnum.PMR_PERFORMANCE_LAST_CLOSED_YEAR ? (
        <div className="pmr-footer-text">{`* ${t('last_validated_qualification')}`}</div>
      ) : (
        ''
      )}
    </div>
  );
};
