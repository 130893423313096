import React from 'react';
import './SectionPreloader.scss';
import BeatLoader from 'react-spinners/BeatLoader';
import { css } from '@emotion/core';
export const SectionPreloader = () => {
  const override = css`
    display: block;
    margin: auto;
  `;

  return (
    <div className="section-preloader-container">
      <BeatLoader css={override} size={7} color={'#417ffe'} loading={true} />
    </div>
  );
};
