export enum QCalificationInitials {
  A = 'A',
  B = 'B',
  FI = 'FI',
  B13 = 'B13',
  C = 'C',
  Not_Assigned = 'Not_Assigned',
}

export enum QCalificationOrder {
  A,
  B,
  FI,
  B13,
  C,
  Not_Assigned,
}
