import React from 'react';
import { useTranslation } from 'react-i18next';
import TextInput from '../TextInput/TextInput';
import CustomSelect from '../../../../Util/CustomSelect/CustomSelect';
import CustomDatePicker from '../../../../Util/CustomDatePicker/CustomDatePicker';
import {
  acquisitionTypes,
  revisionTypes,
  customInputStyle,
  customDatePickerStyle,
  stateTypes,
} from './NewItemFormUtil';
import { PlanningItemType } from './NewPlanningItem';
import CommentsIcon from '../../Accordances/CommentsIcon/CommentsIcon';

type Props = {
  itemType: PlanningItemType;
  formData: any;
  errors: any;
  descriptionChange: any;
  descriptionSubmit: any;
  acquisitionTypeChange?: any;
  startDateChange?: any;
  endDateChange?: any;
  planAmountChange: any;
  contractAmountChange: any;
  paidAmountChange: any;
  revisionTypeChange?: any;
  stateChange?: any;
  plannedDateChange?: any;
  realDateChange?: any;
  commentsClick?: any;
  disabledAmounts?: boolean;
};

const CommonInputs = (props: Props) => {
  const {
    itemType,
    formData,
    errors,
    descriptionChange,
    descriptionSubmit,
    acquisitionTypeChange,
    startDateChange,
    endDateChange,
    planAmountChange,
    contractAmountChange,
    paidAmountChange,
    revisionTypeChange,
    stateChange,
    plannedDateChange,
    realDateChange,
    commentsClick,
    disabledAmounts,
  } = props;
  const { t } = useTranslation();

  return (
    <>
      <div className="column-item">
        <TextInput
          placeHolder={t('description')}
          customStyleInput={{ ...customInputStyle, width: '90%', marginLeft: 10 }}
          value={formData.name}
          onValueChange={descriptionChange}
          submitValue={descriptionSubmit}
          error={errors.name}
        />
      </div>
      {itemType === PlanningItemType.Acquisition && (
        <div className="column-item">
          <CustomSelect
            options={acquisitionTypes}
            handleSelected={acquisitionTypeChange}
            type={'medium'}
            placeholder={'-'}
            selectedOption={formData.acquisitionType}
            style={{ width: '95%' }}
            heightCustomSelect={{ backgroundColor: '#e2e9ee' }}
            error={errors.acquisitionType}
            disabled={formData.id}
          />
        </div>
      )}
      <div className="column-item">
        <CustomDatePicker
          label={''}
          setDate={itemType === PlanningItemType.Acquisition ? startDateChange : plannedDateChange}
          minDate={null}
          style={customDatePickerStyle}
          small={true}
          placeholderComplete={true}
          date={itemType === PlanningItemType.Acquisition ? formData.startDate : formData.plannedDate}
          isClearable={true}
        />
      </div>
      <div className="column-item">
        <CustomDatePicker
          label={''}
          setDate={itemType === PlanningItemType.Acquisition ? endDateChange : realDateChange}
          minDate={null}
          style={customDatePickerStyle}
          small={true}
          placeholderComplete={true}
          date={itemType === PlanningItemType.Acquisition ? formData.endDate : formData.realDate}
          error={errors.realDate}
          maxDate={itemType === PlanningItemType.Activity ? new Date() : null}
          isClearable={true}
        />
      </div>
      <div className="column-item">
        <TextInput
          placeHolder={t('dolar_sign')}
          customStyleInput={customInputStyle}
          valueType={'number'}
          onValueChange={planAmountChange}
          value={formData.planAmount}
          disabled={disabledAmounts}
        />
      </div>
      <div className="column-item">
        <TextInput
          placeHolder={t('dolar_sign')}
          customStyleInput={customInputStyle}
          valueType={'number'}
          onValueChange={contractAmountChange}
          value={formData.contractAmount}
          disabled={disabledAmounts}
        />
      </div>
      <div className="column-item">
        <TextInput
          placeHolder={t('dolar_sign')}
          customStyleInput={customInputStyle}
          valueType={'number'}
          onValueChange={paidAmountChange}
          value={formData.paidAmount}
          disabled={disabledAmounts}
        />
      </div>
      {itemType === PlanningItemType.Acquisition && (
        <div className="column-item">
          <CustomSelect
            options={revisionTypes}
            handleSelected={revisionTypeChange}
            type={'medium'}
            placeholder={'-'}
            selectedOption={formData.revisionType}
            style={{ width: '95%' }}
            heightCustomSelect={{ backgroundColor: '#e2e9ee' }}
          />
        </div>
      )}
      {itemType === PlanningItemType.Activity && (
        <div className="column-item" style={{ justifyContent: 'center', display: 'flex', width: '9%' }}>
          {formData.id && (
            <CommentsIcon
              hasUnreadComments={false}
              alwaysShowIcon={true}
              onClick={commentsClick}
              comments={formData.comments ? formData.comments.filter(item => !item.isDeleted).length : 0}
            />
          )}
        </div>
      )}
      {itemType === PlanningItemType.Activity && (
        <div className="column-item">
          <CustomSelect
            options={stateTypes}
            handleSelected={stateChange}
            type={'medium'}
            placeholder={'-'}
            selectedOption={formData.state}
            style={{ width: '95%' }}
            heightCustomSelect={{ backgroundColor: '#e2e9ee' }}
          />
        </div>
      )}
    </>
  );
};

export default CommonInputs;
