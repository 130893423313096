import { useTranslation } from "react-i18next";
import { MemoryHelpContextType, MemoryHelpSection } from "./models";

// Initial state
export const initialStateMemoryHelp: MemoryHelpSection[] = [
    {
        type: 'generalData',
        institution_borrower: '',
        objectives: '',
        inputOpt: '',
        criteria: '',
        institutions_review: '',
        aditional_info_general_data: '',
        status: false,
    },
    {
        type: 'generalAspects',
        aditional_info_general_aspects: '',
        status: true,
    },
    {
        type: 'transversalIssues',
        projectDescription: [
            {
                title: null,
                description: null,
                agreement: null,
                id: Date.now(),
                status: false
            }
        ],
        status: false
    },
    {
        type: 'agreements',
        status: false,
    },
    {
        type: 'issues',
        aditional_info_other_issues: '',
        status: true
    },
    {
        type: 'developmentAndSustainability',
        aditional_info_dev_and_sustainability: '',
        status: false
    },
    {
        type: 'close',
        aditional_info_close: '',
        status: false
    },
    {
        type: 'annexes',
        status: false
    }
]

// Normalize state
export const templateNonNormalize = [
    {
        "revisionId": null,
        "type": "generalData",
        "institution_borrower": null,
        "objectives": null,
        "inputOpt": null,
        "criteria": null,
        "institutions_review": null,
        "aditional_info_general_data": null,
        "aditional_info_general_aspects": null,
        "aditional_info_other_issues": null,
        "aditional_info_dev_and_sustainability": null,
        "aditional_info_close": null,
        "status": null,
        "projectDescriptions": []
    },
    {
        "revisionId": null,
        "type": "generalAspects",
        "institution_borrower": null,
        "objectives": null,
        "inputOpt": null,
        "criteria": null,
        "institutions_review": null,
        "aditional_info_general_data": null,
        "aditional_info_general_aspects": null,
        "aditional_info_other_issues": null,
        "aditional_info_dev_and_sustainability": null,
        "aditional_info_close": null,
        "status": null,
        "projectDescriptions": []
    },
    {
        "revisionId": null,
        "type": "transversalIssues",
        "institution_borrower": null,
        "objectives": null,
        "inputOpt": null,
        "criteria": null,
        "institutions_review": null,
        "aditional_info_general_data": null,
        "aditional_info_general_aspects": null,
        "aditional_info_other_issues": null,
        "aditional_info_dev_and_sustainability": null,
        "aditional_info_close": null,
        "status": null,
        "projectDescriptions": [
            {
                "title": null,
                "description": null,
                "agreement": null,
                "idProjectDescription": null,
                "status": null,
                "helpMemoryId": null
            }
        ]
    },
    {
        "revisionId": null,
        "type": "agreements",
        "institution_borrower": null,
        "objectives": null,
        "inputOpt": null,
        "criteria": null,
        "institutions_review": null,
        "aditional_info_general_data": null,
        "aditional_info_general_aspects": null,
        "aditional_info_other_issues": null,
        "aditional_info_dev_and_sustainability": null,
        "aditional_info_close": null,
        "status": null,
        "projectDescriptions": []
    },
    {
        "revisionId": null,
        "type": "issues",
        "institution_borrower": null,
        "objectives": null,
        "inputOpt": null,
        "criteria": null,
        "institutions_review": null,
        "aditional_info_general_data": null,
        "aditional_info_general_aspects": null,
        "aditional_info_other_issues": null,
        "aditional_info_dev_and_sustainability": null,
        "aditional_info_close": null,
        "status": null,
        "projectDescriptions": []
    },
    {
        "revisionId": null,
        "type": "developmentAndSustainability",
        "institution_borrower": null,
        "objectives": null,
        "inputOpt": null,
        "criteria": null,
        "institutions_review": null,
        "aditional_info_general_data": null,
        "aditional_info_general_aspects": null,
        "aditional_info_other_issues": null,
        "aditional_info_dev_and_sustainability": null,
        "aditional_info_close": null,
        "status": null,
        "projectDescriptions": []
    },
    {
        "revisionId": null,
        "type": "close",
        "institution_borrower": null,
        "objectives": null,
        "inputOpt": null,
        "criteria": null,
        "institutions_review": null,
        "aditional_info_general_data": null,
        "aditional_info_general_aspects": null,
        "aditional_info_other_issues": null,
        "aditional_info_dev_and_sustainability": null,
        "aditional_info_close": null,
        "status": null,
        "projectDescriptions": []
    },
    {
        "revisionId": null,
        "type": "annexes",
        "institution_borrower": null,
        "objectives": null,
        "inputOpt": null,
        "criteria": null,
        "institutions_review": null,
        "aditional_info_general_data": null,
        "aditional_info_general_aspects": null,
        "aditional_info_other_issues": null,
        "aditional_info_dev_and_sustainability": null,
        "aditional_info_close": null,
        "status": null,
        "projectDescriptions": []
    }
];

// default values for agreements endpoint
function getDefaultAgreementData() {
    return {
        dataRevision: {
            startDate: "",
            endDate: "",
            revisionNumber: "",
            year: "",
            totalProjects: "",
            projectsNumber: 0,
            approveD_AMOUNT: "",
            pendinG_DISBURSEMENT_AMOUNT: "",
            actuaL_PROJECTION_AMNT_USEQ: "",
            disburseD_AMNT_USEQ: "",
            closingyear: "",
            alerT_OPER_CNT: "",
            probleM_OPER_CNT: "",
        },
        revisionHelpMemory: initialStateMemoryHelp,
        status: ""
    };
}

export const defaultValue: MemoryHelpContextType = {
    formState: initialStateMemoryHelp,
    errors: {},
    isFormComplete: false,
    showConfirmContent: false,
    agreementsData: getDefaultAgreementData(),
    fileRevision: null,
    isUserUploaded: false,
    currentReview: null,
    loading: null,
    loadingHelpFile: null,
    optionalFields: [],
    visitedTabs: [],
    userCanEditRevision: null,
    loadingHelpError: null,
    triggerGetFiles: () => { },
    updateErrors: (field: string, stage: string, isValid: boolean) => { },
    generateUniqueId: () => Date.now(),
    getStatusArray: () => initialStateMemoryHelp.map(section => section.status),
    onInputChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, currentTabId: string) => { },
    onTabClickDynamicInputs: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => { },
    onTabClick: (currentTabIndex: number) => { },
    setFormState: () => { },
    setErrors: () => { },
    setIsFormComplete: () => { },
    setShowConfirmContent: () => { },
    setIsUserUploaded: () => { },
    setCurrentReview: () => { },
    setTrigger: () => { },
    setAttachmentTrigger: () => { },
    setPreviousView: () => { },
    setOptionalFields: () => { },
    validateFormComplete: (prev: any) => { },
    handleFileChange: () => { },
    refetch: () => { }
};

/**
 * Validates a field based on its value, name, and optional fields.
 * 
 * @param fieldValue - The value of the field to be validated
 * @param fieldName - The name of the field to be validated
 * @param optionalFields - An array of field names that are considered optional
 * @returns {boolean} - Returns true if the field is valid, otherwise false
 */
export const validateField = (fieldValue: string, fieldName: string, optionalFields: string[]): boolean => {
    // If the field is optional, it is always valid
    if (optionalFields.includes(fieldName)) {
        return true;
    }
    return (fieldValue ?? '').length >= 3;
};

/**
 * Extracts the date part from a given date-time string.
 * @param dateTimeString - The date-time string to extract the date from.
 * @returns The date part of the string in 'YYYY-MM-DD' format, or an empty string if the input is invalid.
 */
export function extractDate(dateTimeString: string): string {
    if (!dateTimeString) return '';
    const datePart = dateTimeString.split(' ')[0];
    return datePart;
}

/**
 * Parses a date string in 'DD/MM/YYYY' or 'MM/DD/YYYY' format into a Date object.
 * @param dateString - The date string to be parsed.
 * @param format - The format of the date string, either 'DD/MM/YYYY' or 'MM/DD/YYYY'.
 * @returns The parsed Date object.
 * @throws Will throw an error if the dateString is invalid.
 */
export function parseDate(dateString: string, format: 'DD/MM/YYYY' | 'MM/DD/YYYY'): Date {
    if (!dateString) throw new Error("Invalid date string"); // Check if dateString is not null or undefined

    const [part1, part2, year] = dateString.split('/').map(Number);

    let day, month;
    if (format === 'DD/MM/YYYY') {
        day = part1;
        month = part2;
    } else { // 'MM/DD/YYYY'
        month = part1;
        day = part2;
    }

    return new Date(year, month - 1, day); // The month in JavaScript is 0-indexed
}

/**
 * Formats a Date object into 'YYYY-MM-DD' string format.
 * @param date - The Date object to be formatted.
 * @returns The formatted date string.
 */
export function formatDateToISO(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

/**
 * Validates if the current date is on or after the revision start date.
 * @param currentDate - The current date string in 'DD/MM/YYYY' format.
 * @param revisionStartDate - The revision start date string in 'MM/DD/YYYY' format.
 * @returns A boolean indicating if the current date is on or after the revision start date.
 */
export function dateValidation(currentDate: string, revisionStartDate: string): boolean {
    const timeFormat = 'DD/MM/YYYY'
    // Parse dates
    const actualDate = parseDate(currentDate, timeFormat);
    const beginDate = parseDate(revisionStartDate, timeFormat);

    // Convert dates to 'YYYY-MM-DD' format for uniform comparison
    const formattedActualDate = formatDateToISO(actualDate);
    const formattedBeginDate = formatDateToISO(beginDate);

    return formattedBeginDate <= formattedActualDate; // Compare the dates
}

export const revisionTranslationKeys: { [key: number]: string } = {
    1: 'memory_help.revision_number.first',
    2: 'memory_help.revision_number.second',
    3: 'memory_help.revision_number.third',
    4: 'memory_help.revision_number.fourth',
    5: 'memory_help.revision_number.fifth',
    6: 'memory_help.revision_number.sixth',
    7: 'memory_help.revision_number.seventh',
    8: 'memory_help.revision_number.eighth',
    9: 'memory_help.revision_number.ninth',
    10: 'memory_help.revision_number.tenth'
};