import React, { useState } from 'react';
import './WorkWithProgressChart.scss';
import { ProjectCounter } from '../ProjectCounter/ProjectCounter';
import { PortfolioSimpleIndicatorTable } from '../PortfolioSimpleIndicatorTable/PortfolioSimpleIndicatorTable';
import { getProjectsSimpleTableIndicator } from '../../../redux/actions/indicatorProjects';
import { useDispatch, useSelector } from 'react-redux';
import Indicator from '../../../models/Indicator';
import Project from '../../../models/Project';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';
import { IndicatorKeyEnum } from '../../../models/IndicatorKeyEnum';
import { sortTablesByType } from '../../../services/sortsUtil';
import { handleLoading } from '../../utils';

interface ReceivedProps {
  currentIndicator: Indicator;
  itsDisbursed: boolean;
}

type Props = ReceivedProps;
export const WorkWithProgressChart = (props: Props) => {
  const { currentIndicator, itsDisbursed } = props;
  const dataIndicator = currentIndicator.data;
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const indicatorProjects = useSelector<any, Project[]>(state => state.indicatorProjects);

  React.useEffect(() => {
    handleLoading(() => getFilteredSimpleIndicators(dispatch, currentIndicator.id), setLoading);
  }, [currentIndicator.id, dispatch]);

  return (
    <div
      className={
        indicatorProjects.length === 0
          ? 'work-with-progress-chart-container'
          : 'work-with-progress-chart-container adjust-height'
      }
    >
      <div className="title">{itsDisbursed ? t('disbursed') : t('pending')}</div>

      <div className="description">
        <NumberFormat
          value={itsDisbursed ? fixedMoneyValue(dataIndicator.disbursed) : fixedMoneyValue(dataIndicator.pending)}
          displayType={'text'}
          thousandSeparator={'.'}
          decimalSeparator={','}
          prefix={`${t('dolar_sign')} `}
        />
      </div>

      <div className="table-container">
        <PortfolioSimpleIndicatorTable
          projects={
            currentIndicator.indicatorKey === IndicatorKeyEnum.PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS
              ? sortTablesByType(indicatorProjects, true, 'code', 'string')
              : indicatorProjects
          }
          tableInfo={getDynamicTableFormat(itsDisbursed)}
          indicatorKey={currentIndicator.indicatorKey}
          loading={loading}
        />
        <ProjectCounter arrayToCount={indicatorProjects} countProjects={true} />
      </div>
    </div>
  );
};

export const getFilteredSimpleIndicators = (d, currentIndicatorId) => {
  return d(getProjectsSimpleTableIndicator(currentIndicatorId));
};

export const getDynamicTableFormat = isDisbursed => {
  switch (isDisbursed) {
    case true:
      return [
        {
          tableHeader: 'disbursed_to_date_01 dolar_sign_parentesis',
          rootProperty: 'field2',
          fieldType: 'money',
        },
      ];
    case false:
      return [
        {
          tableHeader: 'pending_disbursement dolar_sign_parentesis',
          rootProperty: 'field1',
          fieldType: 'money',
        },
      ];

    default:
      return [];
  }
};

const fixedMoneyValue = valueToFix => {
  return (valueToFix * 1000000).toFixed(0);
};
