import {
  field1TcpNationalAndIG,
  field1TcpRegional,
  field2TcpNationalAndIG,
  field11TcpRegional,
  field12TcpRegional,
  operation,
  project,
  projectName,
  field7TcpNational,
  field8TcpNationalAndRegional,
  field9TcpNationalAndRegional,
  field10TcpNationalAndTcpRegional,
  field3LonAndPTcpRegional,
  field3TcpNational,
} from '../../Util';
import { SummaryTcpInternalFilters } from '../SummaryTcp';

export const getDynamicTableFormatByInternalFilter = filterBy => {
  if (filterBy === SummaryTcpInternalFilters.NATIONAL) {
    return [
      project,
      operation,
      projectName,
      field1TcpNationalAndIG,
      field2TcpNationalAndIG,
      field3TcpNational,
      field7TcpNational,
      field8TcpNationalAndRegional,
      field9TcpNationalAndRegional,
      field10TcpNationalAndTcpRegional,
    ];
  } else {
    return [
      project,
      operation,
      projectName,
      field1TcpRegional,
      field11TcpRegional,
      field12TcpRegional,
      field8TcpNationalAndRegional,
      field9TcpNationalAndRegional,
      field3LonAndPTcpRegional,
      field10TcpNationalAndTcpRegional,
    ];
  }
};
