import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import styles from './DisbursementChart.module.scss';

interface DisbursedValue {
  title: string;
  percentage: number;
  total: number;
}

type Props = {
  total: number;
  disbursed: DisbursedValue;
  commited: DisbursedValue;
  available: DisbursedValue;
};

const options = {
  cutoutPercentage: 70,
  tooltips: {
    enabled: false,
  },
  legend: {
    display: false,
  },
  plugins: {
    datalabels: { display: false },
  },
};

const dimensions = { width: 90, height: 90 };
export const DisbursementChart = ({ total, disbursed, commited, available }: Props) => {
  const {
    container,
    horizontal,
    title,
    total: totalStyle,
    chartValue,
    list,
    disbursedCircle,
    values,
    chartPercentage,
    chartTotal,
    commitedCircle,
    availableCircle,
  } = styles;
  const { t } = useTranslation();

  const data = {
    labels: [disbursed.title, commited.title, available.title],
    datasets: [
      {
        data: [disbursed.percentage, commited.percentage, available.percentage],
        backgroundColor: ['#89AFF0', '#FFC76C', '#A6D277'],
        hoverOffset: 4,
        borderWidth: 0,
      },
    ],
  };

  const setNumberFormat = value => (
    <NumberFormat
      value={value}
      displayType={'text'}
      thousandSeparator={'.'}
      decimalSeparator={','}
      decimalScale={2}
      suffix={' USD'}
    />
  );

  return (
    <div className={container}>
      <div className={horizontal}>
        <div className={title}>{t('disbursement')}</div>
        <div className={totalStyle}>
          {`${t('total')}`} {setNumberFormat(total)}
        </div>
      </div>
      <div className={horizontal}>
        <div>
          <Doughnut data={data} options={options} {...dimensions} />
        </div>
        <div className={list}>
          <div className={chartValue}>
            <div className={disbursedCircle} />
            <div className={values}>
              <div className={chartPercentage}>
                {`${t(disbursed.title)} ${disbursed.percentage} ${t('percentage_sign')}`}
              </div>
              <div className={chartTotal}> {setNumberFormat(disbursed.total)}</div>
            </div>
          </div>
          <div className={chartValue}>
            <div className={commitedCircle} />
            <div className={values}>
              <div className={chartPercentage}>
                {`${t(commited.title)} ${commited.percentage} ${t('percentage_sign')}`}
              </div>
              <div className={chartTotal}>{setNumberFormat(commited.total)}</div>
            </div>
          </div>
          <div className={chartValue}>
            <div className={availableCircle} />
            <div className={values}>
              <div className={chartPercentage}>
                {`${t(available.title)} ${available.percentage} ${t('percentage_sign')}`}
              </div>
              <div className={chartTotal}>{setNumberFormat(available.total)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
