import { axiosDefaultConfig } from './axiosConfig';
import * as config from './config.json';
import { setFilterReportParams } from './filterReportUtil';
import { addMenuTabFilter, checkReportFilters } from './filterPortfolioAndProjectUtil';
import axios from 'axios';
import { GetSectionReportEnum } from '../models/ReportEnums';
import { store } from '../redux/store';
import { IndicatorKeyEnum } from '../models/IndicatorKeyEnum';
import { msalInstance } from '../index';
import { loginRequest } from '../authConfig';
import FilterIndicators from '../models/FilterIndicators';
import { MenuTabSectionEnum } from '../components/MenuTabs/userTabsInterface';
import { parseFilterToApiCall } from '../services/util';
import FilterEarlyWarnings from '../models/FilterEarlyWarnings';
import { EarlyWarningTypeLabel } from '../models/EarlyWarnings';
import { IAccordanceReportParams } from '../models/AccordanceReportParams';

export const getSectionReports = id => {
  const url = `${config.REPORT_LIST}/${id}`;
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosDefaultConfig.get(url);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getReportFile = (reportId, reportFormat, completeFileName) => {
  let urlEnd = config.REPORT_GET;
  urlEnd = urlEnd.replace('{reportId}', reportId);
  urlEnd = urlEnd.replace('{reportFormat}', reportFormat);

  return new Promise(async (resolve, reject) => {
    try {
      const account = msalInstance.getActiveAccount();
      if (!account) {
        throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
      }
      const resp = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account,
      });
      axios({
        url: `${process.env.REACT_APP_BASE_URL}/${urlEnd}`,
        method: 'GET',
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
          'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_APIM_KEY,
          Authorization: `Bearer ${resp.accessToken}`,
        },
      }).then(response => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', completeFileName);
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const deleteReport = id => {
  const url = `${config.REPORT_DELETE}/${id}`;
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosDefaultConfig.delete(url);
      resolve(response.status);
    } catch (error) {
      reject(error);
    }
  });
};

export const createPortfolioReport = filters => {
  const url = config.REPORT_REQUEST;
  const params = {
    ...setPortfolioParams(filters),
    reportType: GetSectionReportEnum.Portfolio_Section,
  };

  return basicPost(url, params);
};

const submitFormDataWithImages = (params: any, url: string, images?: any[]) => {
  const data = new FormData();
  const paramsToBase64 = btoa(JSON.stringify(params));
  data.append('data', paramsToBase64);
  if (images && images.length) {
    for (const img of images) {
      //Excepcion por imagen upcoming de reportes, que el BE no soporta nombres 'custom'
      const imgName =
        img.name === 'PCR_NEXT_PRESENTATION_UPCOMING'
          ? 'PCR_NEXT_PRESENTATION_DETAIL'
          : img.name === 'PCR_NEXT_PRESENTATION_UPCOMING_WORD'
          ? 'PCR_NEXT_PRESENTATION_TCP_DETAIL'
          : img.name;
      data.append(imgName, img.blob, `${imgName}.png`);
    }
  }

  return new Promise<any>(async (resolve, reject) => {
    try {
      const response = await axiosDefaultConfig.post(url, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (response.data) {
        resolve(response.data);
      } else {
        reject('no data');
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const createProjectReport = (projectId, reportSections, images?) => {
  const url = config.REPORT_PROJECT_REQUEST;
  const params = setProjectParams(projectId, reportSections);
  return submitFormDataWithImages(params, url, images);
};

const basicPost = (url, params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosDefaultConfig.post(url, params);
      resolve(response.status);
    } catch (error) {
      reject(error);
    }
  });
};

export const sendReportByMail = (recipients, message, reportId, fileName) => {
  const url = config.REPORT_SEND_BY_EMAIL;
  const params = {
    recipients,
    message,
    reportId,
    fileName,
  };

  return basicPost(url, params);
};

export const getProductTypesFilterAndIgConfigForReports = projectTypeFilters => {
  const parsedFilters: any = parseFilterToApiCall(projectTypeFilters);
  const { projectProductAmounts, projectProductTypes } = parsedFilters;
  return { projectProductAmounts, projectProductTypes };
};

const setPortfolioParams = (
  reportFilter,
  menuTabSection: MenuTabSectionEnum = MenuTabSectionEnum.PORTFOLIO_MENU_TAB,
) => {
  const regionsFilter = Array.from(reportFilter.common.regionId, (x: any) => x.id);
  const { portfolioSelectedTab } = store.getState().menuTabs;
  const [haveCountriesFilter, haveDivisionFilter, havePendingDisbursement, haveLastDisbursement, haveAgeOfProject] =
    checkReportFilters(reportFilter);

  const haveTaxonomy = reportFilter.common.taxonomyFilter && reportFilter.common.taxonomyFilter.id;
  const haveExecution = reportFilter.common.executionFilter && reportFilter.common.executionFilter.id;
  const havePmrClassification = reportFilter.common && reportFilter.common.pmrId.filter(pmr => pmr.selected === true);
  const { projectProductAmounts, projectProductTypes } = getProductTypesFilterAndIgConfigForReports(
    reportFilter.common.projectTypeFilters,
  );
  const coReponsibleDivisions = Array.from(reportFilter.common.coReponsibleDivisions, (x: any) => x.id);
  const participantDivisions = Array.from(reportFilter.common.participantDivisions, (x: any) => x.id);
  const teamLeaders = Array.from(reportFilter.common.teamLeaders, (x: any) => x.id);
  const interestIssues = Array.from(reportFilter.common.interestIssues, (x: any) => x.id);
  const haveExcludeRegionales = reportFilter.common.excludeRegionals || portfolioSelectedTab.excludeRegionals;

  let returnedParams = {
    inYears: reportFilter.common.ageProjectMonths ? false : true,
    sortParameter: 1,
    regions: regionsFilter.length > 0 ? regionsFilter : [],
    Filters: [] as any,
    assignedToMe: false,
    projectProductAmounts,
    projectProductTypes,
    coReponsibleDivisions,
    participantDivisions,
    teamLeaders,
    interestIssues,
  };

  const returnedParamsFilters = {
    haveCountriesFilter,
    haveDivisionFilter,
    havePendingDisbursement,
    haveLastDisbursement,
    haveAgeOfProject,
    havePmrClassification,
    haveTaxonomy,
    haveExecution,
    haveExcludeRegionales,
  };

  returnedParams = setFilterReportParams(returnedParams, returnedParamsFilters, reportFilter);
  returnedParams = addMenuTabFilter(returnedParams, menuTabSection);
  return returnedParams;
};

const setProjectParams = (projectId, reportSections) => {
  const selectedSections: any[] = reportSections.filter(section => section.value === true);
  const selectedSectionsArray = Array.from(selectedSections, x => x.key);
  return {
    reportType: 1,
    projectId,
    sections: selectedSectionsArray,
    sortParameter: 1,
    regions: [],
    Filters: [] as any,
    assignedToMe: false,
  };
};

const getReportDownload = (params: any, fileName: string) => {
  const url = config.REPORT_DOWNLOAD;

  return new Promise(async (resolve, reject) => {
    try {
      const account = msalInstance.getActiveAccount();
      if (!account) {
        throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
      }
      const resp = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account,
      });

      axios({
        url: `${process.env.REACT_APP_BASE_URL}/${url}`,
        method: 'POST',
        data: params,
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
          'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_APIM_KEY,
          Authorization: `Bearer ${resp.accessToken}`,
        },
      })
        .then(response => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', fileName);
          document.body.appendChild(fileLink);
          fileLink.click();
          resolve(true);
        })
        .catch(err => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const createReviewAgendaReport = revisionId => {
  const actualDate = new Date();
  const params = {
    reportType: 3,
    revisionId,
    reportFormat: 2,
    sortParameter: 1,
    regions: [],
    Filters: [] as any,
    assignedToMe: false,
    timeZone: -actualDate.getTimezoneOffset() / 60,
  };

  return getReportDownload(params, 'Agenda.xlsx');
};

export const createSuggestedPlan = projectId => {
  const params = {
    reportType: 6,
    reportFormat: 2,
    projectId,
  };
  return getReportDownload(params, 'Plan.xlsx');
};

export const createReviewReport = params => {
  const url = config.REPORT_REQUEST;
  return basicPost(url, params);
};

export const createAccordancesReport = (accordanceParams: IAccordanceReportParams) => {
  const filters: any[] = [];
  accordanceParams.division.length &&
    filters.push({ filterType: 6, field: 'DivisionId', value: accordanceParams.division });
  accordanceParams.country.length &&
    filters.push({ filterType: 6, field: 'ResponsibleCountryId', value: accordanceParams.country });
  const params = {
    reportType: accordanceParams.reportType,
    accordanceTypes: accordanceParams.accordanceTypes,
    accordanceState: accordanceParams.accordanceState,
    accordanceYears: accordanceParams.accordanceYears,
    regions: accordanceParams.region,
    Filters: filters,
  };
  const url = config.REPORT_REQUEST;
  return basicPost(url, params);
};

const deleteDuplicateRegions = (array: number[]) => {
  return array.filter((item, index) => array.indexOf(item) === index);
};

export const createEarlyWarningExcelReport = (filters: FilterEarlyWarnings, selectedTab: string) => {
  const operativeAlertsFilter: any = [];
  filters.buttonsModule.forEach(
    btn => btn.selected && operativeAlertsFilter.push(EarlyWarningTypeLabel[btn.buttonEWType]),
  );
  const url = config.REPORT_REQUEST;
  const params = {
    ...setPortfolioParams(filters, MenuTabSectionEnum.EARLY_WARNINGS_TAB),
    reportType: GetSectionReportEnum.Alert_Section,
    operativeAlertsFilter,
    selectedTab,
    projectProductAmounts: [],
    projectProductTypes: [],
  };
  const paramsWithMenuTabsFilters = addMenuTabFilter(params, MenuTabSectionEnum.EARLY_WARNINGS_TAB);
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosDefaultConfig.post(url, {
        ...paramsWithMenuTabsFilters,
        regions: deleteDuplicateRegions(paramsWithMenuTabsFilters.regions),
      });

      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

const knowledgeIndicatorParam = (indicator: any, allIndicators: any[]) => {
  const options = { 0: 'AllProducts', 30: 'Less30Days', 180: 'Less6Months', 365: 'Less12Months' };
  const foundItem = allIndicators.find(
    item => item.id === indicator.id && item.indicatorKey === IndicatorKeyEnum.PROJECTS_KNOWLEDGE_PRODUCTS,
  );
  if (foundItem) {
    const storageValue = localStorage.getItem('knowledgeIndicatorRange');
    const value = storageValue !== undefined && storageValue !== null ? storageValue : '30';
    return options[value];
  } else {
    return undefined;
  }
};

export const createCustomReport = (filters: FilterIndicators, indicators: any[], images?: any[]) => {
  const url = config.PORTFOLIO_REQUEST;
  const allIndicators = store.getState().indicators;

  const params = {
    ...setPortfolioParams(filters),
    reportType: GetSectionReportEnum.Customizable_Section,
    indicatorsId: indicators.map(indicator => ({
      indicatorId: indicator.id,
      isWithDetail: indicator.includeDetails,
      parameters: knowledgeIndicatorParam(indicator, allIndicators),
    })),
  };

  return submitFormDataWithImages(params, url, images);
};
