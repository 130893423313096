import React from 'react';
import { PmrClassification } from '../../../models/PmrClassification';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';

interface ReceivedProps {
  pmrClassi: PmrClassification;
  pmrValue: any;
}

type Props = ReceivedProps;

export const PmrPrintValue = (props: Props) => {
  const { pmrClassi, pmrValue } = props;

  const { t } = useTranslation();
  return pmrClassi === PmrClassification.Na ? (
    <span>{t('not_assigned_initials')}</span>
  ) : (
    <NumberFormat
      value={pmrValue}
      displayType={'text'}
      thousandSeparator={true}
      decimalScale={1}
      fixedDecimalScale={true}
    />
  );
};
