import React from 'react';
import { useTranslation } from 'react-i18next';
import './CardIndicator.scss';
import Indicator from '../../../models/Indicator';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCurrentIndicator } from '../../../redux/actions/currentIndicator';
import { translateMultipleText } from '../../../services/stringUtil';
import { urlIsImage } from '../../../services/booleanUtil';
import { IndicatorKeyEnum } from '../../../models/IndicatorKeyEnum';
import { CardTypesEnum } from '../../../models/CardTypesEnum';
import { trackActionClicked } from '../../../api/trackActionClickedApi';
import { TrackedActions } from '../../../models/TrackedActionsEnum';
import { ReportImageDisplay } from '../../Util/Modals/CustomizableReports/CustomizableReports';

export const isReportDetailImg = forReport => {
  return forReport && forReport === ReportImageDisplay.Detail;
};

export const backgroundColorCustomStyle = forReport => {
  if (!forReport) {
    return {};
  } else {
    const bgColor = forReport === ReportImageDisplay.Detail ? '#1F3864' : 'white';
    return { backgroundColor: bgColor, borderRadius: '0px' };
  }
};

const fallbackId = (reportWordKey = '', card: Indicator, forReport?: ReportImageDisplay) =>
  forReport ? `${reportWordKey || card.indicatorKey}` : '';

type Props = {
  card: Indicator;
  forReport?: ReportImageDisplay;
  reportWordKey?: string;
};

const CardRecommends = (props: Props) => {
  const { card, forReport, reportWordKey } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const onClickCard = () => {
    card.data !== null && onClickMore();
  };

  const onClickMore = () => {
    dispatch(setCurrentIndicator(card));
    history.push(`/portfoliodetail`);
    trackActionClicked(TrackedActions.indicatorDetailsClicked, `[${card.id}]`);
  };


  return (
    <div className='card-indicator-container'>

      <div
        className="card-section"
        onClick={onClickCard}
        id={fallbackId(reportWordKey, card, forReport)}
        style={backgroundColorCustomStyle(forReport)}
      >
        <div className="img">
          {card.image && urlIsImage(card.image) ? (
            <img className="img-container" alt="" src={card.image} />
          ) : (
            null
          )}
        </div>

        {card.title && (
          <div
            className='text-title'
            onClick={onClickCard}
            style={isReportDetailImg(forReport) || reportWordKey ? { visibility: 'hidden' } : {}}
          >
            <h3 className="title">
              {translateMultipleText(card.title, t)}
            </h3>

            {card.subtitle && (
              <span className={`subtitle`}>
                {translateMultipleText(card.subtitle, t)}
              </span>
            )}
          </div>
        )}

        <div onClick={onClickCard}>
          {card.data !== null && CardHaveData(onClickMore)}
        </div>
      </div>
    </div>
  );
};

export default CardRecommends;


export const CardHaveData = onClickMore => {
  const { t } = useTranslation();
  return (
    <button className="btn btn-primary-outlined" onClick={onClickMore}>
      {t('last_show_indicator')}
    </button>
  );
};

export const updateDatasetColorBar = card => {
  const dat = [...card.data.datasets];
  const maxDivisionsOrCountriesValues = 8;
  return dat.map(set => {
    if (
      (set.data.length >= maxDivisionsOrCountriesValues && IndicatorKeyEnum.PMR_PERFORMANCE_LAST_CLOSED_YEAR) ||
      (set.data.length >= maxDivisionsOrCountriesValues &&
        IndicatorKeyEnum.PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS_BY_DIVISION) ||
      (set.data.length >= maxDivisionsOrCountriesValues &&
        IndicatorKeyEnum.PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS_BY_COUNTRY)
    ) {
      const newBackgroundColor = [...set.backgroundColor];
      newBackgroundColor.splice(5, 1, colorsWithOpacity(set.label).penultimateBackgroundColor);
      newBackgroundColor.splice(6, 1, colorsWithOpacity(set.label).lastBackgroundColor);
      set = { ...set, backgroundColor: newBackgroundColor };
    }

    return set;
  });
};

export const colorsWithOpacity = label => {
  let penultimateBackgroundColor = '';
  let lastBackgroundColor = '';

  switch (label) {
    case 'problemChartData':
      penultimateBackgroundColor = 'rgba(240, 109, 109, 0.5)';
      lastBackgroundColor = 'rgba(240, 109, 109, 0.2)';
      break;
    case 'alertChartdata':
      penultimateBackgroundColor = 'rgba(247, 221, 0, 0.5)';
      lastBackgroundColor = 'rgba(247, 221, 0, 0.2)';
      break;
    case 'satisChartData':
      penultimateBackgroundColor = 'rgba(145, 226, 161, 0.5)';
      lastBackgroundColor = 'rgba(145, 226, 161, 0.2)';
      break;
    case 'naChartData':
      penultimateBackgroundColor = 'rgba(215, 219, 228, 0.5)';
      lastBackgroundColor = 'rgba(215, 219, 228, 0.2)';
      break;
    case 'lonChartData':
      penultimateBackgroundColor = 'rgba(177, 148, 190, 0.5)';
      lastBackgroundColor = 'rgba(177, 148, 190, 0.2)';
      break;
    case 'tcpChartData':
      penultimateBackgroundColor = 'rgba(148, 182, 182, 0.5)';
      lastBackgroundColor = 'rgba(148, 182, 182, 0.2)';
      break;
    case 'igrChartData':
      penultimateBackgroundColor = 'rgba(249, 194, 104, 0.5)';
      lastBackgroundColor = 'rgba(249, 194, 104, 0.2)';
      break;

    default:
      penultimateBackgroundColor = '';
      lastBackgroundColor = '';
      break;
  }

  return { penultimateBackgroundColor, lastBackgroundColor };
};

const fallbackDataSets = (card, forReport) =>
  card.data ? (forReport ? [...card.data.datasets] : updateDatasetColorBar(card)) : [];
const fallbackLabels = (card, minDivisionsValues, maxDivisionsValues) =>
  card.data ? card.data.labels.slice(minDivisionsValues, maxDivisionsValues) : [];
const fallbackSubLabels = (card, minDivisionsValues, maxDivisionsValues) =>
  card.data ? card.data.subLabels.slice(minDivisionsValues, maxDivisionsValues) : [];

export const barChartData = (card, t, forReport) => {
  const minDivisionsValues = 0;
  const maxDivisionsValues = 7;

  if (!card) {
    return [];
  }

  return card.translateChartLabels
    ? {
      ...card.data,
      labels: card.data.labels.map(label => {
        return t(label);
      }),
    }
    : card.type === CardTypesEnum.HORIZONTAL
      ? {
        ...card.data,
        datasets: fallbackDataSets(card, forReport),
        labels: fallbackLabels(card, minDivisionsValues, maxDivisionsValues),
        subLabels: fallbackSubLabels(card, minDivisionsValues, maxDivisionsValues),
      }
      : card.data;
};
