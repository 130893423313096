import React from 'react';
import css from '@emotion/css';
import BeatLoader from 'react-spinners/BeatLoader';

type Props = {
  styles?: any;
};

export default function ContentManagerLoader({ styles }: Props) {
  const override = css(styles || `padding-left: 50%;`);
  return <BeatLoader css={override} speedMultiplier={0.5} color={'#00bac7'} />;
}
