import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getEarlyWarningsTrends, getProjectsWithEarlyWarnings } from '../../../api/earlyWarningApi';
import { TempQuarterLabels, TrendChartInformation, Trends } from '../../../models/EarlyWarnings';
import { EarlyWarningButtonType, IEarlyWarningsProjects, LoadingState } from '../../../models/EarlyWarningsSection';
import {
  setEarlyWarningsProjects,
  updateLoadingStateEarlyWarningsProjects,
} from '../../../redux/actions/earlyWarningsProjects';
import { AppMenuTabs } from '../../MenuTabs/userTabsInterface';

import { TrendChart } from '../TrendChart/TrendChart';
import {
  handleCancelledTodaySnapshot,
  handleElegibleUnDisbursementTodaySnapshot,
  handleEsgSafeguardTodaySnapshot,
  handleExpiredTodaySnapshot,
  handleLastPmrTodaySnapshot,
  handlePendingSignatureTodaySnapshot,
} from './TodaySnapshot';
import styles from './TrendsSection.module.scss';
import { handleChartDataObject } from './Util';

const { trendsContainer } = styles;

type TrendsProp = {
  waitingResponse: Function;
};
export const TrendsSection = ({ waitingResponse }: TrendsProp) => {
  const MAX_QTYS_OF_QUARTERS = 4;
  const [chartInformation, setChartInformation] = useState<TrendChartInformation[]>([]);
  const { projects, loadingState } = useSelector<any, IEarlyWarningsProjects>(state => state.earlyWarningsProjects);
  const filterEarlyWarnings = useSelector<any, any>(state => state.filterEarlyWarnings);
  const menuTabs = useSelector<any, AppMenuTabs>(state => state.menuTabs);
  const { common } = filterEarlyWarnings;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const getAndHandleData = async () => {
    waitingResponse(true);
    const todaySnapshot = loadingState === LoadingState.firstIn ? projects : await getProjects();

    const pendigSignatureTodaySnapshot: TrendChartInformation = handlePendingSignatureTodaySnapshot(todaySnapshot, {
      label: t('today'),
    });
    const eligibleUnDisbursementTodaySnapshot: TrendChartInformation = handleElegibleUnDisbursementTodaySnapshot(
      todaySnapshot,
      {
        label: t('today'),
      },
    );

    const lastPmrTodaySnapshot: TrendChartInformation = handleLastPmrTodaySnapshot(todaySnapshot, {
      label: t('today'),
    });

    const esgSafeguardTodaySnapshot: TrendChartInformation = handleEsgSafeguardTodaySnapshot(todaySnapshot, {
      label: t('today'),
    });

    const cancelledTodaySnapshot: TrendChartInformation = handleCancelledTodaySnapshot(todaySnapshot, {
      label: t('today'),
    });
    const expiredTodaySnapshot: TrendChartInformation = handleExpiredTodaySnapshot(todaySnapshot, {
      label: t('today'),
    });

    const lastTrends: Trends[] = await getPastTrends();

    const quarterLabels: TempQuarterLabels[] = lastTrends.slice(0, MAX_QTYS_OF_QUARTERS).map((trend: Trends) => {
      return {
        qLabels: `${t('quarter_abbreviation')}${trend.quarter} ${trend.year}`,
        cancelled: [trend.cancelled.countColorGreen, trend.cancelled.countColorYellow, trend.cancelled.countColorRed],
        eligibleUnDisbursement: [
          trend.eligibleUnDisbursement.countColorGreen,
          trend.eligibleUnDisbursement.countColorYellow,
          trend.eligibleUnDisbursement.countColorRed,
        ],
        esgSafeguard: [
          trend.esgSafeguard.countColorGreen,
          trend.esgSafeguard.countColorLightGreen,
          trend.esgSafeguard.countColorYellow,
          trend.esgSafeguard.countColorRed,
        ],
        expired: [trend.expired.countColorGreen, trend.expired.countColorYellow, trend.expired.countColorRed],
        pmr: [trend.pmr.countColorGreen, trend.pmr.countColorYellow, trend.pmr.countColorRed],
        pendingSignature: [
          trend.pendingSignature.countColorGreen,
          trend.pendingSignature.countColorYellow,
          trend.pendingSignature.countColorRed,
        ],
      };
    });

    const pendigSignatureChartComplete: TrendChartInformation = handleChartDataObject(
      pendigSignatureTodaySnapshot,
      EarlyWarningButtonType.PROJECTS_PENDING_SIGNATURE,
      quarterLabels,
    );

    const elegibleUnDisbursementChartComplete: TrendChartInformation = handleChartDataObject(
      eligibleUnDisbursementTodaySnapshot,
      EarlyWarningButtonType.ELIGIBLE_PROJECTS_PENDING_FIRST_DISBURSEMENT,
      quarterLabels,
    );

    const lastThreePmrChartComplete: TrendChartInformation = handleChartDataObject(
      lastPmrTodaySnapshot,
      EarlyWarningButtonType.PROJECTS_WITH_RECENT_ALERT_OR_PROBLEM_CLASSIFICATIONS,
      quarterLabels,
    );
    const esgSafeguardChartComplete: TrendChartInformation = handleChartDataObject(
      esgSafeguardTodaySnapshot,
      EarlyWarningButtonType.PROJECTS_WITH_UNSATISFACTORY_PERFORMANCE_IN_SAFEGUARDS,
      quarterLabels,
    );
    const cancelledChartComplete: TrendChartInformation = handleChartDataObject(
      cancelledTodaySnapshot,
      EarlyWarningButtonType.PROJECTS_WITH_PARTIAL_CANCELLATIONS,
      quarterLabels,
    );
    const expiredChartComplete: TrendChartInformation = handleChartDataObject(
      expiredTodaySnapshot,
      EarlyWarningButtonType.PROJECTS_WITH_EXPIRED_DISBURSEMENT_DEADLINE,
      quarterLabels,
    );

    setChartInformation([
      pendigSignatureChartComplete,
      elegibleUnDisbursementChartComplete,
      lastThreePmrChartComplete,
      esgSafeguardChartComplete,
      cancelledChartComplete,
      expiredChartComplete,
    ]);
    waitingResponse(false);
  };

  const getPastTrends = async () => {
    const projectsResponse: Trends[] = await getEarlyWarningsTrends(filterEarlyWarnings, menuTabs);
    dispatch(updateLoadingStateEarlyWarningsProjects(LoadingState.firstInOnTrends));
    return projectsResponse;
  };

  const getProjects = async () => {
    const projectsResponse = await getProjectsWithEarlyWarnings(filterEarlyWarnings, menuTabs);
    dispatch(setEarlyWarningsProjects({ projects: projectsResponse, loadingState: LoadingState.firstInOnTrends }));
    return projectsResponse;
  };

  useEffect(() => {
    getAndHandleData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [common, menuTabs]);

  return (
    <div className={trendsContainer}>
      {chartInformation.map(chart => {
        return <TrendChart buildTrendChart={chart} key={chart.key} />;
      })}
    </div>
  );
};
