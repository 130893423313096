export interface BEMenuTabsResponse {
  userTabs: BEMenuTabs[];
}

export interface BEMenuTabs {
  tabId: number;
  title: string;
  filters: Filter[] | null;
  regions: number[] | null;
  assignedToMe: boolean;
  default: boolean;
  coReponsibleDivisions: number[] | null;
  participantDivisions: number[] | null;
  teamLeaders: number[] | null;
  interestIssues: number[] | null;
  inFollow: boolean;
}

export interface AppMenuTabs {
  userTabs: MenuTabs[];
  portfolioSelectedTab: MenuTabs;
  projectSelectedTab: MenuTabs;
  earlyWarningsSelectedTab: MenuTabs;
  managerialReviewSelectedTab: MenuTabs;
  creatingOrEditing: boolean;
  firstLoad: boolean;
}

export interface MenuTabs {
  portfolioSelectedTab: any;
  internalId: string;
  tabId: number;
  title: string;
  filters: Filter[];
  regions: number[];
  assignedToMe: boolean;
  default: boolean;
  coReponsibleDivisions: number[];
  participantDivisions: number[];
  interestIssues: number[];
  teamLeaders: any[];
  inFollow: boolean;
}

export interface Filter {
  filterType: number;
  field: string;
  value: number[];
}

export enum MenuTabSectionEnum {
  PORTFOLIO_MENU_TAB,
  PROJECT_MENU_TAB,
  EARLY_WARNINGS_TAB,
  MANAGERIAL_REVIEW_TAB,
}

export const emptyMenuTabsObject = {
  internalId: '',
  tabId: -1,
  title: '',
  filters: null,
  regions: null,
  assignedToMe: false,
  default: false,
};

export interface FilterSingleValue {
  field: string;
  filterType: number;
  value: number[];
}
