import React, { useContext, useEffect, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import styles from './CustomTextArea.module.scss';
import { TranslationsContext } from '../context/TranslationsContext';

type Props = {
  indicator: any;
  translation: any;
  sectionRow: string;
  hasChanges: any;
};

const CustomTextArea = ({ indicator, translation, sectionRow, hasChanges }: Props) => {
  const [localValue, setLocalValue] = useState<string>('');
  const { dispatch } = useContext(TranslationsContext);

  const onTextChange = value => {
    setLocalValue(value);
    hasChanges();
  };

  const updateList = () => {
    dispatch({
      type: 'ADD_INDICATOR',
      payload: {
        id: indicator.id,
        code: indicator.code,
        translation: {
          ...translation,
          [sectionRow]: localValue,
          sectionRow,
        },
      },
    });
  };

  useEffect(() => {
    setLocalValue(translation[sectionRow] || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TextareaAutosize
      value={localValue}
      onChange={e => onTextChange(e.target.value)}
      className={styles.customTextArea}
      onBlur={updateList}
    />
  );
};

export default CustomTextArea;
