import React from 'react';
import { useTranslation } from 'react-i18next';
import ReportButton from '../../Util/Buttons/ReportButton/ReportButton';
import { ReportType } from '../../../models/ReportType';
import UndoButton from '../../Util/Buttons/UndoButton/UndoButton';
import { isMobile } from '../../../services/booleanUtil';

const reportButtonReviewAgendaStyle = {
  marginRight: '153px',
  backgroundColor: '#417FFE',
  color: '#fff',
};
const reportButtonReviewStyle = {
  marginRight: '15px',
  backgroundColor: '#417FFE',
  color: '#fff',
};

type Props = {
  isAgenda: boolean;
  editable?: boolean;
  handleOnclck: any;
  revision: any;
};
const ReportEditListButtons = ({ isAgenda, editable, handleOnclck, revision }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      {isAgenda ? (
        <ReportButton
          customStyle={reportButtonReviewAgendaStyle}
          reportType={ReportType.Review_Agenda}
          disabled={false}
          revision={revision}
        />
      ) : (
        <>
          <ReportButton customStyle={reportButtonReviewStyle} reportType={ReportType.Review} />
          {editable ? (
            <UndoButton
              customStyle={isMobile() ? { display: 'none' } : {}}
              customText={t('edit_list')}
              handleClick={handleOnclck}
            />
          ) : null}
        </>
      )}
    </>
  );
};

export default ReportEditListButtons;
