export enum ProjectDetailsTab {
  BASE_INFORMATION,
  PLANNING,
  SUPERVISION,
  AGREEMENTS,
  FINANCIAL,
  RISK,
  PRODUCTS,
  MANAGEMENT,
  EVOLUTION,
  ACQUISITIONS,
}
