import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getRegionsAndCountries } from '../../../api/filterApi';
import { addRegions } from '../../../redux/actions/regions';
import AnalyticsCollapseContainer from './AnalyticsCollapseContainer/AnalyticsCollapseContainer';
import AnalyticsHeader from './AnalyticsHeader/AnalyticsHeader';

const Analytics = () => {
  const [analyticsData, setAnalyticsData] = useState('');
  const dispatch = useDispatch();
  useEffect(() => {
    getRegionsAndCountries(true).then(regionsData => {
      dispatch(addRegions(regionsData));
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <AnalyticsHeader analyticsData={analyticsData} />
      <AnalyticsCollapseContainer setAnalyticsData={setAnalyticsData} />
    </div>
  );
};

export default Analytics;
