import { axiosDefaultConfig } from './axiosConfig';
import * as config from './config.json';
import User from '../models/User';
import { buildGet } from './genericMethods';

export const getUserData = () => {
  const url = config.USER_PROFILE;
  return new Promise<User>(async (resolve, reject) => {
    try {
      const response = await axiosDefaultConfig.get(url);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const updateUserData = (data: any) => {
  const url = config.USER;
  return new Promise<User>(async (resolve, reject) => {
    try {
      await axiosDefaultConfig.put(url, data);
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const searchUser = (term: string, projectId?: number, countryFiltered = false, filteredRoles: number[] = []) => {
  const projectIdParam = projectId ? `&projectId=${projectId.toString()}` : '';
  const countryFilteredParam = countryFiltered ? '&countryfiltered=true' : '';
  const filteredRolesParam = filteredRoles.length > 0 ? `&roles=${filteredRoles}` : '';
  const url = `${config.USER_SEARCH}?name=${term}&pagenumber=0${projectIdParam}${countryFilteredParam}${filteredRolesParam}`;
  return new Promise<User[]>(async (resolve, reject) => {
    try {
      const response = await axiosDefaultConfig.get(url);
      if (response.data && response.data.collection) {
        resolve(response.data.collection);
      } else {
        reject('no data');
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const getHelpInfo = (language: string) => {
  const url = config.USERS_HELP.replace('{encodedName}', language);
  return buildGet(url);
};

export const sendPoll = (score: number, suggestions: string) => {
  const data = { score, suggestions };
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosDefaultConfig.post(config.HELP_POLL, data);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const getLanguages = () => {
  return buildGet(config.LANGUAGES);
};

export const getLanguageConfig = (storedLanguage: string | null) => {
  const url = config.GET_TRANSLATION_FILE.replace('{locale}', storedLanguage || '');
  return new Promise<any>(async (resolve, reject) => {
    try {
      const response = await axiosDefaultConfig.get(url);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const checkMaintenance = () => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const response = await axiosDefaultConfig.get(config.STATUS_MAINTENANCE);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const createCustomTab = (data: any) => {
  const url = config.USER_CUSTOM_TAB;
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosDefaultConfig.post(url, data);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const editCustomTab = (data: any, tabId: number) => {
  const url = `${config.USER_CUSTOM_TAB}/${tabId}`;

  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosDefaultConfig.put(url, data);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const deleteCustomTab = (tabId: number) => {
  const url = `${config.USER}/${tabId}`;

  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosDefaultConfig.delete(url);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};
