import React, { useContext, useState } from 'react';
import './AddEvent.scss';
import CustomDatePicker from '../../../../Util/CustomDatePicker/CustomDatePicker';
import { getAccordanceType } from '../../../../../api/accordanceApi';
import { displayName } from '../NewAccordanceForm/SharedInputs';
import { useTranslation } from 'react-i18next';
import Modal from '@material-ui/core/Modal';
import { ReactComponent as Closebutton } from '../../../../../assets/icons/u_multiply.svg';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { style } from '../../../../Help/HelpModal/HelpModal.Styles';
import moment from 'moment';
import { newEvent } from '../AccordanceTrello/util';
import { useEventContext } from '../../../../../context/EventContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      padding: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      width: 700,
      display: 'flex',
      justifyContent: 'flexCenter',
      flexDirection: 'column',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: '24px',
      borderRadius: '16px',
      border: 'none',
      height: 'auto',
      gap: '48px',
    },
  }),
);

type Props = {
  accordanceId: number;
  expirationDate: any;
  creationDate: any;
};

const AddEvent = (props: Props) => {
  const { accordanceId, expirationDate, creationDate } = props;
  const { t } = useTranslation();
  const [openModal, setopenModal] = useState<any>({ modal1: false, modal2: false });
  const [eventType, setEventType] = useState<any>();
  const [revisionDate, setRevisionDate] = useState<any>();
  const [accordanceType, setAccordanceType] = useState<any>();

  const { getDataEvent } = useEventContext();

  const rootRef = React.useRef<HTMLDivElement>(null);

  const classes = useStyles();

  const getData = async () => {
    const typeAccordance = await getAccordanceType();
    setAccordanceType(typeAccordance);
  };

  const openModalForm = modal => {
    setopenModal({ ...openModal, [modal]: true });
    getData();
  };

  const closeModalForm = modal => {
    setopenModal({ ...openModal, [modal]: false });
  };

  const handleDefinedAgreementChange = (e, value) => {
    setEventType(value);
  };

  const addEvent = async () => {
    const data = {
      accordanceId: accordanceId,
      accordanceAgreementsTypeId: eventType?.id,
      dateCreated: moment(revisionDate).format(),
    };

    await newEvent(data);
    await getDataEvent();
  };

  return (
    <>
      <div style={{ width: '100%' }}>
        <button className={'add-event-button'} onClick={() => openModalForm('modal1')}>
          <span className={'label-span'}>+ {t('new_add_event')}</span>
        </button>
      </div>
      <Modal
        disableBackdropClick
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        onClose={() => setopenModal({ ...openModal, modal1: false })}
        open={openModal.modal1}
        aria-labelledby="server-modal-title"
        aria-describedby="server-modal-description"
        className={classes.modal}
        container={() => rootRef.current}
      >
        <div className={classes.paper}>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <button
              style={{ cursor: 'pointer', border: 'none', backgroundColor: 'transparent' }}
              onClick={() => {
                closeModalForm('modal1');
              }}
              className="button-toggle"
            >
              <Closebutton />
            </button>
          </div>
          <h2 className={'label-event-title'}>{t('new_add_associated')}</h2>
          <div className={'label-select-container-event'}>
            <p className="label">{t('new_associated_event')} </p>
            <Autocomplete
              id="combo-box-demo"
              value={eventType}
              onChange={handleDefinedAgreementChange}
              options={accordanceType}
              getOptionLabel={displayName}
              style={{ width: '100%' }}
              renderInput={params => <TextField {...params} placeholder={t('new_write_select')} fullWidth />}
              noOptionsText={t('no_results')}
              // classes={classes}
              // getOptionSelected={(option, value) => option.id === value.id}
            />
          </div>
          <div className={'label-select-container-event'}>
            <CustomDatePicker
              date={revisionDate}
              label={t('new_portfolio_review_date_two')}
              setDate={date => {
                // setFormErrors({ ...formErrors, ...{ revisionDate: false } });
                setRevisionDate(date);
                // if (newRevisionDateBiggerExpiration(date, expirationDate)) {
                //   setExpirationDate(null);
                // }
              }}
              style={{ customInput: { width: 270, boxSizing: 'border-box', justifyContent: 'flex-start' } }}
              // error={formErrors?.revisionDate}
              placeholderComplete={true}
              minDate={new Date(creationDate)}
              maxDate={new Date(expirationDate)}
            />
          </div>
          <div className={'label-select-container-event-button'}>
            <button
              onClick={() => {
                addEvent();
                setopenModal({ ...openModal, modal1: false });
              }}
              className={`btn btn-primary`}
              // disabled={submitting}
            >
              {t('new_add_event')}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddEvent;
