import React, { useState, useRef, useEffect } from 'react';
import styles from './InfoEarlyWarnings.module.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconClose } from '../../../assets/icons/u_multiply.svg';
import { ReactComponent as IconLON } from '../../../assets/icons/u_lon.svg';
import { ReactComponent as IconTCP } from '../../../assets/icons/u_tcp.svg';
import { ReactComponent as IconIGR } from '../../../assets/icons/u_igr.svg';
import { ReactComponent as IconSatisfactory } from '../../../assets/icons/u_satisfactory.svg';
import { ReactComponent as IconCheck } from '../../../assets/icons/u_alert-check.svg';
import { ReactComponent as IconPartially } from '../../../assets/icons/u_partially-alert.svg';
import { ReactComponent as IconAlertExclamation } from '../../../assets/icons/u_alert-exclamacion.svg';
import { ReactComponent as IconInsastifaction } from '../../../assets/icons/u_insatisfation.svg';
import { ReactComponent as IconAlertMultyple } from '../../../assets/icons/u_alert-multyple.svg';
import { ReactComponent as IconAlertNone } from '../../../assets/icons/u_alert-not-assigned.svg';

const {
  container,
  clickable,
  capitalized,
  title,
  message,
  infoEl,
  tableIndicatorHelp,
  header,
  footer,
  tableInfoBullets,
  firstInfo,
  secondInfo,
  infoText,
  infoHeader,
  toolTipsInfo,
  closeIcon,
  infoIcons,
} = styles;
const InfoEarlyWarnings = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const toolTipsInfoRef = useRef<HTMLDivElement>(null);
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  const handleClickOutside = (event: MouseEvent) => {
    if (toolTipsInfoRef.current && !toolTipsInfoRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={container}>
      <div className={header}>
        <div className={clickable} onClick={handleToggle}>
          <p className={capitalized}> {t('alert_information')}</p>
        </div>

        <div className={tableIndicatorHelp}>
          <TableInfoBullets />
        </div>
      </div>
      {isOpen ? (
        <div ref={toolTipsInfoRef} className={toolTipsInfo}>
          <div className={closeIcon} onClick={handleToggle}>
            <IconClose />
          </div>
          <div className={infoHeader}>
            <p className={infoText}>{t('earlyWarningInfo_p1')}</p>
            <p className={infoText}>{t('earlyWarningInfo_p2')}</p>
            <p className={infoText}>{t('earlyWarningInfo_p3')}:</p>
          </div>
          {infoEWData.map(infoElement => (
            <div key={infoElement.title} className={infoEl}>
              <p className={title}>
                {t(infoElement.title)}: <span className={message}>{t(infoElement.message)}</span>
              </p>
            </div>
          ))}
        </div>
      ) : null}
      {/* {isOpen && (
        <div className={footer}>
          <TableInfoBullets />
        </div>
      )} */}
    </div>
  );
};

interface InfoEW {
  title: string;
  message: string;
}

const infoEWData: InfoEW[] = [
  { title: 'earlyWarningInfoTitle1', message: 'earlyWarningInfoMessage1' },
  { title: 'earlyWarningInfoTitle2', message: 'earlyWarningInfoMessage2' },
  { title: 'earlyWarningInfoTitle3', message: 'earlyWarningInfoMessage3' },
  { title: 'earlyWarningInfoTitle4', message: 'earlyWarningInfoMessage4' },
  { title: 'earlyWarningInfoTitle5', message: 'earlyWarningInfoMessage5' },
  { title: 'earlyWarningInfoTitle6', message: 'earlyWarningInfoMessage6' },
];

const TableInfoBullets = () => {
  const { t } = useTranslation();
  return (
    <div className={tableInfoBullets}>
      <div className={firstInfo}>
        <ul className={infoIcons}>
          <li>
            <IconLON /> LON
          </li>
          <li>
            <IconTCP /> TCP
          </li>
          <li>
            <IconIGR /> IGR
          </li>
        </ul>
      </div>
      <div className={secondInfo}>
        <ul className={infoIcons}>
          {/* <li>
            <IconSatisfactory />
            {`${t('partially_satisfactory')}`}
          </li> */}
          <li>
            <IconCheck /> {`${t('new_no_alerts')}`}
          </li>
          <li>
            <IconPartially /> <IconInsastifaction /> {`${t('no_associated_actions_found')}`}
            {/*Parcialmente Alerta */}
          </li>
          <li>
            <IconAlertExclamation /> <IconAlertMultyple /> {`${t('associated_actions_found')}`}
          </li>
          {/* <li>
            <IconInsastifaction /> {`${t('partially_unsatisfactory')}`}
          </li> */}
          {/* <li>
            <IconAlertMultyple /> {`${t('partially_unsatisfactory')}`}
          </li> */}
          <li>
            <IconAlertNone /> {`${t('new_does_not_apply')}`}
            {/* No aplica */}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default InfoEarlyWarnings;
