import { HelpTypes, SET_HELP_ITEM } from '../actions/types';
import Help from '../../models/Help';

const initialState: Help = {
  title: '',
  type: '',
  iconUrl: '',
  content: [],
};

const helpReducer = (state = initialState, action: HelpTypes): Help => {
  switch (action.type) {
    case SET_HELP_ITEM:
      return action.payload;
    default:
      return state;
  }
};

export default helpReducer;
