import React from 'react';
import './ChronologyAccordance.scss';
// import { GcmHighlighted } from '../../../models/GcmAlerts';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

interface IHighlightInfo {
  highlightNode: string | null;
  barColor: CSSProperties;
  highlightEnumReference: string | null;
}

interface IDividerInfo {
  firstDivider: string;
}
const EMPTY_HIGHLIGHT_INFO: IHighlightInfo = {
  highlightNode: null,
  barColor: {},
  highlightEnumReference: null,
};

const DEFAULT_DIVIDER_INFO: IDividerInfo = { firstDivider: 'small-divider grey' };

type PropsDateNodeSegment = {
  nodeTitle: string;
  nodeSubtitle?: string;
  data?: string;
  last?: boolean;
  styleCircleData?: string;
};

export const DateSegment = ({ nodeTitle, nodeSubtitle, data, styleCircleData, last = false }: PropsDateNodeSegment) => {
  return last ? (
    <>
      <li className="stylesLi">
        <span className="last-item">{nodeTitle}</span> <br />
        {nodeSubtitle ? <span className="date-text">{nodeSubtitle}</span> : null} <br />
        <span className="date-text">{data}</span>
      </li>
    </>
  ) : (
    <>
      <li className={`${styleCircleData}`}>
        <span className="last-item">{nodeTitle}:</span> <br />
        {nodeSubtitle ? <span className="date-text">{nodeSubtitle}</span> : null} <br />
        <span className="date-text">{data}</span>
      </li>
    </>
  );
};
