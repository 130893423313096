/**
 *
 * ESG Indicators
 */
/***
 *keyEnum.ESG_DISTRIBUTION_ACCORDING_TO_ENVIRONMENTAL_AND_SOCIAL_CATEGORY
 *
 */
import { QCalificationInitials } from '../../../models/EsgQCalification';
import { IndicatorKeyEnum as keyEnum } from '../../../models/IndicatorKeyEnum';
import {
  findMetricValue,
  getIndicatorData,
  metricAreZeros,
  metricIsEmpty,
  getGenericDoughnutIndicator,
} from './indicatorHelperUtil';
import { getDoughnutIndicator } from './reusableIndicators';

const addEsgAccordingEnvironmentalAndSocialCategory = (indicators, cardIndicators) => {
  const indicator = getIndicatorData(
    indicators,
    keyEnum.ESG_DISTRIBUTION_ACCORDING_TO_ENVIRONMENTAL_AND_SOCIAL_CATEGORY,
  );

  const title = 'environmental_and_social_category';
  const detailTitle = '';
  const type = 'doughnutChart';

  if (!indicator) {
    return;
  } else if (metricAreZeros(indicator)) {
    cardIndicators.push({
      id: indicator.id,
      indicatorKey: indicator.key,
      type,
      title,
      detailTitle,
      data: null,
      order: indicator.order,
    });
  } else {
    const A = findMetricValue(indicator.metrics, QCalificationInitials.A);
    const B = findMetricValue(indicator.metrics, QCalificationInitials.B);
    const B13 = findMetricValue(indicator.metrics, QCalificationInitials.B13);
    const C = findMetricValue(indicator.metrics, QCalificationInitials.C);
    const FI = findMetricValue(indicator.metrics, QCalificationInitials.FI);
    const NA = findMetricValue(indicator.metrics, QCalificationInitials.Not_Assigned);

    const projectQuantity: any = [];
    projectQuantity.push(A, B, FI, B13, C, NA);

    const dataLabelsTranslations = [
      QCalificationInitials.A,
      QCalificationInitials.B,
      QCalificationInitials.FI,
      QCalificationInitials.B13,
      QCalificationInitials.C,
      QCalificationInitials.Not_Assigned,
    ];

    const dataLabelsClasses = ['chambray', 'indigo', 'cornflower-blue-01', 'anakiwa-02', 'periwinkle', 'silver'];
    const backgroundColor = ['#000E2A', '#538CFF', '#002368', '#B1CBFF', '#004CE4', '#D0D5E0'];
    const hoverBackgroundColor = ['#000E2A', '#538CFF', '#002368', '#B1CBFF', '#004CE4', '#D0D5E0'];
    const conceptTranslation = { singular: 'project', plural: 'menu_projects' };
    const options = {
      projectQuantity,
      dataLabelsTranslations,
      dataLabelsClasses,
      backgroundColor,
      hoverBackgroundColor,
      conceptTranslation,
      type,
      title,
      detailTitle,
      columns: 2,
    };
    cardIndicators.push(getDoughnutIndicator(indicator, options));
  }
};

const addDistributionAccordingToLatestEfas = (indicators, cardIndicators) => {
  const indicator = getIndicatorData(indicators, keyEnum.DISTRIBUTION_ACCORDING_TO_LATEST_EFAS);
  const title = 'opinion_of_latest_afs';
  const detailTitle = '';
  const type = 'doughnutChart';
  if (!indicator) {
    return;
  } else if (metricIsEmpty(indicator) || metricAreZeros(indicator, 'FISCAL_YEAR')) {
    cardIndicators.push({
      id: indicator.id,
      indicatorKey: indicator.key,
      type,
      title,
      detailTitle,
      data: null,
      order: indicator.order,
    });
  } else {
    const clean = findMetricValue(indicator.metrics, 'CLEAN');
    const disclaimerOfOpinion = findMetricValue(indicator.metrics, 'DISCLAIMER_OF_OPINION');
    const qualifiedOpinion = findMetricValue(indicator.metrics, 'QUALIFIED_OPINION');
    const adverseOpinion = findMetricValue(indicator.metrics, 'ADVERSE_OPINION');
    const pending = findMetricValue(indicator.metrics, 'PENDING');
    const fiscalYear = findMetricValue(indicator.metrics, 'FISCAL_YEAR');

    const projectQuantity: any = [];
    projectQuantity.push(clean, qualifiedOpinion, adverseOpinion, disclaimerOfOpinion, pending);
    const dataLabelsTranslations = [
      'clean',
      'qualified_opinion',
      'adverse_opinion',
      'disclaimer_of_opinion',
      'pending',
    ];
    const dataLabelsClasses = ['green', 'yellow', 'red', 'orange', 'pending'];
    const backgroundColor = ['#91E2A1', '#F7DD00', '#F06D6D', '#EF9206', '#D0DEF8'];
    const hoverBackgroundColor = ['#91E2A1', '#F7DD00', '#F06D6D', '#EF9206', '#D0DEF8'];
    const conceptTranslation = { singular: 'project', plural: 'menu_projects' };
    const detailExtraProps = { fiscalYear };
    const options = {
      projectQuantity,
      dataLabelsTranslations,
      dataLabelsClasses,
      backgroundColor,
      hoverBackgroundColor,
      conceptTranslation,
      title,
      detailTitle,
      type,
      detailExtraProps,
    };
    cardIndicators.push(getDoughnutIndicator(indicator, options));
  }
};

const addClassificationOfAcquisitionPlans = (indicators, cardIndicators) => {
  const indicator = getIndicatorData(indicators, keyEnum.CLASSIFICATION_OF_ACQUISITION_PLANS);
  const title = 'last_title_clasification';
  const detailTitle = 'last_title_clasification';
  const type = 'doughnutChart';

  if (!indicator) {
    return;
  } else if (metricIsEmpty(indicator) || metricAreZeros(indicator, 'FISCAL_YEAR')) {
    cardIndicators.push({
      id: indicator.id,
      indicatorKey: indicator.key,
      type,
      title,
      detailTitle,
      data: null,
      order: indicator.order,
    });
  } else {
    const calification_metric = findMetricValue(indicator.metrics, 'QUALIFICATION_GENERAL');
    let alert_text = '';
    if (calification_metric === 1) {
      alert_text = 'satisfactory'
    } else if (calification_metric === 2) {
      alert_text = 'alert'
    } else {
      alert_text = 'problem'
    }
    const alert_number = findMetricValue(indicator.metrics, 'TOTAL_WEIGHTED');
    const clean = findMetricValue(indicator.metrics, 'GREEN');
    const qualifiedOpinion = findMetricValue(indicator.metrics, 'YELLOW');
    const adverseOpinion = findMetricValue(indicator.metrics, 'RED');
    const totalWeight = findMetricValue(indicator.metrics, 'TOTAL_WEIGHTED');
    const projectQuantity: any = [];
    projectQuantity.push(clean, qualifiedOpinion, adverseOpinion);
    const dataLabelsTranslations = [
      'satisfactory',
      'alert',
      'problem',
    ];
    const dataLabelsClasses = ['satisfactory', 'alert', 'problem'];
    const backgroundColor = ['#50AC32', '#E7CE00', '#f06d6d'];
    const hoverBackgroundColor = ['#50AC32', '#E7CE00', '#f06d6d'];
    const conceptTranslation = { singular: 'last_plan', plural: 'last_plans' };

    const detailExtraProps = { totalWeight };
    const options = {
      projectQuantity,
      dataLabelsTranslations,
      dataLabelsClasses,
      backgroundColor,
      hoverBackgroundColor,
      conceptTranslation,
      title,
      alert_number,
      alert_text,
      detailTitle,
      type,
      detailExtraProps,
    };
    cardIndicators.push(getDoughnutIndicator(indicator, options));
  }
};

const addPmrStateDistribution = (indicators, cardIndicators) => {
  const customInfo = {
    title: 'pmr_initials',
    detailTitle: 'pmr_initials',
    dataLabel1: 'satisfactory',
    dataLabel2: 'alert',
    dataLabel3: 'problem',
    dataLabel4: 'not_assigned_initials',
  };

  const indicator = getIndicatorData(indicators, keyEnum.PMR_STATE_DISTRIBUTION);
  const type = 'doughnutChart';

  const doughnutIndicator = getGenericDoughnutIndicator(indicator, type, customInfo);
  doughnutIndicator && cardIndicators.push(doughnutIndicator);
};

const addCTPerformceRating = (indicators, cardIndicators) => {
  const customInfo = {
    title: 'cts_perfomance_rating',
    detailTitle: 'cts_perfomance_rating',
    dataLabel1: 'satisfactory',
    dataLabel2: 'alert',
    dataLabel3: 'problem',
    dataLabel4: 'not_applicable',
  };

  const indicator = getIndicatorData(indicators, keyEnum.TCP_PERFORMANCE);
  const type = 'doughnutChart';

  const doughnutIndicator = getGenericDoughnutIndicator(indicator, type, customInfo);
  doughnutIndicator && cardIndicators.push(doughnutIndicator);
};

export {
  addEsgAccordingEnvironmentalAndSocialCategory,
  addDistributionAccordingToLatestEfas,
  addPmrStateDistribution,
  addCTPerformceRating,
  addClassificationOfAcquisitionPlans
};
