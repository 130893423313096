import React from 'react';
import { useTranslation } from 'react-i18next';
import './TextIndicator.scss';
import { translateMultipleText } from '../../../../../services/stringUtil';

type Props = {
  card: any;
  analyticIndicator?: boolean;
  onClickMore?: any;
};

const TextIndicator = ({ card, analyticIndicator = false, onClickMore }: Props) => {
  const { t } = useTranslation(analyticIndicator ? 'contentManager' : '');
  return (
    <div className={analyticIndicator ? 'text-analytic-indicator-container' : 'text-indicator-container'}>
      <span className="value-text">{card.data ? card.data.value : '-'}</span>
      <span className="light-text">{card.title ? t(card.title) : ''}</span>
      <button className="bold-text text-inidicator-subtitle" onClick={onClickMore} tabIndex={30}>
        {card.subtitle ? translateMultipleText(card.subtitle, t) : ''}
      </button>
    </div>
  );
};

export { TextIndicator };
