export enum GetSectionReportEnum {
  Projects_Section = 1,
  Portfolio_Section,
  Review_Section = 4,
  Accordance_Section = 7,
  Alert_Section = 10,
  Customizable_Section = 5,
}

export enum GetReportsFileTypeEnum {
  PDF = 0,
  Excel = 2,
  Document_Word = 1,
  Power_Point = 3,
}
export enum ReportStatusEnum {
  Done = 1,
  Generating = 2,
  Unset = 3,
  Error = 0,
}

export enum ReportSectionsEnum {
  'baseInfo',
  'planning',
  'financial',
  'accordances',
  'risk',
  'products',
  'management',
}

export enum ReviewReportSectionsEnum {
  'general_indicators_of_the_portfolio' = 0,
  'original_disbursements_annual_projection',
  'adjusted_disbursements_annual_projection',
  'base_information',
  'planning',
  'financial',
  'risk',
  'active_accordance',
  'accomplished_accordance',
  'cancelled_accordance',
  'previous_revision_accordances',
  'current_revision_acordances',
  'include_monitoring_accordances',
  'annual_disbursements_proj',
  'detail_of_each_selected_project',
  'revision_accordances',
}
export enum ReportSectionTabs {
  PORTFOLIO,
  PROJECTS,
  REVIEWS,
  ACCORDANCES,
  ALERTS,
}
