import React, { useEffect, useState } from 'react';
import { TextareaField } from '../MemoryHelpComponents/MemoryHelpsInputs';
import { Trans, useTranslation } from 'react-i18next';
import { useFormMemoryHelpContext } from '../../../context/MemoryHelpFormContext';
import { useNumberFormatter } from '../../../hooks/useNumberFormatter';
import { extractDate } from '../utils';

const CURRENT_STAGE = "generalAspects";

export const GeneralAspects = () => {
  const { t } = useTranslation();

  const [formattedStartDate, setFormattedStartDate] = useState('');

  const { onInputChange, agreementsData, setOptionalFields } = useFormMemoryHelpContext();

  const { formatMillions } = useNumberFormatter();

  useEffect(() => {
    if (agreementsData) {
      const startDate = agreementsData?.dataRevision?.startDate
      setFormattedStartDate(extractDate(startDate));
    }
  }, [agreementsData]);

  useEffect(() => {
    setOptionalFields(['aditional_info_general_aspects']);
  }, [setOptionalFields]);

  return (
    <>
      <h3 className='title'>2.
        <Trans i18nKey='memory_help.tabs.2'>
          {{ value1: formattedStartDate }}
        </Trans>
      </h3 >
      <ul>
        <li>
          <span className='num'>2.1</span>
          <Trans i18nKey="memory_help.container_tabs.2.p_2_1.p1">
            {{ value1: agreementsData?.dataRevision?.totalProjects }}
            {{ value2: formatMillions(parseInt(agreementsData?.dataRevision?.approveD_AMOUNT)) }}
            {{ value3: formatMillions(parseInt(agreementsData?.dataRevision?.pendinG_DISBURSEMENT_AMOUNT)) }}
          </Trans>
        </li>
        <li>
          <span className='num'>2.2</span>
          <Trans i18nKey="memory_help.container_tabs.2.p_2_2.p1">
            {{ value1: formatMillions(parseInt(agreementsData?.dataRevision?.actuaL_PROJECTION_AMNT_USEQ)) }}
            {{ value2: formatMillions(parseInt(agreementsData?.dataRevision?.disburseD_AMNT_USEQ)) }}
          </Trans>
        </li>
        <li>
          <span className='num'>2.3</span>
          <Trans i18nKey="memory_help.container_tabs.2.p_2_3.p1">
            {{ value1: agreementsData?.dataRevision?.year }}
            {{ value2: agreementsData?.dataRevision?.totalProjects }}
            {{ value3: agreementsData?.dataRevision?.alerT_OPER_CNT }}
            {{ value4: agreementsData?.dataRevision?.probleM_OPER_CNT }}
            {{ value5: '5' }}
            {{ value6: "6%" }}
          </Trans>
        </li>
        <li>
          <span className='num'>2.4</span>
          <TextareaField
            placeholder={t('memory_help.container_tabs.2.p_2_4.placeholder')}
            name='aditional_info_general_aspects'
            onChange={(e) => onInputChange(e, CURRENT_STAGE)}
            currentTabId={CURRENT_STAGE} />
        </li>
      </ul>
    </>
  );
};

export default GeneralAspects;
