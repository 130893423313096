import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { ProjectsListSkeleton } from '../../../LoadersSkeletons/ProjectsListSkeleton/ProjectsListSkeleton';
import { ReactComponent as IconSort } from '../../../../assets/icons/u_sort.svg';
import { ReactComponent as IconAlertMulyple } from '../../../../assets/icons/u_alert-multyple.svg';
import { ReactComponent as IconAlertExclamation } from '../../../../assets/icons/u_alert-exclamacion.svg';
import { ReactComponent as IconAlertCheck } from '../../../../assets/icons/u_alert-check.svg';
import PaginationTable from '../../PaginationTable/PaginationTable';
import { Pagination } from '@material-ui/lab';
import usePaginationSort from '../../../../hooks/usePaginationSort';
import useTable from '../../../../hooks/useTable';
import useDataNormalize from '../../../../hooks/useDataNormalize';
import { ExcelDownloadButton } from '../../../Util/ExcelDownloadButton/ExcelDownloadButton';
import { useSelector } from 'react-redux';
import { CLASSIFICATION_OF_ACQUISITION_PLANS } from '../../../../services/sessionFiltersIndicatorDetailHelper';
import { IndicatorKeyEnum } from '../../../../models/IndicatorKeyEnum';
import { ProjectCounter } from '../../ProjectCounter/ProjectCounter';
import { renderDateOrSlash } from '../../../../services/stringUtil';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CriteriaType } from '../../../../models/PaginationIndicator';
import { FilterTables } from '../../../Filter/FilterTables/FilterTables';

enum FilterColorSelected {
  NONE_SELECTED = -1,
  GREEN = 0,
  YELLOW = 1,
  RED = 2,
}

const handleMonthDiff = (filterColor: FilterColorSelected, projecyt: any) => {
  if (filterColor === FilterColorSelected.NONE_SELECTED) {
    return true;
  }
  if (filterColor === FilterColorSelected.YELLOW) {
    return projecyt.field5 === 'YELLOW';
  } else if (filterColor === FilterColorSelected.RED) {
    return projecyt.field5 === 'RED';
  }
  return projecyt.field5 === 'GREEN';
};

type Props = {
  earlyWarningsTab: boolean[];
  projectsQuantities: Function;
  loading?: boolean;
};

const headers = [
  'last_project',
  'operation',
  'last_name_project',
  'last_plan_status',
  'new_no_objection_date',
  'new_processes_with_expired_dates',
  'last_purchases_made',
  'last_overall_classification',
];
const selectedKeys = ['code', 'loanNumber', 'title', 'field1', 'field2', 'field3', 'field4', 'field6'];

export default function ClassificationOfAcquisitionPlansTable({
  projectsQuantities,
  earlyWarningsTab,
  loading,
}: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const [sortAsc, setSortAsc] = React.useState(true);
  const { t } = useTranslation();
  const { handleClick, projects } = useTable();
  const [filterdProjectsWithClasificationPlans, setFilteredProjectssWithClasificationPlans] = useState<any>(projects);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { currentIndicator } = useSelector<any, any>(state => state);

  const [dataFilter, setDataFilter] = useState<any>(projects);
  const [dataFilterBtn, setDataFilterBtn] = useState<any>();

  useEffect(() => {
    setDataFilter(projects);
  }, [projects]);

  const { handlePaginationChange, handleSort, currentList, pagesCount, currentPage } = usePaginationSort({
    projects: dataFilter,
    defaultSortCriteria: 'code',
    pageCount: rowsPerPage,
  });

  useEffect(() => {
    const filterNewProjects = [...projects];
    const dataExtrated = currentIndicator.data.datasets[0].projectQuantity;
    projectsQuantities(dataExtrated);
    setFilteredProjectssWithClasificationPlans(filterNewProjects);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects]);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      if (filterdProjectsWithClasificationPlans.length !== 0) {
        setIsLoading(false);
      }
    }, 2000);
  }, [filterdProjectsWithClasificationPlans]);

  useEffect(() => {
    setDataFilter(
      projects.filter(p => {
        return handleMonthDiff(earlyWarningsTab.indexOf(true), p);
      }),
    );
    if (earlyWarningsTab.includes(true)) {
      setDataFilterBtn(
        projects.filter(p => {
          return handleMonthDiff(earlyWarningsTab.indexOf(true), p);
        }),
      );
    } else {
      setDataFilterBtn([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [earlyWarningsTab]);

  const handleClickOnProject = project => {
    const storage = sessionStorage.getItem(CLASSIFICATION_OF_ACQUISITION_PLANS);
    storage &&
      sessionStorage.setItem(
        CLASSIFICATION_OF_ACQUISITION_PLANS,
        JSON.stringify({ ...JSON.parse(storage), changeProjectType: false }),
      );
    handleClick(project);
  };

  let data = useDataNormalize(headers, selectedKeys, dataFilter);

  const sortTable = (keyToSort, fieldType) => {
    let criteriaType: CriteriaType;
    switch (fieldType) {
      case 'numeric':
      case 'money':
      case 'percentage':
      case 'no_round_two_decimal':
        criteriaType = CriteriaType.number;
        break;
      case 'date':
        criteriaType = CriteriaType.date;
        break;
      default:
        criteriaType = CriteriaType.string;
        break;
    }
    handleSort(keyToSort, criteriaType);
  };

  const renderIcon = calification => {
    if (calification === 'RED') {
      return <IconAlertMulyple />;
    } else if (calification === 'YELLOW') {
      return <IconAlertExclamation />;
    } else {
      return <IconAlertCheck />;
    }
  };

  const BlackTooltip = withStyles({
    tooltip: {
      backgroundColor: '#101319',
      fontSize: '16px',
      fontFamily: 'Rubik',
      lineHeight: '20px',
      padding: '8px 12px',
      borderRadius: '8px',
    },
    arrow: {
      fontSize: 20,
      color: '#4A4A4A',
      '&::before': {
        backgroundColor: '#101319',
      },
    },
  })(Tooltip);

  return (
    <>
      {isLoading ? (
        <ProjectsListSkeleton />
      ) : (
        <div className="indicator-detail-table-container">
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <ExcelDownloadButton headers={headers} selectedKeys={selectedKeys} dataExcel={data} />
            <FilterTables
              projects={projects.filter(p => {
                return handleMonthDiff(earlyWarningsTab.indexOf(true), p);
              })}
              keysFilter={selectedKeys}
              fnDataFilter={setDataFilter}
              disabledNumber={currentPage}
              dataFilterBtn={dataFilterBtn}
              indicatorKey={IndicatorKeyEnum.CLASSIFICATION_OF_ACQUISITION_PLANS}
              filterBtn={earlyWarningsTab}
            />
          </div>
          <TableContainer>
            <div className="border-table">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell onClick={() => sortTable('code', 'string')}>
                      <div className="text-head">
                        {t('menu_projects')} <IconSort className="sortIcon" />
                      </div>
                    </TableCell>
                    <TableCell onClick={() => sortTable('loanNumber', 'string')}>
                      <div className="text-head">
                        {t('operation')} <IconSort className="sortIcon" />
                      </div>
                    </TableCell>
                    <TableCell onClick={() => sortTable('title', 'string')}>
                      <div className="text-head">
                        {t('project_name')} <IconSort className="sortIcon" />
                      </div>
                    </TableCell>
                    <TableCell onClick={() => sortTable('field1', 'string')}>
                      <div className="text-head">
                        {t('new_plan_status')} <IconSort className="sortIcon" />
                      </div>
                    </TableCell>
                    <TableCell onClick={() => sortTable('field2', 'date')} style={{ width: '12%' }}>
                      <div className="text-head">
                        {t('new_no_objection_date')} <IconSort className="sortIcon" />
                      </div>
                    </TableCell>
                    <TableCell onClick={() => sortTable('field3', 'numeric')} style={{ width: '15%' }}>
                      <div className="text-head">
                        {t('new_processes_with_expired_dates')} <IconSort className="sortIcon" />
                      </div>
                    </TableCell>
                    <TableCell onClick={() => sortTable('field4', 'numeric')} style={{ width: '10%' }}>
                      <div className="text-head purchases_made">
                        {t('new_purchases_made')} <IconSort className="sortIcon" />
                      </div>
                    </TableCell>
                    <TableCell onClick={() => sortTable('field6', 'numeric')}>
                      <div className="text-head">
                        {t('new_general_rating')} <IconSort className="sortIcon" />
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataFilter?.length === 0
                    ? dataFilter.map((row, i) => (
                        <TableRow key={i}>
                          <TableCell className="link-project">
                            <BlackTooltip title={t('go_to_project_info')} arrow>
                              <Link style={{ color: '#3D3E40' }} to={`/projectdetail?id=${row.id}`}>
                                {row.code}
                              </Link>
                            </BlackTooltip>
                          </TableCell>
                          <TableCell>{row.loanNumber}</TableCell>
                          <TableCell className="name-project">
                            <BlackTooltip title={row.title} placement="bottom" enterDelay={500} leaveDelay={100} arrow>
                              <p className=" name-project-nowrap"> {row.title}</p>
                            </BlackTooltip>
                          </TableCell>
                          <TableCell>{row.field1}</TableCell>
                          <TableCell>{renderDateOrSlash(row.field2)}</TableCell>
                          <TableCell>{row.field3}</TableCell>
                          <TableCell>{Math.trunc(row.field4)} %</TableCell>
                          <TableCell>
                            <div className="flex-row">
                              {renderIcon(row.field5)}
                              {row.field6}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                    : currentList.map((row, i) => (
                        <TableRow key={i}>
                          <TableCell className="link-project">
                            <BlackTooltip title={t('go_to_project_info')} arrow>
                              <Link style={{ color: '#3D3E40' }} to={`/projectdetail?id=${row.id}`}>
                                {row.code}
                              </Link>
                            </BlackTooltip>
                          </TableCell>
                          <TableCell>{row.loanNumber}</TableCell>
                          <TableCell className="name-project">
                            <BlackTooltip title={row.title} placement="bottom" enterDelay={500} leaveDelay={100} arrow>
                              <p className=" name-project-nowrap"> {row.title}</p>
                            </BlackTooltip>
                          </TableCell>
                          <TableCell>{row.field1}</TableCell>
                          <TableCell>{renderDateOrSlash(row.field2)}</TableCell>
                          <TableCell>{row.field3}</TableCell>
                          <TableCell>{Math.trunc(row.field4)} %</TableCell>
                          <TableCell>
                            <div className="flex-row">
                              {renderIcon(row.field5)}
                              {row.field6}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </div>
          </TableContainer>
          <ProjectCounter
            arrayToCount={filterdProjectsWithClasificationPlans}
            countProjects={true}
            indicatorKey={IndicatorKeyEnum.CLASSIFICATION_OF_ACQUISITION_PLANS}
          />
          <div className="pagination-style">
            <PaginationTable fnSetRows={setRowsPerPage} rows={rowsPerPage} numProyects={dataFilter?.length} />
            {dataFilter?.length !== 0 ? (
              <Pagination count={pagesCount} page={currentPage} onChange={handlePaginationChange} />
            ) : null}
          </div>
        </div>
      )}
    </>
  );
}
