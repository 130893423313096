import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { multiSelectStyle, DropdownIndicator } from '../FilterComponent.Styles';
import {
  getCustomTabFilterDefaultValue,
  getSectorOptions,
  getDivisionFilterOptions,
  getPortfolioOrProjectSelectedTab,
  getDivisionsFromSectors,
} from '../UtilFunctions';
import { CalledFrom } from '../../../models/CalledFromEnum';
import { setFilterIndicators } from '../../../redux/actions/filterIndicators';
import { setFilter } from '../../../redux/actions/filter';
import useFilters from '../../../hooks/useFilters';


type Props = {
  setOptionSelected?: Function;
  calledFrom: CalledFrom;
  isEdit?: boolean;
  setSelectedSectorsOnParent?: Function;
};

const SectorsFilter = (props: Props) => {
  const { setOptionSelected, calledFrom, isEdit, setSelectedSectorsOnParent } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { filterEarlyWarnings, setFilterEarlyWarnings } = useFilters();
  const sectorsFromRedux = useSelector<any, any>(state => state.sectors);
  const menuTabs = useSelector<any, any>(state => state.menuTabs);
  const filterIndicators = useSelector<any, any>(state => state.filterIndicators);
  const filter = useSelector<any, any>(state => state.filter);

  const selectedTab = getPortfolioOrProjectSelectedTab(calledFrom, menuTabs);
  const sectorsOptions = getSectorOptions(sectorsFromRedux, menuTabs, calledFrom);
  const userDivisionSelected = filterIndicators.common.divisionId.filter(d => d.selectedByUser);




  const handleChange = value => {
    if (value === null) {
      value = [];
    }


    setSelectedSectorsOnParent && setSelectedSectorsOnParent(value);
    const divisions = getDivisionsFromSectors(value, sectorsFromRedux);
    const divisionOptions = getDivisionFilterOptions(divisions);


    switch (calledFrom) {
      case CalledFrom.PORTFOLIO:
        dispatch(
          setFilterIndicators({
            ...filterIndicators,
            common: {
              ...filterIndicators.common,
              sectors: value,
              divisionId: updatedDivisions(value).length !== 0 ? updatedDivisions(value) : divisionOptions,
            },
          }),
        );
        break;
      case CalledFrom.PROJECT:
        dispatch(
          setFilter({
            ...filter,
            common: {
              ...filter.common,
              sectors: value,
              divisionId: divisionOptions,
            },
          }),
        );
        break;
      case CalledFrom.CUSTOM_TABS_PORTFOLIO:
      case CalledFrom.CUSTOM_TABS_PROJECT:
        setOptionSelected && setOptionSelected(value);
        break;
      case CalledFrom.EARLY_WARNINGS:
        dispatch(
          setFilterEarlyWarnings({
            ...filterEarlyWarnings,
            common: {
              ...filterEarlyWarnings.common,
              sectors: value,
              divisionId: divisionOptions,
            },
          }),
        );
        break;
    }
  };

  useEffect(() => {
    let selectedOption;
    const isSectorTab = selectedTab.tabId === 0 && sectorsFromRedux.find(item => item.code === selectedTab.title);

    if (isSectorTab && calledFrom !== CalledFrom.CUSTOM_TABS_PORTFOLIO) {
      selectedOption = [
        {
          id: isSectorTab.id,
          value: isSectorTab.id,
          label: `${isSectorTab.code} - ${isSectorTab.description}`,
        },
      ];
      setSelectedSectorsOnParent && setSelectedSectorsOnParent(selectedOption);
    } else {
      selectedOption = getDefaultValue();
      setSelectedSectorsOnParent && setSelectedSectorsOnParent(selectedOption ? selectedOption : []);
    }

    if (isEdit) {
      selectedOption.length && setOptionSelected && setOptionSelected(selectedOption);
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDefaultValue = () => {
    switch (calledFrom) {
      case CalledFrom.PORTFOLIO:
        return filterIndicators.common.sectors;
      case CalledFrom.PROJECT:
        return filter.common.sectors;
      case CalledFrom.CUSTOM_TABS_PORTFOLIO:
      case CalledFrom.CUSTOM_TABS_PROJECT:
        return isEdit ? getCustomTabFilterDefaultValue(selectedTab, 'SectorId', sectorsOptions) : null;
      case CalledFrom.EARLY_WARNINGS:
        return filterEarlyWarnings.common.sectors;
      default:
        return null;
    }
  };


  const mapDivision = (sectorsDivisions) => {
    return sectorsDivisions.map(option => ({
      id: option.id,
      value: option.name,
      label: `${option.code} - ${option.name}`,
    }));
  };

  const updatedDivisions = (listvalues) => {

    if (userDivisionSelected && userDivisionSelected.length !== 0) {

      let index = listvalues.length - 1;
      let lastSectorSelected = listvalues[index];
      const newDivisionsToSearchFor = getDivisionsFromSectors(listvalues.filter(v => v.id === lastSectorSelected.id), sectorsFromRedux);
      let newDivisions = mapDivision(newDivisionsToSearchFor);
      let updatedDivisions = [...userDivisionSelected, ...newDivisions];
      return updatedDivisions;
    }
    return [];
  }


  return (
    <Select
      id="sectorsSelect"
      components={{ DropdownIndicator }}
      defaultValue={getDefaultValue()}
      name="sectors"
      options={sectorsOptions}
      className="basic-multi-select"
      classNamePrefix="All"
      placeholder={t('all')}
      onChange={value => handleChange(value)}
      styles={multiSelectStyle}
      isMulti
    />
  );
};

export default SectorsFilter;
