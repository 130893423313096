import React, { Fragment } from 'react';
import { Bubble } from '../BubbleElement/BubbleElement';
import useFilters from '../../../hooks/useFilters';
import { CalledFrom } from '../../../models/CalledFromEnum';
import { handleActualStateWithAnalytics, handleBubblesWithAnalytics } from '../UtilFunctions';
import { IFilterBubble } from '../../../models/FilterBubble';

type Props = IFilterBubble;

const RegionBubbles = (props: Props) => {
  const { indicatorCall, analyticCall, calledFrom } = props;

  const {
    filter,
    filterIndicators,
    filterAnalytics,
    dispatch,
    setFilter,
    setFilterIndicators,
    setFilterAnalytics,
    filterEarlyWarnings,
    setFilterEarlyWarnings,
  } = useFilters();

  const bubbles = handleBubblesWithAnalytics({
    calledFrom,
    indicatorCall,
    filterIndicators,
    analyticCall,
    filterAnalytics,
    filterEarlyWarnings,
    filter,
  });
  const closeBubble = regionId => {
    const actualState = handleActualStateWithAnalytics({
      calledFrom,
      indicatorCall,
      filterIndicators,
      analyticCall,
      filterEarlyWarnings,
      filterAnalytics,
      filter,
      commonProp: 'regionId',
    });
    const newState = actualState.filter(region => {
      return region.id !== regionId;
    });

    if (calledFrom === CalledFrom.EARLY_WARNINGS) {
      dispatch(
        setFilterEarlyWarnings({
          ...filterEarlyWarnings,
          common: {
            ...filterEarlyWarnings.common,
            regionId: newState,
          },
        }),
      );
    } else {
      if (indicatorCall) {
        dispatch(
          setFilterIndicators({
            ...filterIndicators,
            common: {
              ...filterIndicators.common,
              regionId: newState,
            },
          }),
        );
      } else if (analyticCall) {
        dispatch(
          setFilterAnalytics({
            ...filterAnalytics,
            regionId: newState,
          }),
        );
      } else {
        dispatch(
          setFilter({
            ...filter,
            common: {
              ...filter.common,
              regionId: newState,
              itSearch: false,
            },
          }),
        );
      }
    }
  };

  return (
    <Fragment>
      {bubbles.regionId.map(region => (
        <Bubble key={region.id} itsHidden={false} label={region.label} closeBubble={() => closeBubble(region.id)} />
      ))}
    </Fragment>
  );
};

export default RegionBubbles;
