import React, { useEffect } from 'react';
import DisbursementProjectionEvolution from '../DisbursementProjectionEvolution/DisbursementProjectionEvolution';
import { EarlyWarningHistory } from '../EarlyWarningHistory/EarlyWarningHistory';
import { DisbursementsProjectionsEvolution } from '../../../models/DisbursementsProjectionsEvolution';
import { isMobile } from '../../../services/booleanUtil';
import { ProjectsListSkeleton } from '../../LoadersSkeletons/ProjectsListSkeleton/ProjectsListSkeleton';

type Props = {
  disbursementsProjectionsEvolution: DisbursementsProjectionsEvolution[];
  dataEvolutionEmpty: boolean;
};
const ExpandedComponent = ({ disbursementsProjectionsEvolution, dataEvolutionEmpty }: Props) => {
  const [isLoading, setisLoading] = React.useState(true);

  useEffect(() => {
    if (disbursementsProjectionsEvolution.length > 0) return setisLoading(false);
  }, [disbursementsProjectionsEvolution]);

  return isMobile() ? (
    <>
      <DisbursementProjectionEvolution data={disbursementsProjectionsEvolution} typeHeader={false} />
      <EarlyWarningHistory />
    </>
  ) : (
    <>
      {isLoading ? (
        <div className="portfolio-detail-content__loading__container">
          <ProjectsListSkeleton />
        </div>
      ) : (<div
        className={
          disbursementsProjectionsEvolution.length !== 0 && dataEvolutionEmpty
            ? 'have-disbursement'
            : 'not-have-disbursement'
        }
      >
        {dataEvolutionEmpty && (
          <DisbursementProjectionEvolution data={disbursementsProjectionsEvolution} typeHeader={false} />
        )}
        <EarlyWarningHistory />
      </div>)}

    </>

  );
};

export default ExpandedComponent;
