/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AppMenuTabs } from '../../MenuTabs/userTabsInterface';
import { CalledFrom } from '../../../models/CalledFromEnum';
import { buildInitialStates, buildSelectedTab, handleTeamLeaderChange } from './utils';
import Select from 'react-select';
import { DropdownIndicator, multiSelectStyle } from '../FilterComponent.Styles';
import { MultiSearchUser } from '../../Util/MultiSearchUser/MultiSearchUser';
import { RolesIdEnum } from '../../../models/RolesIdEnum';
import useFilters from '../../../hooks/useFilters';
import { TagFilterContext } from '../../../context/TagFilterContext';
import {
  getCustomTabDefaultValueFromProps,
  getDivisionFilterOptions,
  getPortfolioOrProjectSelectedTab,
} from '../UtilFunctions';

type Props = {
  calledFrom: CalledFrom;
  isEdit?: boolean;
};
const TeamLeaderFilter = (props: Props) => {
  const { filterIndicators, filter, filterEarlyWarnings, setFilter, setFilterIndicators, setFilterEarlyWarnings } =
    useFilters();
  const { calledFrom, isEdit } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filterProjects = useSelector<any, any>(state => state.filter);
  const divisionsFromRedux = useSelector<any, any>(state => state.divisions);
  const [teamLeaderSelected, setTeamLeaderSelected] = useState<any>([]);
  const menuTabsState = useSelector<any, AppMenuTabs>(state => state.menuTabs);
  const selectedTab = buildSelectedTab(calledFrom, menuTabsState);
 
  //
  const { setTagOptions } = useContext(TagFilterContext);

  const getDefaultValue = () => {
    const participatingDivisionOptionsAux = getDivisionFilterOptions(divisionsFromRedux);

    switch (calledFrom) {
      case CalledFrom.PORTFOLIO:
        return filterIndicators.common.teamLeaders;
      case CalledFrom.PROJECT:
        return filterProjects.common.teamLeaders;
      case CalledFrom.CUSTOM_TABS_PORTFOLIO:
      case CalledFrom.CUSTOM_TABS_PROJECT:
        return isEdit
          ? getCustomTabDefaultValueFromProps(selectedTab, 'teamLeaders', participatingDivisionOptionsAux)
          : null;
      case CalledFrom.EARLY_WARNINGS:
        return filterEarlyWarnings.common.teamLeaders.filter(division => division.selectedByUser);
      default:
        return null;
    }
  };
  
  const getValuesSelected = getDefaultValue();

  useEffect(() => {
    const teamLeaderSelectedAux = buildInitialStates(calledFrom, menuTabsState, {
      filterIndicators,
      filter,
      filterEarlyWarnings,
    });
    setTeamLeaderSelected(teamLeaderSelectedAux);
  }, []);

  useEffect(() => {
    
    setTagOptions("teamLeader", getValuesSelected)
   
  }, [getValuesSelected, setTagOptions])

  return (
    <div className="filter-wrapper">
      <h4 className="paragraph-x-small filter-wrapper__label">{t('team_leader')}</h4>
      {teamLeaderSelected.length && selectedTab.tabId !== 0 ? (
        <Select
          id="teamLeadersSelect"
          components={{ DropdownIndicator }}
          name="teamLeaders"
          options={teamLeaderSelected}
          className="basic-multi-select"
          classNamePrefix="All"
          placeholder={t('all')}
          onChange={value =>
            handleTeamLeaderChange(
              value,
              calledFrom,
              dispatch,
              { filterIndicators, filter, filterEarlyWarnings },
              { setFilterIndicators, setFilter, setFilterEarlyWarnings },
            )
          }
          styles={multiSelectStyle}
          isMulti
        />
      ) : (
        <MultiSearchUser
          countryFiltered={false}
          returnUsersSelected={user => {
            handleTeamLeaderChange(
              user,
              calledFrom,
              dispatch,
              { filterIndicators, filter, filterEarlyWarnings },
              { setFilterIndicators, setFilter, setFilterEarlyWarnings },
            );
          }}
          filteredRoles={[RolesIdEnum.Team_Leader]}
          clearStyle
          delegatedUsers={teamLeaderSelected}
        />
      )}
    </div>
  );
};

export default TeamLeaderFilter;
