import {
  MenuTabsActionTypes,
  SET_MENU_TABS,
  UPDATE_CREATION_EDITION_VALUE,
  UPDATE_MANAGERIAL_REVIEW_SELECTED_TAB,
  UPDATE_MENUTABS_FIRST_LOAD,
  UPDATE_PORTFOLIO_SELECTED_TAB,
  UPDATE_PROJECT_SELECTED_TAB,
  UPDATE_EARLY_WARNINGS_SELECTED_TAB,
} from '../actions/types';
import { AppMenuTabs, BEMenuTabs, BEMenuTabsResponse, MenuTabs } from './../../components/MenuTabs/userTabsInterface';
import { cloneDeep } from 'lodash';

const initialState: AppMenuTabs = {
  userTabs: [],
  portfolioSelectedTab: {} as MenuTabs,
  projectSelectedTab: {} as MenuTabs,
  earlyWarningsSelectedTab: {} as MenuTabs,
  managerialReviewSelectedTab: {} as MenuTabs,
  creatingOrEditing: false,
  firstLoad: true,
};

const BID_GLOBAL_OBJECT: BEMenuTabs = {
  tabId: 0,
  title: 'global_bid',
  filters: null,
  regions: null,
  assignedToMe: false,
  default: false,
  coReponsibleDivisions: [],
  participantDivisions: [],
  interestIssues: [],
  teamLeaders: [],
  inFollow: false,
};

const menuTabsReducer = (state = initialState, action: MenuTabsActionTypes): AppMenuTabs | MenuTabs => {
  switch (action.type) {
    case SET_MENU_TABS:
      return setMenuTabProcess(action.payload);

    case UPDATE_PORTFOLIO_SELECTED_TAB:
      return { ...state, portfolioSelectedTab: action.payload };

    case UPDATE_PROJECT_SELECTED_TAB:
      return { ...state, projectSelectedTab: action.payload };

    case UPDATE_EARLY_WARNINGS_SELECTED_TAB:
      return { ...state, earlyWarningsSelectedTab: action.payload };

    case UPDATE_MANAGERIAL_REVIEW_SELECTED_TAB:
      return { ...state, managerialReviewSelectedTab: action.payload };

    case UPDATE_CREATION_EDITION_VALUE:
      return {
        ...state,
        creatingOrEditing: action.payload,
      };
    case UPDATE_MENUTABS_FIRST_LOAD:
      return {
        ...state,
        firstLoad: action.payload,
      };
    default:
      return state;
  }
};
export default menuTabsReducer;

const setMenuTabProcess = (payload: BEMenuTabsResponse): AppMenuTabs => {
  const payloadWithGlobalBid = addBidGlobalObject(payload);
  const setInternalIds = handleSetInternalIds(payloadWithGlobalBid);
  const checkAndHandleIfExistDefaultValue = handleBidGlobalDefaultValue(setInternalIds);
  return createAppMenuTabsObject(checkAndHandleIfExistDefaultValue);
};

const addBidGlobalObject = (payload: BEMenuTabsResponse): BEMenuTabs[] => {
  let returnedMenuTabs: BEMenuTabs[];
  const appMenuTabs: BEMenuTabs[] = cloneDeep(payload.userTabs);
  const reversedTabs = [...appMenuTabs].reverse();
  const bidGlobalIndex = reversedTabs.findIndex(p => p.tabId === 0);
  if (bidGlobalIndex !== -1) {
    reversedTabs.splice(bidGlobalIndex, 0, BID_GLOBAL_OBJECT);
    returnedMenuTabs = [...reversedTabs].reverse();
  } else {
    appMenuTabs.unshift(BID_GLOBAL_OBJECT);
    returnedMenuTabs = appMenuTabs;
  }
  return returnedMenuTabs;
};

const handleDefaultValueToNumbersArrays = (tab: BEMenuTabs, propToCheck: string): [] => {
  return tab[propToCheck] !== null ? tab[propToCheck] : [];
};

const handleSetInternalIds = (payloadWithGlobalBid: BEMenuTabs[]): MenuTabs[] => {
  return payloadWithGlobalBid.map((tab: BEMenuTabs) => ({
    ...tab,
    internalId: `${tab.tabId}_${tab.title.replace(/\s/g, '_')}`,
    regions: handleDefaultValueToNumbersArrays(tab, 'regions'),
    filters: handleDefaultValueToNumbersArrays(tab, 'filters'),
    coReponsibleDivisions: handleDefaultValueToNumbersArrays(tab, 'coReponsibleDivisions'),
    participantDivisions: handleDefaultValueToNumbersArrays(tab, 'participantDivisions'),
    interestIssues: handleDefaultValueToNumbersArrays(tab, 'interestIssues'),
    teamLeaders: handleDefaultValueToNumbersArrays(tab, 'teamLeaders'),
  }));
};

const handleBidGlobalDefaultValue = (payload: MenuTabs[]): MenuTabs[] => {
  const appMenuTabs: MenuTabs[] = cloneDeep(payload);
  const haveSettedDefaultValue = appMenuTabs.find(p => p.default);
  if (haveSettedDefaultValue === undefined) {
    const indexValue = appMenuTabs.findIndex(p => p.internalId === '0_global_bid');
    appMenuTabs[indexValue].default = true;
  }
  return appMenuTabs;
};

const managerialReviewAllowedTabs = (payloadReadyToAppMenuTabs: MenuTabs[]) => {
  const allowedTabs = payloadReadyToAppMenuTabs.filter((tab: MenuTabs) => tab.tabId === 0 && !tab.assignedToMe);
  return allowedTabs[0];
};

const createAppMenuTabsObject = (payloadReadyToAppMenuTabs: MenuTabs[]): AppMenuTabs => {
  const contingencyDefault: MenuTabs = {
    ...BID_GLOBAL_OBJECT,
    internalId: '0_global_bid',
    filters: [],
    regions: [],
    coReponsibleDivisions: [],
    participantDivisions: [],
    interestIssues: [],
    teamLeaders: [],
    inFollow: false,
  };
  const defaultSelected = payloadReadyToAppMenuTabs.find(p => p.default) || contingencyDefault;
  const managerialReviewDefaultSelected = managerialReviewAllowedTabs(payloadReadyToAppMenuTabs) || contingencyDefault;
  return {
    userTabs: payloadReadyToAppMenuTabs,
    portfolioSelectedTab: defaultSelected,
    projectSelectedTab: defaultSelected,
    earlyWarningsSelectedTab: defaultSelected,
    managerialReviewSelectedTab: managerialReviewDefaultSelected,
    creatingOrEditing: false,
    firstLoad: true,
  };
};
