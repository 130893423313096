import { PmrClassification } from '../../../../../../models/PmrClassification';
import { ProjectProductTypeFilters } from '../../../../../../models/ProjectProductTypeFilters';
import { handleCheckChanges } from '../../../../../Filter/ProjectTypeFilter/UtilFunctions';

export const renderPmrText = pmrClassification => {
  switch (pmrClassification) {
    case PmrClassification.Green:
      return 'satisfactory';
    case PmrClassification.Yellow:
      return 'alert';
    case PmrClassification.Red:
      return 'problem';
    case PmrClassification.Na:
      return 'not_assigned';
    default:
      return 'satisfactory';
  }
};

export const updateFilterToDrawProperties = (actualState, filterToDraw) => {
  let updateFilterToDraw;
  const addPmrDrawSelected = actualState.pmrId.map(pmr => {
    return pmr.selected ? { ...pmr, drawSelected: pmr.selected } : pmr;
  });
  updateFilterToDraw = { ...filterToDraw, common: { ...filterToDraw.common, pmrId: addPmrDrawSelected } };

  const addDivisionDrawSelected = actualState.divisionId.map(division => {
    return { ...division, drawSelected: true, selected: true };
  });

  updateFilterToDraw = {
    ...updateFilterToDraw,
    common: { ...updateFilterToDraw.common, divisionId: addDivisionDrawSelected },
  };

  const addCoReponsibleDivisionDrawSelected = actualState.coReponsibleDivisions.map(division => {
    return { ...division, drawSelected: true, selected: true };
  });

  updateFilterToDraw = {
    ...updateFilterToDraw,
    common: { ...updateFilterToDraw.common, coReponsibleDivisions: addCoReponsibleDivisionDrawSelected },
  };

  const addParticipantDivisionDrawSelected = actualState.participantDivisions.map(division => {
    return { ...division, drawSelected: true, selected: true };
  });

  updateFilterToDraw = {
    ...updateFilterToDraw,
    common: { ...updateFilterToDraw.common, participantDivisions: addParticipantDivisionDrawSelected },
  };

  const addTimeWithoutDisbusementSelectedFrom = actualState.timeWithoutDisbursementFrom;
  const addTimeWithoutDisbusementSelectedTo = actualState.timeWithoutDisbursementTo;
  const timeWithoutDisbursementCheckbox = compareTwoInputs(
    addTimeWithoutDisbusementSelectedFrom,
    addTimeWithoutDisbusementSelectedTo,
    'time_without_performing_disbursements',
    'days',
  );
  updateFilterToDraw = {
    ...updateFilterToDraw,
    common: { ...updateFilterToDraw.common, timeWithoutDisbursementCheckbox },
  };

  const addAgeProjectFrom = actualState.ageProjectFrom;
  const addAgeProjectTo = actualState.ageProjectTo;
  const addAgeProjectMonths = actualState.ageProjectMonths;

  const ageProjectsCheckbox = compareTwoInputs(
    addAgeProjectFrom,
    addAgeProjectTo,
    'project_age',
    addAgeProjectMonths ? 'months' : 'years',
  );

  updateFilterToDraw = {
    ...updateFilterToDraw,
    common: { ...updateFilterToDraw.common, ageProjectsCheckbox },
  };

  const addDisbursementProjectionFrom = actualState.disbursementProjectionFrom;
  const addDisbursementProjectionTo = actualState.disbursementProjectionTo;
  const disbursementProjectionCheckbox = compareTwoInputs(
    addDisbursementProjectionFrom,
    addDisbursementProjectionTo,
    'projection_disbursed',
    'dolar_sign',
  );

  updateFilterToDraw = {
    ...updateFilterToDraw,
    common: { ...updateFilterToDraw.common, disbursementProjectionCheckbox },
  };

  const addRegionsDrawSelected = actualState.regionId.map(region => {
    return { ...region, drawSelected: true, selected: true };
  });

  updateFilterToDraw = {
    ...updateFilterToDraw,
    common: { ...updateFilterToDraw.common, regionId: addRegionsDrawSelected },
  };

  const addCountriesDrawSelected = actualState.countryId.map(country => {
    return { ...country, drawSelected: true, selected: true };
  });

  updateFilterToDraw = {
    ...updateFilterToDraw,
    common: { ...updateFilterToDraw.common, countryId: addCountriesDrawSelected },
  };

  const addTeamLeadersDrawSelected = actualState.teamLeaders.map(tl => {
    return { ...tl, drawSelected: true, selected: true };
  });

  updateFilterToDraw = {
    ...updateFilterToDraw,
    common: { ...updateFilterToDraw.common, teamLeaders: addTeamLeadersDrawSelected },
  };

  const addInterestIssuesDrawSelected = actualState.interestIssues.map(interestIssue => {
    return { ...interestIssue, drawSelected: true, selected: true };
  });

  updateFilterToDraw = {
    ...updateFilterToDraw,
    common: { ...updateFilterToDraw.common, interestIssues: addInterestIssuesDrawSelected },
  };
  if (actualState.executionFilter) {
    const addExecutionFilterDrawSelected = { ...actualState.executionFilter, drawSelected: true, selected: true };

    updateFilterToDraw = {
      ...updateFilterToDraw,
      common: { ...updateFilterToDraw.common, executionFilter: addExecutionFilterDrawSelected },
    };
  }

  if (actualState.taxonomyFilter) {
    const addTaxonomyFilterDrawSelected = { ...actualState.taxonomyFilter, drawSelected: true, selected: true };

    updateFilterToDraw = {
      ...updateFilterToDraw,
      common: { ...updateFilterToDraw.common, taxonomyFilter: addTaxonomyFilterDrawSelected },
    };
  }

  //TODO este hay que revisarlo de acuerdo a los cambios en dicho integracion y filtro
  const excludeRegionalsCheckbox = actualState.excludeRegionals
    ? {
        drawSelected: true,
        selected: true,
        label: 'exclude_regional',
      }
    : {};

  updateFilterToDraw = {
    ...updateFilterToDraw,
    common: { ...updateFilterToDraw.common, excludeRegionalsCheckbox },
  };
  return updateFilterToDraw;
};

export const compareTwoInputs = (from, to, initialLabel, endLabel) => {
  let returnedObject = {
    drawSelected: false,
    selected: false,
    label: `none`,
  };
  if ((from !== 0 || to !== 0) && from !== to) {
    if (from !== 0 && to !== 0) {
      returnedObject = {
        drawSelected: true,
        selected: true,
        label: `${initialLabel} : between ${from} and ${to} ${endLabel}`,
      };
    } else {
      if (from > to) {
        returnedObject = {
          drawSelected: true,
          selected: true,
          label: `${initialLabel} : greater_than ${from} ${endLabel}`,
        };
      } else {
        returnedObject = {
          drawSelected: true,
          selected: true,
          label: `${initialLabel} : less_than ${to} ${endLabel}`,
        };
      }
    }
  }
  return returnedObject;
};

export const isJustXProjectTypeFilterSelected = (filter, xProjectProductTypeFilters) => {
  return (
    filter.common.projectTypeFilters.length === 1 && filter.common.projectTypeFilters[0] === xProjectProductTypeFilters
  );
};

export const isSelectedProjectTypeFilter = (
  checkboxData,
  filterToReturn,
  filterToDraw,
  setFilterToReturn,
  setFilterToDraw,
) => {
  const { projectProductType } = checkboxData;
  const updateFilterToDraw = [...filterToDraw.common.projectTypeFilters];
  const setterFunction = (newFilters, filterState) => {
    if (newFilters.length !== filterState.length) {
      setFilterToDraw({
        ...filterToDraw,
        common: {
          ...filterToDraw.common,
          projectTypeFilters: newFilters,
        },
      });
      setFilterToReturn({
        ...filterToReturn,
        common: {
          ...filterToReturn.common,
          projectTypeFilters: newFilters,
        },
      });
    }
  };
  handleCheckChanges(updateFilterToDraw, projectProductType, setterFunction);
};

export const isSelectedPmr = (
  filterToReturn,
  filterToDraw,
  setFilterToReturn,
  returnedReportFilter,
  setFilterToDraw,
  pmrClassificationValue,
) => {
  const actualState = filterToReturn.common.pmrId;
  const actualStateDraw = filterToDraw.common.pmrId;
  const foundItem = actualState.findIndex(x => x.PmrClassification === pmrClassificationValue);
  actualState[foundItem] = { PmrClassification: pmrClassificationValue, selected: !actualState[foundItem].selected };
  const updatedFilterToReturn = { ...filterToReturn, common: { ...filterToReturn.common, pmrId: actualState } };
  setFilterToReturn(updatedFilterToReturn);
  returnedReportFilter(filterToReturn);

  const foundItemToDraw = actualStateDraw.findIndex(x => x.PmrClassification === pmrClassificationValue);
  actualStateDraw[foundItemToDraw] = {
    PmrClassification: pmrClassificationValue,
    selected: !actualStateDraw[foundItemToDraw].selected,
    drawSelected: !actualStateDraw[foundItemToDraw].selected,
  };

  const updatedFilterToDraw = { ...filterToDraw, common: { ...filterToDraw.common, pmrId: actualStateDraw } };
  setFilterToDraw(updatedFilterToDraw);
};

export const isSelectedDivision = (filterToReturn, filterToDraw, setFilterToReturn, setFilterToDraw, divisionId) => {
  const actualStateDraw = filterToDraw.common.divisionId;

  const foundIndexToDraw = actualStateDraw.findIndex(x => x.id === divisionId);
  const foundItemToDraw = actualStateDraw.find(x => x.id === divisionId);
  actualStateDraw[foundIndexToDraw] = {
    ...foundItemToDraw,
    selected: !foundItemToDraw.selected,
  };
  const updatedFilterToDraw = { ...filterToDraw, common: { ...filterToDraw.common, divisionId: actualStateDraw } };
  setFilterToDraw(updatedFilterToDraw);

  const returnedState: any = [];
  filterToDraw.common.divisionId.map(division => {
    if (division.selected) {
      const objectToAdd = { id: division.id, label: division.label, value: division.value };
      returnedState.push(objectToAdd);
    }

    return returnedState;
  });

  const updatedFilterToReturn = {
    ...filterToReturn,
    common: { ...filterToReturn.common, divisionId: returnedState },
  };
  setFilterToReturn(updatedFilterToReturn);
};
export const isSelectedParticipantDivision = (
  filterToReturn,
  filterToDraw,
  setFilterToReturn,
  setFilterToDraw,
  divisionId,
) => {
  const actualStateDraw = filterToDraw.common.participantDivisions;

  const foundIndexToDraw = actualStateDraw.findIndex(x => x.id === divisionId);
  const foundItemToDraw = actualStateDraw.find(x => x.id === divisionId);
  actualStateDraw[foundIndexToDraw] = {
    ...foundItemToDraw,
    selected: !foundItemToDraw.selected,
  };
  const updatedFilterToDraw = {
    ...filterToDraw,
    common: { ...filterToDraw.common, participantDivisions: actualStateDraw },
  };
  setFilterToDraw(updatedFilterToDraw);

  const returnedState: any = [];
  filterToDraw.common.participantDivisions.map(participantDivision => {
    if (participantDivision.selected) {
      const objectToAdd = {
        id: participantDivision.id,
        label: participantDivision.label,
        value: participantDivision.value,
      };
      returnedState.push(objectToAdd);
    }

    return returnedState;
  });

  const updatedFilterToReturn = {
    ...filterToReturn,
    common: { ...filterToReturn.common, participantDivisions: returnedState },
  };
  setFilterToReturn(updatedFilterToReturn);
};
export const isSelectedCoResponsibleDivision = (
  filterToReturn,
  filterToDraw,
  setFilterToReturn,
  setFilterToDraw,
  divisionId,
) => {
  const actualStateDraw = filterToDraw.common.coReponsibleDivisions;

  const foundIndexToDraw = actualStateDraw.findIndex(x => x.id === divisionId);
  const foundItemToDraw = actualStateDraw.find(x => x.id === divisionId);
  actualStateDraw[foundIndexToDraw] = {
    ...foundItemToDraw,
    selected: !foundItemToDraw.selected,
  };
  const updatedFilterToDraw = {
    ...filterToDraw,
    common: { ...filterToDraw.common, coReponsibleDivisions: actualStateDraw },
  };
  setFilterToDraw(updatedFilterToDraw);

  const returnedState: any = [];
  filterToDraw.common.coReponsibleDivisions.map(coReponsibleDivision => {
    if (coReponsibleDivision.selected) {
      const objectToAdd = {
        id: coReponsibleDivision.id,
        label: coReponsibleDivision.label,
        value: coReponsibleDivision.value,
      };
      returnedState.push(objectToAdd);
    }

    return returnedState;
  });

  const updatedFilterToReturn = {
    ...filterToReturn,
    common: { ...filterToReturn.common, coReponsibleDivisions: returnedState },
  };
  setFilterToReturn(updatedFilterToReturn);
};

export const isSelectedRegion = (filterToReturn, filterToDraw, setFilterToReturn, setFilterToDraw, regionId) => {
  const actualStateDraw = filterToDraw.common.regionId;

  const foundIndexToDraw = actualStateDraw.findIndex(x => x.id === regionId);
  const foundItemToDraw = actualStateDraw.find(x => x.id === regionId);
  actualStateDraw[foundIndexToDraw] = {
    ...foundItemToDraw,
    selected: !foundItemToDraw.selected,
  };
  const updatedFilterToDraw = { ...filterToDraw, common: { ...filterToDraw.common, regionId: actualStateDraw } };

  setFilterToDraw(updatedFilterToDraw);

  const returnedState: any = [];
  filterToDraw.common.regionId.map(region => {
    if (region.selected) {
      const objectToAdd = { id: region.id, label: region.label, value: region.value };
      returnedState.push(objectToAdd);
    }

    return returnedState;
  });

  const updatedFilterToReturn = {
    ...filterToReturn,
    common: { ...filterToReturn.common, regionId: returnedState },
  };
  setFilterToReturn(updatedFilterToReturn);
};

export const isSelectedCountry = (filterToReturn, filterToDraw, setFilterToReturn, setFilterToDraw, countryId) => {
  const actualStateDraw = filterToDraw.common.countryId;

  const foundIndexToDraw = actualStateDraw.findIndex(x => x.id === countryId);
  const foundItemToDraw = actualStateDraw.find(x => x.id === countryId);
  actualStateDraw[foundIndexToDraw] = {
    ...foundItemToDraw,
    selected: !foundItemToDraw.selected,
  };
  const updatedFilterToDraw = { ...filterToDraw, common: { ...filterToDraw.common, countryId: actualStateDraw } };

  setFilterToDraw(updatedFilterToDraw);

  const returnedState: any = [];
  filterToDraw.common.countryId.map(country => {
    if (country.selected) {
      const objectToAdd = {
        id: country.id,
        label: country.label,
        value: country.value,
        categoryId: country.categoryId,
      };
      returnedState.push(objectToAdd);
    }

    return returnedState;
  });

  const updatedFilterToReturn = {
    ...filterToReturn,
    common: { ...filterToReturn.common, countryId: returnedState },
  };
  setFilterToReturn(updatedFilterToReturn);
};

export const isSelectedTimeWithoutDisbursement = (
  filterToReturn,
  filterToDraw,
  setFilterToReturn,
  setFilterToDraw,
  isChecked,
) => {
  const actualStateDraw = { ...filterToDraw.common.timeWithoutDisbursementCheckbox, selected: isChecked };
  const updatedFilterToDraw = {
    ...filterToDraw,
    common: { ...filterToDraw.common, timeWithoutDisbursementCheckbox: actualStateDraw },
  };
  setFilterToDraw(updatedFilterToDraw);

  if (filterToDraw.common.timeWithoutDisbursementCheckbox.selected) {
    delete filterToReturn.common.timeWithoutDisbursementFrom;
    delete filterToReturn.common.timeWithoutDisbursementTo;
  } else {
    filterToReturn.common.timeWithoutDisbursementFrom = filterToDraw.common.timeWithoutDisbursementFrom;
    filterToReturn.common.timeWithoutDisbursementTo = filterToDraw.common.timeWithoutDisbursementTo;
  }
  const updatedFilterToReturn = {
    ...filterToReturn,
  };
  setFilterToReturn(updatedFilterToReturn);
};

export const isSelectedDisbursementProjection = (
  filterToReturn,
  filterToDraw,
  setFilterToReturn,
  setFilterToDraw,
  isChecked,
) => {
  const actualStateDraw = { ...filterToDraw.common.disbursementProjectionCheckbox, selected: isChecked };
  const updatedFilterToDraw = {
    ...filterToDraw,
    common: { ...filterToDraw.common, disbursementProjectionCheckbox: actualStateDraw },
  };
  setFilterToDraw(updatedFilterToDraw);

  if (filterToDraw.common.disbursementProjectionCheckbox.selected) {
    delete filterToReturn.common.disbursementProjectionFrom;
    delete filterToReturn.common.disbursementProjectionTo;
  } else {
    filterToReturn.common.disbursementProjectionFrom = filterToDraw.common.disbursementProjectionFrom;
    filterToReturn.common.disbursementProjectionTo = filterToDraw.common.disbursementProjectionTo;
  }
  const updatedFilterToReturn = {
    ...filterToReturn,
  };
  setFilterToReturn(updatedFilterToReturn);
};

export const isSelectedAgeProject = (filterToReturn, filterToDraw, setFilterToReturn, setFilterToDraw, isChecked) => {
  const actualStateDraw = { ...filterToDraw.common.ageProjectsCheckbox, selected: isChecked };
  const updatedFilterToDraw = {
    ...filterToDraw,
    common: { ...filterToDraw.common, ageProjectsCheckbox: actualStateDraw },
  };
  setFilterToDraw(updatedFilterToDraw);
  if (filterToDraw.common.ageProjectsCheckbox.selected) {
    delete filterToReturn.common.ageProjectFrom;
    delete filterToReturn.common.ageProjectTo;
    delete filterToReturn.common.ageProjectMonths;
  } else {
    filterToReturn.common.ageProjectFrom = filterToDraw.common.ageProjectFrom;
    filterToReturn.common.ageProjectTo = filterToDraw.common.ageProjectTo;
    filterToReturn.common.ageProjectMonths = filterToDraw.common.ageProjectMonths;
  }
  const updatedFilterToReturn = {
    ...filterToReturn,
  };
  setFilterToReturn(updatedFilterToReturn);
};

export const isSelectedTeamLeaders = (
  filterToReturn,
  filterToDraw,
  setFilterToReturn,
  setFilterToDraw,
  teamLeaderId,
) => {
  const actualStateDraw = filterToDraw.common.teamLeaders;

  const foundIndexToDraw = actualStateDraw.findIndex(x => x.id === teamLeaderId);
  const foundItemToDraw = actualStateDraw.find(x => x.id === teamLeaderId);
  actualStateDraw[foundIndexToDraw] = {
    ...foundItemToDraw,
    selected: !foundItemToDraw.selected,
  };
  const updatedFilterToDraw = {
    ...filterToDraw,
    common: { ...filterToDraw.common, teamLeaders: actualStateDraw },
  };
  setFilterToDraw(updatedFilterToDraw);

  const returnedState: any = [];
  filterToDraw.common.teamLeaders.map(tl => {
    if (tl.selected) {
      const objectToAdd = { id: tl.id, label: tl.label, value: tl.value };
      returnedState.push(objectToAdd);
    }

    return returnedState;
  });

  const updatedFilterToReturn = {
    ...filterToReturn,
    common: { ...filterToReturn.common, teamLeaders: returnedState },
  };
  setFilterToReturn(updatedFilterToReturn);
};

export const isSelectedInterestIssues = (
  filterToReturn,
  filterToDraw,
  setFilterToReturn,
  setFilterToDraw,
  interestIssueId,
) => {
  const actualStateDraw = filterToDraw.common.interestIssues;

  const foundIndexToDraw = actualStateDraw.findIndex(x => x.id === interestIssueId);
  const foundItemToDraw = actualStateDraw.find(x => x.id === interestIssueId);
  actualStateDraw[foundIndexToDraw] = {
    ...foundItemToDraw,
    selected: !foundItemToDraw.selected,
  };
  const updatedFilterToDraw = {
    ...filterToDraw,
    common: { ...filterToDraw.common, interestIssues: actualStateDraw },
  };
  setFilterToDraw(updatedFilterToDraw);

  const returnedState: any = [];
  filterToDraw.common.interestIssues.map(interestIssue => {
    if (interestIssue.selected) {
      const objectToAdd = { id: interestIssue.id, label: interestIssue.label, value: interestIssue.value };
      returnedState.push(objectToAdd);
    }

    return returnedState;
  });

  const updatedFilterToReturn = {
    ...filterToReturn,
    common: { ...filterToReturn.common, interestIssues: returnedState },
  };
  setFilterToReturn(updatedFilterToReturn);
};

export const isSelectedTaxonomyFilter = (
  filterToReturn,
  filterToDraw,
  setFilterToReturn,
  setFilterToDraw,
  isChecked,
) => {
  const actualStateDraw = { ...filterToDraw.common.taxonomyFilter, selected: isChecked };
  const updatedFilterToDraw = {
    ...filterToDraw,
    common: { ...filterToDraw.common, taxonomyFilter: actualStateDraw },
  };
  setFilterToDraw(updatedFilterToDraw);

  if (filterToDraw.common.taxonomyFilter.selected) {
    delete filterToReturn.common.taxonomyFilter;
  } else {
    delete filterToDraw.common.taxonomyFilter.selected;
    delete filterToDraw.common.taxonomyFilter.drawSelected;
    filterToReturn.common.taxonomyFilter = filterToDraw.common.taxonomyFilter;
  }
  const updatedFilterToReturn = {
    ...filterToReturn,
  };
  setFilterToReturn(updatedFilterToReturn);
};

export const isSelectedExecutionFilter = (
  filterToReturn,
  filterToDraw,
  setFilterToReturn,
  setFilterToDraw,
  isChecked,
) => {
  const actualStateDraw = { ...filterToDraw.common.executionFilter, selected: isChecked };
  const updatedFilterToDraw = {
    ...filterToDraw,
    common: { ...filterToDraw.common, executionFilter: actualStateDraw },
  };
  setFilterToDraw(updatedFilterToDraw);

  if (filterToDraw.common.executionFilter.selected) {
    delete filterToReturn.common.executionFilter;
  } else {
    delete filterToDraw.common.executionFilter.selected;
    delete filterToDraw.common.executionFilter.drawSelected;
    filterToReturn.common.executionFilter = filterToDraw.common.executionFilter;
  }
  const updatedFilterToReturn = {
    ...filterToReturn,
  };
  setFilterToReturn(updatedFilterToReturn);
};

export const isSelectedExcludeRegionals = (
  filterToReturn,
  filterToDraw,
  setFilterToReturn,
  setFilterToDraw,
  isChecked,
) => {
  const actualStateDraw = { ...filterToDraw.common.excludeRegionalsCheckbox, selected: isChecked };
  const updatedFilterToDraw = {
    ...filterToDraw,
    common: { ...filterToDraw.common, excludeRegionalsCheckbox: actualStateDraw },
  };
  setFilterToDraw(updatedFilterToDraw);

  if (filterToDraw.common.excludeRegionalsCheckbox.selected) {
    delete filterToReturn.common.excludeRegionals;
  } else {
    filterToReturn.common.excludeRegionals = filterToDraw.common.excludeRegionals;
  }
  const updatedFilterToReturn = {
    ...filterToReturn,
  };
  setFilterToReturn(updatedFilterToReturn);
};

export const workWithLoanSpecificFilters = (filterToReturn, filterToDraw, setFilterToReturn) => {
  const justLoanProjectTypeFilterSelected = isJustXProjectTypeFilterSelected(
    filterToReturn,
    ProjectProductTypeFilters.Loan,
  );
  let updatedPmrIdOnFilterToReturn: Array<{ PmrClassification: PmrClassification; selected: boolean }>[] = [];
  let updateDisbursementProjectionFrom;
  let updateDisbursementProjectionTo;
  if (!justLoanProjectTypeFilterSelected) {
    const pmrIdOnFilterToReturn = [...filterToReturn.common.pmrId];
    const pmrIdOnFilterToDraw = [...filterToDraw.common.pmrId];

    updatedPmrIdOnFilterToReturn = pmrIdOnFilterToReturn.map(pmrObject => {
      if (pmrObject.selected && pmrIdOnFilterToDraw[pmrObject.PmrClassification].drawSelected) {
        pmrObject.selected = false;
      }
      return pmrObject;
    });
    updateDisbursementProjectionFrom = 0;
    updateDisbursementProjectionTo = 0;
  } else {
    const pmrIdOnFilterToReturn = [...filterToReturn.common.pmrId];
    const pmrIdOnFilterToDraw = [...filterToDraw.common.pmrId];

    updatedPmrIdOnFilterToReturn = pmrIdOnFilterToReturn.map(pmrObject => {
      if (!pmrObject.selected && pmrIdOnFilterToDraw[pmrObject.PmrClassification].drawSelected) {
        pmrObject.selected = true;
      }
      return pmrObject;
    });
    updateDisbursementProjectionFrom = filterToDraw.common.disbursementProjectionFrom;
    updateDisbursementProjectionTo = filterToDraw.common.disbursementProjectionTo;
  }

  setFilterToReturn({
    ...filterToReturn,
    common: {
      ...filterToReturn.common,
      pmrId: updatedPmrIdOnFilterToReturn,
      disbursementProjectionFrom: updateDisbursementProjectionFrom,
      disbursementProjectionTo: updateDisbursementProjectionTo,
    },
  });
};

export const workWithCTSpecificFilters = (filterToReturn, filterToDraw, setFilterToReturn) => {
  const justCTProjectTypeFilterSelected = isJustXProjectTypeFilterSelected(
    filterToReturn,
    ProjectProductTypeFilters.CT,
  );
  if (!justCTProjectTypeFilterSelected) {
    const taxonomyFilterToDraw = { ...filterToDraw.common.taxonomyFilter };
    const executionFilterToDraw = { ...filterToDraw.common.executionFilter };

    if (taxonomyFilterToDraw.selected && taxonomyFilterToDraw.drawSelected) {
      delete filterToReturn.common.taxonomyFilter;
    }

    if (executionFilterToDraw.selected && executionFilterToDraw.drawSelected) {
      delete filterToReturn.common.executionFilter;
    }

    setFilterToReturn({
      ...filterToReturn,
    });
  } else {
    const taxonomyFilterToDraw = { ...filterToDraw.common.taxonomyFilter };
    const executionFilterToDraw = { ...filterToDraw.common.executionFilter };
    const updatedCommon = { ...filterToReturn.common };

    if (taxonomyFilterToDraw.selected) {
      delete taxonomyFilterToDraw.drawSelected;
      delete taxonomyFilterToDraw.selected;
      updatedCommon.taxonomyFilter = taxonomyFilterToDraw;
    }

    if (executionFilterToDraw.selected) {
      delete executionFilterToDraw.drawSelected;
      delete executionFilterToDraw.selected;
      updatedCommon.executionFilter = executionFilterToDraw;
    }

    setFilterToReturn({
      ...filterToReturn,
      common: {
        ...filterToReturn.common,
        updatedCommon,
      },
    });
  }
};
