import React from 'react';
import { useTranslation } from 'react-i18next';
import './RequiredErrorMessage.scss';

const RequiredErrorMessage = () => {
  const { t } = useTranslation();
  return (
    <div className="required-error-message-container">
      <span className="error-icon"></span>
      <span className="message">{t('required_data')}</span>
    </div>
  );
};

export default RequiredErrorMessage;
