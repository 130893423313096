import { withStyles } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { isMobile } from '../../../../../services/booleanUtil';

export const AccordionDetails = withStyles(theme => ({
  root: {
    padding: 0,
  },
}))(MuiAccordionDetails);

export const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    padding: '0',
    border: 'none',
    '&$expanded': {
      margin: '0 0 0 0',
    },
    '&::before': {
      content: '',
      opacity: 0,
    },
  },
  expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    padding: 0,
    '&$focused': {
      backgroundColor: '#FFF',
    },
  },
  content: { margin: 0 },
  expanded: {},
  expandIcon: {
    position: 'absolute',
    left: 0,
    top: isMobile() ? 10 : 'unset',
  },
  focused: {
    backgroundColor: '#FFF',
  },
})(MuiAccordionSummary);
