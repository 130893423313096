import React, { useState, useEffect } from 'react';
import styles from './ExecutionByComponent.module.scss';
import '../Financial.scss';
import { useTranslation } from 'react-i18next';
import { ExecComponentTooltip } from '../../../../Util/Tooltip/Tooltip';
import ComponentBalances from '../../../../../models/ComponentBalances';
import {
  customChartHeight,
  barIndicatorWidth,
  addProgressAndWidthToData,
  withoutDataIndicators,
  maxWidthPixels,
  labelWidth,
} from './util';
import { capitalizeFirstLetter } from '../../../../../services/stringUtil';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import { Line } from 'rc-progress';
import { ProjectsListSkeleton } from '../../../../LoadersSkeletons/ProjectsListSkeleton/ProjectsListSkeleton';
import { useDispatch } from 'react-redux';

const BlackTooltip = withStyles({
  tooltip: {
    //   color: '$ci-color-greyscale-90',
    backgroundColor: '#101319',
    fontSize: '16px',
    fontFamily: 'Rubik',
    lineHeight: '20px',
    padding: '8px 12px',
    borderRadius: '8px',
  },
  arrow: {
    fontSize: 20,
    color: '#4A4A4A',
    '&::before': {
      backgroundColor: '#101319',
    },
  },
})(Tooltip);

const maxLengthName = 15;
const lowestMaxValue = 2;

const labelStyleForReport = idForReport => (idForReport ? { width: 250 } : { width: labelWidth });

type Props = {
  data: ComponentBalances[];
  idForReport?: string;
};

const ExecutionByComponent = ({ data, idForReport }: Props) => {
  const [formatedData, setFormatedData] = useState<any | null>(null);
  const [isLoading, setIsLoading] = React.useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    setFormatedData(addProgressAndWidthToData(data, idForReport));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, idForReport]);


  useEffect(() => {
    if (data.length > 0) {
      setIsLoading(false);
    }
  }, [data]);

  const {
    container,
    itemContainer,
    label,
    stripes,
    stripe,
    stripeLast,
    stripeValue,
    stripeValueUsd,
    disbursedProgress,
    pendingProgress,
    disbursedIcon,
    pendingIcon,
    footerText,
    footer,
    mms,
    usd,
    emptyContainer,
    bigContainer,
    hideTextForReport,
    infoContainer,
  } = styles;

  return (
    <div className={bigContainer} id={idForReport} style={idForReport ? { backgroundColor: 'white', width: 800 } : {}}>
      <span className={idForReport ? hideTextForReport : 'title'}>{t('exectution_by_component')}</span>

      {!isLoading && data.length > 0 ?
        <div className={container} style={{ height: customChartHeight(data) }}>
          {formatedData?.list.map(item => (
            <div className={itemContainer} key={`execution_component_${item.id}`}>
              {item.name.length > maxLengthName ? (
                <BlackTooltip
                  title={capitalizeFirstLetter(item.name.toLowerCase())}
                  arrow
                  enterDelay={500}
                  leaveDelay={200}
                >
                  <h5 className={label}>
                    {capitalizeFirstLetter(item.name.toLowerCase())}
                  </h5>
                </BlackTooltip>
              ) : (
                <h5 className={label}>
                  {capitalizeFirstLetter(item.name.toLowerCase())}
                </h5>
              )}

              {item.percent !== withoutDataIndicators ? (
                <div className={infoContainer}>
                  {/* <div className="line-container">
                    <Line
                      percent={item.percent}
                      strokeWidth={5}
                      trailWidth={5}
                      strokeColor={false ? 'lightblue' : '#538CFF'}
                      strokeLinecap="square"
                      trailColor="#D0D5E0"
                    />
                  </div> */}


                  <div style={{ display: 'flex' }} className='containerExecution-info'>
                    <div className='containerBarsExecution'>
                      <BlackTooltip
                        title={
                          <>
                            {t('disbursed')}: {' '}
                            <NumberFormat value={item.disbursedAmount} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />{' '}
                          </>
                        }
                        enterDelay={500}
                        leaveDelay={200}
                        arrow
                      >
                        <div
                          className={disbursedProgress}
                          style={{
                            width: barIndicatorWidth(item.width, item.percent),
                          }}
                        ></div>
                      </BlackTooltip>

                      <BlackTooltip
                        title={
                          <>
                            {t('pending')}: {' '}
                            <NumberFormat value={item.availableAmount} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />{' '}
                          </>

                        }
                        enterDelay={500}
                        leaveDelay={200}
                        arrow
                      >
                        <div
                          className={pendingProgress}
                          style={{
                            width: item.width - barIndicatorWidth(item.width, item.percent) + 8,
                            marginLeft: item?.percent > 0 ? -8 : 0,
                          }}
                        ></div>
                      </BlackTooltip>
                    </div>
                    <div className="containerDataExecution">
                      <div className='dataExecutionItem-blue'>${<NumberFormat value={item.disbursedAmount} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />}{' '}</div>
                      <div className='dataExecutionItem-gray'>${<NumberFormat value={item.availableAmount} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />}{' '}</div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='empty'>
                  <span className={emptyContainer}>-</span>
                </div>
              )}
            </div>
          ))}
          <div
            className={stripes}
            style={
              idForReport
                ? { height: customChartHeight(data) - 20, width: 410, }
                : { height: customChartHeight(data) + 20, }
            }
          >
            <div className={stripe}>
              <span className={stripeValue}>0</span>
            </div>
            {formatedData?.maxValue < lowestMaxValue ? (
              <div className={stripeLast}>
                <span className={stripeValueUsd}>
                  {(formatedData?.maxValue.toFixed(3) * 1000000).toLocaleString('es')}
                </span>
                <span className={usd}>USD</span>
              </div>
            ) : (
              <div className={stripeLast}>
                <span className={stripeValue}>{formatedData?.maxValue.toFixed(1)}</span>
                <span className={mms}>{t('millions')}</span>
              </div>
            )}
          </div>
          <div className={footer} style={idForReport ? { marginTop: 0 } : {}}>
            <span className={footerText}>
              <span className={disbursedIcon}></span>
              {t('justified')}
            </span>
            <span className={footerText}>
              <span className={pendingIcon}></span>
              {t('pending')}
            </span>
          </div>
        </div>
        :
        <div>
          <span>{t('no_data_available')}</span>
        </div>

      }
    </div>
  );
};

export default ExecutionByComponent;
