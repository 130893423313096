import React from 'react';
import styles from './SelectableProjectsResponsive.module.scss';
import { useTranslation } from 'react-i18next';
import ProjectCard from './ProjectCard';

interface CustomStyle {
  title?: Object;
  container?: Object;
  noData?: Object;
}

type Props = {
  projects: any;
  clickable?: boolean;
  customStyle?: CustomStyle;
  canCreateAccordance?: boolean;
  customNoResultsMessage?: any;
};

const SelectableProjectsResponsive = ({
  projects,
  clickable,
  customStyle,
  canCreateAccordance,
  customNoResultsMessage,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.title} style={customStyle ? customStyle.title : {}}>
        {projects.title}
      </div>
      {projects.list.length ? (
        projects.list.map(proj => {
          return (
            <ProjectCard project={proj} clickable={clickable} key={proj.id} canCreateAccordance={canCreateAccordance} />
          );
        })
      ) : (
        <h5 className={styles.noData}>{customNoResultsMessage || t('no_results')}</h5>
      )}
    </div>
  );
};

export default SelectableProjectsResponsive;
