import React from 'react';
import './TextInput.scss';
import { checkCharacterNumber } from '../../../../../services/util';
import { allowedCharacterCode } from '../../../../../services/booleanUtil';
import ReactDOM from 'react-dom';

type Props = {
  submitValue?: any;
  placeHolder?: any;
  value?: string;
  valueType?: any;
  customStyleInput?: any;
  disabled?: boolean;
  onValueChange: any;
  error?: boolean;
};

const currentInputIsFocused = inputRef => {
  return inputRef && inputRef.current && document.activeElement === ReactDOM.findDOMNode(inputRef.current);
};

const TextInput = ({
  submitValue,
  value,
  placeHolder,
  valueType,
  customStyleInput,
  disabled,
  onValueChange,
  error,
}: Props) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  function handleKeyPress(event: any) {
    if (currentInputIsFocused(inputRef)) {
      if (event.keyCode === 13 && submitValue) {
        submitValue();
      }
      if (valueType === 'number') {
        handleKeypressNumeric(event);
      }
    }
  }

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  });

  const handleKeypressNumeric = e => {
    const characterCode = e.key;

    if (allowedCharacterCode(characterCode)) {
      const characterNumber = Number(characterCode);
      checkCharacterNumber(characterNumber, e);
    } else {
      return;
    }
  };

  return (
    <div className="planning-text-input-container">
      <input
        ref={inputRef}
        className={`text-input ${error ? 'error' : ''}`}
        style={customStyleInput}
        type={valueType ? valueType : 'text'}
        placeholder={placeHolder}
        onChange={e => onValueChange(e.target.value)}
        value={value}
        disabled={disabled}
      ></input>
    </div>
  );
};

export default TextInput;
