import {
  SupervisionPlanItemState,
  SupervisionPlanItemType,
} from '../components/ProjectDetail/ProjectTabs/Supervision/util';
import { AccordanceState } from './AccordanceState';
import { AccordanceType } from './AccordanceType';

export const PROJECT_ASSIGNED_TO_ME = '1';
export const FOLLOWED_PROJECTS = '1';

export enum EarlyWarningButtonType {
  PROJECTS_PENDING_SIGNATURE = 'pendingSignature',
  ELIGIBLE_PROJECTS_PENDING_FIRST_DISBURSEMENT = 'eligibleUnDisbursement',
  PROJECTS_WITH_RECENT_ALERT_OR_PROBLEM_CLASSIFICATIONS = 'pmr',
  PROJECTS_WITH_UNSATISFACTORY_PERFORMANCE_IN_SAFEGUARDS = 'esgSafeguard',
  PROJECTS_WITH_PARTIAL_CANCELLATIONS = 'cancelled',
  PROJECTS_WITH_EXPIRED_DISBURSEMENT_DEADLINE = 'expired',
}
export interface ModuleButton {
  buttonEWType: EarlyWarningButtonType;
  quantity: number;
  singularLabel: string;
  pluralLabel: string;
  selected: boolean;
}

export const baseModuleButton: ModuleButton = {
  buttonEWType: EarlyWarningButtonType.PROJECTS_PENDING_SIGNATURE,
  quantity: 0,
  selected: false,
  singularLabel: '',
  pluralLabel: '',
};

export interface EWProjectResponse {
  id: number;
  code: string;
  title: string;
  pendingSignature: null | string;
  pendingSignatureWithColor: string;
  expired: null | string;
  expiredWithColor: string;
  esgSafeguard: null | string;
  esgSafeguardWithColor: string;
  eligibleUnDisbursement: null | string;
  eligibleUnDisbursementWithColor: string;
  cancelled: null | string;
  cancelledWithColor: string;
  pmr: null | string;
  pmrWithColor: string;
  assigned: string;
  follow: string;
  projectProductType: string;
}

export enum WithColorEnum {
  HOLLOW = '0',
  FILLED = '1',
  NOT_APPLICABLE = '2',
}

export enum IconColorsEnum {
  NOT_APPLICABLE = '2',
  GREEN = '3',
  HOLLOW_YELLOW = '4',
  FILLED_YELLOW = '5',
  HOLLOW_RED = '6',
  FILLED_RED = '7',
}

export enum SAFEGUARD_LABELS_ENUM {
  Satisfactory = 'Satisfactory',
  Partially_Unsatisfactory = 'Partially_Unsatisfactory',
  Unsatisfactory = 'Unsatisfactory',
  Partially_Satisfactory = 'Partially_Satisfactory',
}
export enum PMR_LABELS_ENUM {
  SATISFACTORY = 'SATISFACTORY',
  ALERT = 'ALERT',
  PROBLEM = 'PROBLEM',
  NA = 'N/A',
}

export enum LinkedActionTableTypeEnum {
  ACCORDANCES,
  SUPERVISION_PLAN,
}
interface THeader {
  text: string;
}

export enum TableStateEnum {
  READY,
  LOADING,
  NO_RESULTS,
}
export interface TableData {
  linkedActionTableType: LinkedActionTableTypeEnum;
  tHeader: THeader[];
  tableState: TableStateEnum;
  tRows: TableRowDataAccordances[] | TableRowDataSupervisionPlan[];
}

export interface TableRowDataAccordances {
  id: number;
  accordanceState: AccordanceState;
  accordanceType: AccordanceType;
  description: string;
  expirationDate: string;
  lastComment: Comment | null;
  projectId: number;
}

export const DEFAULT_AND_EMPTY_ACCORDANCE_TABLE_DATA = {
  id: 0,
  accordanceState: AccordanceState.CANCELLED,
  accordanceType: AccordanceType.Revision,
  description: '',
  expirationDate: '',
  lastComment: null,
  projectId: 0,
};
export interface TableRowDataSupervisionPlan {
  id: number;
  description: string;
  type: SupervisionPlanItemType;
  plannedDate: string;
  state: SupervisionPlanItemState;
  lastComment: Comment | null;
  projectId: number;
  plannedQuarter: string;
}

export const DEFAULT_AND_EMPTY_SUPERVISION_PLAN_TABLE_DATA = {
  id: 0,
  description: '',
  type: SupervisionPlanItemType.AcquisitionTrustConsulting,
  plannedDate: '',
  state: SupervisionPlanItemState.Cancelled,
  lastComment: null,
  projectId: 0,
  plannedQuarter: '',
};

export interface Comment {
  id: number;
  author: null;
  date: string;
  comment: string;
  parentId: number;
  isDeleted: boolean;
  origin: number;
  executingUnitUserId: null;
  executingUnitName: null;
  executingUnitRole: null;
}

export const moduleButtonsInitialState: ModuleButton[] = [
  {
    ...baseModuleButton,
    buttonEWType: EarlyWarningButtonType.PROJECTS_PENDING_SIGNATURE,
    singularLabel: 'project_pending_signature_singular',
    pluralLabel: 'projects_pending_signature_plural',
  },
  {
    ...baseModuleButton,
    buttonEWType: EarlyWarningButtonType.ELIGIBLE_PROJECTS_PENDING_FIRST_DISBURSEMENT,
    singularLabel: 'eligible_project_pending_first_disbursement_singular',
    pluralLabel: 'eligible_projects_pending_first_disbursement_plural',
  },
  {
    ...baseModuleButton,
    buttonEWType: EarlyWarningButtonType.PROJECTS_WITH_RECENT_ALERT_OR_PROBLEM_CLASSIFICATIONS,
    singularLabel: 'project_with_recent_alert_or_problem_classifications_singular',
    pluralLabel: 'projects_with_recent_alert_or_problem_classifications_plural',
  },
  {
    ...baseModuleButton,
    buttonEWType: EarlyWarningButtonType.PROJECTS_WITH_UNSATISFACTORY_PERFORMANCE_IN_SAFEGUARDS,
    singularLabel: 'project_with_unsatisfactory_performance_in_safeguards_singular',
    pluralLabel: 'projects_with_unsatisfactory_performance_in_safeguards_plural',
  },
  {
    ...baseModuleButton,
    buttonEWType: EarlyWarningButtonType.PROJECTS_WITH_PARTIAL_CANCELLATIONS,
    singularLabel: 'project_with_partial_cancellations_singular',
    pluralLabel: 'projects_with_partial_cancellations_plural',
  },
  {
    ...baseModuleButton,
    buttonEWType: EarlyWarningButtonType.PROJECTS_WITH_EXPIRED_DISBURSEMENT_DEADLINE,
    singularLabel: 'projects_with_expired_disbursement_deadline_singular',
    pluralLabel: 'projects_with_expired_disbursement_deadline_plural',
  },
];

export enum LoadingState {
  firstIn,
  backFromProjectDetail,
  firstInOnTrends,
}
export interface IEarlyWarningsProjects {
  projects: EWProjectResponse[];
  loadingState: LoadingState;
}

export enum EarlyWarningVirtualColumns {
  sortablePendingSignature = 'sortablePendingSignature',
  sortableElegibleUndisbursement = 'sortableElegibleUndisbursement',
  sortablePmrColumn = 'sortablePmrColumn',
  sortableSafeguardPerformance = 'sortableSafeguardPerformance',
  sortablePartialCancellations = 'sortablePartialCancellations',
  sortableDisbursementExpired = 'sortableDisbursementExpired',
}

export enum EarlyWarningSecondSortCriteria {
  PENDING_SIGNATURE = 'pendingSignature',
  ELIGIBLE_UNDISBURSEMENT = 'eligibleUnDisbursement',
  PMR = 'pmr',
  ESG_SAFEGUARD = 'esgSafeguard',
  CANCELLED = 'cancelled',
  EXPIRED = 'expired',
}
