import React from 'react';
import { useTranslation } from 'react-i18next';
import './RequiredErrorMessageCustomMessage.scss';

const RequiredErrorMessageCustomMessage = props => {
  const { errorMessage, isHidden } = props;
  const { t } = useTranslation();
  return isHidden ? (
    <div></div>
  ) : (
    <div className="required-error-message-custom-message-container">
      <span className="error-icon"></span>
      <span className="message">{t(errorMessage)}</span>
    </div>
  );
};

export default RequiredErrorMessageCustomMessage;
