import { IconColorsEnum, SAFEGUARD_LABELS_ENUM, WithColorEnum } from '../../../models/EarlyWarningsSection';
import {
  dateIsAfterToday,
  filterProjectsBetweenLimitsByDays,
  getSubstractedDateByDays,
  getSubstractedDateByMonths,
  substractDays,
  sumDays,
} from '../../PortfolioDetail/EarlyWarningsFilters/Util';
import { isGreenPmrAlert, isRedPmrAlert } from '../Util';

const handleDisbursementExpired = (icon: WithColorEnum, expiredDate: string) => {
  if (icon === WithColorEnum.NOT_APPLICABLE) {
    return IconColorsEnum.NOT_APPLICABLE;
  } else if (filterProjectsBetweenLimitsByDays(new Date(expiredDate), getSubstractedDateByDays(120), new Date())) {
    return IconColorsEnum.GREEN;
  } else if (
    filterProjectsBetweenLimitsByDays(
      new Date(expiredDate),
      getSubstractedDateByDays(364),
      getSubstractedDateByDays(121),
    )
  ) {
    return handleYellowIcons(icon);
  } else if (filterProjectsBetweenLimitsByDays(new Date(expiredDate), getSubstractedDateByDays(365))) {
    return handleRedIcons(icon);
  }
  return null;
};

const handlePartialCancellations = (icon: WithColorEnum, percentage: string) => {
  const yellowPercentageLimit = 15;
  const percentageNumber = percentage !== null ? parseFloat(percentage) : 0;
  if (icon === WithColorEnum.NOT_APPLICABLE) {
    return IconColorsEnum.GREEN;
  }

  if (percentageNumber <= yellowPercentageLimit) {
    return handleYellowIcons(icon);
  }
  if (percentageNumber > yellowPercentageLimit) {
    return handleRedIcons(icon);
  }
  return null;
};

const handleSafeguardPerformance = (icon, safeguardLabel) => {
  if (icon === WithColorEnum.NOT_APPLICABLE) {
    return IconColorsEnum.NOT_APPLICABLE;
  } else if (
    safeguardLabel === SAFEGUARD_LABELS_ENUM.Satisfactory ||
    safeguardLabel === SAFEGUARD_LABELS_ENUM.Partially_Satisfactory
  ) {
    return IconColorsEnum.GREEN;
  } else if (safeguardLabel === SAFEGUARD_LABELS_ENUM.Unsatisfactory) {
    return handleRedIcons(icon);
  } else if (safeguardLabel === SAFEGUARD_LABELS_ENUM.Partially_Unsatisfactory) {
    return handleYellowIcons(icon);
  }
  return null;
};

const handleYellowIcons = (icon: WithColorEnum) =>
  icon === WithColorEnum.HOLLOW ? IconColorsEnum.HOLLOW_YELLOW : IconColorsEnum.FILLED_YELLOW;

const handleRedIcons = (icon: WithColorEnum) =>
  icon === WithColorEnum.HOLLOW ? IconColorsEnum.HOLLOW_RED : IconColorsEnum.FILLED_RED;

const handleLast3Pmr = (icon, pmrLabels) => {
  if (icon === WithColorEnum.NOT_APPLICABLE) {
    return IconColorsEnum.NOT_APPLICABLE;
  }

  const pmrLabelsArray: string[] = pmrLabels ? pmrLabels.split(',') : [];
  const greenAlert = pmrLabelsArray.some(isGreenPmrAlert) && !pmrLabelsArray.some(isRedPmrAlert);

  if (greenAlert) {
    return IconColorsEnum.GREEN;
  }

  const redAlert = pmrLabelsArray.length === 3 && pmrLabelsArray.every(isRedPmrAlert);
  if (redAlert) {
    return handleRedIcons(icon);
  }
  const yellowAlert = pmrLabelsArray.some(isRedPmrAlert);
  if (yellowAlert) {
    return handleYellowIcons(icon);
  }
  return null;
};

const handleElegibleUnisbursementAlert = (icon: WithColorEnum, dateString: string) => {
  if (icon === WithColorEnum.NOT_APPLICABLE) {
    return IconColorsEnum.NOT_APPLICABLE;
  } else if (
    filterProjectsBetweenLimitsByDays(new Date(dateString), sumDays(getSubstractedDateByMonths(1), 1), new Date())
  ) {
    return IconColorsEnum.GREEN;
  } else if (
    filterProjectsBetweenLimitsByDays(
      new Date(dateString),
      getSubstractedDateByMonths(6),
      getSubstractedDateByMonths(1),
    )
  ) {
    return handleYellowIcons(icon);
  } else if (filterProjectsBetweenLimitsByDays(new Date(dateString), substractDays(getSubstractedDateByMonths(6), 1))) {
    return handleRedIcons(icon);
  }
  return null;
};

const handlePendingSignatureAlert = (icon, dateString) => {
  if (icon === WithColorEnum.NOT_APPLICABLE) {
    return IconColorsEnum.NOT_APPLICABLE;
  } else if (dateIsAfterToday(new Date(dateString))) {
    return IconColorsEnum.GREEN;
  } else if (
    filterProjectsBetweenLimitsByDays(new Date(dateString), getSubstractedDateByDays(364), getSubstractedDateByDays(0))
  ) {
    return handleYellowIcons(icon);
  } else if (filterProjectsBetweenLimitsByDays(new Date(dateString), getSubstractedDateByDays(365))) {
    return handleRedIcons(icon);
  }
  return null;
};

const handleEsgSafeguardText = (safeguardValue, t) => (safeguardValue ? safeguardValue : t('not_assigned_initials'));

export {
  handlePartialCancellations,
  handleSafeguardPerformance,
  handleLast3Pmr,
  handleElegibleUnisbursementAlert,
  handlePendingSignatureAlert,
  handleDisbursementExpired,
  handleEsgSafeguardText,
};
