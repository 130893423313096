import { SuccessMessageActionTypes, SET_SUCCESS_MESSAGE } from '../actions/types';

const initialState = { show: false, message: '' };

const successMessageReducer = (
  state = initialState,
  action: SuccessMessageActionTypes,
): { show: boolean; message: string } => {
  switch (action.type) {
    case SET_SUCCESS_MESSAGE:
      return action.payload;
    default:
      return state;
  }
};

export default successMessageReducer;
