import { SET_REVIEW_SELECTED_FILTER, RESET_REVIEW_SELECTED_FILTER } from './types';

export function setReviewSelectedFilter(filter: any) {
  return {
    type: SET_REVIEW_SELECTED_FILTER,
    payload: filter,
  };
}

export function resetReviewSelectedFilter() {
  return {
    type: RESET_REVIEW_SELECTED_FILTER,
  };
}
