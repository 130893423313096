import React from 'react';
import { useTranslation } from 'react-i18next';
import './AccordanceButton.scss';

import { ReactComponent as Further} from '../../../../assets/icons/u_plus-circle.svg';
import { trackActionClicked } from '../../../../api/trackActionClickedApi';
import { TrackedActions } from '../../../../models/TrackedActionsEnum';

type Props = {
  handleClick: any;
  customStyle?: {};
  disabled?: boolean;
  customLabel?: any;
};

const AccordanceButton = (props: Props) => {
  const { disabled, handleClick, customStyle, customLabel } = props;
  const { t } = useTranslation();

  const handleClickWrapper = () => {
    handleClick();
    trackActionClicked(TrackedActions.newAccordanceClicked);
  };

  return (
    <button
      disabled={disabled}
      style={customStyle || {}}
      className={`accordance-button-container button-svg ${disabled ? 'disabled' : ''}`}
      onClick={handleClickWrapper}
    >
      <Further />
      <span className="text">{customLabel || `${t('new_accordance')}`}</span>
    </button>
  );
};

export default AccordanceButton;
