import React from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import FilterIndicators from '../../../../../models/FilterIndicators';
import { multiSelectStyle, DropdownIndicator } from '../../../../Filter/FilterComponent.Styles';
import { useFrontEndBasedSelectedsOptions } from '../../../../../hooks/useFrontEndBasedSelectedsOptions';

type Props = {
  reportFilters: FilterIndicators;
  reportFiltersChanged: Function;
};
export const ExecutionFilter = ({ reportFilters, reportFiltersChanged }: Props) => {
  const { t } = useTranslation();
  const [localFilter, setLocalFilter] = React.useState(reportFilters);
  const { executionOptions } = useFrontEndBasedSelectedsOptions();

  const handleChange = value => {
    if (value === null) {
      value = {};
    }

    const newFilter = {
      ...localFilter,
      common: {
        ...localFilter.common,
        executionFilter: value,
      },
    };

    setLocalFilter(newFilter);
    reportFiltersChanged({ executionFilter: newFilter.common.executionFilter });
  };

  return (
    <Select
      id="executionSelect"
      defaultValue={localFilter.common.executionFilter}
      isMulti={false}
      components={{ DropdownIndicator }}
      name="execution"
      options={executionOptions}
      className="basic-multi-select"
      classNamePrefix="All"
      placeholder={t('all')}
      onChange={value => handleChange(value)}
      styles={multiSelectStyle}
      isClearable
    />
  );
};
