import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CalledFrom } from '../../../models/CalledFromEnum';
import SelectOption from '../../../models/SelectOption';
import { setFilterButtonText } from '../PortfolioUtil';
import { getDivisionFilterOptions } from '../../Filter/UtilFunctions';
import Filters from '../../FilterV2/Filters';
import { ReactComponent as IconFiltersV } from '../../../assets/icons/u_filters-v.svg';
import { ReactComponent as IconFiltersClosed } from '../../../assets/icons/u_chevron-down.svg';
import { ReactComponent as IconFiltersOpen } from '../../../assets/icons/u_chevron-up.svg';
import ProjectTypeFilter from '../../Filter/ProjectTypeFilter/ProjectTypeFilter';
//* Se agregan importaciones para el Filter desplegable
import css from './FilterBar.module.scss';
import { useContainerDimensions } from '../../../hooks/useContainerDimension';
import { TagFilter } from '../../TagFilter/TagFilter';
import { useDynamicTagLayout } from '../../../hooks/useDynamicTagLayout';
import { ReactComponent as IconTrash } from '../../../assets/icons/u_trash.svg';
import { setFilterIndicators } from '../../../redux/actions/filterIndicators';
import { useDispatch } from 'react-redux';
import { mockObjFilter } from '../../../mockData/mockObjFilter';
import { DimensionContext } from '../../../context/DimensionContext';
import { TagFilterContext } from '../../../context/TagFilterContext';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { AppMenuTabs } from '../../MenuTabs/userTabsInterface';
interface Props {
  hideFilter?: boolean;
}

const Filter = ({ hideFilter }: Props) => {
  const { t } = useTranslation();
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [sectorsSelected, setSectorsSelected] = useState<SelectOption[]>([]);
  const [availableDivisions, setAvailableDivisions] = useState<SelectOption[]>([]);
  const sectorsFromRedux = useSelector<any, any>(state => state.sectors);
  const divisionsFromRedux = useSelector<any, any>(state => state.divisions);
  //* Se agrega variables para el Filter desplegable
  const dropMenuRef = useRef(null);
  const { containerRef, dimensions } = useContainerDimensions();
  const { visibleTags, overflowTags } = useDynamicTagLayout();
  const [isMoreFiltersHovered, setIsMoreFiltersHovered] = useState(false);
  const { tagDimension, setTagDimension } = useContext(DimensionContext);
  const { labels } = useContext(TagFilterContext);
  const dispatch = useDispatch();

  const menuTabs = useSelector<any, AppMenuTabs>(state => state.menuTabs);
  const portfolioSelectedTab = menuTabs.portfolioSelectedTab;

  const currentTagNames = useMemo(
    () => new Set(tagDimension.map(tag => tag.name)),
    [tagDimension, portfolioSelectedTab],
  );

  useOutsideClick(dropMenuRef, () => setShowFilters(false));

  useEffect(() => {
    if (sectorsSelected && sectorsSelected.length) {
      const filteredSectors = sectorsFromRedux.filter(sector =>
        sectorsSelected.map(selected => selected.id).includes(sector.id),
      );
      const divisionsFromSelected = filteredSectors.flatMap(filtered => filtered.divisions);
      const divisionsOptions = getDivisionFilterOptions(divisionsFromSelected);
      setAvailableDivisions(divisionsOptions);
    } else {
      const divisionsOptions = getDivisionFilterOptions(divisionsFromRedux);
      setAvailableDivisions(divisionsOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectorsSelected]);

  //* Se agregan funciones para el Filter desplegable

  useEffect(() => {
    const currentTagNamesArray = Array.from(currentTagNames);

    const newTags = labels.filter(label => !currentTagNames.has(label));
    const removedTags = currentTagNamesArray.filter(name => !labels.includes(name));

    if (newTags.length > 0 || removedTags.length > 0) {
      setTagDimension(prevDimensions => {
        const updatedDimensions = prevDimensions.filter(tag => !removedTags.includes(tag.name));
        return [...updatedDimensions, ...newTags.map(label => ({ width: 0, height: 0, name: label }))];
      });
    }
  }, [labels, currentTagNames, setTagDimension]);

  const toggleShowFilters = () => setShowFilters(prev => !prev);
  const handleMouseEnter = useCallback(() => setIsMoreFiltersHovered(true), []);
  const handleMouseLeave = useCallback(() => setIsMoreFiltersHovered(false), []);

  const cleanAllTags = () => {
    setShowFilters(true);
    dispatch(
      setFilterIndicators({
        ...mockObjFilter.common,
        common: {
          ...mockObjFilter.common,
        },
      }),
    );
  };

  return (
    <div ref={dropMenuRef} className={`${css['Filters']} ${showFilters ? css['show-filters'] : css['hide-filters']}`}>
      <div className={css['container-filter']}>
        {!hideFilter && (
          <button className={`btn-primary btn-filter ${css['btn-filter']}`} onClick={() => toggleShowFilters()}>
            <IconFiltersV className={css['icon-color']} />
            {setFilterButtonText(t)}
            {showFilters ? (
              <IconFiltersOpen className={css['icon-color']} />
            ) : (
              <IconFiltersClosed className={css['icon-color']} />
            )}
          </button>
        )}
        <div ref={containerRef} className={css['c-tag']}>
          {dimensions && visibleTags.map(tag => <TagFilter key={tag.name} tag={tag.name} />)}
          {overflowTags.length > 0 && (
            <p className={css['more-filters-text']} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              {`+${overflowTags.length} filtros`}
            </p>
          )}
          {visibleTags.length > 0 && (
            <div className={css['clean-filter']} onClick={cleanAllTags}>
              <IconTrash /> {t('clean')}{' '}
            </div>
          )}
        </div>

        <ul className={`${css['c-hidden-filters']} ${isMoreFiltersHovered ? css['show-hidden-filters'] : ''}`}>
          {overflowTags.map(item => (
            <li key={item.name}>{item.name}</li>
          ))}
        </ul>
        <div className={css['filters-button']}>
          <ProjectTypeFilter filterTitle="the_portfolio_consists_of" indicatorCall />
        </div>
      </div>
      {showFilters && <Filters indicatorCall={true} calledFrom={CalledFrom.PORTFOLIO} />}
    </div>
  );
};

export default Filter;
