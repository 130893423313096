import React, { useEffect, useState } from 'react';
import style from './PerformancePMRCollapsed.module.scss';
import { sortDates } from '../../../services/sortsUtil';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { PmrClassificationBig } from '../../Util/PmrClassification/PmrClassificationBig';
import { PmrEvolution } from '../../../models/PmrEvolution';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const BlackTooltip = withStyles({
  tooltip: {
    //   color: '$ci-color-greyscale-90',
    backgroundColor: '#101319',
    fontSize: '16px',
    fontFamily: 'Rubik',
    fontWeight: '400',
    lineHeight: '20px',
    padding: '8px 12px',
    borderRadius: '8px',
  },
  arrow: {
    fontSize: 20,
    color: '#4A4A4A',
    '&::before': {
      backgroundColor: '#101319',
    },
  },
})(Tooltip);

type Props = {
  data: PmrEvolution[];
  forReport?: boolean;
};

const customReportStyle = forReport => (forReport ? { marginLeft: 40 } : {});
export const lastTenEvents = dataGroup => {
  if (dataGroup.length > 10) {
    const amountDataMayorOfTen = dataGroup.length - 10;
    return dataGroup.splice(0, amountDataMayorOfTen);
  }
  return null;
};
const processData = data => {
  const sortedData = [...data].sort(sortDates('date', 'asc'));
  const dataGroup = sortedData.map(item => {
    item.date = moment(item.date).format('YYYY');
    return item;
  });
  lastTenEvents(dataGroup);
  return dataGroup;
};
const { performancePMRCollapsed, performanceTitle, PMRLineSection, dataContainer, dataPMR, dataDate } = style;

const performanceTexts = {
  0: 'satisfactory',
  1: 'alert',
  2: 'problem',
  3: 'not_assigned_initials',
};

export const PerformancePMRCollapsed = ({ data, forReport = false }: Props) => {
  const { t } = useTranslation();
  const [events, setEvents] = useState<any>([]);
  useEffect(() => {
    setEvents(processData(cloneDeep(data)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={performancePMRCollapsed}>
      <div style={customReportStyle(forReport)}>
        <p className={performanceTitle}>{t('performance_pmr_title')}</p>
      </div>
      <ul className={PMRLineSection} style={customReportStyle(forReport)}>
        {events.map((item, index) => {
          return (
            <li key={`evolutionYear_${index}`} className={dataContainer}>
              <BlackTooltip
                title={t(performanceTexts[item.pmrClassification]) || ''}
                placement="top"
                enterDelay={400}
                leaveDelay={100}
                arrow
              >
                <div className={dataPMR}>
                  <PmrClassificationBig classificationColor={item.pmrClassification} />
                </div>
              </BlackTooltip>
              <p className={dataDate}>{item.date}</p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
