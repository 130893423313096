import React from 'react';
import ProgressBar from './ProgressBar';
import { useTranslation } from 'react-i18next';

interface Props {
  data: AcquisitionProcessBalancesCompenent[];
}
interface AcquisitionProcessBalancesCompenent {
  componentNumber: number;
  contractPercent: number;
  pendingPercent: number;
  unallocatedPlan: number;
  acquisitionComponentBalances: AcquisitionComponentBalance[];
}
interface AcquisitionComponentBalance {
  componentNumber: string;
  componentNM: string;
  outputNumber: string;
  outputNM: string;
  contractPercent: number;
  pendingPercent: number;
  unallocatedPlan: number;
}

const ComponentsAvanceMatriz = ({ data }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={`component-box`}>
      {data?.map((component, index) => (
        <div className="component-num" key={index}>
          <ul className="header component-list">
            <li>
              <p>
                {t('new_component')} {component?.componentNumber}
              </p>
            </li>
            <li>
              <ProgressBar firstValue={component?.contractPercent} secondValue={component?.pendingPercent} />
            </li>
          </ul>
          {component?.acquisitionComponentBalances.map((item, idx) => (
            <ul className="component-list" key={idx}>
              <li>
                <p>{item.outputNM}</p>
              </li>
              <li>
                <ProgressBar firstValue={item.contractPercent} secondValue={item.pendingPercent} />
              </li>
            </ul>
          ))}
        </div>
      ))}
    </div>
  );
};

export default ComponentsAvanceMatriz;
