import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getProjectAccordances } from '../redux/actions/currentProject';
import { getIdFromUrl } from '../services/numberUtil';
import { deleteAccordance, updateBundleAccordances } from '../api/accordanceApi';
import { AccordanceState } from '../models/AccordanceState';
import Project from '../models/Project';

const useAccordanceDetailModalsState = () => {
  const currentProject = useSelector<any, Project>(state => state.currentProject);
  const dispatch = useDispatch();
  const history = useHistory();
  const [showDetail, setShowDetail] = React.useState(false);
  const [showReject, setShowReject] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [showCancelModal, setShowCancelModal] = React.useState(false);
  const [showFulfilledModal, setShowFulfilledModal] = React.useState(false);
  const [showConfirmDeleteBundle, setShowConfirmDeleteBundle] = React.useState(false);

  const showModal = () => {
    setShowDetail(true);
  };

  const hideModal = (data?: any) => {
    if (data && data.refreshData) {
      dispatch(
        getProjectAccordances(
          getIdFromUrl(history.location.search),
          currentProject.selectedYear,
          currentProject.selectedType,
        ),
      );
    }
    setShowDetail(false);
  };

  const showJustificationModal = () => {
    setShowReject(true);
  };

  const refreshBoard = () => {
    dispatch(getProjectAccordances(getIdFromUrl(history.location.search)));
  };

  const deleteAcc = async (id: number) => {
    await deleteAccordance(id);
    refreshBoard();
    setShowConfirm(false);
  };

  const refreshAndCloseModals = () => {
    refreshBoard();
    setShowConfirmDeleteBundle(false);
    setShowDetail(false);
  };

  const deleteBundle = async (bundleId: string) => {
    if (bundleId) {
      await updateBundleAccordances({ bundleId, accordances: [] });
      refreshAndCloseModals();
    } else {
      refreshAndCloseModals();
    }
  };

  const changeStatus = value => {
    if (value === AccordanceState.CANCELLED) {
      hideModal(false);
      setShowCancelModal(true);
    } else if (value === AccordanceState.FULLFILLED) {
      hideModal(false);
      setShowFulfilledModal(true);
    }
  };

  return {
    showDetail,
    showReject,
    showConfirm,
    showCancelModal,
    showFulfilledModal,
    showConfirmDeleteBundle,
    showModal,
    hideModal,
    showJustificationModal,
    refreshBoard,
    deleteAcc,
    changeStatus,
    setShowDetail,
    setShowReject,
    setShowConfirm,
    setShowCancelModal,
    setShowFulfilledModal,
    setShowConfirmDeleteBundle,
    deleteBundle,
  };
};

export default useAccordanceDetailModalsState;
