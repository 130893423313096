import { ReportImageDisplay } from '../../../../Util/Modals/CustomizableReports/CustomizableReports';

export const options = forReport => {
  return {
    annotation: {
      annotations: [
        {
          drawTime: 'beforeDatasetsDraw',
          borderColor: forReport === ReportImageDisplay.Detail ? 'white' : 'rgba(114, 126, 140, 0.5)',
          borderWidth: 1,
          mode: 'vertical',
          type: 'line',
          value: 5,
          scaleID: 'x-axis-0',
        },
        {
          drawTime: 'beforeDatasetsDraw',
          borderColor: forReport === ReportImageDisplay.Detail ? 'white' : 'rgba(114, 126, 140, 0.5)',
          borderWidth: 1,
          mode: 'horizontal',
          type: 'line',
          value: 5,
          scaleID: 'y-axis-0',
        },
      ],
    },
      tooltips: {
        backgroundColor: '#101319',
      titleFontSize: 14, 
      bodyFontSize: 14,
      },
    plugins: {
      datalabels: {
        display: false,
      },
    },

    scales: {
      xAxes: [
        {
          display: false,
          stacked: false,
          ticks: {
            beginAtZero: true,
            min: 0,
            max: 10,
          },
          gridLines: {
            display: false,
          },
        },
      ],

      yAxes: [
        {
          display: false,
          stacked: true,
          ticks: {
            min: 0,
            max: 10,

            beginAtZero: true,
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
  };
};
