import { useCallback } from 'react';

export const useNumberFormatter = () => {
    /** formatNumber - A function to format a given number.
     * 
     * It handles decimal places and inserts thousands separators.
     * @param {number} num - The number to format.
     * @returns {string} - The formatted number as a string.
     */
    const formatNumber = useCallback((num: number): string => {
        let formattedNumber = num % 1 === 0 ? num.toString() : num.toFixed(1);
        let [integerPart, decimalPart] = formattedNumber.split('.');

        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        integerPart = integerPart.replace(/(?<=\d)\.(?=\d{3}\.)/g, ".");

        return decimalPart ? integerPart + ',' + decimalPart : integerPart;
    }, []);

    /** A function to format numbers as millions.
     * 
     * It calls formatNumber for numbers less than a million,
     * and formats larger numbers in terms of millions.
     * @param {number} num - The number to format.
     * @returns {string} - The formatted number as a string.
     */
    const formatMillions = useCallback((num: number): string => {
        if (num < 1000000) return formatNumber(num);

        const millions = num / 1000000;
        let formattedMillions = millions.toFixed(1);

        formattedMillions = formattedMillions.replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');

        return formattedMillions;
    }, [formatNumber]);

    return { formatNumber, formatMillions };
};
