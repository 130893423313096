import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReviewsStatusEnum } from '../../../../models/ReviewsStatusEnum';
import { replaceMomentFormat } from '../../../../services/util';
import './ReadOnlyDates.scss';

type Props = {
  title: string;
  review: any;
};

export const ReadOnlyDates = ({ title, review: { status, startDate, endDate } }: Props) => {
  const { t } = useTranslation();
  const dateFormat = 'DD/MMM/YYYY';
  return (
    <div className="read-only-dates-container">
      <div className="title">{title}</div>

      {status === ReviewsStatusEnum.Attempt || status === null ? (
        <div className="subtitle"> {t('there_is_no_set_date_for_the_next_portfolio_review')}</div>
      ) : (
        <div>
          <div className="date-confirmed">
            {replaceMomentFormat(moment(startDate).format(dateFormat))}
            <span className="lowercase"> {` ${t('to_the')} `}</span>
            {replaceMomentFormat(moment(endDate).format(dateFormat))}
          </div>
          <div className="date-message">{t('at_the_moment_no_projects_selected_next_portfolio_review')}</div>
        </div>
      )}
    </div>
  );
};
