import { groupBy } from 'lodash';
import { RolesIdEnum } from '../../../models/RolesIdEnum';

export const setOrderById = (id: number) => {
  switch (id) {
    case RolesIdEnum.Team_Leader:
      return 1;
    case RolesIdEnum.Financial_Specialist:
      return 2;
    case RolesIdEnum.Fiduciaries:
      return 3;
    case RolesIdEnum.ESG_Specialist:
      return 4;
    case RolesIdEnum.Operations_Analyst:
      return 5;
    default:
      return 6;
  }
};

export const parseGroupedToOrdered = (grouped: any) => {
  const auxList: any[] = [];
  for (const [key, value] of Object.entries(grouped)) {
    auxList.push({ role: key, list: value, order: setOrderById(parseInt(key)) });
  }
  return auxList.sort((a, b) => {
    if (a.order > b.order) {
      return 1;
    } else if (a.order < b.order) {
      return -1;
    } else {
      return 0;
    }
  });
};

export const sortByRoles = (projectTeams: any[]) => {
  const bidMembers = projectTeams
    .filter(x => x.bidMember && x.applicationUser.id)
    .map((item, index) => {
      item.internalId = index;
      return item;
    });
  const groupedByRole = groupBy(bidMembers, a => a.roleInProject.id);
  return parseGroupedToOrdered(groupedByRole);
};

export const bidMembersLayout = bidMembers => {
  let membersList = 0;
  for (const m of bidMembers) {
    membersList += m.list.length;
  }
  const listLength = bidMembers.length + membersList;
  if (listLength === 0) {
    return 100;
  } else {
    return Math.ceil(listLength / 2) * 35;
  }
};
