/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormMemoryHelpContext } from "../../../context/MemoryHelpFormContext";
import FileUploader from "./FileUploader";
import { ReactComponent as IconWarning } from '../../../assets/icons/u_warning.svg';
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { ReactComponent as IconFile } from '../../../assets/icons/u_file-check-alt.svg';

const ConfirmMemoryHelp = ({ fileSetting, isShowModal, fileAttachment }) => {
    const { t } = useTranslation();

    const [isRadioSelected, setIsRadioSelected] = useState(true);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [isUpload, setIsUpload] = useState<boolean | null>(false);
    const [currentInput, setCurrentInput] = useState<string>("setup");

    const { userCanEditRevision, loadingHelpFile, loadingHelpError, triggerGetFiles, setIsUserUploaded } = useFormMemoryHelpContext();

    // Transform to Blob object
    const handleFileAccepted = (file: File) => {
        const fileUrl = URL.createObjectURL(file);
        fileSetting(fileUrl);
        setIsUserUploaded(true);
        setSelectedFile(file);
        fileAttachment(file);
    };

    // Handles file deletion and updates related statuses
    const handleFileDeleted = () => {
        setSelectedFile(null);
        setIsRadioSelected(true);
    };

    // Handles radio selection change and updates related states
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsRadioSelected(true);
        setCurrentInput(e.target.value);
        if (e.target.value === "upload") {
            setIsUpload(true);
            setSelectedFile(null);
        } else {
            setIsUpload(false);
            triggerGetFiles();
        }
    };

    // Determines if the button should be disabled
    const isButtonDisabled = !isRadioSelected || (isUpload && !selectedFile) || loadingHelpFile || loadingHelpError;


    return (
        <>
            {userCanEditRevision && (
                <section className='c-memory-help-confirm'>
                    <div className='icon'><IconWarning /></div>
                    <h1>{t('memory_help.container_tabs.completed.confirm.title')}</h1>
                    <p className='ci-type-text-4'>{t('memory_help.container_tabs.completed.confirm.description')}</p>

                    <RadioGroup name="confirm" value={currentInput} onChange={handleChange}>
                        <FormControlLabel value="setup" control={<Radio />} label={t('memory_help.container_tabs.completed.confirm.p1')} disabled={loadingHelpFile as boolean} />
                        <FormControlLabel value="upload" control={<Radio />} label={t('memory_help.container_tabs.completed.confirm.p2')} disabled={loadingHelpFile as boolean} />
                    </RadioGroup>
                    {currentInput === "upload" && <FileUploader onFileAccepted={handleFileAccepted} onFileDeleted={handleFileDeleted} />}
                    <div className='button'>
                        <button onClick={() => isShowModal(true)} className='btn btn-primary-outlined' disabled={isButtonDisabled as boolean}>
                            {t('memory_help.page_reviews.btn.confirm')}
                            <IconFile />
                        </button>
                    </div>
                </section>
            )}
        </>
    );
};


export default ConfirmMemoryHelp;


