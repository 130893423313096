import React from 'react';
import './PariPassuAdvance.scss';
import { useTranslation } from 'react-i18next';
import { Line } from 'rc-progress';

type Props = {
  label: any;
  completed: number;
  notCompleted: number;
  showTitle?: boolean;
  type?: string;
};

const PariPassuAdvance = (props: Props) => {
  const { t } = useTranslation();
  const { label, completed, showTitle, notCompleted, type } = props;
  return (
    <div className="financial-pari-passu-advance-container">
      {showTitle && (
        <div className="titles">
          {/* <span>{t('contractual')}</span>
          <span>{t('current_year')}</span> */}
          <span>{t('bid')}</span>
          <span>{t('local')}</span>
        </div>
      )}
      <div>
        <span className="label">{label}</span>
      </div>
      <div className="advance-titles">
        <div className="values">
          {/* <span>{`${completed}%`}</span> */}
          <button className={type === 'b' ? 'values-button' : 'values-button-local'}>{completed} %</button>
          {/* <span>{`${notCompleted}%`}</span> */}
          <button className={type === 'b' ? 'values-button' : 'values-button-local'}>{notCompleted} % </button>
        </div>
        {/* <div className="line-container">
          <Line percent={completed} strokeWidth={7} trailWidth={7} strokeColor="#A8C7FD" trailColor="#7C86A2" />
        </div> */}
      </div>
    </div>
  );
};

export default PariPassuAdvance;
