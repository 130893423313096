import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import IndicatorDetailFilter from '../../../../models/IndicatorDetailFilter';
import { isMobile } from '../../../../services/booleanUtil';
import { RenderTextByProjectTypeFilterDetail } from '../RenderTextByProjectTypeFilterDetail/RenderTextByProjectTypeFilterDetail';
import { getCountProjectValue } from '../Util';

type Props = {
  arrayToCount: any;
  countProjects: boolean;
  totalProjects: number;
  widthStyle?: string;
  textValue: string;
};
export const ProjectAndProductTypeCounter = ({
  arrayToCount,
  countProjects,
  totalProjects,
  widthStyle,
  textValue,
}: Props) => {
  const { t } = useTranslation();
  const { projectTypeFilterOnDetail } = useSelector<any, IndicatorDetailFilter>(state => state.indicatorDetailFilter);
  return (
    <div
      className="project-counter-container"
      style={isMobile() ? { width: '100%' } : { width: '100%', maxWidth: widthStyle }}
    >
      <div className="values">
        <div className="project-value" style={{ lineHeight: '40px' }}>
          <span className="capitlaize-text"> {t('total')}</span>:{' '}
          {getCountProjectValue(countProjects, totalProjects, arrayToCount)}
          <span className="lowercase-text"> {t(textValue)} </span>
          {'/'}
        </div>
        <RenderTextByProjectTypeFilterDetail
          arrayToCount={arrayToCount}
          projectTypeFilterOnDetail={projectTypeFilterOnDetail}
        />
      </div>
    </div>
  );
};
