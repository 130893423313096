import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './ProjectsTable.scss';
import Project from '../../../models/Project';
import { SortParameterProject } from '../../../models/SortParameterProjectEnum';
import { ProjectsTableRow } from '../ProjectsTableRow/ProjectsTableRow';
import { isMobile } from '../../../services/booleanUtil';
import PaginationTable from '../../PortfolioDetail/PaginationTable/PaginationTable';
import { Pagination } from '@material-ui/lab';
import usePaginationSort from '../../../hooks/usePaginationSort';
import { ExcelDownloadButton } from '../../Util/ExcelDownloadButton/ExcelDownloadButton';
import useDataNormalize from '../../../hooks/useDataNormalize';
import { useSelector } from 'react-redux';

const headers = [
  'id',
  'Project Number',
  'Operation',
  'Name',
  'Division',
  'Progress in time',
  'Latest disbursement made',
  'Amount disbursed',
  'Undisbursed',
  'Performance',
];

const selectedKeys = [
  'id',
  'code',
  'loanNumber',
  'name',
  'division_code',
  'temporaryAdvance_temporaryAdvanceMade',
  'financialInformation_lastDisbursementInDays',
  'financialInformation_percentRealizedDisbursement',
  'financialInformation_pendingDisbursementAmount',
  'performanceClassification',
];

interface ReceivedProps {
  projects: Project[];
  setInFollow: any;
  sortTable: any;
  isAnnual: any;
  selectable?: boolean;
  withComments?: boolean;
  reviewStyle?: boolean;
  canCreateAccordance?: boolean;
  fromReview?: boolean;
  revision?: any;
  fnPaginacion?: () => number;
  initialPage?: number;
  numberOfPages?: number;
  numberOfProjects?: number;
}

type Props = ReceivedProps;

const selectableThead = reviewStyle => {
  return reviewStyle ? 'theader-selectable' : 'theader-text uppercase-text';
};

const selectableI = reviewStyle => {
  return reviewStyle ? { marginLeft: 9 } : {};
};

const tBodyClassName = isMobile => {
  return isMobile ? 'row-responsive-text' : 'row-text';
};

const headersList = (isAnnual, reviewStyle, canCreateAccordance) => {
  const list = [
    { sortValue: { concept: SortParameterProject.Code }, label: 'code' },
    { sortValue: { concept: SortParameterProject.Operation }, label: 'operation' },
    { sortValue: { concept: SortParameterProject.Name }, label: 'name' },
    { sortValue: { concept: SortParameterProject.Division }, label: 'division' },
    {
      sortValue: { concept: SortParameterProject.PercentTemporaryAdvanceMade },
      label: 'temporary_advance',
    },
    {
      sortValue: { concept: SortParameterProject.LastDisbursementInDays },
      label: 'last_accomplished_disbursement',
      hideOnCanCreateAccordance: true,
    },
    {
      sortValue: {
        concept: isAnnual
          ? SortParameterProject.PercentRealizedAnnualDisbursement
          : SortParameterProject.PercentRealizedDisbursement,
      },
      label: 'amount_disbursed',
    },
    {
      sortValue: {
        concept: isAnnual
          ? SortParameterProject.PendingDisbursementAnnualAmount
          : SortParameterProject.PendingDisbursementAmount,
      },
      label: 'undisbursed',
      hideOnCanCreateAccordance: true,
    },
    {
      sortValue: { concept: SortParameterProject.PmrValue },
      label: 'performance',
    },
  ];

  return canCreateAccordance ? list.filter(item => !item.hideOnCanCreateAccordance) : list;
};

export const ProjectsTable = (props: Props) => {
  const { t } = useTranslation();
  const isAnnual = props.isAnnual === 'annual' ? true : false;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const isTabProject = useSelector<any, any>(state => state.tabValue);

  const {
    selectable,
    withComments,
    reviewStyle,
    canCreateAccordance,
    fromReview,
    revision,
    fnPaginacion,
    initialPage,
    numberOfPages,
    numberOfProjects,
    projects
  } = props;
  const setInFollow = (projectId: number) => {
    props.setInFollow(projectId);
  };
  const sortTable = (sortValue: {}) => {
    props.sortTable(sortValue);
  };

  const { handlePaginationChange, handleSort, currentList, pagesCount, currentPage } = usePaginationSort({
    projects: projects,
    defaultSortCriteria: 'title',
    pageCount: rowsPerPage,
  });

  const flattenObject = (obj, parentKey = '', result = {}) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        let propName = parentKey ? parentKey + '_' + key : key;

        if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
          flattenObject(obj[key], propName, result);
        } else {
          result[propName] = obj[key];
        }
      }
    }
    return result;
  };

  const projectsFlatten = props.projects.map((projects: any) => flattenObject(projects));
  const data = useDataNormalize(headers, selectedKeys, projectsFlatten);

  return (
    <Fragment>
      <ExcelDownloadButton headers={headers} selectedKeys={selectedKeys} dataExcel={data} />
      <div className="table-idb">
        <>
          <table style={reviewStyle ? { borderCollapse: 'collapse' } : {}}>
            {isMobile() ? null : (
              <thead className={selectableThead(reviewStyle)} style={{ height: '88px', backgroundColor: '#F1F3F8' }}>
                <tr>
                  {selectable && <th></th>}
                  {headersList(isAnnual, reviewStyle, canCreateAccordance).map(item => {
                    if (item.sortValue) {
                      return (
                        <th key={item.label} id={item.label} onClick={() => sortTable(item.sortValue || {})}>
                          <div className='text-head'>
                            <div>{t(item.label)}</div>
                            <div><i style={selectableI(reviewStyle)} className="sort-icon"></i></div>
                          </div>
                        </th>
                      );
                    } else {
                      return (
                        <th key="not-sorted" className="not-sorted">
                          {t(item.label)}
                        </th>
                      );
                    }
                  })}
                  {withComments && <th></th>}
                  <th></th>
                  <th></th>
                </tr>
              </thead>
            )}
            <tbody className={tBodyClassName(isMobile())}>
              {currentList &&
                currentList.map((project: Project) => (
                  <ProjectsTableRow
                    key={project.id}
                    project={project}
                    setInFollow={setInFollow}
                    selectable={selectable}
                    withComments={withComments}
                    canCreateAccordance={canCreateAccordance}
                    fromReview={fromReview}
                    revision={revision}
                  />
                ))}
            </tbody>
          </table>
        </>

      </div>
      {isTabProject === 1 ?
        <div className="pagination-style pagination-right">
          <Pagination count={numberOfPages} page={initialPage} onChange={fnPaginacion} />
        </div> :
        <div className="pagination-style">
          <PaginationTable fnSetRows={setRowsPerPage} rows={rowsPerPage} numProyects={projects.length} />
          <Pagination count={pagesCount} page={currentPage} onChange={handlePaginationChange} />
        </div>
      }

    </Fragment>
  );
};
