export const styles = {
  content: {
    marginLeft: 'auto',
    marginRight: 0,
  },
  overlay: {
    backgroundColor: 'none',
    zIndex: 4,
  },
};
