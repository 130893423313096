import { LoadingState } from './../../models/EarlyWarningsSection';
import { IEarlyWarningsProjects } from '../../models/EarlyWarningsSection';
import { RESET_EARLY_WARNINGS_PROJECTS, SET_EARLY_WARNINGS_PROJECTS, UPDATE_LOADING_STATE } from './types';

export function setEarlyWarningsProjects(data: IEarlyWarningsProjects) {
  return {
    type: SET_EARLY_WARNINGS_PROJECTS,
    payload: data,
  };
}

export function resetEarlyWarningsProjects() {
  return {
    type: RESET_EARLY_WARNINGS_PROJECTS,
  };
}

export function updateLoadingStateEarlyWarningsProjects(loadingState: LoadingState) {
  return {
    type: UPDATE_LOADING_STATE,
    payload: loadingState,
  };
}
