import React from 'react';
import './CalendarFiltersMobile.scss';
import { useTranslation } from 'react-i18next';

type ButtonProps = {
  active: boolean;
  customText: string;
  onClick: any;
  activeColor: string;
};

export const CalendarFiltersButton = (props: ButtonProps) => {
  const { t } = useTranslation();
  const { active, customText, onClick, activeColor } = props;
  return (
    <div
      className="calendar-filters-button"
      onClick={onClick}
      style={active ? { backgroundColor: activeColor, boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)', border: 'none' } : {}}
    >
      <span className="text" style={active ? { color: '#fff' } : {}}>
        {t(customText)}
      </span>
    </div>
  );
};

type Props = {
  disbursements: boolean;
  acquisitions: boolean;
  // activities: boolean;
  accordancesMonitor: boolean;
  accordancesRevision: boolean;
  interGroup: boolean;
  supervisoryAction: boolean;
  clickDisbursements: Function;
  clickAcquisitions: Function;
  // clickActivities: Function;
  clickMonitor: Function;
  clickRevision: Function;
  clickInterGroup: Function;
  clickSupervisoryAction: Function;
};

const CalendarFiltersMobile = (props: Props) => {
  const {
    disbursements,
    acquisitions,
    // activities,
    accordancesMonitor,
    accordancesRevision,
    interGroup,
    supervisoryAction,
    clickDisbursements,
    clickAcquisitions,
    // clickActivities,
    clickMonitor,
    clickRevision,
    clickInterGroup,
    clickSupervisoryAction,
  } = props;
  return (
    <div className="calendar-filters-mobile-container">
      <CalendarFiltersButton
        active={disbursements}
        onClick={clickDisbursements}
        activeColor={'#91DECD'}
        customText={'disbursements'}
      />
      <CalendarFiltersButton
        active={acquisitions}
        onClick={clickAcquisitions}
        activeColor={'#CCB0F5'}
        customText={'acquisitions'}
      />
      {/*  <CalendarFiltersButton
        active={activities}
        onClick={clickActivities}
        activeColor={'#A6D277'}
        customText={'activities'}
      /> */}
      <CalendarFiltersButton
        active={accordancesMonitor}
        onClick={clickMonitor}
        activeColor={'#F9C268'}
        customText={'monitor_accordances'}
      />
      <CalendarFiltersButton
        active={accordancesRevision}
        onClick={clickRevision}
        activeColor={'#E092A3'}
        customText={'revision_accordances'}
      />
      <CalendarFiltersButton
        active={interGroup}
        onClick={clickInterGroup}
        activeColor={'#7A629B'}
        customText={'inter_group_accordances'}
      />
      <CalendarFiltersButton
        active={supervisoryAction}
        onClick={clickSupervisoryAction}
        activeColor={'#79B9FF'}
        customText={'supervisory_actions'}
      />
    </div>
  );
};

export default CalendarFiltersMobile;
