import React from 'react';
import Select from 'react-select';
import { multiSelectStyle, DropdownIndicator } from '../FilterComponent.Styles';
import SelectOption from '../../../models/SelectOption';
import { useTranslation } from 'react-i18next';
import styles from './CTFilters.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { dispatchCTFiltersState } from '../../../redux/actions/filter';
import { CTFiltersEnum } from '../../../models/CTFilters';
import { useFrontEndBasedSelectedsOptions } from '../../../hooks/useFrontEndBasedSelectedsOptions';

type Props = {
  indicatorCall?: boolean;
};

const CTFilters = (props: Props) => {
  const { indicatorCall } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filter = useSelector<any, any>(state => (indicatorCall ? state.filterIndicators : state.filter));

  const { executionOptions, taxonomyOptions } = useFrontEndBasedSelectedsOptions();

  const handleSelectChange = (value: SelectOption, filterType: number) => {
    dispatch(dispatchCTFiltersState(filter, value, filterType, indicatorCall));
  };

  return (
    <div className={styles.filtersContainer}>
      <div className={styles.filterContainer}>
        <h4>{t('execution')}</h4>
        <Select
          id="executionSelect"
          components={{ DropdownIndicator }}
          name="executions"
          options={executionOptions}
          className="basic-multi-select"
          styles={multiSelectStyle}
          onChange={value => handleSelectChange(value, CTFiltersEnum.EXECUTION_FILTER)}
          defaultValue={filter.common.executionFilter}
          placeholder={t('select')}
          isClearable
        />
      </div>
      <div className={styles.filterContainer}>
        <h4>{t('taxonomy')}</h4>
        <Select
          id="taxonomySelect"
          components={{ DropdownIndicator }}
          name="taxonomy"
          options={taxonomyOptions}
          className="basic-multi-select"
          styles={multiSelectStyle}
          onChange={value => handleSelectChange(value, CTFiltersEnum.TAXONOMY_FILTER)}
          defaultValue={filter.common.taxonomyFilter}
          placeholder={t('select')}
          isClearable
        />
      </div>
    </div>
  );
};

export default CTFilters;
