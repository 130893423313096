import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ContentManagerTable from '../ContentManagerTable/ContentManagerTable';
import CustomDatePicker from '../../Util/CustomDatePicker/CustomDatePicker';
import { dataTypes, tableRowTypeKeys } from '../ContentManagerKeys';
import { getMaintenanceService, updateMaintenanceService } from './../../../api/contentManager';
import { contentsTabLoaderStyles, getValidDate, hasDateError } from '../../Util/ContentManager/ContentManager';
import { isNil } from 'lodash';
import moment from 'moment';
import ContentManagerLoader from '../ContentManagerLoader/ContentManagerLoader';

const dateTimeFormat = 'DD/MM/YYYY - HH:mm';
const currentDate = new Date();
const getHasMaintenance = (startDate, endDate) => (!isNil(startDate) && !isNil(endDate) ? true : false);
const getMaintenanceText = (startDate, endDate, t) => {
  return `${moment.utc(startDate).local().format(dateTimeFormat)} hs  ${t('contents_maintenance_to')} ${moment
    .utc(endDate)
    .local()
    .format(dateTimeFormat)} hs`;
};

export default function Maintenance({ displayGenericErrorMessage }) {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(true);
  const [hasMaintenance, setHasMaintenance] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState(false);
  const [formFields, setFormFields] = React.useState<any>({
    startDate: null,
    endDate: null,
  });

  const handleUpdateMaintenanceService = async () => {
    try {
      const { startDate, endDate } = formFields;
      if (!hasDateError(startDate, endDate)) {
        setErrorMessage(false);
        await updateMaintenanceService({
          startDate: new Date(startDate).toISOString(),
          endDate: new Date(endDate).toISOString(),
        });
        await getMaintenance();
      } else {
        setErrorMessage(true);
      }
    } catch {
      displayGenericErrorMessage(true);
    }
  };

  const getMaintenance = async () => {
    try {
      const maintenanceResponse = await getMaintenanceService();
      const { startDate, endDate } = maintenanceResponse;
      setFormFields({
        startDate: getValidDate(startDate),
        endDate: getValidDate(endDate),
      });
      setHasMaintenance(getHasMaintenance(startDate, endDate));
      setLoading(false);
    } catch {
      displayGenericErrorMessage();
    }
  };

  useEffect(() => {
    getMaintenance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? (
    <ContentManagerLoader styles={contentsTabLoaderStyles} />
  ) : (
    <div className="content-manager__container">
      <div className="content-manager__container__title">{t('contents_maintenance_title')}</div>
      <div className="content-manager__container__subtitle">{t('contents_maintenance_subtitle')}</div>
      <div className="content-manager__container__table">
        {hasMaintenance ? (
          <ContentManagerTable
            type={tableRowTypeKeys.deleteOnly}
            items={[
              {
                id: 0,
                text: getMaintenanceText(formFields.startDate, formFields.endDate, t),
                type: dataTypes.maintenance,
              },
            ]}
            displayGenericErrorMessage={displayGenericErrorMessage}
            updateFunction={getMaintenance}
          />
        ) : (
          <div className="content-manager__container__body">
            <div className="content-manager__date-container">
              <CustomDatePicker
                date={formFields.startDate}
                label={t('contents_maintenance_date_from')}
                setDate={date => setFormFields({ ...formFields, startDate: date })}
                minDate={currentDate}
              />
              <CustomDatePicker
                date={formFields.startDate}
                label={t('contents_maintenance_hour')}
                setDate={date => setFormFields({ ...formFields, startDate: date })}
                minDate={currentDate}
                showTimeSelect
                showTimeSelectOnly
                dateFormat={'hh:mm'}
              />
              <CustomDatePicker
                date={formFields.endDate}
                label={t('contents_maintenance_date_to')}
                setDate={date => setFormFields({ ...formFields, endDate: date })}
                minDate={currentDate}
              />
              <CustomDatePicker
                date={formFields.endDate}
                label={t('contents_maintenance_hour')}
                setDate={date => setFormFields({ ...formFields, endDate: date })}
                minDate={currentDate}
                dateFormat={'hh:mm'}
                showTimeSelect
                showTimeSelectOnly
              />
            </div>
            {errorMessage && (
              <div className="content-manager__error-message">{t('contents_maintenance_dates_error_message')}</div>
            )}
            <div className="content-manager__container__actions">
              <button
                style={{ width: '175px' }}
                className="save-button-container"
                onClick={() => handleUpdateMaintenanceService()}
              >
                {t('contents_maintenance_save_button_label')}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
