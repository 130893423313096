import { buildPost, buildPut, buildDelete, buildGet } from './genericMethods';
import * as config from './config.json';
import { dataTypes } from '../components/ContentManager/ContentManagerKeys';
import { store } from '../../src/redux/store';

export const defaultPaginationData = {
  pageNumber: 1,
  itemsPerPage: 5,
};

export const defaultSortData = {
  orderDesc: true,
  sortField: 0,
};

export const defaultDelegationFilters = {
  countryId: null,
  roleId: null,
  userName: null,
  ...defaultSortData,
  ...defaultPaginationData,
};

export const getFaqListService = paginationData => {
  const url = `${config.QUESTION}/list`;
  return buildPost(url, paginationData);
};

export const postFaqListItem = faqItem => {
  const url = `${config.QUESTION}`;
  return buildPost(url, faqItem);
};

export const updateFaqListItem = faqItem => {
  const url = `${config.QUESTION}/${faqItem.id}`;
  return buildPut(url, faqItem);
};

export const deleteFaqListItem = faqItem => {
  const url = `${config.QUESTION}/${faqItem.id}`;
  return buildDelete(url);
};

export const addCategory = data => {
  const url = `${config.ACCORDANCE_CATEGORY}`;
  return buildPost(url, data);
};
export const addCriticalIssue = data => {
  const url = `${config.ACCORDANCE_CRITICAL_ISSUE}`;
  return buildPost(url, data);
};
export const addPossibleAction = data => {
  const url = `${config.ACCORDANCE_POSIBLE_ACTION}`;
  return buildPost(url, data);
};
export const getTutorialListService = paginationData => {
  const url = `${config.TUTORIALS}/list`;
  return buildPost(url, paginationData);
};

export const postTutorialListItem = tutorialItem => {
  const url = `${config.TUTORIALS}`;
  return buildPost(url, tutorialItem);
};
export const updateTutorialListItem = tutorialItem => {
  const url = `${config.TUTORIALS}/${tutorialItem.id}`;
  return buildPut(url, tutorialItem);
};

export const deleteTutorialListItem = tutorialItem => {
  const url = `${config.TUTORIALS}/${tutorialItem.id}`;
  return buildDelete(url);
};

export const getSystemMessageListItem = paginationData => {
  const url = `${config.SYSTEM_MESSAGE}/list`;
  return buildPost(url, paginationData);
};

export const postSystemMessageListItem = systemMessageItem => {
  const url = `${config.SYSTEM_MESSAGE}`;
  return buildPost(url, systemMessageItem);
};

export const updateSystemMessageListItem = systemMessageItem => {
  const url = `${config.SYSTEM_MESSAGE}/${systemMessageItem.id}`;
  return buildPut(url, systemMessageItem);
};

export const deleteSystemMessageListItem = systemMessageItem => {
  const url = `${config.SYSTEM_MESSAGE}/${systemMessageItem.id}`;
  return buildDelete(url);
};

export const getActiveMessageService = encodedName => {
  const url = `${config.SYSTEM_MESSAGE}/active/${encodedName}`;
  return buildGet(url);
};

export const postNotification = postNotificationItem => {
  const url = `${config.CONTENT_MANAGER_NOTIFICATIONS}/send`;
  return buildPost(url, postNotificationItem);
};

export const getTourListService = paginationData => {
  const url = `${config.TOUR}/list`;
  return buildPost(url, { ...paginationData, itemsPerPage: 6 });
};

export const getTourListItem = tourListItem => {
  const url = `${config.TOUR}/list/${tourListItem.Item}`;
  return buildGet(url);
};

export const postTourListItem = postTourItem => {
  const url = `${config.TOUR}`;
  return buildPost(url, postTourItem);
};

export const deleteTourListItem = tourItem => {
  const url = `${config.TOUR}/${tourItem.id}`;
  return buildDelete(url);
};

export const updateTourListItem = tourListItem => {
  const url = `${config.TOUR}/${tourListItem.id}`;
  return buildPut(url, tourListItem);
};

export const getMaintenanceService = () => {
  const url = `${config.MAINTENANCE}`;
  return buildGet(url);
};

export const updateMaintenanceService = maintenanceItem => {
  const url = `${config.MAINTENANCE}`;
  return buildPut(url, maintenanceItem);
};

export const getTranslationsGroups = () => {
  return buildGet(config.TRANSLATIONS_GROUP);
};

export const getTranslationsByGroup = (data: any) => {
  return buildPost(config.TRANSLATIONS_GROUP, data);
};

export const updateTranslations = (data: any) => {
  return buildPut(config.TRANSLATIONS, data);
};

export const getTranslationsIndicator = () => {
  return buildGet(config.TRANSLATIONS_INDICATOR);
};

export const updateIndicatorsTranslations = (data: any) => {
  return buildPut(config.TRANSLATIONS_INDICATOR, data);
};

export const getHelpRequestsService = paginationData => {
  const url = `${config.HELP_REQUESTS}/list`;
  return buildPost(url, paginationData);
};

export const postHelpRequestAnswer = postHelpRequest => {
  const url = `${config.HELP_REQUESTS}/answer`;
  return buildPost(url, postHelpRequest);
};

export const getLeavesService = sortData => {
  const url = `${config.LEAVE}/list`;
  return buildPost(url, sortData);
};

export const getUsersByCountryService = countryId => {
  const url = `${config.LEAVE}/user/${countryId}`;
  return buildGet(url);
};

export const postLeaveListItem = (leaveListItem: any) => {
  const url = `${config.LEAVE}`;
  return buildPost(url, leaveListItem);
};

export const deleteLeaveListItem = leaveId => {
  const url = `${config.LEAVE}/${leaveId}`;
  return buildDelete(url);
};

export const updateLeaveListItem = leaveItem => {
  const url = `${config.LEAVE}`;
  return buildPut(url, leaveItem);
};

export const getDelegationsService = filter => {
  const url = `${config.DELEGATION}/delegation/filter`;
  return buildPost(url, filter);
};

export const getDelegationsUsers = () => {
  const url = `${config.DELEGATION}/delegation/user`;
  return buildGet(url);
};

export const getUserToDelegate = id => {
  const url = `${config.DELEGATION}/delegation/user/${id}`;
  return buildGet(url);
};

export const postDelegationListItem = delegationListItem => {
  const url = `${config.DELEGATION}/delegation`;
  return buildPost(url, delegationListItem);
};

export const updateDelegationListItem = delegationListItem => {
  const url = `${config.DELEGATION}/delegation`;
  return buildPut(url, delegationListItem);
};

export const deleteDelegationListItem = id => {
  const url = `${config.DELEGATION}/delegation/${id}`;
  return buildDelete(url);
};

export const getCategoryListService = paginationData => {
  const url = config.LIST_CATEGORY;
  return buildPost(url, paginationData);
};

export const getCriticalIssueListService = paginationData => {
  const url = config.LIST_CRITICAL_ISSUE;
  return buildPost(url, paginationData);
};

export const getPossibleActionListService = paginationData => {
  const url = config.LIST_POSSIBLE_ACTION;
  return buildPost(url, paginationData);
};

export const updateCategory = payload => {
  const url = config.ACCORDANCE_CATEGORY;
  return buildPut(url, payload);
};
export const updateCriticalIssue = payload => {
  const url = config.ACCORDANCE_CRITICAL_ISSUE;
  return buildPut(url, payload);
};
export const updatePossibleAction = payload => {
  const url = config.ACCORDANCE_POSIBLE_ACTION;
  return buildPut(url, payload);
};

export const deleteEntity = (dataType, item) => {
  const CONFIG_VALUE =
    dataType === dataTypes.catergories
      ? config.DELETE_CATEGORY
      : dataType === dataTypes.criticalIssue
        ? config.DELETE_CRITICAL_ISSUE
        : config.DELETE_POSSIBLE_ACTION;
  const url = `${CONFIG_VALUE}/${item.id}`;

  return buildDelete(url);
};

export const updateRecommendedIndicators = (data: any) => {
  return buildPut(config.RECOMMENDED_INDICATOR, data);
};

export const getIndicatorsRecommended = projectId => {

  const indicatorPayload = store.getState().indicatorPayload.indicatorPayload;
  let returnedParams = { ...indicatorPayload };

  returnedParams['indicatorId'] = parseInt(`${projectId}.`);
  const url = config.RECOMMENDED_INDICATOR;
  return buildPost(url, returnedParams);
};


export const postIndicatorsRecommended = indicator => {
  const url = `${config.RECOMMENDED_INDICATOR}`;
  return buildPost(url, indicator);
};

