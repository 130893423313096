export enum TrackedActions {
  portfolioClicked = 'portfolioClicked',
  projectsClicked = 'projectsClicked',
  reportsClicked = 'reportsClicked',
  reviewsClicked = 'reviewsClicked',
  projectDetailsFromCardClicked = 'projectDetailsFromCardClicked',
  projectDetailsFromListClicked = 'projectDetailsFromListClicked',
  indicatorDetailsClicked = 'indicatorDetailsClicked',
  projectDetailsTabClicked = 'projectDetailsTabClicked',
  generateReportClicked = 'generateReportClicked',
  filterClicked = 'filterClicked',
  followProjectFromListClicked = 'followProjectFromListClicked',
  followProjectFromDetailsClicked = 'followProjectFromDetailsClicked',
  newAccordanceClicked = 'newAccordanceClicked',
  createAccordanceClicked = 'createAccordanceClicked',
  searchProject = 'searchProject',
  projectLinkedOutlook = 'projectLinkedOutlook',
  disbursementDataAnnual = 'disbursementDataAnnual',
  disbursementDataAccumulated = 'disbursementDataAccumulated',
  filterSectionClicked = 'filterSectionClicked',
  divisionFilterChangedFromPortfolio = 'divisionFilterChangedFromPortfolio',
  pmrFilterChangedFromPortfolio = 'pmrFilterChangedFromPortfolio',
  timeWithoutDisbursementFilterChangedFromPortfolio = 'timeWithoutDisbursementFilterChangedFromPortfolio',
  projectAgeFilterChangedFromPortfolio = 'projectAgeFilterChangedFromPortfolio',
  disbusementProjectionFilterChangedFromPortfolio = 'disbusementProjectionFilterChangedFromPortfolio',
  countryFilterChangedFromPortfolio = 'countryFilterChangedFromPortfolio',
  regionFilterChangedFromPortfolio = 'regionFilterChangedFromPortfolio',
  divisionFilterChangedFromProject = 'divisionFilterChangedFromProject',
  pmrFilterChangedFromProject = 'pmrFilterChangedFromProject',
  timeWithoutDisbursementFilterChangedFromProject = 'timeWithoutDisbursementFilterChangedFromProject',
  projectAgeFilterChangedFromProject = 'projectAgeFilterChangedFromProject',
  disbusementProjectionFilterChangedFromProject = 'disbusementProjectionFilterChangedFromProject',
  countryFilterChangedFromProject = 'countryFilterChangedFromProject',
  regionFilterChangedFromProject = 'regionFilterChangedFromProject',
  indicatorCreated = 'indicatorCreated',
  indicatorRemoved = 'indicatorRemoved',
  addToPorftfolioClicked = 'addToPorftfolioClicked',
  planningInformationAdded = 'planningInformationAdded',
  reportOptionClicked = 'reportOptionClicked',
  projectDetailsBaseInfoTabClicked = 'projectDetailsBaseInfoTabClicked',
  projectDetailsPlanningTabClicked = 'projectDetailsPlanningTabClicked',
  projectDetailsSupervisionTabClicked = 'projectDetailsSupervisionTabClicked',
  projectDetailsAccordanceTabClicked = 'projectDetailsAccordanceTabClicked',
  projectDetailsFinancialTabClicked = 'projectDetailsFinancialTabClicked',
  projectDetailsAdquisitionTabClicked = 'projectDetailsAdquisitionTabClicked',
  projectDetailsRiskTabClicked = 'projectDetailsRiskTabClicked',
  predefinedReportClicked = 'predefinedReportClicked',
  customReportClicked = 'customReportClicked',
  portfolioTypeFilterProjectChanged = 'portfolioTypeFilterProjectChanged',
  earlyWarningsClicked = 'earlyWarningsClicked',
  regionFilterChangedFromEarlyWarnings = 'regionFilterChangedFromEarlyWarnings',
  countryFilterChangedFromEarlyWarnings = 'countryFilterChangedFromEarlyWarnings',
  divisionFilterChangedFromEarlyWarnings = 'divisionFilterChangedFromEarlyWarnings',
  pmrFilterChangedFromEarlyWarnings = 'pmrFilterChangedFromEarlyWarnings',
  timeWithoutDisbursementFilterChangedFromEarlyWarnings = 'timeWithoutDisbursementFilterChangedFromEarlyWarnings',
  projectAgeFilterChangedFromEarlyWarnings = 'projectAgeFilterChangedFromEarlyWarnings',
  disbusementProjectionFilterChangedFromEarlyWarnings = 'disbusementProjectionFilterChangedFromEarlyWarnings',
  menuTabClicked = 'menuTabClicked',
  addCustomTabClicked = 'addCustomTabClicked',
  generateReportButtonClicked = 'generateReportButtonClicked',
  filterClickedButton = 'filterClickedButton',
  addIndicatorClicked = 'addIndicatorClicked',
}
