import React, { createContext, useContext, useState } from 'react';
import { getEvents, getValidity } from '../components/ProjectDetail/ProjectTabs/Accordances/AccordanceTrello/util';

interface defaultValueProps {
  events: string[];
  validity: any;
  getDataEvent?: any;
  handleCardClick?: any;
  setEventId?: any;
  eventId?: any;
  getDataValidity?: any;
}

const defaultValue: defaultValueProps = {
  events: [],
  validity: [],
};

const EventContext = createContext(defaultValue);

export const useEventContext = () => useContext(EventContext);

export const EventProvider = ({ children }) => {
  const [events, setEvents] = useState<any>();
  const [eventId, setEventId] = useState<any>();
  const [validity, setValidity] = useState<any>();

  const handleCardClick = (cardId, metadata, laneId) => {
    setEventId(cardId);
  };

  const getDataEvent = async () => {
    setEvents(await getEvents(eventId));
  };

  const getDataValidity = async currentProjectId => {
    setValidity(await getValidity(eventId, currentProjectId));
  };

  const values = {
    events,
    validity,
    getDataEvent,
    handleCardClick,
    getDataValidity,
    setEventId,
    eventId,
  };

  return <EventContext.Provider value={values}>{children}</EventContext.Provider>;
};
