export enum CardTypesEnum {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
  VERTICAL_SIMPLE = 'verticalSimple',
  RADIAL = 'radial',
  TEXT = 'text',
  SUMMARY = 'summary',
  DOUGHNUT = 'doughnutChart',
  PROGRESS = 'progress',
  LIST = 'list',
  HALF_SIZE_VERTICAL = 'halfSizeVertical',
  CALENDAR = 'calendar',
  DOUBLE_DATA_NOT_STACKED = 'doubleDataNotStacked',
  RADIAL_TO_BARCHART = 'radialToBarChart',
  PROGRESS_CHART = 'progressChart',
  BUBBLE_CARD_TYPE = 'bubbleCardType',
  SIMPLE_SUMMARY = 'simpleSummary',
  TEXT_DOUBLE = 'text_double',
  MIXED_VERTICAL_BAR_LINE = 'mixedVerticalBarLine',
  VERTICAL_SKYBLUE_ORANGE_BAR = 'verticalSkyblueOrangeBar',
  MULTI_LINE = 'multiLine',
  DOUBLE_DATA_PROGRESS_CHART = 'doubleDataProgressChart',
  DOUBLE_DATA_NOT_STACKED_MOUNTED_BARS = 'doubleDataNotStackedMountedBars',
}
