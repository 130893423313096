import React, { useEffect, useState } from 'react';
import './PortfolioProjectsDoughnutDetailContent.scss';
import { DoughnutChartDetailContent } from '../DoughnutChartDetailContent/DoughnutChartDetailContent';
import { WorkWithDoughnutChart } from '../WorkWithDoughnutChart/WorkWithDoughnutChart';
import { getDoughnutTotalProjects } from '../PortfolioDetailsUtil';
import { IndicatorKeyEnum } from '../../../models/IndicatorKeyEnum';
import { EfasConcepts } from '../../../models/EfasConcepts';
import { QCalificationOrder } from '../../../models/EsgQCalification';
import { FiscalYearFilterChart } from '../FiscalYearFilterChart/FiscalYearFilterChart';
import { useDispatch, useSelector } from 'react-redux';
import { setIndicatorDetailFilterChart } from '../../../redux/actions/indicatorDetailFilters';

type Props = {
  currentIndicator: any;
  headerTitle?: any;
};
export const PortfolioProjectsDoughnutDetailContent = ({ currentIndicator, headerTitle }: Props) => {
  const filterIndicators = useSelector<any, any>(state => state.indicatorDetailFilter);
  const [filterTable, setFilterTable] = useState({ label: '', totalProjects: 0 });
  const [fiscalYearFiltersState, setFiscalYearFiltersState] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    const firstConceptWithInfo = setFirstConceptWithInfo(currentIndicator);
    setFilterTable({ label: firstConceptWithInfo, totalProjects: getDoughnutTotalProjects(currentIndicator) });
    headerTitle(true, setDetailTitle(currentIndicator.indicatorKey));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterValues = (value, totalProjects) => {
    setFilterTable({ label: value.label, totalProjects });
  };

  return (
    <div className="portfolio-projects-doughnut-detail-content">
      {renderChart(currentIndicator, filterValues, setFiscalYearFiltersState, dispatch, filterIndicators)}

      <div className="table-content">
        <WorkWithDoughnutChart
          currentIndicator={currentIndicator}
          conceptFilter={filterTable}
          fiscalYearFilters={fiscalYearFiltersState}
        />
      </div>
    </div>
  );
};

const renderChart = (currentIndicator, filterValues, setFiscalYearFiltersState, dispatch, filterIndicators) => {
  if (currentIndicator.indicatorKey === IndicatorKeyEnum.DISTRIBUTION_ACCORDING_TO_LATEST_EFAS) {
    const fiscalYearFilterValues = filters => {
      setFiscalYearFiltersState(filters);
      dispatchIndicatorFilterFiscalYear(dispatch, filterIndicators, filters);
    };
    return (
      <div className="chart-and-filter-content">
        <div className="filter-container">
          <FiscalYearFilterChart
            indicatorKey={currentIndicator.indicatorKey}
            fiscalYearFilter={fiscalYearFilterValues}
          />
          <DoughnutChartDetailContent currentIndicator={currentIndicator} filterValues={filterValues} />
        </div>
      </div>
    );
  } else {
    return (
      <div className="chart-content">
        <DoughnutChartDetailContent currentIndicator={currentIndicator} filterValues={filterValues} />
      </div>
    );
  }
};

const setDetailTitle = indicatoKey => {
  switch (indicatoKey) {
    case IndicatorKeyEnum.DISTRIBUTION_ACCORDING_TO_LATEST_EFAS:
      return 'distribution_of_the_portfolio_according_to_the_opinion_of_the_last_afs';
    case IndicatorKeyEnum.PMR_STATE_DISTRIBUTION:
      return 'portfolio_distribution_according_to_pmr';
    case IndicatorKeyEnum.ESG_DISTRIBUTION_ACCORDING_TO_ENVIRONMENTAL_AND_SOCIAL_CATEGORY:
      return 'distribution_according_to_environmental_and_social_category';
    case IndicatorKeyEnum.TCP_PERFORMANCE:
      return 'cts_perfomance_rating';

    default:
      return '';
  }
};

const setFirstConceptWithInfo = currentIndicator => {
  switch (currentIndicator.indicatorKey) {
    case IndicatorKeyEnum.DISTRIBUTION_ACCORDING_TO_LATEST_EFAS:
      return EfasConcepts[currentIndicator.data.datasets[0].data.findIndex(firstWithInfo => firstWithInfo !== 0)];
    case IndicatorKeyEnum.PMR_STATE_DISTRIBUTION:
      return currentIndicator.data.datasets[0].data.findIndex(firstWithInfo => firstWithInfo !== 0);
    case IndicatorKeyEnum.ESG_DISTRIBUTION_ACCORDING_TO_ENVIRONMENTAL_AND_SOCIAL_CATEGORY:
      return QCalificationOrder[currentIndicator.data.datasets[0].data.findIndex(firstWithInfo => firstWithInfo !== 0)];
    case IndicatorKeyEnum.TCP_PERFORMANCE:
      return 1;
    default:
      return '';
  }
};

const dispatchIndicatorFilterFiscalYear = (d, filterIndicators, fiscalYear) => {
  d(
    setIndicatorDetailFilterChart({
      ...filterIndicators.indicatorCharts,
      fiscalYear,
    }),
  );
};
