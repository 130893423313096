import React from 'react';
import styles from './SummaryHeaderSingleValue.module.scss';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { formatMillionNumber } from '../../../services/numberUtil';
import { addMillionsLabel } from '../../../services/stringUtil';
import { renderDecimalNumber } from '../../../services/booleanUtil';
import LinkText from '../../Util/Buttons/LinkText/LinkText';
import './SummaryHeaderSingleValue.scss';
import { ReactComponent as IconFilter } from '../../../assets/icons/u_filters-v.svg';

const { buttonContainer, buttonContainerSelected } = styles;
type Props = {
  value: any;
  title: string;
  millionLogic?: boolean;
  isPercentage?: boolean;
  isYear?: boolean;
  isMonth?: boolean;
  littleValue?: boolean;
  hideValue?: boolean;
  customValueStyle?: any;
  customSubtilteStyle?: any;
  customLinkStyle?: any;
  isLink?: any;
  isSelected?: any;
  callbackLink?: any;
  customStyle?: any;
  isButton?: boolean;
  isButtonSelected?: boolean;
};
const SummaryHeaderSingleValue = ({
  value,
  title,
  millionLogic,
  isPercentage,
  isYear,
  isMonth,
  littleValue,
  hideValue,
  customValueStyle,
  customSubtilteStyle,
  customLinkStyle,
  isLink,
  isSelected,
  callbackLink,
  isButton,
  isButtonSelected,
}: Props) => {
  const { t } = useTranslation();
  const handleClickLinkText = () => {
    callbackLink();
  };

  return (
    <div
       className={`${handleContainerClass(isButton, isButtonSelected, hideValue)} filters-cards`}
      onClick={() => { handleButtonClick(callbackLink, isButton); }}
    >
      <div>
        <div className='heading-filter'>
          {renderValue(millionLogic, value, isYear, isPercentage)}
          {renderPercentage(isPercentage)}
          {renderYear(isYear, t, value)}
          {renderMonth(isMonth, t, value)}
          {renderMillionValueText(millionLogic, value, littleValue)}
        </div>
        {displayLink(isLink, isSelected, value) ? (
          <div className='subtitle-filter'>
            <LinkText
              onClick={handleClickLinkText}
              label={t(title)}
            />
          </div>
        ) : (
          <div className='subtitle-filter'>
            {t(title)}
          </div>
        )}
      </div>
      <div className='icon-filter'>
        <IconFilter />
      </div>
    </div>
  );
};

const displayLink = (isLink, isSelected, value) => {
  return isLink && !isSelected && value !== 0;
};

const renderPercentage = isPercentage => isPercentage && <span className={styles.simbolsValue}>%</span>;
const renderYear = (isYear, t, value) =>
  isYear && <span className={styles.simbolsValue}>{value > 1 ? t('years') : t('year')}</span>;

const renderMonth = (isMonth, t, value) =>
  isMonth && <span className={styles.simbolsValue}>{value > 1 ? t('months') : t('month')}</span>;
const renderValue = (millionLogic, value, isYear, isPercentage) =>
  millionLogic ? formatNumberSummaryIndicator(value) : isYear || isPercentage ? replaceDecimalDot(value) : value;

const renderMillionValueText = (millionLogic, value, littleValue) =>
  millionLogic && (
    <span className={littleValue ? styles.simbolsLittleValue : styles.simbolsValue}>{addMillionsLabel(value)}</span>
  );

const formatNumberSummaryIndicator = number => (
  <NumberFormat
    value={formatMillionNumber(number)}
    displayType={'text'}
    className={'value'}
    thousandSeparator={'.'}
    decimalSeparator={','}
    decimalScale={renderDecimalNumber(formatMillionNumber(number)) ? 1 : 0}
  />
);

const replaceDecimalDot = value => {
  if (typeof value !== 'number' && typeof value !== 'string') {
    return '-';
  }
  const strValue = typeof value === 'number' ? value.toFixed(1).toString() : value;
  const replacedDotValue = strValue.replace(/\./g, ',');
  const splitText = replacedDotValue.split(',');
  return splitText[1] !== '0' ? replacedDotValue : splitText[0];
};
export default SummaryHeaderSingleValue;

const handleButtonClick = (callbackLink, isButton) => {
  if (isButton) {
    callbackLink();
  }
};

const handleContainerClass = (isButton, isButtonSelected, hideValue) => {
  if (isButton) {
    return isButtonSelected ? buttonContainerSelected : buttonContainer;
  }
  return hideValue ? styles.summaryHideValue : styles.summaryValuesContainer;
};
