import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledTableCellHeader } from '../PorfolioTableShared.Styles';
import { ReactComponent as IconSort } from '../../../assets/icons/u_sort.svg';  


type Props = {
  havePmr?: boolean;
  haveTcdi?: boolean;
  sortTable: Function;
  projects: any;
};

export const LastColumnHeader = ({ havePmr, haveTcdi, sortTable, projects }: Props) => {
  const { t } = useTranslation();

  const returnedColumnHeader = () => {
    if (havePmr || haveTcdi) {
      return (
        <StyledTableCellHeader align="left" onClick={() => sortTable(projects, 'field1', 'string')}>
           <div className="text-head">
          {`${havePmr ? t('pmr_initials') : ''}${havePmr && haveTcdi ? '/' : ''}${haveTcdi ? t('tcdi_initials') : ''}`}
          <div><IconSort className='sortIcon' /></div>
          </div>
        </StyledTableCellHeader>
      );
    } else {
      return (
        <StyledTableCellHeader align="center" onClick={() => sortTable(projects, 'field1', 'money')}>
          {`${t('amount_to_be_disbursed')}  ${t('dolar_sign_parentesis')}`} <i className="sort-icon"></i>
        </StyledTableCellHeader>
      );
    }
  };

  return returnedColumnHeader();
};
