import { EarlyWarningButtonType } from './EarlyWarningsSection';

export enum IconCode {
  GREEN,
  YELLOW,
  RED,
  SATISFACTORY,
  PARTIALLY_SATISFACTORY,
}

export interface TabFilterOption {
  id: number;
  projectQuantity: number;
  projectLabel: any;
  filterIcon: IconCode;
  filterLabel: string;
  selected: boolean;
}

export interface FilterConfigValues {
  id: number;
  filterIcon: IconCode;
  filterLabel: string;
}

export enum EarlyWarningsSectionViewsEnum {
  PROJECTS = 'projects',
  TRENDS = 'trends',
}

//TRENDS
export interface Trends {
  year: number;
  quarter: number;
  pendingSignature: AlertQuarterInformation;
  expired: AlertQuarterInformation;
  cancelled: AlertQuarterInformation;
  eligibleUnDisbursement: AlertQuarterInformation;
  esgSafeguard: AlertQuarterInformation;
  pmr: AlertQuarterInformation;
}

export interface AlertQuarterInformation {
  countColorGreen: number;
  countColorYellow: number;
  countColorRed: number;
  countColorLightGreen: number;
}

interface BarChartDataset {
  data: number[];
  backgroundColor: string;
  borderWidth: number;
  barThickness: number[];
  label: string;
}
interface BarChartData {
  labels: string[];
  datasets: BarChartDataset[];
}

interface Bullets {
  className: string;
  translationKey: string;
}
export interface TrendChartInformation {
  key: EarlyWarningButtonType;
  title: string;
  chartData: BarChartData;
  bullets: Bullets[];
}

export interface TempQuarterLabels {
  qLabels: string;
  cancelled: number[];
  eligibleUnDisbursement: number[];
  esgSafeguard: number[];
  expired: number[];
  pmr: number[];
  pendingSignature: number[];
}

export enum EarlyWarningType {
  PROJECTS_PENDING_SIGNATURE,
  ELIGIBLE_PROJECTS_PENDING_FIRST_DISBURSEMENT,
  PROJECTS_WITH_RECENT_ALERT_OR_PROBLEM_CLASSIFICATIONS,
  PROJECTS_WITH_UNSATISFACTORY_PERFORMANCE_IN_SAFEGUARDS,
  PROJECTS_WITH_PARTIAL_CANCELLATIONS,
  PROJECTS_WITH_EXPIRED_DISBURSEMENT_DEADLINE,
}
export enum EarlyWarningTypeLabel {
  'pendingSignature' = 1,
  'expired',
  'esgSafeguard',
  'eligibleUnDisbursement',
  'cancelled',
  'pmr',
}
