import React from 'react';
import './RejectedAccordanceDetail.scss';
import { useTranslation } from 'react-i18next';
import Accordance from '../../../../../models/Accordance';
import { AccordanceType } from '../../../../../models/AccordanceType';
import moment from 'moment';
import Comments from '../../../../Util/Comments/Comments';
import { setLastCommentRead, newAccordanceComment, deleteAccordanceComment } from '../../../../../api/accordanceApi';
import { replaceMomentFormat } from '../../../../../services/util';
import AccordanceFiles from '../AccordanceFiles/AccordanceFiles';
import { AccordanceState } from '../../../../../models/AccordanceState';

type Props = {
  accordance: Accordance;
};

const RejectedAccordanceDetail = (props: Props) => {
  const { t } = useTranslation();

  const { accordance } = props;
  const {
    revisionDate,
    criticalIssue,
    possibleAction,
    personInCharge,
    expirationDate,
    accordanceType,
    accordanceFiles,
    accordanceComments,
    id,
    rejectionDetail,
    project,
    accordanceState,
  } = accordance;

  React.useEffect(() => {
    setLastCommentRead(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="rejected-accordance-detail-container">
      <div className="label-data">
        <span className="label">{t('by')}</span>
        <span className="data">{`${rejectionDetail.rejectedBy.name} ${rejectionDetail.rejectedBy.lastName}`}</span>
      </div>
      <div className="label-data">
        <span className="label">{t('motivation')}</span>
        <span className="data">{rejectionDetail.reason}</span>
      </div>
      <div className="separation-red"></div>
      <div className="label-data">
        <span className="label">{t('project')}</span>
        <span className="data">{project.name}</span>
      </div>
      <div className="label-data">
        <span className="label">{t('loan_number')}</span>
        <span className="data">{project.loanNumber}</span>
      </div>
      <div className="label-data">
        <span className="label">{t('critical_issue')}</span>
        <span className="data">{criticalIssue.name}</span>
      </div>

      <div className="label-data">
        <span className="label">{t('category')}</span>
        <span className="data">{criticalIssue.categoryName}</span>
      </div>
      <div className="label-data">
        <span className="label">{accordanceType === AccordanceType.Revision ? t('accordance') : t('action')}</span>
        <span className="data">{possibleAction.name}</span>
      </div>
      <div className="label-data">
        <span className="label">{t('due_date')}</span>
        <span className="data">
          {expirationDate ? replaceMomentFormat(moment(expirationDate).format('MMM/YYYY')) : '-'}
        </span>
      </div>
      <div className="horizontal-row">
        <div className="label-data">
          <span className="label">{t('bid_responsible')}</span>
          <span className="data">{`${personInCharge.name} ${personInCharge.lastName}`}</span>
        </div>
        {accordanceType === AccordanceType.Revision && (
          <div className="label-data">
            <span className="label">{t('revision_accordance')}</span>
            <span className="data">
              {revisionDate ? replaceMomentFormat(moment(revisionDate).format('DD/MMM/YY')) : '-'}
            </span>
          </div>
        )}
      </div>
      {accordanceFiles && accordanceFiles.length > 0 && <AccordanceFiles files={accordanceFiles} />}
      <Comments
        comments={accordanceComments || []}
        submitComment={comment => newAccordanceComment(id, comment)}
        submitDeleteComment={commentId => deleteAccordanceComment(id, commentId)}
        canDeleteComments={accordanceState === AccordanceState.DRAFT || accordanceState === AccordanceState.PENDING}
      />
    </div>
  );
};

export default RejectedAccordanceDetail;
