import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Indicator from '../../../../../models/Indicator';
import { IndicatorKeyEnum } from '../../../../../models/IndicatorKeyEnum';
import { callIndicatorFromDetail } from '../../../PortfolioUtil';
import './ProgressBarIndicator.scss';

interface ReceivedProps {
  data: any;
  showDisbursed?: any;
}

type Props = ReceivedProps;

const ProgressBarIndicator = (props: Props) => {
  const currentIndicator = useSelector<any, Indicator>(state => state.currentIndicator);
  const dispatch = useDispatch();
  const projectTypeFilterOnDetail = useSelector<any, any>(
    state => state.indicatorDetailFilter.projectTypeFilterOnDetail,
  );
  const { data, showDisbursed } = props;
  const progress = data ? `${data.progress}%` : '';
  const leftProgress = data ? `${100 - data.progress}%` : '';
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    if (currentIndicator.indicatorKey !== 'default_indicator' && location.pathname === '/portfoliodetail') {
      callIndicatorFromDetail(IndicatorKeyEnum.PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS, dispatch);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectTypeFilterOnDetail]);

  const handleDisbursed = () => {
    showDisbursed(true);
  };

  const handlePending = () => {
    showDisbursed(false);
  };
  return data ? (
    <div className="progress-bar-indicator-container">
      <div className="text-info-progress">
        <div>{`${t('disbursed')} `}</div>
        <div>{`${t('pending')} `}</div>
      </div>
      <div className="progress-bar-container">
        <div style={{ width: progress }} className="progress">
          <div className="progress-text" onClick={handleDisbursed}>
            <span className="porcent-num">{progress}</span>
          </div>
        </div>

        {data.progress < 100 && (
          <div style={{ width: leftProgress }} className="left-progress-text" onClick={handlePending}>
            <span className="porcent-num">{leftProgress}</span>
          </div>
        )}
      </div>
      <div className="progress-porcent">
        <div className="progress-text" onClick={handleDisbursed}>
          {progress}
        </div>
        <div className="left-progress-text" onClick={handlePending}>
          {leftProgress}
        </div>
      </div>

      <ul className="list-info">
        <li onClick={handleDisbursed}>
          <NumberFormat
            value={data.disbursed}
            displayType={'text'}
            thousandSeparator={'.'}
            decimalSeparator={','}
            decimalScale={1}
          />
          <span className="currency">{data.currency}</span>
        </li>
        <li onClick={handlePending}>
          <NumberFormat
            value={data.pending}
            displayType={'text'}
            thousandSeparator={'.'}
            decimalSeparator={','}
            decimalScale={1}
          />
          <span className="currency">{data.currency}</span>
        </li>
      </ul>
      <div className="planned">
        {`${t('total')} `}
        <NumberFormat
          value={data.planned}
          displayType={'text'}
          thousandSeparator={'.'}
          decimalSeparator={','}
          decimalScale={1}
        />
        <span className="currency">{data.currency}</span>
      </div>
    </div>
  ) : (
    <div className="no-data-msj-indicator">
      <span className="value-text">-</span>
    </div>
  );
};

export { ProgressBarIndicator };
