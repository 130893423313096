import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './CustomTabPanelButtons.module.scss';
import { getCustomTabButtonText } from '../../PortfolioUtil';

type Props = {
  isEdit: boolean;
  handleLeftButton: Function;
  handleRightButton: Function;
};

const CustomTabPanelButtons = (props: Props) => {
  const { t } = useTranslation();
  const { isEdit, handleLeftButton, handleRightButton } = props;
  const { buttons, leftButton, rightButton } = styles;

  return (
    <div className={buttons}>
      <button style={{ width: '125px' }} className={leftButton} onClick={() => handleLeftButton()}>
        {t(getCustomTabButtonText('left', isEdit))}
      </button>
      <button style={{ width: '150px' }} className={rightButton} onClick={() => handleRightButton()}>
        {t(getCustomTabButtonText('right', isEdit))}
      </button>
    </div>
  );
};

export default CustomTabPanelButtons;
