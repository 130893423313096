import React from 'react';
import { useTranslation } from 'react-i18next';
import './FileUploader.scss';
import UploadedFile from './UploadedFile';
import { uploadFile } from '../../api/miscellaneusApi';

export const getTitle = (t: any, filesList: any[]) => {
  return filesList.length === 0
    ? `${t('attach_files')} (${t('optional')})`
    : filesList.length === 1
    ? t('attached_file')
    : t('attached_files');
};

export const checkFile = (e?: any) => (e && e.target && e.target.files ? e.target.files[0] : null);

export const noFileOrRepeated = (file: any, filesList: any[]) =>
  !file || filesList.find(item => item.name === file.name);

export const allowedFormat = (file: any, acceptedFileTypes: string) => {
  const acceptedList = acceptedFileTypes.split(', ').map(item => item.replace('.', ''));
  const auxList = file.name.split('.');
  const extension = auxList[auxList.length - 1];
  return acceptedList.find(item => item === extension);
};

type Props = {
  filesList: any[];
  addFile: Function;
  removeFile: Function;
  customFileTypes?: string;
  customId?: any;
  uploadUrl: string;
  singleFile?: boolean;
};

const basicFileTypes = '.jpeg, .jpg, .png, .ppt, .pptx, .xls, .xlsx, .doc, .docx, .pdf, .txt';
const maxSize = 15000000;

const FileUploader = ({
  filesList,
  addFile,
  removeFile,
  customFileTypes,
  customId = 'file',
  uploadUrl,
  singleFile = false,
}: Props) => {
  const { t } = useTranslation();
  const [submitting, setSubmitting] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<any>('');
  const [acceptedFileTypes] = React.useState<any>(customFileTypes || basicFileTypes);

  const checkSizeRepeatedUpload = async (e?: any) => {
    setErrorMessage('');
    const file = checkFile(e);
    if (noFileOrRepeated(file, filesList)) {
      return;
    } else if (!allowedFormat(file, acceptedFileTypes)) {
      setErrorMessage(`${t('not_allowed_file_type')}${acceptedFileTypes}`);
      return;
    } else if (file.size > maxSize) {
      setErrorMessage(`${t('max_size_error')}${maxSize / 1000000}Mb`);
      return;
    } else {
      setSubmitting(true);
      try {
        const uploadedFile = await uploadFile(file, file.name, uploadUrl);
        addFile(uploadedFile);
        setSubmitting(false);
      } catch (error) {
        setSubmitting(false);
      }
    }
  };

  const getFileSelector = () => {
    if (submitting) {
      return <span className="loading">{t('uploading_file')}</span>;
    } else if ((singleFile && filesList.length === 0) || !singleFile) {
      return (
        <label id={customId} className="link" htmlFor={customId}>
          {t('select_file')}
        </label>
      );
    }
    return null;
  };

  return (
    <div className="accordance-file-uploader-container">
      <p className="label">{getTitle(t, filesList)}</p>
      {filesList.map(item => {
        return <UploadedFile key={item.name} file={item} deleteFile={() => removeFile(item)} />;
      })}
      <input
        id={customId}
        type="file"
        onChange={e => checkSizeRepeatedUpload(e)}
        className="input-file"
        accept={acceptedFileTypes}
      />
      {getFileSelector()}
      {errorMessage && <span className="error">{errorMessage}</span>}
    </div>
  );
};

export default FileUploader;
