import { AgendaByDefaultActionTypes, SET_AGENDA_BY_DEFAULT } from './../actions/types';

const initialState = false;
const agendaSelectedByDefaultReducer = (state = initialState, action: AgendaByDefaultActionTypes): boolean => {
  switch (action.type) {
    case SET_AGENDA_BY_DEFAULT:
      return action.payload;
    default:
      return state;
  }
};
export default agendaSelectedByDefaultReducer;
