import { CardTypesEnum } from '../../../../../models/CardTypesEnum';
import { IndicatorKeyEnum } from '../../../../../models/IndicatorKeyEnum';

export const setDimensions = (
  isMobile,
  listPage,
  dataCount,
  report,
  type,
  modalChart = false,
  contentManager = false,
) => {
  if (isVerticalAndReport(report, type)) {
    return { width: 560, height: 320 };
  } else if (isHorizontalAndReport(report, type)) {
    return { width: 250, height: 320 };
  } else if (isModalChartVerticalSimple(modalChart, type)) {
    return { width: 500, height: 200, marginLeft: 20, marginBottom: 40 };
  } else if (isModalChartAndPortfolioSection(modalChart, contentManager)) {
    return { width: 400, height: 350, marginLeft: 40 };
  } else if (isModalChartAndContentManagerHalfSizeVertical(contentManager, modalChart, type)) {
    return { width: 580, height: 340, marginTop: 30 };
  } else if (isModalChartAndContentManagerHorizontal(contentManager, modalChart, type)) {
    return { width: 500, height: 225, marginTop: 30 };
  } else if (isContentManagerHalfSizeVertical(listPage, type)) {
    return { width: 320, minHeight: 320, cursor: 'pointer' };
  } else if (isContentManagerListPageHorizontal(contentManager, listPage, type)) {
    return { width: isMobile ? 70 : 220, height: isMobile ? 100 : 225 };
  } else if (type === CardTypesEnum.VERTICAL_SIMPLE) {
    return { width: 650, height: 170, marginTop: 20, marginLeft: 100 };
  } else {
    return setMobileDimensions(isMobile, listPage, dataCount, type);
  }
};

const isModalChartVerticalSimple = (modalChart, type) => modalChart && type === 'verticalSimple';
const isVerticalAndReport = (report, type) => report && type === 'vertical';
const isHorizontalAndReport = (report, type) => report && type === 'horizontal';
const isModalChartAndPortfolioSection = (modalChart, contentManager) => modalChart && !contentManager;
const isModalChartAndContentManagerHalfSizeVertical = (contentManager, modalChart, type) =>
  contentManager && modalChart && type === CardTypesEnum.HALF_SIZE_VERTICAL;
const isContentManagerHalfSizeVertical = (listPage, type) => !listPage && type === CardTypesEnum.HALF_SIZE_VERTICAL;
const isContentManagerListPageHorizontal = (contentManager, listPage, type) =>
  contentManager && !listPage && type === CardTypesEnum.HORIZONTAL;
const isModalChartAndContentManagerHorizontal = (contentManager, modalChart, type) =>
  contentManager && modalChart && type === CardTypesEnum.HORIZONTAL;

export const getDefaultFontSize = (listPage, isMobile) => {
  return !listPage && isMobile ? 12 : 8;
};

export const setMobileDimensions = (isMobile, listPage, dataCount, type) => {
  if (isMobile) {
    return listPage ? { width: 120, height: 90 } : { width: 320, height: 320, cursor: 'pointer' };
  } else {
    return listPage
      ? getChartDimensionByTypeOnListPage(type)
      : { width: 320, height: dataCount <= 7 ? 320 : 550, cursor: 'pointer' };
  }
};

export const getChartDimensionByTypeOnListPage = type => {
  if (type === CardTypesEnum.HALF_SIZE_VERTICAL) {
    return { width: 235, height: 95 };
  } else {
    return { width: 220, height: 190 };
  }
};

export const renderTooltips = (indicatorKey, t) => {
  return {
    enabled:
      IndicatorKeyEnum.ESG_SAFEGUARD_PERFORMANCE_RATING === indicatorKey ||
      IndicatorKeyEnum.ESG_ENVIRONMENTAL_AND_SOCIAL_RISK_RATING === indicatorKey,
    displayColors: false,
    backgroundColor: '#101319',
    titleFontSize: 14, 
    bodyFontSize: 14,
    callbacks:
      indicatorKey === IndicatorKeyEnum.ESG_SAFEGUARD_PERFORMANCE_RATING
        ? {
            title: (tooltipItem, data) => {
              return esgSafeguardTooltip(tooltipItem, data, t);
            },
            label: (tooltipItem, data) => {
              return esgSafeguardTooltipData(tooltipItem, data, t);
            },
          }
        : {
            title: (tooltipItem, data) => {
              return t(data['dataListTranslationLabels'][tooltipItem[0]['index']]);
            },
            label: (tooltipItem, data) => {
              return `${data['datasets'][0]['data'][tooltipItem['index']].toFixed(0)} ${t('projects')}`;
            },
          },
  };
};

export const esgSafeguardTooltip = (tooltipItem, data, t) => {
  let returnedTransaltion = {};
  if (tooltipItem[0].datasetIndex === 0) {
    if (tooltipItem[0].index === 0) {
      returnedTransaltion = t('satisfactory');
    } else if (tooltipItem[0].index === 1) {
      returnedTransaltion = t('unsatisfactory');
    } else {
      returnedTransaltion = t('not_assigned');
    }
  } else if (tooltipItem[0].datasetIndex === 1) {
    if (tooltipItem[0].index === 0) {
      returnedTransaltion = t('partially_satisfactory');
    } else if (tooltipItem[0].index === 1) {
      returnedTransaltion = t('partially_unsatisfactory');
    }
  }
  return returnedTransaltion;
};

const esgSafeguardTooltipData = (tooltipItem, data, t) => {
  return `${tooltipItem.value} ${t('projects')}`;
};

export const setXAxesDisplay = type =>
  type === CardTypesEnum.VERTICAL ||
  type === CardTypesEnum.HALF_SIZE_VERTICAL ||
  type === CardTypesEnum.VERTICAL_SIMPLE;

export const renderGridAndTicks = (type, listPage, haveGridLines, data) => {
  return {
    display: type === CardTypesEnum.VERTICAL || type === CardTypesEnum.HALF_SIZE_VERTICAL,
    stacked: true,
    ticks: {
      min: 0,
      suggestedMax:
        type === CardTypesEnum.HALF_SIZE_VERTICAL &&
        data.dataQtyProjects &&
        Math.round(Math.max(...data.dataQtyProjects)),
      maxTicksLimit: 6,
      display: type === CardTypesEnum.HALF_SIZE_VERTICAL && !listPage,
      beginAtZero: false,
    },
    gridLines: haveGridLines
      ? {
          display: true,
          drawBorder: false,
          borderDash: [3, 3],
        }
      : {
          display: false,
        },
  };
};

export const useBasicProperties = data => {
  const cornerRadius = data && data.cornerRadius ? data.cornerRadius : 9;
  const haveGridLines = data && data.gridLines ? data.gridLines : false;
  const listTranslationLabels = data && data.dataListTranslationLabels ? data.dataListTranslationLabels : [];
  const dataQtyProjects = data && data.dataQtyProjects ? data.dataQtyProjects : [];
  const backgroundColorId =
    data && data.datasets && data.datasets[0] && data.datasets[0].backgroundColorId
      ? data.datasets[0].backgroundColorId
      : [];
  return { cornerRadius, haveGridLines, listTranslationLabels, dataQtyProjects, backgroundColorId };
};

export const getElementAtEvent = (
  element,
  datasetClick,
  fromList = false,
  data: any = undefined,
  indicatorKey: string | undefined = undefined,
) => {
  if (indicatorKey === IndicatorKeyEnum.SUPERVISION_PLAN_PLANNED_VERSUS_EXECUTED) {
    return datasetClick(element);
  }

  if (fromList) {
    datasetClick &&
      datasetClick({
        datasetLabel: element.datasetLabel,
        label: '',
        dataIndex: element.dataIndex,
      });
  } else {
    if (element.length !== 0) {
      datasetClick &&
        datasetClick({
          datasetLabel: element[0]._model.datasetLabel,
          label: element[0]._model.label,
          dataIndex: element[0]._index,
          completeGroupingValue: data && data.completeGroupingValue && data.completeGroupingValue[element[0]._index],
        });
    }
  }
};

export const haveDataSetLength = data => data && data.datasets && data.datasets.length;

export const noValueClassName = contentManager =>
  contentManager ? 'no-data-msj-indicator-content-manager' : 'no-data-msj-indicator';
