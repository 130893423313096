import React from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../../../../services/booleanUtil';
import { getCountProjectValue } from '../Util';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { RenderTextByProjectTypeFilterDetail } from '../RenderTextByProjectTypeFilterDetail/RenderTextByProjectTypeFilterDetail';

type Props = {
  arrayToCount: any;
  countProjects: boolean; //If false Count Accordance
  totalProjects: number;
  widthStyle?: string;
};

export const DynamicProjectProductTypeCounter = ({ arrayToCount, countProjects, totalProjects, widthStyle }: Props) => {
  const { t } = useTranslation();
  const { projectTypeFilterOnDetail } = useSelector<any, IndicatorDetailFilter>(state => state.indicatorDetailFilter);
  const totalAmount =
    arrayToCount.length > 0 &&
    arrayToCount
      .map(p => p.field2)
      .reduce((a, c) => {
        return parseInt(a) + parseInt(c);
      });

  const projectAmount = getCountProjectValue(countProjects, totalProjects, arrayToCount);
  return (
    <div
      className="project-counter-container"
      style={isMobile() ? { width: '100%' } : { width: '100%', maxWidth: widthStyle }}
    >
      <div className="multiple-values">
        <div
          className="projects-loans-values-containter"
          style={{ width: projectTypeFilterOnDetail === 0 ? '41%' : '45%' }}
        >
          <div className="project-value" style={{ lineHeight: '40px' }}>
            <span className="capitlaize-text"> {t('total')}</span>: {projectAmount}
            <span className="lowercase-text"> {projectAmount > 1 ? t('projects') : t('project')} </span>
            {'/'}
          </div>
          <RenderTextByProjectTypeFilterDetail
            arrayToCount={arrayToCount}
            projectTypeFilterOnDetail={projectTypeFilterOnDetail}
          />
        </div>
        <div className="amount-value" style={{ lineHeight: '40px' }}>
          <NumberFormat
            value={totalAmount}
            displayType={'text'}
            className={'value'}
            thousandSeparator={'.'}
            decimalSeparator={','}
          />
        </div>
      </div>
    </div>
  );
};
