import React from 'react';
import Switch from 'react-switch';
import './CustomSwitch.scss';

interface IProps extends React.HTMLProps<JSX.Element> {
  active: boolean;
  handleChange: (value: any) => void;
  disabled?: boolean;
}

const CustomSwitch = ({ active, handleChange, disabled }: IProps) => {
  return (
    <Switch
      checkedIcon={false}
      uncheckedIcon={false}
      onChange={handleChange}
      checked={active}
      onColor={'#236BFF'}
      offColor={'#424D64'}
      offHandleColor={'#fff'}
      onHandleColor={'#fff'}
      className="custom-switch-container"
      activeBoxShadow={'0 0 2px 3px #3bf'}
      handleDiameter={10}
      height={14}
      width={24}
      disabled={disabled}
      tabIndex={30}
    />
  );
};

export default CustomSwitch;
