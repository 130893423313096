import React from 'react';
import { isDiferentToIGLower } from '../EarlyWarningsTimeLapse/Util';
import styles from './EarlyWarningsTrafficLight.module.scss';

export enum TrafficLightEnum {
  GREEN = 'GREEN',
  YELLOW = 'YELLOW',
  RED = 'RED',
  NA = 'NA',
}
type Props = {
  earlyWarningColor: TrafficLightEnum | null;
  projectProductType?: string;
};

export const EarlyWarningsTrafficLight = ({ earlyWarningColor, projectProductType = '' }: Props) => {
  const { trafficLightContainer, iconContainer, red, yellow, green, notAvailable } = styles;
  const classMap = { RED: red, GREEN: green, YELLOW: yellow, NA: notAvailable };

  return earlyWarningColor !== null && isDiferentToIGLower(projectProductType) ? (
    <div className={trafficLightContainer}>
      <div className={iconContainer}>
        <span className={classMap[earlyWarningColor]} />
      </div>
    </div>
  ) : (
    <>-</>
  );
};
