import { dataTypes } from '../ContentManagerKeys';

export enum AddEntityOptionsType {
  CriticalIssue = 1,
  PossibleAction,
}

export const mapEntitiesDataTypes = (collection, dataType) => {
  return collection.map(c => {
    return { ...c, type: dataType };
  });
};

export const getDataTypes = optionsData => {
  if (!optionsData) {
    return dataTypes.catergories;
  } else if (optionsData && optionsData.type === AddEntityOptionsType.CriticalIssue) {
    return dataTypes.criticalIssue;
  } else {
    return dataTypes.possibleAction;
  }
};

export const renderModalTextType = type => {
  return dataTypes.catergories === type
    ? 'this_category'
    : dataTypes.criticalIssue === type
    ? 'this_critical_issue'
    : 'this_possible_action';
};
