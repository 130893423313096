/* eslint-disable array-callback-return */
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { ProjectsTable } from '../ProjectsTable/ProjectsTable';
import Project from '../../../models/Project';
import { getFollowedProjectsFromApi, getFollowUnfollowProjectFromApi } from '../../../redux/actions/followedProjects';
import { ProjectsListSkeleton } from '../../LoadersSkeletons/ProjectsListSkeleton/ProjectsListSkeleton';
import { Pagination } from '@material-ui/lab';
import '../../Util/Pagination/Pagination.scss';
import useFilters from '../../../hooks/useFilters';

interface IProps {
  itsSearch: boolean;
}
type PropsProjectLists = IProps;
const ProjectsList = (props: PropsProjectLists) => {
  const followedProjects = useSelector<any, Project[]>(state => state.followedProjects);
  const { itsSearch } = props;
  const { filter, dispatch, setFilter } = useFilters();
  const rowCount = Math.ceil(filter.followedProjectsPagination?.rowCount / 9);
  const initialPage =
    filter.followedProjectsPagination?.currentPage > rowCount
      ? rowCount
      : filter.followedProjectsPagination?.currentPage;
  const numberOfPages = filter.followedProjectsPagination?.pageCount;
  const searchValue = filter.followedProjectsFilter.searchValue;
  const haveFollowedProjects = filter.followedProjectsPagination.rowCount;

  const paginate = (pageNumber: any) => {
    dispatch(
      setFilter({
        ...filter,
        followedProjectsPagination: {
          ...filter.followedProjectsPagination,
          currentPage: pageNumber,
        },
      }),
    );
    dispatch(getFollowedProjectsFromApi());
  };

  React.useEffect(() => {
    dispatch(
      setFilter({
        ...filter,
        followedProjectsPagination: {
          ...filter.followedProjectsPagination,
          pageSize: 9,
          currentPage: initialPage === 0 ? 1 : initialPage,
        },
      }),
    );
    if (itsSearch) {
      paginate(1);
    } else {
      dispatch(getFollowedProjectsFromApi());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, filter.common]);

  const handleChange = (event, value) => {
    paginate(value);
  };

  const setInFollow = (projectId: number) => {
    dispatch(getFollowUnfollowProjectFromApi(projectId, true));
    if (followedProjects.length === 1 && initialPage !== 1) {
      paginate(initialPage - 1);
    }
  };

  const sortTable = (sortValue: any) => {
    dispatch(
      setFilter({
        ...filter,
        followedProjectsFilter: {
          ...filter.followedProjectsFilter,
          sortParamenter: sortValue.concept,
          orderDesc: !filter.followedProjectsFilter?.orderDesc,
        },
      }),
    );
    dispatch(getFollowedProjectsFromApi());
  };

  return (
    <Fragment>
      {followedProjects.length !== 0 ? (
        <ProjectsTable
          projects={followedProjects}
          setInFollow={setInFollow}
          sortTable={sortTable}
          isAnnual={filter.common.isAnnual}
        />
      ) : haveFollowedProjects === -1 ? (
        <ProjectsListSkeleton />
      ) : null}
    </Fragment>
  );
};

export default ProjectsList;
