import React from 'react';
import { useTranslation } from 'react-i18next';
import { CardTypesEnum } from '../../../../../../models/CardTypesEnum';
import { capitalizeFirstLetter, setTranslationFile } from '../../../../../../services/stringUtil';
import { isReportDetailImg } from '../../../CardIndicator';
import { getElementAtEvent } from '../Util';

import { renderProjectWord, returnSelectedConcept, setLabelOnDetailPage, setLiStyle, setUlStyle } from './Util';

type Props = {
  setOfProps: any;
};

const customStyle = forReport => (isReportDetailImg(forReport) ? { color: 'white' } : {});

export const FooterList = ({ setOfProps }: Props) => {
  const {
    type,
    listTranslationLabels,
    listPage,
    dataQtyProjects,
    indicatorKey,
    datasetClick,
    backgroundColorId,
    contentManager,
    modalChart,
    forReport,
  } = setOfProps;
  const { t } = useTranslation(setTranslationFile(contentManager));

  const renderListText = (modalChart, label, t) => {
    return modalChart ? (
      `${capitalizeFirstLetter(t(label))}: `
    ) : (
      <>
        <span className="ellipsis-text">{`${capitalizeFirstLetter(t(label))}`}</span> {': '}
      </>
    );
  };

  return type === CardTypesEnum.HALF_SIZE_VERTICAL ? (
    <div className={contentManager ? 'list-info-container-content-manager' : 'list-info-container'}>
      <ul style={setUlStyle(contentManager, modalChart, listPage)}>
        {listTranslationLabels.map((label, ind) => {
          if (listPage && label !== 'not_assigned') {
            return (
              <li
                style={customStyle(forReport)}
                className={setLiStyle(contentManager, backgroundColorId, ind, label)}
                key={label}
              >
                {renderListText(modalChart, label, t)}

                <span style={{ marginRight: 5 }}>{dataQtyProjects[ind]}</span>
                {`${renderProjectWord(indicatorKey, dataQtyProjects[ind], listPage, t)}`}
              </li>
            );
          } else if (!listPage) {
            return (
              <li
                className={`li-modal-view ${label}`}
                key={label}
                onClick={() => getElementAtEvent(returnSelectedConcept(indicatorKey, ind, label), datasetClick, true)}
                style={customStyle(forReport)}
              >
                {setLabelOnDetailPage(label, t)}
                <div className="projects-value">{` ${dataQtyProjects[ind]} ${renderProjectWord(
                  indicatorKey,
                  dataQtyProjects[ind],
                  listPage,
                  t,
                )}`}</div>
              </li>
            );
          } else {
            return '';
          }
        })}
      </ul>

      <div
        style={{
          fontFamily: 'Rubik',
          fontSize: 10,
          color: '#9f9f9f',
        }}
      ></div>
    </div>
  ) : null;
};
