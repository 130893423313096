import React from 'react';
import Advance from '../Advancev2/Advance';
import FinancialInformation from '../../../models/FinancialInformation';
import TemporaryAdvance from '../../../models/TemporaryAdvance';
import './AdvanceData.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  financialInformation?: FinancialInformation | any;
  temporaryAdvance: TemporaryAdvance | any;
  titleOnTop?: boolean;
  isWarranty?: any;
  dataBar?: any;
  baseInfo?: any;
};
export const AdvanceData = (props: Props) => {
  const { temporaryAdvance, financialInformation, dataBar, baseInfo } = props;
  const { t } = useTranslation();

  return (
    <>
      {temporaryAdvance ? (
        <Advance {...temporaryAdvance} />
      ) : (
        <div className="empty-object-container linebox">
          <div className="dash">-</div>
          <div className="title"> {t('temporary_advance')}</div>
        </div>
      )}
      <div className="linebox">
        {financialInformation ? <Advance {...financialInformation} dataBar={dataBar} baseInfo={baseInfo} /> : null}
      </div>
    </>
  );
};

export default AdvanceData;
