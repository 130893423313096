import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import './LinksAndDocsMobile.scss';

type CardProps = {
  link: any;
};

export const LinkDocMobileCard = (props: CardProps) => {
  const { t } = useTranslation();
  const { link } = props;
  return (
    <div className="link-doc-mobile-card">
      <div className="left-container">
        <span className="label">{t('type')}</span>
        <span className="data">{link.type}</span>
        <span className="label">{t('date')}</span>
        <span className="data">{moment(link.date).format('DD/MM/YY')}</span>
      </div>
      <div className="right-container">
        <span className="label">{t('description')}</span>
        <a href={`${link.url}`} className="link-text" target="_blank" rel="noopener noreferrer">
          {link.name}
        </a>
      </div>
    </div>
  );
};

type Props = {
  links: any[];
};
const LinksAndDocsMobile = (props: Props) => {
  const { t } = useTranslation();
  const { links } = props;

  return links && links.length > 0 ? (
    <div className="links-docs-mobile-container">
      {links.map(link => {
        return <LinkDocMobileCard link={link} />;
      })}
    </div>
  ) : (
    <span className="mobile-no-links-text">{t('no_links_found')}</span>
  );
};

export default LinksAndDocsMobile;
