import {
  SET_CALENDAR_FILTERS,
  SET_CALENDAR_EVENTS,
  SET_CALENDAR_DATE,
  SET_CALENDAR_EVENT_COUNTER,
  RESET_CALENDAR_FILTERS,
} from './types';
import { getEvents } from '../../api/calendarApi';
import CalendarEvent from '../../models/CalendarEvent';
import { EventType } from '../../models/EventType';
import { store } from '../store';

export function setCalendarFilters(filter: any, isCalendarIndicator = false) {
  return (dispatch: any, getState: any) => {
    dispatch(setFilters(filter));
    if (!isCalendarIndicator) {
      const projectId = getState().currentProject.id;
      dispatch(getCalendarEvents(projectId));
    }
  };
}

export function setFilters(filter: any) {
  return {
    type: SET_CALENDAR_FILTERS,
    payload: filter,
  };
}

export const defaultCalendarFiltersPayload = {
  disbursements: true,
  acquisitions: true,
  activities: true,
  accordancesMonitor: true,
  accordancesRevision: true,
  supervisoryAction: true,
  secretarialEvents: true,
  lifecycle: true,
  missions: true,
  interGroup: true,
};
export function resetFilters(isIndicatorCalendar) {
  const filterInStorage = localStorage.getItem('projectDetailCalendarFilters');

  if (isIndicatorCalendar || !filterInStorage) {
    return {
      type: RESET_CALENDAR_FILTERS,
      payload: defaultCalendarFiltersPayload,
    };
  }

  return {
    type: RESET_CALENDAR_FILTERS,
    payload: JSON.parse(filterInStorage),
  };
}

export function setCalendarDate(date: any) {
  return (dispatch: any, getState: any) => {
    dispatch(setDate(date));
    const projectId = getState().currentProject.id;
    dispatch(getCalendarEvents(projectId));
  };
}

export function setDate(date: any) {
  return {
    type: SET_CALENDAR_DATE,
    payload: date,
  };
}

export function setCalendarEvents(events: CalendarEvent[]) {
  return {
    type: SET_CALENDAR_EVENTS,
    payload: events,
  };
}
export function setCalendarEventCounter(eventsCounter: any) {
  return {
    type: SET_CALENDAR_EVENT_COUNTER,
    payload: eventsCounter,
  };
}

const addProjectIdToList = (list: CalendarEvent[], projectId: number) => {
  return list.map(item => {
    return !item.projectId ? { ...item, projectId } : item;
  });
};

export const firstMonthDate = date => {
  const monthsList = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  const year = date.getFullYear().toString();
  const month = monthsList[date.getMonth()];
  return `${year}-${month}-01`;
};

export const allEventCounterTracker = allEvents => {
  const filters = store.getState().calendar.filters;

  const group = allEvents.reduce((r, a) => {
    r[a.eventType] = [...(r[a.eventType] || []), a];
    return r;
  }, {});

  const quantityByEventTypeObject = [
    { calendarFilter: EventType.Disbursement, value: 0, active: true, filterName: 'disbursements' },
    { calendarFilter: EventType.Acquisition, value: 0, active: true, filterName: 'acquisitions' },
    { calendarFilter: EventType.Activity, value: 0, active: true, filterName: 'activities' },
    { calendarFilter: EventType.MonitoringAccordance, value: 0, active: true, filterName: 'accordancesMonitor' },
    { calendarFilter: EventType.RevisionAccordance, value: 0, active: true, filterName: 'accordancesRevision' },
    { calendarFilter: EventType.InterGroup, value: 0, active: true, filterName: 'interGroup' },
    { calendarFilter: EventType.SupervisoryAction, value: 0, active: true, filterName: 'supervisoryAction' },
  ];

  return quantityByEventTypeObject.map(q => {
    return {
      ...q,
      value: q.calendarFilter.toString() in group ? group[q.calendarFilter.toString()].length : 0,
      active: filters[q.filterName],
    };
  });
};

export function getCalendarEvents(projectId) {
  return async (dispatch: any, getState: any) => {
    const filters = getState().calendar.filters;
    const month = firstMonthDate(getState().calendar.date);

    const list: string[] = [];
    if (filters.disbursements) {
      list.push('eventTypes=0');
    }

    if (filters.acquisitions) {
      list.push('eventTypes=1');
    }
    if (filters.activities) {
      list.push('eventTypes=2');
    }
    if (filters.accordancesMonitor) {
      list.push('eventTypes=3');
    }
    if (filters.accordancesRevision) {
      list.push('eventTypes=4');
    }
    if (filters.interGroup) {
      list.push('eventTypes=8');
    }
    if (filters.supervisoryAction) {
      list.push('eventTypes=9');
    }

    const eventTypes = list.length > 0 ? `&${list.join('&')}` : '';

    try {
      const response = await getEvents(projectId, month, eventTypes);
      dispatch(setCalendarEvents(addProjectIdToList(response, projectId)));
    } catch (error) {}
  };
}
