import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TimeRange } from '../../../models/TimeRangeKnowledgeProductsEnum';
import styles from './KnowledgeProductFilterBubbles.module.scss';

type Props = {
  searchContext: any;
};

const KnowledgeProductFilterBubbles = ({ searchContext }: Props) => {
  const [filtersApplied, setfiltersApplied] = useState([]);
  const { searchButtonFired } = searchContext;
  const { t } = useTranslation();
  useEffect(() => {
    setfiltersApplied(renderBubbles(searchContext, t));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchButtonFired]);

  return filtersApplied.length ? (
    <div className={styles.searchResult}>
      <div className={styles.searchResultTitle}>{t('search_results')}:</div>

      {filtersApplied.map(filter => (
        <div key={filter} className={styles.searchResultBubble}>
          {filter}
        </div>
      ))}
    </div>
  ) : (
    <></>
  );
};

const renderBubbles = (searchContext, t) => {
  const {
    productType,
    departmentDivision,
    filteredCountries,
    rangeDate,
    from,
    to,
    minDate,
    maxDate,
    componenteHandleDateRanges,
  } = searchContext;
  const formatDate = 'DD/MM/YYYY';
  const returnedFilters: any = [];

  if (productType[0].value !== 'all') {
    productType.forEach((element: any) => {
      returnedFilters.push(element.value);
    });
  }

  if (departmentDivision[0].value !== 'all') {
    departmentDivision.forEach((element: any) => {
      returnedFilters.push(element.value);
    });
  }

  if (filteredCountries[0].value !== 'all') {
    filteredCountries.forEach((element: any) => {
      returnedFilters.push(element.value);
    });
  }

  if (
    rangeDate === TimeRange.Everyone &&
    !componenteHandleDateRanges &&
    (moment(from).format(formatDate) !== moment(minDate).format(formatDate) ||
      moment(to).format(formatDate) !== moment(maxDate).format(formatDate))
  ) {
    returnedFilters.push(
      `${t('release_date')} ${moment(from).format(formatDate)} ${t('and')} ${moment(to).format(formatDate)}`,
    );
  }
  return returnedFilters;
};

export default KnowledgeProductFilterBubbles;
