export enum RolesAnalyticsIdEnum {
  Representatives = 1,
  Chiefs_Of_Operations,
  Sector_Specialists,
  Trust_Specialists,
  ESG_Specialists,
  Operations_Analysts,
  Front_Office_Management_VP,
  Lawyers,
  KIC,
  SPD,
  Others,
}
