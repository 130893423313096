import { RESET_TEXT_SEARCH, TextSearchActionTypes, SET_TEXT_SEARCH } from '../actions/types';

const initialState = '';

const textSearchReducer = (state = initialState, action: TextSearchActionTypes) => {
  switch (action.type) {
    case SET_TEXT_SEARCH:
      return action.payload;
    case RESET_TEXT_SEARCH:
      return '';

    default:
      return state;
  }
};

export default textSearchReducer;
