import React from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import './BaseInformation.scss';

type Props = {
  baseInfo: any;
};

const Amounts = (props: Props) => {
  const { t } = useTranslation();
  const { baseInfo } = props;

  return (
    <div className="objective column">
      <span className="section-title">{t('current_amount')}</span>
      <div className="title-text-group title-spacing">
        <span className="small-text  capitalize ">{t('bid')}</span>
        <span className="text-name">
          <NumberFormat
            value={baseInfo.bidAmount}
            displayType={'text'}
            thousandSeparator={'.'}
            decimalSeparator={','}
          />
          {' USD'}
        </span>
      </div>
      <div className="title-text-group">
        <span className="small-text capitalize">{t('local_amount')}</span>
        <span className="text-name">
          <NumberFormat
            value={baseInfo.localAmount}
            displayType={'text'}
            thousandSeparator={'.'}
            decimalSeparator={','}
          />
          {' USD'}
        </span>
      </div>
      <div className="title-text-group">
        <span className="small-text capitalize">{t('cancelled_amount')}</span>
        <span className="text-name">
          <NumberFormat
            value={baseInfo.cancelledAmount}
            displayType={'text'}
            thousandSeparator={'.'}
            decimalSeparator={','}
          />
          {' USD'}
        </span>
      </div>
      <div className="title-text-group">
        <span className="small-text capitalize">{t('reformulated_amount')}</span>
        <span className="text-name">
          <NumberFormat
            value={baseInfo.adjustmentAmount}
            displayType={'text'}
            thousandSeparator={'.'}
            decimalSeparator={','}
          />
          {' USD'}
        </span>
      </div>
    </div>
  );
};

export default Amounts;
