import React from 'react';
import './ActivityRow.scss';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import { animateScroll } from 'react-scroll';
import RemoveEdit from '../../../RemoveEdit/RemoveEdit';
import { Acquisition, ActivityType } from '../../../../../../../models/Planning';
import CommentsIcon from '../../../../Accordances/CommentsIcon/CommentsIcon';
import { ActivityState } from '../../ActivityState/ActivityState';
import { renderDateOrSlash } from '../../../../../../../services/stringUtil';
import { isAuthorizedToEdit } from '../../../../../../../services/booleanUtil';
import { deleteActivity } from '../../../../../../../redux/actions/planification';
import NewActivityForm from '../../../NewItem/NewActivityForm';
import CommentsModal from '../../../CommentsModal/CommentsModal';
import User from '../../../../../../../models/User';
import Project from '../../../../../../../models/Project';

type Props = {
  styleOptions?: any;
  data?: any;
  acquisition: Acquisition;
};

const isDateRow = row => {
  return row === 'plannedDate' || row === 'realDate';
};
const isAmountRow = row => {
  return row === 'planAmount' || row === 'contractAmount' || row === 'paidAmount';
};

const isStartOrEnd = activityType => {
  return activityType === ActivityType.Start || activityType === ActivityType.End;
};

export const ActivityRow = ({ styleOptions, data, acquisition }: Props) => {
  const dispatch = useDispatch();
  const [showEdit, setShowEdit] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [customHeight, setCustomHeight] = React.useState(0);
  const user = useSelector<any, User>(state => state.user);
  const currentProject = useSelector<any, Project>(state => state.currentProject);

  const removeActivity = () => {
    dispatch(
      deleteActivity({ id: data.id, acquisitionId: data.acquisitionId, keyProductId: acquisition.keyProductId }),
    );
  };
  const renderRowHelper = (value, row) => {
    if (isDateRow(row)) {
      return renderDateOrSlash(value);
    }
    if (isAmountRow(row)) {
      return (
        <NumberFormat
          value={value}
          displayType={'text'}
          thousandSeparator={'.'}
          decimalSeparator={','}
          decimalScale={0}
        />
      );
    }

    if (row === 'comments') {
      return (
        <CommentsIcon
          hasUnreadComments={false}
          comments={value ? value.filter(item => !item.isDeleted).length : 0}
          alwaysShowIcon={true}
          onClick={e => {
            animateScroll.scrollMore(e.clientY - 50, { duration: 500 });
            setTimeout(() => {
              setCustomHeight(50);
              setShowModal(true);
            }, 600);
          }}
        />
      );
    }

    if (row === 'state') {
      return <ActivityState activityState={value} />;
    }

    return data[row];
  };

  return (
    <>
      {showEdit ? (
        <NewActivityForm
          activityData={data}
          hideForm={() => setShowEdit(false)}
          keyProductId={acquisition.keyProductId}
          acquisition={acquisition}
        />
      ) : (
        <div className="activity-row-container">
          {styleOptions.map((item, index) => (
            <div className="row" style={styleOptions[index].style} key={item.title}>
              {renderRowHelper(data[item.propName], item.propName)}
            </div>
          ))}

          {isAuthorizedToEdit(user, currentProject) && (
            <div className="remove-edit-container">
              <RemoveEdit
                onDelete={removeActivity}
                onEdit={e => {
                  setShowEdit(true);
                }}
                hideRemove={isStartOrEnd(data.activityType)}
              />
            </div>
          )}
        </div>
      )}
      <CommentsModal
        isOpen={showModal}
        closeModal={() => setShowModal(false)}
        activity={data}
        customHeight={customHeight}
        keyProductId={acquisition.keyProductId}
      />
    </>
  );
};
