/* eslint-disable no-mixed-operators */
import { useContext, useEffect, useState } from "react";
import { DimensionContext } from "../context/DimensionContext";
import { TagFilterContext } from "../context/TagFilterContext";

export interface TagDimensionType {
    width: number;
    height: number;
    name: string;
    row?: number;
}

export const useDynamicTagLayout = () => {
    const { parentDimension, tagDimension, setTagDimension } = useContext(DimensionContext);
    const [visibleTags, setVisibleTags] = useState<TagDimensionType[]>([]);
    const [overflowTags, setOverflowTags] = useState<TagDimensionType[]>([]);

    useEffect(() => {
        let newVisibleTags: TagDimensionType[] = [];
        let newOverflowTags: TagDimensionType[] = [];
        let currentWidth = 0;
        let row = 1;
        const gap = 10;
        const overflowTextWidth = 55;
        let isSecondRowAdjusted = false;

        tagDimension.forEach((tag) => {
            if (row === 2 && !isSecondRowAdjusted) {
                currentWidth += overflowTextWidth;
                isSecondRowAdjusted = true;
            }

            const tagWidthWithGap = (currentWidth === 0 ? tag.width : tag.width + gap);

            if (currentWidth + tagWidthWithGap > parentDimension.width) {
                row++;
                currentWidth = 0;
            }

            if (row > 2 || (row === 2 && currentWidth + tagWidthWithGap > parentDimension.width - overflowTextWidth)) {
                newOverflowTags.push(tag);
            } else {
                newVisibleTags.push({ ...tag, row });
                currentWidth += tagWidthWithGap;
            }
        });
        setVisibleTags(newVisibleTags);
        setOverflowTags(newOverflowTags);
    }, [parentDimension, tagDimension]);

    return { visibleTags, overflowTags };
};
