import React from 'react';
import './ErrorMessage.scss';
import { useTranslation } from 'react-i18next';

interface IProps extends React.HTMLProps<JSX.Element> {
  alertMessage?: any;
  descriptionMessage?: any;
  customStyle?: any;
}

const ErrorMessage = ({ alertMessage, descriptionMessage, customStyle }: IProps) => {
  const { t } = useTranslation();

  return (
    <div className="error-message-container" style={ customStyle || {}}>
      <span className="text alert">{alertMessage || t('generic_error_alert')}</span>
      <span className="text">{descriptionMessage || t('generic_error_text')}</span>
    </div>
  );
};

export default ErrorMessage;
