import React, { useCallback, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import { Box, Button, Grid, IconButton, TableCell, TableRow } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ProgressBar from './ProgressBar';

import { useCards } from '../../../context/CardContext';
import { useNumberFormatter } from '../../../hooks/useNumberFormatter';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { isFloat } from '../../Util/MappingData/DataHomeTableMap';
import { useDispatch } from 'react-redux';
import { setIndicatorDetailFilterProjectType } from '../../../redux/actions/indicatorDetailFilters';

export type DataProps = {
  name: string;
  label: string;
  projectCount: number;
  operations: number;
  ageAverage: number;
  disbursementPercentage: number;
  currentApprovedAmount: number;
  originalApprovedAmount: number;
  disbursementAmount: number;
  pendingDisbursementAmount: number;
  percentageDisbursementAmount?: number;
};

const typeMap = {
  LON: 0,
  GUA: 0,
  TCP: 2,
  TCP_NAT: 2,
  IGR: 3,
};

export default function Row({
  row,
  onRowClick,
  openRow,
}: {
  row: DataProps;
  onRowClick: (rowId: string) => void;
  openRow;
}) {
  const { t } = useTranslation();
  const { formatMillions } = useNumberFormatter();
  const { navigateToPortfolioDetail, setCurrentRowClicked } = useCards();

  const isCollapseOpen = openRow === row.label;

  const dispatch = useDispatch();

  const rowRef = useRef<HTMLElement>(null);
  useOutsideClick(rowRef, () => {
    if (isCollapseOpen) onRowClick?.(row.label);
  });

  const handleCollapseClick = () => onRowClick?.(row.label);

  const setIndicator = useCallback(
    indicatorLabel => {
      const type = typeMap[indicatorLabel] ?? null;
      if (type !== null) dispatch(setIndicatorDetailFilterProjectType(type));
    },
    [dispatch],
  );

  const handleClick = () => {
    setCurrentRowClicked(row.label);
    setIndicator(row.label);
    navigateToPortfolioDetail();
  };

  const renderTableCells = data => {
    return (
      <>
        <TableCell>
          <IconButton aria-label="expand row" onClick={handleCollapseClick}>
            <span
              className={`product-type-icon-${data.label === 'TCP_NAT' ? 'TCP' : data.label && data.label === 'IGR_BOTH' ? 'IGR' : data.label}`}
            >
              {data.label === 'TCP_NAT' ? 'TCP' : data.label && data.label === 'IGR_BOTH' ? 'IGR' : data.label}
            </span>
            {t(`${data.label}_table`)}
          </IconButton>
        </TableCell>

        <TableCell>{data.projectCount}</TableCell>
        <TableCell>{data.operations}</TableCell>

        <TableCell className="amount">
          <Box display="flex" flexDirection="row" alignItems="flex-end">
            <Box>
              {formatMillions(data.currentApprovedAmount)} MM
              <div className="small">{t('active_accordance')}</div>
            </Box>
            <Box>
              {formatMillions(data.originalApprovedAmount)} MM
              <div className="small text-right">{t('classification_original')}</div>
            </Box>
          </Box>
        </TableCell>

        <TableCell className="disbursement">
          <ProgressBar
            completed={
              isFloat(data.disbursementPercentage)
                ? data.disbursementPercentage.toFixed(2)
                : data.disbursementPercentage
            }
          />
          <div>
            <Grid container spacing={3}>
              <Grid item xs={6} style={{ paddingBottom: 0 }}>
                {formatMillions(row.disbursementAmount)} MM
              </Grid>
              <Grid item xs={6} style={{ textAlign: 'right', paddingBottom: 0 }}>
                {formatMillions(row.pendingDisbursementAmount)} MM
              </Grid>
            </Grid>
          </div>
        </TableCell>

        <TableCell>
          {isFloat(data.ageAverage) ? data.ageAverage.toFixed(2) : Math.trunc((data.ageAverage * 10) / 10 / 12)}{' '}
          {t('years')}
        </TableCell>

        <TableCell>
          <Button onClick={handleClick} className="ReadMore">
            {t('see_more')}
            <ArrowForwardIcon />
          </Button>
        </TableCell>
      </>
    );
  };

  const renderRow = () => {
    return (
      <>
        <TableRow innerRef={rowRef} className={`row-top ${isCollapseOpen ? 'active' : ''}`} data-row-id={row.label}>
          {renderTableCells(row)}
        </TableRow>
      </>
    );
  };

  return <>{renderRow()}</>;
}
