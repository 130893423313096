import FilterEarlyWarnings from '../models/FilterEarlyWarnings';
import { FilterTypesEnum } from '../models/FitlerTypesEnum';
import { store } from '../redux/store';

export const setReturnedValue = (actualStateFrom, actualStateTo, field) => {
  let returnedValue;
  if (actualStateFrom !== 0 && actualStateTo !== 0) {
    returnedValue = [
      {
        filterType: FilterTypesEnum.GreaterThanOrEqual,
        field,
        value: actualStateFrom,
      },
      {
        filterType: FilterTypesEnum.LessThanOrEqual,
        field,
        value: actualStateTo,
      },
    ];
  }
  if (actualStateFrom !== 0 && actualStateTo === 0) {
    returnedValue = {
      filterType: FilterTypesEnum.GreaterThanOrEqual,
      field,
      value: actualStateFrom,
    };
  }
  if (actualStateFrom === 0 && actualStateTo !== 0) {
    returnedValue = {
      filterType: FilterTypesEnum.LessThanOrEqual,
      field,
      value: actualStateTo,
    };
  }
  return returnedValue;
};

export const getAgeFilterReturnedValue = (actualStateFrom, actualStateTo, actualStateMonths) => {
  let returnedValue;

  if (actualStateFrom !== 0 && actualStateTo !== 0) {
    returnedValue = [
      {
        filterType: FilterTypesEnum.GreaterThanOrEqual,
        field: 'Age',
        value: actualStateMonths ? actualStateFrom : actualStateFrom * 12,
      },
      {
        filterType: FilterTypesEnum.LessThanOrEqual,
        field: 'Age',
        value: actualStateMonths ? actualStateTo : actualStateTo * 12,
      },
    ];
  }

  if (actualStateFrom !== 0 && actualStateTo === 0) {
    returnedValue = {
      filterType: FilterTypesEnum.GreaterThanOrEqual,
      field: 'Age',
      value: actualStateMonths ? actualStateFrom : actualStateFrom * 12,
    };
  }

  if (actualStateFrom === 0 && actualStateTo !== 0) {
    returnedValue = {
      filterType: FilterTypesEnum.LessThanOrEqual,
      field: 'Age',
      value: actualStateMonths ? actualStateTo : actualStateTo * 12,
    };
  }
  return returnedValue;
};

export const filterSelects = value => {
  return value !== null && value !== undefined && value.length > 0 ? true : false;
};
export const filterInputs = (from, to) => {
  return from !== 0 || to !== 0 ? true : false;
};

export const setHaveFiltersInputsAndSelects = (filterToCheck, indicatorCall, filterReport?) => {
  let filterCommon;
  if (!filterReport) {
    filterCommon = indicatorCall ? store.getState().filterIndicators.common : store.getState().filter.common;
  } else {
    filterCommon = filterReport;
  }
  return handleFilterToCheck(filterToCheck, filterCommon);
};

const handleFilterToCheck = (filterToCheck: string, filterCommon) => {
  switch (filterToCheck) {
    case 'division':
      return filterCommon && filterCommon.divisionId && filterSelects(filterCommon.divisionId);
    case 'country':
      return filterCommon && filterSelects(filterCommon.countryId);
    case 'timeWithoutDisbursement':
      return (
        filterCommon && filterInputs(filterCommon.timeWithoutDisbursementFrom, filterCommon.timeWithoutDisbursementTo)
      );
    case 'ageProject':
      return filterCommon && filterInputs(filterCommon.ageProjectFrom, filterCommon.ageProjectTo);
    case 'disbursementProjection':
      return (
        filterCommon && filterInputs(filterCommon.disbursementProjectionFrom, filterCommon.disbursementProjectionTo)
      );
  }

  return false;
};

export const handleHaveFiltersInputsAndSelects = (filterToCheck: string, filter: FilterEarlyWarnings) => {
  return handleFilterToCheck(filterToCheck, filter.common);
};
