import React from 'react';
import './DoughnutChart.scss';
import { Doughnut } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { mapClassification } from '../../../../PortfolioDetail/PortfolioDetailsUtil';
import { isMobile } from '../../../../../services/booleanUtil';
import { EfasConcepts } from '../../../../../models/EfasConcepts';
import { IndicatorKeyEnum } from '../../../../../models/IndicatorKeyEnum';
import { QCalificationOrder } from '../../../../../models/EsgQCalification';
import { ReportImageDisplay } from '../../../../Util/Modals/CustomizableReports/CustomizableReports';

type CustomDimensions = {
  width: number;
  height: number;
};


type Props = {
  card: any;
  customStyle: any;
  detailPage?: boolean;
  onFooter?: boolean;
  datasetClick?: Function;
  customDimensions?: CustomDimensions;
  forReport?: ReportImageDisplay;
};

const DoughnutChart = ({
  card: { data, indicatorKey, alert_number, alert_text },
  customStyle: { flexFlowStyle },
  detailPage,
  onFooter,
  customDimensions,
  datasetClick,
  forReport,
}: Props) => {
  const { t } = useTranslation();
  const {
    dataLabelsClasses,
    projectQuantity: projects,
    dataLabelsTranslations,
    conceptTranslation,
  } = data && data.datasets ? data.datasets[0] : [];
  const listColumns = data && data.columns ? data.columns : 1;
  const dimensions = setDimensions(customDimensions);
  const infoListStyle = setInfoListStyle({ detailPage, listColumns });

  const options = {
    cutoutPercentage: 75,
    tooltips: {
      displayColors: false,
      backgroundColor: '#101319',
      titleFontSize: 14,
      bodyFontSize: 14,
      callbacks: {
        title: (tooltipItem, data) => {
          return t(data['labels'][tooltipItem[0]['index']]);
        },
        label: (tooltipItem, data) => {
          return `${data['datasets'][0]['data'][tooltipItem['index']].toFixed(0)} %`;
        },
      },
    },
    legend: {
      display: false,
    },
    plugins: {
      datalabels: { display: false },
    },
    animation: {
      duration: forReport ? 0 : 1000,
    },
  };

  const getElementAtEvent = element => {
    if (element.length !== 0) {
      datasetClick &&
        datasetClick({
          datasetLabel: element[0]._model.datasetLabel,
          label: element[0]._model.label,
          dataIndex: element[0]._index,
        });
    }
  };

  const handleListProjects = value => {
    const obje = {
      datasetLabel: value.datasetLabel,
      label: renderListLabel(indicatorKey, value),
      dataIndex: value.dataIndex,
    };

    datasetClick && datasetClick(obje);
  };

  return (
    <div className="doughnut-chart-container" style={{ flexFlow: flexFlowStyle }}>
      {data ? (
        <>
          <div className="chart">
            <Doughnut
              data={data}
              options={options}
              {...dimensions}
              getElementAtEvent={element => getElementAtEvent(element)}
            />
            <div className="text-alert">
              <span>{alert_number}</span> {t(alert_text)}
            </div>
          </div>
          <div
            className="info-list-container"
            style={!detailPage ? setListInfoPortfolioPage() : setListInfoDetailPage(indicatorKey)}
          >
            <ul className={infoListStyle}>
              {dataLabelsClasses.map((labelClass, ind) => {
                return detailPageListInfoArrangement(
                  {
                    detailPage,
                    onFooter,
                    labelClass,
                    dataLabelsTranslations,
                    t,
                    projects,
                    conceptTranslation,
                    ind,
                    handleListProjects,
                    listColumns,
                  },
                  forReport && forReport === ReportImageDisplay.Detail ? 'white' : '#D0DEF8',
                  indicatorKey,
                );
              })}
            </ul>
          </div>
        </>
      ) : (
        <div className="empty-data">
          <p className="bold-text">-</p>
        </div>
      )}
    </div>
  );
};

export { DoughnutChart };

const detailPageListInfoArrangement = (
  {
    detailPage,
    onFooter,
    labelClass,
    dataLabelsTranslations,
    t,
    projects,
    conceptTranslation,
    ind,
    handleListProjects,
    listColumns,
  },
  customFontColor,
  indicatorKey,
) => {
  if (detailPage) {
    return renderDetailPage(
      {
        onFooter,
        labelClass,
        handleListProjects,
        ind,
        t,
        dataLabelsTranslations,
        projects,
        conceptTranslation,
      },
      customFontColor,
    );
  } else {
    return renderFooterOnPortfolioList(
      {
        labelClass,
        dataLabelsTranslations,
        projects,
        ind,
        conceptTranslation,
        t,
        listColumns,
      },
      customFontColor,
      indicatorKey,
    );
  }
};

const returnConceptTransaltionPluralOrSingular = ({ projects, ind, conceptTranslation, t }) => {
  return projects[ind] > 1 ? t(conceptTranslation.plural) : t(conceptTranslation.singular);
};

const setDimensions = chartDimensions => {
  if (isMobile()) {
    return chartDimensions ? chartDimensions : { width: 260, height: 40 };
  } else {
    return chartDimensions ? chartDimensions : { width: 280, height: 120 };
  }
};

const setInfoListStyle = ({ detailPage, listColumns }) => {
  if (detailPage) {
    return 'info-list--detail-page';
  } else {
    if (listColumns === 1) {
      return 'info-list--card-indicator--one-col';
    } else {
      return 'info-list--card-indicator';
    }
  }
};

const returnSelectedConcept = ind => {
  return {
    datasetLabel: undefined,
    label: 'satisfactory',
    dataIndex: ind,
  };
};

const setListInfoPortfolioPage = () => {
  return isMobile() ? { margin: '0 auto 0 20px', maxWidth: '140px' } : { margin: '0px auto 0 0px' };
};
const setListInfoDetailPage = (key: IndicatorKeyEnum) => {
  return isMobile()
    ? { marginLeft: '10%' }
    : key === IndicatorKeyEnum.TCP_PERFORMANCE
      ? { marginLeft: 0, marginTop: '20px' }
      : { marginLeft: '5%' };
};

const renderDetailPage = (
  { onFooter, labelClass, handleListProjects, ind, t, dataLabelsTranslations, projects, conceptTranslation },
  customFontColor,
) => {
  if (onFooter || isMobile()) {
    return (
      <li
        key={labelClass}
        className={`${labelClass} bullets-icons`}
        style={{
          width: isMobile() ? '28%' : '40%',
          marginLeft: isMobile() ? '20px' : '15px',
          marginRight: '15px',
          fontSize: '12px',
          lineHeight: '11px',
          float: 'left',
          color: customFontColor,
        }}
        onClick={() => handleListProjects(returnSelectedConcept(ind))}
      >
        {t(dataLabelsTranslations[ind].toLowerCase())}
        <p style={isMobile() ? { fontSize: '12px' } : {}}>
          {projects[ind]}
          <span>{returnConceptTransaltionPluralOrSingular({ projects, ind, conceptTranslation, t })}</span>
        </p>
      </li>
    );
  } else {
    return (
      <li
        key={labelClass}
        className={labelClass}
        style={{
          fontSize: '12px',
          lineHeight: '11px',
          color: customFontColor,
        }}
        onClick={() => handleListProjects(returnSelectedConcept(ind))}
      >
        {t(dataLabelsTranslations[ind].toLowerCase())}:
        <p style={isMobile() ? { fontSize: '12px', marginLeft: '10px' } : {}}>
          {projects[ind]}

          <span>{returnConceptTransaltionPluralOrSingular({ projects, ind, conceptTranslation, t })}</span>
        </p>
      </li>
    );
  }
};

const renderFooterOnPortfolioList = (
  { labelClass, dataLabelsTranslations, projects, ind, conceptTranslation, t, listColumns },
  customFontColor,
  indicatorKey,
) => {
  const maxVisibleItemsOnGridList =
    indicatorKey === IndicatorKeyEnum.ESG_DISTRIBUTION_ACCORDING_TO_ENVIRONMENTAL_AND_SOCIAL_CATEGORY ? 5 : 4;

  return (
    dataLabelsTranslations[ind] !== 'pending' && (
      <li
        key={labelClass}
        className={`data-label ${labelClass}`}
        style={
          isMobile()
            ? { width: 140, display: ind >= maxVisibleItemsOnGridList ? 'none' : '' }
            : listColumns > 1
              ? {
                width: '50%',
                justifyContent: 'center',
                display: ind >= maxVisibleItemsOnGridList ? 'none' : 'inline',
                color: customFontColor,
              }
              : {}
        }
      >
        <span className="ci-type-text-6">{`${t(dataLabelsTranslations[ind])}: `}</span>
        {!isMobile() && (
          <span className="ci-type-text-5">
            {projects[ind] > 1
              ? projects[ind] + ' ' + t(conceptTranslation.plural)
              : projects[ind] + ' ' + t(conceptTranslation.singular)}
          </span>
        )}
      </li>
    )
  );
};

const renderListLabel = (indicatorKey, value) => {
  switch (indicatorKey) {
    case IndicatorKeyEnum.DISTRIBUTION_ACCORDING_TO_LATEST_EFAS:
      return EfasConcepts[value.dataIndex];
    case IndicatorKeyEnum.PMR_STATE_DISTRIBUTION:
    case IndicatorKeyEnum.TCP_PERFORMANCE:
      return mapClassification(value.dataIndex);
    case IndicatorKeyEnum.ESG_DISTRIBUTION_ACCORDING_TO_ENVIRONMENTAL_AND_SOCIAL_CATEGORY:
      return QCalificationOrder[value.dataIndex];

    default:
      return '-';
  }
};
