import { isEqual } from 'lodash';
import moment from 'moment';
import { ProjectProductTypeClassification } from '../models/ProjectProductTypeClassification';
import { ProjectProductTypes } from '../models/ProjectProductTypes';
import { RolesIdEnum } from '../models/RolesIdEnum';
import { responsiveWidth } from './numberUtil';

const isIE11 = () => {
  return !!window.MSInputMethodContext && !!document.DOCUMENT_NODE;
};
const isScrollAtBottom = () => {
  const windowHeight = 'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;
  const body = document.body;
  const html = document.documentElement;
  const docHeight = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight,
  );
  const windowBottom = windowHeight + window.pageYOffset;
  return windowBottom + 1 >= docHeight;
};

const isMobile = () => window.innerWidth <= responsiveWidth;

const urlIsImage = url => {
  return url.match(/\.(jpeg|jpg|gif|png|svg)$/) != null;
};

function isEmpty(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
}

function isTinyTable(receivedNumber) {
  return receivedNumber + 3 <= 5 ? true : false;
}

const isEqualDefaultAndActualState = (currentIndicator, defaultIndicator) => {
  return isEqual(currentIndicator, defaultIndicator);
};

const allowedCharacterCode = characterCode => {
  return (
    characterCode !== 'Backspace' &&
    characterCode !== 'ArrowUp' &&
    characterCode !== 'ArrowDown' &&
    characterCode !== 'Tab'
  );
};

const isAuthorizedToEdit = (user, currentProject) => {
  const isTeamProjectMember =
    currentProject &&
    currentProject.projectTeam &&
    currentProject.projectTeam.find(projectTeamUser => projectTeamUser.applicationUserId === user.id);

  return user.role.id === RolesIdEnum.Chief_of_Operations || isTeamProjectMember;
};
const isMillionNumber = number => {
  return number >= 2000000;
};

const renderDecimalNumber = number => {
  return number % 1 >= 0.1;
};

const isValidYear = (date, minValidYear) => {
  return moment(date).year() > minValidYear;
};

const showIndexTCDI = (projectProductType, igGreater3) => {
  return (
    projectProductType === ProjectProductTypes.CT || (projectProductType === ProjectProductTypes.IG && !igGreater3)
  );
};

const showDisbursement = (project, filter) => {
  const { projectProductType, projectProductTypeClassification } = project;
  const { isAnnual } = filter.common;

  if (
    isAnnual === 'annual' &&
    (projectProductType === ProjectProductTypes.CT ||
      (projectProductType === ProjectProductTypes.IG &&
        projectProductTypeClassification !== ProjectProductTypeClassification.IGR_GREATER_THAN_3MM))
  ) {
    return false;
  }

  return true;
};

const validateMicrosoftStreamVideoShareLink = (link: string) => {
  const regex = /https:\/\/web\.microsoftstream\.com\/video\//i;
  return regex.test(link);
};

export {
  isIE11,
  isScrollAtBottom,
  isMobile,
  urlIsImage,
  isEmpty,
  isTinyTable,
  isEqualDefaultAndActualState,
  allowedCharacterCode,
  isAuthorizedToEdit,
  isMillionNumber,
  renderDecimalNumber,
  isValidYear,
  showIndexTCDI,
  showDisbursement,
  validateMicrosoftStreamVideoShareLink,
};
