import React from 'react';
import { ReactComponent as EqualTrend } from '../../../assets/dynamic-trend-equal.svg';
import { ReactComponent as DownTrend } from '../../../assets/dynamic-trend-down.svg';
import { ReactComponent as UpTrend } from '../../../assets/dynamic-trend-up.svg';
import { PrevTrend } from '../../Util/Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';
import './DynamicTrend.scss';
type Props = {
  trend: any;
  prevTrend: any;
};
export const DynamicTrend = ({ trend, prevTrend }: Props) => {
  const { t } = useTranslation();

  const tootltipText = () => {
    return (
      <div className="dynamic-trend-tooltip">
        <div className="title">{t('previous_performance')}:</div>
        <div className="trend-value">{getPrevTrendTranslation(prevTrend, t)}</div>
      </div>
    );
  };
  return (
    <PrevTrend title={tootltipText()} placement="top-end" enterDelay={500} leaveDelay={200} arrow>
      <div> {getTrend(trend)} </div>
    </PrevTrend>
  );
};

export const getTrend = trend => {
  switch (trend) {
    case 'Equal':
      return <EqualTrend />;
    case 'Desc':
      return <DownTrend />;
    case 'Asc':
      return <UpTrend />;
    case 'N/A':
      return 'N/A';

    default:
      return null;
  }
};

const getPrevTrendTranslation = (prevTrend, t) => {
  switch (prevTrend) {
    case 'Satisfactory':
      return t('satisfactory');
    case 'Unsatisfactory':
      return t('unsatisfactory');
    case 'Partially_Satisfactory':
      return t('partially_satisfactory');
    case 'Partially_Unsatisfactory':
      return t('partially_unsatisfactory');
    case 'High':
      return t('high');
    case 'Low':
      return t('low');
    case 'Substantial':
      return t('substantial');
    case 'Moderate':
      return t('moderate');
    case 'N/A':
      return 'N/A';
    default:
      return 'N/A';
  }
};
