import { isOnCtTab } from '../EarlyWarningsFilters/Util';
import { TabsFiltersEnum } from './TabsFilter/TabsFilter';

const showEarlyWarningModule = (projectTypeFilterOnDetail, selectedTab) => {
  if (isOnCtTab(projectTypeFilterOnDetail)) {
    return false;
  } else if (selectedTab === TabsFiltersEnum.SIGNATURE_PENDING) {
    return true;
  }
  return false;
};

export { showEarlyWarningModule };
