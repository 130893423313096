import React, { ReactElement } from 'react';
import { BidTooltip } from '../../Util/Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';
import { Line } from 'rc-progress';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import './CardProject.scss';
import Project from '../../../models/Project';
import Division from '../../../models/Division';
import FinancialInformation from '../../../models/FinancialInformation';
import TemporaryAdvance from '../../../models/TemporaryAdvance';
import { isMobile, showDisbursement } from '../../../services/booleanUtil';
import { NavigationCardsEnum } from '../CardsProjectList/CardsProjectListUtil';
import { CodeAndLoan } from '../CodeAndLoan/CodeAndLoan';
import { trackActionClicked } from '../../../api/trackActionClickedApi';
import { TrackedActions } from '../../../models/TrackedActionsEnum';
import { CircleAvatar } from '../../Util/CircleAvatar/CircleAvatar';
import { ProjectProductTypesAvatarText } from '../../../models/ProjectProductTypesAvatarText';
import { CardIndex } from '../CardIndex/CardIndex';
import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentProject } from '../../../redux/actions/currentProject';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';

 const BlackTooltip = withStyles({
        tooltip: {
        //   color: '$ci-color-greyscale-90',
          backgroundColor: '#101319',
          fontSize: '16px',
          fontFamily: 'Rubik',
          fontWeight: '400',
          lineHeight: '20px',
          padding:'8px 12px',
          borderRadius: '8px'
        },
        arrow: {
          fontSize: 20,
          color: '#4A4A4A',
          '&::before': {
            backgroundColor: '#101319',
          },
        },
      })(Tooltip);


interface ReceivedProps {
  key: number;
  project: Project;
  setInFollow: any;
  navigationCards?: NavigationCardsEnum;
}

type Props = ReceivedProps;

export const CardProject = ({ navigationCards, project, setInFollow }: Props): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { filter } = useSelector<any, any>(state => state);
  const division: Division = project.division;
  const financialInformation: FinancialInformation = project.financialInformation;
  const temporaryAdvance: TemporaryAdvance = project.temporaryAdvance;
  const history = useHistory();

  const handleClick = () => {
    dispatch(updateCurrentProject(project));
    history.push(`/projectdetail?id=${project.id}`);
    trackActionClicked(TrackedActions.projectDetailsFromCardClicked);
  };

  const handleSetInFollow = (id: number, e: any) => {
    setInFollow(id);
    e.stopPropagation();
  };
  const renderParams = {
    project,
    handleClick,
    handleSetInFollow,
    division,
    financialInformation,
    temporaryAdvance,
    t,
    filter,
  };
  return isMobile() ? (
    <div className="card-swiper-container">
      <div
        className={
          navigationCards === NavigationCardsEnum.last || navigationCards === NavigationCardsEnum.center
            ? navigationCards === NavigationCardsEnum.center
              ? 'card-swiper-left last'
              : 'card-swiper-left'
            : ''
        }
      ></div>
      <div className="card-swiper-center">{renderCardsProjects(renderParams)}</div>
      <div
        className={
          navigationCards === NavigationCardsEnum.first || navigationCards === NavigationCardsEnum.center
            ? 'card-swiper-right'
            : ''
        }
      ></div>
    </div>
  ) : (
    renderCardsProjects(renderParams)
  );
};

export const renderCardsProjects = renderParams => {
  const { project, handleClick, handleSetInFollow, division, financialInformation, temporaryAdvance, t, filter } =
    renderParams;

  return (
    <div className={project.assignedToMe === true ? 'card-asigned' : 'card'} onClick={handleClick}>
      <div className="title">
        <p className="code">
          <CodeAndLoan project={project} isCard={true} />
        </p>
        <p className="is-follow">
          {project.inFollow ? (
            <BlackTooltip arrow title={t('following')} placement="bottom" enterDelay={500} leaveDelay={200}>
              <span onClick={e => handleSetInFollow(project.id, e)} id="setInFollow" className="bg-img" />
            </BlackTooltip>
          ) : (
            ''
          )}
        </p>
      </div>
      <div className="title-cards-container">
        <div className="project-name">{project.name}</div>
        <div className="project-division">{division.name}</div>
      </div>
      <div className="foot-info">
        {!isMobile() && (
          <div className="graphs">
            {showDisbursement(project, filter) && (
              <div className="financialInformation">
                <span className="foot-titles">{t('financialInformation')}</span>

                <div className="graph-distance-from-titles">
                  <Line
                    percent={financialInformation.percentRealizedDisbursement}
                    strokeWidth={5}
                    trailWidth={5}
                    strokeColor="#236BFF"
                    trailColor="#E7EDF3"
                  />
                </div>

                <div className="graphs-info">
                  <span className="money-month">
                    <NumberFormat
                      value={financialInformation.realizedDisbursementAmount}
                      displayType={'text'}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      suffix={t('dolar_sign')}
                    />
                  </span>
                  <span className="money-month right-align">
                    <NumberFormat
                      value={financialInformation.pendingDisbursementAmount}
                      displayType={'text'}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      suffix={t('dolar_sign')}
                    />
                  </span>
                </div>
                <div className="graphs-info">
                  <span className="money-month-percentage">
                    {financialInformation.percentRealizedDisbursement}
                    {t('percentage_paid')}
                  </span>
                  <span className="money-month-percentage right-align">
                    {financialInformation.percentPendigDisbursementAmount}
                    {t('percentage_pending')}
                  </span>
                </div>
              </div>
            )}

            {temporaryAdvance && (
              <div className="temporary-advance" style={!showDisbursement(project, filter) ? { marginTop: 44 } : {}}>
                <div className="graphs-info extra-space">
                  <span className="disbursment-titles">{t('temporary_advance')}</span>
                  <span className="days-from-financialInformation right-align">
                    {financialInformation.lastDisbursementInDays} {t('days_from_last_disbursement')}
                  </span>
                </div>
                <div className="graph-distance-from-titles">
                  <Line
                    percent={temporaryAdvance.percentTemporaryAdvanceMade}
                    strokeWidth={5}
                    trailWidth={5}
                    strokeColor="#236BFF"
                    trailColor="#E7EDF3"
                  />
                </div>
                <div className="graphs-info">
                  <span className="money-month">
                    {temporaryAdvance.temporaryAdvanceMade}
                    {temporaryAdvance.temporaryAdvanceMade !== undefined && temporaryAdvance.temporaryAdvanceMade > 1
                      ? t('months')
                      : t('month')}
                  </span>
                  <span className="money-month right-align">
                    {temporaryAdvance.pendingAdvance}{' '}
                    {temporaryAdvance.pendingAdvance !== undefined && temporaryAdvance.pendingAdvance > 1
                      ? t('months')
                      : t('month')}
                  </span>
                </div>
                <div className="graphs-info">
                  <span className="money-month-percentage-temporary-advance">
                    {`${temporaryAdvance.percentTemporaryAdvanceMade}${t('percentage_sign')} ${t('actual')}`}
                  </span>
                  <span className="money-month-percentage  right-align">
                    {temporaryAdvance.percentPendingAdvance}
                    {t('percentage_pending')}
                  </span>
                </div>
              </div>
            )}
          </div>
        )}
        <CardIndex project={project} />
      </div>
      <div className="ct-icon">{setProjectTypeAvatar(project.projectProductType)}</div>
    </div>
  );
};

const setProjectTypeAvatar = projectType => {
  const mapCircleColor = ['#b194be', '', '#78aeae', '#f9c268'];
  return <CircleAvatar projectType={ProjectProductTypesAvatarText[projectType]} />;
};
