import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { overflowYModalStyles } from '../Styles';
import './EventDetail.scss';
import EventDetailInfo from '../../../ProjectDetail/Calendar/EventDetailInfo/EventDetailInfo';
import { animateScroll } from 'react-scroll';
import { setProjectTabValue } from '../../../../redux/actions/projectTabValue';
import CloseCrossButton from '../../Buttons/CloseCrossButton/CloseCrossButton';
import { EventType } from '../../../../models/EventType';
import BaseModal from '../BaseModal';

type Props = {
  isOpen: boolean;
  closeModal: any;
  event: any;
  eventDetail: any;
};

const EventDetail = (props: Props) => {
  const { isOpen, closeModal, event, eventDetail } = props;
  const dispatch = useDispatch();
  const [isEditState, setIsEditState] = useState(false);

  const seeMore = (projectTab: number) => {
    closeModal();
    dispatch(setProjectTabValue(projectTab));
    setTimeout(() => {
      animateScroll.scrollToBottom({ smooth: true, duration: 1000 });
    }, 300);
  };

  const remiderCreate = () => {
    closeModal(true);
  };

  return (
    <BaseModal
      isOpen={isOpen}
      onRequestClose={() => closeModal(false)}
      style={overflowYModalStyles}
      className={setModalDetailContentClassname(event, isEditState)}
    >
      {showCloseCrossButton(event, closeModal)}
      <EventDetailInfo
        event={event}
        seeMore={seeMore}
        eventDetail={eventDetail}
        createReminder={remiderCreate}
        closeModal={() => closeModal(false)}
        modalEditStateUpdate={setIsEditState}
      />
    </BaseModal>
  );
};

export default EventDetail;

const setModalDetailContentClassname = (event, isEditState) => {
  if (
    event &&
    (event.eventType === EventType.SupervisoryAction || event.eventType === EventType.SupervisoryAction.toString())
  ) {
    return !isEditState ? 'supervision-plan-modal-content' : 'supervision-plan-modal-edit-content';
  } else {
    return 'modal-event-detail-content';
  }
};

const showCloseCrossButton = (event, closeModal) => {
  if (
    event &&
    !(event.eventType === EventType.SupervisoryAction || event.eventType === EventType.SupervisoryAction.toString())
  ) {
    return (
      <div className="close-cross-container">
        <CloseCrossButton handleClick={() => closeModal(false)} />
      </div>
    );
  }
  return null;
};
