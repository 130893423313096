import React from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionSummary, AccordionDetails } from '../AccordionStyles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SingleInputs from '../SingleInputs';
import { AccordanceType } from '../../../../../../models/AccordanceType';
import NewAccordanceFormButtons from '../NewAccordanceFormButtons';
import { Tab, Tabs } from '@material-ui/core';
import ReadonlyShared from './ReadonlyShared';
import SharedInputs from '../SharedInputs';
import useMultiAccordanceFormState from '../../../../../../hooks/useMultiAccordanceFormState';
import { AccordanceState } from '../../../../../../models/AccordanceState';
import { useSelector } from 'react-redux';
import Project from '../../../../../../models/Project';

type Props = {
  bundleAccordances: any[];
  possibleActionsList: any;
  accordanceFormType: AccordanceType;
  accordanceState?: number;
  supervisionActionList: any;
  closeModal: Function;
  currentUserCanApprove?: boolean;
  canCreatePortfolioReviewAccordances?: boolean;
  canCreateMonitoringAccordances?: boolean;
  canCreateInterGroupAccordances?: boolean;
  bundleId?: string;
  showConfirmDelete?: Function;
};

const MultiAccordances = (props: Props) => {
  const { t } = useTranslation();
  const currentProject = useSelector<any, Project>(state => state.currentProject);
  const { accordanceFormType, accordanceState, supervisionActionList, closeModal, currentUserCanApprove } = props;

  const {
    tabValue,
    handleTabChange,
    classes,
    itemsList,
    isEdit,
    setIsEdit,
    tabClasses,
    criticalIssueList,
    accordances,
    updateSharedValues,
    handleCriticalIssueChange,
    localAccordanceType,
    handleExpanded,
    expandedAccordances,
    localPossibleActionsList,
    handleSupervisionActionChange,
    handleResponsibleChange,
    handleExpirationDate,
    handlePossibleActionChange,
    handleDescription,
    addFileToList,
    removeFileFromList,
    removeAccordance,
    addNewAccordance,
    submitAccordances,
    submitting,
    handleRootCauseOfCriticalChange,
    handleRootCauseChange,
    rootCauseCategoriesForm,
    rootCauseForm,
    accordanceType,
    productForm,
    handleAccordanceType,
    updateSharedValueRevision,
    updateSharedValuesProducts,
  } = useMultiAccordanceFormState(props);

  const checkCriticalIssue = () => {
    const value = accordances[0].criticalIssue
      ? criticalIssueList.find(ci => ci.id === accordances[0].criticalIssue.id)
      : null;
    return value;
  };

  const accordanceStateNames = {
    0: 'draft_accordance',
    1: 'pending_accordance_singular',
    2: 'active_accordance_singular',
    3: 'accomplished_accordance_singular',
    4: 'cancelled_accordance_singular',
  };

  return (
    <>
      <div className="new-accordance-form-header">
        <h3>
          {t(
            accordances[0] && accordances[0].bundleId && accordances[0].accordanceState === AccordanceState.DRAFT
              ? 'edit_accordance'
              : 'new_accordance',
          )}{' '}
          / Estado:
          <span> {t(accordanceStateNames[accordances[0].accordanceState])}</span>
        </h3>
        <p>
          <b>{currentProject.code}</b> {t('LON_table')}: {currentProject.loanNumber}
        </p>
      </div>
      {/* <Tabs
        value={tabValue}
        onChange={handleTabChange}
        classes={{ indicator: classes.indicatorColor }}
        variant="standard"
        aria-label="full width tabs example"
      >
        {itemsList.map((item: any) => (
          <Tab disabled={!isEdit} key={item.id} classes={tabClasses} label={item.label} />
        ))}
      </Tabs> */}
      {/* <div style={{ width: '100%', height: 50 }}></div> */}
      {isEdit ? (
        <>
          <p className={'multi-accordance-warning'}>{t('edition_will_apply_to_all')}</p>
          <SharedInputs
            isMulti={true}
            criticalIssueList={criticalIssueList}
            criticalIssue={checkCriticalIssue()}
            handleCriticalIssueChange={handleCriticalIssueChange}
            formErrors={accordances[0].formErrors}
            category={accordances[0].category}
            expirationDate={accordances[0].expirationDate}
            revisionDate={accordances[0].revisionDate}
            criticalIssueDescription={accordances[0].criticalIssueDescription}
            handleCriticalIssueDescription={(e, value) => updateSharedValues(e, value, 'criticalIssueDescription')}
            setFormErrors={(e, value) => updateSharedValues(e, value, 'formErrors')}
            setRevisionDate={value => updateSharedValueRevision(value, 'revisionDate')}
            setExpirationDate={(e, value) => updateSharedValues(e, value, 'expirationDate')}
            setSemesterOption={(e, value) => updateSharedValues(e, value, 'semesterOption')}
            semesterOption={accordances[0].semesterOption}
            accordanceFormType={localAccordanceType}
            definedAgreement={accordances[0].accordanceAgreementsType || accordances[0].definedAgreement}
            linkedProduct={
              accordances[0].linkedProductsAccordance === null ? [] : accordances[0].linkedProductsAccordance
            }
            handleDefinedAgreementChange={(e, value) => updateSharedValues(e, value, 'accordanceAgreementsType')}
            handleLinkedProduct={(e, value) => updateSharedValues(e, value, 'linkedProductsAccordance')}
            productForm={accordances[0].criticalIssue.keyProducts}
            validateTextareaCritical={(e, value) => updateSharedValues(e, value, 'criticalIssueDescription')}
            accordanceDefinitionForm={accordanceType}
          />
        </>
      ) : (
        <ReadonlyShared
          accordance={accordances[0]}
          accordanceFormType={localAccordanceType}
          handleClick={() => setIsEdit(true)}
        />
      )}
      {accordances.map((item, index) => (
        <Accordion
          key={item.internalId}
          elevation={0}
          onChange={(e, exp) => {
            handleExpanded(exp, item.internalId);
          }}
          expanded={expandedAccordances.indexOf(item.internalId) > -1}
        >
          <AccordionSummary className="summary-root" expandIcon={<ExpandMoreIcon />} aria-controls="content">
            {`${t('accordance')} ${(index + 1).toString()}`}
          </AccordionSummary>
          <AccordionDetails className="panel-root">
            <SingleInputs
              isMulti={true}
              criticalIssue={item.criticalIssue}
              possibleAction={item.possibleAction}
              expirationDate={item.expirationDate}
              responsible={item.responsible}
              filesList={item.accordanceFiles}
              description={item.description}
              revisionDate={
                localAccordanceType === AccordanceType.InterGroup && item.semesterOption && item.semesterOption.value
                  ? new Date(item.semesterOption.value)
                  : item.revisionDate
              }
              supervisionAction={item.supervisionAction}
              formErrors={item.formErrors}
              possibleActionsList={localPossibleActionsList}
              accordanceType={localAccordanceType}
              accordanceState={accordanceState}
              supervisionActionList={supervisionActionList}
              handleSupervisionActionChange={value => handleSupervisionActionChange(value, item.internalId)}
              handleIemSelected={value => handleResponsibleChange(value, item.internalId)}
              handleExpirationDate={value => handleExpirationDate(value, item.internalId)}
              handlePossibleActionChange={value => handlePossibleActionChange(value, item.internalId)}
              handleDescription={value => handleDescription(value, item.internalId)}
              addFileToList={value => addFileToList(value, item.internalId)}
              removeFileFromList={value => removeFileFromList(value, item.internalId)}
              removeAccordance={() => removeAccordance(item.internalId)}
              addNewAccordance={() => addNewAccordance(item)}
              internalId={item.internalId}
              rootCauseOfCritical={item.rootCauseOfCritical || item.rootCauseCategory}
              rootCause={item.rootCause}
              handleRootCauseOfCritical={(e, value) => handleRootCauseOfCriticalChange(e, value, item.internalId)}
              handleRootCause={(e, value) => handleRootCauseChange(e, value, item.internalId)}
              rootCauseCategoriesForm={rootCauseCategoriesForm}
              rootCauseForm={rootCauseForm}
              validateTextareaDescription={value => handleDescription(value, item.internalId)}
            />
          </AccordionDetails>
        </Accordion>
      ))}
      {/*   criticalIssue={criticalIssue}
        formErrors={formErrors}
        possibleActionsList={possibleActionsList}
        possibleAction={possibleAction}
        handlePossibleActionChange={handlePossibleActionChange}
        expirationDate={expirationDate}
        handleExpirationDate={handleExpirationDate}
        responsible={responsible}
        handleIemSelected={handleIemSelected}
        filesList={filesList}
        addFileToList={addFileToList}
        removeFileFromList={removeFileFromList}
        editAccordanceData={editAccordanceData}
        accordanceType={accordanceType}
        handleDescription={handleDescription}
        description={description}
        revisionDate={revisionDate}
        accordanceState={accordanceState}
        supervisionActionList={supervisionActionList}
        handleSupervisionActionChange={handleSupervisionActionChange}
        supervisionAction={supervisionAction}
        rootCauseOfCritical={rootCauseOfCritical}
        rootCause={rootCause}
        handleRootCauseOfCritical={handleRootCauseOfCritical}
        handleRootCause={handleRootCause}
        rootCauseCategoriesForm={rootCauseCategoriesForm}
        rootCauseForm={rootCauseForm}
        setFormErrors={setFormErrors} */}
      <NewAccordanceFormButtons
        submit={() => {
          submitAccordances(false);
        }}
        disableDraftSubmit={
          !accordances.some(
            i =>
              i.revisionDate ||
              i.expirationDate ||
              i.semesterOption ||
              i.description ||
              i.criticalIssueDescription ||
              i.accordanceFiles.length,
          )
        }
        submitting={submitting}
        accordanceType={accordanceFormType}
        saveAsDraft={() => {
          submitAccordances(true);
        }}
        currentUserCanApprove={currentUserCanApprove}
        cancel={closeModal}
        publish={() => {
          submitAccordances(false);
        }}
      />
    </>
  );
};

export default MultiAccordances;
