import React from 'react';
import styles from './RelatedProjectLinks.module.scss';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
type Props = {
  loans: any;
  mainLoanNumber: number;
};
const { loansLink, loansLinkUnderline, collapsedContainer, linkButton, othersRowsStyle } = styles;

const getOperations = project => {
  const splitted = project.loanNumber.split('-');
  if (isNaN(parseInt(splitted[splitted.length - 1])) || splitted.length < 2) {
    return project;
  }
  return null;
};

const isOperation = loanNumber => {
  const splitted = loanNumber.split('-');
  return isNaN(parseInt(splitted[splitted.length - 1])) || splitted.length < 2;
};

const removeHyphenNumber = value => {
  const splitted = value.split('-');
  if (isNaN(parseInt(splitted[splitted.length - 1])) || splitted.length < 2) {
    return value;
  } else {
    return splitted.filter((item, index) => index !== splitted.length - 1).join('-');
  }
};

const processFirtsRow = ({ mainLoanNumberIsOperation, operations, subOperations, mainLoanNumber, firstRow }) => {
  //Handle first row of links (operations or suboperations)
  if (mainLoanNumberIsOperation) {
    subOperations.forEach((subOperation: any) => {
      const subOperationParent = removeHyphenNumber(subOperation.loanNumber);
      if (subOperationParent === mainLoanNumber) {
        firstRow.push(subOperation);
      }
    });
  } else {
    const subOperationFormatToOperationFormat = removeHyphenNumber(mainLoanNumber);
    operations.forEach((operation: any) => {
      if (operation.loanNumber === subOperationFormatToOperationFormat) {
        firstRow.push(operation);
      }
    });
    subOperations.forEach((subOperation: any) => {
      const subOperationParent = removeHyphenNumber(subOperation.loanNumber);
      if (subOperationParent === subOperationFormatToOperationFormat) {
        firstRow.push(subOperation);
      }
    });
  }
  return firstRow;
};

const processOthersRows = ({ mainLoanNumberIsOperation, operations, subOperations, mainLoanNumber, othersRows }) => {
  //Handle others rows of links (operations)
  if (mainLoanNumberIsOperation) {
    operations.forEach((operation: any) => {
      if (operation.loanNumber !== mainLoanNumber) {
        othersRows.push([operation]);
      }
    });
  } else {
    operations.forEach((operation: any) => {
      if (operation.loanNumber !== removeHyphenNumber(mainLoanNumber)) {
        othersRows.push([operation]);
      }
    });
  }
  //Handle others rows of links (suboperations)
  othersRows.forEach((row: any) => {
    subOperations
      .filter(subOperation => removeHyphenNumber(subOperation.loanNumber) === row[0].loanNumber)
      .forEach(subOperation => row.push(subOperation));
  });

  return othersRows;
};

const processLinks = (projects, mainLoanNumber) => {
  const operations = projects.filter(project => getOperations(project));
  const subOperations = projects.filter(project => !getOperations(project));
  const mainLoanNumberIsOperation = isOperation(mainLoanNumber);
  const firstRow: any[] = processFirtsRow({
    mainLoanNumberIsOperation,
    operations,
    subOperations,
    mainLoanNumber,
    firstRow: [],
  });
  const othersRows: any[] = processOthersRows({
    mainLoanNumberIsOperation,
    operations,
    subOperations,
    mainLoanNumber,
    othersRows: [],
  });

  return {
    firstRow,
    othersRows,
  };
};
const limitSliceForColapse = 8;

export const RelatedProjectLinks = ({ loans, mainLoanNumber }: Props) => {
  const history = useHistory();
  const handleClick = id => {
    history.push(`/projectdetail?id=${id}`);
  };
  const [relatedProjects, setRelatedProjects] = React.useState<any>({
    firstRow: [],
    othersRows: [],
  });
  const [collapsedLinks, setCollapsedLinks] = React.useState<boolean>(true);
  React.useEffect(() => {
    if (loans?.length) {
      setRelatedProjects(processLinks(loans, mainLoanNumber));
    }
  }, [loans, mainLoanNumber]);

  return (
    <>
      {relatedProjects.firstRow.length >= limitSliceForColapse ? (
        <ColapsedTypelinks
          links={relatedProjects.firstRow}
          handleClick={handleClick}
          mainLoanNumber={mainLoanNumber}
          setCollapsedLinks={setCollapsedLinks}
          collapsedLinks={collapsedLinks}
        />
      ) : (
        <OneTypeLinks links={relatedProjects.firstRow} handleClick={handleClick} mainLoanNumber={mainLoanNumber} />
      )}
      {relatedProjects.othersRows.length
        ? relatedProjects.othersRows.map(otherRow => {
          return (
            <p className={othersRowsStyle}>
              <OneTypeLinks
                links={otherRow}
                handleClick={handleClick}
                mainLoanNumber={mainLoanNumber}
                isOtherRows={true}
              />
            </p>
          );
        })
        : null}
    </>
  );
};

type OneTypeLinksProps = {
  links: any;
  handleClick: any;
  mainLoanNumber: any;
  isOtherRows?: boolean;
};
const OneTypeLinks = ({ links, handleClick, mainLoanNumber, isOtherRows = false }: OneTypeLinksProps) => {
  return (
    links &&
    links.map((link, idx) => {
      return (
        <span key={link.id}>
          {isOtherRows && idx === 0 ? '' : ` | `}
          <span
            className={link.loanNumber === mainLoanNumber ? loansLinkUnderline : loansLink}
            key={link.id}
            onClick={() => handleClick(link.id)}
          >
            {link.loanNumber}
          </span>
        </span>
      );
    })
  );
};

type ColapsedTypelinksProps = {
  links: any;
  handleClick: any;
  mainLoanNumber: any;
  setCollapsedLinks: any;
  collapsedLinks: boolean;
};

const ColapsedTypelinks = ({
  links,
  handleClick,
  mainLoanNumber,
  setCollapsedLinks,
  collapsedLinks,
}: ColapsedTypelinksProps) => {
  const { t } = useTranslation();
  const projectLength = links.length;
  const showedRow = links.slice(0, limitSliceForColapse);
  const colapsed = links.slice(limitSliceForColapse, projectLength);

  return (
    <>
      <OneTypeLinks links={showedRow} handleClick={handleClick} mainLoanNumber={mainLoanNumber} />
      <div className={collapsedContainer}>
        {!collapsedLinks && (
          <OneTypeLinks links={colapsed} handleClick={handleClick} mainLoanNumber={mainLoanNumber} isOtherRows={true} />
        )}
        <span className={linkButton} onClick={() => setCollapsedLinks(!collapsedLinks)}>
          {collapsedLinks ? `[+] ${t('project_links_show_more')} ...` : `[+] ${t('project_links_show_less')} ...`}
        </span>
      </div>
    </>
  );
};
