import React from 'react';
import { CardProjectSkeleton } from '../CardProjectSkeleton/CardProjectSkeleton';
import { cards, calculateCards } from '../commonFunctions';
export const CardProjectSkeletonList = ({ viewportSize }) => {
  const [cardsSkeletons, setCardsSkeletons] = React.useState([<CardProjectSkeleton key="1" />]);

  React.useEffect(() => {
    setCardsSkeletons(cards(calculateCards(viewportSize), CardProjectSkeleton, cardsSkeletons));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{cardsSkeletons}</>;
};
