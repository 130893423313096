import React, { useEffect } from 'react';
import './EsgFilterChart.scss';
import { useTranslation } from 'react-i18next';
import CustomSelectSmall from '../../Util/CustomSelectSmall/CustomSelectSmall';
import { useDispatch, useSelector } from 'react-redux';
import { getNewFilteredIndicators } from '../../../api/indicatorApi';
import { setCurrentIndicator } from '../../../redux/actions/currentIndicator';
import { processIndicators } from '../../Portfolio/indicatorHelper';
import { AppMenuTabs } from '../../MenuTabs/userTabsInterface';

type Props = {
  indicatorKey: any;
  sectorFilter: any;
  regionFilter: any;
};

export const EsgFilterChart = ({ indicatorKey, sectorFilter, regionFilter }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const haveDivisions = useSelector<any, any>(state => state.filterIndicators.common.divisionId);
  const { portfolioSelectedTab } = useSelector<any, AppMenuTabs>(state => state.menuTabs);
  const filterIndicators = useSelector<any, any>(state => state.indicatorDetailFilter.indicatorCharts);

  const regionsState = useSelector<any, any>(state => state.regions);
  const sectorsState = useSelector<any, any>(state => state.sectors);

  const [sectorOption, setSectorOption] = React.useState<any>([{ value: 0, label: t('all') }]);
  const [regionOption, setRegionOption] = React.useState<any>([{ value: 0, label: t('all') }]);
  const [selectSectorValue, setSelectSectorValue] = React.useState(0);

  const [selectRegionValue, setSelectRegionValue] = React.useState(0);

  useEffect(() => {
    if (filterIndicators.region !== undefined && !filterIndicators.firstTime) {
      setSelectRegionValue(filterIndicators.region.regionId);
    }
    const regionsValues = regionsState.map(region => ({ value: region.id, label: region.name }));
    regionsValues.unshift({ value: 0, label: t('all') });
    setRegionOption(regionsValues);

    if (filterIndicators.sector !== undefined && !filterIndicators.firstTime) {
      setSelectSectorValue(filterIndicators.sector.sectorId);
    }
    const sectorsValues = sectorsState.map(sector => ({ value: sector.id, label: sector.code }));
    sectorsValues.unshift({ value: 0, label: t('all') });
    setSectorOption(sectorsValues);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectHandlerRegions = async eventTargetValue => {
    setSelectRegionValue(eventTargetValue);
    const targetValueInt = parseInt(eventTargetValue);
    const options = { regionId: targetValueInt, sectorId: selectSectorValue !== 0 && selectSectorValue };
    const indicatorsWithSectorFilter = await getNewFilteredIndicators(options);
    const indicatorsProc = processIndicators(indicatorsWithSectorFilter);
    const selectIndicatorProc = indicatorsProc.find(item => item.indicatorKey === indicatorKey);
    selectIndicatorProc && dispatch(setCurrentIndicator(selectIndicatorProc));
    regionFilter(targetValueInt !== 0 ? { regionId: targetValueInt } : {});
  };

  const selectHandlerSectors = async eventTargetValue => {
    setSelectSectorValue(eventTargetValue);
    const targetValueInt = parseInt(eventTargetValue);
    const options = { sectorId: targetValueInt, regionId: selectRegionValue !== 0 && selectRegionValue };
    const indicatorsWithSectorFilter = await getNewFilteredIndicators(options);
    const indicatorsProc = processIndicators(indicatorsWithSectorFilter);
    const selectIndicatorProc = indicatorsProc.find(item => item.indicatorKey === indicatorKey);
    selectIndicatorProc && dispatch(setCurrentIndicator(selectIndicatorProc));
    sectorFilter(targetValueInt !== 0 ? { sectorId: targetValueInt } : {});
  };
  return (
    <div className="esg-filter-chart-container">
      {haveDivisions.length === 0 && (
        <div>
          <CustomSelectSmall
            selectValue={selectSectorValue}
            handleChange={selectHandlerSectors}
            label={t('section')}
            options={sectorOption}
          />
        </div>
      )}
      {portfolioSelectedTab.internalId === '0_global_bid' && (
        <div className="have-region">
          <CustomSelectSmall
            selectValue={selectRegionValue}
            handleChange={selectHandlerRegions}
            label={t('region')}
            options={regionOption}
          />
        </div>
      )}
    </div>
  );
};
