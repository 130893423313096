import React from 'react';
import './SelectDateButton.scss';
import { ReactComponent as CalendarIcon } from '../../../../assets/icons/u_calendar-alt.svg';
import { useTranslation } from 'react-i18next';

type Props = {
  submit?: any;
};
export const SelectDateButton = ({ submit }: Props) => {
  const { t } = useTranslation();
  return (
    <div>
      <button
        className='btn btn-primary'
        onClick={() => {
          submit();
        }}
      >
        <CalendarIcon />
        {t('choose_date')}
      </button>
    </div>
  );
};
