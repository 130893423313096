import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MultiDataNotStackedBar } from '../../../../Portfolio/CardIndicator/Cards/MultiDataNotStackedBar/MultiDataNotStackedBar';
import styles from './IndicatorTransactionalSupervisionResources.module.scss';
import { translateMultipleText } from '../../../../../services/stringUtil';
import NumberFormat from 'react-number-format';
import { customOptions, emptyIndicatorObject, getIndicatorDataChart, IIndicatorObject } from './util';
import SpinningLoader from '../../Loader/SpinningLoader';
import { ISupervisionPlanMetric } from '../SupervisionAccordion/SupervisionAccordion';

type Props = {
  data: ISupervisionPlanMetric[];
};

export const IndicatorTransactionalSupervisionResources = ({ data }: Props) => {
  const { t } = useTranslation();
  const [dataChart, setDataChart] = useState<IIndicatorObject>(emptyIndicatorObject);

  useEffect(() => {
    data.length && setDataChart(getIndicatorDataChart(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const transactionalDimensions = {
    mobile: {
      width: 190,
      height: 110,
    },
    desktop: {
      width: 320,
      height: 190,
    },
  };

  const labelStyle = [styles.labelIndex0, styles.labelIndex1, styles.labelIndex2];
  return (
    <div className={styles.indicatorTransactionalResources}>
      {data.length ? (
        <>
          <div className={styles.header}>
            <span className={styles.title}>{t('transactional_resources_for_supervision')}</span>
          </div>
          <div className={styles.chartSection}>
            <div className={styles.chartContainer}>
              <div className={styles.chartYTitle}>{`${t('dolar_sign')} (M)`}</div>
              <div className={styles.barCharts}>
                <MultiDataNotStackedBar
                  data={dataChart}
                  customOption={customOptions}
                  customDimensions={transactionalDimensions}
                />
              </div>
            </div>
            <div className={styles.chartList}>
              <div className={styles.listInfo}>
                <ul>
                  {dataChart.totals.map((item, index) => {
                    return (
                      <li key={index} className={`${labelStyle[index]}`}>
                        <span className={styles.listValue}>
                          <NumberFormat
                            value={item.value}
                            displayType={'text'}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            suffix={`${t('dolar_sign')} `}
                          />
                        </span>
                        <p className={styles.listLabel}> {translateMultipleText(item.label, t)}</p>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </>
      ) : (
        <SpinningLoader />
      )}
    </div>
  );
};
