import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { styles } from '../Styles';
import './LinkEventOutlook.scss';
import SaveButton from '../../Buttons/SaveButton/SaveButton';
import CustomSelect from '../../CustomSelect/CustomSelect';
import CloseCrossButton from '../../Buttons/CloseCrossButton/CloseCrossButton';
import { options } from './util';
import CustomSwitch from '../../CustomSwitch/CustomSwitch';
import { getCalendarEventInfo, syncCalendarEventOutlook } from '../../../../api/calendarApi';
import BaseModal from '../BaseModal';

type Props = {
  isOpen: boolean;
  closeModal: any;
  event: any;
  currentProjectId?: any;
};

const LinkEventOutlook = (props: Props) => {
  const { t } = useTranslation();
  const { isOpen, closeModal, event, currentProjectId } = props;
  const currentProject = useSelector<any, any>(state => state.currentProject);
  const [reminderEvent, setReminderEvent] = React.useState<any>(0);
  const [eventSyncronized, setEventSyncronized] = React.useState<any>(false);
  const [hasReminder, setHasReminder] = React.useState<any>(false);

  const syncData = async () => {
    const data = {
      eventId: parseInt(event.id),
      eventType: parseInt(event.eventType),
      reminder: reminderEvent,
      syncOutlook: eventSyncronized,
      projectId: event.projectId,
    };

    await syncCalendarEventOutlook(currentProjectId || currentProject.id, parseInt(event.id), data);
    closeModal(true);
  };

  const selectReminder = value => {
    setReminderEvent(value.id);
  };

  const handleSwitchChange = () => {
    setEventSyncronized(!eventSyncronized);
  };

  const prePopulateData = async () => {
    const data = await getCalendarEventInfo(event.id, event.eventType);
    if (data) {
      setReminderEvent(data.reminder);
      setEventSyncronized(data.syncOutlook);
      setHasReminder(true);
    } else {
      setReminderEvent(7);
      setEventSyncronized(false);
    }
  };

  React.useEffect(() => {
    if (isOpen) {
      prePopulateData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <BaseModal isOpen={isOpen} onRequestClose={closeModal} style={styles} className="modal-link-event-outlook-content">
      <div className="modal-link-event-outlook-container">
        <div className="cross-container">
          <CloseCrossButton handleClick={closeModal} />
        </div>
        <h3>{hasReminder ? t('created_reminder') : t('create_reminder')}</h3>
        <div className="label-select">
          <span className="label">{t('remind')}</span>
          <CustomSelect
            style={{ width: 185 }}
            handleSelected={selectReminder}
            options={options}
            selectedOption={options.find(item => item.id === reminderEvent)}
            disabled={hasReminder}
          />
        </div>
        <div className="switch-container">
          <p>{t('link_with_outlook')}</p>
          <CustomSwitch handleChange={handleSwitchChange} active={eventSyncronized} disabled={hasReminder} />
        </div>
        <h5>{t('sync_outlook_message')}</h5>
        {!hasReminder && (
          <div className="horizontal">
            <SaveButton
              handleClick={syncData}
              customText={t('create')}
              customStyle={{ padding: '0px 40px' }}
              disabled={!reminderEvent && !eventSyncronized}
            />
          </div>
        )}
      </div>
    </BaseModal>
  );
};

export default LinkEventOutlook;
