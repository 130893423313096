import { SET_CURRENT_REVIEW, RESET_CURRENT_REVIEW } from './types';

export function setCurrentReview(data: any) {
  return {
    type: SET_CURRENT_REVIEW,
    payload: data,
  };
}

export function resetCurrentReview() {
  return {
    type: RESET_CURRENT_REVIEW,
  };
}
