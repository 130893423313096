import { CurrentReviewActionTypes, SET_CURRENT_REVIEW, RESET_CURRENT_REVIEW } from '../actions/types';

export const initialState: any = null;

const currentReviewReducer = (state = initialState, action: CurrentReviewActionTypes): any => {
  switch (action.type) {
    case SET_CURRENT_REVIEW:
      return action.payload;
    case RESET_CURRENT_REVIEW:
      return null;
    default:
      return state;
  }
};

export default currentReviewReducer;
