import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ModalAlert } from '../../../../assets/alerta-modal.svg';
import { dataTypes } from '../../ContentManagerKeys';
import { renderModalTextType } from '../util';
import styles from './DeleteEntitiesModalConfirmation.module.scss';

type Props = {
  closeModal: Function;
  confirm: Function;
  itemDataType: dataTypes;
};
export const DeleteEntitiesModalConfirmation = ({ closeModal, confirm, itemDataType }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.layout}>
      <div className={styles.rowHorizontal}>
        <ModalAlert />
      </div>
      <div className={styles.rowHorizontal}>
        <div className={styles.title}>{`¿${t('you_want_to_delete')} ${t(renderModalTextType(itemDataType))}?`}</div>
      </div>

      <div className={styles.buttonsContainer}>
        <button style={{ width: '185px' }} className={styles.button} onClick={() => confirm()}>
          {t('delete')}
        </button>
        <button style={{ width: '185px' }} className={styles.secondaryButton} onClick={() => closeModal()}>
          {t('cancel')}
        </button>
      </div>
    </div>
  );
};
