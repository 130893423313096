import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Bubble } from '../BubbleElement/BubbleElement';
import { PmrClassification } from '../../../models/PmrClassification';
import useFilters from '../../../hooks/useFilters';
import { CalledFrom } from '../../../models/CalledFromEnum';
import { IFilterBubble } from '../../../models/FilterBubble';
import { handleActualState, handleBubbles } from '../UtilFunctions';

type Props = IFilterBubble;

const PmrClassificationBubbles = (props: Props) => {
  const { indicatorCall, calledFrom } = props;
  const {
    filter,
    filterIndicators,
    dispatch,
    setFilter,
    setFilterIndicators,
    filterEarlyWarnings,
    setFilterEarlyWarnings,
  } = useFilters();
  const bubbles = handleBubbles({ calledFrom, filterEarlyWarnings, indicatorCall, filterIndicators, filter });
  const { t } = useTranslation();
  const closeBubble = pmrClassificationValue => {
    const actualState = handleActualState({
      calledFrom,
      filterEarlyWarnings,
      indicatorCall,
      filterIndicators,
      filter,
      commonProp: 'pmrId',
    });

    const foundItem = actualState.findIndex(x => x.PmrClassification === pmrClassificationValue);
    actualState[foundItem] = { PmrClassification: pmrClassificationValue, selected: false };

    if (calledFrom === CalledFrom.EARLY_WARNINGS) {
      dispatch(
        setFilterEarlyWarnings({
          ...filterEarlyWarnings,
          common: {
            ...filterEarlyWarnings.common,
            pmrId: actualState,
          },
        }),
      );
    } else {
      if (indicatorCall) {
        dispatch(
          setFilterIndicators({
            ...filterIndicators,
            common: {
              ...filterIndicators.common,
              pmrId: actualState,
            },
          }),
        );
      } else {
        dispatch(
          setFilter({
            ...filter,
            common: {
              ...filter.common,
              pmrId: actualState,
              itSearch: false,
            },
          }),
        );
      }
    }
  };
  return (
    <Fragment>
      <Bubble
        itsHidden={!bubbles.pmrId[PmrClassification.Green].selected}
        label={t('pmr_initials')}
        value={t('satisfactory')}
        closeBubble={() => closeBubble(PmrClassification.Green)}
      />
      <Bubble
        itsHidden={!bubbles.pmrId[PmrClassification.Yellow].selected}
        label={t('pmr_initials')}
        value={t('alert')}
        closeBubble={() => closeBubble(PmrClassification.Yellow)}
      />
      <Bubble
        itsHidden={!bubbles.pmrId[PmrClassification.Red].selected}
        label={t('pmr_initials')}
        value={t('problem')}
        closeBubble={() => closeBubble(PmrClassification.Red)}
      />
      <Bubble
        itsHidden={!bubbles.pmrId[PmrClassification.Na].selected}
        label={t('pmr_initials')}
        value={t('not_assigned')}
        closeBubble={() => closeBubble(PmrClassification.Na)}
      />
    </Fragment>
  );
};

export default PmrClassificationBubbles;
