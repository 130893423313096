import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { components } from 'react-select';
import { useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { searchUser } from '../../../api/userApi';
import { ReactComponent as SearchDropDown } from '../../../assets/icons/u_search.svg';
import { multiSelectStyle, multiSelectStyleClear } from './MultiSearchUser.Style';
import { RolesIdEnum } from '../../../models/RolesIdEnum';
import { TagFilterContext } from '../../../context/TagFilterContext';

type Props = {
  delegatedUsers?: any;
  returnUsersSelected: any;
  permissionToDelegate?: any;
  isMulti?: boolean;
  countryFiltered?: boolean;
  isDisabled?: boolean;
  filteredRoles?: number[];
  clearStyle?: boolean;
};

export const MultiSearchUser = ({
  delegatedUsers,
  returnUsersSelected,
  permissionToDelegate,
  isMulti = true,
  countryFiltered = true,
  isDisabled = false,
  filteredRoles,
  clearStyle,
}: Props) => {
  
  const { t } = useTranslation();
  const [inputValue, setinputValue] = useState<string>('');
  const user = useSelector<any, any>(state => state.user);
  
  // * Se llama al contexto para utilizar la información de lo seleccionado en el input.

  const { optionsBySelect } = useContext(TagFilterContext);
  let arrayUser:any = optionsBySelect.teamLeader


  const createOptionFormat = data => {
    if (filteredRoles !== null && filteredRoles !== undefined) {
      if (filteredRoles[0] === RolesIdEnum.Team_Leader) {
        return data
          .map(user => {
            return { id: user.id, label: `${user.name} ${user.lastName}`, value: `${user.name} ${user.lastName}` };
          });
      }
    }
    else {
      return data
        .filter(item => item.id && user.id !== item.id)
        .map(user => {
          return { id: user.id, label: `${user.name} ${user.lastName}`, value: `${user.name} ${user.lastName}` };
        });
    }
  };

  const handleInputChange = async (newValue: string) => {
    setinputValue(newValue);
  };
  
  const handleChange = usersSelected => {
    returnUsersSelected(usersSelected, permissionToDelegate);
  };

  const loadData = async () => {
    if (inputValue && inputValue.length > 1) {
      try {
        const data = await searchUser(inputValue, undefined, countryFiltered, filteredRoles);
        return Promise.resolve(createOptionFormat(data));
      } catch (err) {
        return Promise.resolve([]);
      }
    } else {
      return Promise.resolve([]);
    }
  };

  return (
    <AsyncSelect
      isDisabled={isDisabled}
      isMulti={isMulti}
      cacheOptions
      defaultValue={arrayUser || null}
      loadOptions={loadData}
      onInputChange={handleInputChange}
      styles={clearStyle ? multiSelectStyleClear : multiSelectStyle}
      components={
        clearStyle ? { DropdownIndicator: () => null, IndicatorSeparator: () => null } : { DropdownIndicator }
      }
      placeholder={t('person_name')}
    classNamePrefix="custom-placeholder"
      onChange={value => handleChange(value)}
      noOptionsMessage={() => <div>{t('no_results')}</div>}
      getOptionLabel={e => e.label}
      loadingMessage={() => <div>{t('loading')}</div>}
      isClearable
      value={arrayUser}
    />
  );
};
export const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <SearchDropDown />
    </components.DropdownIndicator>
  );
};
