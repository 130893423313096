import { AccordanceType } from '../../../../models/AccordanceType';

const translateCountries = (accordanceCountries, regionsState, t) => {
  let accordancesCountriesString = '';
  const countriesGroups = regionsState.map(region => region.countries);
  const countries: any = [].concat.apply([], countriesGroups);

  if (accordanceCountries && accordanceCountries.value && accordanceCountries.value.length > 0) {
    const countriesNames = countries
      .filter(country => accordanceCountries.value.includes(country.id))
      .map(country => country.name);
    accordancesCountriesString = countriesNames.sort().join(', ');
    return accordancesCountriesString;
  } else if (accordanceCountries && accordanceCountries.value && accordanceCountries.value.length === 0) {
    return t('all');
  } else {
    return '-';
  }
};

const translateDivisions = (accordanceDivisions, divisionsState, t) => {
  let accordancesDivisionsString = '';
  if (accordanceDivisions && accordanceDivisions.value && accordanceDivisions.value.length > 0) {
    const divisionsNames = divisionsState
      .filter(division => accordanceDivisions.value.includes(division.id))
      .map(division => division.code);
    accordancesDivisionsString = divisionsNames.sort().join(', ');
    return accordancesDivisionsString;
  } else if (accordanceDivisions && accordanceDivisions.value && accordanceDivisions.value.length === 0) {
    return t('all');
  } else {
    return '-';
  }
};

const accordanceTypeText = type => {
  return type === AccordanceType.Revision
    ? 'portfolio_review'
    : type === AccordanceType.Monitoring
    ? 'new_motitoring'
    : type === AccordanceType.InterGroup
    ? 'new_inter_group'
    : type === AccordanceType.PreparationForStart
    ? 'new_preparation_for_start'
    : type === AccordanceType.StartPreparation
    ? 'new_start_preparation'
    : type === AccordanceType.ThermalMediumMonitoring
    ? 'new_thermal_medium_monitoring'
    : 'new_preparation_for_closing'
};

const accordanceTypesText = (types, t) => {
  let accordancesTypesString = '';
  if (types && types.length > 0 && types.length < 3) {
    types.forEach((type, ind, arr) => {
      accordancesTypesString += `${t(accordanceTypeText(type))}${ind < arr.length - 1 ? ',' : ''}  `;
    });
  } else {
    accordancesTypesString = t('all');
  }
  return accordancesTypesString;
};

const translatedAccordancesType = (accordanceReport, t) => {
  return accordanceReport.parameters.accordanceType
    ? t(accordanceTypeText(accordanceReport.parameters.accordanceType))
    : accordanceTypesText(accordanceReport.parameters.accordanceTypes, t);
};

const sortedYearsString = (accordanceReport, t) => {
  let accordancesYearsString = '';
  const accordancesYears = accordanceReport.parameters.accordanceYears;
  if (accordancesYears && accordancesYears.length > 0) {
    accordancesYears.sort().forEach((year, ind, arr) => {
      accordancesYearsString += `${year}${ind < arr.length - 1 ? ',' : ''}  `;
    });
  } else if (accordancesYears && accordancesYears.length === 0) {
    accordancesYearsString = t('all');
  } else {
    accordancesYearsString = '-';
  }
  return accordancesYearsString;
};

const parseTranslatedFields = (response, t, divisionsState, regionsState) => {
  return response.map(r => {
    const accordanceCountries = r.parameters.filters.find(f => f.field === 'ResponsibleCountryId');
    const accordanceDivisions = r.parameters.filters.find(f => f.field === 'DivisionId');
    r.translatedCountries = translateCountries(accordanceCountries, regionsState, t);
    r.translatedDivisions = translateDivisions(accordanceDivisions, divisionsState, t);
    r.translatedAccordancesType = translatedAccordancesType(r, t);
    r.sortedYears = sortedYearsString(r, t);
    return r;
  });
};

export { parseTranslatedFields };
