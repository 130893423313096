import React from 'react';
import styles from './EditReview.module.scss';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SaveButton from '../../Buttons/SaveButton/SaveButton';
import UndoButton from '../../Buttons/UndoButton/UndoButton';
import { EditType } from './ScheduleWarning';
import { DateSelector } from '../../../Reviews/ReviewsDate/DateSelector/DateSelector';
import ProjectsList from './ProjectsList';

type Props = {
  handleSubmit: Function;
  handleCancel: Function;
  type: EditType;
};

const EditReview = ({ handleSubmit, handleCancel, type }: Props) => {
  const { t } = useTranslation();
  const currentReview = useSelector<any, any>(state => state.currentReview);

  return (
    <div className={styles.container}>
      <div className={styles.titles}>
        <h2 className={styles.title}>{t('create_agenda')}</h2>
        <h2 className={styles.titleLight}>{currentReview.title}</h2>
      </div>
      {type === EditType.Dates ? (
        <DateSelector review={currentReview} isEdit={true} fromEditAgenda={true} showProjects={() => {}} />
      ) : (
        <ProjectsList />
      )}
      <div className={styles.footer}>
        <SaveButton
          customText={t('assign_schedules')}
          handleClick={() => handleSubmit()}
          customStyle={{ marginRight: 20 }}
        />
        <UndoButton customText={t('cancel')} handleClick={() => handleCancel()} />
      </div>
    </div>
  );
};

export default EditReview;
