import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectProductTypes } from '../../../../models/ProjectProductTypes';
import { ExecutionOptions } from '../../../../models/ExecutedByOptions';
import '../BaseInformation.scss';

type Props = {
  projectProductType: any;
  baseInfo: any;
  igrGreater: boolean;
  execution?: number;
};

const UdrTaxonomyAndExecutedBy = ({ projectProductType, baseInfo, igrGreater, execution }: Props) => {
  const { t } = useTranslation();

  return projectProductType === ProjectProductTypes.CT ||
    (projectProductType === ProjectProductTypes.IG && !igrGreater) ? (
    <div className="objective column">
      <div className="ct-info-item">
        <span className="section-title">{t('udr')}</span>
        <span className="section-text">{baseInfo.udrCode}</span>
      </div>
      {projectProductType !== ProjectProductTypes.IG && !igrGreater && (
        <div className="ct-info-item">
          <span className="section-title">{t('taxonomy')}</span>
          <span className="section-text">{baseInfo.tcpTaxonomyName}</span>
        </div>
      )}
      <div className="ct-info-item">
        <span className="section-title">{t('executed_by')}</span>
        <span className="section-text">
          {execution !== undefined && execution !== null ? t(ExecutionOptions[execution].label) : '-'}
        </span>
      </div>
    </div>
  ) : null;
};

export default UdrTaxonomyAndExecutedBy;
