import SelectOption from '../../../../models/SelectOption';

const yearsListOptions = (from: number, to: number): SelectOption[] => {
  const years: SelectOption[] = [];
  for (let i = from; i <= to; i++) {
    years.push({ id: i, label: i.toString(), value: i.toString() });
  }
  return years;
};

export { yearsListOptions };
