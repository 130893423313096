import React, { Fragment } from 'react';
import useFilters from '../../../hooks/useFilters';
import { CalledFrom } from '../../../models/CalledFromEnum';
import { IFilterBubble } from '../../../models/FilterBubble';
import { Bubble } from '../BubbleElement/BubbleElement';
import { handleActualStateWithAnalytics, handleBubblesWithAnalytics } from '../UtilFunctions';

type Props = IFilterBubble;

export const CountryBubbles = (props: Props) => {
  const { indicatorCall, analyticCall, calledFrom } = props;
  const {
    filter,
    filterIndicators,
    filterAnalytics,
    dispatch,
    setFilter,
    setFilterIndicators,
    setFilterAnalytics,
    filterEarlyWarnings,
    setFilterEarlyWarnings,
  } = useFilters();

  const bubbles = handleBubblesWithAnalytics({
    calledFrom,
    indicatorCall,
    filterIndicators,
    analyticCall,
    filterAnalytics,
    filter,
    filterEarlyWarnings,
  });

  const closeBubble = countryId => {
    const actualState = handleActualStateWithAnalytics({
      calledFrom,
      indicatorCall,
      filterIndicators,
      analyticCall,
      filterEarlyWarnings,
      filterAnalytics,
      filter,
      commonProp: 'countryId',
    });

    const newState = actualState.filter(country => {
      return country.id !== countryId;
    });

    if (calledFrom === CalledFrom.EARLY_WARNINGS) {
      dispatch(
        setFilterEarlyWarnings({
          ...filterEarlyWarnings,
          common: {
            ...filterEarlyWarnings.common,
            countryId: newState,
          },
        }),
      );
    } else {
      if (indicatorCall) {
        dispatch(
          setFilterIndicators({
            ...filterIndicators,
            common: {
              ...filterIndicators.common,
              countryId: newState,
            },
          }),
        );
      } else if (analyticCall) {
        dispatch(
          setFilterAnalytics({
            ...filterAnalytics,
            countryId: newState,
          }),
        );
      } else {
        dispatch(
          setFilter({
            ...filter,
            common: {
              ...filter.common,
              countryId: newState,
              itSearch: false,
            },
          }),
        );
      }
    }
  };
  return (
    <Fragment>
      {bubbles.countryId.map(country => (
        <Bubble key={country.id} itsHidden={false} label={country.label} closeBubble={() => closeBubble(country.id)} />
      ))}
    </Fragment>
  );
};
export default CountryBubbles;
