export const multiSelectStyle = {
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ':hover': {
      color: 'black',
      backgroundColor: data.color,
    },
  }),
  placeholder: styles => ({
    ...styles,
    color: '#424D64',
  }),
  control: styles => ({
    ...styles,
    borderWidth: 1,
    borderColor: '#7A8AA9',
    cursor: 'pointer',
    backgroundColor: '#fff',
    borderRadius: '6px',
    boxShadow: 'none',
    '&:hover': { borderColor: '#7A8AA9' },
  }),

  multiValue: (styles, { data }) => {
    return {
      ...styles,
      borderWidth: 1,
      backgroundColor: '#F1F3FF',
      borderRadius: '6px',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled ? null : isSelected ? data.color : isFocused ? '#F1F3FF' : null,
      cursor: isDisabled ? 'not-allowed' : 'default',
      color: isSelected ? '#264377' : data.color,
    };
  },
};

export const multiSelectStyleClear = {
  ...multiSelectStyle,
  control: styles => ({
    ...styles,
    minHeight: '42px',
    borderWidth: 1,
    borderColor: '#7A8AA9',
    cursor: 'pointer',
    backgroundColor: '#ffffff',
    borderRadius: '6px',
    boxShadow: 'none',
    '&:hover': { borderColor: '#7A8AA9' },
  }),
  placeholder: styles => ({
    ...styles,
    color: '#424D64',
  }),
};
