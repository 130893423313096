import React, { useContext, useEffect } from 'react';
import './AccordanceTrello.scss';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import AccordanceButton from '../../../../Util/Buttons/AccordanceButton/AccordanceButton';
import { getYears } from '../../../../../api/accordanceApi';
import { getIdFromUrl } from '../../../../../services/numberUtil';
import { isMobile } from '../../../../../services/booleanUtil';
import { parseYearsList } from './util';
import NewAccordance from '../../../../Util/Modals/NewAccordance/NewAccordance';
import TrelloBoard from './TrelloBoard';
import { RolesIdEnum } from '../../../../../models/RolesIdEnum';
import { AccordanceType } from '../../../../../models/AccordanceType';
import CustomSelect from '../../../../Util/CustomSelect/CustomSelect';
import SelectOption from '../../../../../models/SelectOption';
import { SuccessModal } from '../../../../Util/Modals/SuccessModal/SuccessModal';
import bgImg from '../../../../../assets/icon-accordance-created.svg';
import {
  updateAccordanceFilters,
  updateCurrentProject,
  getProjectAccordances,
} from '../../../../../redux/actions/currentProject';
import { boardStyleMobile } from './Styles';
import ApproveAccordancesModal from '../ApproveAccordancesModal/ApproveAccordancesModal';
import { AccordanceState } from '../../../../../models/AccordanceState';
import Project from '../../../../../models/Project';
import User from '../../../../../models/User';
import AccordanceListView from './AccordanceListView/AccordanceListView';
import { NewAccordanceContext } from '../../../../Util/Modals/NewAccordance/context/NewAccordanceContext';
import AccordanceDetailModals from '../AccordanceDetailModals';
import useAccordanceDetailModalsState from '../../../../../hooks/useAccordanceDetailModalsState';

const setAccordancesList = (currentProject: any) => {
  return currentProject.accordancesData &&
    currentProject.accordancesData.lanes &&
    currentProject.accordancesData.lanes.find(i => i.key === AccordanceState.PENDING)
    ? currentProject.accordancesData.lanes
        .find(i => i.key === AccordanceState.PENDING)
        .cards.map(item => item.accordance)
        .filter(o => o.currentUserCanApprove && o.accordanceState === AccordanceState.PENDING)
    : null;
};

export const userAllowedToCreate = (
  canCreateInterGroupAccordances,
  canCreateMonitoringAccordances,
  canCreatePortfolioReviewAccordances,
) => {
  return canCreateInterGroupAccordances || canCreateMonitoringAccordances || canCreatePortfolioReviewAccordances;
};

export const getAccordanceTypeOptions = [
  { value: '', label: 'all', id: 0 },
  { value: AccordanceType.Revision.toString(), label: 'portfolio_review', id: AccordanceType.Revision },
  { value: AccordanceType.Monitoring.toString(), label: 'motitoring', id: AccordanceType.Monitoring },
  { value: AccordanceType.InterGroup.toString(), label: 'inter_group_revision', id: AccordanceType.InterGroup },
  {
    value: AccordanceType.PreparationForStart.toString(),
    label: 'new_preparation_for_start',
    id: AccordanceType.PreparationForStart,
  },
  {
    value: AccordanceType.StartPreparation.toString(),
    label: 'new_start_preparation',
    id: AccordanceType.StartPreparation,
  },
  {
    value: AccordanceType.ThermalMediumMonitoring.toString(),
    label: 'new_thermal_medium_monitoring',
    id: AccordanceType.ThermalMediumMonitoring,
  },
  {
    value: AccordanceType.PreparationForClosing.toString(),
    label: 'new_preparation_for_closing',
    id: AccordanceType.PreparationForClosing,
  },
];

const getDefaultYearOptions = [
  { value: '', label: 'all', id: 0 },
  { value: moment().year().toString(), label: 'current_year', id: 1 },
];

interface Success {
  status: boolean;
  title: string;
  subTitle: string;
}

const AccordanceTrello = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [yearOptions, setYearOptions] = React.useState<any>(getDefaultYearOptions);
  const [showNewAccordanceModal, setShowNewAccordanceModal] = React.useState(false);
  const [filtersYearApplied, setFilterYearApplied] = React.useState(getDefaultYearOptions[0]);
  const [filtersAccordanceTypeApplied, setFilterAccordanceTypeApplied] = React.useState(getAccordanceTypeOptions[0]);
  const [successIsOpen, setSuccessIsOpen] = React.useState<Success>({
    status: false,
    title: t('accordance_sent_for_approval'),
    subTitle: t('accordance_once_approved'),
  });
  const [kanbanView, setKanbanView] = React.useState<boolean>(true);
  const stateProps = useAccordanceDetailModalsState();
  const { showModal } = stateProps;
  const { state } = useContext(NewAccordanceContext);
  useEffect(() => {
    if (state.relatedAccordance) {
      showModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const dispatch = useDispatch();
  const currentProject = useSelector<any, Project>(state => state.currentProject);
  const user = useSelector<any, User>(state => state.user);

  const getAccordanceYears = async (id: number) => {
    try {
      const response = await getYears(id);
      const parsedYearsList = parseYearsList(response);
      setYearOptions([...yearOptions, ...parsedYearsList]);
    } catch (error) {}
  };

  React.useEffect(() => {
    getAccordanceYears(getIdFromUrl(history.location.search));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    dispatch(
      getProjectAccordances(
        getIdFromUrl(history.location.search),
        filtersYearApplied.value,
        filtersAccordanceTypeApplied.value,
      ),
    );
    dispatch(updateAccordanceFilters(filtersYearApplied.value, filtersAccordanceTypeApplied.value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersYearApplied, filtersAccordanceTypeApplied]);

  const selectHandler = (eventTargetValue: SelectOption) => {
    setFilterYearApplied(eventTargetValue);
  };

  const selectAccordanceTypeHandler = (eventTargetValue: SelectOption) => {
    setFilterAccordanceTypeApplied(getAccordanceTypeOptions[eventTargetValue.id]);
  };

  const refreshAccordancesData = () => {
    dispatch(
      getProjectAccordances(
        getIdFromUrl(history.location.search),
        filtersYearApplied.value,
        filtersAccordanceTypeApplied.value,
      ),
    );
    getAccordanceYears(getIdFromUrl(history.location.search));
  };

  const closeNewAccordanceModal = (data: any) => {
    setShowNewAccordanceModal(false);
    if (data && data.refreshData) {
      refreshAccordancesData();
      if (user.role.id !== RolesIdEnum.Chief_of_Operations) {
        setSuccessIsOpen({
          status: !data.isDraft,
          title: data.title || t('accordance_sent_for_approval'),
          subTitle: data.subTitle || t('accordance_once_approved'),
        });
      }
    }
  };

  return (
    <>
      <div className="accordance-trello-container">
        <div className="header">
          <div className="containerButtons">
            <AccordanceButton
              disabled={
                !userAllowedToCreate(
                  currentProject.canCreateInterGroupAccordances,
                  currentProject.canCreateMonitoringAccordances,
                  currentProject.canCreatePortfolioReviewAccordances,
                )
              }
              handleClick={() => setShowNewAccordanceModal(true)}
            />

            <ApproveAccordancesModal data={setAccordancesList(currentProject)} onSubmit={refreshAccordancesData} />
          </div>

          <div className="custom-select-container">
            <span className="select-label">{t('type_of_accordance')}</span>
            <div className="custom-selet-type-project">
              <CustomSelect
                options={getAccordanceTypeOptions}
                handleSelected={selectAccordanceTypeHandler}
                selectedOption={filtersAccordanceTypeApplied}
                style={{ width: 250, textTransform: 'capitalize' }}
                type={'small-dark'}
              />
            </div>
            <span className="select-label">{t('year')}</span>
            <CustomSelect
              options={yearOptions}
              handleSelected={selectHandler}
              selectedOption={filtersYearApplied}
              style={{ width: 120, textTransform: 'capitalize' }}
              type={'small-dark'}
            />
          </div>
          <div className='containerbutton-kanban'>
            <button className='button-kanban' onClick={() => setKanbanView(!kanbanView)}>
              <div className='list'> </div>
              <span className="text"> {t('show_dashboard')} </span>
            </button>

          </div>
        </div>
        {kanbanView ? (
          <TrelloBoard
            boardStyle={isMobile() ? boardStyleMobile : currentProject.boardStyle}
            data={currentProject.accordancesData}
            updateData={newData =>
              dispatch(updateCurrentProject({ ...currentProject, ...{ accordancesData: { ...newData } } }))
            }
          />
        ) : (
          <AccordanceListView />
        )}
      </div>
      <NewAccordance
        isOpen={showNewAccordanceModal}
        closeModal={(refreshData: boolean) => closeNewAccordanceModal(refreshData)}
        canCreateInterGroupAccordances={currentProject.canCreateInterGroupAccordances}
        canCreateMonitoringAccordances={currentProject.canCreateMonitoringAccordances}
        canCreatePortfolioReviewAccordances={currentProject.canCreatePortfolioReviewAccordances}
      />
      <SuccessModal
        isOpen={successIsOpen.status}
        closeModal={() => setSuccessIsOpen({ ...successIsOpen, status: false })}
        title={successIsOpen.title}
        subTitle={successIsOpen.subTitle}
        backgroundImg={bgImg}
      />
      {state.relatedAccordance ? (
        <AccordanceDetailModals accordance={state.relatedAccordance} stateProps={stateProps} />
      ) : null}
    </>
  );
};

export default AccordanceTrello;
