import React, { useReducer, createContext } from 'react';

type InitialStateType = {
  listToUpdate: any[];
  languages: any[];
  indicatorToUpdate: any;
};

const initialState = { listToUpdate: [], languages: [], indicatorToUpdate: null };

export const TranslationsContext = createContext<{ state: InitialStateType; dispatch: React.Dispatch<any> }>({
  state: initialState,
  dispatch: () => null,
});

const addIfNotExists = (list: string[] = [], value: string) => {
  if (list.indexOf(value) > -1) {
    return list;
  } else {
    return [...list, value];
  }
};

const updateTranslations = (currentTranslations: any, newTranslation: any) => {
  if (currentTranslations.find(t => t.id === newTranslation.id)) {
    return currentTranslations.map(ct => {
      if (ct.id === newTranslation.id) {
        newTranslation.updatedRows = addIfNotExists(ct.updatedRows, newTranslation.sectionRow);
        return newTranslation;
      } else {
        return ct;
      }
    });
  } else {
    return [...currentTranslations, { ...newTranslation, updatedRows: [newTranslation.sectionRow] }];
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'ADD_VALUE':
      if (state.listToUpdate.find(i => i.id === action.payload.id)) {
        return {
          ...state,
          listToUpdate: state.listToUpdate.map(o => {
            if (o.id === action.payload.id) {
              o.value = action.payload.value;
            }
            return o;
          }),
        };
      } else {
        return {
          ...state,
          listToUpdate: [...state.listToUpdate, action.payload],
        };
      }

    case 'RESET_LIST':
      return { ...state, listToUpdate: [] };

    case 'ADD_INDICATOR':
      if (state.indicatorToUpdate && state.indicatorToUpdate.id === action.payload.id) {
        return {
          ...state,
          indicatorToUpdate: {
            ...state.indicatorToUpdate,
            translations: updateTranslations(state.indicatorToUpdate.translations, action.payload.translation),
          },
        };
      } else {
        return {
          ...state,
          indicatorToUpdate: {
            id: action.payload.id,
            code: action.payload.code,
            translations: [{ ...action.payload.translation, updatedRows: [action.payload.translation.sectionRow] }],
          },
        };
      }

    case 'RESET_INDICATOR':
      return { ...state, indicatorToUpdate: null };

    case 'SET_LANGUAGES':
      return { ...state, languages: action.payload };
    default:
      return state;
  }
};

export const TranslationsContextProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <TranslationsContext.Provider value={{ state, dispatch }}>{props.children}</TranslationsContext.Provider>;
};
