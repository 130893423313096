import Region from '../models/Region';
import { FilterTypesEnum } from '../models/FitlerTypesEnum';
import Country from '../models/Country';
import { setReturnedValue, getAgeFilterReturnedValue } from './filterSharedUtil';
import { getExcludeRegionalFilter } from './filterPortfolioAndProjectUtil';

export const setFilterReportRegion = (region: Region[]) => {
  return region !== null ? Array.from(region, x => x.id) : [];
};

export const anyIsUndefined = arrayToCheck => {
  const returned = arrayToCheck.find(item => item.value === undefined);
  return returned === undefined ? true : false;
};

export const setFilterReportParams = (returnedParams, stateFilterValues, newFilterObject) => {
  returnedParams = getFilterReportDivision(stateFilterValues.haveDivisionFilter, newFilterObject, returnedParams);
  returnedParams = getFilterReportCountry(stateFilterValues.haveCountriesFilter, newFilterObject, returnedParams);
  returnedParams = getFilterReportHaveLastDisbursement(
    stateFilterValues.haveLastDisbursement,
    newFilterObject,
    returnedParams,
  );

  returnedParams = getFilterReportHaveAgeOfProject(stateFilterValues.haveAgeOfProject, newFilterObject, returnedParams);
  returnedParams = getFilterReportHavePendingDisbursement(
    stateFilterValues.havePendingDisbursement,
    newFilterObject,
    returnedParams,
  );

  returnedParams = getFilterReportHavePmrClassification(
    stateFilterValues.havePmrClassification,
    newFilterObject,
    returnedParams,
  );

  if (stateFilterValues.haveTaxonomy || stateFilterValues.haveTaxonomy === 0) {
    const taxonomyFilter = {
      filterType: FilterTypesEnum.NumberIn,
      field: 'TCPTaxonomyId',
      value: [stateFilterValues.haveTaxonomy],
    };
    const returnedFilters = { Filters: [...returnedParams.Filters, taxonomyFilter] };
    returnedParams = { ...returnedParams, Filters: returnedFilters.Filters };
  }

  if (stateFilterValues.haveExecution || stateFilterValues.haveExecution === 0) {
    const execFilter = {
      filterType: FilterTypesEnum.NumberIn,
      field: 'ProjectExecutorEntity',
      value: [stateFilterValues.haveExecution],
    };
    const returnedFilters = { Filters: [...returnedParams.Filters, execFilter] };
    returnedParams = { ...returnedParams, Filters: returnedFilters.Filters };
  }

  if (stateFilterValues.haveExcludeRegionales) {
    const excludeRegionalFilter = getExcludeRegionalFilter();
    const returnedFilters = { Filters: [...returnedParams.Filters, excludeRegionalFilter] };
    returnedParams = { ...returnedParams, Filters: returnedFilters.Filters };
  }

  return returnedParams;
};

const getFilterReportDivision = (haveDivisionFilter, newFilterObject, returnedParams) => {
  let divisionFilter;

  if (haveDivisionFilter) {
    const divisionsId = newFilterObject.common.divisionId.map(division => {
      return division.id;
    });
    divisionFilter = {
      filterType: FilterTypesEnum.NumberIn,
      field: 'DivisionId',
      value: divisionsId,
    };
    const returnedFilters = { Filters: [...returnedParams.Filters, divisionFilter] };
    returnedParams = { ...returnedParams, Filters: returnedFilters.Filters };
  }
  return returnedParams;
};

const getFilterReportCountry = (haveCountriesFilter, newFilterObject, returnedParams) => {
  let countriesFilter;
  if (haveCountriesFilter) {
    const countries: Country[] = newFilterObject.common.countryId;
    const actualState = Array.from(countries, x => x.id);
    countriesFilter = {
      filterType: FilterTypesEnum.NumberIn,
      field: 'ResponsibleCountryId',
      value: actualState,
    };
    const returnedFilters = { Filters: [...returnedParams.Filters, countriesFilter] };
    returnedParams = { ...returnedParams, Filters: returnedFilters.Filters };
  }

  return returnedParams;
};

const getFilterReportHaveLastDisbursement = (haveLastDisbursement, newFilterObject, returnedParams) => {
  if (
    haveLastDisbursement &&
    anyIsUndefined([
      { value: newFilterObject.common.timeWithoutDisbursementFrom },
      { value: newFilterObject.common.timeWithoutDisbursementTo },
    ])
  ) {
    const actualStateFrom = newFilterObject.common.timeWithoutDisbursementFrom;
    const actualStateTo = newFilterObject.common.timeWithoutDisbursementTo;
    const field = 'LastDisbursementInDays';
    const lastDisbursement = setReturnedValue(actualStateFrom, actualStateTo, field);

    const returnedFilters = Array.isArray(lastDisbursement)
      ? {
          Filters: [...returnedParams.Filters, lastDisbursement[0], lastDisbursement[1]],
        }
      : { Filters: [...returnedParams.Filters, lastDisbursement] };
    returnedParams = { ...returnedParams, Filters: returnedFilters.Filters };
  }

  return returnedParams;
};

const getFilterReportHaveAgeOfProject = (haveAgeOfProject, newFilterObject, returnedParams) => {
  if (
    haveAgeOfProject &&
    anyIsUndefined([
      { value: newFilterObject.common.ageProjectMonths },
      { value: newFilterObject.common.ageProjectFrom },
      { value: newFilterObject.common.ageProjectTo },
    ])
  ) {
    const actualStateMonths = newFilterObject.common.ageProjectMonths;
    const actualStateFrom = newFilterObject.common.ageProjectFrom;
    const actualStateTo = newFilterObject.common.ageProjectTo;
    const ageOfProject = getAgeFilterReturnedValue(actualStateFrom, actualStateTo, actualStateMonths);

    const returnedFilters = Array.isArray(ageOfProject)
      ? {
          Filters: [...returnedParams.Filters, ageOfProject[0], ageOfProject[1]],
        }
      : { Filters: [...returnedParams.Filters, ageOfProject] };
    returnedParams = { ...returnedParams, Filters: returnedFilters.Filters };
  }
  return returnedParams;
};

const getFilterReportHavePendingDisbursement = (havePendingDisbursement, newFilterObject, returnedParams) => {
  if (
    havePendingDisbursement &&
    anyIsUndefined([
      { value: newFilterObject.common.disbursementProjectionFrom },
      { value: newFilterObject.common.disbursementProjectionTo },
    ])
  ) {
    const actualStateFrom = newFilterObject.common.disbursementProjectionFrom;
    const actualStateTo = newFilterObject.common.disbursementProjectionTo;
    const isAnnualField = newFilterObject.common.isAnnual;

    const field = isAnnualField === 'annual' ? 'PendingDisbursementAnnualAmount' : 'DisbursementProjection';
    const pendingDisbursement = setReturnedValue(actualStateFrom, actualStateTo, field);

    const returnedFilters = Array.isArray(pendingDisbursement)
      ? {
          Filters: [...returnedParams.Filters, pendingDisbursement[0], pendingDisbursement[1]],
        }
      : { Filters: [...returnedParams.Filters, pendingDisbursement] };
    returnedParams = { ...returnedParams, Filters: returnedFilters.Filters };
  }

  return returnedParams;
};

const getFilterReportHavePmrClassification = (havePmrClassification, newFilterObject, returnedParams) => {
  let pmrFilter;
  if (havePmrClassification && havePmrClassification.length !== 0) {
    const pmrs = newFilterObject.common.pmrId;
    const actualState: any[] = pmrs.filter(pmr => pmr.selected === true);
    const actualArray = Array.from(actualState, x => x.PmrClassification);
    pmrFilter = {
      filterType: FilterTypesEnum.NumberIn,
      field: 'PmrClassification',
      value: actualArray,
    };

    const returnedFilters = { Filters: [...returnedParams.Filters, pmrFilter] };
    returnedParams = { ...returnedParams, Filters: returnedFilters.Filters };
  }

  return returnedParams;
};

export const getPmrClassificationFilterReport = reportFilter => {
  const pmrs = reportFilter.common.pmrId;
  const actualState = pmrs.filter(pmr => pmr.selected === true);
  const actualArray = Array.from(actualState, (x: any) => x.PmrClassification);
  return {
    filterType: FilterTypesEnum.NumberIn,
    field: 'PmrClassification',
    value: actualArray,
  };
};

export const getAgeOfProjectFilterReport = reportFilter => {
  const actualStateMonths = reportFilter.common.ageProjectMonths;
  const actualStateFrom = reportFilter.ageProjectFrom;
  const actualStateTo = reportFilter.ageProjectTo;

  return getAgeFilterReturnedValue(actualStateFrom, actualStateTo, actualStateMonths);
};

export const getLastDisbursementInDaysFilterReport = reportFilter => {
  const actualStateFrom = reportFilter.common.timeWithoutDisbursementFrom;
  const actualStateTo = reportFilter.common.timeWithoutDisbursementTo;
  const field = 'LastDisbursementInDays';
  return setReturnedValue(actualStateFrom, actualStateTo, field);
};

export const getDivisionFromFilterReport = reportFilter => {
  const actualState = reportFilter.common.divisionId;
  const divisionsId = actualState.map(division => {
    return division.id;
  });
  return {
    filterType: FilterTypesEnum.NumberIn,
    field: 'DivisionId',
    value: divisionsId,
  };
};

export const getPendingDisbursementFilterReport = reportFilter => {
  const actualStateFrom = reportFilter.common.disbursementProjectionFrom;
  const actualStateTo = reportFilter.common.disbursementProjectionTo;
  const isAnnualField = reportFilter.common.isAnnual;

  const field = isAnnualField === 'annual' ? 'PendingDisbursementAnnualAmount' : 'PendingDisbursementAmount';
  return setReturnedValue(actualStateFrom, actualStateTo, field);
};

export const getCountriesFilterReport = reportFilter => {
  const countries = reportFilter.common.countryId;
  const actualState = Array.from(countries, (x: any) => x.id);
  return {
    filterType: FilterTypesEnum.NumberIn,
    field: 'ResponsibleCountryId',
    value: actualState,
  };
};
