import React from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { DropdownIndicator, multiSelectStyle } from '../../Filter/FilterComponent.Styles';

type Props = {
  idSelect1: any,
  idSelect2: any,
  labelSelect1: any;
  labelSelect2: any;
  valueSelect1: any;
  valueSelect2: any;
  optionsSelect1: any;
  optionsSelect2: any;
  setOptionSelect1: any;
  setOptionSelect2: any;
  widthSelect1: any;
  widthSelect2: any;
  isDisabledSelect1?: any;
  isDisabledSelect2?: any;
  noOptionsMessageSelect1?: any;
  noOptionsMessageSelect2?: any;
}

const FunctionDelegationsSelects = ({
  idSelect1,
  idSelect2,
  labelSelect1,
  labelSelect2,
  valueSelect1,
  valueSelect2,
  optionsSelect1,
  optionsSelect2,
  setOptionSelect1,
  setOptionSelect2,
  widthSelect1,
  widthSelect2,
  isDisabledSelect1,
  isDisabledSelect2,
  noOptionsMessageSelect1,
  noOptionsMessageSelect2
}: Props) => {

  const { t } = useTranslation('contentManager');

  return (
    <><div className="label-select-container" style={{ width: widthSelect1 }}>
      <p className="label">{t(labelSelect1)}</p>
      <Select
        id={idSelect1}
        components={{ DropdownIndicator }}
        value={valueSelect1}
        options={optionsSelect1}
        className="basic-multi-select"
        classNamePrefix="All"
        placeholder={t('contents_select_placeholder_search')}
        onChange={value => setOptionSelect1(value)}
        styles={multiSelectStyle}
        isDisabled={isDisabledSelect1}
        noOptionsMessage={noOptionsMessageSelect1}
      />
    </div>
      <div className="label-select-container" style={{ width: widthSelect2, marginLeft: '60px' }}>
        <p className="label">{t(labelSelect2)}</p>
        <Select
          id={idSelect2}
          components={{ DropdownIndicator }}
          value={valueSelect2}
          options={optionsSelect2}
          className="basic-multi-select"
          classNamePrefix="All"
          placeholder={t('contents_select_placeholder_search')}
          onChange={value => setOptionSelect2(value)}
          styles={multiSelectStyle}
          isDisabled={isDisabledSelect2}
          noOptionsMessage={noOptionsMessageSelect2}
        />
      </div></>
  )
}

export default FunctionDelegationsSelects;
