import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Project from '../../../models/Project';
import { getFilteredSimpleIndicators } from '../../PortfolioDetail/PortfolioProjectsDetailContent/PortfolioProjectsDetailContent';
import ProjectsInPreparationTable from '../ProjectsInPreparationTable/ProjectsInPreparationTable';
import { getDynamicTableFormat } from '../../../services/util';
import { ProjectCounter } from '../ProjectCounter/ProjectCounter';
import IndicatorDetailFilter from '../../../models/IndicatorDetailFilter';

type Props = {
  currentIndicator: any;
  indicatorKey: string;
};

const ProjectsInPreparation = (props: Props) => {
  const dispatch = useDispatch();
  const { indicatorKey, currentIndicator } = props;
  const indicatorProjects = useSelector<any, Project[]>(state => state.indicatorProjects);
  const { projectTypeFilterOnDetail } = useSelector<any, IndicatorDetailFilter>(state => state.indicatorDetailFilter);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getFilteredSimpleIndicators(dispatch, currentIndicator.id, currentIndicator.indicatorKey, setLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndicator.id, dispatch, projectTypeFilterOnDetail]);

  return (
    <div className="portfolio-projects-detail-content-container">
      <div className={indicatorProjects.length === 0 ? 'wrapper' : 'wrapper adjust-height'}>
        <ProjectsInPreparationTable
          projects={indicatorProjects}
          tableInfo={getDynamicTableFormat(indicatorKey)}
          loading={loading}
        />
        <ProjectCounter arrayToCount={indicatorProjects} countProjects={true} indicatorKey={indicatorKey} />
      </div>
    </div>
  );
};

export default ProjectsInPreparation;
