import React from 'react';
import './CalendarDetailToolbar.scss';
import moment from 'moment';
import { MonthCalendarTooltip } from '../../../Util/Tooltip/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectsCalendarIndicator } from '../../../../redux/actions/indicatorProjects';

const CalendarDetailToolbar = (toolbar: any) => {
  const dispatch = useDispatch();
  const { id } = useSelector<any, any>(state => state.currentIndicator);

  const goToBack = () => {
    toolbar.date.setMonth(toolbar.date.getMonth() - 1, 1);
    toolbar.onNavigate('prev');
    dispatch(getProjectsCalendarIndicator(id, toolbar.date));
  };

  const goToNext = () => {
    toolbar.date.setMonth(toolbar.date.getMonth() + 1, 1);
    toolbar.onNavigate('next');
    dispatch(getProjectsCalendarIndicator(id, toolbar.date));
  };

  return (
    <div className="calendar-detail-toolbar-container">
      <MonthCalendarTooltip
        title={moment(toolbar.date).add(-1, 'M').endOf('month').format('MMMM')}
        placement="bottom-start"
        enterDelay={500}
        leaveDelay={200}
        arrow
      >
        <button className="nav-button back" onClick={goToBack}></button>
      </MonthCalendarTooltip>
      <div className="title">
        <h3>
          <span> {moment(toolbar.date).format('MMMM')}</span> {moment(toolbar.date).format('YYYY')}
        </h3>
      </div>
      <MonthCalendarTooltip
        title={moment(toolbar.date).add(1, 'M').endOf('month').format('MMMM')}
        placement="bottom-end"
        enterDelay={500}
        leaveDelay={200}
        arrow
      >
        <button className="nav-button next" onClick={goToNext}></button>
      </MonthCalendarTooltip>
    </div>
  );
};

export default CalendarDetailToolbar;
