import React from 'react';
import { styles } from '../Styles';
import { disableEscKey } from '../Util';
import CloseCrossButton from '../../Buttons/CloseCrossButton/CloseCrossButton';
import { useTranslation } from 'react-i18next';
import BaseModal from '../BaseModal';
import {ReactComponent as IconExclamationCircle } from '../../../../assets/icons/u_exclamation-circle.svg'
import './CurrentAgreementsModal.scss';

type Props = {
  isOpen: boolean;
  closeModal: any;
  onConfirm: () => void;
};

export const ApproveAgreementsModal = (props: Props) => {
  const { isOpen, closeModal, onConfirm } = props;
  const { t } = useTranslation();

  return (
    <BaseModal
      isOpen={isOpen}
      onRequestClose={e => disableEscKey(e, closeModal)}
      style={styles}
      className="modal-content approved"
    >
      <div className="modal-container">
        <div className="close-cross-container">
          <CloseCrossButton handleClick={closeModal} />
        </div>
        <div className="content">
            <IconExclamationCircle/>
          <h3>Enviar acuerdos para aprobación </h3>
          <p className='ci-type-text-4'>Los acuerdos seleccionados serán enviados para su revisión y aprobación.</p>
        </div>
        <div className="button">
          <button className='btn btn-primary-outlined' onClick={closeModal}>{t('cancel')}</button>
          <button className='btn btn-primary' onClick={onConfirm}>{t('confirm')}</button>
        </div>
      </div>
    </BaseModal>
  );
};
