import { makeStyles, Tab, Tabs } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Filter from '../../../../../models/Filter';
import { ProjectProductTypeFilters } from '../../../../../models/ProjectProductTypeFilters';
import { tabStyles } from '../../../../Projects/ProjectsAll/ProjectsAll.Style';

export enum TabFilter {
  TECHNICAL_COOPERATIONS,
  SUPERVISION_PLANS,
}

type Props = {
  filterBy: TabFilter;
  handleFilterApplied: Function;
};

const isDisabled = (projectTypeFilters: number[], tabFilter: TabFilter): boolean => {
  if (tabFilter === TabFilter.TECHNICAL_COOPERATIONS) {
    return !(
      projectTypeFilters.includes(ProjectProductTypeFilters.CT) ||
      projectTypeFilters.includes(ProjectProductTypeFilters.IG_lower_3)
    );
  } else if (tabFilter === TabFilter.SUPERVISION_PLANS) {
    return !(
      projectTypeFilters.includes(ProjectProductTypeFilters.Loan) ||
      projectTypeFilters.includes(ProjectProductTypeFilters.IG_greater_3)
    );
  }

  return false;
};

export const TabsFilter = ({ filterBy, handleFilterApplied }: Props) => {
  const { projectTypeFilters } = useSelector<any, Filter>(state => state.filterIndicators.common);

  const classes = makeStyles(tabStyles)();
  const tabClasses = { root: classes.root, selected: classes.selected };
  const { t } = useTranslation();

  const handleSetMenuValue = (tabValue: any) => {
    handleFilterApplied(tabValue);
  };
  return (
    <Tabs
      value={filterBy}
      classes={{ indicator: classes.indicatorColor }}
      variant="standard"
      aria-label="full width tabs example"
    >
      <Tab
        key={TabFilter.TECHNICAL_COOPERATIONS}
        value={TabFilter.TECHNICAL_COOPERATIONS}
        classes={tabClasses}
        label={t('technical_cooperation')}
        onClick={() => handleSetMenuValue(TabFilter.TECHNICAL_COOPERATIONS)}
        disabled={isDisabled(projectTypeFilters, TabFilter.TECHNICAL_COOPERATIONS)}
      />
      <Tab
        key={TabFilter.SUPERVISION_PLANS}
        value={TabFilter.SUPERVISION_PLANS}
        classes={tabClasses}
        label={t('supervision_plans')}
        onClick={() => handleSetMenuValue(TabFilter.SUPERVISION_PLANS)}
        disabled={isDisabled(projectTypeFilters, TabFilter.SUPERVISION_PLANS)}
      />
    </Tabs>
  );
};
