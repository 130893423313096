import React from 'react';
import { useTranslation } from 'react-i18next';
import './EmptySearchResult.scss';
import { isIE11 } from '../../../services/booleanUtil';
import { ReactComponent as NoResult } from '../../../assets/no-results.svg';

type Props = {
  customTitle?: string;
};
export const EmptySearchResult = ({ customTitle }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="empty-search" style={isIE11() ? { height: 180 } : {}}>
      <NoResult />
      <span className="title">{customTitle ? t(customTitle) : t('no_results_for_your_search')}</span>
    </div>
  );
};
