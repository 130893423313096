import React, { useState, useEffect } from 'react';
import './AlertSection.module.scss';
import { useTranslation } from 'react-i18next';
import { getSectionReports, deleteReport, getReportFile } from '../../../../api/reportApi';
import { TableContainer, Table, TableBody } from '@material-ui/core';
import { TableCellHeaderReport } from '../ReportsTableShared.Styles';
import { RowTableReportsSection } from '../RowTableReportsSection/RowTableReportsSection';
import { StyledTableHeader, StyledTableRowHeader } from '../../../PortfolioDetail/PorfolioTableShared.Styles';
import { sortDates, sortAlphabetically } from '../../../../services/sortsUtil';
import { capitalizeFirstLetter } from '../../../../services/stringUtil';
import { ReportsSectionsEmptyState } from '../ReportsSectionsEmptyState/ReportsSectionsEmptyState';
import { GetReportsFileTypeEnum, GetSectionReportEnum, ReportStatusEnum } from '../../../../models/ReportEnums';
import { createReportNameFile, getHeaderTable, parseData } from '../PortfolioSection/Util';
import { SectionPreloader } from '../SectionPreloader/SectionPreloader';
import { ReportType } from '../../../../models/ReportType';
import { EarlyWarningTypeLabel } from '../../../../models/EarlyWarnings';
import { useSelector } from 'react-redux';
import Region from '../../../../models/Region';
import Division from '../../../../models/Division';

const pollingMiliseconds = 20000;

export const AlertSection = () => {
  const { t } = useTranslation();
  const [alertReportData, setAlertReportData] = useState<any[]>([]);
  const [sortAsc, setSortAsc] = useState(true);
  const [loading, setLoading] = useState(true);
  const divisionsState = useSelector<any, Division[]>(state => state.divisions);
  const regionsState = useSelector<any, Region[]>(state => state.regions);

  const earlyWarningsTransaltionKey = {
    [EarlyWarningTypeLabel.pendingSignature]: 'project_pending_signature_singular',
    [EarlyWarningTypeLabel.expired]: 'projects_with_expired_disbursement_deadline_singular',
    [EarlyWarningTypeLabel.esgSafeguard]: 'project_with_unsatisfactory_performance_in_safeguards_singular',
    [EarlyWarningTypeLabel.eligibleUnDisbursement]: 'eligible_project_pending_first_disbursement_singular',
    [EarlyWarningTypeLabel.cancelled]: 'project_with_partial_cancellations_singular',
    [EarlyWarningTypeLabel.pmr]: 'project_with_recent_alert_or_problem_classifications_singular',
  };
  const renderAllAlerts = (report, t) => {
    const returnedString: string[] = [];

    if (handleDefensiveOperativeFilters(report)) {
      report.parameters.operativeAlertsFilter.forEach(element => {
        returnedString.push(`${capitalizeFirstLetter(t(earlyWarningsTransaltionKey[element]))} `);
      });
    }
    return returnedString.join();
  };

  const renderAllFilters = report => {
    const returnedString: string[] = [];
    report.parameters.filters.forEach(element => {
      if (element.field.startsWith('translated') && element.value.trim().lenght !== 0) {
        returnedString.push(`${element.value} `);
      }
    });
    return returnedString.filter(el => {
      return el != null;
    });
  };

  const getProjectData = () => {
    getSectionReports(GetSectionReportEnum.Alert_Section).then((response: any) => {
      const translatedCommonFilters = parseData(response, divisionsState, regionsState, t, true);
      const addPropsToRoot = translatedCommonFilters.map(element => {
        return {
          ...element,
          selectedTab: element.parameters.selectedTab,
          transalatedEarlyWarnings: renderAllAlerts(element, t),
          translatedCommonFilters: renderAllFilters(element),
          parameters: {
            ...element.parameters,
          },
        };
      });
      setAlertReportData(addPropsToRoot);
      setLoading(false);
    });
  };

  useEffect(() => {
    getProjectData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!alertReportData.some(item => item.reportStatus === ReportStatusEnum.Generating)) {
        return () => clearInterval(interval);
      }
      getProjectData();
      return true;
    }, pollingMiliseconds);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertReportData]);

  const sortColumn = (reports, keyToSort) => {
    sortReports(reports, keyToSort, sortAsc);
    setSortAsc(!sortAsc);
  };

  const deleteProjectReport = id => {
    deleteReport(id).then(response => {
      if (response === 200) {
        const reports = alertReportData.filter(report => report.id !== id);
        setAlertReportData(reports);
      }
    });
  };

  const handleDownloadReport = report => {
    const completeFileName = createReportNameFile(
      GetSectionReportEnum.Alert_Section,
      GetReportsFileTypeEnum.Excel,
      report,
      t,
    );
    getReportFile(report.id, GetReportsFileTypeEnum.Excel, completeFileName);
  };

  return !loading ? (
    <div className="project-section-report-table-container">
      {alertReportData.length !== 0 ? (
        <TableContainer>
          <Table stickyHeader style={{ tableLayout: 'auto' }} size="small">
            <StyledTableHeader>
              <StyledTableRowHeader>
                {getHeaderTable(ReportType.Alert).map(header => (
                  <TableCellHeaderReport
                    align="left"
                    key={header.id}
                    onClick={() => sortColumn(alertReportData, header.key)}
                  >
                    {t(header.label)}
                    <i className="sort-icon"></i>
                  </TableCellHeaderReport>
                ))}
                <TableCellHeaderReport align="right" />
              </StyledTableRowHeader>
            </StyledTableHeader>
            <TableBody>
              {alertReportData.map((report, index) => (
                <RowTableReportsSection
                  key={report.id}
                  report={report}
                  index={index}
                  deleteReport={deleteProjectReport}
                  downloadReport={handleDownloadReport}
                  reportType={ReportType.Alert}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <ReportsSectionsEmptyState message={'you_can_create_them_from_the_early_warning_section'} />
      )}
    </div>
  ) : (
    <SectionPreloader />
  );
};

const sortReports = (reports, keyToSort, sortAsc) => {
  switch (keyToSort) {
    case 'dateGenerated':
      reports.sort(sortDates(keyToSort, sortAsc ? 'asc' : 'desc'));
      break;
    case 'selectedTab':
    case 'transalatedEarlyWarnings':
    case 'translatedCommonFilters':
      reports.sort(sortAlphabetically(keyToSort, sortAsc ? 'asc' : 'desc'));
      break;
  }
};

const handleDefensiveOperativeFilters = report =>
  report && report.parameters && report.parameters.operativeAlertsFilter;
