import React from 'react';
import { Box, MenuItem, Select } from '@material-ui/core';
import './PaginationTable.scss';
import { useTranslation } from 'react-i18next';

const PaginationTable = ({ fnSetRows, rows, numProyects }: any) => {
    const rowsPerPageOptions = [10, 20, 30];
    const { t } = useTranslation();
    const handleChangeRowsPerPage = (event) => {
        fnSetRows(event.target.value);
    };

    return (

        <Box className='pagination'>
            <Box className='select-page'>
                <div>{t('show')}:</div>
                <Select value={rows} onChange={handleChangeRowsPerPage}>
                    {rowsPerPageOptions.map((option) => (
                        <MenuItem className='list' key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
                <div className='ci-type-text-5'>{t('flies')} {numProyects}</div>
            </Box>
        </Box>
    );
};

export default PaginationTable;
