import React from 'react';
import './SuccessModal.scss';
import { styles } from '../Styles';
import { disableEscKey } from '../Util';
import CloseCrossButton from '../../Buttons/CloseCrossButton/CloseCrossButton';
import { useTranslation } from 'react-i18next';
import ReportOptionsButton from '../../Buttons/ReportOptionsButton/ReportOptionsButton';
import bgImg from '../../../../assets/report_generated_icon.svg';
import BaseModal from '../BaseModal';

type Props = {
  isOpen: boolean;
  closeModal: any;
  submit?: any;
  title?: any;
  subTitle?: any;
  buttonText?: any;
  buttonAction?: Function;
  backgroundImg?: any;
};
export const SuccessModal = (props: Props) => {
  const { isOpen, closeModal, title, subTitle, buttonText, buttonAction, backgroundImg } = props;
  const { t } = useTranslation();

  const handleClick = () => {
    if (buttonAction) {
      buttonAction();
    } else {
      closeModal();
    }
  };

  const getImgSrc = () => {
    return backgroundImg || bgImg;
  };

  return (
    <BaseModal
      isOpen={isOpen}
      onRequestClose={e => disableEscKey(e, closeModal)}
      style={styles}
      className="success-modal-content"
    >
      <div className="success-modal-container">
        <div className="close-cross-container">
          <CloseCrossButton handleClick={closeModal} buttonClass="close-cross-button-white" spanClass="white" />
        </div>
        <div className="horizontal">
          <div className="icon-image">
            <img src={getImgSrc()} alt="" />
          </div>
        </div>
        <div className="options-list">
          <h3>{title || 'title'}</h3>
          <span className="subtitle">{subTitle || 'subtitle'}</span>
        </div>
        <div className="horizontal">
          <ReportOptionsButton handleClick={handleClick} disabled={false} customText={buttonText || t('close')} />
        </div>
      </div>
    </BaseModal>
  );
};
