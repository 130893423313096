import React, { Fragment } from 'react';
import { Bubble } from '../BubbleElement/BubbleElement';
import useFilters from '../../../hooks/useFilters';

const RoleBubbles = () => {
  const { filterAnalytics, dispatch, setFilterAnalytics } = useFilters();
  const bubbles = filterAnalytics;

  const closeBubble = roleId => {
    const actualState = filterAnalytics.roleId;
    const newState = actualState.filter(role => {
      return role.id !== roleId;
    });

    dispatch(
      setFilterAnalytics({
        ...filterAnalytics,
        roleId: newState,
      }),
    );
  };

  return (
    <Fragment>
      {bubbles.roleId.map(role => (
        <Bubble key={role.id} itsHidden={false} label={role.label} closeBubble={() => closeBubble(role.id)} />
      ))}
    </Fragment>
  );
};

export default RoleBubbles;
