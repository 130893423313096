import SelectOption from '../../models/SelectOption';
import Division from '../../models/Division';
import { MenuTabs, AppMenuTabs } from '../MenuTabs/userTabsInterface';
import { CalledFrom } from '../../models/CalledFromEnum';
import FilterIndicators from '../../models/FilterIndicators';
import FilterProjects from '../../models/FilterProjects';
import FilterEarlyWarnings from '../../models/FilterEarlyWarnings';

export const getValidBubble = (from, to, label, tSingular, tPlural) => {
  let returnedBubble = {
    itsHidden: true,
    label,
    value: '',
    translationSingular: tSingular,
    translationPlural: tPlural,
  };
  if (from < to && from !== 0 && to !== 0) {
    returnedBubble = {
      itsHidden: false,
      label,
      value: 'between',
      translationSingular: tSingular,
      translationPlural: tPlural,
    };
  } else if (from > to && from !== 0 && to === 0) {
    returnedBubble = {
      itsHidden: false,
      label,
      value: 'gt',
      translationSingular: tSingular,
      translationPlural: tPlural,
    };
  } else if (from < to && from === 0 && to !== 0) {
    returnedBubble = {
      itsHidden: false,
      label,
      value: 'lt',
      translationSingular: tSingular,
      translationPlural: tPlural,
    };
  }

  return returnedBubble;
};

export const getSelectedOption = (filterValue: any, options: SelectOption[]) => {
  const returnedOptions: any = [];

  filterValue &&
    filterValue.value.forEach(id => {
      const option = options.find(opt => opt.id === id);
      option && returnedOptions.push(option);
    });

  return returnedOptions;
};

export const getCustomTabFilterDefaultValue = (selectedTab: MenuTabs, field: string, filterOptions: SelectOption[]) => {
  const filterValue =
    selectedTab &&
    selectedTab.filters &&
    selectedTab.filters.length &&
    selectedTab.filters.find(filter => filter.field === field);
  return getSelectedOption(filterValue, filterOptions);
};

export const getCustomTabDefaultValueFromProps = (selectedTab: MenuTabs, propName: string, options: SelectOption[]) => {
  const propValues = selectedTab[propName];
  return options.filter(option => propValues.includes(option.id));
};

export const getDivisionFilterOptions = (divisions: Division[]) => {
  const options: SelectOption[] = [];
  let otherOption;

  divisions.forEach(division => {
    if (division.code !== 'OTHERS') {
      options.push({
        id: division.id,
        label: `${division.code} - ${division.name}`,
        value: division.name,
      });
    } else {
      otherOption = {
        id: division.id,
        label: division.name,
        value: division.name,
      };
    }
  });

  otherOption && options.push(otherOption);
  return options;
};

const countriesList = (selectedTab: MenuTabs, allOptions: any[]) => {
  const countryFilter = selectedTab.filters.find(
    filter => filter.field === 'ResponsibleCountryId' && filter.filterType === 6,
  );

  const selectedRegions = selectedTab.regions;
  const selectedCountries = countryFilter ? countryFilter.value : [];
  const filteredCountriesByRegions = selectedRegions.length
    ? allOptions.filter(item => selectedRegions.includes(item.categoryId))
    : allOptions;

  return selectedCountries.length
    ? filteredCountriesByRegions.filter(item => selectedCountries.includes(item.id))
    : filteredCountriesByRegions;
};

export const getCountryOptions = (allOptions: any[], menuTabs: AppMenuTabs, calledFrom: CalledFrom) => {
  const portfolioSelectedTab = menuTabs.portfolioSelectedTab;
  const projectSelectedTab = menuTabs.projectSelectedTab;

  if (calledFrom === CalledFrom.PORTFOLIO) {
    if (portfolioSelectedTab.tabId === 0) {
      return allOptions;
    } else {
      return countriesList(portfolioSelectedTab, allOptions);
    }
  } else if (calledFrom === CalledFrom.PROJECT) {
    if (projectSelectedTab.tabId === 0) {
      return allOptions;
    } else {
      return countriesList(projectSelectedTab, allOptions);
    }
  } else {
    return allOptions;
  }
};

const optionsToReturn = (selectedTab: MenuTabs, filterField: string, allOptions: SelectOption[]) => {
  const filterState = selectedTab.filters.find(filter => filter.field === filterField);
  const selectedOptions = filterState ? filterState.value : [];
  return selectedOptions.length ? allOptions.filter(item => selectedOptions.includes(item.id)) : allOptions;
};

const getFilterOptions = (
  calledFrom: CalledFrom,
  portfolioSelectedTab: MenuTabs,
  projectSelectedTab: MenuTabs,
  allOptions: SelectOption[],
  filterField: string,
  earlyWarningsSelectedTab: MenuTabs,
) => {
  if (calledFrom === CalledFrom.PORTFOLIO) {
    if (portfolioSelectedTab.tabId === 0) {
      return allOptions;
    } else {
      return optionsToReturn(portfolioSelectedTab, filterField, allOptions);
    }
  } else if (calledFrom === CalledFrom.PROJECT) {
    if (projectSelectedTab.tabId === 0) {
      return allOptions;
    } else {
      return optionsToReturn(projectSelectedTab, filterField, allOptions);
    }
  } else if (calledFrom === CalledFrom.EARLY_WARNINGS) {
    if (earlyWarningsSelectedTab.tabId === 0) {
      return allOptions;
    } else {
      return optionsToReturn(earlyWarningsSelectedTab, filterField, allOptions);
    }
  } else {
    return allOptions;
  }
};

export const getDivisionOptions = (allOptions: SelectOption[], menuTabs: AppMenuTabs, calledFrom: CalledFrom) => {
  const portfolioSelectedTab = menuTabs.portfolioSelectedTab;
  const projectSelectedTab = menuTabs.projectSelectedTab;
  const earlyWarningsSelectedTab = menuTabs.earlyWarningsSelectedTab;

  return getFilterOptions(
    calledFrom,
    portfolioSelectedTab,
    projectSelectedTab,
    allOptions,
    'DivisionId',
    earlyWarningsSelectedTab,
  );
};

export const getSectorOptions = (data: any, menuTabs: AppMenuTabs, calledFrom: CalledFrom) => {
  const portfolioSelectedTab = menuTabs.portfolioSelectedTab;
  const projectSelectedTab = menuTabs.projectSelectedTab;
  const earlyWarningsSelectedTab = menuTabs.earlyWarningsSelectedTab;
  const allOptions = data.map(sector => ({
    id: sector.id,
    value: sector.id,
    label: `${sector.code} - ${sector.description}`,
  }));

  return getFilterOptions(
    calledFrom,
    portfolioSelectedTab,
    projectSelectedTab,
    allOptions,
    'SectorId',
    earlyWarningsSelectedTab,
  );
};

export const handleTeamLeaderChange = (
  teamLeaders: SelectOption[],
  calledFrom: CalledFrom,
  dispatch: any,
  filters,
  setters,
) => {
  const { filterIndicators, filterProjects, filterEarlyWarnings } = filters;
  const { setFilterIndicators, setFilter, setFilterEarlyWarnings } = setters;

  if (teamLeaders === null) {
    teamLeaders = [];
  }

  if (calledFrom === CalledFrom.PORTFOLIO) {
    dispatch(
      setFilterIndicators({
        ...filterIndicators,
        common: {
          ...filterIndicators.common,
          teamLeaders,
        },
      }),
    );
  } else if (calledFrom === CalledFrom.PROJECT) {
    dispatch(
      setFilter({
        ...filterProjects,
        common: {
          ...filterProjects.common,
          teamLeaders,
        },
      }),
    );
  } else if (calledFrom === CalledFrom.EARLY_WARNINGS) {
    dispatch(
      setFilterEarlyWarnings({
        ...filterEarlyWarnings,
        common: {
          ...filterEarlyWarnings.common,
          teamLeaders,
        },
      }),
    );
  }
};

const getTeamLeadersOptions = teamLeaders => {
  return teamLeaders.map(tl => ({
    id: tl.id,
    label: tl.name,
    value: tl.name,
  }));
};

const handleEarlyWarningsFilterPanelBehaivor = ({ selectedTab, setInFollowMessage, filterEarlyWarnings }) => {
  let selectedTeamLeaders;
  if (isSelectedTabDiffThanZero(selectedTab)) {
    selectedTeamLeaders = getTeamLeadersOptions(selectedTab.teamLeaders);
    setInFollowMessage(selectedTab.isFollow);
  } else {
    selectedTeamLeaders = filterEarlyWarnings.common.teamLeaders;
    setInFollowMessage(selectedTab.inFollow);
  }
  return selectedTeamLeaders;
};

export const manageFilterPanelBehavior = (
  isOpen: boolean,
  calledFrom: CalledFrom,
  menuTabs: AppMenuTabs,
  setters: any,
  filters: any,
) => {
  const { setInFollowMessage, setTeamLeaderSelected } = setters;
  const { filterIndicators, filter, filterEarlyWarnings } = filters;
  let selectedTab;
  let selectedTeamLeaders;

  if (isOpen) {
    if (calledFrom === CalledFrom.PORTFOLIO) {
      selectedTab = menuTabs.portfolioSelectedTab;
      if (isSelectedTabDiffThanZero(selectedTab)) {
        selectedTeamLeaders = getTeamLeadersOptions(selectedTab.teamLeaders);
        setInFollowMessage(selectedTab.inFollow);
      } else {
        selectedTeamLeaders = filterIndicators.common.teamLeaders;
        setInFollowMessage(selectedTab.inFollow);
      }
    } else if (calledFrom === CalledFrom.PROJECT) {
      selectedTab = menuTabs.projectSelectedTab;
      if (isSelectedTabDiffThanZero(selectedTab)) {
        selectedTeamLeaders = getTeamLeadersOptions(selectedTab.teamLeaders);
        setInFollowMessage(selectedTab.isFollow);
      } else {
        selectedTeamLeaders = filter.common.teamLeaders;
        setInFollowMessage(selectedTab.inFollow);
      }
    } else if (calledFrom === CalledFrom.EARLY_WARNINGS) {
      selectedTab = menuTabs.earlyWarningsSelectedTab;
      selectedTeamLeaders = handleEarlyWarningsFilterPanelBehaivor({
        selectedTab,
        setInFollowMessage,
        filterEarlyWarnings,
      });
    }

    setTeamLeaderSelected(selectedTeamLeaders);
  }
};

const isSelectedTabDiffThanZero = selectedTab => selectedTab.tabId !== 0;

export const getPortfolioOrProjectSelectedTab = (calledFrom: CalledFrom, menuTabs: AppMenuTabs) => {
  switch (calledFrom) {
    case CalledFrom.PORTFOLIO:
    case CalledFrom.CUSTOM_TABS_PORTFOLIO:
      return menuTabs.portfolioSelectedTab;
    case CalledFrom.EARLY_WARNINGS:
      return menuTabs.earlyWarningsSelectedTab;
    case CalledFrom.PROJECT:
    case CalledFrom.CUSTOM_TABS_PROJECT:
    default:
      return menuTabs.projectSelectedTab;
  }
};

const isSectorOrDivisionTab = (selectedTab: MenuTabs, dataFromRedux: any[]) => {
  return selectedTab.tabId === 0 && dataFromRedux.find(item => item.code === selectedTab.title);
};

const handleSectorAndDivisionFilterRender = (
  selectedTab: MenuTabs,
  dataFromRedux: any,
  setShowSectorFilter: Function,
  setShowDivisionFilters: Function,
) => {
  const { sectorsFromRedux, divisionsFromRedux } = dataFromRedux;
  const sectorFound = isSectorOrDivisionTab(selectedTab, sectorsFromRedux);

  setShowSectorFilter(!sectorFound);

  const divisionFound = isSectorOrDivisionTab(selectedTab, divisionsFromRedux);
  divisionFound && setShowSectorFilter(!divisionFound);
  setShowDivisionFilters(!divisionFound);
};

export const manageSectorsAndDivisionsFilterBehavior = (
  isOpen: boolean,
  calledFrom: CalledFrom,
  menuTabs: AppMenuTabs,
  filters: any,
  setters: any,
  dataFromRedux: any,
) => {
  const { filterIndicators, filterProjects, filterEarlyWarnings } = filters;
  const { setShowHiddenFilters, setShowSectorFilter, setShowDivisionFilters } = setters;

  if (isOpen) {
    let selectedTab;
    let coDivisionResponsibleFilterValue;
    let participatingDivisionFilterValue;

    if (calledFrom === CalledFrom.PORTFOLIO) {
      selectedTab = menuTabs.portfolioSelectedTab;
      coDivisionResponsibleFilterValue = filterIndicators.common.coReponsibleDivisions;
      participatingDivisionFilterValue = filterIndicators.common.participantDivisions;

      handleSectorAndDivisionFilterRender(selectedTab, dataFromRedux, setShowSectorFilter, setShowDivisionFilters);
    } else if (calledFrom === CalledFrom.CUSTOM_TABS_PORTFOLIO) {
      selectedTab = menuTabs.portfolioSelectedTab;
      coDivisionResponsibleFilterValue = selectedTab.coReponsibleDivisions;
      participatingDivisionFilterValue = selectedTab.participantDivisions;
    } else if (calledFrom === CalledFrom.PROJECT) {
      selectedTab = menuTabs.projectSelectedTab;
      coDivisionResponsibleFilterValue = filterProjects.common.coReponsibleDivisions;
      participatingDivisionFilterValue = filterProjects.common.participantDivisions;

      handleSectorAndDivisionFilterRender(selectedTab, dataFromRedux, setShowSectorFilter, setShowDivisionFilters);
    } else if (calledFrom === CalledFrom.CUSTOM_TABS_PROJECT) {
      selectedTab = menuTabs.projectSelectedTab;
      coDivisionResponsibleFilterValue = selectedTab.coReponsibleDivisions;
      participatingDivisionFilterValue = selectedTab.participantDivisions;
    } else if (calledFrom === CalledFrom.EARLY_WARNINGS) {
      selectedTab = menuTabs.earlyWarningsSelectedTab;
      coDivisionResponsibleFilterValue = filterEarlyWarnings.common.coReponsibleDivisions;
      participatingDivisionFilterValue = filterEarlyWarnings.common.participantDivisions;

      handleSectorAndDivisionFilterRender(selectedTab, dataFromRedux, setShowSectorFilter, setShowDivisionFilters);
    }

    if (
      (coDivisionResponsibleFilterValue && coDivisionResponsibleFilterValue.length) ||
      (participatingDivisionFilterValue && participatingDivisionFilterValue.length)
    ) {
      setShowHiddenFilters(true);
    }
  }
};

export const getDivisionsFromSectors = (sectorsSelected: SelectOption[], sectorsFromRedux: any) => {
  let returnedDivisions: any = [];

  sectorsSelected.forEach(sector => {
    const foundSector = sectorsFromRedux.find(sfr => sfr.id === sector.id);
    if (foundSector) {
      returnedDivisions = [...returnedDivisions, ...foundSector.divisions];
    }
  });

  return returnedDivisions;
};

export const getDivisionsDefaultOptions = (
  divisionOptionsAux: SelectOption[],
  selectedTab: MenuTabs,
  sectorsFromRedux: any[],
) => {
  const sectorsSelected = selectedTab.filters.find(filter => filter.field === 'SectorId');
  const sectorsSelectedIds = sectorsSelected ? sectorsSelected.value : [];
  const sectorDivisionsSelectedIds = sectorsFromRedux
    .filter(sector => sectorsSelectedIds.includes(sector.id))
    .flatMap(sector => sector.divisions)
    .map(division => division.id);

  const divisionsSelected = selectedTab.filters.find(filter => filter.field === 'DivisionId');
  const divisionsSelectedIds = divisionsSelected ? divisionsSelected.value : [];

  const hasDivisionSelectedByUser = sectorDivisionsSelectedIds.length !== divisionsSelectedIds.length;
  const finalDivisionIds = hasDivisionSelectedByUser ? divisionsSelectedIds : [];

  return divisionOptionsAux.filter(division => finalDivisionIds.includes(division.id));
};

const getDivisionsFromSectorsSelectedOptions = (
  filter: FilterIndicators | FilterProjects | FilterEarlyWarnings,
  sectorsFromRedux: any,
) => {
  const sectorsFromFilter = filter.common.sectors;
  const sectorsData = sectorsFromRedux.filter(sector => sectorsFromFilter.map(sector => sector.id).includes(sector.id));
  const sectorsDivisions = sectorsData.flatMap(sector => sector.divisions);

  return sectorsDivisions.map(option => ({
    id: option.id,
    value: `${option.code} - ${option.name}`,
    label: `${option.code} - ${option.name}`,
  }));
};

export const getDivisionsSelected = (
  value: SelectOption[],
  calledFrom: CalledFrom,
  filterIndicators: FilterIndicators,
  filterProjects: FilterProjects,
  filterEarlyWarnings: FilterEarlyWarnings,
  sectorsFromRedux: any[],
) => {
  if (value.length || calledFrom === CalledFrom.ANALYTICS) {
    return value.map(option => ({
      id: option.id,
      value: option.value,
      label: option.label,
      selectedByUser: true,
    }));
  } else {
    switch (calledFrom) {
      case CalledFrom.PORTFOLIO:
        return getDivisionsFromSectorsSelectedOptions(filterIndicators, sectorsFromRedux);
      case CalledFrom.PROJECT:
        return getDivisionsFromSectorsSelectedOptions(filterProjects, sectorsFromRedux);
      case CalledFrom.EARLY_WARNINGS:
        return getDivisionsFromSectorsSelectedOptions(filterEarlyWarnings, sectorsFromRedux);
    }
  }
  return null;
};

export const handleBubbles = ({ indicatorCall, filterIndicators, filter, filterEarlyWarnings, calledFrom }) => {
  if (calledFrom === CalledFrom.EARLY_WARNINGS) {
    return filterEarlyWarnings.common;
  }
  return indicatorCall ? filterIndicators.common : filter.common;
};

export const handleBubblesWithAnalytics = params => {
  const { calledFrom, indicatorCall, filterIndicators, analyticCall, filterAnalytics, filter, filterEarlyWarnings } =
    params;

  if (calledFrom === CalledFrom.EARLY_WARNINGS) {
    return filterEarlyWarnings.common;
  } else {
    return indicatorCall ? filterIndicators.common : analyticCall ? filterAnalytics : filter.common;
  }
};

export const handleActualState = params => {
  const { calledFrom, indicatorCall, filterIndicators, filterProjects, filterEarlyWarnings, commonProp } = params;
  if (calledFrom === CalledFrom.EARLY_WARNINGS) {
    return filterEarlyWarnings.common[commonProp];
  } else {
    return indicatorCall ? filterIndicators.common[commonProp] : filterProjects.common[commonProp];
  }
};

export const handleActualStateWithAnalytics = params => {
  const {
    calledFrom,
    indicatorCall,
    filterIndicators,
    analyticCall,
    filterEarlyWarnings,
    filterAnalytics,
    filter,
    commonProp,
  } = params;
  if (calledFrom === CalledFrom.EARLY_WARNINGS) {
    return filterEarlyWarnings.common[commonProp];
  } else {
    return indicatorCall
      ? filterIndicators.common[commonProp]
      : analyticCall
      ? filterAnalytics[commonProp]
      : filter.common[commonProp];
  }
};
