import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import '../MemoryHelp.scss'
import { ReactComponent as IconPlus } from '../../../assets/icons/u_plus-circle.svg';
import { ReactComponent as IconDelete } from '../../../assets/icons/u_multiply-circle.svg';
import { FileUploaderProps } from '../models';
import { useTranslation } from 'react-i18next';

const FileUploader: React.FC<FileUploaderProps> = ({ onFileAccepted, onFileDeleted }) => {
    const { t } = useTranslation();

    const [dragFile, setDragFile] = useState<File | null>(null);
    const [fileName, setFileName] = useState<string>('');

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            'application/pdf': ['.pdf']
        },
        onDropAccepted: files => {
            if (files.length > 0) {
                setDragFile(files[0]);
                setFileName(files[0].name);
                onFileAccepted(files[0]);
            }
        },
        multiple: false
    });

    // Handles file deletion and updates related statuses
    const handleDeleteFile = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        event.stopPropagation();
        setDragFile(null);
        setFileName('');
        onFileDeleted();
    };

    return (
        <div {...getRootProps()} className="dropzone">
            <input {...getInputProps()} />
            <div className='drag-file'>
                <div className='file'>
                    {
                        isDragActive ?
                            <p>{t('memory_help.container_tabs.completed.file_uploader.drag_revision_file')}</p>
                            :
                            <p>{fileName || t('memory_help.container_tabs.completed.file_uploader.add_file')}</p>
                    }
                </div>
                <div className='icon-file'>
                    {dragFile === null && <IconPlus />}
                    {dragFile !== null && <IconDelete onClick={handleDeleteFile} />}
                </div>
            </div>
        </div>
    );
};

export default FileUploader;


