import React, { useState } from 'react';
import './ReviewAccordancesTable.scss';
import { useTranslation } from 'react-i18next';
import { StyledTableCellHeader, StyledTableRowHeader, StyledTableCell } from './ReviewAccordancesTable.style';
import { Table, TableBody, TableHead, TableContainer, TableRow } from '@material-ui/core';
import { BidTooltip } from '../../Util/Tooltip/Tooltip';
import { replaceMomentFormat } from '../../../services/util';
import { tooltipTitle } from '../../../services/stringUtil';
import { AccordanceState } from '../../../models/AccordanceState';
import moment from 'moment';
import CommentsIcon from '../../ProjectDetail/ProjectTabs/Accordances/CommentsIcon/CommentsIcon';
import { SortParameterAccordance } from '../../../models/SortParameterAccordanceEnum';
import { AccordanceModalsFlow } from '../../Util/Modals/AccordanceModalsFlow/AccordanceModalsFlow';
import Accordance from '../../../models/Accordance';
import { ReactComponent as IconSort } from '../../../assets/icons/u_sort.svg';

type Props = {
  accordances: any;
  sortConcept: any;
  refreshData: any;
};

export const ReviewAccordancesTable = ({ accordances, sortConcept, refreshData }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedAccordance, setSelectedAccordance] = useState({});
  const { t } = useTranslation();

  const handleSelectedAccordanceClick = accordanceIdSelected => {
    setSelectedAccordance(accordances.find(accordance => accordance.id === accordanceIdSelected));
    setShowModal(true);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  return (
    <div className={'review-accordances-table-container'}>
      <TableContainer>
        <Table stickyHeader className="custom-table" style={{ tableLayout: 'auto' }}>
          <TableHead>
            <StyledTableRowHeader>
              <StyledTableCellHeader
                className="code"
                align="left"
                onClick={() => sortConcept(SortParameterAccordance.ProjectCode)}
              >
                <div className="text-head">
                  {t('code')}
                  <i className="sort-icon">
                    {' '}
                    <IconSort />{' '}
                  </i>
                </div>
              </StyledTableCellHeader>
              <StyledTableCellHeader align="left" onClick={() => sortConcept(SortParameterAccordance.ProjectName)}>
                <div className="text-head">
                  {t('name')}
                  <i className="sort-icon">
                    <IconSort />{' '}
                  </i>
                </div>
              </StyledTableCellHeader>
              <StyledTableCellHeader
                align="center"
                onClick={() => sortConcept(SortParameterAccordance.CriticalIssueName)}
              >
                <div className="text-head">
                  {t('critical_issue')}
                  <i className="sort-icon">
                    <IconSort />{' '}
                  </i>
                </div>
              </StyledTableCellHeader>

              {/* <StyledTableCellHeader
                className="accordance-about"
                align="center"
                onClick={() => sortConcept(SortParameterAccordance.AccordanceAbout)}
              >
                <div className='text-head'>
                  {t('accordance_about')}
                  <i className="sort-icon"><IconSort /> </i>
                </div>
              </StyledTableCellHeader> */}
              <StyledTableCellHeader
                className="accordance-about"
                align="center"
                onClick={() => sortConcept(SortParameterAccordance.Description)}
              >
                <div className="text-head">
                  {t('accordance_description')}
                  <i className="sort-icon">
                    <IconSort />{' '}
                  </i>
                </div>
              </StyledTableCellHeader>
              <StyledTableCellHeader align="center" onClick={() => sortConcept(SortParameterAccordance.ExpirationDate)}>
                <div className="text-head">
                  {t('expiration')}
                  <i className="sort-icon">
                    <IconSort />{' '}
                  </i>
                </div>
              </StyledTableCellHeader>
              <StyledTableCellHeader align="center" onClick={() => sortConcept(SortParameterAccordance.Responsabile)}>
                <div className="text-head">
                  {t('responsible')}
                  <i className="sort-icon">
                    <IconSort />{' '}
                  </i>
                </div>
              </StyledTableCellHeader>
              <StyledTableCellHeader align="center">{t('comments')}</StyledTableCellHeader>
              <StyledTableCellHeader
                align="center"
                onClick={() => sortConcept(SortParameterAccordance.AccordanceState)}
              >
                <div className="text-head">
                  {t('accordance_status')}
                  <i className="sort-icon">
                    <IconSort />{' '}
                  </i>
                </div>
              </StyledTableCellHeader>
              <StyledTableCellHeader align="right" />
            </StyledTableRowHeader>
          </TableHead>
          <TableBody>
            {accordances.map(accordance => (
              <TableRow key={accordance.id}>
                <StyledTableCell>{`${accordance.project.code} ${accordance.project.loanNumber}`}</StyledTableCell>
                <StyledTableCell>{accordance.project.name}</StyledTableCell>
                <StyledTableCell>{handleValueRender(accordance, 'criticalIssue', 'name')}</StyledTableCell>
                {/* <StyledTableCell>{handleValueRender(accordance, 'possibleAction', 'name')}</StyledTableCell> */}
                <StyledTableCell className="cut-text" align="center">
                  {renderDescription(accordance)}
                </StyledTableCell>
                <StyledTableCell>
                  {accordance.expirationDate
                    ? replaceMomentFormat(moment(accordance.expirationDate).format('MMM/YYYY'))
                    : '-'}
                </StyledTableCell>
                <StyledTableCell>{`${handleValueRender(accordance, 'personInCharge', 'name')} ${handleValueRender(
                  accordance,
                  'personInCharge',
                  'lastName',
                  '',
                )}`}</StyledTableCell>
                <StyledTableCell>
                  <CommentsIcon
                    hasUnreadComments={accordance.hasUnreadComments}
                    comments={
                      accordance.accordanceComments
                        ? accordance.accordanceComments.filter(item => !item.isDeleted).length
                        : 0
                    }
                  />
                </StyledTableCell>
                <StyledTableCell
                  className={`accordance-status ${accordance.accordanceState === 1 && 'pending-status'}`}
                >
                  {accordanceStateText(accordance.accordanceState, t)}
                </StyledTableCell>
                <StyledTableCell align="left">
                  <BidTooltip
                    title={tooltipTitle(t, 'accordance_detail')}
                    placement="bottom-start"
                    enterDelay={500}
                    leaveDelay={200}
                    arrow
                  >
                    <span className="fw" onClick={() => handleSelectedAccordanceClick(accordance.id)} />
                  </BidTooltip>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AccordanceModalsFlow
        accordance={selectedAccordance}
        accordanceDetailSetOpen={showModal}
        accordanceDetailIsClosed={hideModal}
        refreshData={refreshData}
      />
    </div>
  );
};

const accordanceStateText = (state, t) => {
  switch (state) {
    case AccordanceState.DRAFT:
      return t('draft_accordance');
    case AccordanceState.VALID:
      return t('valid');
    case AccordanceState.FULLFILLED:
      return t('accomplished_accordance_singular');
    case AccordanceState.CANCELLED:
      return t('cancelled_word');
    case AccordanceState.REJECT:
      return t('rejected');
    default:
      return t('pending');
  }
};

const renderDescription = accordance => {
  return accordance.description ? (
    <BidTooltip arrow title={accordance.description} placement="bottom-start" enterDelay={500} leaveDelay={200}>
      <span>{accordance.description}</span>
    </BidTooltip>
  ) : (
    '-'
  );
};

const handleValueRender = (dataObject: Accordance, nestedObject: string, returnedProp: any, returnedFalse = '-') => {
  if (dataObject[nestedObject] !== null) {
    return dataObject[nestedObject][returnedProp];
  } else {
    return returnedFalse;
  }
};
