import { languagesKeys } from '../../ContentManagerKeys';
import { isNil, find, map, lowerCase } from 'lodash';

const defaultFaqFormFields = {
  title: '',
  content: '',
  isChecked: false,
  titleError: false,
  contentError: false,
};

export const defaultFormFields = [
  {
    languageid: languagesKeys.spanish,
    ...defaultFaqFormFields,
  },
  {
    languageid: languagesKeys.english,
    ...defaultFaqFormFields,
  },
  {
    languageid: languagesKeys.portuguese,
    ...defaultFaqFormFields,
  },
  {
    languageid: languagesKeys.french,
    ...defaultFaqFormFields,
  },
];

export const getActionButtonTitle = isEdit =>
  isEdit ? 'contents_helpCenter_faq_saveFaq' : 'contents_helpCenter_faq_addFaq_add_button_label';

export const getFormFieldsErrors = (formFields, unCompletedTranslations, field = 'languageid') =>
  map(formFields, faqItem => {
    if (!isNil(find(unCompletedTranslations, translation => faqItem[field] === translation[field]))) {
      let titleError,
        contentError = false;
      if (faqItem.title.trim() === '') {
        titleError = true;
      }
      if (faqItem.content.trim() === '') {
        contentError = true;
      }
      return { ...faqItem, titleError, contentError };
    }
    return faqItem;
  });

export const isChecked = (faqItem, type, value) =>
  (type === 'title' && value.trim() !== '' && faqItem.content.trim() !== '') ||
  (type === 'content' && value.trim() !== '' && faqItem.title.trim() !== '');

export const getOnChangeFormFields = (formFields, type, value, languagesKeys, language) =>
  map(formFields, faqItem => {
    if (faqItem.languageid === languagesKeys[language]) {
      return {
        ...faqItem,
        [type]: value,
        isChecked: isChecked(faqItem, type, value),
        titleError: false,
        contentError: false,
      };
    }
    return faqItem;
  });

export const isFaqChecked = (title, content) => title.trim() !== '' && content.trim() !== '';

export const mapTranslationsToFormFields = (translations, languagesData, findLanguage) =>
  map(translations, item => {
    return {
      ...item,
      isChecked: isFaqChecked(item.title, item.content),
      languageid: lowerCase(findLanguage(languagesData, item).name),
    };
  });

export const getPostFaqItem = (formFields, languagesData, visible, findLanguageByValue) => ({
  visible: !visible,
  translations: map(formFields, translation => {
    return {
      ...translation,
      languageid: findLanguageByValue(languagesData, translation.languageid).id,
    };
  }),
});

export const hasErrors = faqItem => faqItem.titleError || faqItem.contentError;

export const getErrorClass = hasError => hasError && 'error';

export const getDisabledClass = (formFields, hasCompletedTranslation) =>
  !hasCompletedTranslation(formFields) && 'disabled';

export const getExpanded = (faqItem, expanded) => hasErrors(faqItem) || expanded === faqItem.languageid;
