import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import LabelData from '../../../../../components/Util/LabelData/LabelData';
import { emptyValue } from '../constants';
import LinkText from '../../../../Util/Buttons/LinkText/LinkText';

type Props = {
  eventData: any;
  createAccordanceReminder: any;
  isCalendarIndicator?: boolean;
};

const DisbursementDetail = (props: Props) => {
  const { t } = useTranslation();
  let { eventData, createAccordanceReminder, isCalendarIndicator } = props;

  if (!eventData) {
    eventData = {
      amount: emptyValue,
      totalProjectedAnual: emptyValue,
      totalDisbursed: emptyValue,
      minimumAmountToJustify: emptyValue,
      projectTitle: emptyValue,
      projectCode: emptyValue,
    };
  }
  const { amount, totalProjectedAnual, totalDisbursed, minimumAmountToJustify, projectCode, projectTitle } = eventData;

  return (
    <>
      {isCalendarIndicator && <LabelData label={t('code')} data={`${projectCode} ${projectTitle}`} reverse={true} />}
      <LabelData label={t('amount')} data={amount} reverse={true} formatNumber={true} />
      <LabelData label={t('total_projected_anual')} data={totalProjectedAnual} reverse={true} formatNumber={true} />
      <LabelData label={t('total_disbursed')} data={totalDisbursed} reverse={true} formatNumber={true} />
      <LabelData
        label={t('minimum_amount_to_justify')}
        data={minimumAmountToJustify}
        reverse={true}
        formatNumber={true}
      />
      <div style={{ display: 'flex', marginBottom: '20px' }}>
        <LabelData
          label={t('scheduled_date')}
          data={eventData.end ? moment(eventData.end).format('DD/MM/YYYY') : emptyValue}
          reverse={true}
        />
        {eventData.end && (
          <LinkText
            label={t('create_reminder')}
            onClick={createAccordanceReminder}
            customStyle={{ margin: '0px 0px 3px 10px' }}
          />
        )}
      </div>
    </>
  );
};
export default DisbursementDetail;
