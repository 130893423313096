import React from 'react';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';
import { IndicatorKeyEnum } from '../../../models/IndicatorKeyEnum';
import { PmrClassificationComponent } from '../../Util/PmrClassification/PmrClassification';
import { PmrPrintValue } from '../../Util/PmrPrintValue/PmrPrintValue';
import { TcdiClassificationComponent } from '../../Util/TcdiClassification/TcdiClassification';
import { StyledTableCell } from '../PorfolioTableShared.Styles';
import { conditionalDisplay } from './Util';

type Props = {
  havePmr?: boolean;
  haveTcdi?: boolean;
  project: any;
  indicatorKey: string;
};
export const LastColumnValues = ({ havePmr, haveTcdi, project, indicatorKey }: Props) => {
  const { t } = useTranslation();
  const renderLastColumnValues = () => {
    if (havePmr) {
      return (
        <StyledTableCell style={{ width: '15%', border: 'none' }}>
          <div className="display-flex-icon">
            <PmrClassificationComponent
              classificationColor={conditionalDisplay(
                indicatorKey === IndicatorKeyEnum.PMR_PERFORMANCE_LAST_CLOSED_YEAR ||
                  indicatorKey === IndicatorKeyEnum.PMR_STATE_DISTRIBUTION,
                parseInt(project.field2),
                parseInt(project.field3),
              )}
            />
            <span className="pmr-value">
              <PmrPrintValue
                pmrClassi={conditionalDisplay(
                  indicatorKey === IndicatorKeyEnum.PMR_PERFORMANCE_LAST_CLOSED_YEAR ||
                    indicatorKey === IndicatorKeyEnum.PMR_STATE_DISTRIBUTION,
                  parseInt(project.field2),
                  parseInt(project.field3),
                )}
                pmrValue={conditionalDisplay(
                  indicatorKey === IndicatorKeyEnum.PMR_PERFORMANCE_LAST_CLOSED_YEAR ||
                    indicatorKey === IndicatorKeyEnum.PMR_STATE_DISTRIBUTION,
                  parseFloat(project.field1),
                  parseFloat(project.field2),
                )}
              />
            </span>
          </div>
        </StyledTableCell>
      );
    } else if (haveTcdi) {
      return (
        <StyledTableCell style={{ width: '10%' }}>
          <TcdiClassificationComponent
            clasification={conditionalDisplay(
              indicatorKey === IndicatorKeyEnum.TCP_PERFORMANCE,
              project.field1,
              project.field3,
            )}
          />
          <span className="pmr-classification-margin-left">{handleValueOnTCDI(project, t, indicatorKey)}</span>
        </StyledTableCell>
      );
    } else {
      return (
        <StyledTableCell align="center" style={{ width: '30%', minWidth: '70px' }}>
          <span>
            {project.field1 && (
              <NumberFormat
                value={Math.trunc(project.field1)}
                displayType={'text'}
                thousandSeparator={'.'}
                decimalSeparator={','}
                decimalScale={1}
              />
            )}
          </span>
        </StyledTableCell>
      );
    }
  };

  return renderLastColumnValues();
};

const handleValueOnTCDI = (project, t, indicatorKey) => {
  const fieldUsed = indicatorKey === IndicatorKeyEnum.TCP_PERFORMANCE ? project.field1 : project.field2;
  return fieldUsed !== null ? parseFloat(fieldUsed).toFixed(3) : t('not_assigned_initials');
};
