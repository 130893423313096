import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { filterStatus } from '../BasicData';
import styles from './FilterButton.module.scss';
import { setReviewSelectedFilter } from '../../../../redux/actions/reviewProjectSelectedFilters';

type Props = {
  filter: any;
  mostUsedFilters?: boolean;
};

const containerStyle = (filters, id) => {
  return filters.find(i => i.id === id)
    ? { container: styles.containerSelected, text: styles.textSelected }
    : { container: styles.container, text: styles.text };
};

const twoDigitsPadding = '2px 1px 1px 2px';

const borderStyle = status => {
  switch (status) {
    case filterStatus.Green:
      return { border: '1px solid #50ac32' };
    case filterStatus.Yellow:
      return { border: '1px solid #ff8c26' };
    case filterStatus.Red:
      return { border: '1px solid #e92c2c' };
    default:
      return { border: '1px solid #d0d5e0' };
  }
};

const countStyle = (status, count) => {
  switch (status) {
    case filterStatus.Green:
      return { color: '#50ac32', padding: count > 9 ? twoDigitsPadding : undefined };
    case filterStatus.Yellow:
      return { color: '#ff8c26', padding: count > 9 ? twoDigitsPadding : undefined };
    case filterStatus.Red:
      return { color: '#e92c2c', padding: count > 9 ? twoDigitsPadding : undefined };
    default:
      return { color: '#d0d5e0', padding: count > 9 ? twoDigitsPadding : undefined };
  }
};

const FilterButton = ({ filter, mostUsedFilters = false }: Props) => {
  const { status, label, count, id, collapsedText } = filter;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedfilters = useSelector<any, any>(state => state.reviewProjectsSelectedFilters);

  const handleClick = () => {
    dispatch(setReviewSelectedFilter(filter));
  };

  return (
    <div className={containerStyle(selectedfilters, id).container} style={borderStyle(status)} onClick={handleClick}>
      <span className={containerStyle(selectedfilters, id).text}>{mostUsedFilters ? t(collapsedText) : t(label)}</span>
      <span className={styles.count} style={countStyle(status, count)}>
        {count}
      </span>
    </div>
  );
};

export default FilterButton;
