import React from 'react';
import styles from './TranslationsList.module.scss';
import { orderList } from '../util';
import CustomInput from '../CustomInput/CustomInput';

type Props = {
  list: any[];
  editMode: boolean;
  inputHasChanges: any;
};

const renderListItem = (item: any, editMode, inputHasChanges) => {
  return orderList.map(i => {
    const found = item.values.find(o => o.encodedName === i.encodedName);
    return editMode ? (
      <CustomInput
        translation={{
          id: found.id,
          value: found.value,
        }}
        key={item.id.toString() + found.languageId.toString()}
        hasChanges={() => inputHasChanges()}
      />
    ) : (
      <div
        className={found.recentlyUpdated ? styles.listItemSectionUpdated : styles.listItemSection}
        key={item.id.toString() + found.languageId.toString()}
      >
        {found && found.value ? found.value : ''}
      </div>
    );
  });
};

const TranslationsList = ({ list, editMode, inputHasChanges }: Props) => {
  return (
    <div className={styles.container}>
      {list.map(item => (
        <div key={item.id.toString() + item.groupId.toString()} className={styles.listItem}>
          {renderListItem(item, editMode, inputHasChanges)}
        </div>
      ))}
    </div>
  );
};

export default TranslationsList;
