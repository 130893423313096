import React from 'react';
import './ResponsiveCustomDatePicker.scss';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import User from '../../../models/User';

import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';
import pt from 'date-fns/locale/pt-BR';
import SaveButton from '../Buttons/SaveButton/SaveButton';
import {
  customHeaderContainer,
  getYears,
  SelectedDate,
  SelectedDateComplete,
  SelectedDateError,
} from '../CustomDatePicker/CustomDatePicker';

registerLocale('es', es);
registerLocale('en', en);
registerLocale('pt', pt);

type Props = {
  date?: any;
  label: any;
  disabled?: boolean;
  minDate?: any;
  style?: any;
  setDate: Function;
  error?: boolean;
  placeholderComplete?: boolean;
  small?: boolean;
  maxDate?: any;
  isClearable?: boolean;
  withDate?: boolean;
  startDate?: any;
  endDate?: any;
  filterDate?: any;
  applyCustomDisabledStyles?: boolean;
};

const ResponsiveCustomDatePicker = (props: Props) => {
  const { t } = useTranslation();
  const inputEl = React.useRef('ref');
  const calendarRef = React.useRef<DatePicker>();

  const user = useSelector<any, User>(state => state.user);

  const handleChange = date => {
    props.setDate(date);
  };

  const handleCloseDatePicker = () => {
    calendarRef.current.setOpen(false);
  };

  const {
    date,
    label,
    disabled,
    minDate,
    style = { label: {}, customInput: {} },
    error,
    placeholderComplete,
    small,
    maxDate,
    withDate,
    startDate,
    endDate,
    filterDate,
  } = props;
  const today = new Date();
  const years = getYears(minDate);

  return (
    <div style={style.container} className="responsive-custom-date-picker-container">
      <p style={style.label ? style.label : {}} className="label">
        {label}
      </p>
      <DatePicker
        customInputRef={inputEl}
        selected={date}
        onChange={handleChange}
        dateFormat={'dd-MMM-yyyy'}
        ref={calendarRef}
        selectsRange
        startDate={startDate}
        endDate={endDate}
        popperClassName={'slide-top-popper'}
        shouldCloseOnSelect={false}
        customInput={
          error ? (
            <SelectedDateError small={small} withDate={withDate} />
          ) : placeholderComplete ? (
            <SelectedDateComplete small={small} disabled={disabled} withDate={withDate} />
          ) : (
            <SelectedDate small={small} disabled={disabled} withDate={withDate} />
          )
        }
        minDate={minDate !== undefined ? minDate : today}
        disabled={disabled}
        locale={user.language && user.language.encodedName ? user.language.encodedName : 'en'}
        maxDate={maxDate}
        {...props}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div>
            {customHeaderContainer({
              prevMonthButtonDisabled,
              decreaseMonth,
              date,
              changeYear,
              years,
              nextMonthButtonDisabled,
              increaseMonth,
              t,
            })}
            <div className="btn-select-date">
              <SaveButton handleClick={handleCloseDatePicker} disabled={false} customText={t('select')} />
            </div>
          </div>
        )}
        filterDate={filterDate}
      />
    </div>
  );
};

export default ResponsiveCustomDatePicker;
