import React, { Fragment } from 'react';
import CountryFilter from '../CountryFilter/CountryFilter';
import RegionFilter from '../RegionFilter/RegionFilter';
import { useTranslation } from 'react-i18next';
import SelectOption from '../../../models/SelectOption';
import { getRegionsAndCountries } from '../../../api/filterApi';
import { sortAlphabetically } from '../../../services/sortsUtil';
import { useSelector } from 'react-redux';
import { CalledFrom } from '../../../models/CalledFromEnum';
import { MenuTabs } from '../../MenuTabs/userTabsInterface';

interface IProps {
  calledFrom: CalledFrom;
  setRegionSelected?: Function;
  setCountrySelected?: Function;
  isEdit?: boolean;
}

type Props = IProps;

export enum countriesStatesEnum {
  ShowComplete = 1,
  ShowByRegion,
  Hide,
}

const RegionCountryFilter = (props: Props) => {
  const { calledFrom, setRegionSelected, setCountrySelected, isEdit } = props;
  const { t } = useTranslation();
  const menuTabs = useSelector<any, MenuTabs>(state => state.menuTabs);
  const regionsState = useSelector<any, any>(state => state.regions);
  const [regionState, setRegionState] = React.useState<any>();
  const [countriesState, setCountriesState] = React.useState<any>();
  const [allCountries, setAllCountries] = React.useState<any>([]);
  const [hideRegions] = React.useState(handleHideRegions(calledFrom, menuTabs));
  const [showCountries] = React.useState(handleShowCountries(calledFrom, menuTabs));

  const addValueToCountriesOption = (countriesOptions, country) =>
    countriesOptions.push({
      id: country.id,
      label: country.name,
      value: country.name,
      categoryId: country.regionId,
    });

  React.useEffect(() => {
    setInitialStates({
      showCountries,
      addValueToCountriesOption,
      setRegionState,
      setCountriesState,
      setAllCountries,
      regionsState,
      calledFrom,
      menuTabs,
    });


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterCountriesOnSelect = value => {
    filterCountriesOnRegionsSelect(value, allCountries, countries => setCountriesState(countries), regionsState);
  };

  const renderShowCountries = showCountries => {
    let returnedValue;
    if (showCountries === countriesStatesEnum.ShowComplete || showCountries === countriesStatesEnum.ShowByRegion) {
      returnedValue = (
        <>
          <h4>{t('country')}</h4>
          <div className="horizontal-columns">
            <CountryFilter
              countries={countriesState}
              calledFrom={calledFrom}
              setOptionSelected={setCountrySelected}
              isEdit={isEdit}
            />
          </div>
        </>
      );
    }

    return returnedValue;
  };

  return (
    <Fragment>
      {!hideRegions ? (
        <>
          <h4>{t('region')}</h4>
          <div className="horizontal-columns" style={{ paddingBottom: '25px' }}>
            <RegionFilter
              regions={regionState}
              filterCountriesOnSelect={value => filterCountriesOnSelect(value)}
              calledFrom={calledFrom}
              setOptionSelected={setRegionSelected}
              isEdit={isEdit}
            />
          </div>
        </>
      ) : null}
      {renderShowCountries(showCountries)}
    </Fragment>
  );
};

export default RegionCountryFilter;

const getAllCountriesOptions = regionsState => {
  const countriesOptions: SelectOption[] = [];

  regionsState.forEach(region => {
    region.countries.forEach(country => {
      countriesOptions.push({
        id: country.id,
        label: country.name,
        value: country.name,
        categoryId: country.regionId,
      });
    });
  });

  return countriesOptions;
};

export const filterCountriesOnRegionsSelect = (
  regions: SelectOption[],
  countries: SelectOption[],
  updateStateFn: Function,
  regionsState: any,
) => {
  let countriesOptions: SelectOption[] = [];
  const allCountries = getAllCountriesOptions(regionsState);

  if (regions) {
    if (regions.length >= 1) {
      regions.forEach(region => {
        allCountries.forEach(country => {
          if (region.id === country.categoryId) {
            countriesOptions.push({
              id: country.id,
              label: country.label,
              value: country.label,
              categoryId: country.categoryId,
            });
          }
        });
      });
    } else {
      allCountries.forEach(country => {
        countriesOptions.push({
          id: country.id,
          label: country.label,
          value: country.label,
          categoryId: country.categoryId,
        });
      });
    }
  } else {
    countriesOptions = [...allCountries];
  }

  updateStateFn(sortCountries(countriesOptions));
};

const handleInternalId = internalId => {
  if (internalId === '0_global_bid') {
    return false;
  } else {
    return true;
  }
};

export const handleHideRegions = (calledFrom, menuTabs) => {
  if (calledFrom === CalledFrom.PORTFOLIO) {
    if (
      menuTabs.portfolioSelectedTab.internalId === '0_global_bid' ||
      (menuTabs.portfolioSelectedTab.regions &&
        menuTabs.portfolioSelectedTab.regions.length &&
        menuTabs.portfolioSelectedTab.tabId !== 0)
    ) {
      return false;
    } else {
      return true;
    }
  } else if (
    calledFrom === CalledFrom.CUSTOM_TABS_PORTFOLIO ||
    calledFrom === CalledFrom.CUSTOM_TABS_PROJECT ||
    calledFrom === CalledFrom.ANALYTICS
  ) {
    return false;
  } else if (calledFrom === CalledFrom.EARLY_WARNINGS) {
    return handleInternalId(menuTabs.earlyWarningsSelectedTab.internalId);
  } else {
    return handleInternalId(menuTabs.projectSelectedTab.internalId);
  }
};

const isCalledFromPortfolio = calledFrom => calledFrom === CalledFrom.PORTFOLIO;
const isCalledFromCustomTabsOrAnalytics = calledFrom =>
  calledFrom === CalledFrom.CUSTOM_TABS_PORTFOLIO ||
  calledFrom === CalledFrom.CUSTOM_TABS_PROJECT ||
  calledFrom === CalledFrom.ANALYTICS;

const is0GlobalBidOnProject = menuTabs => menuTabs.projectSelectedTab.internalId === '0_global_bid';
const is0GlobalBidOnEarlyWarnings = menuTabs => menuTabs.earlyWarningsSelectedTab.internalId === '0_global_bid';

const isRegionTabSelectedOnProjects = (menuTabs, calledFrom) =>
  menuTabs.projectSelectedTab.regions &&
  menuTabs.projectSelectedTab.regions.length > 0 &&
  calledFrom === CalledFrom.PROJECT;

const isRegionTabSelectedOnEarlyWarnings = (menuTabs, calledFrom) =>
  menuTabs.earlyWarningsSelectedTab.regions &&
  menuTabs.earlyWarningsSelectedTab.regions.length > 0 &&
  calledFrom === CalledFrom.EARLY_WARNINGS;

const handleShowCountriesForProjectAndEarlyWarnings = (menuTabs, calledFrom) => {
  if (
    (calledFrom === CalledFrom.PROJECT && is0GlobalBidOnProject(menuTabs)) ||
    (calledFrom === CalledFrom.EARLY_WARNINGS && is0GlobalBidOnEarlyWarnings(menuTabs))
  ) {
    return countriesStatesEnum.ShowComplete;
  } else if (
    isRegionTabSelectedOnProjects(menuTabs, calledFrom) ||
    isRegionTabSelectedOnEarlyWarnings(menuTabs, calledFrom)
  ) {
    return countriesStatesEnum.ShowByRegion;
  } else {
    return countriesStatesEnum.Hide;
  }
};

export const handleShowCountries = (calledFrom, menuTabs) => {
  if (isCalledFromPortfolio(calledFrom)) {
    if (
      menuTabs.portfolioSelectedTab.internalId === '0_global_bid' ||
      (menuTabs.portfolioSelectedTab.filters.find(
        filter => filter.field === 'ResponsibleCountryId' && menuTabs.portfolioSelectedTab,
      ) &&
        menuTabs.portfolioSelectedTab.tabId !== 0)
    ) {
      return countriesStatesEnum.ShowComplete;
    } else if (menuTabs.portfolioSelectedTab.regions && menuTabs.portfolioSelectedTab.regions.length > 0) {
      return countriesStatesEnum.ShowByRegion;
    } else {
      return countriesStatesEnum.Hide;
    }
  } else if (isCalledFromCustomTabsOrAnalytics(calledFrom)) {
    return countriesStatesEnum.ShowComplete;
  } else {
    return handleShowCountriesForProjectAndEarlyWarnings(menuTabs, calledFrom);
  }
};

const sortCountries = countriesList => countriesList.sort(sortAlphabetically('value'));

const setInitialStates = ({
  showCountries,

  addValueToCountriesOption,
  setRegionState,
  setCountriesState,
  setAllCountries,
  regionsState,
  calledFrom,
  menuTabs,
}) => {
  if (showCountries === countriesStatesEnum.ShowComplete) {
    const regionsOptions: SelectOption[] = [];
    const countriesOptions: SelectOption[] = [];

    getRegionsAndCountries(calledFrom === CalledFrom.ANALYTICS).then(regions => {
      regions.forEach(region => {
        regionsOptions.push({ id: region.id, label: region.acronym, value: region.acronym });
        region.countries.forEach(country => {
          addValueToCountriesOption(countriesOptions, country);
        });
      });

      setRegionState(regionsOptions);
      setCountriesState(sortCountries(countriesOptions));
      setAllCountries(sortCountries(countriesOptions));
    });
  } else if (showCountries === countriesStatesEnum.ShowByRegion) {
    const countriesOptions: SelectOption[] = [];
    const usedFilter = handleUsedFilter(calledFrom, menuTabs);
    const countriesOfRegion = regionsState.find(region => region.id === usedFilter);

    const tabRegions = handleTabRegion(calledFrom, menuTabs);
    const regionsSelected = regionsState.filter(region => tabRegions.includes(region.id));

    const regionsSelectedOptions = regionsSelected.map(region => ({
      id: region.id,
      label: region.acronym,
      value: region.acronym,
    }));

    setRegionState(regionsSelectedOptions);

    countriesOfRegion.countries.forEach(country => {
      addValueToCountriesOption(countriesOptions, country);
    });

    setCountriesState(sortCountries(countriesOptions));
  }
};

const handleUsedFilter = (calledFrom, menuTabs) => {
  if (calledFrom === CalledFrom.PORTFOLIO) {
    return menuTabs.portfolioSelectedTab.regions[0];
  } else if (calledFrom === CalledFrom.EARLY_WARNINGS) {
    return menuTabs.earlyWarningsSelectedTab.regions[0];
  }
  return menuTabs.projectSelectedTab.regions[0];
};

const handleTabRegion = (calledFrom, menuTabs) => {
  if (calledFrom === CalledFrom.PORTFOLIO) {
    return menuTabs.portfolioSelectedTab.regions;
  } else if (calledFrom === CalledFrom.EARLY_WARNINGS) {
    return menuTabs.earlyWarningsSelectedTab.regions;
  }
  return menuTabs.projectSelectedTab.regions;
};
