import { getRegionsAndCountries } from '../../../api/filterApi';
import { CalledFrom } from '../../../models/CalledFromEnum';
import Country from '../../../models/Country';
import SelectOption from '../../../models/SelectOption';
import { sortAlphabetically } from '../../../services/sortsUtil';
import { showCountryFilterStates } from './models';

/* Method buildShowRegionFilter begin */
const handleInternalId = internalId => {
  if (internalId === '0_global_bid') {
    return false;
  } else {
    return true;
  }
};
export const buildShowRegionFilter = (calledFrom, menuTabs) => {
  if (calledFrom === CalledFrom.PORTFOLIO) {
    if (
      menuTabs.portfolioSelectedTab.internalId === '0_global_bid' ||
      (menuTabs.portfolioSelectedTab.regions &&
        menuTabs.portfolioSelectedTab.regions.length &&
        menuTabs.portfolioSelectedTab.tabId !== 0)
    ) {
      return false;
    } else {
      return true;
    }
  } else if (
    calledFrom === CalledFrom.CUSTOM_TABS_PORTFOLIO ||
    calledFrom === CalledFrom.CUSTOM_TABS_PROJECT ||
    calledFrom === CalledFrom.ANALYTICS
  ) {
    return false;
  } else if (calledFrom === CalledFrom.EARLY_WARNINGS) {
    return handleInternalId(menuTabs.earlyWarningsSelectedTab.internalId);
  } else {
    return handleInternalId(menuTabs.projectSelectedTab.internalId);
  }
};
/* Method buildShowRegionFilter end */

/* Method buildShowCountryFilterState begin */
const isCalledFromPortfolio = calledFrom => calledFrom === CalledFrom.PORTFOLIO;
const isCalledFromCustomTabsOrAnalytics = calledFrom =>
  calledFrom === CalledFrom.CUSTOM_TABS_PORTFOLIO ||
  calledFrom === CalledFrom.CUSTOM_TABS_PROJECT ||
  calledFrom === CalledFrom.ANALYTICS;
const is0GlobalBidOnProject = menuTabs => menuTabs.projectSelectedTab.internalId === '0_global_bid';
const is0GlobalBidOnEarlyWarnings = menuTabs => menuTabs.earlyWarningsSelectedTab.internalId === '0_global_bid';
const isRegionTabSelectedOnProjects = (menuTabs, calledFrom) =>
  menuTabs.projectSelectedTab.regions &&
  menuTabs.projectSelectedTab.regions.length > 0 &&
  calledFrom === CalledFrom.PROJECT;
const isRegionTabSelectedOnEarlyWarnings = (menuTabs, calledFrom) =>
  menuTabs.earlyWarningsSelectedTab.regions &&
  menuTabs.earlyWarningsSelectedTab.regions.length > 0 &&
  calledFrom === CalledFrom.EARLY_WARNINGS;
const handleShowCountriesForProjectAndEarlyWarnings = (menuTabs, calledFrom) => {
  if (
    (calledFrom === CalledFrom.PROJECT && is0GlobalBidOnProject(menuTabs)) ||
    (calledFrom === CalledFrom.EARLY_WARNINGS && is0GlobalBidOnEarlyWarnings(menuTabs))
  ) {
    return showCountryFilterStates.ShowComplete;
  } else if (
    isRegionTabSelectedOnProjects(menuTabs, calledFrom) ||
    isRegionTabSelectedOnEarlyWarnings(menuTabs, calledFrom)
  ) {
    return showCountryFilterStates.ShowByRegion;
  } else {
    return showCountryFilterStates.Hide;
  }
};
export const buildShowCountryFilterState = (calledFrom, menuTabs): showCountryFilterStates => {
  if (isCalledFromPortfolio(calledFrom)) {
    if (
      menuTabs.portfolioSelectedTab.internalId === '0_global_bid' ||
      (menuTabs.portfolioSelectedTab.filters.find(
        filter => filter.field === 'ResponsibleCountryId' && menuTabs.portfolioSelectedTab,
      ) &&
        menuTabs.portfolioSelectedTab.tabId !== 0)
    ) {
      return showCountryFilterStates.ShowComplete;
    } else if (menuTabs.portfolioSelectedTab.regions && menuTabs.portfolioSelectedTab.regions.length > 0) {
      return showCountryFilterStates.ShowByRegion;
    } else {
      return showCountryFilterStates.Hide;
    }
  } else if (isCalledFromCustomTabsOrAnalytics(calledFrom)) {
    return showCountryFilterStates.ShowComplete;
  } else {
    return handleShowCountriesForProjectAndEarlyWarnings(menuTabs, calledFrom);
  }
};
/* Method buildShowCountryFilterState end */

/* Method buildCountriesWhenRegionsChange begin */
export const sortCountries = countriesList => countriesList.sort(sortAlphabetically('value'));

const getAllCountriesOptions = regionsState => {
  const countriesOptions: SelectOption[] = [];

  regionsState.forEach(region => {
    region.countries.forEach(country => {
      countriesOptions.push({
        id: country.id,
        label: country.name,
        value: country.name,
        categoryId: country.regionId,
      });
    });
  });

  return countriesOptions;
};

export const buildCountriesWhenRegionsChange = (regions: SelectOption[], regionsState: any): Country[] => {
  let countriesOptions: SelectOption[] = [];
  const allCountries = getAllCountriesOptions(regionsState);

  if (regions) {
    if (regions.length >= 1) {
      regions.forEach(region => {
        allCountries.forEach(country => {
          if (region.id === country.categoryId) {
            countriesOptions.push({
              id: country.id,
              label: country.label,
              value: country.label,
              categoryId: country.categoryId,
            });
          }
        });
      });
    } else {
      allCountries.forEach(country => {
        countriesOptions.push({
          id: country.id,
          label: country.label,
          value: country.label,
          categoryId: country.categoryId,
        });
      });
    }
  } else {
    countriesOptions = [...allCountries];
  }

  return sortCountries(countriesOptions);
};
/* Method buildCountriesWhenRegionsChange end */

/* Method buildInitialStates begin */
const handleUsedFilter = (calledFrom, menuTabs) => {
  if (calledFrom === CalledFrom.PORTFOLIO) {
    return menuTabs.portfolioSelectedTab.regions[0];
  } else if (calledFrom === CalledFrom.EARLY_WARNINGS) {
    return menuTabs.earlyWarningsSelectedTab.regions[0];
  }
  return menuTabs.projectSelectedTab.regions[0];
};

const handleTabRegion = (calledFrom, menuTabs) => {
  if (calledFrom === CalledFrom.PORTFOLIO) {
    return menuTabs.portfolioSelectedTab.regions;
  } else if (calledFrom === CalledFrom.EARLY_WARNINGS) {
    return menuTabs.earlyWarningsSelectedTab.regions;
  }
  return menuTabs.projectSelectedTab.regions;
};

export const buildInitialStates = ({
  showCountryFilterState,
  addValueToCountriesOption,
  setRegionState,
  setCountriesState,
  setAllCountries,
  regionsState,
  calledFrom,
  menuTabs,
}) => {
  if (showCountryFilterState === showCountryFilterStates.ShowComplete) {
    const regionsOptions: SelectOption[] = [];
    const countriesOptions: SelectOption[] = [];

    getRegionsAndCountries(calledFrom === CalledFrom.ANALYTICS).then(regions => {
      regions.forEach(region => {
        regionsOptions.push({ id: region.id, label: region.acronym, value: region.acronym });
        region.countries.forEach(country => {
          addValueToCountriesOption(countriesOptions, country);
        });
      });

      setRegionState(regionsOptions);
      setCountriesState(sortCountries(countriesOptions));
      setAllCountries(sortCountries(countriesOptions));
    });
  } else if (showCountryFilterState === showCountryFilterStates.ShowByRegion) {
    const countriesOptions: SelectOption[] = [];
    const usedFilter = handleUsedFilter(calledFrom, menuTabs);
    const countriesOfRegion = regionsState.find(region => region.id === usedFilter);

    const tabRegions = handleTabRegion(calledFrom, menuTabs);
    const regionsSelected = regionsState.filter(region => tabRegions.includes(region.id));

    const regionsSelectedOptions = regionsSelected.map(region => ({
      id: region.id,
      label: region.acronym,
      value: region.acronym,
    }));

    setRegionState(regionsSelectedOptions);

    countriesOfRegion.countries.forEach(country => {
      addValueToCountriesOption(countriesOptions, country);
    });

    setCountriesState(sortCountries(countriesOptions));
  }
};
/* Method buildInitialStates end */
