import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { styles } from '../Styles';
import './AccordanceDetail.scss';
import { disableEscKey } from '../Util';
import SaveButton from '../../Buttons/SaveButton/SaveButton';
import CloseCrossButton from '../../Buttons/CloseCrossButton/CloseCrossButton';
import { getRootCauseCategories, updateAccordance } from '../../../../api/accordanceApi';
import { AccordanceState } from '../../../../models/AccordanceState';
import { getIdFromUrl } from '../../../../services/numberUtil';
import { isMobile } from '../../../../services/booleanUtil';
import { getProjectAccordances } from '../../../../redux/actions/currentProject';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BaseModal from '../BaseModal';
import SingleAccordance from './SingleAccordance';
import MultiAccordances from '../../../ProjectDetail/ProjectTabs/Accordances/NewAccordanceForm/MultiAccordances/MultiAccordances';
import Project from '../../../../models/Project';
import { getCategories } from '../../../../api/accordanceApi';
import {
  createPossibleActionsList,
  semesterOptionValue,
} from '../../../ProjectDetail/ProjectTabs/Accordances/NewAccordanceForm/NewAccordanceFormUtil';
import { getSupervisionInfo } from '../../../../api/supervisionApi';
import { SortCriteria } from '../../../ProjectDetail/ProjectTabs/Supervision/util';
import { AccordanceType } from '../../../../models/AccordanceType';
import User from '../../../../models/User';
import SpinningLoader from '../../../ProjectDetail/ProjectTabs/Loader/SpinningLoader';
import { ProjectProductTypes } from '../../../../models/ProjectProductTypes';

type Props = {
  isOpen: boolean;
  closeModal: any;
  accordance: any;
  showJustificationModal: any;
  onApproveHandler: Function;
  showConfirmDelete?: Function;
  changeAccordanceStatus: any;
  fromRevision?: boolean;
  role?: number;
  daysToExpire?: number;
  hideEdit?: boolean;
  changeState?: Function;
  showConfirmDeleteBundle?: Function;
  updateAccordanceInfo?: Function;
};

const AccordanceDetail = (props: Props) => {
  const { isOpen, closeModal, accordance, changeState, updateAccordanceInfo } = props;
  const { t } = useTranslation();

  const yearApplied = useSelector<any, any>(state => state.currentProject.accordancesData.selectedYear);
  const accordanceTypeApplied = useSelector<any, any>(state => state.currentProject.accordancesData.selecetdType);
  const currentProject = useSelector<any, Project>(state => state.currentProject);
  //Added

  const [newExpirationDateValue, setNewExpirationDateValue] = useState<any>(null);
  const [newResponsibleValue, setNewResponsibleValue] = useState<any>(null);
  const [newValue, setNewValue] = useState(false);

  const {
    accordancesGrouped,
    canCreateInterGroupAccordances,
    canCreateMonitoringAccordances,
    canCreatePortfolioReviewAccordances,
    id,
  } = useSelector<any, Project>(state => state.currentProject);
  const [bundleAccordances, setBundleAccordances] = useState<any[]>([]);
  const [possibleActionsList, setPossibleActionsList] = useState<any[]>([]);
  const [supervisionActionList, setSupervisionActionList] = useState<any[]>([]);

  const getData = async () => {
    const categories =
      currentProject.projectProductType !== ProjectProductTypes.Loan
        ? await getCategories(currentProject.projectProductType, currentProject.execution, currentProject.id)
        : await getCategories(undefined, undefined, currentProject.id);

    const category = categories.find(({ id }) => id === accordance?.criticalIssue?.categoryId);
    const possibleActions = category?.criticalIssues.find(
      ({ id }) => id === accordance.criticalIssue.id,
    )?.possibleActions;

    setPossibleActionsList(createPossibleActionsList(possibleActions));
  };

  const updateAcc = async (accor: any) => {
    await updateAccordance(accor);

    if (changeState !== undefined) {
      changeState(true);
    }

    if (updateAccordanceInfo !== undefined) {
      updateAccordanceInfo(true);
    }

    setNewValue(false);

    var id = getIdFromUrl(history.location.search);
    if (Number.isInteger(id)) {
      dispatch(getProjectAccordances(getIdFromUrl(history.location.search), yearApplied, accordanceTypeApplied));
    }

    closeModal();
  };

  const getActivitiesAndCriticalProducts = async () => {
    const info = await getSupervisionInfo(id, null, '', 1, SortCriteria.Default, false, 200);
    const infoOptions =
      info &&
      info.paginatedItems &&
      info.paginatedItems.collection.map(activity => ({
        id: activity.id,
        label: activity.description,
        value: activity.description,
      }));
    setSupervisionActionList(infoOptions);
  };

  useEffect(() => {
    setNewValue(false);
    if (accordance.bundleId && accordance.accordanceState === AccordanceState.DRAFT) {
      getData();
      getActivitiesAndCriticalProducts();
      const grouped = accordancesGrouped?.find(i => i.id === accordance.bundleId);
      setBundleAccordances(
        grouped
          ? grouped.accordances.map(i => {
              i.formErrors = {
                criticalIssue: false,
                responsible: false,
                revisionDate: false,
                expirationDate: false,
                possibleAction: false,
                semesterOption: false,
              };
              i.internalId = i.id;
              i.expirationDate = i.expirationDate ? new Date(i.expirationDate) : null;
              i.possibleAction = i.possibleAction
                ? { ...i.possibleAction, label: i.possibleAction.name, value: i.possibleAction.id?.toString() }
                : null;
              i.category = i.criticalIssue ? i.criticalIssue.name : '';
              i.responsible = i.personInCharge;
              i.revisionDate = i.revisionDate ? new Date(i.revisionDate) : null;
              i.semesterOption =
                i.accordanceType === AccordanceType.InterGroup && i.revisionDate
                  ? semesterOptionValue(i.revisionDate, t)
                  : null;
              return i;
            })
          : [],
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accordance, accordancesGrouped]);

  const dispatch = useDispatch();
  const history = useHistory();

  const onNewValue = (value: boolean) => {
    setNewValue(value);
  };

  const onNewExpirationDateValue = (data: Date) => {
    setNewExpirationDateValue(data);
  };

  const OnNewResponsibleValue = (data: User) => {
    setNewResponsibleValue(data);
    setNewValue(true);
  };

  const submitAccordance = async acc => {
    if (
      acc.criticalIssue !== null &&
      acc.personInCharge !== null &&
      acc.expirationDate !== null
      // && acc.possibleAction !== null
    ) {
      const data = {
        accordanceId: acc.id,
        projectId: acc.project.id,
        description: acc.description,
        revisionDate: acc.accordanceType !== AccordanceType.Monitoring ? acc.revisionDate : null,
        expirationDate: acc.expirationDate,
        newExpirationDate: newExpirationDateValue !== null ? newExpirationDateValue : acc.newExpirationDate,
        accordanceType: acc.accordanceType,
        criticalIssueId: acc.criticalIssue.id,
        personInChargeId: newResponsibleValue ? newResponsibleValue.id : acc.personInCharge.id,
        possibleActionId: acc?.possibleAction?.id,
        CountNewExpirationDate: acc.countNewExpirationDate != null ? acc.countNewExpirationDate : null,
        files: acc.accordanceFiles,
      };

      await updateAcc(data);
    }
  };

  return (
    <>
      <BaseModal
        isOpen={isOpen}
        shouldCloseOnOverlayClick={false}
        onRequestClose={e => disableEscKey(e, closeModal)}
        style={{ overlay: { ...styles.overlay, ...{ overflowY: 'auto' } } }}
        className={`modal-accordance-detail-content ${
          accordance.accordanceState === AccordanceState.REJECT ? 'reject' : ''
        }`}
      >
        {accordance.bundleId && accordance.accordanceState === AccordanceState.DRAFT ? (
          <div className="new-accordance-form-container">
            <div className="close-cross-container">
              <CloseCrossButton handleClick={closeModal} />
            </div>
            {accordance.accordanceState === AccordanceState.DRAFT ? (
              <MultiAccordances
                bundleAccordances={bundleAccordances}
                possibleActionsList={possibleActionsList}
                accordanceFormType={accordance.accordanceType}
                supervisionActionList={supervisionActionList}
                closeModal={closeModal}
                canCreateInterGroupAccordances={canCreateInterGroupAccordances}
                canCreateMonitoringAccordances={canCreateMonitoringAccordances}
                canCreatePortfolioReviewAccordances={canCreatePortfolioReviewAccordances}
                bundleId={accordance.bundleId}
                showConfirmDelete={props.showConfirmDeleteBundle}
              />
            ) : (
              <SpinningLoader customText={' '} />
            )}
          </div>
        ) : (
          <SingleAccordance
            {...props}
            onNewValue={event => onNewValue(event)}
            onNewExpirationDateValue={event => onNewExpirationDateValue(event)}
            OnNewResponsibleValue={event => OnNewResponsibleValue(event)}
            newValue={newValue}
            submitAccordance={submitAccordance}
          />
        )}
        {/* {showEditButton(accordance, newValue, submitAccordance, t)} */}
      </BaseModal>
      {renderStickyFooter(isOpen, closeModal, t)}
    </>
  );
};

export default AccordanceDetail;

const renderStickyFooter = (isOpen, closeModal, t) => {
  return (
    isMobile() && (
      <div className={isOpen ? 'sticky-footer open' : 'sticky-footer'}>
        <SaveButton
          handleClick={() => closeModal()}
          customText={t('close')}
          customStyle={{ padding: '0px 40px', marginTop: 10 }}
        />
      </div>
    )
  );
};

export const showEditButton = (accordance, hasNewValue, submitAccordance, t) =>
  accordance &&
  hasNewValue &&
  accordance.currentUserCanEdit &&
  accordance.accordanceState !== AccordanceState.FULLFILLED ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'spaceEvenly',
        alignItems: 'center',
        // padding: '0px 40px',
      }}
    >
      <SaveButton handleClick={() => submitAccordance(accordance)} customText={t('save')} />
    </div>
  ) : null;
