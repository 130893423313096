import ComponentBalances from '../../../../../models/ComponentBalances';

const externalPaddings = 50 + 96;
const columnPadding = 90;
const columnPercentage = 0.37;
const extraMargin = 20;
const containerWidth = (window.innerWidth - externalPaddings) * columnPercentage - columnPadding;
export const maxWidthPixels = containerWidth * 0.65 - extraMargin;
export const labelWidth = containerWidth * 0.35;
const minWidth = 20;
const minBarIndicatorWidth = 10;
const minBarPercentage = 1;
const maxWidthPixelsReport = 400;

export const withoutDataIndicators = -1;

export const customChartHeight = (data: any) => {
  return data.length ? data.length * 50 : 50;
};

export const widthVariation = (total: number, maxValue: number, idForReport?: string) => {
  const maxPixels = idForReport ? maxWidthPixelsReport : maxWidthPixels;
  const withoutData = !total && !maxValue;
  let barWidth = (total * maxPixels) / maxValue;
  if (barWidth < minWidth) {
    barWidth = minWidth;
  } else if (withoutData) {
    barWidth = 0;
  }
  return barWidth;
};

export const barIndicatorWidth = (width, percent) => {
  const indicatorWidth = (width * percent) / 100;
  if (percent) {
    return indicatorWidth < minBarIndicatorWidth ? minBarIndicatorWidth : indicatorWidth;
  } else {
    return 0;
  }
};

export const percentageVariation = (mmsDisbursedAmount: number, total: number) => {
  let barPercentage = (mmsDisbursedAmount / total) * 100;
  const withoutDisbursedAmount = !mmsDisbursedAmount && total;
  const withoutData = !mmsDisbursedAmount && !total;
  const LessThatTotal = barPercentage < minBarPercentage;

  if (withoutDisbursedAmount) {
    barPercentage = 0;
  } else if (withoutData) {
    barPercentage = withoutDataIndicators;
  } else if (LessThatTotal) {
    barPercentage = minBarPercentage;
  }

  return barPercentage;
};
export const addProgressAndWidthToData = (list: ComponentBalances[], idForReport?: string) => {
  const listWithTotal = list.map(item => {
    item.mmsAvailableAmount = item.availableAmount / 1000000;
    item.mmsDisbursedAmount = item.disbursedAmount / 1000000;

    item.total = item.mmsAvailableAmount + item.mmsDisbursedAmount;
    return item;
  });
  const maxValue = Math.max(...listWithTotal.map(i => i.total));

  const result = listWithTotal.map(item => {
    item.width = widthVariation(item.total, maxValue, idForReport);
    item.percent = percentageVariation(item.mmsDisbursedAmount, item.total);
    return item;
  });
  return { list: result, maxValue };
};
