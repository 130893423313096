import React from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

import { CalledFrom } from '../../../models/CalledFromEnum';
import { PmrClassification } from '../../../models/PmrClassification';
import { TrackedActions } from '../../../models/TrackedActionsEnum';
import useFilters from '../../../hooks/useFilters';
import { multiSelectStyle, DropdownIndicator } from '../FilterComponent.Styles';
import { trackActionClicked } from '../../../api/trackActionClickedApi';
import { buildPmrIdInitial, buildPmrOptions } from './utils';

type Props = {
  indicatorCall: boolean;
  calledFrom: CalledFrom;
};
export const PmrFilter = (props: Props) => {
  const { indicatorCall, calledFrom } = props;
  const { t } = useTranslation();
  const {
    dispatch,
    filter,
    filterIndicators,
    filterEarlyWarnings,
    setFilter,
    setFilterIndicators,
    setFilterEarlyWarnings,
  } = useFilters();
  const pmrOptions = buildPmrOptions(t, PmrClassification);
  const pmrIdInitial = buildPmrIdInitial({
    calledFrom,
    indicatorCall,
    filterIndicators,
    filter,
    filterEarlyWarnings,
  });

  const handleChange = value => {
    let pmrIdAux = [...pmrIdInitial];
    value.forEach((el, i) => {
      let foundIndex = 0;
      foundIndex = pmrIdAux.findIndex(pmr => pmr.PmrClassification === el.value);
      pmrIdAux[foundIndex] = {
        ...pmrIdAux[foundIndex],
        selected: true,
      };

      if (calledFrom === CalledFrom.EARLY_WARNINGS) {
        dispatch(
          setFilterEarlyWarnings({
            ...filterEarlyWarnings,
            common: {
              ...filterEarlyWarnings.common,
              pmrId: pmrIdAux,
            },
          }),
        );
        trackActionClicked(TrackedActions.pmrFilterChangedFromEarlyWarnings);
      } else {
        if (indicatorCall) {
          dispatch(
            setFilterIndicators({
              ...filterIndicators,
              common: {
                ...filterIndicators.common,
                pmrId: pmrIdAux,
              },
            }),
          );
          trackActionClicked(TrackedActions.pmrFilterChangedFromPortfolio);
        } else {
          dispatch(
            setFilter({
              ...filter,
              common: {
                ...filter.common,
                pmrId: pmrIdAux,
                itSearch: true,
              },
            }),
          );
          trackActionClicked(TrackedActions.pmrFilterChangedFromProject);
        }
      }
    });
  };



  return (
    <Select
      id="pmrSelect"
      defaultValue={filterIndicators.common.regionId}
      isMulti
      components={{ DropdownIndicator }}
      name="countries"
      options={pmrOptions}
      className="basic-multi-select"
      classNamePrefix="All"
      placeholder={t('all')}
      onChange={value => handleChange(value)}
      styles={multiSelectStyle}
    />
  );
};

export default PmrFilter;
