import FilterAnalytics from '../../models/FilterAnalytics';
import { analyticsDataPeriods } from '../../models/AnalyticsDataPeriods';
import { filterAnalyticsActionTypes, SET_FILTER_ANALYTICS } from '../actions/types';

const initialState: FilterAnalytics = {
    regionId: [],
    countryId: [],
    divisionId: [],
    roleId: [],
    dataPeriod: analyticsDataPeriods[0],
}

const filterAnalyticsReducer = (state = initialState, action: filterAnalyticsActionTypes): any => {

    if (action.type === SET_FILTER_ANALYTICS) {
        return action.payload;
    }

    return state;
}

export default filterAnalyticsReducer;
