import { IconColorsEnum } from './EarlyWarningsSection';
import { ProjectProductTypes } from './ProjectProductTypes';
export enum OperativeAlertsEnum {
  PendingSignature = 1,
  ExpiredProject = 2,
  EsgSafeguard = 3,
  EligibleUnDisbursement = 4,
  CancelledAmount = 5,
  LastThreePMR = 6,
}
interface AlertDetail {
  alertName: OperativeAlertsEnum;
  alertDetailText: string;
  alertLight: IconColorsEnum | null;
}

export interface LinkedActionsModal {
  projectId: number;
  projectCode: string;
  projectName: string;
  alertDetail: AlertDetail;
  projectProductType: ProjectProductTypes;
}

export const DEFAULT_LINKED_MODAL_INFORMATION: LinkedActionsModal = {
  projectId: 0,
  projectCode: '',
  projectName: '',
  alertDetail: {
    alertName: OperativeAlertsEnum.PendingSignature,
    alertDetailText: '',
    alertLight: IconColorsEnum.FILLED_RED,
  },
  projectProductType: ProjectProductTypes.Loan,
};
