import { useEffect, useState } from 'react'
import { getMemoryHelpAgreements } from '../api/projectApi';
import { AgreementData } from '../components/MemoryHelp/models';

const useAgreementsData = (agreementId: number | null, trigger?: boolean) => {
    const [agreementsData, setAgreementsData] = useState<AgreementData | any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        if (agreementId === null) {
            setAgreementsData({ status: "denied", dataRevision: null, revisionHelpMemory: null });
            setLoading(false);
            setError(new Error('agreementId is null'));
            return;
        }

        const fetchAgreements = async () => {
            setLoading(true);
            try {
                const data = await getMemoryHelpAgreements(agreementId as number);
                setAgreementsData(data);
                setError(null);
            } catch (err) {
                console.log('Error al obtener los datos del acuerdo en memory help:', err);
                setError(err as Error);
            } finally {
                setLoading(false);
            }
        };

        if (agreementId) fetchAgreements();
    }, [agreementId, trigger]);

    return { agreementsData, loading, error };
};

export default useAgreementsData;