import moment from 'moment';
import { ReviewNavigationActionTypes, SET_REVIEW_NAVIGATION, RESET_REVIEW_NAVIGATION } from '../actions/types';

const initialState = {
  reviewTabValue: 'upcoming',
  panel: 0,
  isExpanded: false,
  year: moment().year(),
};

const reviewNavigationReducer = (state = initialState, action: ReviewNavigationActionTypes): object => {
  switch (action.type) {
    case SET_REVIEW_NAVIGATION:
      return action.payload;

    case RESET_REVIEW_NAVIGATION:
      return initialState;
    default:
      return state;
  }
};

export default reviewNavigationReducer;
