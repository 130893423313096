import React from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './CustomCalendar.scss';
import { useTranslation } from 'react-i18next';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import CustomToolbar from './CustomToolbar/CustomToolbar';
import CustomEvent from './CustomEvent/CustomEvent';

const localizer = momentLocalizer(moment);

type Props = {
  eventsList?: any[];
  isLoader?: boolean;
};

const CustomCalendar = (props: Props) => {
  const { eventsList, isLoader } = props;

  const { t } = useTranslation();
  return (
    <div className={`custom-calendar-container ${isLoader && 'loader'}`}>
      <div className="ring-2">
        <div className="ball-holder">
          <div className="ball"></div>
        </div>
      </div>
      <Calendar
        popup
        selectable
        localizer={localizer}
        startAccessor={'start'}
        endAccessor={'end'}
        style={{ height: 486 }}
        components={{ toolbar: CustomToolbar, event: CustomEvent }}
        events={eventsList}
        formats={{
          weekdayFormat: date => localizer.format(date, 'ddd').substr(0, 3).toUpperCase(),
        }}
        culture={'en'}
        onSelectSlot={e => console.log(e)}
        onDrillDown={e => console.log(e)}
        onSelectEvent={e => console.log(e)}
        messages={{ showMore: total => `+ ${t('more_events')}` }}
        defaultDate={new Date()}
        defaultView="month"
      />
    </div>
  );
};

export default CustomCalendar;
