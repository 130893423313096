import React from 'react';
import styles from './SpinningLoader.module.scss';
import { ReactComponent as LoadingIcon } from '../../../../assets/loading-spinner-2.svg';
import { useTranslation } from 'react-i18next';

type Props = {
  customStyle?: any;
  customText?: string;
};

const SpinningLoader = ({ customStyle = {}, customText }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.loaderContainer} style={customStyle}>
      <LoadingIcon className={styles.loadingIcon}></LoadingIcon>
      <span className={styles.loaderText}>{t(customText || 'loading_information')}</span>
    </div>
  );
};

export default SpinningLoader;
