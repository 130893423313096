import { EarlyWarningType } from './../../../models/EarlyWarnings';
const hasLargeTexts = groupedResponse => {
  if (!groupedResponse) {
    return false;
  }
  return Array.from(Object.keys(groupedResponse)).find(
    a =>
      a === EarlyWarningType.PROJECTS_WITH_RECENT_ALERT_OR_PROBLEM_CLASSIFICATIONS.toString() ||
      a === EarlyWarningType.ELIGIBLE_PROJECTS_PENDING_FIRST_DISBURSEMENT.toString() ||
      a === EarlyWarningType.PROJECTS_WITH_UNSATISFACTORY_PERFORMANCE_IN_SAFEGUARDS.toString(),
  )
    ? true
    : false;
};

export { hasLargeTexts };
