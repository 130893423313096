import { IndicatorKeyEnum } from '../../../../../../models/IndicatorKeyEnum';
import { isMobile } from '../../../../../../services/booleanUtil';

const setUlStyle = (contentManager, modalChart, listPage) => {
  if (contentManager) {
    return modalChart ? { columns: 2 } : {};
  } else {
    return listPage ? { display: 'flex' } : { borderBottom: '1px solid #E4E4E4', display: 'flex' };
  }
};

const setLiStyle = (contentManager, backgroundColorId, ind, label) =>
  contentManager ? `_${backgroundColorId[ind]}` : label;

const renderProjectWord = (indicatorKey, qty, listPage, t) => {
  if ((indicatorKey === IndicatorKeyEnum.ESG_ENVIRONMENTAL_AND_SOCIAL_RISK_RATING || !listPage) && !isMobile()) {
    if (qty > 1) {
      return t('projects');
    } else {
      return t('project');
    }
  } else {
    return '';
  }
};

const returnSelectedConcept = (indicatorKey, indexValue, label) => {
  return {
    datasetLabel: getStackedName(indicatorKey, label),
    label: '',
    dataIndex: getMappedChartIndex(indicatorKey, indexValue),
  };
};

const getMappedChartIndex = (indicatorKey, indexCtx) => {
  if (IndicatorKeyEnum.ESG_SAFEGUARD_PERFORMANCE_RATING === indicatorKey) {
    switch (indexCtx) {
      case 0:
      case 1:
        return 0;
      case 2:
      case 3:
        return 1;
      case 4:
        return 2;
      default:
        return 0;
    }
  } else {
    return indexCtx;
  }
};

const getStackedName = (indicatorKey, label) => {
  if (IndicatorKeyEnum.ESG_SAFEGUARD_PERFORMANCE_RATING === indicatorKey) {
    if (label === 'satisfactory' || label === 'unsatisfactory' || label === 'not_assigned') {
      return 'firstStack';
    } else {
      return 'secondStack';
    }
  } else {
    return 'firstStack';
  }
};

const setLabelOnDetailPage = (label, t) => (label === 'not_assigned' ? t('not_assigned_exp_not_set') : t(label));

export { setUlStyle, setLiStyle, renderProjectWord, returnSelectedConcept, setLabelOnDetailPage };
