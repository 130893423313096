import React from 'react';
import { SearchRender } from '../SearchRender/SearchRender';
import { trackActionClicked } from '../../../../api/trackActionClickedApi';
import { TrackedActions } from '../../../../models/TrackedActionsEnum';
import useFilters from '../../../../hooks/useFilters';

interface IProps {
  itsFollowed: boolean;
}

type Props = IProps;

const SearchInput = (props: Props) => {
  const { itsFollowed } = props;
  const { filter, dispatch, setFilter } = useFilters();
  const [valueSearched, setValueSearched] = React.useState(filter.allProjectsFilter.searchValue);

  React.useEffect(() => {
    if (filter.common.itSearch) {
      setValueSearched(filter.allProjectsFilter.searchValue);
    } else {
      setValueSearched('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.common.itSearch]);

  const onChangeHandler = (event: any) => {
    const maxCharacter = event.target.value.length;
    const searchValue = event.target.value;

    const setItSearch = () => {
      return {
        ...filter.common,
        itSearch: maxCharacter === 0 ? false : true,
      };
    };

    if (itsFollowed) {
      dispatch(
        setFilter({
          ...filter,
          common: setItSearch(),
          followedProjectsFilter: {
            ...filter.followedProjectsFilter,
            searchValue,
          },
        }),
      );
    } else {
      dispatch(
        setFilter({
          ...filter,
          common: setItSearch(),
          allProjectsFilter: {
            ...filter.allProjectsFilter,
            searchValue,
          },
        }),
      );
    }
    trackActionClicked(TrackedActions.searchProject);
  };

  return (
    <SearchRender placeholder="searchPlaceholder" onChangeHandler={onChangeHandler} valueSearched={valueSearched} />
  );
};

export default SearchInput;
