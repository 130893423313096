import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ButtonWithIcon.module.scss';
import { ReactComponent as IconReport } from '../../../../assets/icons/u_reports.svg';

type Props = {
  handleClick?: any;
  iconClass: string;
  textButton: string;
};

const { buttonWithIconContainer, spreadsheet, buttonElements } = styles;
const ButtonWithIcon = ({ iconClass, handleClick, textButton }: Props) => {
  const { t } = useTranslation();
  const allowedIcons = {
    spreadsheet,
  };
  return (
    <>
      <button className="btn-primary-outlined" onClick={handleClick} disabled={false}>
          <IconReport className="icon" />
          <span>{t(textButton)}</span>
      </button>
    </>
  );
};

export default ButtonWithIcon;
