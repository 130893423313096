import { axiosDefaultConfig } from './axiosConfig';
import { store } from '../../src/redux/store';
import { updateCurrentProject } from '../redux/actions/currentProject';
import * as config from './config.json';
import {buildGet, buildPut, buildPost} from './genericMethods';

const idReplacement = '{id}';

const buildDelete = (url: string) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const response = await axiosDefaultConfig.delete(url);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getKeyProducts = (id: number) => {
  const url = config.KEY_PRODUCTS.replace(idReplacement, id.toString());
  return buildGet(url);
};

export const getKeyProductsList = (id: number) => {
  const url = config.KEY_PRODUCTS_LIST.replace(idReplacement, id.toString());
  return buildGet(url);
};

export const getKeyProductById = (id: number) => {
  const url = config.KEY_PORDUCT_ID.replace(idReplacement, id.toString());
  return buildGet(url);
};

export const newKeyProduct = (id: number) => {
  const url = config.KEY_PORDUCT_ID.replace(idReplacement, id.toString());
  return buildPost(url, null);
};

export const removeKeyProduct = (id: number) => {
  const url = config.KEY_PORDUCT_ID.replace(idReplacement, id.toString());
  return buildDelete(url);
};

export const newAcquisition = (data: any) => {
  return buildPost(config.ACQUISITION_BASE, data);
};

export const removeAcquisition = (data: any) => {
  const url = config.AQCUISITION.replace(idReplacement, data.id.toString());
  return buildDelete(url);
};

export const editAcquisition = (data: any) => {
  return buildPut(config.ACQUISITION_BASE, data);
};

export const getAcquisitionById = (data: any) => {
  const url = config.AQCUISITION.replace(idReplacement, data.id.toString());
  return buildGet(url);
};

export const removeActivity = (data: any) => {
  const url = config.ACTIVITY_DELETE.replace(idReplacement, data.id.toString());
  return buildDelete(url);
};

export const newActivity = (data: any) => {
  return buildPost(config.ACTIVITY_POST, data);
};

export const editActivity = (data: any) => {
  return buildPut(config.ACTIVITY_POST, data);
};

const updateKeyPorductNewComment = (kp, acquisitionId, activityId, response) => {
  return kp.acquisitions?.map(item => {
    if (item.id === acquisitionId) {
      item.activities = item.activities?.map(act => {
        if (act.id === activityId) {
          if (act.comments) {
            act.comments = [...act.comments, response.data];
          } else {
            act.comments = [response.data];
          }
        }
        return act;
      });
    }
    return item;
  });
};

export const newComment = (activityId: number, acquisitionId: number, comment: string, keyProductId: number) => {
  const url = config.ACTIVITY_COMMENT.replace(idReplacement, activityId.toString());
  const data = { comment };

  return new Promise<any>(async (resolve, reject) => {
    try {
      const response = await axiosDefaultConfig.post(url, data);
      store.dispatch(
        updateCurrentProject({
          ...store.getState().currentProject,
          keyProducts: store.getState().currentProject.keyProducts?.map(kp => {
            if (kp.id === keyProductId) {
              kp.acquisitions = updateKeyPorductNewComment(kp, acquisitionId, activityId, response);
            }
            return kp;
          }),
        }),
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

const updateKeyPorductDeleteComment = (kp, acquisitionId, activityId, commentId) => {
  return kp.acquisitions?.map(item => {
    if (item.id === acquisitionId) {
      item.activities = item.activities?.map(act => {
        if (act.id === activityId) {
          act.comments = act.comments.map(c => {
            if (c.id === commentId) {
              c.isDeleted = true;
              c.comment = '';
            }
            return c;
          });
        }
        return act;
      });
    }
    return item;
  });
};

export const deleteComment = (activityId: number, acquisitionId: number, commentId: number, keyProductId: number) => {
  const url = config.ACTIVITY_COMMENT.replace(idReplacement, commentId.toString());
  return new Promise<any>(async (resolve, reject) => {
    try {
      const response = await axiosDefaultConfig.delete(url);
      store.dispatch(
        updateCurrentProject({
          ...store.getState().currentProject,
          keyProducts: store.getState().currentProject.keyProducts?.map(kp => {
            if (kp.id === keyProductId) {
              kp.acquisitions = updateKeyPorductDeleteComment(kp, acquisitionId, activityId, commentId);
            }
            return kp;
          }),
        }),
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};
