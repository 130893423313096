import Indicator from '../../models/Indicator';
import {
  addDistributionAccordingToLatestEfas,
  addEsgAccordingEnvironmentalAndSocialCategory,
  addPmrStateDistribution,
  addCTPerformceRating,
  addClassificationOfAcquisitionPlans
} from './IndicatorsHelpers/doughnutsIndicatorsHelper';
import {
  addAccordancesApprove,
  addAccordancesExpire,
  addExtendedProj,
  addNoDisb180,
  addPendingElegibility,
  addPlannedDisb,
  addProjectsKnowledgeProducts,
  addProjectsWithMICI,
  addProjectsWithTansbudgetDeficit,
  addProjectsInPreparation,
  addProjectsElegibleFirstUndisbursement,
  addPmrAlertProblemLast3Years,
  addProjectsWithPartialCancellations,
} from './IndicatorsHelpers/textIndicatorsHelper';
import { addAverageLag, addTransactionalResourcesSupervision } from './IndicatorsHelpers/radialsIndicatorsHelper';
import {
  addCountries,
  addDisbProjMonthly,
  addDivisions,
  addDivisionsPMR,
  addEsgEnvironmentalAndSocialRiskRatingESRR,
  addEsgSafeGuardPerformaceRatingSPR,
  addPortfolioOverviewLast5Years,
  addSupervisionPlanPlannedVersusExecutedByMonth,
} from './IndicatorsHelpers/barchartsIndicatorsHelper';
import {
  addAnnualProjDisb,
  addGcmAlerts,
  addPcrNextPresentation,
  addPmrValidationState,
} from './IndicatorsHelpers/progressIndicatorsHelper';
import {
  addPortfolioSummary,
  addUserPendingTasks,
  addConsultanciesFinancedAndExecutedByTheBank,
} from './IndicatorsHelpers/summariesIndicatorsHelper';
import { addCalendarIndicator } from './IndicatorsHelpers/calendarIndicatorsHelper';
import { addDisbursementTime } from './IndicatorsHelpers/bubbleChartHelper';
import {
  addPendingSignature,
  addProjectsWhereLastDisbursementDateIsClose12MonthOrExpired,
  addAcquisitionWithMilestones
} from './IndicatorsHelpers/textDoubleIndicatorsHelper';
import { addDisbursementProjectionLast5Years } from './IndicatorsHelpers/mixedchartsIndicatorsHelper';
import { addPmrEvolutionLast5Years } from './IndicatorsHelpers/multiLineIndicatorHelper';

const prepareIndicatorsList = (indicatorsList, isRecommend = false) => {
  if (isRecommend) {
    return indicatorsList.map(indicator => ({ ...indicator.indicatorData }));
  }
  return indicatorsList.indicatorsData.map((item, index) => ({ ...item, order: index }));
};

export const processIndicators = (indicatorsList, indicatorOptions = {}) => {
  const indicators = prepareIndicatorsList(indicatorsList);
  const cardIndicators: Indicator[] = [];
  addIndicators(indicators, cardIndicators, indicatorOptions);

  return cardIndicators.sort((a, b) =>
    a.order !== undefined && b.order !== undefined && a.order > b.order ? 1 : -1,
  );
};

export const processIndicatorsRecommend = (indicatorsList, indicatorOptions = {}) => {
  const indicators = prepareIndicatorsList(indicatorsList, true);
  const cardIndicators: Indicator[] = [];
  addIndicators(indicators, cardIndicators, indicatorOptions);

  return cardIndicators.sort((a, b) =>
    a.order !== undefined && b.order !== undefined && a.order > b.order ? 1 : -1,
  );
};

const addIndicators = (indicators, cardIndicators, indicatorOptions) => {
  addDisbProjMonthly(indicators, cardIndicators);
  addPendingSignature(indicators, cardIndicators);
  addPendingElegibility(indicators, cardIndicators);
  addNoDisb180(indicators, cardIndicators);
  addExtendedProj(indicators, cardIndicators);
  addPlannedDisb(indicators, cardIndicators);
  addAnnualProjDisb(indicators, cardIndicators);
  addDivisions(indicators, cardIndicators);
  addDivisionsPMR(indicators, cardIndicators);
  addAccordancesExpire(indicators, cardIndicators);
  addAccordancesApprove(indicators, cardIndicators);
  addProjectsWithMICI(indicators, cardIndicators);
  addProjectsWithTansbudgetDeficit(indicators, cardIndicators);
  addTransactionalResourcesSupervision(indicators, cardIndicators);
  addPortfolioSummary(indicators, cardIndicators, indicatorOptions);
  addProjectsWhereLastDisbursementDateIsClose12MonthOrExpired(indicators, cardIndicators);
  addPmrStateDistribution(indicators, cardIndicators);
  addUserPendingTasks(indicators, cardIndicators);
  addDistributionAccordingToLatestEfas(indicators, cardIndicators);
  addEsgAccordingEnvironmentalAndSocialCategory(indicators, cardIndicators);
  addEsgSafeGuardPerformaceRatingSPR(indicators, cardIndicators);
  addEsgEnvironmentalAndSocialRiskRatingESRR(indicators, cardIndicators);
  addProjectsKnowledgeProducts(indicators, cardIndicators);
  addCalendarIndicator(indicators, cardIndicators, indicatorOptions);
  addAverageLag(indicators, cardIndicators);
  addSupervisionPlanPlannedVersusExecutedByMonth(indicators, cardIndicators);
  addGcmAlerts(indicators, cardIndicators);
  addDisbursementTime(indicators, cardIndicators, indicatorOptions);
  addProjectsInPreparation(indicators, cardIndicators);
  addCTPerformceRating(indicators, cardIndicators);
  addConsultanciesFinancedAndExecutedByTheBank(indicators, cardIndicators);
  addDisbursementProjectionLast5Years(indicators, cardIndicators);
  addPmrValidationState(indicators, cardIndicators);
  addPortfolioOverviewLast5Years(indicators, cardIndicators);
  addCountries(indicators, cardIndicators);
  addPmrEvolutionLast5Years(indicators, cardIndicators);
  addPcrNextPresentation(indicators, cardIndicators);
  addPmrAlertProblemLast3Years(indicators, cardIndicators);
  addProjectsElegibleFirstUndisbursement(indicators, cardIndicators);
  addProjectsWithPartialCancellations(indicators, cardIndicators);
  addAcquisitionWithMilestones(indicators, cardIndicators);
  addClassificationOfAcquisitionPlans(indicators, cardIndicators);
};
