import React, { useState } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import { styles } from '../Styles';
import './CreateAgenda.scss';
import SaveButton from '../../Buttons/SaveButton/SaveButton';
import { useTranslation } from 'react-i18next';
import { disableEscKey } from '../Util';
import CloseCrossButton from '../../Buttons/CloseCrossButton/CloseCrossButton';
import CustomSelect from '../../CustomSelect/CustomSelect';
import { TimeAssignedEnum } from '../../../../models/TimeAssignedEnum';
import { DropdownIndicator } from '../../../Filter/FilterComponent.Styles';
import { tooltipTitle } from '../../../../services/stringUtil';
import { isMobile } from '../../../../services/booleanUtil';
import { BidTooltipBlueStyles } from '../../Tooltip/Tooltip';
import {
  sortByDivision,
  sortByExecutor,
  sortByTeamLeader,
  groupProjectsAndAssignDateTime,
} from '../../../Reviews/AgendaOrderProjects/agendaOrderProjects';
import { editAgendaTime } from '../../../../api/reviewsApi';
import { setCurrentReview } from '../../../../redux/actions/currentReview';
import { exceededProjectCapacity, updateProjectsTimes } from '../../../Reviews/Agenda/util';
import ScheduleWarning from '../ScheduleWarning/ScheduleWarning';
import BaseModal from '../BaseModal';
import { ReactComponent as IconCalendar } from '../../../../assets/icons/u_calendar-alt.svg';

type Props = {
  revisionTitle?: any;
};

const CreateAgenda = (props: Props) => {
  const { revisionTitle } = props;
  const dispatch = useDispatch();
  const currentReview = useSelector<any, any>(state => state.currentReview);
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [timeAllocated, setTimeAllocated] = useState<any>(timeAllocatedForEachProjectOptions(t)[1]);
  const [priorityToGroup, setPriorityToGroup] = useState<any>(priorityToGroupProjectsOptions(t)[0]);
  const [showWarning, setShowWarning] = useState(false);

  const handleTimeAllocatedChange = item => {
    setTimeAllocated(item);
  };

  const handleAssignSchedules = async (timeAllocated, priorityToGroup) => {
    const criteriaToSort: any[] = [];
    priorityToGroup.map(criteria => criteriaToSort.push(criteria.sorting));
    const sortedAndTimeAssignedProjects = groupProjectsAndAssignDateTime(currentReview, criteriaToSort, timeAllocated);
    await editAgendaTime(currentReview.id, sortedAndTimeAssignedProjects);
    const newCurrentReview = cloneDeep(currentReview);
    newCurrentReview.projects = updateProjectsTimes(currentReview.projects, sortedAndTimeAssignedProjects);
    dispatch(setCurrentReview(newCurrentReview));
    setIsOpen(false);
  };

  const handleSubmit = newTimeAllocated => {
    const allocated = !isNaN(newTimeAllocated)
      ? timeAllocatedForEachProjectOptions(t).find(a => a.value === newTimeAllocated.toString())
      : timeAllocated;

    if (exceededProjectCapacity(currentReview, parseInt(allocated.value))) {
      setIsOpen(false);
      setShowWarning(true);
    } else {
      if (!Array.isArray(priorityToGroup)) {
        handleAssignSchedules(allocated, [priorityToGroup]);
      } else {
        handleAssignSchedules(allocated, priorityToGroup);
      }
    }
  };

  const handleChangePriority = item => {
    if (item === null) {
      setPriorityToGroup(priorityToGroupProjectsOptions(t));
    } else {
      setPriorityToGroup(item);
    }
  };

  const rescheduleWarning = value => {
    setShowWarning(false);
    handleSubmit(value);
  };

  return (
    <>
      <BidTooltipBlueStyles
        title={tooltipTitle(t, 'schedule_projects')}
        placement="bottom"
        enterDelay={500}
        leaveDelay={200}
        arrow
      >
        <span onClick={() => setIsOpen(true)} className="agenda-button"><IconCalendar style={{ fill: 'currentColor' }} /> Crear Agenda</span>
      </BidTooltipBlueStyles>

      <BaseModal
        isOpen={isOpen}
        onRequestClose={e => disableEscKey(e, () => setIsOpen(false))}
        style={styles}
        className="modal-create-agenda-content"
      >
        <div className="modal-report-project-config-container">
          <div className="close-cross-container">
            <CloseCrossButton
              handleClick={() => {
                setIsOpen(false);
              }}
            />
          </div>
          <div className="horizontal">
            <div className="title">
              <h3>{t('create_agenda')}</h3>
            </div>
            <div className="title">
              <h3 className="thin">{revisionTitle}</h3>
            </div>
          </div>
          <div className='content'>
            <div className="horizontal">
              <div className="label">{t('time_allocated_for_each_project')}</div>
              <div className="select">
                <CustomSelect
                  options={timeAllocatedForEachProjectOptions(t)}
                  handleSelected={handleTimeAllocatedChange}
                  selectedOption={timeAllocated}
                  type={'review-list-white'}
                />
              </div>
            </div>
            <div className="horizontal">
              <div className="label"> {t('priority_to_group_projects')}</div>
              <div className="select">
                <Select
                  id="prioritySelect"
                  defaultValue={priorityToGroup}
                  isMulti
                  components={{ DropdownIndicator }}
                  name="priority"
                  options={priorityToGroupProjectsOptions(t)}
                  className="basic-multi-select"
                  classNamePrefix="ALL"
                  placeholder={t('all')}
                  onChange={value => handleChangePriority(value)}
                // styles={multiSelectStyle}
                />
              </div>
            </div>

            <div className="horizontal buttons">
              <button className='btn btn-primary-outlined' onClick={() => setIsOpen(false)} disabled={false}>{t('cancel')}</button>
              <SaveButton
                handleClick={handleSubmit}
                disabled={false}
                customText={t('assign_schedules')}
                customStyle={isMobile() ? { marginBottom: '10px' } : { marginRight: '10px' }}
              />

            </div>
          </div>
        </div>
      </BaseModal>
      <ScheduleWarning
        isOpen={showWarning}
        closeModal={() => setShowWarning(false)}
        timeAllocated={parseInt(timeAllocated.value)}
        reSchedule={value => rescheduleWarning(value)}
      />
    </>
  );
};

export default CreateAgenda;

const timeAllocatedForEachProjectOptions = t => {
  return [
    { value: '45', id: TimeAssignedEnum._45_minutes, label: `45 ${t('minutes')}` },
    { value: '60', id: TimeAssignedEnum._60_minutes, label: `60 ${t('minutes')}` },
    { value: '75', id: TimeAssignedEnum._75_minutes, label: `75 ${t('minutes')}` },
    { value: '90', id: TimeAssignedEnum._90_minutes, label: `90 ${t('minutes')}` },
  ];
};

const priorityToGroupProjectsOptions = t => {
  return [
    { value: '0', id: 0, label: `${t('sameF')} ${t('executing_unit')} `, sorting: sortByExecutor },
    { value: '1', id: 1, label: `${t('sameF')} ${t('division')} `, sorting: sortByDivision },
    { value: '2', id: 2, label: `${t('sameM')} ${t('team_leader')} `, sorting: sortByTeamLeader },
  ];
};
