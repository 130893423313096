import { isMobile } from '../../services/booleanUtil';
export const tabStyles = {
  root: {
    color: '#424D64',
    fontFamily: 'Rubik',
    fontSize: isMobile() ? '16px' : '14px',
    lineHeight: '18px',
    backgroundColor: '#FFFFFF',
    fontWeight: 500,
    width: 'auto',
    textDecoration: 'uppercase',
    minWidth: 'fit-content',
    marginLeft: isMobile() ? '0.2em' : '1.2em',
    '&$selected': {
      color: '#236BFF',
      fontFamily: 'Rubik',
      fontSize: isMobile() ? '16px' : '14px',
      lineHeight: '18px',
      backgroundColor: '#FFFFFF',
      fontWeight: 500,
      width: 'auto',
      textDecoration: 'uppercase',
      minWidth: 'fit-content',
      marginLeft: isMobile() ? '0.2em' : '1.2em',
    },
  },
  selected: {},
  indicatorColor: {
    backgroundColor: '#417FFE',
    height: '3px',
  },
  body1: {
    borderTop: '1px solid #eaeaea',
  },
  flexContainer: {
    borderBottom: '0',
    alignItems: 'center',
  },
  hiddenTab: {
    height: '44px',
    width: '10px',
    minWidth: '10px',
    display: 'none',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
};
