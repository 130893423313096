import React from 'react';
import './NewActivityForm.scss';
import { useDispatch } from 'react-redux';
import { initDataActivity, mapActivityEditDataToFormData, stateTypes } from './NewItemFormUtil';
import { Activity, Acquisition, ActivityType } from '../../../../../models/Planning';
import { createActivity, updateActivity } from '../../../../../redux/actions/planification';
import { newActivity } from '../../../../../api/planningApi';
import CommonInputs from './CommonInputs';
import { PlanningItemType } from './NewPlanningItem';
import { ActivityStateEnum } from '../../../../../models/ActivityStateEnum';
import CommentsModal from '../CommentsModal/CommentsModal';
import { trackActionClicked } from '../../../../../api/trackActionClickedApi';
import { TrackedActions } from '../../../../../models/TrackedActionsEnum';

type Props = {
  hideForm: Function;
  activityData?: Activity;
  keyProductId?: number;
  acquisition?: Acquisition;
};

const noData = (formData: any) => {
  const { name, plannedDate, realDate, planAmount, contractAmount, paidAmount, comments } = formData;
  return (
    name.trim() === '' &&
    plannedDate === null &&
    realDate === null &&
    !planAmount &&
    !contractAmount &&
    !paidAmount &&
    comments.length === 0
  );
};

const parsedFormData = formData => {
  const result = {
    ...formData,
    state: formData.state ? formData.state.id : null,
    planAmount: formData.planAmount ? parseInt(formData.planAmount) : 0,
    contractAmount: formData.contractAmount ? parseInt(formData.contractAmount) : 0,
    paidAmount: formData.paidAmount ? parseInt(formData.paidAmount) : 0,
  };

  if (formData.id === null) {
    delete result.id;
  }
  return result;
};

const previousToStart = (acquisition, currentDate) => {
  const startActivity = acquisition.activities.find(a => a.activityType === ActivityType.Start);
  return (
    currentDate &&
    startActivity &&
    startActivity.plannedDate &&
    new Date(startActivity.plannedDate).getTime() > currentDate.getTime()
  );
};

const isNoRealDate = formData => {
  return formData.state && formData.state.id === ActivityStateEnum.completed && !formData.realDate;
};

const clickIsOutsideAndNoModal = (wrapperRef, event, showModal) => {
  return wrapperRef && wrapperRef.current && !wrapperRef.current.contains(event.target) && !showModal;
};

const getFormClass = formData => {
  return formData.id ? { paddingTop: 10 } : {};
};

const keyProductIdParam = keyProductId => {
  return keyProductId || 0;
};

const commentsVariable = activityData => {
  return activityData && activityData.comments ? activityData.comments : [];
};

const validDataResult = (noDescription, noRealDate) => {
  return noDescription || noRealDate ? false : true;
};

const NewActivityForm = ({ hideForm, activityData, keyProductId, acquisition }: Props) => {
  const dispatch = useDispatch();
  const wrapperRef = React.useRef<HTMLInputElement>(null);
  const [formData, setFormData] = React.useState<any>({ ...initDataActivity });
  const [errors, setErrors] = React.useState<any>({ name: false, acquisitionType: false });
  const [showModal, setShowModal] = React.useState(false);
  const [customHeight, setCustomHeight] = React.useState(0);
  const comments = commentsVariable(activityData);

  function handleClickOutside(event: { target: any }) {
    if (clickIsOutsideAndNoModal(wrapperRef, event, showModal)) {
      submitFromClick();
    }
  }

  const validData = (formData: any) => {
    const noDescription = !formData.name.trim();
    const noRealDate = isNoRealDate(formData);
    if (noDescription) {
      setErrors({ ...errors, name: true });
    }
    if (noRealDate) {
      setErrors({ ...errors, realDate: true });
    }

    return validDataResult(noDescription, noRealDate);
  };

  const dataOkForSubmit = () => {
    if (noData(formData)) {
      hideForm();
      return false;
    }
    if (!validData(formData)) {
      return false;
    }
    return true;
  };

  const submitFromClick = () => {
    if (!dataOkForSubmit()) {
      return;
    }
    formData.id
      ? dispatch(updateActivity({ ...parsedFormData(formData), keyProductId, acquisitionId: acquisition?.id }))
      : dispatch(createActivity({ ...parsedFormData(formData), keyProductId, acquisitionId: acquisition?.id }));
    hideForm();
    trackActionClicked(TrackedActions.planningInformationAdded);
  };

  const submitFromEnter = async () => {
    if (!dataOkForSubmit()) {
      return;
    }
    if (!formData.id) {
      const response = await newActivity({ ...parsedFormData(formData), keyProductId, acquisitionId: acquisition?.id });
      setFormData(mapActivityEditDataToFormData(response));
      trackActionClicked(TrackedActions.planningInformationAdded);
    }
  };

  const updateDescription = value => {
    setFormData({ ...formData, name: value });
    setErrors({ ...errors, name: false });
  };

  const updateRealDate = realDate => {
    const state = stateTypes.find(item => item.id === ActivityStateEnum.completed);
    setFormData({ ...formData, realDate, state });
  };

  const updateState = state => {
    const realDate = state.id !== ActivityStateEnum.completed ? null : formData.realDate;
    setFormData({ ...formData, state, realDate });
  };

  const updatePlannedDate = value => {
    if (previousToStart(acquisition, value)) {
      setFormData({ ...formData, planAmount: 0, contractAmount: 0, paidAmount: 0, plannedDate: value });
    } else {
      setFormData({ ...formData, plannedDate: value });
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  React.useEffect(() => {
    if (activityData) {
      setFormData(mapActivityEditDataToFormData(activityData));
    }
  }, [activityData, comments]);

  return (
    <>
      <div ref={wrapperRef} className="new-activity-form-container" style={getFormClass(formData)}>
        <CommonInputs
          itemType={PlanningItemType.Activity}
          formData={formData}
          errors={errors}
          descriptionChange={updateDescription}
          descriptionSubmit={submitFromEnter}
          plannedDateChange={updatePlannedDate}
          realDateChange={updateRealDate}
          planAmountChange={value => setFormData({ ...formData, planAmount: value })}
          contractAmountChange={value => setFormData({ ...formData, contractAmount: value })}
          paidAmountChange={value => setFormData({ ...formData, paidAmount: value })}
          stateChange={updateState}
          commentsClick={e => {
            setCustomHeight(e.screenY);
            setShowModal(true);
          }}
          disabledAmounts={previousToStart(acquisition, formData.plannedDate)}
        />
      </div>
      {activityData && (
        <CommentsModal
          isOpen={showModal}
          closeModal={() => setShowModal(false)}
          activity={activityData}
          customHeight={customHeight}
          keyProductId={keyProductIdParam(keyProductId)}
        />
      )}
    </>
  );
};

export default NewActivityForm;
