import { useState } from 'react'
import { setMemoryHelpSaveRevision } from '../api/projectApi';

const useSetMemoryHelpFile = () => {
    const [dataFile, setDataFile] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);

    const saveRevision = async (agreementId: number, file: any) => {
        setLoading(true);
        try {
            const data = await setMemoryHelpSaveRevision(agreementId, file);
            setDataFile(data);
            setError(null);
        } catch (err) {
            console.log('Error al actualizar el documento del acuerdo en memory help:', err);
            setError(err as Error);
        } finally {
            setLoading(false);
        }
    };

    return { dataFile, loading, error, saveRevision };
};

export default useSetMemoryHelpFile;