export const boardStyle = {
  backgroundColor: '#fff',
  fontSize: '12px',
  height: '1010px',
  paddingLeft: '5px',
  marginTop: 10,
  with: "100%"
};

export const boardStyleMobile = {
  ...boardStyle,
  overflowY: 'scroll',
};

export const boardStyleCollapsed = {
  ...boardStyle,
  height: '780px',
};

export const laneStyle = {
  marginTop: '17px',
  width: '22vw',
  backgroundColor: '#F1F3F8',
  color: 'rgba(144, 160, 186, 1)',
  fontFamily: 'Rubik',
  textTransform: 'uppercase',
  fontSize: '12px !important',
  fontWeight: '500',
  borderRadius: '6px',
  border: '1px solid #F3F4F6',
};

export const activeLaneStyle = {
  ...laneStyle,
  backgroundColor: '#EFF5FF',
  color: '#264377',
};

export const activeLaneStyleWide = {
  ...activeLaneStyle,
  width: 'calc(42vw - 40px)',
};

export const activeLaneStyleIE11 = {
  ...activeLaneStyle,
  height: '880px',
};

export const laneStyleIE11 = {
  ...laneStyle,
  height: '880px',
};

export const horizontalLane = {
  ...activeLaneStyle,
  position: 'absolute',
  width: 'calc(88vw + 30px)',
  left: 0,
  top: 693,
  height: 300,
  paddingRight: 0,
  paddingLeft: 0,
};

export const hidden = {
  opacity: 0,
  width: 40,
};

export const hiddenDisplayNone = {
  display: 'none',
};
