import { defaultCalendarFiltersPayload } from '../actions/calendar';
import {
  CalendarActionTypes,
  SET_CALENDAR_FILTERS,
  SET_CALENDAR_EVENTS,
  SET_CALENDAR_DATE,
  SET_CALENDAR_EVENT_COUNTER,
  RESET_CALENDAR_FILTERS,
} from '../actions/types';

const getLocalStorageFilterValue = () => {
  const filterInStorage = localStorage.getItem('projectDetailCalendarFilters');
  if (filterInStorage) {
    return JSON.parse(filterInStorage);
  } else {
    return defaultCalendarFiltersPayload;
  }
};

const initialState = {
  date: new Date(),
  events: [],
  filters: getLocalStorageFilterValue(),
  eventCounter: [],
};

const calendarReducer = (state = initialState, action: CalendarActionTypes): any => {
  switch (action.type) {
    case SET_CALENDAR_FILTERS:
      const newState = { ...state, filters: { ...state.filters } };
      newState.filters[action.payload] = !newState.filters[action.payload];
      return newState;
    case SET_CALENDAR_EVENTS:
      return { ...state, events: action.payload };
    case SET_CALENDAR_DATE:
      return { ...state, date: action.payload };
    case SET_CALENDAR_EVENT_COUNTER:
      return { ...state, eventCounter: action.payload };
    case RESET_CALENDAR_FILTERS:
      return { ...state, filters: action.payload };
    default:
      return state;
  }
};
export default calendarReducer;
