import { isMobile } from './../../../services/booleanUtil';
import { getIndicatorData } from './indicatorHelperUtil';
import { IndicatorKeyEnum as keyEnum } from '../../../models/IndicatorKeyEnum';
import { CardTypesEnum } from '../../../models/CardTypesEnum';
import { BubbleStructure } from '../../../models/DisbursementTimeIndicatorInterfaces';
import { getMetricByQuadrant, getRealativeRadio, setLabels } from './util';

const quadrantDataOrEmpty = quadrantMetric => {
  return quadrantMetric.length ? quadrantMetric[0].metricValue : 0;
};

const handleMetricOrZero = metricData => (metricData.length ? metricData[0].metricValue : 0);

const valuesForMobileOrDesktop = (mobileValue, desktopValue) => (isMobile() ? mobileValue : desktopValue);

export const addDisbursementTime = (indicators, cardIndicators, indicatorOptions) => {
  const { projectTypeFilter } = indicatorOptions;
  const indicator = getIndicatorData(indicators, keyEnum.DISBURSEMENT_OVER_TIME);

  const xLeft = 2.5;
  const xRight = 7.5;
  const yBottom = 2.5;
  const yTop = 7.5;
  const maxRadioInPixels = valuesForMobileOrDesktop(10, 35);
  const borderWidth = valuesForMobileOrDesktop(2, 5);
  const colorQ1 = '#4E9C6E';
  const colorQ2 = '#727E8C';
  const colorQ3 = '#FDAC41';
  const colorQ4 = '#F06D6D';
  if (!indicator) {
    return;
  } else {
    const quadrant1Metric = getMetricByQuadrant(indicator.metrics, 'Q1', 'PENDING_DISBURSEMENT_AVERAGE');
    const quadrant2Metric = getMetricByQuadrant(indicator.metrics, 'Q2', 'PENDING_DISBURSEMENT_AVERAGE');
    const quadrant3Metric = getMetricByQuadrant(indicator.metrics, 'Q3', 'PENDING_DISBURSEMENT_AVERAGE');
    const quadrant4Metric = getMetricByQuadrant(indicator.metrics, 'Q4', 'PENDING_DISBURSEMENT_AVERAGE');

    const quadratic1Radio = quadrantDataOrEmpty(quadrant1Metric);
    const quadratic2Radio = quadrantDataOrEmpty(quadrant2Metric);
    const quadratic3Radio = quadrantDataOrEmpty(quadrant3Metric);
    const quadratic4Radio = quadrantDataOrEmpty(quadrant4Metric);

    const maxQuadraticRadio = Math.max(quadratic1Radio, quadratic2Radio, quadratic3Radio, quadratic4Radio);

    const quadrant1Bubble: BubbleStructure = {
      x: xLeft,
      y: yTop,
      r: getRealativeRadio(maxRadioInPixels, maxQuadraticRadio, quadratic1Radio),
    };

    const quadrant2Bubble: BubbleStructure = {
      x: xRight,
      y: yTop,
      r: getRealativeRadio(maxRadioInPixels, maxQuadraticRadio, quadratic2Radio),
    };
    const quadrant3Bubble: BubbleStructure = {
      x: xLeft,
      y: yBottom,
      r: getRealativeRadio(maxRadioInPixels, maxQuadraticRadio, quadratic3Radio),
    };

    const quadrant4Bubble: BubbleStructure = {
      x: xRight,
      y: yBottom,
      r: getRealativeRadio(maxRadioInPixels, maxQuadraticRadio, quadratic4Radio),
    };

    const sumarizeByTab = metricName => {
      const tabIndicatorLoanSumarize = indicator.metrics.map(m => {
        if (m.metricName === metricName) {
          const metricData = getMetricByQuadrant(indicator.metrics, m.groupingValue, metricName);
          return handleMetricOrZero(metricData);
        }
        return undefined;
      });

      const returnNumber = tabIndicatorLoanSumarize.length
        ? tabIndicatorLoanSumarize.filter(t => t !== undefined).reduce((acc, curr) => acc + curr)
        : 0;

      return typeof returnNumber === 'number' ? returnNumber : 0;
    };

    const dataChart = {
      id: indicator.id,
      indicatorKey: indicator.key,
      type: CardTypesEnum.BUBBLE_CARD_TYPE,
      title: 'disbursement_time_indicator_title',
      subtitle: '',
      detailTitle: 'disbursement_time_indicator_title',
      detailChartTitle: '',
      detailChartFoot: '',
      data:
        quadratic1Radio !== 0 || quadratic2Radio !== 0 || quadratic3Radio !== 0 || quadratic4Radio !== 0
          ? {
              datasets: [
                {
                  label: setLabels(projectTypeFilter, indicator.metrics, 'Q1'),
                  data: [quadrant1Bubble],
                  backgroundColor: 'transparent',
                  borderColor: colorQ1,
                  hoverBorderColor: colorQ1,
                  borderWidth,
                },
                {
                  label: setLabels(projectTypeFilter, indicator.metrics, 'Q2'),
                  data: [quadrant2Bubble],
                  backgroundColor: 'transparent',
                  borderColor: colorQ2,
                  hoverBorderColor: colorQ2,
                  borderWidth,
                },
                {
                  label: setLabels(projectTypeFilter, indicator.metrics, 'Q3'),
                  data: [quadrant3Bubble],
                  backgroundColor: 'transparent',
                  borderColor: colorQ3,
                  hoverBorderColor: colorQ3,
                  borderWidth,
                },
                {
                  label: setLabels(projectTypeFilter, indicator.metrics, 'Q4'),
                  data: [quadrant4Bubble],
                  backgroundColor: 'transparent',
                  borderColor: colorQ4,
                  hoverBorderColor: colorQ4,
                  borderWidth,
                },
              ],
            }
          : null,
      order: indicator.order,
      tabIndicatorValues: {
        Loan: sumarizeByTab('LON_PROJECT_COUNT'),
        CT: sumarizeByTab('TCP_PROJECT_COUNT'),
        IGBoth: sumarizeByTab('IGR_PROJECT_COUNT'),
      },
    };
    cardIndicators.push(dataChart);
  }
};
