import React from 'react';
import './ProgressBar.scss';

import LinearProgress from '@material-ui/core/LinearProgress';

function ProgressBar({ completed }) {
  return (
    <div className='container-linear'>
      <LinearProgress variant="determinate" value={completed} style={{ width: '100%' }} />
      <div className='container-linear_porcent'>
        <div className="porcent">{completed}%</div>
        <div className="porcent">{100 - completed}%</div>
      </div>
    </div>
  );
}

export default ProgressBar;
