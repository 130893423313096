const indicatorColumns = {
  PROJECTS_PENDING_SIGNATURE_OR_LEGISLATIVE_APPROVAL: [
    {
      tableHeader: 'approval_date_long',
      rootProperty: 'field1',
      fieldType: 'date',
      customWidth: { padding: '6px 10px' },
    },
    {
      tableHeader: 'days_since_approval',
      rootProperty: 'field2',
      fieldType: 'numeric',
      customWidth: { padding: '6px 10px' },
    },
    {
      tableHeader: 'status',
      rootProperty: 'field3',
      customWidth: { padding: '6px 10px' },
    },
    {
      tableHeader: 'legal_validity_classification',
      rootProperty: 'field5',
      customWidth: { padding: '6px 10px' },
    },
  ],
  PROJECTS_PENDING_DISBURSEMENT_ELIGIBILITY: [
    {
      tableHeader: 'number_of_days_elapsed_from_the_effective_date',
      rootProperty: 'field1',
      fieldType: 'numeric',
    },
  ],
  PROJECTS_OVERALL_STAGE_CODE_DISBURSING_NO_DISBURSEMENTS_OVER_180_DAYS: [
    {
      tableHeader: 'number_of_days_months_without_paying_disbursements',
      rootProperty: 'field1',
      fieldType: 'numeric',
    },
    {
      tableHeader: 'date_of_last_surrender',
      rootProperty: 'field2',
      fieldType: 'date',
    },
    {
      tableHeader: 'FINANCIAL_PLAN_OF_THE_LAST_ADVANCE',
      rootProperty: 'field4',
      fieldType: 'numeric',
    },
    {
      tableHeader: 'amount_pending_surrender',
      rootProperty: 'field3',
      fieldType: 'two_decimal',
    },
  ],

  DISBURSEMENT_PROJECTION_CURRENT_YEAR_INCLUDING_REGIONALS: [
    {
      tableHeader: 'original_projection',
      rootProperty: 'field1',
      fieldType: 'money',
    },
    {
      tableHeader: 'current_projection',
      rootProperty: 'field2',
      fieldType: 'money',
    },
    {
      tableHeader: 'disbursed',
      rootProperty: 'field3',
      fieldType: 'money',
    },
    {
      tableHeader: 'pending',
      rootProperty: 'field4',
      fieldType: 'percentage',
    },
  ],
  PROJECTS_LAST_DISBURSEMENT_DATE_CLOSE_12MONTHS_OR_EXPIRED: [
    {
      tableHeader: 'last_disbursement_date',
      rootProperty: 'field1',
      fieldType: 'date',
    },
    {
      tableHeader: 'months_extended',
      rootProperty: 'field2',
      fieldType: 'numeric',
    },
    {
      tableHeader: 'pending_disbursement_per',
      rootProperty: 'field3',
      fieldType: 'percentage',
    },
  ],
  DISTRIBUTION_ACCORDING_TO_LATEST_EFAS: [
    {
      tableHeader: 'auditor_name',
      rootProperty: 'field1',
    },
    {
      tableHeader: 'date_of_reception',
      rootProperty: 'field2',
      fieldType: 'date',
    },
  ],
  ESG_DISTRIBUTION_ACCORDING_TO_ENVIRONMENTAL_AND_SOCIAL_CATEGORY: [
    {
      tableHeader: 'category',
      rootProperty: 'field1',
    },
    {
      tableHeader: 'risk',
      rootProperty: 'field2',
    },
    {
      tableHeader: 'performance',
      rootProperty: 'field3',
    },
  ],
  ESG_SAFEGUARD_PERFORMANCE_RATING: [
    {
      tableHeader: 'executor',
      rootProperty: 'field1',
    },
    {
      tableHeader: 'division',
      rootProperty: 'field3',
    },
    {
      tableHeader: 'risk',
      rootProperty: 'field4',
    },
    {
      tableHeader: 'performance_dynamics',
      rootProperty: 'field5',
    },
  ],
  ESG_ENVIRONMENTAL_AND_SOCIAL_RISK_RATING: [
    {
      tableHeader: 'executor',
      rootProperty: 'field1',
    },
    {
      tableHeader: 'division',
      rootProperty: 'field3',
    },
    {
      tableHeader: 'performance',
      rootProperty: 'field4',
    },
    {
      tableHeader: 'risk_dynamics',
      rootProperty: 'field5',
    },
  ],
  PMR_PERFORMANCE_LAST_CLOSED_YEAR: [
    {
      tableHeader: 'division',
      rootProperty: 'field3',
    },
    {
      tableHeader: 'pmr_initials',
      rootProperty: 'field1',
      fieldType: 'pmrType',
    },
  ],
  GCM_ALERTS: [
    { tableHeader: 'project', rootProperty: 'code', fieldType: 'string' },
    { tableHeader: 'operation', rootProperty: 'loanNumber', fieldType: 'string' },
    { tableHeader: 'project_name', rootProperty: 'title', fieldType: 'string' },
    { tableHeader: 'partial_elegibility_date', rootProperty: 'elegibilityDate', fieldType: 'date' },
  ],
  PROJECTS_IN_PREPARATION: [
    {
      tableHeader: 'project',
      rootProperty: 'code',
      fieldType: 'string',
      customWidth: { width: '5%' },
    },
    {
      tableHeader: 'name',
      rootProperty: 'title',
      fieldType: 'string',
      customWidth: { width: '25%' },
    },
    {
      tableHeader: 'division',
      rootProperty: 'field1',
      fieldType: 'string',
      customWidth: { width: '5%' },
      align: 'center',
    },
    {
      tableHeader: 'amount_bid_usd',
      rootProperty: 'field2',
      fieldType: 'numeric',
      customWidth: { width: '10%' },
      align: 'center',
    },
    {
      tableHeader: 'team_leader_project_in_preparation_indicator',
      rootProperty: 'field3',
      fieldType: 'string',
      customWidth: { width: '15%' },
    },
    {
      tableHeader: 'executed_by_or_expected_approval_date',
      rootProperty: 'field4',
      fieldType: 'string',
      customWidth: { width: '15%' },
    },
    {
      tableHeader: 'status',
      rootProperty: 'field5',
      fieldType: 'string',
      customWidth: { width: '10%' },
    },
  ],
};

const BID_CONSULTANCY = [
  {
    tableHeader: 'firm_consultant',
    rootProperty: 'field1',
  },
  {
    tableHeader: 'description',
    rootProperty: 'field2',
    customWidth: { width: '30%' },
  },
  {
    tableHeader: 'project',
    rootProperty: 'code',
  },
  {
    tableHeader: 'start_date',
    rootProperty: 'field3',
  },
  {
    tableHeader: 'end_date',
    rootProperty: 'field4',
  },
  {
    tableHeader: 'real_amount',
    rootProperty: 'field5',
  },
  {
    tableHeader: 'paid_amount',
    rootProperty: 'field6',
  },
  {
    tableHeader: 'currency',
    rootProperty: 'field7',
  },
  {
    tableHeader: 'status',
    rootProperty: 'field8',
  },
];

export { indicatorColumns, BID_CONSULTANCY };
