import React, { useEffect, useState } from 'react';
import './NewAccordanceForm.scss';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Checkbox, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStyles } from './NewAccordanceForm.Styles';
import RequiredErrorMessage from '../../../../Util/Errors/RequiredErrorMessage/RequiredErrorMessage';
import { AccordanceType } from '../../../../../models/AccordanceType';
import DateSelector from './DateSelector';
import SelectOption from '../../../../../models/SelectOption';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CustomDatePicker from '../../../../Util/CustomDatePicker/CustomDatePicker';
import SearchUsers from '../../../../Util/SearchUsers/SearchUsers';
import { AccordanceState } from '../../../../../models/AccordanceState';

export const displayLabel = (option: any) => {
  return option.label || '';
};

export const displayName = (option: any) => {
  return option.name || option.nameProduct;
};

type Props = {
  isMulti?: boolean;
  criticalIssueList?: any;
  criticalIssue?: any;
  handleCriticalIssueChange?: any;
  formErrors?: any;
  category?: any;
  expirationDate?: any;
  revisionDate?: any;
  criticalIssueDescription?: string;
  handleCriticalIssueDescription: Function;
  setFormErrors?: any;
  setRevisionDate?: any;
  setExpirationDate?: any;
  setSemesterOption?: any;
  semesterOption: SelectOption | null;
  accordanceFormType: AccordanceType;
  accordanceDate?: any;
  handleAccordanceDate: Function;
  definedAgreement?: any;
  linkedProduct?: any;
  handleDefinedAgreementChange?: any;
  handleLinkedProduct?: any;
  accordanceDefinitionForm?: any;
  productForm?: any;
  validateTextareaCritical?: any;
  expirationDateSingle?: any;
  handleExpirationDateSingle?: any;
  handleDescriptionSingle?: any;
  descriptionSingle?: any;
  validateTextareaDescriptionSingle?: any;
  responsibleSingle?: any;
  handleIemSelectedSingle?: any;
  editAccordanceDataSingle?: any;
  accordanceStateSingle?: any;
};

const SharedInputs = (props: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    isMulti,
    criticalIssueList,
    criticalIssue,
    handleCriticalIssueChange,
    formErrors,
    category,
    expirationDate,
    revisionDate,
    criticalIssueDescription,
    handleCriticalIssueDescription,
    setFormErrors,
    setRevisionDate,
    setExpirationDate,
    setSemesterOption,
    semesterOption,
    accordanceFormType,
    accordanceDate,
    handleAccordanceDate,
    definedAgreement,
    linkedProduct,
    handleDefinedAgreementChange,
    handleLinkedProduct,
    accordanceDefinitionForm,
    productForm,
    validateTextareaCritical,
    expirationDateSingle,
    handleExpirationDateSingle,
    handleDescriptionSingle,
    descriptionSingle,
    validateTextareaDescriptionSingle,
    responsibleSingle,
    handleIemSelectedSingle,
    editAccordanceDataSingle,
    accordanceStateSingle,
  } = props;

  // const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  // const validateTextarea = (value: any) => {
  //   handleCriticalIssueDescription(value);
  //   if (value.length <= 3 || !value) {
  //     setFormErrors({ ...formErrors, ...{ criticalIssueDescription: true } });
  //   } else {
  //     setFormErrors({ ...formErrors, ...{ criticalIssueDescription: false } });
  //   }
  // };

  return (
    <>
      {!isMulti && <h3>{t('new_general_information_of_the_agreement')}</h3>}
      <div className="horizontal-row">
        <div className="label-select-container">
          <p className="label">{t('new_agreement_defined_in')} </p>
          <Autocomplete
            id="combo-box-demo"
            value={definedAgreement}
            onChange={handleDefinedAgreementChange}
            options={accordanceDefinitionForm}
            getOptionLabel={displayName}
            style={{ width: '100%' }}
            renderInput={params => <TextField {...params} placeholder={t('new_write_select')} fullWidth />}
            classes={formErrors?.definedAgreement ? classes : {}}
            noOptionsText={t('no_results')}
            getOptionSelected={(option, value) => option.id === value.id}
          />
          {formErrors?.definedAgreement && <RequiredErrorMessage />}
        </div>
      </div>
      <div className="horizontal-row">
        <DateSelector
          accordanceFormType={accordanceFormType}
          revisionDate={revisionDate}
          setFormErrors={setFormErrors}
          formErrors={formErrors}
          setRevisionDate={setRevisionDate}
          expirationDate={expirationDate}
          setExpirationDate={setExpirationDate}
          setSemesterOption={setSemesterOption}
          semesterOption={semesterOption}
          accordanceDate={accordanceDate}
          handleAccordanceDate={handleAccordanceDate}
        />
        {!isMulti ? (
          <div className="label-select-container">
            <CustomDatePicker
              date={expirationDateSingle}
              label={t('due_date')}
              setDate={handleExpirationDateSingle}
              error={formErrors?.expirationDate}
              minDate={revisionDate}
              disabled={!revisionDate}
              placeholderComplete={true}
            />
            {formErrors?.expirationDate && <RequiredErrorMessage />}
          </div>
        ) : null}
      </div>

      {!isMulti ? (
        <>
          <div className="horizontal-row">
            <div className="label-select-container text-area">
              <div className="label-selec-number-caracteres">
                <p className="label">{`${t('accordance_description')}`}</p>
                <p>{`${descriptionSingle?.length === undefined ? 0 : descriptionSingle?.length}/250`}</p>
              </div>
              <textarea
                {...(formErrors?.description && { style: { border: '1px solid #ed5a4d' } })}
                maxLength={250}
                value={descriptionSingle}
                onChange={e => {
                  validateTextareaDescriptionSingle(e.target.value);
                }}
                onBlur={e => handleDescriptionSingle(e.target.value)}
                placeholder={t('new_describe_in_detail_what_the_agreement_is_about_two')}
              ></textarea>
              {formErrors?.description && <RequiredErrorMessage />}
            </div>
          </div>

          <div className="horizontal-row">
            <div className="label-select-container">
              <p className="label">{t('new_bid_responsible')}</p>
              {editAccordanceDataSingle && accordanceStateSingle === AccordanceState.DRAFT ? (
                <span className="label">
                  {editAccordanceDataSingle.personInCharge.name} {editAccordanceDataSingle.personInCharge.lastName}
                </span>
              ) : (
                <SearchUsers
                  selected={responsibleSingle}
                  itemSelected={handleIemSelectedSingle}
                  error={formErrors?.responsible}
                />
              )}
              <p className="label-observation">{t('new_assign_to_the_manager')}</p>
              {formErrors?.responsible && <RequiredErrorMessage />}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="horizontal-row">
            <div className="label-select-container">
              <p className="label">{t('critical_issue')}</p>
              <Autocomplete
                id="search-criticl-issues"
                options={criticalIssueList?.filter(critical => critical.categoryName !== null)}
                getOptionLabel={displayLabel}
                value={criticalIssue}
                onChange={handleCriticalIssueChange}
                renderInput={params => <TextField placeholder={t('select_item')} {...params} fullWidth />}
                noOptionsText={t('no_results')}
                classes={formErrors?.criticalIssue ? classes : {}}
                getOptionSelected={(option, value) => option.id === value.id}
              />
              {formErrors?.criticalIssue && <RequiredErrorMessage />}
            </div>
            <div className="label-select-container" style={{ width: '100%' }}>
              {/* <p className="label">{t('category')}</p>
              <span className="category-value">{category || '-'}</span> */}
              {productForm?.length !== 0 ? (
                <>
                  <p className="label">{t('new_linked_product')}</p>
                  <Autocomplete
                    groupBy={option => `${t('new_component')} ${option.componentOrder}`}
                    multiple
                    value={linkedProduct}
                    id="checkboxes-tags-demo"
                    options={productForm}
                    onChange={handleLinkedProduct}
                    disableCloseOnSelect
                    noOptionsText={t('no_results')}
                    getOptionSelected={(option, value) => option.id === value.id}
                    getOptionLabel={displayName}
                    renderOption={(option, { selected }) => (
                      <>
                        <Checkbox
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 28 }}
                          checked={
                            !selected ? linkedProduct.find(element => element.nameProduct === option.name) : selected
                          }
                        />
                        {option.name}
                      </>
                    )}
                    renderInput={params => (
                      <TextField {...params} placeholder={linkedProduct.length !== 0 ? ' ' : t('products')} fullWidth />
                    )}
                    classes={formErrors?.linkedProductsAccordance ? classes : {}}
                  />
                  {formErrors?.linkedProductsAccordance && <RequiredErrorMessage />}
                </>
              ) : null}
            </div>
          </div>
          <div className="horizontal-row">
            <div className="label-select-container text-area">
              <div className="label-selec-number-caracteres">
                <p className="label">{`${t('description_of_the_critical_issue')}`}</p>
                <p>{`${criticalIssueDescription?.length === undefined ? 0 : criticalIssueDescription?.length}/250`}</p>
              </div>
              <textarea
                {...(formErrors?.criticalIssueDescription && { style: { border: '1px solid #ed5a4d' } })}
                maxLength={250}
                value={criticalIssueDescription}
                onChange={e => {
                  validateTextareaCritical(e.target.value);
                }}
                onBlur={e => handleCriticalIssueDescription(e.target.value)}
                placeholder={t(
                  'new_enter_more_details_about_the_critical_issue_that_the_new_agreement_will_resolve_two',
                )}
              ></textarea>
              {formErrors?.criticalIssueDescription && <RequiredErrorMessage />}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SharedInputs;
