import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getNewFilteredIndicators } from '../../../api/indicatorApi';
import { updateCurrentIndicatorFromDetail } from '../../../redux/actions/currentIndicator';
import { processIndicators } from '../../Portfolio/indicatorHelper';
import CustomSelectSmall from '../../Util/CustomSelectSmall/CustomSelectSmall';
import './FiscalYearFilterChart.scss';

type Props = {
  indicatorKey;
  fiscalYearFilter;
};
export const FiscalYearFilterChart = ({ indicatorKey, fiscalYearFilter }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const projectTypeFilterOnDetail = useSelector<any, any>(
    state => state.indicatorDetailFilter.projectTypeFilterOnDetail,
  );
  const filterIndicators = useSelector<any, any>(state => state.indicatorDetailFilter.indicatorCharts);
  const currentYear = moment().year();
  const lastYear = currentYear - 1;
  const firstYear = currentYear - 2;
  const [selectFiscalYearValue, setSelectFiscalYearValue] = useState(lastYear);
  const fiscalYearOptions = [
    { value: currentYear, label: currentYear },
    { value: lastYear, label: lastYear },
    { value: firstYear, label: firstYear },
  ];

  useEffect(() => {
    if (filterIndicators.fiscalYear !== undefined && !filterIndicators.firstTime) {
      setSelectFiscalYearValue(filterIndicators.fiscalYear.fiscalYear);
    } else {
      setSelectFiscalYearValue(lastYear);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    selectHandlerFiscalYear(selectFiscalYearValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectTypeFilterOnDetail]);
  const selectHandlerFiscalYear = async fiscalYear => {
    setSelectFiscalYearValue(fiscalYear);
    const indicatorsWithFiscalYearFilter = await getNewFilteredIndicators({
      fiscalYear,
    });
    const indicatorsProc = processIndicators(indicatorsWithFiscalYearFilter);
    const selectIndicatorProc = indicatorsProc.find(item => item.indicatorKey === indicatorKey);
    selectIndicatorProc && dispatch(updateCurrentIndicatorFromDetail(selectIndicatorProc));
    fiscalYearFilter({ fiscalYear });
  };

  return (
    <div className="fiscal-year-filter-chart-container">
      <div>
        <CustomSelectSmall
          selectValue={selectFiscalYearValue}
          handleChange={selectHandlerFiscalYear}
          label={t('efa_presentation_date')}
          options={fiscalYearOptions}
        />
      </div>
    </div>
  );
};
