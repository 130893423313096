import React from 'react';
import { useTranslation } from 'react-i18next';
import SelectableProjects from '../SelectableProjects/SelectableProjects';

type Props = {
  review: any;
  displayMyPortfolio?: boolean;
  setLocalRouter: () => void;
  setVisibleComponent: () => void;
  setProjectId: () => void;
};

const Projects = ({ review, displayMyPortfolio, setLocalRouter, setVisibleComponent, setProjectId }: Props) => {
  const { t } = useTranslation();

  return !displayMyPortfolio ? (
    <SelectableProjects
      projects={{ list: review.projects, title: t('selected_revision_projects') }}
      withComments={true}
      canCreateAccordance={review.canCreateAccordance}
    />
  ) : (
    <>
      <SelectableProjects
        projects={{ list: review.projects.filter(p => p.inPortfolio), title: t('selected_revision_my_portfolio') }}
        withComments={true}
        canCreateAccordance={review.canCreateAccordance}
        customNoResultsMessage={t('no_results_my_portfolio')}
        tableAccordance={true}
      />
      <SelectableProjects
        projects={{ list: review.projects.filter(p => !p.inPortfolio), title: t('selected_revision_other') }}
        withComments={true}
        canCreateAccordance={review.canCreateAccordance}
        tableAccordance={true}
        setLocalRouter={setLocalRouter}
        setVisibleComponent={setVisibleComponent}
        setProjectId={setProjectId}
      />
    </>
  );
};

export default Projects;
