import React from 'react';
import { useTranslation } from 'react-i18next';
import ClickableText from '../../../ClickableText/ClickableText';
import NewAcquisitionForm from './NewAcquisitionForm';
import NewActivityForm from './NewActivityForm';
import { Acquisition } from '../../../../../models/Planning';

export enum PlanningItemType {
  Acquisition = 1,
  Activity,
}

type Props = {
  keyProductId: number;
  type: PlanningItemType;
  acquisition?: Acquisition;
};

const NewPlanningItem = ({ keyProductId, type, acquisition }: Props) => {
  const [showInput, setShowInput] = React.useState(false);
  const { t } = useTranslation();

  const displayInput = () => {
    setShowInput(!showInput);
  };

  const getStyle = () => {
    const basic = { paddingLeft: 20, marginBottom: 20 };
    return type === PlanningItemType.Acquisition ? basic : { ...basic, backgroundColor: '#e2e9ee', paddingBottom: 20 };
  };

  return (
    <>
      {showInput ? (
        type === PlanningItemType.Acquisition ? (
          <NewAcquisitionForm hideForm={displayInput} keyProductId={keyProductId} />
        ) : (
          <NewActivityForm hideForm={displayInput} keyProductId={keyProductId} acquisition={acquisition} />
        )
      ) : (
        <div style={getStyle()}>
          <ClickableText
            text={`+ ${type === PlanningItemType.Acquisition ? t('add_acquisition') : t('add_activity')}`}
            onClick={displayInput}
            fontSize={'12px'}
          />
        </div>
      )}
    </>
  );
};

export default NewPlanningItem;
