import React, { useEffect, useState } from 'react';
import styles from './IndicatorPlannedVsReal.module.scss';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import SelectOption from '../../../../../models/SelectOption';

import { getCustomOptionsPlannedVsRealChart, getPlannedVsRealChartObject, filterOptions, barChartData } from './util';
import { MultiDataNotStackedBar } from '../../../../Portfolio/CardIndicator/Cards/MultiDataNotStackedBar/MultiDataNotStackedBar';
import { plannedVsRealDetailProjectSelect } from '../../../../Filter/FilterComponent.Styles';
import Select from 'react-select';
import SpinningLoader from '../../Loader/SpinningLoader';

type Props = {
  data: any[];
};

const currentMonth = new Date().getMonth();
export const IndicatorPlannedVsReal = ({ data }: Props) => {
  const { t } = useTranslation();
  const setDefaultSelectOption = state => state(filterOptions(t)[0]);
  const [selectedOption, setSelectedOption] = setDefaultSelectOption(useState);
  const [chartData, setChartData] = useState<any>([]);

  const filterDatasets = (filterToApply, data) => {
    let validDatasets;
    if (filterToApply === 0) {
      validDatasets = [...data.dataFullYear.datasets.slice(0, 2)];
    } else {
      validDatasets = [...data.dataFullYear.datasets.slice(2, 4)];
    }
    return {
      ...data,
      dataFullYear: {
        ...data.dataFullYear,
        datasets: validDatasets,
      },
    };
  };

  useEffect(() => {
    if (data.length) {
      setChartData(barChartData(filterDatasets(selectedOption.id, getPlannedVsRealChartObject(cloneDeep(data))), t));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setChartData(barChartData(filterDatasets(selectedOption.id, getPlannedVsRealChartObject(cloneDeep(data))), t));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  const handleSelectedFilter = (item: SelectOption) => {
    setSelectedOption(item);
  };
  return (
    <div className={styles.indicatorPlannedVsReal}>
      {data.length ? (
        <>
          <div className={styles.header}>
            <div className={styles.title}>{t('projected_vs_finished')}</div>
            <div className={styles.filter}>
              <span className={styles.lbl}>{`${t('filter_by')}: `}</span>
              <Select
                value={selectedOption}
                options={filterOptions(t)}
                onChange={value => handleSelectedFilter(value)}
                styles={plannedVsRealDetailProjectSelect}
                isSearchable={false}
                placeholder={t('select')}
                noOptionsMessage={() => t('no_results')}
              />
            </div>
          </div>
          <div className={styles.chartContainer}>
            <MultiDataNotStackedBar
              data={chartData}
              customOption={getCustomOptionsPlannedVsRealChart(chartData, t, currentMonth)}
              customDimensions={{ desktop: { width: 380, height: 220 }, mobile: { width: 190, height: 110 } }}
            />
          </div>
          <div className={styles.footer}>
            <ul className={styles.informativeUnorderedList}>
              <li className={styles.projectedProgress}>{t('projected_progress')}</li>
              <li className={styles.actualProgress}>{t('actual_progress')}</li>
            </ul>
          </div>
        </>
      ) : (
        <SpinningLoader />
      )}
    </div>
  );
};
