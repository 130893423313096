import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Fab from '@material-ui/core/Fab';
import { useStyles } from './ProjectsHeader.Styles';
import './ProjectsHeader.scss';
import { ReactComponent as FilterButton } from '../../../assets/icons/u_filters-v.svg';
import FilterComponent from '../../Filter/FilterComponent';
import FilterBubble from '../../Filter/FilterBubble/FilterBubble';
import { isMobile } from '../../../services/booleanUtil';
import { useSelector } from 'react-redux';
import { CalledFrom } from '../../../models/CalledFromEnum';
import { trackActionClicked } from '../../../api/trackActionClickedApi';
import { TrackedActions } from '../../../models/TrackedActionsEnum';
import useFilters from '../../../hooks/useFilters';

const ProjectsHeader = () => {
  const { filter, dispatch, setFilter } = useFilters();
  const classes = useStyles();
  const { t } = useTranslation();
  const [filterIsOpen, setFilterIsOpen] = React.useState(false);
  const [selectValue, setSelectValue] = useState('accumulated');
  const storeSelectedValue = useSelector<any, string>(state => state.filter.common.isAnnual);

  //accumulated
  React.useEffect(() => {
    if (storeSelectedValue === 'accumulated') {
      setSelectValue('accumulated');
    } else {
      setSelectValue('annual');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectValue]);

  const selecthandler = (event: any) => {
    setSelectValue(event);
    const value = event.target.value;

    setSelectValue(value);

    dispatch(
      setFilter({
        ...filter,
        common: {
          ...filter.common,
          isAnnual: value,
        },
      }),
    );

    if (value === 'accumulated') {
      trackActionClicked(TrackedActions.disbursementDataAccumulated);
    } else {
      trackActionClicked(TrackedActions.disbursementDataAnnual);
    }
  };

  const closeModal = (value: boolean) => {
    setFilterIsOpen(value);
  };

  return (
    <div className="header-container">
      <div className="filter-indicator">
        <div className="title-projects">{t('following_projects_title')}</div>
        <FilterBubble indicatorCall={false} />
      </div>
      {!isMobile() && (
        <div className="filter-select">
          <span className="header-disbursement-data-text">{t('disbursement_data')}</span>

          <select
            data-testid="select"
            value={selectValue}
            className={selectValue === 'accumulated' ? 'select-css select-width-large' : 'select-css'}
            onChange={selecthandler}
          >
            <option value="accumulated">{t('disbursement_data_accumulated')}</option>
            <option value="annual">{t('disbursement_data_annual')}</option>
          </select>
        </div>
      )}
      <div className={'filter-button'} id="filterComponent">
        <Fab variant="extended" className={classes.root} onClick={() => setFilterIsOpen(true)}>
          <FilterButton className={classes.extendedIcon} />
          {isMobile() ? '' : t('filter_button')}
        </Fab>
        <FilterComponent
          closeModal={closeModal}
          isOpen={filterIsOpen}
          indicatorCall={false}
          calledFrom={CalledFrom.PROJECT}
        />
      </div>
    </div>
  );
};

export default ProjectsHeader;
