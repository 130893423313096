import React, { useEffect, useState } from 'react';
import styles from './ProjectsList.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Pagination } from '@material-ui/lab';
import { sortAlphabetically } from '../../../../services/sortsUtil';
import { updateReviewProjects } from '../../../../api/reviewsApi';
import { setCurrentReview } from '../../../../redux/actions/currentReview';

const pageSize = 9;

const subDivideList = list => {
  const result: any[] = [];
  while (list.length) {
    result.push(list.splice(0, pageSize));
  }
  return result;
};

const ProjectsList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentReview = useSelector<any, any>(state => state.currentReview);
  const [sortCriteria, setSortCriteria] = useState('asc');
  const [list, setList] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const sortedList = [...currentReview.projects].sort(sortAlphabetically('code', sortCriteria));
    setList(subDivideList(sortedList));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReview, sortCriteria]);

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  const removeFromList = async (id: number) => {
    const newList = currentReview.projects.filter(proj => proj.id !== id);
    await updateReviewProjects(
      currentReview.id,
      newList.map(p => {
        return p.id;
      }),
    );
    const newReview = { ...currentReview, projects: newList };
    dispatch(setCurrentReview(newReview));
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <span className={styles.textSort} onClick={() => setSortCriteria(sortCriteria === 'asc' ? 'desc' : 'asc')}>
          {t('code_word')}
        </span>
        <span className={styles.text}>{t('name')}</span>
      </div>
      {list.length &&
        list[currentPage - 1].map(project => {
          return (
            <div className={styles.row} key={project.id}>
              <span className={styles.rowText}>{project.code}</span>
              <span className={styles.rowTextClipped}>{project.name}</span>
              <span className={styles.remove} onClick={() => removeFromList(project.id)}>
                {t('remove')}
              </span>
            </div>
          );
        })}
      <Pagination
        count={list.length}
        showFirstButton
        showLastButton
        onChange={handleChange}
        style={{ position: 'absolute', width: '100%', bottom: 6, display: 'flex', justifyContent: 'center' }}
        page={currentPage}
      />
    </div>
  );
};

export default ProjectsList;
