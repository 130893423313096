import React from 'react';
import './AccordanceFiles.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  files: any[];
};

const AccordanceFiles = (props: Props) => {
  const { t } = useTranslation();
  const { files } = props;

  return (
    <div className="accordance-files-container">
      <div className="label-data">
        <span className="label">{t('attached_files')}</span>
        {files.map(item => {
          return (
            <a href={item.url} className="link" key={item.id} target="_blank" rel="noopener noreferrer">
              {item.name || item.url}
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default AccordanceFiles;
