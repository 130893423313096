import React from 'react';
import { useTranslation } from 'react-i18next';
import './FollowButton.scss';
import { isMobile } from '../../../../services/booleanUtil';

type Props = {
  follow: boolean;
  handleClick: any;
};

const FollowButton = (props: Props) => {
  const follow = props.follow;
  const handleClick = props.handleClick;
  const { t } = useTranslation();

  return (
    <button className="follow-button-container" onClick={handleClick}>
      <span className={follow ? 'follow unfollow' : 'follow'}>
        <span className="icon"></span>
        {!isMobile() && <span>{follow ? t('unfollow') : t('follow')}</span>}
      </span>
    </button>
  );
};

export default FollowButton;
