import React, { useEffect, useState } from 'react';
import { ReactComponent as IconFilter } from '../../../assets/icons/u_filters-v.svg';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

type Props = {
  fndataFilter: any;
  dataFilter: any;
  data: any;
  filterOption: any;
  fnSetValue: any;
  defaultValue: any;
  fnSetBtnDataFilterPublic?: any;
};

const FilterCard = ({ title, filters, selectedFilterIndex, onFilterClick }) => {
  const { t } = useTranslation();

  return (
    <div className="cards-filter">
      <h3>{t(title)}</h3>
      <div className="filters">
        <div
          className={`single-filter ${selectedFilterIndex.btn1 && 'selected'}`}
          onClick={() => onFilterClick('btn1', !selectedFilterIndex.btn1)}
        >
          <div>
            <h3>{filters?.count1}</h3>
            <p>{t(filters?.description1)}</p>
          </div>
          <div className="icon-filter">
            <IconFilter />
          </div>
        </div>
        <div
          className={`single-filter ${selectedFilterIndex.btn2 && 'selected'}`}
          onClick={() => onFilterClick('btn2', !selectedFilterIndex.btn2)}
        >
          <div>
            <h3>{filters?.count2}</h3>
            <p>{t(filters?.description2)}</p>
          </div>
          <div className="icon-filter">
            <IconFilter />
          </div>
        </div>
      </div>
    </div>
  );
};

const FiltersCardsProcess = ({
  fndataFilter,
  dataFilter,
  data,
  filterOption,
  fnSetValue,
  fnSetBtnDataFilterPublic,
}: Props) => {
  const [dataPublication, setDataPublication] = useState<any>();
  const [dataContract, setDataContract] = useState<any>();

  const [selectedFilterPublication, setSelectedFilterPublication] = useState<any>({
    btn1: false,
    btn2: false,
  });

  const [selectedFilterContract, setSelectedFilterContract] = useState<any>({
    btn1: false,
    btn2: false,
  });

  let currentDate = new Date();

  const arrayStatePublic = ['Expected', 'Modified', 'Under Review', 'Draft'];
  const arrayStateContract = [
    'Expected',
    'Modified',
    'Under Review',
    'Draft',
    'Process ongoing',
    'Ongoing',
    'Evaluation of Bids/Proposals',
  ];

  const filterDataPublication = () => {
    const countPublication = data?.filter(
      data =>
        moment(data.field8).diff(moment(currentDate).format(), 'days') >= 0 &&
        moment(data.field8).diff(moment(currentDate).format(), 'days') + 1 <= 90 &&
        data.field9 === null &&
        arrayStatePublic.includes(data.field7),
    );

    const countHiring = data?.filter(
      data =>
        moment(data.field8).diff(moment(currentDate).format(), 'days') + 1 <= 0 &&
        data.field9 === null &&
        arrayStatePublic.includes(data.field7),
    );

    setDataPublication({
      count1: countPublication.length,
      description1: 'new_with_publication_date',
      count2: countHiring.length,
      description2: 'new_with_expired_publication_date',
    });
  };

  const filterDataContract = () => {
    const countContract = data?.filter(
      data =>
        moment(data.field10).diff(moment(currentDate).format(), 'days') >= 0 &&
        moment(data.field10).diff(moment(currentDate).format(), 'days') + 1 <= 90 &&
        arrayStateContract.includes(data.field7),
    );
    const countExpired = data?.filter(
      data =>
        moment(data.field10).diff(moment(currentDate).format(), 'days') + 1 <= 0 &&
        arrayStateContract.includes(data.field7),
    );

    setDataContract({
      count1: countContract.length,
      description1: 'new_with_pending_hiring_date',
      count2: countExpired.length,
      description2: 'new_with_expired_contract_date',
    });
  };

  const handleFilterClickPublication = (btn, cardIndex) => {
    fnSetValue('0');
    fnSetBtnDataFilterPublic([]);
    setSelectedFilterPublication(prev => ({ ...prev, [btn]: cardIndex }));
  };

  const handleFilterClickContract = (btn, cardIndex) => {
    fnSetValue('0');
    fnSetBtnDataFilterPublic([]);
    setSelectedFilterContract(prev => ({ ...prev, [btn]: cardIndex }));
  };

  const filterAllPublication = () => {
    const countPublication = data?.filter(
      data =>
        moment(data.field8).diff(moment(currentDate).format(), 'days') >= 0 &&
        moment(data.field8).diff(moment(currentDate).format(), 'days') + 1 <= 90 &&
        data.field9 === null &&
        arrayStatePublic.includes(data.field7),
    );
    const countHiring = data?.filter(
      data =>
        moment(data.field8).diff(moment(currentDate).format(), 'days') + 1 <= 0 &&
        data.field9 === null &&
        arrayStatePublic.includes(data.field7),
    );

    if (selectedFilterPublication.btn1 && selectedFilterPublication.btn2) {
      fndataFilter([...countPublication, ...countHiring]);
      fnSetBtnDataFilterPublic([...countPublication, ...countHiring]);
    } else if (selectedFilterPublication.btn1 && !selectedFilterPublication.btn2) {
      fnSetBtnDataFilterPublic(countPublication);
      fndataFilter(countPublication);
    } else if (!selectedFilterPublication.btn1 && selectedFilterPublication.btn2) {
      fndataFilter(countHiring);
      fnSetBtnDataFilterPublic(countHiring);
    } else {
      fndataFilter(data);
    }
  };

  const filterAllContract = () => {
    const countContract = data?.filter(
      data =>
        moment(data.field10).diff(moment(currentDate).format(), 'days') >= 0 &&
        moment(data.field10).diff(moment(currentDate).format(), 'days') + 1 <= 90 &&
        arrayStateContract.includes(data.field7),
    );
    const countExpired = data?.filter(
      data =>
        moment(data.field10).diff(moment(currentDate).format(), 'days') + 1 <= 0 &&
        arrayStateContract.includes(data.field7),
    );
    if (selectedFilterContract.btn1 && selectedFilterContract.btn2) {
      fndataFilter([...countContract, ...countExpired]);
      fnSetBtnDataFilterPublic([...countContract, ...countExpired]);
    } else if (selectedFilterContract.btn1 && !selectedFilterContract.btn2) {
      fndataFilter(countContract);
      fnSetBtnDataFilterPublic(countContract);
    } else if (!selectedFilterContract.btn1 && selectedFilterContract.btn2) {
      fndataFilter(countExpired);
      fnSetBtnDataFilterPublic(countExpired);
    } else {
      fndataFilter(data);
    }
  };

  useEffect(() => {
    filterDataPublication();
    filterDataContract();
  }, [dataFilter]);

  useEffect(() => {
    filterAllPublication();
    if (selectedFilterContract.btn1 || selectedFilterContract.btn2) {
      setSelectedFilterContract({ btn1: false, btn2: false });
    }
  }, [selectedFilterPublication]);

  useEffect(() => {
    filterAllContract();
    if (selectedFilterPublication.btn1 || selectedFilterPublication.btn2) {
      setSelectedFilterPublication({ btn1: false, btn2: false });
    }
  }, [selectedFilterContract]);

  return (
    <section className="filter-container">
      <FilterCard
        title="new_processes_that_should_be_published"
        filters={dataPublication}
        onFilterClick={handleFilterClickPublication}
        selectedFilterIndex={selectedFilterPublication}
      />
      <FilterCard
        title="new_processes_that_should_be_contracted"
        filters={dataContract}
        onFilterClick={handleFilterClickContract}
        selectedFilterIndex={selectedFilterContract}
      />
    </section>
  );
};

export default FiltersCardsProcess;
