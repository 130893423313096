import moment from 'moment';
import { AnalyticShowDataRangeEnum } from '../../../../models/AnalyticShowDataRangeEnum';
import { AnalyticsKeyEnum } from '../../../../models/AnalyticsKeyEnum';
import { BarChartColorPalleteEnum } from '../../../../models/BarChartColorPalleteEnum';
import { RolesIdEnum } from '../../../../models/RolesIdEnum';
import { getIndicatorData } from '../../../Portfolio/IndicatorsHelpers/indicatorHelperUtil';
import { groupBy, cloneDeep } from 'lodash';
import { getNumericEnumLength } from '../util';
import { CountriesColorPalleteEnum } from '../../../../models/CountriesColorPalleteEnum';

const setComplexTitle = analyticKey => {
  if (analyticKey === AnalyticsKeyEnum.AGGREGATE_INDICATORS_LOGINS_COMPLEX) {
    return 'logins';
  } else if (analyticKey === AnalyticsKeyEnum.AGGREGATE_INDICATORS_USERS_WHO_ENTERED_THE_TOOL_COMPLEX) {
    return 'users_who_entered_the_tool';
  } else {
    return 'use_of_sections_click';
  }
};

const getRolesList = () => {
  let rolesQty = 1;
  const rolesDataSet: any = [];
  while (rolesQty <= getNumericEnumLength(RolesIdEnum)) {
    rolesDataSet.push({ label: rolesQty });
    rolesQty++;
  }
  return rolesDataSet;
};

const getAccumulatedValues = data => {
  return data.reduce((a, b) => a + (isNaN(b) ? 0 : b), 0);
};

const handleFindRolesDataWeekAndMonth = (rolesData, simple, x, roleLabel, dateFormat) => {
  return rolesData.metrics.find(
    m =>
      moment(simple ? m.groupingValue : m.metricLabel).format(dateFormat) === x &&
      roleLabel === (simple ? m.metricName : parseInt(m.metricName)),
  );
};

const handleFilterRolesDataQuarter = (rolesData, simple, roleLabel, x) => {
  return rolesData.metrics.filter(m =>
    simple ? checkBetweenDatesSimple(m, roleLabel, x) : checkBetweenDates(m, roleLabel, x),
  );
};

const handleFilterRolesDataYear = (rolesData, simple, x, roleLabel, dateFormat) => {
  return rolesData.metrics.filter(
    m =>
      moment(simple ? m.groupingValue : m.metricLabel).format(dateFormat) === x &&
      roleLabel === (simple ? m.metricName : parseInt(m.metricName)),
  );
};

export const getHaveRolesMetricsThisDay = (rangeParameter, rolesData, x, roleLabel, simple = false) => {
  let haveMetricsThisDay;

  if (rolesData.metrics) {
    if (rangeParameter === AnalyticShowDataRangeEnum.WEEK || rangeParameter === AnalyticShowDataRangeEnum.MONTH) {
      haveMetricsThisDay = handleFindRolesDataWeekAndMonth(rolesData, simple, x, roleLabel, 'DD/MM');
    } else if (rangeParameter === AnalyticShowDataRangeEnum.QUARTER) {
      const haveMetricsThisWeek = handleFilterRolesDataQuarter(rolesData, simple, roleLabel, x);
      const accumulateMetricValue = haveMetricsThisWeek.reduce((a, b) => a + b.metricValue, 0);
      if (haveMetricsThisWeek.length) {
        haveMetricsThisDay = { ...haveMetricsThisWeek[0], metricValue: accumulateMetricValue };
      }
    } else {
      const haveMetricsThisMonth = handleFilterRolesDataYear(rolesData, simple, x, roleLabel, 'MM/YY');
      const accumulateMetricValue = haveMetricsThisMonth.reduce((a, b) => a + b.metricValue, 0);
      if (haveMetricsThisMonth.length) {
        haveMetricsThisDay = { ...haveMetricsThisMonth[0], metricValue: accumulateMetricValue };
      }
    }
  }

  return haveMetricsThisDay;
};

const checkBetweenDatesSimple = (m, roleLabel, x) => {
  return (
    moment(m.groupingValue).isSameOrAfter(moment(x)) &&
    moment(m.groupingValue).isBefore(moment(x).add(1, 'w')) &&
    roleLabel === m.metricName
  );
};

const checkBetweenDates = (m, roleLabel, x) => {
  return (
    moment(m.metricLabel).isSameOrAfter(moment(x)) &&
    moment(m.metricLabel).isBefore(moment(x).add(1, 'w')) &&
    roleLabel === parseInt(m.metricName)
  );
};

export const isMonthOrWeek = rangeParameter =>
  rangeParameter === AnalyticShowDataRangeEnum.WEEK || rangeParameter === AnalyticShowDataRangeEnum.MONTH;

const handleCountryDataFilterQuarter = (countryData, x, country) => {
  return countryData.metrics.filter(
    m =>
      moment(m.metricLabel).isSameOrAfter(moment(x)) &&
      moment(m.metricLabel).isBefore(moment(x).add(1, 'w')) &&
      country.label === parseInt(m.metricName),
  );
};

export const getHaveCountriesMetricsThisDay = (countryData, rangeParameter, country, x) => {
  let haveMetricsThisDay;

  if (countryData && countryData.metrics) {
    if (isMonthOrWeek(rangeParameter)) {
      haveMetricsThisDay = countryData.metrics.find(
        m => moment(m.metricLabel).format('DD/MM') === x && country.label === parseInt(m.metricName),
      );
    } else if (rangeParameter === AnalyticShowDataRangeEnum.QUARTER) {
      const haveMetricsThisWeek = handleCountryDataFilterQuarter(countryData, x, country);
      const accumulateMetricValue = haveMetricsThisWeek.reduce((a, b) => a + b.metricValue, 0);
      if (haveMetricsThisWeek.length) {
        haveMetricsThisDay = { ...haveMetricsThisWeek[0], metricValue: accumulateMetricValue };
      }
    } else {
      const haveMetricsThisMonth = countryData.metrics.filter(
        m => moment(m.metricLabel).format('MM/YY') === x && country.label === parseInt(m.metricName),
      );
      const accumulateMetricValue = haveMetricsThisMonth.reduce((a, b) => a + b.metricValue, 0);
      if (haveMetricsThisMonth.length) {
        haveMetricsThisDay = { ...haveMetricsThisMonth[0], metricValue: accumulateMetricValue };
      }
    }
  }

  return haveMetricsThisDay && haveMetricsThisDay.metricValue;
};

const getIndicatorKey = (indicatorKey, isRole) => {
  if (indicatorKey === AnalyticsKeyEnum.AGGREGATE_INDICATORS_LOGINS_COMPLEX) {
    return isRole
      ? AnalyticsKeyEnum.AGGREGATE_INDICATORS_LOGINS_COMPLEX_BY_ROLE
      : AnalyticsKeyEnum.AGGREGATE_INDICATORS_LOGINS_COMPLEX_BY_COUNTRY;
  } else {
    return isRole
      ? AnalyticsKeyEnum.AGGREGATE_INDICATORS_USERS_WHO_ENTERED_THE_TOOL_COMPLEX_BY_ROLE
      : AnalyticsKeyEnum.AGGREGATE_INDICATORS_USERS_WHO_ENTERED_THE_TOOL_COMPLEX_BY_COUNTRY;
  }
};

const getSummarizedValues = (concept, summarizedValues) => {
  const data = summarizedValues.find(m => parseInt(m.metricName) === concept.label);
  return data ? data.metricValue : 0;
};

const addAggregateIndicatorsLoginsComplex = (
  analyticList,
  analyticIndicators,
  rangeParameter,
  indicatorKey,
  regions,
) => {
  const rolesData = getIndicatorData(analyticList, getIndicatorKey(indicatorKey, true));

  if (!rolesData) {
    const data = {
      indicatorKey,
      title: setComplexTitle(indicatorKey),
    };

    analyticIndicators.push(data);
    return;
  }
  const summarizedRolesValues = rolesData.metrics.filter(m => m.metricLabel === 'NONE');
  rolesData.metrics = rolesData.metrics.filter(a => a.metricValue !== 0);
  const countryData = getIndicatorData(analyticList, getIndicatorKey(indicatorKey, false));
  const summarizedCountriesValues =
    countryData && countryData.metrics ? countryData.metrics.filter(m => m.metricLabel === 'NONE') : [];
  const xRange: any = createXRange(rangeParameter);
  const rolesDataset: any = getRolesList();

  const rolesAndDatesDataSetObject = rolesDataset.map((role, ind) => {
    const data = xRange.map(x => {
      const haveMetricsThisDay = getHaveRolesMetricsThisDay(rangeParameter, rolesData, x, role.label);

      return haveMetricsThisDay && haveMetricsThisDay.metricValue;
    });

    const accumulateValues =
      AnalyticsKeyEnum.AGGREGATE_INDICATORS_USERS_WHO_ENTERED_THE_TOOL_COMPLEX === indicatorKey
        ? getSummarizedValues(role, summarizedRolesValues)
        : getAccumulatedValues(data);

    const slicedData = data;

    return {
      label: role.label,
      data: slicedData,
      backgroundColor: BarChartColorPalleteEnum[role.label - 1],
      barThickness: 7,
      totalAccumulated: accumulateValues,
      minBarLength: 2,
    };
  });

  const countriesList: any = [];

  regions.forEach(r => {
    r.countries.forEach(c => {
      countriesList.push({ label: c.id, name: c.name, code: c.code });
    });
  });

  //COUNTRY DATA SET
  const countriesAndDatesDataSetObject = countriesList.map(country => {
    const data = xRange.map(x => {
      return getHaveCountriesMetricsThisDay(countryData, rangeParameter, country, x);
    });

    const accumulateValues =
      AnalyticsKeyEnum.AGGREGATE_INDICATORS_USERS_WHO_ENTERED_THE_TOOL_COMPLEX === indicatorKey
        ? getSummarizedValues(country, summarizedCountriesValues)
        : getAccumulatedValues(data);

    const slicedData = data;
    return {
      label: country.code,
      name: country.name,
      data: slicedData,
      backgroundColor: CountriesColorPalleteEnum[country.code],
      barThickness: 7,
      totalAccumulated: accumulateValues,
    };
  });

  const getAccumulatedRoles = () => {
    const groupedRoles = groupBy(rolesData.metrics, 'metricName');
    const accumulateData: any = [];
    const accumulateRange: any = [];
    Object.keys(groupedRoles).forEach(g => {
      const totalData = groupedRoles[g].reduce((a, b) => a + b.metricValue, 0);
      accumulateData.push(totalData);
      accumulateRange.push(`role_analytics_id_${g}`);
    });

    return { accumulateData, accumulateRange };
  };

  const { accumulateData, accumulateRange } = getAccumulatedRoles();

  const rolesDataSetObject = [
    {
      backgroundColor: '#EDD402',
      barThickness: 7,
      data: accumulateData,
      label: 20,
      xRange: accumulateRange,
    },
  ];

  const getAccumulatedCountries = () => {
    const accumulateCountryData: any = [];
    const accumulateCountryRange: any = [];

    if (countryData && countryData.metrics) {
      const groupedCountries = groupBy(countryData.metrics, 'metricName');
      Object.keys(groupedCountries).forEach(g => {
        const totalData = groupedCountries[g].reduce((a, b) => a + b.metricValue, 0);
        accumulateCountryData.push(totalData);
        accumulateCountryRange.push(countriesList.find(c => c.label === parseInt(g)));
      });
    }

    return { accumulateCountryData, accumulateCountryRange };
  };

  const { accumulateCountryData, accumulateCountryRange } = getAccumulatedCountries();

  const countriesDataSetObject = [
    {
      backgroundColor: '#EDD402',
      barThickness: 7,
      data: accumulateCountryData,
      label: 20,
      xRange: accumulateCountryRange,
      minBarLength: 2,
    },
  ];

  const dataObject = {
    xRange: formatXRangeOnQuarterPeriod(xRange, rangeParameter),
    rolesAndDatesDataSetObject: sortData(rolesAndDatesDataSetObject),
    countriesAndDatesDataSetObject: sortData(countriesAndDatesDataSetObject),
    rolesDataSetObject,
    countriesDataSetObject,
  };

  dataObject.rolesAndDatesDataSetObject.push(groupOthersData(dataObject.rolesAndDatesDataSetObject));
  dataObject.countriesAndDatesDataSetObject.push(groupOthersData(dataObject.countriesAndDatesDataSetObject));

  const data = {
    indicatorKey,
    title: setComplexTitle(indicatorKey),
    data: dataObject,
  };

  analyticIndicators.push(data);
};

const addProjectIndicatorsUseOfSectionsComplex = (analyticList, analyticIndicators, rangeParameter) => {
  const sectionsData = getIndicatorData(analyticList, AnalyticsKeyEnum.PROJECT_INDICATORS_USE_OF_SECTIONS_COMPLEX);
  if (!sectionsData) {
    const data = {
      indicatorKey: AnalyticsKeyEnum.PROJECT_INDICATORS_USE_OF_SECTIONS_COMPLEX,
      title: setComplexTitle(AnalyticsKeyEnum.PROJECT_INDICATORS_USE_OF_SECTIONS_COMPLEX),
      subtitle: 'use_of_sections_indicator_subtitle',
    };

    analyticIndicators.push(data);
    return;
  }
  const sectionsDataset = [
    { label: 'base_information', metricName: 'BASE_INFORMATION' },
    { label: 'planning', metricName: 'PLANNING' },
    { label: 'accordances', metricName: 'ACCORDANCE' },
    { label: 'financial', metricName: 'FINANCIAL_INFORMATION' },
    { label: 'risk', metricName: 'RISCK' },
  ];

  const xRange: any = createXRange(rangeParameter);

  const rolesAndDatesDataSetObject = sectionsDataset.map((section, ind) => {
    const data = xRange.map(x => {
      const haveMetricsThisDay = getHaveRolesMetricsThisDay(rangeParameter, sectionsData, x, section.metricName, true);
      return haveMetricsThisDay && haveMetricsThisDay.metricValue;
    });

    const accumulateValues = getAccumulatedValues(data);
    const slicedData = data;

    return {
      label: section.label,
      data: slicedData,
      backgroundColor: BarChartColorPalleteEnum[ind],
      barThickness: 7,
      totalAccumulated: accumulateValues,
      minBarLength: 2,
    };
  });

  const dataObject = {
    xRange,
    rolesAndDatesDataSetObject,
  };

  const dataToSend = {
    indicatorKey: AnalyticsKeyEnum.PROJECT_INDICATORS_USE_OF_SECTIONS_COMPLEX,
    title: setComplexTitle(AnalyticsKeyEnum.PROJECT_INDICATORS_USE_OF_SECTIONS_COMPLEX),
    subtitle: 'use_of_sections_indicator_subtitle',
    data: dataObject,
  };

  analyticIndicators.push(dataToSend);
};

const createXRange = (rangeParameter: AnalyticShowDataRangeEnum) => {
  const xRange: any = [];
  const today = moment();
  if (rangeParameter === AnalyticShowDataRangeEnum.WEEK) {
    let _1WeekBefore = moment().subtract(1, 'w');
    _1WeekBefore = _1WeekBefore.add(1, 'days');
    while (_1WeekBefore.isSameOrBefore(today)) {
      xRange.push(_1WeekBefore.format('DD/MM'));
      _1WeekBefore.add(1, 'days');
    }
  } else if (rangeParameter === AnalyticShowDataRangeEnum.MONTH) {
    const _1MonthBefore = moment().subtract(1, 'M');

    while (_1MonthBefore.isSameOrBefore(today)) {
      xRange.push(_1MonthBefore.format('DD/MM'));
      _1MonthBefore.add(1, 'days');
    }
  } else if (rangeParameter === AnalyticShowDataRangeEnum.QUARTER) {
    const _1QuarterBefore = moment().subtract(3, 'M');
    while (_1QuarterBefore.isSameOrBefore(today)) {
      xRange.push(_1QuarterBefore.format('YYYY/MM/DD'));
      _1QuarterBefore.add(1, 'w');
    }
  } else {
    const _1YearBefore = moment().subtract(1, 'y');
    while (_1YearBefore.isSameOrBefore(today)) {
      xRange.push(_1YearBefore.format('MM/YY'));
      _1YearBefore.add(1, 'month');
    }
  }

  return xRange;
};

const sortData = data => {
  return data.sort((a, b) => (a.totalAccumulated > b.totalAccumulated ? -1 : 1));
};

export const groupOthersData = data => {
  let item = {};

  if (data.length > 0) {
    const sortedData = data.sort((a, b) => (a.totalAccumulated > b.totalAccumulated ? -1 : 1));
    const othersData = sortedData.length > 4 && cloneDeep(sortedData.slice(4, sortedData.length));
    const othersTotalAccumulated = othersData.reduce(
      (a, b) => a + (!isNaN(b.totalAccumulated) ? b.totalAccumulated : 0),
      0,
    );
    const othersTotal = othersData[0].data;

    othersData.slice(1, othersData.length).forEach(element => {
      element.data.forEach((value, ind) => {
        othersTotal[ind] = othersTotal[ind] + (!isNaN(value) ? value : 0);
      });
    });

    item = {
      ...othersData[0],
      data: othersTotal,
      label: 'various',
      name: 'various',
      backgroundColor: '#f7dd00',
      totalAccumulated: othersTotalAccumulated,
    };
  }

  return item;
};

const formatXRangeOnQuarterPeriod = (xrange, rangeParameter) => {
  return rangeParameter === AnalyticShowDataRangeEnum.QUARTER
    ? xrange.map(x => moment(x).format('DD/MM/YYYY'))
    : xrange;
};

export {
  addAggregateIndicatorsLoginsComplex,
  addProjectIndicatorsUseOfSectionsComplex,
  formatXRangeOnQuarterPeriod,
  getRolesList,
  getAccumulatedValues,
  getIndicatorKey,
  getSummarizedValues,
};
