import { Pagination } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { setFollowUnfollowProject } from '../../../api/projectApi';
import usePaginationSort from '../../../hooks/usePaginationSort';
import useTable from '../../../hooks/useTable';
import {
  EarlyWarningSecondSortCriteria,
  EarlyWarningVirtualColumns,
  EWProjectResponse,
  FOLLOWED_PROJECTS,
  LoadingState,
  PROJECT_ASSIGNED_TO_ME,
} from '../../../models/EarlyWarningsSection';
import { CriteriaType } from '../../../models/PaginationIndicator';
import { setBulletColor } from '../../../services/util';
import { isMobile } from '../../../services/booleanUtil';
import EyeButton from '../../Util/Buttons/EyeButton/EyeButton';
import { BidTooltip } from '../../Util/Tooltip/Tooltip';
import styles from './ProjectsWithEarlyWarningsTable.module.scss';
import { useDispatch } from 'react-redux';
import { updateLoadingStateEarlyWarningsProjects } from '../../../redux/actions/earlyWarningsProjects';
import { AddColumns } from '../AddColumns/AddColumns';
import { useTranslation } from 'react-i18next';
import './ProjectsWithEarlyWarningsTable.scss';
import PaginationTable from '../../PortfolioDetail/PaginationTable/PaginationTable';
import useDataNormalize from '../../../hooks/useDataNormalize';
import { ExcelDownloadButton } from '../../Util/ExcelDownloadButton/ExcelDownloadButton';
import InfoEarlyWarnings from '../InfoEarlyWarnings/InfoEarlyWarnings';
type Props = {
  projects: EWProjectResponse[];
};
const headers = ['id', 'Project', 'Name', 'Pending Signature', 'Elegibles Pending First Disbursement', 'Last 3 PMR', 'Safeguard Performance', 'Partial Cancellations', 'Expired Disbursement Deadline',];

const selectedKeys = ['id', 'code', 'title', 'pendingSignature', 'eligibleUnDisbursement', 'pmr', 'esgSafeguard', 'cancelled', 'expired'];

const {
  tableContainer,
  tableHeader,
  headerTitleClickable,
  headerTitle,
  rowList,
  tableRow,
  rowValue,
  rowValueName,
  lastColumn,
  fw,
  tableRowAssignedToMe,
  projectTypeBullet,
  link,
} = styles;

export const ProjectsWithEarlyWarningsTable = ({ projects }: Props) => {
  const dispatch = useDispatch();
  const { handleClick: handleClickToProject } = useTable();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { t } = useTranslation();
  const { handlePaginationChange, handleSort, currentList, pagesCount, currentPage, handleResetPagination } =
    usePaginationSort({
      projects,
      defaultSortCriteria: 'code',
      pageCount: rowsPerPage,
    });
  const [updatedCurrentList, setUpdatedCurrentList] = useState(currentList);
  useEffect(() => {
    setUpdatedCurrentList(currentList);
  }, [currentList]);

  useEffect(() => {
    handleResetPagination(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects]);

  const handleFollow = (projectId: number) => {
    setFollowUnfollowProject(projectId);
    setUpdatedCurrentList(
      currentList.map(cl => {
        if (cl.id === projectId) {
          cl.follow = cl.follow === '1' ? '0' : '1';
        }
        return cl;
      }),
    );
  };
  const data = useDataNormalize(headers, selectedKeys, projects);

  return (
    <div className={tableContainer}>
      <div className='info-warnings'>
        <InfoEarlyWarnings />
        <ExcelDownloadButton headers={headers} selectedKeys={selectedKeys} dataExcel={data} />
      </div>
      <div className="border-table">
        <TableHeader handleSort={handleSort} />
        <ul className={rowList}>
          {updatedCurrentList &&
            updatedCurrentList.map(project => {
              const { id: projectId, assigned, code, title, follow, projectProductType } = project;
              const isAssigned = assigned === PROJECT_ASSIGNED_TO_ME;
              const isFollowed = follow === FOLLOWED_PROJECTS;
              const handleClickUpdateAndOpenProject = () => {
                dispatch(updateLoadingStateEarlyWarningsProjects(LoadingState.backFromProjectDetail));
                handleClickToProject({ id: `${projectId}` });
              };

              return (
                <li className={isAssigned ? tableRowAssignedToMe : tableRow} key={projectId}>
                  <BidTooltip
                    title={t('go_to_project_info')}
                    placement="bottom"
                    enterDelay={500}
                    leaveDelay={200}
                    arrow
                  >
                    <p className={`${rowValue} link-ew`} onClick={() => handleClickUpdateAndOpenProject()}>
                      <span className={`projectTypeBullet ${setBulletColor(parseInt(projectProductType))}`}></span>

                      {code}
                    </p>
                  </BidTooltip>
                  <BidTooltip arrow title={title || ''} placement="bottom-start" enterDelay={500} leaveDelay={100}>
                    <p className={rowValueName}>{title}</p>
                  </BidTooltip>
                  <AddColumns project={project} />
                  <p className={rowValue}>
                    <EyeButton follow={isFollowed} handleClick={() => handleFollow(projectId)} />
                  </p>

                  {/* <span className={lastColumn} onClick={() => handleClickUpdateAndOpenProject()}>
                  <p className={fw}></p>
                </span> */}
                </li>
              );
            })}
        </ul>
      </div>
      <div className={isMobile() ? 'pagination-mobile' : 'pagination-style'}>
        <PaginationTable fnSetRows={setRowsPerPage} rows={rowsPerPage} numProyects={projects.length} />
        <Pagination count={pagesCount} page={currentPage} onChange={handlePaginationChange} />
      </div>
    </div>
  );
};

type TableHeaderProps = {
  handleSort: Function;
};
export const TableHeader = ({ handleSort }: TableHeaderProps) => {
  const { t } = useTranslation();
  return (
    <div className={tableHeader}>
      <h4 className={headerTitleClickable} onClick={() => handleSort('code', CriteriaType.string)}>
        {t('project')}
      </h4>
      <h4 className={headerTitleClickable} onClick={() => handleSort('title', CriteriaType.string)}>
        {t('name')}
      </h4>
      <h4
        className={headerTitleClickable}
        onClick={() =>
          handleSort(
            EarlyWarningVirtualColumns.sortablePendingSignature,
            CriteriaType.twoCriteriasSort,
            EarlyWarningSecondSortCriteria.PENDING_SIGNATURE,
          )
        }
      >
        {`${t('pending_signature_table_header_early_warning_section')}`}
      </h4>
      <h4
        className={headerTitleClickable}
        onClick={() =>
          handleSort(
            EarlyWarningVirtualColumns.sortableElegibleUndisbursement,
            CriteriaType.twoCriteriasSort,
            EarlyWarningSecondSortCriteria.ELIGIBLE_UNDISBURSEMENT,
          )
        }
      >
        {`${t('eligible_pending_first_disbursement_with_number')}`}
      </h4>
      <h4
        className={headerTitleClickable}
        onClick={() =>
          handleSort(
            EarlyWarningVirtualColumns.sortablePmrColumn,
            CriteriaType.twoCriteriasSort,
            EarlyWarningSecondSortCriteria.PMR,
          )
        }
      >
        {`${t('last_3_pmr')}`}
      </h4>

      <h4
        className={headerTitleClickable}
        onClick={() =>
          handleSort(
            EarlyWarningVirtualColumns.sortableSafeguardPerformance,
            CriteriaType.twoCriteriasSort,
            EarlyWarningSecondSortCriteria.ESG_SAFEGUARD,
          )
        }
      >
        {t('safeguard_performance')}
      </h4>
      <h4
        className={headerTitleClickable}
        onClick={() =>
          handleSort(
            EarlyWarningVirtualColumns.sortablePartialCancellations,
            CriteriaType.twoCriteriasSort,
            EarlyWarningSecondSortCriteria.CANCELLED,
          )
        }
      >
        {t('partial_cancellations')}
      </h4>

      <h4
        className={headerTitleClickable}
        onClick={() =>
          handleSort(
            EarlyWarningVirtualColumns.sortableDisbursementExpired,
            CriteriaType.twoCriteriasSort,
            EarlyWarningSecondSortCriteria.EXPIRED,
          )
        }
      >
        {t('term_disbursement_due')}
      </h4>

      <h4 className={headerTitle}>{''}</h4>
      {/* <h4 className={headerTitle}>{''}</h4> */}
    </div>
  );
};
