import { FilterManagerialReviewsActionTypes, SET_FILTER_MANAGERIAL_REVIEWS } from '../actions/types';

import { menuTabValuesEnum } from '../../models/MenuTabValuesEnum';
import FilterManagerialReviews from '../../models/FilterManagerialReviews';

const initialState: FilterManagerialReviews = {
  managerialReviewsFilter: {
    menu: [{ id: menuTabValuesEnum.global_bid, label: 'BID_GLOBAL' }],
    menuValue: menuTabValuesEnum.global_bid,
    firstTime: true,
  },
};

const filterManagerialReducer = (
  state = initialState,
  action: FilterManagerialReviewsActionTypes,
): FilterManagerialReviews => {
  switch (action.type) {
    case SET_FILTER_MANAGERIAL_REVIEWS:
      return action.payload;
    default:
      return state;
  }
};
export default filterManagerialReducer;
