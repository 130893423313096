export enum tableRowTypeKeys {
  default,
  labelActions,
  arrowActions,
  deleteOnly,
  editAndDelete,
}

export enum languagesKeys {
  spanish = 'spanish',
  english = 'english',
  portuguese = 'portuguese',
  french = 'french',
}

export enum tutorialsCategory {
  Accordances = 3,
  Reports,
  InitialConfiguration,
  OperativeAlerts,
  IndicatorsAndMonitoring,
}

export enum dataTypes {
  faq,
  tutorials,
  emailQuestions,
  notifications,
  messages,
  tours,
  maintenance,
  leaves,
  delegations,
  catergories,
  criticalIssue,
  possibleAction,
}
