import React from 'react';
import FilterIndicators from '../../../../../models/FilterIndicators';
import { MultiSearchUser } from '../../../MultiSearchUser/MultiSearchUser';
import { RolesIdEnum } from '../../../../../models/RolesIdEnum';

type Props = {
  reportFilters: FilterIndicators;
  reportFiltersChanged: Function;
};
export const TeamLeaderFilter = ({ reportFilters, reportFiltersChanged }: Props) => {
  const [localFilter, setLocalFilter] = React.useState(reportFilters);

  const handleChange = value => {
    if (value === null) {
      value = [];
    }

    const newFilter = {
      ...localFilter,
      common: {
        ...localFilter.common,
        teamLeaders: value,
      },
    };

    setLocalFilter(newFilter);
    reportFiltersChanged({ teamLeaders: newFilter.common.teamLeaders });
  };

  return (
    <MultiSearchUser
      countryFiltered={false}
      returnUsersSelected={value => handleChange(value)}
      filteredRoles={[RolesIdEnum.Team_Leader]}
      clearStyle
      delegatedUsers={localFilter.common.teamLeaders}
    />
  );
};
