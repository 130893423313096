import { ProjectTabValueActionTypes, SET_PROJECT_TAB_VALUE } from '../actions/types';
import { ProjectDetailsTab } from '../../models/ProjectDetailsTabEnum';

const initialState = ProjectDetailsTab.BASE_INFORMATION;

const projectTabValueReducer = (state = initialState, action: ProjectTabValueActionTypes): number => {
  switch (action.type) {
    case SET_PROJECT_TAB_VALUE:
      return action.payload;
    default:
      return state;
  }
};

export default projectTabValueReducer;
