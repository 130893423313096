import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styles from './CollapsedElements.module.scss';
import { collapseNewBox, showHideLane } from '../../../../../../redux/actions/currentProject';

type Props = {
  data: any;
};

const hasLanes = (data: any) => {
  return data && data.lanes;
};

const cardsCount = (data: any, id: number) => {
  if (hasLanes(data)) {
    const foundLane = data.lanes.find(i => i.key === id);
    return foundLane && foundLane.cards ? foundLane.cards.length : 0;
  }
  return 0;
};

const draftKey = 0;
const pendingKey = 1;
const accompliKey = 3;
const cancellKey = 4;

const displayItem = (data: any, id: number) => {
  if (hasLanes(data)) {
    const foundLane = data.lanes.find(i => i.key === id);
    return foundLane
      ? foundLane.style && id === 4
        ? foundLane.style && foundLane.style.display && foundLane.style.display === 'none'
        : foundLane.style && foundLane.style.opacity !== undefined && foundLane.style.opacity === 0
      : false;
  }
  return false;
};

const CollapsedEements = ({ data }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <>
      {displayItem(data, draftKey) ? (
        <div
          className={styles.vertical}
          onClick={() => {
            dispatch(showHideLane(draftKey, true));
          }}
        >
          <span className={styles.text}>{`${t('draft_accordance')} (${cardsCount(data, draftKey)})`}</span>
        </div>
      ) : null}
      {displayItem(data, pendingKey) ? (
        <div
          style={displayItem(data, draftKey) ? { left: '50px' } : { left: 'calc(21vw + 40px)' }}
          className={styles.vertical}
          onClick={() => {
            dispatch(showHideLane(pendingKey, true));
          }}
        >
          <span className={styles.text}>{`${t('pending_accordance')} (${cardsCount(data, pendingKey)})`}</span>
        </div>
      ) : null}
      {displayItem(data, accompliKey) ? (
        <div
          style={
            (!collapseNewBox[0].show && collapseNewBox[1].show) || (!collapseNewBox[1].show && collapseNewBox[0].show)
              ? { left: '70%' }
              : collapseNewBox[1].show && collapseNewBox[0].show
              ? { left: '75%' }
              : !collapseNewBox[1].show && !collapseNewBox[0].show
              ? { left: '51%' }
              : { left: 'calc(21vw + 25px)' }
          }
          className={styles.vertical}
          onClick={() => {
            dispatch(showHideLane(accompliKey, true));
          }}
        >
          <span className={styles.text}>{`${t('accomplished_accordance')} (${cardsCount(data, accompliKey)})`}</span>
        </div>
      ) : null}
      {displayItem(data, cancellKey) ? (
        <>
          <div
            className={styles.cancelled}
            onClick={() => {
              dispatch(showHideLane(cancellKey, true));
            }}
          >
            {`${t('cancelled_accordance')} (${cardsCount(data, cancellKey)})`}
          </div>
          <div className={styles.cancelledExtra}></div>
        </>
      ) : null}
    </>
  );
};

export default CollapsedEements;
