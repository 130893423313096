import React, { useEffect, useState } from 'react';
import styles from './AnalyticsCollapseContainer.module.scss';
import { useTranslation } from 'react-i18next';
import { PanelValue } from '../../../../models/AnalyticsPanelValueEnum';
import { getLocalStatePanel, setLocalStatePanel } from './Util';
import { getAllAnalyticsIndicators } from '../../../../api/contentManagerAnalyticsApi';
import processAnalyticIndicators from '../analyticsHelper';
import { AnalyticsKeyEnum } from '../../../../models/AnalyticsKeyEnum';
import AnalyticIndicatorModal from '../../../Util/Modals/AnalyticIndicatorModal/AnalyticIndicatorModal';
import FilterAnalytics from '../../../../models/FilterAnalytics';
import { useSelector } from 'react-redux';
import CustomAccordion from './CustomAccordion';
import Region from '../../../../models/Region';
import { AnalyticsCardSizeEnum } from '../../../../models/AnalyticsCardSizeEnum';

type Props = {
  setAnalyticsData?: any;
};

const AnalyticsCollapseContainer = ({ setAnalyticsData }: Props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [analyticIndicatorState, setAnalyticIndicatorState] = useState<any>([]);
  const [selectedIndicatorData, setSelectedIndicatorData] = useState('');
  const filterAnalytics = useSelector<any, FilterAnalytics>(state => state.filterAnalytics);
  const regions = useSelector<any, Region[]>(state => state.regions);
  const [sessionIndFilter, setSessionIndFilter] = useState<any>({ value: '0', id: 0, label: t('role') });
  const [aggregatedIndicatorsPanel, setAggregatedIndicatorsPanel] = useState(
    getLocalStatePanel(PanelValue.AGGREGATED_INDICATORS),
  );
  const [portfolioIndicatorsPanel, setPortfolioIndicatorsPanel] = useState(
    getLocalStatePanel(PanelValue.PORTFOLIO_INDICATORS),
  );
  const [projectIndicatorsPanel, setProjectIndicatorsPanel] = useState(
    getLocalStatePanel(PanelValue.PROJECT_INDICATORS),
  );
  const [supportIndicatorsPanel, setSupportIndicatorsPanel] = useState(
    getLocalStatePanel(PanelValue.SUPPORT_INDICATORS),
  );
  const saveCollapseStateSession = (setter, state, panel) => {
    setter(!state);
    setLocalStatePanel(panel, !state);
  };
  const handlepProcessAnalyticIndicators = async datePeriod => {
    setIsLoading(true);
    const analyticsList = await getAllAnalyticsIndicators(filterAnalytics);

    const result = processAnalyticIndicators(analyticsList, datePeriod, regions);
    setAnalyticIndicatorState(result);
    setAnalyticsData(analyticsList);
    setIsLoading(false);
  };

  useEffect(() => {
    handlepProcessAnalyticIndicators(filterAnalytics.dataPeriod.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterAnalytics]);

  const getAnalyticIndicatorData = indKey => {
    return analyticIndicatorState.find(k => k.indicatorKey === indKey);
  };
  const handleModals = indicatorKey => {
    setModalIsOpen(true);
    setSelectedIndicatorData(getAnalyticIndicatorData(indicatorKey));
  };
  const handleMultipleModal = indicatorKey => {
    setModalIsOpen(true);
    setSelectedIndicatorData(getAnalyticIndicatorData(indicatorKey));
  };

  const aggregateIndicators: any[] = [
    { key: AnalyticsKeyEnum.AGGREGATE_INDICATORS_LOGINS },
    {
      key: AnalyticsKeyEnum.AGGREGATE_INDICATORS_LOGINS_COMPLEX,
      size: AnalyticsCardSizeEnum.LARGE,
      openComplexModal: () => handleMultipleModal(AnalyticsKeyEnum.AGGREGATE_INDICATORS_LOGINS_COMPLEX),
      updateSessionIndFilter: item => setSessionIndFilter(item),
    },
    { key: AnalyticsKeyEnum.AGGREGATE_INDICATORS_USERS_WHO_ENTERED_THE_TOOL },
    {
      key: AnalyticsKeyEnum.AGGREGATE_INDICATORS_USERS_WHO_ENTERED_THE_TOOL_COMPLEX,
      size: AnalyticsCardSizeEnum.LARGE,
      openComplexModal: () =>
        handleMultipleModal(AnalyticsKeyEnum.AGGREGATE_INDICATORS_USERS_WHO_ENTERED_THE_TOOL_COMPLEX),
      updateSessionIndFilter: item => setSessionIndFilter(item),
    },
    {
      key: AnalyticsKeyEnum.AGGREGATE_INDICATORS_MOST_USED_SECTION,
      size: AnalyticsCardSizeEnum.MEDIUM,
    },
  ];
  const portfolioIndicators: any[] = [
    {
      key: AnalyticsKeyEnum.PORTFOLIO_INDICATORS_MOST_USED_INDICATORS,
      openModal: () => handleModals(AnalyticsKeyEnum.PORTFOLIO_INDICATORS_MOST_USED_INDICATORS),
    },
    {
      key: AnalyticsKeyEnum.PORTFOLIO_INDICATORS_USE_OF_FILTERS,
      openModal: () => handleModals(AnalyticsKeyEnum.PORTFOLIO_INDICATORS_USE_OF_FILTERS),
    },
    { key: AnalyticsKeyEnum.PORTFOLIO_INDICATORS_GENERATED_REPORTS },
    {
      key: AnalyticsKeyEnum.PORTFOLIO_INDICATORS_INDICATOR_CUSTOMIZATION,
      openModal: () => handleModals(AnalyticsKeyEnum.PORTFOLIO_INDICATORS_INDICATOR_CUSTOMIZATION),
    },
    {
      key: AnalyticsKeyEnum.PORTFOLIO_INDICATORS_USE_OF_TYPE_PROJECT_FILTER,
      size: AnalyticsCardSizeEnum.LARGE,
      openModal: () => handleModals(AnalyticsKeyEnum.PORTFOLIO_INDICATORS_USE_OF_TYPE_PROJECT_FILTER),
    },
  ];

  const projectIndicators: any[] = [
    {
      key: AnalyticsKeyEnum.PROJECT_INDICATORS_USE_OF_SECTIONS_COMPLEX,
      size: AnalyticsCardSizeEnum.LARGE,
      openComplexModal: () => handleMultipleModal(AnalyticsKeyEnum.PROJECT_INDICATORS_USE_OF_SECTIONS_COMPLEX),
    },
    { key: AnalyticsKeyEnum.PROJECT_INDICATORS_GENERATED_REPORTS },
    {
      key: AnalyticsKeyEnum.PROJECT_INDICATORS_MY_PROJECTS,
      openModal: () => handleModals(AnalyticsKeyEnum.PROJECT_INDICATORS_MY_PROJECTS),
    },
    {
      key: AnalyticsKeyEnum.PROJECT_INDICATORS_CALENDAR_SYNCHRONIZATION_WITH_OUTLOOK,
      openModal: () => handleModals(AnalyticsKeyEnum.PROJECT_INDICATORS_CALENDAR_SYNCHRONIZATION_WITH_OUTLOOK),
    },
    {
      key: AnalyticsKeyEnum.PROJECT_INDICATORS_DISBURSEMENT_DATA,
      openModal: () => handleModals(AnalyticsKeyEnum.PROJECT_INDICATORS_DISBURSEMENT_DATA),
    },
    {
      key: AnalyticsKeyEnum.PROJECT_INDICATORS_USE_OF_FILTERS,
      openModal: () => handleModals(AnalyticsKeyEnum.PROJECT_INDICATORS_USE_OF_FILTERS),
    },
    { key: AnalyticsKeyEnum.PROJECT_INDICATORS_AGREEMENTS_NUMBERS },
    {
      key: AnalyticsKeyEnum.PROJECT_INDICATORS_AGREEMENTS_BAR_GRAPH,
      openModal: () => handleModals(AnalyticsKeyEnum.PROJECT_INDICATORS_AGREEMENTS_BAR_GRAPH),
    },
  ];

  const supportIndicators: any[] = [
    { key: AnalyticsKeyEnum.SUPPORT_INDICATORS_REQUESTS_CUSTOMIZATION_BY_COUNTRY },
    { key: AnalyticsKeyEnum.SUPPORT_INDICATORS_INQUIRIES_TO_SUPPORT_ABOUT_DATA_ERRORS },
    { key: AnalyticsKeyEnum.SUPPORT_INDICATORS_SATISFACTION_SURVEY_WITHIN_THE_ONLINE_HELP },
  ];

  return (
    <div className={styles.container}>
      <CustomAccordion
        expanded={aggregatedIndicatorsPanel}
        onChange={() =>
          saveCollapseStateSession(
            setAggregatedIndicatorsPanel,
            aggregatedIndicatorsPanel,
            PanelValue.AGGREGATED_INDICATORS,
          )
        }
        title={t('user_login_to_the_tool')}
        indicatorsList={aggregateIndicators}
        getAnalyticIndicatorData={getAnalyticIndicatorData}
        isLoadingData={isLoading}
      />
      <CustomAccordion
        expanded={portfolioIndicatorsPanel}
        onChange={() =>
          saveCollapseStateSession(
            setPortfolioIndicatorsPanel,
            portfolioIndicatorsPanel,
            PanelValue.PORTFOLIO_INDICATORS,
          )
        }
        title={t('portfolio_indicators')}
        indicatorsList={portfolioIndicators}
        getAnalyticIndicatorData={getAnalyticIndicatorData}
      />
      <CustomAccordion
        expanded={projectIndicatorsPanel}
        onChange={() =>
          saveCollapseStateSession(setProjectIndicatorsPanel, projectIndicatorsPanel, PanelValue.PROJECT_INDICATORS)
        }
        title={t('project_indicators')}
        indicatorsList={projectIndicators}
        getAnalyticIndicatorData={getAnalyticIndicatorData}
      />
      <CustomAccordion
        expanded={supportIndicatorsPanel}
        onChange={() =>
          saveCollapseStateSession(setSupportIndicatorsPanel, supportIndicatorsPanel, PanelValue.SUPPORT_INDICATORS)
        }
        title={t('support_indicators')}
        indicatorsList={supportIndicators}
        getAnalyticIndicatorData={getAnalyticIndicatorData}
      />

      <AnalyticIndicatorModal
        isOpen={modalIsOpen}
        closeModal={() => {
          setModalIsOpen(false);
        }}
        indicatorData={selectedIndicatorData}
        sessionIndFilter={sessionIndFilter}
      />
    </div>
  );
};

export default AnalyticsCollapseContainer;
