import React from 'react';
import { useTranslation } from 'react-i18next';
import SelectOption from '../../../models/SelectOption';
import './CustomSelect.scss';

interface IProps extends React.HTMLProps<JSX.Element> {
  options: SelectOption[];
  style?: object;
  selectedOption?: SelectOption;
  handleSelected: (arg0: SelectOption) => void;
  disabled?: boolean;
  translatedFromBackend?: boolean;
  placeholder?: any;
  error?: boolean;
  heightCustomSelect?: object;
  type?: string;
  listItemStyles?: object;
}

export const selectedItemFirst = (list: SelectOption[], selectedOption?: SelectOption) => {
  if (selectedOption) {
    const result = list.filter((item: SelectOption) => item.value !== selectedOption.value);
    result.unshift(selectedOption);
    return result;
  } else {
    return list;
  }
};

const CustomSelect = ({
  options,
  selectedOption,
  handleSelected,
  style,
  disabled,
  translatedFromBackend,
  placeholder = '',
  error,
  heightCustomSelect,
  type = '',
  listItemStyles = {},
}: IProps) => {
  const { t } = useTranslation();
  const wrapperRef = React.useRef<HTMLInputElement>(null);

  function handleClickOutside(event: { target: any }) {
    if (wrapperRef && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShowList(false);
    }
  }

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  const [showList, setShowList] = React.useState(false);

  const handleClick = () => {
    if (!disabled) {
      setShowList(!showList);
    }
  };
  const handleSelect = (item: SelectOption) => {
    handleSelected(item);
    setShowList(false);
  };

  return (
    <div className={`custom-select-container ${type} label-select-user`} style={style || {}}>
      <span
        className={`custom-select ${error ? 'error' : ''} ${disabled ? 'disabled' : ''}`}
        onClick={handleClick}
        style={heightCustomSelect || {}}
      >
        <p id="selected-option-label">
          {selectedOption ? (translatedFromBackend ? selectedOption.label : t(selectedOption.label)) : placeholder}
        </p>
      </span>
      {showList && (
        <div ref={wrapperRef} className="list-container list-container-user selet-type-project">
          <ul className="list-container-accordance-select">
            {selectedItemFirst(options, selectedOption).map(item => {
              return (
                <li id={item.value} key={item.value} onClick={e => handleSelect(item)}>
                  {translatedFromBackend ? item.label : t(item.label)}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
