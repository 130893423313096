import React from 'react';
import { useTranslation } from 'react-i18next';

interface PdfViewerProps {
    filePath: any;
}

const PdfViewer: React.FC<PdfViewerProps> = ({ filePath }) => {
    const { t } = useTranslation();

    return (
        <object
            data={`${filePath}#zoom=80`}
            type="application/pdf"
            width="100%"
            height="100%"
            aria-label="Document Viewer"
        >
            <p>{t('memory_help.container_tabs.completed.pdf_viewer.viewer_error')}
                {filePath && <a href={filePath} download="downloaded.pdf">{t('memory_help.container_tabs.completed.pdf_viewer.viewer_download')}</a>}
            </p>
        </object>
    );
};

export default PdfViewer;
