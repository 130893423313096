import React from 'react';
import './NewAcquisitionForm.scss';
import { useDispatch } from 'react-redux';
import { initData, mapEditDataToFormData } from './NewItemFormUtil';
import { Acquisition } from '../../../../../models/Planning';
import { createAcquisition, updateAcquisition } from '../../../../../redux/actions/planification';
import { newAcquisition } from '../../../../../api/planningApi';
import CommonInputs from './CommonInputs';
import { PlanningItemType } from './NewPlanningItem';
import ClearButton from './ClearButton';
import { trackActionClicked } from '../../../../../api/trackActionClickedApi';
import { TrackedActions } from '../../../../../models/TrackedActionsEnum';

type Props = {
  hideForm: Function;
  acquisitionData?: Acquisition;
  keyProductId?: number;
};

const noData = (formData: any) => {
  const { name, acquisitionType, startDate, endDate, planAmount, contractAmount, paidAmount, revisionType } = formData;
  return (
    name.trim() === '' &&
    acquisitionType === null &&
    startDate === null &&
    endDate === null &&
    !planAmount &&
    !contractAmount &&
    !paidAmount &&
    revisionType === null
  );
};

const parsedFormData = formData => {
  const result = {
    ...formData,
    revisionType: formData.revisionType ? formData.revisionType.id : null,
    acquisitionType: formData.acquisitionType ? formData.acquisitionType.id : null,
    planAmount: formData.planAmount ? parseInt(formData.planAmount) : 0,
    contractAmount: formData.contractAmount ? parseInt(formData.contractAmount) : 0,
    paidAmount: formData.paidAmount ? parseInt(formData.paidAmount) : 0,
  };
  if (formData.id === null) {
    delete result.id;
  }
  return result;
};

const NewAcquisitionForm = ({ hideForm, acquisitionData, keyProductId }: Props) => {
  const dispatch = useDispatch();
  const wrapperRef = React.useRef<HTMLInputElement>(null);
  const [formData, setFormData] = React.useState<any>({ ...initData });
  const [errors, setErrors] = React.useState<any>({ name: false, acquisitionType: false });

  function handleClickOutside(event: { target: any }) {
    if (wrapperRef && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      submitFromClick();
    }
  }

  const validData = (formData: any) => {
    if (!formData.name.trim()) {
      setErrors({ ...errors, name: true });
    }
    if (!formData.acquisitionType) {
      setErrors({ ...errors, acquisitionType: true });
    }
    return !formData.name.trim() || !formData.acquisitionType ? false : true;
  };

  const dataOkForSubmit = () => {
    if (noData(formData)) {
      hideForm();
      return false;
    }
    if (!validData(formData)) {
      return false;
    }
    return true;
  };

  const submitFromClick = () => {
    if (!dataOkForSubmit()) {
      return;
    }
    formData.id
      ? dispatch(updateAcquisition({ ...parsedFormData(formData), keyProductId }))
      : dispatch(createAcquisition({ ...parsedFormData(formData), keyProductId }));
    hideForm();
    trackActionClicked(TrackedActions.planningInformationAdded);
  };

  const submitFromEnter = async () => {
    if (!dataOkForSubmit()) {
      return;
    }
    if (!formData.id) {
      const response = await newAcquisition({ ...parsedFormData(formData), keyProductId });
      setFormData(mapEditDataToFormData(response));
      trackActionClicked(TrackedActions.planningInformationAdded);
    }
  };

  const updateDescription = value => {
    setFormData({ ...formData, name: value });
    setErrors({ ...errors, name: false });
  };

  const updateAcquisitionType = item => {
    setFormData({ ...formData, acquisitionType: item });
    setErrors({ ...errors, acquisitionType: false });
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  React.useEffect(() => {
    if (acquisitionData) {
      setFormData(mapEditDataToFormData(acquisitionData));
    }
  }, [acquisitionData]);
  return (
    <div ref={wrapperRef} className="new-acquisition-form-container">
      <CommonInputs
        itemType={PlanningItemType.Acquisition}
        formData={formData}
        errors={errors}
        descriptionChange={updateDescription}
        descriptionSubmit={submitFromEnter}
        acquisitionTypeChange={updateAcquisitionType}
        startDateChange={value => setFormData({ ...formData, startDate: value })}
        endDateChange={value => setFormData({ ...formData, endDate: value })}
        planAmountChange={value => setFormData({ ...formData, planAmount: value })}
        contractAmountChange={value => setFormData({ ...formData, contractAmount: value })}
        paidAmountChange={value => setFormData({ ...formData, paidAmount: value })}
        revisionTypeChange={item => setFormData({ ...formData, revisionType: item })}
      />
      {!formData.id && <ClearButton onClick={hideForm} />}
    </div>
  );
};

export default NewAcquisitionForm;
