import React from 'react';
import classes from './ReportError.module.scss';
import { styles } from '../Styles';
import { disableEscKey } from '../Util';
import CloseCrossButton from '../../Buttons/CloseCrossButton/CloseCrossButton';
import { useTranslation } from 'react-i18next';
import bgImg from '../../../../assets/report-error.svg';
import BaseModal from '../BaseModal';

type Props = {
  isOpen: boolean;
  closeModal: any;
};
export const ReportError = (props: Props) => {
  const { isOpen, closeModal } = props;
  const { content, container, closeCrossContainer, horizontal, iconImage, optionsList, subtitle } = classes;
  const { t } = useTranslation();

  return (
    <BaseModal isOpen={isOpen} onRequestClose={e => disableEscKey(e, closeModal)} style={styles} className={content}>
      <div className={container}>
        <div className={closeCrossContainer}>
          <CloseCrossButton handleClick={closeModal} buttonClass="close-cross-button-white" spanClass="white" />
        </div>
        <div className={horizontal}>
          <div className={iconImage}>
            <img src={bgImg} alt="error" />
          </div>
        </div>
        <div className={optionsList}>
          <h3>{t('error_generating_report')}</h3>
          <span className={subtitle}>{t('try_later')}</span>
        </div>
      </div>
    </BaseModal>
  );
};
