import React from 'react';
import './ContentManager.scss';
import { useSelector } from 'react-redux';
import Header from '../../components/Header/Header';
import { ContentManagerSidetabs } from '../../components/ContentManager/ContentManagerSidetabs/ContentManagerSidetabs';
import User from '../../models/User';

const ContentManager = () => {
  const user = useSelector<any, User>(state => state.user);

  return (
    <React.Fragment>
      <div className="content-home">
        <div className="header">
          <Header isContentManager />
        </div>
      </div>
      {user.id && user.userId ? (
        <div>
          <ContentManagerSidetabs />
        </div>
      ) : null}
    </React.Fragment>
  );
};

export { ContentManager };
