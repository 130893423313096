import {
  CurrentIndicatorActionTypes,
  SET_CURRENT_INDICATOR,
  RESET_CURRENT_INDICATOR,
  UPDATE_CURRENT_INDICATOR_FROM_DETAIL,
} from '../actions/types';
import Indicator from '../../models/Indicator';

export const initialIndicator: Indicator = {
  key: 'default_indicator',
  type: 'vertical',
  data: {
    labels: ['1', '2'],
    datasets: [
      {
        backgroundColor: '#F06D6D',
        borderWidth: 0,
        barThickness: 8,
        label: 'alertChartdata',
      },
    ],
    subLabels: ['1', '2'],
  },
  title: 'initialState',
  subtitle: 'sub title',
  months: [],
  yearNumber: [],
  indicatorKey: 'default_indicator',
};

const currentIndicatorReducer = (state = initialIndicator, action: CurrentIndicatorActionTypes): Indicator => {
  switch (action.type) {
    case SET_CURRENT_INDICATOR:
      return action.payload;
    case RESET_CURRENT_INDICATOR:
      return { ...initialIndicator };
    case UPDATE_CURRENT_INDICATOR_FROM_DETAIL:
      const originalTabIndicatorValues = { ...state.tabIndicatorValues };
      return {
        ...action.payload,
        tabIndicatorValues: originalTabIndicatorValues,
      };
    default:
      return state;
  }
};

export default currentIndicatorReducer;
