import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomDatePicker from '../../Util/CustomDatePicker/CustomDatePicker';
import styles from './KnowledgeSearcher.module.scss';
import Select from 'react-select';
import {
  multiSelectStyle,
  DropdownIndicator,
  multiSelectDarkBackgroundStyle,
} from '../../Filter/FilterComponent.Styles';
import SaveButton from '../../Util/Buttons/SaveButton/SaveButton';
import { TimeRange } from '../../../models/TimeRangeKnowledgeProductsEnum';
import { getDepartmentDivision, getProductTypes } from '../../../api/knowledgeProductApi';
import { SearchObjectContext } from '../KnowledgeProductsDetail/KnowledgeProductDetail';
import { useSelector } from 'react-redux';
import { dateIsNull } from '../../Reviews/ReviewsDate/Util';

type Props = {
  searchSubmit: any;
};

const KnowledgeSearcher = ({ searchSubmit }: Props) => {
  const [openAdvancedSearch, setOpenAdvancedSearch] = useState(false);

  const { t } = useTranslation();
  const [productTypesOptions, setProductTypesOptions] = useState([{ value: 'all', id: 0, label: 'all' }]);
  const [departmentDivisionOptions, setDepartmentDivisionOptions] = useState([{ value: 'all', id: 0, label: 'all' }]);
  const [selectedProductType, setSelectedProductType] = useState<any>([]);
  const [selectedDepartmentDivision, setSelectedDepartmentDivision] = useState<any>([]);
  const [searchContext, setSearchContext] = useContext<any>(SearchObjectContext);
  const [selectedCountry, setSelectedCountry] = useState<any>([]);
  const { countryId: countriesOnGlobalFilter } = useSelector<any, any>(state => state.filterIndicators.common);
  const minDate = new Date(searchContext.minDate);
  const maxDate = new Date(searchContext.maxDate);
  const [countriesWithFixedOptions, setCountriesWithFixedOptions] = useState(searchContext.countries);

  const handleOpenCloseAdvancedSearcher = () => {
    setOpenAdvancedSearch(!openAdvancedSearch);
  };
  const customInputStyle = { width: 270, boxSizing: 'border-box', justifyContent: 'flex-start' };
  const customLabelStyle = {
    color: '#7a8693',
    fontSize: '10px',
    marginBottom: 7,
    marginLeft: 15,
    textTransform: 'capitalize',
  };

  const getProductTypesOptions = async () => {
    const response = await getProductTypes();
    const options: any = [];
    response.forEach(element => {
      options.push({ value: element.name, id: element.id, label: element.name });
    });

    setProductTypesOptions(options);
  };

  const getDepartmentDivisionOptions = async () => {
    const response = await getDepartmentDivision();
    const options: any = [];
    response.forEach(element => {
      options.push({ value: element.name, id: element.name, label: element.name });
    });
    setDepartmentDivisionOptions(options);
  };

  useEffect(() => {
    getProductTypesOptions();
    getDepartmentDivisionOptions();
  }, []);

  useEffect(() => {
    const countriesWithFixed = handleCountriesWithFixedOptions(searchContext, countriesOnGlobalFilter);
    setCountriesWithFixedOptions(countriesWithFixed);
    const setSelectedCountriesWithFixed = countriesWithFixed.filter(wf => wf.hasOwnProperty('isFixed'));
    setSelectedCountry(mergeArrays(setSelectedCountriesWithFixed, selectedCountry));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchContext.countries]);

  const handleProductTypeChange = item => {
    handleSetProductType(item, setSelectedProductType, setSearchContext, searchContext);
  };

  const handleDepartmentDivisionChange = item => {
    handleSetDepartmentDivision(item, setSelectedDepartmentDivision, setSearchContext, searchContext);
  };

  const handleCountryChange = item => {
    handleSetCountry(item, setSelectedCountry, setSearchContext, searchContext);
  };

  const handleClickSearchButton = () => {
    searchSubmit();
  };

  const handleDatePickerFromSelectedDate = date => {
    setSearchContext({
      ...searchContext,
      from: new Date(date).toISOString(),
      componenteHandleDateRanges: false,
    });
  };
  const handleDatePickerToSelectedDate = date => {
    setSearchContext({
      ...searchContext,
      to: new Date(date).toISOString(),
      componenteHandleDateRanges: false,
    });
  };

  return (
    <div>
      <div>
        <span
          className={openAdvancedSearch ? styles.openArrowModal : styles.searchForm}
          onClick={handleOpenCloseAdvancedSearcher}
        ></span>
      </div>
      <div className={openAdvancedSearch ? styles.searcBox : styles.hide}>
        {openAdvancedSearch && (
          <div className={styles.formLayout}>
            <div className={styles.text}>{t('release_date')}</div>
            <div className={styles.halfHorizontal}>
              <div className={styles.dateSelector}>
                <CustomDatePicker
                  date={searchContext.from && new Date(searchContext.from)}
                  label={''}
                  setDate={date => {
                    handleDatePickerFromSelectedDate(date);
                  }}
                  style={{
                    customInput: customInputStyle,
                    label: customLabelStyle,
                  }}
                  error={false}
                  withDate={searchContext.rangeDate === TimeRange.Everyone && dateIsNull(new Date(searchContext.from))}
                  placeholderComplete={true}
                  minDate={minDate}
                  maxDate={maxDate}
                  disabled={searchContext.rangeDate !== TimeRange.Everyone}
                  customPlaceholder={'from'}
                />
              </div>
              <div className={styles.dateSelector}>
                <CustomDatePicker
                  date={searchContext.to && new Date(searchContext.to)}
                  label={''}
                  setDate={date => {
                    handleDatePickerToSelectedDate(date);
                  }}
                  style={{
                    customInput: customInputStyle,
                    label: customLabelStyle,
                  }}
                  error={false}
                  withDate={searchContext.rangeDate === TimeRange.Everyone && dateIsNull(new Date(searchContext.to))}
                  placeholderComplete={true}
                  minDate={minDate}
                  maxDate={maxDate}
                  disabled={searchContext.rangeDate !== TimeRange.Everyone}
                  customPlaceholder={'until'}
                />
              </div>
            </div>
            <div className={styles.horizontal}>
              <div className={styles.text}>{t('type_of_product')}</div>
              <div className={styles.select}>
                <Select
                  id="productType"
                  value={selectedProductType}
                  isMulti
                  components={{ DropdownIndicator }}
                  name="productType"
                  options={productTypesOptions}
                  className="basic-multi-select"
                  classNamePrefix="All"
                  placeholder={t('all')}
                  onChange={value => handleProductTypeChange(value)}
                  styles={multiSelectDarkBackgroundStyle}
                />
              </div>
            </div>
            <div className={styles.horizontal}>
              <div className={styles.text}>{t('dept_div')}</div>
              <div className={styles.select}>
                <Select
                  id="departmentDivisionSelect"
                  value={selectedDepartmentDivision}
                  isMulti
                  components={{ DropdownIndicator }}
                  name="countries"
                  options={departmentDivisionOptions}
                  className="basic-multi-select"
                  classNamePrefix="All"
                  placeholder={t('all')}
                  onChange={value => handleDepartmentDivisionChange(value)}
                  styles={multiSelectDarkBackgroundStyle}
                />
              </div>
            </div>
            <div className={styles.horizontal}>
              <div className={styles.text}>{t('country')}</div>
              <div className={styles.select}>
                <Select
                  id="countriesSelect"
                  value={searchContext.disabledCountriesSelect ? searchContext.countries[0] : selectedCountry}
                  isMulti
                  components={{ DropdownIndicator }}
                  name="countries"
                  options={countriesWithFixedOptions}
                  className="basic-multi-select"
                  classNamePrefix="All"
                  placeholder={t('all')}
                  onChange={value => handleCountryChange(value)}
                  styles={searchContext.disabledCountriesSelect ? multiSelectStyle : multiSelectDarkBackgroundStyle}
                  isDisabled={searchContext.disabledCountriesSelect}
                  isClearable={selectedCountry && selectedCountry.some(v => !v.isFixed)}
                />
              </div>
            </div>
            <div className={styles.horizontalButton}>
              <div className={styles.button}>
                <SaveButton handleClick={handleClickSearchButton} disabled={false} customText={t('search')} />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default KnowledgeSearcher;

const handleCountriesWithFixedOptions = (searchContext, countriesOnGlobalFilter) => {
  return searchContext.countries.map(c => {
    if (countriesOnGlobalFilter.find(cg => cg.value === c.value)) {
      return { ...c, isFixed: true };
    } else {
      return c;
    }
  });
};

const handleSetProductType = (item, setSelectedProductType, setSearchContext, searchContext) => {
  setSelectedProductType(item);
  setSearchContext({
    ...searchContext,
    productType: !item || !item.length ? [{ value: 'all', id: 0, label: 'all' }] : item,
  });
};

const handleSetDepartmentDivision = (item, setSelectedDepartmentDivision, setSearchContext, searchContext) => {
  setSelectedDepartmentDivision(item);
  setSearchContext({
    ...searchContext,
    departmentDivision: !item || !item.length ? [{ value: 'all', id: 0, label: 'all' }] : item,
  });
};

const handleSetCountry = (item, setSelectedCountry, setSearchContext, searchContext) => {
  setSelectedCountry(item);
  setSearchContext({
    ...searchContext,
    filteredCountries: !item || !item.length ? [{ value: 'all', id: 0, label: 'all' }] : item,
  });
};

const mergeArrays = (one, two) => {
  let jointArray: any = [];
  jointArray = [...one, ...two];
  return Array.from(new Set(jointArray.map(s => s.id))).map(id => {
    return {
      value: jointArray.find(s => s.id === id).value,
      id,
      label: jointArray.find(s => s.id === id).label,
      isFixed: jointArray.find(s => s.id === id).isFixed,
    };
  });
};
