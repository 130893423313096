import React from 'react';
import Dotdotdot from 'react-dotdotdot';
import styles from './ParagraphTooltipOnModal.module.scss';

type Props = {
  message: string;
};

const { paragraphTooltipContainer, tooltip, dottedText, tooltiptext } = styles;
export const ParagraphTooltipOnModal = ({ message }: Props) => {
  return (
    <div className={paragraphTooltipContainer}>
      <div className={tooltip}>
        <Dotdotdot clamp={4} className={dottedText}>
          {message}
        </Dotdotdot>
        <div className={tooltiptext}>
          <p>{message}</p>
        </div>
      </div>
    </div>
  );
};
