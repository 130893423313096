import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  LinkedActionTableTypeEnum,
  TableData,
  TableRowDataAccordances,
  TableRowDataSupervisionPlan,
  TableStateEnum,
} from '../../../../models/EarlyWarningsSection';
import { LinkedActionsModal, OperativeAlertsEnum } from '../../../../models/LinkedActions';
import { LinkedActionsTable } from '../../../EarlyWarnings/LinkedActionsTable/LinkedActionsTable';
import CloseCrossButton from '../../Buttons/CloseCrossButton/CloseCrossButton';
import BaseModal from '../BaseModal';
import { styles } from '../Styles';
import linkedStyles from './LinkedActions.module.scss';

type Props = {
  openModal: boolean;
  closeModal: Function;
  linkedActionInformation: LinkedActionsModal;
  accordances: TableRowDataAccordances[];
  accordanceTableState: TableStateEnum;
  supervisionPlans: TableRowDataSupervisionPlan[];
  supervisionPlanTableState: TableStateEnum;
};

const {
  container,
  content,
  topButtons,
  mainHeader,
  title,
  linkedActionSubtitle,
  firstSubtitle,
  alertNameSubtitle,
  alertDetailTextSubtitle,
  filledRed,
  filledGreen,
  hollowYellow,
  filledYellow,
  hollowRed,
  icon,
  tablesContainer,
  notApplicable,
} = linkedStyles;

const classNameMap = {
  '2': notApplicable,
  '3': filledGreen,
  '4': hollowYellow,
  '5': filledYellow,
  '6': hollowRed,
  '7': filledRed,
};
export const LinkedActions = ({
  openModal,
  closeModal,
  linkedActionInformation,
  accordances,
  accordanceTableState,
  supervisionPlans,
  supervisionPlanTableState,
}: Props) => {
  const { projectCode, projectName, alertDetail, projectId, projectProductType } = linkedActionInformation;

  const { alertName, alertDetailText, alertLight } = alertDetail;
  const { t } = useTranslation();

  useEffect(() => {}, []);

  const accordanceTableData: TableData = {
    linkedActionTableType: LinkedActionTableTypeEnum.ACCORDANCES,
    tHeader: [
      { text: 'description_of_the_agreement' },
      { text: 'type_of_accordance' },
      { text: 'expiration' },
      { text: 'accordance_status' },
      { text: 'last_comment' },
    ],
    tRows: accordances,
    tableState: accordanceTableState,
  };

  const supervisionPlanTableData: TableData = {
    linkedActionTableType: LinkedActionTableTypeEnum.SUPERVISION_PLAN,
    tHeader: [
      { text: 'description' },
      { text: 'type' },
      { text: 'planned_date' },
      { text: 'accordance_status' },
      { text: 'last_comment' },
    ],
    tRows: supervisionPlans,
    tableState: supervisionPlanTableState,
  };

  return (
    <BaseModal
      isOpen={openModal}
      onRequestClose={() => {}}
      style={{ overlay: { ...styles.overlay, ...{ overflowY: 'auto', overflowX: 'hidden' } } }}
      className={container}
    >
      <div className={content}>
        <div className={topButtons}>
          <CloseCrossButton handleClick={closeModal} buttonClass="close-cross-button-medium" spanClass="medium" />
        </div>
        <div className={mainHeader}>
          <div className={title}>
            <p>{`${projectCode}: ${projectName}`}</p>
          </div>
          <div className={linkedActionSubtitle}>
            <p className={firstSubtitle}>{t('actions_linked_to_early_warning')}:</p>
            <p className={alertNameSubtitle}>{t(handleAlertNameTransaltionKey[alertName])}</p>
            <p className={alertDetailTextSubtitle}>{handleDetailText(alertDetailText, alertName, t)}</p>
            <p className={icon}>{alertLight && <span className={classNameMap[alertLight]} />}</p>
          </div>
        </div>
        <div className={tablesContainer}>
          <LinkedActionsTable
            projectProductType={projectProductType}
            projectId={projectId}
            title="accordances"
            tableData={accordanceTableData}
          />
          <LinkedActionsTable
            projectProductType={projectProductType}
            projectId={projectId}
            title="supervision_plan"
            tableData={supervisionPlanTableData}
          />
        </div>
      </div>
    </BaseModal>
  );
};

const handleDetailText = (alertDetailText, alertName, t) => {
  if (alertName === OperativeAlertsEnum.LastThreePMR) {
    const splitText = alertDetailText.split(',');

    return splitText.map((pmrText, i, arr) => {
      if (i === arr.length - 1) {
        return t(pmrText.toLowerCase());
      }
      return `${t(pmrText.toLowerCase())}, `;
    });
  } else if (alertName === OperativeAlertsEnum.EsgSafeguard) {
    return alertDetailText && t(alertDetailText.toLowerCase());
  }

  return alertDetailText;
};

const handleAlertNameTransaltionKey = {
  1: 'earlyWarningInfoTitle1',
  2: 'earlyWarningInfoTitle6',
  3: 'earlyWarningInfoTitle4',
  4: 'earlyWarningInfoTitle2',
  5: 'earlyWarningInfoTitle5',
  6: 'earlyWarningInfoTitle3',
};
