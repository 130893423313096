import {
  field1TcpNationalAndIG,
  field2TcpNationalAndIG,
  field3IG,
  field4IG,
  field5IG,
  operation,
  project,
  projectName,
} from '../../Util';

export const getDynamicTableFormatByInternalFilter = () => {
  return [
    project,
    operation,
    projectName,
    field1TcpNationalAndIG,
    field2TcpNationalAndIG,
    field3IG,
    field4IG,
    field5IG,
  ];
};
