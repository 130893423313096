import React from 'react';
import './NotificationsCard.scss';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import { ReactComponent as Review } from '../../../assets/icons/u_reviews.svg';
import { ReactComponent as Accordance } from '../../../assets/icons/u_reports.svg';
import { ReactComponent as Calendar } from '../../../assets/icons/u_calendar-alt.svg';
import { ReactComponent as Info } from '../../../assets/icons/u_info-circle.svg';
import { ReactComponent as GcmAlert } from '../../../assets/icons/u_exclamation-circle.svg';
import { ReactComponent as ReportIcon } from '../../../assets/icons/u_reports.svg';
import { ReactComponent as ArrowRightIcon } from '../../../assets/icons/u_arrow-right.svg';
import { useTranslation } from 'react-i18next';
import { setProjectTabValue } from '../../../redux/actions/projectTabValue';
import { useDispatch } from 'react-redux';
import { markReaded } from '../../../api/notificationApi';
import { NotificationsTypesEnum as NotificationType } from '../../../models/NotificationsTypesEnum';
import { setTabValue } from '../../../redux/actions/tabValue';
import { tabIndexes } from '../../../models/TabIndexes';
import { setAgendaSelectedByDefault } from '../../../redux/actions/agendaSelectedByDefault';
import { ReportSectionTabs } from '../../../models/ReportEnums';
import { setReportSectionTabValue } from '../../../redux/actions/reportSectionTabValue';

export const notificationIcon = alertType => {
  switch (alertType) {
    case NotificationType.AccordanceCreate:
    case NotificationType.AccordanceCanceled:
    case NotificationType.AccordanceComment:
    case NotificationType.AccordanceApproved:
    case NotificationType.AccordanceToApprove:
    case NotificationType.AccordanceReject:
    case NotificationType.AccordanceCompleted:
      return <Accordance className="icon" />;
    case NotificationType.PortfolioReviewDetails:
    case NotificationType.PortfolioReviewDate:
    case NotificationType.PortfolioReviewCalendarConfirmed:
    case NotificationType.PortfolioReviewPublished:
    case NotificationType.PortfolioReviewProjectRemoved:
    case NotificationType.ContentManagerNotification:
      return <Review className="icon adjust-portfolio" />;
    case NotificationType.CalendarDisbursement:
    case NotificationType.CalendarActivity:
    case NotificationType.CalendarAcquisition:
    case NotificationType.CalendarAccordance:
    case NotificationType.DisbursementReminder:
    case NotificationType.AcquisitionReminder:
    case NotificationType.ActivityReminder:
    case NotificationType.MonitoringAccordanceReminder:
    case NotificationType.RevisionAccordanceReminder:
      return <Calendar className="icon" />;
    case NotificationType.NewGcmAlert:
    case NotificationType.NewEarlyWarningAlert:
      return <GcmAlert className="icon adjust-portfolio" />;
    case NotificationType.EarlyWarningAutoGeneratedReport:
      return <ReportIcon className="icon adjust-portfolio" />;
    default:
      return <Info className="icon" />;
  }
};

const NotificationsCard = ({
  alertType,
  creationDate,
  readed,
  projectCode,
  projectName,
  notificationType,
  projectId,
  accordanceId,
  dueDays,
  aditionalInformation,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const startDate = moment.utc(creationDate);
  const endDate = moment.utc(new Date().toISOString());

  const translatedType = `notifications_title_type_${notificationType}`;

  const accordanceLink = (id, alertType, accordanceId) => {
    markReaded();
    switch (alertType) {
      case NotificationType.AccordanceCreate:
      case NotificationType.AccordanceCanceled:
      case NotificationType.AccordanceComment:
      case NotificationType.AccordanceApproved:
      case NotificationType.AccordanceToApprove:
      case NotificationType.AccordanceReject:
      case NotificationType.AccordanceCompleted:
      case NotificationType.CalendarAccordance:
        history.push(`/projectdetail?id=${id}&accordance=${accordanceId}`);
        dispatch(setProjectTabValue(2));
        break;
      case NotificationType.PortfolioReviewDate:
        history.push(`/projectdetail?id=${id}`);
        break;
      case NotificationType.CalendarDisbursement:
      case NotificationType.CalendarActivity:
      case NotificationType.CalendarAcquisition:
      case NotificationType.DisbursementReminder:
      case NotificationType.AcquisitionReminder:
      case NotificationType.ActivityReminder:
      case NotificationType.NewGcmAlert:
      case NotificationType.NewEarlyWarningAlert:
        history.push(`/projectdetail?id=${id}`);
        break;
      case NotificationType.MonitoringAccordanceReminder:
      case NotificationType.RevisionAccordanceReminder:
        history.push(`/projectdetail?id=${id}`);
        dispatch(setProjectTabValue(2));
        break;
      case NotificationType.PortfolioReviewCalendarConfirmed:
      case NotificationType.PortfolioReviewProjectRemoved:
      case NotificationType.PortfolioReviewDetails:
        history.push(`/home`);
        dispatch(setTabValue(tabIndexes.reviews));
        break;
      case NotificationType.EarlyWarningAutoGeneratedReport:
        history.push(`/home`);
        dispatch(setTabValue(tabIndexes.reports));
        dispatch(setReportSectionTabValue(ReportSectionTabs.ALERTS));
        break;
      case NotificationType.PortfolioReviewPublished:
        history.push(`/home`);
        dispatch(setTabValue(tabIndexes.reviews));
        dispatch(setAgendaSelectedByDefault(true));
        break;
      default:
        return;
    }
  };

  return (
    <div
      onClick={() => accordanceLink(projectId, alertType, accordanceId)}
      className={'alert-card-container'}
    >
      <div className="alert-card">
        <div className='icon-notification'>
        {notificationIcon(alertType)}
        </div>
        <div className='info-notification'>
        <span className="time-stamp">{endDate.to(startDate)}</span>

        <div className="header">
          
          <h2> {dueDays ? t(translatedType).replace('(xx)', dueDays) : t(translatedType)}</h2>
          {/* <span className={readed ? '' : 'red-alert'}></span> */}
        </div>
        <div className="list-of-info">
          {renderNotificationTemplates(alertType, projectCode, projectName, t, aditionalInformation)}
        </div>
        </div>
        <div className='arrow-link'>
            <ArrowRightIcon />
        </div>
      </div>
    </div>
  );
};

export default NotificationsCard;

const renderNotificationTemplates = (alertType, projectCode, projectName, t, aditionalInformation) => {
  const dateFormat = 'DD/MM/YYYY';

  switch (alertType) {
    case NotificationType.PortfolioReviewCalendarConfirmed:
      return aditionalInformation &&
        JSON.parse(aditionalInformation).StartDate &&
        JSON.parse(aditionalInformation).EndDate ? (
        <div className="info-item">
          <p className="body no-title-body">
            {t(`notification_body_type_${alertType}`)
              .replace('xfromx', moment(JSON.parse(aditionalInformation).StartDate).format(dateFormat))
              .replace('xtox', moment(JSON.parse(aditionalInformation).EndDate).format(dateFormat))}
          </p>
        </div>
      ) : null;
    case NotificationType.PortfolioReviewPublished:
      return (
        <div className="info-item">
          <p className="body no-title-body">{t(`notification_body_type_${alertType}`)}</p>
        </div>
      );
    case NotificationType.ContentManagerNotification:
      return (
        <div className="info-item">
          <p className="body">{aditionalInformation}</p>
        </div>
      );
    case NotificationType.EarlyWarningAutoGeneratedReport:
      return (
        <div className="info-item-adjust-padding">
          <p className="body no-title-body">{t('go_to_reports_alerts')}</p>
        </div>
      );
    default:
      return (
        <div className="info-item">
          <p className="title">{projectCode}</p>
          <p className="body">{projectName}</p>
        </div>
      );
  }
};
