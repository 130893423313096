import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UndoButton from '../../Util/Buttons/UndoButton/UndoButton';
import Calendar from '../Calendar/Calendar';
import { CalendarCollapsed } from '../CalendarCollapsed/CalendarCollapsed';
import styles from './SwitchCalendarView.module.scss';
import { ReactComponent as IconBack } from '../../../assets/icons/u_chevron-down-blue.svg';
import { ReactComponent as IconUp } from '../../../assets/icons/u_chevron-up.svg';
import { MoonLoader } from 'react-spinners';
import { style } from '../../Help/HelpModal/HelpModal.Styles';

export const SwitchCalendarView = () => {
  setCalendarStorageState(true);
  const [calendarCollapsed, setCalendarCollapsed] = useState(getCalendarStorageState());

  const [haveNextEvents, setHaveNextEvents] = useState(false);

  const [loader, setLoader] = useState<boolean>(false);

  const { t } = useTranslation();

  const handleCollapsedState = () => {
    //setCalendarStorageState(!calendarCollapsed);
    setLoader(true);
    setCalendarCollapsed(!calendarCollapsed);
  };

  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 3000);
  }, [calendarCollapsed]);

  const handleHaveDataForCollapsedLabel = nextEventsQuatity => {
    setHaveNextEvents(nextEventsQuatity);
  };

  return (
    <div
      className={calendarCollapsed ? styles.switchCollapsedCalendarContainer : styles.switchCompleteCalendarContainer}
    >
      {calendarCollapsed ? (
        <div onClick={handleCollapsedState} className={styles.selectableSection}>
          <div className={styles.titleSection}>
            <h2 className={styles.title}>{t('calendar')}</h2>
            <div className={calendarCollapsed ? styles.subtitle : styles.hideSubtitle}>
              {haveNextEvents ? t('next_events') : t('there_are_no_upcoming_events')}
            </div>
          </div>
          {loader ? (
            <div className={styles.positionSpinner}>
              <MoonLoader size={30} color={'#417ffe'} loading={loader} speedMultiplier={1} />
            </div>
          ) : (
            showCollapsedData(calendarCollapsed, handleHaveDataForCollapsedLabel)
          )}
          {handleButton(calendarCollapsed, t)}
        </div>
      ) : (
        <div onClick={handleCollapsedState} className={`${styles.selectableSection}`}>
          <div className={styles.titleSection}>
            <h2 className={styles.title}>{t('calendar')}</h2>
            <div style={{ marginBottom: '16px' }} className={calendarCollapsed ? styles.hideSubtitle : styles.subtitle}>
              {haveNextEvents ? t('next_events') : t('there_are_no_upcoming_events')}
            </div>
          </div>
          {loader ? (
            <div className={styles.positionSpinner}>
              <MoonLoader size={30} color={'#417ffe'} loading={loader} speedMultiplier={1} />
            </div>
          ) : (
            showCollapsedData(calendarCollapsed, handleHaveDataForCollapsedLabel)
          )}

          {handleButton(calendarCollapsed, t)}
        </div>
      )}

      {showCompleteCalendar(calendarCollapsed)}
    </div>
  );
};

const handleButton = (collapsedState, t) => {
  return (
    <>
      <div className={styles.buttonSection}>
        <UndoButton handleClick={() => {}} customText={t(collapsedState ? 'see_more' : 'see_less')} />
        <span className="icon">{collapsedState ? <IconBack /> : <IconUp />}</span>
      </div>
    </>
  );
};

const showCollapsedData = (collapsedState, handleHaveDataForCollapsedLabel) => {
  return (
    <>
      <div className={`${styles['margin-div_Bubble']}`}>
        <div className={styles.eventBubblesSection}>
          <CalendarCollapsed haveData={handleHaveDataForCollapsedLabel} />
        </div>
      </div>
    </>
  );
};

const showCompleteCalendar = calendarCollapsed => {
  return (
    <div className={calendarCollapsed ? styles.completeCalendarCollapsed : styles.completeCalendarExpanded}>
      <Calendar getData={!calendarCollapsed} />
    </div>
  );
};

const getCalendarStorageState = () => {
  return localStorage.getItem('calendarCollapsed') === null || localStorage.getItem('calendarCollapsed') === 'true';
};

const setCalendarStorageState = newValue => {
  localStorage.setItem('calendarCollapsed', newValue.toString());
};
