import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectsByCountry } from '../../../redux/actions/indicatorProjects';
import { BarChart } from '../../Portfolio/CardIndicator/Cards';
import { HeaderData } from './HeaderData/HeaderData';
import styles from './ProjectsByCountry.module.scss';
import { Table } from './Table/Table';
import { handleDataInDetail } from './Util';
import Country from '../../../models/Country';



const handleDynamicChartDimensions = data => {
  const quantity = data && data.subLabels && data.subLabels.length;
  return {
    mobile: { width: 320, height: quantity ? quantity * 20 : 320 },
    desktop: { width: 320, height: quantity && quantity > 10 ? quantity * 15 : 550, cursor: 'pointer' },
  };
};

const getCountryBy = (regions, id) => {
  for (const region of regions) {
    const countryAux = region.countries.find(cy => cy.id === parseInt(id));
    if (countryAux) {
      return countryAux;
    }
  }
  return { code: 'REG', id: parseInt(id), name: 'regional', regionId: 0 };
};

export const ProjectsByCountry = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentIndicator, regions } = useSelector<any, any>(state => state);
  const { type, data, detailTitle, detailChartFoot, totalProjects } = currentIndicator;
  const COUNTRY_INDICATOR_CODE =
    sessionStorage.getItem('COUNTRY_INDICATOR_CODE') || (data && data.completeGroupingValue[0].trim());

  const [selectedCountry, setSelectedCountry] = useState<Partial<Country>>({});
  const qtyCountries = data && data.labels && data.labels.length;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    chartDetailContainer,
    capitalizeWord,
    subTitle,
    chartSection,
    projectsByCountryContainer,
    chartContainer,
    tableContainer,
    title,
    footerTitle,
    footerSubTitle,
    tableSection,
    tableDetail,
    titleUpper,
    description,
    left,
    right,
  } = styles;

  useEffect(() => {
    setIsLoading(true);
    const firstCountry = getCountryBy(regions, COUNTRY_INDICATOR_CODE);
    setSelectedCountry(firstCountry);
    dispatch(getProjectsByCountry(COUNTRY_INDICATOR_CODE));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const datasetClick = value => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    sessionStorage.removeItem('PaginationIndicator');
    sessionStorage.setItem('COUNTRY_INDICATOR_CODE', value.completeGroupingValue);
    const countrySelected = getCountryBy(regions, value.completeGroupingValue);
    setSelectedCountry(countrySelected);
    dispatch(getProjectsByCountry(value.completeGroupingValue));
  };
  return (
    <div className={projectsByCountryContainer}>
      <div className={chartSection}>
        <div className={chartDetailContainer}>
          <div className={title}>{t(detailTitle)}</div>
          <div className={subTitle}>
            <span className={capitalizeWord}> {t('total')}</span>: {` ${qtyCountries} `}
            {t('countries')}
          </div>
          <div className={chartContainer}>
            <BarChart
              type={type}
              data={handleDataInDetail(currentIndicator)}
              showSubLabels={false}
              listPage={true}
              datasetClick={datasetClick}
              chartDimensions={handleDynamicChartDimensions(data)}
            />
          </div>
          <div className={footerTitle}>{t(detailChartFoot)}</div>
          <div className={footerSubTitle}>
            <span className={capitalizeWord}> {t('total')}</span>: {totalProjects} {t('projects')}
          </div>
        </div>
      </div>
      <div className={tableSection}>
        <div className={tableDetail}>
          <div className={titleUpper}>{t('country')}</div>
          <div className={description}>
            <div className={left}>{selectedCountry.name}</div>
            <div className={right}>
              <HeaderData country={selectedCountry} />
            </div>
          </div>
          <div className={tableContainer}>
            <Table hideLoader={() => setIsLoading(false)} isLoading={isLoading} />
          </div>

        </div>
      </div>
    </div>
  );
};
