import React, { useEffect, useState } from 'react';
import './CustomCheckbox.scss';
import { useSelector } from 'react-redux';
import { isValidProjectTypeFilters } from '../../Filter/ProjectTypeFilter/UtilFunctions';

interface IProps extends React.HTMLProps<JSX.Element> {
  labelText: string;
  highlightedText?: string;
  handleOnClick: any;
  isChecked: boolean;
  bulletColor?: string;
  customStyle?: any;
  disabled?: boolean;
  showProductTypeIcon?: boolean;
  productTypeIconText?: string;
  isSubFilter?: boolean;
  customCheckboxAligment?: boolean;
}
type Props = IProps;
export const CustomCheckbox = ({
  labelText,
  highlightedText,
  handleOnClick,
  isChecked,
  bulletColor,
  customStyle,
  disabled,
  showProductTypeIcon,
  productTypeIconText,
  isSubFilter,
  customCheckboxAligment,
}: Props) => {
  return (
    <label className="custom-checkbox-container" tabIndex={30}>
      {bulletColor && (
        <span
          className="bullet"
          style={{ backgroundColor: bulletColor, display: 'inline-flex', marginRight: 10 }}
        ></span>
      )}
      {showProductTypeIcon && <span className={`product-type-icon-${productTypeIconText}`}>{productTypeIconText}</span>}
      {isSubFilter ? (
        <div className={customCheckboxAligment ? 'sub-filter-label-vertical' : 'sub-filter-label'}>{labelText}</div>
      ) : (
        labelText
      )}
      {highlightedText && (
        <span
          style={{
            backgroundColor: '#fdefef',
            paddingBottom: '2px',
            paddingTop: '2px',
            paddingRight: '4px',
            borderRadius: '4px',
          }}
        >{`${' '} ${highlightedText}`}</span>
      )}
      <input type="checkbox" onChange={() => handleOnClick(!isChecked)} checked={isChecked} disabled={disabled} />
      <span
        className={
          showProductTypeIcon && customCheckboxAligment
            ? 'checkmark-product-type-vertical'
            : showProductTypeIcon && !customCheckboxAligment
            ? 'checkmark-product-type'
            : 'checkmark'
        }
      ></span>
    </label>
  );
};

export default CustomCheckbox;
