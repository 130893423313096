import React from 'react';
import './CustomizableTimeWithoutDisbursementsFilter.scss';
import { useTranslation } from 'react-i18next';
import { renderTimeWithoutDisbursementsFilter } from '../../../../Filter/TimeWithoutDisbursementsFilter/TimeWithoutDisbursementsFilter';
import FilterIndicators from '../../../../../models/FilterIndicators';

type Props = {
  reportFilters: FilterIndicators;
  reportFiltersChanged: Function;
};

export const CustomizableTimeWithoutDisbursementsFilter = ({ reportFilters, reportFiltersChanged }: Props) => {
  const [errorMessages, handleErrorMessages] = React.useState({ isHidden: true, message: '' });
  const [localFilter, setLocalFilter] = React.useState(reportFilters);
  const [from, setFrom] = React.useState(reportFilters.common.timeWithoutDisbursementFrom);
  const [to, setTo] = React.useState(reportFilters.common.timeWithoutDisbursementTo);

  const defaultMaxValue = 0;
  const defaultMinValue = 0;
  const defaultValueFrom = from !== defaultMinValue ? from : 0;
  const defaultValueTo = to !== defaultMinValue ? to : 0;

  const { t } = useTranslation();

  const handleValue = (min, value) => {
    let timeWithoutDisbursementFrom = from;
    let timeWithoutDisbursementTo = to;

    if (min === true) {
      setFrom(+value);
      timeWithoutDisbursementFrom = +value;
    } else {
      setTo(+value);
      timeWithoutDisbursementTo = +value;
    }

    if (timeWithoutDisbursementFrom >= timeWithoutDisbursementTo && timeWithoutDisbursementTo !== 0) {
      handleErrorMessages({ isHidden: false, message: 'right_input_validation' });
    } else {
      handleErrorMessages({ isHidden: true, message: '' });
      if (timeWithoutDisbursementTo === defaultMinValue) {
        timeWithoutDisbursementTo = defaultMaxValue;
        setTo(defaultMaxValue);
      }
    }

    const newFilter = {
      ...localFilter,
      common: {
        ...localFilter.common,
        timeWithoutDisbursementFrom,
        timeWithoutDisbursementTo,
      },
    };
    setLocalFilter(newFilter);
    reportFiltersChanged({
      timeWithoutDisbursementFrom: newFilter.common.timeWithoutDisbursementFrom,
      timeWithoutDisbursementTo: newFilter.common.timeWithoutDisbursementTo,
    });
  };

  return renderTimeWithoutDisbursementsFilter(defaultValueFrom, defaultValueTo, errorMessages, handleValue, t);
};
