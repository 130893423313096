import { UserActionTypes, SET_USER, UPDATE_USER, RESET_USER } from '../actions/types';
import User from '../../models/User';
import Country from '../../models/Country';
import Role from '../../models/Role';
import Language from '../../models/Language';

const countryInitialState: Country = {
  id: 1,
  name: 'Argentina',
  acronym: 'CAR',
  regionId: 1,
  code: 'UY',
};

const roleInitialState: Role = {
  id: 1,
  name: 'Analista de Operaciones',
};

const languageInitialState: Language = {
  name: 'Spanish',
  encodedName: 'es',
};

const initialState: User = {
  id: 0,
  userId: '',
  name: '',
  lastName: '',
  email: '',
  country: countryInitialState,
  language: languageInitialState,
  role: roleInitialState,
  acceptMailDelivery: false,
  syncCalendar: false,
  regions: [],
  delegateRevision: [],
  delegateAccordances: [],
};

const userReducer = (state = initialState, action: UserActionTypes): User => {
  switch (action.type) {
    case SET_USER:
      return action.payload;
    case UPDATE_USER:
      return { ...state, ...action.payload };
    case RESET_USER:
      return initialState;
    default:
      return state;
  }
};

export default userReducer;
