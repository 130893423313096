import React from 'react';
import { SupervisionDetailForm } from '../../../ProjectTabs/Supervision/SupervisionModal/SupervisionDetailForm';

type Props = {
  eventData: any;
  createReminder: any;
  closeModal: Function;
  modalEditStateUpdate: Function;
  currentProjectId: number;
  setHideSeeMoreButton: Function;
};

export const SupervisoryActionEventDetail = ({
  eventData,
  createReminder,
  closeModal,
  modalEditStateUpdate,
  currentProjectId,
  setHideSeeMoreButton,
}: Props) => {
  return (
    eventData && (
      <SupervisionDetailForm
        activity={eventData}
        modalEditStateUpdate={modalEditStateUpdate}
        createReminder={createReminder}
        closeModal={closeModal}
        currentProjectId={currentProjectId}
        setHideSeeMoreButton={setHideSeeMoreButton}
      />
    )
  );
};
