import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import './AccordanceDetail.scss';
import SaveButton from '../../Buttons/SaveButton/SaveButton';
import UndoButton from '../../Buttons/UndoButton/UndoButton';
import { AccordanceState } from '../../../../models/AccordanceState';
import { validInputs } from '../../../ProjectDetail/ProjectTabs/Accordances/NewAccordanceForm/NewAccordanceFormUtil';
import Accordance from '../../../../models/Accordance';
import { getProject } from '../../../../redux/actions/currentProject';
import { useLocation } from 'react-router-dom';
import Project from '../../../../models/Project';

type Props = {
  accordance: Accordance;
  approveAcc: Function;
  rejectAcc: Function;
  deleteAcc: Function;
  setEditForm: Function;
  submitDraftToApproval: Function;
  closeModal: Function;
  changeAccordanceStatus: Function;
  hideApproveButtons?: boolean;
};

const FooterButtons = ({
  accordance,
  approveAcc,
  rejectAcc,
  deleteAcc,
  setEditForm,
  submitDraftToApproval,
  closeModal,
  changeAccordanceStatus,
  hideApproveButtons,
}: Props) => {
  const { t } = useTranslation();
  const {
    accordanceState,
    currentUserCanApprove,
    criticalIssue,
    personInCharge,
    expirationDate,
    possibleAction,
    accordanceType,
    revisionDate,
    accordanceAgreementsType,
    rootCauseCategory,
    rootCause,
    linkedProductsAccordance,
    description,
    criticalIssueDescription,
  } = accordance;

  const dispatch = useDispatch();
  const location = useLocation();

  const currentProject = useSelector<any, Project>(state => state.currentProject);

  React.useEffect(() => {
    dispatch(getProject(accordance.project.id));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  if (accordanceState === AccordanceState.PENDING && currentUserCanApprove && !hideApproveButtons) {
    return (
      <div className="approve-buttons">
        <SaveButton handleClick={approveAcc} customText={t('approve')} customStyle={{ padding: '0px 40px' }} />
        <UndoButton handleClick={rejectAcc} customText={t('reject')} customStyle={{ padding: '0px 40px' }} />
      </div>
    );
  } else if (accordanceState === AccordanceState.REJECT) {
    return (
      <div className="approve-buttons">
        <SaveButton
          handleClick={() => setEditForm(true)}
          customText={t('edit')}
          customStyle={{ padding: '0px 40px' }}
        />
        <UndoButton handleClick={deleteAcc} customText={t('delete_accordance')} customStyle={{ padding: '0px 40px' }} />
      </div>
    );
  } else if (accordanceState === AccordanceState.DRAFT) {
    return (
      <div className="approve-buttons">
        {validInputs(
          criticalIssue,
          personInCharge,
          expirationDate,
          // possibleAction,
          revisionDate,
          accordanceType,
          // semesterOption,
          accordanceAgreementsType,
          linkedProductsAccordance,
          rootCauseCategory,
          rootCause,
          criticalIssueDescription,
          description,
        ) && (
          <SaveButton
            handleClick={() => submitDraftToApproval()}
            customText={currentUserCanApprove ? t('approve') : t('send_for_approval')}
            customStyle={{ padding: '0px 40px' }}
          />
        )}
        <UndoButton handleClick={() => closeModal()} customText={t('close')} customStyle={{ padding: '0px 40px' }} />
      </div>
    );
  } else if (accordanceState === AccordanceState.VALID && !hideApproveButtons) {
    return (
      <div className="approve-buttons">
        <UndoButton
          handleClick={() => changeAccordanceStatus(AccordanceState.CANCELLED)}
          customText={t('cancel')}
          customStyle={{ padding: '0px 40px' }}
          disabled={!currentProject.assignedToMe}
        />
        <SaveButton
          handleClick={() => changeAccordanceStatus(AccordanceState.FULLFILLED)}
          customText={t('accomplish_accordance')}
          customStyle={{ padding: '0px 40px' }}
          disabled={!currentProject.assignedToMe}
        />
      </div>
    );
  } else {
    return <SaveButton handleClick={closeModal} customText={t('close')} customStyle={{ padding: '0px 40px' }} />;
  }
};

export default FooterButtons;
