import { isOnCtTab } from './EarlyWarningsFilters/Util';

const handleValueOrSlash = (value, postFix = '') => {
  if (value === '0' || !value) {
    return '-';
  }
  return `${value} ${postFix}`;
};
const showEarlyWarning = projectTypeFilterOnDetail => {
  return !isOnCtTab(projectTypeFilterOnDetail);
};

export { handleValueOrSlash, showEarlyWarning };
