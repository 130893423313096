import React from 'react';
import Modal from 'react-modal';
import { ProductFileState } from '../../../../../models/ProductInformation';
import { ReactComponent as Check } from '../../../../../assets/selected-tick.svg';
import { ReactComponent as Cross } from '../../../../../assets/close-cross.svg';
import { isMobile } from '../../../../../services/booleanUtil';
import CloseCrossButton from '../../../../Util/Buttons/CloseCrossButton/CloseCrossButton';
import styles from './modalProjectVerificationFiles.module.scss';
import BaseModal from '../../../../Util/Modals/BaseModal';

const {
  modalProductHeader,
  modalProductHeaderTitle,
  modalProductTable,
  modalProductTableHeader,
  modalProductTableHeaderBorder,
  modalProductTableSecondRow,
} = styles;
const customStyles: Modal.Styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10000,
    overflowY: 'auto',
  },
  content: {
    top: isMobile() ? '0%' : '10%',
    left: 0,
    right: 0,
    margin: ' 0 auto',
    bottom: 'auto',
    borderRadius: isMobile() ? '0px' : '15px',
    padding: '30px 53px 57px 51px',
    overflow: 'auto',
    width: '482px',
  },
};
type Props = {
  isOpen: boolean;
  closeModal: any;
  t: Function;
  projectId: number;
  productFileState: ProductFileState[];
};
const ModalProjectVerificationFiles = (props: Props) => {
  const { isOpen, closeModal, t, productFileState } = props;
  const [productsFiles, setProductsFiles] = React.useState<ProductFileState[]>([]);

  React.useEffect(() => {
    productFileState && setProductsFiles(Object.values(productFileState));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productFileState]);
  return (
    <>
      <BaseModal isOpen={isOpen} style={customStyles}>
        <div className={modalProductHeader}>
          <span className={modalProductHeaderTitle}>{t('modal_title_product_file_verification')}</span>
          <div className="close-cross-container">
            <CloseCrossButton handleClick={() => closeModal()} />
          </div>
        </div>
        <table className={modalProductTable}>
          <thead className={modalProductTableHeaderBorder}>
            <tr className={modalProductTableHeader}>
              <th>{t('modal_product_table_title_fist')}</th>
              <th className={modalProductTableSecondRow}>{t('modal_product_table_title_second')}</th>
            </tr>
          </thead>
          <tbody>
            {productsFiles.map(files => (
              <tr key={`modalProductFile_${files.document}`}>
                <td>{files.document}</td>
                <td className={modalProductTableSecondRow}>{setIconPublished(files.isPublished)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </BaseModal>
    </>
  );
};
const setIconPublished = (fileState: any) => {
  if (fileState) {
    return <Check />;
  }
  return <Cross />;
};
export default ModalProjectVerificationFiles;
