import React, { useEffect, useState } from 'react';
import styles from './PcrNextPresentationTable.module.scss';
import { useTranslation } from 'react-i18next';
import { Pagination } from '@material-ui/lab';
import '../../../Util/Pagination/Pagination.scss';
import usePaginationSort from '../../../../hooks/usePaginationSort';
import { CriteriaType } from '../../../../models/PaginationIndicator';
import useTable from '../../../../hooks/useTable';
import {
  betweenMonthToDueDate,
  dueDate,
  formatMethodology,
  percentageFormat,
  renderLikeyToBeSubmittedPcrStatus,
  renderUpcomingSubmissionPcrStatus,
} from '../Util';
import { renderDateOrSlash } from '../../../../services/stringUtil';
import { Tooltip } from '@material-ui/core';
import { ExcelDownloadButton } from '../../../Util/ExcelDownloadButton/ExcelDownloadButton';
import useDataNormalize from '../../../../hooks/useDataNormalize';
import PaginationTable from '../../PaginationTable/PaginationTable';
import { withStyles } from '@material-ui/core/styles';
import { FilterTables } from '../../../Filter/FilterTables/FilterTables';
import { ReactComponent as ArrowUpRight } from '../../../../assets/icons/u_arrow-up-right.svg';
import { ReactComponent as ClockTen } from '../../../../assets/icons/u_clock-ten.svg';
import { Link } from 'react-router-dom';
import ModalPCR from './ModalPcr/ModalPcr';
import moment from 'moment';
import {
  getAccordancesPCR,
  getPCRConvergeData,
  postScheduleDates,
} from '../../../ProjectDetail/ProjectTabs/Accordances/AccordanceTrello/util';
import ModalPcrAccordances from './ModalPcr/ModalPcrAccordances';

const BlackTooltip = withStyles({
  tooltip: {
    //   color: '$ci-color-greyscale-90',
    backgroundColor: '#101319',
    fontSize: '16px',
    fontFamily: 'Rubik',
    fontWeight: '400',
    lineHeight: '20px',
    padding: '8px 12px',
    borderRadius: '8px',
  },
  arrow: {
    fontSize: 20,
    color: '#4A4A4A',
    '&::before': {
      backgroundColor: '#101319',
    },
  },
})(Tooltip);

const {
  container,
  rowValueCentered,
  headerTitleClickableCentered,
  headerTitle,
  rowValue,
  rowList,
  rowValueName,
  fw,
  lastColumn,
  headerTitleClickable,
  tableHeaderUpcomingSubmission,
  tableHeaderLikelyToBeSubmitted,
  tableRowUpcomingSubmission,
  tableRowLikelyToBeSubmitted,
  rowValueCenteredAndUppercase,
  rowValueMoreThanOneMonthAgo,
  rowValueBetweenLastMonth,
  closeRowValue,
  borderTable,
  headerTitleClickableCenteredNoIcon,
  containerIcon,
  textSeeAccordances,
  textSeeAccordancesNull,
} = styles;

const headersUpcoming = [
  'id',
  'last_project',
  'last_operation',
  'name',
  'methodology',
  'contents_helpCenter_roles_team_leader',
  'oc_date_pcr_start',
  'status',
  'due_date_ove',
];

const headersShorTerm = [
  'id',
  'last_project',
  'last_operation',
  'name',
  'date_of_last_disbursement',
  'percentage_disbursed_pcr_next_presentation',
  'probability',
];

interface scheduleDates {
  data: { [key: string]: string };
}

const selectedKeysUpcoming = ['id', 'code', 'loanNumber', 'title', 'field1', 'field10', 'field2', 'field3', 'field4'];
const selectedKeysShorTerm = ['id', 'code', 'loanNumber', 'title', 'field6', 'field5', 'field3'];
type Props = {
  isUpcommingPresentationTable: boolean;
};

const PcrNextPresentationTable = ({ isUpcommingPresentationTable }: Props) => {
  const { t } = useTranslation();
  const { handleClick, projects } = useTable();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dataFilter, setDataFilter] = useState<any>(projects);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenAccordances, setIsModalOpenAccordances] = useState(false);

  const [scheduleDates, setScheduleDates] = useState<scheduleDates | unknown>();
  const [convergeData, setConvergeData] = useState<scheduleDates | unknown>();
  const [accordancesData, setAccordancesData] = useState<any | unknown>();

  const handleOpenModal = async (idProjectAccordances, operationCloseDate, dueDate, instrument) => {
    const data = {
      coDate: moment(operationCloseDate).format(),
      instrument: instrument,
      PCRCOVE: moment(dueDate).format(),
    };

    const resolveData = await postScheduleDates(data);
    const resolveConvergeData = await getPCRConvergeData(idProjectAccordances);

    setScheduleDates(resolveData);
    setConvergeData(resolveConvergeData);
    setIsModalOpen(true);
  };

  const handleOpenModalAccordances = async (idProjectAccordances, code, title, idProjectDetail) => {
    const data = await getAccordancesPCR(idProjectAccordances);
    const addData = data.map(d => ({ ...d, code, title, idProjectDetail }));
    setAccordancesData(addData);
    setIsModalOpenAccordances(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsModalOpenAccordances(false);
  };

  useEffect(() => {
    setDataFilter(projects);
  }, [projects]);

  const { handlePaginationChange, handleSort, currentList, pagesCount, currentPage } = usePaginationSort({
    projects: dataFilter,
    defaultSortCriteria: 'title',
    pageCount: rowsPerPage,
  });

  const handleClickIfHasProjectId = project => {
    if (project.id !== 0) {
      handleClick(project);
    }
  };
  const headers = isUpcommingPresentationTable === true ? headersUpcoming : headersShorTerm;
  const selectedKeys = isUpcommingPresentationTable === true ? selectedKeysUpcoming : selectedKeysShorTerm;
  const data = useDataNormalize(headers, selectedKeys, dataFilter);

  return projects.length ? (
    <div className={container}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <ExcelDownloadButton headers={headers} selectedKeys={selectedKeys} dataExcel={data} />
        <FilterTables
          projects={projects}
          keysFilter={selectedKeys}
          fnDataFilter={setDataFilter}
          disabledNumber={currentPage}
          indicatorKey={'PCR_NEXT_PRESENTATION'}
          filterBtn={isUpcommingPresentationTable}
        />
      </div>

      <div className={borderTable}>
        <div className={handleTableHeaderClassname(isUpcommingPresentationTable)}>
          <h4 className={headerTitleClickable} onClick={() => handleSort('code', CriteriaType.string)}>
            {t('code')}
          </h4>
          {/* <h4 className={headerTitleClickable} onClick={() => handleSort('loanNumber', CriteriaType.string)}>
            {t('operation')}
          </h4> */}
          <h4 className={headerTitleClickable} onClick={() => handleSort('title', CriteriaType.string)}>
            {t('name')}
          </h4>
          {handleTableHeaderByFilter(isUpcommingPresentationTable, { handleSort, t })}
        </div>

        <ul className={rowList}>
          {dataFilter?.length === 0
            ? dataFilter?.map((project, index) => {
                const {
                  id,
                  frontendId,
                  code,
                  loanNumber,
                  title,
                  field1: methodology,
                  field2: operationCloseDate,
                  field3: pcrStatus,
                  field4: dueDate,
                  field5: percentDisbursement,
                  field6: lastDisbursementDate,
                  field7: instrument,
                  field8: idProjectAccordances,
                  field9: hasAccordances,
                  field10: teamLeader,
                } = project;

                return (
                  <li
                    className={handleTableRowClassname(isUpcommingPresentationTable)}
                    key={frontendId}
                    style={id === 0 ? { cursor: 'default' } : {}}
                  >
                    {id === 0 ? (
                      <p className={`${rowValue}`}>{code}</p>
                    ) : (
                      <BlackTooltip title={t('go_to_project_info')} arrow>
                        <Link className={'link'} to={`/projectdetail?id=${id}`}>
                          {code}
                        </Link>
                      </BlackTooltip>
                    )}
                    <BlackTooltip arrow title={title} placement="bottom" enterDelay={500} leaveDelay={100}>
                      <p className={rowValueName}>{title}</p>
                    </BlackTooltip>

                    {handleTableBodyByFilter(isUpcommingPresentationTable, {
                      t,
                      methodology,
                      operationCloseDate,
                      pcrStatus,
                      dueDate,
                      percentDisbursement,
                      lastDisbursementDate,
                      handleOpenModal,
                      handleOpenModalAccordances,
                      instrument,
                      idProjectAccordances,
                      hasAccordances,
                      teamLeader,
                      code,
                      title,
                      id,
                    })}
                  </li>
                );
              })
            : currentList?.map((project, index) => {
                const {
                  id,
                  frontendId,
                  code,
                  loanNumber,
                  title,
                  field1: methodology,
                  field2: operationCloseDate,
                  field3: pcrStatus,
                  field4: dueDate,
                  field5: percentDisbursement,
                  field6: lastDisbursementDate,
                  field7: instrument,
                  field8: idProjectAccordances,
                  field9: hasAccordances,
                  field10: teamLeader,
                } = project;

                return (
                  <li
                    className={handleTableRowClassname(isUpcommingPresentationTable)}
                    key={frontendId}
                    style={id === 0 ? { cursor: 'default' } : {}}
                  >
                    {id === 0 ? (
                      <p className={`${rowValue}`}>{code}</p>
                    ) : (
                      <BlackTooltip title={t('go_to_project_info')} arrow>
                        <Link className={styles['link-table']} to={`/projectdetail?id=${id}`}>
                          {code}
                        </Link>
                      </BlackTooltip>
                    )}
                    <BlackTooltip arrow title={title} placement="bottom" enterDelay={500} leaveDelay={100}>
                      <p className={rowValueName}>{title}</p>
                    </BlackTooltip>

                    {handleTableBodyByFilter(isUpcommingPresentationTable, {
                      t,
                      methodology,
                      operationCloseDate,
                      pcrStatus,
                      dueDate,
                      percentDisbursement,
                      lastDisbursementDate,
                      handleOpenModal,
                      handleOpenModalAccordances,
                      instrument,
                      idProjectAccordances,
                      hasAccordances,
                      teamLeader,
                      code,
                      title,
                      id,
                    })}
                  </li>
                );
              })}
        </ul>
      </div>

      <div className="pagination-style">
        <PaginationTable fnSetRows={setRowsPerPage} rows={rowsPerPage} numProyects={dataFilter?.length} />
        {dataFilter?.length !== 0 ? (
          <Pagination count={pagesCount} page={currentPage} onChange={handlePaginationChange} />
        ) : null}
      </div>
      <ModalPcrAccordances isOpen={isModalOpenAccordances} closeModal={closeModal} data={accordancesData} />
      <ModalPCR isOpen={isModalOpen} closeModal={closeModal} data={scheduleDates} convergeData={convergeData} />
    </div>
  ) : null;
};

const handleTableHeaderClassname = isUpcommingPresentationTable =>
  isUpcommingPresentationTable ? tableHeaderUpcomingSubmission : tableHeaderLikelyToBeSubmitted;

const handleTableRowClassname = isUpcommingPresentationTable =>
  isUpcommingPresentationTable ? tableRowUpcomingSubmission : tableRowLikelyToBeSubmitted;

const handleTableHeaderByFilter = (isUpcommingPresentationTable, params) => {
  const { handleSort, t } = params;
  if (isUpcommingPresentationTable) {
    return (
      <>
        <h4 className={headerTitleClickableCentered} onClick={() => handleSort('field1', CriteriaType.string)}>
          {t('methodology')}
        </h4>
        {/* <h4 className={headerTitleClickableCentered} onClick={() => handleSort('field10', CriteriaType.string)}>
          {t('contents_helpCenter_roles_team_leader')}
        </h4> */}
        <h4 className={headerTitleClickableCentered} onClick={() => handleSort('field2', CriteriaType.date)}>
          {t('oc_date_pcr_start')}
        </h4>
        <h4 className={headerTitleClickableCentered} onClick={() => handleSort('field3', CriteriaType.string)}>{`${t(
          'status',
        )}`}</h4>
        <h4 className={headerTitleClickableCentered} onClick={() => handleSort('field4', CriteriaType.date)}>{`${t(
          'new_delivery_date_to_ove',
        )}`}</h4>
        <h4 className={headerTitleClickableCenteredNoIcon}>{`${t('new_agreements_on_PCR')}`}</h4>
        <h4 className={headerTitleClickableCenteredNoIcon}>{`${t('new_schedule')}`}</h4>
      </>
    );
  } else {
    return (
      <>
        <h4 className={headerTitleClickableCentered} onClick={() => handleSort('field1', CriteriaType.string)}>
          {t('methodology')}
        </h4>
        <h4 className={headerTitleClickableCentered} onClick={() => handleSort('field6', CriteriaType.date)}>
          {t('date_of_last_disbursement')}
        </h4>
        <h4 className={headerTitleClickableCentered} onClick={() => handleSort('field5', CriteriaType.number)}>
          {t('percentage_disbursed_pcr_next_presentation')}
        </h4>
        <h4 className={headerTitleClickableCentered} onClick={() => handleSort('field3', CriteriaType.string)}>{`${t(
          'probability',
        )}`}</h4>
        <h4 className={headerTitleClickableCenteredNoIcon}>{`${t('new_agreements_on_PCR')}`}</h4>
      </>
    );
  }
};
const handleTableBodyByFilter = (isUpcommingPresentationTable, params) => {
  if (isUpcommingPresentationTable) {
    const {
      t,
      methodology,
      operationCloseDate,
      pcrStatus,
      dueDate,
      handleOpenModal,
      handleOpenModalAccordances,
      instrument,
      idProjectAccordances,
      hasAccordances,
      teamLeader,
      code,
      title,
      id,
    } = params;

    const formaterYear = formatMethodology(methodology);

    return (
      <>
        {formaterYear === '2018' ? (
          <a
            className={`${rowValueCentered}`}
            href={`https://idbg.sharepoint.com/teams/ez-SPD/SDV/PD/15%20Project%20Closing%20Report/015.%20Technical%20Documents/2020%20guias%20y%20checklists/2020-Update-to-2018-PCR-Principles-and-Guidelines.pdf`}
            target="_blank"
          >
            <span>{formaterYear}</span>
            <ArrowUpRight />
          </a>
        ) : (
          // <p className={rowValueCentered}>{formaterYear}</p>
          <p className={rowValueCentered}>{formaterYear}</p>
        )}
        {/* <p className={rowValueCentered}>{teamLeader ? teamLeader : '-'}</p> */}
        <p className={rowValueCenteredAndUppercase}>{renderDateOrSlash(operationCloseDate)}</p>
        <p className={rowValueCentered}>{t(renderUpcomingSubmissionPcrStatus(pcrStatus))}</p>
        <p className={handleDueDateOveStyle(dueDate)}>{renderDateOrSlash(dueDate)}</p>
        {hasAccordances === '1' ? (
          <p
            className={textSeeAccordances}
            onClick={() => handleOpenModalAccordances(idProjectAccordances, code, title, id)}
          >
            {t('new_voir_accords_en_pcr')}
          </p>
        ) : (
          <p className={textSeeAccordancesNull}>-</p>
        )}
        {operationCloseDate && dueDate ? (
          <p
            className={containerIcon}
            onClick={() => handleOpenModal(idProjectAccordances, operationCloseDate, dueDate, instrument)}
          >
            <ClockTen />
          </p>
        ) : (
          <p className={containerIcon}>-</p>
        )}
      </>
    );
  } else {
    const {
      t,
      pcrStatus,
      percentDisbursement,
      lastDisbursementDate,
      methodology,
      instrument,
      idProjectAccordances,
      handleOpenModalAccordances,
      hasAccordances,
      code,
      title,
    } = params;
    const formaterYear = formatMethodology(methodology);
    return (
      <>
        {formaterYear === '2018' ? (
          <a
            className={`${rowValueCentered}`}
            href={`https://idbg.sharepoint.com/teams/ez-SPD/SDV/PD/15%20Project%20Closing%20Report/015.%20Technical%20Documents/2020%20guias%20y%20checklists/2020-Update-to-2018-PCR-Principles-and-Guidelines.pdf`}
            target="_blank"
          >
            <span>{formaterYear}</span>
            <ArrowUpRight />
          </a>
        ) : (
          // <p className={rowValueCentered}>{formaterYear}</p>
          <p className={rowValueCentered}>{formaterYear}</p>
        )}
        <p className={rowValueCenteredAndUppercase}>{renderDateOrSlash(lastDisbursementDate)}</p>
        <p className={rowValueCentered}>{percentageFormat(percentDisbursement)}</p>
        <p className={rowValueCentered}>{t(renderLikeyToBeSubmittedPcrStatus(pcrStatus))}</p>
        {hasAccordances === '1' ? (
          <p
            className={textSeeAccordances}
            onClick={() => handleOpenModalAccordances(idProjectAccordances, code, title, true)}
          >
            {t('new_voir_accords_en_pcr')}
          </p>
        ) : (
          <p className={textSeeAccordancesNull}>-</p>
        )}
      </>
    );
  }
};

const handleDueDateOveStyle = (stringDate: string) => {
  if (stringDate === null) {
    return rowValueCenteredAndUppercase;
  } else if (dueDate(new Date(stringDate))) {
    return rowValueMoreThanOneMonthAgo;
  } else if (betweenMonthToDueDate(new Date(stringDate))) {
    return rowValueBetweenLastMonth;
  }
  return rowValueCenteredAndUppercase;
};

export default PcrNextPresentationTable;
