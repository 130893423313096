import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table, TableBody } from '@material-ui/core';
import ContentManagerTableRow from '../ContentManagerTableRow/ContentManagerTableRow';
import { ContentManagerTableContainer } from './ContentMangerTable.Styles';
import { Pagination } from '@material-ui/lab';
import { isNil, isEmpty } from 'lodash';
import { defaultPaginationData } from '../../../api/contentManager';
import { dataTypes } from '../ContentManagerKeys';
import { getNoRecordKey } from '../../Util/ContentManager/ContentManager';

type Props = {
  items: any;
  type: any;
  updateFunction?: any;
  getLanguageTranslationFromKey?: Function;
  displayGenericErrorMessage?: Function;
  languagesData?: any;
  dataInfo?: any;
  dataType?: any;
  resetStyle?: object;
  updateItem?: Function;
};

export default function ContentManagerTable({
  items,
  type,
  updateFunction,
  getLanguageTranslationFromKey,
  displayGenericErrorMessage,
  languagesData,
  dataInfo = {},
  dataType,
  resetStyle = {},
  updateItem = () => {},
}: Props) {
  const { t } = useTranslation();

  const paginate = pageNumber =>
    updateFunction({
      ...defaultPaginationData,
      pageNumber,
    });

  const handleChange = (e, value) => paginate(value);

  return (
    <div>
      {isEmpty(items) ? (
        <div className="content-manager__no-records">{t(`no_records_${getNoRecordKey(dataType, dataTypes)}`)}</div>
      ) : (
        <ContentManagerTableContainer style={resetStyle}>
          <Table>
            <TableBody>
              {items.map(item => (
                <ContentManagerTableRow
                  key={item.id}
                  item={item}
                  type={type}
                  updateFunction={updateFunction}
                  getLanguageTranslationFromKey={getLanguageTranslationFromKey}
                  displayGenericErrorMessage={displayGenericErrorMessage}
                  languagesData={languagesData}
                  updateItem={updateItem}
                />
              ))}
            </TableBody>
          </Table>
          {!isNil(dataInfo) && dataInfo.pageCount > 0 ? (
            <div className="pagination-style-content-manager">
              <Pagination
                count={dataInfo.pageCount}
                page={dataInfo.currentPage}
                onChange={handleChange}
                style={{ display: 'flex', justifyContent: 'flex-start' }}
              />
            </div>
          ) : null}
        </ContentManagerTableContainer>
      )}
    </div>
  );
}
