import React from 'react';
import './CloseCrossButton.scss';

interface IProps extends React.HTMLProps<JSX.Element> {
  handleClick: any;
  buttonClass?: string; //receive medium (default), round, little values, white
  spanClass?: string; //receive medium (default), round, little values, white
}

const CloseCrossButton = React.forwardRef((props: IProps, ref: any) => {
  const { buttonClass, spanClass } = props;
  const handleClick = e => {
    props.handleClick(e);
  };
  return (
    <button className={buttonClass ? buttonClass : 'close-cross-button-medium'} onClick={handleClick} ref={ref}>
      <span className={spanClass ? spanClass : 'medium'} />
    </button>
  );
});
export default CloseCrossButton;
