import { getProductTypesFilterAndIgConfigFromStore } from '../../../../api/filterPortfolioAndProjectUtil';
import { SummaryIGInternalFilters } from '../../../../enums/summary-ig-internal-filters';

const setInheritedIGFilter = () => {
  const { projectProductAmounts } = getProductTypesFilterAndIgConfigFromStore();
  if (projectProductAmounts.length === 0) {
    return SummaryIGInternalFilters.BOTH_IG_FALSE;
  } else if (projectProductAmounts.length === 2) {
    return SummaryIGInternalFilters.BOTH_IG;
  } else if (projectProductAmounts.length === 1 && projectProductAmounts[0].filterType === 1) {
    return SummaryIGInternalFilters.GREATER_3_IG;
  } else if (projectProductAmounts.length === 1 && projectProductAmounts[0].filterType === 0) {
    return SummaryIGInternalFilters.LESS_3_IG;
  }
  return SummaryIGInternalFilters.BOTH_IG_FALSE;
};

export { setInheritedIGFilter };
