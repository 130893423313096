import { TempQuarterLabels, TrendChartInformation } from '../../../models/EarlyWarnings';
import { EarlyWarningButtonType } from '../../../models/EarlyWarningsSection';

const handleChartDataObject = (
  todaySnapshotByAlertType: TrendChartInformation,
  earlyWarningType: EarlyWarningButtonType | string,
  quarterLabels: TempQuarterLabels[],
): TrendChartInformation => {
  return {
    ...todaySnapshotByAlertType,
    chartData: {
      ...todaySnapshotByAlertType.chartData,
      labels: [...todaySnapshotByAlertType.chartData.labels, ...quarterLabels.map(q => q.qLabels)],
      datasets: [
        ...todaySnapshotByAlertType.chartData.datasets.map((ds, idx) => {
          return { ...ds, data: [...ds.data, ...quarterLabels.map(ql => ql[earlyWarningType][idx])] };
        }),
      ],
    },
  };
};

export { handleChartDataObject };
