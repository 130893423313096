import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { getLanguageConfig } from '../api/userApi';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './locales/en.json';
import es from './locales/es.json';
import pt from './locales/pt.json';
import fr from './locales/fr.json';



const backendOptions = {
  loadPath: '/locales/{{lng}}/{{ns}}.json',
  addPath: '/locales/add/{{lng}}/{{ns}}',
  allowMultiLoading: false,
  parse: function (data) {
    return data;
  },
  parsePayload: function (namespace, key, fallbackValue) {
    return { key };
  },
  crossDomain: false,
  withCredentials: false,
  overrideMimeType: false,
  request: function (options, url, payload, callback) {
    sessionStorage.setItem('loadingLanguageFile', 'true');
    getLanguageConfig(localStorage.getItem('i18nextLng') || 'es')
      .then(response => {
        if (response && response.status === 200) {
          let fileLGSSelected: any;
          switch (localStorage.getItem('i18nextLng')) {
            case 'en':
              fileLGSSelected = en;
              break;
            case 'pt':
              fileLGSSelected = pt;
              break;
            case 'fr':
              fileLGSSelected = fr;
              break;
            default:
              fileLGSSelected = es;
              break;
          }
          callback(null, { status: response.status, data: { ...response.data, ...fileLGSSelected } });
        } else {
          callback(null, { status: 200, data: {} });
        }
        sessionStorage.removeItem('loadingLanguageFile');
      })
      .catch(error => {
        sessionStorage.removeItem('loadingLanguageFile');
      });
  },
};

const initOptions = {
  react: {
    wait: true,
    bindI18n: 'languageChanged',
    useSuspense: true,
  },
  lng: localStorage.getItem('i18nextLng') || 'es',
  fallbackLng: [localStorage.getItem('i18nextLng') || 'es'],
  interpolation: {
    escapeValue: false,
  },
  initImmediate: false,
  backend: backendOptions,
};

i18n.use(LanguageDetector).use(HttpApi).use(initReactI18next).init(initOptions);

export default i18n;