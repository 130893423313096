import { AcquisitionType } from '../../../../../models/Planning';
import { ActivityStateEnum } from '../../../../../models/ActivityStateEnum';

export const acquisitionTypes = [
  { value: 'consulting', id: AcquisitionType.Consulting_Firm, label: 'consulting' },
  { value: 'independent_consulting', id: AcquisitionType.Individual_Consulting, label: 'independent_consulting' },
  { value: 'works', id: AcquisitionType.Works, label: 'works' },
  { value: 'assets', id: AcquisitionType.Assets, label: 'assets' },
  { value: 'consulting_service', id: AcquisitionType.Consulting_Service, label: 'consulting_service' },
  { value: 'transfer', id: AcquisitionType.Transfer, label: 'transfer' },
];

export const revisionTypes = [
  { value: 'expost', id: 1, label: 'expost' },
  { value: 'exante', id: 2, label: 'exante' },
];

export const customInputStyle = {
  width: '95%',
};

export const customDatePickerStyle = {
  container: { height: 30, width: '100%', minWidth: 'unset' },
  label: { margin: 0 },
};

export const initData = {
  name: '',
  acquisitionType: null,
  startDate: null,
  endDate: null,
  planAmount: 0,
  contractAmount: 0,
  paidAmount: 0,
  revisionType: null,
  id: null,
  keyProductId: null,
};

export const mapEditDataToFormData = acquisitionData => {
  const {
    name,
    acquisitionType,
    startDate,
    endDate,
    planAmount,
    contractAmount,
    paidAmount,
    revisionType,
    id,
    keyProductId,
  } = acquisitionData;
  return {
    name,
    acquisitionType: acquisitionType ? acquisitionTypes.find(item => item.id === acquisitionType) : null,
    startDate: startDate ? new Date(startDate) : null,
    endDate: endDate ? new Date(endDate) : null,
    planAmount: planAmount || 0,
    contractAmount: contractAmount || 0,
    paidAmount: paidAmount || 0,
    revisionType: revisionType ? revisionTypes.find(item => item.id === revisionType) : null,
    id,
    keyProductId,
  };
};

export const initDataActivity = {
  name: '',
  plannedDate: null,
  realDate: null,
  planAmount: 0,
  contractAmount: 0,
  paidAmount: 0,
  comments: [],
  state: { value: 'pending', id: ActivityStateEnum.pending, label: 'pending' },
  id: null,
  acquisitionId: null,
  activityType: 3,
};

export const stateTypes = [
  { value: 'pending', id: ActivityStateEnum.pending, label: 'pending' },
  { value: 'in_progress', id: ActivityStateEnum.in_progress, label: 'in_progress' },
  { value: 'completed', id: ActivityStateEnum.completed, label: 'completed' },
  { value: 'out_of_time', id: ActivityStateEnum.out_of_time, label: 'out_of_time' },
];

export const mapActivityEditDataToFormData = activityData => {
  const {
    name,
    plannedDate,
    realDate,
    planAmount,
    contractAmount,
    paidAmount,
    state,
    id,
    keyProductId,
    comments,
    activityType,
  } = activityData;
  return {
    name,
    plannedDate: plannedDate ? new Date(plannedDate) : null,
    realDate: realDate ? new Date(realDate) : null,
    planAmount: planAmount || 0,
    contractAmount: contractAmount || 0,
    paidAmount: paidAmount || 0,
    state: state ? stateTypes.find(item => item.id === state) : null,
    id,
    keyProductId,
    comments,
    activityType,
  };
};
