import React from 'react';
import './UploadedFile.scss';
import DeleteIcon from '@material-ui/icons/Delete';

type Props = {
  file: any;
  deleteFile: any;
};

const UploadedFile = (props: Props) => {
  const { file, deleteFile } = props;

  return (
    <div className="accordance-uploaded-file-container">
      <div className="remove-container">
        <span className="remove" onClick={deleteFile}>
          <DeleteIcon style={{ color: 'white' }} fontSize={'small'} />
        </span>
      </div>
      <a href={file.url} target="_blank" rel="noopener noreferrer" className="name">
        {file.name}
      </a>
    </div>
  );
};

export default UploadedFile;
