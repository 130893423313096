import React from 'react';
import '../../../../Util/SelectFilters/SelectFilters.scss';

type Props = {
  options: { value: string, optionLabel: string }[];
  disabled?: boolean;
  handleChange: (value: string) => void;
  selectValue: string;
};

export const SelectAgreements = ({
  options,
  disabled,
  handleChange,
  selectValue,
}: Props) => {
  const selectHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    handleChange(value);
  };

  return (
    <div className="select-filter-container">
      <select
        data-testid="select"
        value={selectValue}
        className={'select-css select-width-large'}
        onChange={selectHandler}
        disabled={disabled}
      >
        {options.map(option => (
          <option key={option.value} value={option.value} className="capitalize">
            {option.optionLabel}
          </option>
        ))}
      </select>
    </div>
  );
};
