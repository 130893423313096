/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { MemoryHelpModal } from './MemoryHelpModal';
import { useFormMemoryHelpContext } from '../../../context/MemoryHelpFormContext';
import { LoadingComponent } from '../../../pages';
import { ReactComponent as IconError } from '../../../assets/icons/u_multiply-circle.svg';
import { useTranslation } from 'react-i18next';
import PDFViewer from './PDFViewer';
import ConfirmContentMemoryHelp from './ConfirmContentMemoryHelp';
import MemoryAidEraser from './MemoryAidEraser';
import ConfirmMemoryHelp from './ConfirmMemoryHelp';
import '../MemoryHelp.scss'

const MemoryHelpViewer = ({ handleOnClick }) => {
    const { t } = useTranslation();

    const [file, setFile] = useState<any>(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const { showConfirmContent, fileRevision, loadingHelpFile, userCanEditRevision, loadingHelpError } = useFormMemoryHelpContext();

    // Update 'file' status when fileRevision changes
    useEffect(() => {
        if (fileRevision && fileRevision.pdfBlob) {
            setFile(URL.createObjectURL(fileRevision.pdfBlob));
        }
    }, [fileRevision]);

    const handleCloseModal = () => setShowModal(false);

    const handleFileSetting = (file: File) => {
        setSelectedFile(file);
    };

    return (
        <div className='c-memory-help'>
            <section className='c-memory-help-viewer'>
                {loadingHelpFile
                    ? <LoadingComponent />

                    // if a file exists, render it | if it does not exist, display an error message
                    : file && !loadingHelpError
                        ? <PDFViewer filePath={file} />
                        : (
                            <div className='alert'>
                                <div className='error'>
                                    <IconError />  <p>{t('memory_help.container_tabs.completed.file_not_found')}</p>
                                </div>
                            </div>
                        )
                }
            </section>

            <section className='c-memory-help-operations'>
                {/* Only show MemoryAidEraser if content has not been committed */}
                {(!showConfirmContent && <MemoryAidEraser onClickHandle={handleOnClick} />)}

                {/* Show ConfirmMemoryHelp only for userCanEditRevision if content has not been committed */}
                {userCanEditRevision && !showConfirmContent && <ConfirmMemoryHelp
                    fileSetting={setFile}
                    isShowModal={setShowModal}
                    fileAttachment={handleFileSetting} />}

                {/* Show ConfirmContentMemoryHelp if the content is confirmed or completed */}
                {showConfirmContent && <ConfirmContentMemoryHelp onClickHandle={handleOnClick} />}
            </section>

            <MemoryHelpModal isOpen={showModal} closeModal={handleCloseModal} selectedFile={selectedFile} />
        </div>
    );
};

export default MemoryHelpViewer;
