import React, { useState } from 'react';
import './PortfolioProjectsDetailContent.scss';
import { PortfolioSimpleIndicatorTable } from '../PortfolioSimpleIndicatorTable/PortfolioSimpleIndicatorTable';
import { ProjectCounter } from '../ProjectCounter/ProjectCounter';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectsSimpleTableIndicator } from '../../../redux/actions/indicatorProjects';
import Indicator from '../../../models/Indicator';
import { getDynamicTableFormat } from '../../../services/util';
import { isTinyTable } from '../../../services/booleanUtil';
import { IndicatorKeyEnum } from '../../../models/IndicatorKeyEnum';
import SummaryHeaderSingleValue from '../SummaryHeaderSingleValue/SummaryHeaderSingleValue';
import { ProjectProductTypes } from '../../../models/ProjectProductTypes';
import { sortTablesByType } from '../../../services/sortsUtil';
import { handleLoading } from '../../utils';

const customValueStyle = { fontSize: 36 };
const customSubtilteStyle = { fontSize: 16, fontWeight: 300, color: '#264377', paddingTop: 10 };

interface ReceivedProps {
  currentIndicator: Indicator;
  indicatorKey: string;
}

type Props = ReceivedProps;

//Función usada por indicador PROJECTS_PENDING_SIGNATURE_OR_LEGISLATIVE_APPROVAL
const handleBlueBoxValues = (currentIndicator, projectTypeFilterOnDetail, getSignature) => {
  const {
    loanBlueBoxSignature,
    loanBlueBoxRatification,
    tcpBlueBoxSignature,
    tcpBlueBoxRatification,
    igrBlueBoxSignature,
    igrBlueBoxRatification,
  } = currentIndicator.blueBoxValues;
  if (projectTypeFilterOnDetail === ProjectProductTypes.Loan) {
    return getSignature ? loanBlueBoxSignature : loanBlueBoxRatification;
  } else if (projectTypeFilterOnDetail === ProjectProductTypes.CT) {
    return getSignature ? tcpBlueBoxSignature : tcpBlueBoxRatification;
  } else {
    return getSignature ? igrBlueBoxSignature : igrBlueBoxRatification;
  }
};

export const PortfolioProjectsDetailContent = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const { currentIndicator, indicatorKey } = props;
  const indicatorProjects = useSelector<any, any[]>(state => state.indicatorProjects);
  const { projectTypeFilterOnDetail } = useSelector<any, any>(state => state.indicatorDetailFilter);
  const dispatch = useDispatch();

  React.useEffect(
    () => {
      getFilteredSimpleIndicators(dispatch, currentIndicator.id, currentIndicator.indicatorKey, setLoading);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentIndicator.id, dispatch, projectTypeFilterOnDetail],
  );

  return (
    <>
      {indicatorKey === IndicatorKeyEnum.PROJECTS_PENDING_SIGNATURE_OR_LEGISLATIVE_APPROVAL && (
        <div className="portfolio-projects-detail-content-header">
          <SummaryHeaderSingleValue
            value={handleBlueBoxValues(currentIndicator, projectTypeFilterOnDetail, true)}
            title={'pending_signing_of_contracts'}
            customValueStyle={customValueStyle}
            customSubtilteStyle={customSubtilteStyle}
          />
          <SummaryHeaderSingleValue
            value={handleBlueBoxValues(currentIndicator, projectTypeFilterOnDetail, false)}
            title={'pending_legislative_ratification'}
            customValueStyle={customValueStyle}
            customSubtilteStyle={customSubtilteStyle}
          />
        </div>
      )}
      <div className="portfolio-projects-detail-content-container">
        <div className={indicatorProjects.length === 0 ? 'wrapper' : 'wrapper adjust-height'}>
          <PortfolioSimpleIndicatorTable
            projects={
              currentIndicator.indicatorKey === IndicatorKeyEnum.PROJECTS_PENDING_DISBURSEMENT_ELIGIBILITY ||
                currentIndicator.indicatorKey ===
                IndicatorKeyEnum.DISBURSEMENT_PROJECTION_CURRENT_YEAR_INCLUDING_REGIONALS ||
                currentIndicator.indicatorKey ===
                IndicatorKeyEnum.PROJECTS_OVERALL_STAGE_CODE_DISBURSING_NO_DISBURSEMENTS_OVER_180_DAYS
                ? sortTablesByType(indicatorProjects, true, 'code', 'string')
                : indicatorProjects
            }
            tableInfo={getDynamicTableFormat(indicatorKey)}
            indicatorKey={indicatorKey}
            loading={loading}
          />
          {/* <ProjectCounter
            arrayToCount={indicatorProjects}
            countProjects={true}
            widthStyle={
              indicatorKey === IndicatorKeyEnum.PROJECTS_PENDING_SIGNATURE_OR_LEGISLATIVE_APPROVAL
                ? '1300px'
                : indicatorKey ===
                  IndicatorKeyEnum.PROJECTS_OVERALL_STAGE_CODE_DISBURSING_NO_DISBURSEMENTS_OVER_180_DAYS
                ? '1600px'
                : isTinyTable(getDynamicTableFormat(indicatorKey).length)
                ? '1024px'
                : '1250px'
            }
            indicatorKey={indicatorKey}
          /> */}
        </div>
      </div>
    </>
  );
};

export const getFilteredSimpleIndicators = (d, currentIndicatorId, currentIndicatorKey, setLoading) => {
  return handleLoading(() => d(getProjectsSimpleTableIndicator(currentIndicatorId, currentIndicatorKey)), setLoading);
};
