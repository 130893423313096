import React from 'react';
import moment from 'moment';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import MyWeek from './MyWeek';
import MyEvent from './MyEvent';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss';

const localizer = momentLocalizer(moment);

type Props = {
  events: any[];
  currentReview: any;
};

const ReadonlyCalendar = ({ events, currentReview }: Props) => {
  return (
    <Calendar
      toolbar={false}
      selectable={false}
      localizer={localizer}
      events={events}
      defaultView={Views.WEEK}
      views={{ month: false, week: MyWeek }}
      defaultDate={new Date(currentReview.startDate)}
      popup={true}
      min={new Date(2010, 9, 19, 9, 0, 0)}
      max={new Date(2050, 9, 28, 18, 0, 0)}
      step={15}
      components={{ event: MyEvent }}
      formats={{ timeGutterFormat: date => localizer.format(date, 'h A') }}
    />
  );
};

export default ReadonlyCalendar;
