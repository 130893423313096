import React from 'react';
import styles from './HelpTooltip.module.scss';

type Props = {
  title: string;
  message: string;
};

export const HelpTooltip = ({ title, message }: Props) => {
  return (
    <div className={styles.helpTooltipContainer}>
      <div className={styles.tooltip}>
        <strong className={styles.helpQuestionSign}>?</strong>
        <div className={styles.tooltiptext}>
          <h3>{title}</h3>
          <p>{message}</p>
        </div>
      </div>
    </div>
  );
};
