import { ProjectProductTypeFilters } from '../../../models/ProjectProductTypeFilters';

const hasNoneIG = (auxFilters: ProjectProductTypeFilters[]) =>
  !auxFilters.includes(ProjectProductTypeFilters.IG_greater_3) &&
  !auxFilters.includes(ProjectProductTypeFilters.IG_lower_3);

const hasSomeIG = (auxFilters: ProjectProductTypeFilters[]) =>
  auxFilters.includes(ProjectProductTypeFilters.IG_greater_3) ||
  auxFilters.includes(ProjectProductTypeFilters.IG_lower_3);

export const showIgrSubFilters = (filterState: ProjectProductTypeFilters[]) => {
  return (
    filterState.includes(ProjectProductTypeFilters.IG_greater_3) ||
    filterState.includes(ProjectProductTypeFilters.IG_lower_3)
  );
};

export const igrIsDisabled = (filterState: ProjectProductTypeFilters[]) => {
  const subFiltersChecked =
    filterState.includes(ProjectProductTypeFilters.IG_greater_3) ||
    filterState.includes(ProjectProductTypeFilters.IG_lower_3);
  const othersChecked =
    !filterState.includes(ProjectProductTypeFilters.Loan) && !filterState.includes(ProjectProductTypeFilters.CT);
  return subFiltersChecked && othersChecked;
};

const clickOnProductTypeIGMaster = (projectProductType, newFilters, auxFilters) => {
  if (projectProductType === -1) {
    if (hasNoneIG(auxFilters)) {
      newFilters = [...auxFilters, ProjectProductTypeFilters.IG_greater_3, ProjectProductTypeFilters.IG_lower_3];
    } else if (hasSomeIG(auxFilters)) {
      newFilters = [...auxFilters];
      const indexGreater = auxFilters.indexOf(ProjectProductTypeFilters.IG_greater_3);
      const indexLower = auxFilters.indexOf(ProjectProductTypeFilters.IG_lower_3);

      if (indexGreater > -1) {
        newFilters = newFilters.filter(type => type !== ProjectProductTypeFilters.IG_greater_3);
      }

      if (indexLower > -1) {
        newFilters = newFilters.filter(type => type !== ProjectProductTypeFilters.IG_lower_3);
      }
    }
  } else {
    newFilters = [...auxFilters, projectProductType];
  }

  return newFilters;
};

export const handleCheckChanges = (
  filterState: ProjectProductTypeFilters[],
  projectProductType: number,
  setterFunction: Function,
  extraArgs: any = {},
) => {
  const auxFilters = [...filterState];
  let newFilters: any = [...auxFilters];

  if (auxFilters.includes(projectProductType)) {
    if (auxFilters.length > 1) {
      const index = auxFilters.indexOf(projectProductType);
      if (index > -1) {
        newFilters = auxFilters.filter(type => type !== projectProductType);
      }
    }
  } else {
    newFilters = clickOnProductTypeIGMaster(projectProductType, newFilters, auxFilters);
  }

  setterFunction(newFilters, filterState, extraArgs);
};
