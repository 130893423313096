import Modal from 'react-modal';

export const styles: Modal.Styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10000,
  },
};

export const overflowYModalStyles: Modal.Styles = {
  ...styles,
  overlay: {
    ...styles.overlay,
    overflowY: 'auto',
  },
};
