import React from 'react';
import { useTranslation } from 'react-i18next';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import './BackButton.scss';
import { isMobile } from '../../../../services/booleanUtil';
import { ReactComponent as IconBack } from '../../../../assets/icons/u_arrow-left.svg';

type Props = {
  handleClick: any;
  customStyle?: {};
};

const BackButton = (props: Props) => {
  const handleClick = props.handleClick;
  const { t } = useTranslation();

  return (
    <div style={props.customStyle || {}} className="back-button-container" onClick={handleClick}>
      {isMobile() ? (
        <ChevronLeftRoundedIcon fontSize="large" />
      ) : (
        <>
          <span className="icon">
            <IconBack />
          </span>
          <span className="text">{t('back')}</span>
        </>
      )}
    </div>
  );
};

export default BackButton;
