import React, { useState } from 'react';
import { getNewFilteredIndicators } from '../../../api/indicatorApi';
import { IndicatorKeyEnum } from '../../../models/IndicatorKeyEnum';
import { processIndicators } from '../../Portfolio/indicatorHelper';
import EsgSafeGuardModal from '../../Util/Modals/EsgSafeGuardModal/EsgSafeGuardModal';
import './ExcecutorLink.scss';

type Props = {
  text: any;
  excecutorId: any;
};
export const ExcecutorLink = ({ text, excecutorId }: Props) => {
  const [openModal, setOpenModal] = useState(false);
  const [currentAdHocIndicator, setCurrentAdHocIndicator] = useState({
    data: {},
  });

  const options = { executorCode: excecutorId };

  const getData = async () => {
    const indicatorsWithSectorFilter = await getNewFilteredIndicators(options);
    const indicatorsProc = processIndicators(indicatorsWithSectorFilter);
    const selectIndicatorProc: any = indicatorsProc.find(
      item => item.indicatorKey === IndicatorKeyEnum.ESG_SAFEGUARD_PERFORMANCE_RATING,
    );

    selectIndicatorProc && setCurrentAdHocIndicator(selectIndicatorProc);
  };

  const handleClick = () => {
    setOpenModal(true);
    getData();
  };

  return (
    <>
      <span className="excecutor-text" onClick={handleClick}>
        {text}
      </span>
      <EsgSafeGuardModal
        isOpen={openModal}
        closeModal={() => setOpenModal(false)}
        title={text}
        chartData={currentAdHocIndicator.data}
      />
    </>
  );
};
