export const handleLoading = (cb, setLoading) => {
  setLoading(true);
  cb()
    .then(() => {
      setLoading(false);
    })
    .catch(error => {
      setLoading(false);
    });
};
