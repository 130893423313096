import React from 'react';
import { renderDateOrSlash } from '../../../../../services/stringUtil';
import { useTranslation } from 'react-i18next';
import './ModalPcr.scss';

const Timeline = ({ dates }) => {
  const { t } = useTranslation();
  return (
    <div className="timeline">
      <div className="timeline-content">
        <div className="timeline-pais">
          <div className="timeline-title">
            <p className="ci-type-text-7">{t('new_start_PCR_(CO)')}</p>
          </div>
          <div className="timeline-date ci-type-text-8">
            <p>{renderDateOrSlash(dates?.coDate)}</p>
          </div>
        </div>
        <div className="timeline-bar">
          <div className="text">
            <p>{t('new_definition_of_PCR_team')}</p>
          </div>
          <div className="bar"></div>
          <div className="date">
            <p>{renderDateOrSlash(dates?.pcrEquipmentDefinition)}</p>
          </div>
        </div>
        <div className="border-right"></div>
        <div className="timeline-bar bar-transp">
          <div className="text">
            <p>{t('new_PCR_draft')}</p>
          </div>
          <div className="bar-two"></div>
          <div className="date">
            <p>{renderDateOrSlash(dates?.pcrDraft)}</p>
          </div>
        </div>
        <div className="border-right"></div>
        <div className="timeline-bar">
          <div className="text">
            <p>{t('new_Sing_off_of_the_team_to_the_draft')}</p>
          </div>
          <div className="bar w112"></div>
          <div className="date">
            <p>{renderDateOrSlash(dates?.teamSingOffDraft)}</p>
          </div>
        </div>
        <div className="border-right"></div>
        <div className="timeline-bar bar-transp h100 w64">
          <div className="text">
            <p>{t('new_PCR_QRR')}</p>
          </div>
          <div className="bar-two w63"></div>
          <div className="date">
            <p>{renderDateOrSlash(dates?.qualityRiskPCR)}</p>
          </div>
        </div>
        <div className="border-right"></div>
        <div className="timeline-bar w140">
          <div className="text">
            <p className="text-post-qrr">{t('new_adjustments_to_the_doc_Post-QRR')}</p>
          </div>
          <div className="bar w112"></div>
          <div className="date">
            <p>{renderDateOrSlash(dates?.postQRR)}</p>
          </div>
        </div>
        <div className="border-right"></div>
        <div className="timeline-bar bar-transp h100 w106">
          <div className="text-1">
            <p>{t('new_VPC/MGR_(PCRA)_approved')}</p>
          </div>
          <div className="bar-two w106"></div>
          <div className="date">
            <p>{renderDateOrSlash(dates?.approvalVPCMGR)}</p>
          </div>
        </div>

        <div className="border-right"></div>
      </div>

      <div className="grafic">
        <div className="timeline-bar bar-transp h100 w106">
          <div className="text-right">
            <p>{t('new_sent_to_borrower')}</p>
          </div>
          <div className="border">
            <div className="border-1">
              <div className="date">
                <p>{renderDateOrSlash(dates?.pcrSendingBorrower)}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="timeline-bar bar-transp h100 w106">
          <div className="text-right">
            <p>{t('new_sent_to_OVE')}</p>
          </div>
          <div className="border">
            <div className="border-2">
              <div className="date">
                <p>{renderDateOrSlash(dates?.shippingOVE)}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="border-right"></div>
      </div>

      <div className="gov">
        <div className="timeline-gov">
          <div className="timeline-bar">
            <div className="text">
              <p className="pb-18">
                <p>{t('new_borrowers_no_objection')}</p>
              </p>
            </div>
            <div className="bar w110"></div>
            <div className="date">
              <p>{renderDateOrSlash(dates?.noObjectionBorrower)}</p>
            </div>
          </div>
          <div className="border-right"></div>
          <div className="timeline-bar bar-transp">
            <div className="bar-two w106"></div>
            <div className="text">
              <p className="ci-type-text-7">{t('new_PCR_disclosed_on_the_internet_(PCRINTER)')} </p>
              <div className="">
                <p>{renderDateOrSlash(dates?.pcrinter)}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="timeline-ove">
          <div className="timeline-bar">
            <div className="text">
              <p className="pb10">{t('new_OVE_Validation_(PCRCOVE)')}</p>
            </div>
            <div className="bar w150"></div>
            <div className="date text">
              <p>{renderDateOrSlash(dates?.oveValidation)}</p>
            </div>
          </div>
          <div className="border-right"></div>
        </div>
      </div>
    </div>
  );
};

export default Timeline;
