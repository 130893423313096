import React, { useEffect, useState } from 'react';
import './DateSelector.scss';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CustomDatePicker from '../../../Util/CustomDatePicker/CustomDatePicker';
import CustomSelect from '../../../Util/CustomSelect/CustomSelect';
import { ReviewsStatusEnum } from '../../../../models/ReviewsStatusEnum';
import { createReview, modifyReview } from '../../../../api/reviewsApi';
import SaveButton from '../../../Util/Buttons/SaveButton/SaveButton';
import ResponsiveCustomDatePicker from '../../../Util/ResponsiveCustomDatePicker/ResponsiveCustomDatePicker';
import { isMobile } from '../../../../services/booleanUtil';
import { checkIfDates, dateIsNull } from '../Util';
import { setCurrentReview } from '../../../../redux/actions/currentReview';

type Props = {
  review?: any;
  isEdit?: boolean;
  showProjects: any;
  fromEditAgenda?: boolean;
};

const minDateFromAgenda = (fromEditAgenda, endDateState, startDateState) => {
  return fromEditAgenda ? endDateState : startDateState;
};

const containerStyle = fromEditAgenda => {
  return fromEditAgenda ? { width: 'auto' } : {};
};

const disabledFromAgenda = fromEditAgenda => {
  return fromEditAgenda || false;
};

export const isWeekday = date => {
  const day = date.getDay();
  return day !== 0 && day !== 6;
};

const hasDatesNotFirstCall = (startDateState, endDateState, firstCall) => {
  return startDateState && endDateState && !firstCall;
};

export const reviewStartEndDate = (date, hour) => {
  return date ? new Date(date.setHours(hour, 0)) : null;
};

export const DateSelector = ({ review, isEdit, showProjects, fromEditAgenda }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id, startDate, endDate, title } = review;
  const minDate = new Date();
  // minDate.setFullYear(minDate.getFullYear() - 5);
  minDate.setDate(minDate.getDate() + 1)
  const [idState, setIdState] = useState<any>(id);
  const [statusDate, setStatusDate] = useState<any>(statusDates[0]);
  const [startDateState, setStartDateState] = useState(checkIfDates(startDate));
  const [endDateState, setEndDateState] = useState(checkIfDates(endDate));
  const [disableSelectProjectsBtn, setDisableSelectProjectsBtn] = useState(true);
  const [isEditState, setIsEditState] = useState(isEdit);
  const [firstCall, setFirstCall] = useState(true);

  const addReview = async () => {
    const newReview = {
      startDate: reviewStartEndDate(startDateState, 9),
      endDate: reviewStartEndDate(endDateState, 18),
      status: statusDate.id,
    };
    const resultData = await createReview(newReview);
    setIdState(resultData.id);
    setIsEditState(true);
    dispatch(setCurrentReview({ ...newReview, id: resultData.id, title }));
  };

  const updateReview = async () => {
    const r = {
      id: idState,
      startDate: reviewStartEndDate(startDateState, 9),
      endDate: reviewStartEndDate(endDateState, 18),
      status: statusDate.id,
    };
    await modifyReview(r);
    dispatch(setCurrentReview({ ...review, ...r, title }));
  };

  useEffect(() => {
    if (hasDatesNotFirstCall(startDateState, endDateState, firstCall)) {
      setDisableSelectProjectsBtn(false);
      isEditState ? updateReview() : addReview();
    }
    setFirstCall(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateState, endDateState, statusDate]);

  const handleStatusDateChange = item => {
    setStatusDate(item);
  };

  const handleChooseProjects = () => {
    showProjects();
  };

  const customInputStyle = { width: 270, boxSizing: 'border-box', justifyContent: 'flex-start' };
  const customLabelStyle = {
    color: '#7a8693',
    fontSize: '10px',
    marginBottom: 7,
    marginLeft: 15,
    textTransform: 'capitalize',
  };

  return (
    <div className="date-selector-container" style={containerStyle(fromEditAgenda)}>
      <div className="start-date">
        {isMobile() ? (
          <ResponsiveCustomDatePicker
            date={startDateState}
            label={t('start')}
            setDate={date => {
              setStartDateState(date);
            }}
            style={{
              customInput: customInputStyle,
              label: customLabelStyle,
            }}
            error={false}
            placeholderComplete={true}
            minDate={minDate}
            withDate={dateIsNull(startDateState)}
            maxDate={endDateState}
            startDate={startDateState}
            endDate={endDateState}
            filterDate={isWeekday}
            disabled={disabledFromAgenda(fromEditAgenda)}
            applyCustomDisabledStyles={true}
          />
        ) : (
          <CustomDatePicker
            date={startDateState}
            label={t('start')}
            setDate={date => {
              setStartDateState(date);
            }}
            style={{
              customInput: customInputStyle,
              label: customLabelStyle,
            }}
            error={false}
            placeholderComplete={true}
            minDate={minDate}
            withDate={dateIsNull(startDateState)}
            maxDate={endDateState}
            filterDate={isWeekday}
            disabled={disabledFromAgenda(fromEditAgenda)}
            applyCustomDisabledStyles={true}
          />
        )}
      </div>
      <div className="end-date">
        {isMobile() ? (
          <ResponsiveCustomDatePicker
            date={endDateState}
            label={t('end')}
            setDate={date => {
              setEndDateState(date);
            }}
            style={{
              customInput: customInputStyle,
              label: customLabelStyle,
            }}
            error={false}
            placeholderComplete={true}
            minDate={minDateFromAgenda(fromEditAgenda, endDateState, startDateState)}
            withDate={dateIsNull(endDateState)}
            startDate={startDateState}
            endDate={endDateState}
            filterDate={isWeekday}
            applyCustomDisabledStyles={true}
          />
        ) : (
          <CustomDatePicker
            date={endDateState}
            label={t('end')}
            setDate={date => {
              setEndDateState(date);
            }}
            style={{
              customInput: customInputStyle,
              label: customLabelStyle,
            }}
            error={false}
            placeholderComplete={true}
            minDate={minDateFromAgenda(fromEditAgenda, endDateState, startDateState)}
            withDate={dateIsNull(endDateState)}
            filterDate={isWeekday}
            applyCustomDisabledStyles={true}
          />
        )}
      </div>
      {!fromEditAgenda && (
        <>
          <div className="status-date">
            <div className="text">{`${t('status')}:`}</div>
            <div className="select">
              <CustomSelect
                options={statusDates}
                handleSelected={handleStatusDateChange}
                selectedOption={statusDate}
                style={{ width: 130 }}
                heightCustomSelect={{ backgroundColor: '#f7f8fc' }}
                type={'review'}
              />
            </div>
          </div>

          <div className="btn-select-projects">
            <SaveButton
              handleClick={handleChooseProjects}
              disabled={disableSelectProjectsBtn}
              customText={t('choose_projects')}
              customStyle={{ width: '235px' }}
            />
          </div>
        </>
      )}
    </div>
  );
};
export const statusDates = [
  { value: 'attempt', id: ReviewsStatusEnum.Attempt, label: 'attempt' },
  { value: 'confirmed', id: ReviewsStatusEnum.Confirmed, label: 'confirmed' },
];
