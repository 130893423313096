import React from 'react';
import { ReactComponent as IconAlertCheck } from '../../../../../assets/icons/u_alert-check.svg';
import { ReactComponent as IconAlertExclamation } from '../../../../../assets/icons/u_alert-exclamacion.svg';
import { ReactComponent as IconAlertMultyple } from '../../../../../assets/icons/u_alert-multyple.svg';
import { useTranslation } from 'react-i18next';
import { renderDateOrSlash } from '../../../../../services/stringUtil';

const ClassificationPlan = ({ classificationData }) => {
  const {
    amountExpiredProcesses,
    idPlan,
    lastNonObjectionDate,
    processExpiredDate,
    purchaseMadePercent,
    qualification,
    qualificationPercent,
    qualificationPurchaseMade,
    processExceedEstimated,
    amountProcessExceedEstimated,
    processExceedReal,
    amountProcessExceedReal,
    status,
  } = classificationData;
  const { t } = useTranslation();

  const renderIcon = qualification => {
    if (qualification == 'RED') {
      return <IconAlertMultyple />;
    } else if (qualification == 'YELLOW') {
      return <IconAlertExclamation />;
    } else {
      return <IconAlertCheck />;
    }
  };

  return (
    <div className="classification">
      <p className="title">
        {t('last_classification_plan')}{' '}
        <span>
          {renderIcon(qualification)} {qualificationPercent?.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]}{' '}
        </span>
      </p>
      <div className="content-classsification">
        <div className="data">
          <h6>{t('last_current_state')} </h6>
          <p>{status}</p>
        </div>
        <div className="data">
          <h6>{t('last_date_last_objection_plan')}</h6>
          <p>
            <IconAlertCheck /> {renderDateOrSlash(lastNonObjectionDate)}{' '}
          </p>
        </div>
        <div className="data">
          <h6>{t('last_processes_with_expired_dates')}</h6>
          <p>
            <IconAlertExclamation />

            {`${processExpiredDate} ${t('last_processes_by')}  ${amountExpiredProcesses?.toLocaleString('en-US', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })} `}
          </p>
        </div>
        <div className="data">
          <h6>{t('last_purchases_made')}</h6>
          <p>
            {' '}
            {renderIcon(qualificationPurchaseMade)} {purchaseMadePercent} %{' '}
          </p>
        </div>

        <div className="data">
          <h6>{t('last_proccess_pending_disbursement')}</h6>
          <p>
            {' '}
            {t('last_exceeds_signing_of_the_contract')} {processExceedEstimated} {t('last_processes_by')}
            {` ${amountProcessExceedEstimated?.toLocaleString('en-US', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })} `}
          </p>

          <p>
            {t('last_exceeds_end_of_the_contract')} {processExceedReal} {t('last_processes_by')}
            {` ${amountProcessExceedReal?.toLocaleString('en-US', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })} `}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ClassificationPlan;
