import React, { useEffect, useState } from 'react';
import CloseCrossButton from '../../Util/Buttons/CloseCrossButton/CloseCrossButton';
import BaseModal from '../../Util/Modals/BaseModal';
import { styles } from '../../Util/Modals/Styles';
import '../MemoryHelp.scss';
import { ReactComponent as IconWarning } from '../../../assets/icons/u_warning.svg';
import { useFormMemoryHelpContext } from '../../../context/MemoryHelpFormContext';
import { MemoryHelModalpProps } from '../models';
import { useTranslation } from 'react-i18next';

export const MemoryHelpModal = ({ isOpen, closeModal, selectedFile }: MemoryHelModalpProps) => {
  const { t } = useTranslation();

  const [base64File, setBase64File] = useState<any | string | null>(null);

  const { handleFileChange, fileRevision, setAttachmentTrigger, setShowConfirmContent, setTrigger } = useFormMemoryHelpContext();

  useEffect(() => {
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        setBase64File(base64String.split(',')[1]); // Get only the base64 part
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setBase64File(fileRevision?.pdfFile);
    }
  }, [selectedFile, fileRevision?.pdfFile]);

  const handleOnClick = async () => {
    setAttachmentTrigger(prev => !prev);
    const payload: any = {
      filepdf: base64File || "",
      fileWord: ""
    };

    await handleFileChange(payload); // Wait to update the file

    setTimeout(() => setTrigger(prev => !prev), 2000);

    setShowConfirmContent(true)
    closeModal();
  };

  return (
    <BaseModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={styles}
      className='modal-bid'
    >
      <div>
        <div className='close'>
          <CloseCrossButton handleClick={closeModal} />
        </div>
        <div className='container'>
          <div className='content'>
            <IconWarning />
            <h3 className='ci-type-display-3'>{t('memory_help.container_tabs.completed.modals.confirm_doc.title')}</h3>
            <p>{t('memory_help.container_tabs.completed.modals.confirm_doc.description')}</p>
          </div>
          <div className='button'>
            <button className={'btn btn-primary-outlined ci-type-display-5'} onClick={closeModal}>
              {t('memory_help.container_tabs.completed.modals.button_cancel')}
            </button>
            <button className={'btn-primary ci-type-display-5'} onClick={handleOnClick}>{t('memory_help.container_tabs.completed.modals.button_confirm')}</button>
          </div>
        </div>
      </div>
    </BaseModal>
  );
}
