import React, { useEffect, useState } from 'react';
import './ReportSendByMail.scss';
import { styles } from '../Styles';
import { disableEscKey } from '../Util';
import CloseCrossButton from '../../Buttons/CloseCrossButton/CloseCrossButton';

import { useTranslation } from 'react-i18next';
import SaveButton from '../../Buttons/SaveButton/SaveButton';
import { CustomEmailsInput } from '../../../Reports/ReportsSection/CustomEmailsInput/CustomEmailsInput';
import { getReportFile, sendReportByMail } from '../../../../api/reportApi';
import LinkText from '../../Buttons/LinkText/LinkText';
import { GetReportsFileTypeEnum } from '../../../../models/ReportEnums';
import BaseModal from '../BaseModal';

type Props = {
  isOpen: boolean;
  closeModal: any;
  submit?: any;
  reportTitle?: string;
  reportId?: any;
  reportFileType?: GetReportsFileTypeEnum;
};
export const ReportSendByMail = (props: Props) => {
  const { isOpen, closeModal, reportTitle, reportId, reportFileType = GetReportsFileTypeEnum.PDF } = props;
  const [message, setMessage] = useState();
  const [disabledButton, setDisabledButton] = useState(true);
  const [emails, setEmails] = useState([]);

  const { t } = useTranslation();
  const handleMessage = value => {
    setMessage(value);
  };

  useEffect(() => {
    emails.length === 0 ? setDisabledButton(true) : setDisabledButton(false);
  }, [emails]);

  const sendReport = async () => {
    setDisabledButton(true);
    const result = await sendReportByMail(emails, message, reportId, reportTitle);
    result === 200 ? closeModal() : setDisabledButton(false);
  };

  const handleDownloadPdf = () => {
    getReportFile(reportId, reportFileType, reportTitle);
  };

  return (
    <BaseModal
      isOpen={isOpen}
      onRequestClose={e => disableEscKey(e, closeModal)}
      style={styles}
      className="modal-report-send-by-mail-content"
    >
      <div className="modal-report-send-by-mail-container">
        <div className="close-cross-container">
          <CloseCrossButton handleClick={closeModal} />
        </div>

        <div className="options-list">
          <h3>{t('send_report_by_mail')}</h3>
        </div>

        <span className="label">{t('to')}</span>
        <div className="horizontal">
          <CustomEmailsInput
            sendEmails={emails => {
              setEmails(emails);
            }}
          />
        </div>

        <span className="label">{`${t('message')} (${t('optional')})`}</span>
        <div className="horizontal">
          <textarea
            value={message}
            onChange={e => handleMessage(e.target.value)}
            placeholder={t('write_here')}
          ></textarea>
        </div>

        <span className="label">{t('attached')}</span>
        <div className="horizontal link">
          <LinkText label={reportTitle} onClick={handleDownloadPdf} customStyle={{ fontSize: 12 }} />
        </div>
        <div className="horizontal button">
          <SaveButton
            handleClick={sendReport}
            customText={t('send_by_mail')}
            customStyle={{ padding: '0px 40px' }}
            disabled={disabledButton}
          />
        </div>
      </div>
    </BaseModal>
  );
};
