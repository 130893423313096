import React, { useEffect, useState } from 'react';
import { tabStyles } from '../../Util/Tabs.Styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import './ProjectTabs.scss';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { setProjectTabValue } from '../../../redux/actions/projectTabValue';
import { trackActionClicked } from '../../../api/trackActionClickedApi';
import { TrackedActions } from '../../../models/TrackedActionsEnum';
import { ProjectDetailsTab } from '../../../models/ProjectDetailsTabEnum';
// import Historical from './Historical/Historical';
import { getPreferenceTab, updatePreferenceTab } from '../../../api/projectApi';
import { ReactComponent as ULocationUnpin } from '../../../assets/icons/u_location-unpin.svg';
import { ReactComponent as ULocationPin } from '../../../assets/icons//u_location-pin.svg';
import { ReactComponent as ULocationPinBlue } from '../../../assets/icons//u_location-pin_blue.svg';
import { Snackbar } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconCheck } from '../../../assets/icons/u_check-circle.svg';
import SpinningLoader from './Loader/SpinningLoader';
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  customPanelStyle?: any;
}

const hasDefaultTab = (defaultTab, externalTabs) => {
  return defaultTab !== undefined && defaultTab !== null && !externalTabs;
};

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, customPanelStyle, ...other } = props;
  let panelStyles = { ...tabStyles };
  if (customPanelStyle) {
    panelStyles = { ...panelStyles, body1: customPanelStyle };
  }
  const classes = makeStyles((theme: Theme) => panelStyles)();
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`project-detail-tabpanel-${index}`}
      {...other}
      classes={{ body1: classes.body1 }}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </Typography>
  );
};

interface ProjectTabItem {
  id: number;
  label: any;
  panel: any;
}
interface TabsProps {
  itemsList: ProjectTabItem[];
  customStyle?: any;
  externalTabs?: true;
  underline?: boolean;
  defaultTab?: number;
  showHistorical?: boolean;
  fromProjectDetails?: boolean;
}

const ProjectTabs = (props: TabsProps) => {
  const { itemsList, customStyle, externalTabs, underline, defaultTab, showHistorical, fromProjectDetails } = props;
  const classes = makeStyles((theme: Theme) => tabStyles)();
  const tabClasses: any = { root: classes.root, selected: classes.selected, wrapper: classes.wrapper };
  const hiddenTab = { root: classes.hiddenTab };
  const [value, setValue] = React.useState<any>(0);
  const [isFixed, setIsFixed] = React.useState<any>(0);

  const [preferenceTab, setPreferenceTab] = React.useState<any>();
  const [message, setMessage] = React.useState('');
  const { t } = useTranslation();

  const selectedTab = useSelector<any, number>(state => state.projectTabValue);
  const dispatch = useDispatch();
  const [state, setState] = React.useState<any>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const { open, vertical, horizontal } = state;
  const [isEffectExecuted, setIsEffectExecuted] = useState(false);


  const handleClose = () => {
    setState({ ...state, open: false });
  };

  useEffect(() => {
    let isMounted = true;

    if (itemsList && itemsList.length > 0 && !isEffectExecuted) {
      getPreferenceTab().then(tab => {
        if (!isMounted) return;

        if (tab.title === null || parseInt(tab.title) > (itemsList.length)) {
          dispatch(setProjectTabValue(0));
        } else {
          const idTab = parseInt(tab.title);
          setPreferenceTab(idTab);
          setValue(idTab);
          dispatch(setProjectTabValue(idTab));
        }
        setIsEffectExecuted(true);

      });
    }
    return () => {
      isMounted = false;
    };

  }, [itemsList, isEffectExecuted]);


  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    externalTabs ? dispatch(setProjectTabValue(newValue)) : setValue(newValue);
    if (fromProjectDetails && selectedTab !== newValue) {
      switch (newValue) {
        case ProjectDetailsTab.BASE_INFORMATION:
          trackActionClicked(TrackedActions.projectDetailsBaseInfoTabClicked);
          break;
        case ProjectDetailsTab.PLANNING:
          trackActionClicked(TrackedActions.projectDetailsPlanningTabClicked);
          break;
        case ProjectDetailsTab.SUPERVISION:
          trackActionClicked(TrackedActions.projectDetailsSupervisionTabClicked);
          break;
        case ProjectDetailsTab.AGREEMENTS:
          trackActionClicked(TrackedActions.projectDetailsAccordanceTabClicked);
          break;
        case ProjectDetailsTab.FINANCIAL:
          trackActionClicked(TrackedActions.projectDetailsFinancialTabClicked);
          break;
        case ProjectDetailsTab.RISK:
          trackActionClicked(TrackedActions.projectDetailsRiskTabClicked);
          break;
        case ProjectDetailsTab.ACQUISITIONS:
          trackActionClicked(TrackedActions.projectDetailsAdquisitionTabClicked);
          break;
      }
    }
  };

  React.useEffect(() => {
    if (hasDefaultTab(defaultTab, externalTabs)) {
      setTimeout(() => {
        setValue(defaultTab);
      }, 550);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTab]);
  const handleSetPreferenceTab = type => {
    const idTab = preferenceTab === selectedTab ? 0 : selectedTab;
    setPreferenceTab(idTab);
    updatePreferenceTab(idTab).then(tab => {
      const objTab = itemsList.filter(element => parseInt(tab.title) === element.id);
      let message = (
        <span>
          <IconCheck />
          {preferenceTab === selectedTab ? t('unchecked_view') : t('marked_first_view')}{' '}
          {objTab[0].label} {t('as_tab_favorite')}
        </span>
      );
      setMessage(message);
      setState({ vertical: 'bottom', horizontal: 'right', open: true, tab: 'epa' });
    });
  };

  return (
    <div className="project-detail-tabs-container" style={containerStyle(customStyle)}>
      {/* {showHistorical && <Historical />} */}
      {itemsList && itemsList.length > 0 ? (

        <Tabs
          id="project-tabs"
          value={tabPanelValue(externalTabs, selectedTab, value)}
          onChange={handleChange}
          classes={tabsClasses(underline, classes)}
          variant="standard"
          aria-label="full width tabs example"
          className="tabs-ds"
        >
          {itemsList.map((item: any) => (
            <Tab
              key={item.id}
              classes={itemTabClasses(item, hiddenTab, tabClasses)}
              label={<h2 className="project-tabs__tab-label">{item.label}</h2>}
              {...(preferenceTab !== null && preferenceTab === item.id && { icon: <ULocationPin /> })}
            />
          ))}
          <div className="containerButtonFixed">
            {itemsList.length > 0 && (
              <>
                {preferenceTab === selectedTab ? (
                  <>
                    {preferenceTab !== 0 && (
                      <button className="buttonFixed" onClick={() => handleSetPreferenceTab('desm')}>
                        <span>{t('uncheck_view')}</span>
                        <ULocationUnpin />
                      </button>
                    )}
                  </>
                ) : (
                  <>
                    <button className="buttonFixed" onClick={() => handleSetPreferenceTab('marc')}>
                      <ULocationPinBlue />
                      <span>{t('check_first_view')}</span>
                    </button>
                  </>
                )}
              </>
            )}
          </div>
        </Tabs>

      ) :
        <SpinningLoader />
      }


      {itemsList.map((item: any) => (
        <TabPanel
          key={item.id}
          value={tabPanelValue(externalTabs, selectedTab, value)}
          index={item.id}
          customPanelStyle={bodyStyle(customStyle)}
        >
          {item.panel}
        </TabPanel>
      ))}

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={3000}
        open={open}
        onClose={handleClose}
        message={message}
        key={vertical + horizontal}
        className='alert-bar'
      />
    </div>
  );
};

export default ProjectTabs;

const containerStyle = customStyle => (customStyle && customStyle.container ? customStyle.container : {});
const bodyStyle = customStyle => (customStyle && customStyle.body ? customStyle.body : {});
const tabPanelValue = (externalTabs, selectedTab, value) => (externalTabs ? selectedTab : value);
const tabsClasses = (underline, classes) =>
  underline
    ? { indicator: classes.indicatorColor, flexContainer: classes.flexContainer }
    : { indicator: classes.indicatorColor };
const itemTabClasses = (item, hiddenTab, tabClasses) => (item.hidden ? hiddenTab : tabClasses);
