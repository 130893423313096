import React, { useEffect, useState } from 'react';
import { TextInputField, TextareaField, RadioInputField } from '../MemoryHelpComponents/MemoryHelpsInputs';
import { Trans, useTranslation } from 'react-i18next';
import { useFormMemoryHelpContext } from '../../../context/MemoryHelpFormContext';
import { extractDate, validateField } from '../utils';
import { MemoryHelpSection, GeneralData as GeneralDataType, revisionNumberStrings } from '../models';

const CURRENT_STAGE = "generalData";
const INPUT_RADIO_2 = "false";

export function isGeneralData(section: MemoryHelpSection): section is GeneralDataType {
  return section.type === 'generalData';
}

export const GeneralData = () => {
  const { t } = useTranslation();
  const {
    formState,
    agreementsData,
    optionalFields,
    updateErrors,
    setIsFormComplete,
    onInputChange,
    setOptionalFields,
    setFormState
  } = useFormMemoryHelpContext();

  const [formattedStartDate, setFormattedStartDate] = useState('');
  const [formattedEndDate, setFormattedEndDate] = useState('');
  const [showTextarea, setShowTextarea] = useState(false);

  const validateFormComplete = () => {
    const isFormValid = formState.every((section) => {
      // Si la sección completa debe ser opcional y no es crítica para la validación del formulario
      if (optionalFields.includes(section.type)) {
        return true; // La sección es considerada válida independientemente de su status
      }
      // Validación estándar basada en el status de la sección
      return section.status;
    });
    setIsFormComplete(isFormValid);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    onInputChange(e, CURRENT_STAGE);

    const isOpt2Selected = value === INPUT_RADIO_2;
    setShowTextarea(isOpt2Selected);

    // Actualizar los campos opcionales
    setOptionalFields(prevFields => {
      const fieldsSet = new Set(prevFields);
      if (isOpt2Selected) {
        fieldsSet.delete('criteria');
      } else {
        fieldsSet.add('criteria');
      }
      return Array.from(fieldsSet);
    });

    // Encuentra la sección generalData y valida o limpia el campo 'criteria'
    const generalDataSection = formState.find(isGeneralData);
    if (generalDataSection) {
      if (isOpt2Selected) {
        const isValid = validateField(generalDataSection.criteria, 'criteria', []);
        updateErrors('criteria', CURRENT_STAGE, isValid);
      } else {
        updateErrors('criteria', CURRENT_STAGE, true);
      }
    }

    // Actualizar el estado del formulario
    const updatedFormState = formState.map((section) =>
      isGeneralData(section) && section.type === CURRENT_STAGE ?
        { ...section, inputOpt: value, status: isOpt2Selected ? validateField(section.criteria, 'criteria', []) : true } : section
    );
    setFormState(updatedFormState);
    validateFormComplete();
  };

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    onInputChange(e, CURRENT_STAGE);
    const isValid = validateField(value, name, optionalFields);
    updateErrors(name, CURRENT_STAGE, isValid);

    // Update form state and validate form
    const updatedFormState = formState.map((section) =>
      section.type === CURRENT_STAGE ? { ...section, [name]: value, status: isValid } : section
    );
    setFormState(updatedFormState);
    validateFormComplete();
  };

  useEffect(() => {
    const currentSection = formState.find((item) => item.type === CURRENT_STAGE) as GeneralDataType | undefined;
    if (currentSection && currentSection.inputOpt === INPUT_RADIO_2) {
      setShowTextarea(true);
    } else {
      setShowTextarea(false);
    }
  }, [formState]);

  useEffect(() => {
    setOptionalFields(['criteria', 'aditional_info_general_data']);
    validateFormComplete();
  }, []);


  useEffect(() => {
    if (agreementsData) {
      const revisionStartDate = agreementsData?.dataRevision?.startDate;
      const revisionEndDate = agreementsData?.dataRevision?.endDate;

      setFormattedStartDate(extractDate(revisionStartDate));
      setFormattedEndDate(extractDate(revisionEndDate));
    }
  }, [agreementsData]);

  return (
    <>
      <h3 className='title'>1. {t('memory_help.tabs.1')}</h3>
      <ul>
        <li className='date'>
          <span className='num'>1.1</span>
          <div className='text-first'>
            <div className='text'>
              <Trans i18nKey="memory_help.container_tabs.1.p_1_1.p1">
                {{ value1: formattedStartDate, value2: formattedEndDate }}
              </Trans>
            </div>
            <div className='input-message'>
              <TextInputField
                placeholder={t('memory_help.container_tabs.1.p_1_1.placeholder_institution')}
                name="institution_borrower"
                onChange={(e) => onInputChange(e, CURRENT_STAGE)}
                currentTabId={CURRENT_STAGE}
              />
            </div>
          </div>
          <Trans i18nKey="memory_help.container_tabs.1.p_1_1.p2">
            {{ value1: agreementsData && revisionNumberStrings[agreementsData?.dataRevision?.revisionNumber], value2: agreementsData?.dataRevision?.year }}
          </Trans>
        </li>

        <li>
          <span className='num'>1.2</span>
          {t('memory_help.container_tabs.1.p_1_2.p1')}

          <TextareaField
            placeholder={t('memory_help.container_tabs.1.p_1_2.placeholder_objectives')}
            name='objectives'
            onChange={(e) => onInputChange(e, CURRENT_STAGE)}
            currentTabId={CURRENT_STAGE} />

          {t('memory_help.container_tabs.1.p_1_2.p2')}
        </li>

        <li>
          <span className='num'>1.3</span>
          <div className='bg-grey'>
            <span className='title-box'>{t('memory_help.container_tabs.1.p_1_3.alternative_text.bold')}</span>
            <span className='description'>{t('memory_help.container_tabs.1.p_1_3.alternative_text.light')}</span>
          </div>

          <div className='radio-button'>
            <RadioInputField
              name="inputOpt"
              currentStage={CURRENT_STAGE}
              value="true"
              onChange={(e) => handleInputChange(e)}
              formState={formState} />

            <p>
              <Trans i18nKey="memory_help.container_tabs.1.p_1_3.p1">
                {{ value1: formattedEndDate }}
              </Trans>
            </p>
          </div>

          <div className='radio-button'>
            <RadioInputField
              name="inputOpt"
              currentStage={CURRENT_STAGE}
              value="false"
              onChange={(e) => handleInputChange(e)}
              formState={formState} />

            <p>{t('memory_help.container_tabs.1.p_1_3.p2')}</p>
          </div>

          {showTextarea && (
            <div className='input-strong'>
              <TextareaField
                placeholder={t('memory_help.container_tabs.1.p_1_3.placeholder_p2')}
                name='criteria'
                onChange={handleTextareaChange}
                currentTabId={CURRENT_STAGE} />

              <Trans i18nKey="memory_help.container_tabs.1.p_1_3.p3">
                {{ value1: agreementsData?.dataRevision?.projectsNumber, }} {{ value2: agreementsData?.dataRevision?.totalProjects }} {{ value3: formattedEndDate }}
              </Trans>
            </div>
          )}
        </li>

        <li>
          <span className='num'>1.4</span>
          {t('memory_help.container_tabs.1.p_1_4.p1')}

          <TextareaField
            placeholder={t('memory_help.container_tabs.1.p_1_4.placeholder')}
            name='institutions_review'
            onChange={(e) => onInputChange(e, CURRENT_STAGE)}
            currentTabId={CURRENT_STAGE} />

          {t('memory_help.container_tabs.1.p_1_4.p2')}
        </li>

        <li>
          <span className='num'>1.5</span>
          <TextareaField
            placeholder={t('memory_help.container_tabs.1.p_1_5.placeholder')}
            name='aditional_info_general_data'
            onChange={(e) => onInputChange(e, CURRENT_STAGE)}
            currentTabId={CURRENT_STAGE} />
        </li>
      </ul>
    </>
  );
};

export default GeneralData;
