import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './Header.scss';
/*Assets imported */

/* Material Imports */
import AppBar from '@material-ui/core/AppBar';
import ToolBar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';

//Custom Styles for component
import { useStyles } from './Header.Styles';
import User from '../../models/User';
import { tabIndexes } from '../../models/TabIndexes';
import NotificationsModal from '../Notifications/NotificationsModal/NotificationsModal';
import { getNotificationsFromApi } from '../../redux/actions/notifications';

import Notifications from '../../models/Notifications';
import { isMobile } from '../../services/booleanUtil';
import ConvergenceModal from '../Util/Modals/ConvergenceModal/ConvergenceModal';
import LogoBidCartera from '../Util/LogoBidCartera/LogoBidCartera';
import { setTabValue } from '../../redux/actions/tabValue';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { BidTooltip } from '../Util/Tooltip/Tooltip';

const BlackTooltip = withStyles({
  tooltip: {
    //   color: '$ci-color-greyscale-90',
    backgroundColor: '#101319',
    fontSize: '16px',
    fontFamily: 'Rubik',
    lineHeight: '20px',
    padding: '8px 12px',
    borderRadius: '8px',
  },
  arrow: {
    fontSize: 20,
    color: '#4A4A4A',
    '&::before': {
      backgroundColor: '#101319',
    },
  },
})(Tooltip);

interface IProps extends React.HTMLProps<JSX.Element> {
  detailProject?: boolean;
  isContentManager?: boolean;
}

export const hasUnreadedElements = items => {
  return items.filter(element => element.readed === false).length === 0 ? false : true;
};
export const UserAlertAvatar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector<any, User>(state => state.user);
  const [hasAlert, setAlert] = React.useState(true);
  const [modalOpen, setModaleOpen] = React.useState(false);
  const notificationsItems = useSelector<any, Notifications[]>(state => state.notifications);

  const handlerCloseModal = () => {
    setAlert(false);
    setModaleOpen(!modalOpen);
  };
  const dispatchNotifications = () => {
    dispatch(getNotificationsFromApi());
  };

  React.useEffect(() => {
    if (user.id > 0) {
      dispatchNotifications();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  React.useEffect(() => {
    setAlert(hasUnreadedElements(notificationsItems));
  }, [notificationsItems]);

  return (
    <div onClick={handlerCloseModal} tabIndex={1} className="notifications-bell__container">
      <BidTooltip arrow title={t('alert_tooltip') || ''} placement="bottom-start" enterDelay={500} leaveDelay={200}>
        <span className={hasAlert ? 'notifications-bell alert' : 'notifications-bell'}></span>
      </BidTooltip>
      <NotificationsModal closeModal={handlerCloseModal} isOpen={modalOpen} />
    </div>
  );
};

export const UserAdjustAvatar = ({ detailProject, isContentManager }: IProps) => {
  const { t } = useTranslation();
  const { userAdjustAvatar, userAdjustTextAvatar } = useStyles();
  const user = useSelector<any, User>(state => state.user);
  const history = useHistory();
  const dispatch = useDispatch();

  const userAdjustHandler = () => {
    dispatch(setTabValue(tabIndexes.userSettings));
    if (detailProject || isContentManager) {
      history.push('/home');
    }
  };

  return (
    <Avatar className={`${userAdjustAvatar} user-adjust-avatar`} tabIndex={2}>
      <BidTooltip
        arrow
        title={t('user_avatar_tooltip') || ''}
        placement="bottom-start"
        enterDelay={500}
        leaveDelay={200}
      >
        <IconButton onClick={userAdjustHandler} className={userAdjustTextAvatar}>
          {user.name ? user.name.charAt(0) : ''}
          {user.lastName ? user.lastName.charAt(0) : ''}
        </IconButton>
      </BidTooltip>
    </Avatar>
  );
};

const Header = ({ detailProject, isContentManager }: IProps) => {
  const { root, headerContainer, userGreeting, rootDetail, detailContainer, toolBar } = useStyles();
  const dispatch = useDispatch();
  const user = useSelector<any, User>(state => state.user);
  const convergenceModal = useSelector<any, any>(state => state.convergenceModal);
  const { t } = useTranslation();
  const history = useHistory();

  const goToHome = () => {
    dispatch(setTabValue(tabIndexes.portfolio));
    history.push('/home');
  };

  return (
    <>
      <div
        className={detailProject ? rootDetail : root}
        style={convergenceModal ? { marginTop: isMobile() ? 50 : 24 } : {}}
      >
        <AppBar className={detailProject ? detailContainer : headerContainer} elevation={0} position="static">
          <ToolBar disableGutters={true} className={toolBar}>
            <LogoBidCartera customStyle={{ flexGrow: 1 }} onClick={goToHome} isContentManager={isContentManager} />

            {!isMobile() && (
              <Typography className={userGreeting}>
                {user.name ? t('greet') : ''} {user.name}
              </Typography>
            )}

            <UserAlertAvatar />

            <UserAdjustAvatar detailProject={detailProject} isContentManager={isContentManager} />
            {/* {isContentManager ? null : <HelpButton />} */}
          </ToolBar>
        </AppBar>
      </div>
      {convergenceModal && <ConvergenceModal />}
    </>
  );
};

export default Header;
