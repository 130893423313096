import React from 'react';
import CustomCheckbox from '../../../../CustomCheckbox/CustomCheckbox';
import { useTranslation } from 'react-i18next';
import { translateMultipleText } from '../../../../../../services/stringUtil';
import {
  isJustXProjectTypeFilterSelected,
  isSelectedAgeProject,
  isSelectedCoResponsibleDivision,
  isSelectedCountry,
  isSelectedDisbursementProjection,
  isSelectedDivision,
  isSelectedExcludeRegionals,
  isSelectedExecutionFilter,
  isSelectedInterestIssues,
  isSelectedParticipantDivision,
  isSelectedPmr,
  isSelectedProjectTypeFilter,
  isSelectedRegion,
  isSelectedTaxonomyFilter,
  isSelectedTeamLeaders,
  isSelectedTimeWithoutDisbursement,
  renderPmrText,
  updateFilterToDrawProperties,
  workWithCTSpecificFilters,
  workWithLoanSpecificFilters,
} from './Util';
import ProjectTypeFilterCheckbox from '../../../../../Filter/ProjectTypeFilterCheckbox/ProjectTypeFilterCheckbox';
import { igrIsDisabled, showIgrSubFilters } from '../../../../../Filter/ProjectTypeFilter/UtilFunctions';
import { ProjectProductTypeFilters } from '../../../../../../models/ProjectProductTypeFilters';

type Props = {
  reportFilterOriginal: any;
  returnedReportFilter: any;
};

export const FilterReport = (props: Props) => {
  const { t } = useTranslation();
  const { reportFilterOriginal, returnedReportFilter } = props;

  const [filterToReturn, setFilterToReturn] = React.useState(JSON.parse(JSON.stringify(reportFilterOriginal)));
  const [filterToDraw, setFilterToDraw] = React.useState(JSON.parse(JSON.stringify(reportFilterOriginal)));

  React.useEffect(() => {
    const actualState = filterToDraw.common;
    setFilterToDraw(updateFilterToDrawProperties(actualState, filterToDraw));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    returnedReportFilter(filterToReturn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterToReturn]);

  React.useEffect(() => {
    workWithLoanSpecificFilters(filterToReturn, filterToDraw, setFilterToReturn);
    workWithCTSpecificFilters(filterToReturn, filterToDraw, setFilterToReturn);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterToReturn.common.projectTypeFilters]);

  return (
    <div>
      {renderProjectTypeFilter(filterToDraw, filterToReturn, setFilterToReturn, setFilterToDraw)}
      {renderPmrCheckboxes(filterToDraw, filterToReturn, setFilterToReturn, setFilterToDraw, t, returnedReportFilter)}
      {renderDivisionCheckboxes(filterToDraw, filterToReturn, setFilterToReturn, setFilterToDraw)}
      {renderCoResponsibleDivisionCheckboxes(filterToDraw, filterToReturn, setFilterToReturn, setFilterToDraw, t)}
      {renderParticipantDivisionCheckboxes(filterToDraw, filterToReturn, setFilterToReturn, setFilterToDraw, t)}
      {renderTimeWithoutDisbursement(filterToDraw, filterToReturn, setFilterToReturn, setFilterToDraw, t)}
      {renderDisbursementProjection(filterToDraw, filterToReturn, setFilterToReturn, setFilterToDraw, t)}
      {renderAgeProject(filterToDraw, filterToReturn, setFilterToReturn, setFilterToDraw, t)}
      {renderRegionCheckboxes(filterToDraw, filterToReturn, setFilterToReturn, setFilterToDraw)}
      {renderCountryCheckboxes(filterToDraw, filterToReturn, setFilterToReturn, setFilterToDraw)}
      {renderTeamLeadersCheckboxes(filterToDraw, filterToReturn, setFilterToReturn, setFilterToDraw)}
      {renderInterestIssuesCheckboxes(filterToDraw, filterToReturn, setFilterToReturn, setFilterToDraw)}
      {renderTaxonomyFilter(filterToDraw, filterToReturn, setFilterToReturn, setFilterToDraw)}
      {renderExecutionFilter(filterToDraw, filterToReturn, setFilterToReturn, setFilterToDraw)}
      {renderExcludeRegionals(filterToDraw, filterToReturn, setFilterToReturn, setFilterToDraw, t)}
    </div>
  );
};

const renderProjectTypeFilter = (
  filterToDraw: any,
  filterToReturn: any,
  setFilterToReturn: Function,
  setFilterToDraw: Function,
) => {
  return (
    <ProjectTypeFilterCheckbox
      handleChanges={checkboxData =>
        isSelectedProjectTypeFilter(checkboxData, filterToReturn, filterToDraw, setFilterToReturn, setFilterToDraw)
      }
      igrIsDisabled={() => igrIsDisabled(filterToDraw.common.projectTypeFilters)}
      showIgrSubFilters={() => showIgrSubFilters(filterToDraw.common.projectTypeFilters)}
      filterState={filterToDraw.common.projectTypeFilters}
      onModalReports={true}
    />
  );
};

const renderPmrCheckboxes = (
  filterToDraw: any,
  filterToReturn: any,
  setFilterToReturn: Function,
  setFilterToDraw: Function,
  t: Function,
  returnedReportFilter: any,
) => {
  const pmr = filterToDraw.common.pmrId;
  const justLoanProjectTypeFilterSelected = isJustXProjectTypeFilterSelected(
    filterToReturn,
    ProjectProductTypeFilters.Loan,
  );

  return justLoanProjectTypeFilterSelected
    ? pmr.map(
        p =>
          p.hasOwnProperty('drawSelected') && (
            <CustomCheckbox
              key={p.PmrClassification}
              isChecked={p.selected}
              handleOnClick={() =>
                isSelectedPmr(
                  filterToReturn,
                  filterToDraw,
                  setFilterToReturn,
                  returnedReportFilter,
                  setFilterToDraw,
                  p.PmrClassification,
                )
              }
              labelText={t(renderPmrText(p.PmrClassification))}
            />
          ),
      )
    : null;
};

const renderDivisionCheckboxes = (
  filterToDraw: any,
  filterToReturn: any,
  setFilterToReturn: Function,
  setFilterToDraw: Function,
) => {
  const divisions = filterToDraw.common.divisionId;

  return divisions.map(
    d =>
      d.hasOwnProperty('drawSelected') && (
        <CustomCheckbox
          key={d.id}
          isChecked={d.selected}
          handleOnClick={() =>
            isSelectedDivision(filterToReturn, filterToDraw, setFilterToReturn, setFilterToDraw, d.id)
          }
          labelText={d.label}
        />
      ),
  );
};

const renderCoResponsibleDivisionCheckboxes = (
  filterToDraw: any,
  filterToReturn: any,
  setFilterToReturn: Function,
  setFilterToDraw: Function,
  t: Function,
) => {
  const divisions = filterToDraw.common.coReponsibleDivisions;

  return divisions.map(
    d =>
      d.hasOwnProperty('drawSelected') && (
        <CustomCheckbox
          key={d.id}
          isChecked={d.selected}
          handleOnClick={() =>
            isSelectedCoResponsibleDivision(filterToReturn, filterToDraw, setFilterToReturn, setFilterToDraw, d.id)
          }
          labelText={`${d.label} (${t('co_responsible')})`}
        />
      ),
  );
};

const renderParticipantDivisionCheckboxes = (
  filterToDraw: any,
  filterToReturn: any,
  setFilterToReturn: Function,
  setFilterToDraw: Function,
  t: Function,
) => {
  const divisions = filterToDraw.common.participantDivisions;

  return divisions.map(
    d =>
      d.hasOwnProperty('drawSelected') && (
        <CustomCheckbox
          key={d.id}
          isChecked={d.selected}
          handleOnClick={() =>
            isSelectedParticipantDivision(filterToReturn, filterToDraw, setFilterToReturn, setFilterToDraw, d.id)
          }
          labelText={`${d.label} (${t('participating')})`}
        />
      ),
  );
};

const renderTimeWithoutDisbursement = (
  filterToDraw: any,
  filterToReturn: any,
  setFilterToReturn: Function,
  setFilterToDraw: Function,
  t: Function,
) => {
  const timeWithoutDisbursementToDraw = filterToDraw.common.timeWithoutDisbursementCheckbox;

  return (
    timeWithoutDisbursementToDraw &&
    timeWithoutDisbursementToDraw.drawSelected === true && (
      <CustomCheckbox
        isChecked={timeWithoutDisbursementToDraw.selected}
        handleOnClick={isChecked =>
          isSelectedTimeWithoutDisbursement(filterToReturn, filterToDraw, setFilterToReturn, setFilterToDraw, isChecked)
        }
        labelText={translateMultipleText(timeWithoutDisbursementToDraw.label, t)}
      />
    )
  );
};

const renderDisbursementProjection = (
  filterToDraw: any,
  filterToReturn: any,
  setFilterToReturn: Function,
  setFilterToDraw: Function,
  t: Function,
) => {
  const disbursementProjectionToDraw = filterToDraw.common.disbursementProjectionCheckbox;

  const justLoanProjectTypeFilterSelected = isJustXProjectTypeFilterSelected(
    filterToReturn,
    ProjectProductTypeFilters.Loan,
  );

  return justLoanProjectTypeFilterSelected
    ? disbursementProjectionToDraw && disbursementProjectionToDraw.drawSelected === true && (
        <CustomCheckbox
          isChecked={disbursementProjectionToDraw.selected}
          handleOnClick={isChecked =>
            isSelectedDisbursementProjection(
              filterToReturn,
              filterToDraw,
              setFilterToReturn,
              setFilterToDraw,
              isChecked,
            )
          }
          labelText={translateMultipleText(disbursementProjectionToDraw.label, t)}
        />
      )
    : null;
};

const renderAgeProject = (
  filterToDraw: any,
  filterToReturn: any,
  setFilterToReturn: Function,
  setFilterToDraw: Function,
  t: Function,
) => {
  const ageProjectToDraw = filterToDraw.common.ageProjectsCheckbox;

  return (
    ageProjectToDraw &&
    ageProjectToDraw.drawSelected === true && (
      <CustomCheckbox
        isChecked={ageProjectToDraw.selected}
        handleOnClick={isChecked =>
          isSelectedAgeProject(filterToReturn, filterToDraw, setFilterToReturn, setFilterToDraw, isChecked)
        }
        labelText={translateMultipleText(ageProjectToDraw.label, t)}
      />
    )
  );
};

const renderRegionCheckboxes = (
  filterToDraw: any,
  filterToReturn: any,
  setFilterToReturn: Function,
  setFilterToDraw: Function,
) => {
  const regions = filterToDraw.common.regionId;

  return regions.map(
    d =>
      d.hasOwnProperty('drawSelected') && (
        <CustomCheckbox
          key={d.id}
          isChecked={d.selected}
          handleOnClick={() => isSelectedRegion(filterToReturn, filterToDraw, setFilterToReturn, setFilterToDraw, d.id)}
          labelText={d.label}
        />
      ),
  );
};

const renderCountryCheckboxes = (
  filterToDraw: any,
  filterToReturn: any,
  setFilterToReturn: Function,
  setFilterToDraw: Function,
) => {
  const countries = filterToDraw.common.countryId;

  return countries.map(
    d =>
      d.hasOwnProperty('drawSelected') && (
        <CustomCheckbox
          key={d.id}
          isChecked={d.selected}
          handleOnClick={() =>
            isSelectedCountry(filterToReturn, filterToDraw, setFilterToReturn, setFilterToDraw, d.id)
          }
          labelText={d.label}
        />
      ),
  );
};

const renderTeamLeadersCheckboxes = (
  filterToDraw: any,
  filterToReturn: any,
  setFilterToReturn: Function,
  setFilterToDraw: Function,
) => {
  const teamLeaders = filterToDraw.common.teamLeaders;

  return teamLeaders.map(
    d =>
      d.hasOwnProperty('drawSelected') && (
        <CustomCheckbox
          key={d.id}
          isChecked={d.selected}
          handleOnClick={() =>
            isSelectedTeamLeaders(filterToReturn, filterToDraw, setFilterToReturn, setFilterToDraw, d.id)
          }
          labelText={d.label}
        />
      ),
  );
};

const renderInterestIssuesCheckboxes = (
  filterToDraw: any,
  filterToReturn: any,
  setFilterToReturn: Function,
  setFilterToDraw: Function,
) => {
  const interestIssues = filterToDraw.common.interestIssues;

  return interestIssues.map(
    d =>
      d.hasOwnProperty('drawSelected') && (
        <CustomCheckbox
          key={d.id}
          isChecked={d.selected}
          handleOnClick={() =>
            isSelectedInterestIssues(filterToReturn, filterToDraw, setFilterToReturn, setFilterToDraw, d.id)
          }
          labelText={d.label}
        />
      ),
  );
};

const renderTaxonomyFilter = (
  filterToDraw: any,
  filterToReturn: any,
  setFilterToReturn: Function,
  setFilterToDraw: Function,
) => {
  const taxonomyFilterToDraw = filterToDraw.common.taxonomyFilter;

  const justCTProjectTypeFilterSelected = isJustXProjectTypeFilterSelected(
    filterToReturn,
    ProjectProductTypeFilters.CT,
  );

  return justCTProjectTypeFilterSelected
    ? taxonomyFilterToDraw && taxonomyFilterToDraw.drawSelected === true && (
        <CustomCheckbox
          isChecked={taxonomyFilterToDraw.selected}
          handleOnClick={isChecked =>
            isSelectedTaxonomyFilter(filterToReturn, filterToDraw, setFilterToReturn, setFilterToDraw, isChecked)
          }
          labelText={taxonomyFilterToDraw.label}
        />
      )
    : null;
};

const renderExecutionFilter = (
  filterToDraw: any,
  filterToReturn: any,
  setFilterToReturn: Function,
  setFilterToDraw: Function,
) => {
  const executionFilterToDraw = filterToDraw.common.executionFilter;
  const justCTProjectTypeFilterSelected = isJustXProjectTypeFilterSelected(
    filterToReturn,
    ProjectProductTypeFilters.CT,
  );
  return justCTProjectTypeFilterSelected
    ? executionFilterToDraw && executionFilterToDraw.drawSelected === true && (
        <CustomCheckbox
          isChecked={executionFilterToDraw.selected}
          handleOnClick={isChecked =>
            isSelectedExecutionFilter(filterToReturn, filterToDraw, setFilterToReturn, setFilterToDraw, isChecked)
          }
          labelText={executionFilterToDraw.label}
        />
      )
    : null;
};

const renderExcludeRegionals = (
  filterToDraw: any,
  filterToReturn: any,
  setFilterToReturn: Function,
  setFilterToDraw: Function,
  t: Function,
) => {
  const excludeRegionalsToDraw = filterToDraw.common.excludeRegionalsCheckbox;

  return excludeRegionalsToDraw
    ? excludeRegionalsToDraw.drawSelected === true && (
        <CustomCheckbox
          isChecked={excludeRegionalsToDraw.selected}
          handleOnClick={isChecked =>
            isSelectedExcludeRegionals(filterToReturn, filterToDraw, setFilterToReturn, setFilterToDraw, isChecked)
          }
          labelText={t(excludeRegionalsToDraw.label)}
        />
      )
    : null;
};
