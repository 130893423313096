import { RESET_TEXT_SEARCH, SET_TEXT_SEARCH } from './types';

export function setTextSearch(text: string) {
  return {
    type: SET_TEXT_SEARCH,
    payload: text,
  };
}

export function resetTextSearch() {
  return {
    type: RESET_TEXT_SEARCH,
  };
}
