import React, { useEffect } from 'react';
import { css } from '@emotion/core';
import { useDispatch } from 'react-redux';
import PropagateLoader from 'react-spinners/PropagateLoader';
import { useHistory } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { resetUser } from '../../redux/actions/user';

const override = css`
  display: block;
  margin: 0 auto;
`;

export const LoadingComponent = () => (
  <div
    style={{
      width: '100%',
      height: '90vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <PropagateLoader css={override} size={15} color={'#417ffe'} loading={true} />
  </div>
);

const Landing = () => {
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetUser());
    localStorage.removeItem('language');
    if (isAuthenticated && (inProgress === InteractionStatus.HandleRedirect || inProgress === InteractionStatus.None)) {
      history.push('/home');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, inProgress]);

  return <LoadingComponent />;
};

export { Landing };
