import React from 'react';
import Popover from '@material-ui/core/Popover';
import { ReactComponent as IconInfo } from '../../../../assets/icons/u_info-circle.svg';
import { ReactComponent as IconClose } from '../../../../assets/icons/u_multiply.svg';
import '../../../MemoryHelp/MemoryHelp.scss';
import { useTranslation } from 'react-i18next';


export default function ModalIconInfo() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'info-modal-memory' : undefined;

  return (
    <div>
      <IconInfo aria-describedby={id}  onClick={handleClick} />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className='header-modal-memory'>
        <h5>{t('memory_help.page_reviews.popover_date.title')}</h5>
        <IconClose  onClick={handleClose}/>
        </div>
       <p>{t('memory_help.page_reviews.popover_date.description')}</p>
      </Popover>
    </div>
  );
}
