import React from 'react';
import { TFunction } from 'i18next';
import { showCountryFilterStates } from '../../../FilterV2/RegionCountryFilter/models';
import { CustomizableCountryFilter } from './Components/CustomizableCountryFilter';
import { CustomizableDisbursementProjectionFilter } from './Components/CustomizableDisbursementProjectionFilter';
import { CustomizableDivisionFilter } from './Components/CustomizableDivisionFilter';
import { CustomizableProjectAgeFilter } from './Components/CustomizableProjectAgeFilter';
import { CustomizableRegionFilter } from './Components/CustomizableRegionFilter';
import { CustomizableTimeWithoutDisbursementsFilter } from './Components/CustomizableTimeWithoutDisbursementsFilter';
import { CutomizablePmrFilter } from './Components/CutomizablePmrFilter';
import { FilterAccordion } from './Components/FilterAccordion';
import SelectOption from '../../../../models/SelectOption';
import FilterIndicators from '../../../../models/FilterIndicators';
import { ProjectProductTypeFilters } from '../../../../models/ProjectProductTypeFilters';
import { isJustXProjectTypeFilterSelected } from './Steps/FilterReport/Util';
import { CustomizableCoResponsibleDivisionFilter } from './Components/CustomizableCoResponsibleDivisionFilter';
import { ParticipantDivisionFilter } from './Components/ParticipantDivisionFilter';
import { TeamLeaderFilter } from './Components/TeamLeaderFilter';
import { InterestIssueFilter } from './Components/InterestIssueFilter';
import { ExecutionFilter } from './Components/ExecutionFilter';
import { capitalizeFirstLetter } from '../../../../services/stringUtil';
import { TaxonomyFilter } from './Components/TaxonomyFilter';
import { CutomizableExcludeRegionals } from './Components/CutomizableExcludeRegionals';

export type FilterCheckbox = {
  alreadyApplied: boolean;
  checked: boolean;
  children?: React.ReactNode;
  id: string;
  title: string;
  summary: string;
  hideAccordion?: boolean;
};

const accordionTitle = (title: string, summary: string) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
      <span style={{ fontWeight: 500, fontSize: 18, lineHeight: '21px', fontFamily: 'Rubik' }}>{title}</span>

      <span style={{ fontWeight: 300, fontSize: 12 }}>{summary}</span>
    </div>
  );
};

export const renderFilter = (filter: FilterCheckbox) => {
  return (
    <div className="filter-container" key={filter.id}>
      <FilterAccordion title={accordionTitle(filter.title, filter.summary)} hideAccordion={filter.hideAccordion}>
        {filter.children}
      </FilterAccordion>
    </div>
  );
};

const handleSpecifcFiltersLogic = (reportFilter, filterSpecification) => {
  if (filterSpecification === ProjectProductTypeFilters.Loan) {
    const justLoanProjectTypeFilterSelected = isJustXProjectTypeFilterSelected(
      reportFilter,
      ProjectProductTypeFilters.Loan,
    );
    if (justLoanProjectTypeFilterSelected) {
      return false;
    } else {
      return true;
    }
  } else if (filterSpecification === ProjectProductTypeFilters.CT) {
    const justCTProjectTypeFilterSelected = isJustXProjectTypeFilterSelected(
      reportFilter,
      ProjectProductTypeFilters.CT,
    );

    if (justCTProjectTypeFilterSelected) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
};

export const buildFilters = ({
  filter,
  reportFilter,
  handleReportFilterChange,
  countriesState,
  filterCountriesOnSelect,
  regionState,
  hideRegions,
  showCountries,
  setFilters,
  t,
}: {
  filter: any;
  reportFilter: FilterIndicators;
  handleReportFilterChange: Function;
  countriesState: SelectOption[];
  filterCountriesOnSelect: Function;
  regionState: SelectOption[];
  hideRegions: boolean;
  showCountries: showCountryFilterStates;
  setFilters: Function;
  t: TFunction;
}) => {
  const pmrFilter = {
    alreadyApplied: filter.pmrId.some(p => p.drawSelected),
    checked: filter.pmrId.some(p => p.drawSelected),
    children: <CutomizablePmrFilter reportFilters={reportFilter} reportFiltersChanged={handleReportFilterChange} />,
    id: 'pmr',
    title: t('pmr_initials'),
    hideAccordion: handleSpecifcFiltersLogic(reportFilter, ProjectProductTypeFilters.Loan),
  } as FilterCheckbox;

  const divisionFilter = {
    alreadyApplied: filter.divisionId.some(d => d.drawSelected),
    checked: filter.divisionId.some(d => d.drawSelected),
    children: (
      <CustomizableDivisionFilter reportFilters={reportFilter} reportFiltersChanged={handleReportFilterChange} />
    ),
    id: 'division',
    title: t('division_capital'),
  } as FilterCheckbox;

  const coResponsibleDivisionFilter = {
    alreadyApplied: filter.coReponsibleDivisions.some(d => d.drawSelected),
    checked: filter.coReponsibleDivisions.some(d => d.drawSelected),
    children: (
      <CustomizableCoResponsibleDivisionFilter
        reportFilters={reportFilter}
        reportFiltersChanged={handleReportFilterChange}
      />
    ),
    id: 'coReponsibleDivision',
    title: t('co_responsible_division'),
  } as FilterCheckbox;

  const participantDivisionFilter = {
    alreadyApplied: filter.coReponsibleDivisions.some(d => d.drawSelected),
    checked: filter.coReponsibleDivisions.some(d => d.drawSelected),
    children: (
      <ParticipantDivisionFilter reportFilters={reportFilter} reportFiltersChanged={handleReportFilterChange} />
    ),
    id: 'participatingDivision',
    title: t('participating_division'),
  } as FilterCheckbox;

  const teamLeaderFilter = {
    alreadyApplied: filter.teamLeaders.some(d => d.drawSelected),
    checked: filter.teamLeaders.some(d => d.drawSelected),
    children: <TeamLeaderFilter reportFilters={reportFilter} reportFiltersChanged={handleReportFilterChange} />,
    id: 'teamLeaders',
    title: t('team_leader'),
  } as FilterCheckbox;

  const interestIssuesFilter = {
    alreadyApplied: filter.interestIssues.some(d => d.drawSelected),
    checked: filter.interestIssues.some(d => d.drawSelected),
    children: <InterestIssueFilter reportFilters={reportFilter} reportFiltersChanged={handleReportFilterChange} />,
    id: 'interestIssues',
    title: t('attributes'),
  } as FilterCheckbox;

  const executionFilter = {
    alreadyApplied: filter.executionFilter ? filter.executionFilter.drawSelected : false,
    checked: filter.executionFilter ? filter.executionFilter.drawSelected : false,
    children: <ExecutionFilter reportFilters={reportFilter} reportFiltersChanged={handleReportFilterChange} />,
    id: 'execution',
    title: capitalizeFirstLetter(t('execution')),
    hideAccordion: handleSpecifcFiltersLogic(reportFilter, ProjectProductTypeFilters.CT),
  } as FilterCheckbox;
  const taxonomyFilter = {
    alreadyApplied: filter.taxonomyFilter ? filter.taxonomyFilter.drawSelected : false,
    checked: filter.taxonomyFilter ? filter.taxonomyFilter.drawSelected : false,
    children: <TaxonomyFilter reportFilters={reportFilter} reportFiltersChanged={handleReportFilterChange} />,
    id: 'taxonomy',
    title: t('taxonomy'),
    hideAccordion: handleSpecifcFiltersLogic(reportFilter, ProjectProductTypeFilters.CT),
  } as FilterCheckbox;

  const excludeRegionalFilter = {
    alreadyApplied: filter.excludeRegionals ? filter.excludeRegionalsCheckbox.drawSelected : false,
    checked: filter.excludeRegionals ? filter.excludeRegionalsCheckbox.drawSelected : false,
    children: (
      <CutomizableExcludeRegionals reportFilters={reportFilter} reportFiltersChanged={handleReportFilterChange} />
    ),
    id: 'excludeRegional',
    title: t('exclude_regional'),
  } as FilterCheckbox;

  const timeWithoutDisbursementFilter = {
    alreadyApplied: filter.timeWithoutDisbursementCheckbox.drawSelected,
    checked: filter.timeWithoutDisbursementCheckbox.drawSelected,
    children: (
      <CustomizableTimeWithoutDisbursementsFilter
        reportFilters={reportFilter}
        reportFiltersChanged={handleReportFilterChange}
      />
    ),
    id: 'timeWithoutDisbursement',
    title: t('time_without_performing_disbursements'),
  } as FilterCheckbox;

  const ageProjectsFilter = {
    alreadyApplied: filter.ageProjectsCheckbox.drawSelected,
    checked: filter.ageProjectsCheckbox.drawSelected,
    children: (
      <>
        <span>{t('time_from_date_of_eligibility')}</span>
        <CustomizableProjectAgeFilter reportFilters={reportFilter} reportFiltersChanged={handleReportFilterChange} />
      </>
    ),
    id: 'ageProjects',
    title: t('project_age'),
  } as FilterCheckbox;

  const disbursementProjectionFilter = {
    alreadyApplied: filter.disbursementProjectionCheckbox.drawSelected,
    checked: filter.disbursementProjectionCheckbox.drawSelected,
    children: (
      <CustomizableDisbursementProjectionFilter
        reportFilters={reportFilter}
        reportFiltersChanged={handleReportFilterChange}
      />
    ),
    id: 'disbursementProjection',
    title: t('projection_disbursed'),
    hideAccordion: handleSpecifcFiltersLogic(reportFilter, ProjectProductTypeFilters.Loan),
  } as FilterCheckbox;

  const countryFilter = {
    alreadyApplied: filter.countryId.some(d => d.drawSelected),
    checked: filter.countryId.some(d => d.drawSelected),
    children: (
      <CustomizableCountryFilter
        countries={countriesState}
        reportFilters={reportFilter}
        reportFiltersChanged={handleReportFilterChange}
      />
    ),
    id: 'country',
    title: t('country'),
  } as FilterCheckbox;

  const regionFilter = {
    alreadyApplied: filter.regionId.some(d => d.drawSelected),
    checked: filter.regionId.some(d => d.drawSelected),
    children: (
      <CustomizableRegionFilter
        filterCountriesOnSelect={filterCountriesOnSelect}
        regions={regionState}
        reportFilters={reportFilter}
        reportFiltersChanged={handleReportFilterChange}
      />
    ),
    id: 'region',
    title: t('region'),
  } as FilterCheckbox;

  const filters = [
    divisionFilter,
    coResponsibleDivisionFilter,
    participantDivisionFilter,
    teamLeaderFilter,
    interestIssuesFilter,
    executionFilter,
    taxonomyFilter,
    excludeRegionalFilter,
    pmrFilter,
    timeWithoutDisbursementFilter,
    ageProjectsFilter,
    disbursementProjectionFilter,
  ];

  if (!hideRegions) {
    filters.push(regionFilter);
  }

  if (
    showCountries === showCountryFilterStates.ShowComplete ||
    showCountries === showCountryFilterStates.ShowByRegion
  ) {
    filters.push(countryFilter);
  }

  setFilters(filters);
};
