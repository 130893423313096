import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import './AddEntity.scss';
import SaveButton from '../../Util/Buttons/SaveButton/SaveButton';
import styles from './AddEntity.module.scss';
import { getCategories } from '../../../api/accordanceApi';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { addCategory, addPossibleAction, addCriticalIssue, defaultPaginationData } from '../../../api/contentManager';
import { showSuccesMessage } from '../../../redux/actions/sucessMessage';
import { multiSelectAddEntityStyle, DropdownIndicator } from '../../Filter/FilterComponent.Styles';
import ContentManagerTable from '../ContentManagerTable/ContentManagerTable';
import { tableRowTypeKeys } from '../ContentManagerKeys';
import ContentManagerModal from '../ContentManagerModal/ContentManagerModal';
import { ReactComponent as ModalAlert } from '../../../assets/alerta-modal.svg';
import { getDataTypes, mapEntitiesDataTypes, renderModalTextType, AddEntityOptionsType } from './util';

const criticalIssuesList = categories => {
  return categories
    .reduce((accumulator: any, currentValue: any) => [...accumulator, ...currentValue.criticalIssues], [])
    .map(item => {
      return {
        id: item.id,
        value: item.id.toString(),
        label: item.name,
      };
    });
};

const categoryList = categories => {
  return categories.map(item => {
    return { id: item.id, label: item.name, value: item.id.toString() };
  });
};

const displayLabel = (option: any) => {
  return option.label || '';
};

const disabledButton = (text, optionsData, selectedOption) => {
  return !text.trim() || (optionsData && !selectedOption);
};

const displayOptions = (optionsData, type) => {
  return optionsData && optionsData.type === type;
};

const setButtonContainer = (optionsData): any => {
  return optionsData === undefined
    ? { position: 'absolute', margin: '63px 0 0 330px' }
    : { display: 'contents', marginRight: 0 };
};

const displayError = (error, setErrorMessage) => {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.Message &&
    error.response.data.Code &&
    error.response.data.Code === 'EntityAlreadyExist'
  ) {
    setErrorMessage(error.response.data.Message);
  }
};

const handleExpandedEffect = (expanded, resetData, optionsData, getOptionsData) => {
  if (!expanded) {
    resetData();
  } else {
    if (optionsData) {
      getOptionsData();
    }
  }
};

const customStyleRow = optionsData => (optionsData ? { justifyContent: 'space-between' } : {});
const customInputClassName = errorMessage => (errorMessage ? styles.inputError : styles.inputClass);

interface OptionsData {
  label: string;
  type: AddEntityOptionsType;
}

interface IPaginationAnswer {
  collection: any[];
  currentPage: number;
  firstRowOnPage: number;
  lastRowOnPage: number;
  pageCount: number;
  pageSize: number;
  rowCount: number;
}

type Props = {
  title: string;
  subtitle: string;
  inputLabel: string;
  optionsData?: OptionsData;
  languageId: number;
  expanded: string | boolean;
  apiCall: Function;
};

const emptyPaginationAnswer: IPaginationAnswer = {
  collection: [],
  currentPage: 1,
  firstRowOnPage: 1,
  lastRowOnPage: 1,
  pageCount: 1,
  pageSize: 1,
  rowCount: 1,
};
const AddEntity = ({ title, subtitle, inputLabel, optionsData, languageId, expanded, apiCall }: Props) => {
  const [text, setText] = useState<string>('');
  const { t } = useTranslation();
  const [options, setOptions] = useState<any[]>([]);
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [dataTableList, setDataTableList] = useState<IPaginationAnswer>(emptyPaginationAnswer);
  const [modal, setModal] = useState<boolean>(false);

  const getOptionsData = async () => {
    const categories = await getCategories();
    const newList =
      optionsData?.type === AddEntityOptionsType.CriticalIssue
        ? categoryList(categories)
        : optionsData?.type === AddEntityOptionsType.PossibleAction
        ? criticalIssuesList(categories)
        : [];
    setOptions(newList);
  };

  useEffect(() => {
    handleExpandedEffect(expanded, resetData, optionsData, getOptionsData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  useEffect(() => {
    getDataList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetData = () => {
    setText('');
    setSelectedOption(null);
    setErrorMessage('');
  };

  const clearDataShowSuccess = () => {
    resetData();
    dispatch(showSuccesMessage());
  };

  const handleSubmit = async () => {
    const newData = { translations: [{ languageId, name: text.trim() }] };
    if (optionsData) {
      if (optionsData?.type === AddEntityOptionsType.CriticalIssue) {
        try {
          await addCriticalIssue({ ...newData, categoryId: selectedOption.id });
          clearDataShowSuccess();
        } catch (error) {
          displayError(error, setErrorMessage);
        }
      } else if (optionsData?.type === AddEntityOptionsType.PossibleAction) {
        try {
          await addPossibleAction({ ...newData, criticalIssueId: selectedOption.id });
          clearDataShowSuccess();
        } catch (error) {
          displayError(error, setErrorMessage);
        }
      }
    } else {
      try {
        await addCategory(newData);
        clearDataShowSuccess();
      } catch (error) {
        displayError(error, setErrorMessage);
      }
    }

    getDataList();
  };

  const onInptChange = value => {
    setText(value);
    setErrorMessage('');
  };

  const getDataList = async (paginationData = defaultPaginationData) => {
    const tableDataResponseList = await apiCall(paginationData);
    tableDataResponseList.collection = mapEntitiesDataTypes(
      tableDataResponseList.collection,
      getDataTypes(optionsData),
    );
    setDataTableList(tableDataResponseList);
  };

  const updateItem = item => {
    dataTableList.collection.forEach(i => {
      if (item.id === i.id) {
        i.text = item.text;
      }
    });
    setDataTableList(dataTableList);
  };

  const displayModalDeleteError = errorOnDelete => {
    setModal(errorOnDelete);
  };

  return (
    <div className={styles.container}>
      <h4 className={styles.subtitle}>{subtitle}</h4>
      <div className={styles.row} style={customStyleRow(optionsData)}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <p className={styles.label}>{inputLabel}</p>
          <input
            value={text}
            onChange={e => onInptChange(e.target.value)}
            placeholder={t('write_here')}
            className={customInputClassName(errorMessage)}
          ></input>
          <span className={styles.errorMessage}>{errorMessage}</span>
        </div>

        {displayOptions(optionsData, AddEntityOptionsType.CriticalIssue) && (
          <div style={{ width: 300, fontFamily: 'Rubik', color: '#727e8c', fontSize: '14px' }}>
            <p className={styles.label}>{optionsData?.label}</p>
            <Select
              id="delegatedUser"
              components={{ DropdownIndicator }}
              value={selectedOption}
              options={options}
              className="basic-multi-select"
              classNamePrefix="All"
              placeholder={t('select')}
              onChange={value => setSelectedOption(value)}
              styles={multiSelectAddEntityStyle}
            />
          </div>
        )}
        {displayOptions(optionsData, AddEntityOptionsType.PossibleAction) && (
          <div className="add-entity-container">
            <p className={styles.label}>{optionsData?.label}</p>
            <Autocomplete
              id="search-criticl-issues-add"
              options={options}
              getOptionLabel={displayLabel}
              value={selectedOption}
              onChange={(event, item) => {
                setSelectedOption(item);
              }}
              renderInput={params => <TextField placeholder={t('select')} {...params} fullWidth />}
              noOptionsText={t('translation:no_results')}
            />
          </div>
        )}
      </div>
      <div style={setButtonContainer(optionsData)}>
        <SaveButton
          handleClick={() => {
            handleSubmit();
          }}
          customText={title}
          customStyle={{ alignSelf: 'flex-end' }}
          disabled={disabledButton(text, optionsData, selectedOption)}
        />
      </div>

      <div className={styles.row} style={{ marginTop: 36 }}>
        <ContentManagerTable
          type={tableRowTypeKeys.editAndDelete}
          items={dataTableList.collection}
          dataInfo={dataTableList}
          resetStyle={{ marginLeft: 0 }}
          updateItem={updateItem}
          displayGenericErrorMessage={error => displayModalDeleteError(error)}
          updateFunction={paginationData => getDataList(paginationData)}
        />
      </div>

      <ContentManagerModal
        isOpen={modal}
        openModal={() => setModal(true)}
        closeModal={() => setModal(false)}
        modalTitle=""
        fromButton={false}
      >
        <div className={styles.modalRow}>
          <ModalAlert />
          <h2 className={styles.alertText}>
            {`${t('there_are_agreements_that_use')} ${t(renderModalTextType(getDataTypes(optionsData)))} ${t(
              'so_it',
            )} `}
            <span className={styles.boldAlertText}>{` ${t('cannot_be_eliminated')}`}</span>
          </h2>
        </div>
      </ContentManagerModal>
    </div>
  );
};

export default AddEntity;
