import React from 'react';
import { useTranslation } from 'react-i18next';
import './ListEsg.scss';

type Props = {
  values: any;
};

export const ListEsg = ({ values: { esgClasification, esgRisk, esgSafeguard } }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="list-esg-container">
      <h3 className="title">{t('environmental_and_social')}</h3>
      <div className="list-item">
        {t('category')}: <span className="data-value">{esgClasification}</span>
      </div>
      <div className="list-item">
        {t('risk')}:
        <span
          className="bullet"
          style={{
            backgroundColor: getRiskTranslations(esgRisk).color,
            display: 'inline-flex',
            marginLeft: 5,
            marginRight: 5,
          }}
        ></span>
        <span className="data-value">{t(getRiskTranslations(esgRisk).label)}</span>
      </div>
      <div className="list-item">
        {t('performance')} :
        <span
          className="bullet"
          style={{
            backgroundColor: getSafeguardTranslations(esgSafeguard).color,
            display: 'inline-flex',
            marginLeft: 5,
            marginRight: 5,
          }}
        ></span>
        <span className="data-value">{t(getSafeguardTranslations(esgSafeguard).label)}</span>
      </div>
    </div>
  );
};

const getRiskTranslations = risk => {
  switch (risk) {
    case 1:
      return { color: '#91E2A1', label: 'low' };
    case 2:
      return { color: '#F7DD00', label: 'moderate' };
    case 3:
      return { color: '#FDAC41', label: 'substantial' };
    case 4:
      return { color: '#F06D6D', label: 'high' };

    default:
      return { color: '#C1C7D0', label: 'not_assigned' };
  }
};

const getSafeguardTranslations = safeguard => {
  switch (safeguard) {
    case 1:
      return { color: '#F06D6D', label: 'unsatisfactory' };
    case 2:
      return { color: '#FFAFAF', label: 'partially_unsatisfactory' };
    case 3:
      return { color: '#C7EAA3', label: 'partially_satisfactory' };
    case 4:
      return { color: '#91E2A1', label: 'satisfactory' };

    default:
      return { color: '#C1C7D0', label: 'not_assigned' };
  }
};
