import React, { createContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { headerDetailData, tableDetailData } from '../../../api/knowledgeProductApi';
import { TimeRange } from '../../../models/TimeRangeKnowledgeProductsEnum';
import { SearchRender } from '../../Util/CustomInput/SearchRender/SearchRender';
import KnowledgeProductTable from '../KnowledgeProductTable/KnowledgeProductTable';
import KnowledgeSearcher from '../KnowledgeSearcher/KnowledgeSearcher';
import SummaryHeaderSingleValue from '../SummaryHeaderSingleValue/SummaryHeaderSingleValue';
import styles from './KnowledgeProductDetail.module.scss';
import {
  getCountries,
  getMaxDate,
  getMinDate,
  multipleFilter,
  searcherAndFilter,
  setObjectParams,
  setRangeDateByDays,
} from './Util';
import { isEqual } from 'lodash';
import KnowledgeProductFilterBubbles from '../KnowledgeProductFilterBubbles/KnowledgeProductFilterBubbles';
import { AppMenuTabs, MenuTabs } from '../../MenuTabs/userTabsInterface';
import { RegionsAcronymEnum } from '../../../models/RegionsAcronymEnum';

const setSelectedLink = (seter, stateValue) => {
  return seter({
    ...stateValue,
    selected: !stateValue.selected,
  });
};

const setUnselectedLink = (seter, stateValue) => {
  return seter({
    ...stateValue,
    selected: stateValue.selected === true && !stateValue.selected,
  });
};

const customStyle = { minWidth: 120 };
const customValueStyle = { fontSize: 36 };
const customSubtitleStyle = { fontSize: 16, fontWeight: 600, color: '#264377', paddingTop: 10 };
const customLinkStyle = { fontSize: 16, fontWeight: 300, textDecoration: 'underline', color: '#264377' };

export const searchObject = {
  from: new Date().toISOString() || null,
  to: new Date().toISOString() || null,
  componenteHandleDateRanges: true,
  rangeDate: 30,
  productType: [{ value: 'all', id: 0, label: 'all' }],
  filteredCountries: [{ value: 'all', id: 0, label: 'all' }],
  departmentDivision: [{ value: 'all', id: 0, label: 'all' }],
  countries: [{ value: 'all', id: 0, label: 'all' }],
  disabledCountriesSelect: false,
  modalFilterUsed: false,
  firstLoad: true,
  minDate: new Date().toISOString() || null,
  maxDate: new Date().toISOString() || null,
  searchButtonFired: false,
};

export const SearchObjectContext = createContext([{}, () => {}]);

const defineDefaultSelect = timeRange => {
  const rangeStored = localStorage.getItem('knowledgeIndicatorRange');
  if (!rangeStored) {
    return timeRange === TimeRange.Month_1;
  }

  return rangeStored === timeRange.toString();
};

const defineDefaultRangeDate = () => {
  const rangeStored = localStorage.getItem('knowledgeIndicatorRange');
  if (rangeStored) {
    return parseInt(rangeStored);
  } else {
    return TimeRange.Month_1;
  }
};

type Props = {
  headerTitle: any;
};
const KnowledgeProductDetail = ({ headerTitle }: Props) => {
  const { t } = useTranslation();
  const [last30DaysState, setLast30DaysState] = useState({
    value: 0,
    selected: defineDefaultSelect(TimeRange.Month_1),
  });
  const [last6MonthsState, setLast6MonthsState] = useState({
    value: 0,
    selected: defineDefaultSelect(TimeRange.Month_6),
  });
  const [last12MonthsState, setLast12MonthsState] = useState({
    value: 0,
    selected: defineDefaultSelect(TimeRange.Month_12),
  });
  const [everyoneState, setEveryoneState] = useState({ value: 0, selected: defineDefaultSelect(TimeRange.Everyone) });
  const [tableData, setTableData] = useState([]);
  const [renderData, setRenderData] = useState([]);
  const [filteredModalUsedData, setFilteredModalUsedData] = useState([]);
  const [rangeDate, setRangeDate] = useState(setRangeDateByDays(defineDefaultRangeDate()));
  const [searchContext, setSearchContext] = useState(searchObject);
  const { regionId: regionsFilterIndicator } = useSelector<any, any>(state => state.filterIndicators.common);
  const { portfolioSelectedTab } = useSelector<any, AppMenuTabs>(state => state.menuTabs);
  const { regions } = useSelector<any, any>(state => state);

  const [filterBtnData, setFilterBtnData] = useState<boolean>(false);

  const handleGetKnowledgeHeaderDetail = async () => {
    const objectParams = setObjectParams(searchContext);
    const data: any = await Promise.all([headerDetailData(objectParams), tableDetailData(rangeDate)]);
    setLast30DaysState({ ...last30DaysState, value: data[0].totalQuantityLast30Days });
    setLast6MonthsState({ ...last6MonthsState, value: data[0].totalQuantityLast180Days });
    setLast12MonthsState({ ...last12MonthsState, value: data[0].totalQuantityLast365Days });
    setEveryoneState({ ...everyoneState, value: data[0].totalQuantity });
    setTableData(data[1]);
    const multipleFilterData = multipleFilter(data[1], searchContext);
    setRenderData(multipleFilterData);
  };

  useEffect(() => {
    setIndicatorTitle(portfolioSelectedTab);
    setSearchContext({
      ...searchContext,
      from: setRangeDateByDays(TimeRange.Month_1).from,
      to: setRangeDateByDays(TimeRange.Month_1).to,
      rangeDate: TimeRange.Month_1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateSearchContextOnrenderDataChange = (min, max) => {
    setSearchContext({
      ...searchContext,
      from: min,
      to: max,
      minDate: min,
      maxDate: max,
      firstLoad: false,
      rangeDate: defineDefaultRangeDate(),
    });
  };

  useEffect(() => {
    if (
      (searchContext.rangeDate === TimeRange.Everyone && searchContext.componenteHandleDateRanges) ||
      searchContext.firstLoad
    ) {
      const minDate = getMinDate(renderData);
      const maxDate = getMaxDate(renderData);
      updateSearchContextOnrenderDataChange(minDate, maxDate);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderData]);

  useEffect(() => {
    handleGetKnowledgeHeaderDetail();
    if (searchContext.firstLoad) {
      setSearchContext({
        ...searchContext,
        from: setRangeDateByDays(searchContext.rangeDate).from,
        to: setRangeDateByDays(searchContext.rangeDate).to,
        rangeDate: searchContext.rangeDate,
        firstLoad: false,
      });
      setCountriesToFilter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rangeDate]);

  const handleRangeDate = rangeSelected => {
    setFilterBtnData(prev => !prev);
    setRangeDate(setRangeDateByDays(rangeSelected));
    const minDate = getMinDate(renderData);
    const maxDate = getMaxDate(renderData);
    setSearchContext({
      ...searchContext,
      from: setRangeDateByDays(rangeSelected).from !== null ? setRangeDateByDays(rangeSelected).from : minDate,
      to: setRangeDateByDays(rangeSelected).to !== null ? setRangeDateByDays(rangeSelected).to : maxDate,
      rangeDate: rangeSelected,
      searchButtonFired: !searchContext.searchButtonFired,
      componenteHandleDateRanges: true,
    });
  };

  const updateFilterModalUsed = tableDataIsOriginal => {
    setSearchContext({
      ...searchContext,
      modalFilterUsed: !tableDataIsOriginal,
      searchButtonFired: !searchContext.searchButtonFired,
    });
  };

  const handleAdvancedSearch = async () => {
    const objectParams = setObjectParams(searchContext);
    const updateHeaderDetailValues: any = await headerDetailData(objectParams);
    const multipleFilterData = multipleFilter(tableData, searchContext);
    const tableDataIsOriginal = isEqual(tableData, multipleFilterData);
    updateFilterModalUsed(tableDataIsOriginal);
    setFilteredModalUsedData(multipleFilterData);
    setRenderData(multipleFilterData);
    setLast30DaysState({ ...last30DaysState, value: updateHeaderDetailValues.totalQuantityLast30Days });
    setLast6MonthsState({ ...last6MonthsState, value: updateHeaderDetailValues.totalQuantityLast180Days });
    setLast12MonthsState({ ...last12MonthsState, value: updateHeaderDetailValues.totalQuantityLast365Days });
    setEveryoneState({ ...everyoneState, value: updateHeaderDetailValues.totalQuantity });
  };

  const handleSearchTerm = e => {
    const searchTerm = e.target.value.toLowerCase();
    setRenderData(searcherAndFilter(searchTerm, searchContext.modalFilterUsed ? filteredModalUsedData : tableData));
  };

  const setCountriesToFilter = () => {
    if (
      portfolioSelectedTab.internalId === '0_global_bid' ||
      (regionsFilterIndicator.length &&
        (portfolioSelectedTab.title === RegionsAcronymEnum.CAN ||
          portfolioSelectedTab.title === RegionsAcronymEnum.CCB ||
          portfolioSelectedTab.title === RegionsAcronymEnum.CID ||
          portfolioSelectedTab.title === RegionsAcronymEnum.CSC ||
          portfolioSelectedTab.title === RegionsAcronymEnum.REG))
    ) {
      const haveRegionsInfilter = regionsFilterIndicator.length ? regionsFilterIndicator.map(r => r.label) : [];
      setSearchContext({
        ...searchContext,
        countries: getCountries(regions, haveRegionsInfilter),
        disabledCountriesSelect: false,
      });
    } else {
      setSearchContext({
        ...searchContext,
        countries: getCountries(regions),
        disabledCountriesSelect: false,
      });
    }
  };

  const setIndicatorTitle = (portfolioSelectedTab: MenuTabs) => {
    if (portfolioSelectedTab.internalId === '0_global_bid') {
      headerTitle(true, `${t('knowledge_product_of')} ${t(portfolioSelectedTab.title)}`);
    } else {
      headerTitle(true, `${t('knowledge_product_related_to')} ${portfolioSelectedTab.title}`);
    }
  };

  const linkStateOptions = {
    setLast30DaysState,
    last30DaysState,
    setLast6MonthsState,
    last6MonthsState,
    setLast12MonthsState,
    last12MonthsState,
    setEveryoneState,
    everyoneState,
  };
  return (
    <div className={styles.layout}>
      <KnowledgeProductFilterBubbles searchContext={searchContext} />
      <div className={styles.header}>
        {renderSummaryHeaderSingleValues(linkStateOptions, handleRangeDate, t)}
        <div className={styles.searchContainer}>
          <SearchRender placeholder="search_by_name_type_etc" onChangeHandler={e => handleSearchTerm(e)} />
          <SearchObjectContext.Provider value={[searchContext, setSearchContext]}>
            <KnowledgeSearcher searchSubmit={handleAdvancedSearch} />
          </SearchObjectContext.Provider>
        </div>
      </div>
      <div className={styles.table}>
        <KnowledgeProductTable tableData={renderData} filterBtnData={filterBtnData} />
      </div>
    </div>
  );
};

export default KnowledgeProductDetail;

const renderSummaryHeaderSingleValues = (linkStateOptions, handleRangeDate, t) => {
  const { last30DaysState, last6MonthsState, last12MonthsState, everyoneState } = linkStateOptions;
  return (
    <>
      {' '}
      <SummaryHeaderSingleValue
        value={last30DaysState.value}
        title={t('last_30_days')}
        customStyle={customStyle}
        customValueStyle={customValueStyle}
        customSubtilteStyle={customSubtitleStyle}
        customLinkStyle={customLinkStyle}
        isLink={true}
        isSelected={last30DaysState.selected}
        callbackLink={() => {
          handleSelectedLink(TimeRange.Month_1, handleRangeDate, linkStateOptions);
        }}
      />
      <SummaryHeaderSingleValue
        value={last6MonthsState.value}
        title={t('last_6_months')}
        customStyle={customStyle}
        customValueStyle={customValueStyle}
        customSubtilteStyle={customSubtitleStyle}
        customLinkStyle={customLinkStyle}
        isLink={true}
        isSelected={last6MonthsState.selected}
        callbackLink={() => {
          handleSelectedLink(TimeRange.Month_6, handleRangeDate, linkStateOptions);
        }}
      />
      <SummaryHeaderSingleValue
        value={last12MonthsState.value}
        title={t('last_12_months')}
        customStyle={customStyle}
        customValueStyle={customValueStyle}
        customSubtilteStyle={customSubtitleStyle}
        customLinkStyle={customLinkStyle}
        isSelected={last12MonthsState.selected}
        isLink={true}
        callbackLink={() => {
          handleSelectedLink(TimeRange.Month_12, handleRangeDate, linkStateOptions);
        }}
      />
      <SummaryHeaderSingleValue
        value={everyoneState.value}
        title={t('everyone')}
        customStyle={customStyle}
        customValueStyle={customValueStyle}
        customSubtilteStyle={customSubtitleStyle}
        customLinkStyle={customLinkStyle}
        isLink={true}
        isSelected={everyoneState.selected}
        callbackLink={() => {
          handleSelectedLink(TimeRange.Everyone, handleRangeDate, linkStateOptions);
        }}
      />
    </>
  );
};

const handleSelectedLink = (
  rangeSelected = TimeRange.Month_1,
  handleRangeDate,
  {
    setLast30DaysState,
    last30DaysState,
    setLast6MonthsState,
    last6MonthsState,
    setLast12MonthsState,
    last12MonthsState,
    setEveryoneState,
    everyoneState,
  },
) => {
  handleRangeDate(rangeSelected);
  localStorage.setItem('knowledgeIndicatorRange', rangeSelected.toString());
  switch (rangeSelected) {
    case TimeRange.Month_1:
      setSelectedLink(setLast30DaysState, last30DaysState);
      setUnselectedLink(setLast6MonthsState, last6MonthsState);
      setUnselectedLink(setLast12MonthsState, last12MonthsState);
      setUnselectedLink(setEveryoneState, everyoneState);
      break;
    case TimeRange.Month_6:
      setUnselectedLink(setLast30DaysState, last30DaysState);
      setSelectedLink(setLast6MonthsState, last6MonthsState);
      setUnselectedLink(setLast12MonthsState, last12MonthsState);
      setUnselectedLink(setEveryoneState, everyoneState);
      break;
    case TimeRange.Month_12:
      setUnselectedLink(setLast30DaysState, last30DaysState);
      setUnselectedLink(setLast6MonthsState, last6MonthsState);
      setSelectedLink(setLast12MonthsState, last12MonthsState);
      setUnselectedLink(setEveryoneState, everyoneState);
      break;
    default:
      setUnselectedLink(setLast30DaysState, last30DaysState);
      setUnselectedLink(setLast6MonthsState, last6MonthsState);
      setUnselectedLink(setLast12MonthsState, last12MonthsState);
      setSelectedLink(setEveryoneState, everyoneState);
      break;
  }
};
