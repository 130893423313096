import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, Theme } from '@material-ui/core/styles';

const commonStyles = {
  margin: '15px 0 0 0',
  fontFamily: 'Rubik',
  fontSize: 16,
  fontWeight: 400,
  lineHeight: '20px',
};

const commonStylesBidTooltipGrayAndWhite = {
  margin: '15px 0 2px 0',
  padding: 10,
  fontFamily: 'Rubik',
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '14px',
};

const bidTooltipOriginalStyles = {
   ...commonStyles, 
   backgroundColor: '#000000', 
   color: '#ffffff', 
   padding: '8px 12px',
  
  //  '&:before':{
  //   content:"''",
  //   borderBottom: '15px solid #000',
  //   borderLeft: '8px solid transparent',
  //   borderRight: '8px solid transparent',
  //   position: 'absolute',
  //   left: '5%',
  //   height: '0',
  //   top: '0',
  // }
  };
const bidTooltipBlueStyles = { ...commonStyles, backgroundColor: '#417FFE', color: '#ffffff' };
const bidTooltipGrayAndWhite = { ...commonStylesBidTooltipGrayAndWhite, backgroundColor: '#101319', color: '#ffffff' };

export const BidTooltip = withStyles(() => ({
  tooltip: bidTooltipOriginalStyles,
  arrow: {
    fontSize: 20,
    color: '#4A4A4A',
    '&::before': {
      backgroundColor: '#101319',
    }
  }
}))(Tooltip);

export const BidTooltipBlueStyles = withStyles(() => ({
  tooltip: bidTooltipBlueStyles,
}))(Tooltip);

export const BidTooltipGrayAndWhite = withStyles(() => ({
  tooltip: bidTooltipGrayAndWhite,
}))(Tooltip);

export const PrevTrend = withStyles((theme: Theme) => ({
  tooltip: {
    margin: '15px 0 0 0',
    backgroundColor: '#101319',
    color: '#FFFFFF',
    fontFamily: 'Rubik',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    titleFontSize: 14, 
    bodyFontSize: 14,
  },
}))(Tooltip);

export const MonthCalendarTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
    margin: '-20px 0 0 0',
    backgroundColor: '#101319',
    color: '#D2D2D2',
    fontFamily: 'Source Sans Pro',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '10px',
    titleFontSize: 14, 
     bodyFontSize: 14,
  },
  arrow: {
    color: '#101319',
  },
}))(Tooltip);

export const ExecComponentTooltip = withStyles(() => ({
  tooltip: { ...bidTooltipOriginalStyles, margin: '-10px 0 0 0' },
}))(Tooltip);
