import React from 'react';
import './DisbursementProjectionFilter.scss';
import { useTranslation } from 'react-i18next';
import NumberInput from '../../Util/CustomInput/NumberInput/NumberInput';
import RequiredErrorMessageCustomMessage from '../../Util/Errors/RequiredErrorMessageCustomMessage/RequiredErrorMessageCustomMessage';
import { trackActionClicked } from '../../../api/trackActionClickedApi';
import { TrackedActions } from '../../../models/TrackedActionsEnum';
import useFilters from '../../../hooks/useFilters';
import { CalledFrom } from '../../../models/CalledFromEnum';

interface Props {
  indicatorCall?: boolean;
  calledFrom: CalledFrom;
}

export const setDefaultValueFrom = (
  indicatorCall,
  filter,
  filterIndicators,
  defaultMinValue,
  calledFrom,
  filterEarlyWarnings,
) => {
  if (calledFrom === CalledFrom.EARLY_WARNINGS) {
    return filterEarlyWarnings.common.disbursementProjectionFrom !== defaultMinValue
      ? filterEarlyWarnings.common.disbursementProjectionFrom
      : 0;
  } else {
    if (indicatorCall) {
      return filterIndicators.common.disbursementProjectionFrom !== defaultMinValue
        ? filterIndicators.common.disbursementProjectionFrom
        : 0;
    } else {
      return filter.common.disbursementProjectionFrom !== defaultMinValue
        ? filter.common.disbursementProjectionFrom
        : 0;
    }
  }
};

export const setDefaultValueTo = (
  indicatorCall,
  filter,
  filterIndicators,
  defaultMaxValue,
  calledFrom,
  filterEarlyWarnings,
) => {
  if (calledFrom === CalledFrom.EARLY_WARNINGS) {
    return filterEarlyWarnings.common.disbursementProjectionTo !== defaultMaxValue
      ? filterEarlyWarnings.common.disbursementProjectionTo
      : 0;
  } else {
    if (indicatorCall) {
      return filterIndicators.common.disbursementProjectionTo !== defaultMaxValue
        ? filterIndicators.common.disbursementProjectionTo
        : 0;
    } else {
      return filter.common.disbursementProjectionTo !== defaultMaxValue ? filter.common.disbursementProjectionTo : 0;
    }
  }
};

export const setDisbursementProjectionFrom = (
  indicatorCall,
  filterIndicators,
  filter,
  filterEarlyWarnings,
  calledFrom,
) => {
  if (CalledFrom.EARLY_WARNINGS === calledFrom) {
    return filterEarlyWarnings.common.disbursementProjectionFrom;
  } else {
    if (indicatorCall) {
      return filterIndicators.common.disbursementProjectionFrom;
    } else {
      return filter.common.disbursementProjectionFrom;
    }
  }
};

export const setDisbursementProjectionTo = (
  indicatorCall,
  filterIndicators,
  filter,
  filterEarlyWarnings,
  calledFrom,
) => {
  if (CalledFrom.EARLY_WARNINGS === calledFrom) {
    return filterEarlyWarnings.common.disbursementProjectionTo;
  } else {
    if (indicatorCall) {
      return filterIndicators.common.disbursementProjectionTo;
    } else {
      return filter.common.disbursementProjectionTo;
    }
  }
};

export const getDefaultValue = (indicatorCall, from, to, calledFrom, earlyWarningFromTo) => {
  if (calledFrom === CalledFrom.EARLY_WARNINGS) {
    return earlyWarningFromTo;
  }
  return indicatorCall ? from : to;
};

const DisbursementProjectionFilter = (props: Props) => {
  const [errorMessages, handleErrorMessages] = React.useState({ isHidden: true, message: '' });
  const { indicatorCall, calledFrom } = props;
  const {
    filter,
    filterIndicators,
    setFilter,
    setFilterIndicators,
    dispatch,
    filterEarlyWarnings,
    setFilterEarlyWarnings,
  } = useFilters();

  const defaultMaxValue = 0;
  const defaultMinValue = 0;

  let disbursementProjectionFrom = setDisbursementProjectionFrom(
    indicatorCall,
    filterIndicators,
    filter,
    filterEarlyWarnings,
    calledFrom,
  );
  let disbursementProjectionTo = setDisbursementProjectionTo(
    indicatorCall,
    filterIndicators,
    filter,
    filterEarlyWarnings,
    calledFrom,
  );

  const { t } = useTranslation();

  const handleValue = (min, value) => {
    if (min) {
      disbursementProjectionFrom = +value;
    } else {
      disbursementProjectionTo = +value;
    }

    if (disbursementProjectionFrom >= disbursementProjectionTo && disbursementProjectionTo !== defaultMaxValue) {
      handleErrorMessages({ isHidden: false, message: 'right_input_validation' });
      handleDispatch({
        calledFrom,
        dispatch,
        setFilterEarlyWarnings,
        filterEarlyWarnings,
        disbursementProjectionFrom,
        disbursementProjectionTo,
        indicatorCall,
        setFilterIndicators,
        filterIndicators,
        setFilter,
        filter,
        trackedAction: false,
      });
    } else {
      handleErrorMessages({ isHidden: true, message: '' });
      if (disbursementProjectionTo === defaultMinValue) {
        disbursementProjectionTo = defaultMaxValue;
      }
      handleDispatch({
        calledFrom,
        dispatch,
        setFilterEarlyWarnings,
        filterEarlyWarnings,
        disbursementProjectionFrom,
        disbursementProjectionTo,
        indicatorCall,
        setFilterIndicators,
        filterIndicators,
        setFilter,
        filter,
        trackedAction: true,
      });
    }
  };

  const defaultValueFilterProjectsDisbusementProjectionFrom = setDefaultValueFrom(
    indicatorCall,
    filter,
    filterIndicators,
    defaultMinValue,
    calledFrom,
    filterEarlyWarnings,
  );

  const defaultValueFilterIndicatorsDisbusementProjectionFrom = setDefaultValueFrom(
    indicatorCall,
    filter,
    filterIndicators,
    defaultMinValue,
    calledFrom,
    filterEarlyWarnings,
  );

  const defaultValueFilterEarlyWarningsDisbusementProjectionFrom = setDefaultValueFrom(
    indicatorCall,
    filter,
    filterIndicators,
    defaultMinValue,
    calledFrom,
    filterEarlyWarnings,
  );

  const defaultValueFilterProjectsDisbusementProjectionTo = setDefaultValueTo(
    indicatorCall,
    filter,
    filterIndicators,
    defaultMaxValue,
    calledFrom,
    filterEarlyWarnings,
  );
  const defaultValueFilterIndicatorsDisbusementProjectionTo = setDefaultValueTo(
    indicatorCall,
    filter,
    filterIndicators,
    defaultMaxValue,
    calledFrom,
    filterEarlyWarnings,
  );

  const defaultValueFilterEarlyWarningsDisbusementProjectionTo = setDefaultValueTo(
    indicatorCall,
    filter,
    filterIndicators,
    defaultMaxValue,
    calledFrom,
    filterEarlyWarnings,
  );

  const defaultValueFrom = getDefaultValue(
    indicatorCall,
    defaultValueFilterIndicatorsDisbusementProjectionFrom,
    defaultValueFilterProjectsDisbusementProjectionFrom,
    calledFrom,
    defaultValueFilterEarlyWarningsDisbusementProjectionFrom,
  );
  const defaultValueTo = getDefaultValue(
    indicatorCall,
    defaultValueFilterIndicatorsDisbusementProjectionTo,
    defaultValueFilterProjectsDisbusementProjectionTo,
    calledFrom,
    defaultValueFilterEarlyWarningsDisbusementProjectionTo,
  );

  return renderDisbursementProjectionFilter(defaultValueFrom, defaultValueTo, errorMessages, handleValue, t);
};

const handleDispatch = ({
  calledFrom,
  dispatch,
  setFilterEarlyWarnings,
  filterEarlyWarnings,
  disbursementProjectionFrom,
  disbursementProjectionTo,
  indicatorCall,
  setFilterIndicators,
  filterIndicators,
  setFilter,
  filter,
  trackedAction,
}) => {
  if (calledFrom === CalledFrom.EARLY_WARNINGS) {
    dispatch(
      setFilterEarlyWarnings({
        ...filterEarlyWarnings,
        common: {
          ...filterEarlyWarnings.common,
          disbursementProjectionFrom,
          disbursementProjectionTo,
        },
      }),
    );
    trackedAction && trackActionClicked(TrackedActions.disbusementProjectionFilterChangedFromEarlyWarnings);
  } else {
    if (indicatorCall) {
      dispatch(
        setFilterIndicators({
          ...filterIndicators,
          common: {
            ...filterIndicators.common,
            disbursementProjectionFrom,
            disbursementProjectionTo,
          },
        }),
      );
      trackedAction && trackActionClicked(TrackedActions.disbusementProjectionFilterChangedFromPortfolio);
    } else {
      dispatch(
        setFilter({
          ...filter,
          common: {
            ...filter.common,
            disbursementProjectionFrom,
            disbursementProjectionTo,
            itSearch: true,
          },
        }),
      );
      trackedAction && trackActionClicked(TrackedActions.disbusementProjectionFilterChangedFromProject);
    }
  }
};

export const renderDisbursementProjectionFilter = (defaultValueFrom, defaultValueTo, errorMessages, handleValue, t) => {
  return (
    <div className="disbursements-projection-filter-container">
      <div className="input-section">
        <span>{t('between')}</span>
        <NumberInput
          min="1000"
          placeholderText="1000"
          value={handleValue}
          minParam={true}
          defaultValue={defaultValueFrom}
          name="timeFrom"
        />
        <span>{t('and')}</span>
        <NumberInput
          min="1000"
          placeholderText="100.000.000"
          value={handleValue}
          minParam={false}
          inputWidth={{ width: '104px' }}
          defaultValue={defaultValueTo}
          name="timeTo"
        />
        <span>{t('dolar_sign')}</span>
      </div>
      <div className="validation-section">
        <RequiredErrorMessageCustomMessage isHidden={errorMessages.isHidden} errorMessage={t(errorMessages.message)} />
      </div>
    </div>
  );
};

export default DisbursementProjectionFilter;
