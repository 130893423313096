export enum ShortMonthsEnum {
  'january_short' = 1,
  'february_short',
  'march_short',
  'april_short',
  'may_short',
  'june_short',
  'july_short',
  'august_short',
  'september_short',
  'october_short',
  'november_short',
  'december_short',
}
