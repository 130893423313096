import React, { Fragment } from 'react';
import { Bubble } from '../BubbleElement/BubbleElement';
import useFilters from '../../../hooks/useFilters';
import { CalledFrom } from '../../../models/CalledFromEnum';
import { IFilterBubble } from '../../../models/FilterBubble';
import { handleActualStateWithAnalytics, handleBubblesWithAnalytics } from '../UtilFunctions';

type Props = IFilterBubble;

const DivisionBubbles = (props: Props) => {
  const { indicatorCall, analyticCall, calledFrom } = props;
  const {
    filter,
    filterIndicators,
    filterAnalytics,
    dispatch,
    setFilter,
    setFilterIndicators,
    setFilterAnalytics,
    filterEarlyWarnings,
    setFilterEarlyWarnings,
  } = useFilters();

  const bubbles = handleBubblesWithAnalytics({
    calledFrom,
    indicatorCall,
    filterIndicators,
    analyticCall,
    filterAnalytics,
    filter,
    filterEarlyWarnings,
  });
  const closeBubble = divisionId => {
    const actualState = handleActualStateWithAnalytics({
      calledFrom,
      indicatorCall,
      filterIndicators,
      analyticCall,
      filterEarlyWarnings,
      filterAnalytics,
      filter,
      commonProp: 'divisionId',
    });

    const newState = actualState.filter(division => {
      return division.id !== divisionId;
    });

    if (calledFrom === CalledFrom.EARLY_WARNINGS) {
      dispatch(
        setFilterEarlyWarnings({
          ...filterEarlyWarnings,
          common: {
            ...filterEarlyWarnings.common,
            divisionId: newState,
          },
        }),
      );
    } else {
      if (indicatorCall) {
        dispatch(
          setFilterIndicators({
            ...filterIndicators,
            common: {
              ...filterIndicators.common,
              divisionId: newState,
            },
          }),
        );
      } else if (analyticCall) {
        dispatch(
          setFilterAnalytics({
            ...filterAnalytics,
            divisionId: newState,
          }),
        );
      } else {
        dispatch(
          setFilter({
            ...filter,
            common: {
              ...filter.common,
              divisionId: newState,
              itSearch: false,
            },
          }),
        );
      }
    }
  };
  return (
    <Fragment>
      {bubbles.divisionId
        .filter(division => division.selectedByUser)
        .map(division => (
          <Bubble
            key={division.id}
            itsHidden={false}
            label={division.label}
            closeBubble={() => closeBubble(division.id)}
          />
        ))}
    </Fragment>
  );
};

export default DivisionBubbles;
