/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isScrollAtBottom, isMobile } from '../../../../services/booleanUtil';
import { sortAlphabetically } from '../../../../services/sortsUtil';
import './Risks.scss';
import Project from '../../../../models/Project';
import { getRisks } from '../../../../api/projectApi';
import * as config from '../../../../api/config.json';
import { ReactComponent as Sort } from '../../../../assets/icons/u_sort.svg';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: 'none',
      paddingLeft: 0,
    },
  }),
)(TableCell);

export const getSeverity = (severity, t) => {
  let colorClass;
  switch (severity) {
    case 0:
      colorClass = 'low';
      break;
    case 1:
      colorClass = 'medium-low';
      break;
    case 2:
      colorClass = 'medium-high';
      break;
    case 3:
      colorClass = 'high';
      break;
  }

  return <div className={`severity-container ${colorClass}`}>{t(colorClass)}</div>;
};

export const Risks = () => {
  const currentProject = useSelector<any, Project>(state => state.currentProject);

  const [risks, setRisks] = React.useState<any[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [paginatedRisks, setPaginatedRisks] = React.useState<any[]>([]);

  const [sortSeverity, setSortSeverity] = React.useState(true);
  const { t } = useTranslation();

  const loadRisks = async () => {
    const response = await getRisks(currentProject.id);
    const riskList = response;
    if (riskList && riskList.length) {
      setRisks(riskList);

      let subprogramsCount = 0;
      let programIndex = 0;
      while (subprogramsCount < 10 && programIndex < riskList.length) {
        subprogramsCount += riskList[programIndex].riskPrograms.length;
        programIndex++;
      }
      setPaginatedRisks(riskList.slice(0, programIndex));
    }
  };

  React.useEffect(() => {
    loadRisks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!isLoading) {
      return;
    }
    const index = paginatedRisks.length;
    if (index < risks.length) {
      const risk = risks[index];
      paginatedRisks.push(risk);
      setPaginatedRisks(paginatedRisks);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const sortBySeverity = () => {
    risks.forEach(risk => {
      risk.riskPrograms.sort(sortAlphabetically('severity', sortSeverity ? 'asc' : 'desc'));
    });
    setRisks(risks);
    setSortSeverity(!sortSeverity);
  };

  window.onscroll = ev => {
    if (isScrollAtBottom()) {
      setIsLoading(true);
    }
  };

  const getRiskTable = programs => {
    return (
      <TableContainer>
        <Table className="custom-table" style={{ tableLayout: 'auto' }} size="small">
          <TableBody>
            {programs.map(program => (
              <TableRow style={{ height: '30px' }}>
                <StyledTableCell style={{ width: '85%' }}>{program.description}</StyledTableCell>
                <StyledTableCell style={{ width: '5%' }}>{getSeverity(program.severity, t)}</StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const getRisksMobile = programs => {
    return programs.map(program => (
      <div className="risk-mobile-container">
        <div className="top">{getSeverity(program.severity, t)}</div>
        <p className="cell-text mobile-desc">{program.description}</p>
      </div>
    ));
  };

  return (
    <div className="risks-container">
      {renderPaginatadRisks(paginatedRisks, sortBySeverity, getRisksMobile, getRiskTable, currentProject, t)}
    </div>
  );
};

export default Risks;

const renderPaginatadRisks = (paginatedRisks, sortBySeverity, getRisksMobile, getRiskTable, currentProject, t) => {
  return paginatedRisks.length ? (
    <>
      <div className="header">
        <span className="table-title severity-header" onClick={sortBySeverity}>
          {t('type_desc')}
          <Sort />
        </span>
        {!isMobile() && (
          <span className="table-title severity-header" onClick={sortBySeverity}>
            {t('severity')} <i className="sort-icon"></i>
            <Sort />
          </span>
        )}
      </div>
      <div className="table">
        {paginatedRisks &&
          paginatedRisks.map(risk => (
            <>
              <div className="container-row-table">
                <div className="subtitle">
                  <span>{risk.riskType.name}</span>
                </div>
                {isMobile() ? getRisksMobile(risk.riskPrograms) : getRiskTable(risk.riskPrograms)}
              </div>
            </>
          ))}
      </div>
    </>
  ) : (
    <div className="empty-state">
      <p className="message">
        {`${t('there_is_no_information_about_the_risks')} `}

        <a
          href={`${config.CONVERGENCIA_URL.replace('{project_number}', currentProject.code)}`}
          className="link"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('add_it_in_convergence')}
        </a>
      </p>
    </div>
  );
};
