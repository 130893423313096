import React from 'react';
import { useSelector } from 'react-redux';
import './Acquisitions.scss';
import { AcquisitionHeader } from '../AcquisitionHeader/AcquisitionHeader';
import AcquisitionAccordeon from '../AcquisitionAccordeon/AcquisitionAccordeon';
import NewPlanningItem, { PlanningItemType } from '../NewItem/NewPlanningItem';
import { KeyProduct } from '../../../../../models/Planning';
import { isAuthorizedToEdit } from '../../../../../services/booleanUtil';
import User from '../../../../../models/User';
import Project from '../../../../../models/Project';

type Props = {
  keyProduct: KeyProduct;
};

const Acquisitions = ({ keyProduct }: Props) => {
  const user = useSelector<any, User>(state => state.user);
  const currentProject = useSelector<any, Project>(state => state.currentProject);
  return (
    <div className="acquisitions-planning-container">
      <AcquisitionHeader />
      <AcquisitionAccordeon keyProduct={keyProduct} />

      {isAuthorizedToEdit(user, currentProject) && (
        <NewPlanningItem keyProductId={keyProduct.id || 0} type={PlanningItemType.Acquisition} />
      )}
    </div>
  );
};

export default Acquisitions;
