import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './LargeImage.module.scss';
import { Bubble } from 'react-chartjs-2';
import { setOptions } from '../../../../PortfolioDetail/DisbursementTime/Util';
import { DisbursementOverTimeLargeKeyToProductType, getBiggerX } from './Util';
import { hasData } from '../ImagesHelper';

type Props = {
  indicator: any;
};

const DisbursementTime = ({ indicator }: Props) => {
  const { t } = useTranslation();
  const { multiChartContainer, flexCenterCentered, noDataText } = styles;
  return indicator.datasets && hasData(indicator.datasets) ? (
    <div className={multiChartContainer} id={indicator.largeImageKey}>
      <Bubble
        data={{ datasets: indicator.datasets }}
        width={1200}
        height={900}
        options={setOptions(
          DisbursementOverTimeLargeKeyToProductType[indicator.largeImageKey],
          {
            upperY: 100,
            bottomY: -10,
            leftX: -1,
            rightX: getBiggerX(indicator.datasets),
          },
          true,
        )}
      />
    </div>
  ) : (
    <div className={flexCenterCentered} id={indicator.largeImageKey}>
      <h2 className={noDataText}>{t('no_data_available')}</h2>
    </div>
  );
};

export default DisbursementTime;
