import React from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { MonthsEnum } from '../../../../models/MonthsEnum';
import styles from './HeaderTable.module.scss';

const { headerContainer, titlesContainer, title, valuesContainer, titleValue } = styles;

export interface IHeaderTable {
  month: string;
  year: number;
  amountToBeDisbursed: number;
}

type Props = {
  data: IHeaderTable;
};

export const HeaderTable = ({ data }: Props) => {
  const currentYear = new Date().getFullYear();
  const { month, amountToBeDisbursed, year } = data;
  const { t } = useTranslation();

  return (
    <div className={headerContainer}>
      <div className={titlesContainer}>
        <div className={title}>{t('month')}</div>
        <div className={title}>{t('current_projection')}</div>
      </div>
      <div className={valuesContainer}>
        <div className={titleValue}>{`${t(MonthsEnum[parseInt(month)].toLowerCase())} ${year > currentYear ? year : ''
          }`}</div>
        <div className={titleValue}>
          <NumberFormat
            value={amountToBeDisbursed}
            displayType={'text'}
            thousandSeparator={'.'}
            decimalSeparator={','}
            prefix={`${t('dolar_sign')} `}
          />
        </div>
      </div>
    </div>
  );
};
