import React, { useContext, useEffect, useState } from 'react';
import styles from './CustomInput.module.scss';
import { TranslationsContext } from '../context/TranslationsContext';

type Props = {
  translation: any;
  hasChanges: any;
};

const CustomInput = ({ translation, hasChanges }: Props) => {
  const [localValue, setLocalValue] = useState<string>('');
  const { dispatch } = useContext(TranslationsContext);

  const onTextChange = value => {
    setLocalValue(value);
    dispatch({ type: 'ADD_VALUE', payload: { id: translation.id, value } });
    hasChanges();
  };

  useEffect(() => {
    setLocalValue(translation.value || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <input
      className={styles.customInput}
      type={'text'}
      placeholder={''}
      onChange={e => onTextChange(e.target.value)}
      value={localValue}
    ></input>
  );
};

export default CustomInput;
