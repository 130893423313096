import React, { useEffect } from 'react';
import Popover from '@material-ui/core/Popover';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styles from './ActivityOptions.module.scss';
import { withStyles } from '@material-ui/core';
import SupervisionModal from '../../SupervisionModal/SupervisionModal';
import LinkEventOutlook from '../../../../../Util/Modals/LinkProjectOutlook/LinkEventOutlook';
import { EventType } from '../../../../../../models/EventType';
import { deleteAlert } from '../../../../../../api/supervisionApi';
import { SupervisionPlanItemState } from '../../util';

const StyledPopover = withStyles({
  paper: { borderRadius: '10px', boxShadow: '0px 1px 4px rgba(223, 225, 229, 0.5)' },
})(Popover);

type Props = {
  displayAlertOption: boolean;
  hasAlert: boolean;
  activity: any;
  updateData: Function;
  canEdit: boolean;
};

const ActivityOptions = ({ hasAlert, displayAlertOption, activity, updateData, canEdit }: Props) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [alertModalIsOpen, setAlertModalIsOpen] = React.useState(false);
  const currentProjectId = useSelector<any, number>(state => state.currentProject.id);
  const [editFromPopover, setEditFromPopover] = React.useState<boolean>(false);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity]);

  const activityEvent = {
    id: activity.id,
    eventType: EventType.SupervisoryAction,
    projectId: currentProjectId,
    title: activity.description,
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenModal = (value, editFromPopover) => {
    setAnchorEl(null);
    setModalIsOpen(value);
    setEditFromPopover(editFromPopover);
  };

  const handleCloseModal = refreshData => {
    updateDataIfRefresh(refreshData, updateData);
    setModalIsOpen(false);
  };

  const handleCloseAlertModal = refreshData => {
    updateDataIfRefresh(refreshData, updateData);
    setAlertModalIsOpen(false);
  };

  const handleAlert = () => {
    if (activity.hasAlert) {
      handleDeleteAlert();
    } else {
      setAnchorEl(null);
      setAlertModalIsOpen(true);
    }
  };

  const handleDeleteAlert = async () => {
    try {
      setAnchorEl(null);
      await deleteAlert(activity.id, EventType.SupervisoryAction);
      updateData();
    } catch {
      setAnchorEl(null);
      updateData();
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <div onClick={handleClick} className={styles.threeDots}></div>
      <StyledPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={styles.options}>
          <span className={styles.option} onClick={() => handleOpenModal(true, false)}>
            <p className={styles.text}>{t('see_detail')}</p>
          </span>
          {canEdit &&
          activity.state !== SupervisionPlanItemState.Completed &&
          activity.state !== SupervisionPlanItemState.Cancelled ? (
            <span className={styles.option} onClick={() => handleOpenModal(true, true)}>
              <p className={styles.text}>{t('edit')}</p>
            </span>
          ) : null}
          {displayAlertOption ? (
            <span className={styles.option} onClick={handleAlert}>
              <p className={styles.text}>{t(hasAlert ? 'remove_alert' : 'create_alert')}</p>
              <span className={hasAlert ? styles.removeAlert : styles.addAlert}></span>
            </span>
          ) : null}
        </div>
      </StyledPopover>
      <SupervisionModal
        modalIsOpen={modalIsOpen}
        openModal={() => handleOpenModal(true, false)}
        closeModal={(refreshData: boolean) => handleCloseModal(refreshData)}
        activity={activity}
        currentProjectId={currentProjectId}
        editFromPopover={editFromPopover}
      />
      <LinkEventOutlook
        isOpen={alertModalIsOpen}
        closeModal={(refreshData: boolean) => handleCloseAlertModal(refreshData)}
        event={activityEvent}
        currentProjectId={currentProjectId}
      />
    </>
  );
};

export default ActivityOptions;

const updateDataIfRefresh = (refreshData: any, updateData: Function) => {
  if (refreshData) {
    updateData();
  }
};
