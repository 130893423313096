import React from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../../../../../services/booleanUtil';
import SummaryHeaderSingleValue from '../../../SummaryHeaderSingleValue/SummaryHeaderSingleValue';
import styles from '../../SharedStyles/HeaderDrops.module.scss';
import { SummaryIGInternalFilters } from '../../../../../enums/summary-ig-internal-filters';

type Props = {
  igMetrics: any;
};

const FILTER_BY = SummaryIGInternalFilters.BOTH_IG;

export const HeaderDrops = ({ igMetrics }: Props) => {
  const {
    igBothProjectAmount,
    igGreaterProjectAmount,
    igLowerProjectAmount,
    igBothApprovedAmount,
    igGreaterApprovedAmount,
    igLowerApprovedAmount,
    igBothPendingDisbursementAmount,
    igGreaterPendingDisbursementAmount,
    igLowerPendingDisbursementAmount,
    igBothDisbursementAmount,
    igGreaterDisbursementAmount,
    igLowerDisbursementAmount,
    igBothDisbursementPercent,
    igGreaterDisbursementPercent,
    igLowerDisbursementPercent,
    igBothAgeAverage,
    igGreaterAgeAverage,
    igLowerAgeAverage,
    igBothPendingSignatureAmount,
    igGreaterPendingSignatureAmount,
    igLowerPendingSignatureAmount,
    igBothPendingRatificationAmount,
    igGreaterPendingRatificationAmount,
    igLowerPendingRatificationAmount,
  } = igMetrics;
  const { t } = useTranslation();
  const { dropsLayout, firstDropContainer, dropsTitle, dropsValues, dropsContainer } = styles;
  return (
    <div className={`${dropsLayout} filters-igr`}>
      <div className={firstDropContainer}>
        <div className={dropsTitle}>{t('composition')}</div>
        <div className={`${dropsValues} drops-value`}>
          <SummaryHeaderSingleValue
            value={renderValueByInternaFilterApplied(
              FILTER_BY,
              igBothProjectAmount.value,
              igGreaterProjectAmount.value,
              igLowerProjectAmount.value,
            )}
            title={renderSingularOrPluralByInternalFilterApplied(
              FILTER_BY,
              igBothProjectAmount,
              igGreaterProjectAmount,
              igLowerProjectAmount,
              t,
            )}
          />
          <SummaryHeaderSingleValue
            value={renderValueByInternaFilterApplied(
              FILTER_BY,
              igBothApprovedAmount.value,
              igGreaterApprovedAmount.value,
              igLowerApprovedAmount.value,
            )}
            title={renderTextByInternalFilterApplied(
              FILTER_BY,
              igBothApprovedAmount.text,
              igGreaterApprovedAmount.text,
              igLowerApprovedAmount.text,
              t,
            )}
            millionLogic={true}
            customStyle={{ minWidth: 160, paddingRight: 5 }}
          />
        </div>
      </div>
      <div className={dropsContainer}>
        <div className={dropsTitle}>{t('performance_diff_in_portguese')}</div>
        <div className={`${dropsValues} drops-value`}>
          <SummaryHeaderSingleValue
            value={renderValueByInternaFilterApplied(
              FILTER_BY,
              igBothPendingDisbursementAmount.value,
              igGreaterPendingDisbursementAmount.value,
              igLowerPendingDisbursementAmount.value,
            )}
            title={renderTextByInternalFilterApplied(
              FILTER_BY,
              igBothPendingDisbursementAmount.text,
              igGreaterPendingDisbursementAmount.text,
              igLowerPendingDisbursementAmount.text,
              t,
            )}
            millionLogic={true}
            customStyle={{ minWidth: 170, paddingRight: 5 }}
          />
          <SummaryHeaderSingleValue
            value={renderValueByInternaFilterApplied(
              FILTER_BY,
              igBothDisbursementAmount.value,
              igGreaterDisbursementAmount.value,
              igLowerDisbursementAmount.value,
            )}
            title={renderTextByInternalFilterApplied(
              FILTER_BY,
              igBothDisbursementAmount.text,
              igGreaterDisbursementAmount.text,
              igLowerDisbursementAmount.text,
              t,
            )}
            millionLogic={true}
            customStyle={{ minWidth: 100, paddingRight: 5 }}
          />
          <SummaryHeaderSingleValue
            value={renderValueByInternaFilterApplied(
              FILTER_BY,
              Math.round(igBothDisbursementPercent.value),
              Math.round(igGreaterDisbursementPercent.value),
              Math.round(igLowerDisbursementPercent.value),
            )}
            title={renderTextByInternalFilterApplied(
              FILTER_BY,
              igBothDisbursementPercent.text,
              igGreaterDisbursementPercent.text,
              igLowerDisbursementPercent.text,
              t,
            )}
            isPercentage={true}
            customStyle={isMobile() ? { paddingLeft: 0 } : { paddingLeft: 24 }}
          />
          <SummaryHeaderSingleValue
            value={renderValueByInternaFilterApplied(
              FILTER_BY,
              igBothAgeAverage.value,
              igGreaterAgeAverage.value,
              igLowerAgeAverage.value,
            )}
            title={renderTextByInternalFilterApplied(
              FILTER_BY,
              igBothAgeAverage.text,
              igGreaterAgeAverage.text,
              igLowerAgeAverage.text,
              t,
            )}
            isYear={true}
            customStyle={{ paddingLeft: isMobile() ? 0 : 10, minWidth: 120 }}
          />
        </div>
      </div>

      <div className={dropsContainer}>
        <div className={dropsTitle}>{t('pendings')}</div>
        <div className={`${dropsValues} drops-value`}>
          <SummaryHeaderSingleValue
            value={renderValueByInternaFilterApplied(
              FILTER_BY,
              igBothPendingSignatureAmount.value,
              igGreaterPendingSignatureAmount.value,
              igLowerPendingSignatureAmount.value,
            )}
            title={renderTextByInternalFilterApplied(
              FILTER_BY,
              igBothPendingSignatureAmount.text,
              igGreaterPendingSignatureAmount.text,
              igLowerPendingSignatureAmount.text,
              t,
            )}
            millionLogic={true}
            customStyle={{ minWidth: 180, paddingRight: 5 }}
          />
          <SummaryHeaderSingleValue
            value={renderValueByInternaFilterApplied(
              FILTER_BY,
              igBothPendingRatificationAmount.value,
              igGreaterPendingRatificationAmount.value,
              igLowerPendingRatificationAmount.value,
            )}
            title={renderTextByInternalFilterApplied(
              FILTER_BY,
              igBothPendingRatificationAmount.text,
              igGreaterPendingRatificationAmount.text,
              igLowerPendingRatificationAmount.text,
              t,
            )}
            millionLogic={true}
            customStyle={{ minWidth: 180, paddingRight: 15 }}
          />
        </div>
      </div>
    </div>
  );
};

const renderSingularOrPluralByInternalFilterApplied = (filter, both, greater, lower, t) => {
  switch (filter) {
    case SummaryIGInternalFilters.GREATER_3_IG:
      return greater.value > 1 ? t(greater.pluralTextDetail) : t(greater.singularTextDetail);
    case SummaryIGInternalFilters.LESS_3_IG:
      return lower.value > 1 ? t(lower.pluralTextDetail) : t(lower.singularTextDetail);
    default:
      return both.value > 1 ? t(both.pluralTextDetail) : t(both.singularTextDetail);
  }
};
const renderTextByInternalFilterApplied = (filter, both, greater, lower, t) => {
  switch (filter) {
    case SummaryIGInternalFilters.GREATER_3_IG:
      return t(greater);
    case SummaryIGInternalFilters.LESS_3_IG:
      return t(lower);
    default:
      return t(both);
  }
};

const renderValueByInternaFilterApplied = (filter, both, greater, lower) => {
  switch (filter) {
    case SummaryIGInternalFilters.GREATER_3_IG:
      return greater;
    case SummaryIGInternalFilters.LESS_3_IG:
      return lower;

    default:
      return both;
  }
};
