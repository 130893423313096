import React from 'react';
import './AddKeyProduct.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { BidTooltip } from '../../../../Util/Tooltip/Tooltip';
import { tooltipTitle } from '../../../../../services/stringUtil';
import ClickableText from '../../../ClickableText/ClickableText';
import CustomSelect from '../../../../Util/CustomSelect/CustomSelect';
import { getKeyProductsList } from '../../../../../api/planningApi';
import { addKeyProduct } from '../../../../../redux/actions/planification';
import Project from '../../../../../models/Project';
import { availableKeyProducts } from './AddKeyProductUtil';
import { trackActionClicked } from '../../../../../api/trackActionClickedApi';
import { TrackedActions } from '../../../../../models/TrackedActionsEnum';

enum formStates {
  Clickable = 1,
  Select,
  Disabled,
  Loading,
}

type Props = {
  currentProject: Project;
};

export const AddKeyProduct = ({ currentProject }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formState, setFormState] = React.useState<formStates>(formStates.Loading);
  const [options, setOptions] = React.useState<any[]>([]);

  const handleOnClick = () => {
    setFormState(formStates.Select);
  };
  const handleOnChange = value => {
    dispatch(addKeyProduct(value.id));
    trackActionClicked(TrackedActions.planningInformationAdded);
  };

  const getData = async () => {
    const list = await getKeyProductsList(currentProject.id);
    const availableList = availableKeyProducts(list, currentProject.keyProducts);

    if (availableList.length) {
      const selectOptions = availableList.map(item => {
        return { id: item.id, label: item.name, value: item.name };
      });
      setOptions(selectOptions);
      setFormState(formStates.Clickable);
    } else {
      setFormState(formStates.Disabled);
    }
  };

  React.useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject.id, currentProject.keyProducts]);
  return (
    <>
      <div className={`add-key-product-disabled-container ${formState !== formStates.Disabled ? 'hide-this' : ''}`}>
        <BidTooltip
          title={tooltipTitle(t, 'key_product_header_tooltip')}
          placement="right-end"
          enterDelay={500}
          leaveDelay={200}
        >
          <p>{t('add_key_product')}</p>
        </BidTooltip>
      </div>
      <div className={`add-key-product-container ${formState !== formStates.Clickable ? 'hide-this' : ''}`}>
        <div className={'link-container'}>
          <ClickableText text={`+ ${t('add_key_product')}`} onClick={handleOnClick} fontSize={'12px'} />
        </div>
      </div>
      <div className={`add-key-product-edit-container ${formState === formStates.Select ? '' : 'hide-this'}`}>
        <CustomSelect
          options={options}
          handleSelected={handleOnChange}
          translatedFromBackend={true}
          placeholder={t('select_key_product')}
          type={'medium'}
          style={{ width: 250 }}
        />
      </div>
    </>
  );
};
