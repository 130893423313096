import { ModuleButton } from '../../models/EarlyWarningsSection';
import FilterEarlyWarnings from '../../models/FilterEarlyWarnings';
import { SET_FILTER_EARLY_WARNINGS, SET_FILTER_EARLY_WARNINGS_BUTTONS_MODULES } from './types';

export function setFilterEarlyWarnings(filterEarlyWarnings: Partial<FilterEarlyWarnings>) {
  return {
    type: SET_FILTER_EARLY_WARNINGS,
    payload: filterEarlyWarnings,
  };
}

export function setFilterEarlyWarningsModuleButtons(moduleButtonsEarlyWarnings: ModuleButton[]) {
  return {
    type: SET_FILTER_EARLY_WARNINGS_BUTTONS_MODULES,
    payload: moduleButtonsEarlyWarnings,
  };
}
