import { useEffect } from 'react';

const refs = new Set<React.RefObject<HTMLElement>>();

function useOutsideClick(ref, callback) {
    useEffect(() => {
        function handleClickOutside(event) {
            let clickedInsideAnotherComponent = false;
            Array.from(refs).forEach(otherRef => {
                if (otherRef.current && otherRef.current.contains(event.target)) {
                    clickedInsideAnotherComponent = true;
                    return;
                }
            });
            if (ref.current && !ref.current.contains(event.target) && !clickedInsideAnotherComponent) {
                callback();
            }
        }

        function handleKeyDown(event) {
            if (event.key === 'Escape' || event.keyCode === 27) {
                callback();
            }
        }

        refs.add(ref);

        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            refs.delete(ref);

            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [ref, callback]);
}

export default useOutsideClick;