import React from 'react';
import './Financial.scss';
import { useTranslation } from 'react-i18next';
import LabelData from '../../../Util/LabelData/LabelData';
import NumberFormat from 'react-number-format';

type Props = {
  financialInfo: any;
  ctExecutingAgency: boolean;
};
const AnnualProjectionSection = ({ financialInfo, ctExecutingAgency }: Props) => {
  const {
    totalDisbursementAnnualAmount,
    realizedDisbursementAnnualAmount,
    pendingDisbursementAnnualAmount,
    percentRealizedAnnualDisbursement,
    percentPendingAnnualDisbursement,
  } = financialInfo;

  const { t } = useTranslation();

  return (
    <div className="basic-display-columns-container annual-projection">
      {ctExecutingAgency ? null : (
        <div className='data'>
          <div className="subtitle">
            <span className="bullet gray" />
            {t('annual_projection')}{' '}
            <div className='number'>
              <NumberFormat
                value={totalDisbursementAnnualAmount}
                displayType={'text'}
                thousandSeparator={'.'}
                decimalSeparator={','}
              />{' '}
              {totalDisbursementAnnualAmount ? 'USD' : ''}
            </div>
          </div>
        </div>
      )}

      <div className='data'>
        <span className="bullet" />
        <LabelData
          label={t('disbursed')}
          data={realizedDisbursementAnnualAmount}
          reverse={true}
          formatNumber={true}
          reverseUsd={true}
          previousToNumber={`${percentRealizedAnnualDisbursement?.toFixed(2)}% = `}
        />
      </div>
      {ctExecutingAgency ? null : (
        <div className='data'>

          <LabelData
            label={t('pending')}
            data={pendingDisbursementAnnualAmount}
            reverse={true}
            formatNumber={true}
            reverseUsd={true}
            previousToNumber={`${percentPendingAnnualDisbursement}% = `}
          />
        </div>
      )}
    </div>

  );
};

export default AnnualProjectionSection;
