import { useEffect, useRef, useState } from 'react';
import { getAgreementsData } from '../api/projectApi';

export const useGetAgreements = (
  projectId: number | null,
  accordanceState: string | null,
  numberReview: number | null,
  trigger: any = null,
) => {
  const [agreementsData, setAgreementsData] = useState<any>(null);
  const [agreementsLoading, setAgreementsLoading] = useState<boolean>(false);
  const [agreementsError, setAgreementsError] = useState<Error | null>(null);

  const previousAgreementId = useRef<number | null>(null);

  const fetchAgreementsInfo = async () => {
    if (projectId === null) return;

    setAgreementsLoading(true);
    try {
      const data = await getAgreementsData(projectId, accordanceState, numberReview);

      setAgreementsData(data);
      setAgreementsError(null);
      previousAgreementId.current = projectId;
    } catch (err) {
      console.error('Error al actualizar los acuerdos:', err);
      setAgreementsError(err as Error);
    } finally {
      setAgreementsLoading(false);
    }
  };

  useEffect(() => {
    fetchAgreementsInfo();
  }, [trigger]);

  return { agreementsData, agreementsLoading, agreementsError };
};
