import React from "react";
import { useTranslation } from "react-i18next";
import { useFormMemoryHelpContext } from "../../../context/MemoryHelpFormContext";
import { ReactComponent as IconBack } from '../../../assets/icons/u_arrow-left.svg';
import { ReactComponent as IconDownload } from '../../../assets/icons/u_download-alt.svg';

const time = Date.now()
const currentDate = new Date(time).toLocaleDateString();

const MemoryAidEraser = ({ onClickHandle }) => {
    const { t } = useTranslation();

    const { fileRevision, showConfirmContent, userCanEditRevision, loadingHelpFile } = useFormMemoryHelpContext();

    const handleDownload = () => {
        const blob = fileRevision?.wordBlob;
        const filename = `${t('memory_help.container_tabs.completed.donwload_memory_files.draft')} - ${currentDate}`;

        if (!blob) {
            console.error(t('memory_help.container_tabs.completed.error'));
            return;
        }

        const url = URL.createObjectURL(blob);

        const element = document.createElement("a");
        element.href = url;
        element.download = filename;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        URL.revokeObjectURL(url);
    };

    const handleRole = () => userCanEditRevision ? onClickHandle('memoryHelpPage') : onClickHandle('managerialPanel');

    return (
        <>
            <section className='c-memory-help-download'>
                <div className='text-center'>
                    <h1>
                        {
                            showConfirmContent
                                ? `${t('memory_help.container_tabs.completed.draft.label')}`
                                : `${t('memory_help.container_tabs.completed.draft.label_uncomplete')}`
                        }
                    </h1>

                    <button className='btn btn-primary-text btn-sm' onClick={() => handleRole()} disabled={loadingHelpFile as boolean}>
                        <IconBack />
                        {
                            userCanEditRevision
                                ? `${t('memory_help.container_tabs.completed.draft.return_edit')}`
                                : `${t('memory_help.container_tabs.completed.info_success.return_revisions')}`

                        }
                    </button>
                    <button className='btn btn-primary btn-sm' onClick={handleDownload} disabled={loadingHelpFile as boolean}><IconDownload />{t('memory_help.container_tabs.completed.draft.button_download')}</button>
                </div>
                <p>{userCanEditRevision && showConfirmContent ? `${t('memory_help.container_tabs.completed.draft.description')}` : ""}</p>
            </section >
            <div className='divider'></div>
        </>
    );
}

export default MemoryAidEraser;