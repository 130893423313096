import React from 'react';
import { styles } from '../Styles';
import './EsgSafeGuardModal.scss';
import { disableEscKey } from '../Util';
import CloseCrossButton from '../../Buttons/CloseCrossButton/CloseCrossButton';
import { useTranslation } from 'react-i18next';
import { BarChart } from '../../../Portfolio/CardIndicator/Cards/BarChart/BarChart';
import { CardTypesEnum } from '../../../../models/CardTypesEnum';
import { isMobile } from '../../../../services/booleanUtil';
import SaveButton from '../../Buttons/SaveButton/SaveButton';
import BaseModal from '../BaseModal';

type Props = {
  isOpen: boolean;
  closeModal: any;
  title: any;
  chartData: any;
};

const EsgSafeGuardModal = (props: Props) => {
  const { isOpen, closeModal, title, chartData } = props;
  const { t } = useTranslation();
  return (
    <BaseModal
      isOpen={isOpen}
      onRequestClose={e => disableEscKey(e, closeModal)}
      style={{ overlay: { ...styles.overlay, ...{ overflowY: 'auto' } } }}
      className="modal-esg-safeguard-content"
    >
      <div className="modal-esg-safeguard-container">
        <div className="close-cross-container">
          <CloseCrossButton handleClick={closeModal} />
        </div>
        <h3>{title}</h3>
        <div className="horizontal">
          <BarChart
            type={CardTypesEnum.HALF_SIZE_VERTICAL}
            data={chartData}
            showSubLabels={isMobile() ? false : true}
            listPage={false}
            datasetClick={() => {}}
            modalChart={true}
          />
        </div>
      </div>
      <div className="button-footer">
        <SaveButton handleClick={closeModal} customText={t('close')} />
      </div>
    </BaseModal>
  );
};

export default EsgSafeGuardModal;
