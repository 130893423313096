import React from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import FilterIndicators from '../../../../../models/FilterIndicators';
import { multiSelectStyle, DropdownIndicator } from '../../../../Filter/FilterComponent.Styles';
import SelectOption from '../../../../../models/SelectOption';

type Props = {
  countries: SelectOption[];
  reportFilters: FilterIndicators;
  reportFiltersChanged: Function;
};
export const CustomizableCountryFilter = ({ countries, reportFilters, reportFiltersChanged }: Props) => {
  const { t } = useTranslation();
  const [localFilter, setLocalFilter] = React.useState(reportFilters);

  const handleChange = value => {
    if (value === null) {
      value = [];
    }

    const newFilter = {
      ...localFilter,
      common: {
        ...localFilter.common,
        countryId: value,
      },
    };
    setLocalFilter(newFilter);
    reportFiltersChanged({ countryId: newFilter.common.countryId });
  };

  return (
    <Select
      id="countriesSelect"
      defaultValue={localFilter.common.countryId}
      value={localFilter.common.countryId}
      isMulti
      components={{ DropdownIndicator }}
      name="countries"
      options={countries}
      className="basic-multi-select"
      classNamePrefix="All"
      placeholder={t('all')}
      onChange={value => handleChange(value)}
      styles={multiSelectStyle}
    />
  );
};
