import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { trackActionClicked } from '../../../api/trackActionClickedApi';
import { TrackedActions } from '../../../models/TrackedActionsEnum';
import { dispatchProjectTypeFilterState } from '../../../redux/actions/filter';
import ProjectTypeFilterCheckbox from '../ProjectTypeFilterCheckbox/ProjectTypeFilterCheckbox';
import { showIgrSubFilters, igrIsDisabled, handleCheckChanges } from './UtilFunctions';

type Props = {
  filterTitle?: string;
  isVertical?: boolean;
  isRight?: boolean;
  indicatorCall?: boolean;
};


const setterFunction = (newFilters, filterState, extraArgs) => {
  const { dispatch, value, indicatorCall, projectProductType, filterFromRedux } = extraArgs;
  if (newFilters.length !== filterState.length) {
    dispatch(dispatchProjectTypeFilterState(filterFromRedux, newFilters, value, projectProductType, indicatorCall));
    if (indicatorCall) {
      localStorage.setItem('projectTypeFiltersPortfolio', JSON.stringify(newFilters))
      const stringNewFiltersPortfolio = [...newFilters].sort((a: number, b: number) => a - b).join('|');
      trackActionClicked(TrackedActions.portfolioTypeFilterProjectChanged, stringNewFiltersPortfolio);
    } else {
      localStorage.setItem('projectTypeFiltersProject', JSON.stringify(newFilters));
    }
  }
};

const ProjectTypeFilter = (props: Props) => {
  const dispatch = useDispatch();
  const { filterTitle, isVertical, isRight, indicatorCall } = props;
  const filterFromRedux = useSelector<any, any>(state => (indicatorCall ? state.filterIndicators : state.filter));

  const handleChanges = (checkboxData: any) => {
    const { value, projectProductType } = checkboxData;
    handleCheckChanges(filterFromRedux.common.projectTypeFilters, projectProductType, setterFunction, {
      dispatch,
      value,
      indicatorCall,
      projectProductType,
      filterFromRedux,
    });

  };

  return (
    <ProjectTypeFilterCheckbox
      filterTitle={filterTitle}
      handleChanges={handleChanges}
      igrIsDisabled={() => igrIsDisabled(filterFromRedux.common.projectTypeFilters)}
      showIgrSubFilters={() => showIgrSubFilters(filterFromRedux.common.projectTypeFilters)}
      isVertical={isVertical}
      isRight={isRight}
      filterState={filterFromRedux.common.projectTypeFilters}
    />
  );
};

export default ProjectTypeFilter;
