import React from 'react';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isMobile } from '../../../../services/booleanUtil';
import { sortDates, sortAlphabetically } from '../../../../services/sortsUtil';
import './LinksAndDocs.scss';
import Project from '../../../../models/Project';
import { getProjectLinks } from '../../../../api/projectApi';
import LinksAndDocsMobile from './LinksAndDocsMobile';
import * as config from '../../../../api/config.json';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: 'none',
    },
  }),
)(TableCell);

export const LinksAndDocs = () => {
  const currentProject = useSelector<any, Project>(state => state.currentProject);

  const [links, setLinks] = React.useState<any[]>([]);
  const [sortTypeAsc, setSortTypeAsc] = React.useState(true);
  const [sortDateAsc, setSortDateAsc] = React.useState(false);
  const { t } = useTranslation();

  const loadLinks = async () => {
    const response = await getProjectLinks(currentProject.id);
    setLinks(response);
  };

  React.useEffect(() => {
    loadLinks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortType = () => {
    links.sort(sortAlphabetically('type', sortTypeAsc ? 'asc' : 'desc'));
    setLinks(links);
    setSortTypeAsc(!sortTypeAsc);
  };

  const sortDate = () => {
    links.sort(sortDates('date', sortDateAsc ? 'asc' : 'desc'));
    setLinks(links);
    setSortDateAsc(!sortDateAsc);
  };

  return isMobile() ? (
    <LinksAndDocsMobile links={links} />
  ) : (
    <div className="links-and-docs-container">
      <div className="left-container">
        {links && (
          <TableContainer>
            <Table className="custom-table" style={{ tableLayout: 'auto' }} size="small">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left" onClick={sortType} className="type-title">
                    {t('type')} <i className="sort-icon"></i>
                  </StyledTableCell>
                  <StyledTableCell align="left">{t('description')}</StyledTableCell>
                  <StyledTableCell align="left" onClick={sortDate} className="date-title">
                    {t('date')} <i className="sort-icon"></i>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {links.map(link => (
                  <TableRow key={link.id} style={{ height: '30px' }}>
                    <StyledTableCell style={{ width: '10%' }}>{link.type}</StyledTableCell>
                    <StyledTableCell style={{ width: '70%' }}>
                      <a href={`${link.url}`} className="link-text" target="_blank" rel="noopener noreferrer">
                        {link.name}
                      </a>
                    </StyledTableCell>
                    <StyledTableCell style={{ width: '20%' }}>{moment(link.date).format('DD/MM/YY')}</StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {(!links || links.length === 0) && <span className="cell-text">{t('no_links_found')}</span>}
      </div>
      <div className="right-container">
        <h1 className="table-title">{t('project_links')}</h1>
        <a
          href={`${config.CONVERGENCIA_URL.replace('project_number', currentProject.code)}`}
          className="link"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('convergence')}
        </a>
        <a
          href={`${config.EZSHARE_URL.replace('project_number', currentProject.code)}`}
          className="link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Ezshare
        </a>
      </div>
    </div>
  );
};

export default LinksAndDocs;
