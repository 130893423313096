import { AllProjectsActionTypes, SET_ALL_PROJECTS } from '../actions/types';
import Project from '../../models/Project';

const initialAllProjects: Project[] = [];

const initialState = initialAllProjects;
const allProjectsReducer = (state = initialState, action: AllProjectsActionTypes): Project[] => {
  switch (action.type) {
    case SET_ALL_PROJECTS:
      return action.payload;
    default:
      return state;
  }
};

export default allProjectsReducer;
