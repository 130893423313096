export enum MissionStateEnum {
  'draft',
  'approved_with_modifications',
  'completed_pending_report',
  'pending_approval_custom',
  'cancelled_word',
  'completed_word',
  'approved_word',
  'pending_approval_modifications',
}
