import React from 'react';
import { useTranslation } from 'react-i18next';
import { updateLeaveListItem } from '../../../../api/contentManager';
import { getValidDate, hasDateError } from '../../../Util/ContentManager/ContentManager';
import CustomDatePicker from '../../../Util/CustomDatePicker/CustomDatePicker';

type Props = {
  id: any;
  delegatedUserName: any;
  from: any;
  to: any;
  handleOpenModal: any;
  setErrorMessage: any;
  updateTable: any;
};

const currentDate = new Date();

const LeavesFormModal = ({ id, delegatedUserName, from, to, handleOpenModal, setErrorMessage, updateTable }: Props) => {
  const { t } = useTranslation();
  const [innerErrorMessage, setInnerErrorMessage] = React.useState(false);
  const [dates, setDates] = React.useState({
    from: new Date(from),
    to: new Date(to),
  });

  const handleLeaveSubmit = async () => {
    try {
      const { from, to } = dates;

      const parsedFrom = getValidDate(from);
      const parsedTo = getValidDate(to);

      if (!hasDateError(from, to)) {
        const leaveListItemPost = {
          id,
          from: parsedFrom,
          to: parsedTo,
        };

        await updateLeaveListItem(leaveListItemPost);

        handleOpenModal(false);
        updateTable();
      } else {
        setInnerErrorMessage(true);
      }
    } catch {
      setErrorMessage(true);
    }
  };

  return (
    <>
      <div className="content-manager__date-container">
        <div className="content-manager__column-info">
          <div className="content-manager__column-info--title">{t('content_manager_user')}</div>
          <div className="content-manager__column-info--subtitle">{delegatedUserName}</div>
        </div>
        <CustomDatePicker
          date={dates.from}
          label={t('content_manager_leaves_from')}
          setDate={date => setDates({ ...dates, from: date })}
          minDate={currentDate}
        />
        <CustomDatePicker
          date={dates.to}
          label={t('content_manager_leaves_to')}
          setDate={date => setDates({ ...dates, to: date })}
          minDate={currentDate}
          style={{ container: { marginLeft: '30px' } }}
        />
      </div>
      {innerErrorMessage && (
        <div className="content-manager__error-message">{t('contents_maintenance_dates_error_message')}</div>
      )}
      <div className="content-manager-modal__button-group">
        <button className="content-manager-modal__button" onClick={() => handleLeaveSubmit()}>
          {t('content_manager_leaves_save_button_label')}
        </button>
        <button
          style={{ width: '195px' }}
          className="content-manager-modal__button--secondary"
          onClick={() => handleOpenModal(false)}
        >
          {t('content_manager_leaves_close_button_label')}
        </button>
      </div>
    </>
  );
};

export default LeavesFormModal;
