import React, { Fragment } from 'react';
import TimeWithoutBubbles from '../TimeWithoutDisbursementsFilter/TimeWithoutDisbusementsBubble';
import DisbursementProjectionBubbles from '../DisbursementProjectionFilter/DisbursementProjectionBubbles';
import ProjectAgeBubbles from '../ProjectAgeFilter/ProjectAgeBubbles';
import PmrClassificationBubbles from '../PmrFilter/PmrClassificationBubbles';
import SectorBubbles from '../SectorsFilter/SectorBubbles';
import DivisionBubbles from '../DivisionFilter/DivisionBubbles';
import CoResponsibleDivisionBubbles from '../CoResponsibleDivisionFilter/CoResponsibleDivisionBubbles';
import ParticipatingDivisionBubbles from '../ParticipatingDivisionFilter/ParticipatingDivisionBubbles';
import RegionBubbles from '../RegionFilter/RegionBubbles';
import CountryBubbles from '../CountryFilter/CountryBubbles';
import RoleBubbles from '../RoleFilter/RoleBubbles';
import CTFiltersBubbles from '../CTFiltersBubbles/CTFiltersBubbles';
import AttributesBubbles from '../AttributesFilter/AttributesBubbles';
import TeamLeaderBubbles from '../TeamLeaderFilter/TeamLeaderBubbles';
import { CalledFrom } from '../../../models/CalledFromEnum';

interface IProps {
  indicatorCall?: boolean;
  analyticCall?: boolean;
  calledFrom?: CalledFrom;
}

const FilterBubble = (props: IProps) => {
  const { indicatorCall, analyticCall, calledFrom } = props;
  return (
    <Fragment>
      <RegionBubbles indicatorCall={indicatorCall} analyticCall={analyticCall} calledFrom={calledFrom} />
      <CountryBubbles indicatorCall={indicatorCall} analyticCall={analyticCall} calledFrom={calledFrom} />
      <DivisionBubbles indicatorCall={indicatorCall} analyticCall={analyticCall} calledFrom={calledFrom} />
      <SectorBubbles indicatorCall={indicatorCall} calledFrom={calledFrom} />
      <CoResponsibleDivisionBubbles indicatorCall={indicatorCall} calledFrom={calledFrom} />
      <ParticipatingDivisionBubbles indicatorCall={indicatorCall} calledFrom={calledFrom} />
      <PmrClassificationBubbles indicatorCall={indicatorCall} calledFrom={calledFrom} />
      <TimeWithoutBubbles indicatorCall={indicatorCall} calledFrom={calledFrom} />
      <ProjectAgeBubbles indicatorCall={indicatorCall} calledFrom={calledFrom} />
      <DisbursementProjectionBubbles indicatorCall={indicatorCall} calledFrom={calledFrom} />
      <CTFiltersBubbles indicatorCall={indicatorCall} />
      <AttributesBubbles indicatorCall={indicatorCall} calledFrom={calledFrom} />
      <TeamLeaderBubbles indicatorCall={indicatorCall} calledFrom={calledFrom} />
      {analyticCall && <RoleBubbles />}
    </Fragment>
  );
};

export default FilterBubble;
