import { findMetricValueByGrouping, getIndicatorData, metricAreZeros } from './indicatorHelperUtil';
import { IndicatorKeyEnum as keyEnum } from '../../../models/IndicatorKeyEnum';
import { CardTypesEnum } from '../../../models/CardTypesEnum';
import { isMobile } from '../../../services/booleanUtil';
import { yearsList } from './util';

/* DISBURSEMENT_PROJECTION_LAST_5_YEARS */
const addDisbursementProjectionLast5Years = (indicators, cardIndicators) => {
  const indicator = getIndicatorData(indicators, keyEnum.DISBURSEMENT_PROJECTION_LAST_5_YEARS);

  if (!indicator) {
    return null;
  } else if (metricAreZeros(indicator)) {
    const returnData = {
      id: indicator.id,
      indicatorKey: indicator.key,
      type: CardTypesEnum.MIXED_VERTICAL_BAR_LINE,
      title: 'disbursement_projection_last_5_years',
      subtitle: '',
      detailTitle: '',
      detailChartTitle: '',
      detailChartFoot: '',
      data: null,
      order: indicator.order,
    };
    cardIndicators.push(returnData);
    return returnData;
  } else {
    const labels = yearsList(indicator.metrics.filter(i => i.groupingName === 'TRANSACTION_YEAR'));
    const barsData: number[] = [];
    const lineData: number[] = [];

    for (const label of labels) {
      const bar = findMetricValueByGrouping(indicator.metrics, 'TRANSACTION_YEAR', label, 'DISBURSED_AMNT_USEQ');
      const line = findMetricValueByGrouping(
        indicator.metrics,
        'TRANSACTION_YEAR',
        label,
        'ACTUAL_PROJECTION_AMNT_USEQ',
      );
      barsData.push(bar);
      lineData.push(line);
    }

    const barsBackgroundColors = barsData.map((bar, index, list) => {
      if (index < list.length - 1) {
        return '#00CFDD';
      } else {
        return '#FDAC41';
      }
    });

    const bars = {
      data: barsData,
      backgroundColor: barsBackgroundColors,
      borderWidth: 0,
      barThickness: isMobile() ? 10 : 25,
      order: 2,
      label: 'bar',
    };
    const line = {
      data: lineData,
      borderColor: '#424D64',
      backgroundColor: 'transparent',
      borderWidth: 3,
      type: 'line',
      pointRadius: 0,
      lineTension: 0,
      order: 1,
      label: 'line',
    };

    const datasets = [bars, line];
    const dataChart: any = {
      id: indicator.id,
      indicatorKey: indicator.key,
      type: CardTypesEnum.MIXED_VERTICAL_BAR_LINE,
      title: 'disbursement_projection_last_5_years',
      subtitle: '',
      detailTitle: 'disbursement_projection_last_5_years_detail_title',
      detailChartTitle: '',
      detailChartFoot: '',
      data: {
        labels,
        datasets,
        subLabels: [],
        cornerRadius: 3,
        gridLines: true,
      },
      order: indicator.order,
    };

    cardIndicators.push(dataChart);
    return dataChart;
  }
};

const mixedVerticalOptions = {
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        gridLines: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        ticks: {
          fontColor: '#101319',
          fontFamily: 'rubik',
          fontSize: 12,
          fontStyle: 400,
        },
      },
    ],
  },
  cornerRadius: 3,
  plugins: { datalabels: { display: false } },
};

export { addDisbursementProjectionLast5Years, mixedVerticalOptions };
