import { sortAlphabetically } from './../../../services/sortsUtil';
import moment from 'moment';
import { TimeRange } from '../../../models/TimeRangeKnowledgeProductsEnum';

const setRangeDateByDays = (days = 0) => {
  let dateParams;

  switch (days) {
    case 180:
      dateParams = 6;
      break;
    case 365:
      dateParams = 12;
      break;
    default:
      dateParams = 30;
      break;
  }
  const pastDate = moment();
  const today = moment();

  return {
    from:
      days === 0
        ? null
        : dateParams === TimeRange.Month_1
        ? pastDate.subtract(dateParams, 'd').toISOString()
        : pastDate.subtract(dateParams, 'M').toISOString(),
    to: days === 0 ? null : today.toISOString(),
  };
};

const getMinDate = data => {
  const minDateObject =
    data.length && data.reduce((a, b) => (new Date(a.availableDate) < new Date(b.availableDate) ? a : b));
  return minDateObject.availableDate;
};
const getMaxDate = data => {
  const maxDateObject =
    data.length && data.reduce((a, b) => (new Date(a.availableDate) > new Date(b.availableDate) ? a : b));
  return maxDateObject.availableDate;
};

const setObjectParams = searchContext => {
  const departmentDivisionParams = searchContext.departmentDivision.map(d => d.value);
  const productTypeParams = searchContext.productType.map(p => p.id);
  const countriesParams = searchContext.filteredCountries.map(c => c.id);
  return {
    divisions: departmentDivisionParams[0] !== 'all' ? departmentDivisionParams : [],
    productTypeIds: productTypeParams[0] !== 0 ? productTypeParams : [],
    from: searchContext.from && new Date(searchContext.from).toISOString(),
    to: searchContext.to && new Date(searchContext.to).toISOString(),
    countriesOnAdvancedFilter: countriesParams[0] !== 0 ? countriesParams : [],
  };
};

const getCountries = (regions, countriesOfRegions: any = []) => {
  const countriesOptions: any = [];
  const getCountriesOfDefinedRegions = countriesOfRegions.length
    ? regions.filter(region => {
        return countriesOfRegions.find(i => i === region.name);
      })
    : regions;
  getCountriesOfDefinedRegions.forEach(region => {
    region.countries.forEach(country => {
      countriesOptions.push({ value: country.name, id: country.id, label: country.name });
    });
  });

  countriesOptions.sort(sortAlphabetically('value'));
  return countriesOptions;
};

const multipleFilter = (dataToFilter, searchContext) => {
  return dataToFilter.filter((product: any) => {
    return (
      (searchContext.productType[0].value !== 'all'
        ? searchContext.productType.find(i => i.value.toLowerCase() === product.productType.toLowerCase())
        : dataToFilter) &&
      (searchContext.filteredCountries[0].value !== 'all'
        ? product.countryName && searchContext.filteredCountries.find(i => i.value === product.countryName)
        : dataToFilter) &&
      (searchContext.departmentDivision[0].value !== 'all'
        ? product.department && searchContext.departmentDivision.find(i => i.value === product.department)
        : dataToFilter) &&
      (!searchContext.componenteHandleDateRanges && searchContext.from && searchContext.to
        ? new Date(product.availableDate).getTime() >= new Date(searchContext.from).getTime() &&
          new Date(product.availableDate).getTime() <= new Date(searchContext.to).getTime()
        : dataToFilter)
    );
  });
};

const searcherAndFilter = (searchTerm = '', dataToFilter) => {
  return dataToFilter.filter((product: any) => {
    return (
      (product.availableDate && product.availableDate.toLowerCase().search(searchTerm) !== -1) ||
      (product.productType && product.productType.toLowerCase().search(searchTerm) !== -1) ||
      (product.name && product.name.toLowerCase().search(searchTerm) !== -1) ||
      (product.responsible && product.responsible.toLowerCase().search(searchTerm) !== -1) ||
      (product.department && product.department.toLowerCase().search(searchTerm) !== -1) ||
      (product.projectCode && product.projectCode.toLowerCase().search(searchTerm) !== -1)
    );
  });
};
export { setRangeDateByDays, getMinDate, getMaxDate, setObjectParams, getCountries, multipleFilter, searcherAndFilter };
