import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppMenuTabs } from '../../MenuTabs/userTabsInterface';
import styles from './EWHeader.module.scss';
import { FilterButton } from '../../Util/Buttons/FilterButton/FilterButton';
import ButtonWithIcon from '../../Util/Buttons/ButtonWithIcon/ButtonWithIcon';
import FilterComponent from '../../Filter/FilterComponent';
import { CalledFrom } from '../../../models/CalledFromEnum';
import { EarlyWarningsSectionViewsEnum } from '../../../models/EarlyWarnings';
import SubmitButton from '../../Util/Buttons/SubmitButton/SubmitButton';
import { RequestStatus } from '../../../models/RequestStatus';
import FilterBubble from '../../Filter/FilterBubble/FilterBubble';
import FilterEarlyWarnings from '../../../models/FilterEarlyWarnings';
import { createEarlyWarningExcelReport, getReportFile, getSectionReports } from '../../../api/reportApi';
import { LoadingModal } from '../../Util/Modals/LoadingModal/LoadingModal';
import { GetReportsFileTypeEnum, GetSectionReportEnum, ReportStatusEnum } from '../../../models/ReportEnums';
import { createReportNameFile } from '../../Reports/ReportsSection/PortfolioSection/Util';
import { ReportError } from '../../Util/Modals/ReportError/ReportError';
import { findeCountryFilterFieldOnSelectedTab } from '../ProjectsSection/Util';

const {
  headerContainer,
  topRow,
  bottomRow,
  headerTitle,
  topButtonsContainer,
  titleBold,
  downloadButtonContainer,
  alertTrendsButtonContainer,
  filterBubbleContainer,
  filterButtonContainer,
} = styles;

const pollingMiliseconds = 2000;
type Props = {
  updateSession: Function;
  sessionState: string;
  waitForResponse: boolean;
};

interface ReportGenerated {
  id: number;
  reportStatus: ReportStatusEnum;
}

export const EWHeader = ({ updateSession, sessionState, waitForResponse }: Props) => {
  const [filterIsOpen, setFilterIsOpen] = useState(false);
  const { t } = useTranslation();
  const menuTabs = useSelector<any, AppMenuTabs>(state => state.menuTabs);
  const filterEarlyWarnings = useSelector<any, FilterEarlyWarnings>(state => state.filterEarlyWarnings);
  const { title } = menuTabs.earlyWarningsSelectedTab;
  const [openDownloadModal, setOpenDownloadModal] = useState<boolean>(false);
  const [reportGenerated, setReportGenerated] = useState<ReportGenerated>({
    id: 0,
    reportStatus: ReportStatusEnum.Unset,
  });

  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  const getProjectData = async (reportGenerated: ReportGenerated) => {
    const reportList: any = await getSectionReports(GetSectionReportEnum.Alert_Section);
    const report = reportList.find(report => report.id === reportGenerated.id);

    if (report && report.reportStatus === ReportStatusEnum.Done) {
      const completeFileName = createReportNameFile(
        GetSectionReportEnum.Alert_Section,
        GetReportsFileTypeEnum.Excel,
        report,
        t,
      );
      getReportFile(report.id, GetReportsFileTypeEnum.Excel, completeFileName);
      setOpenDownloadModal(false);
    } else if (report && report.reportStatus === ReportStatusEnum.Error) {
      setOpenDownloadModal(false);
      setShowErrorModal(true);
    }
  };

  const handleDownloadExcel = async () => {
    setOpenDownloadModal(true);
    const generateReport: any = await createEarlyWarningExcelReport(
      filterEarlyWarnings,
      findeCountryFilterFieldOnSelectedTab(menuTabs.earlyWarningsSelectedTab),
    );
    setReportGenerated({ id: generateReport.id, reportStatus: generateReport.reportStatus });
  };

  useEffect(() => {
    if (openDownloadModal) {
      const interval = setInterval(() => {
        if (
          reportGenerated.reportStatus === ReportStatusEnum.Done ||
          reportGenerated.reportStatus === ReportStatusEnum.Error
        ) {
          return () => clearInterval(interval);
        }
        getProjectData(reportGenerated);
        return true;
      }, pollingMiliseconds);
      return () => clearInterval(interval);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDownloadModal, reportGenerated]);

  return (
    <>
      <div className={headerContainer}>
        <div className={topRow}>
          <div className={headerTitle}>
            <h1>
              {`${t(
                isInAlertTrendsPage(
                  sessionState,
                  'alert_trends_in_the_portfolio_of',
                  'alerts_in_the_loan_portfolio_in',
                ),
              )} `}
              <span className={titleBold}>{t(title)}</span>
            </h1>
          </div>
          <div className={topButtonsContainer}>
            {sessionState === EarlyWarningsSectionViewsEnum.PROJECTS && (
              <div className={downloadButtonContainer}>
                <ButtonWithIcon
                  iconClass="spreadsheet"
                  textButton="download_report"
                  handleClick={handleDownloadExcel}
                />
              </div>
            )}
            <div className={alertTrendsButtonContainer}>
              <SubmitButton
                buttonState={RequestStatus.Default}
                handleClick={() => updateSession()}
                customText={t(isInAlertTrendsPage(sessionState, 'see_alerts', 'see_trends'))}
                disabled={waitForResponse}
              />
            </div>
            <div className={filterButtonContainer}>
              <FilterButton handleClick={() => setFilterIsOpen(true)} />
            </div>
            <FilterComponent
              closeModal={() => setFilterIsOpen(false)}
              isOpen={filterIsOpen}
              indicatorCall={false}
              calledFrom={CalledFrom.EARLY_WARNINGS}
            />
          </div>
        </div>
        <div className={bottomRow}>
          <p>{t('includes_loans_and_igr_of_more_than_usd_3_million')}</p>
        </div>
        <div className={filterBubbleContainer}>
          <FilterBubble calledFrom={CalledFrom.EARLY_WARNINGS} />
        </div>
      </div>
      <LoadingModal isOpen={openDownloadModal} closeModal={() => {}} />
      <ReportError
        isOpen={showErrorModal}
        closeModal={() => {
          setShowErrorModal(false);
        }}
      />
    </>
  );
};

const isInAlertTrendsPage = (sessionState, trendValue, alertValue) =>
  sessionState === 'trends' ? trendValue : alertValue;

export default EWHeader;
