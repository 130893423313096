import {
  InicatorAccordancesActionTypes,
  SET_INDICATOR_ACCORDANCES,
  RESET_INDICATOR_ACCORDANCES,
} from '../actions/types';
import Accordance from '../../models/Accordance';

const initialIndicatorAccordances: Accordance[] = [];

const indicatorAccordancesReducer = (
  state = initialIndicatorAccordances,
  action: InicatorAccordancesActionTypes,
): Accordance[] => {
  switch (action.type) {
    case SET_INDICATOR_ACCORDANCES:
      return action.payload;
    case RESET_INDICATOR_ACCORDANCES:
      return initialIndicatorAccordances;

    default:
      return state;
  }
};

export default indicatorAccordancesReducer;
