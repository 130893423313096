import React from 'react';

export const cards = (qtyCards, Component, actualState) => {
  const cardsArray = [...actualState];
  for (let i = 2; i <= qtyCards; i++) {
    cardsArray.push(<Component key={i} />);
  }
  return cardsArray;
};

export const calculateCards = viewportSize => {
  const twoCardsPerRow = 1042;
  const fourCardsPerRow = 1468;
  const sixCardsPerRow = 1894;
  if (viewportSize <= twoCardsPerRow) {
    return 2;
  } else if (viewportSize > twoCardsPerRow && viewportSize <= fourCardsPerRow) {
    return 4;
  } else if (viewportSize > fourCardsPerRow && viewportSize <= sixCardsPerRow) {
    return 6;
  } else {
    return 8;
  }
};
