import React, { createContext, useState } from 'react';
import Accordance from '../../../../../models/Accordance';
import { AccordanceType } from '../../../../../models/AccordanceType';

type InitialStateType = {
  bundleAccordances: any[];
  possibleActionsList: any[];
  supervisionActionList: any;
  accordanceFormType: AccordanceType;
  relatedAccordance: Accordance | null;
};

export const initialState = {
  bundleAccordances: [],
  possibleActionsList: [],
  supervisionActionList: [],
  accordanceFormType: AccordanceType.Revision,
  relatedAccordance: null,
};

const NewAccordanceContext = createContext<{ state: InitialStateType; setState: Function }>({
  state: initialState,
  setState: () => {},
});

const NewAccordanceContextProvider = props => {
  const [state, setState] = useState(initialState);
  return <NewAccordanceContext.Provider value={{ state, setState }}>{props.children}</NewAccordanceContext.Provider>;
};

export { NewAccordanceContextProvider, NewAccordanceContext };
