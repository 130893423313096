import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Comments from '../../Util/Comments/Comments';
import CloseCrossButton from '../../Util/Buttons/CloseCrossButton/CloseCrossButton';
import { disableEscKey } from '../../Util/Modals/Util';
import styles from './CommentsAlerts.module.scss';
import ProjectTabs from '../../ProjectDetail/ProjectTabs/ProjectTabs';
import Alerts from './Alerts';
import { createComment, deleteComment } from '../../../api/reviewsApi';
import BaseModal from '../../Util/Modals/BaseModal';

const modalStyles: Modal.Styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10000,
    overflowY: 'scroll',
  },
};

const customStyle = {
  container: {
    height: '100%',
    border: 'none',
    width: '100%',
    margin: 0,
    boxSizing: 'border-box',
    padding: 0,
    marginTop: 30,
  },
  body: {
    borderTop: 'none',
  },
};

type Props = {
  project: any;
  revision?: any;
};

export enum ActionType {
  Create = 0,
  Remove,
}

const CommentsAlerts = ({ project, revision }: Props) => {
  const [showModal, setShowModal] = useState<any>(false);
  const [comments, setComments] = useState<any[]>([]);
  const { t } = useTranslation();
  const currentReview = useSelector<any, any>(state => state.currentReview);

  const updateLocalList = (actionType: ActionType, data: any) => {
    if (actionType === ActionType.Create) {
      setComments([...comments, data]);
    } else {
      setComments(
        comments.map(c => {
          if (c.id === data) {
            c.isDeleted = true;
          }
          return c;
        }),
      );
    }
  };

  const itemsList = [
    {
      id: 0,
      label: t('internal_notes'),
      panel: (
        <Comments
          comments={comments}
          submitComment={comment => createComment(revision ? revision.id : currentReview.id, project.id, comment)}
          submitDeleteComment={commentId => deleteComment(commentId)}
          updateParent={(actionType, data) => updateLocalList(actionType, data)}
        />
      ),
    },
    {
      id: 1,
      label: t('alerts'),
      panel: <Alerts project={project} />,
    },
    {
      id: 2,
      label: '',
      panel: <div></div>,
      hidden: true,
    },
  ];

  useEffect(() => {
    setComments(project.comments);
  }, [project]);

  const toggleModal = (e, value) => {
    e.stopPropagation();
    setShowModal(value);
  };

  const hasUndeletedComments = comments => {
    return comments && comments.length && comments.some(c => !c.isDeleted);
  };

  return (
    <div
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <span
        className={hasUndeletedComments(comments) ? styles.withComments : styles.comment}
        onClick={e => toggleModal(e, true)}
      ></span>
      <BaseModal
        isOpen={showModal}
        onRequestClose={e => disableEscKey(e, () => toggleModal(e, false))}
        style={{ ...modalStyles }}
        className={styles.modal}
      >
        <div>
          <div className={styles.close}>
            <CloseCrossButton handleClick={e => toggleModal(e, false)} />
          </div>
          <ProjectTabs itemsList={itemsList} customStyle={customStyle} defaultTab={0} />
        </div>
      </BaseModal>
    </div>
  );
};

export default CommentsAlerts;
