import React, { useState, useEffect } from 'react';
import { CustomCheckbox } from '../../../Util/CustomCheckbox/CustomCheckbox';
import { useTranslation } from 'react-i18next';
import styles from './PipelineFilter.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { getProjectsSimpleTableIndicator } from '../../../../redux/actions/indicatorProjects';

const customStyleMainFilter = {
  display: 'flex',
  alignItems: 'center',
  height: '12px',
  color: '#727E8C',
  fontFamily: 'Source Sans Pro',
  fontWeight: '300',
  fontSize: '14px',
  marginRight: '20px',
};

enum filterType {
  PIPELINE_A = 'A',
  PIPELINE_B = 'B',
}

const PipelineFilter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentIndicator } = useSelector<any, any>(state => state);
  const { projectTypeFilterOnDetail } = useSelector<any, any>(state => state.indicatorDetailFilter);
  const [pipelineAIsChecked, setPipelineAIsChecked] = useState<boolean>(true);
  const [pipelineBIsChecked, setPipelineBIsChecked] = useState<boolean>(false);
  const { checkboxContainer } = styles;

  const handleChanges = (value, type) => {
    const sessionStorageFilterState: any = sessionStorage.getItem('indicatorPipelineFilter');
    const sessionStorageFilterStateArray = JSON.parse(sessionStorageFilterState);

    if (type === filterType.PIPELINE_A) {
      if (value) {
        setPipelineAIsChecked(value);
        const auxState = [...sessionStorageFilterStateArray, type];
        sessionStorage.setItem('indicatorPipelineFilter', JSON.stringify(auxState));
      } else {
        if (pipelineBIsChecked) {
          setPipelineAIsChecked(value);
          sessionStorage.setItem('indicatorPipelineFilter', JSON.stringify([filterType.PIPELINE_B]));
        }
      }
    } else {
      if (value) {
        setPipelineBIsChecked(value);
        const auxState = [...sessionStorageFilterStateArray, type];
        sessionStorage.setItem('indicatorPipelineFilter', JSON.stringify(auxState));
      } else {
        if (pipelineAIsChecked) {
          setPipelineBIsChecked(value);
          sessionStorage.setItem('indicatorPipelineFilter', JSON.stringify([filterType.PIPELINE_A]));
        }
      }
    }
  };

  useEffect(() => {
    const initialPipelineFilterState = ['A'];
    sessionStorage.setItem('indicatorPipelineFilter', JSON.stringify(initialPipelineFilterState));

    return () => {
      sessionStorage.removeItem('indicatorPipelineFilter');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (projectTypeFilterOnDetail !== null) {
      getFilteredSimpleIndicators(dispatch, currentIndicator.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pipelineAIsChecked, pipelineBIsChecked, projectTypeFilterOnDetail]);

  return (
    <>
      <div className={checkboxContainer}>
        <CustomCheckbox
          labelText={t('pipeline_a')}
          handleOnClick={value => handleChanges(value, filterType.PIPELINE_A)}
          isChecked={pipelineAIsChecked}
         
        />
        <CustomCheckbox
          labelText={t('pipeline_b')}
          handleOnClick={value => handleChanges(value, filterType.PIPELINE_B)}
          isChecked={pipelineBIsChecked}
          
        />
      </div>
    </>
  );
};

export default PipelineFilter;

const getFilteredSimpleIndicators = (d, currentIndicatorId) => {
  return d(getProjectsSimpleTableIndicator(currentIndicatorId));
};
