export interface KeyProduct {
  name: string;
  yearlyPhysicalGoal?: number;
  yearlyFinancialGoal?: number;
  projectId: number;
  id?: number;
  acquisitions?: Acquisition[];
}

export interface Acquisition {
  id?: number;
  name?: string;
  startDate?: string;
  endDate?: string;
  acquisitionType?: number;
  acquisitionState?: any;
  keyProductId: number;
  activities?: Activity[];
  planAmount: number;
  contractAmount: number;
  paidAmount: number;
  revisionType: number;
}

export interface Activity {
  id?: number;
  name?: string;
  plannedDate?: string;
  realDate?: string;
  planAmount: number;
  contractAmount: number;
  paidAmount: number;
  state: number;
  dependence: string;
  acquisitionId: number;
  acquisitionName: string;
  activityType: number;
  comments: any[];
}

export enum AcquisitionType {
  Consulting_Firm = 1,
  Individual_Consulting,
  Works,
  Assets,
  Consulting_Service,
  Transfer,
}

export enum RevisionType {
  Expost = 1,
  Exante,
}

export enum ActivityState {
  Pending = 1,
  In_Progress,
  Completed,
  Out_of_Date,
}

export enum ActivityType {
  Start = 1,
  End,
  Other,
}
