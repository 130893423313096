import Filter from '../../models/Filter';
import { PmrClassification } from '../../models/PmrClassification';
import { ProjectProductTypes } from '../../models/ProjectProductTypes';

const getProjectTypeFilters = () => {
  const projectTypeFilters: number[] = [];
  const storedProjectTypeFilters =
    localStorage.projectTypeFiltersProject && JSON.parse(localStorage.projectTypeFiltersProject);

  storedProjectTypeFilters &&
    storedProjectTypeFilters.forEach(item => {
      projectTypeFilters.push(item);
    });

  return projectTypeFilters.length > 0 ? projectTypeFilters : [ProjectProductTypes.Loan];
};

export const startedFilter: Filter = {
  started: false,
  projectTypeFilters: getProjectTypeFilters(),
  executionFilter: null,
  taxonomyFilter: null,
  excludeRegionals: false,
  divisionId: [],
  coReponsibleDivisions: [],
  participantDivisions: [],
  pmrId: [
    { PmrClassification: PmrClassification.Green, selected: false },
    { PmrClassification: PmrClassification.Yellow, selected: false },
    { PmrClassification: PmrClassification.Red, selected: false },
    { PmrClassification: PmrClassification.Na, selected: false },
  ],
  timeWithoutDisbursementFrom: 0,
  timeWithoutDisbursementTo: 0,
  ageProjectFrom: 0,
  ageProjectTo: 0,
  ageProjectMonths: true,
  disbursementProjectionFrom: 0,
  disbursementProjectionTo: 0,
  disbursementProjectionCompleted: null,
  regionId: [],
  countryId: [],
  isAnnual: 'accumulated',
  itSearch: false,
  sectors: [],
  teamLeaders: [],
  interestIssues: [],
};
