import { useState, useEffect } from 'react';
import { CriteriaType, DEFAULT_PAGINATION_INDICATOR } from '../models/PaginationIndicator';
import {
  sortDecimalNumbers,
  sortEncoded,
  sortNested,
  sortByTwoCriteriasOnEarlyWarnings,
  sortDates,
  sortAlphabetically,
  sortNumbers,
} from '../services/sortsUtil';

/*Session related functions. */
const writeDefaultSession = defaultPaginationSet => {
  const exist = sessionStorage.getItem('PaginationIndicator');
  if (!exist) {
    sessionStorage.setItem('PaginationIndicator', JSON.stringify(defaultPaginationSet));
  }
};

const updateSession = (key: string, value: string | boolean | number) => {
  const sessionItem: any = sessionStorage.getItem('PaginationIndicator');
  const sessionObject = JSON.parse(sessionItem);
  sessionStorage.setItem('PaginationIndicator', JSON.stringify({ ...sessionObject, [key]: value }));
};

const getCurrentPageSession = () => {
  const sessionItem: any = sessionStorage.getItem('PaginationIndicator');
  if (sessionItem) {
    return JSON.parse(sessionItem).currentPage;
  }
  return 1;
};

const getSortAscSession = () => {
  const sessionItem: any = sessionStorage.getItem('PaginationIndicator');
  if (sessionItem) {
    return JSON.parse(sessionItem).sortAsc;
  }
  return true;
};

const getSortCriteria = defaultSortCriteria => {
  const sessionItem: any = sessionStorage.getItem('PaginationIndicator');

  const result = { criteria: defaultSortCriteria, type: CriteriaType.string };
  if (sessionItem) {
    result.criteria = JSON.parse(sessionItem).sortCriteria;
    result.type = JSON.parse(sessionItem).type;
  }
  return result;
};

const getNestedSession = () => {
  const sessionItem: any = sessionStorage.getItem('PaginationIndicator');
  if (sessionItem) {
    return JSON.parse(sessionItem).nested;
  }
  return '';
};
/*End Session related functions */

type Props = {
  projects: any[];
  pageCount?: number;
  defaultSortCriteria?: string;
};

const sortBoolString = {
  true: 'asc',
  false: 'desc',
};

const varTypeFn = {
  0: sortAlphabetically,
  1: sortNumbers,
  2: sortDates,
  3: sortNested,
  4: sortEncoded,
  5: sortDecimalNumbers,
  6: sortByTwoCriteriasOnEarlyWarnings,
};

interface SortCriteria {
  criteria: string;
  type: CriteriaType;
}

const usePaginationSort = (props: Props) => {
  const { projects, pageCount = 10, defaultSortCriteria = '' } = props;
  const [paginatedList, setPaginatedList] = useState<any>({ 1: [] });
  const [currentPage, setCurrentPage] = useState<number>(getCurrentPageSession());
  const [pagesCount, setPagesCount] = useState<number>(1);
  const [currentList, setCurrentList] = useState<any[]>([]);
  const [sortAsc, setSortAsc] = useState<boolean>(getSortAscSession());
  const [sortCriteria, setSortCriteria] = useState<SortCriteria>(getSortCriteria(defaultSortCriteria));
  const [nestedState, setNestedState] = useState<string | null>(getNestedSession());

  const handleSort = (criteria: string, type: CriteriaType, nested: string | null = null) => {
    if (criteria === sortCriteria.criteria) {
      setSortAsc(!sortAsc);
      updateSession('sortAsc', !sortAsc);
    }
    setSortCriteria({ criteria, type });
    updateSession('sortCriteria', criteria);
    updateSession('type', type);
    setNestedState(nested);
    updateSession('nested', nested ? nested : '');
  };

  const handlePaginationChange = (event, value) => {
    setCurrentPage(value);
    updateSession('currentPage', value);
  };

  const handleResetPagination = (value: number) => {
    setCurrentPage(value);
    updateSession('currentPage', value);
  };

  const sortByCriteria = (list, sortCriteria: SortCriteria, sortAsc, nested: string | null) => {
    return [...list].sort(
      varTypeFn[sortCriteria.type](sortCriteria.criteria, sortBoolString[sortAsc], nested ? nested : ''),
    );
  };

  const updatePaginatedList = projects => {
    const paginatedList = {};
    const totalPagesCount = Math.ceil(projects.length / pageCount);
    for (let i = 1; i <= totalPagesCount; i++) {
      paginatedList[i] = [];
    }

    let currentStep = 1;
    for (let i = 0; i < projects.length; i++) {
      if (i < currentStep * pageCount) {
        paginatedList[currentStep].push(projects[i]);
      } else {
        currentStep++;
        paginatedList[currentStep].push(projects[i]);
      }
    }
    return paginatedList;
  };

  useEffect(() => {
    const sortedList = sortByCriteria(projects, sortCriteria, sortAsc, nestedState);
    setPaginatedList(updatePaginatedList([...sortedList]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortCriteria, sortAsc]);

  useEffect(() => {
    if (currentPage !== getCurrentPageSession()) {
      setCurrentPage(getCurrentPageSession());
    } else {
      setCurrentList(paginatedList[currentPage]);
    }
  }, [paginatedList, currentPage]);

  useEffect(() => {
    if (projects.length) {
      writeDefaultSession({ ...DEFAULT_PAGINATION_INDICATOR, sortCriteria: defaultSortCriteria, nested: nestedState });
      const sortedList = sortByCriteria(projects, sortCriteria, sortAsc, nestedState);
      setPaginatedList(updatePaginatedList([...sortedList]));
      setPagesCount(Math.ceil(sortedList.length / pageCount));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects, pageCount]);

  return {
    handleResetPagination,
    handlePaginationChange,
    handleSort,
    paginatedList,
    currentList,
    setPaginatedList,
    pagesCount,
    setPagesCount,
    currentPage,
  };
};

export default usePaginationSort;
