import React from 'react';
import './LocalCounterPart.scss';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';
import { Line } from 'rc-progress';
import { Grid } from '@material-ui/core';

type Props = {
  financialInformation: any;
};

const LocalCounterPart = (props: Props) => {
  const safeNumber = (value) => (typeof value === 'number' ? value : 0);

  const {
    localPending,
    localAmount,
    localDisbursement,
    percentLocalDisbursement,
    percentLocalPending,
  } = props.financialInformation;

  const safeLocalPending = safeNumber(localPending);
  const safeLocalAmount = safeNumber(localAmount);
  const safeLocalDisbursement = safeNumber(localDisbursement);
  const safePercentLocalDisbursement = safeNumber(percentLocalDisbursement);
  const safePercentLocalPending = safeNumber(percentLocalPending);

  const { t } = useTranslation();

  return (
    <div className="project-detail-advance-container">
      <div className="title">
        {t('local_counterpart')}
        <div className="financial-title-container">
          <div className="detail">
            <span>{t('amount')}{' '}</span>
            {<NumberFormat
              value={safeLocalAmount}
              displayType={'text'}
              thousandSeparator={'.'}
              decimalSeparator={','}
            />}{' '} USD
          </div>
        </div>
      </div>
      <div className="horizontal vertical">
        <div className='horizontal-info'>
          <div className="detail-info">
            <div className="top">{t('justified')}</div>
          </div>
          <div className="detail-info right">
            <div className="top">{t('pending')}</div>
          </div>
        </div>
        <Line
          percent={safePercentLocalDisbursement}
          strokeWidth={5}
          trailWidth={5}
          strokeColor={false ? '#44bcef' : '#538CFF'}
          strokeLinecap="square"
          trailColor="#D0D5E0"
        />
      </div>
      <div className="horizontal marginTop-neg">
        <div className="porcent">{safePercentLocalDisbursement.toFixed(2)}%</div>
        <div className="porcent">{safePercentLocalPending.toFixed(2)}%</div>
      </div>

      <div className="horizontal marginTop-neg">
        <Grid container className="detail-info">
          <Grid className="bottom">
            {<NumberFormat value={safeLocalDisbursement} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />}{' '} USD
          </Grid>
        </Grid>
        <Grid container className="detail-info">
          <Grid className="bottom">
            {<NumberFormat value={safeLocalPending} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />}{' '} USD
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default LocalCounterPart;
