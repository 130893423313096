import React from 'react';
import './AcquisitionItem.scss';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { renderDateOrSlash } from '../../../../../services/stringUtil';
import { isAuthorizedToEdit } from '../../../../../services/booleanUtil';
import { Acquisition } from '../../../../../models/Planning';
import ConfirmDelete from '../../../../Util/Modals/ConfirmDelete/ConfirmDelete';
import NewAcquisitionForm from '../NewItem/NewAcquisitionForm';
import { deleteAcquisition } from '../../../../../redux/actions/planification';
import { acquisitionTypes, revisionTypes } from '../NewItem/NewItemFormUtil';
import RemoveEdit from '../RemoveEdit/RemoveEdit';
import User from '../../../../../models/User';
import Project from '../../../../../models/Project';

type Props = {
  acquisition: Acquisition;
};

const getAcquisitionType = (type = 0) => {
  const foundItem = acquisitionTypes.find(item => item.id === type);
  return type ? (foundItem ? foundItem.label : '-') : '-';
};

const getRevisionType = (type = 0) => {
  const foundItem = revisionTypes.find(item => item.id === type);
  return type ? (foundItem ? foundItem.label : '-') : '-';
};

const AcquisitionItem = ({ acquisition }: Props) => {
  const { t } = useTranslation();
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState(false);
  const dispatch = useDispatch();
  const user = useSelector<any, User>(state => state.user);
  const currentProject = useSelector<any, Project>(state => state.currentProject);
  const {
    keyProductId,
    name,
    acquisitionType,
    startDate,
    endDate,
    planAmount,
    contractAmount,
    paidAmount,
    revisionType,
    acquisitionState,
  } = acquisition;
  return (
    <>
      {showEdit ? (
        <NewAcquisitionForm
          acquisitionData={acquisition}
          hideForm={() => {
            setShowEdit(false);
          }}
          keyProductId={keyProductId}
        />
      ) : (
        <div className="acquisition-item-container">
          <span className="chevron"></span>
          <span className="column-item">{name}</span>
          <span className="column-item">{acquisitionType ? t(getAcquisitionType(acquisitionType)) : '-'}</span>
          <span className="column-item">{renderDateOrSlash(startDate)}</span>
          <span className="column-item">{renderDateOrSlash(endDate)}</span>
          <span className="column-item">
            <NumberFormat
              value={planAmount}
              displayType={'text'}
              thousandSeparator={'.'}
              decimalSeparator={','}
              decimalScale={0}
            />
          </span>
          <span className="column-item">
            <NumberFormat
              value={contractAmount}
              displayType={'text'}
              thousandSeparator={'.'}
              decimalSeparator={','}
              decimalScale={0}
            />
          </span>
          <span className="column-item">
            <NumberFormat
              value={paidAmount}
              displayType={'text'}
              thousandSeparator={'.'}
              decimalSeparator={','}
              decimalScale={0}
            />
          </span>
          <span className="column-item">{revisionType ? t(getRevisionType(revisionType)) : '-'}</span>
          <span
            className="column-item"
            style={
              acquisitionState && acquisitionState.value && acquisitionState.value > 3
                ? { color: '#ed5a4d', width: 60 }
                : { width: 60 }
            }
          >
            {acquisitionState && acquisitionState.name ? acquisitionState.name : '-'}
          </span>

          {isAuthorizedToEdit(user, currentProject) && (
            <div className="remove-edit-container">
              <RemoveEdit
                onDelete={e => {
                  setDeleteModal(true);
                  e.stopPropagation();
                }}
                onEdit={e => {
                  setShowEdit(true);
                  e.stopPropagation();
                }}
              />
            </div>
          )}
        </div>
      )}
      <ConfirmDelete
        isOpen={deleteModal}
        closeModal={e => {
          setDeleteModal(false);
          e.stopPropagation();
        }}
        customTitle={'confirm_delete_acquisition_title'}
        customMessage={'confirm_delete_acquisition_message'}
        submit={e => {
          dispatch(deleteAcquisition({ keyProductId: acquisition.keyProductId, id: acquisition.id }));
          e.stopPropagation();
        }}
      />
    </>
  );
};

export default AcquisitionItem;
