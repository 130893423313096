import { SET_CURRENT_INDICATOR, RESET_CURRENT_INDICATOR, UPDATE_CURRENT_INDICATOR_FROM_DETAIL } from './types';
import Indicator from '../../models/Indicator';

export function setCurrentIndicator(data: Indicator) {
  return {
    type: SET_CURRENT_INDICATOR,
    payload: data,
  };
}

export function updateCurrentIndicatorFromDetail(data: Indicator) {
  return {
    type: UPDATE_CURRENT_INDICATOR_FROM_DETAIL,
    payload: data,
  };
}
export function resetCurrentIndicator() {
  return {
    type: RESET_CURRENT_INDICATOR,
  };
}
