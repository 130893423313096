import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import { useStyles } from './AnalyticsHeader.Styles';
import { ReactComponent as FilterButton } from '../../../../assets/filter.svg';
import './AnalyticsHeader.scss';
import AnalyticsFilter from '../../../Filter/AnalyticsFilter/AnalyticsFilter';
import CustomSelect from '../../../Util/CustomSelect/CustomSelect';
import { analyticsDataPeriods } from '../../../../models/AnalyticsDataPeriods';
import FilterAnalytics from '../../../../models/FilterAnalytics';
import { dispatchFilterAnalyticsState } from '../../../../redux/actions/filterAnalytics';
import { useJsonToCsv } from 'react-json-csv';
import FilterBubble from '../../../Filter/FilterBubble/FilterBubble';

type Props = {
  analyticsData?: any;
};

const AnalyticsHeader = ({ analyticsData }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [filterIsOpen, setFilterIsOpen] = useState(false);
  const filterAnalytics = useSelector<any, FilterAnalytics>(state => state.filterAnalytics);
  const dispatch = useDispatch();
  const { saveAsCsv } = useJsonToCsv();

  const closeModal = (value: boolean) => {
    setFilterIsOpen(value);
  };

  const selectHandler = value => {
    dispatch(dispatchFilterAnalyticsState(filterAnalytics, value));
  };

  const exportCSV = () => {
    const filename = 'CI_metricas_analytics';

    const fields = {
      key: 'key',
      groupingName: 'groupingName',
      groupingValue: 'groupingValue',
      metricLabel: 'metricLabel',
      metricName: 'metricName',
      metricValue: 'metricValue',
    };

    const data: any[] = [];

    analyticsData.forEach(element => {
      element.metrics.forEach(metric => {
        const item = {
          key: element.key,
          ...metric,
        };

        data.push(item);
      });
    });

    saveAsCsv({ data, fields, filename });
  };

  const selectStyle = {
    width: '181px',
    marginRight: '17px',
    textAlign: 'left',
  };

  return (
    <div className="header-container">
      <div className="header-title">
        <h1>{t('analytics_header_title')}</h1>
        <div className="filter-button">
          <span className="analytics-filter-title">{t('analytics_filter_title')}</span>
          <CustomSelect
            options={analyticsDataPeriods}
            handleSelected={value => selectHandler(value)}
            selectedOption={filterAnalytics.dataPeriod}
            style={selectStyle}
          />
          <Fab variant="extended" className={classes.root} onClick={() => setFilterIsOpen(true)}>
            <FilterButton className={classes.extendedIcon} />
            {t('filter_button')}
          </Fab>
          <Button className={classes.buttonStyle} onClick={() => exportCSV()}>
            {t('export_csv')}
          </Button>
          <AnalyticsFilter closeModal={closeModal} isOpen={filterIsOpen} />
        </div>
      </div>
      <div className="bubbles-container-analytics">
        <FilterBubble indicatorCall={false} analyticCall={true}/>
      </div>
    </div>
  );
};

export default AnalyticsHeader;
