enum DiscriminatorFilterValue {
  SupervisionPlanActivity = 'SupervisionPlanActivity',
  CriticalProduct = 'CriticalProduct',
}

enum AttributeFilterValue {
  BOOT_WORKSHOP = 'BOOT_WORKSHOP',
  INTERMEDIATE_EVALUATION = 'INTERMEDIATE_EVALUATION',
  CLOSING_WORKSHOP = 'CLOSING_WORKSHOP',
}

enum ProjectVsCompletedIndicatorFilterField {
  Discriminator = 'Discriminator',
  Attributes = 'Attributes',
}

enum SelectOptionId {
  SupervisionPlanActivity,
  CriticalProduct,
  BOOT_WORKSHOP,
  INTERMEDIATE_EVALUATION,
  CLOSING_WORKSHOP,
}

export { DiscriminatorFilterValue, AttributeFilterValue, ProjectVsCompletedIndicatorFilterField, SelectOptionId };
