import React from 'react';
import './CommentInfo.scss';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Comment from '../../../models/Comment';
import User from '../../../models/User';
import { replaceMomentFormat } from '../../../services/util';

type Props = {
  accordanceComment: Comment;
  onClickRemove: any;
  id: number;
  canDelete?: boolean;
};

const CommentInfo = (props: Props) => {
  const user = useSelector<any, User>(state => state.user);
  const { t } = useTranslation();
  const { accordanceComment, onClickRemove, id, canDelete = false } = props;
  const { author, date, comment, isDeleted } = accordanceComment;

  return (
    <div
      className="comment-info-container"
      style={isDeleted || (!canDelete && author && author.id !== user.id) ? { backgroundColor: '#f1f3f8' } : {}}
    >
      {(canDelete || (author && author.id === user.id && !isDeleted)) && (
        <div className="remove-container">
          <span className="remove" onClick={() => onClickRemove(id)}>
            <DeleteIcon style={{ color: 'white' }} fontSize={'small'} />
          </span>
        </div>
      )}
      <div className="title">
        {author && <span className="name">{`${author.name} ${author.lastName}`}</span>}
        <span className="date">{date ? ` - ${replaceMomentFormat(moment(date).format('DD/MMM/YY'))}` : '-'}</span>
      </div>
      {isDeleted ? <span className="deleted-message">{t('comment_was_deleted')}</span> : <p>{comment}</p>}
    </div>
  );
};

export default CommentInfo;
