import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { ContentManagerActionButton } from '../ContentManagerTable/ContentMangerTable.Styles';
import { isNil, isEmpty } from 'lodash';

import { ReactComponent as DraggableIcon } from '../../../assets/draggable-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/contents-delete-icon.svg';
import { ReactComponent as EditIcon } from '../../../assets/contents-edit-icon.svg';

import { defaultPaginationData } from '../../../api/contentManager';

import './ContentManagerSortable.scss';
import { getNoRecordKey } from '../../Util/ContentManager/ContentManager';
import { dataTypes } from '../ContentManagerKeys';

type Props = {
  listItems?: any;
  openModal?: any;
  setFormData?: any;
  deleteItem?: any;
  updateFunction?: any;
  moveTours?: any;
  updateTourListItem?: any;
  deleteTourListItem?: any;
  dataInfo?: any;
  dataType?: any;
  showPagination?: any;
};

const DragHandle = SortableHandle(() => <DraggableIcon />);

const SortableItem = SortableElement(({ value, handleEdit, handleDelete }) => (
  <div className="sortable-item">
    <div className="sortable-item__icon">
      <DragHandle />
    </div>
    <div className="sortable-item__text">
      <Typography>
        <span className="sortable-item__text--index">{`${value.order}. `}</span>
        {value.text}
      </Typography>
    </div>
    <div className="sortable-item__actions">
      <ContentManagerActionButton onClick={() => handleEdit(value)}>
        <EditIcon />
      </ContentManagerActionButton>
      <ContentManagerActionButton onClick={() => handleDelete(value)}>
        <DeleteIcon />
      </ContentManagerActionButton>
    </div>
  </div>
));

const CustomSortableContainer = SortableContainer(({ children }) => <div>{children}</div>);

export default function ContentManagerSortable({
  listItems,
  openModal,
  setFormData,
  deleteItem,
  updateFunction,
  moveTours,
  updateTourListItem,
  deleteTourListItem,
  dataInfo = {},
  dataType,
  showPagination = true,
}: Props) {
  const { t } = useTranslation();

  const handleEdit = item => {
    openModal();
    setFormData(item);
  };

  const handleDelete = async item => {
    await deleteItem(item, updateFunction, listItems, deleteTourListItem);
    updateFunction();
  };

  const onSortEnd = async ({ oldIndex, newIndex }) =>
    moveTours(oldIndex, newIndex, listItems, updateTourListItem, updateFunction);

  const paginate = pageNumber =>
    updateFunction({
      ...defaultPaginationData,
      pageNumber,
    });

  const handleChange = (e, value) => paginate(value);

  return (
    <>
      {isEmpty(listItems) ? (
        <div className="content-manager__no-records">{t(`no_records_${getNoRecordKey(dataType, dataTypes)}`)}</div>
      ) : (
        <CustomSortableContainer onSortEnd={onSortEnd} useDragHandle>
          {listItems.map((value, index) => {
            return (
              <SortableItem
                key={`item-${value.id}`}
                index={index}
                value={value}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
              />
            );
          })}
          {showPagination && !isNil(dataInfo) && dataInfo.pageCount > 0 ? (
            <div className="pagination-style-content-manager">
              <Pagination
                count={dataInfo.pageCount}
                page={dataInfo.currentPage}
                onChange={handleChange}
                style={{ display: 'flex', justifyContent: 'flex-start' }}
              />
            </div>
          ) : null}
        </CustomSortableContainer>
      )}
    </>
  );
}
