import React, { useEffect, useState } from 'react';
import styles from './SelectableProjects.module.scss';
import { useTranslation } from 'react-i18next';
import { ProjectsTable } from '../../Projects/ProjectsTable/ProjectsTable';
import { sortProjectsTable } from '../../../services/sortsUtil';
import { ProjectsListSkeleton } from '../../LoadersSkeletons/ProjectsListSkeleton/ProjectsListSkeleton';
import { ProjectsTableAccordance } from '../ManageAgreements/ManageAgreementsComponents/ProjectsTableAccordance/ProjectsTableAccordance';

type Props = {
  projects: any;
  selectable?: boolean;
  withComments?: boolean;
  canCreateAccordance?: boolean;
  customNoResultsMessage?: any;
  revision?: any;
  tableAccordance?: boolean;
  setLocalRouter?: () => void;
  setVisibleComponent?: () => void;
  setProjectId?: () => void;
};

const SelectableProjects = ({
  projects,
  selectable,
  withComments,
  canCreateAccordance,
  customNoResultsMessage,
  revision,
  tableAccordance,
  setLocalRouter,
  setVisibleComponent,
  setProjectId,
}: Props) => {
  const { t } = useTranslation();
  const [list, setList] = useState<any[]>([]);
  const [sortAsc, setSortAsc] = React.useState(true);
  const [isLoading, setisLoading] = React.useState(true);

  useEffect(() => {
    setisLoading(true);

    if (list.length !== 0) {
      setisLoading(false);
    } else if (projects.list.length !== 0) {
      setTimeout(() => {
        setList([...projects.list]);
        setisLoading(false);
      }, 3000);
    } else {
      setisLoading(false);
    }
  }, [list, projects.list]);

  // useEffect(() => {
  //   setisLoading(true);
  //   setList([...projects.list]);
  //   if (projects.list.length === 0) {
  //     setisLoading(false);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const sortProjects = sortValue => {
    const newList = sortProjectsTable([...list], sortValue.concept, sortAsc);
    setList(newList);
    setSortAsc(!sortAsc);
  };

  return (
    <div className={styles.container}>
      {projects.title ? <div className={styles.title}>{projects.title}</div> : null}
      {isLoading ? (
        <ProjectsListSkeleton />
      ) : list.length ? (
        <>
          {tableAccordance ? (
            <ProjectsTableAccordance
              projects={list}
              setInFollow={() => {}}
              sortTable={sortProjects}
              isAnnual={''}
              selectable={selectable}
              withComments={withComments}
              reviewStyle={true}
              canCreateAccordance={canCreateAccordance}
              fromReview={true}
              revision={revision}
              setLocalRouter={setLocalRouter}
              setVisibleComponent={setVisibleComponent}
              setProjectId={setProjectId}
            />
          ) : (
            <ProjectsTable
              projects={list}
              setInFollow={() => {}}
              sortTable={sortProjects}
              isAnnual={''}
              selectable={selectable}
              withComments={withComments}
              reviewStyle={true}
              canCreateAccordance={canCreateAccordance}
              fromReview={true}
              revision={revision}
            />
          )}
        </>
      ) : (
        <h5 className={styles.noData}>{customNoResultsMessage || t('no_results')}</h5>
      )}
      {/* {projects.title ? <div className={styles.title}>{projects.title}</div> : null}
      {list.length ? (
        <ProjectsTable
          projects={list}
          setInFollow={() => {}}
          sortTable={sortProjects}
          isAnnual={''}
          selectable={selectable}
          withComments={withComments}
          reviewStyle={true}
          canCreateAccordance={canCreateAccordance}
          fromReview={true}
          revision={revision}
        />
      ) : (
        <h5 className={styles.noData}>{customNoResultsMessage || t('no_results')}</h5>
      )} */}
    </div>
  );
};

export default SelectableProjects;
