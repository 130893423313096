import React from 'react';
import { styles } from '../Styles';
import './AccordanceReject.scss';
import SaveButton from '../../Buttons/SaveButton/SaveButton';
import { useTranslation } from 'react-i18next';
import { disableEscKey } from '../Util';
import { rejectAccordance } from '../../../../api/accordanceApi';
import CloseCrossButton from '../../Buttons/CloseCrossButton/CloseCrossButton';
import BaseModal from '../BaseModal';

type Props = {
  isOpen: boolean;
  closeModal: any;
  accordanceId: number;
  onRejectHandler: Function;
};

const AccordanceReject = (props: Props) => {
  const { isOpen, closeModal, accordanceId, onRejectHandler } = props;
  const { t } = useTranslation();
  const [justification, setJustification] = React.useState('');

  const resetData = () => {
    setJustification('');
    closeModal();
  };

  const submit = async () => {
    try {
      await rejectAccordance(accordanceId, justification);
      onRejectHandler();
      setJustification('');
      closeModal();
    } catch (error) {}
  };

  const modalContent = (
    <div className="modal-accordance-reject-container">
      <div className="close-cross-container">
        <CloseCrossButton handleClick={resetData} />
      </div>
      <h3>{t('accordance_was_rejected')}</h3>
      <div className="textarea-section">
        <span>{t('please_write_motivation')}:</span>
        <textarea value={justification} onChange={e => setJustification(e.target.value)}></textarea>
      </div>
      <div className="horizontal">
        <SaveButton handleClick={submit} disabled={!justification.trim()} customText={t('send')} />
      </div>
    </div>
  );

  return (
    <BaseModal
      style={styles}
      isOpen={isOpen}
      className="modal-accordance-reject-content"
      onRequestClose={e => disableEscKey(e, resetData)}
    >
      {modalContent}
    </BaseModal>
  );
};

export default AccordanceReject;
