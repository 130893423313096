import React from 'react';
import './PortfolioProjectsChartsDetailContent.scss';
import { WorkWithChart } from '../WorkWithChart/WorkWithChart';
import Indicator from '../../../models/Indicator';
import { IndicatorKeyEnum as key, IndicatorKeyEnum } from '../../../models/IndicatorKeyEnum';
import { WorkWithProgressChart } from '../WorkWithProgressChart/WorkWithProgressChart';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../../../services/booleanUtil';
import { showChart, mapClassification, isMoney } from '../PortfolioDetailsUtil';
import MobileSimpleChart from '../MobileSimpleChart/MobileSimpleChart';
import { MobilePMRChart } from '../MobilePMRChart/MobilePMRChart';
import { WorkWithRadialChart } from '../WorkWithRadialChart/WorkWithRadialChart';
import SummaryHeaderSingleValue from '../SummaryHeaderSingleValue/SummaryHeaderSingleValue';
import { TransactionalResourcesFilterChart } from '../FiltersInCharts/TransactionalResourcesFilterChart';
import { WorkWithRadialToBarchart } from '../WorkWithRadialToBarchart/WorkWithRadialToBarchart';

interface ReceivedProps {
  currentIndicator: Indicator;
  headerTitle: any;
}

type Props = ReceivedProps;

export const PortfolioProjectsChartsDetailContent = (props: Props) => {
  const { t } = useTranslation();
  const { currentIndicator, headerTitle } = props;

  const [filterTable, setFilterTable] = React.useState({
    datasetLabel: '',
    label: '',
    dataDate: {},
    disbursementValue: 0,
    completeGroupingValue: '',
  });
  const [itsDisbursed, setItsDisbursed] = React.useState(true);
  const [circleData, setCircleData] = React.useState(firstTimeTransactionalResources(currentIndicator));
  const [totalProjects, setTotalProjects] = React.useState(100000);
  const [activeTab, setActiveTab] = React.useState(0);

  const showDisbursed = itsDisbursed => {
    setItsDisbursed(itsDisbursed);
  };

  React.useEffect(() => {
    if (
      currentIndicator.indicatorKey === key.PMR_PERFORMANCE_LAST_CLOSED_YEAR ||
      currentIndicator.indicatorKey === key.PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS_BY_DIVISION
    ) {
      firstCallIndicatorHorizontalCharts(currentIndicator, setFilterTable);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndicator]);

  const filterValues = (value, totProjects) => {
    setActiveTab(1);
    if (currentIndicator.indicatorKey === key.PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS_BY_DIVISION) {
      const stateValue = {
        datasetLabel: '',
        label: value.label.trim(),
        dataDate: {},
        disbursementValue: 0,
        completeGroupingValue: value.completeGroupingValue,
      };

      setFilterTable(stateValue);
    } else {
      setFilterTable(value);
      mapClassification(filterTable.datasetLabel);
      setTotalProjects(totProjects);
    }
    setHeaderTitle(value.label, headerTitle);
  };

  const selectedCircle = (value, label) => {
    setCircleData({ value, label });
  };

  return !isMobile()
    ? renderDesktop({
        currentIndicator,
        filterValues,
        showDisbursed,
        itsDisbursed,
        filterTable,
        totalProjects,
        selectedCircle,
        circleData,
        t,
      })
    : renderMobile({
        currentIndicator,
        filterValues,
        showDisbursed,
        itsDisbursed,
        filterTable,
        totalProjects,
        activeTab,
        selectedCircle,
        circleData,
        t,
      });
};

export const setStateValue = (currentIndicator, value) => {
  const dateMonthYear = {
    month: currentIndicator.months ? parseInt(currentIndicator.months[value.dataIndex]) : [],
    year: currentIndicator.yearNumber ? currentIndicator.yearNumber[value.dataIndex] : [],
  };

  return { datasetLabel: '', label: value.label, dataDate: dateMonthYear };
};

export const setHeaderTitle = (label, func) => {
  if (label === '') {
    func(false, '');
  } else {
    func(true, label);
  }
};

export const renderDesktop = ({
  currentIndicator,
  filterValues,
  showDisbursed,
  itsDisbursed,
  filterTable,
  totalProjects,
  selectedCircle,
  circleData,
  t,
}) => {
  return (
    <div
      className="portfolio-projects-chart-detail-content-container"
      style={currentIndicator.indicatorKey === key.AVERAGE_LAG ? { marginTop: 90 } : {}}
    >
      {renderHeaderNumerals(currentIndicator, t)}

      <div
        className={setWrapperClass(currentIndicator.indicatorKey, true)}
        style={currentIndicator.indicatorKey === key.AVERAGE_LAG ? { minHeight: 300 } : {}}
      >
        {renderFilterOnChart({
          currentIndicator,
        })}

        {showChart(currentIndicator, filterValues, showDisbursed, selectedCircle)}

        {renderFootOnChart({ currentIndicator, t })}
      </div>
      <div
        className={setWrapperClass(currentIndicator.indicatorKey, false)}
        style={currentIndicator.indicatorKey === key.AVERAGE_LAG ? { backgroundColor: 'white' } : {}}
      >
        {getIndicatorTable(currentIndicator, { itsDisbursed, circleData, filterTable, totalProjects })}

        {/* {currentIndicator.indicatorKey === IndicatorKeyEnum.PMR_PERFORMANCE_LAST_CLOSED_YEAR ? (
          <div className="pmr-footer-text">{`* ${t('last_validated_qualification')}`}</div>
        ) : (
          ''
        )} */}
      </div>
    </div>
  );
};

const renderHeaderNumerals = (currentIndicator, t) => {
  if (currentIndicator.indicatorKey === key.SUPERVISION_PLAN_BUDGET_STATUS) {
    const data = currentIndicator.data.toBarchart.data.datasets[0].data;
    const labels = currentIndicator.data.toBarchart.data.datasets[0].label;

    const customStyle = { marginLeft: 60 };
    const customValueStyle = { fontSize: 36 };
    const customSubtitleStyle = { fontSize: 16, fontWeight: 300, color: '#264377', paddingTop: 10 };
    return (
      <div className="header-numerals">
        {data.map((hv, ind) => (
          <SummaryHeaderSingleValue
            value={isMoney(hv, true)}
            title={`${t(labels[ind])} (${t('dolar_sign')})`}
            customStyle={customStyle}
            customValueStyle={customValueStyle}
            customSubtilteStyle={customSubtitleStyle}
          />
        ))}
      </div>
    );
  }

  return null;
};

export const renderMobile = ({
  currentIndicator,
  filterValues,
  showDisbursed,
  itsDisbursed,
  filterTable,
  totalProjects,
  activeTab,
  selectedCircle,
  circleData,
  t,
}) => {
  return currentIndicator.indicatorKey === key.PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS ||
    currentIndicator.indicatorKey === key.SUPERVISION_PLAN_BUDGET_STATUS ? (
    <div className="portfolio-projects-chart-detail-content-container">
      {renderHeaderNumerals(currentIndicator, t)}
      <div className="wrapper-left">
        {renderFilterOnChart({
          currentIndicator,
        })}

        {showChart(currentIndicator, filterValues, showDisbursed, selectedCircle)}
      </div>
      <div className="wrapper-right">{getMobileChart(currentIndicator, itsDisbursed, circleData)}</div>
    </div>
  ) : currentIndicator.indicatorKey === key.PMR_PERFORMANCE_LAST_CLOSED_YEAR ? (
    <MobilePMRChart
      currentIndicator={currentIndicator}
      filterValues={filterValues}
      setActiveTab={activeTab}
      division={filterTable.label}
      totalProjects={totalProjects}
    />
  ) : (
    <MobileSimpleChart
      currentIndicator={currentIndicator}
      filterValues={filterValues}
      showDisbursed={showDisbursed}
      setActiveTab={activeTab}
      pmrClassification={mapClassification(filterTable.datasetLabel)}
      division={filterTable.label}
      totalProjects={totalProjects}
      indicatorKey={currentIndicator.indicatorKey}
      dataDate={filterTable.dataDate}
      indicatorId={currentIndicator.id}
      dataIndex={filterTable.dataIndex}
    />
  );
};

const setWrapperClass = (indicatorKey, left) => {
  if (indicatorKey === IndicatorKeyEnum.SUPERVISION_PLAN_BUDGET_STATUS) {
    if (left) {
      return 'wrapper-left-extended';
    } else {
      return 'wrapper-right-extended';
    }
  } else {
    if (left) {
      return 'wrapper-left';
    } else {
      return 'wrapper-right';
    }
  }
};

export const firstDatasetLabelPmr = currentIndicator => {
  if (currentIndicator.data.datasets[0].data[0] !== 0) {
    return 'problemChartData';
  } else if (currentIndicator.data.datasets[1].data[0] !== 0) {
    return 'alertChartdata';
  } else if (currentIndicator.data.datasets[2].data[0] !== 0) {
    return 'satisChartData';
  } else if (currentIndicator.data.datasets[3].data[0] !== 0) {
    return 'naChartData';
  }
  return '';
};

export const firstCallIndicatorHorizontalCharts = (currentIndicator, setFilterTable) => {
  const datasetLabel = firstDatasetLabelPmr(currentIndicator);
  const label = currentIndicator.data.labels[0];
  const completeGroupingValue =
    currentIndicator.indicatorKey === IndicatorKeyEnum.PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS_BY_DIVISION
      ? currentIndicator.data.completeGroupingValue[0]
      : '';
  setFilterTable({ datasetLabel, label, dataDate: {}, dataIndex: 0, completeGroupingValue });
};

export const firstCallIndicatorVerticalChart = (currentIndicator, setFilterTable, t) => {
  const getCurrentIndicatorMonths = currentIndicator.months ? currentIndicator.months : '0';
  const getCurrentIndicatorYear = currentIndicator.yearNumber ? currentIndicator.yearNumber : '0';

  const firstWithData = currentIndicator.data.datasets[0].data.findIndex(data => data !== 0);

  const getCurrentIndicatorMonthLabels = currentIndicator.data.labels;

  const dateMonthYear = {
    month: getCurrentIndicatorMonths[firstWithData],
    year: getCurrentIndicatorYear[firstWithData],
  };

  const monthlyDisburmetProjection = currentIndicator.data.datasets[0].data[firstWithData];

  const stateValue = {
    datasetLabel: '',
    label: t(getCurrentIndicatorMonthLabels[firstWithData]),
    dataDate: dateMonthYear,
    disbursementValue: monthlyDisburmetProjection,
  };

  setFilterTable(stateValue);
};

export const firstTimeTransactionalResources = currentIndicator => {
  const foundCircle =
    currentIndicator && currentIndicator.data && currentIndicator.data.circles
      ? currentIndicator.data.circles.find(item => item.value !== 0)
      : null;
  return { label: foundCircle ? foundCircle.clickValue : '', value: foundCircle ? foundCircle.value : 0 };
};

const renderFilterOnChart = ({ currentIndicator }) => {
  if (currentIndicator.indicatorKey === IndicatorKeyEnum.SUPERVISION_PLAN_BUDGET_STATUS) {
    return <TransactionalResourcesFilterChart />;
  }
  return null;
};

const renderFootOnChart = ({ currentIndicator, t }) => {
  if (currentIndicator.indicatorKey === IndicatorKeyEnum.TRANSACTIONAL_SUPERVISION_RESOURCES) {
    return (
      <div className="listInfo">
        <ul>
          {currentIndicator.data.toBarchart.data.datasets[0].data.map((item, index) => {
            return (
              <li key={index} className={`label-size-${3}-index-${index}`}>{`${t(
                currentIndicator.data.toBarchart.data.datasets[0].label[index],
              )}${' '}${item} ${t('dolar_sign')}`}</li>
            );
          })}
        </ul>
      </div>
    );
  }
  return null;
};

const getIndicatorTable = (currentIndicator, { itsDisbursed, circleData, filterTable, totalProjects }) => {
  switch (currentIndicator.indicatorKey) {
    case key.PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS:
      return <WorkWithProgressChart currentIndicator={currentIndicator} itsDisbursed={itsDisbursed} />;
    case key.AVERAGE_LAG:
      return <WorkWithRadialChart currentIndicator={currentIndicator} circleData={circleData} />;
    case key.SUPERVISION_PLAN_BUDGET_STATUS:
      return <WorkWithRadialToBarchart />;
    default:
      return (
        <WorkWithChart
          pmrClassification={mapClassification(filterTable.datasetLabel)}
          division={filterTable.label}
          completeGroupingValue={filterTable.completeGroupingValue}
          totalProjects={totalProjects}
          indicatorKey={currentIndicator.indicatorKey}
          dataDate={filterTable.dataDate}
          indicatorId={currentIndicator.id}
          disbursementValue={filterTable.disbursementValue}
        />
      );
  }
};

const getMobileChart = (currentIndicator, itsDisbursed, circleData) => {
  if (currentIndicator.indicatorKey === key.PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS) {
    return <WorkWithProgressChart currentIndicator={currentIndicator} itsDisbursed={itsDisbursed} />;
  } else if (currentIndicator.indicatorKey === key.SUPERVISION_PLAN_BUDGET_STATUS) {
    return <WorkWithRadialToBarchart />;
  } else {
    return <WorkWithRadialChart currentIndicator={currentIndicator} circleData={circleData} />;
  }
};
