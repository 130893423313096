export const addColumn = ({
  tableHeader,
  rootProperty,
  fieldType,
  headerAlign,
  cellAlign,
  headerCellStyle = {},
  cellCustomStyle = {},
  customCellContent,
}) => {
  return {
    tableHeader,
    rootProperty,
    fieldType,
    headerAlign,
    cellAlign,
    headerCellStyle,
    cellCustomStyle,
    customCellContent,
  };
};
const project = addColumn({
  tableHeader: 'project',
  rootProperty: 'code',
  fieldType: null,
  headerAlign: 'left',
  cellAlign: 'left',
  headerCellStyle: { border: 'none' },
  cellCustomStyle: { borderRadius: '6px 0 0 6px', minWidth: '80px', border: 'none' },
  customCellContent: null,
});

const operation = addColumn({
  tableHeader: 'operation',
  rootProperty: 'loanNumber',
  fieldType: null,
  headerAlign: 'center',
  cellAlign: 'center',
  headerCellStyle: { border: 'none' },
  cellCustomStyle: { minWidth: '100px', border: 'none' },
  customCellContent: null,
});

const projectName = addColumn({
  tableHeader: 'name',
  rootProperty: 'title',
  fieldType: null,
  headerAlign: 'left',
  cellAlign: 'left',
  headerCellStyle: { border: 'none' },
  cellCustomStyle: { minWidth: '120px', border: 'none' },
  customCellContent: null,
});

const field1Lon = addColumn({
  tableHeader: 'current_approved_amount_usd',
  rootProperty: 'field1',
  fieldType: 'money',
  headerAlign: 'left',
  cellAlign: 'left',
  headerCellStyle: { border: 'none' },
  cellCustomStyle: { minWidth: '120px', border: 'none' },
  customCellContent: null,
});
const field1TcpNationalAndIG = {
  ...field1Lon,
  tableHeader: 'current_approved_amount_usd_complete',
  headerAlign: 'center',
  cellAlign: 'center',
  cellCustomStyle: { minWidth: '90px', border: 'none' },
};

const field1TcpRegional = {
  ...field1TcpNationalAndIG,
  cellCustomStyle: { minWidth: '110px', border: 'none' },
};

const field2Lon = addColumn({
  tableHeader: 'disbursed_usd',
  rootProperty: 'field2',
  fieldType: 'no_round_two_decimal',
  headerAlign: 'left',
  cellAlign: 'left',
  headerCellStyle: { border: 'none' },
  cellCustomStyle: { minWidth: '60px', border: 'none' },
  customCellContent: null,
});

const field2TcpNationalAndIG = {
  ...field2Lon,
  tableHeader: 'amount_disbursed_usd',
  headerAlign: 'center',
  cellAlign: 'center',
  cellCustomStyle: { minWidth: '115px', border: 'none' },
};

const field11TcpRegional = {
  ...field2TcpNationalAndIG,
  fieldType: null,
  rootProperty: 'field11',
  tableHeader: 'udr',
  cellCustomStyle: { border: 'none', minWidth: '80px' },
};

const field3LonAndPTcpRegional = addColumn({
  tableHeader: 'disbursed_per',
  rootProperty: 'field3',
  fieldType: 'percentage',
  headerAlign: 'center',
  cellAlign: 'center',
  headerCellStyle: { border: 'none' },
  cellCustomStyle: { minWidth: '130px', border: 'none', padding: 0 },
  customCellContent: null,
});
const field3TcpNational = {
  ...field3LonAndPTcpRegional,
  tableHeader: 'percentage_sign',
};

const field7TcpNational = addColumn({
  tableHeader: 'team_leader',
  rootProperty: 'field7',
  fieldType: null,
  headerAlign: 'left',
  cellAlign: 'left',
  headerCellStyle: { border: 'none' },
  cellCustomStyle: { minWidth: '60px', border: 'none' },
  customCellContent: null,
});
const field12TcpRegional = {
  ...field3LonAndPTcpRegional,
  rootProperty: 'field12',
  tableHeader: 'taxonomy',
  fieldType: null,
  cellCustomStyle: { minWidth: '100px', border: 'none' },
};
const field3IG = {
  ...field3LonAndPTcpRegional,
  tableHeader: 'amount_disbursed_per_complete',
  cellCustomStyle: { minWidth: '108px', border: 'none' },
  fieldType: null,
};

const field4Lon = addColumn({
  tableHeader: 'amount_undisbursed_usd',
  rootProperty: 'field4',
  fieldType: 'no_round_two_decimal',
  headerAlign: 'left',
  cellAlign: 'left',
  headerCellStyle: { border: 'none' },
  cellCustomStyle: { minWidth: '60px', border: 'none' },
  customCellContent: null,
});

const field8TcpNationalAndRegional = {
  ...field4Lon,
  rootProperty: 'field8',
  tableHeader: 'last_disbursement_term',
  fieldType: 'date',
  headerAlign: 'center',
  cellAlign: 'center',
  cellCustomStyle: { minWidth: '100px', border: 'none' },
};

const field4IG = {
  ...field4Lon,
  tableHeader: 'amount_undisbursed_usd',
  fieldType: 'no_round_two_decimal',
  headerAlign: 'center',
  cellAlign: 'center',
  cellCustomStyle: { minWidth: '100px', border: 'none' },
};

const field5Lon = addColumn({
  tableHeader: 'pmr_initials',
  rootProperty: 'field5',
  fieldType: 'no_round_two_decimal',
  headerAlign: 'left',
  cellAlign: 'left',
  headerCellStyle: { border: 'none' },
  cellCustomStyle: { minWidth: '60px', border: 'none' },
  customCellContent: { name: 'pmr' },
});

//Added

const approvalDate = addColumn({
  tableHeader: 'approval_date_long',
  rootProperty: 'approvalDate',
  fieldType: 'date',
  headerAlign: 'left',
  cellAlign: 'left',
  headerCellStyle: { border: 'none' },
  cellCustomStyle: { minWidth: '60px', border: 'none' },
  customCellContent: null,
});

const currentDisbursementExpirationDate = addColumn({
  tableHeader: 'current_deadline_for_final_disbursement',
  rootProperty: 'currentDisbursementExpirationDate',
  fieldType: 'date',
  headerAlign: 'left',
  cellAlign: 'left',
  headerCellStyle: { border: 'none' },
  cellCustomStyle: { minWidth: '60px', border: 'none' },
  customCellContent: null,
});

const overallStage = addColumn({
  tableHeader: 'current_stage',
  rootProperty: 'overallStage',
  fieldType: null,
  headerAlign: 'left',
  cellAlign: 'left',
  headerCellStyle: { border: 'none' },
  cellCustomStyle: { minWidth: '60px', border: 'none' },
  customCellContent: null,
});

const instrument = addColumn({
  tableHeader: 'instrument',
  rootProperty: 'instrument',
  fieldType: null,
  headerAlign: 'left',
  cellAlign: 'left',
  headerCellStyle: { border: 'none' },
  cellCustomStyle: { minWidth: '60px', border: 'none' },
  customCellContent: null,
});


const field5IG = {
  ...field5Lon,
  tableHeader: 'alert',
  headerAlign: 'center',
  cellAlign: 'center',
  cellCustomStyle: { minWidth: '80px', border: 'none' },
  customCellContent: { name: 'pmr_and_tcdi' },
};

const field9TcpNationalAndRegional = addColumn({
  tableHeader: 'division',
  rootProperty: 'field9',
  fieldType: null,
  headerAlign: 'center',
  cellAlign: 'center',
  headerCellStyle: { border: 'none' },
  cellCustomStyle: { minWidth: '80px', border: 'none' },
  customCellContent: null,
});

const field10TcpNationalAndTcpRegional = addColumn({
  tableHeader: 'gcm_alert',
  rootProperty: 'field10',
  fieldType: 'no_round_two_decimal',
  headerAlign: 'center',
  cellAlign: 'center',
  headerCellStyle: { border: 'none' },
  cellCustomStyle: { minWidth: '90px', border: 'none' },
  customCellContent: null,
});

const field6TcpRegional = {
  ...field10TcpNationalAndTcpRegional,
  tableHeader: 'amount_disbursed_per',
  fieldType: 'percentage',
  rootProperty: 'field6',
};

const field7TcpRegional = addColumn({
  tableHeader: 'gcm_alert',
  rootProperty: 'field7',
  fieldType: 'no_round_two_decimal',
  headerAlign: 'center',
  cellAlign: 'center',
  headerCellStyle: { border: 'none' },
  cellCustomStyle: { minWidth: '90px', border: 'none' },
  customCellContent: null,
});

export {
  project,
  operation,
  projectName,
  field1Lon,
  field2Lon,
  field3TcpNational,
  field3LonAndPTcpRegional,
  field4Lon,
  field5Lon,
  field1TcpNationalAndIG,
  field1TcpRegional,
  field2TcpNationalAndIG,
  field12TcpRegional,
  field3IG,
  field4IG,
  field5IG,
  field6TcpRegional,
  field7TcpRegional,
  field7TcpNational,
  field8TcpNationalAndRegional,
  field9TcpNationalAndRegional,
  field10TcpNationalAndTcpRegional,
  field11TcpRegional,
  approvalDate,
  currentDisbursementExpirationDate,
  overallStage,
  instrument
};
