import { createReducer } from "@reduxjs/toolkit"
import { setUserIndicatorCollection } from "../actions/userIndicatorCollection";

const initialState: any = {
    userIndicatorCollection: null
}

const userIndicatorCollectionReducer = createReducer(initialState, builder => {
    builder
        .addCase(setUserIndicatorCollection, (state, action) => {
            state.userIndicatorCollection = action.payload;
        })
})

export default userIndicatorCollectionReducer;