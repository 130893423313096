import React from 'react';
import './WorkWithRadialChart.scss';
import { ProjectCounter } from '../ProjectCounter/ProjectCounter';
import { getProjectsSimpleTableIndicator } from '../../../redux/actions/indicatorProjects';
import { useDispatch, useSelector } from 'react-redux';
import Indicator from '../../../models/Indicator';
import Project from '../../../models/Project';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';
import { IndicatorKeyEnum as key } from '../../../models/IndicatorKeyEnum';
import Select from 'react-select';
import {
  colourStyles,
  bgColor,
  basicBorder,
} from '../../ProjectDetail/ProjectTabs/Supervision/StateSelect/StateSelect';
import AverageLagTable from '../AverageLagTable/AverageLagTable';
import { setCurrentIndicator } from '../../../redux/actions/currentIndicator';
import IndicatorDetailFilter from '../../../models/IndicatorDetailFilter';
import { handleLoading } from '../../utils';

interface ReceivedProps {
  currentIndicator: Indicator;
  circleData?: any;
}

type Props = ReceivedProps;
export const WorkWithRadialChart = (props: Props) => {
  const { currentIndicator, circleData } = props;
  const { value, label } = circleData;
  const { t } = useTranslation();
  const color = 'rgba(102, 102, 102, 1)';
  const stateOptions = [
    { value: 'totalData', label: t('all'), color },
    { value: 'criticalProductsData', label: t('critical_products'), color },
    { value: 'activitiesData', label: t('activities'), color },
  ];
  const [optionValue, setOptionValue] = React.useState<any>(stateOptions[0]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const { projectTypeFilterOnDetail } = useSelector<any, IndicatorDetailFilter>(state => state.indicatorDetailFilter);
  const changeOption = data => {
    let newCenterText: string;

    if (data.value === 'totalData') {
      newCenterText = currentIndicator.data.centerTextTotal;
    } else if (data.value === 'criticalProductsData') {
      newCenterText = currentIndicator.data.centerTextProducts;
    } else {
      newCenterText = currentIndicator.data.centerTextActivities;
    }

    const newIndicator = {
      ...currentIndicator,
      data: { ...currentIndicator.data, circles: currentIndicator.data[data.value], centerText: newCenterText },
    };
    dispatch(setCurrentIndicator(newIndicator));
    setOptionValue(data);
  };

  const dispatch = useDispatch();
  const indicatorProjects = useSelector<any, Project[]>(state => state.indicatorProjects);

  React.useEffect(() => {
    getFilteredSimpleIndicators(dispatch, currentIndicator.id, setLoading);
  }, [currentIndicator.id, dispatch, projectTypeFilterOnDetail]);

  return (
    <div
      className={`work-with-radial-chart-container  ${indicatorProjects.length === 0 ? '' : 'adjust-height'}`}
      style={currentIndicator.indicatorKey === key.AVERAGE_LAG ? { paddingTop: 0 } : {}}
    >
      {currentIndicator.indicatorKey !== key.AVERAGE_LAG ? (
        <>
          <div className="title">{t(label)}</div>

          <div className="description">
            <NumberFormat
              value={value.toFixed(0)}
              displayType={'text'}
              thousandSeparator={'.'}
              decimalSeparator={','}
              prefix={`${t('dolar_sign')} `}
            />
          </div>
        </>
      ) : (
        <div className="select-container">
          <h5 className="label">{t('advance_according_to')}:</h5>
          <div className="select-side">
            <Select className="select-ibd"
              value={optionValue}
              label="Single select"
              options={stateOptions}
              // styles={{
              //   ...colourStyles,
              //   control: (styles, { isFocused }) => ({
              //     ...styles,
              //     borderRadius: 20,
              //     outline: 'none',
              //     border: basicBorder,
              //     boxShadow: isFocused ? 0 : basicBorder,
              //     '&:hover': {
              //       border: basicBorder,
              //     },
              //     height: '28px',
              //     minHeight: '28px',
              //     maxWidth: 'unset',
              //     cursor: 'pointer',
              //     backgroundColor: bgColor,
              //   }),
              // }}
              components={{
                IndicatorSeparator: () => null,
              }}
              onChange={changeOption}
            />
          </div>
        </div>
      )}

      <div
        className="table-container"
        style={currentIndicator.indicatorKey === key.AVERAGE_LAG ? { backgroundColor: 'white' } : {}}
      >
        <AverageLagTable projects={indicatorProjects} loading={loading} />

        {/* <ProjectCounter
          indicatorKey={key.AVERAGE_LAG}
          arrayToCount={indicatorProjects}
          countProjects={true}
          footerColumnsInformation={{
            columnData1: sumValueByField('field1', indicatorProjects),
            columnData3: sumValueByField('field3', indicatorProjects),
            columnData4: sumValueByField('field4', indicatorProjects),
            columnData2: sumValueByField('field2', indicatorProjects),
            arrayToCount: indicatorProjects,
            countProjects: true,
          }}
        /> */}
      </div>
    </div>
  );
};

export const getFilteredSimpleIndicators = (d, currentIndicatorId, setLoading) => {
  return handleLoading(() => d(getProjectsSimpleTableIndicator(currentIndicatorId)), setLoading);
};

const sumValueByField = (fieldName, data) => {
  return data.reduce((a, b) => parseInt(b[fieldName]) + a, 0);
};
