import React from 'react';
import './RowTableReportsSection.scss';
import { TableCellReport } from '../ReportsTableShared.Styles';
import { BidTooltip } from '../../../Util/Tooltip/Tooltip';
import { replaceMomentFormat, showCorrespondingComponent, ShowComponentEnum } from '../../../../services/util';
import { tooltipTitle } from '../../../../services/stringUtil';
import { useTranslation } from 'react-i18next';
import { TableRow } from '@material-ui/core';
import moment from 'moment';
import { ProjectSectionEnum } from '../../../../models/ProjectSectionsEnum';
import BeatLoader from 'react-spinners/BeatLoader';
import { css } from '@emotion/core';
import { ReportStatusEnum } from '../../../../models/ReportEnums';
import { ReportType } from '../../../../models/ReportType';
import { reviewSectionsModal } from '../../../../models/ReviewSectionsModalEnum';
import { haveManagerialReviewSectionRole } from '../../../Reviews/ReviewsDate/Util';
import { useSelector } from 'react-redux';
import { ProjectProductTypeClassification } from '../../../../models/ProjectProductTypeClassification';
import { ReactComponent as IconDownload } from '../../../../assets/icons/u_file-download-alt.svg';
import { ReactComponent as IconEnvelope } from '../../../../assets/icons/u_envelope.svg';
import { ReactComponent as IconDelete } from '../../../../assets/icons/u_trash-alt.svg';

type Props = {
  report?: any;
  index?: any;
  isPortfolio?: boolean;
  deleteReport?: any;
  downloadReport?: any;
  sendByMailReport?: any;
  reportType?: any;
};
const dateFormat = 'DD/MM/YYYY';
export const RowTableReportsSection = ({
  report,
  index,
  isPortfolio,
  deleteReport,
  downloadReport,
  sendByMailReport,
  reportType,
}: Props) => {
  const { t } = useTranslation();
  const { role, country } = useSelector<any, any>(state => state.user);
  const override = css`
    display: block;
    margin: 0 16px 0 50px;
  `;
  return (
    <TableRow
      className="row-table-report-section-container"
      key={report.id}
    >
      {renderRowByReportType(reportType, report, t, role, country)}

      <TableCellReport align="right">
        {report.reportStatus === ReportStatusEnum.Done ? (
          <div className='icons-left'>
            <BidTooltip arrow
              onClick={() => {
                downloadReport(report);
              }}
              title={tooltipTitle(t, 'download')}
              placement="bottom-start"
              enterDelay={500}
              leaveDelay={200}
            >
              <span className="icon download-icon">
              <IconDownload/>
              </span>
            </BidTooltip>

            {reportType === ReportType.Portfolio ||
            reportType === ReportType.Project ||
            reportType === ReportType.Review ||
            reportType === ReportType.Accordance ? (
              <BidTooltip arrow
                onClick={() => {
                  sendByMailReport(report);
                }}
                title={tooltipTitle(t, 'send_by_mail')}
                placement="bottom-start"
                enterDelay={500}
                leaveDelay={200}
              >
                <span className="icon send-icon">
                  <IconEnvelope />
                </span>
              </BidTooltip>
            ) : null}

            <BidTooltip arrow
              onClick={() => {
                deleteReport(report.id);
              }}
              title={tooltipTitle(t, 'delete')}
              placement="bottom-start"
              enterDelay={500}
              leaveDelay={200}
            >
              <span className="icon delete-icon">
                <IconDelete />
              </span>
            </BidTooltip>
          </div>
        ) : (
          <BeatLoader css={override} size={7} color={'#417ffe'} loading={true} />
        )}
      </TableCellReport>
    </TableRow>
  );
};

const renderProjectSections = (reports, t) => {
  const { parameters, project } = reports;

  const { execution, productType, projectProductTypeClassification } = project;

  if (parameters.sections.length === 0) {
    return t('REPORT_SECTION_BASIC_INFORMATION');
  } else if (
    (showCorrespondingComponent(
      productType,
      execution,
      projectProductTypeClassification === ProjectProductTypeClassification.IGR_GREATER_THAN_3MM,
    ) === ShowComponentEnum.LOAN_IGR_GREATER &&
      parameters.sections.length === 4) ||
    (showCorrespondingComponent(
      productType,
      execution,
      projectProductTypeClassification === ProjectProductTypeClassification.IGR_GREATER_THAN_3MM,
    ) === ShowComponentEnum.TCP_BID_IGR_LOWER &&
      parameters.sections.length === 4) ||
    (showCorrespondingComponent(
      productType,
      execution,
      projectProductTypeClassification === ProjectProductTypeClassification.IGR_GREATER_THAN_3MM,
    ) === ShowComponentEnum.TCP_AGENCY &&
      parameters.sections.length === 3)
  ) {
    return t('all');
  }
  const reportSections = parameters.sections.map(section => t(ProjectSectionEnum[section]));

  reportSections.splice(0, 1);
  reportSections.unshift(t('REPORT_SECTION_BASIC_INFORMATION'));

  return reportSections.join(', ');
};

const renderPortfolioFields = (report, field) => {
  return report.parameters.filters.find(filter => filter.field === field).value;
};

const renderRowByReportType = (reportType, report, t, role, country) => {
  if (reportType === ReportType.Portfolio) {
    return (
      <>
        <BidTooltip arrow title={renderPortfolioFields(report, 'translatedDivision')} placement="bottom-start">
          <TableCellReport className="adjust-text">
            {renderPortfolioFields(report, 'translatedDivision')}
          </TableCellReport>
        </BidTooltip>
        <TableCellReport>{renderPortfolioFields(report, 'translatedAges')}</TableCellReport>
        <TableCellReport>{renderPortfolioFields(report, 'translatedDisbursementProjection')}</TableCellReport>
        <BidTooltip title={renderPortfolioFields(report, 'translatedCountry')} placement="bottom-start">
          <TableCellReport className="adjust-text">
            {renderPortfolioFields(report, 'translatedCountry')}
          </TableCellReport>
        </BidTooltip>
        <BidTooltip arrow
          title={`${renderPortfolioFields(report, 'translatedPmrValues')} ${renderPortfolioFields(
            report,
            'translatedRegions',
          )} ${renderPortfolioFields(report, 'translatedLastDisbursement')}`}
          placement="bottom-start"
        >
          <TableCellReport className="adjust-text">
            {`${renderPortfolioFields(report, 'translatedPmrValues')} ${renderPortfolioFields(
              report,
              'translatedRegions',
            )} ${renderPortfolioFields(report, 'translatedLastDisbursement')}`}
          </TableCellReport>
        </BidTooltip>
        <TableCellReport>{replaceMomentFormat(moment(report.dateGenerated).format(dateFormat))}</TableCellReport>
      </>
    );
  } else if (reportType === ReportType.Project) {
    return (
      <>
        <TableCellReport>{report.project.codeAndLoan} </TableCellReport>
        <TableCellReport>{report.project.name}</TableCellReport>
        <BidTooltip arrow title={renderProjectSections(report, t)} placement="bottom-start">
          <TableCellReport className="adjust-text">{renderProjectSections(report, t)}</TableCellReport>
        </BidTooltip>
        <TableCellReport>{replaceMomentFormat(moment(report.dateGenerated).format(dateFormat))}</TableCellReport>
      </>
    );
  } else if (reportType === ReportType.Accordance) {
    return (
      <>
        <BidTooltip arrow title={report.translatedAccordancesType} placement="bottom-start">
          <TableCellReport className="adjust-text">{report.translatedAccordancesType}</TableCellReport>
        </BidTooltip>
        <BidTooltip arrow title={report.sortedYears} placement="bottom-start">
          <TableCellReport className="adjust-text">{report.sortedYears}</TableCellReport>
        </BidTooltip>
        <BidTooltip arrow title={report.translatedCountries} placement="bottom-start">
          <TableCellReport className="adjust-text">{report.translatedCountries}</TableCellReport>
        </BidTooltip>
        <BidTooltip arrow title={report.translatedDivisions} placement="bottom-start">
          <TableCellReport className="adjust-text">{report.translatedDivisions} </TableCellReport>
        </BidTooltip>
        <TableCellReport>{replaceMomentFormat(moment(report.dateGenerated).format(dateFormat))}</TableCellReport>
      </>
    );
  } else if (reportType === ReportType.Alert) {
    return (
      <>
        <TableCellReport style={{ textTransform: 'uppercase' }}>{t(report.selectedTab)}</TableCellReport>
        <BidTooltip arrow title={report.transalatedEarlyWarnings} placement="bottom-start">
          <TableCellReport className="adjust-text">{report.transalatedEarlyWarnings} </TableCellReport>
        </BidTooltip>
        <BidTooltip arrow title={report.translatedCommonFilters} placement="bottom-start">
          <TableCellReport className="adjust-text">{report.translatedCommonFilters} </TableCellReport>
        </BidTooltip>
        <TableCellReport>{replaceMomentFormat(moment(report.dateGenerated).format(dateFormat))}</TableCellReport>
      </>
    );
  } else {
    return (
      <>
        {haveManagerialReviewSectionRole(role.id, country.name) && (
          <TableCellReport>{report.revision.country}</TableCellReport>
        )}
        <TableCellReport>
          {`${t('revision_number_'.concat(report.revision.revisionNumber.toString()))} `}
        </TableCellReport>
        <TableCellReport>{renderDateFromTo(report.revision.startDate, report.revision.endDate, t)}</TableCellReport>
        <TableCellReport style={{ textAlign: 'center' }}>
          {haveSectionSelected(report.parameters.sections, reviewSectionsModal.PORTFOLIO_SUMMARY)}
        </TableCellReport>
        <TableCellReport style={{ textAlign: 'center' }}>
          {haveSectionSelected(report.parameters.sections, reviewSectionsModal.PROJECT_DETAIL)}
        </TableCellReport>
        <TableCellReport style={{ textAlign: 'center' }}>
          {haveSectionSelected(report.parameters.sections, reviewSectionsModal.CURRENT_REVISION_AGREEMENTS)}
        </TableCellReport>

        <TableCellReport>{replaceMomentFormat(moment(report.dateGenerated).format(dateFormat))}</TableCellReport>
      </>
    );
  }
};

const renderDateFromTo = (from, to, t) => {
  return `${replaceMomentFormat(moment(from).format(dateFormat))} ${t('to_the')} ${replaceMomentFormat(
    moment(to).format(dateFormat),
  )} `;
};

const getCheckOrNot = isCheck => {
  return isCheck ? <>&#10003;</> : <>&#10005;</>;
};

const haveSectionSelected = (sections, sectionsToCheck) => {
  return getCheckOrNot(sections.includes(sectionsToCheck));
};
