import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { isMobile } from '../../services/booleanUtil';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },

    rootDetail: {
      flexGrow: 1,
      paddingLeft: isMobile() ? 0 : 50,
    },
    headerContainer: {
      height: isMobile() ? '45px' : '74px',
      backgroundColor: '#FFF',
      paddingLeft: isMobile() ? 0 : 31,
      zIndex: 'auto',
    },
    detailContainer: {
      height: isMobile() ? '45px' : '74px',
      backgroundColor: '#FFF',
      zIndex: 'auto',
    },
    logo: {
      minWidth: isMobile() ? '50px' : '81px',
      height: isMobile() ? '15px' : '29.6px',
      cursor: 'pointer',
    },
    appTitle: {
      flexGrow: 1,
      color: '#383B41',
      textTransform: 'uppercase',
      fontFamily: 'Rubik',
      fontSize: isMobile() ? '12px' : '20px',
      margin: isMobile() ? '0 0 0 -10px' : '0 0 0 11px',
    },
    //User greeting
    userGreeting: {
      margin: '3px 0 0 0',
      color: '#383B41',
      textTransform: 'capitalize',
      fontFamily: 'Rubik',
      fontSize: '16px',
      lineHeight: '19px',
      textAlign: 'right',
    },

    //User Adjust
    userAdjustAvatar: {
      margin: isMobile() ? '0 14px 0 0' : '22.69px 30px 11.84px 32px',
      height: isMobile() ? '28px' : '39.47px',
      width: isMobile() ? '28px' : '40px',
      backgroundColor: '#D7DBE4',
    },

    userAdjustTextAvatar: {
      fontSize: isMobile() ? '11px !important' : '16px !important',
      fontWeight: 600,
      lineHeight: '20px',
      color: '#383B41',
      fontFamily: 'Source Sans Pro',
      textAlign: 'right',
      textTransform: 'uppercase',
    },

    toolBar: {
      minHeight: isMobile() ? '45px' : '56px',
    },
  }),
);
