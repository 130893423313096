import { RolesIdEnum } from '../../../models/RolesIdEnum';

export const checkIfDates = date => {
  return date ? new Date(date) : null;
};

export const dateIsNull = date => {
  return date === null ? false : true;
};

export const haveManagerialReviewSectionRole = (roleId, countryName = '') => {
  if (
    roleId === RolesIdEnum.Managerial ||
    roleId === RolesIdEnum.VPF_Vice_Presidency_for_Finance_and_Administration ||
    roleId === RolesIdEnum.Strategists ||
    roleId === RolesIdEnum.VPS_VPC_etc ||
    countryName.toLowerCase().includes('washington')
  ) {
    return true;
  } else {
    return false;
  }
};

export const translateTitleReview = (isFirstRevision, lastReview, existActiveReview) => {
  return `revision_number_${
    isFirstRevision
      ? lastReview.revisionNumber
      : existActiveReview && existActiveReview.revisionNumber
      ? existActiveReview.revisionNumber
      : lastReview && lastReview.revisionNumber + 1
  }`;
};
