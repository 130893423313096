import React from 'react';
import Select from 'react-select';
import CustomSwitch from '../../Util/CustomSwitch/CustomSwitch';
import { multiSelectStyle, DropdownIndicator } from '../../Filter/FilterComponent.Styles';
import { RolesIdEnum } from '../../../models/RolesIdEnum';
import { useTranslation } from 'react-i18next';
import { postNotification } from '../../../api/contentManager';
import '../HelpCenter/HelpCenterModalForm/HelpCenterModalForm.scss';
import { getOptionsFromKeys } from '../../Util/ContentManager/ContentManager';
import styles from './Notifications.module.scss';
import { validateFieldsCorrectly, getOptionsIds } from './Util';
import { useDispatch } from 'react-redux';
import { showSuccesMessage } from '../../../redux/actions/sucessMessage';
type Props = {
  countryList: any;
  displayGenericErrorMessage: any;
};

export const defaultNotificationFormFields = {
  message: '',
  emailNotification: false,
  appNotification: false,
};

export default function Notifications({ countryList, displayGenericErrorMessage }: Props) {
  const { t } = useTranslation();
  const [roles, setRoles] = React.useState<any[]>([]);
  const [countries, setCountries] = React.useState<any[]>([]);
  const [formFields, setFormFields] = React.useState(defaultNotificationFormFields);
  const [messageError, setMessageError] = React.useState('');
  const [switchError, setSwitchError] = React.useState('');
  const dispatch = useDispatch();
  const clearFields = () => {
    setRoles([]);
    setCountries([]);
    setFormFields(defaultNotificationFormFields);
    dispatch(showSuccesMessage('notifications_sent'));
  };

  const sendNotification = (notificationTypes, notificationItem) => {
    notificationTypes.forEach((notificationType, index) => {
      if (notificationType) {
        postNotification({ ...notificationItem, notificationMode: index });
      }
    });
  };

  const handleSendNotification = () => {
    try {
      const { emailNotification, appNotification, message } = formFields;
      if (
        validateFieldsCorrectly({
          formFields,
          setMessageError,
          setSwitchError,
          t,
        })
      ) {
        sendNotification([appNotification, emailNotification], {
          message,
          countryIds: getOptionsIds(countries, countryList),
          roleIds: getOptionsIds(roles, roleOptions)
        });
        clearFields();
      }
    } catch {
      displayGenericErrorMessage();
    }
  };

  const roleOptions = getOptionsFromKeys(RolesIdEnum, t, null, 'role_id_');

  return (
    <div className="content-manager__container">
      <div className="content-manager__container__title">{t('contents_notification_title')}</div>
      <div className="content-manager__container__form">
        <div className="horizontal-row">
          <div className="label-select-container text-area">
            <textarea
              value={formFields.message}
              name={`message`}
              onChange={e => setFormFields({ ...formFields, message: e.target.value })}
              placeholder={t('contents_notification_placeholder')}
              className={messageError && styles.inputError}
            ></textarea>
            <span className={styles.errorMessage}>{messageError}</span>
          </div>
        </div>
        <div className="content-manager__options content-manager__options--space-between">
          <span style={{ fontWeight: 500 }}>{t('contents_notification_to_whom_label')}</span>
          <div className="content-manager__option-item">
            <span className="content-manager__option-label">{t('contents_notification_through_email')}</span>
            <CustomSwitch
              active={formFields.emailNotification}
              handleChange={checked => setFormFields({ ...formFields, emailNotification: checked })}
            />
          </div>
          <div className="content-manager__option-item">
            <span className="content-manager__option-label">{t('contents_notification_through_app')}</span>
            <CustomSwitch
              active={formFields.appNotification}
              handleChange={checked => setFormFields({ ...formFields, appNotification: checked })}
            />
          </div>
        </div>
        <span className={styles.errorMessage}>{switchError}</span>
        <div className="content-manager__options content-manager__options--space-between" style={{ marginTop: '0px' }}>
          <div className="label-select-container" style={{ width: '355px' }}>
            <p className="label">{t('contents_notification_role')}</p>
            <Select
              id="roles"
              isMulti
              components={{ DropdownIndicator }}
              value={roles}
              options={roleOptions}
              className="basic-multi-select"
              classNamePrefix="All"
              placeholder={t('all')}
              onChange={value => setRoles(value)}
              styles={multiSelectStyle}
              noOptionsMessage={() => t('no_option_message')}
            />
          </div>
          <div className="label-select-container" style={{ width: '355px' }}>
            <p className="label">{t('contents_notification_country')}</p>
            <Select
              id="countries"
              isMulti
              components={{ DropdownIndicator }}
              value={countries}
              options={countryList}
              className="basic-multi-select"
              classNamePrefix="All"
              placeholder={t('all')}
              onChange={value => setCountries(value)}
              styles={multiSelectStyle}
              noOptionsMessage={() => t('no_option_message')}
            />
          </div>
        </div>
        <div className="content-manager__options content-manager__options--space-between">
          <br />
          <button
            style={{ width: '207px', marginTop: '28px' }}
            className="save-button-container"
            onClick={() => handleSendNotification()}
          >
            {t('contents_notification_send_button_label')}
          </button>
        </div>
      </div>
    </div>
  );
}
