import React from 'react';

type Prop = {
  project: any;
  isCard?: boolean;
};
export const CodeAndLoan = ({ project, isCard }: Prop) => {
  const { code, withLoans, loanNumber } = project;

  return (
    <>
      {isCard ? '#' : ''} {code} {`${withLoans ? '| ' && loanNumber : ''} `}
    </>
  );
};
