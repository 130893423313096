import React from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from '@material-ui/lab/Skeleton';
import { ModuleButton } from '../../../../models/EarlyWarningsSection';
import styles from './Button.module.scss';
import {ReactComponent as IconFilter} from '../../../../assets/icons/u_filters-v.svg';
const {
  buttonContainer,
  quantityLabel,
  projectLabel,
  alertLabel,
  selectedButtonContainer,
  zeroButtonContainer,
  skeletonContainer,
  skeletonContainerBottom,
  iconFilter,
  topContainer,
} = styles;

type Props = {
  buttonData: ModuleButton;
  handleClick: Function;
  loadingData: boolean;
};

export const Button = ({ buttonData, handleClick, loadingData = false }: Props) => {
  const { t } = useTranslation();
  const { singularLabel, pluralLabel, quantity, selected } = buttonData;
  const buttonQuantityIsZero = buttonData.quantity === 0;
  return loadingData ? (
    <div className={buttonContainer}>
      <div className={skeletonContainer}>
        <Skeleton animation="wave" variant="circle" width={30} height={30} />
        <Skeleton
          animation="wave"
          variant="text"
          width={48}
          height={15}
          style={{ width: '40%', marginLeft: 10, alignSelf: 'end', marginBottom: 2 }}
        />
      </div>
      {/* <div className={skeletonContainerBottom}>
        <Skeleton animation="wave" variant="text" height={25} style={{ width: '100%' }} />
      </div> */}
    </div>
  ) : (
    <div
      className={handleClassNamesIfQuantityIsZero(buttonQuantityIsZero, selected)}
      onClick={handleClickIfQuantityIsZero(buttonQuantityIsZero, handleClick, buttonData)}
    >
       <div className={topContainer}>
        <p className={quantityLabel}>
          {quantity} {t(renderSingularOrPlural(quantity, 'project', 'projects'))}
        </p>
        <p className={alertLabel}> {t(renderSingularOrPlural(quantity, singularLabel, pluralLabel))}</p>
        </div>
      <div className={iconFilter}><IconFilter/></div>
    </div>
  );
};

const renderSingularOrPlural = (qty: number, singularLabel: string, pluralLabel: string) => {
  return qty === 1 ? singularLabel : pluralLabel;
};

const handleClickIfQuantityIsZero = (buttonQuantityIsZero, handleClick, buttonData) => {
  return !buttonQuantityIsZero ? () => handleClick(buttonData) : () => {};
};

const handleClassNamesIfQuantityIsZero = (buttonQuantityIsZero, selected) => {
  return buttonQuantityIsZero ? zeroButtonContainer : selected ? selectedButtonContainer : buttonContainer;
};
