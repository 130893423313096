import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import * as dates from 'date-arithmetic';
import TimeGrid from 'react-big-calendar/lib/TimeGrid';

import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss';

const MyWeek = props => {
  const currentReview = useSelector<any, any>(state => state.currentReview);
  const range = MyWeek.range(
    new Date(moment.utc(currentReview.startDate).local().toString()),
    new Date(moment.utc(currentReview.endDate).local().toString()),
  );
  return <TimeGrid {...props} range={range} eventOffset={15} components={{ ...props.components, header: Algo }} />;
};

MyWeek.range = (start: any, end: any) => {
  let current = start;
  const range: any[] = [];
  while (dates.lte(current, end, 'day')) {
    if (current.getDay() !== 0 && current.getDay() !== 6) {
      range.push(current);
    }
    current = dates.add(current, 1, 'day');
  }
  return range;
};

MyWeek.title = date => {
  return `My awesome week: ${date.toLocaleDateString()}`;
};

const Algo = ({ date, localizer }) => {
  const textArray = localizer.format(date, 'dd D').split(' ');
  const letter = textArray[0][0].toUpperCase();
  const number = textArray[1];

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'default' }}>
      <span style={{ fontFamily: 'Rubik', color: '#E5E5E5' }}>{letter}</span>
      <span style={{ fontFamily: 'Rubik', color: '#7C86A2' }}>{number}</span>
    </div>
  );
};

export default MyWeek;
