import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { AccordanceType } from '../models/AccordanceType';
import { makeStyles, Theme } from '@material-ui/core';
import { tabStyles } from '../components/Util/Tabs.Styles';
import {
  getAccordanceType,
  getCategories,
  getRootCauseCategories,
  newBundleAccordances,
  publishBundleAccordances,
  updateBundleAccordances,
} from '../api/accordanceApi';
import {
  createCriticalIssuesList,
  createPossibleActionsList,
  formatDataForSubmit,
  setCategoryName,
  updatedFormErrors,
  validInputs,
} from '../components/ProjectDetail/ProjectTabs/Accordances/NewAccordanceForm/NewAccordanceFormUtil';
import Project from '../models/Project';
import { getCalendarEvents } from '../redux/actions/calendar';

export const emptyBundleAccordance = {
  expirationDate: null,
  responsible: null,
  // possibleAction: null,
  accordanceFiles: [],
  files: [],
  description: '',
  supervisionAction: null,
  // definedAgreement: null,
  // linkedProduct: [],
  rootCauseOfCritical: null,
  rootCause: null,
};

const generateItemsList = (
  t,
  canCreatePortfolioReviewAccordances,
  canCreateMonitoringAccordances,
  canCreateInterGroupAccordances,
) => {
  const list: any[] = [];
  if (canCreatePortfolioReviewAccordances) {
    list.push({ id: 0, label: t('portfolio_review'), type: AccordanceType.Revision });
  }
  if (canCreateMonitoringAccordances) {
    list.push({ id: 1, label: t('motitoring'), type: AccordanceType.Monitoring });
  }
  if (canCreateInterGroupAccordances) {
    list.push({ id: 2, label: t('inter_group'), type: AccordanceType.InterGroup });
  }
  return list;
};

const useMultiAccordanceFormState = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    bundleAccordances,
    possibleActionsList,
    accordanceFormType,
    closeModal,
    canCreatePortfolioReviewAccordances,
    canCreateMonitoringAccordances,
    canCreateInterGroupAccordances,
    bundleId,
    showConfirmDelete
  } = props;

  const [accordances, setAccordances] = useState<any[]>([...bundleAccordances]);
  const [submitting, setSubmitting] = useState<any>(false);
  const [itemsList, setItemsList] = useState<any[]>([]);
  const classes = makeStyles((theme: Theme) => tabStyles)();
  const tabClasses: any = { root: classes.root, selected: classes.selected };
  const [tabValue, setTabValue] = React.useState<any>(0);
  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const currentProject = useSelector<any, Project>(state => state.currentProject);
  const [expandedAccordances, setExpandedAccordances] = useState<string[]>([]);
  const [criticalIssueList, setCriticalIssuelist] = useState<any[]>([]);
  const [accordanceType, setAccordanceType] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [localPossibleActionsList, setLocalPossibleActionsList] = useState<any[]>(possibleActionsList);
  const [localAccordanceType, setLocalAccordanceType] = useState<AccordanceType>(accordanceFormType);

  const [rootCauseCategoriesForm, setRootCauseCategoriesForm] = useState<any>([]);
  const [rootCauseForm, setRootCauseForm] = useState<any>([]);
  const [productForm, setProductForm] = useState<any>([]);
  
  const getData = async () => {
    const cat = await getCategories(undefined, undefined, currentProject.id);
    setCategories(cat);

    const accordanceTypeForm = await getAccordanceType();
    setAccordanceType(accordanceTypeForm)

    const criticalIssuesList = await createCriticalIssuesList(cat);
    setCriticalIssuelist(criticalIssuesList)

    const rootCauseCategories = await getRootCauseCategories();
    setRootCauseCategoriesForm(rootCauseCategories)
  };
  
  const handleCriticalIssueChange = async (event: any, item: any) => {
    setProductForm(criticalIssueList?.find(value => value.label === item.label).keyProducts)
    const previousCriticalIssue = accordances[0].criticalIssue;
    if (!item || item.id === '-') {
      setAccordances(
        accordances.map(i => {
          i.criticalIssue = null;
          i.category = '';
          i.formErrors.criticalIssue = true;
          i.linkedProductsAccordance = null;
          return i;
        }),
      );
    } else {
      setProductForm(item.keyProducts)
      setAccordances(
        accordances.map(i => {
          i.criticalIssue = item;
          i.category = setCategoryName(item);
          i.formErrors.criticalIssue = false;
          i.linkedProductsAccordance = null;
          return i;
        }),
      );
      const category = categories.find(({ id }) => id === item.categoryId);
      const possibleActions = category.criticalIssues.find(({ id }) => id === item.id)?.possibleActions;
      setLocalPossibleActionsList(createPossibleActionsList(possibleActions));
    }
    previousCriticalIssue &&
      setAccordances(
        accordances.map(i => {
          i.possibleAction = null;
          i.formErrors.possibleAction = true;
          return i;
        }),
      );
  };

  const updateSharedValues = (e, value: any, key: string) => {
     setAccordances(
       accordances.map(item => {
         item[key] = value;
         return item;
       }),
     );

  };

  const updateSharedValueRevision = (value: any, key: string) => {
    setAccordances(
      accordances.map(item => {
        item[key] = value;
        return item;
      }),
    );
  };

  const updateSharedValuesProducts = (e, value: any, key: string) => {
    setAccordances(
      accordances.map(item => {
        item[key] = value;
        return item;
      }),
    );
  };

  const everyAccordanceValid = () => {
    for (const acc of accordances) {
      if (
        !validInputs(
          acc.criticalIssue,
          acc.responsible,
          acc.expirationDate,
          // acc.possibleAction,
          acc.revisionDate,
          acc.definedAgreement?.id || acc.accordanceAgreementsType?.id,
          // acc.semesterOption,
          acc.definedAgreement?.name || acc.accordanceAgreementsType?.name,
          acc.linkedProductsAccordance,
          acc.rootCauseOfCritical || acc.rootCauseCategory,
          acc.rootCause,
          acc.criticalIssueDescription,
          acc.description
        )
      ) {
        return false;
      }
    }
    return true;
  };

  const updateEveryError = () => {
    const accErrorsList: string[] = [];
    const newList = accordances.map(i => {
      if (
        !validInputs(
          i.criticalIssue,
          i.responsible,
          i.expirationDate,
          // i.possibleAction,
          i.revisionDate,
          i.definedAgreement?.id || i.accordanceAgreementsType?.id,
          // i.semesterOption,
          i.definedAgreement || i.accordanceAgreementsType,
          i.linkedProductsAccordance,
          i.rootCauseOfCritical || i.rootCauseCategory,
          i.rootCause,
          i.criticalIssueDescription,
          i.description
        )
      ) {
        accErrorsList.push(i.internalId);
      }
      i.formErrors = updatedFormErrors(
        i.criticalIssue,
        i.responsible,
        i.expirationDate,
        // i.possibleAction,
        i.revisionDate,
        i.definedAgreement?.id || i.accordanceAgreementsType?.id,
        // i.semesterOption,
        // i.accordanceDate,
        i.definedAgreement,
        i.linkedProductsAccordance,
        i.rootCauseOfCritical || i.rootCauseCategory,
        i.rootCause,
        i.criticalIssueDescription,
        i.description
      );
      return i;
    });
    setAccordances(newList);
    setTimeout(() => {
      setExpandedAccordances([...expandedAccordances, ...accErrorsList]);
    }, 200);
  };

  const getCalendarEventsAndCloseModal = (currentProjectId: number, closeModalData: any) => {
    dispatch(getCalendarEvents(currentProjectId));
    closeModal(closeModalData);
  };

  const submitAccordances = async (draft = false) => {
    if (draft || everyAccordanceValid()) {
      setSubmitting(true);
      const data = accordances.map(i => {
        return formatDataForSubmit(currentProject.id,  i.accordanceAgreementsType?.id || i.definedAgreement?.id , i, draft);
      });

      const title = `${t('you_have_sent')} ${data.length} ${t('related_accordances_for_approval')}`;
      const closeModalData = {
        refreshData: true,
        isDraft: draft,
        subTitle: t('accordance_once_approved_plural'),
        title,
      };

      if (bundleId && draft) {
        try {
          await updateBundleAccordances({ bundleId, accordances: data });
          getCalendarEventsAndCloseModal(currentProject.id, closeModalData);
        } catch (error) {
          setSubmitting(false);
        }
      } else if (bundleId && !draft) {
        try {
          const updatedAccordances = await updateBundleAccordances({ bundleId, accordances: data });
          await publishBundleAccordances({ accordanceIds: updatedAccordances.map(i => i.id) });
          getCalendarEventsAndCloseModal(currentProject.id, closeModalData);
        } catch (error) {
          setSubmitting(false);
        }
      } else {
        try {
          await newBundleAccordances({ accordances: data });
          getCalendarEventsAndCloseModal(currentProject.id, closeModalData);
        } catch (error) {
          setSubmitting(false);
        }
      }
    } else {
      updateEveryError();
    }
  };

  useEffect(() => {
    getData();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  
  useEffect(() => {
    setItemsList(accordanceType);
    setTabValue(accordanceType.find(i => i.id === accordanceFormType)?.id);
    setExpandedAccordances([accordances[accordances.length - 1].internalId]);
  }, [accordanceType])
  

  const updateListByKey = (internalId: string, itemKey: string, value) => {
    return accordances.map(item => {
      if (item.internalId === internalId) {
        item[itemKey] = value;
        item.formErrors[itemKey] = false;
      }
      return item;
    });
  };

  const handleRootCauseOfCriticalChange = (e, value, internalId: string) => {

    setRootCauseForm(rootCauseCategoriesForm?.find((val) => val.name === value.name).rootCause)

    setAccordances(updateListByKey(internalId, 'rootCauseOfCritical', value));
    setAccordances(updateListByKey(internalId, 'rootCause', null));
  }

  const handleRootCauseChange = (e, value, internalId: string) => {
    setAccordances(updateListByKey(internalId, 'rootCause', value));
  }

  const handleAccordanceType = (e, value, internalId: string) => {
    setAccordances(updateListByKey(internalId, 'accordanceAgreementsType', value));
  }

  const handleSupervisionActionChange = (value, internalId: string) => {
    setAccordances(updateListByKey(internalId, 'supervisionAction', value));
  };
  const handleResponsibleChange = (value, internalId: string) => {
    setAccordances(updateListByKey(internalId, 'responsible', value));
  };
  const handleExpirationDate = (value, internalId: string) => {
    setAccordances(updateListByKey(internalId, 'expirationDate', value));
  };
  const handlePossibleActionChange = (value, internalId: string) => {
    setAccordances(updateListByKey(internalId, 'possibleAction', value));
  };
  const handleDescription = (value, internalId: string) => {
    setAccordances(updateListByKey(internalId, 'description', value));
  };
  const removeFileFromList = (value, internalId: string) => {
    const newList = accordances.map(item => {
      if (item.internalId === internalId) {
        item.accordanceFiles = item.accordanceFiles.filter(i => i.url !== value.url);
      }
      return item;
    });
    setAccordances(newList);
  };
  const addFileToList = (value, internalId: string) => {
    const newList = accordances.map(item => {
      if (item.internalId === internalId) {
        item.accordanceFiles = [...item.accordanceFiles, value];
      }
      return item;
    });
    setAccordances(newList);
  };
  const addNewAccordance = item => {
    let newList: any[] = [];
    if (!item.responsible 
      // || !item.possibleAction 
      || !item.expirationDate || !item.rootCauseOfCritical || !item.rootCause || !item.description) {
      newList = accordances.map(o => {
        if (o.internalId === item.internalId) {
          o.formErrors = {
            responsible: !item.responsible,
            // possibleAction: !item.possibleAction,
            expirationDate: !item.expirationDate,
            rootCauseOfCritical: !item.rootCauseOfCritical,
            rootCause: !item.rootCause,
            description: !item.description,
          };
        }
        return o;
      });
      
      setAccordances(newList);
    } else {
      const newItem = {
        ...accordances[0],
        ...emptyBundleAccordance,
        internalId: new Date().getTime().toString(),
        id: null,
        accordanceId: null,
      };
      newList = [...accordances, newItem];
      setAccordances(newList);
      setExpandedAccordances([newItem.internalId]);
    }
  };
  const removeAccordance = (accordanceId: string) => {
    if (accordances.length === 1) {
      if (accordances[0].bundleId) {
        showConfirmDelete(true);
      } else {
        closeModal();
      }
    } else {
      const newList = accordances.filter(i => i.internalId !== accordanceId);
      setAccordances(newList);
    }
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
    setLocalAccordanceType(itemsList.find(i => i.id === newValue).type);
  };

  const handleExpanded = (expanded: boolean, internalId: string) => {
    if (expanded && expandedAccordances.indexOf(internalId) < 0) {
      setExpandedAccordances([...expandedAccordances, internalId]);
    } else {
      setExpandedAccordances(expandedAccordances.filter(i => i !== internalId));
    }
  };

  return {
    tabValue,
    handleTabChange,
    classes,
    itemsList,
    isEdit,
    setIsEdit,
    tabClasses,
    criticalIssueList,
    accordances,
    updateSharedValues,
    handleCriticalIssueChange,
    localAccordanceType,
    handleExpanded,
    expandedAccordances,
    localPossibleActionsList,
    handleSupervisionActionChange,
    handleResponsibleChange,
    handleExpirationDate,
    handlePossibleActionChange,
    handleDescription,
    addFileToList,
    removeFileFromList,
    removeAccordance,
    addNewAccordance,
    submitAccordances,
    submitting,
    handleRootCauseOfCriticalChange,
    handleRootCauseChange,
    rootCauseCategoriesForm,
    rootCauseForm,
    accordanceType,
    productForm,
    handleAccordanceType,
    updateSharedValueRevision,
    updateSharedValuesProducts
  };
};

export default useMultiAccordanceFormState;
