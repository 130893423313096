import React from 'react';
import '../Financial.scss';
import styles from './MultiProgress.module.scss';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';

interface IMultiProgressItem {
  id: number;
  label: string;
  value: number;
  color: string;
  percent: number;
}

interface IMultiProgressProps {
  list: IMultiProgressItem[];
  width?: number;
}

type Props = {
  data: IMultiProgressProps;
};

const MultiProgress = ({ data }: Props) => {
  const { t } = useTranslation();
  const { list, width = 300 } = data;
  const { container, bar, progressContainer, listItem, itemBullet, text, containerList, textLight } = styles;

  const getPercentageWidth = (item: IMultiProgressItem) => (item.percent / 100) * width;

  return (
    <div className={container}>
      <div style={{ width }} className={progressContainer}>
        {list.map((item, index) => (
          <div
            className={bar}
            key={item.id}
            style={{
              backgroundColor: item.color,
              width: getPercentageWidth(item) + (index > 0 ? 10 : 0),
              marginLeft: index > 0 ? -10 : 0,
              zIndex: index * -1,
            }}
          ></div>
        ))}
      </div>
      <ul className={containerList}>
        {list.map(item => (
          <li className={listItem} key={item.id}>
            <span className={itemBullet} style={{ backgroundColor: item.color }}></span>
            <div>
              <p className={text}>{`${t(item.label)} ${item.percent}%`}</p>
              <p className={textLight}>
                <NumberFormat value={item.value} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />
                <span>{t('dolar_sign')}</span>
              </p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MultiProgress;
