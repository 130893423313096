import React from 'react';
import { useTranslation } from 'react-i18next';
import { QCalificationInitials } from '../../../models/EsgQCalification';
import './EsgCategoryPortfolio.scss';

type Props = {
  esgCategoryConcept: any;
};
export const EsgCategoryPortfolio = ({ esgCategoryConcept }: Props) => {
  const { t } = useTranslation();

  const setClassName = esgCategoryConcept => {
    switch (esgCategoryConcept) {
      case QCalificationInitials.A:
        return 'a';
      case QCalificationInitials.B:
        return 'b';
      case QCalificationInitials.B13:
        return 'b13';
      case QCalificationInitials.C:
        return 'c';
      case QCalificationInitials.FI:
        return 'fi';
      default:
        return 'na';
    }
  };

  const setValueText = esgCategoryConcept => {
    switch (esgCategoryConcept) {
      case QCalificationInitials.Not_Assigned:
        return t('not_assigned');
      case QCalificationInitials.B13:
        return t('b13');
      case QCalificationInitials.FI:
        return t('fi');
      default:
        return esgCategoryConcept;
    }
  };

  return (
    <div className="esg-category-portfolio-container">
      <span className={`esg-text ${setClassName(esgCategoryConcept)}`}> {setValueText(esgCategoryConcept)} </span>
    </div>
  );
};
