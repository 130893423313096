import { CalledFrom } from '../../../models/CalledFromEnum';
import { AppMenuTabs, MenuTabs } from '../../MenuTabs/userTabsInterface';
import { getDivisionFilterOptions } from '../UtilFunctions';

/* Method buildInitialStates begin */
const isSectorOrDivisionTab = (selectedTab: MenuTabs, dataFromRedux: any[]) => {
  return selectedTab.tabId === 0 && dataFromRedux.find(item => item.code === selectedTab.title);
};

const handleSectorAndDivisionFilterRender = (
  selectedTab: MenuTabs,
  dataFromRedux: any,
  setShowSectorFilter: Function,
  setShowDivisionFilters: Function,
) => {
  const { sectorsState, divisionsState } = dataFromRedux;
  const sectorFound = isSectorOrDivisionTab(selectedTab, sectorsState);

  setShowSectorFilter(!sectorFound);

  const divisionFound = isSectorOrDivisionTab(selectedTab, divisionsState);
  divisionFound && setShowSectorFilter(!divisionFound);
  setShowDivisionFilters(!divisionFound);
};

export const buildInitialStates = (
  calledFrom: CalledFrom,
  menuTabs: AppMenuTabs,
  filters: any,
  setters: any,
  dataFromRedux: any,
) => {
  const { filterIndicators, filterProjects, filterEarlyWarnings } = filters;
  const { setShowHiddenFilters, setShowSectorFilter, setShowDivisionFilters } = setters;

  let selectedTab;
  let coDivisionResponsibleFilterValue;
  let participatingDivisionFilterValue;

  if (calledFrom === CalledFrom.PORTFOLIO) {
    selectedTab = menuTabs.portfolioSelectedTab;
    coDivisionResponsibleFilterValue = filterIndicators.common.coReponsibleDivisions;
    participatingDivisionFilterValue = filterIndicators.common.participantDivisions;

    handleSectorAndDivisionFilterRender(selectedTab, dataFromRedux, setShowSectorFilter, setShowDivisionFilters);
  } else if (calledFrom === CalledFrom.CUSTOM_TABS_PORTFOLIO) {
    selectedTab = menuTabs.portfolioSelectedTab;
    coDivisionResponsibleFilterValue = selectedTab.coReponsibleDivisions;
    participatingDivisionFilterValue = selectedTab.participantDivisions;
  } else if (calledFrom === CalledFrom.PROJECT) {
    selectedTab = menuTabs.projectSelectedTab;
    coDivisionResponsibleFilterValue = filterProjects.common.coReponsibleDivisions;
    participatingDivisionFilterValue = filterProjects.common.participantDivisions;

    handleSectorAndDivisionFilterRender(selectedTab, dataFromRedux, setShowSectorFilter, setShowDivisionFilters);
  } else if (calledFrom === CalledFrom.CUSTOM_TABS_PROJECT) {
    selectedTab = menuTabs.projectSelectedTab;
    coDivisionResponsibleFilterValue = selectedTab.coReponsibleDivisions;
    participatingDivisionFilterValue = selectedTab.participantDivisions;
  } else if (calledFrom === CalledFrom.EARLY_WARNINGS) {
    selectedTab = menuTabs.earlyWarningsSelectedTab;
    coDivisionResponsibleFilterValue = filterEarlyWarnings.common.coReponsibleDivisions;
    participatingDivisionFilterValue = filterEarlyWarnings.common.participantDivisions;

    handleSectorAndDivisionFilterRender(selectedTab, dataFromRedux, setShowSectorFilter, setShowDivisionFilters);
  }

  if (
    (coDivisionResponsibleFilterValue && coDivisionResponsibleFilterValue.length) ||
    (participatingDivisionFilterValue && participatingDivisionFilterValue.length)
  ) {
    setShowHiddenFilters(true);
  }
};
/* Method buildInitialStates end */
