import React from 'react';
import { RadialHeaderList } from '../RadialHeaderList/RadialHeaderList';
import { PmrValidationStateTable } from './PmrValidationStateTable/PmrValidationStateTable';

type Props = {
  headerTitle: Function;
};
export const PmrValidationState = ({ headerTitle }: Props) => {
  return (
    <div>
      <RadialHeaderList headerTitle={headerTitle} />
      <PmrValidationStateTable />
    </div>
  );
};
