import React, { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { styles } from '../Styles';
import './ReportReviewConfig.scss';
import SaveButton from '../../Buttons/SaveButton/SaveButton';
import { useTranslation } from 'react-i18next';
import { disableEscKey } from '../Util';
import { useDispatch, useSelector } from 'react-redux';
import CloseCrossButton from '../../Buttons/CloseCrossButton/CloseCrossButton';
import { initialValues, updateChildren, lastToUncheck, createParamsForSubmit } from './util';
import { createReviewReport } from '../../../../api/reportApi';
import ReviewReportOption from './ReviewReportOption';
import { ReportSectionTabs } from '../../../../models/ReportEnums';
import { setProjectTabValue } from '../../../../redux/actions/projectTabValue';
import BaseModal from '../BaseModal';

type Props = {
  isOpen: boolean;
  closeModal: any;
  submit: any;
};

const ReportReviewConfig = (props: Props) => {
  const { isOpen, closeModal, submit } = props;
  const { t } = useTranslation();
  const currentReview = useSelector<any, any>(state => state.currentReview);
  const [disabledButtonWhenClick, setDisabledButtonWhenClick] = React.useState(false);
  const [reviewReportSections, setReviewReportSections] = useState<any>(cloneDeep(initialValues));
  const dispatch = useDispatch();
  useEffect(() => {
    setReviewReportSections(cloneDeep(initialValues));
  }, [isOpen]);

  const resetChildren = (key, isChecked) => {
    const newList = reviewReportSections.map(option => {
      if (option.parent.key === key) {
        option.parent.value = isChecked;
        option.children = cloneDeep(initialValues.find(o => o.parent.key === key)?.children);
      }
      return option;
    });
    setReviewReportSections(newList);
  };

  const updateOptions = (isChecked, key, radioButton, parentKey) => {
    if (lastToUncheck(isChecked, key, radioButton, parentKey, reviewReportSections)) {
      updateOptions(isChecked, parentKey, radioButton, parentKey);
    } else if (key === parentKey && !isChecked) {
      resetChildren(key, isChecked);
    } else {
      const newOptions = reviewReportSections.map(item => {
        if (item.parent && item.parent.key === key && isChecked) {
          item.parent.value = isChecked;
        } else {
          if (item.children && item.children.length) {
            item.children = updateChildren(isChecked, key, radioButton, parentKey, item);
          }
        }
        return item;
      });
      setReviewReportSections(newOptions);
    }
  };

  const openReportModal = async () => {
    setDisabledButtonWhenClick(true);
    dispatch(setProjectTabValue(ReportSectionTabs.REVIEWS));
    const params = createParamsForSubmit(reviewReportSections, currentReview);
    await createReviewReport(params);
    setDisabledButtonWhenClick(false);
    submit();
    closeModal();
  };

  return (
    <BaseModal
      isOpen={isOpen}
      onRequestClose={e => disableEscKey(e, closeModal)}
      style={styles}
      className="modal-report-review-config-content"
    >
      <div className="modal-report-review-config-container">
        <div className="close-cross-container">
          <CloseCrossButton handleClick={closeModal} />
        </div>
        <h3>{`${t('generate_report_for')} ${currentReview.title}`}</h3>
        <div className="options-list">
          <span className="subtitle">{t('sections_to_include')}</span>
          <div className="options-display">
            {reviewReportSections.map(item => {
              return (
                <ReviewReportOption
                  option={item}
                  key={item.parent.key}
                  onChange={(isChecked, key, radioButton, parentKey) =>
                    updateOptions(isChecked, key, radioButton, parentKey)
                  }
                />
              );
            })}
          </div>
        </div>
        <div className="horizontal">
          <SaveButton
            handleClick={openReportModal}
            disabled={disabledButtonWhenClick || !reviewReportSections.some(i => i.parent.value)}
            customText={t('generate')}
          />
        </div>
      </div>
    </BaseModal>
  );
};

export default ReportReviewConfig;
