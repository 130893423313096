export enum CountriesColorPalleteEnum {
  'CH' = '#00CFDD',
  'CR' = '#91E2A1',
  'BR' = '#FDAC41',
  'DR' = '#F7DD00',
  'EC' = '#ECDBC0',
  'GY' = '#F9C268',
  'SU' = '#D3E0C5',
  'BA' = '#A6D277',
  'HA' = '#DED6EA',
  'NI' = '#417FFE',
  'PE' = '#7A629B',
  'UR' = '#C2B0DB',
  'VE' = '#B1EDF1',
  'BH' = '#ED5A4D',
  'BL' = '#E4CDD2',
  'GU' = '#E092A3',
  'PN' = '#CCE4DE',
  'AR' = '#91DECD',
  'BO' = '#CCB0F5',
  'HO' = '#00BAC7',
  'JA' = '#8DA8D7',
  'RG' = '#9CBFFD',
  'CO' = '#C70048',
  'ES' = '#9C395D',
  'ME' = '#91D0DE',
  'PR' = '#9CA6FD',
  'TT' = '#9ca674',
  'SEDE' = '#00848D',
}

export enum CountriesCodeEnum {
  'CH' = 1,
  'CR',
  'BR',
  'DR',
  'EC',
  'GY',
  'SU',
  'BA',
  'HA',
  'NI',
  'PE',
  'UR',
  'VE',
  'BH',
  'BL',
  'GU',
  'PN',
  'AR',
  'BO',
  'HO',
  'JA',
  'RG',
  'CO',
  'ES',
  'ME',
  'PR',
  'TT',
  'SEDE',
}
