import { LoadingState } from './../../models/EarlyWarningsSection';
import { BEMenuTabsResponse, MenuTabs } from './../../components/MenuTabs/userTabsInterface';
import User from '../../models/User';
import FilterProjects from '../../models/FilterProjects';
import Project from '../../models/Project';
import { RequestStatus } from '../../models/RequestStatus';
import { tabIndexes } from '../../models/TabIndexes';
import Indicator from '../../models/Indicator';
import CalendarEvent from '../../models/CalendarEvent';
import Notifications from '../../models/Notifications';
import Region from '../../models/Region';
import Division from '../../models/Division';
import Accordance from '../../models/Accordance';
import FilterIndicators from '../../models/FilterIndicators';
import { KeyValueBoolean } from '../../models/KeyValueBoolean';
import Help from '../../models/Help';
import FilterManagerialReviews from '../../models/FilterManagerialReviews';
import { ReportSectionsEnum } from '../../models/ReportEnums';
import FilterAnalytics from '../../models/FilterAnalytics';
import IndicatorDetailFilter, { IndicatorDetailChartFilter } from '../../models/IndicatorDetailFilter';
import FilterEarlyWarnings from '../../models/FilterEarlyWarnings';
import { IEarlyWarningsProjects, ModuleButton } from '../../models/EarlyWarningsSection';
export const SET_FILTER = 'SET_FILTER';
export const SET_FILTER_INDICATORS = 'SET_FILTER_INDICATORS';
export const SET_FILTER_MANAGERIAL_REVIEWS = 'SET_FILTER_MANAGERIAL_REVIEWS';
export const SET_USER = 'SET_USER';
export const RESET_USER = 'RESET_USER';
export const UPDATE_USER = 'UPDATE_USER';

// CheckedItems filter
export const SET_CHECKED_ITEMS = 'SET_CHECKED_ITEMS';

// Indicator Detail Filter

export const SET_INDICATOR_DETAIL_FILTER = 'SET_INDICATOR_DETAIL_FILTER';
export const SET_INDICATOR_DETAIL_FILTER_CHART = 'SET_INDICATOR_DETAIL_FILTER_CHART';
export const SET_INDICATOR_DETAIL_FILTER_PROJECT_TYPE = 'SET_INDICATOR_DETAIL_FILTER_PROJECT_TYPE';
export const RESET_INDICATOR_DETAIL_FILTER_CHART = 'RESET_INDICATOR_DETAIL_FILTER_CHART';
export const RESET_INDICATOR_DETAIL_FILTER = 'RESET_INDICATOR_DETAIL_FILTER';

interface SetIndicatorDetailFilter {
  type: typeof SET_INDICATOR_DETAIL_FILTER;
  payload: IndicatorDetailFilter;
}
interface SetIndicatorDetailFilterChart {
  type: typeof SET_INDICATOR_DETAIL_FILTER_CHART;
  payload: IndicatorDetailChartFilter;
}
interface SetIndicatorDetailFilterProjectType {
  type: typeof SET_INDICATOR_DETAIL_FILTER_PROJECT_TYPE;
  payload: boolean | null;
}
interface ResetIndicatorDetailFilterChart {
  type: typeof RESET_INDICATOR_DETAIL_FILTER_CHART;
}

interface ResetIndicatorDetailFilter {
  type: typeof RESET_INDICATOR_DETAIL_FILTER;
}

export type IndicatorDetailFiltersActionTypes =
  | SetIndicatorDetailFilter
  | SetIndicatorDetailFilterChart
  | SetIndicatorDetailFilterProjectType
  | ResetIndicatorDetailFilterChart
  | ResetIndicatorDetailFilter;

//Current Review

export const SET_CURRENT_REVIEW = 'SET_CURRENT_REVIEW';
export const RESET_CURRENT_REVIEW = 'RESET_CURRENT_REVIEW';

interface SetCurrentReviewAction {
  type: typeof SET_CURRENT_REVIEW;
  payload: any;
}
interface ResetCurrentReviewAction {
  type: typeof RESET_CURRENT_REVIEW;
}

export type CurrentReviewActionTypes = SetCurrentReviewAction | ResetCurrentReviewAction;

//REview select projects section
export const SET_SHOW_SELECT_PROJECTS = 'SET_SHOW_SELECT_PROJECTS';

interface ReviewShowSelectProjectsAction {
  type: typeof SET_SHOW_SELECT_PROJECTS;
  payload: boolean;
}

export type ReviewShowSelectProjectsActionTypes = ReviewShowSelectProjectsAction;

//Review Selected Filters

export const RESET_REVIEW_SELECTED_FILTER = 'RESET_REVIEW_SELECTED_FILTER';

interface ResetReviewProjectsSelectedFiltersAction {
  type: typeof RESET_REVIEW_SELECTED_FILTER;
}

export const SET_REVIEW_SELECTED_FILTER = 'SET_REVIEW_SELECTED_FILTER';

interface ReviewProjectsSelectedFiltersAction {
  type: typeof SET_REVIEW_SELECTED_FILTER;
  payload: any;
}

export type ReviewProjectsSelectedFiltersActionTypes =
  | ReviewProjectsSelectedFiltersAction
  | ResetReviewProjectsSelectedFiltersAction;

//Review Selected Projects

//Text Search
export const SET_TEXT_SEARCH = 'SET_TEXT_SEARCH';
interface SetTextSearchAction {
  type: typeof SET_TEXT_SEARCH;
  payload: string;
}

export const RESET_TEXT_SEARCH = 'RESET_TEXT_SEARCH';

interface ResetTextSearchAction {
  type: typeof RESET_TEXT_SEARCH;
}

export type TextSearchActionTypes = SetTextSearchAction | ResetTextSearchAction;

//Text Search

export const SET_REVIEW_SELECTED_PROJECT = 'SET_REVIEW_SELECTED_PROJECT';

interface ReviewProjectsSelectedRowsAction {
  type: typeof SET_REVIEW_SELECTED_PROJECT;
  payload: number;
}

export const SET_LIST_REVIEW_SELECTED_PROJECT = 'SET_LIST_REVIEW_SELECTED_PROJECT';

interface AllReviewProjectsSelectedRowsAction {
  type: typeof SET_LIST_REVIEW_SELECTED_PROJECT;
  payload: number[];
}

export const SET_NONE_REVIEW_SELECTED_PROJECT = 'SET_NONE_REVIEW_SELECTED_PROJECT';

interface NoneReviewProjectsSelectedRowsAction {
  type: typeof SET_NONE_REVIEW_SELECTED_PROJECT;
}

export type ReviewProjectsSelectedRowsActionTypes =
  | ReviewProjectsSelectedRowsAction
  | AllReviewProjectsSelectedRowsAction
  | NoneReviewProjectsSelectedRowsAction;

//Permissions modal
export const SET_SUCCESS_MESSAGE = 'SET_SUCCESS_MESSAGE';

interface SuccessMessageAction {
  type: typeof SET_SUCCESS_MESSAGE;
  payload: { show: boolean; message: string };
}

export type SuccessMessageActionTypes = SuccessMessageAction;

//Permissions modal
export const SHOW_PERMISSIONS_MODAL = 'SHOW_PERMISSIONS_MODAL';

interface ShowPermissionsModalAction {
  type: typeof SHOW_PERMISSIONS_MODAL;
  payload: boolean;
}

export type PermissionsModalActionTypes = ShowPermissionsModalAction;

//Project Report Sections
export const SET_PROJECT_REPORT_SECTIONS = 'SET_PROJECT_REPORT_SECTIONS';

interface SetProjectReportSectionsAction {
  type: typeof SET_PROJECT_REPORT_SECTIONS;
  payload: KeyValueBoolean[];
}

export const RESOLVE_PROJECT_REPORT_SECTIONS = 'RESOLVE_PROJECT_REPORT_SECTIONS';

interface ResolveProjectReportSectionsAction {
  type: typeof RESOLVE_PROJECT_REPORT_SECTIONS;
  payload: ReportSectionsEnum;
}

export type ProjectReportSectionsActionTypes = SetProjectReportSectionsAction | ResolveProjectReportSectionsAction;

//Divisions
export const SET_DIVISIONS = 'SET_DIVISIONS';

interface SetDivisionsAction {
  type: typeof SET_DIVISIONS;
  payload: Division[];
}

export type DivisionsActionTypes = SetDivisionsAction;

//User
interface SetUserAction {
  type: typeof SET_USER;
  payload: User;
}

//Filter Projects
interface SetFilterAction {
  type: typeof SET_FILTER;
  payload: FilterProjects;
}
//Filter indicators
interface SetFilterIndicatorsAction {
  type: typeof SET_FILTER_INDICATORS;
  payload: FilterIndicators;
}

// Filter checkitems 
export interface CheckboxState {
  [key: string]: boolean;
}
interface SetCheckedItemsAction {
  type: typeof SET_CHECKED_ITEMS;
  payload: CheckboxState;
}

//Filter Managerial reviews
interface SetFilterManagerialReviewsAction {
  type: typeof SET_FILTER_MANAGERIAL_REVIEWS;
  payload: FilterManagerialReviews;
}
export type FilterActionTypes = SetFilterAction;
export type FilterIndicatorsActionTypes =
  | SetFilterIndicatorsAction
  | SetCheckedItemsAction; // refactor para checkbox
export type FilterManagerialReviewsActionTypes = SetFilterManagerialReviewsAction;
interface UpdateUserAction {
  type: typeof UPDATE_USER;
  payload: any;
}

interface ResetUserAction {
  type: typeof RESET_USER;
}

//current project
export const SET_CURRENT_PROJECT = 'SET_CURRENT_PROJECT';
export const RESET_CURRENT_PROJECT = 'RESET_CURRENT_PROJECT';
export const UPDATE_CURRENT_PROJECT = 'UPDATE_CURRENT_PROJECT';

interface SetCurrentProjectAction {
  type: typeof SET_CURRENT_PROJECT;
  payload: Project;
}

interface ResetCurrentProjectAction {
  type: typeof RESET_CURRENT_PROJECT;
  payload: any;
}

interface UpdateCurrentProjectAction {
  type: typeof UPDATE_CURRENT_PROJECT;
  payload: any;
}

//tabValue
export const SET_TAB_VALUE = 'SET_TAB_VALUE';

interface SetTabValueAction {
  type: typeof SET_TAB_VALUE;
  payload: tabIndexes;
}

//projecttabValue
export const SET_PROJECT_TAB_VALUE = 'SET_PROJECT_TAB_VALUE';

interface SetProjectTabValueAction {
  type: typeof SET_PROJECT_TAB_VALUE;
  payload: number;
}

//Request status
export const SET_REQUEST_STATUS = 'SET_REQUEST_STATUS';

interface SetRequestStatusAction {
  type: typeof SET_REQUEST_STATUS;
  payload: RequestStatus;
}

//Calendar
export const SET_CALENDAR_FILTERS = 'SET_CALENDAR_FILTERS';

interface SetCalendarFilters {
  type: typeof SET_CALENDAR_FILTERS;
  payload: any;
}

export const SET_CALENDAR_EVENTS = 'SET_CALENDAR_EVENTS';

interface SetCalendarEvents {
  type: typeof SET_CALENDAR_EVENTS;
  payload: CalendarEvent[];
}

export const SET_CALENDAR_DATE = 'SET_CALENDAR_DATE';

interface SetCalendarDate {
  type: typeof SET_CALENDAR_DATE;
  payload: any;
}

export const SET_CALENDAR_EVENT_COUNTER = 'SET_CALENDAR_EVENT_COUNTER';
interface SetCalendarEventCounter {
  type: typeof SET_CALENDAR_EVENT_COUNTER;
  payload: any;
}

export const RESET_CALENDAR_FILTERS = 'RESET_CALENDAR_FILTERS';
interface ResetCalendarFilters {
  type: typeof RESET_CALENDAR_FILTERS;
  payload: any;
}

// Regions
export const SET_REGIONS = 'SET_REGIONS';

interface SetRegions {
  type: typeof SET_REGIONS;
  payload: Region[];
}

// Sectors
export const SET_SECTORS = 'SET_SECTORS';

interface SetSectors {
  type: typeof SET_SECTORS;
  payload: [];
}

export type SectorsActionTypes = SetSectors;

// Interest Issues

export const SET_INTEREST_ISSUES = 'SET_INTEREST_ISSUES';

interface SetInterestIssues {
  type: typeof SET_INTEREST_ISSUES;
  payload: [];
}

export type InterestIssuesActionTypes = SetInterestIssues;

export type TabValueActionTypes = SetTabValueAction;
export type ProjectTabValueActionTypes = SetProjectTabValueAction;
export type UserActionTypes = SetUserAction | UpdateUserAction | ResetUserAction;
export type RequestStatusActionTypes = SetRequestStatusAction;
export type CurrentProjectActionTypes =
  | SetCurrentProjectAction
  | ResetCurrentProjectAction
  | UpdateCurrentProjectAction;

//Followed projects
export const SET_FOLLOWED_PROJECTS = 'SET_FOLLOWED_PROJECTS';
export const SET_FOLLOW_UNFOLLOW_PROJECT = 'SET_FOLLOW_UNFOLLOW_PROJECT';

interface SetFollowedProjectsAction {
  type: typeof SET_FOLLOWED_PROJECTS;
  payload: Project[];
}

interface SetFollowUnfollowProjectAction {
  type: typeof SET_FOLLOW_UNFOLLOW_PROJECT;
  payload: Project[];
}
export type FollowedProjectsActionTypes = SetFollowedProjectsAction | SetFollowUnfollowProjectAction;

//All projects
export const SET_ALL_PROJECTS = 'SET_ALL_PROJECTS';
export const SET_ALL_PROJECTS_BY_COUNTRY = 'SET_ALL_PROJECTS_BY_COUNTRY';
export const SET_ALL_PROJECTS_BY_PROPERTY_PARAM = 'SET_ALL_PROJECTS_BY_PROPERTY_PARAM';

interface SetAllProjectsAction {
  type: typeof SET_ALL_PROJECTS;
  payload: Project[];
}

interface SetAllProjectsByCountryAction {
  type: typeof SET_ALL_PROJECTS_BY_COUNTRY;
  payload: Project[];
}
interface SetAllProjectsByPropertyParamAction {
  type: typeof SET_ALL_PROJECTS_BY_PROPERTY_PARAM;
  payload: Project[];
}

export type AllProjectsActionTypes =
  | SetAllProjectsAction
  | SetAllProjectsByCountryAction
  | SetAllProjectsByPropertyParamAction;

//indicators
export const ADD_INDICATORS = 'ADD_INDICATORS';
interface AddIndicators {
  type: typeof ADD_INDICATORS;
  payload: Indicator[];
}
export type AddIndicatorActionTypes = AddIndicators;

export const SET_ALL_INDICATORS = 'SET_ALL_INDICATORS';
interface SetAllIndicators {
  type: typeof SET_ALL_INDICATORS;
  payload: any[];
}
export type SetAllIndicatorActionTypes = SetAllIndicators;

export type CalendarActionTypes =
  | SetCalendarFilters
  | SetCalendarEvents
  | SetCalendarDate
  | SetCalendarEventCounter
  | ResetCalendarFilters;

export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
interface SetNotifications {
  type: typeof SET_NOTIFICATIONS;
  payload: Notifications[];
}

export const SET_NOTIFICATIONS_TOTAL_PAGES = 'SET_NOTIFICATIONS_TOTAL_PAGES';
interface SetNotificationsTotalPages {
  type: typeof SET_NOTIFICATIONS_TOTAL_PAGES;
  payload: any;
}
export type NotificationsActionTypes = SetNotifications | SetNotificationsTotalPages;
export type RegionActionTypes = SetRegions;

export const SET_CURRENT_INDICATOR = 'SET_CURRENT_INDICATOR';
export const RESET_CURRENT_INDICATOR = 'RESET_CURRENT_INDICATOR';
export const UPDATE_CURRENT_INDICATOR_FROM_DETAIL = 'UPDATE_CURRENT_INDICATOR_FROM_DETAIL';

interface SetCurrentIndicatorAction {
  type: typeof SET_CURRENT_INDICATOR;
  payload: Indicator;
}
interface ResetCurrentIndicatorAction {
  type: typeof RESET_CURRENT_INDICATOR;
  payload: Indicator;
}

interface UpdateCurrentIndicatorFromDetailAction {
  type: typeof UPDATE_CURRENT_INDICATOR_FROM_DETAIL;
  payload: Indicator;
}
export type CurrentIndicatorActionTypes =
  | SetCurrentIndicatorAction
  | ResetCurrentIndicatorAction
  | UpdateCurrentIndicatorFromDetailAction;

export const SET_INDICATOR_PROJECTS = 'SET_INDICATOR_PROJECTS';
interface SetIndicatorProjects {
  type: typeof SET_INDICATOR_PROJECTS;
  payload: Project[];
}

export const RESET_INDICATOR_PROJECTS = 'RESET_INDICATOR_PROJECTS';
interface ResetIndicatorProjects {
  type: typeof RESET_INDICATOR_PROJECTS;
  payload: Project[];
}

export type IndicatorProjectsActionTypes = SetIndicatorProjects | ResetIndicatorProjects;

export const SET_INDICATOR_ACCORDANCES = 'SET_INDICATOR_ACCORDANCES';
interface SetIndicatorAccordances {
  type: typeof SET_INDICATOR_ACCORDANCES;
  payload: Accordance[];
}

export const RESET_INDICATOR_ACCORDANCES = 'RESET_INDICATOR_ACCORDANCES';
interface ResetIndicatorAccordances {
  type: typeof RESET_INDICATOR_ACCORDANCES;
}

export type InicatorAccordancesActionTypes = SetIndicatorAccordances | ResetIndicatorAccordances;

// HELP
export const SET_HELP_ITEM = 'SET_HELP_ITEM';
interface SetHelpItem {
  type: typeof SET_HELP_ITEM;
  payload: Help;
}
export type HelpTypes = SetHelpItem;

//Convergence modal
export const SET_CONVERGENCE_MODAL = 'SET_CONVERGENCE_MODAL';

interface ConvergenceModalAction {
  type: typeof SET_CONVERGENCE_MODAL;
  payload: boolean;
}

export type ConvergenceModalActionTypes = ConvergenceModalAction;

//agenda by default
export const SET_AGENDA_BY_DEFAULT = 'SET_AGENDA_BY_DEFAULT';

interface SetAgendaByDefaultAction {
  type: typeof SET_AGENDA_BY_DEFAULT;
  payload: boolean;
}

export type AgendaByDefaultActionTypes = SetAgendaByDefaultAction;

// Analytics filter
export const SET_FILTER_ANALYTICS = 'SET_FILTER_ANALYTICS';

interface SetFilterAnalyticsAction {
  type: typeof SET_FILTER_ANALYTICS;
  payload: FilterAnalytics;
}

export type filterAnalyticsActionTypes = SetFilterAnalyticsAction;

//reviewtabValue
export const SET_REVIEW_NAVIGATION = 'SET_REVIEW_NAVIGATION';
export const RESET_REVIEW_NAVIGATION = 'RESET_REVIEW_NAVIGATION';

interface SetReviewNavigationAction {
  type: typeof SET_REVIEW_NAVIGATION;
  payload: object;
}

interface ResetReviewNavigation {
  type: typeof RESET_REVIEW_NAVIGATION;
}
export type ReviewNavigationActionTypes = SetReviewNavigationAction | ResetReviewNavigation;

//MenuTabs Reducer
export const SET_MENU_TABS = 'SET_MENU_TABS';
export const UPDATE_PORTFOLIO_SELECTED_TAB = 'UPDATE_PORTFOLIO_SELECTED_TAB';
export const UPDATE_PROJECT_SELECTED_TAB = 'UPDATE_PROJECT_SELECTED_TAB';
export const UPDATE_EARLY_WARNINGS_SELECTED_TAB = 'UPDATE_EARLY_WARNINGS_SELECTED_TAB';
export const UPDATE_MANAGERIAL_REVIEW_SELECTED_TAB = 'UPDATE_MANAGERIAL_REVIEW_SELECTED_TAB';
export const UPDATE_CREATION_EDITION_VALUE = 'UPDATE_CREATION_EDITION_VALUE';
export const UPDATE_MENUTABS_FIRST_LOAD = 'UPDATE_MENUTABS_FIRST_LOAD';

interface SetMenuTabsAction {
  type: typeof SET_MENU_TABS;
  payload: BEMenuTabsResponse;
}
interface UpdatePortfolioSelectedTabAction {
  type: typeof UPDATE_PORTFOLIO_SELECTED_TAB;
  payload: MenuTabs;
}

interface UpdateProjectSelectedTabAction {
  type: typeof UPDATE_PROJECT_SELECTED_TAB;
  payload: MenuTabs;
}

interface UpdateEarlyWarningsSelectedTabAction {
  type: typeof UPDATE_EARLY_WARNINGS_SELECTED_TAB;
  payload: MenuTabs;
}

interface UpdateManagerialReviewSelectedTabAction {
  type: typeof UPDATE_MANAGERIAL_REVIEW_SELECTED_TAB;
  payload: MenuTabs;
}

interface UpdateCreationEditionValue {
  type: typeof UPDATE_CREATION_EDITION_VALUE;
  payload: boolean;
}

interface UpdateMenuTabsFirstLoad {
  type: typeof UPDATE_MENUTABS_FIRST_LOAD;
  payload: boolean;
}
export type MenuTabsActionTypes =
  | SetMenuTabsAction
  | UpdatePortfolioSelectedTabAction
  | UpdateProjectSelectedTabAction
  | UpdateEarlyWarningsSelectedTabAction
  | UpdateManagerialReviewSelectedTabAction
  | UpdateCreationEditionValue
  | UpdateMenuTabsFirstLoad;

// Early Warnings Filter
export const SET_FILTER_EARLY_WARNINGS = 'SET_FILTER_EARLY_WARNINGS';
export const SET_FILTER_EARLY_WARNINGS_BUTTONS_MODULES = 'SET_FILTER_EARLY_WARNINGS_BUTTONS_MODULES';

interface SetFilterEarlyWarningsAction {
  type: typeof SET_FILTER_EARLY_WARNINGS;
  payload: FilterEarlyWarnings;
}

interface SetFilterEarlyWarningsButtonsModuleAction {
  type: typeof SET_FILTER_EARLY_WARNINGS_BUTTONS_MODULES;
  payload: ModuleButton[];
}

export type FilterEarlyWarningsActionTypes = SetFilterEarlyWarningsAction | SetFilterEarlyWarningsButtonsModuleAction;

export const SET_EARLY_WARNINGS_PROJECTS = 'SET_EARLY_WARNINGS_PROJECTS';
interface SetEarlyWarningsProjects {
  type: typeof SET_EARLY_WARNINGS_PROJECTS;
  payload: IEarlyWarningsProjects;
}

export const RESET_EARLY_WARNINGS_PROJECTS = 'RESET_EARLY_WARNINGS_PROJECTS';
interface ResetEarlyWarningsProjects {
  type: typeof RESET_EARLY_WARNINGS_PROJECTS;
  payload: IEarlyWarningsProjects;
}

export const UPDATE_LOADING_STATE = 'UPDATE_LOADING_STATE';
interface UpdateLoadingState {
  type: typeof UPDATE_LOADING_STATE;
  payload: LoadingState;
}

export type EarlyWarningsProjectsActionTypes =
  | SetEarlyWarningsProjects
  | ResetEarlyWarningsProjects
  | UpdateLoadingState;

//reportSectionTabValue
export const SET_REPORT_SECTION_TAB_VALUE = 'SET_REPORT_SECTION_TAB_VALUE';

interface SetReportSectionTabValueAction {
  type: typeof SET_REPORT_SECTION_TAB_VALUE;
  payload: number;
}
export type ReportSectionTabValueActionTypes = SetReportSectionTabValueAction;
