import { axiosDefaultConfig } from './axiosConfig';

export const uploadFile = (file: any, fileName: string, uploadUrl: string) => {
  const data = new FormData();
  data.append(fileName, file, fileName);
  return new Promise<any>(async (resolve, reject) => {
    try {
      const response = await axiosDefaultConfig.post(uploadUrl, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (response.data && response.data.length > 0) {
        resolve(response.data[0]);
      } else {
        reject('no data');
      }
    } catch (error) {
      reject(error);
    }
  });
};
