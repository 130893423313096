import React from 'react';

import { styles } from '../Styles';
import CloseCrossButton from '../../Buttons/CloseCrossButton/CloseCrossButton';
import BaseModal from '../BaseModal';

import css from "./ExcelDownloadModal.module.scss"

type Props = {
    children?: JSX.Element | JSX.Element[]
    isOpen: boolean;
    closeModal: any;
    retryDownload: () => void;
    cancelDownload: () => void;
    errorMsg: ErrorMsg;
};

interface ErrorMsg {
    title: string,
    subtitleMsg: {
        sub1: string
        sub2?: string
    }
}

export const ExcelDownloadModal = ({ isOpen, closeModal, retryDownload, cancelDownload, errorMsg }: Props) => {
    const { content, container, closeCrossContainer, titleErrorMsg, subtitleErrorMsg, subtitleBoldErrorMsg, icon } = css;

    return (
        <BaseModal isOpen={isOpen} onRequestClose={closeModal} style={styles} className={content}>
            <div className={container}>
                <div className={closeCrossContainer}>
                    <CloseCrossButton handleClick={closeModal} />
                </div>
                <div className={titleErrorMsg}>
                    <div className={icon}>X</div>
                    <h3>{errorMsg.title}</h3>
                    <p className={subtitleErrorMsg}>{errorMsg.subtitleMsg.sub1}</p>
                    <p className={subtitleBoldErrorMsg}>{errorMsg.subtitleMsg.sub2}</p>
                </div>
                <div className={css["c-btn"]}>
                    <button className={`btn-primary ci-type-display-5 ${css["btn-msg"]}`} onClick={retryDownload}>Reintentar</button>
                    <button className={`btn-primary ci-type-display-5 ${css["btn-msg"]}  ${css["btn-msg-white"]}`} onClick={cancelDownload}>Cancelar</button>
                </div>
            </div>
        </BaseModal>
    );
};
