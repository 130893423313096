import React from 'react';
import styles from './LargeImage.module.scss';
import { IndicatorKeyEnum } from '../../../../../models/IndicatorKeyEnum';
import GcmChart from '../../../../PortfolioDetail/GcmAlert/GcmChart/GcmChart';
import LastFiveYears from './LastFiveYears';
import DisbursementTime from './DisbursementTime';
import PmrEvolutionLastFiveYears from './PmrEvolutionLastFiveYears';

type Props = {
  indicator: any;
};

const LargeImage = ({ indicator }: Props) => {
  const { multiChartContainerGcm } = styles;

  if (indicator.indicatorKey === IndicatorKeyEnum.GCM_ALERTS) {
    return (
      <div className={multiChartContainerGcm} id={indicator.largeImageKey}>
        {indicator.data && indicator.data.chartData
          ? indicator.data.chartData.map(item => (
              <GcmChart
                disableAnimation={true}
                data={item.data}
                title={item.title}
                key={item.status}
                handleClick={() => {}}
                customWidth={380}
              />
            ))
          : null}
      </div>
    );
  } else if (
    indicator.largeImageKey === `${IndicatorKeyEnum.DISBURSEMENT_OVER_TIME}_DETAIL` ||
    indicator.largeImageKey === `${IndicatorKeyEnum.DISBURSEMENT_OVER_TIME}_TCP_DETAIL` ||
    indicator.largeImageKey === `${IndicatorKeyEnum.DISBURSEMENT_OVER_TIME}_IGR_DETAIL`
  ) {
    return <DisbursementTime indicator={indicator} />;
  } else if (
    indicator.largeImageKey === `${IndicatorKeyEnum.DISBURSEMENT_PROJECTION_LAST_5_YEARS}_DETAIL` ||
    indicator.largeImageKey === `${IndicatorKeyEnum.DISBURSEMENT_PROJECTION_LAST_5_YEARS}_IGR_DETAIL` ||
    indicator.largeImageKey === `${IndicatorKeyEnum.PORTFOLIO_OVERVIEW_LAST_5_YEARS}_DETAIL` ||
    indicator.largeImageKey === `${IndicatorKeyEnum.PORTFOLIO_OVERVIEW_LAST_5_YEARS}_TCP_DETAIL` ||
    indicator.largeImageKey === `${IndicatorKeyEnum.PORTFOLIO_OVERVIEW_LAST_5_YEARS}_IGR_DETAIL`
  ) {
    return <LastFiveYears currentIndicator={indicator} />;
  } else if (
    indicator.largeImageKey === `${IndicatorKeyEnum.PMR_EVOLUTION_LAST_5_YEARS}_DETAIL` ||
    indicator.largeImageKey === `${IndicatorKeyEnum.PMR_EVOLUTION_LAST_5_YEARS}_TCP_DETAIL`
  ) {
    return <PmrEvolutionLastFiveYears currentIndicator={indicator} />;
  } else {
    return null;
  }
};

export default LargeImage;
