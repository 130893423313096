import { usePrevious } from './usePrevious';
import { useEffect, useState } from 'react';
import { TabFilterOption } from '../models/EarlyWarnings';
import { TabsFiltersEnum } from '../components/PortfolioDetail/LastDisbursementDateCloseOrExpired/TabsFilter/TabsFilter';

type Props = {
  sessionKey: any;
  defaultSessionFilterObject?: any;
  projectTypeFilterOnDetail?: number;
};

export const useEarlyWarningsFilters = (props: Props) => {
  const { sessionKey, defaultSessionFilterObject = {}, projectTypeFilterOnDetail = 0 } = props;

  const getFilterFromSession = () => {
    const storage = sessionStorage.getItem(sessionKey);
    const storageObject = storage && JSON.parse(storage);
    return storageObject ? storageObject : defaultSessionFilterObject;
  };

  const [filterConfigState, setFilterConfigState] = useState(getFilterFromSession().filterConfigState);
  const [selectedTab, setSelectedTab] = useState(getFilterFromSession().selectedTab);
  const previousTab = usePrevious(selectedTab);

  useEffect(() => {
    const storage = sessionStorage.getItem(sessionKey);
    storage && sessionStorage.setItem(sessionKey, JSON.stringify({ ...JSON.parse(storage), filterConfigState }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterConfigState]);

  const handleEarlyWarningsFilter = (filter: TabFilterOption) => {
    const storage = sessionStorage.getItem(sessionKey);
    const selectedEarlyWarningsStorage = storage && JSON.parse(storage);
    if (selectedEarlyWarningsStorage.filterConfigState.find(f => f.id === filter.id).selected) {
      const filterConfigComponentToUpdate = selectedEarlyWarningsStorage.filterConfigState.map(obj => {
        return { ...obj, selected: false };
      });

      setFilterConfigState(filterConfigComponentToUpdate);
    } else {
      const filterConfigComponentToUpdate = selectedEarlyWarningsStorage.filterConfigState.map(obj => {
        if (obj.id === filter.id) {
          return { ...obj, selected: true };
        }
        return { ...obj, selected: false };
      });
      setFilterConfigState(filterConfigComponentToUpdate);
    }
    sessionStorage.removeItem('PaginationIndicator');
  };

  const handleProjectsQuantities = projectsQuantity => {
    const updateFilterConnfigState = filterConfigState.map((obj, idx) => {
      return { ...obj, projectQuantity: projectsQuantity[idx] };
    });
    setFilterConfigState(updateFilterConnfigState);
  };

  useEffect(() => {
    if (getFilterFromSession().changeProjectType) {
      setFilterConfigState(
        getFilterFromSession().filterConfigState.map(obj => {
          return { ...obj, selected: false };
        }),
      );
      if (getFilterFromSession().changeProjectType) {
        sessionStorage.removeItem('PaginationIndicator');
      }
    } else {
      const storage = sessionStorage.getItem(sessionKey);
      storage &&
        sessionStorage.setItem(sessionKey, JSON.stringify({ ...JSON.parse(storage), changeProjectType: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectTypeFilterOnDetail]);

  useEffect(() => {
    if (getFilterFromSession().changeProjectType) {
      const storage = sessionStorage.getItem(sessionKey);
      storage && sessionStorage.setItem(sessionKey, JSON.stringify({ ...JSON.parse(storage), selectedTab }));
      if (selectedTab !== previousTab && previousTab !== undefined) {
        sessionStorage.removeItem('PaginationIndicator');
      }
    }
    if (selectedTab === TabsFiltersEnum.NO_EXPIRED) {
      const updateFilterConnfigState = filterConfigState.map(obj => {
        return { ...obj, selected: false };
      });
      setFilterConfigState(updateFilterConnfigState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  return {
    filterConfigState,
    handleEarlyWarningsFilter,
    handleProjectsQuantities,
    selectedTab,
    setSelectedTab,
  };
};
