import React from 'react';
import './DisbursementsChart.scss';
import { useTranslation } from 'react-i18next';
import { Bar } from 'react-chartjs-2';
import '../../../../Util/RounderedBars/RounderedBars';
import { maxBy, minBy, max, min } from 'lodash';
import WindowSizeListener from 'react-window-size-listener';
import { basicYAxes } from '../../../../../services/util';
import { thousandsSeparatorToMillion } from '../../../../../services/stringUtil';

type Props = {
  data: any;
  double?: boolean;
  financialInformation?: any;
  ctExecutingAgency?: boolean;
};

const options = {
  plugins: {
    datalabels: {
      display: false,
      offset: 30,
      anchor: 'start',
      align: 'bottom',
      clip: false,
      rotation: 45,
      padding: 0,
      textAlign: "center",
      formatter: function (value) {
        return 'line1\nline2\n' + value;
      },
    },

  },
  layout: {
    padding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    }
  },
  legend: {
    display: false,
  },
  tooltips: {
    enabled: true,
    backgroundColor: '#101319',
    titleFontSize: 14,
    bodyFontSize: 14,
    callbacks: {
      title: () => { },
      label(tooltipItem, data) {
        return `${thousandsSeparatorToMillion(tooltipItem.value)}`;
      },
    },
  },
  scales: {
    xAxes: [
      {
        display: true,
        stacked: false,
        ticks: {
          beginAtZero: true,
          fontColor: '#727E8C',
          fontSize: 14,
          fontFamily: 'Rubik',
        },
        gridLines: {
          display: false,
          drawBorder: false,
        },
      },
    ],
    yAxes: [
      {
        display: false,
        stacked: false,
        ticks: {
          beginAtZero: true,
          fontColor: '#727E8C',
          fontSize: 14,
          fontFamily: 'Rubik',
          callback(value) {
            return `${thousandsSeparatorToMillion(value)}`;
          },
        },
        gridLines: {
          display: true,
          drawBorder: false,
          zeroLineColor: '#F2F2F2',
          color: '#F2F2F2',
        },
      },
    ],
  },
  cornerRadius: 4,
};

const getOptions = (double, data) => {
  if (!double) {
    return {
      ...options,
      scales: {
        ...options.scales,
        yAxes: basicYAxes(data),
      },
    }
      ;
  } else {
    return {
      ...options,
      barValueSpacing: 20,
      scales: {
        ...options.scales,
        yAxes: basicYAxes(data),
      },
    };
  }
};

const months = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

const calculateCurrentMonthStyle = (data, containerRef, double) => {
  const month = new Date().getMonth();
  if (!data.datasets[0].data || data.datasets[0].data.length === 0) {
    return { display: 'none' };
  }
  const hasMinusValues = double
    ? data.datasets[0].data.filter(item => parseInt(item) < 0).length > 0
    : data.datasets[0].data.filter(item => parseInt(item.y) < 0).length > 0;
  const hasNoValues = double
    ? data.datasets[0].data.filter(item => parseInt(item) === 0).length === data.datasets[0].data.length
    : data.datasets[0].data.filter(item => parseInt(item.y) === 0).length === data.datasets[0].data.length;
  const containerWidth = containerRef && containerRef.current ? containerRef.current.clientWidth : 0;
  const maxValue = double ? parseInt(max(data.datasets[0].data)) : maxBy(data.datasets[0].data, 'y').y;
  const minValue = double
    ? Math.abs(parseInt(min(data.datasets[0].data)))
    : Math.abs(minBy(data.datasets[0].data, 'y').y);
  const mostLength = maxValue > minValue ? maxValue.toString() : minValue.toString();
  const offset = double ? 0 : mostLength.length * 5 + 6 + (hasMinusValues ? 7 : 0) + (hasNoValues ? 8 : 0);
  const chartWidth = containerWidth - offset - containerWidth * 0.044 - containerWidth * 0.053;
  const leftValue = (chartWidth / 11) * month + offset - 10 + containerWidth * 0.053;

  return { left: `${leftValue}px` };
};

const DisbursementsChart = (props: Props) => {
  const { t } = useTranslation();
  const { financialInformation, data, double, ctExecutingAgency } = props;

  const containerRef = React.useRef(null);
  const [currentMontStyle, setCurrentMonthstyle] = React.useState<any>({ display: 'none' });
  const currentMonth = new Date().getMonth();

  React.useEffect(() => {
    setCurrentMonthstyle(calculateCurrentMonthStyle(data, containerRef, double));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <WindowSizeListener
      onResize={windowSize => {
        setCurrentMonthstyle(calculateCurrentMonthStyle(data, containerRef, double));
      }}
    >
      <div className="financial-disbursement-chart-container" ref={containerRef}>
        <span className="title">{t('disbursements_for_the_year')} (USD)</span>
        {data && <Bar data={data} options={getOptions(double, data)} />}
        <span className="current-month" style={currentMontStyle}>
          {t(months[currentMonth]).substring(0, 1)}
        </span>
      </div>
    </WindowSizeListener>
  );
};

export default DisbursementsChart;
