import React from 'react';
import './NewAccordanceForm.scss';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SelectOption from '../../../../../models/SelectOption';
import { AccordanceType } from '../../../../../models/AccordanceType';
import { CommonInputs } from './CommonInputs';
import NewAccordanceFormButtons from './NewAccordanceFormButtons';
import useFormState from '../../../../../hooks/useFormState';
import Project from '../../../../../models/Project';

const revisionDateValue = (
  revisionDate: Date,
  accordanceFormType: AccordanceType,
  semester: SelectOption,
): Date | null => {
  if (accordanceFormType === AccordanceType.Revision) {
    return revisionDate;
  } else if (accordanceFormType === AccordanceType.InterGroup && semester) {
    return new Date(semester.value);
  }
  return null;
};

type Props = {
  close: Function;
  accordanceFormType: AccordanceType;
  displayGenericErrorsMessage: Function;
  editAccordanceData?: any;
  review?: any;
  accordanceState?: number;
  currentUserCanApprove?: boolean;
  accordanceCreateRevision?: boolean;
  closeModalEditReviewer?: any;
};

const NewAccordanceForm = (props: Props) => {
  const currentProject = useSelector<any, Project>(state => state.currentProject);
  const { t } = useTranslation();
  const {
    accordanceState,
    currentUserCanApprove,
    editAccordanceData,
    review,
    closeModalEditReviewer,
    accordanceCreateRevision,
  } = props;

  const {
    accordanceFormType,
    revisionDate,
    setFormErrors,
    formErrors,
    setRevisionDate,
    expirationDate,
    setExpirationDate,
    setSemesterOption,
    semesterOption,
    criticalIssueList,
    criticalIssue,
    handleCriticalIssueChange,
    category,
    possibleActionsList,
    possibleAction,
    handlePossibleActionChange,
    handleExpirationDate,
    responsible,
    handleIemSelected,
    accordanceFiles,
    setAccordanceFiles,
    editAccordanceInfo,
    description,
    criticalIssueDescription,
    setCriticalIssueDescription,
    setDescription,
    submit,
    disableDraftButton,
    submitting,
    submitAccordanceDraft,
    supervisionActionList,
    handleSupervisionActionChange,
    supervisionAction,
    accordanceDate,
    handleAccordanceDate,
    setMultipleAccordances,
    definedAgreement,
    linkedProductsAccordance,
    rootCauseOfCritical,
    rootCause,
    handleDefinedAgreementChange,
    handleLinkedProduct,
    handleRootCauseOfCritical,
    handleRootCause,
    accordanceType,
    rootCauseCategories,
    rootCauseForm,
    productForm,
    validateTextareaCritical,
    validateTextareaDescription,
  } = useFormState({ ...props, currentProject });

  return (
    <div className="new-accordance-form-container">
      <CommonInputs
        criticalIssueList={criticalIssueList}
        criticalIssue={criticalIssue}
        handleCriticalIssueChange={handleCriticalIssueChange}
        formErrors={formErrors}
        category={category}
        possibleActionsList={possibleActionsList}
        possibleAction={possibleAction}
        handlePossibleActionChange={handlePossibleActionChange}
        expirationDate={expirationDate}
        handleExpirationDate={handleExpirationDate}
        responsible={responsible}
        handleIemSelected={handleIemSelected}
        projectId={currentProject.id}
        filesList={accordanceFiles}
        addFileToList={file => {
          setAccordanceFiles([...accordanceFiles, file]);
        }}
        removeFileFromList={file => {
          setAccordanceFiles(accordanceFiles.filter(item => item.url !== file.url));
        }}
        editAccordanceData={editAccordanceInfo}
        closeParentModal={props.close}
        accordanceType={accordanceFormType}
        description={description}
        criticalIssueDescription={criticalIssueDescription}
        handleCriticalIssueDescription={desc => {
          setCriticalIssueDescription(desc);
        }}
        handleDescription={desc => {
          setDescription(desc);
        }}
        revisionDate={revisionDate}
        accordanceState={accordanceState}
        supervisionActionList={supervisionActionList}
        handleSupervisionActionChange={handleSupervisionActionChange}
        supervisionAction={supervisionAction}
        setFiles={setAccordanceFiles}
        setFormErrors={setFormErrors}
        setRevisionDate={setRevisionDate}
        setExpirationDate={setExpirationDate}
        setSemesterOption={setSemesterOption}
        semesterOption={semesterOption}
        accordanceFormType={accordanceFormType}
        accordanceDate={accordanceDate}
        handleAccordanceDate={handleAccordanceDate}
        definedAgreement={definedAgreement}
        linkedProduct={linkedProductsAccordance}
        rootCauseOfCritical={rootCauseOfCritical}
        rootCause={rootCause}
        handleDefinedAgreementChange={handleDefinedAgreementChange}
        handleLinkedProduct={handleLinkedProduct}
        handleRootCauseOfCritical={handleRootCauseOfCritical}
        handleRootCause={handleRootCause}
        accordanceDefinitionForm={accordanceType}
        rootCauseCategoriesForm={rootCauseCategories}
        rootCauseForm={rootCauseForm}
        productForm={productForm}
        validateTextareaCritical={validateTextareaCritical}
        validateTextareaDescription={validateTextareaDescription}
      />
      {review || editAccordanceData || accordanceCreateRevision ? null : (
        <div className="container-multi-accordances-button">
          <button className="multi-accordances-link" onClick={setMultipleAccordances}>
            <div className="multi-accordances-buttton"></div>
            {t('save_and_add_to_bundle')}
          </button>
        </div>
      )}
      <NewAccordanceFormButtons
        submit={() => submit(false)}
        disableDraftSubmit={disableDraftButton}
        submitting={submitting}
        accordanceType={accordanceFormType}
        saveAsDraft={submitAccordanceDraft}
        currentUserCanApprove={currentUserCanApprove}
        cancel={props.close}
        editAccordanceData={editAccordanceData}
        publish={() => submit(true)}
        closeModalEditReviewer={closeModalEditReviewer}
        accordanceCreateRevision={accordanceCreateRevision}
      />
    </div>
  );
};

export default NewAccordanceForm;
