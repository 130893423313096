import { SET_PROJECT_REPORT_SECTIONS, RESOLVE_PROJECT_REPORT_SECTIONS } from './types';
import { KeyValueBoolean } from '../../models/KeyValueBoolean';

export function setProjectReportSections(value: KeyValueBoolean[]) {
  return {
    type: SET_PROJECT_REPORT_SECTIONS,
    payload: value,
  };
}

export function resolveProjectReportSections(value: string) {
  return {
    type: RESOLVE_PROJECT_REPORT_SECTIONS,
    payload: value,
  };
}
