import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import userReducer from './user';
import currentProjectReducer from './currentProject';
import filterReducer from './filter';
import tabValueReducer from './tabValue';
import requestStatusReducer from './requestStatus';
import followedProjectsReducer from './followedProjects';
import allProjectsReducer from './allProjects';
import indicatorsReducer from './indicators';
import calendarReducer from './calendar';
import filterIndicatorsReducer from './filterIndicators';
import projectTabValueReducer from './projectTabValue';
import notificationsReducer, { notificationsTotalPagesReducer } from './notifications';
import regionsReducer from './regions';
import currentIndicatorReducer from './currentIndicator';
import divisionsReducer from './divisions';
import allIndicatorsReducer from './allIndicators';
import indicatorProjectsReducer from './indicatorProjects';
import indicatorAccordanceReducer from './indicatorAccordances';
import permissionsModalReducer from './permissionsModal';
import projectReportSectionsReducer from './projectReportSections';
import successMessageReducer from './succesMessage';
import helpReducer from './help';
import convergenceModalReducer from './convergenceModal';
import sectorsReducer from './sectors';
import reviewProjectsSelectedRowsReducer from './reviewProjectsSelectedRows';
import reviewProjectsSelectedFiltersReducer from './reviewProjectsSelectedFilters';
import filterManagerialReviewsReducer from './filterManagerialReviews';
import reviewShowSelectProjectsReducer from './reviewShowSelectProjects';
import currentReviewReducer from './currentReview';
import agendaSelectedByDefaultReducer from './agendaSelectedByDefault';
import filterAnalyticsReducer from './filterAnalytics';
import textSearchReducer from './textSearch';
import reviewNavigationReducer from './reviewNavigation';
import menuTabsReducer from './menuTabs';
import interestIssuesReducer from './InterestIssues';
import indicatorDetailFilterReducer from './indicatorDetailFilters';
import filterEarlyWarningsReducer from './filterEarlyWarnings';
import earlyWarningsProjectsReducer from './earlyWarningsProjects';
import reportSectionTabValueReducer from './reportSectionTabValue';
import dataTableReducer from './dataTable';
import indicatorPayloadReducer from './indicatorPayload';
import reviewsDelegateReducer from './reviewsDelegate';
import userIndicatorCollectionReducer from './userIndicatorCollection';

export default (history: any) =>
  combineReducers({
    filterEarlyWarnings: filterEarlyWarningsReducer,
    earlyWarningsProjects: earlyWarningsProjectsReducer,
    router: connectRouter(history),
    user: userReducer,
    currentProject: currentProjectReducer,
    tabValue: tabValueReducer,
    requestStatus: requestStatusReducer,
    filter: filterReducer,
    followedProjects: followedProjectsReducer,
    allProjects: allProjectsReducer,
    indicators: indicatorsReducer,
    currentIndicator: currentIndicatorReducer,
    calendar: calendarReducer,
    filterIndicators: filterIndicatorsReducer,
    projectTabValue: projectTabValueReducer,
    notifications: notificationsReducer,
    notificationsPages: notificationsTotalPagesReducer,
    regions: regionsReducer,
    sectors: sectorsReducer,
    divisions: divisionsReducer,
    allIndicators: allIndicatorsReducer,
    indicatorProjects: indicatorProjectsReducer,
    indicatorAccordances: indicatorAccordanceReducer,
    permissionsModal: permissionsModalReducer,
    projectReportSections: projectReportSectionsReducer,
    succesMessage: successMessageReducer,
    help: helpReducer,
    convergenceModal: convergenceModalReducer,
    reviewProjectsSelectedRows: reviewProjectsSelectedRowsReducer,
    reviewProjectsSelectedFilters: reviewProjectsSelectedFiltersReducer,
    textSearch: textSearchReducer,
    filterManagerialSection: filterManagerialReviewsReducer,
    reviewShowSelectProjects: reviewShowSelectProjectsReducer,
    currentReview: currentReviewReducer,
    agendaIsSelected: agendaSelectedByDefaultReducer,
    filterAnalytics: filterAnalyticsReducer,
    reviewNavigation: reviewNavigationReducer,
    menuTabs: menuTabsReducer,
    interestIssues: interestIssuesReducer,
    indicatorDetailFilter: indicatorDetailFilterReducer,
    reportSectionTabValue: reportSectionTabValueReducer,
    dataTableInfo: dataTableReducer,
    indicatorPayload: indicatorPayloadReducer,
    reviewsDelegate: reviewsDelegateReducer,
    userIndicatorCollection: userIndicatorCollectionReducer
  });
