import React, { useEffect, useState } from 'react';
import styles from './ProductCard.module.scss';
import { useTranslation } from 'react-i18next';
import Dotdotdot from 'react-dotdotdot';
import NumberFormat from 'react-number-format';
import { ProductFileState, ProductInformation, ProductItem } from '../../../../../models/ProductInformation';
import ModalProjectVerificationFiles from '../modalProjectVerificationFiles/modalProjectVerificationFiles';
import { getProductFileStateData } from '../../../../../api/productApi';

type Props = {
  product: ProductInformation;
  productItem: ProductItem;
};

const ProductCard = ({ product, productItem }: Props) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [productFileState, setProductFileState] = useState<ProductFileState[]>([]);
  const { t } = useTranslation();
  const {
    description,
    name,
    projectId,
    id,
    yearlyFinancialGoal,
    yearlyPhysicalGoal,
    yearlyFinancialPlan,
    yearlyPhysicalPlan,
  } = product;
  const {
    cardContainer,
    content,
    multipleColumn,
    descriptionContainer,
    descriptionStyle,
    typesContainer,
    type,
    divider,
    singleColumn,
    data,
    column,
    urlContainer,
    urlStyle,
    urlNoDataStyle,
  } = styles;
  const setDataVerificationFile = async id => {
    const response = await getProductFileStateData(id);
    setProductFileState(response);
  };
  useEffect(() => {
    setDataVerificationFile(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={cardContainer}>
      <div className={content}>
        <div className={multipleColumn}>
          <div className={descriptionContainer}>
            <div className={descriptionStyle} title={description ? description : name}>
              <Dotdotdot clamp={4}>{description ? description : name}</Dotdotdot>
            </div>
          </div>
          <div className={typesContainer}>
            <span className={type}>P(a)</span>
            <div className={divider}></div>
            <span className={type}>A</span>
          </div>
        </div>
        <div className={singleColumn}>
          <span className={data}>{productItem.physicalPlanned}</span>
          <div className={divider}></div>
          <span className={data}>{productItem.physicalActual}</span>
        </div>
        <div className={singleColumn}>
          <span className={data}>{yearlyPhysicalPlan}</span>
          <div className={divider}></div>
          <span className={data}>{yearlyPhysicalGoal}</span>
        </div>
        <div className={singleColumn}>
          <div className={data}>
            <NumberFormat
              value={productItem.financialPlanned}
              displayType={'text'}
              thousandSeparator={'.'}
              decimalSeparator={','}
              prefix={'USD '}
            />
          </div>
          <div className={divider}></div>
          <div className={data}>
            <NumberFormat
              value={productItem.financialActual}
              displayType={'text'}
              thousandSeparator={'.'}
              decimalSeparator={','}
              prefix={'USD '}
            />
          </div>
        </div>
        <div className={multipleColumn}>
          <div className={column}>
            <div className={data}>
              <NumberFormat
                value={yearlyFinancialPlan}
                displayType={'text'}
                thousandSeparator={'.'}
                decimalSeparator={','}
                prefix={'USD '}
              />
            </div>
            <div className={divider}></div>
            <div className={data}>
              <NumberFormat
                value={yearlyFinancialGoal}
                displayType={'text'}
                thousandSeparator={'.'}
                decimalSeparator={','}
                prefix={'USD '}
              />
            </div>
          </div>
          {productFileState?.length !== 0 ? (
            <div className={urlContainer}>
              <div className={urlStyle} onClick={() => setIsOpen(true)}>
                {t('see_means_of_verification')}
              </div>
            </div>
          ) : (
            <div className={urlContainer}>
              <div className={urlNoDataStyle}>{t('see_means_of_verification_no_data')}</div>
            </div>
          )}
          <ModalProjectVerificationFiles
            t={t}
            isOpen={isOpen}
            closeModal={() => setIsOpen(false)}
            productFileState={productFileState}
            projectId={projectId || 0}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
