import React from 'react';
import styles from '../SupervisionDetailForm.module.scss';
import CloseCrossButton from '../../../../../Util/Buttons/CloseCrossButton/CloseCrossButton';
import Dotdotdot from 'react-dotdotdot';
import { SupervisionPlanItemType } from '../../../../../ProjectDetail/ProjectTabs/Supervision/util';
import { useTranslation } from 'react-i18next';
import { SupervisionPlanItemState } from '../../../Supervision/util';
import { ReactComponent as EditIcon } from '../../../../../../assets/edit_pencil_icon.svg';

type Props = {
  handleCloseModal: Function;
  activityDescription: string;
  activityType: number;
  activityState: number;
  isEdit: boolean;
  setIsEdit: Function;
  canEditForm: boolean;
};

const SupervisionModalHeader = ({
  handleCloseModal,
  activityDescription,
  activityType,
  activityState,
  isEdit,
  setIsEdit,
  canEditForm,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.closeCrossContainer}>
        <CloseCrossButton handleClick={() => handleCloseModal()} />
      </div>
      <div className={styles.headerContainer} title={activityDescription}>
        <Dotdotdot clamp={3} className={styles.modalTitle}>
          {activityDescription}
        </Dotdotdot>
        <div
          className={
            activityType === SupervisionPlanItemType.CriticalProduct
              ? styles.headerLabelCritical
              : styles.headerLabelActivity
          }
        >
          {t(`plan_item_type_${activityType}`)}
        </div>
      </div>

      {canEditForm && (
        <div className={styles.editContainer}>
          {activityState !== SupervisionPlanItemState.Completed &&
            activityState !== SupervisionPlanItemState.Cancelled && (
              <div className={!isEdit ? styles.editControls : styles.editing} onClick={() => setIsEdit(true)}>
                <span>{!isEdit ? t('edit') : `${t('editing')}...`}</span>
                {!isEdit && <EditIcon />}
              </div>
            )}
        </div>
      )}
    </>
  );
};

export default SupervisionModalHeader;
