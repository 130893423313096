/**
 * Transforms the input data into a structured format.
 * @param response - The raw data input to be transformed.
 * @returns Transformed data with specific properties and aggregations.
 */
export const transformData = response => {
  // Finds and retrieves the metrics for a specific indicator from the data.
  const filteredProjectsSummaryPortfolioIndicator = response.indicatorsData.find(item => item.key === "PROJECTS_SUMMARY_PORTFOLIO").metrics;
  const resultMap = new Map();

  filteredProjectsSummaryPortfolioIndicator.forEach(item => {
    if (resultMap.has(item.groupingValue)) {
      const existingItem = resultMap.get(item.groupingValue);
      if (existingItem) {
        existingItem[item.metricName] = (existingItem[item.metricName] || 0) + item.metricValue;
      }
    } else {
      resultMap.set(item.groupingValue, {
        LABEL: item.groupingValue,
        [item.metricName]: item.metricValue,
      });
    }
  });

  const rawData = Array.from(resultMap.values());

  const transformedData = rawData.map(item => ({
    label: item.LABEL,
    projectCount: item.PROJECT_COUNT,
    operations: item.AMOUNT,
    ageAverage: item.AGE_AVERAGE,
    currentApprovedAmount: item.APPROVED_AMOUNT,
    disbursementAmount: item.DISBURSEMENT_AMOUNT,
    disbursementPercentage: item.DISBURSEMENT_PERCENT || item.DISBURSEMENT_PERCENTAGE,
    originalApprovedAmount: item.ORIGINAL_AMOUNT,
    pendingDisbursementAmount: item.PENDING_DISBURSEMENT_AMOUNT,
  }));

  //* Filters the combined data to keep only the specified labels.
  // const combineData = combineIgrObjects(transformedData);

  return filteredData(transformedData);
};

/**
 * Combines data objects for 'IGR_GREATER' and 'IGR_LOWER' labels into a single 'IGR_BOTH' label.
 * @param transformedData - The array of transformed data objects.
 * @returns The transformed data array with combined 'IGR_BOTH' objects.
 */
const combineIgrObjects = transformedData => {
  const igrGreater = transformedData.find(item => item.label === 'IGR_GREATER');
  const igrLower = transformedData.find(item => item.label === 'IGR_LOWER');
  const igr = transformedData.find(item => item.label === 'IGR');

  if (igrGreater && igrLower) {
    const totalDisbursementAmount = igrGreater.disbursementAmount + igrLower.disbursementAmount;
    const igrBoth = {
      label: 'IGR_BOTH',
      projectCount: igrGreater.projectCount + igrLower.projectCount,
      operations: igr.operations,
      ageAverage: igr.ageAverage,
      currentApprovedAmount: igrGreater.currentApprovedAmount + igrLower.currentApprovedAmount,
      disbursementAmount: totalDisbursementAmount,
      disbursementPercentage:
        totalDisbursementAmount === 0
          ? 0
          : (igrGreater.disbursementPercentage * igrGreater.disbursementAmount +
            igrLower.disbursementPercentage * igrLower.disbursementAmount) /
          totalDisbursementAmount,
      originalApprovedAmount: igrGreater.originalApprovedAmount + igrLower.originalApprovedAmount,
      pendingDisbursementAmount: igrGreater.pendingDisbursementAmount + igrLower.pendingDisbursementAmount,
    };

    transformedData.push(igrBoth);
  }
  return transformedData;
};

/**
 * Filters the data to include only objects with specific labels.
 * @param data - The array of data objects to be filtered.
 * @returns An array of data objects filtered by specified labels.
 */
const labelsToKeep = ['LON', 'TCP', 'TCP_NAT', 'IGR', 'GUA'];
const filteredData = data => data.filter(item => labelsToKeep.includes(item.label));

/**
 * Checks if the given input is a floating-point number.
 * @param n - The input to be checked.
 * @returns `true` if the input is a floating-point number, otherwise `false`.
 */
export const isFloat = n => Number(n) === n && n % 1 !== 0;

/**
 * Sorts objects by their label property based on a predefined order.
 * @param {Object} a An object with a label property.
 * @param {Object} b An object with a label property.
 * @returns {number} Returns a negative, zero, or positive value to indicate the sorting order.
 */
const order = { LON: 1, TCP: 2, TCP_NAT: 2, IGR: 3 };
export function sortByLabel(a, b) {
  return order[a.label] - order[b.label];
}