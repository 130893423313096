import React from 'react';
import './ReportsSection.scss';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import { TabPanel } from '../../Projects/ProjectsAll/ProjectAllUtil';
import { SearchRender } from '../../Util/CustomInput/SearchRender/SearchRender';
import { PortfolioSection } from './PortfolioSection/PortfolioSection';
import { ProjectSection } from './ProjectsSection/ProjectSection';
import { ReviewSection } from './ReviewSection/ReviewSection';
import { SelectFilters } from './SelectFilters/SelectFilters';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { MultiTabsComponent } from '../../Util/MultiTabsComponent/MultiTabsComponent';
import { AccordancesSection } from './AccordancesSection/AccordancesSection';
import { AlertSection } from './AlertSection/AlertSection';

const tabsTexts = ['portfolio', 'projects', 'revision', 'accordances', 'alerts'];

export const ReportsSection = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { reportSectionTabValue } = useSelector<any, any>(state => state);
  const [tabValue, setTabValue] = React.useState(tabsTexts[reportSectionTabValue]);
  const [optionMonth, setOptionMonth] = React.useState(getLongMonthOptions());
  const [optionYears, setOptionYears] = React.useState([]);
  const [yearFilter, setYearFilter] = React.useState(0);
  const [monthFilter, setMonthFilter] = React.useState(0);
  const [searchValue, setSearchValue] = React.useState('');

  const handleSetMenuValue = menuValue => {
    setOptionMonth(getLongMonthOptions());
    setYearFilter(0);
    setMonthFilter(0);
    setTabValue(menuValue);
  };

  const onChangeHandler = (event: any) => {
    setSearchValue(event.target.value);
  };

  const handleReports = yearsFromReports => {
    setOptionYears(yearsFromReports);
  };

  const handleFilterValue = (value, filterType) => {
    filterType === 'Y' ? setYearFilter(parseInt(value)) : setMonthFilter(parseInt(value));
  };

  return (
    <div className="reports-section-container">
      <div className="reports-section-container-options">
        <div className="title-reports-section-projects">{t('menu_reports')}</div>
        <div className="reports-section-tabs">
          <MultiTabsComponent tabValue={tabValue} handleSetMenuValue={handleSetMenuValue} tabsTexts={tabsTexts} />
        </div>
        {tabValue === 'projects' && (
          <div className="reports-section-search-input">
            <SearchRender placeholder="search_reports" onChangeHandler={onChangeHandler} />
          </div>
        )}
      </div>

      <div className="reports-section-filters">
        {showMonthYearFilter(tabValue) ? (
          <>
            <SelectFilters
              labelValue={t('month')}
              options={optionMonth}
              filterValue={handleFilterValue}
              filterType={'M'}
            />
            <SelectFilters
              labelValue={t('year')}
              options={optionYears}
              filterValue={handleFilterValue}
              filterType={'Y'}
            />
          </>
        ) : null}
      </div>
      <TabPanel value={tabValue} index={'portfolio'} dir={theme.direction}>
        <PortfolioSection sendReports={handleReports} filters={{ monthFilter, yearFilter }} />
      </TabPanel>
      <TabPanel value={tabValue} index={'projects'} dir={theme.direction}>
        <ProjectSection sendReports={handleReports} filters={{ monthFilter, yearFilter, searchValue }} />
      </TabPanel>
      <TabPanel value={tabValue} index={'revision'} dir={theme.direction}>
        <ReviewSection sendReports={handleReports} filters={{ monthFilter, yearFilter, searchValue }} />
      </TabPanel>
      <TabPanel value={tabValue} index={'accordances'} dir={theme.direction}>
        <AccordancesSection />
      </TabPanel>
      <TabPanel value={tabValue} index={'alerts'} dir={theme.direction}>
        <AlertSection />
      </TabPanel>
    </div>
  );
};

const getLongMonthOptions = () => {
  const longMonths = moment.months();
  const returnedOptions: any[] = [];
  longMonths.forEach((month, ind) => returnedOptions.push({ value: ind + 1, optionLabel: month }));
  return returnedOptions;
};

export const haveAppliedFilters = (filters, projects) => {
  if (filters.monthFilter !== 0 || filters.yearFilter !== 0) {
    if (filters.monthFilter !== 0 && filters.yearFilter === 0) {
      return projects.filter(project => {
        return moment(project.dateGenerated).month() + 1 === parseInt(filters.monthFilter);
      });
    } else if (filters.monthFilter === 0 && filters.yearFilter !== 0) {
      return projects.filter(project => {
        return moment(project.dateGenerated).year() === parseInt(filters.yearFilter);
      });
    } else if (filters.monthFilter !== 0 && filters.yearFilter !== 0) {
      return projects.filter(project => {
        return (
          moment(project.dateGenerated).year() === parseInt(filters.yearFilter) &&
          moment(project.dateGenerated).month() + 1 === parseInt(filters.monthFilter)
        );
      });
    }
  }
  return projects;
};

const showMonthYearFilter = tabValue => {
  return tabValue !== 'accordances' && tabValue !== 'alerts';
};
