import React from 'react';
import './NumberInput.scss';
import { DebounceInput } from 'react-debounce-input';
import { checkCharacterNumber } from '../../../../services/util';
import { allowedCharacterCode } from '../../../../services/booleanUtil';

interface IProps extends React.HTMLProps<JSX.Element> {
  placeholderText: string;
  inputWidth?: object;
  value: any;
  minParam: boolean;
  defaultValue: number;
  min?: string;
}
type Props = IProps;

export const NumberInput = ({ placeholderText, inputWidth, value, minParam, defaultValue, min }: Props) => {
  const handleChange = event => {
    value(minParam, event.target.value);
  };
  const handleKeypress = e => {
    const characterCode = e.key;
    if (allowedCharacterCode(characterCode)) {
      const characterNumber = Number(characterCode);
      checkCharacterNumber(characterNumber, e);
    } else {
      return;
    }
  };
  return (
    <div className="number-input-container">
      <DebounceInput
        style={inputWidth || {}}
        placeholder={placeholderText}
        className="input"
        type="number"
        min={min ? min : '0'}
        value={defaultValue}
        debounceTimeout={300}
        onKeyDown={event => handleKeypress(event)}
        onChange={event => handleChange(event)}
      />
    </div>
  );
};
export default NumberInput;
