import React, { useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';

export default function CompositionPlan({ compositionData }) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState({
    'panel-0': true,
    'panel-0-sub-0': true,
    'panel-0-sub-0-sub-0': true,
    'panel-0-sub-0-sub-1': true,
    'panel-0-sub-1': true,
  });

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(prevExpanded => ({
      ...prevExpanded,
      [panel]: isExpanded,
    }));
  };

  const data = React.useMemo(
    () => [
      {
        title: 'last_approved_bid_amount_current',
        amount: compositionData.currentApprovedAmountBID,
        subSections: [
          {
            title_h3: 'last_procurement_plan_amount',
            amount: compositionData.procurementPlanAmountBID,
            subSections: [
              {
                title_h5: 'last_contracted_processes',
                amount: compositionData.contractedProcessesBID,
                details: [
                  { text: 'last_contracts_in_execution', amount: compositionData.contractUndeExecutionBID },
                  { text: 'last_terminated_contracts', amount: compositionData.contractFinishedBID },
                ],
              },
              {
                title_h5: 'last_pending_processes',
                amount: compositionData.pendingProcessBID,
                details: [
                  { text: 'supervision_plan_state_1', amount: compositionData.processOngoingBID },
                  { text: 'new_provided', amount: compositionData.expectedBID },
                ],
              },
            ],
          },
          {
            title_h3: 'last_unallocated_amount_in_the_procurement_plan',
            amount: compositionData.unallocatedAmountProcurementPlanBID,
          },
        ],
      },
      {
        title: 'last_local_contribution_amount',
        amount: compositionData.currentApprovedAmountCounterPartLocal,
        subSections: [
          {
            title_h3: 'last_procurement_plan_amount',
            amount: compositionData.procurementPlanAmountCounterPartLocal,
            subSections: [
              {
                title_h5: 'last_contracted_processes',
                amount: compositionData.contractedProcessesCounterPartLocal,
                details: [
                  {
                    text: 'last_contracts_in_execution',
                    amount: compositionData.contractUndeExecutionCounterPartLocal,
                  },
                  { text: 'last_terminated_contracts', amount: compositionData.contractFinishedCounterPartLocal },
                ],
              },
              {
                title_h5: 'last_pending_processes',
                amount: compositionData.pendingProcessCounterPartLocal,
                details: [
                  { text: 'supervision_plan_state_1', amount: compositionData.processOngoingCounterPartLocal },
                  { text: 'new_provided', amount: compositionData.expectedCounterPartLocal },
                ],
              },
            ],
          },
          {
            title_h3: 'last_unallocated_amount_in_the_procurement_plan',
            amount: compositionData.unallocatedAmountProcurementPlanCounterPartLocal,
          },
        ],
      },
      {
        title: 'last_cofinancing_amount',
        amount: compositionData.currentApprovedAmountCofinancing,
        subSections: [
          {
            title_h3: 'last_procurement_plan_amount',
            amount: compositionData.procurementPlanAmountCofinancing,
            subSections: [
              {
                title_h5: 'last_contracted_processes',
                amount: compositionData.contractedProcessesCofinancing,
                details: [
                  { text: 'last_contracts_in_execution', amount: compositionData.contractUndeExecutionCofinancing },
                  { text: 'last_terminated_contracts', amount: compositionData.contractFinishedCofinancing },
                ],
              },
              {
                title_h5: 'last_pending_processes',
                amount: compositionData.pendingProcessCofinancing,
                details: [
                  { text: 'supervision_plan_state_1', amount: compositionData.processOngoingCofinancing },
                  { text: 'new_provided', amount: compositionData.expectedCofinancing },
                ],
              },
            ],
          },
          {
            title_h3: 'last_unallocated_amount_in_the_procurement_plan',
            amount: compositionData.unallocatedAmountProcurementPlanCofinancing,
          },
        ],
      },
    ],
    [compositionData],
  );

  const renderDetails = (details, parentKey) =>
    details
      ? details.map((detail, index) => (
          <div key={`${parentKey}-detail-${index}`} className="acordion-details flex border">
            <p>{t(detail.text) || t(detail.title_text)}</p>
            <p className="pl-25">
              <NumberFormat
                value={detail.amount}
                displayType={'text'}
                thousandSeparator={','}
                decimalSeparator={'.'}
                decimalScale={2}
              />
            </p>
          </div>
        ))
      : null;

  const renderSubSections = (subSections, parentKey) =>
    subSections.map((subSection, index) => {
      const subKey = `${parentKey}-sub-${index}`;
      const isTitleH5 = !!subSection.title_h5;
      const hasContent = subSection.subSections || subSection.details;

      return (
        <div key={subKey}>
          <Accordion expanded={expanded[subKey] || false} onChange={handleChange(subKey)}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className={!hasContent ? 'no-content-icon' : ''} />}
              className="subtitle-acordion border"
            >
              <div className="flex">
                <h5 className={isTitleH5 ? 'subtitle-tex-6' : ''}>
                  {t(subSection.title_h5) || t(subSection.title_h3)}
                </h5>
                <h5 className={isTitleH5 ? 'subtitle-tex-6' : ''}>
                  <NumberFormat
                    value={subSection.amount}
                    displayType={'text'}
                    thousandSeparator={','}
                    decimalSeparator={'.'}
                    decimalScale={2}
                  />
                </h5>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              {subSection.subSections
                ? renderSubSections(subSection.subSections, subKey)
                : renderDetails(subSection.details, subKey)}
            </AccordionDetails>
          </Accordion>
        </div>
      );
    });

  const renderAccordions = sections =>
    sections.map((section, index) => {
      const key = `panel-${index}`;
      const hasContent = section.subSections;
      return (
        <Accordion key={key} expanded={expanded[key] || false} onChange={handleChange(key)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={!hasContent ? 'no-content-icon' : ''} />}
            className="title-acordion border"
          >
            <div className="flex">
              <h3>{t(section.title)}</h3>
              <h3>
                <NumberFormat
                  value={section.amount}
                  displayType={'text'}
                  thousandSeparator={','}
                  decimalSeparator={'.'}
                  decimalScale={2}
                />
              </h3>
            </div>
          </AccordionSummary>
          <AccordionDetails>{renderSubSections(section.subSections, key)}</AccordionDetails>
        </Accordion>
      );
    });

  return (
    <div className="composition">
      <div className="header-title">
        <h2>{t('last_composition_procurement_plan')}</h2>
        <p>{t('last_total_amounts_according_status')}</p>
      </div>
      <div className="accordion">{renderAccordions(data)}</div>
    </div>
  );
}
