import { lowerCase, filter, some } from 'lodash';
import { validateMicrosoftStreamVideoShareLink } from '../../../../services/booleanUtil';
import { languagesKeys } from '../../ContentManagerKeys';

interface VideoUrlAttachment {
  id: number;
  url: string;
  name?: string;
}

const defaultVideoUrlAttachment: VideoUrlAttachment = {
  id: 0,
  url: '',
  name: '',
};
export const tutorialCommonFields = {
  title: '',
  isChecked: false,
  attachments: [],
  titleError: false,
  attachment: defaultVideoUrlAttachment,
  videoUrlError: false,
  description: '',
  descriptionError: false,
};

export const defaultFormFields = [
  {
    ...tutorialCommonFields,
    tutorialLanguageId: languagesKeys.spanish,
  },
  {
    ...tutorialCommonFields,
    tutorialLanguageId: languagesKeys.english,
  },
  {
    ...tutorialCommonFields,
    tutorialLanguageId: languagesKeys.portuguese,
  },
  {
    ...tutorialCommonFields,
    tutorialLanguageId: languagesKeys.french,
  },
];

export const mapTranslationsToTutorials = (tutorialItem, languagesData, findLanguage) =>
  tutorialItem.translations.map(tutorialItem => {
    return {
      ...tutorialItem,
      tutorialLanguageId: lowerCase(findLanguage(languagesData, tutorialItem).name),
      isChecked: tutorialItem.title !== '' && tutorialItem.attachment && tutorialItem.attachment.url !== '',
    };
  });

interface NewAttachment {
  id: number;
  name: string;
  base64Url: string;
  url?: string;
}
export const getPostTutorialItem = (category, formFields, findLanguageByValue, languagesData) => ({
  category: category.id,
  translations: formFields.map(translation => {
    const { title, tutorialLanguageId, attachment, description } = translation;

    let newAttachment: NewAttachment = { id: 0, name: '', base64Url: '' };
    if (attachment) {
      const base64Url = urlToBase64(attachment.url);
      newAttachment = { ...attachment, base64Url };
      delete newAttachment.url;
    }

    return {
      title,
      description,
      attachment: attachment ? newAttachment : null,
      languageId: findLanguageByValue(languagesData, tutorialLanguageId).id,
    };
  }),
});

const urlToBase64 = (url: string) => {
  return url && url.length ? btoa(url) : null;
};

export const addAttachment = (formFields, tutorialLanguageId, attachment, field = 'languageId') =>
  formFields.map(tutorialItem =>
    tutorialItem[field] === languagesKeys[tutorialLanguageId]
      ? { ...tutorialItem, attachments: [...tutorialItem.attachments, attachment] }
      : tutorialItem,
  );

export const removeAttachments = (formFields, tutorialLanguageId, attachmentUrl, field = 'languageId') =>
  formFields.map(tutorialItem =>
    tutorialItem[field] === languagesKeys[tutorialLanguageId]
      ? {
          ...tutorialItem,
          attachments: filter(tutorialItem.attachments, attachment => attachment.url !== attachmentUrl),
        }
      : tutorialItem,
  );

const updateAttachmetObject = (type, value, item) => {
  if (type === 'attachment') {
    return { ...item.attachment, url: value };
  } else {
    return value;
  }
};
export const getOnChangeUpdatedFormFields = (formFields, type, value, language) =>
  formFields.map(tutorialItem => {
    return tutorialItem.tutorialLanguageId === languagesKeys[language]
      ? {
          ...tutorialItem,
          [type]: updateAttachmetObject(type, value, tutorialItem),
          isChecked: value !== '',
          videoUrlError: !validateMicrosoftStreamVideoShareLink(value),
        }
      : tutorialItem;
  });

export const getButtonTitle = isEdit =>
  isEdit ? 'contents_helpCenter_tutorial_edit_button_label' : 'contents_helpCenter_tutorial_add_button_label';

export const hasCompletedTutorial = formFields => some(formFields, tutorialItem => tutorialItem.title.trim() !== '');

export const getDisabledClass = formFields => !hasCompletedTutorial(formFields) && 'disabled';
