import { AnalyticsKeyEnum } from '../../../../models/AnalyticsKeyEnum';
import { getTextIndicator } from '../../../Portfolio/IndicatorsHelpers/reusableIndicators';
import { setTurnOffIndicator } from '../analyticsHelper';

const addAggregateIndicatorsLogins = (analyticList, analyticIndicators) => {
  const aggregateIndicatorsLogins = getTextIndicator(
    analyticList,
    AnalyticsKeyEnum.AGGREGATE_INDICATORS_LOGINS,
    'total_of',
    'beginnings_of session',
    'beginnings_of session',
    true,
  );

  if (aggregateIndicatorsLogins) {
    analyticIndicators.push(aggregateIndicatorsLogins);
  }
};

const addAggregateIndicatorsUsersWhoEnteredTheTool = (analyticList, analyticIndicators) => {
  const aggregateIndicatorsUsersWhoEnteredTheTool = getTextIndicator(
    analyticList,
    AnalyticsKeyEnum.AGGREGATE_INDICATORS_USERS_WHO_ENTERED_THE_TOOL,
    'total_of',
    'users_who_entered_the_tool',
    'users_who_entered_the_tool',
    true,
  );

  if (aggregateIndicatorsUsersWhoEnteredTheTool) {
    analyticIndicators.push(aggregateIndicatorsUsersWhoEnteredTheTool);
  }
};

const addInquiriesToSupportAboutDataErrors = (analyticList, analyticIndicators) => {
  const inquiriesToSupportAboutDataErros = getTextIndicator(
    analyticList,
    AnalyticsKeyEnum.SUPPORT_INDICATORS_INQUIRIES_TO_SUPPORT_ABOUT_DATA_ERRORS,
    'inquiries_to_support_about',
    'data_errors',
    'inquiries_to_support_about data_errors',
    true,
  );

  if (inquiriesToSupportAboutDataErros) {
    analyticIndicators.push(inquiriesToSupportAboutDataErros);
  }
};

const addRequestCustomizationByCountry = (analyticList, analyticIndicators, turnOff = false) => {
  if (turnOff) {
    setTurnOffIndicator(
      analyticIndicators,
      AnalyticsKeyEnum.SUPPORT_INDICATORS_REQUESTS_CUSTOMIZATION_BY_COUNTRY,
      turnOff,
    );
  }
  const requestCustomizationByCountry = getTextIndicator(
    analyticList,
    AnalyticsKeyEnum.SUPPORT_INDICATORS_REQUESTS_CUSTOMIZATION_BY_COUNTRY,
    'orders_of',
    'customizations_by_country',
    'orders_of customizations_by_country',
    true,
  );

  if (requestCustomizationByCountry) {
    analyticIndicators.push(requestCustomizationByCountry);
  }
};

const addSatisfactionSurveyWithinTheOnlineHelp = (analyticList, analyticIndicators, turnOff = false) => {
  if (turnOff) {
    setTurnOffIndicator(
      analyticIndicators,
      AnalyticsKeyEnum.SUPPORT_INDICATORS_SATISFACTION_SURVEY_WITHIN_THE_ONLINE_HELP,
      turnOff,
    );
  }
  const satisfactionSurveyWithinTheOnlineHelp = getTextIndicator(
    analyticList,
    AnalyticsKeyEnum.SUPPORT_INDICATORS_SATISFACTION_SURVEY_WITHIN_THE_ONLINE_HELP,
    'satisfaction_survey',
    'within_the_online_help',
    'satisfaction_survey within_the_online_help',
    true,
  );

  if (satisfactionSurveyWithinTheOnlineHelp) {
    analyticIndicators.push(satisfactionSurveyWithinTheOnlineHelp);
  }
};

export {
  addAggregateIndicatorsLogins,
  addInquiriesToSupportAboutDataErrors,
  addRequestCustomizationByCountry,
  addSatisfactionSurveyWithinTheOnlineHelp,
  addAggregateIndicatorsUsersWhoEnteredTheTool,
};
