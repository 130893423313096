import React from 'react';
import { isMobile } from '../../../services/booleanUtil';
import Skeleton from '@material-ui/lab/Skeleton';
import './CardAnalyticSkeleton.scss';

export const CardAnalyticSkeletonNumber = () => {
  return (
    <div className="card-analytic-skeleton-number">
      <div className="number">
        <Skeleton animation="wave" variant="circle" width={isMobile() ? 27 : 58} height={isMobile() ? 27 : 58} />
      </div>
      <div className="horizontal">
        <div className="horizontal-inner">
          <Skeleton
            animation="wave"
            variant="text"
            width={isMobile() ? 48 : 103}
            height={isMobile() ? 7 : 15}
            style={{ marginTop: isMobile() ? 11 : 25 }}
          />
        </div>
      </div>
    </div>
  );
};
