import { makeStyles, Tab, Tabs } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { tabStyles } from '../../../Projects/ProjectsAll/ProjectsAll.Style';

export enum TabsFiltersEnum {
  EXPIRED,
  NO_EXPIRED,
}

type Props = {
  filterBy: TabsFiltersEnum;
  handleFilterApplied: Function;
};
export const TabsFilter = ({ filterBy, handleFilterApplied }: Props) => {
  const { t } = useTranslation();
  const classes = makeStyles(tabStyles)();
  const tabClasses = { root: classes.root, selected: classes.selected };
  return (
    <div>
      <Tabs
        value={filterBy}
        classes={{ indicator: classes.indicatorColor }}
        variant="standard"
        aria-label="full width tabs example"
      >
        <Tab
          key={TabsFiltersEnum.EXPIRED}
          value={TabsFiltersEnum.EXPIRED}
          classes={tabClasses}
          label={t('expired')}
          onClick={() => handleFilterApplied(TabsFiltersEnum.EXPIRED)}
        />
        <Tab
          key={TabsFiltersEnum.NO_EXPIRED}
          value={TabsFiltersEnum.NO_EXPIRED}
          classes={tabClasses}
          label={t('about_to_expire')}
          onClick={() => handleFilterApplied(TabsFiltersEnum.NO_EXPIRED)}
        />
      </Tabs>
    </div>
  );
};
