import { useTranslation } from 'react-i18next';
import { AdvanceProps } from '../components/ProjectDetail/Advancev2/Advance';
import { useDispatch } from 'react-redux';
import { setProjectTabValue } from '../redux/actions/projectTabValue';

export const useMapToFinancialAdvance = (financialInformation: any): AdvanceProps => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const measurementUnit = t('dolar_sign');
  const subtitleAmount = financialInformation?.totalDisbursementAmount;
  const completedAmount = financialInformation?.percentRealizedDisbursement;
  const pendingAmount = financialInformation?.percentPendigDisbursementAmount;
  const footerOneAmount = financialInformation?.realizedDisbursementAmount;
  const footerTwoAmount = financialInformation?.pendingDisbursementAmount;

  const handleSeeMoreClick = () => {
    const targetSection = document.getElementById('project-tabs');
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: 'smooth' });
    }
    setTimeout(() => {
      dispatch(setProjectTabValue(1));
    }, 380);
  };

  return {
    title: t(`financial_advance_title`),
    subtitle: `${t('financial_advance_total')} ${subtitleAmount?.toLocaleString()} ${measurementUnit}`,
    completedLabel: t('disbursed'),
    pendingLabel: t('temporary_advance_pending_label'),
    percent: completedAmount,
    completed: `${completedAmount}%`,
    pending: `${pendingAmount}%`,
    // completedOne: `${footerOneAmount.toLocaleString('en')} ${measurementUnit}`,
    completedOne: `${footerOneAmount?.toLocaleString()} ${measurementUnit}`,
    pendingOne: `${footerTwoAmount?.toLocaleString()} ${measurementUnit}`,
    footerOne: '',
    footerTwo: '',
    handleSeeMoreClick: handleSeeMoreClick,
  };
};
