import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import { ReactComponent as IconChevronDown } from '../../../../../assets/icons/u_chevron-down.svg';
import { ReactComponent as IconChevronUp } from '../../../../../assets/icons/u_chevron-up.svg';
import { setBulletColor } from '../../../../../services/util';
import { showIndexTCDI, isMobile } from '../../../../../services/booleanUtil';
import { PmrClassificationComponent } from '../../../../Util/PmrClassification/PmrClassification';
import { PmrPrintValue } from '../../../../Util/PmrPrintValue/PmrPrintValue';
import { TcdiClassificationComponent } from '../../../../Util/TcdiClassification/TcdiClassification';
import { ProjectProductTypeClassification } from '../../../../../models/ProjectProductTypeClassification';
import { setReviewSelectedProject } from '../../../../../redux/actions/reviewProjectsSelectedRows';
import { trackActionClicked } from '../../../../../api/trackActionClickedApi';
import { TrackedActions } from '../../../../../models/TrackedActionsEnum';
import { TableRowExpanded } from './TableRowExpanded';

const BlackTooltip = withStyles({
  tooltip: {
    backgroundColor: '#101319',
    fontSize: '16px',
    fontFamily: 'Rubik',
    lineHeight: '20px',
    padding: '8px 12px',
    borderRadius: '8px',
    transformOrigin: 'center',
  },
  arrow: {
    color: '#101319',
    '&::before': {
      borderWidth: '0.5em 0.5em 0 0.5em',
      borderColor: 'transparent transparent transparent #101319',
    },
    transform: 'translateX(-50%)',
    top: '100%',
  },
})(Tooltip);

const selectableTr = selectable => (selectable ? 'selectable-project-row' : '');
const assignedToMeClassName = assignedToMe =>
  assignedToMe ? 'uppercase-text td-assigned-to-me' : 'uppercase-text project-name';
const selectedStyle = selected => (selected ? { backgroundColor: 'rgba(235, 236, 240, 0.5)' } : {});
const selectSquareClass = isSelected => (isSelected ? 'selected' : '');

export const ProjectsTableAccordanceRow = props => {
  const {
    project,
    selectable,
    canCreateAccordance,
    fromReview,
    revision,
    setLocalRouter,
    setVisibleComponent,
    setProjectId,
  } = props;
  const { projectProductType, projectProductTypeClassification } = project;
  const dispatch = useDispatch();
  const reviewProjectsSelectedRows = useSelector(state => state.reviewProjectsSelectedRows);
  const [isSelected, setIsSelected] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  const setInFollow = id => props.setInFollow(id);

  const handleClick = () => {
    const state = fromReview ? { fromReview: true } : {};
    history.push(`/projectdetail?id=${project.id}`, state);
    trackActionClicked(TrackedActions.projectDetailsFromListClicked);
  };

  const handleFollow = (e, projectId) => {
    e.stopPropagation();
    setInFollow(projectId);
    if (!project.inFollow) {
      trackActionClicked(TrackedActions.followProjectFromListClicked);
    }
  };

  const handleSelected = (e, projectId) => {
    e.stopPropagation();
    dispatch(setReviewSelectedProject(projectId));
  };

  const handleRowClick = () => {
    setIsExpanded(!isExpanded);
  };

  const showManageAgreements = id => {
    setLocalRouter('manageAgreements');
    setVisibleComponent(true);
    setProjectId(id);
  };

  React.useEffect(() => {
    setIsSelected(reviewProjectsSelectedRows.includes(project.id));
  }, [reviewProjectsSelectedRows, project.id]);

  return isMobile() ? (
    <>
      <tr key={project.id} onClick={handleClick}>
        <td
          rowSpan={3}
          className={
            project.assignedToMe ? 'uppercase-text td-assigned-to-me project-code' : 'uppercase-text project-code'
          }
        />
        <td className="no-border project-name">{project.name}</td>
        <td rowSpan={3} className="pmr">
          <span className="pmr-value">
            <PmrPrintValue pmrClassi={project.pmrClassification} pmrValue={project.pmrValue} />
          </span>
          <div className="pmr-flag">
            <PmrClassificationComponent classificationColor={project.pmrClassification} />
            <div className="pmr-title">{t('pmr_initials')}</div>
          </div>
        </td>
      </tr>
      <tr>
        <td className="no-border project-temporary">
          {`${t('temporary_advance')}: `}
          <span className="bold-font">
            {project.temporaryAdvance ? project.temporaryAdvance.percentTemporaryAdvanceMade : '-'}{' '}
            {project.temporaryAdvance ? t('percentage_sign') : ''}
          </span>
        </td>
      </tr>
      <tr>
        <td className="project-financial-information">
          {`${t('amount_disbursed')}: `}
          <span className="bold-font">
            {project.financialInformation.percentRealizedDisbursement}
            {t('percentage_sign')}
          </span>
        </td>
      </tr>
    </>
  ) : (
    <>
      <tr
        key={project.id}
        onClick={handleRowClick}
        className={`${selectableTr(selectable)} ${isExpanded ? 'expanded' : ''}`}
        style={{ cursor: 'pointer' }}
      >
        {selectable && (
          <td onClick={e => handleSelected(e, project.id)}>
            <span className={`select-square ${selectSquareClass(isSelected)}`} />
          </td>
        )}
        <td className={assignedToMeClassName(project.assignedToMe)}>
          <BlackTooltip title={t('go_to_project_info')} placement="bottom" enterDelay={500} leaveDelay={200} arrow>
            <div>
              <span className={`project-type-bullet ${setBulletColor(projectProductType)}`} />
              <Link className="link" to={`/projectdetail?id=${project.id}`}>
                {project.code}
              </Link>
            </div>
          </BlackTooltip>
        </td>
        <td>{project.loanNumber}</td>
        <td className="adjust-project-name">
          <BlackTooltip title={project.name} placement="bottom" enterDelay={500} leaveDelay={200} arrow>
            <span>{project.name}</span>
          </BlackTooltip>
        </td>
        <td className="adjust-projec-division">{project.division.code}</td>
        <td className="project-aligned icons">
          {showIndexTCDI(
            projectProductType,
            projectProductTypeClassification === ProjectProductTypeClassification.IGR_GREATER_THAN_3MM,
          ) ? (
            <>
              <TcdiClassificationComponent clasification={project.tcdiValue || null} />
              <span className="pmr-classification-margin-left">{project.tcdiValue}</span>
            </>
          ) : (
            <>
              <PmrClassificationComponent classificationColor={project.pmrClassification} />
              <span className="pmr-classification-margin-left">
                <PmrPrintValue pmrClassi={project.pmrClassification} pmrValue={project.pmrValue} />
              </span>
            </>
          )}
        </td>
        {canCreateAccordance && (
          <td className="btn-accordance">
            {/* <ReviewAccordance
              project={project}
              revision={revision}
              buttonTextAccordence={t('manage_agreements')}
            /> */}
            <button
              className="btn btn-sm btn-primary"
              onClick={() => showManageAgreements(project.id)}
              // disabled={project.accordanceManagementIsClose}
            >
              Gestionar acuerdos
            </button>
          </td>
        )}
        <td>{isExpanded ? <IconChevronUp /> : <IconChevronDown />}</td>
      </tr>
      {isExpanded && (
        <tr className="content-expand">
          <td colSpan={selectable ? 10 : 9}>
            <div className="expanded-content">
              <TableRowExpanded project={project} setInFollow={setInFollow} canCreateAccordance={canCreateAccordance} />
            </div>
          </td>
        </tr>
      )}
    </>
  );
};
