import { cloneDeep } from 'lodash';
import { IndicatorKeyEnum } from '../../../../models/IndicatorKeyEnum';
import { ProjectProductTypeFilters } from '../../../../models/ProjectProductTypeFilters';
import { buttonsFilterConfig } from '../../CtFilterTab/CTFilterTabConfig';
import { ReportImageDisplay } from './CustomizableReports';
import { getDisbProjLastFiveYearsData, getDisbursementTimeData, getPmrEvolutionLastFiveYears } from './imagesHelperApi';
import { addDisbursementProjectionLast5Years } from '../../../Portfolio/IndicatorsHelpers/mixedchartsIndicatorsHelper';
import { addPortfolioOverviewLast5Years } from '../../../Portfolio/IndicatorsHelpers/barchartsIndicatorsHelper';

export const smallImageIndicators = {
  //DoughnutChart
  DISTRIBUTION_ACCORDING_TO_LATEST_EFAS: true,
  PMR_STATE_DISTRIBUTION: true,
  ESG_DISTRIBUTION_ACCORDING_TO_ENVIRONMENTAL_AND_SOCIAL_CATEGORY: true,
  TCP_PERFORMANCE: true,
  //BarChart
  ESG_SAFEGUARD_PERFORMANCE_RATING: true,
  ESG_ENVIRONMENTAL_AND_SOCIAL_RISK_RATING: true,
  DISBURSEMENT_PROJECTION_LAST_5_YEARS: true,
  PMR_EVOLUTION_LAST_5_YEARS: true,
  PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS_BY_COUNTRY: true,
  PORTFOLIO_OVERVIEW_LAST_5_YEARS: true,
  //MultiDataNotStackedBar
  SUPERVISION_PLAN_PLANNED_VERSUS_EXECUTED: true,
  //RadialBarChart
  SUPERVISION_PLAN_BUDGET_STATUS: true,
  AVERAGE_LAG: true,
  //TextDoubleIndicator
  PROJECTS_PENDING_SIGNATURE_OR_LEGISLATIVE_APPROVAL: true,
  PROJECTS_LAST_DISBURSEMENT_DATE_CLOSE_12MONTHS_OR_EXPIRED: true,
  //Bubbles
  DISBURSEMENT_OVER_TIME: true,
  //MultiProgress
  GCM_ALERTS: true,
  PMR_VALIDATION_STATE: true,
  PCR_NEXT_PRESENTATION: true,
};

export const largeImageIndicatorsProcessedOnDashboard = {
  GCM_ALERTS: true,
};

export const largeImageIndicatorsWithDetailRequest = {
  DISBURSEMENT_OVER_TIME: true,
  DISBURSEMENT_PROJECTION_LAST_5_YEARS: true,
  PORTFOLIO_OVERVIEW_LAST_5_YEARS: true,
  PMR_EVOLUTION_LAST_5_YEARS: true,
};

export const indicatorsToImage = (context: any) => {
  const selectedIndicatorsToImage: any = {};
  for (const ind of context.indicators) {
    if (smallImageIndicators.hasOwnProperty(ind.code)) {
      selectedIndicatorsToImage[ind.code] = true;
    }
  }
  return selectedIndicatorsToImage;
};

export const disbProjLastFiveYearsNames = {
  0: 'DISBURSEMENT_PROJECTION_LAST_5_YEARS_DETAIL',
  1: 'DISBURSEMENT_PROJECTION_LAST_5_YEARS_IGR_DETAIL',
  2: 'DISBURSEMENT_PROJECTION_LAST_5_YEARS_IGR_DETAIL',
  3: 'DISBURSEMENT_PROJECTION_LAST_5_YEARS_IGR_DETAIL',
};

export const approvalsLastFiveYearsNames = {
  0: 'PORTFOLIO_OVERVIEW_LAST_5_YEARS_DETAIL',
  1: 'PORTFOLIO_OVERVIEW_LAST_5_YEARS_TCP_DETAIL',
  2: 'PORTFOLIO_OVERVIEW_LAST_5_YEARS_IGR_DETAIL',
  3: 'PORTFOLIO_OVERVIEW_LAST_5_YEARS_IGR_DETAIL',
};

export const getLargeImagesList = async (context: any, processedList: any[], indicatorKey: IndicatorKeyEnum) => {
  return new Promise<any>((resolve, reject) => {
    const indicatorsToLargeImageObj = indicatorsToLargeImage(context, largeImageIndicatorsWithDetailRequest);
    if (!Object.keys(indicatorsToLargeImageObj).length) {
      resolve([]);
    } else {
      if (
        indicatorKey === IndicatorKeyEnum.DISBURSEMENT_OVER_TIME &&
        indicatorsToLargeImageObj[IndicatorKeyEnum.DISBURSEMENT_OVER_TIME]
      ) {
        resolve(getDisbursementTimeData(context, processedList));
      } else if (
        indicatorKey === IndicatorKeyEnum.DISBURSEMENT_PROJECTION_LAST_5_YEARS &&
        indicatorsToLargeImageObj[IndicatorKeyEnum.DISBURSEMENT_PROJECTION_LAST_5_YEARS]
      ) {
        resolve(
          getDisbProjLastFiveYearsData(
            context,
            processedList,
            indicatorKey,
            addDisbursementProjectionLast5Years,
            disbProjLastFiveYearsNames,
          ),
        );
      } else if (
        indicatorKey === IndicatorKeyEnum.PORTFOLIO_OVERVIEW_LAST_5_YEARS &&
        indicatorsToLargeImageObj[IndicatorKeyEnum.PORTFOLIO_OVERVIEW_LAST_5_YEARS]
      ) {
        resolve(
          getDisbProjLastFiveYearsData(
            context,
            processedList,
            indicatorKey,
            addPortfolioOverviewLast5Years,
            approvalsLastFiveYearsNames,
          ),
        );
      } else if (
        indicatorKey === IndicatorKeyEnum.PMR_EVOLUTION_LAST_5_YEARS &&
        indicatorsToLargeImageObj[IndicatorKeyEnum.PMR_EVOLUTION_LAST_5_YEARS]
      ) {
        resolve(getPmrEvolutionLastFiveYears(context, processedList, indicatorKey));
      } else {
        resolve([]);
      }
    }
  });
};

export const indicatorsToLargeImage = (context: any, indictorsListObj: Object) => {
  const selectedIndicatorsToImage: any = {};
  for (const ind of context.indicators) {
    if (indictorsListObj.hasOwnProperty(ind.code) && ind.includeDetails) {
      selectedIndicatorsToImage[ind.code] = true;
    }
  }
  return selectedIndicatorsToImage;
};

export const getAlreadyProcessedLargeImagesList = (context: any, processedList: any[]) => {
  const indicatorsToLargeImageObj = indicatorsToLargeImage(context, largeImageIndicatorsProcessedOnDashboard);
  if (!Object.keys(indicatorsToLargeImageObj).length) {
    return [];
  } else {
    return cloneDeep(processedList)
      .filter(i => indicatorsToLargeImageObj.hasOwnProperty(i.indicatorKey))
      .map(a => {
        a.largeImageKey = `${a.indicatorKey}_DETAIL`;
        return a;
      });
  }
};

export const resolveEmptyIfNoData = (indicatorId: number, context: any, resolve: Function) => {
  if (!indicatorId || !context || !context.reportFilters || !context.reportFilters.common) {
    resolve([]);
  }
};

export const pareseFiltersForSingleRequest = (list: ProjectProductTypeFilters[], indicatorKey: IndicatorKeyEnum) => {
  const result: any[] = [];
  if (
    list.includes(ProjectProductTypeFilters.Loan) &&
    buttonsFilterConfig[indicatorKey] &&
    buttonsFilterConfig[indicatorKey].Loan
  ) {
    result.push([ProjectProductTypeFilters.Loan]);
  }
  if (
    list.includes(ProjectProductTypeFilters.CT) &&
    buttonsFilterConfig[indicatorKey] &&
    buttonsFilterConfig[indicatorKey].CT
  ) {
    result.push([ProjectProductTypeFilters.CT]);
  }
  if (
    list.includes(ProjectProductTypeFilters.IG_greater_3) &&
    list.includes(ProjectProductTypeFilters.IG_lower_3) &&
    buttonsFilterConfig[indicatorKey] &&
    buttonsFilterConfig[indicatorKey].IG
  ) {
    result.push([ProjectProductTypeFilters.IG_greater_3, ProjectProductTypeFilters.IG_lower_3]);
  } else if (
    list.includes(ProjectProductTypeFilters.IG_greater_3) &&
    !list.includes(ProjectProductTypeFilters.IG_lower_3) &&
    buttonsFilterConfig[indicatorKey] &&
    buttonsFilterConfig[indicatorKey].IG
  ) {
    result.push([ProjectProductTypeFilters.IG_greater_3]);
  } else if (
    !list.includes(ProjectProductTypeFilters.IG_greater_3) &&
    list.includes(ProjectProductTypeFilters.IG_lower_3) &&
    buttonsFilterConfig[indicatorKey] &&
    buttonsFilterConfig[indicatorKey].IG
  ) {
    result.push([ProjectProductTypeFilters.IG_lower_3]);
  }
  return result;
};

export const disbursementLargeImagesNames = {
  0: 'DISBURSEMENT_OVER_TIME_DETAIL',
  1: 'DISBURSEMENT_OVER_TIME_TCP_DETAIL',
  2: 'DISBURSEMENT_OVER_TIME_IGR_DETAIL',
  3: 'DISBURSEMENT_OVER_TIME_IGR_DETAIL',
};

export const hasReportWordKey = (contextIndicators, indicator) => {
  const found = contextIndicators.find(
    a =>
      a.code === indicator.indicatorKey ||
      /* excepción por requerir tarjeta doble de indicador PCR*/
      (indicator.indicatorKey === 'PCR_NEXT_PRESENTATION_UPCOMING' &&
        a.code === IndicatorKeyEnum.PCR_NEXT_PRESENTATION),
  );
  if (found) {
    return found.includeDetails ? `${indicator.indicatorKey}_WORD` : undefined;
  }
  return undefined;
};

export const setReportImageDisplay = (contextIndicators, indicator) => {
  const found = contextIndicators.find(
    a =>
      a.code === indicator.indicatorKey ||
      /* excepción por requerir tarjeta doble de indicador PCR*/
      (indicator.indicatorKey === 'PCR_NEXT_PRESENTATION_UPCOMING' &&
        a.code === IndicatorKeyEnum.PCR_NEXT_PRESENTATION),
  );
  if (found) {
    return found.includeDetails && !indicator.reportWordKey ? ReportImageDisplay.Detail : ReportImageDisplay.Miniature;
  }
  return found;
};

export const getKey = (ind: any) => ind.largeImageKey || ind.reportWordKey || ind.indicatorKey;
export const getKeyOrId = (item: any) => item.reportWordKey || item.id;
export const resetImgIndicators = (isOpen: boolean, setImgIndicators: Function) => {
  if (!isOpen) {
    setImgIndicators([]);
  }
};

export const generateImgsIfData = (imgIndicators: any[], generateImagesAndSubmit: Function) => {
  if (imgIndicators.length) {
    generateImagesAndSubmit();
  }
};

export const hasData = (datasets: any[]) => {
  for (const ds of datasets) {
    if (ds.data && ds.data.length > 0) {
      return true;
    }
  }
  return false;
};
