import { InterestIssuesActionTypes, SET_INTEREST_ISSUES } from '../actions/types';
import InterestIssue from '../../models/InterestIssue';

const initialState: InterestIssue[] = [];

const interestIssuesReducer = (state = initialState, action: InterestIssuesActionTypes): any => {
  switch (action.type) {
    case SET_INTEREST_ISSUES:
      return action.payload;
    default:
      return state;
  }
};
export default interestIssuesReducer;
