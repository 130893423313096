import React from 'react';
import './AccordanceInfoItem.scss';

type Props = {
  label: string;
  data: string;
  fullWidth?: boolean;
  renderdFont?: string;

};


const AccordanceInfoItem = ({ label, data, fullWidth, renderdFont = "#101319" }: Props) => {
  return (
    <div className={`item-label-data ${fullWidth ? 'full-width' : ''}`}>
      <span className="item-label">{label}</span>
      <span className="item-data" style={{ color: `${renderdFont}` }}>{data}</span>
    </div>
  );
};

export default AccordanceInfoItem;
