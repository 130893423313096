const getLocalStatePanel = panelValue => {
  const sessionPanels = localStorage.getItem('analytics_panels');

  if (!sessionPanels) {
    return true;
  } else {
    return JSON.parse(sessionPanels)[panelValue] !== undefined ? JSON.parse(sessionPanels)[panelValue] : true;
  }
};
const setLocalStatePanel = (panelValue, value) => {
  const sessionPanels = localStorage.getItem('analytics_panels');
  if (!sessionPanels) {
    localStorage.setItem(`analytics_panels`, JSON.stringify({ [panelValue]: value }));
  } else {
    const newSessionPanels = sessionPanels && { ...JSON.parse(sessionPanels), [panelValue]: value };
    localStorage.setItem(`analytics_panels`, JSON.stringify(newSessionPanels));
  }
};

export { getLocalStatePanel, setLocalStatePanel };
